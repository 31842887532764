import * as React from "react";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { GlobalConfig } from "stores/wizard/config";
import { useLocalization } from "@shared-ui/localization-context";

export interface CobrandImageProps {
  globalConfig?: GlobalConfig;
}

export const CobrandImage: React.FunctionComponent<CobrandImageProps> = ({ globalConfig }) => {
  const { formatText } = useLocalization();
  const altText = formatText("cobrandImage.altText");
  const { cobrandImageUrl = "" } = globalConfig || {};

  if (!cobrandImageUrl) {
    return null;
  }

  return (
    <EGDSSpacing padding="two">
      <EGDSLayoutGrid>
        <EGDSLayoutGridItem>
          <EGDSFigure imageFit="contain" style={{ alignSelf: "center", paddingBottom: "15%" }}>
            <EGDSImage alt={altText} src={cobrandImageUrl} />
          </EGDSFigure>
        </EGDSLayoutGridItem>
      </EGDSLayoutGrid>
    </EGDSSpacing>
  );
};
