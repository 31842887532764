import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSText, EGDSTextProps } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { getRatingText } from "components/utility/Reviews";
import { Hotel } from "typings/microserviceModels/hotels-flex-module";

const GuestRating = (props: { hotel: Hotel; alignment?: EGDSTextProps["align"] }) => {
  const { formatText } = useLocalization();
  const { hotel, alignment } = props;
  const { hotelOverallReviewRating, reviewCount } = hotel;

  const numberOfReviewsLocalized = "hotels.review.reviewCount";

  if (!(hotelOverallReviewRating && reviewCount)) {
    return (
      <EGDSText size={300} align={alignment}>
        {formatText(numberOfReviewsLocalized, 0, "0")}
      </EGDSText>
    );
  }

  return (
    <div>
      <EGDSText inline size={300} align={alignment} theme="emphasis" weight="medium">
        {formatText("hotelRecentReviews.ratingSlashNoSpace", Math.round(hotelOverallReviewRating))}
      </EGDSText>
      <EGDSSpacing margin={{ inlinestart: "one" }}>
        <EGDSText size={300} inline align={alignment}>
          {getRatingText(hotelOverallReviewRating)} ({formatText(numberOfReviewsLocalized, reviewCount, reviewCount)})
        </EGDSText>
      </EGDSSpacing>
    </div>
  );
};

export default GuestRating;
