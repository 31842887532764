import * as React from "react";
import { LobSubtitleProps, SubtitleProps } from "./typings";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSubheading } from "@egds/react-core/text";
import { Viewport, ViewSmall, ViewMedium, ViewLarge, ViewExtraLarge } from "@shared-ui/viewport-context";

const Subtitle: React.FunctionComponent<SubtitleProps> = ({ text, className }) => (
  <div className={className} data-testid="lobsubtitle">
    <EGDSSubheading>{text}</EGDSSubheading>
  </div>
);

export const LobSubtitle: React.FunctionComponent<LobSubtitleProps> = ({ lobState, className, customSubTitle }) => {
  const { formatText } = useLocalization();
  const text =
    customSubTitle ||
    formatText(lobState.config.heading.locSubtitleTextToken, lobState.config.heading.locSubtitleVariant);
  const breakpointMode = lobState.config.heading.lobSubtitleBreakPoint || "off";

  if (breakpointMode === "off") {
    return null;
  }

  const displayMode: any = {
    on: (
      <ViewSmall>
        <Subtitle text={text} className={className} />
      </ViewSmall>
    ),
    small: (
      <ViewSmall>
        <Subtitle text={text} className={className} />
      </ViewSmall>
    ),
    medium: (
      <ViewMedium>
        <Subtitle text={text} className={className} />
      </ViewMedium>
    ),
    large: (
      <ViewLarge>
        <Subtitle text={text} className={className} />
      </ViewLarge>
    ),
    "extra-large": (
      <ViewExtraLarge>
        <Subtitle text={text} />
      </ViewExtraLarge>
    ),
  };

  return <Viewport>{displayMode[breakpointMode]}</Viewport>;
};
