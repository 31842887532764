import * as React from "react";

import { EGDSText } from "@egds/react-core/text";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { getDirectDistance } from "components/flexComponents/Hotels/views/HotelsViews/HotelUtils";
import { DirectDistanceProps } from "src/components/flexComponents/Hotels/typings";

const DirectDistance = (props: DirectDistanceProps) => {
  const { hotel, context, showIcon = true, alignment } = props;
  const directDistance = getDirectDistance(hotel, context);

  if (!directDistance) {
    return null;
  }

  return (
    <EGDSText size={300} align={alignment}>
      {showIcon && <EGDSIcon className="distance-icon" size={EGDSIconSize.SMALL} name="place" />}
      <EGDSSpacing margin={{ inlinestart: "one" }}>
        <span>{directDistance}</span>
      </EGDSSpacing>
    </EGDSText>
  );
};

export default DirectDistance;
