import * as React from "react";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { EGDSText } from "@egds/react-core/text";

export interface SuperscriptSmallCharactersProps {
  children?: string;
  characters?: string[];
}

/**
 * Superscripts specific characters within a given text and renders them smaller (optional).
 *
 * Example: Hot Rate® becomes Hot Rate <sup><small>®</small></sup>
 *
 * @param children text content
 * @param characters array of characters to superscript (default: "™", "®", "*")
 */
export const SuperscriptSmallCharacters: React.FC<SuperscriptSmallCharactersProps> = ({
  children,
  characters = ["™", "®", "*"],
}) => {
  const keyHelper = new ItemKeyHelper("superscript-characters");
  const regexp = new RegExp(`([${characters.join("")}])`);

  if (!children) {
    return null;
  }

  return (
    <>
      {children.split(regexp).map((textFragment) => {
        return textFragment.match(regexp) ? (
          <EGDSText inline="sup" key={keyHelper.next()}>
            <EGDSText inline="small">{textFragment}</EGDSText>
          </EGDSText>
        ) : (
          <React.Fragment key={keyHelper.next()}>{textFragment}</React.Fragment>
        );
      })}
    </>
  );
};
