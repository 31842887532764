import { UisMicroMessage } from "@shared-ui/clickstream-analytics-context";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { CompositionStore } from "stores/CompositionStore";

export function getAdditionalUismicromessages(
  context: ExtendedContextStore,
  compositionStore: CompositionStore
): UisMicroMessage[] {
  const templateId = compositionStore?.composition?.templateId
    ? String(compositionStore.composition.templateId)
    : undefined;
  const pageTheme = context.searchContext.affinity?.code ? String(context.searchContext.affinity.code) : undefined;

  return [
    {
      schemaName: "experiencePlatform",
      messageContent: {
        pagePlatform: "Flex",
        pageTheme: pageTheme,
        pageTemplateId: templateId,
      },
    },
  ];
}
