// @ts-nocheck
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AnalyticsPayload: { input: any; output: any };
  BigDecimal: { input: any; output: any };
  Locale: { input: any; output: any };
  URL: { input: any; output: any };
  UUID: { input: any; output: any };
};

export type AbandonBookingResponse = {
  __typename?: "AbandonBookingResponse";
  checkoutSessionToken: Scalars["String"]["output"];
  signals: Array<CheckoutUiSignal>;
};

export type AbandonWorkflowMutationResponse = {
  __typename?: "AbandonWorkflowMutationResponse";
  success: Scalars["Boolean"]["output"];
};

export type AbandonedCheckout = {
  __typename?: "AbandonedCheckout";
  abandonedCheckoutId?: Maybe<Scalars["String"]["output"]>;
  card: HomeCard;
  heading: EgdsHeading;
};

export type AbandonedCheckoutDiscardStatus = {
  __typename?: "AbandonedCheckoutDiscardStatus";
  isSuccessful: Scalars["Boolean"]["output"];
};

export type AbandonedCheckoutPriceDetails = {
  __typename?: "AbandonedCheckoutPriceDetails";
  impressionTracking: ClientSideAnalytics;
  leadPrice: DisplayPrice;
  secondaries: Array<Scalars["String"]["output"]>;
  strikethroughPrice?: Maybe<DisplayPrice>;
  urgencyMessage: Scalars["String"]["output"];
};

export type AcceleratorAction = AcceleratorOpenDialogAction | AcceleratorOpenSheetAction;

export type AcceleratorActionText = AcceleratorText & {
  __typename?: "AcceleratorActionText";
  button: AcceleratorButton;
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type AcceleratorBanner = {
  __typename?: "AcceleratorBanner";
  actions?: Maybe<Array<AcceleratorButton>>;
  fullWidth: Scalars["Boolean"]["output"];
  leadingIcon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
  theme: Scalars["String"]["output"];
};

export type AcceleratorBasicText = AcceleratorText & {
  __typename?: "AcceleratorBasicText";
  text: Scalars["String"]["output"];
};

export type AcceleratorBlockElement = AcceleratorPerformanceComparisonElement | AcceleratorSettingsElement;

export type AcceleratorButton = {
  __typename?: "AcceleratorButton";
  action?: Maybe<AcceleratorAction>;
  buttonType: AcceleratorButtonTypes;
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type AcceleratorButtonTypes = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type AcceleratorCard = {
  __typename?: "AcceleratorCard";
  /** @deprecated Use cardAction instead to differentiate between card and button actions */
  action?: Maybe<AcceleratorOpenSheetAction>;
  button?: Maybe<AcceleratorButton>;
  cardAction?: Maybe<AcceleratorOpenSheetAction>;
  content?: Maybe<Array<AcceleratorText>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  leadingGraphic?: Maybe<UiGraphic>;
  trailingGraphic?: Maybe<UiGraphic>;
};

export type AcceleratorCoaching = {
  __typename?: "AcceleratorCoaching";
  expiringAccelerators?: Maybe<Array<AcceleratorCard>>;
};

export type AcceleratorDates = {
  __typename?: "AcceleratorDates";
  addMoreAction?: Maybe<AcceleratorButton>;
  datePicker: Array<EgdsDateRangePicker>;
  description?: Maybe<Scalars["String"]["output"]>;
  errorMessages?: Maybe<AcceleratorErrorMessage>;
  heading: Scalars["String"]["output"];
  noEndDateCheckBox?: Maybe<EgdsCheckBox>;
  removeAction?: Maybe<AcceleratorButton>;
};

export type AcceleratorDialog = AcceleratorTableDialog;

export type AcceleratorErrorMessage = {
  __typename?: "AcceleratorErrorMessage";
  errors: Array<AcceleratorPair>;
};

export type AcceleratorExpando = {
  __typename?: "AcceleratorExpando";
  collapsed: Scalars["Boolean"]["output"];
  collapsedLabel: Scalars["String"]["output"];
  content?: Maybe<Array<AcceleratorText>>;
  expandedLabel: Scalars["String"]["output"];
};

export type AcceleratorIconText = AcceleratorText & {
  __typename?: "AcceleratorIconText";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type AcceleratorInfo = {
  __typename?: "AcceleratorInfo";
  additionalMargin: Scalars["Int"]["output"];
  createDateTime: DateTime;
  dateRange: DateRange;
  id: Scalars["Int"]["output"];
  propertyId: Scalars["Int"]["output"];
};

export interface AcceleratorInput {
  additionalMargin: Scalars["Int"]["input"];
  dateRange: DateRangeInput;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  propertyId: Scalars["Int"]["input"];
}

export type AcceleratorLinkAction = {
  __typename?: "AcceleratorLinkAction";
  resource: Scalars["String"]["output"];
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"]["output"];
};

export type AcceleratorLinkText = AcceleratorText & {
  __typename?: "AcceleratorLinkText";
  link: AcceleratorLinkAction;
  text: Scalars["String"]["output"];
  trailingIcon?: Maybe<Icon>;
};

export type AcceleratorManageComponents = {
  __typename?: "AcceleratorManageComponents";
  blockoutDates?: Maybe<AcceleratorDates>;
  settingsCard?: Maybe<AcceleratorCard>;
  stayDates?: Maybe<AcceleratorDates>;
  summaryCard?: Maybe<AcceleratorCard>;
};

export type AcceleratorMoreInfoTrigger = {
  __typename?: "AcceleratorMoreInfoTrigger";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
  triggerAction: AcceleratorMoreInfoTriggerAction;
};

export type AcceleratorMoreInfoTriggerAction = {
  __typename?: "AcceleratorMoreInfoTriggerAction";
  text: Array<AcceleratorText>;
};

export type AcceleratorOpenDialogAction = {
  __typename?: "AcceleratorOpenDialogAction";
  dialog?: Maybe<AcceleratorDialog>;
};

export type AcceleratorOpenSheetAction = {
  __typename?: "AcceleratorOpenSheetAction";
  sheet?: Maybe<AcceleratorSheet>;
};

export type AcceleratorPair = {
  __typename?: "AcceleratorPair";
  id: Scalars["String"]["output"];
  value: AcceleratorText;
};

export type AcceleratorPerformanceComparisonCard = {
  __typename?: "AcceleratorPerformanceComparisonCard";
  heading?: Maybe<AcceleratorText>;
  improvementMessage?: Maybe<AcceleratorText>;
  withAcceleratorPerformance?: Maybe<AcceleratorText>;
  withoutAcceleratorPerformance?: Maybe<AcceleratorText>;
};

export type AcceleratorPerformanceComparisonElement = {
  __typename?: "AcceleratorPerformanceComparisonElement";
  card: AcceleratorPerformanceComparisonCard;
};

export type AcceleratorQuickSetupSheet = {
  __typename?: "AcceleratorQuickSetupSheet";
  advancedSettingsMessage?: Maybe<AcceleratorExpando>;
  banner?: Maybe<AcceleratorBanner>;
  basicSettings?: Maybe<AcceleratorSheetBlock>;
  performancePlayback?: Maybe<AcceleratorSheetBlock>;
  primaryButton: AcceleratorButton;
  secondaryButton?: Maybe<AcceleratorButton>;
  toolbarTitle?: Maybe<Scalars["String"]["output"]>;
};

export interface AcceleratorSelectionInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type AcceleratorSettings = EgdsBasicSelect | EgdsCheckBoxGroup;

export type AcceleratorSettingsCard = {
  __typename?: "AcceleratorSettingsCard";
  description?: Maybe<Array<AcceleratorText>>;
  footer?: Maybe<Array<AcceleratorText>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  settingFields?: Maybe<AcceleratorSettings>;
};

export type AcceleratorSettingsElement = {
  __typename?: "AcceleratorSettingsElement";
  settings: AcceleratorSettings;
};

export interface AcceleratorSettingsInput {
  selections: Array<AcceleratorSelectionInput>;
}

export type AcceleratorSettingsSheet = {
  __typename?: "AcceleratorSettingsSheet";
  cancelButtonLabel?: Maybe<Scalars["String"]["output"]>;
  saveButtonLabel?: Maybe<Scalars["String"]["output"]>;
  settingCards?: Maybe<Array<AcceleratorSettingsCard>>;
  toolbarTitle?: Maybe<Scalars["String"]["output"]>;
};

export type AcceleratorSheet = AcceleratorQuickSetupSheet | AcceleratorSettingsSheet;

export type AcceleratorSheetBlock = {
  __typename?: "AcceleratorSheetBlock";
  content?: Maybe<Array<AcceleratorBlockElement>>;
  footer?: Maybe<Array<AcceleratorText>>;
  heading: Scalars["String"]["output"];
  moreInfo?: Maybe<AcceleratorMoreInfoTrigger>;
  subheading?: Maybe<AcceleratorText>;
};

export type AcceleratorStylizedText = AcceleratorText & {
  __typename?: "AcceleratorStylizedText";
  leadingIcon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
  trailingIcon?: Maybe<Icon>;
  weight?: Maybe<TextWeight>;
};

export type AcceleratorTable = {
  __typename?: "AcceleratorTable";
  columnNames: Array<AcceleratorPair>;
  rows: Array<AcceleratorTableRow>;
};

export type AcceleratorTableCell = {
  __typename?: "AcceleratorTableCell";
  column: Scalars["String"]["output"];
  value: AcceleratorText;
};

export type AcceleratorTableDialog = {
  __typename?: "AcceleratorTableDialog";
  closeButtonLabel?: Maybe<Scalars["String"]["output"]>;
  content: AcceleratorTable;
  description: Array<AcceleratorText>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type AcceleratorTableRow = {
  __typename?: "AcceleratorTableRow";
  cells: Array<AcceleratorTableCell>;
};

export interface AcceleratorText {
  text: Scalars["String"]["output"];
}

export type AcceleratorUpsertFailureResponse = {
  __typename?: "AcceleratorUpsertFailureResponse";
  message?: Maybe<AcceleratorBanner>;
};

export type AcceleratorUpsertResponse = {
  __typename?: "AcceleratorUpsertResponse";
  failure?: Maybe<AcceleratorUpsertFailureResponse>;
  success?: Maybe<AcceleratorUpsertSuccessResponse>;
};

export type AcceleratorUpsertSuccessResponse = {
  __typename?: "AcceleratorUpsertSuccessResponse";
  acceleratorsInfo: Array<AcceleratorInfo>;
  message?: Maybe<AcceleratorCard>;
};

export interface AcceleratorsUpsertInput {
  accelerators: Array<AcceleratorInput>;
}

export interface AccessibilityData {
  hint?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
}

export interface AccessibilityField {
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
}

export type AccountMerge = {
  __typename?: "AccountMerge";
  accountMergeInfoLink?: Maybe<AccountMergeLink>;
  /** @deprecated AccountMergeLink should be used */
  accountMergelink?: Maybe<EgdsStandardLink>;
  actionButton?: Maybe<AccountMergeActionButton>;
  brandLogos?: Maybe<Array<Mark>>;
  impressionAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subTitles?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AccountMergeAction = {
  __typename?: "AccountMergeAction";
  interactionAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  outcomeAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  resourceUrl?: Maybe<Scalars["String"]["output"]>;
};

export type AccountMergeActionButton = {
  __typename?: "AccountMergeActionButton";
  accountMergeAction?: Maybe<AccountMergeAction>;
  /** @deprecated AccountMergeAction should be used */
  action?: Maybe<UiLinkAction>;
  button?: Maybe<UiPrimaryButton>;
};

export type AccountMergeLink = {
  __typename?: "AccountMergeLink";
  interactionAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  link?: Maybe<EgdsStandardLink>;
};

export type AccountMergeMutationResponse = {
  __typename?: "AccountMergeMutationResponse";
  outcomeAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  redirectURL?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export interface AccountMergeRequestContextInput {
  cmsToken?: InputMaybe<Scalars["String"]["input"]>;
  flowIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  referrerURL?: InputMaybe<Scalars["String"]["input"]>;
  scenario?: InputMaybe<IdentityAuthenticationScenario>;
}

export type ActionButtonTypes = UiPrimaryButton | UiSecondaryButton;

export type ActionCheckBox = {
  __typename?: "ActionCheckBox";
  analytics: ClientSideAnalytics;
  checkBox: EgdsBasicCheckBox;
};

export type ActionDetails = AccessibilityField & {
  __typename?: "ActionDetails";
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.action.accessibility instead. */
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. */
  action: Scalars["String"]["output"];
  /** @deprecated Do not use. */
  details?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accessibilityLabel instead, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use action field instead, enums are discouraged. */
  name: ActionDetailsType;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.text instead. */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActionDetailsType =
  | "AUTHWINDOW"
  | "CLOSE"
  | "COPY"
  | "DATEPICKER"
  | "EXTERNAL_LINK"
  | "FREE_LX"
  | "OPEN"
  | "TERMS_AND_CONDITIONS";

export type ActionHistory = {
  __typename?: "ActionHistory";
  campaignId: Scalars["String"]["output"];
  entryPoints?: Maybe<EntryData>;
  expUserId: Scalars["String"]["output"];
  sharePoints?: Maybe<EntryData>;
  total: Scalars["Int"]["output"];
  triviaPoints?: Maybe<Array<EntryData>>;
};

export type ActionLink = {
  __typename?: "ActionLink";
  action: ActionType;
  analytics: ClientSideAnalytics;
  text: Scalars["String"]["output"];
};

export interface ActionPanelContext {
  coachingParameters?: InputMaybe<SupplyContentCoachingParameters>;
  type: Scalars["String"]["input"];
  view: Scalars["String"]["input"];
}

export type ActionPanelPrimaryButton = {
  __typename?: "ActionPanelPrimaryButton";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: UiPrimaryButton;
};

export type ActionPanelSecondaryButton = {
  __typename?: "ActionPanelSecondaryButton";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: UiSecondaryButton;
};

export type ActionPayload = {
  __typename?: "ActionPayload";
  name: UiActionName;
};

export type ActionType =
  | "DownloadDPReport"
  | "JUMPLINK"
  | "MODAL"
  | "Modal"
  | "Open"
  | "OpenPriceChangeAlert"
  | "REDIRECT";

export type ActiveState = "ACTIVE" | "INACTIVE" | "UNKNOWN" | "USED";

export type ActivitiesByCategoriesModule = {
  __typename?: "ActivitiesByCategoriesModule";
  action?: Maybe<ActivityGroupAction>;
  groups: Array<ActivityGroup>;
  seoStructuredData?: Maybe<Scalars["String"]["output"]>;
};

export interface ActivitiySearchCriteriaInput {
  primary: PrimaryActivitySearchCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type Activity = {
  __typename?: "Activity";
  activityDuration?: Maybe<EgdsText>;
  /** @deprecated use clientSideAnalytics */
  analytics?: Maybe<ActivityAnalytics>;
  /** @deprecated Use cardBadges instead */
  badges?: Maybe<ActivityTileBadges>;
  cardBadges?: Maybe<ActivityCardBadges>;
  categoryDetails?: Maybe<Array<Maybe<ActivityCategoryDetails>>>;
  clickAction?: Maybe<ActivityAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  description?: Maybe<Scalars["String"]["output"]>;
  detailsLink?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated Use activityDuration instead */
  duration: ActivityDuration;
  enabled: Scalars["Boolean"]["output"];
  features: ActivitySearchFeature;
  /** @deprecated should use features instead */
  freeCancel: Scalars["Boolean"]["output"];
  /** @deprecated Use imageCarousel instead */
  gallery?: Maybe<ActivityGallery>;
  id: Scalars["String"]["output"];
  /** @deprecated Use imageCarousel instead */
  image?: Maybe<Image>;
  imageCarousel?: Maybe<EgdsCarousel>;
  /** @deprecated Use leadTicketPrice instead */
  leadTicket: ActivitySearchTicket;
  leadTicketPrice: ActivityPriceDisplay;
  link?: Maybe<Scalars["String"]["output"]>;
  location?: Maybe<Coordinates>;
  /** @deprecated Use primary instead */
  name: Scalars["String"]["output"];
  /** @deprecated We send badges instead directly now */
  priceMetadata?: Maybe<ActivityPriceMetadata>;
  primary: EgdsText;
  redemption?: Maybe<ActivityRedemption>;
  reviewSummary?: Maybe<ActivityReviewsSummary>;
  saveItem?: Maybe<TripsSaveItem>;
  secondaryMessage?: Maybe<Scalars["String"]["output"]>;
  sectionRef?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use TripsSaveItem */
  shortlistingMessages?: Maybe<ActivityShortlistingMessages>;
  supplierName?: Maybe<Scalars["String"]["output"]>;
  thirdPartyPartnerName?: Maybe<Scalars["String"]["output"]>;
  tripUpdateButtons?: Maybe<ActivityTripUpdateButtons>;
  /** @deprecated should use clickAction instead */
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type ActivityAboveTheFold = {
  __typename?: "ActivityAboveTheFold";
  banners?: Maybe<Array<ActivityBanner>>;
  breadcrumbs: Array<ActivityBreadcrumb>;
  pills?: Maybe<Array<ActivityPill>>;
};

export interface ActivityAccessibility {
  label?: Maybe<Scalars["String"]["output"]>;
}

export type ActivityAction = {
  __typename?: "ActivityAction";
  accessibilityText: Scalars["String"]["output"];
  /** @deprecated use clickanalytics */
  analytics?: Maybe<ActivityAnalytics>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  openInNewTab: Scalars["Boolean"]["output"];
  uri?: Maybe<HttpUri>;
  url: Scalars["String"]["output"];
};

export type ActivityActionCallbackMessages = {
  __typename?: "ActivityActionCallbackMessages";
  failure: Scalars["String"]["output"];
  success: Scalars["String"]["output"];
};

export type ActivityActionTile = {
  __typename?: "ActivityActionTile";
  /** @deprecated use clientSideAnalytics */
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  enabled: Scalars["Boolean"]["output"];
  image?: Maybe<Image>;
  link?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  primary: EgdsText;
  primaryAction: ActivityAction;
  secondaryMessage?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityAddOfferToTripAction = UiAction & {
  __typename?: "ActivityAddOfferToTripAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  offerNaturalKey: ActivityOfferNaturalKey;
};

export type ActivityAnalytics = {
  __typename?: "ActivityAnalytics";
  linkName: Scalars["String"]["output"];
  microMessagePayload?: Maybe<Scalars["String"]["output"]>;
  referrerId: Scalars["String"]["output"];
};

export type ActivityAnchoredMessage = {
  __typename?: "ActivityAnchoredMessage";
  accessibilityText: Scalars["String"]["output"];
  clickAction?: Maybe<ActivityClickAction>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityAssortedComponentsTile = {
  __typename?: "ActivityAssortedComponentsTile";
  pills?: Maybe<ActivityPills>;
  shopWithPoints?: Maybe<ActivityToggle>;
  /** @deprecated Use sortSection for the consistency across all platforms */
  sort?: Maybe<ShoppingDropdownField>;
  sortDisclaimer?: Maybe<ActivityAnchoredMessage>;
  sortSection?: Maybe<ShoppingSortAndFilterSection>;
};

export type ActivityAvailability = {
  __typename?: "ActivityAvailability";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  date: Date;
  formattedDate: EgdsPlainText;
  formattedPrice?: Maybe<Scalars["String"]["output"]>;
  highlighted: Scalars["Boolean"]["output"];
  isAvailable: Scalars["Boolean"]["output"];
  /** @deprecated Use formattedPrice instead */
  price?: Maybe<Money>;
  priceTextTheme?: Maybe<ActivityAvailabilityPriceTextTheme>;
};

export type ActivityAvailabilityPriceTextTheme = "POSITIVE";

export type ActivityBadge = {
  __typename?: "ActivityBadge";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text?: Maybe<Scalars["String"]["output"]>;
  type: ActivityBadgeType;
};

export type ActivityBadgeType = "CAMPAIGN_DEAL" | "DEAL_ADD_ON" | "DEAL_GENERIC" | "DEAL_MEMBER" | "GLOBAL_LOW_TIER";

export type ActivityBanner = {
  __typename?: "ActivityBanner";
  action?: Maybe<ActivityMessageActionLink>;
  analytics?: Maybe<ActivityAnalytics>;
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  /** @deprecated use title instead */
  primaryText: Icon;
  secondaryAction?: Maybe<ActivityMessageActionLink>;
  /** @deprecated use subtitle instead */
  secondaryText: Icon;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: ActivityBannerType;
  /** @deprecated use icon/mark instead as per the use case */
  view: Icon;
};

export type ActivityBannerType = "ALERT" | "INFO" | "SHOP_WITH_POINTS";

export type ActivityBasicTile = {
  __typename?: "ActivityBasicTile";
  /** @deprecated use clientSideAnalytics */
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  enabled: Scalars["Boolean"]["output"];
  image?: Maybe<Image>;
  link?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use primary instead */
  name: Scalars["String"]["output"];
  primary: EgdsText;
  secondaryMessage?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityBreadcrumb = {
  __typename?: "ActivityBreadcrumb";
  analytics?: Maybe<ActivityAnalytics>;
  label: Scalars["String"]["output"];
  selectionsToRemove: Array<ActivitySelectedValue>;
};

export type ActivityButtonDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityButtonDialogTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  button: EgdsButton;
};

export type ActivityCancellationType = "FREE_CANCELLATION" | "NO_CANCELLATION";

export interface ActivityCard {
  analytics?: Maybe<ClientSideAnalytics>;
}

export type ActivityCardBadges = {
  __typename?: "ActivityCardBadges";
  primary?: Maybe<EgdsBadge>;
  secondary?: Maybe<EgdsBadge>;
};

export type ActivityCardContent =
  | ActivityCardHeadingContent
  | ActivityCardHtmlContent
  | ActivityCardSectionContent
  | ActivityCardTextContent
  | ActivityCardTextListContent
  | ActivityPriceDisplay;

export type ActivityCardHeadingContent = {
  __typename?: "ActivityCardHeadingContent";
  heading?: Maybe<EgdsText>;
};

export type ActivityCardHtmlContent = {
  __typename?: "ActivityCardHtmlContent";
  expando?: Maybe<EgdsExpando>;
  heading?: Maybe<EgdsText>;
  text: Scalars["String"]["output"];
};

export type ActivityCardLinkListContent = {
  __typename?: "ActivityCardLinkListContent";
  heading?: Maybe<EgdsHeading>;
  list: EgdsLinkList;
};

export type ActivityCardReviewsRatingContent = {
  __typename?: "ActivityCardReviewsRatingContent";
  disclaimer?: Maybe<ActivityDisclaimerDialog>;
  primary?: Maybe<EgdsText>;
  secondaries?: Maybe<Array<EgdsText>>;
};

export type ActivityCardSectionContent = {
  __typename?: "ActivityCardSectionContent";
  columns: Array<ActivityCardSectionContentColumn>;
  /**
   * @deprecated
   *         Please start using the rows within columns in ActivityCardSectionContent instead of using this field directly.
   *         The new pattern allows us query both rows and columns in a more SDUI way.
   *
   */
  rows: Array<ActivityCardSectionContentElement>;
};

export type ActivityCardSectionContentColumn = {
  __typename?: "ActivityCardSectionContentColumn";
  rows: Array<ActivityCardSectionContentElement>;
};

export type ActivityCardSectionContentElement =
  | ActivityCardHeadingContent
  | ActivityCardHtmlContent
  | ActivityCardLinkListContent
  | ActivityCardReviewsRatingContent
  | ActivityCardTextContent
  | ActivityCardTextListContent
  | ActivityPriceDisplay;

export type ActivityCardTextContent = {
  __typename?: "ActivityCardTextContent";
  expando?: Maybe<EgdsExpando>;
  heading?: Maybe<EgdsText>;
  items: Array<EgdsText>;
};

export type ActivityCardTextListContent = {
  __typename?: "ActivityCardTextListContent";
  expando?: Maybe<EgdsExpando>;
  heading?: Maybe<EgdsText>;
  icon?: Maybe<Icon>;
  list: EgdsTextList;
};

export type ActivityCarouselAccessibilityData = ActivityAccessibility & {
  __typename?: "ActivityCarouselAccessibilityData";
  label?: Maybe<Scalars["String"]["output"]>;
  nextButton?: Maybe<Scalars["String"]["output"]>;
  prevButton?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityCarouselContainer = {
  __typename?: "ActivityCarouselContainer";
  accessibility?: Maybe<ActivityAccessibility>;
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  cards: Array<ActivityCard>;
  heading?: Maybe<EgdsHeading>;
};

export type ActivityCategory =
  | "ADULTS_ONLY_SHOWS"
  | "ADVENTURES"
  | "ADVENTURE_OUTDOOR"
  | "AIRBOAT_TOURS"
  | "AIRPLANE_RIDES"
  | "AIRPLANE_TOURS"
  | "AIRPORT_GROUND_TRANSPORTATION"
  | "AIRPORT_LOUNGES"
  | "AIRPORT_TO_AIRPORT_TRANSFERS"
  | "AIR_BALLOON_HELICOPTER_TOURS"
  | "AIR_HELICOPTER_BALLOON_TOURS"
  | "AMUSEMENT_THEME_PARKS"
  | "ARCHITECTURE_TOURS"
  | "ART_CLASSES"
  | "ART_CULTURE_HISTORY_TOURS"
  | "ART_MUSIC_LITERARYTOURS"
  | "ATTRACTIONS"
  | "ATTRACTION_TICKETS"
  | "BALLOON_RIDES"
  | "BALLOON_TOURS"
  | "BAR_PUB_TOURS"
  | "BEER_BREWERY_TOURS"
  | "BEYOND_THE_CITY"
  | "BIKE_MOUNTAIN_BIKE_TOURS"
  | "BIKE_RENTALS"
  | "BOAT_RENTAL"
  | "BOAT_TOURS"
  | "BOAT_TOURS_CRUISES"
  | "BRUNCHC_RUISES"
  | "BUNGEE_JUMPING_SKYDIVING"
  | "BUS_MINIVAN_TOURS"
  | "CABARET"
  | "CAMPAIGN_DEALS"
  | "CASTLE_PALACE_TOURS"
  | "CHAUFFER_SERVICES"
  | "CHRISTMAS"
  | "CIRQUE"
  | "CIRQUE_DU_SOLEIL"
  | "CIRQUE_DU_SOLEILSHOWS"
  | "CITY_AND_ATTRACTION_TRANSFERS"
  | "CITY_ATTRACTION_TRANSFERS"
  | "CITY_TOURS"
  | "CITY_TOURS_PACKAGES"
  | "CLASSES_WORKSHOPS"
  | "CLUB_AND_PARTY_BUS_TOURS"
  | "CLUB_PARTY_BUS_TOURS"
  | "COFFEE_TEA_TOURS"
  | "COMEDY_SHOWS"
  | "CONCERTS_SPECIAL_EVENTS"
  | "COOKING_CLASSES"
  | "CRUISES_BOAT_TOURS"
  | "CRUISES_WATER_TOURS"
  | "CULTURAL_HERITAGE_EXPERIENCES"
  | "CUSTOM_PRIVATE_TOURS"
  | "DANCE_CLASSES"
  | "DAY_OF_THE_DEAD"
  | "DAY_SPAS"
  | "DAY_TRIPS"
  | "DAY_TRIPS_EXCURSIONS"
  | "DINING_EXPERIENCES"
  | "DINNER_CRUISES"
  | "DINNER_THEATER"
  | "DISNEY"
  | "DISNEY_PARKS"
  | "DOLPHIN_WHALE_WATCHING"
  | "DOSE_OF_CULTURE"
  | "DUCK_TOURS"
  | "EASTER"
  | "FAMILY_FRIENDLY"
  | "FASHION_SHOWS_TOURS"
  | "FISHING_CHARTERS_TOURS"
  | "FISHING_TOURS_AND_CHARTERS"
  | "FOOD_DRINK"
  | "FOOD_DRINK_NIGHTLIFE"
  | "FOOD_TOURS"
  | "FUN_FOR_FOODIES"
  | "FUN_ON_THE_WATER"
  | "GALLERIES_MUSEUMS"
  | "GAMES_SHOWS_SPECIAL_EVENTS"
  | "GETTING_AROUND"
  | "GOLF_TEE_TIMES"
  | "GOLF_TOURS_TEE_TIMES"
  | "HALLOWEEN"
  | "HAMMAMS_TURKISH_BATHS"
  | "HELICOPTER_RIDES"
  | "HELICOPTER_TOURS"
  | "HIGH_SPEED_DRIVING"
  | "HIGH_SPEED_RIDES"
  | "HIKING_CAMPING"
  | "HISTORICAL_TOURS"
  | "HISTORY_CULTURE"
  | "HOLIDAY_SEASONAL_TOURS"
  | "HONEYMOON_PACKAGE"
  | "HONEYMOON_PACKAGES"
  | "HOP_ON_HOP_OFF"
  | "HOP_ON_HOP_OFF_TOURS"
  | "HORSE_CARRIAGE_RIDES"
  | "HOT_SPRINGS_THERMAL_SPAS"
  | "INTERESTS"
  | "KAYAKING_CANOEING"
  | "LATEST_GREATEST"
  | "LIFT_TICKETS"
  | "LIKELY_TO_SELL_OUT"
  | "LITERARY_ART_MUSIC_TOURS"
  | "LOCAL_EXPERT_PICKS"
  | "LUNCH_CRUISES"
  | "MOTORCYCLE_SCOOTER_TOURS"
  | "MOVIE_TV_TOURS"
  | "MULTIDAY_CRUISES"
  | "MULTI_DAY_EXTENDED_TOURS"
  | "MUSEUM_TICKETS"
  | "MUSEUM_TICKETS_PASSES"
  | "MUST_SEE_SIGHTS"
  | "NA"
  | "NATIONAL_HOLIDAYS"
  | "NEW"
  | "NEWY_EARS"
  | "NIGHTLIFE"
  | "NIGHTLIFE_PASSES"
  | "NIGHT_CRUISES"
  | "NIGHT_TOURS"
  | "ONSENS"
  | "OTHER_ANIMALS_ACTIVITIES"
  | "OTHER_ANIMAL_ACTIVITIES"
  | "OTHER_CLASSES"
  | "OTHER_OUTDOOR_ACTIVITIES"
  | "OTHER_SEASONAL_EVENTS"
  | "OTHER_TOURS"
  | "OTHER_TRANSFERS"
  | "OTHER_TRANSPORTATION"
  | "OTHER_WATER_ACTIVITIES"
  | "PHOTOGRAPHY_TOURS"
  | "PORT_TRANSFERS"
  | "PRIVATE_CUSTOM_TOURS"
  | "PRIVATE_DAY_TRIPS"
  | "PRIVATE_TOURS"
  | "PRIVATE_TRANSFERS"
  | "PURE_ROMANCE"
  | "RELIGIOUS_SPIRITUAL_TOURS"
  | "RIVER_HARBOUR_CRUISES"
  | "ROMAN_STAYCATION"
  | "SAFARIS"
  | "SAILING_TRIPS"
  | "SCOOTER_RENTALS"
  | "SEGWAY_TOURS"
  | "SHARED_TRANSFERS"
  | "SHARK_DIVING"
  | "SHOPPING_FASHION"
  | "SHOPPING_PASSES_OFFERS"
  | "SHOPPING_TOURS"
  | "SHORE_EXCURIONS"
  | "SHOWS_CONCERTS"
  | "SHOW_SPORT_TICKETS"
  | "SIGHTSEEING_ATTRACTION_PASSES"
  | "SIGHTSEEING_CITY_PASSES"
  | "SIGHTSEEING_PASSES"
  | "SKIING_RENTALS"
  | "SKIP_THE_LINE_TOURS"
  | "SNORKELING_SCUBA_DIVING"
  | "SNOWBOARDING_RENTALS"
  | "SNOWMOBILE_TOURS"
  | "SNOWSHOEING"
  | "SPA"
  | "SPA_WELLNESS"
  | "SPEED_BOATS_JET_SKIS"
  | "SPORTING_EVENTS_PACKAGES"
  | "SPORTS_PACKAGES"
  | "STAY_ACTIVE"
  | "SUBMARINE_TOURS"
  | "SUNSET_CRUISES"
  | "SUPERNATURAL_TOURS"
  | "SUSTAINABLE_TOURS"
  | "SWIM_WITH_DOLPHINS"
  | "THEATER_SHOWS_MUSICALS"
  | "THEME_PARKS"
  | "THEME_PARK_TICKETS_TOURS"
  | "THE_GREAT_OUTDOORS"
  | "THRILL_SEEKER"
  | "TICKETS"
  | "TOURS_ACTIVITIES"
  | "TOURS_SIGHTSEEING"
  | "TRANSPORTATION_SERVICES"
  | "TREAT_YOURSELF"
  | "TUBING"
  | "UNIVERSAL_THEME_PARKS"
  | "VALENTINES_DAY"
  | "WALKING_BIKE_TOURS"
  | "WALKING_TOURS"
  | "WATERSKIING_WAKEBOARDING"
  | "WATER_ACTIVITIES"
  | "WATER_PARKS"
  | "WEDDING_CEREMONIES"
  | "WEDDING_HONEYMOON"
  | "WEDDING_PACKAGES"
  | "WEDDING_SHONEYMOONS"
  | "WHAT_TO_DO_WITH_GROUPS"
  | "WHAT_TO_DO_WITH_KIDS"
  | "WHITE_WATER_RAFTING"
  | "WILDLIFE_NATURE"
  | "WINE_TASTING_WINERY_TOURS"
  | "WINTER_ACTIVITIES"
  | "YOGA_CLASS"
  | "YOGA_CLASSES"
  | "ZOO_TICKETS"
  | "ZOO_TICKETS_PASSES";

export type ActivityCategoryDetails = {
  __typename?: "ActivityCategoryDetails";
  category: ActivityCategory;
  enabled: Scalars["Boolean"]["output"];
  isSelected: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  raw: Scalars["String"]["output"];
  size: FormattedNumber;
};

export type ActivityCategoryType =
  | "ACTIVITIES"
  | "CATEGORIES"
  | "DEALS_PROMOTIONS"
  | "NA"
  | "RECOMMENDATIONS"
  | "TOURS"
  | "TRANSPORTATION";

export interface ActivityClickAction {
  accessibilityText: Scalars["String"]["output"];
  /** @deprecated Use ClientSideAnalytics instead. */
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
}

export type ActivityCloseDialogAction = UiAction & {
  __typename?: "ActivityCloseDialogAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ActivityCloseDialogClickAction = ActivityClickAction & {
  __typename?: "ActivityCloseDialogClickAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
};

export type ActivityCopyLinkAction = ActivityNavigationBarClickAction & {
  __typename?: "ActivityCopyLinkAction";
  accessibilityText: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  toast?: Maybe<EgdsToast>;
  value: Scalars["String"]["output"];
};

export type ActivityCrossSellSearchResults = {
  __typename?: "ActivityCrossSellSearchResults";
  group: ActivityGroup;
};

export type ActivityDateRange = {
  __typename?: "ActivityDateRange";
  endDate: Date;
  startDate: Date;
};

export interface ActivityDateRangeInput {
  endDate: DateInput;
  startDate: DateInput;
}

export type ActivityDateRangePickerDialog = ActivityDialog & {
  __typename?: "ActivityDateRangePickerDialog";
  dateRangePicker: EgdsDateRangePicker;
  trigger: ActivityDialogTrigger;
};

export type ActivityDestination = {
  __typename?: "ActivityDestination";
  coordinates?: Maybe<Coordinates>;
  pinnedActivityId?: Maybe<Scalars["String"]["output"]>;
  referencedActivityId?: Maybe<Scalars["String"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
  regionType?: Maybe<Scalars["String"]["output"]>;
};

export interface ActivityDestinationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  pinnedActivityId?: InputMaybe<Scalars["String"]["input"]>;
  referencedActivityId?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
  regionType?: InputMaybe<Scalars["String"]["input"]>;
}

export type ActivityDetailViewOptions = "NON_CONSOLIDATED_OFFERS" | "SHOP_WITH_POINTS" | "THIRD_PARTY_REVIEWS";

export type ActivityDetailsMessagingAction = {
  __typename?: "ActivityDetailsMessagingAction";
  text?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["URL"]["output"];
};

export type ActivityDetailsUisPayLoad = {
  __typename?: "ActivityDetailsUisPayLoad";
  detailsPayload: Scalars["String"]["output"];
};

export interface ActivityDialog {
  trigger: ActivityDialogTrigger;
}

export interface ActivityDialogTrigger {
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
}

export type ActivityDisclaimerDialog = ActivityDialog & {
  __typename?: "ActivityDisclaimerDialog";
  contents: Array<EgdsText>;
  dialog: EgdsActionDialog;
  dialogActions?: Maybe<Array<ActivityClickAction>>;
  trigger: ActivityDialogTrigger;
};

export type ActivityDiscountType =
  | "AIR_ATTACH_MIP"
  | "AIR_HOTEL_ATTACH_MIP"
  | "CAMPAIGN_DEAL"
  | "FREE_ACTIVITY"
  | "GUEST"
  | "HOTEL_ATTACH_MIP"
  | "MIP"
  | "MOD"
  | "STRIKE_OUT";

export type ActivityDuration = {
  __typename?: "ActivityDuration";
  formatted?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  raw: Duration;
};

export type ActivityEmailAction = ActivityNavigationBarClickAction & {
  __typename?: "ActivityEmailAction";
  accessibilityText: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  mailBody: Scalars["String"]["output"];
  mailSubject: Scalars["String"]["output"];
  mailTo?: Maybe<MailToUri>;
};

export type ActivityEmptyStateTile = {
  __typename?: "ActivityEmptyStateTile";
  analytics?: Maybe<ClientSideAnalytics>;
  body: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
};

export type ActivityErrorMessagingClickAction = ActivityClickAction & {
  __typename?: "ActivityErrorMessagingClickAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
};

export type ActivityEventLocationDetails = {
  __typename?: "ActivityEventLocationDetails";
  address: Address;
  coordinates: Coordinates;
  description: Scalars["String"]["output"];
  formattedAddress?: Maybe<Scalars["String"]["output"]>;
  region: ActivityRegion;
};

export type ActivityExpando = {
  __typename?: "ActivityExpando";
  collapseAnalytics?: Maybe<ActivityAnalytics>;
  collapseLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ActivityAnalytics>;
  expandLabel: Scalars["String"]["output"];
  minimalHeight?: Maybe<Scalars["Int"]["output"]>;
  threshold?: Maybe<Scalars["Int"]["output"]>;
};

export type ActivityFakeInputDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityFakeInputDialogTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  /** @deprecated Use Icon instead */
  graphic?: Maybe<UiGraphic>;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityFilter = ActivityThemeFilter;

export type ActivityFreeCancellation = {
  __typename?: "ActivityFreeCancellation";
  formatted: Scalars["String"]["output"];
  type: ActivityCancellationType;
};

export type ActivityGallery = {
  __typename?: "ActivityGallery";
  /** @deprecated Use featuredMedia instead */
  featured: Image;
  featuredMedia: Media;
  /** @deprecated Use medias instead */
  images: Array<Image>;
  medias: Array<Media>;
};

export type ActivityGalleryComponent = {
  __typename?: "ActivityGalleryComponent";
  carousel?: Maybe<EgdsCarousel>;
  featuredMedia?: Maybe<Array<ActivityMediaItem>>;
  thumbnailDialog?: Maybe<ActivityThumbnailMediaGalleryDialog>;
};

export type ActivityGraphicDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityGraphicDialogTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  graphic: UiGraphic;
};

export type ActivityGroup = {
  __typename?: "ActivityGroup";
  action?: Maybe<ActivityGroupAction>;
  activityTiles: Array<ActivityTile>;
  /** @deprecated Use analyticsList instead */
  analytics?: Maybe<ActivityAnalytics>;
  analyticsList: Array<ClientSideAnalytics>;
  heading?: Maybe<EgdsText>;
  /** @deprecated The analytics object provides the complete referrerId now */
  id?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use heading instead */
  name?: Maybe<Scalars["String"]["output"]>;
  navigationActions?: Maybe<ActivityNavigationActions>;
  /** @deprecated Use viewType instead */
  type: ActivityGroupType;
  viewType: ActivityGroupViewType;
};

export type ActivityGroupAction = {
  __typename?: "ActivityGroupAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  label: Scalars["String"]["output"];
  pagination?: Maybe<Pagination>;
  url: Scalars["String"]["output"];
};

export type ActivityGroupType =
  | "ACTIVITY_LIST"
  | "ACTIVITY_MESSAGE"
  | "AVAILABLE_TODAY"
  | "CATEGORY"
  | "FILTERED_ACTIVITIES"
  | "INTERESTS"
  | "KEYWORD_SEARCH"
  | "MORE_ACTIVITIES"
  | "NEIGHBOURHOOD"
  | "PINNED_ACTIVITY"
  | "POI"
  | "RECOMMENDATIONS"
  | "SLIM_CATEGORY"
  | "SUB_CATEGORIES"
  | "SUB_CATEGORY"
  | "SWP_SORT_SECTION"
  | "TOP_ACTIVITIES"
  | "TRIPS_ACTIVITY_LIST"
  | "XSELL_RECOMMENDATIONS";

export type ActivityGroupViewType =
  | "ACTIVITY_LIST"
  | "ACTIVITY_SPANNED_TILE_LIST"
  | "ACTIVITY_TILES_CAROUSEL"
  | "CATEGORY_TILES_CAROUSEL"
  | "KEYWORD_SEARCH"
  | "MESSAGE"
  | "SLIM_CATEGORY_TILES_CAROUSEL"
  | "TEXT";

export type ActivityIcon = {
  __typename?: "ActivityIcon";
  anchoredSubtext?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated Use nullabe field icon_temp instead */
  icon: Icon;
  icon_temp?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  mark?: Maybe<Mark>;
  subtext?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type ActivityImageHeaderCard = ActivityCard & {
  __typename?: "ActivityImageHeaderCard";
  analytics?: Maybe<ClientSideAnalytics>;
  badges?: Maybe<ActivityCardBadges>;
  clickAction?: Maybe<ActivityClickAction>;
  contents?: Maybe<Array<ActivityCardContent>>;
  /** @deprecated use mediaTile instead */
  image?: Maybe<Image>;
  mediaTile?: Maybe<MediaTile>;
  saveItem?: Maybe<TripsSaveItem>;
};

export type ActivityInfo = {
  __typename?: "ActivityInfo";
  activity: Activity;
  availabilities: Array<ActivityAvailability>;
  availabilityCalendar: Array<ActivityAvailability>;
  directFeedbackLink?: Maybe<DirectFeedbackLink>;
  gallery: ActivityGallery;
  imageGallery?: Maybe<ActivityGalleryComponent>;
  /** @deprecated Use staticDetails query which will contain location card. */
  location: ActivityLocation;
  loyaltyPoints?: Maybe<ActivityLoyaltyPoints>;
  navigationBar?: Maybe<ActivityNavigationBar>;
  notFound: ActivityNotFoundComponent;
  /** @deprecated will remove filter, replace with offer(offerId) */
  offer: ActivityOffer;
  offerComponent: ActivityOfferDetailsCard;
  /** @deprecated will remove filter, replace with offers(selectedDate) */
  offers: Array<ActivityOffer>;
  offersComponent?: Maybe<ActivityOffersComponent>;
  offersMessage?: Maybe<ActivityOfferMessage>;
  offersSummary: ActivityOffersSummary;
  overview?: Maybe<ActivityOverviewComponent>;
  presentation: ActivityPresentation;
  recommendations?: Maybe<ActivityRecommendationsComponent>;
  reviews: Array<ActivityReview>;
  staticDetails?: Maybe<ActivityStaticDetailsComponent>;
  tabbedNavigationBar?: Maybe<ActivityTabbedNavigationBar>;
  telesales?: Maybe<ActivityTelesales>;
  uisPayload: ActivityDetailsUisPayLoad;
};

export type ActivityInfoNavigationBarArgs = {
  searchFilters?: InputMaybe<ActivitySearchFiltersInput>;
};

export type ActivityInfoOfferArgs = {
  filter: ActivityOfferFilterInput;
  offerId: Scalars["String"]["input"];
};

export type ActivityInfoOfferComponentArgs = {
  offerId: Scalars["String"]["input"];
  selectedDate: DateInput;
};

export type ActivityInfoOffersArgs = {
  filter?: InputMaybe<ActivityOfferFilterInput>;
  selectedDate?: InputMaybe<DateInput>;
};

export type ActivityInfoOffersComponentArgs = {
  selectedDate?: InputMaybe<DateInput>;
};

export type ActivityInfoReviewsArgs = {
  pagination?: InputMaybe<PaginationInput>;
  sortBy?: InputMaybe<ActivityReviewSort>;
  source?: InputMaybe<Scalars["String"]["input"]>;
};

export type ActivityItemsByPoiModule = {
  __typename?: "ActivityItemsByPoiModule";
  groups: Array<ActivityPoiGroup>;
};

export type ActivityItineraryCard = {
  __typename?: "ActivityItineraryCard";
  contents: Array<ActivityItineraryCardContent>;
  heading: EgdsHeading;
  sectionRef?: Maybe<Scalars["String"]["output"]>;
};

export interface ActivityItineraryCardContent {
  expando?: Maybe<EgdsExpando>;
  stops: Array<ActivityItineraryStopLocationContent>;
}

export type ActivityItineraryCardHopOnHopOffContent = ActivityItineraryCardContent & {
  __typename?: "ActivityItineraryCardHopOnHopOffContent";
  expando?: Maybe<EgdsExpando>;
  features?: Maybe<EgdsTextIconList>;
  pointsOfInterest?: Maybe<ActivityCardTextListContent>;
  schedule?: Maybe<ActivityCardTextContent>;
  stops: Array<ActivityItineraryStopLocationContent>;
};

export type ActivityItineraryCardMultiDayContent = ActivityItineraryCardContent & {
  __typename?: "ActivityItineraryCardMultiDayContent";
  expando?: Maybe<EgdsExpando>;
  features?: Maybe<EgdsTextIconList>;
  stops: Array<ActivityItineraryStopLocationContent>;
};

export type ActivityItineraryCardStandardContent = ActivityItineraryCardContent & {
  __typename?: "ActivityItineraryCardStandardContent";
  expando?: Maybe<EgdsExpando>;
  menu?: Maybe<Array<ActivityCardTextListContent>>;
  stops: Array<ActivityItineraryStopLocationContent>;
};

export type ActivityItineraryStopLocationContent = {
  __typename?: "ActivityItineraryStopLocationContent";
  heading?: Maybe<EgdsHeading>;
  icon?: Maybe<Icon>;
  list?: Maybe<EgdsTextList>;
  /** @deprecated Will be removed - use textV2 instead */
  text?: Maybe<EgdsParagraph>;
  textV2?: Maybe<EgdsText>;
};

export type ActivityLeadTicket = {
  __typename?: "ActivityLeadTicket";
  label?: Maybe<Scalars["String"]["output"]>;
  legalDisclaimer?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated To be removed. */
  likelyToSellOut?: Maybe<ActivityLikelyToSellOut>;
  loyaltyPointsOption?: Maybe<ActivityLoyaltyPointsOption>;
  price?: Maybe<ActivityPrice>;
  priceAccessibilityText?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityLeadTicketView = {
  __typename?: "ActivityLeadTicketView";
  action: ActivityMessageActionLink;
  message?: Maybe<Scalars["String"]["output"]>;
  ticket: ActivityLeadTicket;
};

export type ActivityLikelyToSellOut = {
  __typename?: "ActivityLikelyToSellOut";
  formatted?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
};

export type ActivityLinkClickAction = ActivityClickAction & {
  __typename?: "ActivityLinkClickAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  anchorTagUrl?: Maybe<Scalars["String"]["output"]>;
  clientSideAnalytics: ClientSideAnalytics;
  linkTagUrl?: Maybe<Scalars["String"]["output"]>;
  opensInNewTab: Scalars["Boolean"]["output"];
};

export type ActivityLinkDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityLinkDialogTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  graphic?: Maybe<UiGraphic>;
  label: Scalars["String"]["output"];
};

export type ActivityLocation = {
  __typename?: "ActivityLocation";
  event: ActivityEventLocationDetails;
  redemption: Array<ActivityLocationDetails>;
  sectionRef?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityLocationCard = ActivityCard & {
  __typename?: "ActivityLocationCard";
  analytics?: Maybe<ClientSideAnalytics>;
  contents: Array<ActivityCardSectionContent>;
  heading: EgdsHeading;
  map: ActivityLocationMapDialog;
  sectionRef: Scalars["String"]["output"];
};

export type ActivityLocationDetails = {
  __typename?: "ActivityLocationDetails";
  address: Address;
  coordinates: Coordinates;
  description: Scalars["String"]["output"];
  distanceFromHotel?: Maybe<ActivityText>;
  formattedAddress?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityLocationMapDialog = ActivityDialog & {
  __typename?: "ActivityLocationMapDialog";
  dialog: EgdsFullScreenDialog;
  dynamicMap: ActivityMap;
  trigger: ActivityDialogTrigger;
};

export type ActivityLoyaltyPoints = {
  __typename?: "ActivityLoyaltyPoints";
  message: Scalars["String"]["output"];
};

export type ActivityLoyaltyPointsOption = {
  __typename?: "ActivityLoyaltyPointsOption";
  formattedPoints?: Maybe<Scalars["String"]["output"]>;
  leadingCaption?: Maybe<Scalars["String"]["output"]>;
  pointsAmount?: Maybe<FormattedNumber>;
  pointsFirst: Scalars["Boolean"]["output"];
  pointsUnit?: Maybe<Scalars["String"]["output"]>;
  strikeThroughFirst: Scalars["Boolean"]["output"];
};

export type ActivityMap = {
  __typename?: "ActivityMap";
  egMap: EgdsBasicMap;
  markers: Array<ActivityMapMarker>;
};

export type ActivityMapDialogTrigger = ActivityDialogTrigger & {
  __typename?: "ActivityMapDialogTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars["String"]["output"]>;
  staticMap: ActivityMap;
  staticMapImage?: Maybe<StaticMapImage>;
  /** @deprecated use staticMap image instead because staticMapUrl does not have map accessibility text */
  staticMapUrl?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityMapGroupType = "POI";

export type ActivityMapMarker = EgdsMapMarker & {
  __typename?: "ActivityMapMarker";
  id?: Maybe<Scalars["String"]["output"]>;
  markerIconType: Array<Scalars["String"]["output"]>;
  markerPosition: Coordinates;
  markerStatus: EgdsMapMarkerStatus;
  markerType: Scalars["String"]["output"];
  placeCard?: Maybe<ActivityMapPlaceCard>;
  text: Scalars["String"]["output"];
};

export type ActivityMapPlaceCard = {
  __typename?: "ActivityMapPlaceCard";
  image?: Maybe<Image>;
  primary: EgdsText;
  secondaries?: Maybe<Array<EgdsText>>;
  tag: EgdsIconText;
};

export interface ActivityMarketingInfoInput {
  mctc?: InputMaybe<Scalars["Int"]["input"]>;
  sem?: InputMaybe<Scalars["String"]["input"]>;
  semdtl?: InputMaybe<Scalars["String"]["input"]>;
}

export type ActivityMediaItem = MediaItem & {
  __typename?: "ActivityMediaItem";
  media: Media;
};

export type ActivityMessage = {
  __typename?: "ActivityMessage";
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityMessageAction = {
  __typename?: "ActivityMessageAction";
  first?: Maybe<ActivityMessageClickAction>;
  /** @deprecated Use first instead */
  primary?: Maybe<ActivityMessageActionLink>;
  second?: Maybe<ActivityMessageClickAction>;
  /** @deprecated Use second instead */
  secondary?: Maybe<ActivityMessageActionLink>;
};

export type ActivityMessageActionLink = {
  __typename?: "ActivityMessageActionLink";
  accessibilityText: Scalars["String"]["output"];
  filterSelection?: Maybe<ActivitySelectedValue>;
  href: Scalars["String"]["output"];
  rfrr?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityMessageClickAction = {
  __typename?: "ActivityMessageClickAction";
  clickAction: ActivityClickAction;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityMessageResult = {
  __typename?: "ActivityMessageResult";
  action?: Maybe<ActivityMessagingResultAction>;
  analytics?: Maybe<ActivityAnalytics>;
  filterKey?: Maybe<Scalars["String"]["output"]>;
  index?: Maybe<Scalars["Int"]["output"]>;
  subtitle?: Maybe<ActivityMessagingResultTitle>;
  title?: Maybe<ActivityMessagingResultTitle>;
  type?: Maybe<ActivityMessageType>;
};

export type ActivityMessageTile = {
  __typename?: "ActivityMessageTile";
  action?: Maybe<ActivityMessageAction>;
  analytics?: Maybe<ActivityAnalytics>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use graphic instead */
  icon?: Maybe<Icon>;
  /** @deprecated Use graphic instead */
  mark?: Maybe<Mark>;
  subtitle?: Maybe<ActivityMessageTitle>;
  title?: Maybe<ActivityMessageTitle>;
  type?: Maybe<ActivityMessageType>;
};

export type ActivityMessageTitle = {
  __typename?: "ActivityMessageTitle";
  styleTokens?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityMessageType =
  | "ADDON"
  | "EARN_2X_REWARD_POINTS"
  | "GIFTING_LX_BANNER"
  | "LOGIN"
  | "SOURCED_CAMPAIGN"
  | "SUMMER_CAMPAIGN_PRIMARY"
  | "SUMMER_CAMPAIGN_SECONDARY";

export type ActivityMessagingAction = {
  __typename?: "ActivityMessagingAction";
  href: Scalars["String"]["output"];
  rfrr?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityMessagingResultAction = {
  __typename?: "ActivityMessagingResultAction";
  primary?: Maybe<ActivityMessagingAction>;
  secondary?: Maybe<ActivityMessagingAction>;
};

export type ActivityMessagingResultTitle = {
  __typename?: "ActivityMessagingResultTitle";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityModulesResponse = {
  __typename?: "ActivityModulesResponse";
  activitiesByCategories: ActivitiesByCategoriesModule;
  activityItemsByPoi?: Maybe<ActivityItemsByPoiModule>;
  categories?: Maybe<ActivityGroup>;
};

export type ActivityMultiItemTripUpdateAction = UiAction & {
  __typename?: "ActivityMultiItemTripUpdateAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  offerNaturalKey: ActivityOfferNaturalKey;
  /** @deprecated use offerNaturalKey */
  productId: Scalars["String"]["output"];
};

export type ActivityNaturalKey = {
  __typename?: "ActivityNaturalKey";
  offerToken: Scalars["String"]["output"];
  productToken: Scalars["String"]["output"];
};

export interface ActivityNaturalKeyInput {
  offerToken: Scalars["String"]["input"];
  productToken: Scalars["String"]["input"];
}

export type ActivityNavigationAction = {
  __typename?: "ActivityNavigationAction";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<ClientSideAnalytics>;
};

export type ActivityNavigationActions = {
  __typename?: "ActivityNavigationActions";
  next: ActivityNavigationAction;
  previous: ActivityNavigationAction;
};

export type ActivityNavigationBar = {
  __typename?: "ActivityNavigationBar";
  backLink: EgdsStandardLink;
  primary?: Maybe<Scalars["String"]["output"]>;
  saveItem?: Maybe<TripsSaveItem>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  shareMenu?: Maybe<EgdsMenu>;
};

export interface ActivityNavigationBarClickAction {
  accessibilityText: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
}

export type ActivityNotFoundComponent = {
  __typename?: "ActivityNotFoundComponent";
  backButton: EgdsButton;
  backButtonAction: ActivityLinkClickAction;
  graphic?: Maybe<UiGraphic>;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
};

export type ActivityOffer = {
  __typename?: "ActivityOffer";
  activityOfferMessages?: Maybe<ActivityOfferMessages>;
  activityOfferTicketsSummary?: Maybe<ActivityOfferTicketsSummary>;
  /** @deprecated use formattedAvailableTime or availableTimeEpoch instead */
  availableTime: DateTime;
  availableTimeEpoch: Scalars["String"]["output"];
  /** @deprecated Use badge from ActivityOfferPriceDetails instead */
  badges?: Maybe<ActivityOfferBadges>;
  count: Scalars["Int"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  features: Array<ActivityIcon>;
  formattedAvailableTime: Scalars["String"]["output"];
  highlighted: Scalars["Boolean"]["output"];
  hotelPickupAvailable: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  maxTicketSelectionAllowed?: Maybe<Scalars["Int"]["output"]>;
  minTicketSelectionAllowed?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  priceDetails: ActivityOfferPriceDetails;
  /** @deprecated We send badges instead directly now */
  priceMetaData?: Maybe<ActivityPriceMetadata>;
  /** @deprecated Use priceDetails instead */
  priceSummary: ActivityOfferPriceSummary;
  priceType: ActivityPriceType;
  promoId?: Maybe<Scalars["String"]["output"]>;
  selections?: Maybe<Array<ActivityOfferOption>>;
  /** @deprecated  Use travelerSelectorDialog instead */
  ticketTypes: Array<ActivityOfferTicketType>;
  travelerSelectorDialog: ActivityTravelerSelectorDialog;
  tripUpdateButtons?: Maybe<ActivityTripUpdateButtons>;
};

export type ActivityOfferBadges = {
  __typename?: "ActivityOfferBadges";
  primary?: Maybe<ActivityBadge>;
  secondary?: Maybe<ActivityBadge>;
};

export type ActivityOfferBookingErrorBanner = {
  __typename?: "ActivityOfferBookingErrorBanner";
  banner: UiBanner;
  clientSideAnalytics: ClientSideAnalytics;
  statusCode: Scalars["Int"]["output"];
};

export type ActivityOfferCreateTripAction = ActivityClickAction & {
  __typename?: "ActivityOfferCreateTripAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  callbackMessages: ActivityActionCallbackMessages;
  clientSideAnalytics: ClientSideAnalytics;
  createTripRequestBody: Scalars["String"]["output"];
};

export type ActivityOfferDetailsCard = {
  __typename?: "ActivityOfferDetailsCard";
  activitySignIn?: Maybe<ActivitySignInDialog>;
  bookButton: EgdsButton;
  createTripAction: ActivityOfferCreateTripAction;
  description?: Maybe<EgdsParagraph>;
  elementId: Scalars["String"]["output"];
  errorMessages?: Maybe<Array<ActivityOfferBookingErrorBanner>>;
  features?: Maybe<ActivityCardTextListContent>;
  heading: Scalars["String"]["output"];
  priceDetails: ActivityOfferPriceDetails;
  selections?: Maybe<Array<ActivityOfferSelectionElement>>;
};

export interface ActivityOfferFilterInput {
  selections?: InputMaybe<Array<ActivitySelectedValueInput>>;
  tickets?: InputMaybe<Array<ActivityTicketInput>>;
}

export type ActivityOfferItem = OfferItem & {
  __typename?: "ActivityOfferItem";
  activityOffer?: Maybe<OfferData>;
  badge?: Maybe<CampaignOfferBadge>;
  city: Scalars["String"]["output"];
  dates?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["String"]["output"]>;
  durationLabel?: Maybe<Scalars["String"]["output"]>;
  freeCancellationMessage?: Maybe<Scalars["String"]["output"]>;
  image: Image;
  name: Scalars["String"]["output"];
  province?: Maybe<Scalars["String"]["output"]>;
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityOfferLoyaltyEarnAmount = {
  __typename?: "ActivityOfferLoyaltyEarnAmount";
  /** @deprecated The Money type is now deprecated. */
  loyaltyEarnAmount: Money;
  selectedCount: Scalars["Int"]["output"];
};

export type ActivityOfferMessage = {
  __typename?: "ActivityOfferMessage";
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ActivityOfferMessages = {
  __typename?: "ActivityOfferMessages";
  /** @deprecated To be removed. */
  likelyToSellOut?: Maybe<ActivityLikelyToSellOut>;
  /** @deprecated To be removed. */
  nonRefundable?: Maybe<ActivityMessage>;
  oneKeyEarnPointsMessage?: Maybe<EgdsText>;
  operatingHours?: Maybe<EgdsText>;
  removeSelectedOffer?: Maybe<EgdsText>;
  vbpMessage?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityOfferMultiSelectionOption = ActivityOfferOption & {
  __typename?: "ActivityOfferMultiSelectionOption";
  dialog: EgdsFullScreenDialog;
  doneButton: UiPrimaryButton;
  fields?: Maybe<Array<ActivityOfferSelectionField>>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<EgdsHeading>;
  id: Scalars["String"]["output"];
  selections?: Maybe<Array<ActivityOfferSelectionField>>;
  title: Scalars["String"]["output"];
  trigger: ActivityDialogTrigger;
};

export type ActivityOfferNaturalKey = {
  __typename?: "ActivityOfferNaturalKey";
  offerProductNaturalKey: Scalars["String"]["output"];
};

export interface ActivityOfferNaturalKeyInput {
  offerProductNaturalKey: Scalars["String"]["input"];
}

export interface ActivityOfferOption {
  /** @deprecated Use ActivityOfferMultiSelectionOption.selections now */
  fields?: Maybe<Array<ActivityOfferSelectionField>>;
  /** @deprecated Use ActivityOfferMultiSelectionOption.trigger now */
  graphic?: Maybe<UiGraphic>;
  id: Scalars["String"]["output"];
  /** @deprecated Use ActivityOfferMultiSelectionOption.trigger now */
  title: Scalars["String"]["output"];
}

export type ActivityOfferPrice = {
  __typename?: "ActivityOfferPrice";
  perTravelerPrice: ActivityPrice;
  selectedCount: Scalars["Int"]["output"];
  totalLoyaltyPointsOption?: Maybe<ActivityLoyaltyPointsOption>;
  totalPrice: ActivityPrice;
};

export type ActivityOfferPriceDetails = {
  __typename?: "ActivityOfferPriceDetails";
  badge?: Maybe<EgdsStandardBadge>;
  detailsTitle: Scalars["String"]["output"];
  footer?: Maybe<Array<ActivityOfferPriceDetailsFooterElement>>;
  lineItems: Array<ActivityOfferPriceLineItems>;
  priceDisplay: ActivityPriceDisplay;
  totalTitle: Scalars["String"]["output"];
};

export type ActivityOfferPriceDetailsFooterElement = ActivityDisclaimerDialog | EgdsPlainText;

export type ActivityOfferPriceLineItems = {
  __typename?: "ActivityOfferPriceLineItems";
  accessibility: Scalars["String"]["output"];
  lockupPrice: Scalars["String"]["output"];
  priceBreakdown: Scalars["String"]["output"];
  strikeThroughPrice?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityOfferPriceSummary = {
  __typename?: "ActivityOfferPriceSummary";
  ticketBreakdownSummary: ActivityOfferTicketBreakdownPriceSummary;
  totalPriceSummary: ActivityOfferTotalPriceSummary;
};

export type ActivityOfferSelectionElement =
  | ActivityOfferSelectionGraphicText
  | ActivityRadioGroupDialog
  | ActivityTravelerSelectorDialog;

export type ActivityOfferSelectionField = {
  __typename?: "ActivityOfferSelectionField";
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"]["output"];
  theme: ActivityOfferUdsToken;
  value: Scalars["String"]["output"];
};

export type ActivityOfferSelectionGraphicText = {
  __typename?: "ActivityOfferSelectionGraphicText";
  graphicText: EgdsGraphicText;
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ActivityOfferSingleSelectionOption = ActivityOfferOption & {
  __typename?: "ActivityOfferSingleSelectionOption";
  fields?: Maybe<Array<ActivityOfferSelectionField>>;
  graphic?: Maybe<UiGraphic>;
  id: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ActivityOfferTicketBreakdownPriceSummary = {
  __typename?: "ActivityOfferTicketBreakdownPriceSummary";
  ticketTypePrices: Array<ActivityOfferTicketTypePriceSummary>;
  title: Scalars["String"]["output"];
};

export type ActivityOfferTicketSummaryExtendedMessage = {
  __typename?: "ActivityOfferTicketSummaryExtendedMessage";
  action?: Maybe<ActivityMessagingAction>;
  text: Scalars["String"]["output"];
};

export type ActivityOfferTicketType = {
  __typename?: "ActivityOfferTicketType";
  defaultSelectedCount: Scalars["Int"]["output"];
  label: Scalars["String"]["output"];
  /** @deprecated The loyalty earn is being calculated with offers call */
  loyaltyEarnAwards?: Maybe<Array<ActivityOfferLoyaltyEarnAmount>>;
  maxSelectableCount: Scalars["Int"]["output"];
  minSelectableCount: Scalars["Int"]["output"];
  prices: Array<ActivityOfferPrice>;
  promoId?: Maybe<Scalars["String"]["output"]>;
  restrictionText?: Maybe<Scalars["String"]["output"]>;
  step: Scalars["Int"]["output"];
  ticketKey: Scalars["String"]["output"];
  ticketPlural: Scalars["String"]["output"];
  ticketSingular: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type ActivityOfferTicketTypePriceSummary = {
  __typename?: "ActivityOfferTicketTypePriceSummary";
  accessibilityText: Scalars["String"]["output"];
  breakdownLeadPriceLabel: Scalars["String"]["output"];
  breakdownStrikeOutPriceLabel?: Maybe<Scalars["String"]["output"]>;
  totalLeadPriceLabel: Scalars["String"]["output"];
};

export type ActivityOfferTicketsSummary = {
  __typename?: "ActivityOfferTicketsSummary";
  activityOfferTicketsSummaryMessages?: Maybe<Array<ActivityOfferTicketsSummaryMessage>>;
  icon: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ActivityOfferTicketsSummaryMessage = {
  __typename?: "ActivityOfferTicketsSummaryMessage";
  extendedMessage?: Maybe<ActivityOfferTicketSummaryExtendedMessage>;
  href?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  rfrr?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityOfferTotalPriceSummary = {
  __typename?: "ActivityOfferTotalPriceSummary";
  legalDisclaimer?: Maybe<ActivityAnchoredMessage>;
  loyaltyPointsMessage?: Maybe<Scalars["String"]["output"]>;
  pointsFirst: Scalars["Boolean"]["output"];
  price: ActivityPrice;
  priceAccessibilityText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ActivityOfferUdsToken = "SELECTED" | "UNSELECTED";

export type ActivityOffersComponent = {
  __typename?: "ActivityOffersComponent";
  offersListComponent?: Maybe<ActivityOffersListComponent>;
  offersSummaryComponent?: Maybe<ActivityOffersSummaryComponent>;
  sectionRef: Scalars["String"]["output"];
};

export type ActivityOffersListComponent = {
  __typename?: "ActivityOffersListComponent";
  analytics?: Maybe<ClientSideAnalytics>;
  datePickerLink: ActivityDateRangePickerDialog;
  dateRange: EgdsDateRange;
  filters?: Maybe<ActivityOffersListFilterPills>;
  heading?: Maybe<Scalars["String"]["output"]>;
  offers?: Maybe<Array<ActivityOfferDetailsCard>>;
  offersMessage?: Maybe<ActivityOfferMessage>;
  pointsToggle?: Maybe<ActivityToggle>;
};

export type ActivityOffersListDialog = ActivityDialog & {
  __typename?: "ActivityOffersListDialog";
  dialog: EgdsFullScreenDialog;
  offersList: ActivityOffersListComponent;
  trigger: ActivityDialogTrigger;
};

export type ActivityOffersListFilterPills = {
  __typename?: "ActivityOffersListFilterPills";
  heading?: Maybe<Scalars["String"]["output"]>;
  pills: Array<EgdsBasicPill>;
};

export type ActivityOffersSummary = {
  __typename?: "ActivityOffersSummary";
  filters?: Maybe<Array<ActivityOfferOption>>;
  leadTicketView: ActivityLeadTicketView;
};

export type ActivityOffersSummaryComponent = {
  __typename?: "ActivityOffersSummaryComponent";
  datePickerLink: ActivityDateRangePickerDialog;
  dateRange: EgdsDateRange;
  heading?: Maybe<Scalars["String"]["output"]>;
  offersButton: ActivityOffersListDialog;
  priceDisplay: ActivityPriceDisplay;
};

export type ActivityOpenDetailsModalClickAction = ActivityClickAction & {
  __typename?: "ActivityOpenDetailsModalClickAction";
  accessibilityText: Scalars["String"]["output"];
  activityId: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  dateRange: ActivityDateRange;
  dialog: EgdsFullScreenDialog;
};

export type ActivityOpenDialogAction = UiAction & {
  __typename?: "ActivityOpenDialogAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  dialog: ActivityDialog;
};

export type ActivityOpenOffersModalAction = UiAction & {
  __typename?: "ActivityOpenOffersModalAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  activityId: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  dateRange: ActivityDateRange;
  dismissButtonAccessibility: Scalars["String"]["output"];
  dismissButtonAnalytics: ClientSideAnalytics;
  heading?: Maybe<EgdsHeading>;
  selectedOffers?: Maybe<Array<ActivityOfferNaturalKey>>;
  subHeading?: Maybe<EgdsPlainText>;
};

export type ActivityOverviewComponent = {
  __typename?: "ActivityOverviewComponent";
  contents: ActivityCardSectionContent;
  findTicketsAction?: Maybe<ActivityRefClickAction>;
  findTicketsButton?: Maybe<UiPrimaryButton>;
  heading: EgdsHeading;
  location?: Maybe<ActivityLocationCard>;
  priceDisplay?: Maybe<ActivityPriceDisplay>;
  reviewSummary?: Maybe<ActivityReviewsSummary>;
  sectionRef: Scalars["String"]["output"];
  /** @deprecated Use `findTicketsAction`. */
  seeTicketsAction: ActivityRefClickAction;
  /** @deprecated Use `findTicketsButton`. */
  seeTicketsButton: UiPrimaryButton;
  subHeading?: Maybe<Array<EgdsText>>;
};

export type ActivityPage = {
  __typename?: "ActivityPage";
  pageId: Scalars["String"]["output"];
  pageName?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityPageHrefLangLink = {
  __typename?: "ActivityPageHrefLangLink";
  href: Scalars["String"]["output"];
  hrefLang: Scalars["String"]["output"];
};

export type ActivityPageMetaTagType = "HTTP_EQUIV" | "NAME" | "PROPERTY";

export type ActivityPageSeoMetaTag = {
  __typename?: "ActivityPageSeoMetaTag";
  content: Scalars["String"]["output"];
  metaTagType: ActivityPageMetaTagType;
  metaTagValue: Scalars["String"]["output"];
};

export type ActivityPageType = "ACTIVITY_INFO";

export type ActivityPaginationAction = UiAction & {
  __typename?: "ActivityPaginationAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  pagination: Pagination;
};

export type ActivityPaginationInfo = {
  __typename?: "ActivityPaginationInfo";
  currentPageIndex: Scalars["Int"]["output"];
  nextPageIndex: Scalars["Int"]["output"];
  pageSize: Scalars["Int"]["output"];
};

export type ActivityPaginationInfoAction = ActivityClickAction & {
  __typename?: "ActivityPaginationInfoAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  pagination: Pagination;
};

export type ActivityPartnerProvidedDetails = {
  __typename?: "ActivityPartnerProvidedDetails";
  body: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
};

export type ActivityPill = UiPill & {
  __typename?: "ActivityPill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiAction>;
  /** @deprecated DO NOT USE. Will be removed. */
  analytics?: Maybe<ActivityAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  /** @deprecated DO NOT USE. Will be removed. */
  label?: Maybe<Scalars["String"]["output"]>;
  primary: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  /** @deprecated DO NOT USE. Will be removed. */
  selections?: Maybe<Array<ActivitySelectedValue>>;
};

export type ActivityPills = {
  __typename?: "ActivityPills";
  appliedFilters: Array<ShoppingSortAndFilterAppliedFilter>;
  clearAll?: Maybe<UiTertiaryButton>;
};

export type ActivityPoiGroup = {
  __typename?: "ActivityPoiGroup";
  activitiesGroup?: Maybe<ActivityGroup>;
  poiTile: ActivityPoiTile;
};

export type ActivityPoiTile = {
  __typename?: "ActivityPoiTile";
  description: Scalars["String"]["output"];
  detailsLink?: Maybe<ActivityAnchoredMessage>;
  medias: Array<Media>;
  title: Scalars["String"]["output"];
};

export type ActivityPointsToRemember = {
  __typename?: "ActivityPointsToRemember";
  expando?: Maybe<ActivityExpando>;
  heading: Scalars["String"]["output"];
  headingGraphic?: Maybe<UiGraphic>;
  items: Array<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  sectionRef?: Maybe<Scalars["String"]["output"]>;
  sectionRefLink?: Maybe<ActivityRefClickAction>;
  structuredItems: Array<ActivityIcon>;
};

export type ActivityPopupClickAction = ActivityClickAction & {
  __typename?: "ActivityPopupClickAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  dismissAction?: Maybe<ActivityMessagingAction>;
  messages: Array<Scalars["String"]["output"]>;
  /** @deprecated use messages instead */
  text: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityPresentation = {
  __typename?: "ActivityPresentation";
  banners: Array<ActivityBanner>;
  detailsSectionGroups: Array<ActivitySectionGroup>;
  features?: Maybe<Array<ActivityIcon>>;
  legalDisclaimer?: Maybe<ActivityBanner>;
  localisationDisclaimer?: Maybe<ActivityBanner>;
  loyaltyPointsValue?: Maybe<Money>;
  partnerProvidedDetails?: Maybe<Array<ActivityPartnerProvidedDetails>>;
  /** @deprecated Use detailsSectionGroup instead */
  pointsToRemember: Array<ActivityPointsToRemember>;
  promotionMessage?: Maybe<ActivityPromotion>;
  staticMap?: Maybe<Scalars["URL"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ActivityPrice = {
  __typename?: "ActivityPrice";
  /** @deprecated Use leadFormatted or leadAmount instead. */
  lead: Money;
  leadAmount: Scalars["Float"]["output"];
  leadFormatted: Scalars["String"]["output"];
  /** @deprecated Use strikeOutFormatted or strikeOutAmount instead. */
  strikeOut?: Maybe<Money>;
  strikeOutAmount?: Maybe<Scalars["Float"]["output"]>;
  strikeOutFormatted?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityPriceDisplay = {
  __typename?: "ActivityPriceDisplay";
  disclaimer?: Maybe<ActivityDisclaimerDialog>;
  footer?: Maybe<Array<EgdsText>>;
  header?: Maybe<Array<EgdsText>>;
  priceLockup?: Maybe<EgdsPriceLockup>;
};

export type ActivityPriceMetadata = {
  __typename?: "ActivityPriceMetadata";
  discountPercent: Scalars["String"]["output"];
  discountType: ActivityDiscountType;
};

export type ActivityPriceType = "GROUP" | "NORMAL" | "VOLUME_BASED";

export interface ActivityProductInfoInput {
  offerToken: Scalars["String"]["input"];
}

export type ActivityPromotion = {
  __typename?: "ActivityPromotion";
  /** @deprecated Use clickAction instead */
  action?: Maybe<ActivityPromotionDetailsAction>;
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityMessageAction>;
  icon: Icon;
  mark?: Maybe<Mark>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type: ActivityMessageType;
};

export type ActivityPromotionDetailsAction = {
  __typename?: "ActivityPromotionDetailsAction";
  primary: ActivityDetailsMessagingAction;
  secondary?: Maybe<ActivityDetailsMessagingAction>;
};

export type ActivityRadioGroupDialog = ActivityDialog & {
  __typename?: "ActivityRadioGroupDialog";
  dialog: EgdsFullScreenDialog;
  doneButton: UiPrimaryButton;
  heading?: Maybe<EgdsHeading>;
  radioGroup: EgdsBasicRadioGroup;
  trigger: ActivityDialogTrigger;
};

export type ActivityRecommendationDetails = {
  __typename?: "ActivityRecommendationDetails";
  enabled: Scalars["Boolean"]["output"];
  formatted: Icon;
  isSelected: Scalars["Boolean"]["output"];
  raw: Scalars["String"]["output"];
  size: FormattedNumber;
};

export type ActivityRecommendationsComponent = {
  __typename?: "ActivityRecommendationsComponent";
  carousel: ActivityCarouselContainer;
  heading?: Maybe<EgdsHeading>;
};

export type ActivityRedemption = {
  __typename?: "ActivityRedemption";
  locations?: Maybe<Array<Coordinates>>;
  type?: Maybe<ActivityRedemptionType>;
};

export type ActivityRedemptionType = "PRINT" | "VOUCHER_LESS";

export type ActivityRefClickAction = ActivityClickAction & {
  __typename?: "ActivityRefClickAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  sectionRef: Scalars["String"]["output"];
};

export type ActivityRegion = {
  __typename?: "ActivityRegion";
  fullRegionName?: Maybe<Scalars["String"]["output"]>;
  regionId: Scalars["String"]["output"];
  regionName?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityRemoveOffersFromTripAction = UiAction & {
  __typename?: "ActivityRemoveOffersFromTripAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  offerNaturalKeys: Array<ActivityOfferNaturalKey>;
};

export type ActivityReview = {
  __typename?: "ActivityReview";
  /** @deprecated use formattedActivityDate instead */
  activityDate?: Maybe<DateTime>;
  author?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<EgdsExpando>;
  formattedActivityDate?: Maybe<Scalars["String"]["output"]>;
  formattedSubmissionDate?: Maybe<Scalars["String"]["output"]>;
  helpfulVotes: ActivityReviewHelpfulVote;
  id: Scalars["String"]["output"];
  /** @deprecated Not being queried or used */
  locale: Scalars["Locale"]["output"];
  /** @deprecated Use overallRatingMessage instead */
  overallRating: FormattedNumber;
  /** @deprecated Use overallScoreMessage instead */
  overallRatingMessage?: Maybe<Scalars["String"]["output"]>;
  overallScoreMessage?: Maybe<EgdsText>;
  reviewedOn?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use formattedSubmissionDate instead */
  submissionDate?: Maybe<DateTime>;
  text: Scalars["String"]["output"];
  userLocation?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityReviewCommentsDialog = ActivityDialog & {
  __typename?: "ActivityReviewCommentsDialog";
  activityId: Scalars["String"]["output"];
  dialog: EgdsFullScreenDialog;
  trigger: ActivityDialogTrigger;
};

export type ActivityReviewDetailsComponent = ActivityReviewsComponent & {
  __typename?: "ActivityReviewDetailsComponent";
  comments: Array<ActivityReview>;
  heading: Scalars["String"]["output"];
  seeAllButton?: Maybe<ActivityReviewCommentsDialog>;
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  summary?: Maybe<ActivityReviewsSummary>;
};

export type ActivityReviewHelpfulVote = {
  __typename?: "ActivityReviewHelpfulVote";
  count: Scalars["Int"]["output"];
  icon: Icon;
  message: Scalars["String"]["output"];
};

export type ActivityReviewListComponent = ActivityReviewsComponent & {
  __typename?: "ActivityReviewListComponent";
  comments: Array<ActivityReview>;
  moreButton?: Maybe<ActivityReviewsPaginationButton>;
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  summary?: Maybe<ActivityReviewsSummary>;
};

export type ActivityReviewSort = "HIGHEST_TO_LOWEST_RATED" | "LOWEST_TO_HIGHEST_RATED" | "NEWEST_TO_OLDEST";

export interface ActivityReviewsComponent {
  comments: Array<ActivityReview>;
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  summary?: Maybe<ActivityReviewsSummary>;
}

export type ActivityReviewsInfo = {
  __typename?: "ActivityReviewsInfo";
  /** @deprecated Use reviewsComponent instead */
  comments: Array<ActivityReview>;
  reviewsComponent?: Maybe<ActivityReviewDetailsComponent>;
  reviewsDialog?: Maybe<ActivityReviewListComponent>;
  scores?: Maybe<Array<EgdsProgressBar>>;
  /** @deprecated Use reviewsComponent instead */
  showMoreButton?: Maybe<EgdsButton>;
  /** @deprecated Use reviewsComponent instead */
  sortTabs?: Maybe<ActivityTabbedNavigationBar>;
  /** @deprecated Use reviewsComponent instead */
  summary?: Maybe<ActivityReviewsSummary>;
};

export type ActivityReviewsPaginationButton = {
  __typename?: "ActivityReviewsPaginationButton";
  action: ActivityPaginationInfoAction;
  button: EgdsButton;
};

export type ActivityReviewsSummary = {
  __typename?: "ActivityReviewsSummary";
  averageScore: EgdsText;
  averageScorePhraseParts?: Maybe<Array<EgdsStylizedText>>;
  blockWebCrawlersEnabled: Scalars["Boolean"]["output"];
  disclaimer?: Maybe<ActivityDisclaimerDialog>;
  /** @deprecated use `blockWebCrawlersEnabled` */
  isExternalSource: Scalars["Boolean"]["output"];
  reviewCommentsDialog?: Maybe<ActivityReviewCommentsDialog>;
  /** @deprecated use disclaimer instead */
  reviewCountMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use accessibility from averageScore EGDSHeading instead */
  reviewRatingAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use averageScore instead */
  reviewRatingMessage?: Maybe<Scalars["String"]["output"]>;
  reviewScoreBase?: Maybe<EgdsText>;
  /** @deprecated use averageScore instead */
  score: FormattedNumber;
  sectionRef?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use disclaimer instead */
  total: Scalars["String"]["output"];
  /** @deprecated use disclaimer instead */
  verifiedReviewsMessage?: Maybe<ActivityAnchoredMessage>;
};

export type ActivitySmsAction = ActivityNavigationBarClickAction & {
  __typename?: "ActivitySMSAction";
  accessibilityText: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  smsBody: Scalars["String"]["output"];
};

export type ActivitySearchByKeywordTile = {
  __typename?: "ActivitySearchByKeywordTile";
  analytics?: Maybe<ActivityAnalytics>;
  clearAnalytics?: Maybe<ActivityAnalytics>;
  dismiss?: Maybe<Icon>;
  icon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  searchTerm?: Maybe<Scalars["String"]["output"]>;
};

export interface ActivitySearchCriteriaDateRangeInput {
  endDate: DateInput;
  startDate: DateInput;
}

export interface ActivitySearchCriteriaLocationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  pinnedActivityId?: InputMaybe<Scalars["String"]["input"]>;
  referencedActivityId?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
  regionType?: InputMaybe<Scalars["String"]["input"]>;
}

export type ActivitySearchFeature = {
  __typename?: "ActivitySearchFeature";
  /** @deprecated Use enhancedCleaningFeature instead */
  enhancedCleaning?: Maybe<ActivityIcon>;
  enhancedCleaningFeature?: Maybe<EgdsText>;
  /** @deprecated Use freeCancellationFeature instead */
  freeCancellation?: Maybe<ActivityFreeCancellation>;
  freeCancellationFeature?: Maybe<EgdsText>;
  hotelPickup?: Maybe<ActivityIcon>;
  likelyToSellOut?: Maybe<ActivityLikelyToSellOut>;
  loyaltyEarnAwards?: Maybe<ActivityIcon>;
  pinnedTopBottom?: Maybe<Pinned>;
  promotion?: Maybe<Icon>;
  selectedOfferDetails?: Maybe<Array<EgdsText>>;
  travelDistance?: Maybe<ActivityTravelDistance>;
  /** @deprecated Use travelDistance */
  travelDuration?: Maybe<ActivityTravelDuration>;
  volumePricing?: Maybe<Icon>;
};

export interface ActivitySearchFiltersInput {
  activityId?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  selections?: InputMaybe<Array<ActivitySelectedValueInput>>;
}

export type ActivitySearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "ActivitySearchForm";
    actionURL: Scalars["String"]["output"];
    activitySearchFormInputIds: ActivitySearchFormInputIds;
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    subTitle?: Maybe<EgdsSpannableText>;
  };

export type ActivitySearchFormInputIds = {
  __typename?: "ActivitySearchFormInputIds";
  dateRangeInputId: Scalars["String"]["output"];
  destinationInputId: Scalars["String"]["output"];
};

export type ActivitySearchMap = {
  __typename?: "ActivitySearchMap";
  center: Coordinates;
  name: Scalars["String"]["output"];
  staticMap?: Maybe<Scalars["URL"]["output"]>;
  zoom: Scalars["Int"]["output"];
};

export type ActivitySearchOptions =
  | "CATEGORY"
  | "FILTER_NESTED_CATEGORIES"
  | "GAIA_RETURN_ADDITIONAL_DISTANCE_PARAMETER"
  | "GIFTING_ACTIVITIES"
  | "GUEST_PROMO"
  | "NON_CONSOLIDATED_OFFERS"
  | "SHOP_WITH_POINTS"
  | "SHOW_GT_BANNER";

export type ActivitySearchResults = {
  __typename?: "ActivitySearchResults";
  activities: Array<Activity>;
  activityGroups: Array<ActivityGroup>;
  /** @deprecated This field is no longer used and will be removed in the future */
  categorySelection?: Maybe<CategorySelectionComponent>;
  map?: Maybe<ActivitySearchMap>;
  noMatchesFound?: Maybe<NoMatchesFoundComponent>;
  pageTitle?: Maybe<Scalars["String"]["output"]>;
  productGroups: Array<ProductGroupsContainer>;
  searchSummary: ActivitySearchSummary;
  /** @deprecated Use universalSortAndFilter which is EGDS compliant */
  sortAndFilter: ActivitySortAndFilter;
  sortDisclaimer?: Maybe<ActivityAnchoredMessage>;
  uisPayload: Scalars["String"]["output"];
  universalSortAndFilter?: Maybe<ShoppingSortAndFilters>;
};

export type ActivitySearchResultsActivityGroupsArgs = {
  types?: InputMaybe<Array<ActivityGroupType>>;
};

export type ActivitySearchResultsMapArgs = {
  type?: InputMaybe<ActivityMapGroupType>;
};

export type ActivitySearchSummary = {
  __typename?: "ActivitySearchSummary";
  aboveTheFold?: Maybe<ActivityAboveTheFold>;
  activitySize: Scalars["Int"]["output"];
  dateValidationResult?: Maybe<DateValidationResult>;
  marketSize: Scalars["Int"]["output"];
  messages?: Maybe<Array<ActivityMessageResult>>;
  pageHeading?: Maybe<Scalars["String"]["output"]>;
  pageInfo: ActivityPage;
  /** @deprecated Use pagination from activity group action */
  paginationInfo?: Maybe<ActivityPaginationInfo>;
  resolvedRegion?: Maybe<ActivityRegion>;
};

export type ActivitySearchTicket = {
  __typename?: "ActivitySearchTicket";
  code?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  legalDisclaimer?: Maybe<ActivityAnchoredMessage>;
  loyaltyPointsOption?: Maybe<ActivityLoyaltyPointsOption>;
  price?: Maybe<ActivityPrice>;
  priceAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  taxesAndFeesMessage?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ActivitySearchViewOptions =
  | "DISTANCE_FROM_HOTEL_TO_ACTIVITY"
  | "FILTER_NESTED_CATEGORIES"
  | "NEARBY_ACTIVITIES"
  | "SHOP_WITH_POINTS"
  | "SHOW_GT_BANNER"
  | "THIRD_PARTY_REVIEWS"
  | "TRAVEL_DISTANCE_FROM_HOTEL";

export type ActivitySection = {
  __typename?: "ActivitySection";
  heading: Scalars["String"]["output"];
  headingGraphic?: Maybe<UiGraphic>;
  sectionRefLink?: Maybe<ActivityRefClickAction>;
  structuredItems: Array<ActivityIcon>;
};

export type ActivitySectionGroup = {
  __typename?: "ActivitySectionGroup";
  footer?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  sectionRef?: Maybe<Scalars["String"]["output"]>;
  sections: Array<ActivitySection>;
};

export type ActivitySelectableFilterOption = ActivitySortAndFilterOption & {
  __typename?: "ActivitySelectableFilterOption";
  analytics?: Maybe<ActivitySortAndFilterAnalytics>;
  enabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  nestedOptions?: Maybe<Array<ActivitySelectableFilterOption>>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export type ActivitySelectedValue = {
  __typename?: "ActivitySelectedValue";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface ActivitySelectedValueInput {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type ActivitySelectionClickAction = ActivityClickAction & {
  __typename?: "ActivitySelectionClickAction";
  accessibilityText: Scalars["String"]["output"];
  analytics?: Maybe<ActivityAnalytics>;
  clientSideAnalytics: ClientSideAnalytics;
  selectionList?: Maybe<Array<ActivitySelectedValue>>;
};

export type ActivitySelectionField = ActivitySortAndFilterField & {
  __typename?: "ActivitySelectionField";
  id: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<ActivitySelectableFilterOption>;
  udsToken: ActivityUdsToken;
};

export type ActivitySeoContent = {
  __typename?: "ActivitySeoContent";
  canonicalUrl?: Maybe<HttpUri>;
  hreflangLinks: Array<ActivityPageHrefLangLink>;
  metaTags: Array<ActivityPageSeoMetaTag>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityShortlistingMessages = {
  __typename?: "ActivityShortlistingMessages";
  removeFocusText?: Maybe<Scalars["String"]["output"]>;
  removeText?: Maybe<Scalars["String"]["output"]>;
  saveFocusText?: Maybe<Scalars["String"]["output"]>;
  saveText?: Maybe<Scalars["String"]["output"]>;
};

export type ActivitySignInDialog = {
  __typename?: "ActivitySignInDialog";
  activityMemberSignIn: MemberSignInDialog;
};

export type ActivitySort =
  | "CAMPAIGN_DEALS"
  | "DISCOUNT"
  | "DISTANCE_FROM_HOTEL"
  | "PRICE_HIGH_TO_LOW"
  | "PRICE_LOW_TO_HIGH"
  | "RECOMMENDED";

export type ActivitySortAndFilter = {
  __typename?: "ActivitySortAndFilter";
  appliedFilterCount?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated use sortAndFilterSections now as it's a more generic and powerful schema */
  categoryFilterOptions?: Maybe<Array<CategoryFilterViewModel>>;
  clearButtonTitle?: Maybe<Scalars["String"]["output"]>;
  closeIcon?: Maybe<Icon>;
  defaultSort?: Maybe<ActivitySort>;
  /** @deprecated use sortAndFilterSections now as it's a more generic and powerful schema */
  recommendationFilterOptions?: Maybe<Array<ActivityRecommendationDetails>>;
  /** @deprecated use sortAndFilterSections now as it's a more generic and powerful schema */
  sort: Array<ActivitySortViewModel>;
  sortAndFilterSections: Array<ActivitySortAndFilterSection>;
  title: Scalars["String"]["output"];
};

export type ActivitySortAndFilterAnalytics = {
  __typename?: "ActivitySortAndFilterAnalytics";
  linkName: Scalars["String"]["output"];
  referrerId: Scalars["String"]["output"];
};

export interface ActivitySortAndFilterField {
  id: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  udsToken: ActivityUdsToken;
}

export interface ActivitySortAndFilterOption {
  analytics?: Maybe<ActivitySortAndFilterAnalytics>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
}

export type ActivitySortAndFilterSection = {
  __typename?: "ActivitySortAndFilterSection";
  dismiss?: Maybe<Icon>;
  fields?: Maybe<Array<ActivitySortAndFilterField>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivitySortViewModel = {
  __typename?: "ActivitySortViewModel";
  formatted: Scalars["String"]["output"];
  raw: ActivitySort;
  selected: Scalars["Boolean"]["output"];
};

export type ActivityStaticDetailsCard = {
  __typename?: "ActivityStaticDetailsCard";
  content: Array<ActivityCardContent>;
  heading: EgdsHeading;
  sectionRef?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityStaticDetailsComponent = {
  __typename?: "ActivityStaticDetailsComponent";
  sections: Array<ActivityStaticDetailsSection>;
};

export type ActivityStaticDetailsSection = ActivityItineraryCard | ActivityLocationCard | ActivityStaticDetailsCard;

export type ActivitySubMenu = EgdsSubMenu & {
  __typename?: "ActivitySubMenu";
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ActivitySubMenuItem = EgdsMenuListItem & {
  __typename?: "ActivitySubMenuItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<ActivityNavigationBarClickAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title: Scalars["String"]["output"];
};

export type ActivityTabbedNavigationBar = {
  __typename?: "ActivityTabbedNavigationBar";
  findTicketsAction?: Maybe<ActivityRefClickAction>;
  findTicketsButton?: Maybe<UiPrimaryButton>;
  heading?: Maybe<Scalars["String"]["output"]>;
  tabs: Array<NavigationTab>;
};

export type ActivityTelesales = {
  __typename?: "ActivityTelesales";
  analytics?: Maybe<ActivityAnalytics>;
  label: Scalars["String"]["output"];
  phoneNumberLink: TelUri;
};

export type ActivityText = {
  __typename?: "ActivityText";
  accessibilityText?: Maybe<Scalars["String"]["output"]>;
  size: ActivityTextSize;
  text: Scalars["String"]["output"];
};

export type ActivityTextAlignment = "CENTRE" | "LEFT" | "RIGHT";

export type ActivityTextInputSortField = ActivitySortAndFilterField & {
  __typename?: "ActivityTextInputSortField";
  id: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  placeholder: Scalars["String"]["output"];
  udsToken: ActivityUdsToken;
  value: Scalars["String"]["output"];
};

export type ActivityTextSize = "LARGE" | "MEDIUM" | "SMALL";

export type ActivityTextTile = {
  __typename?: "ActivityTextTile";
  /** @deprecated Will rename it 'Text' after cleanup */
  activityText?: Maybe<ActivityText>;
  alignment: ActivityTextAlignment;
  anchoredText?: Maybe<ActivityAnchoredMessage>;
  /** @deprecated We use ActivityText now */
  size: ActivityTextSize;
  /** @deprecated We use ActivityText now */
  text: Scalars["String"]["output"];
};

export type ActivityThemeFilter = {
  __typename?: "ActivityThemeFilter";
  themes: Array<Scalars["String"]["output"]>;
};

export type ActivityThumbnailMediaGalleryDialog = ActivityDialog & {
  __typename?: "ActivityThumbnailMediaGalleryDialog";
  dialog: EgdsBasicThumbnailMediaGallery;
  trigger: ActivityDialogTrigger;
};

export interface ActivityTicketInput {
  code: Scalars["String"]["input"];
  count: Scalars["Int"]["input"];
}

export type ActivityTile =
  | Activity
  | ActivityActionTile
  | ActivityAssortedComponentsTile
  | ActivityBasicTile
  | ActivityEmptyStateTile
  | ActivityMessageTile
  | ActivitySearchByKeywordTile
  | ActivityTextTile;

export type ActivityTileBadges = {
  __typename?: "ActivityTileBadges";
  primary?: Maybe<ActivityBadge>;
  secondary?: Maybe<ActivityBadge>;
};

export type ActivityToggle = UiToggle & {
  __typename?: "ActivityToggle";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel: Scalars["String"]["output"];
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription: Scalars["String"]["output"];
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel: Scalars["String"]["output"];
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription: Scalars["String"]["output"];
  uncheckedLabel: Scalars["String"]["output"];
};

export type ActivityTravelDistance = {
  __typename?: "ActivityTravelDistance";
  label?: Maybe<Scalars["String"]["output"]>;
};

export type ActivityTravelDuration = {
  __typename?: "ActivityTravelDuration";
  formatted?: Maybe<Scalars["String"]["output"]>;
};

export interface ActivityTravelerDetailsInput {
  age: Scalars["Int"]["input"];
  count: Scalars["Int"]["input"];
}

export interface ActivityTravelerInfoInput {
  numberOfAdults: Scalars["Int"]["input"];
  numberOfChildren: Scalars["Int"]["input"];
  numberOfInfants: Scalars["Int"]["input"];
}

export type ActivityTravelerSelectorDialog = ActivityDialog & {
  __typename?: "ActivityTravelerSelectorDialog";
  dialog: EgdsFullScreenDialog;
  doneButton: UiPrimaryButton;
  errorMessageForSelectedTickets?: Maybe<EgdsGraphicText>;
  heading?: Maybe<EgdsHeading>;
  ticketsStepInput: Array<EgdsTravelerStepInput>;
  trigger: ActivityDialogTrigger;
};

export type ActivityTripUpdateButtons = {
  __typename?: "ActivityTripUpdateButtons";
  addToTrip?: Maybe<EgdsButton>;
  removeFromTrip?: Maybe<EgdsButton>;
  updateTrip?: Maybe<EgdsButton>;
};

export type ActivityUdsToken = "MULTI_SELECT_LIST" | "MULTI_SELECT_RANGE" | "SINGLE_SELECT" | "TEXT";

export interface ActivityUpdateInput {
  newActivity: ActivityNaturalKeyInput;
  oldActivity: ActivityNaturalKeyInput;
}

export type ActivityUpdateOfferInTripAction = UiAction & {
  __typename?: "ActivityUpdateOfferInTripAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  callbackMessages?: Maybe<ActivityActionCallbackMessages>;
  newOfferNaturalKey: ActivityOfferNaturalKey;
  oldOfferNaturalKey: ActivityOfferNaturalKey;
};

export type AdBadges = AdTransparencyTrigger | EgdsStandardBadge;

export interface AdButton {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["String"]["output"]>;
}

export type AdButtonTypes = AdTransparencyButton;

export interface AdCopyInput {
  fslContent?: InputMaybe<FlightCreativeInput>;
  isUpdated: Scalars["Boolean"]["input"];
  languages: Array<Scalars["String"]["input"]>;
  lslContent?: InputMaybe<LodgingCreativeInput>;
}

export type AdGroup = {
  __typename?: "AdGroup";
  adGroupId?: Maybe<Scalars["String"]["output"]>;
  checkboxAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  creative?: Maybe<EgdsStandardLink>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export interface AdGroupInput {
  ads: Array<AdInput>;
  hydrated: Scalars["Boolean"]["input"];
  id: Scalars["String"]["input"];
  locations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name: Scalars["String"]["input"];
}

export type AdGroupLineItemHeader = {
  __typename?: "AdGroupLineItemHeader";
  adGroupId?: Maybe<Scalars["String"]["output"]>;
  checkboxAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  creative?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type AdGroupLocationCard = {
  __typename?: "AdGroupLocationCard";
  locationExpandos: Array<LocationExpando>;
  validationMessage?: Maybe<UiBanner>;
};

export type AdGroupOption = {
  __typename?: "AdGroupOption";
  adGroupId: Scalars["String"]["output"];
  adGroupName: Scalars["String"]["output"];
  campaignId: Scalars["String"]["output"];
  isPaused: Scalars["Boolean"]["output"];
};

export type AdGroupOverviewStatusCell = {
  __typename?: "AdGroupOverviewStatusCell";
  adGroupId?: Maybe<Scalars["String"]["output"]>;
  adGroupName: Scalars["String"]["output"];
  campaignId: Scalars["String"]["output"];
  switch: EgdsStandardSwitch;
};

export interface AdGroupRequestInput {
  adGroupId?: InputMaybe<Scalars["UUID"]["input"]>;
  adGroupName: Scalars["String"]["input"];
  adGroupTargeting: AdGroupTargetingInput;
  adList: Array<AdRequestInput>;
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUpdated: Scalars["Boolean"]["input"];
}

export type AdGroupReviewCard = {
  __typename?: "AdGroupReviewCard";
  ads: Array<AdReviewContent>;
  editButton: UiSecondaryButton;
  editItemId: Scalars["String"]["output"];
  items: Array<ReviewCardLineItem>;
};

export type AdGroupTable = {
  __typename?: "AdGroupTable";
  adGroupList: Array<AdGroup>;
  associateCreativeForm?: Maybe<AssociateCreativeForm>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<AdGroupLineItemHeader>;
};

export interface AdGroupTargetingInput {
  locationList: Array<TargetingInput>;
  locationsUpdated: Scalars["Boolean"]["input"];
  productId: TargetingInput;
}

export interface AdInput {
  canOverrideImage: Scalars["Boolean"]["input"];
  content: ContentInput;
  hydrated: Scalars["Boolean"]["input"];
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

export type AdOverviewStatusCell = {
  __typename?: "AdOverviewStatusCell";
  adGroupId?: Maybe<Scalars["String"]["output"]>;
  adId?: Maybe<Scalars["String"]["output"]>;
  adName: Scalars["String"]["output"];
  campaignId: Scalars["String"]["output"];
  switch: EgdsStandardSwitch;
};

export type AdPreview = {
  __typename?: "AdPreview";
  adPreviewContent?: Maybe<AdPreviewContent>;
  radioButtonGroup: EgdsBasicRadioGroup;
};

export interface AdRequestInput {
  adCopy: AdCopyInput;
  adId?: InputMaybe<Scalars["UUID"]["input"]>;
  adName: Scalars["String"]["input"];
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  isUpdated: Scalars["Boolean"]["input"];
}

export type AdReviewContent = {
  __typename?: "AdReviewContent";
  adPreview?: Maybe<AdPreview>;
  editButton: UiSecondaryButton;
  editItemId: Scalars["String"]["output"];
  items: Array<ReviewCardLineItem>;
  title: Scalars["String"]["output"];
};

export type AdTransparencyButton = AdButton & {
  __typename?: "AdTransparencyButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  size?: Maybe<Scalars["String"]["output"]>;
};

export type AdTransparencyTrigger = {
  __typename?: "AdTransparencyTrigger";
  adTransparencyButton?: Maybe<AdButtonTypes>;
  adTransparencyId: Scalars["String"]["output"];
  adTransparencyOverlaySkeleton?: Maybe<TransparencyOverlaySkeleton>;
};

export type AdaptExEventProperty = {
  __typename?: "AdaptExEventProperty";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type Adaptex = {
  __typename?: "Adaptex";
  arm: Scalars["String"]["output"];
};

export type AdaptexCampaignTrackingDetail = {
  __typename?: "AdaptexCampaignTrackingDetail";
  banditDisplayed?: Maybe<Scalars["Boolean"]["output"]>;
  campaignId: Scalars["String"]["output"];
  eventTarget?: Maybe<Scalars["String"]["output"]>;
  eventType?: Maybe<Scalars["String"]["output"]>;
};

export type AdaptexClientsConfig = {
  __typename?: "AdaptexClientsConfig";
  clientName: Scalars["String"]["output"];
  device: DeviceType;
};

export type AdaptexConfig = {
  __typename?: "AdaptexConfig";
  clients: Array<AdaptexClientsConfig>;
  featureCampaign: Scalars["String"]["output"];
  isEnabled: Scalars["Boolean"]["output"];
  supportedPageTypes: Array<Scalars["String"]["output"]>;
};

export type AddCreativeResponse = AddCreativeResponseError | AddCreativeResponseSuccess;

export type AddCreativeResponseError = {
  __typename?: "AddCreativeResponseError";
  adGroupId?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<UiBanner>;
};

export type AddCreativeResponseSuccess = {
  __typename?: "AddCreativeResponseSuccess";
  adGroupUpdated: AdGroup;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type AddOnRequested = "CB1" | "COB" | "SC";

export interface AddParticipantsToConversationInput {
  conversationId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
}

export type AddParticipantsToConversationResult = {
  __typename?: "AddParticipantsToConversationResult";
  isSuccess: Scalars["Boolean"]["output"];
};

export interface AdditionalChangeQueryParamsInput {
  itineraryNumber?: InputMaybe<Scalars["String"]["input"]>;
  opaqueToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type AdditionalInformation = {
  __typename?: "AdditionalInformation";
  durationAndStop: Scalars["String"]["output"];
  layoverInfo?: Maybe<FlightsIconAndLabel>;
  /** @deprecated This will be replaced by nextFlightOriginAirportInfo */
  nextFlightOriginAirport?: Maybe<Scalars["String"]["output"]>;
  nextFlightOriginAirportInfo?: Maybe<FlightsIconAndLabel>;
};

export type AdditionalInformationPopover = {
  __typename?: "AdditionalInformationPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  closeLabel?: Maybe<Scalars["String"]["output"]>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use `enrichedSecondaries` instead. */
  secondaries: Array<Scalars["String"]["output"]>;
};

export type AdditionalInformationPopoverDialogSection = EgdsDialog & {
  __typename?: "AdditionalInformationPopoverDialogSection";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer?: Maybe<EgdsDialogFooter>;
  subSections: Array<PricePresentationSubSection>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AdditionalInformationPopoverGridSection = {
  __typename?: "AdditionalInformationPopoverGridSection";
  subSections: Array<PricePresentationSubSection>;
};

export type AdditionalInformationPopoverListSection = {
  __typename?: "AdditionalInformationPopoverListSection";
  content: EgdsList;
};

export type AdditionalInformationPopoverSection =
  | AdditionalInformationPopoverDialogSection
  | AdditionalInformationPopoverGridSection
  | AdditionalInformationPopoverListSection
  | AdditionalInformationPopoverTextSection;

export type AdditionalInformationPopoverTextSection = {
  __typename?: "AdditionalInformationPopoverTextSection";
  text: EgdsText;
};

export interface AdditionalOnboardingContextInput {
  cmsToken?: InputMaybe<Scalars["String"]["input"]>;
  isFirstAppLaunch?: InputMaybe<Scalars["Boolean"]["input"]>;
  madCustomerPlacementId?: InputMaybe<Scalars["String"]["input"]>;
  originUrl?: InputMaybe<Scalars["String"]["input"]>;
  scenario?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AdditionalOnboardingOperationContextInput {
  cmsToken?: InputMaybe<Scalars["String"]["input"]>;
  originUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AdditionalProductsInput {
  activityProductInfos?: InputMaybe<Array<ActivityProductInfoInput>>;
  additionalProductsOperationType: AdditionalProductsOperationType;
}

export type AdditionalProductsOperationType = "ADD" | "REMOVE";

export type AdditionalShoppedAncillaries = "FIRST_CHECKED_BAG";

export type AdditionalShopping = "FIRST_CHECKED_BAG";

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]["output"]>;
  country: Scalars["String"]["output"];
  firstLine: Scalars["String"]["output"];
  province?: Maybe<Scalars["String"]["output"]>;
  secondLine?: Maybe<Scalars["String"]["output"]>;
  thirdLine?: Maybe<Scalars["String"]["output"]>;
  zipCode?: Maybe<Scalars["String"]["output"]>;
};

export interface AdjustmentBreakdownItemInput {
  adjustmentAmountType: Scalars["String"]["input"];
  adjustmentType: AdjustmentType;
  amount: Scalars["Float"]["input"];
  date?: InputMaybe<DateInput>;
}

export type AdjustmentType = "Nightly" | "PerStay";

export type AdsPortalAdGroupFocusTypeahead = CampaignOverviewFocusTypeahead & {
  __typename?: "AdsPortalAdGroupFocusTypeahead";
  adGroupOptions?: Maybe<Array<AdGroupOption>>;
  campaignOptions?: Maybe<Array<CampaignOption>>;
  heading: Scalars["String"]["output"];
  renderType: EgdsTypeaheadRenderType;
  searchTypeahead: EgdsTypeaheadInputField;
  subheading: Scalars["String"]["output"];
  triggerMenu: TypeaheadTriggerMenu;
  typeaheadSelect?: Maybe<EgdsBasicSelect>;
};

export type AdsPortalAdGroupTableResponse = CampaignOverviewTableResponse & {
  __typename?: "AdsPortalAdGroupTableResponse";
  columns: Array<OverviewTableColumn>;
  createLabel: Scalars["String"]["output"];
  editLabel: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  lastSort: CampaignOverviewTableSorting;
  noRowsIcon: Icon;
  noRowsPlaceholder: Scalars["String"]["output"];
  rows: Array<CampaignOverviewDataRows>;
  striped: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AdsPortalAdTableResponse = CampaignOverviewTableResponse & {
  __typename?: "AdsPortalAdTableResponse";
  columns: Array<OverviewTableColumn>;
  createLabel: Scalars["String"]["output"];
  editLabel: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  lastSort: CampaignOverviewTableSorting;
  noRowsIcon: Icon;
  noRowsPlaceholder: Scalars["String"]["output"];
  rows: Array<CampaignOverviewDataRows>;
  striped: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AdsPortalCampaignFocusTypeahead = CampaignOverviewFocusTypeahead & {
  __typename?: "AdsPortalCampaignFocusTypeahead";
  adGroupOptions?: Maybe<Array<AdGroupOption>>;
  campaignOptions?: Maybe<Array<CampaignOption>>;
  heading: Scalars["String"]["output"];
  renderType: EgdsTypeaheadRenderType;
  searchTypeahead: EgdsTypeaheadInputField;
  subheading: Scalars["String"]["output"];
  triggerMenu: TypeaheadTriggerMenu;
  typeaheadSelect?: Maybe<EgdsBasicSelect>;
};

export type AdsPortalCampaignTableResponse = CampaignOverviewTableResponse & {
  __typename?: "AdsPortalCampaignTableResponse";
  columns: Array<OverviewTableColumn>;
  createLabel: Scalars["String"]["output"];
  editLabel: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  lastSort: CampaignOverviewTableSorting;
  noRowsIcon: Icon;
  noRowsPlaceholder: Scalars["String"]["output"];
  rows: Array<CampaignOverviewDataRows>;
  striped: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AdsTargetingData = {
  __typename?: "AdsTargetingData";
  adProvider: Scalars["String"]["output"];
  dateEnd?: Maybe<Scalars["String"]["output"]>;
  dateStart?: Maybe<Scalars["String"]["output"]>;
  dest?: Maybe<Scalars["String"]["output"]>;
  locResolver?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<Scalars["String"]["output"]>;
  pageName: Scalars["String"]["output"];
  siteId: Scalars["Int"]["output"];
  uuid: Scalars["String"]["output"];
};

export type AdvertiserMultiSelectOptions = {
  __typename?: "AdvertiserMultiSelectOptions";
  options?: Maybe<Array<ReportSelectionOptions>>;
  section?: Maybe<Scalars["String"]["output"]>;
};

export type AdvertiserPortalOnboarding = {
  __typename?: "AdvertiserPortalOnboarding";
  onboardingSideBar: AdvertiserPortalOnboardingSideBar;
  onboardingStep: AdvertiserPortalOnboardingStep;
};

export type AdvertiserPortalOnboardingOnboardingStepArgs = {
  onboardStep: OnboardStep;
  onboardingInput: OnboardingInput;
  productContext: ProductContext;
};

export type AdvertiserPortalOnboardingSideBar = {
  __typename?: "AdvertiserPortalOnboardingSideBar";
  onboardingSections: Array<OnboardingSection>;
};

export type AdvertiserPortalOnboardingStep = {
  __typename?: "AdvertiserPortalOnboardingStep";
  backButton?: Maybe<UiSecondaryButton>;
  continueButton: UiPrimaryButton;
  pageContent: Array<OnboardingCard>;
  pageHeading?: Maybe<Scalars["String"]["output"]>;
  pageText?: Maybe<Scalars["String"]["output"]>;
};

export type AdvertiserPortalSharedUiCard = {
  __typename?: "AdvertiserPortalSharedUICard";
  mode?: Maybe<SharedUiCardMode>;
  selectedResourceId?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<SharedUiCardType>;
};

export type AdvertiserReportingBadgeCell = {
  __typename?: "AdvertiserReportingBadgeCell";
  badge?: Maybe<EgdsStandardBadge>;
};

export type AdvertiserReportingCellInfo =
  | AdvertiserReportingBadgeCell
  | AdvertiserReportingNameCell
  | AdvertiserReportingNumberCell
  | AdvertiserReportingTextCell;

export type AdvertiserReportingContext = "FLIGHTS" | "LODGING_SPONSORED_LISTINGS";

export type AdvertiserReportingForm = {
  __typename?: "AdvertiserReportingForm";
  currencyInputField?: Maybe<EgdsInputField>;
  downloadButton: DownloadButtonWithDialog;
  filterOptions: ReportingOptionFilters;
  filtersAppliedToggle: EgdsExpandoPeek;
  header: AdvertiserReportingFormHeader;
  loadingText?: Maybe<EgdsStylizedText>;
  refreshButton: UiPrimaryButton;
};

export type AdvertiserReportingFormHeader = {
  __typename?: "AdvertiserReportingFormHeader";
  primaryText: Scalars["String"]["output"];
  secondaryText?: Maybe<Scalars["String"]["output"]>;
};

export type AdvertiserReportingGroupBy = "AD" | "AD_GROUP" | "CAMPAIGN" | "COUNTRY_CODE" | "PERIOD";

export type AdvertiserReportingLevel = "AD" | "AD_GROUP" | "CAMPAIGN" | "CAMPAIGN_AND_AD_GROUP";

export type AdvertiserReportingNameCell = {
  __typename?: "AdvertiserReportingNameCell";
  align?: Maybe<Scalars["String"]["output"]>;
  border?: Maybe<Scalars["String"]["output"]>;
  colSpan?: Maybe<Scalars["Int"]["output"]>;
  icon?: Maybe<Icon>;
  padding?: Maybe<Scalars["String"]["output"]>;
  striped?: Maybe<Scalars["Boolean"]["output"]>;
  text?: Maybe<EgdsStylizedText>;
};

export type AdvertiserReportingNumberCell = {
  __typename?: "AdvertiserReportingNumberCell";
  amount?: Maybe<Scalars["Int"]["output"]>;
  amountDouble?: Maybe<Scalars["Float"]["output"]>;
  formattedAmount?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<TextWeight>;
};

export interface AdvertiserReportingOptionsInput {
  adGroupIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  adIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  campaignIds?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  countryCodePOS?: InputMaybe<Array<Scalars["String"]["input"]>>;
  endDate: DateInput;
  groupBy?: InputMaybe<Array<AdvertiserReportingGroupBy>>;
  period?: InputMaybe<Scalars["String"]["input"]>;
  reportLevel?: InputMaybe<AdvertiserReportingLevel>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<Scalars["String"]["input"]>;
  startDate: DateInput;
}

export type AdvertiserReportingTableCellContent = {
  __typename?: "AdvertiserReportingTableCellContent";
  backgroundColor?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<AdvertiserReportingCellInfo>;
};

export type AdvertiserReportingTableCurrency = {
  __typename?: "AdvertiserReportingTableCurrency";
  currencyCode: Scalars["String"]["output"];
  primaryText: Scalars["String"]["output"];
};

export type AdvertiserReportingTableHeaders = {
  __typename?: "AdvertiserReportingTableHeaders";
  border?: Maybe<Scalars["String"]["output"]>;
  sortIcon?: Maybe<Icon>;
  sortable?: Maybe<Scalars["Boolean"]["output"]>;
  text?: Maybe<EgdsStylizedText>;
  width?: Maybe<Scalars["String"]["output"]>;
};

export type AdvertiserReportingTableNoResults = {
  __typename?: "AdvertiserReportingTableNoResults";
  noResultsIcon?: Maybe<Icon>;
  primaryText?: Maybe<EgdsStylizedText>;
  secondaryText?: Maybe<EgdsStylizedText>;
};

export type AdvertiserReportingTableResponse = {
  __typename?: "AdvertiserReportingTableResponse";
  adGroupMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  adMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  campaignInfoMap?: Maybe<Array<AdvertiserTableMapping>>;
  campaignMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  campaignOptions?: Maybe<Array<ReportSelectionOptions>>;
  currency: AdvertiserReportingTableCurrency;
  headers: Array<AdvertiserReportingTableHeaders>;
  lastSort: AdvertiserReportingTableSorting;
  noResultsSection?: Maybe<AdvertiserReportingTableNoResults>;
  regionMultiSelectOptions?: Maybe<Array<AdvertiserMultiSelectOptions>>;
  regionOptions?: Maybe<Array<ReportSelectionOptions>>;
  reportingData?: Maybe<Array<CampaignReportingData>>;
  striped: Scalars["String"]["output"];
  totalsRow?: Maybe<Array<AdvertiserReportingTotalCell>>;
};

export type AdvertiserReportingTableSorting = {
  __typename?: "AdvertiserReportingTableSorting";
  sortBy?: Maybe<Scalars["String"]["output"]>;
  sortDirection?: Maybe<Scalars["String"]["output"]>;
};

export type AdvertiserReportingTextCell = {
  __typename?: "AdvertiserReportingTextCell";
  text?: Maybe<EgdsStylizedText>;
};

export type AdvertiserReportingTotalCell = {
  __typename?: "AdvertiserReportingTotalCell";
  backgroundColor?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<AdvertiserReportingCellInfo>;
};

export type AdvertiserTableMapping = {
  __typename?: "AdvertiserTableMapping";
  endDate?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  list?: Maybe<Array<Scalars["String"]["output"]>>;
  startDate?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliateProfile = {
  __typename?: "AffiliateProfile";
  avatar: EgdsAvatar;
  avatarAccessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics: ClientSideAnalytics;
  legalDisclaimer?: Maybe<Scalars["String"]["output"]>;
  legalDisclaimerAccessibility?: Maybe<Scalars["String"]["output"]>;
  shopName?: Maybe<Scalars["String"]["output"]>;
  shopUrl?: Maybe<Scalars["URL"]["output"]>;
};

export type AffiliatesAccountCreatorBio = {
  __typename?: "AffiliatesAccountCreatorBio";
  description: Scalars["String"]["output"];
  /** @deprecated This field is deprecated and will be removed in the future */
  email: Scalars["String"]["output"];
  /** @deprecated This field is deprecated and will be removed in the future */
  status: AffiliatesBadge;
  title: Scalars["String"]["output"];
};

export type AffiliatesAccountDashboardFailureResponse = {
  __typename?: "AffiliatesAccountDashboardFailureResponse";
  backButton: AffiliatesButton;
  errorView: AffiliatesErrorView;
  heading: Scalars["String"]["output"];
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesAccountDashboardNavigatorFailureResponse = {
  __typename?: "AffiliatesAccountDashboardNavigatorFailureResponse";
  affiliatesCheckFailure?: Maybe<AffiliatesCheckFailure>;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesAccountDashboardNavigatorResponse =
  | AffiliatesAccountDashboardNavigatorFailureResponse
  | AffiliatesAccountDashboardNavigatorSuccessResponse;

export type AffiliatesAccountDashboardNavigatorSuccessResponse = {
  __typename?: "AffiliatesAccountDashboardNavigatorSuccessResponse";
  affiliatesAccountNavigation: AffiliatesMessagingCard;
  affiliatesCoachMark: AffiliatesCoachMark;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesAccountDashboardResponse =
  | AffiliatesAccountDashboardFailureResponse
  | AffiliatesAccountDashboardSuccessResponse;

export type AffiliatesAccountDashboardSuccessResponse = {
  __typename?: "AffiliatesAccountDashboardSuccessResponse";
  affiliatesProgramLink: Array<AffiliatesSpannableText>;
  backButton: AffiliatesButton;
  creatorBio: AffiliatesAccountCreatorBio;
  description: Array<AffiliatesSpannableText>;
  heading: Scalars["String"]["output"];
  impressionAnalyticEvent: Array<AffiliatesAnalyticEvent>;
  options: Array<AffiliatesMessagingCard>;
};

export type AffiliatesAccountResponse = {
  __typename?: "AffiliatesAccountResponse";
  accountDashboard: AffiliatesAccountDashboardResponse;
  accountDashboardNavigator: AffiliatesAccountDashboardNavigatorResponse;
};

export type AffiliatesAccountResponseAccountDashboardArgs = {
  clientContext: AffiliatesClientContextInput;
};

export type AffiliatesAccountResponseAccountDashboardNavigatorArgs = {
  clientContext: AffiliatesClientContextInput;
};

export type AffiliatesAddCollectionItemToRemovalListAction = {
  __typename?: "AffiliatesAddCollectionItemToRemovalListAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
  itemId: Scalars["String"]["output"];
};

export type AffiliatesAddCollectionToListAction = {
  __typename?: "AffiliatesAddCollectionToListAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
};

export type AffiliatesAddOrRemoveCollectionItemAction = {
  __typename?: "AffiliatesAddOrRemoveCollectionItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  itemDescription: Scalars["String"]["output"];
  itemName: Scalars["String"]["output"];
  productInfo: AffiliatesCollectionItemProductInfo;
};

export type AffiliatesAddOrRemoveCollectionItemFailureResponse = {
  __typename?: "AffiliatesAddOrRemoveCollectionItemFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesAddOrRemoveCollectionItemRequestInput {
  collectionIdsForAdd: Array<Scalars["String"]["input"]>;
  collectionItemsForRemove: Array<AffiliatesCollectionItemIdentifierInput>;
  itemRequest: AffiliatesCollectionItemRequestInput;
}

export type AffiliatesAddOrRemoveCollectionItemResponse =
  | AffiliatesAddOrRemoveCollectionItemFailureResponse
  | AffiliatesAddOrRemoveCollectionItemSuccessResponse;

export type AffiliatesAddOrRemoveCollectionItemSuccessResponse = {
  __typename?: "AffiliatesAddOrRemoveCollectionItemSuccessResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<AffiliatesToast>;
};

export type AffiliatesAddToCollectionAction = {
  __typename?: "AffiliatesAddToCollectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesAnalyticEvent {
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
}

export type AffiliatesAnalyticsInfo = {
  __typename?: "AffiliatesAnalyticsInfo";
  alternateProperties?: Maybe<AffiliatesCarousel>;
  avgStay?: Maybe<AffiliatesContent>;
  guestReviews?: Maybe<AffiliatesReviews>;
  popularPlacesToVisit?: Maybe<AffiliatesCarousel>;
  title: Scalars["String"]["output"];
};

export type AffiliatesBackAction = {
  __typename?: "AffiliatesBackAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesBadge = {
  __typename?: "AffiliatesBadge";
  badge: EgdsStandardBadge;
};

export type AffiliatesBasicOption = {
  __typename?: "AffiliatesBasicOption";
  content: EgdsBasicOption;
  icon?: Maybe<Icon>;
  selectionAction: AffiliatesBasicOptionSelectionAction;
};

export type AffiliatesBasicOptionSelectionAction = {
  __typename?: "AffiliatesBasicOptionSelectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesBasicSelectField = {
  __typename?: "AffiliatesBasicSelectField";
  input: EgdsBasicSelect;
  inputOptions: Array<AffiliatesBasicOption>;
  selectionAction: AffiliatesBasicSelectFieldSelectionAction;
};

export type AffiliatesBasicSelectFieldSelectionAction = {
  __typename?: "AffiliatesBasicSelectFieldSelectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesButton = {
  __typename?: "AffiliatesButton";
  action: AffiliatesButtonAction;
  button: EgdsButton;
  showActionProgress?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AffiliatesButtonAction =
  | AffiliatesAddOrRemoveCollectionItemAction
  | AffiliatesAddToCollectionAction
  | AffiliatesBackAction
  | AffiliatesCancelAction
  | AffiliatesCloseAction
  | AffiliatesCloseToolbarAction
  | AffiliatesCollectionDeleteAction
  | AffiliatesCollectionEditAction
  | AffiliatesCollectionItemDeleteAction
  | AffiliatesCollectionItemEditAction
  | AffiliatesCollectionVisibilityEditAction
  | AffiliatesCopyLinkAction
  | AffiliatesCreateCollectionWithItemAction
  | AffiliatesCreateLinkAction
  | AffiliatesCreateTagSaveAction
  | AffiliatesCustomLinkSaveAction
  | AffiliatesDeleteAction
  | AffiliatesDownloadImageAction
  | AffiliatesEditAction
  | AffiliatesLinkAccountAction
  | AffiliatesNavigateAction
  | AffiliatesOpenCloseToolbarDialogAction
  | AffiliatesOpenMenuAction
  | AffiliatesOpenNativeSettingsAction
  | AffiliatesOpenPartnerDetailsAction
  | AffiliatesOpenToolboxAction
  | AffiliatesOutwardLinkAction
  | AffiliatesSaveAction
  | AffiliatesSelectVideoAction
  | AffiliatesShareLinkAction
  | AffiliatesShowCollectionFormAction
  | AffiliatesShowCollectionItemFormAction
  | AffiliatesShowConfirmationDialogAction
  | AffiliatesShowDialogAction
  | AffiliatesShowDownloadImagesGalleryFormAction
  | AffiliatesShowHighlightedReviewsInfoAction
  | AffiliatesShowInitiateAddOrRemoveCollectionItemAction
  | AffiliatesSignInFirstAction
  | AffiliatesSubmitAction
  | AffiliatesVanityLinkSaveAction
  | AffiliatesViewCollectionAction
  | AffiliatesViewStatsAction;

export type AffiliatesCancelAction = {
  __typename?: "AffiliatesCancelAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCarousel = {
  __typename?: "AffiliatesCarousel";
  items: Array<AffiliatesCarouselItem>;
  nextButton: AffiliatesPagingButton;
  previousButton: AffiliatesPagingButton;
  title: Scalars["String"]["output"];
  titleHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesCarouselItem =
  | AffiliatesImageCarouselItem
  | AffiliatesLodgingCardCarouselItem
  | AffiliatesTravelerCollectionItem;

export type AffiliatesCarouselNextAction = {
  __typename?: "AffiliatesCarouselNextAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCarouselPrevAction = {
  __typename?: "AffiliatesCarouselPrevAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesChannelType = "ANDROID" | "IOS" | "WEB";

export type AffiliatesCheckFailure = {
  __typename?: "AffiliatesCheckFailure";
  failureType: AffiliatesCheckFailureType;
};

export type AffiliatesCheckFailureType = "PARTNER_NOT_LINKED" | "STATUS_NOT_APPROVED" | "TOOL_DISABLED";

export type AffiliatesCheckbox = {
  __typename?: "AffiliatesCheckbox";
  checkbox: EgdsCheckBox;
  offAction: AffiliatesCheckboxAction;
  onAction: AffiliatesCheckboxAction;
};

export type AffiliatesCheckboxAction =
  | AffiliatesCreateCustomLinkWithDatesAction
  | AffiliatesCreateCustomLinkWithoutDatesAction;

export interface AffiliatesClientContextInput {
  deviceContext?: InputMaybe<AffiliatesDeviceContextInput>;
  lineOfBusiness: LineOfBusinessDomain;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  pageLocation: PageLocation;
  partnerContext?: InputMaybe<AffiliatesPartnerContextInput>;
}

export type AffiliatesCloseAction = {
  __typename?: "AffiliatesCloseAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  webTargetUrl?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesCloseToolbarAction = {
  __typename?: "AffiliatesCloseToolbarAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesCloseToolbarDialog = {
  __typename?: "AffiliatesCloseToolbarDialog";
  cancelButton: AffiliatesButton;
  closeToolbarButton: AffiliatesButton;
  content: Array<AffiliatesSpannableText>;
  title: Scalars["String"]["output"];
};

export type AffiliatesCloseToolbarFailureResponse = {
  __typename?: "AffiliatesCloseToolbarFailureResponse";
  banner: UiBanner;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesCloseToolbarResponse =
  | AffiliatesCloseToolbarFailureResponse
  | AffiliatesCloseToolbarSuccessResponse;

export type AffiliatesCloseToolbarSuccessResponse = {
  __typename?: "AffiliatesCloseToolbarSuccessResponse";
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesCoachMark = {
  __typename?: "AffiliatesCoachMark";
  autoDismissAfterSecs: Scalars["Int"]["output"];
  dismissButton?: Maybe<AffiliatesButton>;
  heading: Scalars["String"]["output"];
  instructions: Array<AffiliatesSpannableText>;
};

export type AffiliatesCollection = {
  __typename?: "AffiliatesCollection";
  action: AffiliatesLinkAction;
  card: EgdsImageCard;
  collectionHandle: Scalars["String"]["output"];
  shareButton: AffiliatesButton;
};

export type AffiliatesCollectionDeleteAction = {
  __typename?: "AffiliatesCollectionDeleteAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
};

export type AffiliatesCollectionDetailsFailureResponse = {
  __typename?: "AffiliatesCollectionDetailsFailureResponse";
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesCollectionDetailsFooter = {
  __typename?: "AffiliatesCollectionDetailsFooter";
  options: Array<AffiliatesMessagingCard>;
  title: Scalars["String"]["output"];
};

export interface AffiliatesCollectionDetailsRequestInput {
  collectionId: Scalars["String"]["input"];
}

export type AffiliatesCollectionDetailsResponse =
  | AffiliatesCollectionDetailsFailureResponse
  | AffiliatesCollectionDetailsSuccessResponse;

export type AffiliatesCollectionDetailsSuccessResponse = {
  __typename?: "AffiliatesCollectionDetailsSuccessResponse";
  badges: Array<AffiliatesBadge>;
  collectionId: Scalars["String"]["output"];
  confirmDeleteDialog?: Maybe<AffiliatesDialog>;
  description: Array<AffiliatesSpannableText>;
  dismissButton: AffiliatesButton;
  editCollectionForm?: Maybe<AffiliatesCollectionForm>;
  footer?: Maybe<AffiliatesCollectionDetailsFooter>;
  heroImage?: Maybe<Image>;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  items: Array<AffiliatesCollectionItemCard>;
  menu: AffiliatesMenu;
  /** @deprecated This field is deprecated, use menuIconButton instead */
  menuButton: AffiliatesButton;
  menuIconButton?: Maybe<AffiliatesButton>;
  /** @deprecated This field is deprecated, use shareIconButton instead */
  shareButton: AffiliatesButton;
  shareIconButton?: Maybe<AffiliatesButton>;
  title: Scalars["String"]["output"];
  visibilityInterstitial?: Maybe<AffiliatesCollectionVisibilityInterstitial>;
};

export type AffiliatesCollectionEditAction = {
  __typename?: "AffiliatesCollectionEditAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
};

export type AffiliatesCollectionForm = {
  __typename?: "AffiliatesCollectionForm";
  descriptionField: AffiliatesTextAreaField;
  dismissButton: AffiliatesButton;
  formDescription: AffiliatesSpannableText;
  formTitle: Scalars["String"]["output"];
  nameField: AffiliatesTextInputField;
  saveButton: AffiliatesButton;
  visibilityField?: Maybe<AffiliatesBasicSelectField>;
};

export type AffiliatesCollectionItemCard = {
  __typename?: "AffiliatesCollectionItemCard";
  card: EgdsImageCard;
  confirmDeleteDialog?: Maybe<AffiliatesDialog>;
  editCollectionItemForm?: Maybe<AffiliatesCollectionItemForm>;
  itemId: Scalars["String"]["output"];
  menu: AffiliatesMenu;
  menuButton: AffiliatesButton;
};

export type AffiliatesCollectionItemDeleteAction = {
  __typename?: "AffiliatesCollectionItemDeleteAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
  itemId: Scalars["String"]["output"];
};

export type AffiliatesCollectionItemEditAction = {
  __typename?: "AffiliatesCollectionItemEditAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
  itemId: Scalars["String"]["output"];
};

export type AffiliatesCollectionItemForm = {
  __typename?: "AffiliatesCollectionItemForm";
  descriptionField: AffiliatesTextAreaField;
  dismissButton: AffiliatesButton;
  formTitle: Scalars["String"]["output"];
  image: Image;
  name: Scalars["String"]["output"];
  saveButton: AffiliatesButton;
};

export interface AffiliatesCollectionItemIdentifierInput {
  collectionId: Scalars["String"]["input"];
  itemId: Scalars["String"]["input"];
}

export type AffiliatesCollectionItemLodgingInfo = {
  __typename?: "AffiliatesCollectionItemLodgingInfo";
  egPropertyId: Scalars["String"]["output"];
};

export type AffiliatesCollectionItemProductInfo = AffiliatesCollectionItemLodgingInfo;

export interface AffiliatesCollectionItemRequestInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
  targetUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type AffiliatesCollectionVisibilityEditAction = {
  __typename?: "AffiliatesCollectionVisibilityEditAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
  visibilityType: Scalars["String"]["output"];
};

export type AffiliatesCollectionVisibilityInterstitial = {
  __typename?: "AffiliatesCollectionVisibilityInterstitial";
  /** @deprecated This field is deprecated, use visibilityTypeToConfirmDialogMap instead */
  confirmPrivateVisibilityDialog?: Maybe<AffiliatesDialog>;
  /** @deprecated This field is deprecated, use visibilityTypeToConfirmDialogMap instead */
  confirmPublicVisibilityDialog?: Maybe<AffiliatesDialog>;
  visibilityTypeMenu?: Maybe<AffiliatesMenu>;
  visibilityTypeToConfirmDialogMap?: Maybe<Array<AffiliatesStringToDialogMap>>;
};

export type AffiliatesCollectionsEmptySuccessResponse = {
  __typename?: "AffiliatesCollectionsEmptySuccessResponse";
  createCollectionButton: AffiliatesButton;
  createCollectionForm: AffiliatesCollectionForm;
  description: AffiliatesSpannableText;
  dismissButton: AffiliatesButton;
  exploreButton: AffiliatesButton;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  placeholderImage: Image;
  title: Scalars["String"]["output"];
};

export type AffiliatesCollectionsFailureResponse = {
  __typename?: "AffiliatesCollectionsFailureResponse";
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCollectionsResponse =
  | AffiliatesCollectionsEmptySuccessResponse
  | AffiliatesCollectionsFailureResponse
  | AffiliatesCollectionsSuccessResponse;

export type AffiliatesCollectionsSuccessResponse = {
  __typename?: "AffiliatesCollectionsSuccessResponse";
  createCollectionButton: AffiliatesButton;
  createCollectionForm: AffiliatesCollectionForm;
  dismissButton: AffiliatesButton;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  list: Array<AffiliatesCollection>;
  title: Scalars["String"]["output"];
};

export type AffiliatesCommissionInfo = {
  __typename?: "AffiliatesCommissionInfo";
  avgBookingValue?: Maybe<AffiliatesCommissionInfoItem>;
  brandDomain: Scalars["String"]["output"];
  brandLogo: Mark;
  commissionRate: AffiliatesCommissionInfoItem;
  estimatedCommission?: Maybe<AffiliatesCommissionInfoItem>;
  title: Scalars["String"]["output"];
  validityPeriod: AffiliatesCommissionInfoItem;
};

export type AffiliatesCommissionInfoItem = {
  __typename?: "AffiliatesCommissionInfoItem";
  title: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type AffiliatesConfirmAccountLinkFailureResponse = {
  __typename?: "AffiliatesConfirmAccountLinkFailureResponse";
  banner: UiBanner;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesConfirmAccountLinkResponse =
  | AffiliatesConfirmAccountLinkFailureResponse
  | AffiliatesConfirmAccountLinkSuccessResponse;

export type AffiliatesConfirmAccountLinkSuccessResponse = {
  __typename?: "AffiliatesConfirmAccountLinkSuccessResponse";
  affiliatesState: AffiliatesState;
  closeAction: AffiliatesCloseAction;
  goToAccountButton: AffiliatesButton;
  heading: Scalars["String"]["output"];
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  message: Scalars["String"]["output"];
  toolboxInstruction: Scalars["String"]["output"];
  toolboxPreviewImage: Image;
};

export type AffiliatesConnectionInfo = {
  __typename?: "AffiliatesConnectionInfo";
  companyName: Scalars["String"]["output"];
  companyNameLabel: Scalars["String"]["output"];
  emailAddress: Scalars["String"]["output"];
  emailAddressLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AffiliatesContent = {
  __typename?: "AffiliatesContent";
  description?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesCopyLinkAction = {
  __typename?: "AffiliatesCopyLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  toast: EgdsToast;
  url: Scalars["String"]["output"];
};

export type AffiliatesCreateAffiliateTagFailureResponse = {
  __typename?: "AffiliatesCreateAffiliateTagFailureResponse";
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesCreateAffiliateTagRequestInput {
  journeyContext?: InputMaybe<AffiliatesJourneyContextInput>;
  userText: Scalars["String"]["input"];
}

export type AffiliatesCreateAffiliateTagResponse =
  | AffiliatesCreateAffiliateTagFailureResponse
  | AffiliatesCreateAffiliateTagSuccessResponse;

export type AffiliatesCreateAffiliateTagSuccessResponse = {
  __typename?: "AffiliatesCreateAffiliateTagSuccessResponse";
  customLinkForm?: Maybe<AffiliatesCustomLinkForm>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  onloadAction?: Maybe<AffiliatesOnloadAction>;
  switch: AffiliatesSwitch;
  vanityLinkForm: AffiliatesVanityLinkForm;
};

export type AffiliatesCreateCollectionFailureResponse = {
  __typename?: "AffiliatesCreateCollectionFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesCreateCollectionRequestInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  itemRequest?: InputMaybe<AffiliatesCollectionItemRequestInput>;
  name: Scalars["String"]["input"];
  visibilityType: Scalars["String"]["input"];
}

export type AffiliatesCreateCollectionResponse =
  | AffiliatesCreateCollectionFailureResponse
  | AffiliatesCreateCollectionSuccessButSaveItemFailureResponse
  | AffiliatesCreateCollectionSuccessResponse;

export type AffiliatesCreateCollectionSuccessButSaveItemFailureResponse = {
  __typename?: "AffiliatesCreateCollectionSuccessButSaveItemFailureResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast: EgdsToast;
};

export type AffiliatesCreateCollectionSuccessResponse = {
  __typename?: "AffiliatesCreateCollectionSuccessResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<AffiliatesToast>;
};

export type AffiliatesCreateCollectionWithItemAction = {
  __typename?: "AffiliatesCreateCollectionWithItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  itemDescription: Scalars["String"]["output"];
  itemName: Scalars["String"]["output"];
  productInfo: AffiliatesCollectionItemProductInfo;
};

export type AffiliatesCreateCustomLinkFailureResponse = {
  __typename?: "AffiliatesCreateCustomLinkFailureResponse";
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  statusMessage: AffiliatesIconText;
};

export interface AffiliatesCreateCustomLinkRequestInput {
  journeyContext?: InputMaybe<AffiliatesJourneyContextInput>;
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
  targetUrl: Scalars["String"]["input"];
  userText?: InputMaybe<Scalars["String"]["input"]>;
}

export type AffiliatesCreateCustomLinkResponse =
  | AffiliatesCreateCustomLinkFailureResponse
  | AffiliatesCreateCustomLinkSuccessResponse;

export type AffiliatesCreateCustomLinkSuccessResponse = {
  __typename?: "AffiliatesCreateCustomLinkSuccessResponse";
  copyButton: AffiliatesTransitionButton;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  label: Scalars["String"]["output"];
  onloadAction: AffiliatesOnloadAction;
  shareButton: AffiliatesButton;
  statusMessage: AffiliatesIconText;
  url: Scalars["String"]["output"];
  urlDescription?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesCreateCustomLinkWithDatesAction = {
  __typename?: "AffiliatesCreateCustomLinkWithDatesAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCreateCustomLinkWithoutDatesAction = {
  __typename?: "AffiliatesCreateCustomLinkWithoutDatesAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCreateLinkAction = {
  __typename?: "AffiliatesCreateLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesCreateLinkDefaultSuccessResponse = {
  __typename?: "AffiliatesCreateLinkDefaultSuccessResponse";
  closeAction: AffiliatesCloseAction;
  createTagForm?: Maybe<AffiliatesCreateTagForm>;
  customLinkForm?: Maybe<AffiliatesCustomLinkForm>;
  descriptiveLinkForm: AffiliatesDescriptiveLinkForm;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  onloadAction: AffiliatesOnloadAction;
  pageInfo: AffiliatesPageInfo;
  shareToBlog: AffiliatesShareToBlog;
  statsLink?: Maybe<AffiliatesStandardLink>;
  statusMessage: AffiliatesIconText;
  switch?: Maybe<AffiliatesSwitch>;
  vanityLinkForm?: Maybe<AffiliatesVanityLinkForm>;
};

export type AffiliatesCreateLinkFailureResponse = {
  __typename?: "AffiliatesCreateLinkFailureResponse";
  banner: UiBanner;
  closeAction: AffiliatesCloseAction;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  statsLink?: Maybe<AffiliatesStandardLink>;
};

export type AffiliatesCreateLinkLodgingSuccessResponse = {
  __typename?: "AffiliatesCreateLinkLodgingSuccessResponse";
  closeAction: AffiliatesCloseAction;
  createTagForm?: Maybe<AffiliatesCreateTagForm>;
  customLinkForm?: Maybe<AffiliatesCustomLinkForm>;
  descriptiveLinkForm: AffiliatesDescriptiveLinkForm;
  downloadImages?: Maybe<AffiliatesDownloadImages>;
  downloadImagesGalleryForm?: Maybe<AffiliatesDownloadImagesGalleryForm>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  lodgingInfo: AffiliatesLodgingInfo;
  onloadAction: AffiliatesOnloadAction;
  shareToBlog: AffiliatesShareToBlog;
  statsLink?: Maybe<AffiliatesStandardLink>;
  statusMessage: AffiliatesIconText;
  switch?: Maybe<AffiliatesSwitch>;
  vanityLinkForm?: Maybe<AffiliatesVanityLinkForm>;
};

export interface AffiliatesCreateLinkRequestInput {
  journeyContext?: InputMaybe<AffiliatesJourneyContextInput>;
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
  targetUrl: Scalars["String"]["input"];
}

export type AffiliatesCreateLinkResponse =
  | AffiliatesCreateLinkDefaultSuccessResponse
  | AffiliatesCreateLinkFailureResponse
  | AffiliatesCreateLinkLodgingSuccessResponse;

export type AffiliatesCreateTagExampleCustomLink = {
  __typename?: "AffiliatesCreateTagExampleCustomLink";
  defaultTag: Scalars["String"]["output"];
  description: AffiliatesSpannableText;
  label: Scalars["String"]["output"];
  tagPlaceholder: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type AffiliatesCreateTagForm = {
  __typename?: "AffiliatesCreateTagForm";
  backButton: AffiliatesButton;
  createTagButton: AffiliatesButton;
  description: Array<AffiliatesSpannableText>;
  exampleCustomLink: AffiliatesCreateTagExampleCustomLink;
  serviceFailureError?: Maybe<Scalars["String"]["output"]>;
  tagHandle: AffiliatesTextInputField;
  title: Scalars["String"]["output"];
};

export type AffiliatesCreateTagSaveAction = {
  __typename?: "AffiliatesCreateTagSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesCreateVanityLinkFailureResponse = {
  __typename?: "AffiliatesCreateVanityLinkFailureResponse";
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  statusMessage: AffiliatesIconText;
};

export interface AffiliatesCreateVanityLinkRequestInput {
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
  targetUrl: Scalars["String"]["input"];
  userText: Scalars["String"]["input"];
}

export type AffiliatesCreateVanityLinkResponse =
  | AffiliatesCreateVanityLinkFailureResponse
  | AffiliatesCreateVanityLinkSuccessResponse;

export type AffiliatesCreateVanityLinkSuccessResponse = {
  __typename?: "AffiliatesCreateVanityLinkSuccessResponse";
  copyButton: AffiliatesTransitionButton;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  label: Scalars["String"]["output"];
  onloadAction: AffiliatesOnloadAction;
  shareButton: AffiliatesButton;
  statusMessage: AffiliatesIconText;
  url: Scalars["String"]["output"];
};

export type AffiliatesCreator = {
  __typename?: "AffiliatesCreator";
  creatorBio: Array<AffiliatesSpannableText>;
  creatorHandle: Scalars["String"]["output"];
  creatorImage: Image;
  creatorName: Scalars["String"]["output"];
  partnerEngagementText?: Maybe<AffiliatesSpannableText>;
  socialPlatformLinks: Array<AffiliatesSocialPlatformLink>;
};

export type AffiliatesCreatorShop = {
  __typename?: "AffiliatesCreatorShop";
  creator: AffiliatesCreator;
  shopImage: Image;
  shopLinkAction?: Maybe<AffiliatesLinkAction>;
  shopName: Scalars["String"]["output"];
};

export type AffiliatesCreatorToolingResponse = {
  __typename?: "AffiliatesCreatorToolingResponse";
  collectionDetails: AffiliatesCollectionDetailsResponse;
  collections: AffiliatesCollectionsResponse;
  initiateAddOrRemoveCollectionItem: AffiliatesInitiateAddOrRemoveCollectionItemResponse;
  initiateCollectionItem: AffiliatesInitiateCollectionItemResponse;
  previewCollection: AffiliatesPreviewCollectionResponse;
};

export type AffiliatesCreatorToolingResponseCollectionDetailsArgs = {
  clientContext: AffiliatesClientContextInput;
  request: AffiliatesCollectionDetailsRequestInput;
};

export type AffiliatesCreatorToolingResponseCollectionsArgs = {
  clientContext: AffiliatesClientContextInput;
};

export type AffiliatesCreatorToolingResponseInitiateAddOrRemoveCollectionItemArgs = {
  clientContext: AffiliatesClientContextInput;
  request: AffiliatesInitiateAddOrRemoveCollectionItemRequestInput;
};

export type AffiliatesCreatorToolingResponseInitiateCollectionItemArgs = {
  clientContext: AffiliatesClientContextInput;
  request: AffiliatesInitiateCollectionItemRequestInput;
};

export type AffiliatesCreatorToolingResponsePreviewCollectionArgs = {
  clientContext: AffiliatesClientContextInput;
  request: AffiliatesPreviewCollectionRequestInput;
};

export type AffiliatesCustomLinkForm = {
  __typename?: "AffiliatesCustomLinkForm";
  cancelButton: AffiliatesButton;
  customValidationRules: Array<AffiliatesFormValidationRule>;
  includeDates?: Maybe<AffiliatesCheckbox>;
  pageHandle: AffiliatesTextInputField;
  saveButton: AffiliatesButton;
  serviceFailureError?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesCustomLinkSaveAction = {
  __typename?: "AffiliatesCustomLinkSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  statusMessage: AffiliatesIconText;
};

export type AffiliatesDeleteAction = {
  __typename?: "AffiliatesDeleteAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesDeleteCollectionFailureResponse = {
  __typename?: "AffiliatesDeleteCollectionFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesDeleteCollectionRequestInput {
  collectionId: Scalars["String"]["input"];
}

export type AffiliatesDeleteCollectionResponse =
  | AffiliatesDeleteCollectionFailureResponse
  | AffiliatesDeleteCollectionSuccessResponse;

export type AffiliatesDeleteCollectionSuccessResponse = {
  __typename?: "AffiliatesDeleteCollectionSuccessResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<AffiliatesToast>;
};

export type AffiliatesDescriptiveLinkForm = {
  __typename?: "AffiliatesDescriptiveLinkForm";
  copyButton: AffiliatesTransitionButton;
  label: Scalars["String"]["output"];
  shareButton: AffiliatesButton;
  url: Scalars["String"]["output"];
};

export interface AffiliatesDeviceContextInput {
  appVersion?: InputMaybe<Scalars["String"]["input"]>;
  channelType?: InputMaybe<AffiliatesChannelType>;
}

export type AffiliatesDialog = {
  __typename?: "AffiliatesDialog";
  content: Array<AffiliatesSpannableText>;
  primaryButton: AffiliatesButton;
  secondaryButton?: Maybe<AffiliatesButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesDownloadImageAction = {
  __typename?: "AffiliatesDownloadImageAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesDownloadImages = {
  __typename?: "AffiliatesDownloadImages";
  description: AffiliatesSpannableText;
  openDownloadImagesGalleryFormButton: AffiliatesButton;
  title: Scalars["String"]["output"];
};

export type AffiliatesDownloadImagesGalleryForm = {
  __typename?: "AffiliatesDownloadImagesGalleryForm";
  backButton: AffiliatesButton;
  description: AffiliatesSpannableText;
  images: Array<AffiliatesDownloadableImage>;
  pagination?: Maybe<AffiliatesPagination>;
  permissionRequiredErrorBanner?: Maybe<UiBanner>;
  title: Scalars["String"]["output"];
};

export type AffiliatesDownloadableImage = {
  __typename?: "AffiliatesDownloadableImage";
  defaultButton: AffiliatesButton;
  downloadUrl: Scalars["String"]["output"];
  image: Image;
  successButton: AffiliatesButton;
};

export type AffiliatesEditAction = {
  __typename?: "AffiliatesEditAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesEditCollectionFailureResponse = {
  __typename?: "AffiliatesEditCollectionFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesEditCollectionItemFailureResponse = {
  __typename?: "AffiliatesEditCollectionItemFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesEditCollectionItemRequestInput {
  collectionId: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  itemId: Scalars["String"]["input"];
}

export type AffiliatesEditCollectionItemResponse =
  | AffiliatesEditCollectionItemFailureResponse
  | AffiliatesEditCollectionItemSuccessResponse;

export type AffiliatesEditCollectionItemSuccessResponse = {
  __typename?: "AffiliatesEditCollectionItemSuccessResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<AffiliatesToast>;
};

export interface AffiliatesEditCollectionRequestInput {
  collectionId: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
}

export type AffiliatesEditCollectionResponse =
  | AffiliatesEditCollectionFailureResponse
  | AffiliatesEditCollectionSuccessResponse;

export type AffiliatesEditCollectionSuccessResponse = {
  __typename?: "AffiliatesEditCollectionSuccessResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<AffiliatesToast>;
};

export type AffiliatesErrorView = {
  __typename?: "AffiliatesErrorView";
  heading: Scalars["String"]["output"];
  icon: Icon;
  message: Array<AffiliatesSpannableText>;
};

export type AffiliatesExpandoAction = AffiliatesExpandoTextAction;

export type AffiliatesExpandoText = {
  __typename?: "AffiliatesExpandoText";
  collapseAction: AffiliatesExpandoAction;
  expandAction: AffiliatesExpandoAction;
  text: Scalars["String"]["output"];
  textControl: EgdsExpandoPeek;
};

export type AffiliatesExpandoTextAction = {
  __typename?: "AffiliatesExpandoTextAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesFieldError = {
  __typename?: "AffiliatesFieldError";
  errorMessage: AffiliatesSpannableText;
  identifier: Scalars["String"]["output"];
};

export type AffiliatesFileFormatValidation = {
  __typename?: "AffiliatesFileFormatValidation";
  accept: Array<Scalars["String"]["output"]>;
  banner: UiBanner;
  validationFailureAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesFileSizeValidation = {
  __typename?: "AffiliatesFileSizeValidation";
  banner: UiBanner;
  sizeInMb: Scalars["Int"]["output"];
  validationFailureAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesFileUploadValidation =
  | AffiliatesFileFormatValidation
  | AffiliatesFileSizeValidation
  | AffiliatesVideoAspectRatioValidation
  | AffiliatesVideoDimensionsValidation
  | AffiliatesVideoLengthValidation;

export type AffiliatesFormError = {
  __typename?: "AffiliatesFormError";
  errorMessage?: Maybe<AffiliatesSpannableText>;
  fieldErrors: Array<AffiliatesFieldError>;
};

export interface AffiliatesFormValidationRule {
  errorMessage: Scalars["String"]["output"];
}

export type AffiliatesGenerateVideoUploadUrlFailureResponse = {
  __typename?: "AffiliatesGenerateVideoUploadUrlFailureResponse";
  errorBanner?: Maybe<UiBanner>;
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesGenerateVideoUploadUrlRequestInput {
  contentType: Scalars["String"]["input"];
  fileName: Scalars["String"]["input"];
}

export type AffiliatesGenerateVideoUploadUrlResponse =
  | AffiliatesGenerateVideoUploadUrlFailureResponse
  | AffiliatesGenerateVideoUploadUrlSuccessResponse;

export type AffiliatesGenerateVideoUploadUrlSuccessResponse = {
  __typename?: "AffiliatesGenerateVideoUploadUrlSuccessResponse";
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  uploadReference: Scalars["String"]["output"];
  uploadUrl: Scalars["String"]["output"];
};

export type AffiliatesHighlightedReview = {
  __typename?: "AffiliatesHighlightedReview";
  date: Scalars["String"]["output"];
  rating: Scalars["String"]["output"];
  reviewerName: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  textControl: EgdsExpandoPeek;
  title: Scalars["String"]["output"];
};

export type AffiliatesHighlightedReviews = {
  __typename?: "AffiliatesHighlightedReviews";
  criticalReview?: Maybe<AffiliatesHighlightedReview>;
  infoButton: AffiliatesButton;
  infoDialog: AffiliatesInfoDialog;
  positiveReview?: Maybe<AffiliatesHighlightedReview>;
  title: Scalars["String"]["output"];
};

export type AffiliatesIconSwitch = {
  __typename?: "AffiliatesIconSwitch";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel: Scalars["String"]["output"];
  offAction: AffiliatesSwitchAction;
  offIcon: UiGraphic;
  onAction: AffiliatesSwitchAction;
  onIcon: UiGraphic;
  uncheckedAccessibilityLabel: Scalars["String"]["output"];
};

export type AffiliatesIconText = {
  __typename?: "AffiliatesIconText";
  graphic: UiGraphic;
  text: EgdsStylizedText;
};

export type AffiliatesImageCarouselItem = {
  __typename?: "AffiliatesImageCarouselItem";
  card: EgdsImageCard;
  cardLinkAction: AffiliatesLinkAction;
};

export type AffiliatesImpressionAnalyticEvent = AffiliatesAnalyticEvent & {
  __typename?: "AffiliatesImpressionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type AffiliatesInfoDialog = {
  __typename?: "AffiliatesInfoDialog";
  closeButton: AffiliatesButton;
  message: AffiliatesSpannableText;
};

export type AffiliatesInitiateAccountLinkConnectionExistsResponse = {
  __typename?: "AffiliatesInitiateAccountLinkConnectionExistsResponse";
  affiliatesState: AffiliatesState;
  closeAction: AffiliatesCloseAction;
  continueButton: AffiliatesButton;
  heading: Scalars["String"]["output"];
  icon: Icon;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  message: Scalars["String"]["output"];
};

export type AffiliatesInitiateAccountLinkFailureResponse = {
  __typename?: "AffiliatesInitiateAccountLinkFailureResponse";
  closeAction: AffiliatesCloseAction;
  heading: Scalars["String"]["output"];
  icon: Icon;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  message: Scalars["String"]["output"];
};

export type AffiliatesInitiateAccountLinkResponse =
  | AffiliatesInitiateAccountLinkConnectionExistsResponse
  | AffiliatesInitiateAccountLinkFailureResponse
  | AffiliatesInitiateAccountLinkSignInResponse
  | AffiliatesInitiateAccountLinkSuccessResponse;

export type AffiliatesInitiateAccountLinkSignInResponse = {
  __typename?: "AffiliatesInitiateAccountLinkSignInResponse";
  affiliatesLogo: Mark;
  brandLogo: Mark;
  cancelButton: AffiliatesButton;
  closeAction: AffiliatesCloseAction;
  heading: Scalars["String"]["output"];
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  linkingIcon: Icon;
  message: Scalars["String"]["output"];
  signInButton: AffiliatesButton;
};

export type AffiliatesInitiateAccountLinkSuccessResponse = {
  __typename?: "AffiliatesInitiateAccountLinkSuccessResponse";
  affiliatesConnectionInfo: AffiliatesConnectionInfo;
  affiliatesLogo: Mark;
  brandLogo: Mark;
  cancelButton: AffiliatesButton;
  closeAction: AffiliatesCloseAction;
  connectButton: AffiliatesButton;
  heading: Scalars["String"]["output"];
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  linkingIcon: Icon;
  message: Scalars["String"]["output"];
};

export type AffiliatesInitiateAddItemToNewCollectionResponse = {
  __typename?: "AffiliatesInitiateAddItemToNewCollectionResponse";
  createCollectionForm: AffiliatesCollectionForm;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesInitiateAddOrRemoveCollectionItemFailureResponse = {
  __typename?: "AffiliatesInitiateAddOrRemoveCollectionItemFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesInitiateAddOrRemoveCollectionItemRequestInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
}

export type AffiliatesInitiateAddOrRemoveCollectionItemResponse =
  | AffiliatesInitiateAddItemToNewCollectionResponse
  | AffiliatesInitiateAddOrRemoveCollectionItemFailureResponse
  | AffiliatesInitiateAddOrRemoveItemByCollectionsResponse;

export type AffiliatesInitiateAddOrRemoveItemByCollectionsResponse = {
  __typename?: "AffiliatesInitiateAddOrRemoveItemByCollectionsResponse";
  additionalOptions: Array<AffiliatesMessagingCard>;
  collectionCards: Array<AffiliatesToggleCard>;
  createCollectionButton: AffiliatesButton;
  createCollectionForm: AffiliatesCollectionForm;
  currentPicksCard: AffiliatesToggleCard;
  dismissButton: AffiliatesButton;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  saveButton: AffiliatesButton;
  title: Scalars["String"]["output"];
};

export type AffiliatesInitiateCollectionItemFailureResponse = {
  __typename?: "AffiliatesInitiateCollectionItemFailureResponse";
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesInitiateCollectionItemRequestInput {
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
}

export type AffiliatesInitiateCollectionItemResponse =
  | AffiliatesInitiateCollectionItemFailureResponse
  | AffiliatesInitiateCollectionItemSuccessResponse;

export type AffiliatesInitiateCollectionItemSuccessResponse = {
  __typename?: "AffiliatesInitiateCollectionItemSuccessResponse";
  createCollectionItemForm: AffiliatesCollectionItemForm;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesInlineLink = AffiliatesSpannableTextItem & {
  __typename?: "AffiliatesInlineLink";
  action: AffiliatesLinkAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  text: Scalars["String"]["output"];
  trailingIcons?: Maybe<Array<Icon>>;
};

export type AffiliatesInteractionAnalyticEvent = AffiliatesAnalyticEvent & {
  __typename?: "AffiliatesInteractionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export interface AffiliatesJourneyContextInput {
  inputDateRange: DateRangeInput;
}

export type AffiliatesLinkAccountAction = {
  __typename?: "AffiliatesLinkAccountAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesLinkAction =
  | AffiliatesCreateLinkAction
  | AffiliatesNavigateAction
  | AffiliatesOutwardLinkAction
  | AffiliatesViewCollectionAction
  | AffiliatesViewCollectionItemAction
  | AffiliatesViewStatsAction
  | AffiliatesViewTravelerCollectionAction;

export type AffiliatesLodgingCardCarouselItem = {
  __typename?: "AffiliatesLodgingCardCarouselItem";
  amenities?: Maybe<Array<AffiliatesIconText>>;
  cardLinkAction: AffiliatesLinkAction;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  overAllDescription?: Maybe<Scalars["String"]["output"]>;
  overAllRating?: Maybe<Scalars["String"]["output"]>;
  reviewsStat?: Maybe<Scalars["String"]["output"]>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesLodgingInfo = {
  __typename?: "AffiliatesLodgingInfo";
  propertyImage?: Maybe<Image>;
  propertyName: Scalars["String"]["output"];
};

export type AffiliatesLodgingStatsSuccessResponse = {
  __typename?: "AffiliatesLodgingStatsSuccessResponse";
  analyticsInfo?: Maybe<AffiliatesAnalyticsInfo>;
  closeAction: AffiliatesCloseAction;
  commissionInfo?: Maybe<AffiliatesCommissionInfo>;
  getLink: AffiliatesStandardLink;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  lodgingInfo: AffiliatesLodgingInfo;
};

export type AffiliatesLogo = {
  __typename?: "AffiliatesLogo";
  action: AffiliatesOutwardLinkAction;
  mark: Mark;
};

export type AffiliatesMenu = {
  __typename?: "AffiliatesMenu";
  dismissButton?: Maybe<AffiliatesButton>;
  items: Array<AffiliatesMenuItem>;
  selectedIcon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesMenuItem = {
  __typename?: "AffiliatesMenuItem";
  action: AffiliatesMenuItemAction;
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  text: AffiliatesIconText;
};

export type AffiliatesMenuItemAction =
  | AffiliatesPinCollectionItemToTopAction
  | AffiliatesPreviewCollectionAction
  | AffiliatesSetCollectionVisibilityAction
  | AffiliatesShowCollectionFormAction
  | AffiliatesShowCollectionItemFormAction
  | AffiliatesShowCollectionListForSaveAction
  | AffiliatesShowCollectionVisibilityInterstitialAction
  | AffiliatesShowConfirmationDialogAction;

export type AffiliatesMessagingCard = {
  __typename?: "AffiliatesMessagingCard";
  action: AffiliatesMessagingCardAction;
  card: EgdsStandardMessagingCard;
};

export type AffiliatesMessagingCardAction =
  | AffiliatesNavigateAction
  | AffiliatesOpenCreatorCollectionsAction
  | AffiliatesOpenCreatorShopAction
  | AffiliatesOutwardLinkAction;

export type AffiliatesMultiValidationFailureError = {
  __typename?: "AffiliatesMultiValidationFailureError";
  banner: UiBanner;
  validationFailureAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesNavigateAction = {
  __typename?: "AffiliatesNavigateAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  destination: Scalars["String"]["output"];
};

export type AffiliatesNavigationBar = {
  __typename?: "AffiliatesNavigationBar";
  leadingButton?: Maybe<AffiliatesButton>;
  navigationTitle?: Maybe<Scalars["String"]["output"]>;
  trailingButtons: Array<AffiliatesButton>;
};

export type AffiliatesNonEmptyFormValidationRule = AffiliatesFormValidationRule & {
  __typename?: "AffiliatesNonEmptyFormValidationRule";
  errorMessage: Scalars["String"]["output"];
  errorMessagePlacementId?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesOnloadAction = AffiliatesCopyLinkAction | AffiliatesShowToastAction;

export type AffiliatesOpenCloseToolbarDialogAction = {
  __typename?: "AffiliatesOpenCloseToolbarDialogAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenCreatorCollectionsAction = {
  __typename?: "AffiliatesOpenCreatorCollectionsAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenCreatorShopAction = {
  __typename?: "AffiliatesOpenCreatorShopAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  creatorHandle: Scalars["String"]["output"];
};

export type AffiliatesOpenMenuAction = {
  __typename?: "AffiliatesOpenMenuAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenNativeSettingsAction = {
  __typename?: "AffiliatesOpenNativeSettingsAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenPartnerDetailsAction = {
  __typename?: "AffiliatesOpenPartnerDetailsAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOpenToolboxAction = {
  __typename?: "AffiliatesOpenToolboxAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesOutwardLinkAction = {
  __typename?: "AffiliatesOutwardLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  openLinkInExternalView?: Maybe<Scalars["Boolean"]["output"]>;
  url: Scalars["String"]["output"];
};

export type AffiliatesPageInfo = {
  __typename?: "AffiliatesPageInfo";
  pageImage?: Maybe<Image>;
  pageName: Scalars["String"]["output"];
};

export type AffiliatesPagination = {
  __typename?: "AffiliatesPagination";
  label?: Maybe<Scalars["String"]["output"]>;
  labelEndPlaceHolder?: Maybe<Scalars["String"]["output"]>;
  labelStartPlaceHolder?: Maybe<Scalars["String"]["output"]>;
  nextButton: AffiliatesPagingButton;
  pageSize: Scalars["Int"]["output"];
  prevButton: AffiliatesPagingButton;
};

export type AffiliatesPagingAction =
  | AffiliatesCarouselNextAction
  | AffiliatesCarouselPrevAction
  | AffiliatesPagingNextAction
  | AffiliatesPagingPrevAction;

export type AffiliatesPagingButton = {
  __typename?: "AffiliatesPagingButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: AffiliatesPagingAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  iconId: Scalars["String"]["output"];
  iconTitle: Scalars["String"]["output"];
};

export type AffiliatesPagingNextAction = {
  __typename?: "AffiliatesPagingNextAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesPagingPrevAction = {
  __typename?: "AffiliatesPagingPrevAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesPartnerContextInput {
  token?: InputMaybe<Scalars["String"]["input"]>;
}

export type AffiliatesPartnerDetails = {
  __typename?: "AffiliatesPartnerDetails";
  account: AffiliatesStandardLink;
  closeToolbarDialog: AffiliatesCloseToolbarDialog;
  email: Scalars["String"]["output"];
  help: AffiliatesStandardLink;
  linkHistory: AffiliatesStandardLink;
  name: Scalars["String"]["output"];
  openCloseToolbarDialogButton: AffiliatesButton;
};

export type AffiliatesPinCollectionItemToTopAction = {
  __typename?: "AffiliatesPinCollectionItemToTopAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesPreviewCollectionAction = {
  __typename?: "AffiliatesPreviewCollectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
};

export type AffiliatesPreviewCollectionFailureResponse = {
  __typename?: "AffiliatesPreviewCollectionFailureResponse";
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesPreviewCollectionRequestInput {
  collectionId: Scalars["String"]["input"];
}

export type AffiliatesPreviewCollectionResponse =
  | AffiliatesPreviewCollectionFailureResponse
  | AffiliatesPreviewCollectionSuccessResponse;

export type AffiliatesPreviewCollectionSuccessResponse = {
  __typename?: "AffiliatesPreviewCollectionSuccessResponse";
  closeAction: AffiliatesCloseAction;
  collectionDetails: AffiliatesTravelerCollection;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  shopDetails: AffiliatesCreatorShop;
};

export interface AffiliatesPropertyContextInput {
  egPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  hotelsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
}

export type AffiliatesRatings = {
  __typename?: "AffiliatesRatings";
  categories: Array<AffiliatesContent>;
  grades: Array<EgdsProgressBar>;
  overAllDescription: Scalars["String"]["output"];
  overAllRating: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AffiliatesRemoveCollectionFromListAction = {
  __typename?: "AffiliatesRemoveCollectionFromListAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
};

export type AffiliatesRemoveCollectionItemFromRemovalListAction = {
  __typename?: "AffiliatesRemoveCollectionItemFromRemovalListAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
  itemId: Scalars["String"]["output"];
};

export type AffiliatesRemoveTripItemAction = {
  __typename?: "AffiliatesRemoveTripItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesReviews = {
  __typename?: "AffiliatesReviews";
  highlightedReviews?: Maybe<AffiliatesHighlightedReviews>;
  ratings?: Maybe<AffiliatesRatings>;
};

export type AffiliatesSaveAction = {
  __typename?: "AffiliatesSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSaveTripItemAction = {
  __typename?: "AffiliatesSaveTripItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSelectVideoAction = {
  __typename?: "AffiliatesSelectVideoAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSetCollectionVisibilityAction = {
  __typename?: "AffiliatesSetCollectionVisibilityAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  visibilityType: Scalars["String"]["output"];
};

export type AffiliatesShareLinkAction = {
  __typename?: "AffiliatesShareLinkAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  toast: EgdsToast;
  url: Scalars["String"]["output"];
};

export type AffiliatesShareToBlog = {
  __typename?: "AffiliatesShareToBlog";
  buttons: Array<AffiliatesShareToBlogButton>;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AffiliatesShareToBlogButton = {
  __typename?: "AffiliatesShareToBlogButton";
  action: AffiliatesOutwardLinkAction;
  mark: Mark;
};

export type AffiliatesShowCollectionFormAction = {
  __typename?: "AffiliatesShowCollectionFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowCollectionItemFormAction = {
  __typename?: "AffiliatesShowCollectionItemFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowCollectionListForSaveAction = {
  __typename?: "AffiliatesShowCollectionListForSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  itemDescription: Scalars["String"]["output"];
  itemName: Scalars["String"]["output"];
  productInfo: AffiliatesCollectionItemProductInfo;
};

export type AffiliatesShowCollectionVisibilityInterstitialAction = {
  __typename?: "AffiliatesShowCollectionVisibilityInterstitialAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowConfirmationDialogAction = {
  __typename?: "AffiliatesShowConfirmationDialogAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowCreateTagFormAction = {
  __typename?: "AffiliatesShowCreateTagFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowDescriptiveLinkFormAction = {
  __typename?: "AffiliatesShowDescriptiveLinkFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<EgdsToast>;
};

export type AffiliatesShowDialogAction = {
  __typename?: "AffiliatesShowDialogAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowDownloadImagesGalleryFormAction = {
  __typename?: "AffiliatesShowDownloadImagesGalleryFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowHighlightedReviewsInfoAction = {
  __typename?: "AffiliatesShowHighlightedReviewsInfoAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesShowInitiateAddOrRemoveCollectionItemAction = {
  __typename?: "AffiliatesShowInitiateAddOrRemoveCollectionItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  productInfo: AffiliatesCollectionItemProductInfo;
};

export type AffiliatesShowToastAction = {
  __typename?: "AffiliatesShowToastAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  toast: EgdsToast;
};

export type AffiliatesShowVanityLinkFormAction = {
  __typename?: "AffiliatesShowVanityLinkFormAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSignInFirstAction = {
  __typename?: "AffiliatesSignInFirstAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSocialPlatformLink = {
  __typename?: "AffiliatesSocialPlatformLink";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  action: AffiliatesOutwardLinkAction;
  mark: Mark;
};

export type AffiliatesSpannableExpandoText = {
  __typename?: "AffiliatesSpannableExpandoText";
  collapseAction: AffiliatesExpandoAction;
  expandAction: AffiliatesExpandoAction;
  expandedText: Array<AffiliatesSpannableText>;
  textControl: EgdsExpandoPeek;
};

export type AffiliatesSpannableText = {
  __typename?: "AffiliatesSpannableText";
  inlineContent: Array<AffiliatesSpannableTextItem>;
};

export interface AffiliatesSpannableTextItem {
  text: Scalars["String"]["output"];
}

export type AffiliatesStandardLink = {
  __typename?: "AffiliatesStandardLink";
  action: AffiliatesLinkAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  subtext?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type AffiliatesState = {
  __typename?: "AffiliatesState";
  token: Scalars["String"]["output"];
};

export type AffiliatesStateCheckFailureResponse = {
  __typename?: "AffiliatesStateCheckFailureResponse";
  affiliatesCheckFailure?: Maybe<AffiliatesCheckFailure>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesStateCheckResponse = AffiliatesStateCheckFailureResponse | AffiliatesStateCheckSuccessResponse;

export type AffiliatesStateCheckSuccessResponse = {
  __typename?: "AffiliatesStateCheckSuccessResponse";
  affiliatesState: AffiliatesState;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesStatsFailureResponse = {
  __typename?: "AffiliatesStatsFailureResponse";
  banner: UiBanner;
  closeAction: AffiliatesCloseAction;
  getLink: AffiliatesStandardLink;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesStatsRequestInput {
  propertyContext?: InputMaybe<AffiliatesPropertyContextInput>;
}

export type AffiliatesStatsResponse = AffiliatesLodgingStatsSuccessResponse | AffiliatesStatsFailureResponse;

export type AffiliatesStringToDialogMap = {
  __typename?: "AffiliatesStringToDialogMap";
  key: Scalars["String"]["output"];
  value: AffiliatesDialog;
};

export type AffiliatesStripeFailureResponse = {
  __typename?: "AffiliatesStripeFailureResponse";
  affiliatesCheckFailure?: Maybe<AffiliatesCheckFailure>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export type AffiliatesStripeResponse = AffiliatesStripeFailureResponse | AffiliatesStripeSuccessResponse;

export type AffiliatesStripeSuccessResponse = {
  __typename?: "AffiliatesStripeSuccessResponse";
  addToCollectionButton?: Maybe<AffiliatesButton>;
  addToCollectionCoachMark?: Maybe<AffiliatesCoachMark>;
  addTravelVideoButton?: Maybe<AffiliatesButton>;
  addTravelVideoCoachMark?: Maybe<AffiliatesCoachMark>;
  getLinkButton: AffiliatesButton;
  getLinkCoachMark: AffiliatesCoachMark;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  logo: AffiliatesLogo;
  openPartnerDetailsButton: AffiliatesButton;
  partnerDetails: AffiliatesPartnerDetails;
  statsButton: AffiliatesButton;
  statsCoachMark: AffiliatesCoachMark;
  toolboxButton: AffiliatesButton;
  toolboxCoachMark: AffiliatesCoachMark;
};

export type AffiliatesSubmitAction = {
  __typename?: "AffiliatesSubmitAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesSubmitVideoFailureResponse = {
  __typename?: "AffiliatesSubmitVideoFailureResponse";
  errorBanner?: Maybe<UiBanner>;
  fieldErrors: Array<AffiliatesFieldError>;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesSubmitVideoRequestInput {
  consentForTravelVideoProgram?: InputMaybe<Scalars["Boolean"]["input"]>;
  externalVideoUrl?: InputMaybe<Scalars["String"]["input"]>;
  productId: Scalars["String"]["input"];
  uploadReference: Scalars["String"]["input"];
}

export type AffiliatesSubmitVideoResponse = AffiliatesSubmitVideoFailureResponse | AffiliatesSubmitVideoSuccessResponse;

export type AffiliatesSubmitVideoScreen = {
  __typename?: "AffiliatesSubmitVideoScreen";
  contentGuidelines: AffiliatesVideoContentGuidelinesComponent;
  discardDialog: AffiliatesDialog;
  errorBanner: UiBanner;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  navigationBar: AffiliatesNavigationBar;
  submitButton: AffiliatesButton;
};

export type AffiliatesSubmitVideoSuccessResponse = {
  __typename?: "AffiliatesSubmitVideoSuccessResponse";
  continueButton: AffiliatesButton;
  heading: AffiliatesSpannableText;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  navigationBar: AffiliatesNavigationBar;
  socialMediaThumbnail?: Maybe<Image>;
  videoIdentifier: AffiliatesSpannableText;
  videoStatus: UiBanner;
  viewVideosLink: AffiliatesInlineLink;
};

export type AffiliatesSwitch = {
  __typename?: "AffiliatesSwitch";
  offAction: AffiliatesSwitchAction;
  onAction: AffiliatesSwitchAction;
  switch: EgdsStandardSwitch;
};

export type AffiliatesSwitchAction =
  | AffiliatesAddCollectionItemToRemovalListAction
  | AffiliatesAddCollectionToListAction
  | AffiliatesRemoveCollectionFromListAction
  | AffiliatesRemoveCollectionItemFromRemovalListAction
  | AffiliatesShowCreateTagFormAction
  | AffiliatesShowDescriptiveLinkFormAction
  | AffiliatesShowVanityLinkFormAction;

export type AffiliatesText = AffiliatesSpannableTextItem & {
  __typename?: "AffiliatesText";
  text: Scalars["String"]["output"];
};

export type AffiliatesTextAreaField = {
  __typename?: "AffiliatesTextAreaField";
  input: EgdsTextAreaInputField;
  selectionAction: AffiliatesTextAreaFieldSelectionAction;
  showCharacterCount?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AffiliatesTextAreaFieldSelectionAction = {
  __typename?: "AffiliatesTextAreaFieldSelectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesTextFieldSelectionAction = {
  __typename?: "AffiliatesTextFieldSelectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesTextFieldsValidationResponse =
  | AffiliatesValidateTextFieldsFailureResponse
  | AffiliatesValidateTextFieldsSuccessResponse;

export type AffiliatesTextInputField = {
  __typename?: "AffiliatesTextInputField";
  handle: EgdsTextInputField;
  heading?: Maybe<Scalars["String"]["output"]>;
  selectionAction: AffiliatesTextFieldSelectionAction;
};

export type AffiliatesToast = {
  __typename?: "AffiliatesToast";
  button?: Maybe<AffiliatesButton>;
  toast: EgdsToast;
};

export type AffiliatesToggleCard = {
  __typename?: "AffiliatesToggleCard";
  card: EgdsImageCard;
  switch: AffiliatesIconSwitch;
};

export type AffiliatesTransitionButton = {
  __typename?: "AffiliatesTransitionButton";
  defaultButton: AffiliatesButton;
  transitionButton: AffiliatesButton;
  transitionInSecs: Scalars["Int"]["output"];
  transitionOnRender?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AffiliatesTravelerCollection = {
  __typename?: "AffiliatesTravelerCollection";
  collectionHandle: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  descriptionImage?: Maybe<Image>;
  items: AffiliatesCarousel;
  legalEngagementText?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  shareButton: AffiliatesButton;
  splashScreenAnnounceAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  splashScreenAutoDismissAfterMilliSecs: Scalars["Int"]["output"];
  splashScreenImage?: Maybe<Image>;
  summary: Scalars["String"]["output"];
};

export type AffiliatesTravelerCollectionCard = {
  __typename?: "AffiliatesTravelerCollectionCard";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  card: EgdsImageCard;
  cardLinkAction: AffiliatesLinkAction;
  collectionHandle: Scalars["String"]["output"];
  itemCount: AffiliatesIconText;
};

export type AffiliatesTravelerCollectionDetailsFailureResponse = {
  __typename?: "AffiliatesTravelerCollectionDetailsFailureResponse";
  banner: UiBanner;
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesTravelerCollectionDetailsRequestInput {
  collectionHandle: Scalars["String"]["input"];
  creatorHandle: Scalars["String"]["input"];
}

export type AffiliatesTravelerCollectionDetailsResponse =
  | AffiliatesTravelerCollectionDetailsFailureResponse
  | AffiliatesTravelerCollectionDetailsSuccessResponse;

export type AffiliatesTravelerCollectionDetailsSuccessResponse = {
  __typename?: "AffiliatesTravelerCollectionDetailsSuccessResponse";
  closeAction: AffiliatesCloseAction;
  collectionDetails: AffiliatesTravelerCollection;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  shopDetails: AffiliatesCreatorShop;
};

export type AffiliatesTravelerCollectionItem = {
  __typename?: "AffiliatesTravelerCollectionItem";
  bookNowButton: AffiliatesButton;
  /** @deprecated Use descriptionContent instead, replace with descriptionContent */
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionContent?: Maybe<AffiliatesExpandoText>;
  /** @deprecated Use descriptionContent instead, replace with descriptionContent */
  descriptionControl?: Maybe<EgdsExpandoPeek>;
  dismissButton: AffiliatesButton;
  image?: Maybe<Image>;
  itemId: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  positionLabel?: Maybe<Scalars["String"]["output"]>;
  productInfo?: Maybe<AffiliatesTravelerCollectionItemProductInfo>;
  shareButton: AffiliatesButton;
  tripsSaveItem?: Maybe<AffiliatesTripsSaveItem>;
  visuallyHiddenCollectionControlHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  visuallyHiddenPropertyControlsHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  visuallyHiddenPropertyDescriptionHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  visuallyHiddenSummaryHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesTravelerCollectionItemLodgingInfo = {
  __typename?: "AffiliatesTravelerCollectionItemLodgingInfo";
  city?: Maybe<Scalars["String"]["output"]>;
  priceSummary?: Maybe<PropertyPrice>;
  rating?: Maybe<AffiliatesBadge>;
  ratingDescription?: Maybe<Scalars["String"]["output"]>;
  visuallyHiddenPropertyDetailsHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesTravelerCollectionItemProductInfo = AffiliatesTravelerCollectionItemLodgingInfo;

export type AffiliatesTravelerCollectionsSection = {
  __typename?: "AffiliatesTravelerCollectionsSection";
  collections: Array<AffiliatesTravelerCollectionCard>;
  title: Scalars["String"]["output"];
  titleHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesTravelerShopDetailsFailureResponse = {
  __typename?: "AffiliatesTravelerShopDetailsFailureResponse";
  banner: UiBanner;
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
};

export interface AffiliatesTravelerShopDetailsRequestInput {
  creatorHandle: Scalars["String"]["input"];
}

export type AffiliatesTravelerShopDetailsResponse =
  | AffiliatesTravelerShopDetailsFailureResponse
  | AffiliatesTravelerShopDetailsSuccessResponse;

export type AffiliatesTravelerShopDetailsSuccessResponse = {
  __typename?: "AffiliatesTravelerShopDetailsSuccessResponse";
  collectionsSection: AffiliatesTravelerCollectionsSection;
  currentPicks?: Maybe<AffiliatesCarousel>;
  dismissButton: AffiliatesButton;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  shareButton: AffiliatesButton;
  shopDetails: AffiliatesCreatorShop;
  visuallyHiddenSummaryHeadingAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesTripsSaveItem = {
  __typename?: "AffiliatesTripsSaveItem";
  removeTripItemAction: AffiliatesTripsSaveItemAction;
  saveTripItemAction: AffiliatesTripsSaveItemAction;
  tripsSaveItem: TripsSaveItem;
};

export type AffiliatesTripsSaveItemAction = AffiliatesRemoveTripItemAction | AffiliatesSaveTripItemAction;

export type AffiliatesUpdateCollectionVisibilityFailureResponse = {
  __typename?: "AffiliatesUpdateCollectionVisibilityFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
};

export interface AffiliatesUpdateCollectionVisibilityRequestInput {
  collectionId: Scalars["String"]["input"];
  visibilityType: Scalars["String"]["input"];
}

export type AffiliatesUpdateCollectionVisibilityResponse =
  | AffiliatesUpdateCollectionVisibilityFailureResponse
  | AffiliatesUpdateCollectionVisibilitySuccessResponse;

export type AffiliatesUpdateCollectionVisibilitySuccessResponse = {
  __typename?: "AffiliatesUpdateCollectionVisibilitySuccessResponse";
  impressionAnalytics: Array<AffiliatesAnalyticEvent>;
  toast?: Maybe<AffiliatesToast>;
};

export interface AffiliatesValidateTextFieldRequestInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
}

export type AffiliatesValidateTextFieldsFailureResponse = {
  __typename?: "AffiliatesValidateTextFieldsFailureResponse";
  formError: AffiliatesFormError;
  impressionAnalytics?: Maybe<Array<AffiliatesImpressionAnalyticEvent>>;
};

export type AffiliatesValidateTextFieldsSuccessResponse = {
  __typename?: "AffiliatesValidateTextFieldsSuccessResponse";
  impressionAnalytics?: Maybe<Array<AffiliatesImpressionAnalyticEvent>>;
};

export type AffiliatesVanityLinkForm = {
  __typename?: "AffiliatesVanityLinkForm";
  cancelButton: AffiliatesButton;
  pageHandle: AffiliatesTextInputField;
  saveButton: AffiliatesButton;
  serviceFailureError?: Maybe<Scalars["String"]["output"]>;
};

export type AffiliatesVanityLinkSaveAction = {
  __typename?: "AffiliatesVanityLinkSaveAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  statusMessage: AffiliatesIconText;
};

export type AffiliatesVideoAspectRatioValidation = {
  __typename?: "AffiliatesVideoAspectRatioValidation";
  aspectRatio: Scalars["Float"]["output"];
  banner: UiBanner;
  validationFailureAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesVideoContentGuidelinesComponent = {
  __typename?: "AffiliatesVideoContentGuidelinesComponent";
  footer: Array<AffiliatesSpannableText>;
  guidelines: Array<AffiliatesSpannableExpandoText>;
};

export type AffiliatesVideoDimensionsValidation = {
  __typename?: "AffiliatesVideoDimensionsValidation";
  banner: UiBanner;
  dimensions: Dimensions;
  validationFailureAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesVideoLengthValidation = {
  __typename?: "AffiliatesVideoLengthValidation";
  banner: UiBanner;
  maxLengthInSeconds: Scalars["Int"]["output"];
  minLengthInSeconds: Scalars["Int"]["output"];
  validationFailureAnalytics: Array<AffiliatesAnalyticEvent>;
};

export type AffiliatesVideoResponse = {
  __typename?: "AffiliatesVideoResponse";
  affiliatesVideoUploadForm: AffiliatesVideoUploadResponse;
};

export type AffiliatesVideoResponseAffiliatesVideoUploadFormArgs = {
  request: AffiliatesVideoUploadRequestInput;
};

export type AffiliatesVideoUploadFailureResponse = {
  __typename?: "AffiliatesVideoUploadFailureResponse";
  errorView: AffiliatesErrorView;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  navigationBar: AffiliatesNavigationBar;
};

export type AffiliatesVideoUploadHomeScreen = {
  __typename?: "AffiliatesVideoUploadHomeScreen";
  contentGuidelines: AffiliatesVideoContentGuidelinesComponent;
  description: Array<AffiliatesSpannableText>;
  fileValidations: Array<AffiliatesFileUploadValidation>;
  heading: AffiliatesSpannableText;
  multiValidationFailureError: AffiliatesMultiValidationFailureError;
  navigationBar: AffiliatesNavigationBar;
  permissionDeniedDialog: AffiliatesDialog;
  productImage: Image;
  selectVideoButton: AffiliatesButton;
  videoRequirementsButton: AffiliatesButton;
  videoRequirementsDialog: AffiliatesDialog;
};

export interface AffiliatesVideoUploadRequestInput {
  productId: Scalars["String"]["input"];
}

export type AffiliatesVideoUploadResponse = AffiliatesVideoUploadFailureResponse | AffiliatesVideoUploadSuccessResponse;

export type AffiliatesVideoUploadSuccessResponse = {
  __typename?: "AffiliatesVideoUploadSuccessResponse";
  homeScreen: AffiliatesVideoUploadHomeScreen;
  impressionAnalytics: Array<AffiliatesImpressionAnalyticEvent>;
  submitVideoScreen: AffiliatesSubmitVideoScreen;
  uploadingScreen: AffiliatesVideoUploadingScreen;
};

export type AffiliatesVideoUploadingScreen = {
  __typename?: "AffiliatesVideoUploadingScreen";
  cancelUploadDialog: AffiliatesDialog;
  description: Array<AffiliatesSpannableText>;
  heading: AffiliatesSpannableText;
  navigationBar: AffiliatesNavigationBar;
};

export type AffiliatesViewCollectionAction = {
  __typename?: "AffiliatesViewCollectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  collectionId: Scalars["String"]["output"];
  /** @deprecated This field is deprecated and will be removed in the future */
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesViewCollectionItemAction = {
  __typename?: "AffiliatesViewCollectionItemAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesViewStatsAction = {
  __typename?: "AffiliatesViewStatsAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type AffiliatesViewTravelerCollectionAction = {
  __typename?: "AffiliatesViewTravelerCollectionAction";
  analytics: Array<AffiliatesAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
  webTargetUrl?: Maybe<Scalars["String"]["output"]>;
};

export type AffirmContentDetails = {
  __typename?: "AffirmContentDetails";
  elementAttributes: Array<InstallmentPlanAttribute>;
  link: InstallmentPlanLink;
  logo: UiGraphic;
  text: EgdsStylizedText;
};

export type AffirmDetails = {
  __typename?: "AffirmDetails";
  analytics: ClientSideAnalytics;
  elementAttributes: Array<InstallmentPlanAttribute>;
};

export type AffirmInstallmentPlanDetails = {
  __typename?: "AffirmInstallmentPlanDetails";
  apiKey: Scalars["String"]["output"];
  elementAttributes: Array<InstallmentPlanAttribute>;
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  scriptUrl: Scalars["String"]["output"];
};

export type AffirmPaymentMethodAttribute = {
  __typename?: "AffirmPaymentMethodAttribute";
  providerConfirmationStatus?: Maybe<Scalars["String"]["output"]>;
  publicApiKey?: Maybe<Scalars["String"]["output"]>;
  script?: Maybe<Scalars["String"]["output"]>;
};

export type AgencyBusinessModel = "EXPEDIA_COLLECT" | "HOTEL_COLLECT";

export type AgencyDetail = {
  __typename?: "AgencyDetail";
  agencyEntryFields: Array<EgdsInputField>;
  logo: AgencyImage;
};

export type AgencyImage = {
  __typename?: "AgencyImage";
  altText?: Maybe<Scalars["String"]["output"]>;
  logoToggle: UiToggle;
  url?: Maybe<Uri>;
};

export type AgencyLogo = {
  __typename?: "AgencyLogo";
  altText?: Maybe<Scalars["String"]["output"]>;
  logoToggle: UiToggle;
  url?: Maybe<Uri>;
};

export type AgencyPropertyUnitKey = {
  __typename?: "AgencyPropertyUnitKey";
  lodgingType: Scalars["String"]["output"];
  packageRates?: Maybe<Scalars["Boolean"]["output"]>;
  propertyDestination: Scalars["String"]["output"];
  propertyNaturalKey: PropertyNaturalKey;
};

export type AgencyQuoteAction = UiAction & {
  __typename?: "AgencyQuoteAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionIdentifier: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type AgencyQuoteBuilderClear = {
  __typename?: "AgencyQuoteBuilderClear";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  clearButton: UiSecondaryButton;
};

export type AgencyQuoteBuilderCollapsedButton = {
  __typename?: "AgencyQuoteBuilderCollapsedButton";
  /** @deprecated Floating button for a collapsed side QuoteBuilder */
  collapsedButton?: Maybe<UiPrimaryFloatingActionButton>;
  expandAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  floatingButton: UiSecondaryFloatingActionButton;
};

export type AgencyQuoteBuilderExpandLink = {
  __typename?: "AgencyQuoteBuilderExpandLink";
  collapseAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  collapsedLabel?: Maybe<Scalars["String"]["output"]>;
  expandAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  expandedLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Simplified to just label and analytics without EGDSExpandoLink class */
  expandoLink: EgdsExpandoLink;
  /** @deprecated Replacing with toolbarNavType to be string */
  navType: ToolbarNavType;
  toolbarNavType: Scalars["String"]["output"];
};

export type AgencyQuoteBuilderGenerateQuote = {
  __typename?: "AgencyQuoteBuilderGenerateQuote";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  generateQuoteButton: UiPrimaryButton;
};

export type AgencyQuoteBuilderHeader = {
  __typename?: "AgencyQuoteBuilderHeader";
  /** @deprecated Replaced by clearClickstream */
  clear: UiSecondaryButton;
  clearClickstream: AgencyQuoteBuilderClear;
  /** @deprecated Replaced by expandLinkClickstream */
  expandLink: EgdsExpandoLink;
  expandLinkClickstream: AgencyQuoteBuilderExpandLink;
  /** @deprecated Replaced by generateQuoteClickstream */
  generateQuote: UiPrimaryButton;
  generateQuoteClickstream: AgencyQuoteBuilderGenerateQuote;
  quoteTitle: Scalars["String"]["output"];
};

export type AgencyQuoteBuilderHeaderContent = {
  __typename?: "AgencyQuoteBuilderHeaderContent";
  clear?: Maybe<AgencyQuoteBuilderClear>;
  generateQuote?: Maybe<AgencyQuoteBuilderGenerateQuote>;
  quoteTitle: Scalars["String"]["output"];
  toolbarAction?: Maybe<AgencyQuoteBuilderExpandLink>;
};

export interface AgencyQuoteBuilderPropertyRateCardInput {
  businessModelType?: InputMaybe<BusinessModelType>;
  checkIn: DateInput;
  checkOut: DateInput;
  inventoryType: InventoryType;
  packageRate: Scalars["Boolean"]["input"];
  pricePerNight: Scalars["String"]["input"];
  priceTotal: Scalars["String"]["input"];
  propertyDestination: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
  propertyName: Scalars["String"]["input"];
  propertyType: Scalars["String"]["input"];
  ratePlanId: Scalars["String"]["input"];
  refundabilityType?: InputMaybe<RefundableType>;
  roomTypeDescription: Scalars["String"]["input"];
  roomTypeId: Scalars["String"]["input"];
  rooms: Array<PropertyRoomInput>;
  starRating?: InputMaybe<Scalars["Float"]["input"]>;
}

export type AgencyQuoteBuilderRateCard = {
  __typename?: "AgencyQuoteBuilderRateCard";
  dates: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  packageRate: Scalars["Boolean"]["output"];
  packageRateLabel?: Maybe<Scalars["String"]["output"]>;
  price: Array<Scalars["String"]["output"]>;
  rating?: Maybe<EgdsRating>;
  /** @deprecated Replaced by removeClickstream to use EG Clickstream events instead of UIS Prime */
  remove: UiTertiaryButton;
  removeClickstream: AgencyQuoteBuilderRemove;
  summary: Array<Scalars["String"]["output"]>;
};

export interface AgencyQuoteBuilderRateCardInput {
  propertyInput: AgencyQuoteBuilderPropertyRateCardInput;
}

export type AgencyQuoteBuilderRemove = {
  __typename?: "AgencyQuoteBuilderRemove";
  analytics: Scalars["AnalyticsPayload"]["output"];
  removeButton: UiTertiaryButton;
};

export interface AgencyQuoteBuilderRemoveInput {
  all: Scalars["Boolean"]["input"];
  index?: InputMaybe<Scalars["Int"]["input"]>;
}

export type AgencyQuoteBuilderResponse = {
  __typename?: "AgencyQuoteBuilderResponse";
  announceMessage: Scalars["String"]["output"];
  collapsedButton?: Maybe<AgencyQuoteBuilderCollapsedButton>;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Replacing with sheetDisplayType to be string */
  displayType: QuoteBuilderType;
  /** @deprecated Replaced by headerContent */
  header: AgencyQuoteBuilderHeader;
  headerContent?: Maybe<AgencyQuoteBuilderHeaderContent>;
  notification?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Needed to remove the timer */
  quoteBuilderAutoCollapseTimeout?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Moved into sheetContent */
  rateCards: Array<AgencyQuoteBuilderRateCard>;
  sheetContent?: Maybe<AgencyQuoteBuilderSheetContent>;
  /** @deprecated Sheet value is not needed anymore */
  sheetDisplayType: Scalars["String"]["output"];
};

export type AgencyQuoteBuilderSheetContent = {
  __typename?: "AgencyQuoteBuilderSheetContent";
  clearAll?: Maybe<AgencyQuoteBuilderClear>;
  generateQuote?: Maybe<AgencyQuoteBuilderGenerateQuote>;
  rateCards: Array<AgencyQuoteBuilderRateCard>;
};

export interface AgencyQuoteDeleteInput {
  quoteIds: Array<Scalars["String"]["input"]>;
}

export type AgencyQuoteDeleteStatus = {
  __typename?: "AgencyQuoteDeleteStatus";
  deletedCount: Scalars["Int"]["output"];
  isSuccessful: Scalars["Boolean"]["output"];
};

export interface AgencyQuoteDeleteStatusInput {
  deletedCount: Scalars["Int"]["input"];
  isSuccessful: Scalars["Boolean"]["input"];
}

export interface AgencyQuoteDetails {
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
}

export type AgencyQuoteDetailsFailure = AgencyQuoteDetails & {
  __typename?: "AgencyQuoteDetailsFailure";
  /** @deprecated Use notification placard in place of banner.  Banner will be removed in a future release. */
  banner: UiBanner;
  notificationPlacard: AgencyQuoteNotificationPlacard;
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
};

export type AgencyQuoteDetailsSuccess = AgencyQuoteDetails & {
  __typename?: "AgencyQuoteDetailsSuccess";
  actionButtons: Array<UiPrimaryButton>;
  agencyQuoteIdentityInfo: AgencyQuoteIdentityInfo;
  agencySiteInfo: AgencyDetail;
  agentMessage: AgentComment;
  /** @deprecated Use notification placard in place of banner.  Banner will be removed in a future release. */
  banner?: Maybe<UiBanner>;
  messages?: Maybe<Array<EgdsText>>;
  notificationPlacard?: Maybe<AgencyQuoteNotificationPlacard>;
  pageTitle: EgdsHeading;
  /** @deprecated This link is moved to PropertyUnitCard and should be consumed from there. */
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
  /** @deprecated Use propertyUnitCards in place of propertyRoomCards. */
  propertyRoomCards: Array<PropertyUnitCard>;
  propertyUnitCards: Array<UnitCard>;
  quoteRegulatoryMessages?: Maybe<Array<EgdsText>>;
  /** @deprecated use Trip Summary in PropertyUnitCard */
  tripSummary: EgdsTextSection;
};

export type AgencyQuoteIdentityInfo = {
  __typename?: "AgencyQuoteIdentityInfo";
  identityEntryFields: Array<EgdsInputField>;
  messages?: Maybe<Array<EgdsText>>;
};

export type AgencyQuoteInputField = EgdsInputField & {
  __typename?: "AgencyQuoteInputField";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AgencyQuoteListContent = {
  __typename?: "AgencyQuoteListContent";
  header: AgencyQuoteListHeader;
  quotes?: Maybe<Array<AgencyQuoteListQuote>>;
};

export type AgencyQuoteListElement = {
  __typename?: "AgencyQuoteListElement";
  element: Element;
};

export type AgencyQuoteListHeader = {
  __typename?: "AgencyQuoteListHeader";
  contents?: Maybe<Array<AgencyQuoteListHeaderContent>>;
};

export type AgencyQuoteListHeaderContent = {
  __typename?: "AgencyQuoteListHeaderContent";
  contentId?: Maybe<Scalars["String"]["output"]>;
  element?: Maybe<AgencyQuoteListElement>;
  sortType?: Maybe<AgencyQuoteListSortType>;
};

export interface AgencyQuoteListInput {
  deleteStatus?: InputMaybe<AgencyQuoteDeleteStatusInput>;
  paginationIndex?: InputMaybe<Scalars["Int"]["input"]>;
  searchCriteria?: InputMaybe<Scalars["String"]["input"]>;
  selectedTab?: InputMaybe<Scalars["String"]["input"]>;
  sortContentId?: InputMaybe<Scalars["String"]["input"]>;
  sortType?: InputMaybe<AgencyQuoteListSortType>;
}

export type AgencyQuoteListPagination = {
  __typename?: "AgencyQuoteListPagination";
  nextButton?: Maybe<EgdsButton>;
  nextIndex?: Maybe<Scalars["Int"]["output"]>;
  paginationText?: Maybe<Scalars["String"]["output"]>;
  previousButton?: Maybe<EgdsButton>;
  previousIndex?: Maybe<Scalars["Int"]["output"]>;
};

export type AgencyQuoteListQuote = {
  __typename?: "AgencyQuoteListQuote";
  contents?: Maybe<Array<AgencyQuoteListQuoteContent>>;
  quoteId: Scalars["String"]["output"];
};

export type AgencyQuoteListQuoteContent = {
  __typename?: "AgencyQuoteListQuoteContent";
  element?: Maybe<AgencyQuoteListElement>;
};

export type AgencyQuoteListResponse = {
  __typename?: "AgencyQuoteListResponse";
  confirmationDialog?: Maybe<ConfirmationDialog>;
  deleteButton?: Maybe<EgdsButton>;
  deleteToast?: Maybe<EgdsToast>;
  message?: Maybe<Scalars["String"]["output"]>;
  notificationPlacard?: Maybe<AgencyQuoteNotificationPlacard>;
  pageTitle: Scalars["String"]["output"];
  pagination?: Maybe<AgencyQuoteListPagination>;
  quoteContent?: Maybe<AgencyQuoteListContent>;
  searchSection?: Maybe<AgencyQuoteListSearchSection>;
  tabs?: Maybe<EgdsTabs>;
};

export type AgencyQuoteListSearchSection = {
  __typename?: "AgencyQuoteListSearchSection";
  searchBox: EgdsInputField;
  searchButton: EgdsButton;
  searchPills?: Maybe<Array<EgdsPill>>;
};

export type AgencyQuoteListSortType = "ASCENDING" | "DESCENDING" | "NOT_SORTED";

export type AgencyQuoteListTooltip = {
  __typename?: "AgencyQuoteListTooltip";
  text: Scalars["String"]["output"];
  tooltip: PopoverSheet;
};

export interface AgencyQuoteNaturalKeyInput {
  lodgingType: Scalars["String"]["input"];
  packageRates?: InputMaybe<Scalars["Boolean"]["input"]>;
  propertyDestination: Scalars["String"]["input"];
  propertyNaturalKeys: Array<PropertyNaturalKeyInput>;
}

export type AgencyQuoteNotificationPlacard = {
  __typename?: "AgencyQuoteNotificationPlacard";
  details: UiBanner;
  displayType: BannerDisplayType;
};

export interface AgencyQuoteSaveAgencyDetailInput {
  address1?: InputMaybe<Scalars["String"]["input"]>;
  address2?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
}

export interface AgencyQuoteSaveCardInput {
  agencyServiceCharge?: InputMaybe<AgencyServiceChargeInput>;
  destination: Scalars["String"]["input"];
  hideCancellationState: Scalars["Boolean"]["input"];
  hidePriceState: Scalars["Boolean"]["input"];
  lob: ExpLineOfBusiness;
  naturalKey: AgencyQuoteNaturalKeyInput;
  totalPrice?: InputMaybe<Scalars["String"]["input"]>;
}

export type AgencyQuoteSaveFailure = {
  __typename?: "AgencyQuoteSaveFailure";
  banner: UiBanner;
};

export interface AgencyQuoteSaveInput {
  agencyInfo: AgencyQuoteSaveAgencyDetailInput;
  agentMessage?: InputMaybe<Scalars["String"]["input"]>;
  quoteCards: Array<AgencyQuoteSaveCardInput>;
  quoteId?: InputMaybe<Scalars["String"]["input"]>;
  quoteName: Scalars["String"]["input"];
  travelerName: Scalars["String"]["input"];
}

export type AgencyQuoteSaveResponse = AgencyQuoteSaveFailure | AgencyQuoteSaveSuccess;

export type AgencyQuoteSaveSuccess = {
  __typename?: "AgencyQuoteSaveSuccess";
  quoteId?: Maybe<Scalars["String"]["output"]>;
  toast: EgdsToast;
};

export type AgencyQuoteSwitchableMessage = {
  __typename?: "AgencyQuoteSwitchableMessage";
  displayToggle: UiToggle;
  message: EgdsTextSection;
};

export type AgencyQuoteSwitchablePriceSummary = {
  __typename?: "AgencyQuoteSwitchablePriceSummary";
  agencyServiceCharge?: Maybe<AgencyServiceChargeSection>;
  displayPriceToggle?: Maybe<UiToggle>;
  /** @deprecated Please do not use displayToggle as it has been deprecated. */
  displayToggle: UiToggle;
  priceBreakdownSummary?: Maybe<PricePresentation>;
  priceChangeAlert?: Maybe<PopoverSheet>;
};

export type AgencyQuoteToggle = UiToggle & {
  __typename?: "AgencyQuoteToggle";
  addLogoAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  /** @deprecated This declaration overrides deprecated member but not marked as deprecated itself. */
  label: Scalars["String"]["output"];
  removeLogoAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
};

export type AgencyServiceChargeActionElement = UiPrimaryButton | UiSecondaryButton;

export type AgencyServiceChargeBanner = EgdsElement & {
  __typename?: "AgencyServiceChargeBanner";
  actions?: Maybe<Array<EgdsStandardLink>>;
  buttonActions?: Maybe<Array<EgdsButton>>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  fullWidth?: Maybe<Scalars["Boolean"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type AgencyServiceChargeForm = {
  __typename?: "AgencyServiceChargeForm";
  actions?: Maybe<Array<AgencyServiceChargeActionElement>>;
  header?: Maybe<AgencyServiceChargeHeadSection>;
  inputs?: Maybe<AgencyServiceChargeInputSection>;
  messages?: Maybe<Array<AgencyServiceChargeMessageLine>>;
};

export type AgencyServiceChargeHeadSection = {
  __typename?: "AgencyServiceChargeHeadSection";
  heading: EgdsPlainText;
  popover?: Maybe<PopoverSheet>;
  subHeading?: Maybe<EgdsPlainText>;
};

export interface AgencyServiceChargeInput {
  baseAmount: Scalars["Float"]["input"];
  percentageAmount: Scalars["Float"]["input"];
}

export type AgencyServiceChargeInputSection = {
  __typename?: "AgencyServiceChargeInputSection";
  amount: AgencyQuoteInputField;
  description?: Maybe<EgdsPlainText>;
  icon: EgdsGraphicText;
  percentage: AgencyQuoteInputField;
  subtotalAmount: Scalars["Float"]["output"];
};

export type AgencyServiceChargeMessageLine = {
  __typename?: "AgencyServiceChargeMessageLine";
  icon?: Maybe<EgdsGraphicText>;
  message: EgdsPlainText;
  type: AgencyServiceChargeMessageLineType;
};

export type AgencyServiceChargeMessageLineType = "ERROR" | "WARNING";

export type AgencyServiceChargeSection = AgencyServiceChargeBanner | AgencyServiceChargeWidget;

export type AgencyServiceChargeWidget = {
  __typename?: "AgencyServiceChargeWidget";
  edit?: Maybe<AgencyServiceChargeBanner>;
  form: AgencyServiceChargeForm;
};

export type AgencySiteInfo = {
  __typename?: "AgencySiteInfo";
  agencyEntryFields: Array<EgdsInputField>;
  logo: AgencyLogo;
};

export type AgentComment = {
  __typename?: "AgentComment";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<EgdsText>;
  placeHolder?: Maybe<Scalars["String"]["output"]>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AgentMessage = {
  __typename?: "AgentMessage";
  label?: Maybe<EgdsText>;
  placeHolder?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AgentPresence = {
  __typename?: "AgentPresence";
  agentResourceUri: Scalars["String"]["output"];
  agentStateNames: Array<Scalars["String"]["output"]>;
  agentStates: Array<AgentState>;
  avatar: Scalars["String"]["output"];
  currentState: AgentState;
  emailId: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  logonName?: Maybe<Scalars["String"]["output"]>;
};

export interface AgentQuoteDetails {
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
}

export type AgentQuoteDetailsFailure = AgentQuoteDetails & {
  __typename?: "AgentQuoteDetailsFailure";
  banner: UiBanner;
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
};

export type AgentQuoteDetailsSuccess = AgentQuoteDetails & {
  __typename?: "AgentQuoteDetailsSuccess";
  actionButtons: Array<UiPrimaryButton>;
  agencySiteInfo: AgencySiteInfo;
  agentMessage: AgentMessage;
  banner?: Maybe<UiBanner>;
  messages?: Maybe<Array<EgdsText>>;
  pageTitle: EgdsHeading;
  propertyDetailsLink?: Maybe<EgdsStandardLink>;
  propertyRoomCards: Array<PropertyRoomCard>;
  tripSummary: EgdsTextSection;
};

export type AgentQuoteInputField = EgdsInputField & {
  __typename?: "AgentQuoteInputField";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export interface AgentQuoteNaturalKeyInput {
  packageRates?: InputMaybe<Scalars["Boolean"]["input"]>;
  propertyDestination: Scalars["String"]["input"];
  propertyNaturalKeys: Array<PropertyNaturalKeyInput>;
}

export type AgentQuoteSwitchableMessage = {
  __typename?: "AgentQuoteSwitchableMessage";
  displayToggle: UiToggle;
  message: EgdsTextSection;
};

export type AgentQuoteSwitchablePriceSummary = {
  __typename?: "AgentQuoteSwitchablePriceSummary";
  displayToggle: UiToggle;
  priceBreakdownSummary?: Maybe<PricePresentation>;
};

export type AgentQuoteToggle = UiToggle & {
  __typename?: "AgentQuoteToggle";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
};

export type AgentState = {
  __typename?: "AgentState";
  agentPresenceUri: Scalars["String"]["output"];
  localizedText?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  type: AgentStateType;
};

export interface AgentStateInput {
  agentPresenceUri: Scalars["String"]["input"];
  localizedText?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  type: AgentStateType;
}

export type AgentStateType = "NotReady" | "Offline" | "Ready";

export type AgentToolsActionList = {
  __typename?: "AgentToolsActionList";
  items: Array<AgentToolsActionListItem>;
  title: Scalars["String"]["output"];
};

export type AgentToolsActionListDropdown = {
  __typename?: "AgentToolsActionListDropdown";
  label: Scalars["String"]["output"];
  list: AgentToolsActionList;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type AgentToolsActionListItem = {
  __typename?: "AgentToolsActionListItem";
  label: Scalars["String"]["output"];
  logo?: Maybe<AgentToolsLogo>;
  value: Scalars["String"]["output"];
};

export type AgentToolsBookingLineItem = {
  __typename?: "AgentToolsBookingLineItem";
  date: Date;
  /** @deprecated no longer needed */
  label: Scalars["String"]["output"];
  values: Array<Scalars["Float"]["output"]>;
};

export type AgentToolsBookingPrice = {
  __typename?: "AgentToolsBookingPrice";
  additionalFees?: Maybe<LabeledValue>;
  /** @deprecated Use baseNights field */
  base: LabeledValue;
  baseNights: AgentToolsExpandoLabeledValueList;
  bookingPrice: LabeledValue;
  coupons?: Maybe<LabeledValue>;
  taxesAndFees?: Maybe<LabeledValue>;
};

export type AgentToolsBookingSummaryItem = {
  __typename?: "AgentToolsBookingSummaryItem";
  label: Scalars["String"]["output"];
  values: Array<Scalars["Float"]["output"]>;
};

export type AgentToolsChangeOrCancelReason = {
  __typename?: "AgentToolsChangeOrCancelReason";
  approver?: Maybe<AgentToolsReasonApprover>;
  calculateRefund?: Maybe<EgdsBasicRadioGroup>;
  calculateRefundCombined?: Maybe<AgentToolsFieldLabel>;
  calculateRefundExpedia?: Maybe<EgdsBasicRadioGroup>;
  calculator?: Maybe<LodgingChangeReasonCalculator>;
  copy?: Maybe<UiSecondaryButton>;
  currency: Scalars["String"]["output"];
  /** @deprecated use descriptionContent instead */
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionContent?: Maybe<Array<Scalars["String"]["output"]>>;
  fullAmount: Scalars["Float"]["output"];
  isDisabled?: Maybe<Scalars["Boolean"]["output"]>;
  overRefundFieldErrorMessage?: Maybe<Scalars["String"]["output"]>;
  overRefundTitle?: Maybe<Scalars["String"]["output"]>;
  refundSource?: Maybe<EgdsBasicRadioGroup>;
  refundSourcePortion?: Maybe<AgentToolsReasonRefundSourcePortion>;
  requiredField?: Maybe<Scalars["String"]["output"]>;
  reset?: Maybe<UiSecondaryButton>;
  title: Scalars["String"]["output"];
};

export type AgentToolsChannelInformation = {
  __typename?: "AgentToolsChannelInformation";
  channelType: AgentToolsChannelType;
  contactInformationInput?: Maybe<EgdsTextInputField>;
  description: Array<Scalars["String"]["output"]>;
  descriptionByPaymentSelection?: Maybe<DescriptionByPaymentSelection>;
  error?: Maybe<AgentToolsErrorBanner>;
  heading: Scalars["String"]["output"];
  sendPaymentFormButtonLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AgentToolsChannelType = "CHAT" | "EMAIL" | "SMS" | "VOICE";

export type AgentToolsErrorBanner = {
  __typename?: "AgentToolsErrorBanner";
  correlationId?: Maybe<LabeledValue>;
  details?: Maybe<AgentToolsErrorDetail>;
  linkLabel?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AgentToolsErrorDetail = AgentToolsErrorDetailList | AgentToolsErrorDetailText;

export type AgentToolsErrorDetailList = {
  __typename?: "AgentToolsErrorDetailList";
  items: Array<Scalars["String"]["output"]>;
};

export type AgentToolsErrorDetailText = {
  __typename?: "AgentToolsErrorDetailText";
  text: Scalars["String"]["output"];
};

export type AgentToolsExpandoLabeledValueList = {
  __typename?: "AgentToolsExpandoLabeledValueList";
  header: AgentToolsListElement;
  isExpanded?: Maybe<Scalars["Boolean"]["output"]>;
  items?: Maybe<Array<LabeledValue>>;
};

export interface AgentToolsExtraBedOptionsInput {
  extraAdultBedCount: Scalars["Int"]["input"];
  extraChildBedCount: Scalars["Int"]["input"];
  extraCribCount: Scalars["Int"]["input"];
}

export type AgentToolsFtcDates = {
  __typename?: "AgentToolsFTCDates";
  changeFee: InformativeTextInput;
  completeDate: EgdsSingleDatePicker;
  header: Scalars["String"]["output"];
  rebookDate: EgdsSingleDatePicker;
  startDate: EgdsSingleDatePicker;
  subHeader: Scalars["String"]["output"];
};

export type AgentToolsFieldLabel = {
  __typename?: "AgentToolsFieldLabel";
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type AgentToolsFutureTravelCredit = {
  __typename?: "AgentToolsFutureTravelCredit";
  terms?: Maybe<LabeledList>;
  totalAmount: LabeledValue;
  travellerRefundDetails?: Maybe<Array<LabeledValue>>;
};

export interface AgentToolsFutureTravelCreditDatesInput {
  bookByDate?: InputMaybe<Scalars["String"]["input"]>;
  changeFee?: InputMaybe<Scalars["String"]["input"]>;
  travelCompleteByDate?: InputMaybe<Scalars["String"]["input"]>;
  travelStartByDate?: InputMaybe<Scalars["String"]["input"]>;
}

export interface AgentToolsFutureTravelCreditInput {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  dates?: InputMaybe<AgentToolsFutureTravelCreditDatesInput>;
}

export type AgentToolsLabeledList = {
  __typename?: "AgentToolsLabeledList";
  elements: Array<AgentToolsListElement>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type AgentToolsListElement = LabeledValue | StringElement;

export interface AgentToolsLodgingActionInput {
  payload?: InputMaybe<AgentToolsLodgingChangeActionPayloadInput>;
  type: AgentToolsLodgingActionType;
}

export type AgentToolsLodgingActionType = "Cancel" | "ComboChange" | "OccupancyChange" | "RoomTypeChange";

export interface AgentToolsLodgingChangeActionPayloadInput {
  checkInDate?: InputMaybe<DateInput>;
  checkOutDate?: InputMaybe<DateInput>;
  extraBedOptions?: InputMaybe<AgentToolsExtraBedOptionsInput>;
  inventoryType: InventoryType;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomOccupancy?: InputMaybe<AgentToolsRoomOccupancyInput>;
  roomOptions?: InputMaybe<AgentToolsRoomOptionsInput>;
  roomTypeID?: InputMaybe<Scalars["String"]["input"]>;
}

export type AgentToolsLodgingCostBreakdown = {
  __typename?: "AgentToolsLodgingCostBreakdown";
  amountPaid?: Maybe<LabeledValue>;
  /** @deprecated design updated and this field removed */
  beginningBookingBalance?: Maybe<LabeledValue>;
  /** @deprecated design updated and this field removed */
  endingBookingBalance?: Maybe<LabeledValue>;
  /** @deprecated use penalties instead */
  finalPenalties?: Maybe<AgentToolsLodgingFinalPenalties>;
  newBookingPrice?: Maybe<AgentToolsBookingPrice>;
  originalBookingPrice: AgentToolsBookingPrice;
  penalties?: Maybe<LabeledValue>;
  penaltyAmount?: Maybe<Scalars["Float"]["output"]>;
  previousRefunds?: Maybe<LabeledValue>;
  title?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated design updated and this field removed */
  totalCharged?: Maybe<LabeledValue>;
};

export type AgentToolsLodgingFinalPenalties = {
  __typename?: "AgentToolsLodgingFinalPenalties";
  finalPenalties: LabeledValue;
  penalties: LabeledValue;
  penaltyWaivers: LabeledValue;
};

export type AgentToolsLogo = Icon | Mark;

export type AgentToolsNumberInput = {
  __typename?: "AgentToolsNumberInput";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type AgentToolsPaymentAssist = {
  __typename?: "AgentToolsPaymentAssist";
  agentId: Scalars["String"]["output"];
  agentLanguageId: Scalars["String"]["output"];
  channelType: AgentToolsChannelType;
  countryCode: Scalars["String"]["output"];
  currentCardPaymentInstrumentId?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<AgentToolsErrorBanner>;
  fallbackChannelHeader: Scalars["String"]["output"];
  fallbackChannelSelection: EgdsBasicRadioGroup;
  heading: Scalars["String"]["output"];
  paymentAssistTransactionType: PaymentAssistTransactionTypeOutput;
  paymentCapturedMessage: AgentToolsSuccessBanner;
  paymentSelection: EgdsBasicSelect;
  travelerLanguageId: Scalars["String"]["output"];
  vendorBanners: PaymentAssistVendorBanners;
  vendorClientId: Scalars["String"]["output"];
  vendorSessionId?: Maybe<Scalars["String"]["output"]>;
  verificationForm: AgentToolsPaymentVerificationForm;
};

export type AgentToolsPaymentFlow = "PAYMENT_ASSIST" | "PAYMENT_VERIFICATION";

export type AgentToolsPaymentVerificationForm = {
  __typename?: "AgentToolsPaymentVerificationForm";
  channelSelection: EgdsBasicRadioGroup;
  verificationCompleteBanner: AgentToolsSuccessBanner;
  verificationInProgressBanner: AgentToolsSuccessBanner;
  verificationInitiatedBanner: AgentToolsSuccessBanner;
  verificationRequiredBanner: AgentToolsSuccessBanner;
};

export interface AgentToolsPenaltyWaiverInput {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  waivingSource?: InputMaybe<WaivingSource>;
}

export type AgentToolsRadioGroup = {
  __typename?: "AgentToolsRadioGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]["output"]>;
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type AgentToolsReasonApprover = {
  __typename?: "AgentToolsReasonApprover";
  contact: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  header?: Maybe<Scalars["String"]["output"]>;
  lastName: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type AgentToolsReasonRefundSourcePortion = {
  __typename?: "AgentToolsReasonRefundSourcePortion";
  /** @deprecated use errorMessages instead */
  errorMessage: Scalars["String"]["output"];
  errorMessages: RefundSourcePortionErrorMessages;
  expediaPortion: EgdsBasicRadioGroup;
  supplierPortionTitle: Scalars["String"]["output"];
};

export type AgentToolsRefundCalculation = {
  __typename?: "AgentToolsRefundCalculation";
  expediaBalanceAmountInput: EgdsNumberInputField;
  expediaOtherAmountInput: EgdsNumberInputField;
  expediaPortionRadioGroup: AgentToolsRadioGroup;
  fullOrPartialRadioGroup: AgentToolsRadioGroup;
  fullRefundInput: EgdsNumberInputField;
  partialRefundTable: AgentToolsRefundCalculator;
  supplierPortionInput: EgdsNumberInputField;
  supplierPortionTitle: EgdsPlainText;
};

export type AgentToolsRefundCalculator = {
  __typename?: "AgentToolsRefundCalculator";
  footer: AgentToolsRefundTableFooter;
  footnote?: Maybe<EgdsPlainText>;
  header: AgentToolsRefundTableHeader;
  lineItems: Array<AgentToolsRefundTableLineItem>;
  reset: UiSecondaryButton;
};

export type AgentToolsRefundTableFooter = {
  __typename?: "AgentToolsRefundTableFooter";
  bookingSummaryItems: Array<AgentToolsBookingSummaryItem>;
  totalRefund: AgentToolsRefundTableTotalRefund;
};

export type AgentToolsRefundTableHeader = {
  __typename?: "AgentToolsRefundTableHeader";
  bookingTableHeader: Array<AgentToolsRefundTableHeaderItem>;
  refund: AgentToolsRefundTableHeaderItem;
  totalRefund: AgentToolsRefundTableHeaderItem;
};

export type AgentToolsRefundTableHeaderItem = {
  __typename?: "AgentToolsRefundTableHeaderItem";
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type AgentToolsRefundTableInput = {
  __typename?: "AgentToolsRefundTableInput";
  baseAmount: Scalars["Float"]["output"];
  refundType: AgentToolsRadioGroup;
  refundValueInput: AgentToolsRefundTableValueInput;
};

export type AgentToolsRefundTableLineItem = {
  __typename?: "AgentToolsRefundTableLineItem";
  bookingDetails: AgentToolsBookingLineItem;
  refundInput: AgentToolsRefundTableInput;
};

export type AgentToolsRefundTableTotalRefund = {
  __typename?: "AgentToolsRefundTableTotalRefund";
  validations: Array<EgdsInputValidation>;
};

export type AgentToolsRefundTableValueInput = {
  __typename?: "AgentToolsRefundTableValueInput";
  balanceAmount: EgdsNumberInputField;
  partialAmount: EgdsNumberInputField;
  percentage: EgdsNumberInputField;
};

export interface AgentToolsRoomOccupancyInput {
  adultCount: Scalars["Int"]["input"];
  childAgeList: Array<Scalars["Int"]["input"]>;
}

export interface AgentToolsRoomOptionsInput {
  bedSelected: Scalars["Int"]["input"];
  bedSelectedInfo: BedSelectedInfoInput;
  smokingTypeSelected: SmokingTypeSelectedInput;
  specialRequest: Scalars["String"]["input"];
}

export type AgentToolsSavedCardSelect = {
  __typename?: "AgentToolsSavedCardSelect";
  error?: Maybe<AgentToolsErrorBanner>;
  heading: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  savedCardDropdown?: Maybe<AgentToolsActionListDropdown>;
};

export type AgentToolsStep = {
  __typename?: "AgentToolsStep";
  label: Scalars["String"]["output"];
};

export type AgentToolsStepIndicator = {
  __typename?: "AgentToolsStepIndicator";
  activeStep: Scalars["Int"]["output"];
  steps: Array<AgentToolsStep>;
};

export type AgentToolsSuccessBanner = {
  __typename?: "AgentToolsSuccessBanner";
  body: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  iconName?: Maybe<Scalars["String"]["output"]>;
  iconSpotlight?: Maybe<Scalars["String"]["output"]>;
  linkLabel?: Maybe<Scalars["String"]["output"]>;
};

export type AgentToolsSummaryCard = {
  __typename?: "AgentToolsSummaryCard";
  amountPaid?: Maybe<LabeledValue>;
  chargeAmount?: Maybe<AgentToolsTransaction>;
  confirmationEmail?: Maybe<LabeledValue>;
  estimatedDueAmount?: Maybe<LabeledValue>;
  futureTravelCreditAmount?: Maybe<AgentToolsFutureTravelCredit>;
  newBookingBalance?: Maybe<LabeledValue>;
  newDeposit?: Maybe<LabeledValue>;
  newPaymentSchedule?: Maybe<AgentToolsExpandoLabeledValueList>;
  newSupplierCharges?: Maybe<LabeledValue>;
  originalBookingPrice?: Maybe<LabeledValue>;
  penalties?: Maybe<AgentToolsExpandoLabeledValueList>;
  previousRefunds?: Maybe<LabeledValue>;
  refundAmount?: Maybe<AgentToolsTransaction>;
  /** @deprecated No longer needed per design update */
  refundedOrChargedBy?: Maybe<LabeledValue>;
  title: Scalars["String"]["output"];
  totalPenalty?: Maybe<LabeledValue>;
  waiverLossPayout?: Maybe<AgentToolsLabeledList>;
};

export type AgentToolsSummaryCardComponentType = "FLIGHT_CANCEL" | "LODGING_CANCEL" | "LODGING_CHANGE";

export type AgentToolsTransaction = {
  __typename?: "AgentToolsTransaction";
  /** @deprecated Use paymentBreakdown field */
  cardAmount?: Maybe<LabeledValue>;
  paymentBreakdown?: Maybe<AgentToolsTransactionPaymentBreakdown>;
  /** @deprecated Use paymentBreakdown field */
  rewardsAmount?: Maybe<LabeledValue>;
  timeline?: Maybe<LabeledValue>;
  totalAmount: LabeledValue;
};

export type AgentToolsTransactionPaymentBreakdown = {
  __typename?: "AgentToolsTransactionPaymentBreakdown";
  footnote?: Maybe<Scalars["String"]["output"]>;
  paymentBreakdown?: Maybe<Array<LabeledValue>>;
};

export interface AgentToolsTravellerContactInformationInput {
  email?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
}

export type AgentToolsWaiverContact = {
  __typename?: "AgentToolsWaiverContact";
  agentId: EgdsTextInputField;
  title: Scalars["String"]["output"];
  waiverCode: EgdsTextInputField;
  waiverLocation: EgdsBasicSelect;
};

export type AirCreditWebAction = UiAction & {
  __typename?: "AirCreditWebAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
};

export type AirlineCreditsContentPresentation = AirlineCreditsLoadedPresentation | AirlineCreditsLoadingPresentation;

export type AirlineCreditsLoadedPresentation = {
  __typename?: "AirlineCreditsLoadedPresentation";
  dialogId: FlightsDialogId;
  dialogs?: Maybe<Array<FlightsDialog>>;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  sections: Array<FlightsCreditSelectionPresentationSection>;
};

export type AirlineCreditsLoadingPresentation = {
  __typename?: "AirlineCreditsLoadingPresentation";
  heading: Scalars["String"]["output"];
  subheading: Scalars["String"]["output"];
};

export type AirlineCreditsPresentation = {
  __typename?: "AirlineCreditsPresentation";
  dialogId: FlightsDialogId;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  sections: Array<FlightsCreditSelectionPresentationSection>;
};

export type AirportLocationType = "AIRPORT" | "METROCODE" | "UNSPECIFIED";

export type AirportOption = {
  __typename?: "AirportOption";
  accessibilityHeading: Scalars["String"]["output"];
  accessibilityMessage: Scalars["String"]["output"];
  destinationAirportDistance: Scalars["String"]["output"];
  destinationCityName: Scalars["String"]["output"];
  icon: Icon;
  originAirportDistance: Scalars["String"]["output"];
  originCityName: Scalars["String"]["output"];
  selectAction: FlightsAction;
};

export type Alignment = "CENTER" | "LEFT" | "RIGHT";

export type AllArticlesErrorView = {
  __typename?: "AllArticlesErrorView";
  errorMessage: UiBanner;
  /** @deprecated Help Articles title won't be shown */
  title: Scalars["String"]["output"];
};

export type AllHelpArticles = {
  __typename?: "AllHelpArticles";
  dialogTitleOption: HelpArticleHeadingSection;
  helpArticleContactUsWidget?: Maybe<HelpArticlesContactUsWidgetSection>;
  /** @deprecated Help Articles title won't be shown */
  helpArticleTitle: Scalars["String"]["output"];
  helpArticlesOnHomepage: Array<HelpArticleCard>;
  /** @deprecated This component is replaced as separate query helparticlesresultsview.helpArticleSearchBar */
  helpArticlesSearchBar?: Maybe<EgdsTypeaheadInputField>;
  searchButton: UiPrimaryButton;
};

export type AllHelpArticlesView = AllArticlesErrorView | AllHelpArticles;

export type AllHotelProducts = {
  __typename?: "AllHotelProducts";
  hotelProducts: Array<HotelProduct>;
};

export type AllHotelProductsData = {
  __typename?: "AllHotelProductsData";
  messageContent: AllHotelProducts;
  schemaName: Scalars["String"]["output"];
};

export type AllOffersSection = {
  __typename?: "AllOffersSection";
  actionId: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  primaryButton?: Maybe<UiPrimaryButton>;
};

export type AlterMode = "DEBUG" | "NONE" | "PREVIEW" | "RELEASED";

export type AlternateAirportOptions = {
  __typename?: "AlternateAirportOptions";
  accessibilityTitle: Scalars["String"]["output"];
  airportOptionAnalytics: FlightsAnalytics;
  airportOptions: Array<AirportOption>;
  alternateOptionTitle: Scalars["String"]["output"];
};

export type AlternateDateOption = {
  __typename?: "AlternateDateOption";
  dates: LodgingLinkMessage;
  price?: Maybe<PropertyPriceOption>;
};

export type AlternateDateOptions = {
  __typename?: "AlternateDateOptions";
  accessibilityTitle: Scalars["String"]["output"];
  alternateOptionTitle: Scalars["String"]["output"];
  dateOptionAnalytics: FlightsAnalytics;
  dateOptions: Array<DateOption>;
};

export type AlternateDatesCard = {
  __typename?: "AlternateDatesCard";
  header?: Maybe<LodgingHeader>;
  options: Array<AlternateDateOption>;
};

export type AlternateRecommendationCard = CarRecommendationCard & {
  __typename?: "AlternateRecommendationCard";
  action?: Maybe<CarAction>;
  analytics?: Maybe<CarAnalytics>;
  button?: Maybe<CarActionableItem>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  subText?: Maybe<CarPhrase>;
  title?: Maybe<CarPhrase>;
};

export type AlternativeLinkType = "ALTERNATIVE_ACCOMMODATION" | "ALTERNATIVE_LOBS";

export type AmenitiesDialog = {
  __typename?: "AmenitiesDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingDialogTriggerMessage;
};

export type AmenityCategory =
  | "ACCESSIBLE_ROLL_IN_SHOWER"
  | "AIR_CONDITIONING"
  | "ALL_INCLUSIVE"
  | "ARCADE"
  | "BABYSITTING"
  | "BAR"
  | "BARBECUE"
  | "BOWLING"
  | "BREAKFAST_AVAILABLE"
  | "BREAKFAST_INCLUDED"
  | "BREAKFAST_NOT_AVAILABLE"
  | "BUSINESS_SERVICES"
  | "CHILDREN_CLUB"
  | "DAILY_HOUSEKEEPING"
  | "EXTRA_BED"
  | "FIRE_PLACE"
  | "FITNESS_EQUIPMENT"
  | "FREE_AIRPORT_TRANSPORTATION"
  | "FREE_BREAKFAST"
  | "FREE_CANCEL"
  | "FREE_PARKING"
  | "FREE_WELCOME_DRINK"
  | "FREE_WIRED_INTERNET"
  | "FRONT_DESK_24_HOURS"
  | "GROCERY"
  | "HIGH_SPEED_INTERNET"
  | "HOT_TUB"
  | "INTERNET_NOT_AVAILABLE"
  | "IN_ROOM_INTERNET"
  | "KIDS_POOL"
  | "KITCHEN"
  | "LAUNDRY"
  | "LIVING_AREA"
  | "MICROWAVE"
  | "MINI_GOLF"
  | "NON_REFUNDABLE"
  | "ON_PRIVATE_BEACH"
  | "ON_THE_BEACH"
  | "OUTDOOR_SPACE"
  | "PARKING"
  | "PARTIAL_REFUND"
  | "PETS_ALLOWED"
  | "PLAYGROUND"
  | "POOL"
  | "RESERVE_NOW_PAY_DEPOSIT"
  | "RESERVE_NOW_PAY_LATER"
  | "RESTAURANT_IN_HOTEL"
  | "ROOM_SERVICE"
  | "SMOKE_FREE"
  | "SOUNDPROOF_ROOM"
  | "SPA_SERVICES_ON_SITE"
  | "SPECIAL_DEAL"
  | "TENNIS_COURT"
  | "TOYS"
  | "WASHER"
  | "WATERSLIDE"
  | "WIFI_SURCHARGE"
  | "WIRED_INTERNET_SURCHARGE";

export type AmenityFilter = "CARRY_ON_BAG" | "INVALID" | "NO_CANCEL_FEE" | "NO_CHANGE_FEE" | "SEAT_CHOICE";

export type AmountPaidError = {
  __typename?: "AmountPaidError";
  error: Scalars["String"]["output"];
};

export type AmountPaidHeader = {
  __typename?: "AmountPaidHeader";
  primaryText: Scalars["String"]["output"];
  secondaryText: Scalars["String"]["output"];
};

export interface AmountPaidInput {
  orderNumber: Scalars["String"]["input"];
  pnr: Scalars["String"]["input"];
}

export type AmountPaidSuccess = {
  __typename?: "AmountPaidSuccess";
  header: AmountPaidHeader;
  primaryElement: LabeledValue;
  secondaryElements?: Maybe<Array<LabeledValue>>;
};

export type AmountPaidView = AmountPaidError | AmountPaidSuccess;

export interface AnalyticEvent {
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
}

export type AnalyticsElement =
  | VirtualAgentControlImpressionAnalyticEvent
  | VirtualAgentControlInteractionAnalyticEvent
  | VirtualAgentControlPageViewAnalyticEvent;

export type AnalyticsKey = "NUMBER_OF_BAGS" | "PIECE_OR_WEIGHT_CONCEPT" | "QUANTITY_OF_BAGS";

export interface AncillaryContentInput {
  ancillaryToken: Scalars["String"]["input"];
}

export interface AncillaryJourneySelectionsInput {
  journeyIndex: Scalars["Int"]["input"];
  journeyStatus?: InputMaybe<FlightJourneyStatus>;
  travellerAncillarySelections: Array<TravellerAncillarySelectionInput>;
}

export type AncillaryShoppingPath = "PACKAGE_INFOSITE" | "PACKAGE_TRIPS" | "STANDALONE_INFOSITE" | "STANDALONE_TRIPS";

export type AnnualSummaryCarouselItem = {
  __typename?: "AnnualSummaryCarouselItem";
  /** @deprecated Please use carouselItemAccessibility for accessibility strings */
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<UiPrimaryButton>;
  carouselItemAccessibility?: Maybe<CarouselItemAccessibility>;
  carouselItemId: Scalars["String"]["output"];
  closeIcon?: Maybe<UiTertiaryButton>;
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  logo?: Maybe<Mark>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
  travelExplored?: Maybe<Array<AnnualSummaryTravelExplored>>;
};

export type AnnualSummaryHomeResult = {
  __typename?: "AnnualSummaryHomeResult";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  annualSummaryCarouselItem: AnnualSummaryCarouselItem;
};

export type AnnualSummaryResult = {
  __typename?: "AnnualSummaryResult";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  annualSummaryCarouselItem?: Maybe<Array<AnnualSummaryCarouselItem>>;
};

export type AnnualSummaryTravelExplored = {
  __typename?: "AnnualSummaryTravelExplored";
  exploredItems?: Maybe<Array<Scalars["String"]["output"]>>;
  icon?: Maybe<Icon>;
};

export type AppDownloadButton = {
  __typename?: "AppDownloadButton";
  action?: Maybe<UiBannerAction>;
  button?: Maybe<UiSecondaryButton>;
  revealAction?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type AppGrowthActionNotification = {
  __typename?: "AppGrowthActionNotification";
  exitText?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type AppGrowthBorder = {
  __typename?: "AppGrowthBorder";
  shape: AppGrowthBorderShape;
  style: AppGrowthBorderStyle;
};

export type AppGrowthBorderShape = "ROUNDED" | "SQUARE";

export type AppGrowthBorderStyle = "BOLD" | "DEFAULT" | "NONE";

export type AppGrowthButton = {
  __typename?: "AppGrowthButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
  uiAction: AppGrowthUiAction;
};

export type AppGrowthDialog = {
  __typename?: "AppGrowthDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  details?: Maybe<EgdsTextList>;
  footer?: Maybe<EgdsDialogFooter>;
  header: EgdsSectionHeading;
};

export type AppGrowthLineOfBusiness =
  | "ACTIVITIES"
  | "CAR"
  | "CRUISES"
  | "DEFAULT"
  | "FLIGHT"
  | "GROUNDTRANSFERS"
  | "HOTEL"
  | "PACKAGES"
  | "RAILS"
  | "UNCLASSIFIED";

export type AppGrowthMediaItem = MediaItem & {
  __typename?: "AppGrowthMediaItem";
  alt?: Maybe<Scalars["String"]["output"]>;
  media: Media;
};

export type AppGrowthNavigation = {
  __typename?: "AppGrowthNavigation";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbarIcon?: Maybe<AppGrowthToolbarIcon>;
  toolbarStyle?: Maybe<AppGrowthToolbarStyle>;
};

export interface AppGrowthQueryImpressionSupportResponse {
  clientSideAnalytics: ClientSideAnalytics;
  clientSideAnalyticsImpression: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
}

export interface AppGrowthQueryResponse {
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
}

export type AppGrowthSummary = {
  __typename?: "AppGrowthSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type AppGrowthTextIconLinkListItem = {
  __typename?: "AppGrowthTextIconLinkListItem";
  accessibility: Scalars["String"]["output"];
  action?: Maybe<UiLinkAction>;
  border: AppGrowthBorder;
  egdsElementId: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  uiAction?: Maybe<AppGrowthUiAction>;
};

export type AppGrowthToolbarIcon = "BACK" | "CLOSE";

export type AppGrowthToolbarStyle = "TRANSPARENT_ACTION_OVERLAY";

export type AppGrowthUiAction = {
  __typename?: "AppGrowthUIAction";
  actionId?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["String"]["output"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  deeplink?: Maybe<UiLinkAction>;
  shareText?: Maybe<Scalars["String"]["output"]>;
  triviaId?: Maybe<Scalars["String"]["output"]>;
  uiActionType: AppGrowthUiActionName;
};

export type AppGrowthUiActionName =
  | "ACCEPT"
  | "DEEPLINK"
  | "DENY"
  | "EXIT_TO_APP_HOME"
  | "GOTO_DASHBOARD"
  | "LOGIN_AND_SIGNUP"
  | "NONE"
  | "OK"
  | "SHARE"
  | "SIGNUP"
  | "TRIVIA_ANSWER"
  | "TRIVIA_END"
  | "TRIVIA_HOW_TO"
  | "TRIVIA_NEXT"
  | "TRIVIA_START";

export type ApplePayPaymentAttribute = {
  __typename?: "ApplePayPaymentAttribute";
  merchantSession?: Maybe<Scalars["String"]["output"]>;
};

export type ApplePayPaymentFop = IPaymentFop & {
  __typename?: "ApplePayPaymentFop";
  id: Scalars["String"]["output"];
  paymentAmount?: Maybe<PaymentPriceDetails>;
  paymentMethod: PaymentMethodType;
  paymentMethodAttribute: PaymentMethodAttribute;
  paymentSubMethods?: Maybe<Array<Scalars["String"]["output"]>>;
  rank?: Maybe<Scalars["Int"]["output"]>;
  splittable?: Maybe<Scalars["Boolean"]["output"]>;
  state: PaymentState;
};

export type AppliedCoupon = {
  __typename?: "AppliedCoupon";
  confirmationMessage?: Maybe<Scalars["String"]["output"]>;
  couponDetail?: Maybe<AppliedCouponDetail>;
  couponErrorBanner?: Maybe<CouponErrorBanner>;
  /** @deprecated Use uiSignals instead Ref to RFC-590 */
  signals: Array<CouponSignal>;
  uiSignals: Array<UiSignalV2>;
};

export type AppliedCouponDetail = {
  __typename?: "AppliedCouponDetail";
  code?: Maybe<Scalars["String"]["output"]>;
  displayInfo: EgdsStandardMessagingCard;
  instanceId?: Maybe<Scalars["String"]["output"]>;
};

export interface ApplyPaymentMethodRequestInput {
  paymentMethod: PaymentMethodInput;
  sessionId: Scalars["String"]["input"];
  sessionToken: Scalars["String"]["input"];
}

export interface ApproverInput {
  contact: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
}

export type AriaLabelledText = {
  __typename?: "AriaLabelledText";
  accessibilityText: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export interface ArithmeticOperationsCriteriaInput {
  inputNumbers: Array<Scalars["Float"]["input"]>;
  operation: Operation;
}

export type ArkoseChallenge = {
  __typename?: "ArkoseChallenge";
  apiKey: Scalars["String"]["output"];
  src?: Maybe<Scalars["String"]["output"]>;
};

export type ArkoseNativeProviderConfig = {
  __typename?: "ArkoseNativeProviderConfig";
  publicKey: Scalars["String"]["output"];
};

export type ArkoseWebProviderConfig = {
  __typename?: "ArkoseWebProviderConfig";
  publicKey: Scalars["String"]["output"];
};

export type ArticleSearchType = "CONTEXTUAL_FOLLOW_UP" | "GENERIC" | "LLM";

export type ArticlesSummarizationClickStreamAnalytics = {
  __typename?: "ArticlesSummarizationClickStreamAnalytics";
  article: HelpArticleEventPayload;
  duaid: Scalars["UUID"]["output"];
  event: EgClickstreamEvent;
  experience: Experience;
};

export type ArticlesSummarizationView =
  | HelpArticleSearchErrorView
  | HelpArticleSummarizationPoweredByLlm
  | LlmSearchErrorView;

export type AspectRatio =
  | "FOUR_ONE"
  | "FOUR_THREE"
  | "ONE_ONE"
  | "SIXTEEN_NINE"
  | "THREE_FOUR"
  | "THREE_TWO"
  | "TWENTY_ONE_NINE";

export type AssetSelection = {
  __typename?: "AssetSelection";
  asset: ProductEntityAssetSelection;
  param?: Maybe<IProductEntityAssetSelection>;
  parameters?: Maybe<ProductEntityAssetSelectionParameters>;
};

export type AssociateCreativeForm = {
  __typename?: "AssociateCreativeForm";
  associateCreativeHeader?: Maybe<Scalars["String"]["output"]>;
  associateCreativeSaveButton?: Maybe<UiPrimaryButton>;
  creativeSelectField?: Maybe<EgdsSelect>;
  creativeSelectOptions: Array<Scalars["String"]["output"]>;
};

export type AtAGlance = {
  __typename?: "AtAGlance";
  content: ShoppingProductContentSectionGroup;
};

export type AtoChallenge = {
  __typename?: "AtoChallenge";
  action: AtoChallengeAction;
  placement: AtoChallengePlacement;
};

export type AtoChallengeAction = ArkoseChallenge;

export type AtoChallengePlacement = "DirectFeedbackSubmitFormAction";

export interface AtoChallengeValidationInput {
  captchaToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type AuditHistoryDataTableQuery = {
  __typename?: "AuditHistoryDataTableQuery";
  getAuditHistoryDataTableView?: Maybe<AuditHistoryDataTableView>;
};

export type AuditHistoryDataTableQueryGetAuditHistoryDataTableViewArgs = {
  paginationMetadata: PageMetadataInput;
  partnerAccountID: Scalars["String"]["input"];
  productID: Scalars["String"]["input"];
};

export type AuditHistoryDataTableView = {
  __typename?: "AuditHistoryDataTableView";
  table: DataTable;
};

export type AuditHistoryQuery = {
  __typename?: "AuditHistoryQuery";
  getAuditHistoryDataTableView: AuditHistoryDataTableQuery;
};

export interface AuthenticationConfigInput {
  authToken?: InputMaybe<Scalars["String"]["input"]>;
  channelOriginId?: InputMaybe<Scalars["String"]["input"]>;
}

export type AuthenticationSpec = PaymentThreeDsAuthenticationSpec;

export type AuthenticationState = "ANONYMOUS" | "AUTHENTICATED" | "IDENTIFIED";

export type AutoSuggestInfo = {
  __typename?: "AutoSuggestInfo";
  autoSuggestKeyValuePair?: Maybe<Array<AutoSuggestKeyValuePair>>;
  header?: Maybe<Scalars["String"]["output"]>;
  payload?: Maybe<Scalars["String"]["output"]>;
  uri: HttpUri;
};

export type AutoSuggestKeyValuePair = {
  __typename?: "AutoSuggestKeyValuePair";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type AvailabilityCalendar = {
  __typename?: "AvailabilityCalendar";
  configuration: AvailabilityCalendarConfiguration;
  days?: Maybe<Array<AvailabilityCalendarDay>>;
  id: Scalars["ID"]["output"];
};

export type AvailabilityCalendarConfiguration = {
  __typename?: "AvailabilityCalendarConfiguration";
  availableByDefault: Scalars["Boolean"]["output"];
  beginDate: Date;
  currentDate: Date;
  defaultCheckinValidityAfterEndDate: CheckinValidityWithReason;
  defaultCheckoutValidityWhenWithinConstraints: CheckoutValidityWithReason;
  defaultStayConstraints: StayConstraints;
  endDate: Date;
};

export type AvailabilityCalendarDay = {
  __typename?: "AvailabilityCalendarDay";
  available: Scalars["Boolean"]["output"];
  checkinValidity: CheckinValidityWithReason;
  checkoutValidity: CheckoutValidityWithReason;
  date: Date;
  stayConstraints: StayConstraints;
};

export type AvailabilityCallToAction = LodgingButton | LodgingPlainMessage;

export type AvailableRoutes = {
  __typename?: "AvailableRoutes";
  destinationsString?: Maybe<Scalars["String"]["output"]>;
  originCountries: Array<OriginCountry>;
  originString?: Maybe<Scalars["String"]["output"]>;
};

export type Avatar = {
  __typename?: "Avatar";
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  size?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type AvatarGroup = {
  __typename?: "AvatarGroup";
  items: Array<Avatar>;
};

export type AvatarSize = "LARGE" | "MEDIUM" | "SMALL" | "XLARGE";

export type BackgroundType = "PRIMARY" | "SECONDARY";

export type Badge = {
  __typename?: "Badge";
  /** @deprecated Only used for uitk-react-badges v2, use text for v3 */
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Only used for uitk-react-badges v2, use icon_temp temporarily */
  icon?: Maybe<BadgeIcon>;
  icon_temp?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Only used for uitk-react-badges v2, use theme_temp temporarily */
  theme?: Maybe<Theme>;
  theme_temp?: Maybe<Theme>;
};

export type BadgeIcon =
  | "ACCESS_TIME"
  | "ADD"
  | "BUNDLED_SAVINGS_PLUS"
  | "EXP29639_ORB"
  | "FAMILY_FRIENDLY"
  | "INFO_OUTLINE"
  | "LOB_DEALS"
  | "LOCAL_OFFER"
  | "MOD"
  | "SMARTPHONE";

export type BadgeWrapper = {
  __typename?: "BadgeWrapper";
  badge: EgdsBadge;
};

export type BaggageType = "CARRY_BAG" | "CHECKED_BAG" | "UNKNOWN";

export type BagsSelectionType = "ALL" | "NONE" | "SOME";

export type BannerAdditionalInfo = {
  __typename?: "BannerAdditionalInfo";
  content?: Maybe<Array<BannerRichTextContent>>;
};

export type BannerAnalytics = {
  __typename?: "BannerAnalytics";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type BannerButton = EgdsStandardLink | UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type BannerCta = {
  __typename?: "BannerCTA";
  action?: Maybe<UiLinkAction>;
  clickActionType: BannerButton;
  description?: Maybe<Scalars["String"]["output"]>;
};

export type BannerCallToActions = {
  __typename?: "BannerCallToActions";
  items?: Maybe<Array<BannerCta>>;
};

export type BannerDescription = {
  __typename?: "BannerDescription";
  content?: Maybe<Array<BannerRichTextContent>>;
};

export type BannerDisplayType = "ERROR" | "INFO" | "NO_BANNER" | "WARN";

export type BannerMediaAsset = {
  __typename?: "BannerMediaAsset";
  altText?: Maybe<Scalars["String"]["output"]>;
  caption?: Maybe<Scalars["String"]["output"]>;
  credit?: Maybe<Scalars["String"]["output"]>;
  mediaAssetId: Scalars["String"]["output"];
  mediaAssetType?: Maybe<Scalars["String"]["output"]>;
  mediaLibraryId?: Maybe<Scalars["String"]["output"]>;
  mediaType: BannerMediaType;
  targetURL?: Maybe<Scalars["String"]["output"]>;
  usage?: Maybe<Scalars["String"]["output"]>;
};

export type BannerMediaComponents = {
  __typename?: "BannerMediaComponents";
  items?: Maybe<Array<BannerMediaAsset>>;
};

export type BannerMediaType = EgdsPhotosphereImage | Image | Video;

export type BannerPlacementServiceUiLinkAction = UiAction & {
  __typename?: "BannerPlacementServiceUILinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: HttpUri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"]["output"];
};

export type BannerRichTextContent =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsOrderedList
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText
  | EgdsUnorderedList
  | RichText;

export type BannerTag = "AD" | "AD_GROUP" | "CAMPAIGN";

export type BannerTheme = "BRAND_PROMO_PRIMARY" | "PRIMARY" | "SECONDARY";

export type BannerThemes = "CRITICAL" | "ERROR";

export type BannerType = "ERROR" | "INFO";

export type BasicShoppingAppliedFilter = ShoppingAppliedFilter & {
  __typename?: "BasicShoppingAppliedFilter";
  filter?: Maybe<EgdsPill>;
  linkUrl?: Maybe<UiLinkAction>;
  nextSearchCriteria?: Maybe<ShoppingSearchCriteria>;
};

export type BedInformation = {
  __typename?: "BedInformation";
  englishType: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type BedOptions = {
  __typename?: "BedOptions";
  bedInfo: Array<BedInformation>;
  icon: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export interface BedSelectedInfoInput {
  bedSelectedId: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

export type BestTimeToGo = {
  __typename?: "BestTimeToGo";
  impression: ClientSideAnalytics;
  monthlyHistoricalForecast: DestinationMonthlyHistoricalForecast;
  subtitle: EgdsSpannableText;
  title: Scalars["String"]["output"];
};

export type BinSpec = {
  __typename?: "BinSpec";
  bin?: Maybe<Scalars["Int"]["output"]>;
  brand?: Maybe<Scalars["String"]["output"]>;
  cardConfig?: Maybe<PaymentCardConfiguration>;
  cardLogo?: Maybe<Mark>;
  cvvConfig?: Maybe<PaymentCardCvvConfiguration>;
  issuerCountry?: Maybe<Scalars["String"]["output"]>;
};

export type BodyElements = NotificationMessageCard | NotificationMultiBody | NotificationSimpleBody;

export type BookResponse = {
  __typename?: "BookResponse";
  data?: Maybe<BookResponseType>;
};

export type BookResponseType = {
  __typename?: "BookResponseType";
  checkoutSessionToken: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  feedbackMessage?: Maybe<EgdsToast>;
  itineraryNumber?: Maybe<Scalars["String"]["output"]>;
  orderId?: Maybe<Scalars["String"]["output"]>;
  signals: Array<CheckoutUiSignal>;
};

export type BookSubmittingDialog = {
  __typename?: "BookSubmittingDialog";
  primaryContent: EgdsPlainText;
};

export type BookWithAirlinesDialog = FlightsDialogPresentation & {
  __typename?: "BookWithAirlinesDialog";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface BookingActionButton {
  name: EgdsButton;
}

export type BookingActionsClickStreamAnalytics = {
  __typename?: "BookingActionsClickStreamAnalytics";
  event: Event;
  experience: Experience;
  tripDetail?: Maybe<Array<ClickStreamTripInfo>>;
  workFlowList?: Maybe<Array<WorkFlow>>;
};

export interface BookingChangeFlightInput {
  airRecordLocator?: InputMaybe<Scalars["String"]["input"]>;
  client?: InputMaybe<BookingServicingClient>;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
  opaqueId?: InputMaybe<Scalars["String"]["input"]>;
  selectedChanges?: InputMaybe<Array<BookingServicingChangeSelectionInput>>;
  tripId: Scalars["String"]["input"];
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingChangeFlightInputType = {
  __typename?: "BookingChangeFlightInputType";
  airRecordLocator?: Maybe<Scalars["String"]["output"]>;
  client?: Maybe<BookingServicingClient>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  flightSearchCriteria?: Maybe<FlightSearchCriteriaInputType>;
  opaqueId?: Maybe<Scalars["String"]["output"]>;
  selectedChanges?: Maybe<Array<BookingServicingChangeSelectionInputType>>;
  tripId: Scalars["String"]["output"];
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export type BookingConfirmation = {
  __typename?: "BookingConfirmation";
  bookingConfirmationBody: Scalars["String"]["output"];
  /** @deprecated No longer needed because of the design updates. */
  bookingConfirmationHeader: Scalars["String"]["output"];
  bookingConfirmationLinkLabel?: Maybe<Scalars["String"]["output"]>;
};

export type BookingConfirmationNotificationCard = CommunicationCenterCard & {
  __typename?: "BookingConfirmationNotificationCard";
  /** @deprecated replace with image */
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"]["output"];
  deeplink: EgdsStandardLink;
  icon: Icon;
  image: CommunicationCenterImage;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  state: CommunicationCenterNotificationState;
  timeStamp?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  userActions: Array<CommunicationCenterNotificationUserAction>;
  viewReceiptCard: BookingConfirmationViewReceiptCard;
};

export type BookingConfirmationViewReceiptCard = {
  __typename?: "BookingConfirmationViewReceiptCard";
  icon: Icon;
  link: CommunicationCenterLink;
  message: Scalars["String"]["output"];
  theme: EgdsCardThemes;
};

export type BookingDetail = {
  __typename?: "BookingDetail";
  /** @deprecated bookingActions is the replacement, we'll remove this after release */
  actions: Array<HelpCenterIntentButtons>;
  bookingActions: Array<BookingActionButton>;
  bookingBadges?: Maybe<Array<EgdsStandardBadge>>;
  helpCenterIntentButton: HelpCenterIntentButtons;
  icon: Icon;
  isDisable: Scalars["Boolean"]["output"];
  itineraryNumber?: Maybe<Scalars["String"]["output"]>;
  itineraryNumberText?: Maybe<Scalars["String"]["output"]>;
  orderItemUuid?: Maybe<Scalars["String"]["output"]>;
  product: Scalars["String"]["output"];
  timePeriod: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type BookingDetails = {
  __typename?: "BookingDetails";
  tripId?: Maybe<Scalars["String"]["output"]>;
};

export type BookingDialog = {
  __typename?: "BookingDialog";
  dialogs: Array<CheckoutDialog>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
};

export type BookingDialogContent = {
  __typename?: "BookingDialogContent";
  primaryContent: EgdsPlainText;
};

export type BookingFee = {
  __typename?: "BookingFee";
  bookingFeeCheckBox?: Maybe<EgdsBasicCheckBox>;
  bookingFeeDisclaimer?: Maybe<Scalars["String"]["output"]>;
};

export type BookingInfo = {
  __typename?: "BookingInfo";
  currencyCode: Scalars["String"]["output"];
  inventoryType: Scalars["String"]["output"];
  isOutsidePenalty: Scalars["Boolean"]["output"];
  paymentType: AgencyBusinessModel;
  supplierId?: Maybe<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
  tripStage: TripStage;
};

export type BookingLineItem = {
  __typename?: "BookingLineItem";
  amountInput: LodgingCalculatorInput;
  heading?: Maybe<Scalars["String"]["output"]>;
  metadata: BookingLineItemMetadata;
  originalAmountPaid: Scalars["Float"]["output"];
  supplierNetRate: Scalars["Float"]["output"];
};

export type BookingLineItemHeader = {
  __typename?: "BookingLineItemHeader";
  nights: Scalars["String"]["output"];
  originalAmountPaid: Scalars["String"]["output"];
  refundInput: Scalars["String"]["output"];
  supplierNetRate: Scalars["String"]["output"];
  totalRefund: Scalars["String"]["output"];
};

export type BookingLineItemMetadata = {
  __typename?: "BookingLineItemMetadata";
  date?: Maybe<DateMetadata>;
  type: AdjustmentType;
};

export interface BookingOptionsInput {
  checkInTime?: InputMaybe<Scalars["String"]["input"]>;
  checkOutTime?: InputMaybe<Scalars["String"]["input"]>;
  maxMonthsBeforeBooking?: InputMaybe<Scalars["Int"]["input"]>;
  minDaysBeforeBooking?: InputMaybe<Scalars["Int"]["input"]>;
  minNights?: InputMaybe<Scalars["Int"]["input"]>;
}

export type BookingOptionsOperationClickstreamData = {
  __typename?: "BookingOptionsOperationClickstreamData";
  mutationFailureClickstreamData: EgClickstreamData;
  mutationSuccessClickstreamData: EgClickstreamData;
  queryFailureClickstreamData: EgClickstreamData;
  querySuccessClickstreamData: EgClickstreamData;
};

export type BookingOptionsSection = {
  __typename?: "BookingOptionsSection";
  subSections: Array<BookingOptionsSubSection>;
  suggestion?: Maybe<BookingOptionsSuggestion>;
  title: Scalars["String"]["output"];
};

export type BookingOptionsSubSection = {
  __typename?: "BookingOptionsSubSection";
  input: BookingOptionsUserInput;
  selectedClickstreamData: EgClickstreamData;
};

export type BookingOptionsSuggestion = {
  __typename?: "BookingOptionsSuggestion";
  hiddenClickstreamData?: Maybe<EgClickstreamData>;
  shownClickstreamData?: Maybe<EgClickstreamData>;
  text: EgdsText;
};

export type BookingOptionsUserInput = EgdsBasicSelect | EgdsBasicStepInput;

export type BookingRefundEntry = {
  __typename?: "BookingRefundEntry";
  additionalRefundInput?: Maybe<EgdsNumberInputField>;
  amountPaidBalance?: Maybe<Scalars["Float"]["output"]>;
  approverGroup?: Maybe<Array<EgdsTextInputField>>;
  refundCalculationGroup?: Maybe<AgentToolsRefundCalculation>;
  reset?: Maybe<UiSecondaryButton>;
  title: Scalars["String"]["output"];
  waivingSource?: Maybe<AgentToolsRadioGroup>;
};

export type BookingServiceCancel = {
  __typename?: "BookingServiceCancel";
  confirmCancellation: BookingServicingConfirmCancellation;
  headsUpPresentation: BookingServicingCancelHeadsUp;
  reviewCancellation: BookingServicingReviewCancellation;
};

export type BookingServiceChange = {
  __typename?: "BookingServiceChange";
  messagingElements: Array<BookingServicingMessagingElement>;
  originalBookingPriceDetails?: Maybe<BookingServicingPriceDetails>;
  presentation: BookingServicingChangePresentation;
  redirectAction?: Maybe<BookingServicingChangeRedirectPresentation>;
};

export type BookingServiceChangeMessagingElementsArgs = {
  originalBookingId: Scalars["String"]["input"];
};

export type BookingServiceChangeOriginalBookingPriceDetailsArgs = {
  ignoreSelectTravelers?: InputMaybe<Scalars["Boolean"]["input"]>;
  originalBookingId: Scalars["String"]["input"];
};

export type BookingServiceChangePresentationArgs = {
  criteriaInput: BookingServicingChangeCriteriaInput;
};

export type BookingServiceChangeRedirectActionArgs = {
  input: BookingServicingChangeCriteriaInput;
};

export type BookingServiceDetails = {
  __typename?: "BookingServiceDetails";
  detailsAndPriceSummaryView: BookingServicingDetailsAndPriceSummaryView;
  playbackSectionView: BookingServicingPlaybackSectionView;
  travelersPlaybackView: BookingServicingTravelersPlaybackView;
};

export type BookingServicingAccept = {
  __typename?: "BookingServicingAccept";
  acceptConfirmationLoading: BookingServicingAcceptConfirmation;
  headsUp: BookingServicingHeadsUp;
  reviewAccept: BookingServicingAcceptReview;
};

export type BookingServicingAcceptConfirmation = {
  __typename?: "BookingServicingAcceptConfirmation";
  response: BookingServicingAcceptConfirmationResponse;
};

export type BookingServicingAcceptConfirmationElements =
  | BookingServicingAcceptInformationCards
  | BookingServicingFlightsTripDetailedCard
  | BookingServicingInformationBanner;

export type BookingServicingAcceptConfirmationFooter = {
  __typename?: "BookingServicingAcceptConfirmationFooter";
  buttons: Array<BookingServicingButton>;
};

export type BookingServicingAcceptConfirmationPresentation = {
  __typename?: "BookingServicingAcceptConfirmationPresentation";
  content: Array<BookingServicingAcceptConfirmationElements>;
};

export type BookingServicingAcceptConfirmationResponse =
  | BookingServicingAcceptConfirmationSuccess
  | BookingServicingConfirmLoading
  | BookingServicingError;

export type BookingServicingAcceptConfirmationSuccess = {
  __typename?: "BookingServicingAcceptConfirmationSuccess";
  confirmationFooter?: Maybe<BookingServicingFooter>;
  /** @deprecated Replace with generic BookingServicingSummaryPresentation type */
  content: BookingServicingAcceptConfirmationPresentation;
  /** @deprecated Replace with generic BookingServicingFooter type */
  footer: BookingServicingAcceptConfirmationFooter;
  /** @deprecated Heading is included in BookingServicingSummaryPresentation */
  heading: BookingServicingHeading;
  presentation?: Maybe<BookingServicingSummaryPresentation>;
};

export interface BookingServicingAcceptFlightInput {
  airRecordLocator: Scalars["String"]["input"];
  obid?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingAcceptFlightInputType = {
  __typename?: "BookingServicingAcceptFlightInputType";
  airRecordLocator: Scalars["String"]["output"];
  obid?: Maybe<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingAcceptInformationCards = {
  __typename?: "BookingServicingAcceptInformationCards";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  heading: Scalars["String"]["output"];
  policyCards: Array<BookingServicingInformationCard>;
};

export type BookingServicingAcceptReview = {
  __typename?: "BookingServicingAcceptReview";
  content: BookingServicingReviewAcceptContent;
  toolbar: BookingServicingToolbar;
};

export type BookingServicingAcceptView = {
  __typename?: "BookingServicingAcceptView";
  acceptInputs: BookingServicingAcceptFlightInputType;
  acceptView: BookingServicingAcceptViews;
};

export type BookingServicingAcceptViews = "CONFIRMATION" | "HEADS_UP" | "REVIEW";

export type BookingServicingActionablePlayback = {
  __typename?: "BookingServicingActionablePlayback";
  action: BookingServicingClientAction;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingAppNavigation = BookingServicingClientAction & {
  __typename?: "BookingServicingAppNavigation";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  destination: BookingServicingViews;
};

export type BookingServicingBasicCheckBox = BookingServicingCheckBox & {
  __typename?: "BookingServicingBasicCheckBox";
  checkbox: EgdsBasicCheckBox;
};

export type BookingServicingBreakdownItem = {
  __typename?: "BookingServicingBreakdownItem";
  accessibilityMessage: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type BookingServicingButton = {
  __typename?: "BookingServicingButton";
  button: EgdsButton;
  clientAction: BookingServicingClientAction;
  iconPosition?: Maybe<EgdsIconPosition>;
};

export type BookingServicingCancelConfirmationContentElement =
  | BookingServicingCard
  | BookingServicingFlightRefundSummaryCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingItemsBreakdownCard
  | BookingServicingMessages
  | BookingServicingMessagingElement;

export type BookingServicingCancelConfirmationLoaded = {
  __typename?: "BookingServicingCancelConfirmationLoaded";
  response: BookingServicingCancelConfirmationResponse;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingCancelConfirmationResponse =
  | BookingServicingCancelConfirmationSuccess
  | BookingServicingError
  | CancelConfirmationError;

export type BookingServicingCancelConfirmationSuccess = {
  __typename?: "BookingServicingCancelConfirmationSuccess";
  /** @deprecated Deprecated in favor of new type, replace with presentation */
  content: CancelConfirmationPresentation;
  footer: CancelConfirmationFooter;
  presentation?: Maybe<BookingServicingSummaryPresentation>;
};

export interface BookingServicingCancelCriteriaInput {
  flightsCriteriaInput: BookingServicingCancelFlightInput;
}

export type BookingServicingCancelExitDialog = {
  __typename?: "BookingServicingCancelExitDialog";
  content: Array<BookingServicingElement>;
  displayAnalytics: ClientSideAnalytics;
  graphic?: Maybe<UiGraphic>;
  sheet: EgdsSheet;
};

export interface BookingServicingCancelFlightInput {
  airRecordLocator?: InputMaybe<Scalars["String"]["input"]>;
  cancelType: BookingServicingCancelType;
  obid?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
  useCache?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type BookingServicingCancelFlightInputType = {
  __typename?: "BookingServicingCancelFlightInputType";
  airRecordLocator: Scalars["String"]["output"];
  cancelType: BookingServicingCancelType;
  obid?: Maybe<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingCancelHeadsUp = {
  __typename?: "BookingServicingCancelHeadsUp";
  headsUpLoaded: BookingServicingCancelHeadsUpLoaded;
  headsUpLoading: BookingServicingCancelHeadsUpLoading;
};

export type BookingServicingCancelHeadsUpHeadsUpLoadedArgs = {
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type BookingServicingCancelHeadsUpHeadsUpLoadingArgs = {
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type BookingServicingCancelHeadsUpLoaded = {
  __typename?: "BookingServicingCancelHeadsUpLoaded";
  /** @deprecated Use response field */
  content: BookingServicingRulesAndRestrictionsPresentation;
  /** @deprecated Use response field */
  footer: BookingServicingCardCallToAction;
  /** @deprecated Deprecated in favor of BookingServicingToolbar, replace with BookingServicingToolbar */
  goBackAction?: Maybe<BookingServicingResourceLinkAction>;
  /** @deprecated Use response field */
  graphic?: Maybe<UiGraphic>;
  presentation?: Maybe<BookingServicingHeadsUp>;
  response: HeadsUpResponse;
  /** @deprecated Use toolbar inside contentItems */
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingCancelHeadsUpLoading = {
  __typename?: "BookingServicingCancelHeadsUpLoading";
  goBackAction?: Maybe<BookingServicingResourceLinkAction>;
  numberOfCards: Scalars["Int"]["output"];
  rulesAndRestrictionsLink: EgdsStandardLink;
  title: Scalars["String"]["output"];
};

export type BookingServicingCancelType = "INVOLUNTARY" | "VOLUNTARY";

export type BookingServicingCancelView = {
  __typename?: "BookingServicingCancelView";
  cancelInputs: BookingServicingCancelFlightInputType;
  cancelView: BookingServicingCancelViews;
};

export type BookingServicingCancelViews = "CONFIRMATION" | "HEADS_UP" | "REVIEW";

export type BookingServicingCancellationAction = BookingServicingClientAction & {
  __typename?: "BookingServicingCancellationAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingCancellationContentElement =
  | BookingServicingCard
  | BookingServicingFlightRefundSummaryCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingItemsBreakdownCard
  | BookingServicingMessages
  | BookingServicingMessagingElement;

export type BookingServicingCard = {
  __typename?: "BookingServicingCard";
  area?: Maybe<Scalars["String"]["output"]>;
  callToAction?: Maybe<BookingServicingCardCallToAction>;
  content: Array<BookingServicingCardElement>;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  theme?: Maybe<BookingServicingCardTheme>;
  title: Scalars["String"]["output"];
};

export type BookingServicingCardCallToAction = {
  __typename?: "BookingServicingCardCallToAction";
  buttons?: Maybe<Array<BookingServicingButton>>;
  /** @deprecated Deprecated in favor of a list of elements, replace with elements */
  element: EgdsButton;
  /** @deprecated Deprecated in favor of a list of buttons, replace with buttons */
  elements?: Maybe<Array<EgdsButton>>;
  footerText?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingCardElement =
  | BookingServicingCardCallToAction
  | BookingServicingCheckboxesArea
  | BookingServicingCustomTriggerDatePicker
  | BookingServicingCustomTriggerTravelerSelector
  | BookingServicingLink
  | BookingServicingSearchForm
  | BookingServicingText
  | BookingServicingTexts;

export type BookingServicingCardTheme = "NEGATIVE";

export interface BookingServicingChangeCriteriaInput {
  flightsInput?: InputMaybe<BookingChangeFlightInput>;
}

export interface BookingServicingChangeErrorInput {
  adults?: InputMaybe<Scalars["String"]["input"]>;
  backNavigation?: InputMaybe<Scalars["String"]["input"]>;
  changeType?: InputMaybe<Scalars["String"]["input"]>;
  checkInDate?: InputMaybe<Scalars["String"]["input"]>;
  checkOutDate?: InputMaybe<Scalars["String"]["input"]>;
  children?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  errorType?: InputMaybe<Scalars["String"]["input"]>;
  itineraryNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingChangeFormError = {
  __typename?: "BookingServicingChangeFormError";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type BookingServicingChangeFormResponse = {
  __typename?: "BookingServicingChangeFormResponse";
  response?: Maybe<BookingServicingChangeFormResponseType>;
};

export type BookingServicingChangeFormResponseType =
  | BookingServicingChangeFormError
  | BookingServicingChangeFormSuccess;

export type BookingServicingChangeFormSuccess = {
  __typename?: "BookingServicingChangeFormSuccess";
  productChangeform?: Maybe<BookingServicingProductChangeForm>;
};

export type BookingServicingChangeLodging = {
  __typename?: "BookingServicingChangeLodging";
  changeLodging: BookingServicingChangeLodgingResponse;
  changeLodgingSoftChanges: BookingServicingChangeLodgingSoftChangesResponse;
  directWordPresentation: BookingServicingDirectWordPresentation;
  /** @deprecated Use errorView instead */
  errorPresentation: BookingServicingChangePresentation;
  errorView: BookingServicingErrorView;
  messaging?: Maybe<Array<BookingServicingMessagingElement>>;
  /** @deprecated Use changeLodging instead */
  presentation: BookingServicingChangePresentation;
  /** @deprecated Use changeLodgingSoftChanges instead */
  softChangePresentation: BookingServicingChangeLodgingDetailsResponse;
};

export type BookingServicingChangeLodgingChangeLodgingArgs = {
  confirmedChangeTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingChangeLodgingSoftChangesArgs = {
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingDirectWordPresentationArgs = {
  changeType?: InputMaybe<Scalars["String"]["input"]>;
  errorType?: InputMaybe<Scalars["String"]["input"]>;
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingErrorPresentationArgs = {
  changeLodgingInput: BookingServicingChangeLodgingInput;
  errorInput: BookingServicingChangeErrorInput;
};

export type BookingServicingChangeLodgingErrorViewArgs = {
  changeLodgingInput: BookingServicingChangeLodgingInput;
  errorInput: BookingServicingChangeLodgingErrorInput;
};

export type BookingServicingChangeLodgingMessagingArgs = {
  confirmedChangeTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  input?: InputMaybe<BookingServicingChangeLodgingInput>;
};

export type BookingServicingChangeLodgingPresentationArgs = {
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingSoftChangePresentationArgs = {
  input: BookingServicingChangeLodgingInput;
};

export type BookingServicingChangeLodgingContent = BookingServicingChangeLodgingSuccess | BookingServicingError;

export type BookingServicingChangeLodgingDetailsResponse =
  | BookingServicingChangeLodgingFailure
  | BookingServicingChangePresentation;

export interface BookingServicingChangeLodgingErrorInput {
  adults?: InputMaybe<Scalars["String"]["input"]>;
  backNavigation?: InputMaybe<Scalars["String"]["input"]>;
  changeType?: InputMaybe<Scalars["String"]["input"]>;
  checkInDate?: InputMaybe<Scalars["String"]["input"]>;
  checkOutDate?: InputMaybe<Scalars["String"]["input"]>;
  children?: InputMaybe<Scalars["String"]["input"]>;
  childrenAges?: InputMaybe<Array<Scalars["String"]["input"]>>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  errorType?: InputMaybe<Scalars["String"]["input"]>;
  itineraryNumber?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingChangeLodgingFailure = {
  __typename?: "BookingServicingChangeLodgingFailure";
  action?: Maybe<BookingServicingClientAction>;
};

export interface BookingServicingChangeLodgingInput {
  orderLineGuid: Scalars["String"]["input"];
  orderNumber: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
}

export type BookingServicingChangeLodgingMutationResponse = {
  __typename?: "BookingServicingChangeLodgingMutationResponse";
  response: BookingServicingChangeLodgingMutationResponseState;
};

export type BookingServicingChangeLodgingMutationResponseState =
  | BookingServicingChangeLodgingFailure
  | BookingServicingChangeLodgingMutationSuccess;

export type BookingServicingChangeLodgingMutationSuccess = {
  __typename?: "BookingServicingChangeLodgingMutationSuccess";
  action?: Maybe<BookingServicingClientAction>;
};

export type BookingServicingChangeLodgingResponse = {
  __typename?: "BookingServicingChangeLodgingResponse";
  content: BookingServicingChangeLodgingContent;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingChangeLodgingSoftChangesContent =
  | BookingServicingChangeLodgingSoftChangesSuccess
  | BookingServicingError;

export type BookingServicingChangeLodgingSoftChangesResponse = {
  __typename?: "BookingServicingChangeLodgingSoftChangesResponse";
  content: BookingServicingChangeLodgingSoftChangesContent;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingChangeLodgingSoftChangesSuccess = {
  __typename?: "BookingServicingChangeLodgingSoftChangesSuccess";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  footer: BookingServicingFooter;
  heading?: Maybe<Scalars["String"]["output"]>;
  sections: Array<BookingServicingFormSection>;
};

export type BookingServicingChangeLodgingSuccess = {
  __typename?: "BookingServicingChangeLodgingSuccess";
  banner?: Maybe<BookingServicingMessagingElement>;
  cards?: Maybe<Array<BookingServicingCard>>;
  criteria?: Maybe<PropertySearchCriteria>;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  heading?: Maybe<Scalars["String"]["output"]>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingChangePresentation = {
  __typename?: "BookingServicingChangePresentation";
  criteria?: Maybe<BookingServicingSearchCriteria>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  errorAction?: Maybe<BookingServicingClientAction>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]["output"]>;
  immediateSheetAction?: Maybe<BookingServicingSheetAction>;
  messaging?: Maybe<Array<BookingServicingMessagingElement>>;
  navigationAction?: Maybe<UiLinkAction>;
  primaryButton?: Maybe<BookingServicingButton>;
  secondaryButton?: Maybe<BookingServicingButton>;
  sections?: Maybe<Array<BookingServicingElement>>;
  subText?: Maybe<Array<EgdsText>>;
  /** @deprecated Deprecated in favor of a nullable field heading */
  title: Scalars["String"]["output"];
  toolbar?: Maybe<BookingServicingToolbar>;
  virtualAgent?: Maybe<BookingServicingVirtualAgentControl>;
};

export type BookingServicingChangeRedirectAction = BookingServicingClientAction & {
  __typename?: "BookingServicingChangeRedirectAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  redirectURI: Uri;
};

export type BookingServicingChangeRedirectPresentation =
  | BookingServicingAppNavigation
  | BookingServicingChangeRedirectAction
  | BookingServicingErrorSummary;

export interface BookingServicingChangeSelectionInput {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type BookingServicingChangeSelectionInputType = {
  __typename?: "BookingServicingChangeSelectionInputType";
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingChangeView = {
  __typename?: "BookingServicingChangeView";
  changeInputs: BookingChangeFlightInputType;
  changeView: BookingServicingChangeViews;
};

export type BookingServicingChangeViews = "CHANGE_FORM";

export interface BookingServicingCheckBox {
  checkbox: EgdsBasicCheckBox;
}

export type BookingServicingCheckboxesArea = {
  __typename?: "BookingServicingCheckboxesArea";
  /** @deprecated Deprecated in favor of a nullable field, replace with selectAll */
  applyAll: EgdsBasicCheckBox;
  /** @deprecated Deprecated in favor of using BookingServicingCheckbox, replace with selectAll */
  checkAll?: Maybe<EgdsBasicCheckBox>;
  checkboxes?: Maybe<Array<BookingServicingCheckBox>>;
  /** @deprecated Deprecated in favor of using BookingServicingCheckbox, replace with checkboxes */
  individuals: Array<EgdsBasicCheckBox>;
  selectAll?: Maybe<BookingServicingCheckBox>;
  validations?: Maybe<Array<BookingServicingTravelerInputValidation>>;
};

export type BookingServicingClickstreamAnalyticsData = {
  __typename?: "BookingServicingClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]["output"]>;
  eventVersion?: Maybe<Scalars["String"]["output"]>;
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type BookingServicingClient = "VIRTUAL_AGENT" | "VNEXT" | "WEB";

export interface BookingServicingClientAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
}

export type BookingServicingCloseAction = BookingServicingClientAction & {
  __typename?: "BookingServicingCloseAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingComposableTexts =
  | BookingServicingLink
  | EgdsGraphicText
  | EgdsHeading
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText;

export type BookingServicingConfirmCancellation = {
  __typename?: "BookingServicingConfirmCancellation";
  confirmCancellationLoading: BookingServicingConfirmLoading;
};

export type BookingServicingConfirmLoading = {
  __typename?: "BookingServicingConfirmLoading";
  heading?: Maybe<Scalars["String"]["output"]>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingConfirmationContent = BookingServicingConfirmationSuccess | BookingServicingError;

export interface BookingServicingConfirmationInput {
  flightConfirmationInput?: InputMaybe<BookingServicingFlightInput>;
  lodgingConfirmationInput?: InputMaybe<BookingServicingLodgingConfirmationInput>;
}

export type BookingServicingConfirmationResponse = {
  __typename?: "BookingServicingConfirmationResponse";
  content: BookingServicingConfirmationContent;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingConfirmationSuccess = {
  __typename?: "BookingServicingConfirmationSuccess";
  elements: Array<BookingServicingSummary>;
  footer: BookingServicingFooter;
  header: BookingServicingHeading;
};

export type BookingServicingCreditsFailure = {
  __typename?: "BookingServicingCreditsFailure";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  buttons: Array<BookingServicingButton>;
  /** @deprecated Moving to EGClickstream event tracking */
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  emptyState: UiEmptyState;
};

export type BookingServicingCreditsLoading = {
  __typename?: "BookingServicingCreditsLoading";
  heading: Scalars["String"]["output"];
};

export type BookingServicingCreditsResult =
  | BookingServicingCreditsFailure
  | BookingServicingCreditsLoading
  | BookingServicingCreditsSuccess;

export type BookingServicingCreditsSection = {
  __typename?: "BookingServicingCreditsSection";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  banner?: Maybe<UiBanner>;
  cards?: Maybe<Array<BookingServicingOfferCard>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  helpMessages?: Maybe<Array<BookingServicingComposableTexts>>;
  messages?: Maybe<Array<BookingServicingComposableTexts>>;
  unavailableCards?: Maybe<Array<BookingServicingOfferCard>>;
  unavailableCardsExpando?: Maybe<EgdsExpandoLink>;
};

export type BookingServicingCreditsSuccess = {
  __typename?: "BookingServicingCreditsSuccess";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  banner?: Maybe<UiBanner>;
  content: Array<BookingServicingComposableTexts>;
  couponsSection?: Maybe<BookingServicingCreditsSection>;
  credits?: Maybe<Array<BookingServicingOfferCard>>;
  /** @deprecated Moving to EGClickstream event tracking */
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingCustomTrigger = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type BookingServicingCustomTriggerDatePicker = {
  __typename?: "BookingServicingCustomTriggerDatePicker";
  accessibility: Scalars["String"]["output"];
  datePicker: EgdsSingleDatePicker;
  trigger: BookingServicingCustomTrigger;
  validationMessages?: Maybe<Array<BookingServicingDatePickerValidation>>;
};

export type BookingServicingCustomTriggerTravelerSelector = {
  __typename?: "BookingServicingCustomTriggerTravelerSelector";
  accessibility: Scalars["String"]["output"];
  selector: Array<GraphQlPair>;
  trigger: BookingServicingCustomTrigger;
};

export type BookingServicingDatePickerValidation = {
  __typename?: "BookingServicingDatePickerValidation";
  message: Scalars["String"]["output"];
  ruleName: Scalars["String"]["output"];
};

export type BookingServicingDeeplinkAction = BookingServicingClientAction & {
  __typename?: "BookingServicingDeeplinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  deeplink: Uri;
};

export type BookingServicingDetailsAndPriceSummary = {
  __typename?: "BookingServicingDetailsAndPriceSummary";
  bookingDetails?: Maybe<BookingServicingFlightsTripSummaryCard>;
  bookingPriceSummary?: Maybe<BookingServicingFlightsPriceSummaryCard>;
};

export type BookingServicingDetailsAndPriceSummaryView = {
  __typename?: "BookingServicingDetailsAndPriceSummaryView";
  detailsAndPriceSummaryView: BookingServicingDetailsAndPriceSummary;
};

export type BookingServicingDetailsAndPriceSummaryViewDetailsAndPriceSummaryViewArgs = {
  criteriaInput: BookingServicingDetailsInput;
  strategy: BookingServicingDetailsStrategy;
};

export interface BookingServicingDetailsFlightInput {
  airRecordLocator?: InputMaybe<Scalars["String"]["input"]>;
  obid?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface BookingServicingDetailsInput {
  flightsCriteriaInput?: InputMaybe<BookingServicingDetailsFlightInput>;
}

export type BookingServicingDetailsStrategy = "BOOKING_COLLAPSED_DETAILS" | "BOOKING_DETAILS" | "BOOKING_PRICE_DETAILS";

export type BookingServicingDirectWordPresentation = {
  __typename?: "BookingServicingDirectWordPresentation";
  button?: Maybe<BookingServicingButton>;
};

export type BookingServicingElement =
  | BookingServicingButton
  | BookingServicingCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingFormSection
  | BookingServicingLink
  | BookingServicingText
  | BookingServicingTextList;

export type BookingServicingError = {
  __typename?: "BookingServicingError";
  /** @deprecated Use clickstreamPayload instead */
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  buttons?: Maybe<Array<BookingServicingButton>>;
  clickstreamPayload?: Maybe<BookingServicingClickstreamAnalyticsData>;
  description: Array<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of analyticsPayload, replace with analyticsPayload */
  displayAnalytics: Array<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
  virtualAgent?: Maybe<BookingServicingVirtualAgentControl>;
};

export type BookingServicingErrorSummary = {
  __typename?: "BookingServicingErrorSummary";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  errorMessages: Array<UiBanner>;
};

export type BookingServicingErrorView = {
  __typename?: "BookingServicingErrorView";
  toolbar?: Maybe<BookingServicingToolbar>;
  view: BookingServicingError;
  /** @deprecated use virtualAgent in BookingServicingError instead */
  virtualAgent?: Maybe<BookingServicingVirtualAgentControl>;
};

export type BookingServicingExitDialog = {
  __typename?: "BookingServicingExitDialog";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  content: Array<BookingServicingElement>;
  dialogId: Scalars["String"]["output"];
  /** @deprecated use displayAnalyticsList, replace with displayAnalyticsList */
  displayAnalytics: ClientSideAnalytics;
  displayAnalyticsList: Array<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  sheet: EgdsSheet;
};

export type BookingServicingExpando = {
  __typename?: "BookingServicingExpando";
  collapsedLabel: BookingServicingText;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  expandedLabel: BookingServicingText;
  expando: EgdsExpando;
};

export interface BookingServicingFlightHeadsUpCriteriaInput {
  airRecordLocator?: InputMaybe<Scalars["String"]["input"]>;
  obid?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingFlightHeadsUpCriteriaInputType = {
  __typename?: "BookingServicingFlightHeadsUpCriteriaInputType";
  airRecordLocator?: Maybe<Scalars["String"]["output"]>;
  obid?: Maybe<Scalars["String"]["output"]>;
  tripId?: Maybe<Scalars["String"]["output"]>;
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export interface BookingServicingFlightInput {
  airRecordLocator?: InputMaybe<Scalars["String"]["input"]>;
  obid?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
  useCache?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type BookingServicingFlightLegDetails = {
  __typename?: "BookingServicingFlightLegDetails";
  badge?: Maybe<EgdsStandardBadge>;
  departureDate: Scalars["String"]["output"];
  departureTime: Scalars["String"]["output"];
  departureTimeAccessibilityMessage: Scalars["String"]["output"];
  flightInfo: EgdsGraphicText;
  flightsDetailedJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  originAndDestination: Scalars["String"]["output"];
  originAndDestinationAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  stopOverDetails?: Maybe<Scalars["String"]["output"]>;
};

export interface BookingServicingFlightPlaybackSectionCriteriaInput {
  jcid?: InputMaybe<Scalars["String"]["input"]>;
  obid?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingFlightRefundSummaryCard = {
  __typename?: "BookingServicingFlightRefundSummaryCard";
  /** @deprecated Deprecated in favor of a list of description messages */
  description: Scalars["String"]["output"];
  descriptionMessages?: Maybe<Array<EgdsSpannableText>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"]["output"];
  refundDetails?: Maybe<BookingServicingPriceDetails>;
  totalRefundAmount?: Maybe<BookingServicingBreakdownItem>;
};

export type BookingServicingFlightSearch = {
  __typename?: "BookingServicingFlightSearch";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  playbackOptionsHeading?: Maybe<Scalars["String"]["output"]>;
  priceDisclaimer?: Maybe<Scalars["String"]["output"]>;
};

export interface BookingServicingFlightSearchCriteriaInput {
  jcid?: InputMaybe<Scalars["String"]["input"]>;
  journeyCriteriaInput?: InputMaybe<Array<FlightsJourneyCriteriaInput>>;
  obid: Scalars["String"]["input"];
}

export interface BookingServicingFlightSearchInput {
  jcid?: InputMaybe<Scalars["String"]["input"]>;
  journeyCriteriaInput?: InputMaybe<Array<FlightsJourneyCriteriaInput>>;
  obid: Scalars["String"]["input"];
}

export type BookingServicingFlightsDetailedCardDescription = {
  __typename?: "BookingServicingFlightsDetailedCardDescription";
  description?: Maybe<Array<EgdsSpannableText>>;
  heading: Scalars["String"]["output"];
  travelReferenceNumbers?: Maybe<Array<EgdsSpannableText>>;
};

export type BookingServicingFlightsDetailedCardFlightDetails = {
  __typename?: "BookingServicingFlightsDetailedCardFlightDetails";
  legs: Array<BookingServicingFlightLegDetails>;
  travelerTripSummary?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingFlightsDetailedCardSection = {
  __typename?: "BookingServicingFlightsDetailedCardSection";
  contents: Array<BookingServicingFlightsDetailedCardSectionContent>;
};

export type BookingServicingFlightsDetailedCardSectionContent =
  | BookingServicingFlightsDetailedCardDescription
  | BookingServicingFlightsDetailedCardFlightDetails
  | BookingServicingFlightsDetailedCardTravelers;

export type BookingServicingFlightsDetailedCardTravelers = {
  __typename?: "BookingServicingFlightsDetailedCardTravelers";
  heading: Scalars["String"]["output"];
  travelers: Array<BookingServicingGraphicText>;
};

export type BookingServicingFlightsPriceSummaryCard = {
  __typename?: "BookingServicingFlightsPriceSummaryCard";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"]["output"];
  priceDetails: PricePresentation;
};

export type BookingServicingFlightsSearchForm = {
  __typename?: "BookingServicingFlightsSearchForm";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type BookingServicingFlightsTripDetailedCard = {
  __typename?: "BookingServicingFlightsTripDetailedCard";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  sections: Array<BookingServicingFlightsDetailedCardSection>;
};

export type BookingServicingFlightsTripSummaryCard = {
  __typename?: "BookingServicingFlightsTripSummaryCard";
  badge?: Maybe<EgdsStandardBadge>;
  description: Array<EgdsSpannableText>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  expando?: Maybe<EgdsExpandoListItem>;
  heading?: Maybe<Scalars["String"]["output"]>;
  legs: Array<BookingServicingFlightLegDetails>;
  subheading?: Maybe<Scalars["String"]["output"]>;
  travelers?: Maybe<Array<EgdsGraphicText>>;
  travelersTripType?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingFlow = "INVOLUNTARY" | "VOLUNTARY";

export type BookingServicingFooter = {
  __typename?: "BookingServicingFooter";
  buttons: Array<BookingServicingButton>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingFormAnalytics = {
  __typename?: "BookingServicingFormAnalytics";
  analytics: ClientSideAnalytics;
  id: Scalars["String"]["output"];
};

export type BookingServicingFormInputContainer = {
  __typename?: "BookingServicingFormInputContainer";
  actionButton?: Maybe<BookingServicingButton>;
  actionDescription?: Maybe<Scalars["String"]["output"]>;
  analyticsList?: Maybe<Array<BookingServicingFormAnalytics>>;
  inputFields?: Maybe<Array<BookingServicingFormInputField>>;
  label?: Maybe<Scalars["String"]["output"]>;
  subText?: Maybe<Array<Scalars["String"]["output"]>>;
  validationAnalyticsList?: Maybe<Array<BookingServicingFormAnalytics>>;
};

export type BookingServicingFormInputField = BookingServicingFormOptions | EgdsTextAreaInputField | EgdsTextInputField;

export type BookingServicingFormOptions = {
  __typename?: "BookingServicingFormOptions";
  expando: EgdsExpando;
  optionGroup: BookingServicingOptionGroup;
};

export type BookingServicingFormSection = {
  __typename?: "BookingServicingFormSection";
  heading?: Maybe<Scalars["String"]["output"]>;
  subSections?: Maybe<Array<BookingServicingFormSubSection>>;
};

export type BookingServicingFormSubSection = {
  __typename?: "BookingServicingFormSubSection";
  heading?: Maybe<Scalars["String"]["output"]>;
  inputContainers?: Maybe<Array<BookingServicingFormInputContainer>>;
  subText?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type BookingServicingGraphicText = {
  __typename?: "BookingServicingGraphicText";
  graphic: UiGraphic;
  text: Scalars["String"]["output"];
};

export type BookingServicingHeading = {
  __typename?: "BookingServicingHeading";
  heading: Scalars["String"]["output"];
  link?: Maybe<EgdsStandardLink>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingHeadsUp = {
  __typename?: "BookingServicingHeadsUp";
  content: BookingServicingHeadsUpContent;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingHeadsUpContent = BookingServicingError | BookingServicingHeadsUpSuccess;

export interface BookingServicingHeadsUpCriteriaInput {
  flightCriteriaInput?: InputMaybe<BookingServicingFlightHeadsUpCriteriaInput>;
  lodgingCriteriaInput?: InputMaybe<BookingServicingLodgingHeadsUpCriteriaInput>;
}

export type BookingServicingHeadsUpCriteriaInputType = {
  __typename?: "BookingServicingHeadsUpCriteriaInputType";
  flightCriteriaInput?: Maybe<BookingServicingFlightHeadsUpCriteriaInputType>;
  lodgingCriteriaInput?: Maybe<BookingServicingLodgingHeadsUpCriteriaInputType>;
};

export type BookingServicingHeadsUpPresentation = {
  __typename?: "BookingServicingHeadsUpPresentation";
  presentation?: Maybe<BookingServicingHeadsUp>;
};

export type BookingServicingHeadsUpPresentationPresentationArgs = {
  context: ContextInput;
  criteriaInput: BookingServicingHeadsUpCriteriaInput;
  strategy: BookingServicingStrategyInput;
};

export type BookingServicingHeadsUpSuccess = {
  __typename?: "BookingServicingHeadsUpSuccess";
  /** @deprecated Replace with elements BookingServicingInformationElement type */
  contents: Array<BookingServicingInformationCard>;
  displayAnalytics: Array<ClientSideAnalytics>;
  elements: Array<BookingServicingInformationElement>;
  footer: BookingServicingFooter;
  heading: BookingServicingHeading;
};

export type BookingServicingHeadsUpView = {
  __typename?: "BookingServicingHeadsUpView";
  criteriaInput: BookingServicingHeadsUpCriteriaInputType;
  strategy: BookingServicingStrategyType;
};

export type BookingServicingInformationBanner = {
  __typename?: "BookingServicingInformationBanner";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  graphic: UiGraphic;
  text: Scalars["String"]["output"];
};

export type BookingServicingInformationCard = {
  __typename?: "BookingServicingInformationCard";
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
  links?: Maybe<Array<EgdsStandardLink>>;
  texts: Array<Scalars["String"]["output"]>;
};

export type BookingServicingInformationDialog = {
  __typename?: "BookingServicingInformationDialog";
  content: Array<Scalars["String"]["output"]>;
  footerButton: EgdsButton;
};

export type BookingServicingInformationElement = {
  __typename?: "BookingServicingInformationElement";
  descriptions: Array<Scalars["String"]["output"]>;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
  links?: Maybe<Array<EgdsStandardLink>>;
};

export type BookingServicingInformationSection = {
  __typename?: "BookingServicingInformationSection";
  elements: Array<BookingServicingInformationElement>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingIntent = "ACCEPT" | "CANCEL" | "CHANGE";

export type BookingServicingItemsBreakdownCard = {
  __typename?: "BookingServicingItemsBreakdownCard";
  description: Scalars["String"]["output"];
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"]["output"];
  items: Array<BookingServicingBreakdownItem>;
  link?: Maybe<EgdsStandardLink>;
};

export type BookingServicingLink = {
  __typename?: "BookingServicingLink";
  action?: Maybe<BookingServicingClientAction>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  link: EgdsStandardLink;
};

export type BookingServicingLinkTarget = "EXTERNAL" | "INTERNAL";

export interface BookingServicingLodgingConfirmationInput {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  orderLineIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingLodgingCriteriaPlayback = {
  __typename?: "BookingServicingLodgingCriteriaPlayback";
  criteria?: Maybe<Array<EgdsTextListItem>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface BookingServicingLodgingHeadsUpCriteriaInput {
  orderLineGuid: Scalars["String"]["input"];
  orderNumber: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingLodgingHeadsUpCriteriaInputType = {
  __typename?: "BookingServicingLodgingHeadsUpCriteriaInputType";
  orderLineGuid: Scalars["String"]["output"];
  orderNumber: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export interface BookingServicingLodgingReviewInput {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  orderLineIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type BookingServicingLodgingSearch = {
  __typename?: "BookingServicingLodgingSearch";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  footer?: Maybe<BookingServicingFooter>;
  sections?: Maybe<Array<BookingServicingLodgingSearchSection>>;
};

export interface BookingServicingLodgingSearchInput {
  changeType?: InputMaybe<Scalars["String"]["input"]>;
  orderLineId?: InputMaybe<Scalars["String"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria: PropertySearchCriteriaInput;
  tripId: Scalars["String"]["input"];
}

export type BookingServicingLodgingSearchSection = {
  __typename?: "BookingServicingLodgingSearchSection";
  id?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<BookingServicingLodgingSearchSectionItem>>;
  separator?: Maybe<BookingServicingLodgingSearchSectionSeparator>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingLodgingSearchSectionItem =
  | BookingServicingButton
  | BookingServicingHeading
  | BookingServicingInformationElement
  | BookingServicingLodgingCriteriaPlayback
  | BookingServicingMessagingElement
  | BookingServicingOfferCard
  | BookingServicingPolicyDisclaimer
  | BookingServicingPriceDetails
  | PricePresentationSection;

export type BookingServicingLodgingSearchSectionSeparator = {
  __typename?: "BookingServicingLodgingSearchSectionSeparator";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
};

export type BookingServicingMessages = {
  __typename?: "BookingServicingMessages";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"]["output"];
  messages: Array<BookingServicingCard>;
};

export type BookingServicingMessagingElement = {
  __typename?: "BookingServicingMessagingElement";
  component: BookingServicingMessagingElementComponent;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type BookingServicingMessagingElementComponent = EgdsStandardMessagingCard | UiBanner;

export type BookingServicingOfferCard = {
  __typename?: "BookingServicingOfferCard";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use additionalLinkSections instead. Will also support EGClickstream AnalyticsPayload */
  additionalContentSections?: Maybe<Array<EgdsStandardLink>>;
  additionalLinkSections?: Maybe<Array<BookingServicingLink>>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: BookingServicingOfferContent;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  featuredHeader?: Maybe<BookingServicingOfferFeaturedHeader>;
  /** @deprecated Does not allow tracking of multiple analytics. Use displayAnalytics instead */
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
};

export type BookingServicingOfferContent = {
  __typename?: "BookingServicingOfferContent";
  image?: Maybe<Image>;
  priceSection?: Maybe<BookingServicingOfferPriceSection>;
  primarySection: EgdsTextSection;
};

export type BookingServicingOfferFeaturedHeader = {
  __typename?: "BookingServicingOfferFeaturedHeader";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  heading: Scalars["String"]["output"];
  icon: Icon;
};

export type BookingServicingOfferPriceSection = {
  __typename?: "BookingServicingOfferPriceSection";
  priceLockup: EgdsPriceLockup;
};

export type BookingServicingOptionGroup = EgdsBasicRadioGroup | EgdsCheckBoxGroup;

export type BookingServicingPath = "ACCEPT" | "CANCEL" | "CHANGE";

export type BookingServicingPlaybackSection = {
  __typename?: "BookingServicingPlaybackSection";
  element?: Maybe<BookingServicingPlaybackSectionElement>;
  heading?: Maybe<Scalars["String"]["output"]>;
  playbackElement?: Maybe<ProductsPlayback>;
};

export type BookingServicingPlaybackSectionElement = BookingServicingOfferCard | BookingServicingTripPlayback;

export interface BookingServicingPlaybackSectionInput {
  flightCriteriaInput?: InputMaybe<BookingServicingFlightPlaybackSectionCriteriaInput>;
}

export type BookingServicingPlaybackSectionView = {
  __typename?: "BookingServicingPlaybackSectionView";
  playbackSection: BookingServicingPlaybackSection;
};

export type BookingServicingPlaybackSectionViewPlaybackSectionArgs = {
  criteriaInput: BookingServicingPlaybackSectionInput;
};

export type BookingServicingPolicyDisclaimer = {
  __typename?: "BookingServicingPolicyDisclaimer";
  dialog: BookingServicingInformationDialog;
  triggerMessage?: Maybe<EgdsStylizedText>;
  triggerMessageIcon?: Maybe<UiGraphic>;
};

export type BookingServicingPolicySection = {
  __typename?: "BookingServicingPolicySection";
  description?: Maybe<Scalars["String"]["output"]>;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  subtitle?: Maybe<BookingServicingPolicySubtitle>;
  title: Scalars["String"]["output"];
};

export type BookingServicingPolicySubtitle = {
  __typename?: "BookingServicingPolicySubtitle";
  graphic?: Maybe<UiGraphic>;
  text: EgdsStylizedText;
};

export type BookingServicingPrepareChangeCheckoutPayload = {
  __typename?: "BookingServicingPrepareChangeCheckoutPayload";
  lodgingInput?: Maybe<LodgingPrepareChangeCheckout>;
  options?: Maybe<Array<PrepareChangeCheckoutOption>>;
  tripId: Scalars["String"]["output"];
};

export type BookingServicingPreviousView = {
  __typename?: "BookingServicingPreviousView";
  accessibility?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingPriceDetails = {
  __typename?: "BookingServicingPriceDetails";
  content: PricePresentation;
  expando: BookingServicingExpando;
};

export type BookingServicingPriceSummarySection = {
  __typename?: "BookingServicingPriceSummarySection";
  description?: Maybe<Scalars["String"]["output"]>;
  priceBreakdown: PricePresentation;
  title: Scalars["String"]["output"];
};

export type BookingServicingProductChangeForm = BookingServicingActionablePlayback | BookingServicingFlightsSearchForm;

export type BookingServicingProductSearch = BookingServicingFlightSearch | BookingServicingLodgingSearch;

export type BookingServicingRefetch = BookingServicingClientAction & {
  __typename?: "BookingServicingRefetch";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type BookingServicingResourceLinkAction = BookingServicingClientAction & {
  __typename?: "BookingServicingResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  /** @deprecated Use clickstreamPayload instead */
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  clickstreamPayload?: Maybe<BookingServicingClickstreamAnalyticsData>;
  icon?: Maybe<Icon>;
  resource: Uri;
  target: BookingServicingLinkTarget;
  useRelativePath?: Maybe<Scalars["Boolean"]["output"]>;
};

export type BookingServicingReviewAcceptContent = BookingServicingError | BookingServicingReviewAcceptSuccess;

export type BookingServicingReviewAcceptElements =
  | BookingServicingAcceptInformationCards
  | BookingServicingFlightsTripDetailedCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingInformationBanner;

export type BookingServicingReviewAcceptPresentation = {
  __typename?: "BookingServicingReviewAcceptPresentation";
  content: Array<BookingServicingReviewAcceptElements>;
  /** @deprecated Transferring the displayAnalytics field to lower-level components. */
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type BookingServicingReviewAcceptSuccess = {
  __typename?: "BookingServicingReviewAcceptSuccess";
  /** @deprecated Replace with generic BookingServicingSummary type */
  content: BookingServicingReviewAcceptPresentation;
  footer: BookingServicingFooter;
  /** @deprecated No longer needed - page heading is part of BookingServicingSummary */
  heading: BookingServicingHeading;
  presentation?: Maybe<BookingServicingSummaryPresentation>;
};

export type BookingServicingReviewCancellation = {
  __typename?: "BookingServicingReviewCancellation";
  reviewCancellationLoaded: BookingServicingReviewCancellationLoaded;
};

export type BookingServicingReviewCancellationReviewCancellationLoadedArgs = {
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type BookingServicingReviewCancellationError = {
  __typename?: "BookingServicingReviewCancellationError";
  buttons: Array<BookingServicingButton>;
  content: Array<BookingServicingText>;
  /** @deprecated Deprecated in favor of a list of content, replace with content */
  description: Scalars["String"]["output"];
  displayAnalytics: Array<ClientSideAnalytics>;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
};

export type BookingServicingReviewCancellationLoaded = {
  __typename?: "BookingServicingReviewCancellationLoaded";
  response: BookingServicingReviewCancellationResponse;
  /** @deprecated Deprecated in favor of nullable field toolbarSection */
  toolbar: BookingServicingToolbar;
  toolbarSection?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingReviewCancellationResponse =
  | BookingServicingError
  | BookingServicingReviewCancellationError
  | BookingServicingReviewCancellationSuccess;

export type BookingServicingReviewCancellationSuccess = {
  __typename?: "BookingServicingReviewCancellationSuccess";
  /** @deprecated Deprecated in favor of new type, replace with presentation */
  content: ReviewCancellationPresentation;
  /** @deprecated Deprecated in favor of nullable field footerSection */
  footer: ReviewCancellationFooter;
  footerSection?: Maybe<ReviewCancellationFooter>;
  presentation?: Maybe<BookingServicingSummaryPresentation>;
};

export type BookingServicingReviewContent = BookingServicingError | BookingServicingReviewSuccess;

export interface BookingServicingReviewInput {
  flightReviewInput?: InputMaybe<BookingServicingFlightInput>;
  lodgingReviewInput?: InputMaybe<BookingServicingLodgingReviewInput>;
}

export type BookingServicingReviewPresentation = {
  __typename?: "BookingServicingReviewPresentation";
  presentation: BookingServicingReviewResponse;
};

export type BookingServicingReviewPresentationPresentationArgs = {
  context: ContextInput;
  reviewInput: BookingServicingReviewInput;
  strategy: BookingServicingStrategyInput;
};

export type BookingServicingReviewResponse = {
  __typename?: "BookingServicingReviewResponse";
  content: BookingServicingReviewContent;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingReviewSuccess = {
  __typename?: "BookingServicingReviewSuccess";
  elements: Array<BookingServicingSummary>;
  footer: BookingServicingFooter;
  header: BookingServicingHeading;
};

export type BookingServicingRulesAndRestrictionsPresentation = {
  __typename?: "BookingServicingRulesAndRestrictionsPresentation";
  content: Array<BookingServicingElement>;
  displayAnalytics: ClientSideAnalytics;
  footerText?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<EgdsStandardLink>;
  primaryButton?: Maybe<BookingServicingButton>;
};

export type BookingServicingSearchCriteria = PropertySearchCriteria;

export interface BookingServicingSearchCriteriaInput {
  flightCriteriaInput?: InputMaybe<BookingServicingFlightSearchCriteriaInput>;
  flightInput?: InputMaybe<BookingServicingFlightSearchInput>;
  lodgingInput?: InputMaybe<BookingServicingLodgingSearchInput>;
}

export type BookingServicingSearchCriteriaInputType = {
  __typename?: "BookingServicingSearchCriteriaInputType";
  flightCriteriaInput?: Maybe<BookingServicingSearchFlightCriteriaInputType>;
};

export type BookingServicingSearchFlightCriteriaInputType = {
  __typename?: "BookingServicingSearchFlightCriteriaInputType";
  jcid?: Maybe<Scalars["String"]["output"]>;
  obid: Scalars["String"]["output"];
};

export type BookingServicingSearchForm = {
  __typename?: "BookingServicingSearchForm";
  searchForm: FlightSearchInputForm;
};

export type BookingServicingSearchPresentation = {
  __typename?: "BookingServicingSearchPresentation";
  changeForm: BookingServicingChangeFormResponse;
  presentation: BookingServicingSearchResponse;
};

export type BookingServicingSearchPresentationChangeFormArgs = {
  context: ContextInput;
  criteriaInput: BookingServicingSearchCriteriaInput;
  strategy: BookingServicingStrategyInput;
};

export type BookingServicingSearchPresentationPresentationArgs = {
  context: ContextInput;
  criteriaInput: BookingServicingSearchCriteriaInput;
  strategy: BookingServicingStrategyInput;
};

export type BookingServicingSearchResponse = {
  __typename?: "BookingServicingSearchResponse";
  response?: Maybe<BookingServicingSearchResponseType>;
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type BookingServicingSearchResponseType = BookingServicingError | BookingServicingSearchSuccess;

export type BookingServicingSearchSuccess = {
  __typename?: "BookingServicingSearchSuccess";
  productSearch?: Maybe<BookingServicingProductSearch>;
};

export type BookingServicingSearchView = {
  __typename?: "BookingServicingSearchView";
  searchInputs: BookingServicingSearchCriteriaInputType;
  strategy: BookingServicingStrategyType;
};

export type BookingServicingSheetAction = {
  __typename?: "BookingServicingSheetAction";
  dismissAction: BookingServicingResourceLinkAction;
  sheet: EgdsSheet;
  sheetContent: BookingServicingRulesAndRestrictionsPresentation;
};

export interface BookingServicingStrategyInput {
  flow: BookingServicingFlow;
  intent?: InputMaybe<BookingServicingIntent>;
}

export type BookingServicingStrategyType = {
  __typename?: "BookingServicingStrategyType";
  flow: BookingServicingFlow;
  intent?: Maybe<BookingServicingIntent>;
  /** @deprecated Use intent instead */
  path: BookingServicingPath;
};

export type BookingServicingSubmitAction = BookingServicingClientAction & {
  __typename?: "BookingServicingSubmitAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  payload?: Maybe<BookingServicingSubmitActionPayload>;
};

export type BookingServicingSubmitActionPayload = BookingServicingPrepareChangeCheckoutPayload;

export type BookingServicingSummary =
  | BookingServicingCard
  | BookingServicingFlightRefundSummaryCard
  | BookingServicingFlightsTripDetailedCard
  | BookingServicingFlightsTripSummaryCard
  | BookingServicingInformationSection
  | BookingServicingItemsBreakdownCard
  | BookingServicingMessages
  | BookingServicingMessagingElement
  | BookingServicingPlaybackSection
  | BookingServicingPolicySection
  | BookingServicingPriceSummarySection;

export type BookingServicingSummaryPresentation = {
  __typename?: "BookingServicingSummaryPresentation";
  content: Array<BookingServicingSummary>;
  displayAnalytics: Array<ClientSideAnalytics>;
  heading: Scalars["String"]["output"];
};

export type BookingServicingText = {
  __typename?: "BookingServicingText";
  primary: EgdsText;
  secondary?: Maybe<EgdsText>;
};

export type BookingServicingTextList = {
  __typename?: "BookingServicingTextList";
  heading?: Maybe<EgdsHeading>;
  list: EgdsSpannableList;
};

export type BookingServicingTexts = {
  __typename?: "BookingServicingTexts";
  texts: Array<BookingServicingText>;
};

export type BookingServicingToolbar = {
  __typename?: "BookingServicingToolbar";
  dialog?: Maybe<BookingServicingToolbarDialog>;
  navigation: BookingServicingToolbarNavigation;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<BookingServicingToolbarType>;
};

export type BookingServicingToolbarDialog = BookingServicingExitDialog;

export type BookingServicingToolbarNavigation = {
  __typename?: "BookingServicingToolbarNavigation";
  action: BookingServicingClientAction;
  buttonId: Scalars["String"]["output"];
  buttonLabel?: Maybe<Scalars["String"]["output"]>;
  iconLabel: Scalars["String"]["output"];
  navigationType?: Maybe<BookingServicingToolbarNavigationType>;
};

export type BookingServicingToolbarNavigationType = "BACK" | "BACK_DIALOG" | "CLOSE" | "EXPAND_LESS" | "EXPAND_UP";

export type BookingServicingToolbarType = "STANDARD" | "TRANSPARENT";

export type BookingServicingTravelerCheckBox = BookingServicingCheckBox & {
  __typename?: "BookingServicingTravelerCheckBox";
  checkbox: EgdsBasicCheckBox;
  travelerType?: Maybe<BookingServicingTravelerType>;
};

export interface BookingServicingTravelerDetailsInput {
  accessibility?: InputMaybe<Array<Scalars["String"]["input"]>>;
  bedTypeId?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  smoking?: InputMaybe<Scalars["String"]["input"]>;
}

export type BookingServicingTravelerInputValidation = {
  __typename?: "BookingServicingTravelerInputValidation";
  errorSummary?: Maybe<EgdsErrorSummary>;
  type: BookingServicingTravelerValidationType;
};

export type BookingServicingTravelerType = "ADULT" | "CHILD" | "INFANT";

export type BookingServicingTravelerValidationType = "CHILD_TRAVELING_ALONE";

export type BookingServicingTravelersPlayback = {
  __typename?: "BookingServicingTravelersPlayback";
  card?: Maybe<BookingServicingCard>;
};

export type BookingServicingTravelersPlaybackView = {
  __typename?: "BookingServicingTravelersPlaybackView";
  travelersPlayback: BookingServicingTravelersPlayback;
};

export type BookingServicingTravelersPlaybackViewTravelersPlaybackArgs = {
  criteriaInput: BookingServicingDetailsInput;
};

export type BookingServicingTripPlayback = {
  __typename?: "BookingServicingTripPlayback";
  bookingReference?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<BookingServicingTripPlaybackHeader>;
  item: BookingServicingTripPlaybackItem;
};

export type BookingServicingTripPlaybackHeader = {
  __typename?: "BookingServicingTripPlaybackHeader";
  badge?: Maybe<EgdsStandardBadge>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingTripPlaybackItem =
  | BookingServicingTripPlaybackMultiItem
  | BookingServicingTripPlaybackSingleItem;

export type BookingServicingTripPlaybackMultiItem = {
  __typename?: "BookingServicingTripPlaybackMultiItem";
  elements: Array<BookingServicingTripPlaybackMultiItemElement>;
  expando?: Maybe<EgdsExpando>;
};

export type BookingServicingTripPlaybackMultiItemElement = {
  __typename?: "BookingServicingTripPlaybackMultiItemElement";
  description?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingTripPlaybackSingleItem = {
  __typename?: "BookingServicingTripPlaybackSingleItem";
  badge?: Maybe<EgdsStandardBadge>;
  descriptions?: Maybe<Array<Scalars["String"]["output"]>>;
  image?: Maybe<Image>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingViews =
  | BookingServicingAcceptView
  | BookingServicingCancelView
  | BookingServicingChangeView
  | BookingServicingHeadsUpView
  | BookingServicingPreviousView
  | BookingServicingSearchView;

export type BookingServicingVirtualAgentAnalytic = {
  __typename?: "BookingServicingVirtualAgentAnalytic";
  analytic: ClientSideAnalytics;
  subscribedEvent: Scalars["String"]["output"];
};

export type BookingServicingVirtualAgentControl = {
  __typename?: "BookingServicingVirtualAgentControl";
  clientOverrides?: Maybe<BookingServicingVirtualAgentConversationContext>;
  pageName: Scalars["String"]["output"];
};

export type BookingServicingVirtualAgentConversationContext = {
  __typename?: "BookingServicingVirtualAgentConversationContext";
  analytics?: Maybe<Array<BookingServicingVirtualAgentAnalytic>>;
  conversationProperties?: Maybe<BookingServicingVirtualAgentConversationContextProperties>;
  intent?: Maybe<Scalars["String"]["output"]>;
  intentArguments?: Maybe<Array<BookingServicingVirtualAgentIntentArgument>>;
};

export type BookingServicingVirtualAgentConversationContextProperties = {
  __typename?: "BookingServicingVirtualAgentConversationContextProperties";
  launchPoint?: Maybe<Scalars["String"]["output"]>;
  pageName?: Maybe<Scalars["String"]["output"]>;
  skipWelcome?: Maybe<Scalars["String"]["output"]>;
};

export type BookingServicingVirtualAgentIntentAction = BookingServicingClientAction & {
  __typename?: "BookingServicingVirtualAgentIntentAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  context?: Maybe<BookingServicingVirtualAgentConversationContext>;
};

export type BookingServicingVirtualAgentIntentArgument = {
  __typename?: "BookingServicingVirtualAgentIntentArgument";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type BookingSummaryItem = {
  __typename?: "BookingSummaryItem";
  originalAmountPaid: Scalars["Float"]["output"];
  supplierNetRate: Scalars["Float"]["output"];
  title: Scalars["String"]["output"];
};

export type BookingUpdate = {
  __typename?: "BookingUpdate";
  attribute?: Maybe<EgdsStylizedText>;
  existingAttributeValue: EgdsStylizedText;
  updatedAttributeValue: EgdsStylizedText;
};

export type BooleanEnum = "FALSE" | "TRUE";

export type BooleanValue = {
  __typename?: "BooleanValue";
  id: Scalars["String"]["output"];
  value: Scalars["Boolean"]["output"];
};

export interface BooleanValueInput {
  id: Scalars["String"]["input"];
  value: Scalars["Boolean"]["input"];
}

export type Bounds = {
  __typename?: "Bounds";
  northeast: Coordinates;
  southwest: Coordinates;
};

export type BrandResultListingCreativeForm = {
  __typename?: "BrandResultListingCreativeForm";
  /** @deprecated Moved under sponsoredContent */
  clickThroughURLInputField?: Maybe<EgdsInputField>;
  creativeId?: Maybe<Scalars["String"]["output"]>;
  descriptionInputField?: Maybe<EgdsTextAreaInputField>;
  header?: Maybe<BrandResultListingCreativeFormHeader>;
  headlineInputField?: Maybe<EgdsInputField>;
  media?: Maybe<BrandResultListingCreativeMediaFormSection>;
  sponsoredContent?: Maybe<SponsoredContentFormSection>;
  /** @deprecated Moved under sponsoredContent */
  sponsoredLabelInputField?: Maybe<EgdsInputField>;
  submitCreativeButton?: Maybe<UiPrimaryButton>;
};

export type BrandResultListingCreativeFormHeader = {
  __typename?: "BrandResultListingCreativeFormHeader";
  creativeName?: Maybe<Scalars["String"]["output"]>;
  formTitle?: Maybe<Scalars["String"]["output"]>;
};

export type BrandResultListingCreativeMediaFormSection = {
  __typename?: "BrandResultListingCreativeMediaFormSection";
  featuredMedia?: Maybe<FeaturedMediaFormSection>;
  logo?: Maybe<LogoFormSection>;
};

export type BrandedDealCrossSellFallback = MultiItemFlightCrossSellPrimer;

export type BrandedDealCrossSellUiContent = {
  __typename?: "BrandedDealCrossSellUIContent";
  primaryContent: BrandedDealCrossSellUiContentSection;
  secondaryContent: BrandedDealCrossSellUiContentSection;
};

export type BrandedDealCrossSellUiContentItem = BrandedDealCrossSellUiContent | EgdsStylizedText;

export type BrandedDealCrossSellUiContentSection = {
  __typename?: "BrandedDealCrossSellUIContentSection";
  details: Array<BrandedDealCrossSellUiElement>;
};

export type BrandedDealCrossSellUiElement =
  | BrandedDealCrossSellUiImageWithText
  | MultiItemCrossSellUiBadge
  | MultiItemCrossSellUiHeaderText
  | MultiItemCrossSellUiLinkAction
  | MultiItemCrossSellUiText
  | SpecialOfferTypeMessagingSection;

export type BrandedDealCrossSellUiImageWithText = {
  __typename?: "BrandedDealCrossSellUIImageWithText";
  description: MultiItemCrossSellUiText;
  image: Image;
};

export type BrandedDealCrossSellUiResponse = {
  __typename?: "BrandedDealCrossSellUIResponse";
  action: MultiItemCrossSellUiLinkAction;
  actionBar: Array<MultiItemCrossSellUiLinkAction>;
  /** @deprecated Use crossSellBadges instead */
  badges?: Maybe<Array<MultiItemCrossSellUiBadge>>;
  border?: Maybe<Scalars["Boolean"]["output"]>;
  brandedDealContent: Array<BrandedDealCrossSellUiContentItem>;
  /** @deprecated Use brandedDealContent instead */
  contentSections: BrandedDealCrossSellUiContent;
  crossSellBadges: Array<EgdsProgramBadge>;
  impressionAnalytics: ClientSideAnalytics;
  theme?: Maybe<MessagingCardTheme>;
};

export type BrandedDealPrice = {
  __typename?: "BrandedDealPrice";
  base: Money;
  taxesAndFees: Money;
};

export interface BrandedDealProductPriceInput {
  base: MoneyInput;
  taxesAndFees: MoneyInput;
}

export type BrandedDealSearchPrice = {
  __typename?: "BrandedDealSearchPrice";
  base: SearchAmountModel;
  taxesAndFees: SearchAmountModel;
};

export type Breadcrumb = BreadcrumbItem &
  TextBreadcrumb & {
    __typename?: "Breadcrumb";
    text: Scalars["String"]["output"];
    uiLinkAction: UiLinkAction;
  };

export interface BreadcrumbItem {
  text?: Maybe<Scalars["String"]["output"]>;
  uiLinkAction?: Maybe<UiLinkAction>;
}

export type Breadcrumbs = {
  __typename?: "Breadcrumbs";
  breadcrumbItems: Array<BreadcrumbItem>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  menuAnalytics?: Maybe<ClientSideAnalytics>;
  seoStructuredData: Scalars["String"]["output"];
};

export type BreakpointConfig = {
  __typename?: "BreakpointConfig";
  lg: Scalars["Int"]["output"];
  md: Scalars["Int"]["output"];
  sm: Scalars["Int"]["output"];
  xl: Scalars["Int"]["output"];
};

export interface BulkAddInventoryInput {
  dates: Array<DateInput>;
  increment: Scalars["Int"]["input"];
  roomTypeIDs: Array<Scalars["String"]["input"]>;
}

export type BulkAddInventoryResponse = {
  __typename?: "BulkAddInventoryResponse";
  applyFailedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  applySucceededEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  responseType: BulkEditResponseType;
};

export type BulkEditResponseType = "ERROR" | "SUCCESS";

export type BulletStyle = "DEFAULT" | "ICON_ALTERNATE" | "ICON_STANDARD" | "NO_BULLET" | "UNRECOGNIZED";

export type BusinessModelType = "EXPEDIA_COLLECT" | "HOTEL_COLLECT";

export type ButtonSource = {
  __typename?: "ButtonSource";
  alt: Scalars["String"]["output"];
  buttonSourceType: ButtonSourceType;
  url: Scalars["String"]["output"];
};

export type ButtonSourceType = "SCRIPT" | "URL";

export type ButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type CtComponent = {
  __typename?: "CTComponent";
  id: Scalars["String"]["output"];
  payload: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type CtResponse = {
  __typename?: "CTResponse";
  components: Array<CtComponent>;
  layout: Scalars["String"]["output"];
};

export interface CabinInput {
  type: Scalars["String"]["input"];
}

export interface CalculateAwardInput {
  siteFees: Scalars["String"]["input"];
  sitePrice: Scalars["String"]["input"];
  siteTaxes: Scalars["String"]["input"];
  transactionCurrency: Scalars["String"]["input"];
  tripEndDate: Scalars["String"]["input"];
  tripStartDate: Scalars["String"]["input"];
}

export type Campaign = {
  __typename?: "Campaign";
  campaignId: Scalars["UUID"]["output"];
  goal: Scalars["String"]["output"];
  isPaused: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
};

export type CampaignBudgetCard = {
  __typename?: "CampaignBudgetCard";
  inputBadge?: Maybe<EgdsStandardBadge>;
  suggestionText?: Maybe<Scalars["String"]["output"]>;
  textInputBox: EgdsInputField;
};

export type CampaignCardContent =
  | AdGroupLocationCard
  | AdGroupReviewCard
  | CampaignBudgetCard
  | CampaignGoalCard
  | CampaignProductCard
  | CampaignReviewCard
  | CampaignScheduleCard
  | CreativeCard
  | NameCard;

export type CampaignCellInfo =
  | AdGroupOverviewStatusCell
  | AdOverviewStatusCell
  | CampaignOverviewActionCell
  | CampaignOverviewDeliveryStatusCell
  | CampaignOverviewLocationTargetingCell
  | CampaignOverviewNumberCell
  | CampaignOverviewStatusCell
  | CampaignOverviewTextCell;

export type CampaignContentPaginationInfo = {
  __typename?: "CampaignContentPaginationInfo";
  currentPage: Scalars["Int"]["output"];
  hasMoreContentPages: Scalars["Boolean"]["output"];
};

export interface CampaignCreativeDataInput {
  adGroupId?: InputMaybe<Scalars["String"]["input"]>;
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
}

export type CampaignDescriptions = {
  __typename?: "CampaignDescriptions";
  long?: Maybe<Scalars["String"]["output"]>;
  short?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignDetail = {
  __typename?: "CampaignDetail";
  banner: Image;
  campaignContentPaginationInfo?: Maybe<CampaignContentPaginationInfo>;
  deeplinkType?: Maybe<DeeplinkType>;
  description: CampaignDescriptions;
  /** @deprecated No longer relevant, replace with OffersUrl.url */
  destinationUrl?: Maybe<Scalars["String"]["output"]>;
  fromOriginHeaderText?: Maybe<Scalars["String"]["output"]>;
  headline: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  landingPageUrl?: Maybe<Scalars["URL"]["output"]>;
  offers?: Maybe<Array<OfferItem>>;
  offersUrl: OffersUrl;
  productTypes: Array<Scalars["String"]["output"]>;
  recommendedType: RecommendedType;
  referrer: Scalars["String"]["output"];
  termsAndConditions: Scalars["String"]["output"];
};

export type CampaignDetailOffersArgs = {
  numberOfDestinations?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CampaignDetails = {
  __typename?: "CampaignDetails";
  campaignNameHeader?: Maybe<Scalars["String"]["output"]>;
  campaignNameValue?: Maybe<Scalars["String"]["output"]>;
  campaignStatusHeader?: Maybe<Scalars["String"]["output"]>;
  campaignStatusValue?: Maybe<Scalars["String"]["output"]>;
  uapCampaignIdValue?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignGoalCard = {
  __typename?: "CampaignGoalCard";
  goalOptionList?: Maybe<Array<CampaignGoalType>>;
  marketGoal: MarketGoal;
};

export type CampaignGoalType = {
  __typename?: "CampaignGoalType";
  goalBadge?: Maybe<EgdsStandardBadge>;
  goalDisabled: Scalars["Boolean"]["output"];
  goalIcon?: Maybe<Icon>;
  goalId?: Maybe<Scalars["String"]["output"]>;
  goalLabelText?: Maybe<Scalars["String"]["output"]>;
  goalState?: Maybe<Scalars["Boolean"]["output"]>;
  goalSubLabelText?: Maybe<Scalars["String"]["output"]>;
};

export interface CampaignInput {
  adGroups: Array<AdGroupInput>;
  budget?: InputMaybe<Scalars["Float"]["input"]>;
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  goal?: InputMaybe<Scalars["String"]["input"]>;
  hydrated: Scalars["Boolean"]["input"];
  id: Scalars["String"]["input"];
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
  productName: Scalars["String"]["input"];
  startDate: Scalars["String"]["input"];
}

export type CampaignManagementQuery = {
  __typename?: "CampaignManagementQuery";
  adGroupTable?: Maybe<AdGroupTable>;
  campaignDetails?: Maybe<CampaignDetails>;
  campaignSearch?: Maybe<SearchBar>;
};

export type CampaignOfferBadge = {
  __typename?: "CampaignOfferBadge";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
  type: CampaignOfferBadgeType;
};

export type CampaignOfferBadgeType = "DEFAULT" | "DISCOUNT";

export type CampaignOption = {
  __typename?: "CampaignOption";
  campaignId: Scalars["String"]["output"];
  campaignName: Scalars["String"]["output"];
  isPaused: Scalars["Boolean"]["output"];
};

export type CampaignOverviewActionCell = {
  __typename?: "CampaignOverviewActionCell";
  adGroupId?: Maybe<Scalars["String"]["output"]>;
  adId?: Maybe<Scalars["String"]["output"]>;
  campaignId: Scalars["String"]["output"];
  iconAction: Icon;
  iconAdd: Icon;
  iconEdit: Icon;
};

export type CampaignOverviewBorder = {
  __typename?: "CampaignOverviewBorder";
  horizontalBorder?: Maybe<Scalars["Boolean"]["output"]>;
  verticalBorder?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CampaignOverviewDataRows = {
  __typename?: "CampaignOverviewDataRows";
  row: Array<CampaignOverviewTableCellContent>;
};

export type CampaignOverviewDeliveryStatusCell = {
  __typename?: "CampaignOverviewDeliveryStatusCell";
  id: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
};

export interface CampaignOverviewFocusTypeahead {
  adGroupOptions?: Maybe<Array<AdGroupOption>>;
  campaignOptions?: Maybe<Array<CampaignOption>>;
  heading: Scalars["String"]["output"];
  renderType: EgdsTypeaheadRenderType;
  searchTypeahead: EgdsTypeaheadInputField;
  subheading: Scalars["String"]["output"];
  triggerMenu: TypeaheadTriggerMenu;
  typeaheadSelect?: Maybe<EgdsBasicSelect>;
}

export type CampaignOverviewLocationTargetingCell = {
  __typename?: "CampaignOverviewLocationTargetingCell";
  closeIcon: Icon;
  infoIcon: Icon;
  regionCountryCountList: Scalars["String"]["output"];
  total: Scalars["String"]["output"];
};

export type CampaignOverviewNumberCell = {
  __typename?: "CampaignOverviewNumberCell";
  amount?: Maybe<Scalars["Float"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignOverviewStatusCell = {
  __typename?: "CampaignOverviewStatusCell";
  campaignId: Scalars["String"]["output"];
  campaignName: Scalars["String"]["output"];
  endDate: Scalars["String"]["output"];
  switch: EgdsStandardSwitch;
};

export type CampaignOverviewTableCellContent = {
  __typename?: "CampaignOverviewTableCellContent";
  border?: Maybe<CampaignOverviewBorder>;
  content?: Maybe<CampaignCellInfo>;
};

export interface CampaignOverviewTableResponse {
  columns: Array<OverviewTableColumn>;
  createLabel: Scalars["String"]["output"];
  editLabel: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  lastSort: CampaignOverviewTableSorting;
  noRowsIcon: Icon;
  noRowsPlaceholder: Scalars["String"]["output"];
  rows: Array<CampaignOverviewDataRows>;
  striped: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
}

export type CampaignOverviewTableSorting = {
  __typename?: "CampaignOverviewTableSorting";
  sortBy?: Maybe<Scalars["String"]["output"]>;
  sortDirection?: Maybe<Scalars["String"]["output"]>;
};

export interface CampaignOverviewTableSortingInput {
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortDirection?: InputMaybe<Scalars["String"]["input"]>;
}

export type CampaignOverviewTextCell = {
  __typename?: "CampaignOverviewTextCell";
  text: Scalars["String"]["output"];
};

export type CampaignPagination = {
  __typename?: "CampaignPagination";
  hasNextPage: Scalars["Boolean"]["output"];
  referrer: Scalars["String"]["output"];
};

export interface CampaignParametersInput {
  campaignId: Scalars["String"]["input"];
  contentPagination?: InputMaybe<ContentPaginationInput>;
  propertyParameters?: InputMaybe<CampaignPropertyParametersInput>;
}

export type CampaignProductCard = {
  __typename?: "CampaignProductCard";
  content: CampaignProductSelect;
};

export type CampaignProductSelect = CampaignPropertyCard | CampaignReadOnlyProductCard | CampaignRouteCard;

export type CampaignProductType = "ACTIVITY" | "FLIGHT" | "HOTEL" | "PACKAGE";

export type CampaignPropertyCard = {
  __typename?: "CampaignPropertyCard";
  missingPropertyLink: EgdsInlineLink;
  missingPropertyText: Scalars["String"]["output"];
  propertySelectTypeahead: EgdsTypeaheadInputField;
  propertySelectTypeaheadOptions: Array<EgdsBasicOption>;
};

export interface CampaignPropertyParametersInput {
  guestRating?: InputMaybe<Scalars["Float"]["input"]>;
  propertyId: Scalars["String"]["input"];
  starRating?: InputMaybe<Scalars["Float"]["input"]>;
  type?: InputMaybe<PropertyDetailsPropertyType>;
}

export type CampaignPublishDialog = {
  __typename?: "CampaignPublishDialog";
  dialogButtons: Array<CampaignWizardNavigationButton>;
  dialogSubHeading?: Maybe<Scalars["String"]["output"]>;
  dialogTitle?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignPublishResponse = {
  __typename?: "CampaignPublishResponse";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  successMessageDescription?: Maybe<Scalars["String"]["output"]>;
  successMessageHeading?: Maybe<Scalars["String"]["output"]>;
};

export type CampaignReadOnlyProductCard = {
  __typename?: "CampaignReadOnlyProductCard";
  icon: Icon;
  productName: Scalars["String"]["output"];
  productNameTitle: Scalars["String"]["output"];
};

export type CampaignReportingData = {
  __typename?: "CampaignReportingData";
  row?: Maybe<Array<AdvertiserReportingTableCellContent>>;
};

export interface CampaignRequestInput {
  adGroupList: Array<AdGroupRequestInput>;
  budgetAmount: Scalars["Float"]["input"];
  campaignId?: InputMaybe<Scalars["UUID"]["input"]>;
  campaignName: Scalars["String"]["input"];
  campaignTargeting: CampaignTargetingInput;
  isCampaignUpdated: Scalars["Boolean"]["input"];
  isPaused?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type CampaignReviewCard = {
  __typename?: "CampaignReviewCard";
  editButton: UiSecondaryButton;
  editItemId: Scalars["String"]["output"];
  items: Array<ReviewCardLineItem>;
};

export type CampaignRouteCard = {
  __typename?: "CampaignRouteCard";
  routeDestinationSelect?: Maybe<EgdsSelect>;
  routeOriginSelect?: Maybe<EgdsSelect>;
};

export type CampaignScheduleCard = {
  __typename?: "CampaignScheduleCard";
  datePickerClearButtonLabel?: Maybe<Scalars["String"]["output"]>;
  datePickerSubmitButtonLabel?: Maybe<Scalars["String"]["output"]>;
  endDate: EgdsInputField;
  endDateCheckboxLabel?: Maybe<Scalars["String"]["output"]>;
  endDateCheckboxState: Scalars["Boolean"]["output"];
  startDate: EgdsInputField;
};

export interface CampaignTargetingInput {
  endDate?: InputMaybe<TargetingInput>;
  startDate: TargetingInput;
}

export type CampaignWizardCard = {
  __typename?: "CampaignWizardCard";
  cardContent: CampaignCardContent;
  cardHeading: Scalars["String"]["output"];
  cardSubHeading?: Maybe<Scalars["String"]["output"]>;
  learnMoreLink?: Maybe<LearnMoreLink>;
};

export type CampaignWizardNavigation = {
  __typename?: "CampaignWizardNavigation";
  navigationButtons: Array<CampaignWizardNavigationButton>;
  publishDialog: CampaignPublishDialog;
};

export type CampaignWizardNavigationButton = {
  __typename?: "CampaignWizardNavigationButton";
  action: PortalButton;
  actionType: Scalars["String"]["output"];
};

export type CampaignWizardSideBar = {
  __typename?: "CampaignWizardSideBar";
  actionAreas: Array<SideBarActionArea>;
  campaign: SideBarCampaign;
};

export type CampaignWizardStep = {
  __typename?: "CampaignWizardStep";
  banners: Array<PortalBanner>;
  stepButtons?: Maybe<Array<PortalButton>>;
  stepCards?: Maybe<Array<CampaignWizardCard>>;
  stepHeading: Scalars["String"]["output"];
};

export type CancelConfirmationError = {
  __typename?: "CancelConfirmationError";
  buttons: Array<BookingServicingButton>;
  content?: Maybe<Array<BookingServicingText>>;
  displayAnalytics: Array<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"]["output"];
  toolbar?: Maybe<BookingServicingToolbar>;
};

export type CancelConfirmationFooter = {
  __typename?: "CancelConfirmationFooter";
  buttons?: Maybe<Array<BookingServicingButton>>;
};

export type CancelConfirmationPresentation = {
  __typename?: "CancelConfirmationPresentation";
  content?: Maybe<Array<BookingServicingCancelConfirmationContentElement>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: Scalars["String"]["output"];
};

export type CancellationPolicyDialogContentEntry = DialogContentEntry & {
  __typename?: "CancellationPolicyDialogContentEntry";
  entryContent: Array<Scalars["String"]["output"]>;
  entryTitle: LodgingHeader;
};

export interface CancellationWaiverDeclineRequestContext {
  bookingItemId: Scalars["String"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
  reason: Scalars["String"]["input"];
  serviceRequestId: Scalars["String"]["input"];
}

export type CaptchaProtection = {
  __typename?: "CaptchaProtection";
  arkoseNativeProviderConfig?: Maybe<ArkoseNativeProviderConfig>;
  arkoseWebProviderConfig?: Maybe<ArkoseWebProviderConfig>;
  isInitialized: Scalars["Boolean"]["output"];
  recaptchaSafetynetAndroidProviderConfig?: Maybe<RecaptchaSafetyNetAndroidProviderConfig>;
  recaptchaWebProviderConfig?: Maybe<RecaptchaWebProviderConfig>;
};

export type CapturePaymentInfoMutationResponse = {
  __typename?: "CapturePaymentInfoMutationResponse";
  error?: Maybe<AgentToolsErrorBanner>;
  paymentInstrumentId?: Maybe<Scalars["String"]["output"]>;
  paymentSecretId?: Maybe<Scalars["String"]["output"]>;
  successMessage?: Maybe<AgentToolsSuccessBanner>;
};

export type CarAccessory = {
  __typename?: "CarAccessory";
  accessibility: Scalars["String"]["output"];
  analytics: CarAnalytics;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  price?: Maybe<Scalars["String"]["output"]>;
  pricePeriod?: Maybe<Scalars["String"]["output"]>;
  selectionState: CarAccessorySelectionState;
  text?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type CarAccessorySelectionState = "NOT_SELECTED" | "SELECTED";

export type CarAction = {
  __typename?: "CarAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionType: CarActionType;
  analytics?: Maybe<CarAnalytics>;
};

export type CarActionType =
  | "APPLY_LOYALTY_POINTS"
  | "CAR_MAP_MARKER"
  | "CHANGE_SEARCH"
  | "DISMISS"
  | "FILTER_SALE_OFFER"
  | "FORM_SUBMISSION"
  | "GO_BACK_TO_SEARCH"
  | "GO_TO_CANCELLATION_POLICY"
  | "GO_TO_CHECKOUT"
  | "GO_TO_CLEANLINESS_DIALOG"
  | "GO_TO_DELTA_CAMPAIGN_LINK"
  | "GO_TO_DETAILS"
  | "GO_TO_PACKAGES"
  | "GO_TO_RECOMMENDED_SORT_FAQ"
  | "GO_TO_SIGNIN"
  | "GO_TO_SIGNUP"
  | "GO_TO_SIGN_IN_AND_UNLOCK_CAR_SALES"
  | "GO_TO_WPLUS_MEMBER_TRIAL_LINK"
  | "LOAD_MORE"
  | "OFF_AIRPORT_CHANGE_SEARCH"
  | "OPEN_DIALOG"
  | "OPEN_DOC"
  | "OPEN_GROUND_TRANSFERS"
  | "OPEN_INLINE_TIME_WIZARD"
  | "OPEN_INLINE_WIZARD"
  | "OPEN_NEW_TAB"
  | "OPEN_ON_CURRENT_TAB"
  | "OPEN_PARTNER_MESSAGING_LINK"
  | "OPEN_RULE"
  | "OPEN_TEL"
  | "REFRESH"
  | "REMOVE_LOYALTY_POINTS"
  | "SEE_CARS_AT_MAP_MARKER"
  | "SHARE_FEEDBACK"
  | "SHOW_AARP_EXCLUSIVE_OFFERS"
  | "SHOW_FREE_CANCELLATION_OFFERS"
  | "UNLOCK_CAR_SALES";

export type CarActionableItem = {
  __typename?: "CarActionableItem";
  action: CarAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Icon>;
  tel?: Maybe<TelUri>;
  text: Scalars["String"]["output"];
  url?: Maybe<HttpUri>;
};

export type CarActionableItemForSignin = {
  __typename?: "CarActionableItemForSignin";
  memberSignInDialog: MemberSignInDialog;
};

export type CarAdditionalBenefits = {
  __typename?: "CarAdditionalBenefits";
  benefits: Array<CarPhraseIconText>;
  headingText: Scalars["String"]["output"];
};

export type CarAnalytics = {
  __typename?: "CarAnalytics";
  linkName: Scalars["String"]["output"];
  referrerId: Scalars["String"]["output"];
};

export type CarAppliedSortAndFilter = {
  __typename?: "CarAppliedSortAndFilter";
  analytics: ClientSideAnalytics;
  crossIcon: Icon;
  id: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CarAppliedSortAndFilters = {
  __typename?: "CarAppliedSortAndFilters";
  appliedSortAndFilters: Array<CarAppliedSortAndFilter>;
};

export type CarAvailableAccessories = {
  __typename?: "CarAvailableAccessories";
  accessories: Array<CarAccessory>;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CarBreakupComponent = {
  __typename?: "CarBreakupComponent";
  id: Scalars["String"]["output"];
  lineItems: Array<CarBreakupLineItem>;
  title: Scalars["String"]["output"];
};

export type CarBreakupLineItem = {
  __typename?: "CarBreakupLineItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  dialogContent?: Maybe<CarPriceBreakupDialog>;
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  openDialogAction?: Maybe<CarAction>;
  richValue?: Maybe<CarsRichText>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CarBreakupSummarySection = {
  __typename?: "CarBreakupSummarySection";
  lineItems: Array<CarBreakupLineItem>;
  notIncludedInTotal?: Maybe<CarBreakupComponent>;
  title: Scalars["String"]["output"];
  total: Scalars["String"]["output"];
};

export type CarButton = {
  __typename?: "CarButton";
  analytics?: Maybe<CarAnalytics>;
  text: Scalars["String"]["output"];
};

export type CarDetailContext = {
  __typename?: "CarDetailContext";
  carOfferToken: Scalars["String"]["output"];
  continuationContextualId?: Maybe<Scalars["String"]["output"]>;
  optionAction?: Maybe<CarAccessorySelectionState>;
  optionToken?: Maybe<Scalars["String"]["output"]>;
  originalDeeplink?: Maybe<Scalars["String"]["output"]>;
  principalToken?: Maybe<Scalars["String"]["output"]>;
  rewardPointsSelection?: Maybe<RewardsPointSelectionEnum>;
  selectedAccessories?: Maybe<Array<Scalars["String"]["output"]>>;
};

export interface CarDetailContextInput {
  carOfferToken: Scalars["String"]["input"];
  continuationContextualId?: InputMaybe<Scalars["String"]["input"]>;
  optionAction?: InputMaybe<CarAccessorySelectionState>;
  optionToken?: InputMaybe<Scalars["String"]["input"]>;
  originalDeeplink?: InputMaybe<Scalars["String"]["input"]>;
  principalToken?: InputMaybe<Scalars["String"]["input"]>;
  rewardPointsSelection?: InputMaybe<RewardsPointSelectionEnum>;
  selectedAccessories?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type CarDetailsResult = {
  __typename?: "CarDetailsResult";
  actionableConfidenceMessages: Array<CarPhrase>;
  additionalBenefits?: Maybe<CarAdditionalBenefits>;
  adsTargetingData?: Maybe<AdsTargetingData>;
  availableAccessories?: Maybe<CarAvailableAccessories>;
  carOfferToken?: Maybe<Scalars["String"]["output"]>;
  carSearchLink?: Maybe<CarActionableItem>;
  deal?: Maybe<CarMessagingCard>;
  detailSummary: CarDetailsSummary;
  detailsNavTabs?: Maybe<DetailsNavTabs>;
  directFeedbackListing?: Maybe<DirectFeedbackListing>;
  dropOffCharge?: Maybe<CarMessagingCard>;
  easeCancel?: Maybe<CarMessagingCard>;
  enhancedCleanlinessInfo?: Maybe<EnhancedCleanlinessInfo>;
  importantInfo: CarImportantInfo;
  inclusionsDetail?: Maybe<CarInclusionsDetail>;
  insurancePriming?: Maybe<CarInsurancePriming>;
  isTravelApiPath: Scalars["Boolean"]["output"];
  loyaltyInfo?: Maybe<Array<CarPhrase>>;
  offerBadges?: Maybe<Array<CarOfferBadge>>;
  oneKeyDetails?: Maybe<OneKeyDetails>;
  oneKeyInfo?: Maybe<CarOfferBadge>;
  onlineCheckInInfo?: Maybe<CarOnlineCheckIn>;
  pairConfidenceMessages?: Maybe<Array<CarPhrase>>;
  priceAlert?: Maybe<CarMessagingCard>;
  priceDetails?: Maybe<CarPriceDetails>;
  priceSummary: CarPriceSummary;
  promotions: Array<CarPromotion>;
  rentalLocations: CarRentalLocations;
  rentalProtectionInfo?: Maybe<RentalProtectionInfo>;
  /** @deprecated Use reserveButtonAction for action button */
  reserveAction: CarActionableItem;
  reserveButtonAction: CarPhrase;
  reviewsSummary?: Maybe<CarReviewsSummary>;
  saleUnlocked?: Maybe<CarMessagingCard>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  signInBanner?: Maybe<CarMessagingCard>;
  skipTheCounterInfo?: Maybe<CarSkipTheCounterInfo>;
  telesales?: Maybe<CarTelesales>;
  tripsSaveItemWrapper?: Maybe<TripsSaveItemWrapper>;
  variantsSummary?: Maybe<CarVariantsSummary>;
  vehicle?: Maybe<VehicleDetails>;
  vendor: CarVendor;
};

export type CarDetailsSummary = {
  __typename?: "CarDetailsSummary";
  pageTitle: Scalars["String"]["output"];
};

export type CarDialogConfidenceMessage = {
  __typename?: "CarDialogConfidenceMessage";
  dialogContent?: Maybe<CarsDialog>;
  icon?: Maybe<Icon>;
  iconMobileRender: Scalars["Boolean"]["output"];
  openDialogAction?: Maybe<CarAction>;
  text: Scalars["String"]["output"];
};

export type CarDialogContent = {
  __typename?: "CarDialogContent";
  action: CarAction;
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type CarDisclaimerDialog = {
  __typename?: "CarDisclaimerDialog";
  closeAnalytics: CarAnalytics;
  closeIconLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use contents instead */
  content: Array<CarPhrase>;
  contentNative?: Maybe<Array<CarPhraseKeyValueTuple>>;
  contents: Array<Array<CarPhrase>>;
  feedback?: Maybe<UserFeedback>;
};

export type CarDropOffOfferCard = {
  __typename?: "CarDropOffOfferCard";
  carPriceDetail: CarPriceDetail;
  detailsContext: CarDetailContext;
  selectButton: CarActionableItem;
  tripLocations: CarOfferVendorLocations;
  vehicle: VehicleDetails;
  vendor: CarVendor;
  viewMap: CarActionableItem;
};

export type CarDropOffTitle = {
  __typename?: "CarDropOffTitle";
  action: CarAction;
  icon: Icon;
  text: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CarFormatStyle = "IFRAME" | "LIST";

export type CarImportantInfo = {
  __typename?: "CarImportantInfo";
  importantInfoItems: Array<CarImportantInfoItems>;
  infos: Array<Scalars["String"]["output"]>;
  rulesAndRestrictions: CarActionableItem;
  title: Scalars["String"]["output"];
};

export type CarImportantInfoItems = {
  __typename?: "CarImportantInfoItems";
  analytics: CarAnalytics;
  description: Array<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  name: Scalars["String"]["output"];
  subtext: Scalars["String"]["output"];
};

export type CarInclusion = {
  __typename?: "CarInclusion";
  analytics: CarAnalytics;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  infos?: Maybe<Array<CarPhrase>>;
  summary?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type CarInclusionsDetail = {
  __typename?: "CarInclusionsDetail";
  inclusions: Array<CarInclusion>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CarInsurancePriming = {
  __typename?: "CarInsurancePriming";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type CarItemCardMapMarker = CarMapMarker & {
  __typename?: "CarItemCardMapMarker";
  action: CarAction;
  coordinates: Coordinates;
  hoverAction?: Maybe<Array<CarAnalytics>>;
  icon?: Maybe<Icon>;
  itemCard: CarMapItemCard;
  type: CarMapMarkerType;
};

export type CarListingCard = CarMessagingCard | CarOfferCard | CarSaleCard | InsurtechPrimingCarListingCard;

export type CarLoadMoreAction = {
  __typename?: "CarLoadMoreAction";
  action: CarAction;
  icon: Icon;
  searchPagination: CarSearchPagination;
  text: Scalars["String"]["output"];
};

export type CarMap = {
  __typename?: "CarMap";
  bounds: CarMapBounds;
  center: Coordinates;
  closeAction: CarAction;
  closeIcon: Icon;
  dialogAccessibility: Scalars["String"]["output"];
  filterTitle?: Maybe<Scalars["String"]["output"]>;
  mapButton: CarActionableItem;
  mapSearchThisAreaButton?: Maybe<CarActionableItem>;
  markers: Array<CarMapMarker>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  zoomLevel: Scalars["Int"]["output"];
};

export type CarMapBounds = {
  __typename?: "CarMapBounds";
  northeast: Coordinates;
  southwest: Coordinates;
};

export type CarMapDropOffLocationCard = {
  __typename?: "CarMapDropOffLocationCard";
  address: Scalars["String"]["output"];
  carPriceDetail: CarPriceDetail;
  detailsContext: CarDetailContext;
  distance: Scalars["String"]["output"];
  placeId?: Maybe<Scalars["String"]["output"]>;
  selectButton: CarActionableItem;
  superlative: Scalars["String"]["output"];
  vendorImage: Image;
  vendorLocationId: Scalars["String"]["output"];
};

export type CarMapItemCard = CarMapDropOffLocationCard | CarMapPickupLocationCard | CarMapSearchLocationCard;

export interface CarMapMarker {
  action: CarAction;
  coordinates: Coordinates;
  hoverAction?: Maybe<Array<CarAnalytics>>;
  icon?: Maybe<Icon>;
  type: CarMapMarkerType;
}

export type CarMapMarkerType = "CarMarker" | "PlaceMarker";

export type CarMapPickupLocationCard = {
  __typename?: "CarMapPickupLocationCard";
  action: CarAction;
  address: Scalars["String"]["output"];
  distance: Scalars["String"]["output"];
  placeId?: Maybe<Scalars["String"]["output"]>;
  priceSummary: CarPriceSummary;
  seeCarsButtonText: Scalars["String"]["output"];
  shortAddress: Scalars["String"]["output"];
  superlative: Scalars["String"]["output"];
  totalResultsByLocation?: Maybe<Scalars["Int"]["output"]>;
  totalResultsByLocationFormatted?: Maybe<Scalars["String"]["output"]>;
  vendorImage: Image;
  vendorLocationId: Scalars["String"]["output"];
  vendorReview?: Maybe<CarOfferReview>;
};

export type CarMapSearchLocationCard = {
  __typename?: "CarMapSearchLocationCard";
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CarMessagingCard = {
  __typename?: "CarMessagingCard";
  analytics?: Maybe<CarAnalytics>;
  badge?: Maybe<CarOfferBadge>;
  carRecommendations?: Maybe<Array<Maybe<CarRecommendations>>>;
  cardDescriptions: Array<CarPhraseText>;
  cardTitle?: Maybe<CarsRichText>;
  /** @deprecated use cardDescriptions instead */
  description: Scalars["String"]["output"];
  /** @deprecated use cardDescriptions instead */
  descriptions: Array<Scalars["String"]["output"]>;
  dialog?: Maybe<CarsDialog>;
  icon?: Maybe<Icon>;
  illustrationURL?: Maybe<HttpUri>;
  image?: Maybe<Image>;
  links: Array<CarActionableItem>;
  mark?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use cardTitle instead */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CarNaturalKey = {
  __typename?: "CarNaturalKey";
  offerToken: Scalars["String"]["output"];
  specialEquipments?: Maybe<Array<SpecialEquipment>>;
};

export interface CarNaturalKeyInput {
  offerToken: Scalars["String"]["input"];
  specialEquipments?: InputMaybe<Array<SpecialEquipment>>;
}

export type CarOfferBadge = {
  __typename?: "CarOfferBadge";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  text: Scalars["String"]["output"];
  theme: Scalars["String"]["output"];
};

export type CarOfferCard = {
  __typename?: "CarOfferCard";
  accessibilityString?: Maybe<Scalars["String"]["output"]>;
  action: CarAction;
  actionableConfidenceMessages: Array<CarPhrase>;
  additionalBenefits?: Maybe<Array<CarPhraseIconText>>;
  cancellationAndPaymentMessages?: Maybe<Array<CarPhrase>>;
  clubbedPriceOptions?: Maybe<ClubbedPriceOptions>;
  comparableDealsClickAnalytics?: Maybe<CarAnalytics>;
  detailsContext: CarDetailContext;
  enhancedCleanlinessDialog?: Maybe<EnhancedCleanlinessDialog>;
  guaranteedAnalytics?: Maybe<CarAnalytics>;
  infositeURL: HttpUri;
  /** @deprecated to delete */
  isFareComparisonTestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  offerBadges: Array<CarOfferBadge>;
  offerDiscountBadges: Array<CarOfferBadge>;
  offerHeading?: Maybe<Scalars["String"]["output"]>;
  offerPositionClickAnalytics?: Maybe<CarAnalytics>;
  personalisedDeal?: Maybe<CarPhraseText>;
  priceBadges: Array<CarOfferBadge>;
  priceSummary: CarPriceSummary;
  priceSummaryText?: Maybe<Scalars["String"]["output"]>;
  reserveButtonText: Scalars["String"]["output"];
  review?: Maybe<CarOfferReview>;
  shortList: CarShortList;
  tripLocations: CarOfferVendorLocations;
  tripsSaveItemWrapper?: Maybe<TripsSaveItemWrapper>;
  unlockOffer?: Maybe<CarActionableItem>;
  vehicle: VehicleDetails;
  vendor: CarVendor;
};

export interface CarOfferCriteriaInput {
  primary: PrimaryCarOfferCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type CarOfferLocationInfo = {
  __typename?: "CarOfferLocationInfo";
  locationSubInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  text: Scalars["String"]["output"];
};

export type CarOfferLocations = {
  __typename?: "CarOfferLocations";
  dropOffLocation?: Maybe<CarOfferLocationInfo>;
  pickUpLocation: CarOfferLocationInfo;
};

export type CarOfferOptionAction = "SELECT" | "UNSELECT";

export interface CarOfferOptionInput {
  optionAction: CarOfferOptionAction;
  optionToken: Scalars["String"]["input"];
}

export type CarOfferReview = {
  __typename?: "CarOfferReview";
  rating: Scalars["String"]["output"];
  richRatingText?: Maybe<CarsRichText>;
  superlative: Scalars["String"]["output"];
  totalCount?: Maybe<Scalars["String"]["output"]>;
};

export type CarOfferVendorLocationInfo = {
  __typename?: "CarOfferVendorLocationInfo";
  icon: Icon;
  /** @deprecated same value as locationId, will use placeId in the future */
  id: Scalars["String"]["output"];
  locationId: Scalars["String"]["output"];
  locationSubInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  placeId: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type CarOfferVendorLocations = {
  __typename?: "CarOfferVendorLocations";
  dropOffLocation?: Maybe<CarOfferVendorLocationInfo>;
  pickUpLocation: CarOfferVendorLocationInfo;
};

export type CarOnlineCheckIn = {
  __typename?: "CarOnlineCheckIn";
  icon?: Maybe<Icon>;
  infos: Array<CarPhrase>;
  title: Scalars["String"]["output"];
};

export type CarPaymentInfo = {
  __typename?: "CarPaymentInfo";
  additionalPaymentInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type CarPhrase =
  | CarActionableItem
  | CarActionableItemForSignin
  | CarDialogConfidenceMessage
  | CarDialogContent
  | CarOfferBadge
  | CarPhraseHtmlText
  | CarPhraseIconText
  | CarPhraseMark
  | CarPhrasePairText
  | CarPhraseText
  | CarsRichText;

export type CarPhraseHtmlText = {
  __typename?: "CarPhraseHTMLText";
  placeholderTextUrl?: Maybe<Array<CarActionableItem>>;
  value: CarPhraseText;
};

export type CarPhraseIconText = {
  __typename?: "CarPhraseIconText";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type CarPhraseKeyValueTuple = {
  __typename?: "CarPhraseKeyValueTuple";
  key: Scalars["String"]["output"];
  value: Array<CarPhrase>;
};

export type CarPhraseMark = {
  __typename?: "CarPhraseMark";
  description: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  url?: Maybe<HttpUri>;
};

export type CarPhrasePairText = {
  __typename?: "CarPhrasePairText";
  richSubText?: Maybe<CarsRichText>;
  richText?: Maybe<CarsRichText>;
};

export type CarPhraseText = {
  __typename?: "CarPhraseText";
  text: Scalars["String"]["output"];
};

export type CarPriceBreakupDialog = {
  __typename?: "CarPriceBreakupDialog";
  breakupContent?: Maybe<Array<CarBreakupLineItem>>;
  commonDialog: CarsDialog;
};

export type CarPriceDetail = {
  __typename?: "CarPriceDetail";
  priceSubInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  priceTitle: Scalars["String"]["output"];
};

export type CarPriceDetails = {
  __typename?: "CarPriceDetails";
  breakupSection: Array<CarBreakupComponent>;
  closeAction: CarAction;
  closeIcon: Icon;
  priceDetailsButton: CarActionableItem;
  summary: CarBreakupSummarySection;
  title: Scalars["String"]["output"];
};

export type CarPriceInfo = {
  __typename?: "CarPriceInfo";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  formattedValue?: Maybe<Scalars["String"]["output"]>;
  price: Money;
  qualifier: Scalars["String"]["output"];
  rawPrice?: Maybe<Scalars["Float"]["output"]>;
};

export type CarPriceSummary = {
  __typename?: "CarPriceSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  discountBadge?: Maybe<CarOfferBadge>;
  floatingCTAPriceDetailsAnalytics?: Maybe<CarAnalytics>;
  floatingCTAReserveButtonAnalytics?: Maybe<CarAnalytics>;
  lead?: Maybe<CarPriceInfo>;
  loyaltyPointsOption?: Maybe<LoyaltyPointsOption>;
  paymentInfo?: Maybe<CarPaymentInfo>;
  priceAdditionalInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  reference?: Maybe<CarReferencePriceInfo>;
  strikeThroughFirst?: Maybe<Scalars["Boolean"]["output"]>;
  total: CarPriceInfo;
};

export type CarPromotion = {
  __typename?: "CarPromotion";
  additionalInfo?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  text: Scalars["String"]["output"];
};

export interface CarRecommendationCard {
  action?: Maybe<CarAction>;
  icon?: Maybe<Icon>;
}

export type CarRecommendations = {
  __typename?: "CarRecommendations";
  analytics?: Maybe<Array<CarAnalytics>>;
  carRecommendationsCards?: Maybe<Array<CarRecommendationCard>>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type CarReferencePriceInfo = {
  __typename?: "CarReferencePriceInfo";
  /** @deprecated Use price summary accessibility string */
  accessibility?: Maybe<Scalars["String"]["output"]>;
  additionalInfo?: Maybe<Scalars["String"]["output"]>;
  dialogContent?: Maybe<CarsDialog>;
  openDialogAction?: Maybe<CarAction>;
  price: Money;
  qualifier?: Maybe<Scalars["String"]["output"]>;
};

export type CarRentalLocation = {
  __typename?: "CarRentalLocation";
  airportCode?: Maybe<Scalars["String"]["output"]>;
  coordinates?: Maybe<Coordinates>;
  isExactLocationSearch?: Maybe<Scalars["Boolean"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  searchTerm?: Maybe<Scalars["String"]["output"]>;
};

export type CarRentalLocationDetail = {
  __typename?: "CarRentalLocationDetail";
  address: CarVendorLocationInfo;
  analytics?: Maybe<CarAnalytics>;
  analyticsOnClose?: Maybe<CarAnalytics>;
  dateTime: CarVendorLocationInfo;
  hoursOfOperation?: Maybe<CarVendorLocationInfo>;
  instruction?: Maybe<CarVendorLocationInfo>;
  locationTimeLine?: Maybe<Scalars["String"]["output"]>;
  showLocationDetail?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export interface CarRentalLocationInput {
  airportCode?: InputMaybe<Scalars["String"]["input"]>;
  coordinates?: InputMaybe<CoordinatesInput>;
  isExactLocationSearch?: InputMaybe<Scalars["Boolean"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}

export type CarRentalLocations = {
  __typename?: "CarRentalLocations";
  dropOffLocation: CarRentalLocationDetail;
  isLocationDataMerged: Scalars["Boolean"]["output"];
  pickUpLocation: CarRentalLocationDetail;
  title: Scalars["String"]["output"];
};

export type CarReview = {
  __typename?: "CarReview";
  author?: Maybe<Scalars["String"]["output"]>;
  classificationName: Scalars["String"]["output"];
  expandButton: ExpandButton;
  rentalDuration?: Maybe<Scalars["String"]["output"]>;
  submissionTime: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  themes?: Maybe<Array<CarReviewThemes>>;
};

export type CarReviewDialogContent = {
  __typename?: "CarReviewDialogContent";
  description: Scalars["String"]["output"];
  /** @deprecated use disclaimer text list instead */
  disclaimerFullDescription: Scalars["String"]["output"];
  disclaimerLink?: Maybe<CarActionableItem>;
  disclaimerTextList?: Maybe<Array<Scalars["String"]["output"]>>;
  disclaimerTitle: Scalars["String"]["output"];
  disclaimerTitleAction: CarActionableItem;
  heading: Scalars["String"]["output"];
  /** @deprecated Use feedbackForm instead */
  opinionLabFeedback: OpinionLabFeedback;
  reviewBreakdown: Array<ReviewBreakdown>;
  reviews?: Maybe<Array<CarReview>>;
  reviewsSeeMoreAction?: Maybe<CarButton>;
  totalRatings: Scalars["String"]["output"];
  userFeedback: UserFeedback;
};

export type CarReviewThemes = {
  __typename?: "CarReviewThemes";
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type CarReviewsSummary = {
  __typename?: "CarReviewsSummary";
  actionableVerifiedRatingsCountMsg?: Maybe<CarDialogConfidenceMessage>;
  dialogCloseIconLabel: Scalars["String"]["output"];
  dialogContent?: Maybe<CarReviewDialogContent>;
  dialogTrigger?: Maybe<CarActionableItem>;
  location?: Maybe<Scalars["String"]["output"]>;
  recommendedRate: Scalars["String"]["output"];
  recommendedText: Scalars["String"]["output"];
  superlative?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  topReviews: Array<Scalars["String"]["output"]>;
};

export type CarRouteType = "ONEWAY" | "ROUNDTRIP";

export type CarRulesAndRestrictionsContainer = {
  __typename?: "CarRulesAndRestrictionsContainer";
  analytics: CarAnalytics;
  footer?: Maybe<CarPhraseText>;
  formatStyle?: Maybe<CarFormatStyle>;
  id: Scalars["String"]["output"];
  rulesAndRestrictionsItems: Array<CarPhrase>;
  title: CarPhraseText;
};

export interface CarRulesAndRestrictionsCriteriaInput {
  carOfferToken?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  useEgtpPath?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type CarRulesAndRestrictionsResult = {
  __typename?: "CarRulesAndRestrictionsResult";
  carSummary: CarSummary;
  header: CarPhraseText;
  /** @deprecated To be deleted and we need to create separate interface and its implementations(List, Text, Iframe etc) for R&R */
  rulesAndRestrictionsContainer: Array<CarRulesAndRestrictionsContainer>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  toolbar: CarToolbar;
};

export type CarSaleCard = {
  __typename?: "CarSaleCard";
  analytics?: Maybe<CarAnalytics>;
  cardAction?: Maybe<CarAction>;
  cardDescription?: Maybe<CarPhraseText>;
  cardTitle?: Maybe<CarsRichText>;
  image?: Maybe<Image>;
  linkText?: Maybe<CarsRichText>;
};

export type CarSearchCriteria = {
  __typename?: "CarSearchCriteria";
  primary: PrimaryCarCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface CarSearchCriteriaInput {
  primary: PrimaryCarCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type CarSearchFilterNoMatchMessage = {
  __typename?: "CarSearchFilterNoMatchMessage";
  allOffersTextSeparator: Scalars["String"]["output"];
  messagingCard: CarMessagingCard;
};

export type CarSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "CarSearchForm";
    actionURL: Scalars["String"]["output"];
    alternativeDropOffLocationLabel?: Maybe<Scalars["String"]["output"]>;
    dates?: Maybe<EgdsSearchFormDatePickerField>;
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    driverAgeCheck?: Maybe<CarSearchFormDriverAgeCheck>;
    dropOffLocation?: Maybe<EgdsSearchFormLocationField>;
    dropOffTime?: Maybe<EgdsSelect>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    localDateFormat?: Maybe<Scalars["String"]["output"]>;
    pickUpLocation?: Maybe<EgdsSearchFormLocationField>;
    pickUpTime?: Maybe<EgdsSelect>;
    playback?: Maybe<EgdsSearchPlayback>;
    rentalTypes?: Maybe<EgdsBasicTabs>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    specialRates?: Maybe<CarSearchFormSpecialRates>;
  };

export type CarSearchFormCorporateOrContractedOption = {
  __typename?: "CarSearchFormCorporateOrContractedOption";
  discountCode: EgdsTextInputField;
  optionLabel: Scalars["String"]["output"];
};

export type CarSearchFormDiscountCode = {
  __typename?: "CarSearchFormDiscountCode";
  action: CarSearchFormDiscountCodeAction;
  discountCode: EgdsPill;
};

export type CarSearchFormDiscountCodeAction = {
  __typename?: "CarSearchFormDiscountCodeAction";
  discountType: EgdsSelect;
  discountTypeOptions: Array<CarSearchFormDiscountTypeOption>;
  done: EgdsButton;
  errorSummary: EgdsErrorSummary;
  preferredBrand: EgdsSelect;
  toolbar: UiToolbar;
};

export type CarSearchFormDiscountTypeOption = {
  __typename?: "CarSearchFormDiscountTypeOption";
  corporateOrContractedOption?: Maybe<CarSearchFormCorporateOrContractedOption>;
  preferredBrand: CarSearchFormPreferredBrand;
  specialOrAdvertisedOption: CarSearchFormSpecialOrAdvertisedOption;
};

export type CarSearchFormDriverAgeCheck = {
  __typename?: "CarSearchFormDriverAgeCheck";
  driverAge: EgdsNumberInputField;
  driverAgeRequired: EgdsBasicCheckBox;
  label: Scalars["String"]["output"];
};

export type CarSearchFormPreferredBrand =
  | "AC"
  | "AD"
  | "AL"
  | "EP"
  | "ET"
  | "EY"
  | "FX"
  | "GI"
  | "MW"
  | "NU"
  | "RO"
  | "SC"
  | "SV"
  | "SX"
  | "ZA"
  | "ZD"
  | "ZE"
  | "ZI"
  | "ZL"
  | "ZR"
  | "ZT";

export type CarSearchFormSpecialOrAdvertisedDiscountCodeOption = {
  __typename?: "CarSearchFormSpecialOrAdvertisedDiscountCodeOption";
  discountCodeInput: EgdsTextInputField;
  discountCodeTypeOption: Scalars["String"]["output"];
};

export type CarSearchFormSpecialOrAdvertisedOption = {
  __typename?: "CarSearchFormSpecialOrAdvertisedOption";
  addAnotherDiscountCode: EgdsButton;
  discountCodeOptions: Array<CarSearchFormSpecialOrAdvertisedDiscountCodeOption>;
  discountCodeType: EgdsSelect;
  maxDiscountCode: Scalars["Int"]["output"];
  optionLabel: Scalars["String"]["output"];
};

export type CarSearchFormSpecialRates = {
  __typename?: "CarSearchFormSpecialRates";
  discountCode: CarSearchFormDiscountCode;
  label: Scalars["String"]["output"];
  showAarpRate?: Maybe<EgdsPill>;
};

export type CarSearchListing =
  | CarMessagingCard
  | CarOfferCard
  | CarSaleCard
  | InsurtechPrimingCarListingCard
  | SponsoredContentPlacement;

export type CarSearchLocation = {
  __typename?: "CarSearchLocation";
  fullName: Scalars["String"]["output"];
  regionId?: Maybe<Scalars["String"]["output"]>;
  shortName: Scalars["String"]["output"];
};

export type CarSearchLocations = {
  __typename?: "CarSearchLocations";
  dropOffLocation?: Maybe<CarSearchLocation>;
  pickUpLocation: CarSearchLocation;
  routeType: CarRouteType;
  searchAccessibility: Scalars["String"]["output"];
};

export type CarSearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "CarSearchMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type CarSearchPagination = {
  __typename?: "CarSearchPagination";
  size: Scalars["Int"]["output"];
  startingIndex: Scalars["Int"]["output"];
};

export type CarSearchResults = {
  __typename?: "CarSearchResults";
  adsTargetingData?: Maybe<AdsTargetingData>;
  carAppliedSortAndFilters: CarAppliedSortAndFilters;
  /** @deprecated use carRentalLocations node instead */
  carRentalLocation: CarOfferLocations;
  carRentalLocations: CarSearchLocations;
  carSearchListings: Array<CarSearchListing>;
  carsShoppingContext: CarsShoppingContext;
  changeRentalLocation?: Maybe<ChangeRentalLocation>;
  filterNoMatchMessage?: Maybe<CarSearchFilterNoMatchMessage>;
  isCaliforniaUser?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated This field is no longer in use */
  isNonCaliforniaPointOfPurchase: Scalars["Boolean"]["output"];
  /** @deprecated Use 'carSearchListings going forward */
  listings: Array<CarListingCard>;
  loadMoreAction?: Maybe<CarLoadMoreAction>;
  loyaltyInfo?: Maybe<Array<CarPhrase>>;
  makeModelDisclaimer: CarPhrase;
  map?: Maybe<CarMap>;
  multiItemAnchorPriceToken?: Maybe<Scalars["String"]["output"]>;
  multiItemPickUpDropOffMessage?: Maybe<MultiItemPickUpDropOffMessage>;
  multiItemPlayBackTitleBar?: Maybe<MultiItemPlayBackTitleBar>;
  oneKeyInfo?: Maybe<OneKeyInfo>;
  recommendedSortDisclaimer?: Maybe<CarPhrase>;
  recommendedSortExplanation: CarDisclaimerDialog;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  shoppingContext?: Maybe<ShoppingContext>;
  sortAndFilter: ShoppingSortAndFilters;
  summary: CarSearchSummary;
};

export type CarSearchSummary = {
  __typename?: "CarSearchSummary";
  carRecommendations?: Maybe<Array<Maybe<CarRecommendations>>>;
  dynamicTitle: Array<Array<CarPhrase>>;
  includedFeeFooter?: Maybe<IncludedFeeFooter>;
  pageTitle: Scalars["String"]["output"];
  /** @deprecated to be deleted to use dynamicTitle */
  title: Scalars["String"]["output"];
};

export type CarShareFeedbackAction = {
  __typename?: "CarShareFeedbackAction";
  button: CarActionableItem;
  text: Scalars["String"]["output"];
};

export type CarShortList = {
  __typename?: "CarShortList";
  favorited: Scalars["Boolean"]["output"];
  removeUrl: Scalars["String"]["output"];
  saveUrl: Scalars["String"]["output"];
};

export type CarSkipTheCounterInfo = {
  __typename?: "CarSkipTheCounterInfo";
  icon?: Maybe<Icon>;
  infos: Array<CarPhrase>;
  title: Scalars["String"]["output"];
};

export type CarSummary = {
  __typename?: "CarSummary";
  pickupDateTime: CarPhraseText;
  pickupLocation: CarPhraseText;
  vehicleCategory: CarPhraseText;
  vendor: CarVendor;
};

export type CarTelesales = {
  __typename?: "CarTelesales";
  label: Scalars["String"]["output"];
  phoneNumberLink: CarActionableItem;
};

export type CarToolbar = {
  __typename?: "CarToolbar";
  action: CarAction;
  icon: Icon;
};

export type CarVariant = {
  __typename?: "CarVariant";
  additionalInfo: CarVariantAdditionalInfo;
  badges: Array<Scalars["String"]["output"]>;
  carOfferToken: Scalars["String"]["output"];
  features: Array<CarVariantFeature>;
  price: CarVariantPriceSummary;
  selection: CarVariantSelection;
  title: Scalars["String"]["output"];
};

export type CarVariantAdditionalInfo = {
  __typename?: "CarVariantAdditionalInfo";
  icon: Icon;
  infoPanel: CarVariantInfoPanel;
};

export type CarVariantFeature = {
  __typename?: "CarVariantFeature";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type CarVariantInfoPanel = {
  __typename?: "CarVariantInfoPanel";
  details: Array<CarVariantInfoPanelDetail>;
  summary: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CarVariantInfoPanelDetail = {
  __typename?: "CarVariantInfoPanelDetail";
  features: Array<CarVariantInfoPanelFeature>;
  text: Scalars["String"]["output"];
};

export type CarVariantInfoPanelFeature = {
  __typename?: "CarVariantInfoPanelFeature";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type CarVariantPriceSummary = {
  __typename?: "CarVariantPriceSummary";
  lead: CarPriceInfo;
  messages: Array<Scalars["String"]["output"]>;
  total?: Maybe<CarPriceInfo>;
};

export type CarVariantSelection = {
  __typename?: "CarVariantSelection";
  icon?: Maybe<Icon>;
  status: Scalars["Boolean"]["output"];
  text: Scalars["String"]["output"];
};

export type CarVariantsSummary = {
  __typename?: "CarVariantsSummary";
  title: Scalars["String"]["output"];
  variants: Array<CarVariant>;
};

export type CarVendor = {
  __typename?: "CarVendor";
  image?: Maybe<Image>;
};

export type CarVendorLocationInfo = {
  __typename?: "CarVendorLocationInfo";
  icon: Icon;
  /** @deprecated Use locationSubInfoMap instead since it has the provision of accessibility */
  locationSubInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  locationSubInfoMap?: Maybe<Array<LocationSubInfoMap>>;
  text: Scalars["String"]["output"];
};

export type CardActions = "DISMISS";

export type CardConfiguration = PaymentCardConfiguration;

export type CardNumberInput = {
  __typename?: "CardNumberInput";
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  paymentMark?: Maybe<PaymentMark>;
  value: Scalars["String"]["output"];
};

export type CardSize = "LARGE" | "MEDIUM" | "SMALL";

export type CardStatus = "NOT_UPGRADED" | "REFUNDABLE" | "UPDATED" | "UPGRADED";

export type CardTheme =
  | "GLOBAL_LOYALTY_EXTRAHIGH"
  | "GLOBAL_LOYALTY_HIGH"
  | "GLOBAL_LOYALTY_LOW"
  | "GLOBAL_LOYALTY_MIDDLE"
  | "GLOBAL_LOYALTY_STANDARD"
  | "PRIMARY"
  | "SECONDARY";

export type CardinalTemplate = {
  __typename?: "CardinalTemplate";
  category: PluralCategory;
  template: Scalars["String"]["output"];
};

export type CarouselAction = {
  __typename?: "CarouselAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type CarouselActions = {
  __typename?: "CarouselActions";
  next: CarouselAction;
  previous: CarouselAction;
};

export type CarouselButton = EgdsCarouselItem & {
  __typename?: "CarouselButton";
  action: UiLinkAction;
  button: UiButton;
  id: Scalars["String"]["output"];
};

export type CarouselIcon = EgdsCarouselItem & {
  __typename?: "CarouselIcon";
  action: UiLinkAction;
  button: UiPrimaryButton;
  id: Scalars["String"]["output"];
};

export type CarouselItemAccessibility = {
  __typename?: "CarouselItemAccessibility";
  accessibilityNext?: Maybe<Scalars["String"]["output"]>;
  accessibilityPrevious?: Maybe<Scalars["String"]["output"]>;
};

export type CarouselScrimType = "INSET_PAGINATION_DOT" | "OFFSET_PAGINATION_DOT";

export type CarryOnBagType = "CARRY_ON_BAG";

export type CarsDialog = {
  __typename?: "CarsDialog";
  buttonText: Scalars["String"]["output"];
  closeButtonLabel?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<CarsDialogContent>;
  text: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  type?: Maybe<CarsDialogType>;
};

export type CarsDialogBody = {
  __typename?: "CarsDialogBody";
  body: Array<CarPhrase>;
  title: CarPhrase;
};

export type CarsDialogContent = {
  __typename?: "CarsDialogContent";
  body: Array<CarsDialogBody>;
  footer?: Maybe<Array<CarPhrase>>;
  header: CarPhrase;
};

export type CarsDialogType = "LIMITED_RESULTS" | "LIMITED_RESULTS_WITHOUT_TURO" | "LIMITED_RESULTS_WITH_TURO";

export interface CarsDomainRequestContextInput {
  partnerContext: PartnerContextInput;
  principalJWT: Scalars["String"]["input"];
}

export type CarsErrorContent = {
  __typename?: "CarsErrorContent";
  analytics: CarAnalytics;
  errorEventName?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  locationAnalytics?: Maybe<CarAnalytics>;
  resetButtonAction?: Maybe<UiPrimaryButton>;
  subText: Scalars["String"]["output"];
};

export type CarsRichText = {
  __typename?: "CarsRichText";
  style?: Maybe<CarsRichTextStyle>;
  theme?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type CarsRichTextStyle = "BOLD" | "BULLET" | "ITALICIZE";

export type CarsSearchResultsWrapper = {
  __typename?: "CarsSearchResultsWrapper";
  carRecommendationContent?: Maybe<Array<CarRecommendations>>;
  carSearchResults?: Maybe<CarSearchResults>;
  carsErrorContent?: Maybe<CarsErrorContent>;
};

export type CarsShoppingContext = {
  __typename?: "CarsShoppingContext";
  searchId?: Maybe<Scalars["String"]["output"]>;
};

export type Cart = {
  __typename?: "Cart";
  id: Scalars["UUID"]["output"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  itemCount: Scalars["Int"]["output"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  items: Array<CartItem>;
};

export interface CartItem {
  id: Scalars["UUID"]["output"];
}

export type CartMutations = {
  __typename?: "CartMutations";
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  addProperty: CartSummary;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  emptyCart: CartSummary;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  remove: CartSummary;
};

export type CartMutationsAddPropertyArgs = {
  key: PropertyOfferInput;
  searchContext: CartPropertySearchContextInput;
};

export type CartMutationsRemoveArgs = {
  id: Scalars["UUID"]["input"];
};

export interface CartOptionsInput {
  ttl: CartTtl;
}

export type CartPropertySearchContext = {
  __typename?: "CartPropertySearchContext";
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  checkIn: Date;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  checkOut: Date;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  regionId: Scalars["String"]["output"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  rooms: Array<PropertyRoom>;
};

export interface CartPropertySearchContextInput {
  checkIn: DateInput;
  checkOut: DateInput;
  regionId: Scalars["String"]["input"];
  rooms: Array<PropertyRoomInput>;
}

export type CartSummary = {
  __typename?: "CartSummary";
  id: Scalars["UUID"]["output"];
  itemCount: Scalars["Int"]["output"];
};

export type CartTtl = "FIVE_MINUTES" | "SEVEN_DAYS" | "TWELVE_HOURS";

export type CategoryFilterSummary = {
  __typename?: "CategoryFilterSummary";
  details: ActivityCategoryDetails;
  size: Scalars["Int"]["output"];
  subCategories?: Maybe<Array<ActivityCategoryDetails>>;
};

export type CategoryFilterViewModel = {
  __typename?: "CategoryFilterViewModel";
  displayOrder: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  summary?: Maybe<Array<CategoryFilterSummary>>;
  /** @deprecated should use name, i.e. localized value */
  type: ActivityCategoryType;
};

export type CategoryListContentEventAttributes = {
  __typename?: "CategoryListContentEventAttributes";
  categoryId: Scalars["String"]["output"];
  productLine: Scalars["String"]["output"];
};

export type CategorySelectionComponent = {
  __typename?: "CategorySelectionComponent";
  carousel: EgdsCarousel;
  seeAllLink?: Maybe<SeeAllCategoriesLink>;
};

export type ChangeFlightLabelPresentation = {
  __typename?: "ChangeFlightLabelPresentation";
  changeFlightLabel: FlightsActionableLabel;
  departureDate: FlightsPhrase;
};

export type ChangeOfferAction = LodgingPrepareChangeCheckoutAction | SelectChangeOfferAction;

export type ChangeOfferCallToAction = {
  __typename?: "ChangeOfferCallToAction";
  action?: Maybe<ChangeOfferAction>;
  button?: Maybe<UiPrimaryButton>;
};

export type ChangeOfferDetails = {
  __typename?: "ChangeOfferDetails";
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  banner?: Maybe<LodgingChangeShoppingBanner>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  callToAction?: Maybe<ChangeOfferCallToAction>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  cancellationChange?: Maybe<RatePlanMessage>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  changePlaybackSections?: Maybe<Array<LodgingChangePlaybackSection>>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  changeYourOptionsLink: EgdsStandardLink;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  comboChangeType?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  disclaimer?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  errorRedirectUrlPath?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  errorType?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  listings?: Maybe<Array<LodgingCategorizedUnit>>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  messaging?: Maybe<ShoppingProductContentSection>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  propertyId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  subtitle: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  title: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of BookingServicingLodgingSearch schema */
  totalDifference?: Maybe<PricePresentationSection>;
};

export type ChangePackageConfirmationDialog = {
  __typename?: "ChangePackageConfirmationDialog";
  content: MishopUiChangePackageConfirmationDialogContent;
  dialog: EgdsActionDialog;
  trigger: EgdsButton;
};

export type ChangePackageSearchCriteria = {
  __typename?: "ChangePackageSearchCriteria";
  dateRange: DateRange;
  multiItemSessionId: Scalars["String"]["output"];
  origin: PackageLocation;
  travelerGroups: Array<TravelerOccupancyGroup>;
};

export type ChangeRentalLocation = {
  __typename?: "ChangeRentalLocation";
  link: CarPhrase;
  placeHolder: CarPhrase;
  primary: CarPhrase;
  secondary: CarPhrase;
};

export type ChangeType = "DATES" | "GUEST_COUNT" | "ROOM";

export type ChannelType = "VIRTUAL_AGENT";

export type ChatBotIntentType = "CANCEL" | "CHANGE" | "VIEW_BOOKING";

export type ChatBotProductType = "FLIGHT";

export type ChatBotWaiverRedemptionState = "CREDIT_REDEMPTION";

export type ChatCapabilities = {
  __typename?: "ChatCapabilities";
  enableCoachmark?: Maybe<Scalars["Boolean"]["output"]>;
  enableHistory?: Maybe<Scalars["Boolean"]["output"]>;
  enableTagging?: Maybe<Scalars["Boolean"]["output"]>;
  notificationConfig: VirtualAgentControlNotificationConfiguration;
  presenceConfig?: Maybe<VirtualAgentControlPresenceConfiguration>;
  showAvatar?: Maybe<Scalars["Boolean"]["output"]>;
  showParticipantEventMarker?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface ChatGptExperienceGetConversationInput {
  conversationType?: InputMaybe<ConversationType>;
  tripId: Scalars["String"]["input"];
}

export type ChatGptExperienceGetConversationResult = {
  __typename?: "ChatGPTExperienceGetConversationResult";
  conversationId?: Maybe<Scalars["String"]["output"]>;
  isSuccess: Scalars["Boolean"]["output"];
  participantId?: Maybe<Scalars["String"]["output"]>;
};

export interface ChatGptUpdateTripInput {
  tripId: Scalars["String"]["input"];
}

export type ChatGptUpdateTripResult = {
  __typename?: "ChatGPTUpdateTripResult";
  success: Scalars["Boolean"]["output"];
};

export type ChatInput = {
  __typename?: "ChatInput";
  chatInputStatus?: Maybe<ChatInputStatus>;
  interactionMode?: Maybe<InteractionMode>;
};

export type ChatInputStatus = "DISABLE" | "ENABLE";

export type ChatbotAuthentication = {
  __typename?: "ChatbotAuthentication";
  code?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type ChatbotCapabilities = "CLICKSTREAM" | "GLASSBOX";

export type ChatbotConfig = {
  __typename?: "ChatbotConfig";
  auth: ChatbotAuthentication;
  /** @deprecated authToken has been replaced by auth.token */
  authToken: Scalars["String"]["output"];
  channelOriginId: Scalars["String"]["output"];
  chatbotCapabilities?: Maybe<Array<ChatbotCapabilities>>;
  conversationProperties: ConversationProperties;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]["output"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]["output"]>;
  userProperties?: Maybe<UserProperties>;
  version: ChatbotVersion;
};

export interface ChatbotConversationIntentMessage {
  intent: ChatBotIntentType;
}

export type ChatbotConversationRedeemIntentMessage = ChatbotConversationIntentMessage & {
  __typename?: "ChatbotConversationRedeemIntentMessage";
  emailAddress: Scalars["String"]["output"];
  intent: ChatBotIntentType;
  itineraryNumber: Scalars["String"]["output"];
  product: ChatBotProductType;
  waiverRedemptionState?: Maybe<ChatBotWaiverRedemptionState>;
};

export interface ChatbotDeviceIdentifiersInput {
  deviceToken?: InputMaybe<Scalars["String"]["input"]>;
  sailthruDeviceID?: InputMaybe<Scalars["String"]["input"]>;
}

export interface ChatbotIntentValueInput {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type ChatbotVersion = {
  __typename?: "ChatbotVersion";
  javascriptUrl: HttpUri;
  versionId: ChatbotVersionId;
  wrapperUrl?: Maybe<HttpUri>;
};

export type ChatbotVersionId = "V1" | "V2";

export type CheckinValidityWithReason =
  | "INVALID"
  | "INVALID_DUE_TO_BEING_IN_PAST"
  | "INVALID_DUE_TO_CHANGEOVER_EXCLUSION"
  | "INVALID_DUE_TO_MAX_FUTURE_BOOKING"
  | "INVALID_DUE_TO_MIN_PRIOR_NOTIFICATION"
  | "INVALID_DUE_TO_MIN_STAY_NOT_ACHIEVABLE"
  | "INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK"
  | "INVALID_DUE_TO_NOT_AVAILABLE"
  | "INVALID_DUE_TO_NO_VALID_CHECKOUT_WITHIN_CONSTRAINTS"
  | "VALID";

export type CheckoutAccountCreationResponse = {
  __typename?: "CheckoutAccountCreationResponse";
  analytics: Array<EgClickstreamEvent>;
  createAccount: SmartFormSignalAction;
  heading: Scalars["String"]["output"];
  passwordWithStrengthMeter: PasswordWithStrengthMeter;
  subHeading: Scalars["String"]["output"];
};

export type CheckoutAction = CheckoutRedirectAction;

export interface CheckoutBookButton {
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
}

export type CheckoutBookButtonMetaData = {
  __typename?: "CheckoutBookButtonMetaData";
  bookButtonType: Scalars["String"]["output"];
};

export type CheckoutBookButtonResponse = {
  __typename?: "CheckoutBookButtonResponse";
  /** @deprecated Will be removed permanently soon, replace with defaultButton */
  appliedPaymentMethod?: Maybe<Scalars["String"]["output"]>;
  buttons: Array<CheckoutBookButton>;
  defaultButton: CheckoutBookButton;
};

export type CheckoutBookingDialog = {
  __typename?: "CheckoutBookingDialog";
  appliedPaymentMethod?: Maybe<Scalars["String"]["output"]>;
  bookingDialogs: Array<PaymentMethodSubmittingDialog>;
};

export type CheckoutBookingDialogGroup = {
  __typename?: "CheckoutBookingDialogGroup";
  defaultDialog: BookingDialog;
  dialogList?: Maybe<Array<BookingDialog>>;
};

export type CheckoutDetails = {
  __typename?: "CheckoutDetails";
  /** @deprecated This has been substituted with checkoutBookButton. */
  bookButton: UiPrimaryButton;
  bookSubmittingDialog: BookSubmittingDialog;
  checkoutBookButton?: Maybe<CheckoutBookButtonResponse>;
  /** @deprecated This function is being deprecated as we are moving towards a dialog group approach., replace with checkoutBookingDialogGroup */
  checkoutBookingDialog?: Maybe<CheckoutBookingDialog>;
  checkoutBookingDialogGroup?: Maybe<CheckoutBookingDialogGroup>;
  checkoutIdleCallToActionDialog: CheckoutIdleCallToActionDialog;
  checkoutInactivityDialog: CheckoutInactivityDialog;
  checkoutReshopPriceChangeDialog?: Maybe<CheckoutReshopPriceChangeDialog>;
  checkoutSignInDialog?: Maybe<CheckoutSignInDialog>;
  fraudComponentDetails: FraudComponentDetails;
  getSession: CheckoutSessionDetailsResponse;
  pageTitle: Scalars["String"]["output"];
};

export type CheckoutDetailsBookButtonArgs = {
  sessionId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type CheckoutDetailsCheckoutBookButtonArgs = {
  sessionId: Scalars["UUID"]["input"];
};

export type CheckoutDetailsCheckoutBookingDialogArgs = {
  sessionId: Scalars["UUID"]["input"];
};

export type CheckoutDetailsCheckoutBookingDialogGroupArgs = {
  sessionId: Scalars["UUID"]["input"];
};

export type CheckoutDetailsCheckoutReshopPriceChangeDialogArgs = {
  sessionId: Scalars["UUID"]["input"];
};

export type CheckoutDetailsGetSessionArgs = {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  sessionID: Scalars["UUID"]["input"];
};

export type CheckoutDialog = {
  __typename?: "CheckoutDialog";
  data: CheckoutDialogData;
  hideTrigger: Array<Scalars["String"]["output"]>;
  showTrigger: Array<Scalars["String"]["output"]>;
};

export type CheckoutDialogData = {
  __typename?: "CheckoutDialogData";
  loader?: Maybe<CheckoutLoadingSpinner>;
  primaryContents: Array<EgdsPlainText>;
};

export type CheckoutDomainInfo = {
  __typename?: "CheckoutDomainInfo";
  name: Scalars["String"]["output"];
  refId: Scalars["String"]["output"];
};

export interface CheckoutDomainInfoInput {
  name: Scalars["String"]["input"];
  refId: Scalars["String"]["input"];
}

export type CheckoutExternalBookButton = CheckoutBookButton & {
  __typename?: "CheckoutExternalBookButton";
  metadata?: Maybe<CheckoutBookButtonMetaData>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  src: ButtonSource;
};

export type CheckoutFailureReason = FailureReason & {
  __typename?: "CheckoutFailureReason";
  description: Scalars["String"]["output"];
  failureDialog: MishopUiNonFullscreenDialog;
  message: Scalars["String"]["output"];
};

export type CheckoutIdleCallToActionDialog = {
  __typename?: "CheckoutIdleCallToActionDialog";
  heading: Scalars["String"]["output"];
  idleDelay: IdleDelay;
  primaryButton: UiPrimaryButton;
  primaryContent: EgdsPlainText;
};

export type CheckoutInactivityDialog = {
  __typename?: "CheckoutInactivityDialog";
  heading: Scalars["String"]["output"];
  primaryButton: UiPrimaryButton;
  primaryContent: EgdsPlainText;
};

export type CheckoutLoadingSpinner = {
  __typename?: "CheckoutLoadingSpinner";
  size: LoaderSize;
};

export type CheckoutMessage = {
  __typename?: "CheckoutMessage";
  analyticsKeywords: Array<Scalars["String"]["output"]>;
  component: Component;
  eventName?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  showImmediately: Scalars["Boolean"]["output"];
};

export type CheckoutMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "CheckoutMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type CheckoutOption = {
  __typename?: "CheckoutOption";
  type: CheckoutOptionType;
  value: Scalars["String"]["output"];
};

export interface CheckoutOptionInput {
  type: CheckoutOptionType;
  value: Scalars["String"]["input"];
}

export type CheckoutOptionType =
  | "CROSS_SELL"
  | "DESTINATION_ID"
  | "FENCING_ATTRIBUTES"
  | "HOTEL_BUSINESS_MODEL"
  | "HOTEL_MARKETING_CHANNEL"
  | "INSTANT_BOOK"
  | "LOYALTY_REWARDS_ID"
  | "META_DEEPLINK"
  | "NATIVE"
  | "PRICE_TOKEN"
  | "PROPERTY_UPSELL_AMOUNT"
  | "RATE_UPGRADE_ELIGIBLE"
  | "RATE_UPSELL"
  | "RATE_UPSELL_AMOUNT"
  | "REFERRER_ID"
  | "REFUNDABLE_DAMAGE_DEPOSIT_REQUIRED"
  | "ROOM_UPGRADE_ELIGIBLE"
  | "ROOM_UPSELL"
  | "SEARCH_ID"
  | "SHOPPING_DEAL_BADGE_PERCENTAGE"
  | "SHOP_WITH_POINTS"
  | "TAAP_PACKAGE_RATES";

export type CheckoutOptions = {
  __typename?: "CheckoutOptions";
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CheckoutPrimaryBookButton = CheckoutBookButton & {
  __typename?: "CheckoutPrimaryBookButton";
  content: UiPrimaryButton;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
};

export type CheckoutRedirectAction = {
  __typename?: "CheckoutRedirectAction";
  url: Scalars["String"]["output"];
};

export interface CheckoutRedirectInput {
  cabinNumber: Scalars["String"]["input"];
  deck: Scalars["String"]["input"];
  offerToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type CheckoutReshopPriceChangeDialog = {
  __typename?: "CheckoutReshopPriceChangeDialog";
  closeDialogButton: UiTertiaryButton;
  mark: Mark;
  primaryText: Scalars["String"]["output"];
  secondaryText: Scalars["String"]["output"];
};

export type CheckoutResource = {
  __typename?: "CheckoutResource";
  participant: Scalars["String"]["output"];
  resourceIdentifier: ResourceIdentifier;
};

export type CheckoutSessionDetailsResponse = {
  __typename?: "CheckoutSessionDetailsResponse";
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  resources?: Maybe<Array<CheckoutResource>>;
  signals: Array<CheckoutUiSignal>;
  token?: Maybe<Scalars["UUID"]["output"]>;
};

export interface CheckoutSessionInfoInput {
  sessionId: Scalars["UUID"]["input"];
  sessionToken: Scalars["UUID"]["input"];
}

export type CheckoutSignInCallToAction = {
  __typename?: "CheckoutSignInCallToAction";
  button: UiPrimaryButton;
  url?: Maybe<HttpUri>;
};

export type CheckoutSignInDialog = {
  __typename?: "CheckoutSignInDialog";
  buttons: CheckoutSignInDialogButtons;
  icon: Icon;
  primaryText: Scalars["String"]["output"];
  secondaryText: Scalars["String"]["output"];
};

export type CheckoutSignInDialogButtons = {
  __typename?: "CheckoutSignInDialogButtons";
  closeDialogButton: UiTertiaryButton;
  signInButton: CheckoutSignInCallToAction;
};

export type CheckoutSubscription = {
  __typename?: "CheckoutSubscription";
  actionPayloads: Array<ActionPayload>;
  signalUrn: Scalars["String"]["output"];
};

export type CheckoutUiSignal = {
  __typename?: "CheckoutUISignal";
  domainInfoList?: Maybe<Array<CheckoutDomainInfo>>;
  payload?: Maybe<Array<SignalDetail>>;
  signal: UiSignal;
};

export type CheckoutValidityWithReason =
  | "INVALID"
  | "INVALID_DUE_TO_CHANGEOVER_EXCLUSION"
  | "INVALID_DUE_TO_NON_CHANGEOVER_DAY_OF_WEEK"
  | "VALID";

export type Child = {
  __typename?: "Child";
  age: Scalars["Int"]["output"];
};

export interface ChildInput {
  age: Scalars["Int"]["input"];
}

export type ChronoUnit =
  | "CENTURIES"
  | "DAYS"
  | "DECADES"
  | "ERAS"
  | "FOREVER"
  | "HALFDAYS"
  | "HOURS"
  | "MICROS"
  | "MILLENNIA"
  | "MILLIS"
  | "MINUTES"
  | "MONTHS"
  | "NANOS"
  | "SECONDS"
  | "WEEKS"
  | "YEARS";

export type ClaimIncentive = {
  __typename?: "ClaimIncentive";
  button?: Maybe<EgdsButton>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  link?: Maybe<EgdsStandardLink>;
  subtext?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CleaningAndSafetyPractice = "ENHANCED_CLEANING";

export type CleanlinessDialog = {
  __typename?: "CleanlinessDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingDialogTriggerMessage;
};

export type ClickStreamTripInfo = {
  __typename?: "ClickStreamTripInfo";
  tripDomainId?: Maybe<Scalars["String"]["output"]>;
};

export type ClickToClaimToast = {
  __typename?: "ClickToClaimToast";
  toast: EgdsToast;
};

export type ClickstreamAnalytics = {
  __typename?: "ClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: EgClickstreamPayload;
};

export type ClickstreamAnalyticsData = {
  __typename?: "ClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]["output"]>;
  eventVersion?: Maybe<Scalars["String"]["output"]>;
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type ClickstreamError = {
  __typename?: "ClickstreamError";
  code?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ClientAccessibility = {
  __typename?: "ClientAccessibility";
  text: Scalars["String"]["output"];
};

export interface ClientContextInput {
  timeZoneId: Scalars["String"]["input"];
}

export type ClientInfo = {
  __typename?: "ClientInfo";
  name: Scalars["String"]["output"];
  version?: Maybe<Scalars["String"]["output"]>;
};

export interface ClientInfoInput {
  name: Scalars["String"]["input"];
  version?: InputMaybe<Scalars["String"]["input"]>;
}

export type ClientRequestContext = {
  __typename?: "ClientRequestContext";
  context: Context;
  deviceContext: DeviceContext;
  localeInfo: LocaleInfo;
  simpleContext: Context;
  siteContext: SiteContext;
};

export interface ClientRequestInput {
  headers: Array<HttpHeaderInput>;
  remoteAddress: Scalars["String"]["input"];
  url: Scalars["String"]["input"];
}

export type ClientSideAnalytics = {
  __typename?: "ClientSideAnalytics";
  eventType?: Maybe<EgReferrerEventType>;
  linkName: Scalars["String"]["output"];
  referrerId: Scalars["String"]["output"];
  uisPrimeMessages: Array<EgUisMicroMessage>;
  urls?: Maybe<Array<Scalars["URL"]["output"]>>;
};

export interface ClientSideErrorRepresentation {
  /** @deprecated This field is deprecated and replaced with clientSideErrorRepresentationDialog */
  clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
  clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
}

export type ClientSideErrorRepresentationDialog = EgdsDialog & {
  __typename?: "ClientSideErrorRepresentationDialog";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  content: Scalars["String"]["output"];
  dialogFooter: ClientSideErrorRepresentationDialogFooter;
  displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  heading: Scalars["String"]["output"];
  paragraph: Scalars["String"]["output"];
};

export type ClientSideErrorRepresentationDialogFooter = {
  __typename?: "ClientSideErrorRepresentationDialogFooter";
  buttons: Array<ClientSideErrorRepresentationDialogFooterButton>;
};

export type ClientSideErrorRepresentationDialogFooterButton = {
  __typename?: "ClientSideErrorRepresentationDialogFooterButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: ClientSideErrorRepresentationDialogFooterButtonAction;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
};

export type ClientSideErrorRepresentationDialogFooterButtonAction = {
  __typename?: "ClientSideErrorRepresentationDialogFooterButtonAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionType: DialogButtonActionType;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type ClientSideIllustrationAsset = "FlightHotelIncludedSet";

export type ClientSideImpressionAnalytics = {
  __typename?: "ClientSideImpressionAnalytics";
  clickstreamAnalytics?: Maybe<ClickstreamAnalytics>;
  /** @deprecated error has been deprecated */
  error?: Maybe<BooleanEnum>;
  /** @deprecated name has been deprecated, replace with uisPrimeAnalytics.eventType */
  name?: Maybe<EventType>;
  uisPrimeAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated value has been deprecated, replace with uisPrimeAnalytics.referrerId */
  value?: Maybe<Scalars["Float"]["output"]>;
};

export type ClientSideImpressionEventAnalytics = {
  __typename?: "ClientSideImpressionEventAnalytics";
  event: Scalars["String"]["output"];
  linkName?: Maybe<Scalars["String"]["output"]>;
  referrerId: Scalars["String"]["output"];
  uisPrimeMessages: Array<EgUisMicroMessage>;
  /** @deprecated Please use the URLs list field */
  url?: Maybe<Scalars["URL"]["output"]>;
  urls?: Maybe<Array<Scalars["URL"]["output"]>>;
};

export type ClipboardUri = Uri & {
  __typename?: "ClipboardURI";
  body: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CloseType = EgdsSheet;

export type ClosedPeriodBounding = "LOWER" | "UPPER";

export type ClubbedPrice = {
  __typename?: "ClubbedPrice";
  additionalBenefits?: Maybe<Array<CarPhraseIconText>>;
  additionalPaymentMessages?: Maybe<Array<CarPhrase>>;
  cancellationMessages?: Maybe<Array<CarPhrase>>;
  heading?: Maybe<CarPhrase>;
  infositeURL: HttpUri;
  loyaltyEarnMessages?: Maybe<Array<CarPhrase>>;
  offerBadges: Array<CarOfferBadge>;
  paymentMessages: Array<CarPhrase>;
  priceBadges: Array<CarOfferBadge>;
  priceSummary: CarPriceSummary;
  reserveButtonAction: CarAction;
  reserveButtonText: Scalars["String"]["output"];
  title: CarPhrase;
};

export type ClubbedPriceOptions = {
  __typename?: "ClubbedPriceOptions";
  dialogContent: ClubbedPriceOptionsDialog;
  dialogTrigger: CarActionableItem;
};

export type ClubbedPriceOptionsDialog = {
  __typename?: "ClubbedPriceOptionsDialog";
  clubbedPrices: Array<ClubbedPrice>;
  commonAdditionalBenefits?: Maybe<Array<CarPhraseIconText>>;
  modalFooter: Scalars["String"]["output"];
  optionsSummary: Array<CarPhrase>;
  title: Scalars["String"]["output"];
  vehicleCategory: Scalars["String"]["output"];
  vehicleDescription?: Maybe<Scalars["String"]["output"]>;
  vendor: CarVendor;
};

export type CoBrandBannerData = {
  __typename?: "CoBrandBannerData";
  endDate?: Maybe<Scalars["String"]["output"]>;
  logoData?: Maybe<CoBrandBannerLogoData>;
  startDate?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This property will not be supported in future. Please use styles. */
  style: CoBrandBannerStyle;
  styles: CoBrandBannerStyles;
  textData?: Maybe<CoBrandBannerTextData>;
  timezone?: Maybe<Timezone>;
};

export type CoBrandBannerLogoData = {
  __typename?: "CoBrandBannerLogoData";
  altText: Scalars["String"]["output"];
  desktop: CoBrandBannerLogoDetail;
  href?: Maybe<Scalars["URL"]["output"]>;
  logoContainerStyle: CoBrandBannerStyle;
  mobile: CoBrandBannerLogoDetail;
  tablet: CoBrandBannerLogoDetail;
};

export type CoBrandBannerLogoDetail = {
  __typename?: "CoBrandBannerLogoDetail";
  imgSrc: Scalars["URL"]["output"];
  style: Array<CoBrandBannerProperty>;
};

export type CoBrandBannerProperty = {
  __typename?: "CoBrandBannerProperty";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CoBrandBannerStyle = {
  __typename?: "CoBrandBannerStyle";
  commonStyle?: Maybe<Array<CoBrandBannerProperty>>;
  desktop?: Maybe<Array<CoBrandBannerProperty>>;
  mobile?: Maybe<Array<CoBrandBannerProperty>>;
  tablet?: Maybe<Array<CoBrandBannerProperty>>;
};

export type CoBrandBannerStyles = {
  __typename?: "CoBrandBannerStyles";
  mainContainer: CoBrandBannerStyle;
  subContainer: CoBrandBannerStyle;
};

export type CoBrandBannerTextData = {
  __typename?: "CoBrandBannerTextData";
  textContainerStyle: CoBrandBannerStyle;
  textSections: Array<CoBrandBannerTextSection>;
};

export type CoBrandBannerTextSection = {
  __typename?: "CoBrandBannerTextSection";
  style?: Maybe<Array<CoBrandBannerProperty>>;
  textTokens: Array<CoBrandBannerTextToken>;
};

export type CoBrandBannerTextToken = {
  __typename?: "CoBrandBannerTextToken";
  href?: Maybe<Scalars["URL"]["output"]>;
  style?: Maybe<Array<CoBrandBannerProperty>>;
  text: Scalars["String"]["output"];
};

export type CoachingTipsView =
  | "IN_CONTEXT_COACHING_CAROUSEL"
  | "OPPY_HUB"
  | "STANDALONE_MINIMAL"
  | "STANDALONE_SUGGESTED";

export type CollectionCarouselHeroTile = {
  __typename?: "CollectionCarouselHeroTile";
  carouselCTAButton: IncentivesActionLink;
  content: IncentivesTileContent;
  nextSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  offers: Array<IncentivesOfferTile>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  previousSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionCarouselHeroTilesBody = {
  __typename?: "CollectionCarouselHeroTilesBody";
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<CollectionCarouselHeroTile>;
};

export type CollectionCarouselMediaTilesBody = {
  __typename?: "CollectionCarouselMediaTilesBody";
  carouselCTAButton?: Maybe<IncentivesActionLink>;
  nextSelectedAnalytics: CollectionClickstreamAnalytics;
  previousSelectedAnalytics: CollectionClickstreamAnalytics;
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<IncentivesMediaTile>;
};

export type CollectionClickstreamAnalytics = {
  __typename?: "CollectionClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: CollectionClickstreamPayload;
};

export type CollectionClickstreamPayload = {
  __typename?: "CollectionClickstreamPayload";
  categoryId?: Maybe<Scalars["String"]["output"]>;
  collectionId: Scalars["String"]["output"];
  collectionType: CollectionType;
  componentId?: Maybe<Scalars["String"]["output"]>;
  componentName?: Maybe<Scalars["String"]["output"]>;
  componentPosition?: Maybe<Scalars["Int"]["output"]>;
  destinationGeoId?: Maybe<Scalars["String"]["output"]>;
  domainId?: Maybe<Scalars["String"]["output"]>;
  globalResponseId?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  responseId?: Maybe<Scalars["String"]["output"]>;
  trackingId?: Maybe<Scalars["String"]["output"]>;
};

export type CollectionGroupedMediaTile = {
  __typename?: "CollectionGroupedMediaTile";
  content: IncentivesTileContent;
  ctaButton: IncentivesActionLink;
  offers: Array<IncentivesMediaTile>;
};

export type CollectionGroupedMediaTilesBody = {
  __typename?: "CollectionGroupedMediaTilesBody";
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<CollectionGroupedMediaTile>;
};

export type CollectionHeroImage = {
  __typename?: "CollectionHeroImage";
  image: Image;
};

export type CollectionItemClickthrough = "PDP" | "PINNED_SRP";

export type CollectionPaginationAction = {
  __typename?: "CollectionPaginationAction";
  button: EgdsButton;
  paginationQuery: CollectionPaginationQuery;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionPaginationContext = {
  __typename?: "CollectionPaginationContext";
  maxPageSize?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  pageSize?: Maybe<Scalars["Int"]["output"]>;
};

export interface CollectionPaginationContextInput {
  maxPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}

export type CollectionPaginationQuery = {
  __typename?: "CollectionPaginationQuery";
  paginationContext: CollectionPaginationContext;
};

export type CollectionTabbedCarouselMediaTile = {
  __typename?: "CollectionTabbedCarouselMediaTile";
  content: IncentivesTileContent;
  ctaButton: IncentivesActionLink;
  listPresentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  nextSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  offers: Array<IncentivesMediaTile>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  previousSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionTabbedCarouselMediaTilesBody = {
  __typename?: "CollectionTabbedCarouselMediaTilesBody";
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<CollectionTabbedCarouselMediaTile>;
};

export type CollectionTabbedCarouselTile = {
  __typename?: "CollectionTabbedCarouselTile";
  content: IncentivesTileContent;
  ctaButton?: Maybe<IncentivesActionLink>;
  listPresentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  nextSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  offers: Array<IncentivesMediaTile>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  previousSelectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionTabbedCarouselTilesBody = {
  __typename?: "CollectionTabbedCarouselTilesBody";
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<CollectionTabbedCarouselTile>;
};

export type CollectionTripsSaveItem = {
  __typename?: "CollectionTripsSaveItem";
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  savedTrips: TripsSaveItem;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type CollectionType = "FLIGHT" | "GEOGRAPHY" | "LODGING" | "PACKAGE";

export type CommissionTier = "BASIC" | "BASIC_PLUS" | "PREMIUM" | "PREMIUM_PLUS";

export type CommunicationCenter = HeaderSharedUi & {
  __typename?: "CommunicationCenter";
  skipSsr: Scalars["Boolean"]["output"];
};

export type CommunicationCenterActionCard = {
  __typename?: "CommunicationCenterActionCard";
  body?: Maybe<Array<EgdsStylizedText>>;
  heading?: Maybe<EgdsStylizedText>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
};

export type CommunicationCenterAttachmentView = {
  __typename?: "CommunicationCenterAttachmentView";
  authenticated: Scalars["Boolean"]["output"];
  authorized: Scalars["Boolean"]["output"];
};

export type CommunicationCenterBanner = {
  __typename?: "CommunicationCenterBanner";
  action?: Maybe<CommunicationCenterButton>;
  body?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  theme: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CommunicationCenterButton = {
  __typename?: "CommunicationCenterButton";
  action?: Maybe<UiLinkAction>;
  button?: Maybe<EgdsButton>;
};

export interface CommunicationCenterCard {
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"]["output"];
  title?: Maybe<EgdsStylizedText>;
}

export type CommunicationCenterConversation = {
  __typename?: "CommunicationCenterConversation";
  banner?: Maybe<CommunicationCenterBanner>;
  conversation?: Maybe<CommunicationCenterConversationCard>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  genericConversation?: Maybe<CommunicationCenterCard>;
  heading?: Maybe<EgdsStylizedText>;
};

export type CommunicationCenterConversationCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterConversationCard";
  author?: Maybe<Scalars["String"]["output"]>;
  authorChannel?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"]["output"];
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  conversationType?: Maybe<CommunicationCenterConversationType>;
  defaultPreviewMessage?: Maybe<EgdsStylizedText>;
  entityDetail?: Maybe<CommunicationCenterConversationEntity>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  location?: Maybe<EgdsStylizedText>;
  participantId?: Maybe<Scalars["String"]["output"]>;
  sentDateTime?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<CommunicationCenterConversationState>;
  timeStamp?: Maybe<EgdsStylizedText>;
  title?: Maybe<EgdsStylizedText>;
};

export type CommunicationCenterConversationEntity = {
  __typename?: "CommunicationCenterConversationEntity";
  banner?: Maybe<CommunicationCenterBanner>;
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  entityName?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  navigationIcon?: Maybe<Icon>;
  status?: Maybe<EgdsStandardBadge>;
  tripDate?: Maybe<EgdsStylizedText>;
  viewDetailButton?: Maybe<CommunicationCenterButton>;
};

export type CommunicationCenterConversationMutationResponse = {
  __typename?: "CommunicationCenterConversationMutationResponse";
  conversationId: Scalars["String"]["output"];
  operationStatus: CommunicationCenterOperationStatus;
  state: CommunicationCenterConversationState;
};

export type CommunicationCenterConversationState = "READ" | "UNKNOWN" | "UNREAD";

export type CommunicationCenterConversationTrip = {
  __typename?: "CommunicationCenterConversationTrip";
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  inviteIcon?: Maybe<Icon>;
  navigationIcon?: Maybe<Icon>;
  participantsName?: Maybe<EgdsStylizedText>;
  /** @deprecated Use navigationIconButton instead */
  tripBoardNavigationIcon?: Maybe<Icon>;
  tripBoardNavigationIconButton?: Maybe<CommunicationCenterIcon>;
  tripId?: Maybe<Scalars["String"]["output"]>;
  tripName?: Maybe<EgdsStylizedText>;
};

export type CommunicationCenterConversationType = "SUPPLIER" | "TRIP_COLLABORATION";

export type CommunicationCenterConversationView = {
  __typename?: "CommunicationCenterConversationView";
  banner?: Maybe<CommunicationCenterBanner>;
  conversation: Array<Message>;
  conversationMetadata: ConversationMetadata;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  fileUploadSection?: Maybe<ConversationViewFileUpload>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  messageInput: MessageInput;
};

export type CommunicationCenterConversations = {
  __typename?: "CommunicationCenterConversations";
  banner?: Maybe<CommunicationCenterBanner>;
  clickToActionButton?: Maybe<CommunicationCenterButton>;
  conversations?: Maybe<Array<CommunicationCenterConversationCard>>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  genericConversations?: Maybe<Array<CommunicationCenterCard>>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  unread?: Maybe<Scalars["Int"]["output"]>;
};

export type CommunicationCenterEmptyState = {
  __typename?: "CommunicationCenterEmptyState";
  /** @deprecated replace with actions */
  actionButton?: Maybe<Array<EgdsButton>>;
  actions?: Maybe<Array<CommunicationCenterButton>>;
  body: Array<EgdsText>;
  emptyStateType?: Maybe<EmptyStateType>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  title: EgdsStylizedText;
};

export type CommunicationCenterEntryPoint = {
  __typename?: "CommunicationCenterEntryPoint";
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  defaultTab?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  label?: Maybe<Scalars["String"]["output"]>;
  messageUnseenCount?: Maybe<Scalars["Int"]["output"]>;
  notificationUnseenCount?: Maybe<Scalars["Int"]["output"]>;
};

export type CommunicationCenterFormMessage = {
  __typename?: "CommunicationCenterFormMessage";
  displayType: CommunicationCenterNotificationMessageDisplayType;
  level: CommunicationCenterNotificationMessageLevel;
  name: Scalars["String"]["output"];
  title: EgdsStylizedText;
};

export type CommunicationCenterFormSection = {
  __typename?: "CommunicationCenterFormSection";
  fields: Array<CommunicationCenterButton>;
  title: EgdsStylizedText;
};

export type CommunicationCenterIcon = {
  __typename?: "CommunicationCenterIcon";
  action: UiLinkAction;
  icon: Icon;
};

export type CommunicationCenterImage = {
  __typename?: "CommunicationCenterImage";
  image?: Maybe<Image>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type CommunicationCenterLink = {
  __typename?: "CommunicationCenterLink";
  action: UiLinkAction;
  text: Scalars["String"]["output"];
};

export type CommunicationCenterMessageAlignment = "FULL" | "LEFT" | "RIGHT";

export type CommunicationCenterMessageMutationResponse = {
  __typename?: "CommunicationCenterMessageMutationResponse";
  clickAnalytics: ClientSideAnalytics;
  messageId: Scalars["String"]["output"];
  operationStatus: CommunicationCenterOperationStatus;
};

export type CommunicationCenterMessagePreviewCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterMessagePreviewCard";
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"]["output"];
  image: Image;
  label?: Maybe<EgdsStylizedText>;
  state: CommunicationCenterMessagePreviewState;
  timeStamp?: Maybe<EgdsStylizedText>;
  title?: Maybe<EgdsStylizedText>;
};

export type CommunicationCenterMessagePreviewState = "READ" | "UNREAD";

export type CommunicationCenterMessagePreviews = {
  __typename?: "CommunicationCenterMessagePreviews";
  banner?: Maybe<CommunicationCenterBanner>;
  cards?: Maybe<Array<CommunicationCenterMessagePreviewCard>>;
  defaultMessageId?: Maybe<Scalars["String"]["output"]>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  pagination?: Maybe<PaginationDetails>;
};

export type CommunicationCenterMessageProductDetail = {
  __typename?: "CommunicationCenterMessageProductDetail";
  banner?: Maybe<CommunicationCenterBanner>;
  emptyState?: Maybe<CommunicationCenterEmptyState>;
  entityName?: Maybe<EgdsStylizedText>;
  entityOwner?: Maybe<EgdsSpannableText>;
  heading?: Maybe<EgdsStylizedText>;
  messageId: Scalars["String"]["output"];
  navigationIcon?: Maybe<Icon>;
  status?: Maybe<EgdsStandardBadge>;
  viewDetailButton?: Maybe<CommunicationCenterButton>;
};

export type CommunicationCenterMutationResponse = {
  __typename?: "CommunicationCenterMutationResponse";
  messageId: Scalars["String"]["output"];
  updatedState: CommunicationCenterMessagePreviewState;
};

export type CommunicationCenterNotificationCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterNotificationCard";
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"]["output"];
  icon: Icon;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  links?: Maybe<Array<EgdsStandardLink>>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  state: CommunicationCenterNotificationState;
  timeStamp?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  type: CommunicationCenterNotificationType;
  userActions: Array<CommunicationCenterNotificationUserAction>;
};

export type CommunicationCenterNotificationForm = {
  __typename?: "CommunicationCenterNotificationForm";
  feedbackMessages?: Maybe<Array<CommunicationCenterFormMessage>>;
  sections: Array<CommunicationCenterFormSection>;
  title: EgdsStylizedText;
};

export type CommunicationCenterNotificationMessageDisplayType = "FORM" | "PAGE";

export type CommunicationCenterNotificationMessageLevel = "ERROR" | "INFO" | "SUCCESS" | "WARNING";

export type CommunicationCenterNotificationMutationResponse = {
  __typename?: "CommunicationCenterNotificationMutationResponse";
  notificationId: Scalars["String"]["output"];
  operationStatus: CommunicationCenterOperationStatus;
};

export type CommunicationCenterNotificationState = "READ" | "SEEN" | "UNKNOWN" | "UNSEEN";

export type CommunicationCenterNotificationType = "COUPON_CAMPAIGN" | "DEFAULT";

export type CommunicationCenterNotificationUserAction = {
  __typename?: "CommunicationCenterNotificationUserAction";
  icon?: Maybe<Icon>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  secondaryText?: Maybe<EgdsStylizedText>;
  text: EgdsStylizedText;
  type: CommunicationCenterNotificationUserActionType;
};

export type CommunicationCenterNotificationUserActionType = "DELETE" | "MARK_AS_READ" | "MARK_AS_UNREAD";

export type CommunicationCenterNotifications = {
  __typename?: "CommunicationCenterNotifications";
  actionCards?: Maybe<Array<CommunicationCenterActionCard>>;
  banner?: Maybe<CommunicationCenterBanner>;
  clickToActionButton?: Maybe<CommunicationCenterButton>;
  deleteActionForm?: Maybe<CommunicationCenterNotificationForm>;
  emptyState: CommunicationCenterEmptyState;
  genericNotifications?: Maybe<Array<CommunicationCenterCard>>;
  heading: EgdsStylizedText;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  notifications?: Maybe<Array<CommunicationCenterNotificationCard>>;
  /** @deprecated replace with empty state actions */
  redirectPath?: Maybe<Scalars["String"]["output"]>;
  revealActions?: Maybe<Array<ClientSideAnalytics>>;
  /** @deprecated replace with clickToActionButton */
  showAllButton?: Maybe<EgdsButton>;
  unseen: Scalars["Int"]["output"];
};

export type CommunicationCenterOperationStatus = "GENERIC_FAILURE" | "INVALID_INPUT" | "SERVICE_ERROR" | "SUCCESS";

export type CommunicationCenterPreview = {
  __typename?: "CommunicationCenterPreview";
  conversations?: Maybe<CommunicationCenterConversations>;
  label?: Maybe<EgdsStylizedText>;
  messages?: Maybe<CommunicationCenterMessagePreviews>;
  notifications?: Maybe<CommunicationCenterNotifications>;
  /** @deprecated replace with empty state CTA */
  redirectPath?: Maybe<Scalars["String"]["output"]>;
  tabLabels?: Maybe<Array<CommunicationCenterTab>>;
};

export type CommunicationCenterQuery = {
  __typename?: "CommunicationCenterQuery";
  communicationCenterAttachmentView: CommunicationCenterAttachmentView;
  communicationCenterConversationById: CommunicationCenterConversation;
  communicationCenterConversationView: CommunicationCenterConversationView;
  communicationCenterConversations: CommunicationCenterConversations;
  communicationCenterCreateOrResumeConversation: CommunicationCenterConversation;
  communicationCenterMessagePreviews: CommunicationCenterMessagePreviews;
  communicationCenterMessageProductDetail: CommunicationCenterMessageProductDetail;
  communicationCenterNotifications: CommunicationCenterNotifications;
  /** @deprecated This does not doing anything and should not be used, please delete all uses, replace with CommunicationCenterVrboJwt("") */
  communicationCenterVrboJwt: CommunicationCenterVrboJwt;
};

export type CommunicationCenterQueryCommunicationCenterAttachmentViewArgs = {
  attachmentId: Scalars["String"]["input"];
  conversationId: Scalars["String"]["input"];
  messageId: Scalars["String"]["input"];
};

export type CommunicationCenterQueryCommunicationCenterConversationByIdArgs = {
  conversationId: Scalars["String"]["input"];
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type CommunicationCenterQueryCommunicationCenterConversationViewArgs = {
  conversationId: Scalars["String"]["input"];
};

export type CommunicationCenterQueryCommunicationCenterConversationsArgs = {
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type CommunicationCenterQueryCommunicationCenterCreateOrResumeConversationArgs = {
  itineraryNumber: Scalars["String"]["input"];
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type CommunicationCenterQueryCommunicationCenterMessagePreviewsArgs = {
  pagination: PaginationDetailsInput;
};

export type CommunicationCenterQueryCommunicationCenterMessageProductDetailArgs = {
  messageId: Scalars["String"]["input"];
};

export type CommunicationCenterQueryCommunicationCenterNotificationsArgs = {
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type CommunicationCenterTab = {
  __typename?: "CommunicationCenterTab";
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  label: EgdsStylizedText;
  status: TabStatus;
  unseen: Scalars["Int"]["output"];
};

export type CommunicationCenterTripCollaborationConversationCard = CommunicationCenterCard & {
  __typename?: "CommunicationCenterTripCollaborationConversationCard";
  author?: Maybe<Scalars["String"]["output"]>;
  authorChannel?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Array<EgdsStylizedText>>;
  cardId: Scalars["String"]["output"];
  clickAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  conversationType?: Maybe<CommunicationCenterConversationType>;
  defaultPreviewMessage?: Maybe<EgdsStylizedText>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  participantId?: Maybe<Scalars["String"]["output"]>;
  sentDateTime?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<CommunicationCenterConversationState>;
  timeStamp?: Maybe<EgdsStylizedText>;
  title?: Maybe<EgdsStylizedText>;
  tripDetail?: Maybe<CommunicationCenterConversationTrip>;
};

export type CommunicationCenterVrboJwt = {
  __typename?: "CommunicationCenterVrboJwt";
  jwtToken?: Maybe<Scalars["String"]["output"]>;
};

export type CommunicationChannel = "EMAIL" | "PUSH" | "SMS";

export type CommunicationPreferencesAction = UniversalProfileAction & {
  __typename?: "CommunicationPreferencesAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"]["output"];
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type CommunicationPreferencesBrandComponent = {
  __typename?: "CommunicationPreferencesBrandComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  bookings?: Maybe<CommunicationPreferencesOptionsComponent>;
  commPrefsPrivacyStatement?: Maybe<UniversalProfileStandardLink>;
  manageEmailPreferences?: Maybe<UniversalProfileStandardLink>;
  pushNotification?: Maybe<CommunicationPreferencesPushNotificationComponent>;
  shoppings?: Maybe<CommunicationPreferencesOptionsComponent>;
  title: Scalars["String"]["output"];
};

export type CommunicationPreferencesCategory = "EXPEDIA" | "HOTELS" | "ONE_KEY" | "VRBO";

export type CommunicationPreferencesChannelSelectionForm = {
  __typename?: "CommunicationPreferencesChannelSelectionForm";
  channelOptions: Array<UniversalProfileCommunicationPreferencesCheckBoxComponent>;
  contentHeading?: Maybe<UniversalProfileContentHeading>;
  heading: UniversalProfileHeading;
};

export type CommunicationPreferencesForm = {
  __typename?: "CommunicationPreferencesForm";
  categoryChannels: Array<CommunicationPreferencesChannelSelectionForm>;
  formSubmitConfirmationDialog?: Maybe<UniversalProfileFormSubmitConfirmationDialog>;
  subExperienceType?: Maybe<UniversalProfileSubExperienceType>;
  title: Scalars["String"]["output"];
  updateButton: UniversalProfileButton;
};

export type CommunicationPreferencesMainComponent = {
  __typename?: "CommunicationPreferencesMainComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  communicationBrandPreferences?: Maybe<CommunicationPreferencesOptionsComponent>;
  sharedGlobalPreferences?: Maybe<CommunicationPreferencesOptionsComponent>;
  title: Scalars["String"]["output"];
};

export type CommunicationPreferencesMessagingCard = {
  __typename?: "CommunicationPreferencesMessagingCard";
  action?: Maybe<CommunicationPreferencesAction>;
  card: EgdsStandardMessagingCard;
  commPrefsBrandExperienceType?: Maybe<UniversalProfileSubExperienceType>;
  graphicIllustration?: Maybe<Illustration>;
  type?: Maybe<CommunicationPreferencesOptionsType>;
};

export type CommunicationPreferencesOptionsComponent = {
  __typename?: "CommunicationPreferencesOptionsComponent";
  heading?: Maybe<UniversalProfileHeading>;
  options: Array<CommunicationPreferencesMessagingCard>;
};

export type CommunicationPreferencesOptionsType =
  | "ACCOUNT_SUPPORT"
  | "COMMUNICATION_PREFERENCES_EXPEDIA"
  | "COMMUNICATION_PREFERENCES_HOTELS"
  | "COMMUNICATION_PREFERENCES_VRBO"
  | "COMMUNICATION_PREFERENCES_WOTIF"
  | "CONFIRMATION_AND_CRITICAL_UPDATES"
  | "DEFAULT"
  | "MANAGE_EMAIL_PREFERENCE"
  | "OFFERS_AND_INSPIRATION"
  | "ONE_KEY"
  | "ONE_KEY_MONTHLY_STATEMENTS"
  | "PARTNERS_OFFERS_WITH_US"
  | "PERSONALIZED_TRIP_RECOMMENDATIONS"
  | "PRIVACY_STATEMENT"
  | "REVIEWS"
  | "SURVEYS"
  | "THE_TRAVEL_EDIT"
  | "TRIP_BOARD_ACTIVITY"
  | "TRIP_PLANNING_ASSISTANCE"
  | "UNSUB_ALL"
  | "UNSUB_OTHER_EMAILS";

export type CommunicationPreferencesPushNotificationComponent = {
  __typename?: "CommunicationPreferencesPushNotificationComponent";
  heading: UniversalProfileHeading;
};

export type ComparableDealsErrorContent = {
  __typename?: "ComparableDealsErrorContent";
  analytics: CarAnalytics;
  text: Scalars["String"]["output"];
};

export type ComparableDealsResults = {
  __typename?: "ComparableDealsResults";
  carAppliedSortAndFilters: CarAppliedSortAndFilters;
  comparableDealsDisplayAnalytics?: Maybe<CarAnalytics>;
  comparableDealsHeading: Scalars["String"]["output"];
  criteriaLevel: Scalars["Int"]["output"];
  listings: Array<CarListingCard>;
  offerCountByCriteriaLevel: Scalars["String"]["output"];
  offerSize: Scalars["Int"]["output"];
};

export type ComparableDealsResultsWrapper = {
  __typename?: "ComparableDealsResultsWrapper";
  comparableDealsErrorContent?: Maybe<ComparableDealsErrorContent>;
  comparableDealsResults?: Maybe<ComparableDealsResults>;
};

export type Component = EgdsStandardMessagingCard | UiBanner;

export type ComponentConfig = {
  __typename?: "ComponentConfig";
  exposures?: Maybe<Array<ExperimentType>>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  segments?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ComponentMetaData = {
  __typename?: "ComponentMetaData";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  publisher?: Maybe<Scalars["String"]["output"]>;
};

export type CompositeLocalizedTextModel = {
  __typename?: "CompositeLocalizedTextModel";
  key: Scalars["String"]["output"];
  /** @deprecated Use models instead */
  localizedFragment: EgdsLocalizedText;
  models: EgdsTemplateModels;
};

export type CompressionLevel = "CAP" | "HIGH" | "LOW" | "MEDIUM" | "NA";

export type ConfigTag = "H1" | "H2" | "H3" | "H4" | "H5" | "H6";

export type ConfirmationDialog = {
  __typename?: "ConfirmationDialog";
  ariaLabelledText?: Maybe<AriaLabelledText>;
  footer?: Maybe<EgdsDialogFooter>;
  /** @deprecated Use ariaLabelledText instead. */
  message?: Maybe<EgdsText>;
};

export interface ConfirmationDialogInput {
  elementName?: InputMaybe<Scalars["String"]["input"]>;
  elementType: DialogElementType;
  numberOfElements?: InputMaybe<Scalars["Int"]["input"]>;
}

export type ConfirmationSummary = {
  __typename?: "ConfirmationSummary";
  analyticsEvent?: Maybe<EgClickstreamEvent>;
  assuranceHeading: Scalars["String"]["output"];
  confirmationSummarySections: Array<ConfirmationSummarySection>;
  continueInBrowserLink?: Maybe<EgdsStandardLink>;
  image: Image;
  redirectToTripButton: EgdsButton;
  secondaryCTALink?: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type ConfirmationSummarySection = {
  __typename?: "ConfirmationSummarySection";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  confirmationSummarySectionDescription: Scalars["String"]["output"];
  confirmationSummarySectionIdentifier?: Maybe<Scalars["String"]["output"]>;
  confirmationSummarySectionValue: Scalars["String"]["output"];
};

export type ConnectedService = {
  __typename?: "ConnectedService";
  tbd: Scalars["String"]["output"];
};

export type ConnectedServiceProvider = {
  __typename?: "ConnectedServiceProvider";
  connectedServiceCount: Scalars["String"]["output"];
  connectedServices?: Maybe<Array<ServiceDetail>>;
  manageServices: EgdsStandardLink;
  providerId?: Maybe<Scalars["String"]["output"]>;
  providerName: Scalars["String"]["output"];
};

export type ConnectivityProvider = {
  __typename?: "ConnectivityProvider";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type ConnectivitySettingsButton = {
  __typename?: "ConnectivitySettingsButton";
  button?: Maybe<EgdsButton>;
  buttonType?: Maybe<ConnectivitySettingsButtonType>;
};

export type ConnectivitySettingsButtonType =
  | "BACK"
  | "CANCEL"
  | "CHANGE"
  | "CONFIRM"
  | "CONNECT"
  | "DISCONNECT"
  | "NEXT";

export type ConnectivitySettingsConnectedServiceProviders = {
  __typename?: "ConnectivitySettingsConnectedServiceProviders";
  addButton?: Maybe<EgdsButton>;
  connectSuccessMessage?: Maybe<EgdsToast>;
  connectedServiceProvidersTabs: ConnectivitySettingsTabs;
  disconnectSuccessMessage?: Maybe<EgdsToast>;
  onboardingButton?: Maybe<EgdsButton>;
  roomAndRatesPlanMapping?: Maybe<EgdsStandardLink>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ConnectivitySettingsGettingStarted = {
  __typename?: "ConnectivitySettingsGettingStarted";
  addButton?: Maybe<EgdsButton>;
  onboardingButton?: Maybe<EgdsButton>;
  roomAndRatesPlanMapping?: Maybe<EgdsStandardLink>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ConnectivitySettingsHeader = {
  __typename?: "ConnectivitySettingsHeader";
  feedback?: Maybe<EgdsStandardLink>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ConnectivitySettingsMain =
  | ConnectivitySettingsConnectedServiceProviders
  | ConnectivitySettingsGettingStarted;

export type ConnectivitySettingsStep = {
  __typename?: "ConnectivitySettingsStep";
  label: Scalars["String"]["output"];
};

export type ConnectivitySettingsStepIndicator = {
  __typename?: "ConnectivitySettingsStepIndicator";
  activeStep: Scalars["Int"]["output"];
  steps: Array<ConnectivitySettingsStep>;
};

export type ConnectivitySettingsTabName = "BY_PROVIDER" | "BY_SERVICE";

export type ConnectivitySettingsTabs = {
  __typename?: "ConnectivitySettingsTabs";
  errorMessages?: Maybe<Array<SupplyConnectivitySettingsErrorInfo>>;
  expandAll: Scalars["String"]["output"];
  selectedTabId: ConnectivitySettingsTabName;
  selectedTabText: Scalars["String"]["output"];
  tabs: Array<TabContent>;
};

export type ConnectivitySettingsTypeahead = {
  __typename?: "ConnectivitySettingsTypeahead";
  adapterConfig: SettingProviderTypeaheadEssAdapterConfig;
  label: Scalars["String"]["output"];
  placeholder?: Maybe<Scalars["String"]["output"]>;
};

export type ConsentMgmt = {
  __typename?: "ConsentMgmt";
  card: ConsentMgmtCard;
};

export type ConsentMgmtCard = {
  __typename?: "ConsentMgmtCard";
  button: UiPrimaryButton;
  editorial?: Maybe<EgdsTextSection>;
};

export type ContactHostAction = InquiryFormDialogAction | UiLinkAction;

export type ContactHostButton = {
  __typename?: "ContactHostButton";
  action?: Maybe<ContactHostAction>;
  button?: Maybe<ContactHostButtonType>;
};

export type ContactHostButtonType = UiSecondaryButton | UiTertiaryButton;

export type ContactHostSection = {
  __typename?: "ContactHostSection";
  cta?: Maybe<ContactHostButton>;
  header?: Maybe<Scalars["String"]["output"]>;
};

export type ContactOptionsSheet = {
  __typename?: "ContactOptionsSheet";
  heading: Scalars["String"]["output"];
  options: Array<ContactUsOption>;
  subheading: Scalars["String"]["output"];
  toolBar: ContactUsToolBar;
};

export type ContactUsEmailFormView = ContactUsEmailSheet | ContactUsErrorView;

export type ContactUsEmailSheet = {
  __typename?: "ContactUsEmailSheet";
  back: Scalars["String"]["output"];
  customerEmail: EgdsEmailInputField;
  customerName: EgdsTextInputField;
  details: EgdsTextAreaInputField;
  heading: Scalars["String"]["output"];
  send: UiPrimaryButton;
  subheading: Scalars["String"]["output"];
  toolBar: ContactUsToolBar;
};

export type ContactUsErrorView = {
  __typename?: "ContactUsErrorView";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  errorTitle?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  icon: Icon;
  subheading?: Maybe<Scalars["String"]["output"]>;
  toolBar: ContactUsToolBar;
};

export interface ContactUsFormContentInput {
  category: Scalars["String"]["input"];
  intent: Scalars["String"]["input"];
  itineraryNumber: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
}

export type ContactUsOption = {
  __typename?: "ContactUsOption";
  leftIcon: Icon;
  option: ContactUsOptionType;
  optionLabel: Scalars["String"]["output"];
  rightIcon: Icon;
  waitTime?: Maybe<Scalars["String"]["output"]>;
};

export type ContactUsOptionType =
  | "CALL_BACK_WITH_AGENT"
  | "CALL_TO_AGENT"
  | "CHAT_BACK_WITH_AGENT"
  | "CHAT_WITH_AGENT"
  | "SEND_EMAIL_AGENT";

export type ContactUsOptionsView = ContactOptionsSheet | ContactUsErrorView;

export type ContactUsPreFill = {
  __typename?: "ContactUsPreFill";
  category?: Maybe<Scalars["String"]["output"]>;
  product?: Maybe<Scalars["String"]["output"]>;
};

export type ContactUsSendEmailFailed = {
  __typename?: "ContactUsSendEmailFailed";
  button: UiPrimaryButton;
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface ContactUsSendEmailFormRequestInput {
  customerEmail: Scalars["String"]["input"];
  customerName: Scalars["String"]["input"];
  details: Scalars["String"]["input"];
  intent: Scalars["String"]["input"];
  product: Scalars["String"]["input"];
}

export type ContactUsSendEmailFormView = ContactUsSendEmailFailed | ContactUsSendEmailSuccess;

export interface ContactUsSendEmailRequestInput {
  customerEmail: Scalars["String"]["input"];
  customerName: Scalars["String"]["input"];
  message: Scalars["String"]["input"];
  subject: Scalars["String"]["input"];
}

export type ContactUsSendEmailSuccess = {
  __typename?: "ContactUsSendEmailSuccess";
  button: UiPrimaryButton;
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ContactUsSendEmailView = ContactUsSendEmailFailed | ContactUsSendEmailSuccess;

export type ContactUsSheet = {
  __typename?: "ContactUsSheet";
  customerFormContent: ContactUsSheetContent;
  heading: Scalars["String"]["output"];
  seeContactUsOptions: UiPrimaryButton;
  subheading: Scalars["String"]["output"];
  toolBar: ContactUsToolBar;
};

export type ContactUsSheetContent = {
  __typename?: "ContactUsSheetContent";
  categorySelect: EgdsBasicSelect;
  intentSelect: EgdsBasicSelect;
  itineraryNumber: EgdsTextInputField;
  phoneNumber: EgdsTextInputField;
};

export type ContactUsSheetView = ContactUsErrorView | ContactUsSheet;

export type ContactUsToolBar = {
  __typename?: "ContactUsToolBar";
  icon: Icon;
  navIconLabel?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ContactUsView = {
  __typename?: "ContactUsView";
  contactUsEmailFormView: ContactUsEmailFormView;
  contactUsOptionsView: ContactUsOptionsView;
  contactUsSheetView: ContactUsSheetView;
  contactUsSheetViewWithPayload: ContactUsSheetView;
  emailUsFormView: EmailUsFormView;
  speakNextAvailableAgentSheetView: SpeakNextAvailableAgentSheetView;
  speakNextAvailableAgentView: SpeakNextAvailableAgentView;
};

export type ContactUsViewContactUsEmailFormViewArgs = {
  optionType: ContactUsOptionType;
};

export type ContactUsViewContactUsOptionsViewArgs = {
  searchFormInput: ContactUsFormContentInput;
};

export type ContactUsViewContactUsSheetViewWithPayloadArgs = {
  articleIntent?: InputMaybe<Scalars["String"]["input"]>;
  articleProduct?: InputMaybe<Scalars["String"]["input"]>;
};

export type ContactUsViewEmailUsFormViewArgs = {
  optionType: ContactUsOptionType;
};

export type ContactUsViewSpeakNextAvailableAgentSheetViewArgs = {
  speakNextAvailableSheetRequest: SpeakNextAvailableAgentSheetRequestInput;
};

export type ContactUsViewSpeakNextAvailableAgentViewArgs = {
  optionType: ContactUsOptionType;
};

export type ContactUsViewMutation = {
  __typename?: "ContactUsViewMutation";
  sendContactUsEmail: ContactUsSendEmailFormView;
  sendEmail: ContactUsSendEmailView;
};

export type ContactUsViewMutationSendContactUsEmailArgs = {
  sendEmailRequest: ContactUsSendEmailFormRequestInput;
};

export type ContactUsViewMutationSendEmailArgs = {
  sendEmailRequest: ContactUsSendEmailRequestInput;
};

export type ContainerTheme = "DEFAULT" | "IMPORTANT";

export interface ContentActionPanelInput {
  coachingParameters?: InputMaybe<SupplyCoachingParameters>;
  type: Scalars["String"]["input"];
}

export interface ContentInput {
  ad_type: Scalars["String"]["input"];
  creativeFSL?: InputMaybe<FslCreativeInput>;
  creativeLSL?: InputMaybe<LslCreativeInput>;
  locale: Scalars["String"]["input"];
}

export interface ContentLikeInteractionRequestInput {
  action: LikeUnlikeSocialInteractionAction;
  postId: Scalars["String"]["input"];
  postType: SocialInteractionPostType;
  postUrl: Scalars["String"]["input"];
}

export type ContentLikeInteractionResponse = {
  __typename?: "ContentLikeInteractionResponse";
  isSuccess: Scalars["Boolean"]["output"];
};

export interface ContentPaginationInput {
  pageNumber: Scalars["Int"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface ContentScoreInput {
  cardType?: InputMaybe<Scalars["String"]["input"]>;
  previousScore?: InputMaybe<Scalars["String"]["input"]>;
  skipForNow?: InputMaybe<Scalars["String"]["input"]>;
  source: Scalars["String"]["input"];
}

export type ContentSectionDetails = {
  __typename?: "ContentSectionDetails";
  accessibilitySection: PropertyContentSectionGroup;
};

export interface ContentShareInteractionRequestInput {
  postId: Scalars["String"]["input"];
}

export type ContentShareInteractionResponse = {
  __typename?: "ContentShareInteractionResponse";
  isSuccess: Scalars["Boolean"]["output"];
};

export type ContentSource = "DESTINATION_API" | "EM";

export type Context = {
  __typename?: "Context";
  /** @deprecated Rely on HTTP headers for client identification, replace with Format: `Client-Info=<name>,<version>,<environment>` */
  clientInfo?: Maybe<ClientInfo>;
  currency?: Maybe<Scalars["String"]["output"]>;
  debugContext?: Maybe<DebugContext>;
  device: Device;
  eapid?: Maybe<Scalars["Int"]["output"]>;
  identity: Identity;
  locale: Scalars["Locale"]["output"];
  privacyTrackingState?: Maybe<PrivacyTrackingState>;
  siteId: Scalars["Int"]["output"];
  tpid?: Maybe<Scalars["Int"]["output"]>;
};

export interface ContextInput {
  clientInfo?: InputMaybe<ClientInfoInput>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
  debugContext?: InputMaybe<DebugContextInput>;
  device: DeviceInput;
  eapid?: InputMaybe<Scalars["Int"]["input"]>;
  identity?: InputMaybe<IdentityInput>;
  locale: Scalars["Locale"]["input"];
  privacyTrackingState?: InputMaybe<PrivacyTrackingState>;
  siteId: Scalars["Int"]["input"];
  tpid?: InputMaybe<Scalars["Int"]["input"]>;
}

export type ContextualInfo = {
  __typename?: "ContextualInfo";
  applePayJsVersion?: Maybe<Scalars["Int"]["output"]>;
  authenticationSpec?: Maybe<AuthenticationSpec>;
  binSpec?: Maybe<BinSpec>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  etzToken?: Maybe<Scalars["String"]["output"]>;
  providerConfirmationStatus?: Maybe<Scalars["String"]["output"]>;
  publicApiKey?: Maybe<Scalars["String"]["output"]>;
  script?: Maybe<Scalars["String"]["output"]>;
  storageDisabled: Scalars["Boolean"]["output"];
};

export interface ContextualNotification {
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  revealActions?: Maybe<Array<NotificationAction>>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
}

export interface ConversationAttributesInput {
  currentPageUrl?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<ConversationType>;
}

export interface ConversationAuthenticationRequestInput {
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  travelerParticipantId?: InputMaybe<Scalars["String"]["input"]>;
}

export type ConversationAuthenticationResponse = {
  __typename?: "ConversationAuthenticationResponse";
  conversationAuthenticationHeading: EgdsText;
  conversationAuthenticationText: EgdsText;
  success: Scalars["Boolean"]["output"];
};

export type ConversationAuthenticationView = {
  __typename?: "ConversationAuthenticationView";
  updateUserStateAndSendMessageView: ConversationAuthenticationResponse;
};

export type ConversationAuthenticationViewUpdateUserStateAndSendMessageViewArgs = {
  conversationAuthenticationRequest: ConversationAuthenticationRequestInput;
};

export interface ConversationContextInput {
  attributes?: InputMaybe<ConversationAttributesInput>;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  conversationType?: InputMaybe<ConversationType>;
  participantId?: InputMaybe<Scalars["String"]["input"]>;
  sentAfterOffset?: InputMaybe<Scalars["Int"]["input"]>;
}

export type ConversationIdentifiers = {
  __typename?: "ConversationIdentifiers";
  conversationId: Scalars["String"]["output"];
  participantId: Scalars["String"]["output"];
};

export interface ConversationInitializationConfigsInput {
  attributes?: InputMaybe<ConversationAttributesInput>;
  conversation?: InputMaybe<ConversationInput>;
  conversationAttributes?: InputMaybe<Array<VirtualAgentControlAttributeInput>>;
  conversationType?: InputMaybe<ConversationType>;
  forceNewConversation?: InputMaybe<Scalars["Boolean"]["input"]>;
  mode: ConversationMode;
  participant?: InputMaybe<ParticipantInput>;
}

export interface ConversationInput {
  conversationId: Scalars["String"]["input"];
}

export type ConversationMetadata = {
  __typename?: "ConversationMetadata";
  defaultMessageDeliveryText: Scalars["String"]["output"];
  failedMessageText: EgdsGraphicText;
  loadingText: Scalars["String"]["output"];
  retry: CommunicationCenterButton;
};

export type ConversationMode = "CREATE_CONVERSATION" | "JOIN_CONVERSATION" | "RESUME_CONVERSATION";

export type ConversationParticipant = {
  __typename?: "ConversationParticipant";
  cpUserId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  participantType: ConversationParticipantType;
  user?: Maybe<ConversationUser>;
};

export type ConversationParticipantType =
  | "HumanAgent"
  | "HumanAgentVA"
  | "Supplier"
  | "SupplierVA"
  | "Traveler"
  | "TravelerVA";

export type ConversationProperties = {
  __typename?: "ConversationProperties";
  launchPoint: Scalars["String"]["output"];
  pageName?: Maybe<Scalars["String"]["output"]>;
  skipWelcome?: Maybe<Scalars["String"]["output"]>;
  testVaId?: Maybe<Scalars["String"]["output"]>;
  traceId?: Maybe<Scalars["UUID"]["output"]>;
};

export type ConversationType =
  | "AI_CONCIERGE"
  | "CHATGPT"
  | "GOOGLE_ITINERARY"
  | "MAIN"
  | "PROPERTY_COMPARE"
  | "SUPPLIER"
  | "TRIP_COLLABORATION";

export type ConversationUser = {
  __typename?: "ConversationUser";
  attributes?: Maybe<ConversationUserAttributes>;
  avatarUrl?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  preferredName?: Maybe<Scalars["String"]["output"]>;
};

export type ConversationUserAttributes = {
  __typename?: "ConversationUserAttributes";
  displayNameOverride?: Maybe<Scalars["String"]["output"]>;
};

export type ConversationViewFileUpload = {
  __typename?: "ConversationViewFileUpload";
  fileTypeAllowed: Array<Scalars["String"]["output"]>;
  maxUploads: Scalars["Int"]["output"];
  uploadButton: UiSecondaryButton;
  validations?: Maybe<Array<ConversationViewFileUploadValidation>>;
};

export type ConversationViewFileUploadValidation = {
  __typename?: "ConversationViewFileUploadValidation";
  errorBody?: Maybe<Scalars["String"]["output"]>;
  errorHeader: Scalars["String"]["output"];
  maxSize?: Maybe<Scalars["Int"]["output"]>;
  type: Scalars["String"]["output"];
};

export type CoordinateInformation = {
  __typename?: "CoordinateInformation";
  flightDetailsWarningInfo?: Maybe<FlightsIconAndLabel>;
  icon: Icon;
  isLayoverAirport: Scalars["Boolean"]["output"];
  journeyDateTime: Array<EgdsStylizedText>;
  /** @deprecated This will be replaced by flightDetailsWarningInfo */
  overnightMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This will be replaced by flightDetailsWarningInfo */
  overnightMessageInfo?: Maybe<FlightsIconAndLabel>;
  subtitle: Scalars["String"]["output"];
  terminal?: Maybe<EgdsPlainText>;
  /** @deprecated Replaced by titleAndAccessibilityMessage */
  title: Scalars["String"]["output"];
  titleAndAccessibilityMessage: FlightsMessageAndAccessibility;
};

export type Coordinates = {
  __typename?: "Coordinates";
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

export interface CoordinatesInput {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
}

export type CopyToClipboardAction = TripsAction & {
  __typename?: "CopyToClipboardAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  toast?: Maybe<TripsInfoToast>;
  value: Scalars["String"]["output"];
};

export type CostPercentiles = {
  __typename?: "CostPercentiles";
  eightieth: Money;
  fiftieth: Money;
  ninetieth: Money;
  tenth: Money;
  twentieth: Money;
};

export type CostPercentilesByMonth = {
  __typename?: "CostPercentilesByMonth";
  month: Month;
  percentiles: CostPercentiles;
};

export type CostPercentilesByWeek = {
  __typename?: "CostPercentilesByWeek";
  percentiles: CostPercentiles;
  week: Scalars["Int"]["output"];
};

export type Coupon = {
  __typename?: "Coupon";
  code?: Maybe<Scalars["String"]["output"]>;
  instanceId?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type CouponAndCreditAction = UiAction & {
  __typename?: "CouponAndCreditAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  useInstructions?: Maybe<CouponAndCreditUseAction>;
};

export type CouponAndCreditCard = {
  __typename?: "CouponAndCreditCard";
  actions: Array<Maybe<UiAction>>;
  airlineIcon?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  banner?: Maybe<UiBanner>;
  cardContents: Array<Maybe<CouponsAndCreditsCardContent>>;
  heading: Scalars["String"]["output"];
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type CouponAndCreditChatbotAction = UiAction & {
  __typename?: "CouponAndCreditChatbotAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  intentMessage: ChatbotConversationIntentMessage;
  resource: Uri;
};

export type CouponAndCreditUseAction = {
  __typename?: "CouponAndCreditUseAction";
  actions: Array<UiAction>;
  heading?: Maybe<Scalars["String"]["output"]>;
  messages: Array<CouponAndCreditUseInstructionsMessage>;
};

export type CouponAndCreditUseInstructionsMessage = {
  __typename?: "CouponAndCreditUseInstructionsMessage";
  elements: Array<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type CouponCampaign = {
  __typename?: "CouponCampaign";
  couponPublicCode?: Maybe<Scalars["String"]["output"]>;
  endDate: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  startDate: Scalars["String"]["output"];
  status: CouponCampaignStatus;
  statusMessage: Scalars["String"]["output"];
  subTitle: Scalars["String"]["output"];
  terms: Scalars["String"]["output"];
  termsLink: EgdsStandardLink;
  title: Scalars["String"]["output"];
};

export type CouponCampaignStatus = "ACTIVE" | "EXPIRED" | "INACTIVE" | "NOT_STARTED";

export type CouponDetail = {
  __typename?: "CouponDetail";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  statusMessage: Scalars["String"]["output"];
  termsAndConditions: CouponTermsAndConditions;
};

export type CouponDetailsContainer = {
  __typename?: "CouponDetailsContainer";
  content: Array<CouponsText>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: SectionType;
};

export type CouponDomainInfo = {
  __typename?: "CouponDomainInfo";
  name: Scalars["String"]["output"];
  refId: Scalars["String"]["output"];
};

export type CouponEntryTab = EgdsTab & {
  __typename?: "CouponEntryTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
};

export type CouponErrorBanner = {
  __typename?: "CouponErrorBanner";
  message: Scalars["String"]["output"];
};

export interface CouponIdentifierInput {
  type: CouponIdentifierType;
  value: Scalars["String"]["input"];
}

export type CouponIdentifierType = "CODE" | "INSTANCE_ID";

export type CouponRedemption = {
  __typename?: "CouponRedemption";
  appliedCoupon?: Maybe<AppliedCouponDetail>;
  callToAction?: Maybe<EgdsStandardMessagingCard>;
  dialog?: Maybe<CouponRedemptionView>;
  fallBack: Array<FallBack>;
  isModuleApplicable: Scalars["Boolean"]["output"];
  /** @deprecated Use uiSignals instead Ref to RFC-590 */
  signals: Array<CouponSignal>;
  success: Scalars["Boolean"]["output"];
  toastMessage?: Maybe<Scalars["String"]["output"]>;
  uiSignals: Array<UiSignalV2>;
};

export type CouponRedemptionFromAccount = {
  __typename?: "CouponRedemptionFromAccount";
  /** @deprecated This function is being deprecated, please use applyV2 instead, replace with applyV2 */
  apply: EgdsButton;
  applyV2: UiPrimaryButton;
  coupons: Array<Coupon>;
  noCouponSelectedMessage: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type CouponRedemptionView = {
  __typename?: "CouponRedemptionView";
  accountCoupons?: Maybe<CouponRedemptionFromAccount>;
  codeEntry: CouponRedemptionWithCode;
  couponEntryTypeSelection: EgdsBasicTabs;
  toolbar: EgdsDialogToolbar;
};

export type CouponRedemptionWithCode = {
  __typename?: "CouponRedemptionWithCode";
  /** @deprecated This function is being deprecated, please use applyV2 instead, replace with applyV2 */
  apply: EgdsButton;
  applyV2: UiPrimaryButton;
  couponCodeInput: EgdsTextInputField;
  noCouponCodeEnteredMessage: Scalars["String"]["output"];
  signIn?: Maybe<EgdsStandardLink>;
  tabId: Scalars["String"]["output"];
};

export type CouponSignal = {
  __typename?: "CouponSignal";
  domainInfoList: Array<CouponDomainInfo>;
  id?: Maybe<Scalars["String"]["output"]>;
  signal?: Maybe<Scalars["String"]["output"]>;
  signalDetails: Array<CouponSignalDetails>;
  urn: Scalars["String"]["output"];
};

export type CouponSignalDetails = {
  __typename?: "CouponSignalDetails";
  detailedDescription: Scalars["String"]["output"];
  reasonUrn: Scalars["String"]["output"];
  summary: Scalars["String"]["output"];
};

export type CouponTermsAndConditions = {
  __typename?: "CouponTermsAndConditions";
  informationUrl: HttpUri;
  text: Scalars["String"]["output"];
};

export type CouponsAndCredits = {
  __typename?: "CouponsAndCredits";
  activeStatus?: Maybe<ActiveState>;
  additionalMessaging?: Maybe<Scalars["String"]["output"]>;
  banner?: Maybe<UiBanner>;
  elements: Array<CouponsAndCreditsSectionContainer>;
  navLink?: Maybe<UiLinkAction>;
  sourceAnalyticsTagging?: Maybe<ClientSideAnalytics>;
};

export type CouponsAndCreditsCardContent = {
  __typename?: "CouponsAndCreditsCardContent";
  link?: Maybe<UiLinkAction>;
  value: Scalars["String"]["output"];
};

export type CouponsAndCreditsEmptyState = {
  __typename?: "CouponsAndCreditsEmptyState";
  elements: Array<Scalars["String"]["output"]>;
};

export type CouponsAndCreditsExpando = {
  __typename?: "CouponsAndCreditsExpando";
  action: UiAction;
  elements: Array<CouponAndCreditCard>;
};

export type CouponsAndCreditsExplainedLink = {
  __typename?: "CouponsAndCreditsExplainedLink";
  footer?: Maybe<Scalars["String"]["output"]>;
  link: UiLinkAction;
};

export type CouponsAndCreditsGroup = {
  __typename?: "CouponsAndCreditsGroup";
  cards: Array<CouponAndCreditCard>;
  expando?: Maybe<CouponsAndCreditsExpando>;
  heading?: Maybe<Scalars["String"]["output"]>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type CouponsAndCreditsListing = CouponsAndCreditsEmptyState | CouponsAndCreditsGroup;

export type CouponsAndCreditsSectionContainer = {
  __typename?: "CouponsAndCreditsSectionContainer";
  elements: Array<CouponsAndCreditsListing>;
  heading: Scalars["String"]["output"];
  links: Array<Maybe<CouponsAndCreditsExplainedLink>>;
};

export type CouponsAndCreditsSimpleAction = UiAction & {
  __typename?: "CouponsAndCreditsSimpleAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type CouponsText = {
  __typename?: "CouponsText";
  combinedTexts: Array<UnionText>;
};

export type CreateConversationError = {
  __typename?: "CreateConversationError";
  banner?: Maybe<VirtualAgentControlErrorBanner>;
  fullPage?: Maybe<VirtualAgentControlErrorFullPageView>;
};

export type CreateConversationResult = CreateConversationError | CreateConversationSuccess;

export type CreateConversationSuccess = {
  __typename?: "CreateConversationSuccess";
  identifiers: ConversationIdentifiers;
};

export type CreativeCard = {
  __typename?: "CreativeCard";
  content: CreativeCardContent;
};

export type CreativeCardContent = FlightCreativeCard | LodgingCreativeCard;

export type CreativeDialog = {
  __typename?: "CreativeDialog";
  adPreview: AdPreview;
  dialogButton: UiSecondaryButton;
  dialogSubHeading?: Maybe<Scalars["String"]["output"]>;
  dialogTitle?: Maybe<Scalars["String"]["output"]>;
};

export type CreativeFormQuery = {
  __typename?: "CreativeFormQuery";
  approvalStatusUIToggle?: Maybe<EgdsStandardSwitch>;
  brandResultListingCreativeForm?: Maybe<BrandResultListingCreativeForm>;
};

export type CreativeFormQueryApprovalStatusUiToggleArgs = {
  creativeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreativeFormQueryBrandResultListingCreativeFormArgs = {
  creativeId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CreativePictureCard = {
  __typename?: "CreativePictureCard";
  cardContent: CreativeTextArea;
  cardSubHeading: Scalars["String"]["output"];
  cardTitle: Scalars["String"]["output"];
  imageAutoSelectionPrompt: ImageAutoSelectPrompt;
  imageSelectButton: EgdsOverlayButton;
  pictureCard: EgdsImageCard;
};

export type CreativeTextArea = {
  __typename?: "CreativeTextArea";
  description: EgdsInputField;
  descriptionInstruction: Scalars["String"]["output"];
  headline: EgdsInputField;
  headlineInstruction: Scalars["String"]["output"];
};

export type CreditCardAccountPlacement = {
  __typename?: "CreditCardAccountPlacement";
  bottomLabel?: Maybe<Scalars["String"]["output"]>;
  cardImage: Image;
  ctaAction: UiLinkAction;
  ctaIcon: Icon;
  heading: EgdsPlainText;
  impressionAnalytics: ClientSideAnalytics;
  subHeading: Scalars["String"]["output"];
};

export type CreditCardAccountPlacementSourcePage = "GLOBAL_NAV" | "REWARDS" | "UNIVERSAL_PROFILE";

export type CreditCardAccountPlacementVariant = "MEDIUM" | "SMALL";

export type CreditCardApplicationApprovedState = {
  __typename?: "CreditCardApplicationApprovedState";
  disclosures: CreditCardLinkGroup;
  disclosuresInfo: Scalars["String"]["output"];
  image: Image;
  info: Array<Scalars["String"]["output"]>;
  offerDetails: Array<CreditCardApplicationOfferDetail>;
  offerInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type CreditCardApplicationBanner = {
  __typename?: "CreditCardApplicationBanner";
  heading: Array<CreditCardSpannableText>;
  image: Image;
};

export type CreditCardApplicationBenefit = {
  __typename?: "CreditCardApplicationBenefit";
  applyButton: CreditCardApplyButton;
  backgroundImage: Image;
  benefitDetails: Array<CreditCardApplicationBenefitDetail>;
  /** @deprecated card container no longer needed */
  cardContainer: CreditCardApplicationBenefitCardContainer;
  cardFeeLink: EgdsInlineLink;
  cardImage: Image;
  cardType: CreditCardType;
  /** @deprecated use benefitDetails instead */
  details: Array<CreditCardApplicationBenefitDetail>;
  disclaimer?: Maybe<Array<EgdsSpannableText>>;
  links?: Maybe<Array<EgdsInlineLink>>;
  title: EgdsPlainText;
};

export type CreditCardApplicationBenefitCardContainer = {
  __typename?: "CreditCardApplicationBenefitCardContainer";
  cardInfo: Array<CreditCardApplicationBenefitCardInfo>;
  disclaimer?: Maybe<Array<EgdsSpannableText>>;
};

export type CreditCardApplicationBenefitCardInfo = {
  __typename?: "CreditCardApplicationBenefitCardInfo";
  anniversaryBonus?: Maybe<EgdsSpannableText>;
  applyButton: CreditCardApplyButton;
  benefitDetails?: Maybe<CreditCardApplicationBenefitDetail>;
  /** @deprecated use applyButton and links instead */
  buttons?: Maybe<Array<CreditCardApplyButton>>;
  cardFeeLink: EgdsInlineLink;
  cardImage: Image;
  cardType: CreditCardType;
  /** @deprecated use benefitDetails instead */
  description?: Maybe<EgdsStylizedText>;
  /** @deprecated use simpleHeading instead */
  heading: EgdsSpannableText;
  /** @deprecated use cardImage instead */
  image: Image;
  links?: Maybe<Array<EgdsInlineLink>>;
  simpleHeading: EgdsPlainText;
};

export type CreditCardApplicationBenefitDetail = {
  __typename?: "CreditCardApplicationBenefitDetail";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  benefitHeading: Array<CreditCardApplicationBenefitDetailHeading>;
  /** @deprecated use styledDescription instead */
  description: EgdsSpannableText;
  showMorePeek?: Maybe<CreditCardExpandoPeek>;
  styledDescription: CreditCardSpannableText;
  subHeading?: Maybe<EgdsSpannableText>;
};

export type CreditCardApplicationBenefitDetailHeading =
  | CreditCardApplicationSizedHeading
  | EgdsLoyaltyBadge
  | EgdsStandardBadge;

export type CreditCardApplicationBenefitHeader = {
  __typename?: "CreditCardApplicationBenefitHeader";
  /** @deprecated a theme is applied client-side */
  backgroundImage: Image;
  /** @deprecated use disclaimer instead */
  bonusDetails: Array<CreditCardBenefitHeaderDetails>;
  /** @deprecated use cardLink instead */
  cardDescription: Scalars["String"]["output"];
  cardLink: EgdsInlineLink;
  disclaimer?: Maybe<Array<EgdsSpannableText>>;
  earningDetails: Array<CreditCardBenefitHeaderDetails>;
  footer?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use disclaimer instead */
  footerDetails: CreditCardBenefitHeaderDetails;
  /** @deprecated legacy */
  heading: EgdsSpannableText;
  headingDetails: CreditCardBenefitContent;
  image: Image;
  links?: Maybe<Array<EgdsInlineLink>>;
};

export type CreditCardApplicationCompoundLoadingDialog = {
  __typename?: "CreditCardApplicationCompoundLoadingDialog";
  initialContent: CreditCardApplicationCompoundLoadingDialogContent;
  timeoutContent?: Maybe<CreditCardApplicationCompoundLoadingDialogContent>;
  timeoutSeconds?: Maybe<Scalars["Int"]["output"]>;
};

export type CreditCardApplicationCompoundLoadingDialogContent = {
  __typename?: "CreditCardApplicationCompoundLoadingDialogContent";
  heading: Scalars["String"]["output"];
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardApplicationContext = {
  __typename?: "CreditCardApplicationContext";
  applicationToken: Scalars["String"]["output"];
};

export type CreditCardApplicationDeclineAction = {
  __typename?: "CreditCardApplicationDeclineAction";
  actionType: CreditCardApplicationDeclineActionType;
};

export type CreditCardApplicationDeclineActionType = CreditCardApplicationDismissAction | UiLinkAction;

export type CreditCardApplicationDismissAction = {
  __typename?: "CreditCardApplicationDismissAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardApplicationEarlyExitDialog = {
  __typename?: "CreditCardApplicationEarlyExitDialog";
  buttons: Array<CreditCardCtaButton>;
  text?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type CreditCardApplicationForm = {
  __typename?: "CreditCardApplicationForm";
  consentAndCta: CreditCardConsentAndCta;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  header?: Maybe<CreditCardApplicationFormHeader>;
  identifiers: CreditCardApplicationFormIdentifiers;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  loadingDialog: CreditCardApplicationCompoundLoadingDialog;
  sections: Array<CreditCardApplicationFormSection>;
  subtitles?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardApplicationFormCobrandConfiguration = {
  __typename?: "CreditCardApplicationFormCobrandConfiguration";
  brandIdentifier: Scalars["String"]["output"];
  cardType: CreditCardType;
  landingUrl: Scalars["String"]["output"];
  merchantNumber: Scalars["String"]["output"];
  partnerMemberId?: Maybe<Scalars["String"]["output"]>;
  referrer: Scalars["String"]["output"];
  spendAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type CreditCardApplicationFormConfiguration = {
  __typename?: "CreditCardApplicationFormConfiguration";
  coBrand: CreditCardApplicationFormCobrandConfiguration;
  gatewayEntityId: Scalars["String"]["output"];
  merchantBackend: CreditCardApplicationFormMerchantBackendConfiguration;
  prefill?: Maybe<CreditCardApplicationFormPrefillData>;
  provider: CreditCardApplicationFormProviderConfiguration;
  ui: CreditCardApplicationFormUiConfiguration;
};

export type CreditCardApplicationFormDateInputField = {
  __typename?: "CreditCardApplicationFormDateInputField";
  dateFormat: Scalars["String"]["output"];
  egdsElementId: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
  input: CreditCardApplicationFormMaskedNumberInputField;
};

export type CreditCardApplicationFormDisclaimer = {
  __typename?: "CreditCardApplicationFormDisclaimer";
  label: Scalars["String"]["output"];
  text: Array<CreditCardApplicationFormText>;
};

export type CreditCardApplicationFormField =
  | CreditCardApplicationFormDisclaimer
  | CreditCardApplicationFormFieldRow
  | CreditCardApplicationFormFieldSet
  | CreditCardApplicationFormInput
  | CreditCardApplicationFormText
  | CreditCardExternalContent;

export type CreditCardApplicationFormFieldRow = {
  __typename?: "CreditCardApplicationFormFieldRow";
  fields: Array<CreditCardApplicationFormInput>;
};

export type CreditCardApplicationFormFieldSet = {
  __typename?: "CreditCardApplicationFormFieldSet";
  fields: Array<CreditCardApplicationFormFieldSetEntry>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardApplicationFormFieldSetEntry =
  | CreditCardApplicationFormFieldRow
  | CreditCardApplicationFormInput
  | CreditCardApplicationFormText;

export type CreditCardApplicationFormHeader = CreditCardApplicationBanner | CreditCardApplicationBenefitHeader;

export type CreditCardApplicationFormIdentifiers = {
  __typename?: "CreditCardApplicationFormIdentifiers";
  applicationId: Scalars["String"]["output"];
  applicationToken: Scalars["String"]["output"];
  instantCredit: Scalars["Boolean"]["output"];
  landingUrl: Scalars["String"]["output"];
  referrer: Scalars["String"]["output"];
  subProductCode: Scalars["String"]["output"];
};

export type CreditCardApplicationFormInput = {
  __typename?: "CreditCardApplicationFormInput";
  inputField: CreditCardApplicationFormInputField;
  subLabel?: Maybe<Scalars["String"]["output"]>;
  tooltipAction?: Maybe<CreditCardApplicationFormTooltipAction>;
};

export type CreditCardApplicationFormInputField =
  | CreditCardApplicationFormDateInputField
  | CreditCardApplicationFormMaskedNumberInputField
  | CreditCardHiddenDescriptionCheckbox
  | CreditCardInputFieldRemoteValidation
  | EgdsBasicCheckBox
  | EgdsBasicRadioGroup
  | EgdsBasicSelect
  | EgdsEmailInputField
  | EgdsNumberInputField
  | EgdsTextInputField;

export type CreditCardApplicationFormMaskedNumberInputField = {
  __typename?: "CreditCardApplicationFormMaskedNumberInputField";
  input: EgdsNumberInputField;
  masking: Scalars["String"]["output"];
};

export type CreditCardApplicationFormMerchantBackendConfiguration = {
  __typename?: "CreditCardApplicationFormMerchantBackendConfiguration";
  applicationsUrl: Scalars["String"]["output"];
  jwtToken: Scalars["String"]["output"];
  paymentInitializationUrl: Scalars["String"]["output"];
  sdkEventUrl: Scalars["String"]["output"];
  secureConfigurationUrl: Scalars["String"]["output"];
};

export type CreditCardApplicationFormOperation = "EQ" | "GE" | "GT" | "LE" | "LT" | "NE" | "NREX" | "REX";

export type CreditCardApplicationFormPrefillData = {
  __typename?: "CreditCardApplicationFormPrefillData";
  email?: Maybe<Scalars["String"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardApplicationFormProviderConfiguration = {
  __typename?: "CreditCardApplicationFormProviderConfiguration";
  sdkScriptUrl: Scalars["String"]["output"];
  sdkUrl: Scalars["String"]["output"];
};

export interface CreditCardApplicationFormQueryInput {
  landingUrl?: InputMaybe<Scalars["String"]["input"]>;
  sourcePage: CreditCardSourcePage;
}

export type CreditCardApplicationFormSdk = {
  __typename?: "CreditCardApplicationFormSdk";
  configuration: CreditCardApplicationFormConfiguration;
  exitButtons: Array<CreditCardApplicationSdkExitButtons>;
  generalExitPath?: Maybe<Scalars["String"]["output"]>;
  headers: Array<CreditCardApplicationSdkHeader>;
  topToolbarTitle: Scalars["String"]["output"];
};

export type CreditCardApplicationFormSection = {
  __typename?: "CreditCardApplicationFormSection";
  egdsElementId: Scalars["String"]["output"];
  fields: Array<CreditCardApplicationFormField>;
  hasDivider: Scalars["Boolean"]["output"];
  parentId?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  visibilityConditions?: Maybe<Array<CreditCardApplicationFormVisibilityCondition>>;
  visible: Scalars["Boolean"]["output"];
};

export type CreditCardApplicationFormText = {
  __typename?: "CreditCardApplicationFormText";
  textField: CreditCardApplicationTextField;
};

export type CreditCardApplicationFormTooltipAction = {
  __typename?: "CreditCardApplicationFormTooltipAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  sheet: EgdsSheet;
  text: Scalars["String"]["output"];
};

export type CreditCardApplicationFormUiConfiguration = {
  __typename?: "CreditCardApplicationFormUiConfiguration";
  loadCustomCSS?: Maybe<Scalars["Boolean"]["output"]>;
  loadCustomResourceBundle?: Maybe<Scalars["Boolean"]["output"]>;
  noAnimation?: Maybe<Scalars["Boolean"]["output"]>;
  silentInit?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface CreditCardApplicationFormUpdateInput {
  applicationId: Scalars["String"]["input"];
  applicationToken: Scalars["String"]["input"];
  fields: CreditCardApplicationFormValuesInput;
}

export interface CreditCardApplicationFormValuesInput {
  fieldValues: Array<GraphQlPairInput>;
}

export type CreditCardApplicationFormVisibilityCondition = {
  __typename?: "CreditCardApplicationFormVisibilityCondition";
  left: Scalars["String"]["output"];
  operation: CreditCardApplicationFormOperation;
  right: Scalars["String"]["output"];
};

export type CreditCardApplicationHeadingSize = "LARGE" | "SMALL";

export type CreditCardApplicationInactivityAndTimeout = {
  __typename?: "CreditCardApplicationInactivityAndTimeout";
  expiringSessionTimerSeconds: Scalars["Int"]["output"];
  inactivityWarningScreen: CreditCardApplicationInactivityWarningScreen;
  inactivityWarningSeconds: Scalars["Int"]["output"];
  sessionTimeoutScreen: CreditCardApplicationSessionTimeoutScreen;
};

export type CreditCardApplicationInactivityWarningScreen = {
  __typename?: "CreditCardApplicationInactivityWarningScreen";
  buttons: Array<CreditCardCtaButton>;
  content: Scalars["String"]["output"];
  counter: EgdsGraphicText;
  title: Scalars["String"]["output"];
};

export type CreditCardApplicationOfferDetail = {
  __typename?: "CreditCardApplicationOfferDetail";
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface CreditCardApplicationOfferInput {
  applicationId: Scalars["String"]["input"];
  applicationToken: Scalars["String"]["input"];
  secureConsent: CreditCardApplicationSecureConsentInput;
}

export type CreditCardApplicationOfferScreen = {
  __typename?: "CreditCardApplicationOfferScreen";
  consentAndCta: CreditCardConsentAndCta;
  details?: Maybe<Array<CreditCardApplicationOfferDetail>>;
  earlyExitDialog?: Maybe<CreditCardApplicationEarlyExitDialog>;
  image?: Maybe<Image>;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  info?: Maybe<Scalars["String"]["output"]>;
  loadingDialog?: Maybe<CreditCardApplicationCompoundLoadingDialog>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type CreditCardApplicationOpenDialogAction = {
  __typename?: "CreditCardApplicationOpenDialogAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  dialogType: CreditCardDialogType;
};

export type CreditCardApplicationPendingState = {
  __typename?: "CreditCardApplicationPendingState";
  info: Array<Scalars["String"]["output"]>;
  nextStepsText: Array<CreditCardApplicationTextField>;
  nextStepsTitle: Scalars["String"]["output"];
  offerDetails: Array<CreditCardApplicationOfferDetail>;
  title: Scalars["String"]["output"];
};

export type CreditCardApplicationPlacement = {
  __typename?: "CreditCardApplicationPlacement";
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  applicationPageUrl: Scalars["String"]["output"];
  cardFeeLink: EgdsInlineLink;
  cardFeeLinkClicked: Scalars["AnalyticsPayload"]["output"];
  cardImage: Image;
  cta?: Maybe<CreditCardApplyButton>;
  ctaAccessibility: Scalars["String"]["output"];
  ctaIcon?: Maybe<Icon>;
  ctaMessage: Scalars["String"]["output"];
  featuredHeading?: Maybe<Scalars["String"]["output"]>;
  headingClaim: EgdsPlainText;
  placementClicked: Scalars["AnalyticsPayload"]["output"];
  placementPresented: Scalars["AnalyticsPayload"]["output"];
  subHeadingClaim: CreditCardIconText;
  termsClaim: Scalars["String"]["output"];
};

export interface CreditCardApplicationPrefillQueryInput {
  landingUrl?: InputMaybe<Scalars["String"]["input"]>;
  sourcePage: CreditCardSourcePage;
}

export type CreditCardApplicationPrefillScreen = {
  __typename?: "CreditCardApplicationPrefillScreen";
  buttons: Array<CreditCardCtaButton>;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  header: CreditCardApplicationFormHeader;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  loadingDialog: CreditCardApplicationCompoundLoadingDialog;
  phoneNumber: CreditCardApplicationFormMaskedNumberInputField;
  phoneNumberDescriptionExpando: CreditCardExpandoPeek;
  ssnLastFour: CreditCardApplicationFormInput;
  subtitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  toolbarTitle: Scalars["String"]["output"];
};

export type CreditCardApplicationSdkEarlyExitDialog = {
  __typename?: "CreditCardApplicationSdkEarlyExitDialog";
  dialog?: Maybe<CreditCardApplicationEarlyExitDialog>;
  sdkPageIds: Array<Scalars["String"]["output"]>;
};

export type CreditCardApplicationSdkExitButtons = {
  __typename?: "CreditCardApplicationSdkExitButtons";
  buttons?: Maybe<Array<CreditCardCtaButton>>;
  sdkPageIds: Array<Scalars["String"]["output"]>;
};

export type CreditCardApplicationSdkHeader = {
  __typename?: "CreditCardApplicationSdkHeader";
  header?: Maybe<CreditCardApplicationFormHeader>;
  sdkPageIds: Array<Scalars["String"]["output"]>;
};

export interface CreditCardApplicationSecureConsentInput {
  creditConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  disclosureCreatedDatetime?: InputMaybe<Scalars["String"]["input"]>;
  eSignConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  mobileInformationConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  phoneConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  ssaConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsAgreementConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  wellsFargoPrivacyPolicyConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type CreditCardApplicationSessionTimeoutScreen = {
  __typename?: "CreditCardApplicationSessionTimeoutScreen";
  buttons: Array<CreditCardCtaButton>;
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CreditCardApplicationSizedHeading = {
  __typename?: "CreditCardApplicationSizedHeading";
  size: CreditCardApplicationHeadingSize;
  text: Scalars["String"]["output"];
};

export type CreditCardApplicationStepResponse = {
  __typename?: "CreditCardApplicationStepResponse";
  applicationContext: CreditCardApplicationContext;
  nextScreen: CreditCardScreen;
};

export type CreditCardApplicationTextField =
  | CreditCardSpannableText
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableList
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsUnorderedList;

export interface CreditCardApplicationThankYouInput {
  applicantFirstName: Scalars["String"]["input"];
  applicationID: Scalars["String"]["input"];
  applicationToken: Scalars["String"]["input"];
  cardType: CreditCardType;
  screenType: CreditCardThankYouScreenType;
}

export type CreditCardApplicationThankYouScreen = {
  __typename?: "CreditCardApplicationThankYouScreen";
  applicationState: CreditCardApplicationThankYouState;
  banner?: Maybe<CreditCardApplicationBanner>;
  ctaButton: CreditCardCtaButton;
};

export type CreditCardApplicationThankYouState = CreditCardApplicationApprovedState | CreditCardApplicationPendingState;

export type CreditCardApplyButton = {
  __typename?: "CreditCardApplyButton";
  action: CreditCardApplyButtonAction;
  button: CreditCardButton;
};

export type CreditCardApplyButtonAction = CreditCardEmbeddedApplyAction | UiLinkAction;

export type CreditCardAwarenessPlacement = {
  __typename?: "CreditCardAwarenessPlacement";
  backgroundImage: Image;
  badge?: Maybe<EgdsBadge>;
  cta: CreditCardApplyButton;
  heading: EgdsPlainText;
  headingNote?: Maybe<EgdsPlainText>;
  image: Image;
  impressionAnalytics: ClientSideAnalytics;
  subHeading?: Maybe<EgdsPlainText>;
};

export type CreditCardBenefitContent = {
  __typename?: "CreditCardBenefitContent";
  accessibility: Scalars["String"]["output"];
  content: Array<CreditCardBenefitHeaderDetails>;
};

export type CreditCardBenefitHeaderDetails = {
  __typename?: "CreditCardBenefitHeaderDetails";
  description?: Maybe<CreditCardApplicationTextField>;
  title: CreditCardBenefitHeaderTitle;
};

export type CreditCardBenefitHeaderTitle = {
  __typename?: "CreditCardBenefitHeaderTitle";
  icon?: Maybe<UiGraphic>;
  text: CreditCardSpannableText;
  /** @deprecated use stylizedTitle instead */
  title: Scalars["String"]["output"];
};

export type CreditCardBenefitItem = CreditCardExpandableText;

export interface CreditCardBenefitsInput {
  landingUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type CreditCardBenefitsSection = {
  __typename?: "CreditCardBenefitsSection";
  benefits: Array<CreditCardApplicationBenefit>;
  stylizedTitle: CreditCardSpannableText;
  /** @deprecated use stylizedTitle instead */
  title: Scalars["String"]["output"];
};

export type CreditCardButton = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export interface CreditCardCheckoutSessionValidateRequestInput {
  sessionId: Scalars["String"]["input"];
}

export type CreditCardCheckoutSessionValidateResponse = {
  __typename?: "CreditCardCheckoutSessionValidateResponse";
  signals: Array<CreditCardCheckoutSignalType>;
};

export type CreditCardCheckoutSignalType = "OFFER_CHANGED" | "PRICE_ADJUSTED" | "PRICE_CHANGED" | "USER_TIER_UPGRADED";

export type CreditCardComparisonHeader = {
  __typename?: "CreditCardComparisonHeader";
  /** @deprecated legacy */
  backgroundImage: Image;
  cardContainer: CreditCardApplicationBenefitCardContainer;
  heading: CreditCardSpannableText;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated legacy */
  jumpLink: CreditCardIconText;
  /** @deprecated legacy */
  oneKeyLogo: Image;
  subheading: EgdsPlainText;
  /** @deprecated legacy */
  subtitle: EgdsSpannableText;
};

export interface CreditCardComparisonHeaderInput {
  landingUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CreditCardComparisonTableInput {
  landingUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type CreditCardComparisonTableItem = CreditCardApplicationBenefitCardContainer | CreditCardDetailedComparison;

export type CreditCardComparisonTableSection = {
  __typename?: "CreditCardComparisonTableSection";
  content: Array<CreditCardComparisonTableItem>;
  stylizedTitle: CreditCardSpannableText;
  /** @deprecated use stylizedTitle instead */
  title: Scalars["String"]["output"];
};

export type CreditCardConsentAndCta = {
  __typename?: "CreditCardConsentAndCta";
  buttons: Array<CreditCardCtaButton>;
  consent?: Maybe<EgdsSpannableText>;
};

export type CreditCardContentFormat = "HTML" | "PDF";

export type CreditCardContentSource = "TERMS_AND_CONDITIONS";

export type CreditCardCtaAction =
  | CreditCardApplicationDeclineAction
  | CreditCardApplicationDismissAction
  | CreditCardApplicationOpenDialogAction
  | CreditCardDownloadExternalContentAction
  | EgdsDismissAction
  | UiLinkAction;

export type CreditCardCtaButton = {
  __typename?: "CreditCardCtaButton";
  action?: Maybe<CreditCardCtaAction>;
  button: CreditCardButton;
};

export type CreditCardDetailedComparison = {
  __typename?: "CreditCardDetailedComparison";
  details: Array<CreditCardDetailedComparisonRow>;
};

export type CreditCardDetailedComparisonDetails = CreditCardApplicationBenefitDetail | Icon;

export type CreditCardDetailedComparisonRow = {
  __typename?: "CreditCardDetailedComparisonRow";
  comparison: Array<CreditCardDetailedComparisonDetails>;
  stylizedTitle?: Maybe<CreditCardSpannableText>;
  /** @deprecated use stylizedTitle instead */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardDialogType = "EARLY_EXIT";

export type CreditCardDownloadExternalContentAction = {
  __typename?: "CreditCardDownloadExternalContentAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type CreditCardEmbeddedApplyAction = {
  __typename?: "CreditCardEmbeddedApplyAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  container: CreditCardEmbeddedApplyActionSheet;
};

export type CreditCardEmbeddedApplyActionSheet = {
  __typename?: "CreditCardEmbeddedApplyActionSheet";
  sheet: EgdsSheet;
};

export type CreditCardEncodedContent = {
  __typename?: "CreditCardEncodedContent";
  content: Scalars["String"]["output"];
  encodingType: CreditCardEncodingType;
  fileName: Scalars["String"]["output"];
};

export interface CreditCardEncodedContentInput {
  applicationId: Scalars["String"]["input"];
  applicationToken: Scalars["String"]["input"];
  cardType: CreditCardType;
  contentFormat: CreditCardContentFormat;
  contentSource: CreditCardContentSource;
}

export type CreditCardEncodingType = "Base64";

export type CreditCardErrorScreen = {
  __typename?: "CreditCardErrorScreen";
  actionButton?: Maybe<CreditCardCtaButton>;
  actionDescription?: Maybe<Scalars["String"]["output"]>;
  banner?: Maybe<CreditCardApplicationBanner>;
  ctaButton: CreditCardCtaButton;
  heading: Scalars["String"]["output"];
  paragraphs?: Maybe<Array<Scalars["String"]["output"]>>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export interface CreditCardErrorScreenInput {
  cardType?: InputMaybe<CreditCardType>;
  sourcePage: CreditCardSourcePage;
}

export type CreditCardExpandableText = {
  __typename?: "CreditCardExpandableText";
  accessibility?: Maybe<CreditCardExpandoAccessibility>;
  brandImage?: Maybe<CreditCardThemedImage>;
  content: Array<CreditCardApplicationTextField>;
  title: EgdsExpandoListItem;
};

export type CreditCardExpandoAccessibility = {
  __typename?: "CreditCardExpandoAccessibility";
  collapsedAccessibility: Scalars["String"]["output"];
  expandedAccessibility: Scalars["String"]["output"];
};

export type CreditCardExpandoPeek = {
  __typename?: "CreditCardExpandoPeek";
  content: Array<CreditCardSpannableText>;
  /** @deprecated use content instead */
  expandedContent: Array<EgdsSpannableText>;
  expando: EgdsExpandoPeek;
};

export type CreditCardExternalContent = {
  __typename?: "CreditCardExternalContent";
  button: CreditCardCtaButton;
  encodedContent: CreditCardEncodedContent;
  label: Scalars["String"]["output"];
};

export type CreditCardFaqSection = {
  __typename?: "CreditCardFAQSection";
  cardIssuerLogo?: Maybe<CreditCardThemedImage>;
  content: Array<CreditCardExpandableText>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  title: CreditCardSpannableText;
};

export interface CreditCardFraudOtpInput {
  applicationToken: Scalars["String"]["input"];
  passcode?: InputMaybe<Scalars["String"]["input"]>;
  passcodeInstructionId: Scalars["String"]["input"];
  phoneNumberLastFour?: InputMaybe<Scalars["String"]["input"]>;
  screenType: CreditCardFraudOtpScreenType;
}

export type CreditCardFraudOtpScreen = {
  __typename?: "CreditCardFraudOTPScreen";
  consentAndCta: CreditCardConsentAndCta;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  inputField?: Maybe<EgdsNumberInputField>;
  loadingDialog?: Maybe<CreditCardApplicationCompoundLoadingDialog>;
  moreInfoTrigger: CreditCardMoreInfoTrigger;
  paragraphs: Array<EgdsSpannableText>;
  resendCodeAttempts?: Maybe<Scalars["Int"]["output"]>;
  submitCodeAttempts?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  toastMessage?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardFraudOtpScreenType = "GENERATE_CODE" | "VERIFY_CODE";

export type CreditCardHiddenDescriptionCheckbox = {
  __typename?: "CreditCardHiddenDescriptionCheckbox";
  egdsCheckbox: EgdsCheckBox;
};

export type CreditCardIconText = {
  __typename?: "CreditCardIconText";
  icon: Icon;
  position: EgdsIconPosition;
  text: Scalars["String"]["output"];
};

export type CreditCardIllustrationSection = {
  __typename?: "CreditCardIllustrationSection";
  /** @deprecated use themedBrandImage instead */
  brandImage: Image;
  /** @deprecated use themedBrandImage instead */
  brandLogos: Array<UiGraphic>;
  description: Array<CreditCardSpannableText>;
  footnote: Scalars["String"]["output"];
  illustrations?: Maybe<Array<Image>>;
  subtitle: CreditCardSpannableText;
  themedBrandImage: CreditCardThemedImage;
  title: CreditCardSpannableText;
};

export type CreditCardIneligibleMessageBanner = {
  __typename?: "CreditCardIneligibleMessageBanner";
  message: UiBanner;
};

export type CreditCardInputFieldRemoteValidation = {
  __typename?: "CreditCardInputFieldRemoteValidation";
  inputField: CreditCardApplicationFormMaskedNumberInputField;
  validationType: CreditCardRemoteValidationType;
};

export type CreditCardIssuerReferenceCard = {
  __typename?: "CreditCardIssuerReferenceCard";
  action: UiLinkAction;
  icon?: Maybe<Icon>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type CreditCardLinkGroup = {
  __typename?: "CreditCardLinkGroup";
  links: EgdsLinkList;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardMoreInfoTrigger = {
  __typename?: "CreditCardMoreInfoTrigger";
  text: Scalars["String"]["output"];
  tooltipAction: CreditCardApplicationFormTooltipAction;
};

export type CreditCardNoWrapText = {
  __typename?: "CreditCardNoWrapText";
  text: EgdsStylizedText;
};

export type CreditCardOneKeyCashSection = {
  __typename?: "CreditCardOneKeyCashSection";
  backgroundImage: Image;
  /** @deprecated use themedBrandImage instead */
  brandImage: Image;
  /** @deprecated use themedBrandImage instead */
  brandLogos: Array<UiGraphic>;
  cardImage: Image;
  description: Array<EgdsSpannableText>;
  footnote: Scalars["String"]["output"];
  /** @deprecated use subtitle instead */
  plainSubtitle: Scalars["String"]["output"];
  subtitle: EgdsSpannableText;
  themedBrandImage: CreditCardThemedImage;
  title: Scalars["String"]["output"];
};

export type CreditCardPaymentFop = IPaymentFop & {
  __typename?: "CreditCardPaymentFop";
  id: Scalars["String"]["output"];
  paymentAmount?: Maybe<PaymentPriceDetails>;
  paymentMethod: PaymentMethodType;
  paymentMethodAttribute: PaymentMethodAttribute;
  paymentSubMethods?: Maybe<Array<Scalars["String"]["output"]>>;
  rank?: Maybe<Scalars["Int"]["output"]>;
  splittable?: Maybe<Scalars["Boolean"]["output"]>;
  state: PaymentState;
  storedPaymentInstruments?: Maybe<Array<StoredPaymentInstrumentInfo>>;
};

export type CreditCardPaymentMethodAttribute = {
  __typename?: "CreditCardPaymentMethodAttribute";
  authenticationSpec?: Maybe<AuthenticationSpec>;
  binSpec?: Maybe<BinSpec>;
  checkoutId?: Maybe<Scalars["String"]["output"]>;
  encodedBrowserMetadata?: Maybe<Scalars["String"]["output"]>;
  etzToken?: Maybe<Scalars["String"]["output"]>;
  storageDisabled: Scalars["Boolean"]["output"];
};

export type CreditCardRemoteValidationType = "SSN_ITIN";

export type CreditCardRewardActivityHistoryExpando = {
  __typename?: "CreditCardRewardActivityHistoryExpando";
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  items: Array<CreditCardRewardActivityHistoryExpandoItem>;
  title: Scalars["String"]["output"];
  total: CreditCardRewardActivityHistoryExpandoItem;
  value: CreditCardRewardActivityIconText;
};

export type CreditCardRewardActivityHistoryExpandoItem = {
  __typename?: "CreditCardRewardActivityHistoryExpandoItem";
  subtitles?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
  value: CreditCardRewardActivityIconText;
};

export type CreditCardRewardActivityIconText = {
  __typename?: "CreditCardRewardActivityIconText";
  amountChanged: EgdsStylizedText;
  icon: Icon;
};

export type CreditCardRewardActivityItems = {
  __typename?: "CreditCardRewardActivityItems";
  items: Array<CreditCardRewardActivityHistoryExpando>;
  showMorePeek?: Maybe<EgdsExpandoPeek>;
  visibleItems?: Maybe<Scalars["Int"]["output"]>;
};

export type CreditCardRewardNoActivity = {
  __typename?: "CreditCardRewardNoActivity";
  subtitles: Array<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CreditCardRewardsActivity = {
  __typename?: "CreditCardRewardsActivity";
  /** @deprecated This won't be used, as the background color will be applied as a theme on the client side */
  backgroundImage: Image;
  cardIssuerReference: CreditCardIssuerReferenceCard;
  image: Image;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  rewardsActivityContent: CreditCardRewardsActivityContent;
  title: Scalars["String"]["output"];
};

export type CreditCardRewardsActivityContent = CreditCardRewardActivityItems | CreditCardRewardNoActivity;

export type CreditCardRewardsBenefits = {
  __typename?: "CreditCardRewardsBenefits";
  content: Array<CreditCardBenefitItem>;
  title: CreditCardSpannableText;
};

export type CreditCardScreen =
  | "ERROR"
  | "FRAUD_OTP_GENERATE"
  | "FRAUD_OTP_VERIFY"
  | "OFFER"
  | "ORIGIN"
  | "TERMS_AND_CONDITIONS"
  | "THANK_YOU_APPROVED"
  | "THANK_YOU_PENDING";

export type CreditCardSourcePage = "APPLICATION" | "CHECKOUT";

export type CreditCardSpannableText = {
  __typename?: "CreditCardSpannableText";
  accessibility: Scalars["String"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  inlineContent: Array<CreditCardSpannableTextItem>;
};

export type CreditCardSpannableTextItem = CreditCardNoWrapText | EgdsInlineLink | EgdsPlainText | EgdsStylizedText;

export type CreditCardTermsAndConditions = {
  __typename?: "CreditCardTermsAndConditions";
  banner?: Maybe<CreditCardApplicationBanner>;
  consentAndCta: CreditCardConsentAndCta;
  earlyExitDialog: CreditCardApplicationEarlyExitDialog;
  inactivityAndTimeout: CreditCardApplicationInactivityAndTimeout;
  loadingDialog: CreditCardApplicationCompoundLoadingDialog;
  sections: Array<CreditCardApplicationFormSection>;
};

export type CreditCardThankYouScreenType = "APPROVED" | "PENDING";

export type CreditCardThemedImage = {
  __typename?: "CreditCardThemedImage";
  dark: Image;
  light: Image;
};

export type CreditCardType = "FEE" | "NO_FEE";

export type CreditEligibleFilterType = "CREDIT_ELIGIBLE";

export type CreditRedemptionInsurtechOffersResponse = {
  __typename?: "CreditRedemptionInsurtechOffersResponse";
  creditRedemptionInsurtechModule: InsurtechModule;
};

export interface CriteriaRange {
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
}

export type CrossLinkImageLinkCard = {
  __typename?: "CrossLinkImageLinkCard";
  gaiaId?: Maybe<Scalars["String"]["output"]>;
  imagelink: EgdsImageLinkCard;
};

export type CrossLinkItem = CrossLinkImageLinkCard | CrossLinkStandardLink;

export type CrossLinkList = {
  __typename?: "CrossLinkList";
  carousel?: Maybe<EgdsCarousel>;
  items: Array<CrossLinkItem>;
  outline?: Maybe<CrossLinkOutline>;
};

export type CrossLinkLocations = {
  __typename?: "CrossLinkLocations";
  carousel?: Maybe<EgdsCarousel>;
  items: Array<CrossLinkItem>;
  map?: Maybe<EgdsBasicMap>;
  outline?: Maybe<CrossLinkOutline>;
};

export type CrossLinkOutline = {
  __typename?: "CrossLinkOutline";
  description?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  subheader?: Maybe<Scalars["String"]["output"]>;
};

export type CrossLinkStandardLink = {
  __typename?: "CrossLinkStandardLink";
  description?: Maybe<Scalars["String"]["output"]>;
  link: EgdsStandardLink;
};

export type CrossLinksGroupItems = {
  __typename?: "CrossLinksGroupItems";
  items: Array<CrossLinkItem>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CrossLinksGroups = {
  __typename?: "CrossLinksGroups";
  items: Array<CrossLinksGroupItems>;
  outline?: Maybe<CrossLinkOutline>;
};

export interface CrossLinksParameterInput {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type CrossSellAction = CrossSellButtonAction | CrossSellLinkAction;

export type CrossSellButtonAction = UiAction & {
  __typename?: "CrossSellButtonAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  resource: Uri;
};

export type CrossSellLegalDisclaimerDialog = {
  __typename?: "CrossSellLegalDisclaimerDialog";
  analytics: ClientSideAnalytics;
  dialog: EgdsActionDialog;
  graphic: UiGraphic;
  text: Scalars["String"]["output"];
};

export type CrossSellLinkAction = UiAction & {
  __typename?: "CrossSellLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  resource: Uri;
};

export type CrossSellMessageItem = {
  __typename?: "CrossSellMessageItem";
  crossSellMessage: Scalars["String"]["output"];
  disclaimerDialog?: Maybe<CrossSellLegalDisclaimerDialog>;
};

export type CrossSellOption = {
  __typename?: "CrossSellOption";
  action: CrossSellLinkAction;
  actionBar?: Maybe<Array<CrossSellAction>>;
  border?: Maybe<Scalars["Boolean"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  header: CrossSellPrimary;
  image?: Maybe<Image>;
  messageItems: Array<CrossSellSecondary>;
  /** @deprecated use header instead */
  primary: Scalars["String"]["output"];
  /** @deprecated use messageItems instead */
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  theme?: Maybe<MessagingCardTheme>;
};

export type CrossSellPrimary = CrossSellMessageItem;

export type CrossSellProductType = "ACTIVITY" | "CAR" | "FLIGHT" | "GROUND_TRANSFER" | "HOTEL";

export type CrossSellProductTypeQueries = {
  __typename?: "CrossSellProductTypeQueries";
  productTypes: Array<CrossSellProductType>;
};

export type CrossSellQueries = {
  __typename?: "CrossSellQueries";
  crossSellProductTypes: CrossSellProductTypeQueries;
  dynamic: DynamicCrossSellQueries;
  static: StaticCrossSellQueries;
};

export type CrossSellQueriesCrossSellProductTypesArgs = {
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type CrossSellSecondary = CrossSellLinkAction | CrossSellMessageItem | PackageUiButton;

export interface CrossSellUserLocationInput {
  city: Scalars["String"]["input"];
  continent: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  region: Scalars["String"]["input"];
}

export type CruiseAction = UiAction & {
  __typename?: "CruiseAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type CruiseAmenities = {
  __typename?: "CruiseAmenities";
  rows?: Maybe<Array<CruiseHighlightRow>>;
};

export type CruiseAmenitiesList = {
  __typename?: "CruiseAmenitiesList";
  amenities: Array<CruiseAmenityInfo>;
  collapseA11yLabel?: Maybe<Scalars["String"]["output"]>;
  collapseLabel?: Maybe<Scalars["String"]["output"]>;
  expandA11yLabel?: Maybe<Scalars["String"]["output"]>;
  expandLabel?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  visibleItems?: Maybe<Scalars["Int"]["output"]>;
};

export interface CruiseAmenitiesListInput {
  amenities: Array<CruiseAmenityInfoInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
}

export type CruiseAmenitiesListOutput = {
  __typename?: "CruiseAmenitiesListOutput";
  amenities: Array<CruiseAmenityInfoOutput>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseAmenitiesSection = {
  __typename?: "CruiseAmenitiesSection";
  amenities?: Maybe<Array<CruiseAmenityDetails>>;
  heading: Scalars["String"]["output"];
};

export type CruiseAmenityDetails = {
  __typename?: "CruiseAmenityDetails";
  amenitySummaries?: Maybe<CruiseAmenitiesList>;
  amenitySummariesLabel?: Maybe<Scalars["String"]["output"]>;
  benefitTexts: Array<Scalars["String"]["output"]>;
  promotionText: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseAmenityInfo = {
  __typename?: "CruiseAmenityInfo";
  a11yCategory?: Maybe<Scalars["String"]["output"]>;
  a11yDescription: Scalars["String"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  icon: Icon;
};

export interface CruiseAmenityInfoInput {
  a11yCategory?: InputMaybe<Scalars["String"]["input"]>;
  a11yDescription: Scalars["String"]["input"];
  category?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  icon: CruiseOrderSummaryRatesPromotionIconInfoInput;
}

export type CruiseAmenityInfoOutput = {
  __typename?: "CruiseAmenityInfoOutput";
  a11yCategory?: Maybe<Scalars["String"]["output"]>;
  a11yDescription: Scalars["String"]["output"];
  category?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  icon: CruiseOrderSummaryRatesPromotionIconInfoOutput;
};

export type CruiseBadgeTheme =
  | "DEAL_GENERIC"
  | "DEAL_MEMBER"
  | "GLOBAL_LOYALTY_EXTRAHIGH_TIER"
  | "GLOBAL_LOYALTY_HIGH_TIER"
  | "GLOBAL_LOYALTY_LOW_TIER"
  | "GLOBAL_LOYALTY_MID_TIER"
  | "VIP";

export type CruiseBundleDetailsResults = {
  __typename?: "CruiseBundleDetailsResults";
  bundleDetails: CruiseOrderSummaryPromotionSideSheet;
};

export type CruiseCabinCategory = {
  __typename?: "CruiseCabinCategory";
  amenitiesList?: Maybe<CruiseAmenitiesList>;
  deckRange?: Maybe<CruiseInfo>;
  disclaimer?: Maybe<CruiseInfo>;
  galleryCarousel?: Maybe<CruiseGallery>;
  occupancyRange?: Maybe<CruiseInfo>;
  offlineRateDetails?: Maybe<OfflineRateDetails>;
  price: CruisePrice;
  selectButton?: Maybe<UiPrimaryButton>;
  squareFootageRange?: Maybe<CruiseInfo>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type CruiseCabinCategorySection = {
  __typename?: "CruiseCabinCategorySection";
  analytics: CruiseCarouselAnalytics;
  cabinCategories?: Maybe<Array<CruiseCabinCategory>>;
  nextButtonText: Scalars["String"]["output"];
  prevButtonText: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseCabinClass = {
  __typename?: "CruiseCabinClass";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  continueButton: UiPrimaryButton;
  descriptionText: Scalars["String"]["output"];
  features: Array<CruiseCabinClassFeature>;
  galleryCarousel: CruiseGallery;
  occupancyRange?: Maybe<CruiseCabinClassOccupancy>;
  offlineRateDetails?: Maybe<OfflineRateDetails>;
  price: CruisePrice;
  roomRateSelection: CruiseRoomRateSelection;
  squareFootageRange?: Maybe<CruiseCabinClassSquareFootage>;
  title: Scalars["String"]["output"];
  userInterest?: Maybe<CruiseUserInterest>;
};

export type CruiseCabinClassFeature = {
  __typename?: "CruiseCabinClassFeature";
  accessibility: Scalars["String"]["output"];
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type CruiseCabinClassOccupancy = {
  __typename?: "CruiseCabinClassOccupancy";
  accessibility: Scalars["String"]["output"];
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type CruiseCabinClassSquareFootage = {
  __typename?: "CruiseCabinClassSquareFootage";
  accessibility: Scalars["String"]["output"];
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type CruiseCabinExperience = {
  __typename?: "CruiseCabinExperience";
  analytics: CruiseCabinExperienceAnalytics;
  cabinClasses?: Maybe<Array<CruiseCabinClass>>;
  crumbs?: Maybe<Array<CruiseProgressIndicatorStep>>;
  descriptionText?: Maybe<Scalars["String"]["output"]>;
  expressedPreference?: Maybe<CruiseExpressedPreference>;
  nextButtonText: Scalars["String"]["output"];
  prevButtonText: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseCabinExperienceAnalytics = {
  __typename?: "CruiseCabinExperienceAnalytics";
  carouselName: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type CruiseCarouselAnalytics = {
  __typename?: "CruiseCarouselAnalytics";
  carouselName: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type CruiseCarouselButtonText = {
  __typename?: "CruiseCarouselButtonText";
  galleryButton: Scalars["String"]["output"];
  nextButton: Scalars["String"]["output"];
  overlayButton?: Maybe<EgdsOverlayButton>;
  prevButton: Scalars["String"]["output"];
};

export interface CruiseChildInput {
  age: Scalars["Int"]["input"];
}

export interface CruiseCommonItinerary {
  callToAction?: Maybe<UiPrimaryButton>;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
}

export type CruiseConsultationError = {
  __typename?: "CruiseConsultationError";
  error: Scalars["String"]["output"];
  fieldId: Scalars["String"]["output"];
};

export type CruiseConsultationForm = {
  __typename?: "CruiseConsultationForm";
  additionalQuestion: EgdsTextAreaInputField;
  countryDropDown: EgdsSelect;
  passengerBasicInfo: Array<EgdsTextInputField>;
  roomPreferences: Array<EgdsSelect>;
  submit: UiPrimaryButton;
  travelExperiencePreferences: Array<EgdsRadioGroup>;
};

export interface CruiseConsultationFormInput {
  adultCount: Scalars["Int"]["input"];
  childCount: Scalars["Int"]["input"];
  country: Scalars["String"]["input"];
  datesFlexible?: InputMaybe<Scalars["String"]["input"]>;
  emailAddress: Scalars["String"]["input"];
  everCruised?: InputMaybe<Scalars["String"]["input"]>;
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  passengersOver55?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber: Scalars["String"]["input"];
  questions?: InputMaybe<Scalars["String"]["input"]>;
  roomTypePreference: Scalars["String"]["input"];
  zipCode: Scalars["String"]["input"];
}

export type CruiseConsultationFormSuccess = {
  __typename?: "CruiseConsultationFormSuccess";
  link: UiSecondaryButton;
  redirectUrl: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseConsultationResults = {
  __typename?: "CruiseConsultationResults";
  formFields: CruiseConsultationForm;
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  introPassage: EgdsStandardMessagingCard;
  pageTitle?: Maybe<Scalars["String"]["output"]>;
  submit: CruiseConsultationSubmissionResponse;
  tripDetails?: Maybe<CruiseConsultationTripDetails>;
  userErrorMessage: EgdsErrorSummary;
};

export type CruiseConsultationSubmissionResponse = {
  __typename?: "CruiseConsultationSubmissionResponse";
  errorMessage?: Maybe<EgdsErrorSummary>;
  errorMessageLink?: Maybe<EgdsStandardLink>;
  errors?: Maybe<Array<CruiseConsultationError>>;
  success?: Maybe<CruiseConsultationFormSuccess>;
};

export type CruiseConsultationTripDetails = {
  __typename?: "CruiseConsultationTripDetails";
  departingDate: Scalars["String"]["output"];
  departingLocation: Scalars["String"]["output"];
  iconMedia?: Maybe<CruiseMediaItem>;
  numOfTravelers: Scalars["String"]["output"];
  returningDate: Scalars["String"]["output"];
  returningLocation: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  tripDurationAndDestination: Scalars["String"]["output"];
};

export type CruiseDeckSelect = {
  __typename?: "CruiseDeckSelect";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  availableDeckLabel?: Maybe<Scalars["String"]["output"]>;
  availableOptions: Array<CruiseSelectOptions>;
  deckAnalytics: ClientSideAnalytics;
  id?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  unavailableDeckLabel?: Maybe<Scalars["String"]["output"]>;
  unavailableOptions?: Maybe<Array<CruiseSelectOptions>>;
};

export type CruiseDialog = {
  __typename?: "CruiseDialog";
  closeAction?: Maybe<UiAction>;
  content: Scalars["String"]["output"];
  openAction?: Maybe<UiAction>;
  priceText?: Maybe<Scalars["String"]["output"]>;
  primaryButton: UiPrimaryButton;
};

export type CruiseDialogStaleSession = {
  __typename?: "CruiseDialogStaleSession";
  dialogButton: UiTertiaryButton;
  message: Scalars["String"]["output"];
  timeoutInSeconds: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
};

export interface CruiseDurationRangeInput {
  max: Scalars["Int"]["input"];
  min: Scalars["Int"]["input"];
}

export type CruiseExploreMapButton = {
  __typename?: "CruiseExploreMapButton";
  accessibility: Scalars["String"]["output"];
  clientSideAnalytics: ClientSideAnalytics;
  text: Scalars["String"]["output"];
};

export type CruiseExpressedPreference = {
  __typename?: "CruiseExpressedPreference";
  cabinExperienceIndex: Scalars["Int"]["output"];
};

export type CruiseFeature = "LIVE_SHOPPING_USING_MSC_ADAPTER" | "MEMBER_DEALS" | "MEMBER_DEALS_FOR_NON_MEMBERS";

export type CruiseFilterType =
  | "CABIN"
  | "DEPARTURE"
  | "DESTINATION"
  | "LENGTH"
  | "NAME"
  | "NOT_SET"
  | "SHIP"
  | "THEME_OCEAN"
  | "THEME_RIVER";

export type CruiseGallery = {
  __typename?: "CruiseGallery";
  a11yGalleryHeading?: Maybe<Scalars["String"]["output"]>;
  buttonText: CruiseCarouselButtonText;
  detailDialogClose?: Maybe<UiAction>;
  detailDialogLeftRight?: Maybe<UiAction>;
  dialogCloseText?: Maybe<Scalars["String"]["output"]>;
  dialogOpen?: Maybe<UiAction>;
  imageClick?: Maybe<UiAction>;
  leftRightClientSideAnalytics?: Maybe<UiAction>;
  /** @deprecated Use CruiseMediaItem list instead. */
  media?: Maybe<Array<CruiseMedia>>;
  mediaItems?: Maybe<Array<CruiseMediaItem>>;
  text?: Maybe<Scalars["String"]["output"]>;
  thumbnailDialogClose?: Maybe<UiAction>;
  thumbnailDialogImageClick?: Maybe<UiAction>;
  videoButtonText?: Maybe<CruiseVideoButtonText>;
  videoPlayPauseButtonClick?: Maybe<UiAction>;
};

export type CruiseHighlight = {
  __typename?: "CruiseHighlight";
  icon?: Maybe<Icon>;
  secondaryText?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseHighlightRow = {
  __typename?: "CruiseHighlightRow";
  highlights?: Maybe<Array<CruiseHighlight>>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseIcon = {
  __typename?: "CruiseIcon";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<CruiseBadgeTheme>;
};

export type CruiseInfo = {
  __typename?: "CruiseInfo";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  moreInfo?: Maybe<CruisePopover>;
  text: Scalars["String"]["output"];
};

export type CruiseItinerary = CruiseCommonItinerary & {
  __typename?: "CruiseItinerary";
  callToAction?: Maybe<UiPrimaryButton>;
  galleryCarousel: CruiseGallery;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  title: Scalars["String"]["output"];
};

export type CruiseItineraryItem = {
  __typename?: "CruiseItineraryItem";
  accessibilityTimeDescription?: Maybe<Scalars["String"]["output"]>;
  button?: Maybe<EgdsOverlayButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  primarySubtitle?: Maybe<Scalars["String"]["output"]>;
  secondarySubtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseLinePolicies = {
  __typename?: "CruiseLinePolicies";
  policies: Array<CruiseLinePolicy>;
  title: Scalars["String"]["output"];
};

export type CruiseLinePolicy = {
  __typename?: "CruiseLinePolicy";
  clientSideAnalytics: ClientSideAnalytics;
  description: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  link?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export interface CruiseLinePolicyInput {
  link?: InputMaybe<Scalars["String"]["input"]>;
}

export type CruiseLinePolicyOutput = {
  __typename?: "CruiseLinePolicyOutput";
  link?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseMedia = {
  __typename?: "CruiseMedia";
  alt?: Maybe<Scalars["String"]["output"]>;
  caption?: Maybe<Scalars["String"]["output"]>;
  src?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<MediaType>;
};

export type CruiseMediaItem = MediaItem & {
  __typename?: "CruiseMediaItem";
  alt?: Maybe<Scalars["String"]["output"]>;
  media: Media;
};

export type CruiseMessagingCard = {
  __typename?: "CruiseMessagingCard";
  icon: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<CruisePhoneNumber>;
  requestConsultation: CruiseRequestConsultation;
  title: Scalars["String"]["output"];
};

export type CruiseNavigationBar = {
  __typename?: "CruiseNavigationBar";
  primaryButton: UiPrimaryButton;
  tabs?: Maybe<Array<SailingNavigationBarTab>>;
};

export type CruiseOffersResults = {
  __typename?: "CruiseOffersResults";
  amenities: CruiseAmenities;
  cabinExperience?: Maybe<CruiseCabinExperience>;
  cruiseLinePolicies?: Maybe<CruiseLinePolicies>;
  details: CruiseSailingDetails;
  /** @deprecated Use galleryMediaItem instead. */
  gallery: CruiseGallery;
  galleryMediaItem: CruiseGallery;
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  itinerary: CruiseItinerary;
  memberSignInBanner?: Maybe<EgdsStandardMessagingCard>;
  messagingCard: CruiseMessagingCard;
  messagingCardStandard: EgdsStandardMessagingCard;
  navigationBar?: Maybe<CruiseNavigationBar>;
  sailingSearchLink: CruiseSailingSearchLink;
  termsAndConditions: CruiseSubsection;
};

export type CruiseOrderSummaryDeckplan = {
  __typename?: "CruiseOrderSummaryDeckplan";
  a11yLabel?: Maybe<Scalars["String"]["output"]>;
  checkoutUrl: Scalars["String"]["output"];
  guaranteedCabin?: Maybe<CruiseOrderSummaryDeckplanGuaranteedCabin>;
  isCategoryGuaranteed: Scalars["Boolean"]["output"];
  messaging: CruiseOrderSummaryDeckplanMessaging;
  roomLocationSidesheet?: Maybe<CruiseOrderSummaryDeckplanSidesheet>;
};

export type CruiseOrderSummaryDeckplanDialog = {
  __typename?: "CruiseOrderSummaryDeckplanDialog";
  buttons: Array<EgdsButton>;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryDeckplanGuaranteedCabin = {
  __typename?: "CruiseOrderSummaryDeckplanGuaranteedCabin";
  dialog?: Maybe<CruiseOrderSummaryDeckplanDialog>;
  messagingCard: EgdsStandardMessagingCard;
  value: Scalars["String"]["output"];
};

export type CruiseOrderSummaryDeckplanImage = {
  __typename?: "CruiseOrderSummaryDeckplanImage";
  id: Scalars["String"]["output"];
  image: CruiseMediaItem;
};

export type CruiseOrderSummaryDeckplanMessaging = {
  __typename?: "CruiseOrderSummaryDeckplanMessaging";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  accessibilityTemplate?: Maybe<EgdsLocalizedText>;
  headingTemplate?: Maybe<EgdsLocalizedText>;
  messagingCard: EgdsStandardMessagingCard;
};

export type CruiseOrderSummaryDeckplanRoomsExpando = {
  __typename?: "CruiseOrderSummaryDeckplanRoomsExpando";
  collapseLabel: Scalars["String"]["output"];
  expandLabel: Scalars["String"]["output"];
  isVisible: Scalars["Boolean"]["output"];
  items: Scalars["Int"]["output"];
};

export type CruiseOrderSummaryDeckplanSidesheet = {
  __typename?: "CruiseOrderSummaryDeckplanSidesheet";
  backText: Scalars["String"]["output"];
  closeIconLabel: Scalars["String"]["output"];
  deckLayout: Array<CruiseOrderSummaryDeckplanImage>;
  decks: CruiseDeckSelect;
  frontText: Scalars["String"]["output"];
  roomAnalytics: ClientSideAnalytics;
  rooms: Array<CruiseSelect>;
  roomsExpando: CruiseOrderSummaryDeckplanRoomsExpando;
  saveButton: UiPrimaryButton;
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryOfflineRate = {
  __typename?: "CruiseOrderSummaryOfflineRate";
  descriptionForDesktopView: Scalars["String"]["output"];
  descriptionForMobileView: Scalars["String"]["output"];
  phoneNumber?: Maybe<CruisePhoneNumber>;
  promotions?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryPriceDetails = {
  __typename?: "CruiseOrderSummaryPriceDetails";
  closeIcon: Icon;
  depositMessage?: Maybe<Scalars["String"]["output"]>;
  membersSaveMessage?: Maybe<CruiseInfo>;
  passengerFareDetails: Array<PassengerFareDetails>;
  price?: Maybe<CruisePrice>;
  reducedDepositDetails?: Maybe<ReducedDepositDetails>;
  reserveButton?: Maybe<UiPrimaryButton>;
  title: Scalars["String"]["output"];
  totalPrice: Scalars["String"]["output"];
  totalPriceLabel: Scalars["String"]["output"];
};

export type CruiseOrderSummaryPriceSummary = {
  __typename?: "CruiseOrderSummaryPriceSummary";
  a11yLabel?: Maybe<Scalars["String"]["output"]>;
  priceDetails: CruiseOrderSummaryPriceDetails;
  stickyBottom: StickyBottom;
};

export type CruiseOrderSummaryPromotionDetails = {
  __typename?: "CruiseOrderSummaryPromotionDetails";
  amenityInfo: CruiseAmenityInfo;
  cancellationPolicy?: Maybe<CruiseLinePolicy>;
  /** @deprecated This field is deprecated. Use promotionInstruction instead */
  cruiseOrderSummaryPromotionInstruction?: Maybe<CruiseOrderSummaryPromotionInstruction>;
  /** @deprecated This field is deprecated. Use promotionPolicy instead */
  cruiseOrderSummaryPromotionPolicy?: Maybe<CruiseOrderSummaryPromotionPolicy>;
  promotionInstruction?: Maybe<CruiseOrderSummaryPromotionInstruction>;
  promotionPolicy?: Maybe<CruiseOrderSummaryPromotionPolicy>;
};

export interface CruiseOrderSummaryPromotionDetailsInput {
  amenityInfo: CruiseAmenityInfoInput;
  cancellationPolicy?: InputMaybe<CruiseLinePolicyInput>;
  cruiseOrderSummaryPromotionInstruction?: InputMaybe<CruiseOrderSummaryPromotionInstructionInput>;
  cruiseOrderSummaryPromotionPolicy?: InputMaybe<CruiseOrderSummaryPromotionPolicyInput>;
}

export type CruiseOrderSummaryPromotionDetailsOutput = {
  __typename?: "CruiseOrderSummaryPromotionDetailsOutput";
  amenityInfo: CruiseAmenityInfoOutput;
  cancellationPolicy?: Maybe<CruiseLinePolicyOutput>;
  cruiseOrderSummaryPromotionInstruction?: Maybe<CruiseOrderSummaryPromotionInstructionOutput>;
  cruiseOrderSummaryPromotionPolicy?: Maybe<CruiseOrderSummaryPromotionPolicyOutput>;
};

export type CruiseOrderSummaryPromotionExpando = {
  __typename?: "CruiseOrderSummaryPromotionExpando";
  analytics: ClientSideAnalytics;
  combinedAmenities?: Maybe<Array<CruiseAmenitiesList>>;
  title: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryPromotionExpandoInput {
  combinedAmenities?: InputMaybe<Array<CruiseAmenitiesListInput>>;
}

export type CruiseOrderSummaryPromotionExpandoOutput = {
  __typename?: "CruiseOrderSummaryPromotionExpandoOutput";
  combinedAmenities?: Maybe<Array<CruiseAmenitiesListOutput>>;
};

export type CruiseOrderSummaryPromotionInstruction = {
  __typename?: "CruiseOrderSummaryPromotionInstruction";
  promotionCode: Scalars["String"]["output"];
  promotionName?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryPromotionInstructionInput {
  promotionCode: Scalars["String"]["input"];
  promotionName?: InputMaybe<Scalars["String"]["input"]>;
  text: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
}

export type CruiseOrderSummaryPromotionInstructionOutput = {
  __typename?: "CruiseOrderSummaryPromotionInstructionOutput";
  promotionCode: Scalars["String"]["output"];
  promotionName?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryPromotionPolicy = {
  __typename?: "CruiseOrderSummaryPromotionPolicy";
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryPromotionPolicyInput {
  text: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
}

export type CruiseOrderSummaryPromotionPolicyOutput = {
  __typename?: "CruiseOrderSummaryPromotionPolicyOutput";
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryPromotionSideSheet = {
  __typename?: "CruiseOrderSummaryPromotionSideSheet";
  closeIcon: CruiseOrderSummaryPromotionSideSheetCloseIcon;
  /** @deprecated This field is deprecated. Use promotionDetails instead */
  cruiseOrderSummaryPromotionDetails: Array<CruiseOrderSummaryPromotionDetails>;
  expando: CruiseOrderSummaryPromotionExpando;
  offlineSailingMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  price?: Maybe<CruisePrice>;
  promotionDetails: Array<CruiseOrderSummaryPromotionDetails>;
  termsAndConditions?: Maybe<CruiseOrderSummaryPromotionTermsAndConditions>;
  text?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryPromotionSideSheetCloseIcon = {
  __typename?: "CruiseOrderSummaryPromotionSideSheetCloseIcon";
  analytics: ClientSideAnalytics;
  icon: Icon;
  iconLabel: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryPromotionSideSheetInput {
  cruiseOrderSummaryPromotionDetails: Array<CruiseOrderSummaryPromotionDetailsInput>;
  expando: CruiseOrderSummaryPromotionExpandoInput;
  termsAndConditions?: InputMaybe<CruiseOrderSummaryPromotionTermsAndConditionsInput>;
  text?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type CruiseOrderSummaryPromotionSideSheetOutput = {
  __typename?: "CruiseOrderSummaryPromotionSideSheetOutput";
  cruiseOrderSummaryPromotionDetails: Array<CruiseOrderSummaryPromotionDetailsOutput>;
  expando: CruiseOrderSummaryPromotionExpandoOutput;
  termsAndConditions?: Maybe<CruiseOrderSummaryPromotionTermsAndConditionsOutput>;
  text?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type CruiseOrderSummaryPromotionTermsAndConditions = {
  __typename?: "CruiseOrderSummaryPromotionTermsAndConditions";
  accessibleText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<UiLinkAction>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export interface CruiseOrderSummaryPromotionTermsAndConditionsInput {
  link?: InputMaybe<CruiseOrderSummaryRatesUiLinkActionInfoInput>;
}

export type CruiseOrderSummaryPromotionTermsAndConditionsOutput = {
  __typename?: "CruiseOrderSummaryPromotionTermsAndConditionsOutput";
  link?: Maybe<CruiseOrderSummaryRatesUiLinkActionInfoOutput>;
};

export type CruiseOrderSummaryRatesInfo = {
  __typename?: "CruiseOrderSummaryRatesInfo";
  a11ySelectButtonText: Scalars["String"]["output"];
  bookableOnline: Scalars["Boolean"]["output"];
  offlineRateDetails?: Maybe<OfflineRateDetails>;
  price?: Maybe<CruisePrice>;
  promotionDetails?: Maybe<CruiseOrderSummaryPromotionSideSheet>;
  promotionDetailsAnalytics?: Maybe<ClientSideAnalytics>;
  promotionDetailsIcon: Icon;
  promotionDetailsText: Scalars["String"]["output"];
  promotions: CruiseAmenitiesList;
  rateInfo: CruiseOrderSummaryRatesInfoOutput;
  selectedIcon: Icon;
  updateInfo?: Maybe<CruiseOrderSummaryRatesUpdateInfo>;
};

export interface CruiseOrderSummaryRatesInfoInput {
  offerToken: Scalars["String"]["input"];
  price: CruiseOrderSummaryRatesPriceInput;
  promotionDetails?: InputMaybe<CruiseOrderSummaryPromotionSideSheetInput>;
  promotions: Array<CruiseOrderSummaryRatesPromotionInfoInput>;
  updateInfo?: InputMaybe<CruiseOrderSummaryRatesUpdateInfoInput>;
}

export type CruiseOrderSummaryRatesInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesInfoOutput";
  offerToken: Scalars["String"]["output"];
  price: CruiseOrderSummaryRatesPriceOutput;
  promotionDetails?: Maybe<CruiseOrderSummaryPromotionSideSheetOutput>;
  promotions: Array<CruiseOrderSummaryRatesPromotionInfoOutput>;
  updateInfo?: Maybe<CruiseOrderSummaryRatesUpdateInfoOutput>;
};

export interface CruiseOrderSummaryRatesInput {
  adultCount: Scalars["Int"]["input"];
  cabinExperience: Scalars["String"]["input"];
  cabinType: Scalars["String"]["input"];
  checkoutRedirectInput?: InputMaybe<CheckoutRedirectInput>;
  childAges?: InputMaybe<Array<CruiseChildInput>>;
  childCount: Scalars["Int"]["input"];
  groupedCabins?: InputMaybe<Array<CabinInput>>;
  leadInPrice?: InputMaybe<Scalars["Float"]["input"]>;
  sailingToken: Scalars["String"]["input"];
}

export type CruiseOrderSummaryRatesOverviewAmenitiesSidesheet = {
  __typename?: "CruiseOrderSummaryRatesOverviewAmenitiesSidesheet";
  closeButtonAnalytics: CruiseSidesheetAnalytics;
  combinedAmenities?: Maybe<Array<CruiseAmenitiesList>>;
  navIconLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryRatesOverviewSection = {
  __typename?: "CruiseOrderSummaryRatesOverviewSection";
  a11yLabel?: Maybe<Scalars["String"]["output"]>;
  amenitiesSidesheet: CruiseOrderSummaryRatesOverviewAmenitiesSidesheet;
  deckRange?: Maybe<CruiseInfo>;
  disclaimer?: Maybe<CruiseInfo>;
  galleryCarousel?: Maybe<CruiseGallery>;
  occupancyRange?: Maybe<CruiseInfo>;
  price?: Maybe<CruisePrice>;
  squareFootageRange?: Maybe<CruiseInfo>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  viewAmenitiesButtonAnalytics: CruiseSidesheetAnalytics;
  viewAmenitiesIcon: Icon;
  viewAmenitiesString: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryRatesPageDataInput {
  ratesInfo: Array<CruiseOrderSummaryRatesInfoInput>;
  selectedRateIndex: Scalars["Int"]["input"];
}

export type CruiseOrderSummaryRatesPriceChangeMessage = {
  __typename?: "CruiseOrderSummaryRatesPriceChangeMessage";
  icon: Icon;
  message: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryRatesPriceChangeMessageInput {
  price: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}

export type CruiseOrderSummaryRatesPriceChangeMessageOutput = {
  __typename?: "CruiseOrderSummaryRatesPriceChangeMessageOutput";
  price: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryRatesPriceInput {
  baseFare?: InputMaybe<CurrencyTypeInput>;
  cabinCategoryTitle: Scalars["String"]["input"];
  cruiseLineCode?: InputMaybe<Scalars["String"]["input"]>;
  initialPrice?: InputMaybe<CurrencyAmountTypeInput>;
  isSoldOut: Scalars["Boolean"]["input"];
  memberPricing: MemberPricingInput;
  price?: InputMaybe<CurrencyAmountTypeInput>;
  rateCategory: RateCategoryInput;
  strikeoutPrice?: InputMaybe<CurrencyAmountTypeInput>;
}

export type CruiseOrderSummaryRatesPriceOutput = {
  __typename?: "CruiseOrderSummaryRatesPriceOutput";
  baseFare?: Maybe<CurrencyTypeOutput>;
  cabinCategoryTitle: Scalars["String"]["output"];
  cruiseLineCode?: Maybe<Scalars["String"]["output"]>;
  initialPrice?: Maybe<CurrencyAmountTypeOutput>;
  isSoldOut: Scalars["Boolean"]["output"];
  memberPricing: MemberPricing;
  price?: Maybe<CurrencyAmountTypeOutput>;
  rateCategory: RateCategory;
  strikeoutPrice?: Maybe<CurrencyAmountTypeOutput>;
};

export interface CruiseOrderSummaryRatesPromotionIconInfoInput {
  description: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
}

export type CruiseOrderSummaryRatesPromotionIconInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesPromotionIconInfoOutput";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryRatesPromotionInfoInput {
  fullName: Scalars["String"]["input"];
  icon: CruiseOrderSummaryRatesPromotionIconInfoInput;
}

export type CruiseOrderSummaryRatesPromotionInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesPromotionInfoOutput";
  fullName: Scalars["String"]["output"];
  icon: Icon;
};

export type CruiseOrderSummaryRatesResults = {
  __typename?: "CruiseOrderSummaryRatesResults";
  cabinTypeUnavailableMessaging?: Maybe<EgdsStandardMessagingCard>;
  crumbs?: Maybe<Array<CruiseProgressIndicatorStep>>;
  deckPlan?: Maybe<CruiseOrderSummaryDeckplan>;
  details?: Maybe<CruiseSailingDetails>;
  dialogStaleSession?: Maybe<CruiseDialogStaleSession>;
  errorMessaging?: Maybe<CruiseRatesQueryErrorMessaging>;
  expertAdviceMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  groupedCabinTabs?: Maybe<EgdsBasicTabs>;
  groupedRates?: Maybe<Array<GroupedRate>>;
  offlineRates?: Maybe<CruiseOrderSummaryOfflineRate>;
  offlineSailingMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  overview?: Maybe<CruiseOrderSummaryRatesOverviewSection>;
  priceChangeMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  priceSummary?: Maybe<CruiseOrderSummaryPriceSummary>;
  rates?: Maybe<CruiseOrderSummaryRatesSection>;
  roomSoldOutMessaging?: Maybe<RoomSoldOutMessaging>;
};

export type CruiseOrderSummaryRatesSection = {
  __typename?: "CruiseOrderSummaryRatesSection";
  a11yTitle: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  rateAnalytics: ClientSideAnalytics;
  rates: Array<CruiseOrderSummaryRatesInfo>;
  title: Scalars["String"]["output"];
};

export interface CruiseOrderSummaryRatesUiLinkActionInfoInput {
  url: Scalars["String"]["input"];
}

export type CruiseOrderSummaryRatesUiLinkActionInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesUILinkActionInfoOutput";
  url: Scalars["String"]["output"];
};

export type CruiseOrderSummaryRatesUpdateDialog = {
  __typename?: "CruiseOrderSummaryRatesUpdateDialog";
  closeDialogText: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseOrderSummaryRatesUpdateInfo = {
  __typename?: "CruiseOrderSummaryRatesUpdateInfo";
  dialog?: Maybe<CruiseOrderSummaryRatesUpdateDialog>;
  priceChangeMessage?: Maybe<CruiseOrderSummaryRatesPriceChangeMessage>;
};

export interface CruiseOrderSummaryRatesUpdateInfoInput {
  priceChangeMessage?: InputMaybe<CruiseOrderSummaryRatesPriceChangeMessageInput>;
}

export type CruiseOrderSummaryRatesUpdateInfoOutput = {
  __typename?: "CruiseOrderSummaryRatesUpdateInfoOutput";
  priceChangeMessage?: Maybe<CruiseOrderSummaryRatesPriceChangeMessageOutput>;
};

export type CruiseOrderSummaryRoomUnavailableErrorMessage = {
  __typename?: "CruiseOrderSummaryRoomUnavailableErrorMessage";
  icon: Icon;
  message: Scalars["String"]["output"];
};

export type CruiseOrderSummaryRoomUnavailableViewResults = {
  __typename?: "CruiseOrderSummaryRoomUnavailableViewResults";
  cabinCategorySection: CruiseCabinCategorySection;
  cabinExperience: CruiseCabinExperience;
  errorMessage: CruiseOrderSummaryRoomUnavailableErrorMessage;
  messagingCard: CruiseMessagingCard;
  sailingUnavailable?: Maybe<CruiseSearchAgainSection>;
  searchAgain: CruiseSearchAgainSection;
};

export type CruisePhoneNumber = {
  __typename?: "CruisePhoneNumber";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  formatted?: Maybe<Scalars["String"]["output"]>;
  number?: Maybe<Scalars["String"]["output"]>;
};

export type CruisePopover = {
  __typename?: "CruisePopover";
  accessibility: Scalars["String"]["output"];
  close: Icon;
  icon: Icon;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  titleIcon: Icon;
};

export type CruisePrice = {
  __typename?: "CruisePrice";
  allInPriceText?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<CruiseIcon>;
  badges?: Maybe<Array<CruiseIcon>>;
  dialog?: Maybe<CruiseDialog>;
  icon?: Maybe<CruiseIcon>;
  priceFormatted?: Maybe<Scalars["String"]["output"]>;
  priceText?: Maybe<Scalars["String"]["output"]>;
  secondaryText?: Maybe<Scalars["String"]["output"]>;
  strikeoutPriceFormatted?: Maybe<Scalars["String"]["output"]>;
  strikeoutPriceText?: Maybe<Scalars["String"]["output"]>;
  supportingText?: Maybe<SupportingText>;
  /** @deprecated This field is deprecated. Use supportingText.text instead */
  text?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseProductGallery = {
  __typename?: "CruiseProductGallery";
  heading?: Maybe<Scalars["String"]["output"]>;
  nextButton?: Maybe<Scalars["String"]["output"]>;
  prevButton?: Maybe<Scalars["String"]["output"]>;
  thumbnailLeftRightClickAnalytics?: Maybe<UiAction>;
};

export type CruiseProgressChangeLink = {
  __typename?: "CruiseProgressChangeLink";
  a11yLabel: Scalars["String"]["output"];
  action: UiLinkAction;
  label: Scalars["String"]["output"];
};

export type CruiseProgressIndicatorStep = {
  __typename?: "CruiseProgressIndicatorStep";
  a11yLabel: Scalars["String"]["output"];
  changeSelection?: Maybe<CruiseProgressChangeLink>;
  currentStep: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
};

export type CruisePromotionsTermsAndConditionsResult = {
  __typename?: "CruisePromotionsTermsAndConditionsResult";
  termsAndConditions: CruiseAmenitiesSection;
};

export type CruiseRatesQueryErrorDialog = {
  __typename?: "CruiseRatesQueryErrorDialog";
  buttons: Array<EgdsButton>;
  description: Scalars["String"]["output"];
  searchAgainUrl: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseRatesQueryErrorMessaging = {
  __typename?: "CruiseRatesQueryErrorMessaging";
  dialog: CruiseRatesQueryErrorDialog;
};

export type CruiseRequestConsultation = {
  __typename?: "CruiseRequestConsultation";
  clientSideAnalytics: ClientSideAnalytics;
  link: Scalars["String"]["output"];
  openInNewTab: Scalars["Boolean"]["output"];
  text: Scalars["String"]["output"];
};

export interface CruiseRequestContextInput {
  cruiseFeatures?: InputMaybe<Array<CruiseFeature>>;
  defaultCurrency?: InputMaybe<Scalars["String"]["input"]>;
  franchiseModelKey?: InputMaybe<Scalars["String"]["input"]>;
  isAgent?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type CruiseRoomRateSelection = {
  __typename?: "CruiseRoomRateSelection";
  cabinExperienceDeepLinkValue: Scalars["String"]["output"];
  changeButtonAnalytics: ClientSideAnalytics;
  changeButtonText: Scalars["String"]["output"];
  crumbs: Array<CruiseProgressIndicatorStep>;
  /** @deprecated Use offlineMessagingCardStandard */
  offlineMessagingCard?: Maybe<CruiseMessagingCard>;
  offlineMessagingCardStandard?: Maybe<EgdsStandardMessagingCard>;
  termsAndConditions: CruiseSubsection;
  toolbar: CruiseRoomRateSelectionToolbar;
};

export type CruiseRoomRateSelectionToolbar = {
  __typename?: "CruiseRoomRateSelectionToolbar";
  closeButtonAnalytics: CruiseRoomRateSelectionToolbarAnalytics;
  navIconLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CruiseRoomRateSelectionToolbarAnalytics = {
  __typename?: "CruiseRoomRateSelectionToolbarAnalytics";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export interface CruiseSailingCabinCategoriesInput {
  adultCount: Scalars["Int"]["input"];
  cabinExperience: Scalars["String"]["input"];
  childAges?: InputMaybe<Array<CruiseChildInput>>;
  childCount: Scalars["Int"]["input"];
}

export type CruiseSailingDetails = {
  __typename?: "CruiseSailingDetails";
  a11yDescription?: Maybe<Scalars["String"]["output"]>;
  a11yLabel?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  iconMedia?: Maybe<CruiseMediaItem>;
  infoSitePath?: Maybe<Scalars["String"]["output"]>;
  pageTitle?: Maybe<Scalars["String"]["output"]>;
  priceSummary: CruisePrice;
  primarySubsection?: Maybe<CruiseSubsection>;
  primarySubtitle?: Maybe<Scalars["String"]["output"]>;
  secondarySubsection?: Maybe<CruiseSubsection>;
  secondarySubtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface CruiseSailingDetailsCriteriaInput {
  primary: PrimaryCruiseSailingDetailsCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type CruiseSailingSearchLink = {
  __typename?: "CruiseSailingSearchLink";
  defaultLink: UiLinkAction;
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type CruiseSearchAgainSection = {
  __typename?: "CruiseSearchAgainSection";
  description: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  searchAgainLink: UiLinkAction;
  searchAgainText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface CruiseSearchCriteriaInput {
  primary: PrimaryCruiseCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type CruiseSearchFilters = {
  __typename?: "CruiseSearchFilters";
  filtersDialog: CruiseSearchFiltersDialog;
  portTypeaheadAdapterDetails: CruiseTypeaheadAdapterDetails;
  /** @deprecated Use universalSortAndFilter.clearAll.primary instead */
  removeAllFiltersText: Scalars["String"]["output"];
  shipTypeaheadAdapterDetails: CruiseTypeaheadAdapterDetails;
  universalSortAndFilter: ShoppingSortAndFilters;
};

export type CruiseSearchFiltersDialog = {
  __typename?: "CruiseSearchFiltersDialog";
  appliedFilterCount?: Maybe<Scalars["String"]["output"]>;
  clearButtonText: Scalars["String"]["output"];
  closeIconLabel: Scalars["String"]["output"];
  doneButtonText: Scalars["String"]["output"];
  filterButtonIcon: Icon;
  filterCountTheme: CruiseBadgeTheme;
  primary: Scalars["String"]["output"];
};

export interface CruiseSearchFiltersInput {
  cruiseLines?: InputMaybe<Scalars["String"]["input"]>;
  departureLocations?: InputMaybe<Scalars["String"]["input"]>;
  destinationNames?: InputMaybe<Scalars["String"]["input"]>;
  earliestDeptDate?: InputMaybe<Scalars["String"]["input"]>;
  filterType: CruiseFilterType;
  latestDeptDate?: InputMaybe<Scalars["String"]["input"]>;
  maxLength?: InputMaybe<Scalars["String"]["input"]>;
  memberOnlyOffer?: InputMaybe<Scalars["Boolean"]["input"]>;
  minLength?: InputMaybe<Scalars["String"]["input"]>;
  promotionCode?: InputMaybe<Scalars["String"]["input"]>;
  sailingVarieties?: InputMaybe<Scalars["String"]["input"]>;
  ships?: InputMaybe<Scalars["String"]["input"]>;
  superCategoryCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type CruiseSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "CruiseSearchForm";
    actionURL: Scalars["String"]["output"];
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    expertAdviceInfo?: Maybe<Scalars["String"]["output"]>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    typeaheadAdapterDetails?: Maybe<TypeaheadAdapterDetails>;
  };

export type CruiseSearchNavigation = {
  __typename?: "CruiseSearchNavigation";
  button: UiSecondaryButton;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface CruiseSearchOptionsInput {
  adultCount: Scalars["Int"]["input"];
  childCount: Scalars["Int"]["input"];
  limit: Scalars["Int"]["input"];
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
}

export type CruiseSearchResults = {
  __typename?: "CruiseSearchResults";
  filters: CruiseSearchFilters;
  sailingSearch: SailingSearchResults;
};

export type CruiseSearchResultsSailingSearchArgs = {
  cruiseContext: CruiseRequestContextInput;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  sailingToken?: InputMaybe<Scalars["String"]["input"]>;
  signInLinkAppended?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CruiseSelect = {
  __typename?: "CruiseSelect";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<CruiseSelectOptions>;
};

export type CruiseSelectOptions = {
  __typename?: "CruiseSelectOptions";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  value: Scalars["String"]["output"];
};

export type CruiseSidesheetAnalytics = {
  __typename?: "CruiseSidesheetAnalytics";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type CruiseSubsection = {
  __typename?: "CruiseSubsection";
  headingLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use headingLabel instead */
  label: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  secondaryMessage?: Maybe<Scalars["String"]["output"]>;
};

export type CruiseTypeaheadAdapterDetails = {
  __typename?: "CruiseTypeaheadAdapterDetails";
  adapterRequest?: Maybe<CruiseTypeaheadAdapterRequest>;
  domainId: Scalars["String"]["output"];
  essCategories?: Maybe<Array<EssCategory>>;
};

export type CruiseTypeaheadAdapterRequest = {
  __typename?: "CruiseTypeaheadAdapterRequest";
  filters?: Maybe<CruiseTypeaheadFilterContext>;
  includeMetadata?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type CruiseTypeaheadFilterContext = {
  __typename?: "CruiseTypeaheadFilterContext";
  include?: Maybe<Array<FilterItem>>;
};

export type CruiseUserInterest = {
  __typename?: "CruiseUserInterest";
  text: Scalars["String"]["output"];
};

export type CruiseVideoButtonText = {
  __typename?: "CruiseVideoButtonText";
  pauseButton: Scalars["String"]["output"];
  playButton: Scalars["String"]["output"];
};

export type CsrfProtection = {
  __typename?: "CsrfProtection";
  csrfToken: Scalars["String"]["output"];
  isInitialized: Scalars["Boolean"]["output"];
};

export type Currency = {
  __typename?: "Currency";
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
};

export interface CurrencyAmountTypeInput {
  amount?: InputMaybe<Scalars["Float"]["input"]>;
  currencyCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type CurrencyAmountTypeOutput = {
  __typename?: "CurrencyAmountTypeOutput";
  amount?: Maybe<Scalars["Float"]["output"]>;
  currencyCode?: Maybe<Scalars["String"]["output"]>;
};

export type CurrencySelector = LocalePreferenceSelector & {
  __typename?: "CurrencySelector";
  disabled: Scalars["Boolean"]["output"];
  heading: Scalars["String"]["output"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<CurrencySelectorOption>;
  queryParameterName: Scalars["String"]["output"];
  trackingEvent: Scalars["String"]["output"];
};

export type CurrencySelectorOption = LocaleSelectorOption & {
  __typename?: "CurrencySelectorOption";
  name: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export interface CurrencyTypeInput {
  defaultCurrencyAmount?: InputMaybe<CurrencyAmountTypeInput>;
  supplyCurrencyAmount?: InputMaybe<CurrencyAmountTypeInput>;
}

export type CurrencyTypeOutput = {
  __typename?: "CurrencyTypeOutput";
  defaultCurrencyAmount?: Maybe<CurrencyAmountTypeOutput>;
  supplyCurrencyAmount?: Maybe<CurrencyAmountTypeOutput>;
};

export type CurrentBreadcrumb = BreadcrumbItem &
  TextBreadcrumb & {
    __typename?: "CurrentBreadcrumb";
    text: Scalars["String"]["output"];
    uiLinkAction?: Maybe<UiLinkAction>;
  };

export type CurrentTierCredits = {
  __typename?: "CurrentTierCredits";
  amount?: Maybe<LoyaltyAmount>;
  hotelNights?: Maybe<Scalars["Int"]["output"]>;
  points?: Maybe<Scalars["Float"]["output"]>;
  tierName?: Maybe<Scalars["String"]["output"]>;
  tripItems?: Maybe<Scalars["Int"]["output"]>;
};

export type CurrentTripIntent = {
  __typename?: "CurrentTripIntent";
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  timeframe?: Maybe<TripIntentWhen>;
  where?: Maybe<TripIntentWhere>;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  who?: Maybe<TripIntentWho>;
  why: TripIntentWhy;
};

export type CustomSocialShareSheet = {
  __typename?: "CustomSocialShareSheet";
  card?: Maybe<EgdsImageCard>;
  revealActions?: Maybe<ClientSideAnalytics>;
  socialMediaButtons?: Maybe<Array<SocialMediaCta>>;
  toolbar?: Maybe<EgdsToolbar>;
};

export type CustomUiComposableElement = UiSimpleText | UiTextList;

export type Customer = {
  __typename?: "Customer";
  currentTripIntent: CurrentTripIntent;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  findInboxNotificationsByUser: Array<CustomerNotification>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  findInlineNotificationByUser: CustomerNotification;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  findNotificationById: CustomerNotification;
  lodgingRebound?: Maybe<LodgingRebound>;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  mutateHistory?: Maybe<HistoryMutationResponse>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  notification: CustomerNotification;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  searchHistory?: Maybe<Array<History>>;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  searchHistoryGroups?: Maybe<Array<HistoryGroup>>;
};

export type CustomerFindInboxNotificationsByUserArgs = {
  expUserId: Scalars["String"]["input"];
};

export type CustomerFindInlineNotificationByUserArgs = {
  dismissed: Scalars["Boolean"]["input"];
  expUserId: Scalars["Int"]["input"];
  read: Scalars["Boolean"]["input"];
  seen: Scalars["Boolean"]["input"];
};

export type CustomerFindNotificationByIdArgs = {
  notificationId: Scalars["String"]["input"];
};

export type CustomerLodgingReboundArgs = {
  regionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerMutateHistoryArgs = {
  hide?: InputMaybe<Scalars["Boolean"]["input"]>;
  historyTypes?: InputMaybe<Array<HistoryType>>;
  interactionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerNotificationArgs = {
  funnelLocation: FunnelLocation;
  lineOfBusiness: ExpLineOfBusiness;
  notificationLocation: NotificationLocation;
  optionalContext?: InputMaybe<CustomerNotificationOptionalContextInput>;
};

export type CustomerSearchHistoryArgs = {
  filterCriteria?: InputMaybe<SearchHistoryFilterCriteriaInput>;
  includeBookedSearches?: InputMaybe<Scalars["Boolean"]["input"]>;
  numberOfResults?: InputMaybe<Scalars["Int"]["input"]>;
  types?: InputMaybe<Array<HistoryType>>;
};

export type CustomerSearchHistoryGroupsArgs = {
  groupingCriteria?: InputMaybe<SearchHistoryGroupingCriteriaInput>;
  includeBookedSearches?: InputMaybe<Scalars["Boolean"]["input"]>;
  types?: InputMaybe<Array<HistoryType>>;
};

export type CustomerAddress = {
  __typename?: "CustomerAddress";
  city?: Maybe<Scalars["String"]["output"]>;
  countryAlpha3Code?: Maybe<Scalars["String"]["output"]>;
  firstLine?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  province?: Maybe<Scalars["String"]["output"]>;
  secondLine?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerContactInfo = {
  __typename?: "CustomerContactInfo";
  customerAddress?: Maybe<CustomerAddress>;
  emergencyContacts?: Maybe<CustomerEmergencyContacts>;
  phoneNumbers?: Maybe<CustomerPhoneNumbers>;
};

export type CustomerEmergencyContact = {
  __typename?: "CustomerEmergencyContact";
  emergencyContactName?: Maybe<CustomerName>;
  phoneNumber?: Maybe<CustomerPhoneNumber>;
};

export type CustomerEmergencyContacts = {
  __typename?: "CustomerEmergencyContacts";
  emergencyContact?: Maybe<Array<CustomerEmergencyContact>>;
};

export type CustomerGender = "FEMALE" | "MALE" | "UNKNOWN";

export type CustomerName = {
  __typename?: "CustomerName";
  first: Scalars["String"]["output"];
  last: Scalars["String"]["output"];
  middle?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerNotification = {
  __typename?: "CustomerNotification";
  attributes?: Maybe<Array<CustomerNotificationAttribute>>;
  backgroundId?: Maybe<Scalars["String"]["output"]>;
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<CustomerNotificationPhrase>>;
  containerLink?: Maybe<CustomerNotificationContainerLink>;
  dismiss?: Maybe<CustomerNotificationDismiss>;
  displayEndDateTime?: Maybe<DateTime>;
  displayStartDateTime?: Maybe<DateTime>;
  form?: Maybe<CustomerNotificationForm>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<CustomerNotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  qrCode?: Maybe<Image>;
  revealActions?: Maybe<Array<CustomerNotificationAction>>;
  timeStamp?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<CustomerNotificationPhrase>;
  type: CustomerNotificationType;
};

export interface CustomerNotificationAccessibilityField {
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
}

export type CustomerNotificationAction =
  | CustomerNotificationAnalytics
  | CustomerNotificationCookie
  | CustomerNotificationMarketing
  | CustomerNotificationModifiableAttributes
  | CustomerNotificationSpecifications;

export type CustomerNotificationAnalytics = {
  __typename?: "CustomerNotificationAnalytics";
  description?: Maybe<Scalars["String"]["output"]>;
  referrerId: Scalars["String"]["output"];
};

export type CustomerNotificationAttribute = {
  __typename?: "CustomerNotificationAttribute";
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerNotificationContainerLink = CustomerNotificationLink & {
  __typename?: "CustomerNotificationContainerLink";
  actions?: Maybe<Array<CustomerNotificationAction>>;
  id?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<CustomerNotificationLinkTarget>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type CustomerNotificationCookie = {
  __typename?: "CustomerNotificationCookie";
  expires: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CustomerNotificationDismiss = {
  __typename?: "CustomerNotificationDismiss";
  actions?: Maybe<Array<CustomerNotificationAction>>;
  icon: Icon;
};

export type CustomerNotificationForm = {
  __typename?: "CustomerNotificationForm";
  feedbackMessages?: Maybe<Array<CustomerNotificationMessage>>;
  sections: Array<CustomerNotificationFormSection>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerNotificationFormButtonType =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export interface CustomerNotificationFormField {
  label: Scalars["String"]["output"];
}

export type CustomerNotificationFormSection = {
  __typename?: "CustomerNotificationFormSection";
  fields: Array<CustomerNotificationFormField>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface CustomerNotificationLink {
  actions?: Maybe<Array<CustomerNotificationAction>>;
  id?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<CustomerNotificationLinkTarget>;
  url?: Maybe<Scalars["URL"]["output"]>;
}

export type CustomerNotificationLinkTarget = "NEW_TAB" | "SAME_TAB";

export type CustomerNotificationLinkType =
  | "ANCHOR"
  | "APP_DOWNLOAD_LINK"
  | "BUTTON"
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export type CustomerNotificationMarketing = {
  __typename?: "CustomerNotificationMarketing";
  marketingCode: Scalars["String"]["output"];
  marketingCodeDetail: Scalars["String"]["output"];
};

export type CustomerNotificationMessage = {
  __typename?: "CustomerNotificationMessage";
  displayType: CustomerNotificationMessageDisplayType;
  level: CustomerNotificationMessageLevel;
  name: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CustomerNotificationMessageDisplayType = "FORM" | "PAGE";

export type CustomerNotificationMessageLevel = "ERROR" | "SUCCESS" | "WARNING";

export type CustomerNotificationMessageType = "INVALID_INPUT" | "SERVICE_ERROR" | "SUCCESS";

export type CustomerNotificationModifiableAttributes = {
  __typename?: "CustomerNotificationModifiableAttributes";
  dismiss?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  read?: Maybe<Scalars["Boolean"]["output"]>;
  seen?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated This field is now deprecated. */
  star?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface CustomerNotificationModifiableAttributesInput {
  dismiss?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  read?: InputMaybe<Scalars["Boolean"]["input"]>;
  seen?: InputMaybe<Scalars["Boolean"]["input"]>;
  star?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface CustomerNotificationOptionalContextInput {
  itineraryNumber?: InputMaybe<Scalars["String"]["input"]>;
  journeyCriterias?: InputMaybe<Array<CustomerNotificationOptionalContextJourneyCriteriaInput>>;
  referer?: InputMaybe<Scalars["URL"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  regionIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripFolderId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface CustomerNotificationOptionalContextJourneyCriteriaInput {
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<CustomerNotificationOptionalContextJourneyCriteriaLocationInput>;
  origin?: InputMaybe<CustomerNotificationOptionalContextJourneyCriteriaLocationInput>;
}

export interface CustomerNotificationOptionalContextJourneyCriteriaLocationInput {
  airportTLA?: InputMaybe<Scalars["String"]["input"]>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type CustomerNotificationPhrase = CustomerNotificationAccessibilityField & {
  __typename?: "CustomerNotificationPhrase";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  completeText: Scalars["String"]["output"];
  items: Array<CustomerNotificationPhrasePart>;
};

export type CustomerNotificationPhraseLinkNode = CustomerNotificationLink &
  CustomerNotificationPhrasePart & {
    __typename?: "CustomerNotificationPhraseLinkNode";
    actions?: Maybe<Array<CustomerNotificationAction>>;
    icon?: Maybe<Icon>;
    id?: Maybe<Scalars["String"]["output"]>;
    linkStyle?: Maybe<CustomerNotificationPhraseLinkStyle>;
    styles: Array<CustomerNotificationPhrasePartStyle>;
    target?: Maybe<CustomerNotificationLinkTarget>;
    text: Scalars["String"]["output"];
    type: CustomerNotificationLinkType;
    url?: Maybe<Scalars["URL"]["output"]>;
  };

export type CustomerNotificationPhraseLinkStyle =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export interface CustomerNotificationPhrasePart {
  styles: Array<CustomerNotificationPhrasePartStyle>;
  text: Scalars["String"]["output"];
}

export type CustomerNotificationPhrasePartStyle = "EMPHASIS" | "IMPORTANT" | "STANDARD";

export type CustomerNotificationPhraseTextNode = CustomerNotificationPhrasePart & {
  __typename?: "CustomerNotificationPhraseTextNode";
  styles: Array<CustomerNotificationPhrasePartStyle>;
  text: Scalars["String"]["output"];
};

export type CustomerNotificationSelectionField = CustomerNotificationFormField & {
  __typename?: "CustomerNotificationSelectionField";
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  options?: Maybe<Array<CustomerNotificationSelectionOption>>;
};

export type CustomerNotificationSelectionOption = {
  __typename?: "CustomerNotificationSelectionOption";
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  shortLabel?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CustomerNotificationSpecifications = {
  __typename?: "CustomerNotificationSpecifications";
  dismiss?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  read?: Maybe<Scalars["Boolean"]["output"]>;
  seen?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CustomerNotificationSubmitButton = CustomerNotificationFormField & {
  __typename?: "CustomerNotificationSubmitButton";
  actions?: Maybe<Array<CustomerNotificationAction>>;
  buttonType?: Maybe<CustomerNotificationFormButtonType>;
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type CustomerNotificationTextInputField = CustomerNotificationFormField & {
  __typename?: "CustomerNotificationTextInputField";
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  placeholder: Scalars["String"]["output"];
};

export type CustomerNotificationTextToDownloadAppResponse = {
  __typename?: "CustomerNotificationTextToDownloadAppResponse";
  invalidFields?: Maybe<Array<Scalars["String"]["output"]>>;
  messageBody?: Maybe<Scalars["String"]["output"]>;
  messageType: CustomerNotificationMessageType;
};

export type CustomerNotificationType =
  | "APP_DOWNLOAD"
  | "APP_HERO"
  | "CITI_COBRAND"
  | "CITI_COBRAND_VERTICAL"
  | "CLEANLINESS"
  | "GDPR"
  | "GENERIC"
  | "HEADER"
  | "INBOX"
  | "MAD_MARQUEE"
  | "NONE"
  | "OSANO"
  | "TAKEOVER"
  | "TRAVEL_ADVISORY";

export interface CustomerNotificationUserInput {
  inputs: Array<CustomerNotificationUserInputElementInput>;
}

export interface CustomerNotificationUserInputElementInput {
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type CustomerPersonalInfo = {
  __typename?: "CustomerPersonalInfo";
  dateOfBirth?: Maybe<Date>;
  gender?: Maybe<CustomerGender>;
  name?: Maybe<CustomerName>;
};

export type CustomerPhoneNumber = {
  __typename?: "CustomerPhoneNumber";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
};

export type CustomerPhoneNumbers = {
  __typename?: "CustomerPhoneNumbers";
  alternates?: Maybe<Array<CustomerPhoneNumber>>;
  primary?: Maybe<CustomerPhoneNumber>;
};

export type CustomerProfile = {
  __typename?: "CustomerProfile";
  contactInfo?: Maybe<CustomerContactInfo>;
  personalInfo?: Maybe<CustomerPersonalInfo>;
};

export type DamageLiability = "COF" | "RDD";

export type DashboardType = "DEFERRED_PAYMENT" | "PERFORMANCE";

export type DataPoint = {
  __typename?: "DataPoint";
  xAxis?: Maybe<Scalars["String"]["output"]>;
  yAxis?: Maybe<MetricFormat>;
};

export type DataSourceType = "CART" | "CHECKOUT" | "ENTITY" | "OFFER" | "ORDER";

export type DataTable = {
  __typename?: "DataTable";
  actions?: Maybe<DataTableActions>;
  body: DataTableBody;
  filtersSection?: Maybe<DataTableFiltersSection>;
};

export interface DataTableAction {
  actionPayload?: Maybe<DataTableActionPayload>;
  actionType?: Maybe<DataTableActionType>;
  identifier: Scalars["String"]["output"];
}

export type DataTableActionButton = DataTableAction & {
  __typename?: "DataTableActionButton";
  actionPayload?: Maybe<DataTableActionPayload>;
  actionType?: Maybe<DataTableActionType>;
  button: EgdsButton;
  identifier: Scalars["String"]["output"];
};

export type DataTableActionElement = DataTableActionButton | DataTableActionsMenu;

export type DataTableActionFilterInputPayload = {
  __typename?: "DataTableActionFilterInputPayload";
  id: Scalars["String"]["output"];
  values: Array<Scalars["String"]["output"]>;
};

export type DataTableActionInputPayload = {
  __typename?: "DataTableActionInputPayload";
  filters?: Maybe<Array<DataTableActionFilterInputPayload>>;
  pagination?: Maybe<DataTableActionPaginationInputPayload>;
};

export type DataTableActionMenuItem = DataTableAction & {
  __typename?: "DataTableActionMenuItem";
  actionPayload?: Maybe<DataTableActionPayload>;
  actionType?: Maybe<DataTableActionType>;
  identifier: Scalars["String"]["output"];
  item: EgdsMenuStandardItem;
};

export type DataTableActionPaginationInputPayload = {
  __typename?: "DataTableActionPaginationInputPayload";
  cursor?: Maybe<Scalars["Int"]["output"]>;
  pageSize?: Maybe<Scalars["Int"]["output"]>;
};

export type DataTableActionPayload = DataTableCustomActionPayload | DataTableSnapshotActionPayload;

export type DataTableActionPayloadElement = {
  __typename?: "DataTableActionPayloadElement";
  key: Scalars["String"]["output"];
  values: Array<Scalars["String"]["output"]>;
};

export type DataTableActionType = "REFRESH" | "RESET" | "SNAPSHOT";

export type DataTableActions = {
  __typename?: "DataTableActions";
  groups: Array<DataTableActionsGroup>;
};

export type DataTableActionsGroup = {
  __typename?: "DataTableActionsGroup";
  elements: Array<DataTableActionElement>;
  elementsPositioning?: Maybe<DataTableElementsPositioning>;
};

export type DataTableActionsMenu = {
  __typename?: "DataTableActionsMenu";
  icon?: Maybe<Icon>;
  items: Array<DataTableActionMenuItem>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type DataTableAlignItems = "CENTER" | "END" | "START" | "STRETCH";

export type DataTableBody = {
  __typename?: "DataTableBody";
  columns: Array<DataTableColumn>;
  resultsCount: Scalars["Int"]["output"];
  rows: Array<DataTableRow>;
};

export type DataTableCell = {
  __typename?: "DataTableCell";
  columnId: Scalars["String"]["output"];
  contents: Array<DataTableCellContent>;
  contentsPositioning?: Maybe<DataTableElementsPositioning>;
};

export type DataTableCellContent =
  | DataTableActionButton
  | DataTableActionsMenu
  | EgdsStandardLink
  | EgdsStylizedText
  | Icon
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type DataTableColumn = {
  __typename?: "DataTableColumn";
  columnId: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  sortable?: Maybe<Scalars["Boolean"]["output"]>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type DataTableCustomActionPayload = {
  __typename?: "DataTableCustomActionPayload";
  entries: Array<DataTableActionPayloadElement>;
};

export type DataTableElementsFlowDirection = "COLUMN" | "COLUMN_REVERSE" | "ROW" | "ROW_REVERSE";

export type DataTableElementsJustifyContent = "CENTER" | "END" | "SPACE_AROUND" | "SPACE_BETWEEN" | "START";

export type DataTableElementsPositioning = {
  __typename?: "DataTableElementsPositioning";
  alignItems?: Maybe<DataTableAlignItems>;
  direction?: Maybe<DataTableElementsFlowDirection>;
  justifyContent?: Maybe<DataTableElementsJustifyContent>;
  space?: Maybe<DataTableElementsSpace>;
  wrap?: Maybe<DataTableElementsWrap>;
};

export type DataTableElementsSpace = "FOUR" | "HALF" | "ONE" | "SIX" | "THREE" | "TWELVE" | "TWO";

export type DataTableElementsWrap = "NO_WRAP" | "WRAP" | "WRAP_REVERSE";

export type DataTableFilter = {
  __typename?: "DataTableFilter";
  component: DataTableFilterComponent;
  filterId: Scalars["String"]["output"];
};

export type DataTableFilterComponent = EgdsBasicSelect | EgdsCheckBoxGroup | EgdsDateRangePicker | EgdsTextInputField;

export type DataTableFilterGroup = {
  __typename?: "DataTableFilterGroup";
  filters: Array<DataTableFilter>;
  filtersPositioning?: Maybe<DataTableElementsPositioning>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DataTableFiltersSection = {
  __typename?: "DataTableFiltersSection";
  display?: Maybe<DataTableFiltersSectionDisplay>;
  groups: Array<DataTableFilterGroup>;
};

export type DataTableFiltersSectionDisplay = EgdsExpandoCard;

export type DataTableRow = {
  __typename?: "DataTableRow";
  cells: Array<DataTableCell>;
  rowId: Scalars["String"]["output"];
};

export type DataTableSnapshotActionPayload = {
  __typename?: "DataTableSnapshotActionPayload";
  actionInput: DataTableActionInputPayload;
};

export type Date = {
  __typename?: "Date";
  day: Scalars["Int"]["output"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  formatted: Scalars["String"]["output"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  formattedDate: Scalars["String"]["output"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  fullDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Migrate to using platform formatters and return a localized string */
  isoFormat: Scalars["String"]["output"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  longDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Migrate to using platform formatters and return a localized string */
  mediumDateFormat?: Maybe<Scalars["String"]["output"]>;
  month: Scalars["Int"]["output"];
  /** @deprecated Migrate to using platform formatters and return a localized string */
  shortDateFormat?: Maybe<Scalars["String"]["output"]>;
  year: Scalars["Int"]["output"];
};

export type DateFormattedArgs = {
  format: Scalars["String"]["input"];
};

export type DateFormattedDateArgs = {
  format: Scalars["String"]["input"];
};

export type DateInfo = {
  __typename?: "DateInfo";
  day: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export interface DateInput {
  day: Scalars["Int"]["input"];
  month: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
}

export type DateInputType = {
  __typename?: "DateInputType";
  day: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type DateMetadata = {
  __typename?: "DateMetadata";
  day: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type DateOption = {
  __typename?: "DateOption";
  accessibilityHeading: Scalars["String"]["output"];
  accessibilityMessage: Scalars["String"]["output"];
  numDaysDifference: Scalars["String"]["output"];
  selectAction: FlightsAction;
  tripDates: Scalars["String"]["output"];
  tripOD: Scalars["String"]["output"];
};

export type DatePeriod = {
  __typename?: "DatePeriod";
  bound?: Maybe<ClosedPeriodBounding>;
  length: Scalars["Int"]["output"];
  unit: DatePeriodUnit;
};

export interface DatePeriodInput {
  bound?: InputMaybe<ClosedPeriodBounding>;
  length: Scalars["Int"]["input"];
  unit: DatePeriodUnit;
}

export type DatePeriodUnit = "DAY" | "MONTH" | "WEEK" | "WEEKEND";

export type DateRange = {
  __typename?: "DateRange";
  /** @deprecated Use platform formatters and return localized strings, or use java time class for internal logic */
  duration: Duration;
  end: Date;
  start: Date;
};

export type DateRangeDurationArgs = {
  chronoUnit: ChronoUnit;
};

export interface DateRangeInput {
  end: DateInput;
  start: DateInput;
}

export type DateSelectorAnalyticsData = {
  __typename?: "DateSelectorAnalyticsData";
  eventName: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type DateTime = {
  __typename?: "DateTime";
  day: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  epochSeconds: Scalars["String"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  fullDateFormat?: Maybe<Scalars["String"]["output"]>;
  hour: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  longDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumTimeFormat?: Maybe<Scalars["String"]["output"]>;
  minute: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["String"]["output"];
  second: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  shortDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  shortTimeFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use ZonedDateTime if you want time zone information, replace with ZonedDateTime */
  timeZoneOffsetSeconds: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type DateTimeFormattedArgs = {
  format: Scalars["String"]["input"];
};

export interface DateTimeInput {
  day: Scalars["Int"]["input"];
  hour: Scalars["Int"]["input"];
  minute: Scalars["Int"]["input"];
  month: Scalars["Int"]["input"];
  second: Scalars["Int"]["input"];
  year: Scalars["Int"]["input"];
}

export type DateTimeRecommendationCard = CarRecommendationCard & {
  __typename?: "DateTimeRecommendationCard";
  action?: Maybe<CarAction>;
  icon?: Maybe<Icon>;
  subText?: Maybe<CarPhraseText>;
  text?: Maybe<CarsRichText>;
};

export type DateValidationResult = {
  __typename?: "DateValidationResult";
  dateAdjusted?: Maybe<Scalars["Boolean"]["output"]>;
  formattedEndDate?: Maybe<Scalars["String"]["output"]>;
  formattedStartDate?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use formattedEndDate instead */
  searchedEndDate?: Maybe<DateTime>;
  /** @deprecated Use formattedStartDate instead */
  searchedStartDate?: Maybe<DateTime>;
};

export type DateValue = {
  __typename?: "DateValue";
  id: Scalars["String"]["output"];
  value: Date;
};

export interface DateValueInput {
  id: Scalars["String"]["input"];
  value: DateInput;
}

export type DatelessCheckAvailability = {
  __typename?: "DatelessCheckAvailability";
  checkAvailabilityButton: UiPrimaryButton;
};

export type DealType = "AARP" | "POINTS_PROMOTION";

export interface DealsButton {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  onClickAnalytics: Array<ClientSideAnalytics>;
  text?: Maybe<Scalars["String"]["output"]>;
  type: ShoppingButtonType;
}

export type DealsCardContent = {
  __typename?: "DealsCardContent";
  graphic?: Maybe<UiGraphic>;
  headingSection?: Maybe<DealsHeadingSection>;
};

export type DealsClickstreamAnalytics = {
  __typename?: "DealsClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type DealsErrorResponse = ShoppingDealsErrorResponse;

export type DealsErrorType = "NO_DEALS_FOUND" | "SYSTEM_ERROR";

export type DealsFilterButton = DealsButton & {
  __typename?: "DealsFilterButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  onClickAnalytics: Array<ClientSideAnalytics>;
  searchCriteria: ShoppingSearchCriteria;
  text?: Maybe<Scalars["String"]["output"]>;
  type: ShoppingButtonType;
};

export type DealsFlightOfferCard = {
  __typename?: "DealsFlightOfferCard";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<EgdsBadge>;
  carrier: EgdsBasicSectionHeading;
  clickActionId: Scalars["String"]["output"];
  contents?: Maybe<Array<ShoppingProductContentSectionGroupContainer>>;
  dates: EgdsStylizedText;
  footer: EgdsStylizedText;
  onClickAnalytics: Array<ClientSideAnalytics>;
  priceSection: DealsPrice;
  shoppingJoinListContainer: ShoppingJoinListContainer;
  timeline: DealsTimeline;
};

export type DealsGroup = {
  __typename?: "DealsGroup";
  button?: Maybe<DealsButton>;
  /** @deprecated Use egcsAnalytics instead of displayAnalytics */
  displayAnalytics: Array<ClientSideImpressionEventAnalytics>;
  headingSection?: Maybe<DealsHeadingSection>;
  items: Array<DealsGroupItem>;
};

export type DealsGroupItem = DealsFlightOfferCard;

export type DealsGroupResponse = {
  __typename?: "DealsGroupResponse";
  dealResultsWithRefinements: DealsGroupsWithRefinements;
  dealsSearchInfo: DealsSearchInfo;
  staticInfo: DealsHeader;
};

export type DealsGroups = {
  __typename?: "DealsGroups";
  egcsAnalytics: DealsClickstreamAnalytics;
  groups: Array<DealsGroup>;
};

export type DealsGroupsWithRefinements = {
  __typename?: "DealsGroupsWithRefinements";
  dealsGroups?: Maybe<DealsGroups>;
  errorResponse?: Maybe<DealsErrorResponse>;
  refinements?: Maybe<ShoppingSortAndFilters>;
  searchCriteria?: Maybe<PrimaryDealsShoppingCriteria>;
};

export type DealsHeader = {
  __typename?: "DealsHeader";
  contentContainers?: Maybe<Array<ShoppingProductCardContentContainer>>;
  description?: Maybe<Array<EgdsParagraph>>;
  disclaimer?: Maybe<DealsLink>;
  heading?: Maybe<EgdsHeading>;
  shoppingJoinListContainer: ShoppingJoinListContainer;
};

export type DealsHeadingSection = {
  __typename?: "DealsHeadingSection";
  title: EgdsHeading;
};

export type DealsLink = {
  __typename?: "DealsLink";
  actionId: Scalars["String"]["output"];
  onClickAnalytics: Array<ClientSideAnalytics>;
  text: DealsText;
};

export type DealsLinkInfo = {
  __typename?: "DealsLinkInfo";
  contentContainers: Array<ShoppingProductContentSectionGroupContainer>;
  links: Array<DealsLink>;
  shoppingJoinListContainer: ShoppingJoinListContainer;
};

export type DealsMessagingActionCard = {
  __typename?: "DealsMessagingActionCard";
  button: ShoppingButton;
  content: DealsCardContent;
  displayAnalytics: Array<ClientSideAnalytics>;
  shoppingJoinListContainer: ShoppingJoinListContainer;
};

export type DealsMessagingCard = DealsMessagingActionCard;

export type DealsPrice = {
  __typename?: "DealsPrice";
  mainPrice: EgdsStylizedText;
  primaryText?: Maybe<EgdsStylizedText>;
  secondaryText?: Maybe<EgdsStylizedText>;
};

export type DealsSearchInfo = {
  __typename?: "DealsSearchInfo";
  errorResponse?: Maybe<DealsErrorResponse>;
  searchData?: Maybe<DealsLinkInfo>;
};

export interface DealsShoppingCriteriaInput {
  primarySearchCriteria: PrimaryDealsShoppingCriteriaInput;
  secondaryCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type DealsText = EgdsGraphicText;

export type DealsTimeline = FlightShoppingCardDurationTimeline;

export type DebugContext = {
  __typename?: "DebugContext";
  abacusOverrides: Array<Exposure>;
  alterMode?: Maybe<AlterMode>;
};

export interface DebugContextInput {
  abacusOverrides: Array<ExposureInput>;
  alterMode?: InputMaybe<AlterMode>;
}

export type DeeplinkType = "HSR" | "PDP";

export type DeferredPaymentReport = {
  __typename?: "DeferredPaymentReport";
  uri?: Maybe<HttpUri>;
};

export type DeprecatedCustomerContext = {
  __typename?: "DeprecatedCustomerContext";
  device: DeprecatedCustomerContextDevice;
  identity: DeprecatedCustomerContextIdentity;
  locale: Scalars["Locale"]["output"];
  siteId: Scalars["Int"]["output"];
};

export type DeprecatedCustomerContextDevice = {
  __typename?: "DeprecatedCustomerContextDevice";
  type: DeviceType;
};

export type DeprecatedCustomerContextIdentity = {
  __typename?: "DeprecatedCustomerContextIdentity";
  duaid: Scalars["UUID"]["output"];
};

export type DeprecatedMultiItemContext = {
  __typename?: "DeprecatedMultiItemContext";
  device: DeprecatedMultiItemContextDevice;
  identity: DeprecatedMultiItemContextIdentity;
  locale: Scalars["Locale"]["output"];
  siteId: Scalars["Int"]["output"];
};

export type DeprecatedMultiItemContextDevice = {
  __typename?: "DeprecatedMultiItemContextDevice";
  type: DeviceType;
};

export type DeprecatedMultiItemContextIdentity = {
  __typename?: "DeprecatedMultiItemContextIdentity";
  duaid: Scalars["UUID"]["output"];
};

export type DeprecatedPropertyContext = {
  __typename?: "DeprecatedPropertyContext";
  currency?: Maybe<Scalars["String"]["output"]>;
  debugContext?: Maybe<DeprecatedPropertyDebugContext>;
  device: DeprecatedPropertyMultiItemContextDevice;
  eapid?: Maybe<Scalars["Int"]["output"]>;
  identity: DeprecatedPropertyMultiItemContextIdentity;
  locale: Scalars["Locale"]["output"];
  siteId: Scalars["Int"]["output"];
  tpid?: Maybe<Scalars["Int"]["output"]>;
};

export type DeprecatedPropertyDebugContext = {
  __typename?: "DeprecatedPropertyDebugContext";
  abacusOverrides: Array<DeprecatedPropertyExposure>;
  alterMode?: Maybe<AlterMode>;
};

export type DeprecatedPropertyExposure = {
  __typename?: "DeprecatedPropertyExposure";
  bucket: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
};

export type DeprecatedPropertyMultiItemContext = {
  __typename?: "DeprecatedPropertyMultiItemContext";
  id: Scalars["String"]["output"];
  packageType: PackageType;
};

export type DeprecatedPropertyMultiItemContextDevice = {
  __typename?: "DeprecatedPropertyMultiItemContextDevice";
  type: DeviceType;
};

export type DeprecatedPropertyMultiItemContextIdentity = {
  __typename?: "DeprecatedPropertyMultiItemContextIdentity";
  duaid: Scalars["UUID"]["output"];
};

export type DeprecatedShoppingContext = {
  __typename?: "DeprecatedShoppingContext";
  multiItem: DeprecatedPropertyMultiItemContext;
};

export type DescriptionByPaymentSelection = {
  __typename?: "DescriptionByPaymentSelection";
  newCard: Array<Scalars["String"]["output"]>;
  savedCard: Array<Scalars["String"]["output"]>;
};

export type Destination = {
  __typename?: "Destination";
  coordinates?: Maybe<Coordinates>;
  excludedPropertyIds?: Maybe<Array<Scalars["String"]["output"]>>;
  mapBounds?: Maybe<Array<Coordinates>>;
  pinnedPropertyId?: Maybe<Scalars["String"]["output"]>;
  propertyIds?: Maybe<Array<Scalars["String"]["output"]>>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
};

export type DestinationAirport = LocAirport & {
  __typename?: "DestinationAirport";
  airportCode: Scalars["String"]["output"];
  airportName?: Maybe<Scalars["String"]["output"]>;
};

export type DestinationApiFeature = {
  __typename?: "DestinationApiFeature";
  feature: Scalars["String"]["output"];
};

export type DestinationBadges = {
  __typename?: "DestinationBadges";
  accessibility: Scalars["String"]["output"];
  badges: Array<EgdsStandardBadge>;
};

export type DestinationBestTimeToGoInformation = {
  __typename?: "DestinationBestTimeToGoInformation";
  expando: DestinationBestTimeToGoInformationExpando;
  impression: ClientSideAnalytics;
  tabs: Array<DestinationBestTimeToGoInformationTab>;
  viewAllPropertiesButton: UiSecondaryButton;
};

export type DestinationBestTimeToGoInformationExpando = {
  __typename?: "DestinationBestTimeToGoInformationExpando";
  content: EgdsExpandoCard;
  graphic?: Maybe<UiGraphic>;
};

export type DestinationBestTimeToGoInformationTab = {
  __typename?: "DestinationBestTimeToGoInformationTab";
  impression?: Maybe<ClientSideAnalytics>;
  information: DestinationInformation;
  title: EgdsText;
};

export type DestinationButtonMapTrigger = {
  __typename?: "DestinationButtonMapTrigger";
  action: Scalars["String"]["output"];
  button: EgdsButton;
  mapTitle?: Maybe<Scalars["String"]["output"]>;
};

export type DestinationCard = {
  __typename?: "DestinationCard";
  hotelName: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type DestinationCardUserAction = {
  __typename?: "DestinationCardUserAction";
  analytics: ClientSideAnalytics;
  icon: Icon;
  userActionItems: Array<DestinationCardUserActionItem>;
};

export type DestinationCardUserActionItem = {
  __typename?: "DestinationCardUserActionItem";
  actionDialog?: Maybe<UserActionDialog>;
  menuItem: UserActionMenuItem;
};

export type DestinationChatCarousel = {
  __typename?: "DestinationChatCarousel";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  carousel: EgdsCarousel;
  heading: EgdsHeading;
  subHeading: EgdsHeading;
};

export type DestinationCountry = {
  __typename?: "DestinationCountry";
  countryName?: Maybe<Scalars["String"]["output"]>;
  destinations: Array<DestinationAirport>;
};

export type DestinationExpertTips = {
  __typename?: "DestinationExpertTips";
  impression: ClientSideAnalytics;
  tips: Array<DestinationTips>;
  title: Scalars["String"]["output"];
};

export type DestinationFrequentlyAskedQuestionsResponse = {
  __typename?: "DestinationFrequentlyAskedQuestionsResponse";
  id: Scalars["ID"]["output"];
  questionsAndAnswers: Array<DestinationQuestionAndAnswer>;
  seeLess: UiSecondaryButton;
  seeMore: UiSecondaryButton;
  seoStructuredData?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type DestinationGallery = {
  __typename?: "DestinationGallery";
  carousel: EgdsCarousel;
  items: Array<DestinationGalleryItem>;
};

export type DestinationGalleryItem = DestinationImageGalleryItem;

export type DestinationHeading = {
  __typename?: "DestinationHeading";
  button?: Maybe<UiSecondaryButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  destinationBadges: DestinationBadges;
  /** @deprecated Please use expandoPeek */
  expando?: Maybe<EgdsExpandoLink>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  gallery: DestinationGallery;
  impression: ClientSideAnalytics;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export interface DestinationHeadingCriteriaInput {
  imageLimit?: InputMaybe<Scalars["Int"]["input"]>;
  placeId: Scalars["String"]["input"];
  source?: InputMaybe<ContentSource>;
}

export type DestinationHeadingTip = {
  __typename?: "DestinationHeadingTip";
  darkImage: Image;
  impression: ClientSideAnalytics;
  lightImage: Image;
  text: EgdsSpannableText;
};

export type DestinationHeroMedia = {
  __typename?: "DestinationHeroMedia";
  media: DestinationMedia;
  subTitle?: Maybe<EgdsPlainText>;
  title: EgdsText;
};

export interface DestinationHeroMediaCriteriaInput {
  affinityURNs?: InputMaybe<Array<Scalars["String"]["input"]>>;
  contentTopic?: InputMaybe<Scalars["String"]["input"]>;
  destinationId: Scalars["String"]["input"];
  egPlatformURL?: InputMaybe<Scalars["String"]["input"]>;
  lob?: InputMaybe<Scalars["String"]["input"]>;
}

export interface DestinationImageDimensionsInput {
  height: Scalars["Int"]["input"];
  width: Scalars["Int"]["input"];
}

export type DestinationImageGalleryItem = {
  __typename?: "DestinationImageGalleryItem";
  attribution?: Maybe<EgdsSpannableText>;
  image: Image;
  impression: ClientSideAnalytics;
};

export type DestinationInfoPreviewResponse = {
  __typename?: "DestinationInfoPreviewResponse";
  destinationTabs: Array<DestinationTab>;
  impression: ClientSideAnalytics;
  title: Scalars["String"]["output"];
};

export type DestinationInfoResponse = DestinationInfoSummary | DestinationInfoWithImage;

export type DestinationInfoSummary = {
  __typename?: "DestinationInfoSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UiLinkAction;
  cardType: WidgetCardType;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type DestinationInfoWithImage = {
  __typename?: "DestinationInfoWithImage";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UiLinkAction;
  cardType: WidgetCardType;
  description?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type DestinationInformation = DestinationInsightsMonthlyDetails | DestinationMonthlyHistoricalForecast;

export interface DestinationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  excludedPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  mapBounds?: InputMaybe<Array<CoordinatesInput>>;
  pinnedPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  propertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
}

export type DestinationInsights = {
  __typename?: "DestinationInsights";
  localGetaways: Array<InsightsDestination>;
  lodgingCost: Array<CostPercentilesByMonth>;
  popularMonthsToVisit: Array<Month>;
  travelGuide?: Maybe<TravelGuide>;
};

export type DestinationInsightsLocalGetawaysArgs = {
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<MonthId>;
};

export type DestinationInsightsLodgingCostArgs = {
  lengthOfStay?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<MonthId>;
  starRating?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DestinationInsightsMonthlyData = {
  __typename?: "DestinationInsightsMonthlyData";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  averagePrice: DestinationInsightsPrice;
  click: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  monthName: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
};

export type DestinationInsightsMonthlyDetails = {
  __typename?: "DestinationInsightsMonthlyDetails";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  monthlyData: Array<DestinationInsightsMonthlyData>;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  tooltip: DestinationInsightsTooltip;
};

export type DestinationInsightsPrice = {
  __typename?: "DestinationInsightsPrice";
  currency: Scalars["String"]["output"];
  priceAsNumber: Scalars["Int"]["output"];
  priceAsText: Scalars["String"]["output"];
};

export type DestinationInsightsPriceRange = {
  __typename?: "DestinationInsightsPriceRange";
  higherBound: DestinationInsightsPrice;
  lowerBound: DestinationInsightsPrice;
};

export type DestinationInsightsTooltip = {
  __typename?: "DestinationInsightsTooltip";
  button: EgdsButton;
  closeIcon: Icon;
  content: EgdsSpannableText;
  tooltipSheet: EgdsSheet;
};

export type DestinationListContentEventAttributes = {
  __typename?: "DestinationListContentEventAttributes";
  destinationGeoId: Scalars["String"]["output"];
};

export type DestinationMap = {
  __typename?: "DestinationMap";
  backButton: EgdsButton;
  egdsSheet: EgdsSheet;
  map: EgdsBasicMap;
};

export type DestinationMedia = {
  __typename?: "DestinationMedia";
  attribution?: Maybe<EgdsSpannableText>;
  media: Media;
};

export type DestinationMonthlyHistoricalForecast = {
  __typename?: "DestinationMonthlyHistoricalForecast";
  actions: Array<EgdsStandardMessagingCard>;
  description: Scalars["String"]["output"];
  monthsForecast: MonthsForecastsTabs;
};

export type DestinationNeighborhoodsConfigurationIdentifier = "DEFAULT" | "TOP_NEIGHBORHOODS";

export interface DestinationNeighborhoodsContextInput {
  configurationIdentifier?: InputMaybe<DestinationNeighborhoodsConfigurationIdentifier>;
  destination?: InputMaybe<DestinationNeighborhoodsDestinationInput>;
}

export interface DestinationNeighborhoodsDestinationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  place?: InputMaybe<DestinationPlaceInput>;
}

export type DestinationOfferItem = OfferItem & {
  __typename?: "DestinationOfferItem";
  affinities?: Maybe<Array<Scalars["String"]["output"]>>;
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  flightOffer?: Maybe<OfferData>;
  image: Image;
  lodgingOffer?: Maybe<OfferData>;
  name: Scalars["String"]["output"];
  packageOffer?: Maybe<OfferData>;
  propertyCount?: Maybe<Scalars["String"]["output"]>;
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
};

export interface DestinationPlaceInput {
  id?: InputMaybe<Scalars["String"]["input"]>;
}

export type DestinationPriceCard = {
  __typename?: "DestinationPriceCard";
  actionAccessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  badge?: Maybe<EgdsStandardBadge>;
  body: EgdsText;
  description: Scalars["String"]["output"];
  header: EgdsText;
  interaction: ClientSideAnalytics;
};

export type DestinationPriceInsightModule = {
  __typename?: "DestinationPriceInsightModule";
  actionSection?: Maybe<EgdsStandardMessagingCard>;
  legalSheet?: Maybe<EgdsSheet>;
  priceSection?: Maybe<DestinationPriceCard>;
};

export type DestinationPriceInsights = {
  __typename?: "DestinationPriceInsights";
  currentMonthAverage: DestinationInsightsPrice;
  expando: EgdsExpandoPeek;
  expandoIcon?: Maybe<Icon>;
  /** @deprecated Please use expando */
  expandoPeek: EgdsExpandoCard;
  impression: ClientSideAnalytics;
  monthlyDetails: DestinationInsightsMonthlyDetails;
  subtitle: EgdsSpannableText;
  superTitle?: Maybe<EgdsSpannableText>;
  title: EgdsSpannableText;
  typicalRange: DestinationInsightsPriceRange;
};

export type DestinationQuestionAndAnswer = {
  __typename?: "DestinationQuestionAndAnswer";
  answer: Scalars["String"]["output"];
  clickAnalytics: ClientSideAnalytics;
  question: Scalars["String"]["output"];
};

export type DestinationRecommendationCard = {
  __typename?: "DestinationRecommendationCard";
  action: UiLinkAction;
  actionCard?: Maybe<EgdsStandardMessagingCard>;
  affinities?: Maybe<DestinationBadges>;
  analytics: ClientSideAnalytics;
  button?: Maybe<DestinationButtonMapTrigger>;
  details: Array<DestinationRecommendationCardContent>;
  heading?: Maybe<DestinationRecommendationHeading>;
  media: DestinationRecommendationCardMedia;
  userAction?: Maybe<DestinationCardUserAction>;
};

export type DestinationRecommendationCardContent = {
  __typename?: "DestinationRecommendationCardContent";
  button?: Maybe<EgdsButton>;
  content?: Maybe<DestinationRecommendationDetailContent>;
  heading?: Maybe<DestinationRecommendationHeading>;
  link?: Maybe<EgdsStandardLink>;
};

export type DestinationRecommendationCardImage = {
  __typename?: "DestinationRecommendationCardImage";
  attribution?: Maybe<EgdsSpannableText>;
  image?: Maybe<Image>;
  impression: ClientSideAnalytics;
};

export type DestinationRecommendationCardImages = {
  __typename?: "DestinationRecommendationCardImages";
  images: Array<DestinationRecommendationCardImage>;
};

export type DestinationRecommendationCardMedia =
  | DestinationRecommendationCardImage
  | DestinationRecommendationCardImages;

export interface DestinationRecommendationCriteriaInput {
  maxCards?: InputMaybe<Scalars["Int"]["input"]>;
  minCards?: InputMaybe<Scalars["Int"]["input"]>;
  referencePlaceId: Scalars["String"]["input"];
  strategy: DestinationRecommendationStrategy;
  type: DestinationRecommendationPlaceType;
}

export type DestinationRecommendationDetailContent = EgdsParagraph | EgdsPlainText | EgdsTextIconList;

export type DestinationRecommendationGroup = {
  __typename?: "DestinationRecommendationGroup";
  backgroundImage?: Maybe<Image>;
  badge?: Maybe<EgdsStandardBadge>;
  bottomLink?: Maybe<EgdsStandardLink>;
  button?: Maybe<UiPrimaryButton>;
  cards: Array<DestinationRecommendationCard>;
  carousel: EgdsCarousel;
  heading: DestinationRecommendationHeading;
  impression: ClientSideAnalytics;
  interaction: ClientSideAnalytics;
  map?: Maybe<DestinationMap>;
};

export type DestinationRecommendationGroups = {
  __typename?: "DestinationRecommendationGroups";
  groups: Array<DestinationRecommendationGroup>;
  heading?: Maybe<DestinationRecommendationHeading>;
  impression: ClientSideAnalytics;
  interaction: ClientSideAnalytics;
};

export type DestinationRecommendationHeading = {
  __typename?: "DestinationRecommendationHeading";
  subTitle?: Maybe<Scalars["String"]["output"]>;
  superTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type DestinationRecommendationPlaceType = "CITY" | "NEIGHBORHOOD" | "POI";

export type DestinationRecommendationStrategy = "NEARBY" | "SIMILAR" | "TOP";

export type DestinationTab = {
  __typename?: "DestinationTab";
  button: UiSecondaryButton;
  content: Array<DestinationInfoResponse>;
  interaction: ClientSideAnalytics;
  name: Scalars["String"]["output"];
};

export type DestinationTemperature = {
  __typename?: "DestinationTemperature";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  interaction: ClientSideAnalytics;
  max: Scalars["Int"]["output"];
  maxTempText: Scalars["String"]["output"];
  min: Scalars["Int"]["output"];
  minTempText: Scalars["String"]["output"];
  weatherIcon: Icon;
};

export type DestinationThemeVacationsCard = DestinationThemeVacationsIconCard;

export type DestinationThemeVacationsCarousel = {
  __typename?: "DestinationThemeVacationsCarousel";
  cards: Array<DestinationThemeVacationsCard>;
  container: EgdsCarousel;
  heading: EgdsHeading;
};

export type DestinationThemeVacationsIconCard = {
  __typename?: "DestinationThemeVacationsIconCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UiLinkAction;
  icon: Icon;
  id?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type DestinationTip = {
  __typename?: "DestinationTip";
  description: EgdsSpannableText;
  icon: Icon;
};

export type DestinationTipItem = {
  __typename?: "DestinationTipItem";
  destinationTips: Array<DestinationTip>;
  name: Scalars["String"]["output"];
};

export type DestinationTips = {
  __typename?: "DestinationTips";
  category: Scalars["String"]["output"];
  interaction: ClientSideAnalytics;
  items: Array<DestinationTipItem>;
};

export type DestinationTravelGuidePolicy = "PERSONALIZED" | "PLACE_ID" | "USER_LOCATION";

export type DestinationWishlist = {
  __typename?: "DestinationWishlist";
  analytics: ClientSideAnalytics;
  content: WishlistContent;
  heading: WishlistHeading;
};

export type DestinationWishlistContent = {
  __typename?: "DestinationWishlistContent";
  /** @deprecated Please use seeAllButton */
  button: EgdsButton;
  cards: Array<DestinationRecommendationCard>;
  seeAllButton?: Maybe<EgdsButton>;
};

export type DetailMigrationContext = {
  __typename?: "DetailMigrationContext";
  contextInput?: Maybe<MigrationContextInput>;
  flightsDetailCriteria?: Maybe<MigrationFlightsDetailCriteria>;
  queryState?: Maybe<MigrationFlightsQueryState>;
  virtualAgentContext?: Maybe<MigrationVirtualAgentContext>;
};

export type DetailsAndFaresTab = EgdsTab & {
  __typename?: "DetailsAndFaresTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  contents: Array<FlightsDetailsAndFaresSection>;
  label: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
};

export type DetailsAndFaresTabs = EgdsTabs & {
  __typename?: "DetailsAndFaresTabs";
  selectedTabId?: Maybe<Scalars["String"]["output"]>;
  tabs: Array<DetailsAndFaresTab>;
};

export type DetailsMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "DetailsMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type DetailsNavTabs = {
  __typename?: "DetailsNavTabs";
  navTabs: Array<NavTab>;
};

export type Device = {
  __typename?: "Device";
  type: DeviceType;
};

export type DeviceContext = {
  __typename?: "DeviceContext";
  brandName?: Maybe<Scalars["String"]["output"]>;
  deviceOS?: Maybe<Scalars["String"]["output"]>;
  deviceOSVersion?: Maybe<Scalars["String"]["output"]>;
  isMobile?: Maybe<Scalars["Boolean"]["output"]>;
  isTablet?: Maybe<Scalars["Boolean"]["output"]>;
  isWirelessDevice?: Maybe<Scalars["Boolean"]["output"]>;
  mobileBrowser?: Maybe<Scalars["String"]["output"]>;
  modelName?: Maybe<Scalars["String"]["output"]>;
  physicalScreenHeight?: Maybe<Scalars["Int"]["output"]>;
  physicalScreenWidth?: Maybe<Scalars["Int"]["output"]>;
  resolutionHeight?: Maybe<Scalars["Int"]["output"]>;
  resolutionWidth?: Maybe<Scalars["Int"]["output"]>;
};

export interface DeviceInput {
  type: DeviceType;
}

export type DeviceType =
  | "APP_PHONE"
  | "APP_TABLET"
  | "CHAT_APP"
  | "DESKTOP"
  | "MOBILE_PHONE"
  | "MOBILE_TABLET"
  | "VOICE_APP";

export type DeviceTypeTemplate = {
  __typename?: "DeviceTypeTemplate";
  adaptex?: Maybe<Adaptex>;
  brands?: Maybe<Array<Scalars["String"]["output"]>>;
  device: DeviceType;
  identifierTypes?: Maybe<Array<IdentifierType>>;
  template: Scalars["String"]["output"];
};

export type DialogAction = {
  __typename?: "DialogAction";
  icon?: Maybe<Icon>;
  linkUrl: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  referrerId?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type DialogBlock = {
  __typename?: "DialogBlock";
  image: Image;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type DialogButtonActionType =
  | "CLOSE_DIALOG"
  | "CREATE_TRACKING_MUTATION"
  | "DISABLE_TRACKING_MUTATION"
  | "EMAIL_OPT_OUT"
  | "UPDATE_TRACKING_MUTATION";

export type DialogContent = {
  __typename?: "DialogContent";
  content?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<LodgingHeader>;
};

export interface DialogContentEntry {
  entryContent: Array<Scalars["String"]["output"]>;
  entryTitle: LodgingHeader;
}

export type DialogElementType = "CLEAR_RATES" | "DELETE_QUOTES";

export type DialogTriggerButton = UiSecondaryButton;

export type DialogTriggerMessage = {
  __typename?: "DialogTriggerMessage";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type DialogView = {
  __typename?: "DialogView";
  analytics?: Maybe<ClientSideAnalytics>;
  closeButton?: Maybe<UiPrimaryButton>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type Dimensions = {
  __typename?: "Dimensions";
  density: Scalars["Float"]["output"];
  height: Scalars["Float"]["output"];
  width: Scalars["Float"]["output"];
};

export interface DimensionsInput {
  density: Scalars["Float"]["input"];
  height: Scalars["Float"]["input"];
  width: Scalars["Float"]["input"];
}

export type DirectFeedback = {
  __typename?: "DirectFeedback";
  callToAction: DirectFeedbackCallToAction;
  /** @deprecated Include promptId as part of the callToAction field instead */
  promptId: Scalars["ID"]["output"];
};

export type DirectFeedbackCallToAction = {
  __typename?: "DirectFeedbackCallToAction";
  callToAction: DirectFeedbackCallToActionElement;
  contextValues: Array<GraphQlPair>;
  eager: Scalars["Boolean"]["output"];
  postSubmitDisplay?: Maybe<DirectFeedbackPostSubmitElement>;
  promptId: Scalars["String"]["output"];
  sheetType?: Maybe<DirectFeedbackSheetType>;
};

export type DirectFeedbackCallToActionElement =
  | EgdsImageLink
  | EgdsInlineLink
  | EgdsStandardLink
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type DirectFeedbackCheckbox = EgdsCheckBox & {
  __typename?: "DirectFeedbackCheckbox";
  analytics: ClientSideAnalytics;
  description?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label: EgdsText;
  name?: Maybe<Scalars["String"]["output"]>;
  required: Scalars["Boolean"]["output"];
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackFieldError = {
  __typename?: "DirectFeedbackFieldError";
  error: Scalars["String"]["output"];
  fieldId?: Maybe<Scalars["ID"]["output"]>;
};

export type DirectFeedbackFieldSet = {
  __typename?: "DirectFeedbackFieldSet";
  elements: Array<DirectFeedbackFieldSetElement>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackFieldSetElement =
  | DirectFeedbackInputField
  | DirectFeedbackRadioGroup
  | DirectFeedbackTextArea
  | EgdsHeading
  | EgdsPlainText
  | EgdsSpannableText;

export type DirectFeedbackFormDisplayElement =
  | DirectFeedbackTextList
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsPlainText
  | EgdsSpannableText;

export type DirectFeedbackFormPage = {
  __typename?: "DirectFeedbackFormPage";
  commandOrientation: DirectFeedbackPageCommandOrientation;
  commands?: Maybe<Array<EgdsButton>>;
  /** @deprecated Please use displayConditions instead. */
  displayCondition?: Maybe<DirectFeedbackPageDisplayCondition>;
  displayConditions: Array<DirectFeedbackPageDisplayCondition>;
  elements: Array<DirectFeedbackFormPageElement>;
  footer?: Maybe<Array<DirectFeedbackFormDisplayElement>>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Please use commands instead. */
  submitButton: UiPrimaryButton;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackFormPageElement =
  | DirectFeedbackFieldSet
  | DirectFeedbackInputField
  | DirectFeedbackMultiSelectGroup
  | DirectFeedbackRadioGroup
  | DirectFeedbackTextArea
  | DirectFeedbackTextList
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsPlainText
  | EgdsSpannableText;

export type DirectFeedbackFormValidation = {
  __typename?: "DirectFeedbackFormValidation";
  errors: Array<DirectFeedbackFieldError>;
  serviceError?: Maybe<EgdsErrorSummary>;
};

export type DirectFeedbackInputField = EgdsInputField & {
  __typename?: "DirectFeedbackInputField";
  analytics: ClientSideAnalytics;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  name?: Maybe<Scalars["String"]["output"]>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackLink = {
  __typename?: "DirectFeedbackLink";
  feedbackFormAction: DirectFeedbackCallToAction;
};

export type DirectFeedbackListing = {
  __typename?: "DirectFeedbackListing";
  callToAction: DirectFeedbackCallToAction;
};

export type DirectFeedbackMultiSelectGroup = {
  __typename?: "DirectFeedbackMultiSelectGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  legend: Scalars["String"]["output"];
  options: Array<EgdsCheckBox>;
  pillOptions: Array<EgdsPill>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  selected?: Maybe<Scalars["String"]["output"]>;
  style: DirectFeedbackMultiSelectStyle;
};

export type DirectFeedbackMultiSelectStyle = "DEFAULT" | "PILL";

export type DirectFeedbackOpenFormAction = UiAction & {
  __typename?: "DirectFeedbackOpenFormAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  promptId?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackOpenNextPageAction = UiAction & {
  __typename?: "DirectFeedbackOpenNextPageAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  destinationId?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackOpenPreviousPageAction = UiAction & {
  __typename?: "DirectFeedbackOpenPreviousPageAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type DirectFeedbackPageCommandOrientation = "HORIZONTAL" | "VERTICAL";

export type DirectFeedbackPageDisplayCondition = {
  __typename?: "DirectFeedbackPageDisplayCondition";
  conditionType: DirectFeedbackPageDisplayConditionType;
  expectedValue: Scalars["String"]["output"];
  fieldId: Scalars["ID"]["output"];
};

export type DirectFeedbackPageDisplayConditionType = "BOOL" | "NONE" | "PATTERN" | "TEXT";

export type DirectFeedbackPostSubmitElement =
  | EgdsImageLink
  | EgdsInlineLink
  | EgdsSpannableText
  | EgdsStandardLink
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type DirectFeedbackPrompt = {
  __typename?: "DirectFeedbackPrompt";
  /** @deprecated Client spinners should implement this field, using the DirectFeedbackCallToAction found in bex-api-shared-types */
  callToAction?: Maybe<UiTertiaryButton>;
  challenges: Array<AtoChallenge>;
  /** @deprecated Client spinners should implement this field, using the DirectFeedbackCallToAction found in bex-api-shared-types */
  decoratedCallToAction?: Maybe<DirectFeedbackCallToAction>;
  id: Scalars["ID"]["output"];
  pages: Array<DirectFeedbackFormPage>;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackRadioButton = EgdsRadioButton & {
  __typename?: "DirectFeedbackRadioButton";
  analytics: ClientSideAnalytics;
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  labelSuffix?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type DirectFeedbackRadioGroup = EgdsRadioGroup & {
  __typename?: "DirectFeedbackRadioGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  legend: Scalars["String"]["output"];
  options: Array<EgdsRadioButton>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackSheetType = "BOTTOM_SHEET" | "CENTERED_SHEET" | "FULL_SHEET";

export type DirectFeedbackSubmitFormAction = UiAction & {
  __typename?: "DirectFeedbackSubmitFormAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type DirectFeedbackTextArea = EgdsInputField & {
  __typename?: "DirectFeedbackTextArea";
  analytics: ClientSideAnalytics;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  isEditable: Scalars["Boolean"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  maxRows?: Maybe<Scalars["Int"]["output"]>;
  minRows?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type DirectFeedbackTextList = {
  __typename?: "DirectFeedbackTextList";
  bullet: BulletStyle;
  listItems: Array<DirectFeedbackTextListItem>;
};

export type DirectFeedbackTextListItem = {
  __typename?: "DirectFeedbackTextListItem";
  egdsElementId: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  style?: Maybe<EgdsTextListItemStyle>;
  text: EgdsText;
};

export interface DirectFeedbackValuesInput {
  atoShieldValidation?: InputMaybe<AtoChallengeValidationInput>;
  context: Array<GraphQlPairInput>;
  formValues: Array<GraphQlPairInput>;
}

export type DirectionsPath = {
  __typename?: "DirectionsPath";
  path: Scalars["String"]["output"];
  pathType: EgdsPathType;
};

export interface DirectionsReferenceInput {
  id: Scalars["String"]["input"];
  type: EgdsMapFeatureType;
}

export type DisclaimerCard = EgdsStandardMessagingCard;

export type DisclaimerContent = {
  __typename?: "DisclaimerContent";
  content: Array<EgdsSpannableText>;
};

export type DisclaimerContentMarkup = {
  __typename?: "DisclaimerContentMarkup";
  content: Array<MarkupText>;
};

export type DisclaimerMessage = {
  __typename?: "DisclaimerMessage";
  heading?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoverMoreItem = EgdsCarouselItem & {
  __typename?: "DiscoverMoreItem";
  card: EgdsImageLinkCard;
  icon: Icon;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryAdditionalInformation = DiscoveryExpandoTextLists;

export type DiscoveryAlternativeLinkCard = {
  __typename?: "DiscoveryAlternativeLinkCard";
  cardAction: UiLinkAction;
  impression: ClientSideAnalytics;
  media: Media;
  subtitle?: Maybe<EgdsText>;
  title: EgdsText;
};

export interface DiscoveryAlternativeLinkCardCriteriaInput {
  alternativeTripType: AlternativeLinkType;
  destinationId: Scalars["String"]["input"];
}

export type DiscoveryAlternativeLinkCardGroup = {
  __typename?: "DiscoveryAlternativeLinkCardGroup";
  carousel: EgdsCarousel;
  discoveryAlternativeLinkCard: Array<DiscoveryAlternativeLinkCard>;
  title: EgdsText;
};

export type DiscoveryAttribution = {
  __typename?: "DiscoveryAttribution";
  closeContent?: Maybe<EgdsButton>;
  content: EgdsSpannableText;
  showContent?: Maybe<EgdsButton>;
};

export type DiscoveryBadge = {
  __typename?: "DiscoveryBadge";
  action?: Maybe<UiLinkAction>;
  badge: EgdsBadge;
};

export type DiscoveryCard = GenericDiscoveryCard;

export type DiscoveryCardBadges = {
  __typename?: "DiscoveryCardBadges";
  adBadge?: Maybe<AdBadges>;
  heading: Array<EgdsBadge>;
  /** @deprecated Use priceBadge */
  price?: Maybe<EgdsBadge>;
  priceBadge?: Maybe<DiscoveryBadge>;
  priceButton?: Maybe<DiscoveryPriceButton>;
};

export type DiscoveryCardContentSize =
  | "ABBREVIATED"
  | "FOUNDATION"
  | "FOUNDATION_DETAILS_LIST"
  | "LANDING"
  | "LONG_FORM"
  | "LONG_FORM_FEATURED"
  | "SHORT_FORM"
  | "SMART_LANDING"
  | "UBER_ABBREVIATED"
  | "UBER_ABBREVIATED_PRICE"
  | "UBER_ABBREVIATED_RATING";

export type DiscoveryCardLocation = {
  __typename?: "DiscoveryCardLocation";
  leadingIcon: Icon;
  text: EgdsText;
  trailingIcon?: Maybe<Icon>;
};

export type DiscoveryCardMedia = DiscoveryMediaGallery | DiscoveryMediaItem;

export type DiscoveryCloseCardAction = {
  __typename?: "DiscoveryCloseCardAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
};

export type DiscoveryContainer = {
  __typename?: "DiscoveryContainer";
  itemsLayout: DiscoveryGroupLayout;
  wrapper: DiscoveryWrapper;
};

export type DiscoveryContainerType = "CAROUSEL" | "EXPANDO" | "GRID" | "LIST";

export interface DiscoveryDestinationContextInput {
  placeCoordinates?: InputMaybe<CoordinatesInput>;
  placeId?: InputMaybe<DiscoveryPlaceIdInput>;
}

export type DiscoveryDetailsList =
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableList
  | EgdsTextIconList
  | EgdsUnorderedList
  | EnrichedDetailsList;

export type DiscoveryDisclaimer = DiscoveryDisclaimerOverlay;

export type DiscoveryDisclaimerOverlay = {
  __typename?: "DiscoveryDisclaimerOverlay";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  overlay?: Maybe<DiscoveryOverlay>;
  text: Scalars["String"]["output"];
};

export type DiscoveryExpando = {
  __typename?: "DiscoveryExpando";
  expando?: Maybe<EgdsExpandoPeek>;
  hideScrim: Scalars["Boolean"]["output"];
  items: DiscoveryViewportItems;
};

export type DiscoveryExpandoText = {
  __typename?: "DiscoveryExpandoText";
  text: Scalars["String"]["output"];
  textControl: EgdsExpandoPeek;
};

export type DiscoveryExpandoTextLists = {
  __typename?: "DiscoveryExpandoTextLists";
  contents: Array<DiscoveryTextSection>;
  expando: EgdsExpandoPeek;
};

export type DiscoveryFilter = EgdsBasicPill;

export type DiscoveryFilters = {
  __typename?: "DiscoveryFilters";
  emptyState?: Maybe<UiEmptyState>;
  flightClass?: Maybe<EgdsBasicSelect>;
  resetButtton?: Maybe<UiSecondaryButton>;
  starRating?: Maybe<EgdsBasicSelect>;
  tripLengthInNights?: Maybe<EgdsBasicSelect>;
};

export interface DiscoveryFlexFiltersInput {
  accommodationTypeCode?: InputMaybe<Scalars["String"]["input"]>;
  affinityTypeCode?: InputMaybe<Scalars["String"]["input"]>;
  hotelBrandCode?: InputMaybe<Scalars["String"]["input"]>;
  starRatingCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type DiscoveryFooter = DiscoveryFooterSection | DiscoverySlimCard;

export type DiscoveryFooterAction = EgdsStandardLink | UiLinkAction | UiSecondaryButton;

export type DiscoveryFooterSection = {
  __typename?: "DiscoveryFooterSection";
  action?: Maybe<DiscoveryFooterAction>;
  alignment?: Maybe<Scalars["String"]["output"]>;
  disclaimerText?: Maybe<EgdsText>;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<EgdsText>;
};

export type DiscoveryGrid = {
  __typename?: "DiscoveryGrid";
  columns: DiscoveryViewportItems;
};

export type DiscoveryGroupContainer = DiscoveryContainer | EgdsCarousel;

export type DiscoveryGroupErrorSection = {
  __typename?: "DiscoveryGroupErrorSection";
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<UiPrimaryButton>;
  primary?: Maybe<EgdsHeading>;
  secondary?: Maybe<EgdsPlainText>;
};

export type DiscoveryGroupLayout = DiscoveryGrid | DiscoveryList | EgdsCarousel;

export type DiscoveryHeading = {
  __typename?: "DiscoveryHeading";
  disclaimer?: Maybe<DiscoveryDisclaimer>;
  featuredHeader?: Maybe<Scalars["String"]["output"]>;
  featuredHeaderAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  highlight?: Maybe<Scalars["String"]["output"]>;
  highlightAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<EgdsStandardLink>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  subTitleAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  titleAccessibilityLabel: Scalars["String"]["output"];
};

export type DiscoveryItemGroups = {
  __typename?: "DiscoveryItemGroups";
  groups: Array<DiscoveryItemsGroup>;
};

export type DiscoveryItemsGroup = {
  __typename?: "DiscoveryItemsGroup";
  cards: Array<DiscoveryCard>;
  container?: Maybe<DiscoveryGroupContainer>;
  filters?: Maybe<DiscoveryFilters>;
  footer?: Maybe<DiscoveryFooter>;
  /** @deprecated Replace with field action inside footer field */
  footerLink?: Maybe<EgdsStandardLink>;
  heading?: Maybe<DiscoveryHeading>;
  impression?: Maybe<ClientSideAnalytics>;
  secondaryHeading?: Maybe<DiscoverySecondaryHeading>;
  seoStructuredData?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryList = {
  __typename?: "DiscoveryList";
  id: Scalars["String"]["output"];
  scroll?: Maybe<ClientSideAnalytics>;
  scrollEnd?: Maybe<ClientSideAnalytics>;
};

export interface DiscoveryLodgingContextInput {
  checkin?: InputMaybe<DateInput>;
  checkout?: InputMaybe<DateInput>;
  discoveryFlexFilters?: InputMaybe<DiscoveryFlexFiltersInput>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  rooms?: InputMaybe<Array<DiscoveryRoomInput>>;
  searchCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type DiscoveryMap = {
  __typename?: "DiscoveryMap";
  dynamicMapTrigger?: Maybe<EgdsButton>;
  map: EgdsBasicMap;
  mapControls?: Maybe<DiscoveryMapControls>;
  openMap?: Maybe<ClientSideAnalytics>;
  placeMarker?: Maybe<DiscoveryMarker>;
  seeAllProducts: UiPrimaryButton;
  tabs: Array<DiscoveryMapTab>;
  userLocationMarker?: Maybe<DiscoveryUserLocationMarker>;
};

export type DiscoveryMapTabsArgs = {
  strategies?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type DiscoveryMapControls = {
  __typename?: "DiscoveryMapControls";
  buttonGroup: EgdsToggleButtonGroup;
  openControlsButton: EgdsToggleButton;
};

export type DiscoveryMapTab = {
  __typename?: "DiscoveryMapTab";
  cardContainer?: Maybe<DiscoveryItemsGroup>;
  errorSection?: Maybe<DiscoveryMapTabErrorSection>;
  markers?: Maybe<Array<DiscoveryMarker>>;
  pill: EgdsBasicPill;
  srpParams?: Maybe<Scalars["String"]["output"]>;
  staticMapImageUrl?: Maybe<Scalars["String"]["output"]>;
  tab?: Maybe<EgdsBasicTab>;
  tabId: Scalars["String"]["output"];
  toolbar: DiscoveryMapToolbar;
};

export type DiscoveryMapTabErrorSection = {
  __typename?: "DiscoveryMapTabErrorSection";
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<UiPrimaryButton>;
  primary?: Maybe<EgdsHeading>;
  secondary?: Maybe<EgdsPlainText>;
};

export type DiscoveryMapToolbar = {
  __typename?: "DiscoveryMapToolbar";
  action?: Maybe<DiscoveryMapToolbarCloseAction>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryMapToolbarCloseAction = {
  __typename?: "DiscoveryMapToolbarCloseAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
};

export type DiscoveryMarker = {
  __typename?: "DiscoveryMarker";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  impression?: Maybe<ClientSideAnalytics>;
  interaction?: Maybe<ClientSideAnalytics>;
  mapCard?: Maybe<DiscoveryCard>;
  markerGroup?: Maybe<Scalars["String"]["output"]>;
  markerIconType?: Maybe<Array<Scalars["String"]["output"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryMediaGallery = {
  __typename?: "DiscoveryMediaGallery";
  carousel?: Maybe<EgdsCarousel>;
  items: Array<DiscoveryMediaItem>;
};

export type DiscoveryMediaItem = {
  __typename?: "DiscoveryMediaItem";
  attribution?: Maybe<DiscoveryAttribution>;
  impression?: Maybe<ClientSideAnalytics>;
  /** @deprecated Replace with field impression */
  impressionMicromessages?: Maybe<Array<EgUisMicroMessage>>;
  media?: Maybe<Media>;
};

export type DiscoveryMessagingBanner = {
  __typename?: "DiscoveryMessagingBanner";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  text: Scalars["String"]["output"];
  theme?: Maybe<EgdsThemeProviderColor>;
  trailingIcon?: Maybe<Icon>;
};

export type DiscoveryMultiModule = {
  __typename?: "DiscoveryMultiModule";
  heading?: Maybe<DiscoveryHeading>;
  tabbedModules: Array<DiscoveryTabbedModule>;
};

export type DiscoveryOfferingType = "DESTINATION" | "PRODUCT";

export type DiscoveryOverlay = {
  __typename?: "DiscoveryOverlay";
  accessibilityLabel: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  closeButton?: Maybe<EgdsOverlayButton>;
  footerLink?: Maybe<EgdsStandardLink>;
  heading: DiscoveryOverlayHeading;
};

export type DiscoveryOverlayHeading = {
  __typename?: "DiscoveryOverlayHeading";
  backgroundImage?: Maybe<Media>;
  subTitle?: Maybe<EgdsText>;
  title: EgdsHeading;
};

export type DiscoveryPersonalizedModuleList = {
  __typename?: "DiscoveryPersonalizedModuleList";
  heading?: Maybe<DiscoveryText>;
  /** @deprecated modules will be deprecated. Please use personalizedModules instead. */
  modules: Array<DiscoveryItemsGroup>;
  personalizedModules: Array<PersonalizedModule>;
  /** @deprecated title is deprecated. Please use heading instead. */
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface DiscoveryPersonalizedModuleListContextInput {
  useCaseId: Scalars["String"]["input"];
}

export interface DiscoveryPlaceIdInput {
  id: Scalars["String"]["input"];
  type?: InputMaybe<Scalars["String"]["input"]>;
}

export type DiscoveryPriceButton = DiscoveryPrimaryAltButton;

export type DiscoveryPriceStrategy = "DATELESS" | "LIVE" | "NONE";

export type DiscoveryPrimaryAltButton = {
  __typename?: "DiscoveryPrimaryAltButton";
  button: UiPrimaryButton;
};

export type DiscoveryProductGroupMap = {
  __typename?: "DiscoveryProductGroupMap";
  discoveryMap: DiscoveryMap;
  errorSection?: Maybe<DiscoveryGroupErrorSection>;
  staticMapImageUrl?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryProductUniqueness = {
  __typename?: "DiscoveryProductUniqueness";
  background?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<EgdsSpannableList>;
  title?: Maybe<EgdsGraphicText>;
};

export interface DiscoveryRecentActivityContextInput {
  lodging?: InputMaybe<DiscoveryLodgingContextInput>;
  marketingChannelTypeCode?: InputMaybe<Scalars["Int"]["input"]>;
  outputLineOfBusiness: LineOfBusinessDomain;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface DiscoveryRecommendationContextInput {
  destination?: InputMaybe<DiscoveryDestinationContextInput>;
  displayedImageId?: InputMaybe<Scalars["String"]["input"]>;
  lodging?: InputMaybe<DiscoveryLodgingContextInput>;
  marketingChannelCode?: InputMaybe<Scalars["Int"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  outputLineOfBusiness?: InputMaybe<LineOfBusinessDomain>;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
}

export type DiscoveryRecommendationInputContext =
  | "DESTINATION"
  | "DISPLAYED_IMAGE"
  | "ORDER"
  | "PRODUCT"
  | "RECENTLY_VIEWED_PRODUCT"
  | "RECENT_SEARCHES"
  | "USER_LOCATION";

export type DiscoveryRecommendationStrategy =
  | "ALTERNATIVE"
  | "EG_PLACES"
  | "EG_SEARCH_NEAR_PLACE"
  | "EG_SEARCH_SELECTION"
  | "LOOKUP_AND_ASSEMBLE"
  | "NEARBY"
  | "POPULAR"
  | "SEM_RESULT"
  | "SIMILAR"
  | "SPONSORED"
  | "THEMED_POPULAR"
  | "THEMED_REFINEMENT"
  | "TOP_CITIES"
  | "TRENDING";

export type DiscoveryReviewSnippet = DiscoveryExpandoText;

export interface DiscoveryRoomInput {
  adults: Scalars["Int"]["input"];
  children?: InputMaybe<Array<Scalars["Int"]["input"]>>;
}

export type DiscoverySecondaryHeading = DiscoveryMessagingBanner | EgdsPlainText;

export type DiscoverySlimCard = {
  __typename?: "DiscoverySlimCard";
  action?: Maybe<DiscoverySlimCardAction>;
  graphic?: Maybe<UiGraphic>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  rightIcon?: Maybe<Icon>;
  secondaries: Array<DiscoveryText>;
};

export type DiscoverySlimCardAction = UiLinkAction;

export type DiscoveryStarRating = EgdsIconRating;

export type DiscoveryTabbedModule = {
  __typename?: "DiscoveryTabbedModule";
  filter: DiscoveryFilter;
  module: DiscoveryItemsGroup;
};

export type DiscoveryText = EgdsGraphicText | EgdsPlainText;

export type DiscoveryTextContent = EgdsPlainText | EgdsUnorderedList;

export type DiscoveryTextSection = {
  __typename?: "DiscoveryTextSection";
  text: DiscoveryTextContent;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryUserLocationMarker = {
  __typename?: "DiscoveryUserLocationMarker";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  markerIconType?: Maybe<Array<Scalars["String"]["output"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type DiscoveryViewportItems = {
  __typename?: "DiscoveryViewportItems";
  large: Scalars["Int"]["output"];
  medium: Scalars["Int"]["output"];
  small: Scalars["Int"]["output"];
};

export type DiscoveryWrapper = DiscoveryExpando | EgdsExpandoCard;

export type DisplayPrice = {
  __typename?: "DisplayPrice";
  disclaimer?: Maybe<LodgingPlainDialog>;
  price: FormattedMoney;
  role: Scalars["String"]["output"];
};

export type DisplayPriceSummaryItem = {
  __typename?: "DisplayPriceSummaryItem";
  leadPrice?: Maybe<LodgingEnrichedMessage>;
  priceLabel?: Maybe<LodgingEnrichedMessage>;
  priceMessages?: Maybe<Array<LodgingEnrichedMessage>>;
};

export type Distance = {
  __typename?: "Distance";
  unit: DistanceUnit;
  value: Scalars["Float"]["output"];
};

export type DistanceUnit = "KILOMETER" | "MILE";

export type DomainInfo = {
  __typename?: "DomainInfo";
  name: Scalars["String"]["output"];
  refId: Scalars["String"]["output"];
};

export type DownloadButtonWithDialog = {
  __typename?: "DownloadButtonWithDialog";
  dialog: EgdsFullScreenDialog;
  dialogButtons: Array<DialogBlock>;
  title?: Maybe<Scalars["String"]["output"]>;
  triggerButton: UiPrimaryButton;
};

export type DropOffSearchResults = {
  __typename?: "DropOffSearchResults";
  carDropOffOfferCard: Array<CarDropOffOfferCard>;
  carDropOffOfferCardTitle: Scalars["String"]["output"];
  carDropOffTitle: CarDropOffTitle;
  map?: Maybe<CarMap>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
};

export type Duration = {
  __typename?: "Duration";
  /** @deprecated Use platform formatters and return localized strings */
  unit: ChronoUnit;
  /** @deprecated Use platform formatters and return localized strings */
  value: Scalars["String"]["output"];
};

export interface DurationSpan {
  closeButton: EgdsButton;
  doneButton: EgdsButton;
  title: Scalars["String"]["output"];
}

export type DurationSpanInputValidation = EgdsInputValidation & {
  __typename?: "DurationSpanInputValidation";
  errorMessage: Scalars["String"]["output"];
  errorSummary?: Maybe<EgdsErrorSummary>;
  type: DurationSpanValidationType;
};

export type DurationSpanSelector = DurationSpan &
  EgdsElement & {
    __typename?: "DurationSpanSelector";
    closeButton: EgdsButton;
    doneButton: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    maxDurationSpanLimit: EgdsNumberInputField;
    minDurationSpanLimit: EgdsNumberInputField;
    title: Scalars["String"]["output"];
    validations?: Maybe<Array<EgdsInputValidation>>;
  };

export type DurationSpanValidationType =
  | "MAXIMUM_GREATER_THAN_MINIMUM"
  | "MAXIMUM_VALUE"
  | "MINIMUM_LESS_THAN_MAXIMUM"
  | "MINIMUM_VALUE"
  | "REQUIRED_VALUE";

export type DurationTimelineSideSection = {
  __typename?: "DurationTimelineSideSection";
  differentDayArrival?: Maybe<Scalars["String"]["output"]>;
  primary: Scalars["String"]["output"];
  /** @deprecated Use 'secondaryMessage' field to support different screen sizes */
  secondary: Scalars["String"]["output"];
  secondaryMessage: FlightsResponsiveMessage;
};

export type DynamicCard = {
  __typename?: "DynamicCard";
  action?: Maybe<DynamicCardAction>;
  elements?: Maybe<Array<DynamicCardElements>>;
  image?: Maybe<Image>;
  imageOverlays?: Maybe<Array<DynamicCardOverlayElement>>;
  imagePosition?: Maybe<ImagePosition>;
};

export type DynamicCardAction = {
  __typename?: "DynamicCardAction";
  dialog?: Maybe<VirtualAgentControlDialog>;
  event?: Maybe<DynamicCardEvent>;
  link?: Maybe<Uri>;
  properties?: Maybe<ElementProperties>;
  webview?: Maybe<VirtualAgentControlWebview>;
};

export type DynamicCardCarousel = {
  __typename?: "DynamicCardCarousel";
  cards?: Maybe<Array<DynamicCardCarouselItem>>;
  carouselId?: Maybe<Scalars["String"]["output"]>;
  nextButton?: Maybe<EgdsOverlayButton>;
  prevButton?: Maybe<EgdsOverlayButton>;
};

export type DynamicCardCarouselItem = {
  __typename?: "DynamicCardCarouselItem";
  card?: Maybe<DynamicCard>;
  cardPayload: Scalars["String"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
};

export type DynamicCardComposition = {
  __typename?: "DynamicCardComposition";
  progressBar?: Maybe<VirtualAgentControlProgressBar>;
  rating?: Maybe<VirtualAgentControlRating>;
  stepIndicator?: Maybe<VirtualAgentControlStepIndicator>;
};

export type DynamicCardContent = {
  __typename?: "DynamicCardContent";
  action?: Maybe<DynamicCardAction>;
  icon?: Maybe<Icon>;
  text?: Maybe<DynamicCardText>;
};

export type DynamicCardElement =
  | EgdsSpannableText
  | EgdsStylizedText
  | VirtualAgentControlActionable
  | VirtualAgentControlHorizontalBreak;

export type DynamicCardElements = DynamicCardComposition | DynamicCardContent | DynamicCardGroup;

export type DynamicCardEvent = {
  __typename?: "DynamicCardEvent";
  eventId: Scalars["String"]["output"];
  payload?: Maybe<Scalars["String"]["output"]>;
};

export type DynamicCardGroup = {
  __typename?: "DynamicCardGroup";
  forceWrap?: Maybe<Scalars["Boolean"]["output"]>;
  inlineContent?: Maybe<Array<DynamicCardContent>>;
  inlineJustify?: Maybe<InlineJustify>;
  separator?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface DynamicCardOverlayElement {
  position: DynamicCardOverlayPosition;
}

export type DynamicCardOverlayPosition = "BOTTOM_LEFT" | "TOP_RIGHT";

export type DynamicCardOverlayText = DynamicCardOverlayElement & {
  __typename?: "DynamicCardOverlayText";
  position: DynamicCardOverlayPosition;
  title: Scalars["String"]["output"];
};

export type DynamicCardOverlayToggle = DynamicCardOverlayElement & {
  __typename?: "DynamicCardOverlayToggle";
  action?: Maybe<DynamicCardAction>;
  position: DynamicCardOverlayPosition;
  selected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type DynamicCardText = {
  __typename?: "DynamicCardText";
  bold?: Maybe<Array<Scalars["String"]["output"]>>;
  overflow?: Maybe<Overflow>;
  size?: Maybe<Scalars["Int"]["output"]>;
  subscript?: Maybe<Scalars["String"]["output"]>;
  superscript?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<TextTheme>;
  value: Scalars["String"]["output"];
};

export type DynamicCrossSellQueries = {
  __typename?: "DynamicCrossSellQueries";
  productCrossSell: MultiItemCrossSellUiResponse;
};

export type DynamicCrossSellQueriesProductCrossSellArgs = {
  crossSellProductType: CrossSellProductType;
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type DynamicPackageDetailsPrimers = DynamicPackageDetailsPrimersSuccess | PackageDetailsPrimersError;

export type DynamicPackageDetailsPrimersSuccess = {
  __typename?: "DynamicPackageDetailsPrimersSuccess";
  heading: EgdsHeading;
  primers: DynamicPackageDetailsSuccessPrimers;
  state: MishopDetailsUiState;
};

export type DynamicPackageDetailsSuccessPrimers = {
  __typename?: "DynamicPackageDetailsSuccessPrimers";
  bookByPhonePrimer?: Maybe<MishopUiBookByPhonePrimer>;
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  /** @deprecated legalContentPrimer has been moved to be a member of the PackageUIProductContentPrimer union */
  legalContentPrimer: MishopUiLegalContentPrimer;
  messagingPrimers: Array<PackageUiMessagingPrimer>;
  notificationPrimer: MishopUiNotificationPrimer;
  oneKeyBannerPrimer?: Maybe<MishopUiOneKeyBannerPrimer>;
  pricingDetailsPrimer: MishopUiPricingDetailsPrimer;
  productContentPrimers: Array<PackageDetailsProductContentPrimer>;
  searchWizardPrimer?: Maybe<PackageUiChangePackageFormPrimer>;
  stepIndicatorPrimer: MishopUiStepIndicatorPrimer;
  tripSaveItemPrimer?: Maybe<PackageUiTripSaveItemPrimer>;
};

export type DynamicRateRule = {
  __typename?: "DynamicRateRule";
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use description instead of descriptionId, replace with description */
  descriptionId?: Maybe<Scalars["Int"]["output"]>;
  discountPercent?: Maybe<Scalars["Int"]["output"]>;
  discountType?: Maybe<RateDiscountType>;
  expiration?: Maybe<Duration>;
  sameDay: Scalars["Boolean"]["output"];
};

export type EgClickstreamAnalytics = {
  __typename?: "EGClickstreamAnalytics";
  event: EgClickstreamEvent;
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type EgClickstreamEvent = {
  __typename?: "EGClickstreamEvent";
  actionLocation?: Maybe<Scalars["String"]["output"]>;
  clickstreamTraceId?: Maybe<Scalars["String"]["output"]>;
  eventCategory: Scalars["String"]["output"];
  eventName: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
};

export type EgClickstreamPayload = TripBookingModule | TripRecommendationModule;

export type EgdsActionDialog = EgdsDialog & {
  __typename?: "EGDSActionDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer: EgdsDialogFooter;
};

export type EgdsAvatar = {
  __typename?: "EGDSAvatar";
  border: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  size?: Maybe<AvatarSize>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export interface EgdsBadge {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  size?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsBadgeNotification = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSBadgeNotification";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    count?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]["output"]>;
    text?: Maybe<Scalars["String"]["output"]>;
    theme?: Maybe<Scalars["String"]["output"]>;
    visible?: Maybe<Scalars["Boolean"]["output"]>;
  };

export interface EgdsBaseMap {
  center?: Maybe<Coordinates>;
  /** @deprecated Redundant field: locale is already provided at the shared-ui level */
  locale?: Maybe<Scalars["Locale"]["output"]>;
  zoom?: Maybe<Scalars["Int"]["output"]>;
}

export interface EgdsBaseMapConfig {
  accessToken: Scalars["String"]["output"];
  /** @deprecated prefer using data from EGDSDynamicMapConfig */
  config?: Maybe<MapConfiguration>;
  egdsMapProvider: EgdsMapProvider;
}

export type EgdsBasicCheckBox = EgdsCheckBox &
  EgdsElement & {
    __typename?: "EGDSBasicCheckBox";
    checkedAnalytics?: Maybe<ClientSideAnalytics>;
    description?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    enabled: Scalars["Boolean"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    label: EgdsText;
    name?: Maybe<Scalars["String"]["output"]>;
    required: Scalars["Boolean"]["output"];
    state: EgdsCheckBoxState;
    uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsBasicDateRange = EgdsDateRange & {
  __typename?: "EGDSBasicDateRange";
  loadingMessage?: Maybe<Scalars["String"]["output"]>;
  results: Array<EgdsDateRangeResult>;
};

export type EgdsBasicDateRangeResult = EgdsDateRangeResult & {
  __typename?: "EGDSBasicDateRangeResult";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  date: Date;
  label: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  theme?: Maybe<EgdsDateRangeResultTheme>;
};

export type EgdsBasicLocalizedText = EgdsElement &
  EgdsTemplateModels &
  EgdsText & {
    __typename?: "EGDSBasicLocalizedText";
    accessibilityTemplate?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    models: Array<TemplateModel>;
    template: Scalars["String"]["output"];
    text: Scalars["String"]["output"];
  };

export type EgdsBasicMap = EgdsBaseMap & {
  __typename?: "EGDSBasicMap";
  bounds?: Maybe<EgdsMapBounds>;
  center?: Maybe<Coordinates>;
  centeredBoundsOptions?: Maybe<EgdsMapCenteredBoundsOptions>;
  computedBoundsOptions?: Maybe<EgdsMapComputedBoundsOptions>;
  config: EgdsBaseMapConfig;
  initialViewport?: Maybe<EgdsMapInitialViewport>;
  label?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Redundant field: locale is already provided at the shared-ui level */
  locale?: Maybe<Scalars["Locale"]["output"]>;
  markers: Array<EgdsMapMarker>;
  zoom?: Maybe<Scalars["Int"]["output"]>;
};

export type EgdsBasicOption = EgdsElement &
  EgdsOption & {
    __typename?: "EGDSBasicOption";
    changeAnalytics?: Maybe<ClientSideAnalytics>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    selected?: Maybe<Scalars["Boolean"]["output"]>;
    value: Scalars["String"]["output"];
  };

export type EgdsBasicPill = EgdsPill & {
  __typename?: "EGDSBasicPill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  deselectAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"]["output"];
  selectedText?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsBasicRadioButton = EgdsRadioButton & {
  __typename?: "EGDSBasicRadioButton";
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  labelSuffix?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type EgdsBasicRadioGroup = EgdsRadioGroup & {
  __typename?: "EGDSBasicRadioGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsBasicRemovablePill = EgdsPill & {
  __typename?: "EGDSBasicRemovablePill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  remove?: Maybe<UiGraphic>;
  removeAccessibility?: Maybe<Scalars["String"]["output"]>;
  removeAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsBasicSectionHeading = EgdsSectionHeading & {
  __typename?: "EGDSBasicSectionHeading";
  graphic?: Maybe<UiGraphic>;
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsBasicSelect = EgdsElement &
  EgdsSelect & {
    __typename?: "EGDSBasicSelect";
    clickAnalytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    label: Scalars["String"]["output"];
    options: Array<EgdsOption>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsBasicStepInput = EgdsElement &
  EgdsStepInput & {
    __typename?: "EGDSBasicStepInput";
    decreaseAnalytics?: Maybe<ClientSideAnalytics>;
    decreaseText?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    increaseAnalytics?: Maybe<ClientSideAnalytics>;
    increaseText?: Maybe<Scalars["String"]["output"]>;
    key?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    max?: Maybe<Scalars["Int"]["output"]>;
    min?: Maybe<Scalars["Int"]["output"]>;
    step: Scalars["Int"]["output"];
    subLabel?: Maybe<Scalars["String"]["output"]>;
    value: Scalars["Int"]["output"];
  };

export type EgdsBasicTab = EgdsElement &
  EgdsTab & {
    __typename?: "EGDSBasicTab";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    clickAnalytics?: Maybe<ClientSideAnalytics>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    tabId: Scalars["String"]["output"];
  };

export type EgdsBasicTabs = EgdsElement &
  EgdsTabs & {
    __typename?: "EGDSBasicTabs";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    selectedTabId?: Maybe<Scalars["String"]["output"]>;
    tabs: Array<EgdsTab>;
  };

export type EgdsBasicThumbnailMediaGallery = EgdsThumbnailMediaGallery & {
  __typename?: "EGDSBasicThumbnailMediaGallery";
  tabs?: Maybe<EgdsTabs>;
  thumbnails: Array<MediaItem>;
  toolbar: EgdsDialogToolbar;
};

export type EgdsBasicTraveler = EgdsTraveler & {
  __typename?: "EGDSBasicTraveler";
  ages?: Maybe<Array<EgdsSelect>>;
  count: EgdsStepInput;
  type?: Maybe<EgdsTravelerType>;
};

export type EgdsBasicTravelerSelector = EgdsElement &
  EgdsTravelerSelector & {
    __typename?: "EGDSBasicTravelerSelector";
    closeButton: EgdsButton;
    doneButton: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    subTitles?: Maybe<Array<Scalars["String"]["output"]>>;
    title: Scalars["String"]["output"];
    travelers?: Maybe<EgdsTravelers>;
    validations?: Maybe<Array<EgdsInputValidation>>;
  };

export type EgdsBasicTriggerPill = EgdsPill & {
  __typename?: "EGDSBasicTriggerPill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  deselectAnalytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"]["output"];
  trigger?: Maybe<UiGraphic>;
};

export type EgdsBulletedList = EgdsElement &
  EgdsTextList & {
    __typename?: "EGDSBulletedList";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    listItems: Array<EgdsTextListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export interface EgdsButton {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsButtonStyle = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type EgdsCard = EgdsImageCard | EgdsImageLinkCard | EgdsMarkCard | EgdsStandardMessagingCard;

export type EgdsCardBackgroundTheme =
  | "GLOBAL_LOYALTY_EXTRAHIGH"
  | "GLOBAL_LOYALTY_HIGH"
  | "GLOBAL_LOYALTY_LOW"
  | "GLOBAL_LOYALTY_MIDDLE"
  | "GLOBAL_LOYALTY_STANDARD"
  | "HIGHLIGHT"
  | "PRIMARY"
  | "SECONDARY";

export type EgdsCardCarouselItem = EgdsCarouselItem & {
  __typename?: "EGDSCardCarouselItem";
  card: EgdsCard;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsCardThemes = "BORDERED" | "PADDED";

export type EgdsCardinalLocalizedText = EgdsElement &
  EgdsTemplateModels &
  EgdsText & {
    __typename?: "EGDSCardinalLocalizedText";
    accessibilityTemplates?: Maybe<Array<CardinalTemplate>>;
    accessibilityText?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    models: Array<TemplateModel>;
    secondaryTemplates?: Maybe<Array<CardinalTemplate>>;
    templates: Array<CardinalTemplate>;
    text: Scalars["String"]["output"];
  };

export type EgdsCarousel = {
  __typename?: "EGDSCarousel";
  carouselId?: Maybe<Scalars["String"]["output"]>;
  items: Array<EgdsCarouselItem>;
  nextButton?: Maybe<EgdsButton>;
  previousButton?: Maybe<EgdsButton>;
};

export type EgdsCarouselContainer = EgdsGalleryCarousel & {
  __typename?: "EGDSCarouselContainer";
  accessibilityHeadingText: Scalars["String"]["output"];
  media: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
};

export interface EgdsCarouselItem {
  id?: Maybe<Scalars["String"]["output"]>;
}

export interface EgdsCheckBox {
  description?: Maybe<Scalars["String"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label: EgdsText;
  name?: Maybe<Scalars["String"]["output"]>;
  required: Scalars["Boolean"]["output"];
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsCheckBoxGroup = {
  __typename?: "EGDSCheckBoxGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<EgdsCheckBox>;
};

export type EgdsCheckBoxState = "CHECKED" | "INDETERMINATE" | "UNCHECKED";

export type EgdsComposableTextElement =
  | EgdsBulletedList
  | EgdsComposableTextElementSectionContainer
  | EgdsGraphicText
  | EgdsHeading
  | EgdsIconText
  | EgdsInlineLink
  | EgdsLinkList
  | EgdsOrderedList
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText
  | EgdsTextIconList
  | EgdsTextListSection
  | EgdsTextSection
  | EgdsUnorderedList;

export interface EgdsComposableTextElementContainer {
  elements: Array<EgdsComposableTextElement>;
}

export type EgdsComposableTextElementSectionContainer = EgdsComposableTextElementContainer &
  EgdsElement & {
    __typename?: "EGDSComposableTextElementSectionContainer";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsComposableTextElement>;
    heading?: Maybe<EgdsSectionHeading>;
  };

export type EgdsCompositeDatePicker = {
  __typename?: "EGDSCompositeDatePicker";
  dateFormat: Scalars["String"]["output"];
  datePicker: EgdsDatePicker;
  endDateInputField?: Maybe<EgdsDatePickerInputField>;
  showDatePicker: Scalars["Boolean"]["output"];
  singleDateSelect: Scalars["Boolean"]["output"];
  singleMode: Scalars["Boolean"]["output"];
  startDateInputField: EgdsDatePickerInputField;
};

export type EgdsCompositeLocalizedText = EgdsElement &
  EgdsText & {
    __typename?: "EGDSCompositeLocalizedText";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    models: Array<CompositeLocalizedTextModel>;
    template: Scalars["String"]["output"];
    text: Scalars["String"]["output"];
  };

export interface EgdsDatePicker {
  buttonText: Scalars["String"]["output"];
  clearButtonText?: Maybe<Scalars["String"]["output"]>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars["String"]["output"];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars["String"]["output"]>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars["Boolean"]["output"]>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars["String"]["output"];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
}

export type EgdsDatePickerContent = {
  __typename?: "EGDSDatePickerContent";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  dateKey: Scalars["String"]["output"];
  text?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsDatePickerFlexibilityCalendarContent = {
  __typename?: "EGDSDatePickerFlexibilityCalendarContent";
  flexibleDateOptions: Array<EgdsBasicPill>;
  formElementId: Scalars["String"]["output"];
  title?: Maybe<EgdsPlainText>;
};

export type EgdsDatePickerFlexibilityContent = {
  __typename?: "EGDSDatePickerFlexibilityContent";
  calendarContent?: Maybe<EgdsDatePickerFlexibilityCalendarContent>;
  contentSelector?: Maybe<EgdsTabs>;
  flexibilityLabel?: Maybe<EgdsCardinalLocalizedText>;
  /** @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type */
  flexibleDateOptions: Array<EgdsBasicPill>;
  flexibleDatesContent?: Maybe<EgdsDatePickerFlexibilityDatesContent>;
  /** @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type */
  formElementId: Scalars["String"]["output"];
  /** @deprecated Moved to EGDSDatePickerFlexibilityCalendarContent type */
  title?: Maybe<EgdsPlainText>;
};

export type EgdsDatePickerFlexibilityDatesContent = {
  __typename?: "EGDSDatePickerFlexibilityDatesContent";
  monthsSelection?: Maybe<EgdsDatePickerFlexibleMonths>;
  nightsSelection?: Maybe<EgdsDatePickerFlexibleNights>;
};

export type EgdsDatePickerFlexibleMonths = {
  __typename?: "EGDSDatePickerFlexibleMonths";
  heading?: Maybe<Scalars["String"]["output"]>;
  options: EgdsToggleButtonGroup;
  subHeading: Scalars["String"]["output"];
};

export type EgdsDatePickerFlexibleNights = {
  __typename?: "EGDSDatePickerFlexibleNights";
  heading?: Maybe<Scalars["String"]["output"]>;
  includeWeekend: EgdsBasicCheckBox;
  options: Array<EgdsBasicPill>;
};

export type EgdsDatePickerInputField = EgdsInputField & {
  __typename?: "EGDSDatePickerInputField";
  dateFormat?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export interface EgdsDateRange {
  loadingMessage?: Maybe<Scalars["String"]["output"]>;
  results: Array<EgdsDateRangeResult>;
}

export type EgdsDateRangePicker = EgdsDatePicker & {
  __typename?: "EGDSDateRangePicker";
  buttonText: Scalars["String"]["output"];
  buttonTextTemplate?: Maybe<EgdsLocalizedText>;
  clearButtonText?: Maybe<Scalars["String"]["output"]>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars["String"]["output"];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  dateRangeFormat?: Maybe<EgdsBasicLocalizedText>;
  endDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  endDatePlaceholderText: Scalars["String"]["output"];
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars["String"]["output"]>;
  maxNumberOfDaysSelected?: Maybe<Scalars["Int"]["output"]>;
  nextButtonAnalytics?: Maybe<ClientSideAnalytics>;
  previousButtonAnalytics?: Maybe<ClientSideAnalytics>;
  sameDaySelectionAllowed: Scalars["Boolean"]["output"];
  selectedEndDate?: Maybe<Date>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars["Boolean"]["output"]>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars["String"]["output"];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
};

export interface EgdsDateRangeResult {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  date: Date;
  label: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  theme?: Maybe<EgdsDateRangeResultTheme>;
}

export type EgdsDateRangeResultTheme = "LINK" | "POSITIVE";

export type EgdsDayName = "FRI" | "MON" | "SAT" | "SUN" | "THU" | "TUE" | "WED";

export type EgdsDecorativeText = "HIGHLIGHT" | "LINE_THROUGH" | "SUBSCRIPT" | "SUPERSCRIPT" | "UNDERLINE";

export interface EgdsDetailMediaGallery {
  closeText: Scalars["String"]["output"];
  mediaItems: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
}

export interface EgdsDialog {
  closeAnalytics?: Maybe<ClientSideAnalytics>;
}

export interface EgdsDialogFooter {
  buttons: Array<EgdsButton>;
}

export type EgdsDialogSpannableToolbar = {
  __typename?: "EGDSDialogSpannableToolbar";
  actions?: Maybe<Array<EgdsToolbarActionItem>>;
  closeText?: Maybe<Scalars["String"]["output"]>;
  navigationType?: Maybe<NavigationType>;
  title: Scalars["String"]["output"];
};

export type EgdsDialogToolbar = {
  __typename?: "EGDSDialogToolbar";
  actions?: Maybe<Array<EgdsToolbarActionButton>>;
  closeText?: Maybe<Scalars["String"]["output"]>;
  subTitle?: Maybe<EgdsToolbarSubtitle>;
  title: Scalars["String"]["output"];
};

export type EgdsDismissAction = {
  __typename?: "EGDSDismissAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsDynamicMapConfig = EgdsBaseMapConfig & {
  __typename?: "EGDSDynamicMapConfig";
  accessToken: Scalars["String"]["output"];
  /** @deprecated Prefer using data provided by EGDSDynamicMapConfig and not the one from this object */
  config?: Maybe<MapConfiguration>;
  egdsMapProvider: EgdsMapProvider;
  externalConfigContent?: Maybe<Scalars["String"]["output"]>;
  externalConfigEndpoint?: Maybe<HttpUri>;
  mapId?: Maybe<Scalars["String"]["output"]>;
};

export interface EgdsElement {
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsElementWrapper = {
  __typename?: "EGDSElementWrapper";
  element: EgdsElement;
};

export interface EgdsElements {
  elements: Array<EgdsElement>;
}

export type EgdsEmailInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSEmailInputField";
    autofill?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsErrorSummary = EgdsElement & {
  __typename?: "EGDSErrorSummary";
  analytics?: Maybe<ClientSideAnalytics>;
  body?: Maybe<EgdsText>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  goToErrorLinkText?: Maybe<Scalars["String"]["output"]>;
  goToFirstErrorLinkText?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  headingTemplate?: Maybe<EgdsLocalizedText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export interface EgdsExpando {
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedLabel: Scalars["String"]["output"];
}

export type EgdsExpandoCard = EgdsExpando & {
  __typename?: "EGDSExpandoCard";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedLabel: Scalars["String"]["output"];
  subtitle?: Maybe<Scalars["String"]["output"]>;
  triggerPosition?: Maybe<ExpandoTriggerPosition>;
};

export type EgdsExpandoLink = EgdsExpando & {
  __typename?: "EGDSExpandoLink";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  expandedLabel: Scalars["String"]["output"];
};

export type EgdsExpandoListItem = EgdsExpando & {
  __typename?: "EGDSExpandoListItem";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedLabel: Scalars["String"]["output"];
  triggerPosition?: Maybe<ExpandoTriggerPosition>;
};

export type EgdsExpandoPeek = EgdsExpando & {
  __typename?: "EGDSExpandoPeek";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  expandedLabel: Scalars["String"]["output"];
  items?: Maybe<Scalars["Int"]["output"]>;
  lines?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Removed after EGDS graduated of Expando. Replace with `lines` or `items`. */
  minimalHeight?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Removed after EGDS graduated of Expando. */
  threshold?: Maybe<Scalars["Int"]["output"]>;
};

export type EgdsFullScreenDialog = EgdsDialog & {
  __typename?: "EGDSFullScreenDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  toolbar: EgdsDialogToolbar;
};

export interface EgdsGalleryCarousel {
  accessibilityHeadingText: Scalars["String"]["output"];
  media: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsGraphicMenu = EgdsMenu & {
  __typename?: "EGDSGraphicMenu";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  graphic: UiGraphic;
  subMenus: Array<EgdsSubMenu>;
  title: Scalars["String"]["output"];
};

export type EgdsGraphicText = EgdsText & {
  __typename?: "EGDSGraphicText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic: UiGraphic;
  text: Scalars["String"]["output"];
  trailingGraphics?: Maybe<Array<UiGraphic>>;
};

export type EgdsHeading = EgdsElement &
  EgdsText & {
    __typename?: "EGDSHeading";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DO NOT USE. Client should handle heading tags. */
    headingType?: Maybe<EgdsHeadingStyle>;
    text: Scalars["String"]["output"];
  };

export type EgdsHeadingStyle = "H1" | "H2" | "H3" | "H4" | "H5" | "H6" | "H7" | "H8";

export type EgdsIconPosition = "LEADING" | "TRAILING";

export type EgdsIconRating = EgdsRating & {
  __typename?: "EGDSIconRating";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  rating: Scalars["Float"]["output"];
};

export type EgdsIconText = EgdsText & {
  __typename?: "EGDSIconText";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type EgdsImageCard = {
  __typename?: "EGDSImageCard";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsImageLink = EgdsElement & {
  __typename?: "EGDSImageLink";
  action: UiLinkAction;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  image: Image;
};

export type EgdsImageLinkCard = EgdsElement & {
  __typename?: "EGDSImageLinkCard";
  description?: Maybe<Scalars["String"]["output"]>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  link: EgdsImageLink;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsInfantSeat = "IN_SEAT" | "ON_LAP";

export type EgdsInfantSeatRadioButton = EgdsElement &
  EgdsRadioButton & {
    __typename?: "EGDSInfantSeatRadioButton";
    description?: Maybe<Scalars["String"]["output"]>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelSuffix?: Maybe<Scalars["String"]["output"]>;
    seatType: EgdsInfantSeat;
    value: Scalars["String"]["output"];
  };

export type EgdsInlineDialogFooter = EgdsDialogFooter & {
  __typename?: "EGDSInlineDialogFooter";
  buttons: Array<EgdsButton>;
};

export type EgdsInlineLink = EgdsText & {
  __typename?: "EGDSInlineLink";
  action: UiLinkAction;
  disabled: Scalars["Boolean"]["output"];
  size: EgdsLinkSize;
  text: Scalars["String"]["output"];
};

export interface EgdsInputField {
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
}

export interface EgdsInputValidation {
  errorMessage: Scalars["String"]["output"];
}

export type EgdsLevelList = {
  __typename?: "EGDSLevelList";
  items: Array<EgdsLevelListItem>;
};

export type EgdsLevelListItem = {
  __typename?: "EGDSLevelListItem";
  level: Scalars["Int"]["output"];
  text: EgdsText;
  type: EgdsTextListType;
};

export type EgdsLinkList = EgdsElement & {
  __typename?: "EGDSLinkList";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  listItems: Array<EgdsStandardLink>;
};

export type EgdsLinkSize = "LARGE" | "MEDIUM" | "SMALL";

export interface EgdsList {
  /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
  items: Array<EgdsText>;
}

export type EgdsLoaderDialog = EgdsDialog & {
  __typename?: "EGDSLoaderDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
};

export type EgdsLocalizedText = EgdsBasicLocalizedText | EgdsCardinalLocalizedText | EgdsCompositeLocalizedText;

export type EgdsLocalizedTextTemplate = EgdsElement &
  EgdsText & {
    __typename?: "EGDSLocalizedTextTemplate";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    template: Scalars["String"]["output"];
    text: Scalars["String"]["output"];
    token: Scalars["String"]["output"];
  };

export type EgdsLoyaltyBadge = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSLoyaltyBadge";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]["output"]>;
    text?: Maybe<Scalars["String"]["output"]>;
    theme?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsMapBounds = {
  __typename?: "EGDSMapBounds";
  northeast: Coordinates;
  southwest: Coordinates;
};

export type EgdsMapCenteredBoundsOptions = {
  __typename?: "EGDSMapCenteredBoundsOptions";
  centeredOn: Coordinates;
  coordinates: Array<Coordinates>;
};

export type EgdsMapComputedBoundsOptions = {
  __typename?: "EGDSMapComputedBoundsOptions";
  coordinates: Array<Coordinates>;
  gaiaId?: Maybe<Scalars["String"]["output"]>;
  interpolationRatio?: Maybe<Scalars["Float"]["output"]>;
  lowerQuantile?: Maybe<Scalars["Float"]["output"]>;
  marginMultiplier?: Maybe<Scalars["Float"]["output"]>;
  minMargin?: Maybe<Scalars["Int"]["output"]>;
  minimumPins?: Maybe<Scalars["Int"]["output"]>;
  upperQuantile?: Maybe<Scalars["Float"]["output"]>;
};

export type EgdsMapFeature = EgdsMapMarker & {
  __typename?: "EGDSMapFeature";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerIconType?: Maybe<Array<Scalars["String"]["output"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerType?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  onEnterAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  onSelectAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["Float"]["output"]>;
  qualifiers: Array<Scalars["String"]["output"]>;
  score?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated Use name (label) or description (sublabel) */
  text?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<EgdsMapFeatureType>;
};

export type EgdsMapFeatureType = "PLACE" | "PRODUCT" | "PROPERTY";

export type EgdsMapInitialViewport =
  | "BOUNDS"
  | "CENTERED_COMPUTED_BOUNDS"
  | "CENTER_AND_ZOOM"
  | "COMPUTED_BOUNDS"
  | "FIT_MARKERS";

export interface EgdsMapMarker {
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerIconType?: Maybe<Array<Scalars["String"]["output"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  /** @deprecated Map is identifying which icon to use thanks to qualifiers. */
  markerType?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use name (label) or description (sublabel) */
  text?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsMapMarkerStatus = "AVAILABLE" | "FILTERED" | "SOLD_OUT" | "UNKNOWN";

export type EgdsMapPositionMode = "FIT_BOUNDS" | "ZOOM_AND_CENTER";

export type EgdsMapProvider = "GOOGLE" | "MAPBOX" | "MAPKIT";

export type EgdsMark =
  | "AARP"
  | "AARP_MEMBER_BENEFITS"
  | "ADDON"
  | "AIR_NEW_ZEALAND"
  | "APPLE"
  | "APPLE_MAPS"
  | "APP_CTX"
  | "APP_EBOOK"
  | "APP_EXP"
  | "APP_HOTWIRE"
  | "APP_ORB"
  | "APP_TVLY"
  | "APP_WOTIF"
  | "BONUS_EBOOK"
  | "COMMISSION_TIER_FOUR"
  | "COMMISSION_TIER_ONE"
  | "COMMISSION_TIER_THREE"
  | "COMMISSION_TIER_TWO"
  | "FACEBOOK"
  | "FLAGS__AR"
  | "FLAGS__AS"
  | "FLAGS__AT"
  | "FLAGS__AU"
  | "FLAGS__BE"
  | "FLAGS__BO"
  | "FLAGS__BR"
  | "FLAGS__BZ"
  | "FLAGS__CA"
  | "FLAGS__CH"
  | "FLAGS__CL"
  | "FLAGS__CN"
  | "FLAGS__CO"
  | "FLAGS__CR"
  | "FLAGS__CZ"
  | "FLAGS__DE"
  | "FLAGS__DK"
  | "FLAGS__EC"
  | "FLAGS__EE"
  | "FLAGS__EG"
  | "FLAGS__ES"
  | "FLAGS__EU"
  | "FLAGS__FI"
  | "FLAGS__FR"
  | "FLAGS__GR"
  | "FLAGS__GT"
  | "FLAGS__GY"
  | "FLAGS__HK"
  | "FLAGS__HN"
  | "FLAGS__HR"
  | "FLAGS__HU"
  | "FLAGS__ID"
  | "FLAGS__IE"
  | "FLAGS__IL"
  | "FLAGS__IN"
  | "FLAGS__IS"
  | "FLAGS__IT"
  | "FLAGS__JP"
  | "FLAGS__KR"
  | "FLAGS__L1"
  | "FLAGS__LT"
  | "FLAGS__LV"
  | "FLAGS__MX"
  | "FLAGS__MY"
  | "FLAGS__NI"
  | "FLAGS__NL"
  | "FLAGS__NO"
  | "FLAGS__NZ"
  | "FLAGS__PA"
  | "FLAGS__PE"
  | "FLAGS__PH"
  | "FLAGS__PL"
  | "FLAGS__PT"
  | "FLAGS__PY"
  | "FLAGS__RU"
  | "FLAGS__SA"
  | "FLAGS__SE"
  | "FLAGS__SG"
  | "FLAGS__SK"
  | "FLAGS__SR"
  | "FLAGS__SV"
  | "FLAGS__TH"
  | "FLAGS__TR"
  | "FLAGS__TW"
  | "FLAGS__UA"
  | "FLAGS__UAE"
  | "FLAGS__UK"
  | "FLAGS__US"
  | "FLAGS__UY"
  | "FLAGS__VE"
  | "FLAGS__VN"
  | "FLAGS__WW"
  | "FLAGS__ZA"
  | "GOOGLE"
  | "GOOGLE_LOGO"
  | "GOOGLE_MAP"
  | "GOOGLE_TRANSLATED"
  | "HAWAIIAN"
  | "HOTWIRE_HOTRATE_CIRCLE"
  | "LINE"
  | "LOYALTY_HOTELS__HIGH_TIER"
  | "LOYALTY_HOTELS__LOW_TIER"
  | "LOYALTY_HOTELS__MIDDLE_TIER"
  | "LOYALTY_HOTELS__REWARDS"
  | "LOYALTY_HOTELS__STAMP_EMPTY"
  | "LOYALTY_HOTELS__STAMP_PENDING"
  | "LOYALTY_HOTELS__STAMP_SEARCHED"
  | "LOYALTY_TVLY"
  | "MAPBOX"
  | "MIP_PRIMARY"
  | "MIP_REVERSE"
  | "MIP_SECONDARY"
  | "MOD_CTX"
  | "MOD_EBOOK"
  | "MOD_EXP"
  | "MOD_HOTELS"
  | "MOD_ORB"
  | "MOD_TVLY"
  | "MOD_WOTIF"
  | "NAVER"
  | "ONEKEY__ABRITEL"
  | "ONEKEY__BLUE"
  | "ONEKEY__BOOKABACH"
  | "ONEKEY__CASH"
  | "ONEKEY__EXPEDIA"
  | "ONEKEY__FEWODIREKT"
  | "ONEKEY__GOLD"
  | "ONEKEY__HOTELS__CHINESE"
  | "ONEKEY__HOTELS__ENGLISH"
  | "ONEKEY__HOTELS__PORTUGUESE"
  | "ONEKEY__HOTELS__SPANISH"
  | "ONEKEY__KNOCKEDOUT__ALWAYS_LIGHT"
  | "ONEKEY__PLATINUM"
  | "ONEKEY__SILVER"
  | "ONEKEY__STANDARD"
  | "ONEKEY__STANDARD__ALWAYS_LIGHT"
  | "ONEKEY__STAYZ"
  | "ONEKEY__VRBO"
  | "ONEKEY__WORDMARK"
  | "ONEKEY__WORDMARK__ALWAYS_LIGHT"
  | "PAYMENT__7_ELEVEN"
  | "PAYMENT__AFFIRM"
  | "PAYMENT__AIRPLUS"
  | "PAYMENT__ALIPAY"
  | "PAYMENT__AMEX"
  | "PAYMENT__APPLEPAY"
  | "PAYMENT__CHASE"
  | "PAYMENT__CLICKTOPAY"
  | "PAYMENT__DINERS_CLUB_INTERNATIONAL"
  | "PAYMENT__DISCOVER"
  | "PAYMENT__GOOGLE_PAY"
  | "PAYMENT__IDEAL"
  | "PAYMENT__JAPAN_CREDIT_BUREAU"
  | "PAYMENT__KLARNA"
  | "PAYMENT__MAESTRO"
  | "PAYMENT__MASTERCARD"
  | "PAYMENT__MASTERPASS"
  | "PAYMENT__PAYPAL"
  | "PAYMENT__SAMSUNG_CARD"
  | "PAYMENT__UATP"
  | "PAYMENT__UNION_PAY"
  | "PAYMENT__VISA"
  | "PLUS_CIRCLE"
  | "PLUS_CIRCLE_CTX"
  | "PLUS_CIRCLE_EBOOK"
  | "PLUS_CIRCLE_ORB"
  | "PLUS_CIRCLE_TVLY"
  | "PLUS_CIRCLE_WOTIF"
  | "PREMIUM_TVLY"
  | "REWARDS_ORB"
  | "TRIPADVISOR"
  | "TRIVAGO"
  | "VAP_HOTELS__HIGH_TIER"
  | "VAP_HOTELS__LOW_TIER"
  | "VAP_HOTELS__MIDDLE_TIER"
  | "VIP_BEX"
  | "VIP_CIRCLE_BEX"
  | "VIP_CIRCLE_EBOOKERS"
  | "VIP_CIRCLE_HOTELS"
  | "VIP_CIRCLE_ORBITZ"
  | "VIP_EBOOKERS"
  | "VIP_ORBITZ"
  | "VIRGIN_AU"
  | "VRBO";

export type EgdsMarkCard = {
  __typename?: "EGDSMarkCard";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<Mark>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsMarkerUrls = {
  __typename?: "EGDSMarkerUrls";
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type EgdsMaxLengthInputValidation = EgdsInputValidation & {
  __typename?: "EGDSMaxLengthInputValidation";
  errorMessage: Scalars["String"]["output"];
  maxLength: Scalars["Int"]["output"];
};

export type EgdsMediaCarouselItem = EgdsCarouselItem & {
  __typename?: "EGDSMediaCarouselItem";
  id?: Maybe<Scalars["String"]["output"]>;
  media: MediaItem;
};

export interface EgdsMenu {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  subMenus: Array<EgdsSubMenu>;
  title: Scalars["String"]["output"];
}

export type EgdsMenuListIconTextItem = EgdsMenuListItem & {
  __typename?: "EGDSMenuListIconTextItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  graphic: UiGraphic;
  graphicAnalytics?: Maybe<ClientSideAnalytics>;
  title: Scalars["String"]["output"];
};

export interface EgdsMenuListItem {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  title: Scalars["String"]["output"];
}

export type EgdsMenuStandardItem = EgdsMenuListItem & {
  __typename?: "EGDSMenuStandardItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title: Scalars["String"]["output"];
};

export type EgdsMenuTextItem = EgdsMenuListItem & {
  __typename?: "EGDSMenuTextItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  title: Scalars["String"]["output"];
};

export type EgdsMinLengthInputValidation = EgdsInputValidation & {
  __typename?: "EGDSMinLengthInputValidation";
  errorMessage: Scalars["String"]["output"];
  minLength: Scalars["Int"]["output"];
};

export type EgdsMoreInfoTrigger = {
  __typename?: "EGDSMoreInfoTrigger";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon: Icon;
  text: Scalars["String"]["output"];
  triggerAction: EgdsMoreInfoTriggerAction;
};

export type EgdsMoreInfoTriggerAction = {
  __typename?: "EGDSMoreInfoTriggerAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  sheet: EgdsSheet;
  text: Scalars["String"]["output"];
};

export type EgdsNestedLevelOneIconListItem = EgdsNestedLevelOneListItem & {
  __typename?: "EGDSNestedLevelOneIconListItem";
  icon: Icon;
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars["String"]["output"];
};

export type EgdsNestedLevelOneList = {
  __typename?: "EGDSNestedLevelOneList";
  items: Array<EgdsNestedLevelOneListItem>;
  size: EgdsTextListSize;
  type: EgdsTextListType;
};

export interface EgdsNestedLevelOneListItem {
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars["String"]["output"];
}

export type EgdsNestedLevelOneStandardListItem = EgdsNestedLevelOneListItem & {
  __typename?: "EGDSNestedLevelOneStandardListItem";
  style?: Maybe<EgdsTextListItemStyle>;
  text: Scalars["String"]["output"];
};

export type EgdsNumberInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSNumberInputField";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    max?: Maybe<Scalars["Float"]["output"]>;
    min?: Maybe<Scalars["Float"]["output"]>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    step?: Maybe<Scalars["Float"]["output"]>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsOpenDatePickerAction = UiAction & {
  __typename?: "EGDSOpenDatePickerAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  datePicker: EgdsDatePicker;
};

export type EgdsOpenTravelerSelectorAction = UiAction & {
  __typename?: "EGDSOpenTravelerSelectorAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  travelerSelector: EgdsTravelerSelector;
};

export interface EgdsOption {
  label: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  value: Scalars["String"]["output"];
}

export type EgdsOrderedList = EgdsElement &
  EgdsList &
  EgdsTextList & {
    __typename?: "EGDSOrderedList";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
    items: Array<EgdsText>;
    listItems: Array<EgdsTextListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export type EgdsOverlayButton = EgdsButton &
  EgdsElement & {
    __typename?: "EGDSOverlayButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    action?: Maybe<UiLinkAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsParagraph = EgdsElement &
  EgdsText & {
    __typename?: "EGDSParagraph";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated DO NOT USE. Client should handle styling size. */
    size: Scalars["Int"]["output"];
    style: EgdsParagraphStyle;
    text: Scalars["String"]["output"];
  };

export type EgdsParagraphStyle = "P1" | "P2" | "P3";

export type EgdsPasswordInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSPasswordInputField";
    autofill?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    passwordVisible?: Maybe<Scalars["Boolean"]["output"]>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsPathType = "CYCLING" | "DRIVING" | "FLYING" | "TRANSIT" | "WALKING";

export type EgdsPhotosphereCameraMovement = "AUTO_PAN" | "DISABLED" | "FULL_MOVE";

export type EgdsPhotosphereImage = {
  __typename?: "EGDSPhotosphereImage";
  /** @deprecated Use description instead */
  altText?: Maybe<Scalars["String"]["output"]>;
  aspectRatio?: Maybe<AspectRatio>;
  cameraMovement?: Maybe<EgdsPhotosphereCameraMovement>;
  description: Scalars["String"]["output"];
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars["URL"]["output"];
};

export type EgdsPhotosphereImageUrlArgs = {
  dimensions?: InputMaybe<DimensionsInput>;
};

export interface EgdsPill {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<UiGraphic>;
  id?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
}

export type EgdsPlainText = EgdsText & {
  __typename?: "EGDSPlainText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type EgdsPriceCalendar = {
  __typename?: "EGDSPriceCalendar";
  alertText?: Maybe<EgdsGraphicText>;
  calendarPrices: Array<EgdsPriceCalendarByDay>;
  coverageAnalytics?: Maybe<ClientSideAnalytics>;
  disclaimerText: Scalars["String"]["output"];
};

export type EgdsPriceCalendarByDay = {
  __typename?: "EGDSPriceCalendarByDay";
  date: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  selectAnalytics?: Maybe<ClientSideAnalytics>;
  theme?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsPriceLockup = {
  __typename?: "EGDSPriceLockup";
  accessibilityPrice: Scalars["String"]["output"];
  accessibilityStrikeThroughDialogTrigger?: Maybe<Scalars["String"]["output"]>;
  isPointsFirst: Scalars["Boolean"]["output"];
  isStrikeThroughFirst: Scalars["Boolean"]["output"];
  leadingCaption?: Maybe<Scalars["String"]["output"]>;
  lockupFormattedPoints?: Maybe<Scalars["String"]["output"]>;
  lockupPrice?: Maybe<Scalars["String"]["output"]>;
  priceSubtextBold?: Maybe<Scalars["String"]["output"]>;
  priceSubtextFirst?: Maybe<Array<Scalars["String"]["output"]>>;
  priceSubtextStandard?: Maybe<Array<Scalars["String"]["output"]>>;
  strikeThroughPrice?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsProductRatingLink = {
  __typename?: "EGDSProductRatingLink";
  action: EgdsProductRatingShowTextAction;
  link: EgdsStandardLink;
};

export type EgdsProductRatingShowTextAction = {
  __typename?: "EGDSProductRatingShowTextAction";
  text: Scalars["String"]["output"];
};

export type EgdsProductRatingSummary = {
  __typename?: "EGDSProductRatingSummary";
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  supportingMessages: Array<EgdsProductRatingSupportingMessage>;
};

export type EgdsProductRatingSupportingMessage = EgdsPlainText | EgdsProductRatingLink;

export type EgdsProgramBadge = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSProgramBadge";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]["output"]>;
    text?: Maybe<Scalars["String"]["output"]>;
    theme?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsProgressBar = {
  __typename?: "EGDSProgressBar";
  accessibilityLabel: Scalars["String"]["output"];
  percent: Scalars["Int"]["output"];
  progressDescription?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsProgressBarGroup = {
  __typename?: "EGDSProgressBarGroup";
  progressBars: Array<EgdsProgressBar>;
};

export interface EgdsRadioButton {
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  labelSuffix?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
}

export interface EgdsRadioGroup {
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsRangeInputValidation = EgdsInputValidation & {
  __typename?: "EGDSRangeInputValidation";
  errorMessage: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  min: Scalars["Int"]["output"];
};

export interface EgdsRating {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  rating: Scalars["Float"]["output"];
}

export type EgdsRegexInputValidation = EgdsInputValidation & {
  __typename?: "EGDSRegexInputValidation";
  errorMessage: Scalars["String"]["output"];
  pattern: Scalars["String"]["output"];
};

export type EgdsRemovablePill = UiRemovablePill & {
  __typename?: "EGDSRemovablePill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  remove?: Maybe<Icon>;
  selected: Scalars["Boolean"]["output"];
};

export type EgdsRequiredInputValidation = EgdsInputValidation & {
  __typename?: "EGDSRequiredInputValidation";
  errorMessage: Scalars["String"]["output"];
};

export type EgdsRoomsTravelerSelector = EgdsElement &
  EgdsTravelerSelector & {
    __typename?: "EGDSRoomsTravelerSelector";
    addAnotherRoomButton: EgdsButton;
    closeButton: EgdsButton;
    doneButton: EgdsButton;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use infantsOnLap and infantsInSeat instead. */
    infantSeatSelection?: Maybe<EgdsTravelerInfantSeatSelection>;
    maxRoomCount?: Maybe<Scalars["Int"]["output"]>;
    rooms: Array<EgdsTravelerSelectorRoom>;
    title: Scalars["String"]["output"];
    validations?: Maybe<Array<EgdsInputValidation>>;
  };

export type EgdsSameValueInputValidation = EgdsInputValidation & {
  __typename?: "EGDSSameValueInputValidation";
  errorMessage: Scalars["String"]["output"];
};

export interface EgdsSearchForm {
  actionURL: Scalars["String"]["output"];
  elements: Array<EgdsSearchFormElement>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  playback?: Maybe<EgdsSearchPlayback>;
  search?: Maybe<EgdsButton>;
}

export type EgdsSearchFormAction =
  | EgdsOpenDatePickerAction
  | EgdsOpenTravelerSelectorAction
  | EgdsSearchFormSelectAction
  | EgdsShowSearchFormAction
  | EgdsSubmitSearchFormAction
  | OpenDurationSpanAction
  | OpenTypeaheadAction;

export type EgdsSearchFormButton = EgdsButton &
  EgdsElement & {
    __typename?: "EGDSSearchFormButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
     *         or a union type with a manageable number of members
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    onClick?: Maybe<EgdsSearchFormAction>;
    primary?: Maybe<Scalars["String"]["output"]>;
    style?: Maybe<EgdsButtonStyle>;
  };

export type EgdsSearchFormDatePickerField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "EGDSSearchFormDatePickerField";
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsSearchFormElement =
  | EgdsSearchFormDatePickerField
  | EgdsSearchFormInputField
  | EgdsSearchFormLocationField
  | EgdsSearchFormLocationsField
  | EgdsSearchFormTravelersField
  | SearchFormDurationSpanField;

export interface EgdsSearchFormField {
  action?: Maybe<EgdsSearchFormAction>;
}

export type EgdsSearchFormInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSSearchFormInputField";
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsSearchFormLocationField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "EGDSSearchFormLocationField";
    action?: Maybe<EgdsSearchFormAction>;
    changeAccessibility?: Maybe<Scalars["String"]["output"]>;
    changeAnalytics?: Maybe<ClientSideAnalytics>;
    closeAccessibility?: Maybe<Scalars["String"]["output"]>;
    closeAnalytics?: Maybe<ClientSideAnalytics>;
    debounceRate?: Maybe<Scalars["Int"]["output"]>;
    egdsElementId: Scalars["String"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    /** @deprecated Use multiLocations */
    locations?: Maybe<Array<Scalars["String"]["output"]>>;
    multiLocations?: Maybe<Array<EgdsSearchFormSelectedLocation>>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    regionId?: Maybe<Scalars["String"]["output"]>;
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsSearchFormLocationsField = EgdsElement & {
  __typename?: "EGDSSearchFormLocationsField";
  destination: EgdsSearchFormLocationField;
  egdsElementId: Scalars["String"]["output"];
  horizontalSwapper?: Maybe<EgdsButton>;
  origin: EgdsSearchFormLocationField;
  validations?: Maybe<Array<EgdsInputValidation>>;
  verticalSwapper?: Maybe<EgdsButton>;
};

export type EgdsSearchFormSelectAction = {
  __typename?: "EGDSSearchFormSelectAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  apply?: Maybe<EgdsButton>;
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<EgdsOption>;
};

export type EgdsSearchFormSelectedLocation = {
  __typename?: "EGDSSearchFormSelectedLocation";
  text: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsSearchFormTravelersField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "EGDSSearchFormTravelersField";
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export interface EgdsSearchInputs {
  shoppingSearchCriteria?: Maybe<ShoppingSearchCriteria>;
}

export type EgdsSearchPlayback = {
  __typename?: "EGDSSearchPlayback";
  hideButton: EgdsButton;
  primary: EgdsText;
  primaryTemplate?: Maybe<EgdsLocalizedText>;
  secondaries: Array<EgdsText>;
  showAction: EgdsSearchFormAction;
};

export type EgdsSectionContainer = EgdsElement &
  EgdsElements & {
    __typename?: "EGDSSectionContainer";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsElement>;
    /** @deprecated Use sectionHeading instead. It allows allows more formatting options. */
    heading?: Maybe<Scalars["String"]["output"]>;
    sectionHeading?: Maybe<EgdsSectionHeading>;
    /** @deprecated This leads to ambiguity as section themes are not found in EGDS */
    theme?: Maybe<ContainerTheme>;
  };

export interface EgdsSectionHeading {
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
}

export interface EgdsSelect {
  disabled: Scalars["Boolean"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  options: Array<EgdsOption>;
  readOnly: Scalars["Boolean"]["output"];
}

export type EgdsSheet = {
  __typename?: "EGDSSheet";
  actions?: Maybe<Array<EgdsToolbarActionButton>>;
  closeAccessibility?: Maybe<Scalars["String"]["output"]>;
  closeAnalytics: ClientSideAnalytics;
  closeText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  /** @deprecated Use sheetTypes instead */
  sheetType?: Maybe<EgdsSheetType>;
  sheetTypes?: Maybe<Array<EgdsSheetTypePerViewSize>>;
};

export type EgdsSheetType = "BOTTOM" | "CENTERED" | "FULLSCREEN" | "POPOVER" | "SIDE";

export type EgdsSheetTypePerViewSize = {
  __typename?: "EGDSSheetTypePerViewSize";
  sheetType?: Maybe<EgdsSheetType>;
  viewSize?: Maybe<EgdsSheetTypeViewSize>;
};

export type EgdsSheetTypeViewSize = "LARGE" | "MEDIUM" | "SMALL";

export type EgdsShowSearchFormAction = {
  __typename?: "EGDSShowSearchFormAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon: Icon;
  resource: Uri;
};

export type EgdsSingleDatePicker = EgdsDatePicker & {
  __typename?: "EGDSSingleDatePicker";
  buttonText: Scalars["String"]["output"];
  clearButtonText?: Maybe<Scalars["String"]["output"]>;
  clearDatesButtonAnalytics?: Maybe<ClientSideAnalytics>;
  dateFormat: Scalars["String"]["output"];
  datePickerContent?: Maybe<Array<EgdsDatePickerContent>>;
  firstDayOfWeek: EgdsDayName;
  flexibleDateContent?: Maybe<EgdsDatePickerFlexibilityContent>;
  footerText?: Maybe<Scalars["String"]["output"]>;
  selectedStartDate?: Maybe<Date>;
  showClearDatesButton?: Maybe<Scalars["Boolean"]["output"]>;
  startDateButtonAnalytics?: Maybe<ClientSideAnalytics>;
  startDatePlaceholderText: Scalars["String"]["output"];
  submitButtonAnalytics?: Maybe<ClientSideAnalytics>;
  validDaysLowerBoundInclusive?: Maybe<Date>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
};

export type EgdsSortAndFilterAppliedFilter = ShoppingSortAndFilterAppliedFilter & {
  __typename?: "EGDSSortAndFilterAppliedFilter";
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: UiRemovablePill;
};

export type EgdsSpannableDialog = EgdsDialog & {
  __typename?: "EGDSSpannableDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  toolbar: EgdsDialogSpannableToolbar;
};

export type EgdsSpannableList = {
  __typename?: "EGDSSpannableList";
  items: Array<EgdsSpannableListItem>;
  listType: EgdsTextListType;
};

export type EgdsSpannableListItem = {
  __typename?: "EGDSSpannableListItem";
  graphic?: Maybe<UiGraphic>;
  nestedList?: Maybe<EgdsSpannableNestedLevelOneList>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: EgdsSpannableText;
};

export type EgdsSpannableNestedLevelOneList = {
  __typename?: "EGDSSpannableNestedLevelOneList";
  items: Array<EgdsSpannableNestedLevelOneListItem>;
  listType: EgdsTextListType;
};

export type EgdsSpannableNestedLevelOneListItem = {
  __typename?: "EGDSSpannableNestedLevelOneListItem";
  graphic?: Maybe<UiGraphic>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: EgdsSpannableText;
};

export type EgdsSpannableText = EgdsElement &
  EgdsText & {
    __typename?: "EGDSSpannableText";
    /** @deprecated Please use inlineContent */
    contents: Array<EgdsText>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    inlineContent: Array<EgdsSpannableTextItem>;
    text: Scalars["String"]["output"];
  };

export type EgdsSpannableTextItem =
  | EgdsGraphicText
  | EgdsHeading
  | EgdsInlineLink
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStylizedText;

export type EgdsStackedDialogFooter = EgdsDialogFooter & {
  __typename?: "EGDSStackedDialogFooter";
  buttons: Array<EgdsButton>;
};

export type EgdsStandardBadge = EgdsBadge &
  EgdsElement & {
    __typename?: "EGDSStandardBadge";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    graphic?: Maybe<UiGraphic>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    size?: Maybe<Scalars["String"]["output"]>;
    text?: Maybe<Scalars["String"]["output"]>;
    theme?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsStandardLink = EgdsElement &
  EgdsText & {
    __typename?: "EGDSStandardLink";
    action: UiLinkAction;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    iconPosition?: Maybe<EgdsIconPosition>;
    size: EgdsLinkSize;
    text: Scalars["String"]["output"];
  };

export type EgdsStandardMessage = {
  __typename?: "EGDSStandardMessage";
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]["output"]>;
  links?: Maybe<Array<EgdsStandardLink>>;
  message: Scalars["String"]["output"];
};

export type EgdsStandardMessagingCard = EgdsElement & {
  __typename?: "EGDSStandardMessagingCard";
  background?: Maybe<Scalars["String"]["output"]>;
  buttons?: Maybe<Array<EgdsButton>>;
  dismiss?: Maybe<EgdsDismissAction>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  message: Scalars["String"]["output"];
  rightIcon?: Maybe<Icon>;
  themeProvider?: Maybe<EgdsThemeProvider>;
  themes?: Maybe<Array<EgdsCardThemes>>;
};

export type EgdsStandardSubMenu = EgdsSubMenu & {
  __typename?: "EGDSStandardSubMenu";
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsStandardSwitch = UiToggle & {
  __typename?: "EGDSStandardSwitch";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use checked or unchecked label */
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
};

export type EgdsStaticMapConfig = EgdsBaseMapConfig & {
  __typename?: "EGDSStaticMapConfig";
  accessToken: Scalars["String"]["output"];
  config?: Maybe<MapConfiguration>;
  egdsMapProvider: EgdsMapProvider;
  markerUrls: Array<EgdsMarkerUrls>;
  positionMode?: Maybe<EgdsMapPositionMode>;
};

export interface EgdsStepInput {
  decreaseText?: Maybe<Scalars["String"]["output"]>;
  increaseText?: Maybe<Scalars["String"]["output"]>;
  key?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
  step: Scalars["Int"]["output"];
  subLabel?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["Int"]["output"];
}

export type EgdsStylizedText = EgdsText & {
  __typename?: "EGDSStylizedText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  decorative?: Maybe<EgdsDecorativeText>;
  size?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export interface EgdsSubMenu {
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]["output"]>;
}

export type EgdsSubmitSearchFormAction = UiAction & {
  __typename?: "EGDSSubmitSearchFormAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  fixedInputs?: Maybe<EgdsSearchInputs>;
};

export interface EgdsTab {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
}

export interface EgdsTabs {
  selectedTabId?: Maybe<Scalars["String"]["output"]>;
  tabs: Array<EgdsTab>;
}

export interface EgdsTemplateModels {
  models: Array<TemplateModel>;
}

export interface EgdsText {
  text: Scalars["String"]["output"];
}

export type EgdsTextAreaInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSTextAreaInputField";
    disabled?: Maybe<Scalars["Boolean"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    maxRows?: Maybe<Scalars["Int"]["output"]>;
    minRows?: Maybe<Scalars["Int"]["output"]>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export interface EgdsTextContainer {
  contents: Array<EgdsText>;
}

export type EgdsTextIconLinkListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextIconLinkListItem";
    action: UiLinkAction;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon: Icon;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    size?: Maybe<EgdsLinkSize>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]["output"]>;
    text: Scalars["String"]["output"];
  };

export type EgdsTextIconList = EgdsElement &
  EgdsTextList & {
    __typename?: "EGDSTextIconList";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    listItems: Array<EgdsTextIconListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export type EgdsTextIconListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextIconListItem";
    accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon: Icon;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    nestedLevelOneList?: Maybe<EgdsNestedLevelOneList>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]["output"]>;
    text: Scalars["String"]["output"];
  };

export type EgdsTextInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSTextInputField";
    autofill?: Maybe<Scalars["String"]["output"]>;
    disabled?: Maybe<Scalars["Boolean"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export interface EgdsTextList {
  listItems: Array<EgdsTextListItem>;
  size?: Maybe<EgdsTextListSize>;
}

export interface EgdsTextListContainer {
  list: EgdsTextList;
}

export interface EgdsTextListItem {
  /** @deprecated Should not use Recursive datatype. Use custom lists. */
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
}

export type EgdsTextListItemStyle = "DEFAULT" | "EMPHASIS" | "LOYALTY" | "NEGATIVE" | "POSITIVE";

export type EgdsTextListSection = EgdsElement &
  EgdsTextListContainer & {
    __typename?: "EGDSTextListSection";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    heading?: Maybe<EgdsSectionHeading>;
    list: EgdsTextList;
  };

export type EgdsTextListSize = "LIST_1" | "LIST_2" | "LIST_3";

export type EgdsTextListType = "BULLET" | "ICON" | "NO_BULLET" | "NUMBER";

export type EgdsTextMenu = EgdsMenu & {
  __typename?: "EGDSTextMenu";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  subMenus: Array<EgdsSubMenu>;
  title: Scalars["String"]["output"];
};

export type EgdsTextSection = EgdsElement &
  EgdsTextContainer & {
    __typename?: "EGDSTextSection";
    contents: Array<EgdsText>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    heading?: Maybe<EgdsSectionHeading>;
  };

export type EgdsTextStandardListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextStandardListItem";
    accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    nestedLevelOneList?: Maybe<EgdsNestedLevelOneList>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]["output"]>;
    text: Scalars["String"]["output"];
  };

export type EgdsTextWithMarkListItem = EgdsElement &
  EgdsText &
  EgdsTextListItem & {
    __typename?: "EGDSTextWithMarkListItem";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    mark?: Maybe<Mark>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]["output"]>;
    text: Scalars["String"]["output"];
  };

export type EgdsThemeProvider = {
  __typename?: "EGDSThemeProvider";
  darkMode?: Maybe<EgdsThemeProviderConfig>;
  isColorThemeContainer: Scalars["Boolean"]["output"];
  lightMode?: Maybe<EgdsThemeProviderConfig>;
};

export type EgdsThemeProviderColor =
  | "BRIGHT_1"
  | "DARK_1"
  | "GLOBAL_LOYALTY_EXTRA_HIGH"
  | "GLOBAL_LOYALTY_EXTRA_HIGH_BRIGHT"
  | "GLOBAL_LOYALTY_HIGH"
  | "GLOBAL_LOYALTY_HIGH_BRIGHT"
  | "GLOBAL_LOYALTY_LOW"
  | "GLOBAL_LOYALTY_LOW_BRIGHT"
  | "GLOBAL_LOYALTY_MID"
  | "GLOBAL_LOYALTY_MID_BRIGHT"
  | "GLOBAL_LOYALTY_STANDARD"
  | "LIGHT_1"
  | "MARKETING_1"
  | "MARKETING_2"
  | "NONE"
  | "STANDARD";

export type EgdsThemeProviderConfig = {
  __typename?: "EGDSThemeProviderConfig";
  isGradient?: Maybe<Scalars["Boolean"]["output"]>;
  isThemeBase?: Maybe<Scalars["Boolean"]["output"]>;
  themeProviderColor?: Maybe<EgdsThemeProviderColor>;
};

export interface EgdsThumbnailMediaGallery {
  tabs?: Maybe<EgdsTabs>;
  thumbnails: Array<MediaItem>;
  toolbar: EgdsDialogToolbar;
}

export type EgdsToast = EgdsElement & {
  __typename?: "EGDSToast";
  action?: Maybe<UiLinkAction>;
  actionText?: Maybe<Scalars["String"]["output"]>;
  button?: Maybe<UiPrimaryButton>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type EgdsToggleButton = {
  __typename?: "EGDSToggleButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<UiGraphic>;
  name?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  selectedAnalytics?: Maybe<ClientSideAnalytics>;
  subText?: Maybe<Scalars["String"]["output"]>;
  unselectedAnalytics?: Maybe<ClientSideAnalytics>;
};

export type EgdsToggleButtonGroup = {
  __typename?: "EGDSToggleButtonGroup";
  options: Array<EgdsToggleButton>;
};

export type EgdsToolbar = {
  __typename?: "EGDSToolbar";
  actions?: Maybe<Array<EgdsToolbarActionButton>>;
  navigation: EgdsOverlayButton;
  title?: Maybe<EgdsToolbarTitle>;
  type: EgdsToolbarStyle;
};

export type EgdsToolbarActionButton = EgdsBadgeNotification | EgdsOverlayButton;

export type EgdsToolbarActionItem = {
  __typename?: "EGDSToolbarActionItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clickAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]["output"]>;
  isEnabled: Scalars["Boolean"]["output"];
  navigationType?: Maybe<NavigationType>;
  title?: Maybe<EgdsSpannableText>;
};

export type EgdsToolbarStyle = "STANDARD" | "TRANSPARENT";

export type EgdsToolbarSubtitle = EgdsIconRating | EgdsPlainText;

export type EgdsToolbarTitle = {
  __typename?: "EGDSToolbarTitle";
  primary: Scalars["String"]["output"];
  secondary?: Maybe<EgdsToolbarSubtitle>;
};

export interface EgdsTraveler {
  ages?: Maybe<Array<EgdsSelect>>;
  count: EgdsStepInput;
}

export type EgdsTravelerChildAgeSelect = EgdsElement &
  EgdsSelect & {
    __typename?: "EGDSTravelerChildAgeSelect";
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    label: Scalars["String"]["output"];
    labelTemplate: EgdsLocalizedText;
    options: Array<EgdsOption>;
    readOnly: Scalars["Boolean"]["output"];
    value?: Maybe<Scalars["Int"]["output"]>;
  };

export type EgdsTravelerChildren = EgdsTraveler & {
  __typename?: "EGDSTravelerChildren";
  ageTemplate: EgdsSelect;
  ages: Array<EgdsSelect>;
  count: EgdsStepInput;
  /** @deprecated Please use the one defined in EGDSTravelerSelector or EGDSTravelerSelectorRoom instead. */
  travelerNote?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsTravelerInfant = EgdsTraveler & {
  __typename?: "EGDSTravelerInfant";
  ageTemplate: EgdsSelect;
  ages: Array<EgdsSelect>;
  count: EgdsStepInput;
  /** @deprecated Please use the one defined in EGDSTravelerSelector or EGDSTravelerSelectorRoom instead. */
  travelerNote?: Maybe<Scalars["String"]["output"]>;
};

export type EgdsTravelerInfantSeatSelection = EgdsElement &
  EgdsRadioGroup & {
    __typename?: "EGDSTravelerInfantSeatSelection";
    airlineAgeRule: EgdsStandardLink;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage: Scalars["String"]["output"];
    groupName: Scalars["String"]["output"];
    label: Scalars["String"]["output"];
    labelTemplate: EgdsLocalizedText;
    options: Array<EgdsRadioButton>;
    selected: Scalars["String"]["output"];
  };

export interface EgdsTravelerSelector {
  closeButton: EgdsButton;
  doneButton: EgdsButton;
  title: Scalars["String"]["output"];
}

export type EgdsTravelerSelectorRoom = {
  __typename?: "EGDSTravelerSelectorRoom";
  adults: EgdsStepInput;
  children: EgdsTraveler;
  /** @deprecated Use infantsOnLap and infantsInSeat instead. */
  infants?: Maybe<EgdsTraveler>;
  infantsInSeat?: Maybe<EgdsTraveler>;
  infantsOnLap?: Maybe<EgdsTraveler>;
  label: EgdsLocalizedText;
  removeRoomAnnouncement?: Maybe<Scalars["String"]["output"]>;
  removeRoomButton?: Maybe<EgdsButton>;
  travelerNote?: Maybe<EgdsLocalizedText>;
};

export type EgdsTravelerStepInput = EgdsElement &
  EgdsStepInput & {
    __typename?: "EGDSTravelerStepInput";
    /** @deprecated Need specific tracking for increase and decrease count event. Hence, deprecating the action and adding new analytics fields for increase and decrease count. */
    action?: Maybe<EgdsSearchFormAction>;
    decreaseAnalytics: ClientSideAnalytics;
    decreaseText?: Maybe<Scalars["String"]["output"]>;
    decreaseTextTemplate?: Maybe<EgdsLocalizedText>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    increaseAnalytics: ClientSideAnalytics;
    increaseText?: Maybe<Scalars["String"]["output"]>;
    increaseTextTemplate?: Maybe<EgdsLocalizedText>;
    key?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    max?: Maybe<Scalars["Int"]["output"]>;
    min?: Maybe<Scalars["Int"]["output"]>;
    step: Scalars["Int"]["output"];
    subLabel?: Maybe<Scalars["String"]["output"]>;
    value: Scalars["Int"]["output"];
  };

export type EgdsTravelerType = "ADULT" | "CHILD" | "INFANT" | "SENIOR" | "YOUTH";

export type EgdsTravelers = {
  __typename?: "EGDSTravelers";
  adults: EgdsStepInput;
  children?: Maybe<EgdsTraveler>;
  infantsInSeat?: Maybe<EgdsTraveler>;
  infantsOnLap?: Maybe<EgdsTraveler>;
  pets?: Maybe<EgdsCheckBox>;
  seniors?: Maybe<EgdsStepInput>;
  travelerNote?: Maybe<EgdsLocalizedText>;
};

export type EgdsTravelersInputValidation = EgdsInputValidation & {
  __typename?: "EGDSTravelersInputValidation";
  errorMessage: Scalars["String"]["output"];
  errorSummary?: Maybe<EgdsErrorSummary>;
  infantsInSeatPerAdult?: Maybe<Scalars["Int"]["output"]>;
  maxCount?: Maybe<Scalars["Int"]["output"]>;
  minAgeToHoldInfant?: Maybe<Scalars["Int"]["output"]>;
  type: EgdsTravelersValidationType;
};

export type EgdsTravelersValidationType =
  | "CHILD_AGE_REQUIRED"
  | "MANY_INFANT_IN_SEAT"
  | "MANY_INFANT_ON_LAP"
  | "MAX_TRAVELERS";

export type EgdsTypeaheadInputField = EgdsElement &
  EgdsInputField & {
    __typename?: "EGDSTypeaheadInputField";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    renderType?: Maybe<EgdsTypeaheadRenderType>;
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    typeaheadEmptyResultPlaceholder?: Maybe<Scalars["String"]["output"]>;
    typeaheadInfo: TypeaheadInfo;
    typeaheadPlaceholder?: Maybe<Scalars["String"]["output"]>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type EgdsTypeaheadMultiSelect = {
  __typename?: "EGDSTypeaheadMultiSelect";
  clearAllText?: Maybe<Scalars["String"]["output"]>;
  isMultiLocation?: Maybe<Scalars["Boolean"]["output"]>;
  selectedItemHeading?: Maybe<Scalars["String"]["output"]>;
  showPills: Scalars["Boolean"]["output"];
};

export type EgdsTypeaheadRenderType = "ADAPTIVE" | "DIALOG" | "MENU";

export type EgdsUnorderedList = EgdsElement &
  EgdsList &
  EgdsTextList & {
    __typename?: "EGDSUnorderedList";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated This field allows for noncompliance to EGDS. Use EGDSTextList instead */
    items: Array<EgdsText>;
    listItems: Array<EgdsTextListItem>;
    size?: Maybe<EgdsTextListSize>;
  };

export type EgDynamicMap = {
  __typename?: "EGDynamicMap";
  action?: Maybe<UiPrimaryButton>;
  adaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  adaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  autoSearchOnMapMove?: Maybe<EgdsBasicCheckBox>;
  filterPlacement?: Maybe<FilterPlacement>;
  /** @deprecated Replace by title from map toolbar */
  heading?: Maybe<EgdsHeading>;
  map?: Maybe<EgdsBasicMap>;
  mapToolbar?: Maybe<MapToolbar>;
  /** @deprecated Deprecated. Use mapTrigger */
  staticMapSrc?: Maybe<HttpUri>;
  subheading?: Maybe<EgdsHeading>;
};

export type EgLabsCreateTripResponse = {
  __typename?: "EGLabsCreateTripResponse";
  responseMessage: Scalars["String"]["output"];
  tripId?: Maybe<Scalars["String"]["output"]>;
};

export type EgMagazineCard = {
  __typename?: "EGMagazineCard";
  action: UiLinkAction;
  badges: Array<EgdsStandardBadge>;
  description?: Maybe<EgdsParagraph>;
  heading: EgdsHeading;
  impression: ClientSideAnalytics;
  media?: Maybe<Image>;
  subTitle?: Maybe<EgdsPlainText>;
};

export type EgMagazineResponse = {
  __typename?: "EGMagazineResponse";
  actionButton?: Maybe<UiPrimaryButton>;
  bottomLink?: Maybe<EgdsStandardLink>;
  cards: Array<EgMagazineCard>;
  heading: EgdsHeading;
  impression: ClientSideAnalytics;
};

export type EgReferrerEventType = "CLICK" | "IMPRESSION";

export type EgUisMicroMessage = {
  __typename?: "EGUisMicroMessage";
  messageContent: Scalars["String"]["output"];
  schemaName: Scalars["String"]["output"];
};

export type EmCardComponents = {
  __typename?: "EMCardComponents";
  card: EgdsImageLinkCard;
};

export type EmClickAction = {
  __typename?: "EMClickAction";
  action?: Maybe<UiLinkAction>;
  clickActionType: ClickActionType;
  description?: Maybe<Scalars["String"]["output"]>;
};

export type EmContentMetaData = {
  __typename?: "EMContentMetaData";
  contentId: Scalars["String"]["output"];
};

export type EmExperienceCardComponents = {
  __typename?: "EMExperienceCardComponents";
  clickActions?: Maybe<Array<Maybe<EmClickAction>>>;
  contentMetadata?: Maybe<EmContentMetaData>;
  mediaAssets?: Maybe<EmMediaComponents>;
  textComponents?: Maybe<EmRichTextComponents>;
};

export type EmImage = {
  __typename?: "EMImage";
  description: Scalars["String"]["output"];
  sourceSet?: Maybe<Array<EmImageSource>>;
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars["URL"]["output"];
};

export type EmImageSize = "L" | "M" | "S" | "XL" | "XS";

export type EmImageSource = {
  __typename?: "EMImageSource";
  aspectRatio: AspectRatio;
  dimensions: Dimensions;
  size: EmImageSize;
  url: Scalars["URL"]["output"];
};

export type EmMediaComponents = {
  __typename?: "EMMediaComponents";
  items?: Maybe<Array<Maybe<MediaAsset>>>;
};

export type EmMediaType = EmImage | Image | Video;

export type EmRichTextComponents = {
  __typename?: "EMRichTextComponents";
  description: Array<Description>;
  header?: Maybe<EgdsHeading>;
  serializedDescription?: Maybe<Scalars["String"]["output"]>;
  subHeader?: Maybe<EgdsHeading>;
};

export type EmRichTextMediaActions = {
  __typename?: "EMRichTextMediaActions";
  clickActions?: Maybe<Array<Maybe<EmClickAction>>>;
  contentMetadata?: Maybe<EmContentMetaData>;
  mediaAssets?: Maybe<EmMediaComponents>;
  textComponents: EmRichTextComponents;
};

export type EmTextComponents = {
  __typename?: "EMTextComponents";
  description?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<EgdsHeading>;
  subHeader?: Maybe<EgdsHeading>;
};

export type EmTextListComponents = {
  __typename?: "EMTextListComponents";
  list: List;
  textComponents: EmTextComponents;
};

export type EmTextMediaActions = {
  __typename?: "EMTextMediaActions";
  clickActions?: Maybe<Array<Maybe<EmClickAction>>>;
  mediaAssets?: Maybe<EmMediaComponents>;
  textComponents: EmTextComponents;
};

export type EssCategory = {
  __typename?: "ESSCategory";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type EarnMessageContainerListItem = EgdsTextListItem & {
  __typename?: "EarnMessageContainerListItem";
  graphic: UiGraphic;
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  stylizedText?: Maybe<EgdsSpannableText>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type EditUniversalProfileResponse = {
  __typename?: "EditUniversalProfileResponse";
  changeEmail: UniversalProfileActionResponse;
  completenessModule: UniversalProfileActionResponse;
  confirmAvatarUpload: UniversalProfileActionResponse;
  deleteAccount: UniversalProfileActionResponse;
  deleteVerifiedPhoneNumber: UniversalProfileActionResponse;
  editBasicInformation: UniversalProfileActionResponse;
  editCommunicationPreference: UniversalProfileActionResponse;
  editContactInformation: UniversalProfileActionResponse;
  editContactInformationPhoneNumber: UniversalProfileActionResponse;
  editEmailCommunicationPreferences: UniversalProfileActionResponse;
  editPassportInformation: UniversalProfileActionResponse;
  editTravelerCompanion: UniversalProfileActionResponse;
  editTsaInformation: UniversalProfileActionResponse;
  signOut: UniversalProfileActionResponse;
  unsubscribeFromMultiplePreference: UniversalProfileActionResponse;
  unsubscribePreference: UniversalProfileActionResponse;
};

export type EditUniversalProfileResponseChangeEmailArgs = {
  request: UniversalProfileChangeEmailRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseCompletenessModuleArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseConfirmAvatarUploadArgs = {
  request: UniversalProfileConfirmAvatarUploadRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseDeleteAccountArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseDeleteVerifiedPhoneNumberArgs = {
  request: UniversalProfileDeleteVerifiedPhoneRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditBasicInformationArgs = {
  request: UniversalProfileBasicInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditCommunicationPreferenceArgs = {
  request: UniversalProfileCommunicationPreferenceRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditContactInformationArgs = {
  request: UniversalProfileContactInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditContactInformationPhoneNumberArgs = {
  request: UniversalProfileSmsContactInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditEmailCommunicationPreferencesArgs = {
  request: UniversalProfileCommunicationPreferenceEmailRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditPassportInformationArgs = {
  request: UniversalProfilePassportInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseEditTravelerCompanionArgs = {
  request: UniversalProfileTravelerCompanionRequestInput;
  universalProfileContext: UniversalProfileAdditionalTravelerContextInput;
};

export type EditUniversalProfileResponseEditTsaInformationArgs = {
  request: UniversalProfileTsaInformationRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseSignOutArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseUnsubscribeFromMultiplePreferenceArgs = {
  request: UniversalProfileCommunicationPreferenceUnsubscribeCategoriesRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditUniversalProfileResponseUnsubscribePreferenceArgs = {
  request: UniversalProfileCommunicationPreferenceUnsubscribePreferenceRequestInput;
  universalProfileContext: UniversalProfileContextInput;
};

export type EditorialComponent = {
  __typename?: "EditorialComponent";
  content: EditorialComponentContent;
};

export type EditorialComponentContent =
  | EmCardComponents
  | EmExperienceCardComponents
  | EmMediaComponents
  | EmRichTextMediaActions
  | EmTextComponents
  | EmTextListComponents
  | EmTextMediaActions
  | StructuredContentMediaGalleryCarousel;

export type EditorialContent = TravelExperienceContent & {
  __typename?: "EditorialContent";
  id: Scalars["ID"]["output"];
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated */
  unformattedHtml?: Maybe<Scalars["String"]["output"]>;
};

export type EgAdditionalContext = {
  __typename?: "EgAdditionalContext";
  userInterface?: Maybe<EgUserInterface>;
};

export type EgClickStreamContractingPartnerCommonData = {
  __typename?: "EgClickStreamContractingPartnerCommonData";
  resource_id?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickStreamPartnerCommon = {
  __typename?: "EgClickStreamPartnerCommon";
  promotion_id?: Maybe<Scalars["String"]["output"]>;
  resource_id?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamAdditionalContext = {
  __typename?: "EgClickstreamAdditionalContext";
  userInterface?: Maybe<EgClickstreamUserInterface>;
};

export type EgClickstreamData = IEgClickstreamData & {
  __typename?: "EgClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
};

export type EgClickstreamEvent = {
  __typename?: "EgClickstreamEvent";
  action_location?: Maybe<Scalars["String"]["output"]>;
  event_name: Scalars["String"]["output"];
  event_type: Scalars["String"]["output"];
  event_version: Scalars["String"]["output"];
};

export type EgClickstreamEventWithContext = {
  __typename?: "EgClickstreamEventWithContext";
  analytics?: Maybe<EgClickstreamEvent>;
  componentName?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamExperience = {
  __typename?: "EgClickstreamExperience";
  app_name?: Maybe<Scalars["String"]["output"]>;
  app_version?: Maybe<Scalars["String"]["output"]>;
  experience_platform?: Maybe<Scalars["String"]["output"]>;
  page_name?: Maybe<Scalars["String"]["output"]>;
  page_name_detailed?: Maybe<Scalars["String"]["output"]>;
  page_product_line?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamLodgingProduct = {
  __typename?: "EgClickstreamLodgingProduct";
  property_brand_id?: Maybe<Scalars["Int"]["output"]>;
  property_city_name?: Maybe<Scalars["String"]["output"]>;
  property_country_name?: Maybe<Scalars["String"]["output"]>;
  property_market?: Maybe<Scalars["String"]["output"]>;
  property_name?: Maybe<Scalars["String"]["output"]>;
  property_sub_market?: Maybe<Scalars["String"]["output"]>;
  property_type?: Maybe<Scalars["String"]["output"]>;
  structure_type_id?: Maybe<Scalars["Int"]["output"]>;
};

export type EgClickstreamPartnerAdditionalContextData = {
  __typename?: "EgClickstreamPartnerAdditionalContextData";
  user_interface?: Maybe<EgClickstreamPartnerUserInterfaceData>;
};

export type EgClickstreamPartnerCommonData = {
  __typename?: "EgClickstreamPartnerCommonData";
  resource_id?: Maybe<Scalars["String"]["output"]>;
  resource_type?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamPartnerFormData = {
  __typename?: "EgClickstreamPartnerFormData";
  form_id?: Maybe<Scalars["String"]["output"]>;
  full_field_name?: Maybe<Scalars["String"]["output"]>;
  short_field_name?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamPartnerUserInterfaceData = {
  __typename?: "EgClickstreamPartnerUserInterfaceData";
  component_element_text?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamProductListData = {
  __typename?: "EgClickstreamProductListData";
  lodging_product: EgClickstreamLodgingProduct;
};

export type EgClickstreamUserInterface = {
  __typename?: "EgClickstreamUserInterface";
  componentBadgeName?: Maybe<Scalars["String"]["output"]>;
  componentElementText?: Maybe<Scalars["String"]["output"]>;
  componentName?: Maybe<Scalars["String"]["output"]>;
};

export type EgClickstreamVirtualMarketCoachData = {
  __typename?: "EgClickstreamVirtualMarketCoachData";
  opportunities_available?: Maybe<Scalars["Int"]["output"]>;
  sort_position?: Maybe<Scalars["Int"]["output"]>;
};

export type EgNotificationAttributes = {
  __typename?: "EgNotificationAttributes";
  hasUnreadNotifications: Scalars["Boolean"]["output"];
  /** @deprecated No longer supported */
  notificationBool?: Maybe<Scalars["Int"]["output"]>;
  notificationCount?: Maybe<Scalars["Int"]["output"]>;
};

export type EgPropertyCount = {
  __typename?: "EgPropertyCount";
  propertyCount?: Maybe<Scalars["Int"]["output"]>;
};

export type EgSuggestionList = {
  __typename?: "EgSuggestionList";
  textDisplayed?: Maybe<Scalars["String"]["output"]>;
};

export type EgTypeAHead = {
  __typename?: "EgTypeAHead";
  searchInput?: Maybe<Scalars["String"]["output"]>;
  suggestionList?: Maybe<Array<Maybe<EgSuggestionList>>>;
};

export type EgTypeahead = {
  __typename?: "EgTypeahead";
  searchInput?: Maybe<Scalars["String"]["output"]>;
  suggestionList?: Maybe<Array<EgTypeaheadSuggestionList>>;
};

export type EgTypeaheadAdditionalContext = {
  __typename?: "EgTypeaheadAdditionalContext";
  userInterface?: Maybe<EgTypeaheadUserInterface>;
};

export type EgTypeaheadSuggestionList = {
  __typename?: "EgTypeaheadSuggestionList";
  textDisplayed?: Maybe<Scalars["String"]["output"]>;
};

export type EgTypeaheadUserInterface = {
  __typename?: "EgTypeaheadUserInterface";
  componentBadgeName?: Maybe<Scalars["String"]["output"]>;
  componentElementText?: Maybe<Scalars["String"]["output"]>;
};

export type EgUserInterface = {
  __typename?: "EgUserInterface";
  componentBadgeName?: Maybe<Scalars["String"]["output"]>;
  componentElementText?: Maybe<Scalars["String"]["output"]>;
};

export type ElementProperties = {
  __typename?: "ElementProperties";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  buttonType?: Maybe<ButtonType>;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface EmailPreferencesCompletenessModuleRequestInput {
  promptDeniedDate?: InputMaybe<Scalars["String"]["input"]>;
  promptDeniedTimes?: InputMaybe<Scalars["Int"]["input"]>;
}

export type EmailUsFormView = ContactUsErrorView | EmailUsSheet;

export type EmailUsSheet = {
  __typename?: "EmailUsSheet";
  back: Scalars["String"]["output"];
  customerEmail: EgdsEmailInputField;
  customerName: EgdsTextInputField;
  heading: Scalars["String"]["output"];
  message: EgdsTextAreaInputField;
  send: UiPrimaryButton;
  subheading: Scalars["String"]["output"];
  subject: EgdsTextInputField;
  toolBar: ContactUsToolBar;
};

export type EmptyStateType = "ENABLE_PUSH" | "EXPLORE_TRIPS" | "SIGN_IN";

export type EnhancedCleanlinessDialog = {
  __typename?: "EnhancedCleanlinessDialog";
  carouselButtonAllyStrings?: Maybe<Array<Scalars["String"]["output"]>>;
  content: Array<EnhancedCleanlinessDialogContent>;
  description: Scalars["String"]["output"];
  imageList?: Maybe<Array<Image>>;
  images?: Maybe<Array<HttpUri>>;
  shareFeedbackAction?: Maybe<CarShareFeedbackAction>;
  title: Scalars["String"]["output"];
};

export type EnhancedCleanlinessDialogContent = {
  __typename?: "EnhancedCleanlinessDialogContent";
  infos: Array<Scalars["String"]["output"]>;
  title: EnhancedCleanlinessInfoLineItem;
};

export type EnhancedCleanlinessInfo = {
  __typename?: "EnhancedCleanlinessInfo";
  dialog: EnhancedCleanlinessDialog;
  infoProviderText: Scalars["String"]["output"];
  infos: Array<EnhancedCleanlinessInfoLineItem>;
  seeAllAction: CarActionableItem;
  title: Scalars["String"]["output"];
  vendorCleanlinessBadge: HttpUri;
};

export type EnhancedCleanlinessInfoLineItem = {
  __typename?: "EnhancedCleanlinessInfoLineItem";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type EnrichedDetailsList = {
  __typename?: "EnrichedDetailsList";
  additionalDescription?: Maybe<EgdsText>;
  list?: Maybe<EgdsTextList>;
};

export type EntryData = {
  __typename?: "EntryData";
  id: Scalars["String"]["output"];
  timestamp: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

export type Error = {
  __typename?: "Error";
  errorCode: Scalars["String"]["output"];
  header: EgdsHeading;
  retryButton: UiPrimaryButton;
};

export type ErrorMessage = {
  __typename?: "ErrorMessage";
  id: Scalars["String"]["output"];
  message: EgdsStylizedText;
};

export interface ErrorMessagingPresentation {
  egcsErrorAnalytics: EgClickstreamEvent;
  errorAnalytics: ClientSideImpressionEventAnalytics;
}

export type ErrorResponse = {
  __typename?: "ErrorResponse";
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type EssentialInfoItem = {
  __typename?: "EssentialInfoItem";
  itemId: Scalars["String"]["output"];
};

export interface EssentialInfoItemInput {
  itemId: Scalars["String"]["input"];
}

export type EtpDialog = LodgingDialog & {
  __typename?: "EtpDialog";
  content: LodgingPaymentOptionsDialogContent;
  toolbar: LodgingDialogToolbar;
  trigger: LodgingDialogTriggerMessage;
};

export type Event = {
  __typename?: "Event";
  actionLocation?: Maybe<Scalars["String"]["output"]>;
  actionReason?: Maybe<Scalars["String"]["output"]>;
  eventCategory?: Maybe<Scalars["String"]["output"]>;
  eventName: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  eventVersion?: Maybe<Scalars["String"]["output"]>;
};

export interface EventContentInput {
  typingIndicatorEventContent?: InputMaybe<TypingIndicatorEventContentInput>;
}

export type EventRecommendationPrimer = {
  __typename?: "EventRecommendationPrimer";
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export type EventType =
  | "CancelledTripsItemDetailViewed"
  | "DeleteTripAlertPresented"
  | "DeleteTripDialogPresented"
  | "DeleteTripErrorToastPresented"
  | "DeleteTripFailed"
  | "DeleteTripSucceeded"
  | "DeleteTripSuccessToastPresented"
  | "HeroModuleCampaign"
  | "HeroModuleColdStart"
  | "ItinActiveCar"
  | "ItinActiveCruise"
  | "ItinActiveFlight"
  | "ItinActiveGT"
  | "ItinActiveHotel"
  | "ItinActiveLX"
  | "ItinActivePackageFH"
  | "ItinActiveRail"
  | "ItinCancellation"
  | "MoveItemDrawerPresented"
  | "MoveItemErrorToastPresented"
  | "MoveItemSubmitted"
  | "MoveItemSuccessToastPresented"
  | "MoveItemSuceeded"
  | "MoveLinkedItemsDialogPresented"
  | "RemoveItemDialogPresented"
  | "TripItemMenuPresented"
  | "TripSignInDialogPresented"
  | "TripsCancelledItemDetailViewed"
  | "TripsCollabInviteConfirmationViewed"
  | "TripsCollabInviteFailureViewed"
  | "TripsCollabInviteSucceeded"
  | "TripsCollabJoinGroupFailed"
  | "TripsCollabJoinGroupSucceeded"
  | "TripsCollabJoinTripConfirmationViewed"
  | "TripsCollabJoinTripErrorToastPresented"
  | "TripsCollabLandingScreenRedirectToTripOverView"
  | "TripsCollabLandingScreenViewed"
  | "TripsCollabManageGroupViewed"
  | "TripsCollabViewPresentedActivities"
  | "TripsCollabViewPresentedCars"
  | "TripsCollabViewPresentedCruise"
  | "TripsCollabViewPresentedFlight"
  | "TripsCollabViewPresentedHotel"
  | "TripsCollabViewPresentedVacationRental"
  | "TripsEditItemSucceeded"
  | "TripsEditTripErrorToastPresented"
  | "TripsEditTripPresented"
  | "TripsEditTripSubmitted"
  | "TripsEditTripSuccessToastPresented"
  | "TripsFlightScheduleChangeBannerDisplayed"
  | "TripsHeroModuleCurrentTrip"
  | "TripsHeroModulePossibleActivityTrip"
  | "TripsHeroModulePossibleAirTrip"
  | "TripsHeroModulePossibleCarTrip"
  | "TripsHeroModulePossibleHotelTrip"
  | "TripsHeroModulePossiblePackageHFTrip"
  | "TripsHeroModulePossibleTrip"
  | "TripsHeroModulePotentialTrip"
  | "TripsHeroModuleUpcomingTrip"
  | "TripsItemDetailViewed"
  | "TripsListViewed"
  | "TripsModuleDestinationGuide"
  | "TripsModuleRecommendedActivity"
  | "TripsModuleRecommendedCar"
  | "TripsModuleRecommendedHotel"
  | "TripsOverviewViewed"
  | "TripsTravelPlannerItinNumberPresent"
  | "Unknown";

export type EventsRecommendationIdType = "TRIP_ID" | "TRIP_ITEM_ID";

export interface EventsRecommendationRequestInput {
  primaryId: Scalars["String"]["input"];
  primaryIdType: EventsRecommendationIdType;
  secondaryId?: InputMaybe<Scalars["String"]["input"]>;
  secondaryIdType?: InputMaybe<EventsRecommendationIdType>;
}

export type ExitIntent = {
  __typename?: "ExitIntent";
  backgroundImageUrl?: Maybe<Scalars["String"]["output"]>;
  clickHereLinkUrl?: Maybe<Scalars["String"]["output"]>;
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  message: ExitIntentMessage;
};

export type ExitIntentMessage = {
  __typename?: "ExitIntentMessage";
  clickHere?: Maybe<Scalars["String"]["output"]>;
  couponCode?: Maybe<Scalars["String"]["output"]>;
  headingOne?: Maybe<Scalars["String"]["output"]>;
  headingThree?: Maybe<Scalars["String"]["output"]>;
  headingTwo?: Maybe<Scalars["String"]["output"]>;
  percentOff?: Maybe<Scalars["String"]["output"]>;
  termsAndConditionsOne?: Maybe<Scalars["String"]["output"]>;
  termsAndConditionsTwo?: Maybe<Scalars["String"]["output"]>;
};

export type ExpLineOfBusiness =
  | "ACTIVITIES"
  | "CAR"
  | "CRUISES"
  | "FLIGHT"
  | "GROUNDTRANSFERS"
  | "HOTEL"
  | "PACKAGES"
  | "RAILS"
  | "UNCLASSIFIED";

export type ExpandButton = {
  __typename?: "ExpandButton";
  collapseButton: Scalars["String"]["output"];
  expandButton: Scalars["String"]["output"];
};

export type ExpandoPeekOptions = {
  __typename?: "ExpandoPeekOptions";
  hiddenThreshold: Scalars["Int"]["output"];
  seeLessLabel: Scalars["String"]["output"];
  seeMoreLabel: Scalars["String"]["output"];
};

export type ExpandoSubtitles = {
  __typename?: "ExpandoSubtitles";
  allSelected: Scalars["String"]["output"];
  noneSelected: Scalars["String"]["output"];
  someSelected: Scalars["String"]["output"];
};

export type ExpandoTriggerPosition = "LEADING" | "TRAILING";

export type Experience = {
  __typename?: "Experience";
  appName: Scalars["String"]["output"];
  appVersion: Scalars["String"]["output"];
  pageName: Scalars["String"]["output"];
  pageNameDetailed: Scalars["String"]["output"];
};

export type ExperienceScoreBody = EgdsGraphicText | EgdsPlainText;

export type ExperienceScoreBundle = {
  __typename?: "ExperienceScoreBundle";
  icon?: Maybe<EgdsGraphicText>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  message?: Maybe<ExperienceScoreBundleMessage>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ExperienceScoreBundleMessage = {
  __typename?: "ExperienceScoreBundleMessage";
  body?: Maybe<Array<ExperienceScoreBody>>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  footer?: Maybe<EgdsPlainText>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
};

export type ExperienceScoreInfo = {
  __typename?: "ExperienceScoreInfo";
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  primary?: Maybe<ExperienceScorePrimarySection>;
  secondary?: Maybe<Array<ExperienceScoreBundle>>;
};

export type ExperienceScorePrimarySection = {
  __typename?: "ExperienceScorePrimarySection";
  experienceScoreRating?: Maybe<ExperienceScoreRating>;
  productAttribute?: Maybe<ProductTextInfoSection>;
  productRating?: Maybe<ExperienceScoreProductRating>;
};

export type ExperienceScoreProductRating = {
  __typename?: "ExperienceScoreProductRating";
  analytics?: Maybe<ClientSideAnalytics>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  rating: ProductRatingSummary;
};

export type ExperienceScoreRating = {
  __typename?: "ExperienceScoreRating";
  disclaimer?: Maybe<ProductTextInfoSection>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  supportingMessages?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type Experiment = {
  __typename?: "Experiment";
  experimentId?: Maybe<Scalars["String"]["output"]>;
  experimentValue?: Maybe<Scalars["Int"]["output"]>;
};

export type ExperimentType = {
  __typename?: "ExperimentType";
  bucket: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type ExperimentalAction = {
  __typename?: "ExperimentalAction";
  analytics?: Maybe<ExperimentalAnalytics>;
  data: ExperimentalLink;
  /** @deprecated Use typeV2 instead */
  type: ActionType;
  typeV2: ExperimentalActionType;
};

export type ExperimentalActionType = "JUMPLINK" | "MODAL" | "REDIRECT";

export type ExperimentalAnalytics = {
  __typename?: "ExperimentalAnalytics";
  appendToReferrerId: Scalars["String"]["output"];
  client: ClientSideAnalytics;
};

export type ExperimentalAspectRatio =
  | "FIT"
  | "FOUR_ONE"
  | "FOUR_THREE"
  | "NONE"
  | "ONE_ONE"
  | "SIXTEEN_NINE"
  | "THREE_FOUR"
  | "THREE_TWO"
  | "TWENTY_ONE_NINE";

export type ExperimentalButton = {
  __typename?: "ExperimentalButton";
  action: ExperimentalAction;
  isFullWidth: Scalars["Boolean"]["output"];
  type: EgdsButtonStyle;
};

export type ExperimentalCardConfig = {
  __typename?: "ExperimentalCardConfig";
  background?: Maybe<Scalars["String"]["output"]>;
  border: Scalars["Boolean"]["output"];
  featuredHeader?: Maybe<ExperimentalCardFeaturedHeader>;
  featuredImage?: Maybe<Image>;
  link?: Maybe<ExperimentalCardLink>;
  overflow: Scalars["Boolean"]["output"];
  padded: Scalars["Boolean"]["output"];
  selected: Scalars["Boolean"]["output"];
  size?: Maybe<ExperimentalContainerSize>;
};

export type ExperimentalCardFeaturedHeader = {
  __typename?: "ExperimentalCardFeaturedHeader";
  tag?: Maybe<ConfigTag>;
  text: Scalars["String"]["output"];
};

export type ExperimentalCardLink = {
  __typename?: "ExperimentalCardLink";
  action: ExperimentalLinkAction;
  text: Scalars["String"]["output"];
};

export type ExperimentalCarouselAnalytics = {
  __typename?: "ExperimentalCarouselAnalytics";
  scrollEvent?: Maybe<ClientSideAnalytics>;
  viewed?: Maybe<ClientSideAnalytics>;
};

export type ExperimentalCarouselConfig = {
  __typename?: "ExperimentalCarouselConfig";
  alwaysShowPaging: Scalars["Boolean"]["output"];
  analytics?: Maybe<ExperimentalCarouselAnalytics>;
  hideNav: Scalars["Boolean"]["output"];
  imagesList?: Maybe<Array<Image>>;
  itemsVisible?: Maybe<BreakpointConfig>;
  naturalWidth: Scalars["Boolean"]["output"];
  nextButton?: Maybe<Scalars["String"]["output"]>;
  peek: Scalars["Boolean"]["output"];
  prevButton?: Maybe<Scalars["String"]["output"]>;
};

export type ExperimentalClickableConfig = {
  __typename?: "ExperimentalClickableConfig";
  action: ExperimentalAction;
};

export type ExperimentalComponent = ExperimentalRaOneComponent & {
  __typename?: "ExperimentalComponent";
  config: ExperimentalConfig;
  metadata: ExperimentalMetadata;
  type: ExperimentalElements;
  typeClass: Scalars["String"]["output"];
};

export type ExperimentalComponentImplementation = ExperimentalRaOneComponent & {
  __typename?: "ExperimentalComponentImplementation";
  children?: Maybe<Array<ExperimentalComponentImplementation>>;
  config: ExperimentalConfig;
  metadata: ExperimentalMetadata;
  type: ExperimentalElements;
};

export type ExperimentalComponentWrapper = {
  __typename?: "ExperimentalComponentWrapper";
  childrenIds: Array<Scalars["String"]["output"]>;
  element: ExperimentalComponent;
  id: Scalars["String"]["output"];
};

export type ExperimentalConfig = {
  __typename?: "ExperimentalConfig";
  card?: Maybe<ExperimentalCardConfig>;
  carousel?: Maybe<ExperimentalCarouselConfig>;
  /** @deprecated Deprecated: Use 'container.action', replace with container.action */
  clickable?: Maybe<ExperimentalClickableConfig>;
  container?: Maybe<ExperimentalContainerConfig>;
  expando?: Maybe<ExperimentalExpandoConfig>;
  grid?: Maybe<ExperimentalLayoutGrid>;
  infoSection?: Maybe<ExperimentalInfoSection>;
  /** @deprecated Deprecated: Use 'container.impression', replace with container.impression */
  intersection?: Maybe<ExperimentalIntersectionTracker>;
  media?: Maybe<ExperimentalMediaConfig>;
  modal?: Maybe<ExperimentalModalConfig>;
  rating?: Maybe<ExperimentalRatingConfig>;
  script?: Maybe<ExperimentalScript>;
  spacing?: Maybe<ExperimentalSpacing>;
  tabs?: Maybe<ExperimentalTabsConfig>;
  textIconTooltip?: Maybe<ProductTextInfoSection>;
};

export type ExperimentalContainerConfig = {
  __typename?: "ExperimentalContainerConfig";
  action?: Maybe<ExperimentalAction>;
  flex?: Maybe<ExperimentalFlexContainer>;
  impression?: Maybe<ExperimentalImpressionTracker>;
};

export type ExperimentalContainerSize =
  | "EIGHTY_EIGHT"
  | "FIVE_HUNDRED"
  | "FORTY_EIGHT"
  | "NINETY_SIX"
  | "ONE_HUNDRED_EIGHTY"
  | "ONE_HUNDRED_THIRTY"
  | "ONE_HUNDRED_TWELVE"
  | "ONE_HUNDRED_TWENTY"
  | "SEVENTY"
  | "SIXTY"
  | "THIRTY_SIX"
  | "THREE_HUNDRED_TWELVE"
  | "TWELVE"
  | "TWENTY_FOUR"
  | "TWO_HUNDRED_FIFTY_SIX"
  | "TWO_HUNDRED_FOUR";

export type ExperimentalElements =
  | "Card"
  | "Carousel"
  | "Clickable"
  | "Container"
  | "Expando"
  | "Grid"
  | "InfoSection"
  | "IntersectionTracker"
  | "Media"
  | "Modal"
  | "Rating"
  | "Script"
  | "Spacing"
  | "Tabs"
  | "TextIconTooltip";

export type ExperimentalExpandoConfig = {
  __typename?: "ExperimentalExpandoConfig";
  analytics?: Maybe<ExperimentalAnalytics>;
  collapseLabel?: Maybe<Scalars["String"]["output"]>;
  expandLabel: Scalars["String"]["output"];
  hideScrim: Scalars["Boolean"]["output"];
  isList: Scalars["Boolean"]["output"];
  itemsToShow?: Maybe<Scalars["Int"]["output"]>;
  linesToShow?: Maybe<Scalars["Int"]["output"]>;
};

export type ExperimentalFlexAlign = "BASELINE" | "CENTER" | "END" | "START" | "STRETCH";

export type ExperimentalFlexContainer = {
  __typename?: "ExperimentalFlexContainer";
  direction?: Maybe<ExperimentalFlexDirection>;
  horizontal?: Maybe<ExperimentalFlexJustify>;
  padding?: Maybe<ExperimentalPadding>;
  spaceBetween: SpacingOptions;
  vertical?: Maybe<ExperimentalFlexAlign>;
};

export type ExperimentalFlexDirection = "COLUMN" | "ROW";

export type ExperimentalFlexJustify = "CENTER" | "END" | "START";

export type ExperimentalHeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type ExperimentalImage = {
  __typename?: "ExperimentalImage";
  /** @deprecated Deprecated: Use 'Clickable' element instead */
  actions?: Maybe<Array<ExperimentalAction>>;
  /** @deprecated Use aspectRatioV2 instead */
  aspectRatio: AspectRatio;
  aspectRatioV2?: Maybe<ExperimentalAspectRatio>;
  /** @deprecated Consider using `events`, replace with events */
  clickEvent?: Maybe<ExperimentalAnalytics>;
  description?: Maybe<Scalars["String"]["output"]>;
  events?: Maybe<Array<ExperimentalAnalytics>>;
  imageFit: ExperimentalImageFit;
  lazyLoading: ExperimentalImageLazyload;
  placeholder: Scalars["Boolean"]["output"];
  roundedCorner: ExperimentalImageRounded;
  source: Scalars["String"]["output"];
  useBlank: Scalars["Boolean"]["output"];
};

export type ExperimentalImageFit = "CONTAIN" | "COVER";

export type ExperimentalImageLazyload = "EAGER" | "LAZY" | "OFF";

export type ExperimentalImageRounded =
  | "ALL"
  | "END_END"
  | "END_START"
  | "FALSE"
  | "MAX"
  | "START_END"
  | "START_START"
  | "TRUE";

export type ExperimentalImpressionTracker = {
  __typename?: "ExperimentalImpressionTracker";
  analytics: ClientSideAnalytics;
  trackOnce: Scalars["Boolean"]["output"];
};

export type ExperimentalInfoSection = {
  __typename?: "ExperimentalInfoSection";
  badge?: Maybe<EgdsStandardBadge>;
  buttons?: Maybe<Array<ExperimentalButton>>;
  descriptions?: Maybe<ExperimentalInfoSectionDescriptions>;
  links?: Maybe<Array<ExperimentalAction>>;
  subTitle?: Maybe<Array<ExperimentalInfoSectionSubTitle>>;
  title?: Maybe<ExperimentalInfoSectionTitle>;
};

export type ExperimentalInfoSectionDescriptions = {
  __typename?: "ExperimentalInfoSectionDescriptions";
  inheritParagraphStyling: Scalars["Boolean"]["output"];
  paragraphSize: Scalars["Int"]["output"];
  showAsList: Scalars["Boolean"]["output"];
  texts?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ExperimentalInfoSectionSubTitle = {
  __typename?: "ExperimentalInfoSectionSubTitle";
  attributes?: Maybe<ExperimentalTextAttributes>;
  icon?: Maybe<Icon>;
  illustration?: Maybe<Illustration>;
  linesToDisplay?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use overflowV2 instead */
  overflow?: Maybe<Overflow>;
  overflowV2?: Maybe<ExperimentalOverflow>;
  /** @deprecated Use spaceV2 instead */
  space?: Maybe<Space>;
  spaceV2?: Maybe<ExperimentalSpace>;
  text?: Maybe<EgdsStylizedText>;
};

export type ExperimentalInfoSectionTitle = {
  __typename?: "ExperimentalInfoSectionTitle";
  attributes?: Maybe<ExperimentalTextAttributes>;
  icon?: Maybe<Icon>;
  illustration?: Maybe<Illustration>;
  linesToDisplay?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use overflowV2 instead */
  overflow?: Maybe<Overflow>;
  overflowV2?: Maybe<ExperimentalOverflow>;
  spacing?: Maybe<ExperimentalSpacing>;
  text?: Maybe<EgdsStylizedText>;
};

export type ExperimentalIntersectionTracker = {
  __typename?: "ExperimentalIntersectionTracker";
  analytics: ClientSideAnalytics;
  trackOnce: Scalars["Boolean"]["output"];
};

export type ExperimentalLayoutGrid = {
  __typename?: "ExperimentalLayoutGrid";
  columnWidth?: Maybe<Array<Scalars["String"]["output"]>>;
  columns: Scalars["Int"]["output"];
  gridItem?: Maybe<Array<ExperimentalLayoutGridItem>>;
  rowHeight?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Use spaceV2 instead */
  space?: Maybe<Space>;
  spaceV2?: Maybe<ExperimentalSpace>;
};

export type ExperimentalLayoutGridItem = {
  __typename?: "ExperimentalLayoutGridItem";
  colSpan: Scalars["Int"]["output"];
  rowSpan?: Maybe<Scalars["Int"]["output"]>;
};

export type ExperimentalLink = {
  __typename?: "ExperimentalLink";
  action: ExperimentalLinkAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  /** @deprecated Use inlineSpaceV2 instead */
  inlineSpace?: Maybe<Space>;
  inlineSpaceV2?: Maybe<ExperimentalSpace>;
  text: Scalars["String"]["output"];
};

export type ExperimentalLinkAction = {
  __typename?: "ExperimentalLinkAction";
  clickEvent?: Maybe<ClientSideAnalytics>;
  modal?: Maybe<ExperimentalModal>;
  target?: Maybe<UiLinkTarget>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type ExperimentalMediaConfig = {
  __typename?: "ExperimentalMediaConfig";
  image: ExperimentalImage;
};

export type ExperimentalMetadata = {
  __typename?: "ExperimentalMetadata";
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  signalEvents?: Maybe<Array<ExperimentalSignalEvent>>;
};

export type ExperimentalModal = {
  __typename?: "ExperimentalModal";
  id: Scalars["String"]["output"];
  queryKey?: Maybe<Scalars["String"]["output"]>;
};

export type ExperimentalModalConfig = {
  __typename?: "ExperimentalModalConfig";
  fullWidth: Scalars["Boolean"]["output"];
  isExpanded: Scalars["Boolean"]["output"];
  modalId: ExperimentalModal;
  position: ExperimentalModalPosition;
  sideSheetLayout: ModalSideSheetLayout;
  toolbar?: Maybe<ExperimentalModalToolbar>;
  type: ExperimentalModalType;
};

export type ExperimentalModalPosition = "LEADING" | "TRAILING";

export type ExperimentalModalToolbar = {
  __typename?: "ExperimentalModalToolbar";
  analytics?: Maybe<ExperimentalAnalytics>;
  closeLabel: Scalars["String"]["output"];
  navType: ModalNavType;
  title: Scalars["String"]["output"];
};

export type ExperimentalModalType = "BOTTOM" | "CENTERED" | "FULL" | "POPOVER" | "SIDE";

export type ExperimentalOverflow =
  | "TRUNCATE"
  | "TRUNCATE_FOUR_LINES"
  | "TRUNCATE_THREE_LINES"
  | "TRUNCATE_TWO_LINES"
  | "WRAP";

export type ExperimentalPadding = {
  __typename?: "ExperimentalPadding";
  bottom: SpacingOptions;
  end: SpacingOptions;
  start: SpacingOptions;
  top: SpacingOptions;
};

export interface ExperimentalRaOneComponent {
  config: ExperimentalConfig;
  metadata: ExperimentalMetadata;
  type: ExperimentalElements;
}

export type ExperimentalRatingConfig = {
  __typename?: "ExperimentalRatingConfig";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  iconId?: Maybe<Scalars["String"]["output"]>;
  rating: Scalars["Float"]["output"];
};

export type ExperimentalScript = {
  __typename?: "ExperimentalScript";
  expression?: Maybe<Scalars["String"]["output"]>;
  variables?: Maybe<Array<ExperimentalVariable>>;
};

export type ExperimentalSignalEvent = {
  __typename?: "ExperimentalSignalEvent";
  id: Scalars["String"]["output"];
  successEvent: ClientSideAnalytics;
};

export type ExperimentalSpace = "four" | "half" | "one" | "six" | "three" | "twelve" | "two";

export type ExperimentalSpacing = {
  __typename?: "ExperimentalSpacing";
  inlineMarginSpace?: Maybe<SpacingOptions>;
  inlinePaddingSpace?: Maybe<SpacingOptions>;
  /** @deprecated Conflicts with the native definition, use marginSpace instead */
  margin?: Maybe<Spacing>;
  marginSpace?: Maybe<SpacingOptions>;
  /** @deprecated Conflicts with the native definition, use paddingSpace instead */
  padding?: Maybe<Spacing>;
  paddingSpace?: Maybe<SpacingOptions>;
};

export type ExperimentalTab = {
  __typename?: "ExperimentalTab";
  analytics?: Maybe<ExperimentalAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  target: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ExperimentalTabsConfig = {
  __typename?: "ExperimentalTabsConfig";
  border: Scalars["Boolean"]["output"];
  isJumpLink: Scalars["Boolean"]["output"];
  scrollTo: Scalars["Boolean"]["output"];
  selectedIndex: Scalars["Int"]["output"];
  tabs: Array<ExperimentalTab>;
  type: TabType;
};

export type ExperimentalTextAttributes = {
  __typename?: "ExperimentalTextAttributes";
  ariaHidden: Scalars["Boolean"]["output"];
  tag?: Maybe<ExperimentalHeadingType>;
};

export type ExperimentalVariable = {
  __typename?: "ExperimentalVariable";
  expression: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type ExploreCard = {
  __typename?: "ExploreCard";
  button?: Maybe<EgdsButton>;
  heading?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ExploreContent = {
  __typename?: "ExploreContent";
  button?: Maybe<EgdsButton>;
  recommendations?: Maybe<DestinationRecommendationGroup>;
  sheet?: Maybe<EgdsSheet>;
};

export interface ExploreDestinationCriteriaInput {
  destinationId: Scalars["String"]["input"];
  exploreIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type ExploreGuidesByFilter = {
  __typename?: "ExploreGuidesByFilter";
  filters?: Maybe<Array<EgdsBasicPill>>;
  guides: Array<GuideRecommendation>;
};

export type ExploreGuidesResponse = {
  __typename?: "ExploreGuidesResponse";
  guidesResponse: GuidesContent;
  impression: ClientSideAnalytics;
  title: Scalars["String"]["output"];
};

export type ExploreResults = {
  __typename?: "ExploreResults";
  card?: Maybe<ExploreCard>;
  content?: Maybe<ExploreContent>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type Exposure = {
  __typename?: "Exposure";
  bucket: Scalars["Int"]["output"];
  id: Scalars["String"]["output"];
};

export interface ExposureInput {
  bucket: Scalars["Int"]["input"];
  id: Scalars["String"]["input"];
}

export type FbMessengerUri = Uri & {
  __typename?: "FBMessengerURI";
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface FopItemInput {
  id: Scalars["String"]["input"];
  paymentMethod: PaymentMethodType;
  paymentMethodConfiguration?: InputMaybe<PaymentMethodConfigurationInput>;
  state: Scalars["String"]["input"];
}

export interface FslCreativeInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface FailureReason {
  description: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
}

export type FallBack = {
  __typename?: "FallBack";
  message: Scalars["String"]["output"];
  moduleName: ModuleName;
  operation: FallbackOperation;
  /** @deprecated Use uiSignals instead ref to RFC-590 */
  signals: Array<CouponSignal>;
  type: FallBackType;
  uiSignals: Array<UiSignalV2>;
};

export type FallBackType = "ERROR";

export type FallbackOperation = "APPLY_COUPON" | "REMOVE_COUPON";

export type FareAction = {
  __typename?: "FareAction";
  action: FlightsActionButton;
  clickAdaptExAnalytics: Array<FlightsAdaptExAnalytics>;
  /** @deprecated deprecating this for downgradeDialog */
  dialog?: Maybe<EgdsDialog>;
  dialogTrigger?: Maybe<FlightsDialogTrigger>;
  downgradeDialog?: Maybe<FlightsDialog>;
  states: Array<FareState>;
};

export type FareChoiceInformationDynamicElement = {
  __typename?: "FareChoiceInformationDynamicElement";
  farePricePerPerson: Scalars["String"]["output"];
};

export type FareDetails = {
  __typename?: "FareDetails";
  changeAndCancellationFees?: Maybe<FlightsChangeAndCancellationFees>;
  changePolicies?: Maybe<FlightsTextSection>;
  defaultBaggageInformation?: Maybe<FlightsBaggageInformation>;
  /** @deprecated Use ancillarySummaryCard query for Seat Map in the selected fare. */
  fareSeatMapInformation?: Maybe<FareSeatMapInformation>;
  fareSummary?: Maybe<FareSummary>;
};

export type FareHeading = {
  __typename?: "FareHeading";
  badge?: Maybe<EgdsBadge>;
  fareDetails?: Maybe<Array<EgdsText>>;
  farePricing: Array<FarePricing>;
};

export type FareInformationCard = {
  __typename?: "FareInformationCard";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  action: Array<FareAction>;
  amenities: FlightsCategorizedList;
  amenityHighlight?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Not needed as we now use the analytics from the action button on the fare card */
  clickEventAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  collapseAnalytics: Array<ClientSideAnalytics>;
  collapsedStateScrollAnalytics: Array<ClientSideAnalytics>;
  displayAdaptExAnalytics: Array<FlightsAdaptExAnalytics>;
  fareCardIdentifier: Scalars["String"]["output"];
  fareDetails: Array<EgdsText>;
  fareMessage?: Maybe<EgdsStylizedText>;
  /** @deprecated no usecase for this since sponsored upsell tabbed sidesheet is cleaned up */
  fareTabs?: Maybe<EgdsBasicTabs>;
  featuredHeader?: Maybe<Scalars["String"]["output"]>;
  heading: FareHeading;
  highlighted: Scalars["Boolean"]["output"];
  isPopularFare: Scalars["Boolean"]["output"];
  /** @deprecated No longer in use, to be cleaned up */
  isRefundableFare: Scalars["Boolean"]["output"];
  isSponsored: Scalars["Boolean"]["output"];
  journeyContinuationId: Scalars["String"]["output"];
  journeyDetails?: Maybe<Array<FlightsJourneyWithDetails>>;
  priceMatchPromise?: Maybe<FarePriceMatchPromisePresentation>;
  scrollAnalytics: Array<ClientSideAnalytics>;
  /** @deprecated Use isSponsoredContent instead */
  sponsoredContent?: Maybe<FlightsSponsoredFareInformation>;
  state: FareState;
};

export type FareMediaSection = {
  __typename?: "FareMediaSection";
  badge?: Maybe<EgdsStandardBadge>;
  featureImage: Image;
};

export type FareNameAvailable = "AVAILABLE" | "NOT_AVAILABLE";

export interface FarePriceMatchPromiseCard {
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  subHeading: Scalars["String"]["output"];
}

export type FarePriceMatchPromiseCardHeadingType = EgdsBasicSectionHeading | EgdsGraphicText;

export type FarePriceMatchPromisePresentation = {
  __typename?: "FarePriceMatchPromisePresentation";
  cards: Array<FarePriceMatchPromiseCard>;
  priceMatchPromiseState: PriceMatchPromiseState;
};

export type FarePriceMatchPromiseRemovableCard = FarePriceMatchPromiseCard & {
  __typename?: "FarePriceMatchPromiseRemovableCard";
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  removeAction: FlightsActionLink;
  subHeading: Scalars["String"]["output"];
};

export type FarePriceMatchPromiseSelectableCard = FarePriceMatchPromiseCard & {
  __typename?: "FarePriceMatchPromiseSelectableCard";
  checkbox: FlightsActionCheckbox;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionDetailsLinkTrigger?: Maybe<FlightsDialogTrigger>;
  /** @deprecated descriptionDetailsTrigger has been deprecated because used wrong type, instead use descriptionDetailsLinkTrigger */
  descriptionDetailsTrigger?: Maybe<FlightsExperienceDialogTrigger>;
  /** @deprecated Use headingSection for representing header */
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  headingSection: FarePriceMatchPromiseCardHeadingType;
  infoTrigger: FlightsDialogTrigger;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  price: Scalars["String"]["output"];
  priceInfo?: Maybe<Scalars["String"]["output"]>;
  priceMatchPromiseState: PriceMatchPromiseState;
  /** @deprecated Use description instead of subheading favoring optionality */
  subHeading: Scalars["String"]["output"];
};

export type FarePriceMatchPromiseStaticCard = FarePriceMatchPromiseCard & {
  __typename?: "FarePriceMatchPromiseStaticCard";
  header: EgdsGraphicText;
  /** @deprecated Use header which uses EGDSGraphicText instead of heading which uses EGDSIconText */
  heading: EgdsIconText;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  subHeading: Scalars["String"]["output"];
};

export type FarePricing = {
  __typename?: "FarePricing";
  addOnIcons?: Maybe<Array<Icon>>;
  priceLockup: EgdsPriceLockup;
  priceSection?: Maybe<FlightsDetailsAndFaresPriceSection>;
  states: Array<FareState>;
};

export type FareSeatMapAlertDialog = {
  __typename?: "FareSeatMapAlertDialog";
  alert: FlightsDialogStandard;
  id: FlightsActionType;
};

export type FareSeatMapAvailableFlightLegSelection = {
  __typename?: "FareSeatMapAvailableFlightLegSelection";
  errorAnalytics: FlightsAnalytics;
  identifier: Scalars["String"]["output"];
  isSelected: Scalars["Boolean"]["output"];
  seatMapLegSummary?: Maybe<FlightsSeatMapLegSummary>;
  seatSegment?: Maybe<SeatSegment>;
  selectedCard: FlightsIconPlacard;
  unselectedCard: FlightsIconPlacard;
};

export type FareSeatMapAvailableFlightLegsDetails = {
  __typename?: "FareSeatMapAvailableFlightLegsDetails";
  flightLegSelectionList: Array<FareSeatMapAvailableFlightLegSelection>;
  offerIdentifier: Scalars["String"]["output"];
  onViewedAnalytics?: Maybe<Array<FlightsAnalytics>>;
  pricingConstruct?: Maybe<Scalars["String"]["output"]>;
};

export type FareSeatMapDynamicElement = {
  __typename?: "FareSeatMapDynamicElement";
  flightLegsDetails?: Maybe<FareSeatMapAvailableFlightLegsDetails>;
  onSelectAnalytics: Array<FlightsAnalytics>;
};

export type FareSeatMapInformation = {
  __typename?: "FareSeatMapInformation";
  /** @deprecated This field is now deprecated., replace with Use baseOfferAncillaryID */
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<FlightsAction>;
  enableSeatSelection?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated This field is now deprecated., replace with Use the sibling node with list of FlightsSeatSelectionErrorMessage */
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  errorMessages?: Maybe<Array<FlightsSeatSelectionErrorMessage>>;
  fareSeatMapViewDetails?: Maybe<FareSeatMapViewDetails>;
  message: Scalars["String"]["output"];
  selectedSeatDetails?: Maybe<Array<FlightsSelectedSeatDetail>>;
  skeletonCount: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
};

export type FareSeatMapLegendsInformation = {
  __typename?: "FareSeatMapLegendsInformation";
  accessibilityMessage: Scalars["String"]["output"];
  dismissSeatMapLegends?: Maybe<FlightsAnalytics>;
  legendDetails: FlightsSeatMapLegends;
  viewLegendButton?: Maybe<FlightsActionableIcon>;
};

export type FareSeatMapViewDetails = {
  __typename?: "FareSeatMapViewDetails";
  dismissSeatMapDialog: FlightsActionableIcon;
  errorMessage?: Maybe<FlightsIconPlacard>;
  fareSeatMapLegendsInformation?: Maybe<FareSeatMapLegendsInformation>;
  flightLegsDetails?: Maybe<FareSeatMapAvailableFlightLegsDetails>;
  onLoadAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  seatMapAlertDialog?: Maybe<Array<FareSeatMapAlertDialog>>;
  seatMapDialogAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
  title: Scalars["String"]["output"];
};

export type FareSectionTitle = {
  __typename?: "FareSectionTitle";
  fareNameAvailable?: Maybe<FareNameAvailable>;
  icon?: Maybe<Icon>;
  title?: Maybe<EgdsHeading>;
};

export type FareState =
  | "META_UNSELECTED"
  | "SELECTED"
  | "SELECTED_PMP_SELECTED"
  | "UNSELECTED"
  | "UNSELECTED_PMP_SELECTED";

export type FareSummary = {
  __typename?: "FareSummary";
  additionalDetails?: Maybe<FlightsCategorizedList>;
  details: FlightsAction;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  flightsFareNudgeMessage?: Maybe<FlightsIconAndLabel>;
  /** @deprecated Replaced with details and fares node */
  flightsSelectedJourneyAvailableFaresInformation?: Maybe<FlightsSelectedJourneyAvailableFares>;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  upsellNudgingFeatureEnabled: Scalars["Boolean"]["output"];
};

export type FaresLoadingAnalytics = {
  __typename?: "FaresLoadingAnalytics";
  continueButtonOnClickAnalytics: ClientSideAnalytics;
  faresLoadErrorAnalytics: ClientSideAnalytics;
  faresLoadingDisplayedAnalytics: ClientSideAnalytics;
  faresLoadingTimeAnalytics: ClientSideAnalytics;
};

export type FaresTypesLoadingAnalytics = {
  __typename?: "FaresTypesLoadingAnalytics";
  continueButtonOnClickAnalytics: FlightsAnalytics;
  faresLoadErrorAnalytics: FlightsAnalytics;
  faresLoadingDisplayedAnalytics: FlightsAnalytics;
  faresLoadingTimeAnalytics: FlightsAnalytics;
};

export type FastTrackAction = {
  __typename?: "FastTrackAction";
  analytics?: Maybe<FastTrackAnalytics>;
  data: FastTrackLink;
  type: FastTrackActionType;
};

export type FastTrackActionType = "JUMPLINK" | "MODAL" | "REDIRECT";

export type FastTrackAdaptex = {
  __typename?: "FastTrackAdaptex";
  arm: Scalars["String"]["output"];
};

export type FastTrackAdaptexClientsConfig = {
  __typename?: "FastTrackAdaptexClientsConfig";
  clientName: Scalars["String"]["output"];
  device: DeviceType;
};

export type FastTrackAdaptexConfig = {
  __typename?: "FastTrackAdaptexConfig";
  clients: Array<FastTrackAdaptexClientsConfig>;
  featureCampaign: Scalars["String"]["output"];
  isEnabled: Scalars["Boolean"]["output"];
};

export type FastTrackAnalytics = {
  __typename?: "FastTrackAnalytics";
  appendToReferrerId: Scalars["String"]["output"];
  client: ClientSideAnalytics;
};

export type FastTrackAssetSelection = {
  __typename?: "FastTrackAssetSelection";
  asset: FastTrackProductEntityAssetSelection;
  param?: Maybe<IFastTrackProductEntityAssetSelection>;
  parameters?: Maybe<FastTrackProductEntityAssetSelectionParameters>;
};

export type FastTrackBreakpointConfig = {
  __typename?: "FastTrackBreakpointConfig";
  lg: Scalars["Int"]["output"];
  md: Scalars["Int"]["output"];
  sm: Scalars["Int"]["output"];
  xl: Scalars["Int"]["output"];
};

export type FastTrackButton = {
  __typename?: "FastTrackButton";
  action: FastTrackAction;
  isFullWidth: Scalars["Boolean"]["output"];
  type: EgdsButtonStyle;
};

export type FastTrackCardConfig = {
  __typename?: "FastTrackCardConfig";
  background?: Maybe<Scalars["String"]["output"]>;
  border: Scalars["Boolean"]["output"];
  featuredHeader?: Maybe<FastTrackCardFeaturedHeader>;
  featuredImage?: Maybe<Image>;
  link?: Maybe<FastTrackCardLink>;
  overflow: Scalars["Boolean"]["output"];
  padded: Scalars["Boolean"]["output"];
  selected: Scalars["Boolean"]["output"];
  size?: Maybe<FastTrackContainerSize>;
};

export type FastTrackCardFeaturedHeader = {
  __typename?: "FastTrackCardFeaturedHeader";
  tag?: Maybe<FastTrackConfigTag>;
  text: Scalars["String"]["output"];
};

export type FastTrackCardLink = {
  __typename?: "FastTrackCardLink";
  action: FastTrackLinkAction;
  text: Scalars["String"]["output"];
};

export type FastTrackCarouselAnalytics = {
  __typename?: "FastTrackCarouselAnalytics";
  scrollEvent?: Maybe<ClientSideAnalytics>;
  viewed?: Maybe<ClientSideAnalytics>;
};

export type FastTrackCarouselConfig = {
  __typename?: "FastTrackCarouselConfig";
  alwaysShowPaging: Scalars["Boolean"]["output"];
  analytics?: Maybe<FastTrackCarouselAnalytics>;
  hideNav: Scalars["Boolean"]["output"];
  imagesList?: Maybe<Array<Image>>;
  itemsVisible?: Maybe<FastTrackBreakpointConfig>;
  naturalWidth: Scalars["Boolean"]["output"];
  nextButton?: Maybe<Scalars["String"]["output"]>;
  peek: Scalars["Boolean"]["output"];
  prevButton?: Maybe<Scalars["String"]["output"]>;
};

export type FastTrackClickableConfig = {
  __typename?: "FastTrackClickableConfig";
  action: FastTrackAction;
};

export type FastTrackCloseType = EgdsSheet;

export interface FastTrackComponent {
  config: FastTrackExperimentalConfig;
  metadata: FastTrackMetadata;
  type: FastTrackElements;
}

export type FastTrackComponentWrapper = {
  __typename?: "FastTrackComponentWrapper";
  childrenIds: Array<Scalars["String"]["output"]>;
  element: FastTrackExperimentalComponent;
  id: Scalars["String"]["output"];
};

export type FastTrackConfig = {
  __typename?: "FastTrackConfig";
  features: Array<FastTrackFeatureTest>;
};

export type FastTrackConfigTag = "H1" | "H2" | "H3" | "H4" | "H5" | "H6";

export type FastTrackContainerConfig = {
  __typename?: "FastTrackContainerConfig";
  action?: Maybe<FastTrackAction>;
  flex?: Maybe<FastTrackFlexContainer>;
  impression?: Maybe<FastTrackImpressionTracker>;
};

export type FastTrackContainerSize =
  | "EIGHTY_EIGHT"
  | "FIVE_HUNDRED"
  | "FORTY_EIGHT"
  | "NINETY_SIX"
  | "ONE_HUNDRED_EIGHTY"
  | "ONE_HUNDRED_THIRTY"
  | "ONE_HUNDRED_TWELVE"
  | "SEVENTY"
  | "SIXTY"
  | "THIRTY_SIX"
  | "THREE_HUNDRED_TWELVE"
  | "TWELVE"
  | "TWENTY_FOUR"
  | "TWO_HUNDRED_FOUR";

export type FastTrackDestinationApiFeature = {
  __typename?: "FastTrackDestinationApiFeature";
  feature: Scalars["String"]["output"];
};

export type FastTrackDeviceTypeTemplate = {
  __typename?: "FastTrackDeviceTypeTemplate";
  adaptex?: Maybe<FastTrackAdaptex>;
  brands?: Maybe<Array<Scalars["String"]["output"]>>;
  device: DeviceType;
  identifierTypes?: Maybe<Array<IdentifierType>>;
  template: Scalars["String"]["output"];
};

export type FastTrackDomain = {
  __typename?: "FastTrackDomain";
  productEntity?: Maybe<Scalars["String"]["output"]>;
};

export type FastTrackElements =
  | "Card"
  | "Carousel"
  | "Clickable"
  | "Expando"
  | "Grid"
  | "InfoSection"
  | "IntersectionTracker"
  | "Media"
  | "Modal"
  | "Rating"
  | "Spacing"
  | "Tabs"
  | "TextIconTooltip";

export type FastTrackExpandoConfig = {
  __typename?: "FastTrackExpandoConfig";
  analytics?: Maybe<FastTrackAnalytics>;
  collapseLabel?: Maybe<Scalars["String"]["output"]>;
  expandLabel: Scalars["String"]["output"];
  hideScrim: Scalars["Boolean"]["output"];
  isList: Scalars["Boolean"]["output"];
  itemsToShow?: Maybe<Scalars["Int"]["output"]>;
  linesToShow?: Maybe<Scalars["Int"]["output"]>;
};

export type FastTrackExperimentalComponent = FastTrackComponent & {
  __typename?: "FastTrackExperimentalComponent";
  config: FastTrackExperimentalConfig;
  metadata: FastTrackMetadata;
  type: FastTrackElements;
  typeClass: Scalars["String"]["output"];
};

export type FastTrackExperimentalConfig = {
  __typename?: "FastTrackExperimentalConfig";
  card?: Maybe<FastTrackCardConfig>;
  carousel?: Maybe<FastTrackCarouselConfig>;
  /** @deprecated Deprecated: Use 'container.action', replace with container.action */
  clickable?: Maybe<FastTrackClickableConfig>;
  container?: Maybe<FastTrackContainerConfig>;
  expando?: Maybe<FastTrackExpandoConfig>;
  grid?: Maybe<FastTrackLayoutGrid>;
  infoSection?: Maybe<FastTrackInfoSection>;
  /** @deprecated Deprecated: Use 'container.impression', replace with container.impression */
  intersection?: Maybe<FastTrackIntersectionTracker>;
  media?: Maybe<FastTrackMediaConfig>;
  modal?: Maybe<FastTrackModalConfig>;
  rating?: Maybe<FastTrackRatingConfig>;
  script?: Maybe<FastTrackScript>;
  spacing?: Maybe<FastTrackSpacing>;
  tabs?: Maybe<FastTrackTabsConfig>;
  textIconTooltip?: Maybe<FastTrackProductTextInfoSection>;
};

export type FastTrackFeatureExperiment = {
  __typename?: "FastTrackFeatureExperiment";
  id: Scalars["Int"]["output"];
};

export type FastTrackFeatureTest = {
  __typename?: "FastTrackFeatureTest";
  adaptexConfig?: Maybe<FastTrackAdaptexConfig>;
  destinationApi?: Maybe<FastTrackDestinationApiFeature>;
  experiment?: Maybe<FastTrackFeatureExperiment>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  productEntity?: Maybe<Array<FastTrackAssetSelection>>;
  templateName: FastTrackTemplateConfig;
  usePebble: Scalars["Boolean"]["output"];
};

export type FastTrackFlexAlign = "BASELINE" | "CENTER" | "END" | "START" | "STRETCH";

export type FastTrackFlexContainer = {
  __typename?: "FastTrackFlexContainer";
  direction?: Maybe<FastTrackFlexDirection>;
  horizontal?: Maybe<FastTrackFlexJustify>;
  vertical?: Maybe<FastTrackFlexAlign>;
};

export type FastTrackFlexDirection = "COLUMN" | "ROW";

export type FastTrackFlexJustify = "CENTER" | "END" | "START";

export type FastTrackHelperConfigMap =
  | "And"
  | "CapitalizeFirst"
  | "DeviceTypeEvaluator"
  | "Equals"
  | "EvaluateExperiment"
  | "EvaluateExperimentAndLog"
  | "ExperimentEvaluator"
  | "FormatDoubleNumber"
  | "FormatIntegerNumber"
  | "GetSystemTimeInMs"
  | "GetUsableURN"
  | "Greater"
  | "GreaterAndEquals"
  | "HTTPLink"
  | "ImpressionAnalyticsPayload"
  | "IsNotNullOrEmpty"
  | "IsQualifiedVrboProperty"
  | "Join"
  | "Limit"
  | "Localize"
  | "Not"
  | "Or"
  | "Substring";

export type FastTrackHelperMetadata = {
  __typename?: "FastTrackHelperMetadata";
  description?: Maybe<Scalars["String"]["output"]>;
  name: FastTrackHelperConfigMap;
  usage?: Maybe<Scalars["String"]["output"]>;
};

export type FastTrackIdentifierMetadata = {
  __typename?: "FastTrackIdentifierMetadata";
  description?: Maybe<Scalars["String"]["output"]>;
  name: IdentifierType;
};

export type FastTrackImage = {
  __typename?: "FastTrackImage";
  /** @deprecated Deprecated: Use 'Clickable' element instead */
  actions?: Maybe<Array<FastTrackAction>>;
  aspectRatio: AspectRatio;
  /** @deprecated Consider using `events`, replace with events */
  clickEvent?: Maybe<FastTrackAnalytics>;
  description?: Maybe<Scalars["String"]["output"]>;
  events?: Maybe<Array<FastTrackAnalytics>>;
  imageFit: FastTrackImageFit;
  lazyLoading: FastTrackImageLazyload;
  placeholder: Scalars["Boolean"]["output"];
  roundedCorner: FastTrackImageRounded;
  source: Scalars["String"]["output"];
  useBlank: Scalars["Boolean"]["output"];
};

export type FastTrackImageAssetSelection = {
  __typename?: "FastTrackImageAssetSelection";
  groupImages: Scalars["Boolean"]["output"];
  limit: Scalars["Int"]["output"];
};

export type FastTrackImageFit = "CONTAIN" | "COVER";

export type FastTrackImageLazyload = "EAGER" | "LAZY" | "OFF";

export type FastTrackImageRounded =
  | "ALL"
  | "END_END"
  | "END_START"
  | "FALSE"
  | "MAX"
  | "START_END"
  | "START_START"
  | "TRUE";

export type FastTrackImpressionTracker = {
  __typename?: "FastTrackImpressionTracker";
  analytics: ClientSideAnalytics;
  trackOnce: Scalars["Boolean"]["output"];
};

export type FastTrackInfoSection = {
  __typename?: "FastTrackInfoSection";
  badge?: Maybe<EgdsStandardBadge>;
  buttons?: Maybe<Array<FastTrackButton>>;
  descriptions?: Maybe<FastTrackInfoSectionDescriptions>;
  links?: Maybe<Array<FastTrackAction>>;
  subTitle?: Maybe<Array<FastTrackInfoSectionSubTitle>>;
  title?: Maybe<FastTrackInfoSectionTitle>;
};

export type FastTrackInfoSectionDescriptions = {
  __typename?: "FastTrackInfoSectionDescriptions";
  showAsList: Scalars["Boolean"]["output"];
  texts?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type FastTrackInfoSectionSubTitle = {
  __typename?: "FastTrackInfoSectionSubTitle";
  icon?: Maybe<Icon>;
  linesToDisplay?: Maybe<Scalars["Int"]["output"]>;
  overflow?: Maybe<FastTrackOverflow>;
  space?: Maybe<FastTrackSpace>;
  text?: Maybe<EgdsStylizedText>;
};

export type FastTrackInfoSectionTitle = {
  __typename?: "FastTrackInfoSectionTitle";
  icon?: Maybe<Icon>;
  linesToDisplay?: Maybe<Scalars["Int"]["output"]>;
  overflow?: Maybe<FastTrackOverflow>;
  spacing?: Maybe<FastTrackSpacing>;
  text?: Maybe<EgdsStylizedText>;
};

export type FastTrackIntersectionTracker = {
  __typename?: "FastTrackIntersectionTracker";
  analytics: ClientSideAnalytics;
  trackOnce: Scalars["Boolean"]["output"];
};

export type FastTrackLayoutGrid = {
  __typename?: "FastTrackLayoutGrid";
  columns: Scalars["Int"]["output"];
  gridItem?: Maybe<Array<FastTrackLayoutGridItem>>;
  space?: Maybe<FastTrackSpace>;
};

export type FastTrackLayoutGridItem = {
  __typename?: "FastTrackLayoutGridItem";
  colSpan: Scalars["Int"]["output"];
};

export type FastTrackLink = {
  __typename?: "FastTrackLink";
  action: FastTrackLinkAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  inlineSpace?: Maybe<FastTrackSpace>;
  text: Scalars["String"]["output"];
};

export type FastTrackLinkAction = {
  __typename?: "FastTrackLinkAction";
  clickEvent?: Maybe<ClientSideAnalytics>;
  modal?: Maybe<FastTrackModal>;
  target?: Maybe<UiLinkTarget>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type FastTrackMediaConfig = {
  __typename?: "FastTrackMediaConfig";
  image: FastTrackImage;
};

export type FastTrackMetadata = {
  __typename?: "FastTrackMetadata";
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  signalEvents?: Maybe<Array<FastTrackSignalEvent>>;
};

export type FastTrackModal = {
  __typename?: "FastTrackModal";
  id: Scalars["String"]["output"];
  queryKey?: Maybe<Scalars["String"]["output"]>;
};

export type FastTrackModalConfig = {
  __typename?: "FastTrackModalConfig";
  fullWidth: Scalars["Boolean"]["output"];
  isExpanded: Scalars["Boolean"]["output"];
  modalId: FastTrackModal;
  position: FastTrackModalPosition;
  sideSheetLayout: FastTrackModalSideSheetLayout;
  toolbar?: Maybe<FastTrackModalToolbar>;
  type: FastTrackModalType;
};

export type FastTrackModalNavType = "BACK" | "CLOSE" | "EXPAND_DOWN" | "EXPAND_UP";

export type FastTrackModalPosition = "LEADING" | "TRAILING";

export type FastTrackModalSideSheetLayout = "EIGHT" | "FOUR" | "ONE" | "SIX" | "THREE" | "TWELVE" | "TWO";

export type FastTrackModalToolbar = {
  __typename?: "FastTrackModalToolbar";
  analytics?: Maybe<FastTrackAnalytics>;
  closeLabel: Scalars["String"]["output"];
  navType: FastTrackModalNavType;
  title: Scalars["String"]["output"];
};

export type FastTrackModalType = "BOTTOM" | "CENTERED" | "FULL" | "POPOVER" | "SIDE";

export type FastTrackOverflow =
  | "TRUNCATE"
  | "TRUNCATE_FOUR_LINES"
  | "TRUNCATE_THREE_LINES"
  | "TRUNCATE_TWO_LINES"
  | "WRAP";

export type FastTrackPattern = {
  __typename?: "FastTrackPattern";
  description: Scalars["String"]["output"];
  hasChildren: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  type: FastTrackElements;
};

export type FastTrackPopUpMessage = {
  __typename?: "FastTrackPopUpMessage";
  close?: Maybe<FastTrackCloseType>;
  content?: Maybe<Array<FastTrackProductSupportingMessage>>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FastTrackProductEntityAssetSelection =
  | "ACCESSIBILITY_CONTENT"
  | "AMENITY"
  | "EXPERIENCE_SCORE"
  | "EXPERIENCE_SCORE_WITH_TOP_RATED_ATTRIBUTES"
  | "FAMILY_HIGHLIGHTS"
  | "HEADLINE"
  | "IMAGE"
  | "LOCATION_DESCRIPTION"
  | "LUXURY_STAYS"
  | "PLACE"
  | "POLICY"
  | "PRODUCT_ATTRIBUTE"
  | "PRODUCT_HIGHLIGHTS"
  | "PRODUCT_UNIQUENESS_HIGHLIGHTS"
  | "PROPERTY_AMENITY_GROUPS"
  | "RELEVANT_AMENITY_DETAILS"
  | "REVIEW_DETAILS"
  | "REVIEW_IMAGES"
  | "REVIEW_SUMMARY"
  | "REVIEW_SUMMARY_HIGHLIGHTS"
  | "SUPPLY_SCORE"
  | "TOP_RATED_DETAILS"
  | "VIRTUAL_TOUR";

export type FastTrackProductEntityAssetSelectionParameters = {
  __typename?: "FastTrackProductEntityAssetSelectionParameters";
  limit?: Maybe<Scalars["Int"]["output"]>;
  refinements?: Maybe<Array<FastTrackProductEntityRefinementSelection>>;
};

export type FastTrackProductEntityRefinementSelection = "EMBARGO";

export type FastTrackProductSupportingMessage = EgdsGraphicText | EgdsInlineLink | EgdsPlainText | EgdsStandardBadge;

export type FastTrackProductTextInfoSection = {
  __typename?: "FastTrackProductTextInfoSection";
  accessibilityText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  message?: Maybe<FastTrackPopUpMessage>;
  /** @deprecated Deprecated for a more generic type, replace with accessibilityText */
  policyA11yText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated for a shared type, replace with theme */
  policyTheme: Scalars["String"]["output"];
  theme: IconTheme;
  title: EgdsStylizedText;
  /** @deprecated Deprecated for a more generic type, replace with message */
  tooltipContent?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Deprecated for a more generic type, replace with icon */
  tooltipIcon?: Maybe<Icon>;
};

export type FastTrackRatingConfig = {
  __typename?: "FastTrackRatingConfig";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  iconId?: Maybe<Scalars["String"]["output"]>;
  rating: Scalars["Float"]["output"];
};

export type FastTrackResponse = {
  __typename?: "FastTrackResponse";
  id: Scalars["String"]["output"];
  mapping: Array<FastTrackComponentWrapper>;
  metadata?: Maybe<FastTrackMetadata>;
};

export type FastTrackScript = {
  __typename?: "FastTrackScript";
  expression?: Maybe<Scalars["String"]["output"]>;
  variables?: Maybe<Array<FastTrackVariable>>;
};

export type FastTrackSetup = {
  __typename?: "FastTrackSetup";
  assets: Array<FastTrackProductEntityAssetSelection>;
  featureIds: FastTrackConfig;
  helpers: Array<FastTrackHelperMetadata>;
  identifiers: Array<FastTrackIdentifierMetadata>;
  patterns: Array<FastTrackPattern>;
};

export type FastTrackSignalEvent = {
  __typename?: "FastTrackSignalEvent";
  id: Scalars["String"]["output"];
  successEvent: ClientSideAnalytics;
};

export type FastTrackSpace = "four" | "half" | "one" | "six" | "three" | "twelve" | "two";

export type FastTrackSpacing = {
  __typename?: "FastTrackSpacing";
  inlineMarginSpace?: Maybe<FastTrackSpacingOptions>;
  inlinePaddingSpace?: Maybe<FastTrackSpacingOptions>;
  /** @deprecated Conflicts with the native definition, use marginSpace instead */
  margin?: Maybe<FastTrackSpacingOptions>;
  marginSpace?: Maybe<FastTrackSpacingOptions>;
  /** @deprecated Conflicts with the native definition, use paddingSpace instead */
  padding?: Maybe<FastTrackSpacingOptions>;
  paddingSpace?: Maybe<FastTrackSpacingOptions>;
};

export type FastTrackSpacingOptions =
  | "FOUR"
  | "HALF"
  | "ONE"
  | "SIX"
  | "SIXTEEN"
  | "THREE"
  | "TWELVE"
  | "TWO"
  | "UNSET";

export type FastTrackTab = {
  __typename?: "FastTrackTab";
  analytics?: Maybe<FastTrackAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  target: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type FastTrackTabType = "EQUAL_WIDTH" | "NATURAL";

export type FastTrackTabsConfig = {
  __typename?: "FastTrackTabsConfig";
  border: Scalars["Boolean"]["output"];
  isJumpLink: Scalars["Boolean"]["output"];
  scrollTo: Scalars["Boolean"]["output"];
  selectedIndex: Scalars["Int"]["output"];
  tabs: Array<FastTrackTab>;
  type: FastTrackTabType;
};

export type FastTrackTemplateConfig = {
  __typename?: "FastTrackTemplateConfig";
  default: Scalars["String"]["output"];
  platform?: Maybe<Array<FastTrackDeviceTypeTemplate>>;
};

export type FastTrackVariable = {
  __typename?: "FastTrackVariable";
  expression: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type FeatureExperiment = {
  __typename?: "FeatureExperiment";
  id: Scalars["Int"]["output"];
};

export type FeatureTest = {
  __typename?: "FeatureTest";
  adaptexConfig?: Maybe<AdaptexConfig>;
  destinationApi?: Maybe<DestinationApiFeature>;
  experiment?: Maybe<FeatureExperiment>;
  id: Scalars["String"]["output"];
  languages?: Maybe<Array<Scalars["String"]["output"]>>;
  name?: Maybe<Scalars["String"]["output"]>;
  productEntity?: Maybe<Array<AssetSelection>>;
  templateName: TemplateConfig;
  useTemplatingEngine: Scalars["Boolean"]["output"];
};

export type FeaturedCustomerCampaigns = {
  __typename?: "FeaturedCustomerCampaigns";
  carousel: EgdsCarousel;
  title: Scalars["String"]["output"];
};

export type FeaturedCustomerCard = EgdsCarouselItem & {
  __typename?: "FeaturedCustomerCard";
  featuredImage: Image;
  featuredSummary: Scalars["String"]["output"];
  featuredTitle: Scalars["String"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  readMoreText: Scalars["String"]["output"];
  readMoreUrl: Scalars["String"]["output"];
};

export type FeaturedMediaFormSection = {
  __typename?: "FeaturedMediaFormSection";
  featuredMediaSectionHeader?: Maybe<Scalars["String"]["output"]>;
  uploadImageButton?: Maybe<UploadButton>;
};

export type FederationPropertyNaturalKey = {
  __typename?: "FederationPropertyNaturalKey";
  checkIn: FederationPropertyNaturalKeyDate;
  checkOut: FederationPropertyNaturalKeyDate;
  id: Scalars["String"]["output"];
  inventoryType: InventoryType;
  noCreditCard?: Maybe<Scalars["Boolean"]["output"]>;
  ratePlanId: Scalars["String"]["output"];
  ratePlanType?: Maybe<Scalars["String"]["output"]>;
  roomTypeId: Scalars["String"]["output"];
  rooms: Array<FederationPropertyNaturalKeyRoom>;
  shoppingPath?: Maybe<ShoppingPathType>;
};

export type FederationPropertyNaturalKeyDate = {
  __typename?: "FederationPropertyNaturalKeyDate";
  day: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type FederationPropertyNaturalKeyRoom = {
  __typename?: "FederationPropertyNaturalKeyRoom";
  childAges: Array<Scalars["Int"]["output"]>;
  numberOfAdults: Scalars["Int"]["output"];
};

export type FeeType =
  | "CARD_ON_FILE_DAMAGE_LIABILITY"
  | "DEFAULT"
  | "DEFAULT_SUPPLIER_COLLECTED"
  | "GROUPED_MANDATORY_FEES"
  | "L26_TAX_BREAKOUT"
  | "NORMAL"
  | "OTHER"
  | "PER_PERSON_PER_NIGHT"
  | "PROPERTY"
  | "REFUNDABLE_DAMAGE_DEPOSIT"
  | "RESORT";

export type Feedback = {
  __typename?: "Feedback";
  action?: Maybe<FeedbackAction>;
};

export type FeedbackAction = {
  __typename?: "FeedbackAction";
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackIndicator = AccessibilityField & {
  __typename?: "FeedbackIndicator";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackOption = {
  __typename?: "FeedbackOption";
  inputHeading: Scalars["String"]["output"];
  inputTextPlaceholder: Scalars["String"]["output"];
  option: CarActionableItem;
};

export type Fees = {
  __typename?: "Fees";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  inSaleCurrency?: Maybe<Scalars["Boolean"]["output"]>;
  included?: Maybe<Scalars["Boolean"]["output"]>;
  price?: Maybe<Money>;
  type?: Maybe<FeeType>;
};

export type File = {
  __typename?: "File";
  error?: Maybe<Scalars["String"]["output"]>;
  errorEntries?: Maybe<Scalars["Int"]["output"]>;
  fileCategory: Scalars["String"]["output"];
  fileLocation: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  fileType: Scalars["String"]["output"];
  fileUploadDateTime: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  outputFilePath?: Maybe<Scalars["String"]["output"]>;
  processedEntries?: Maybe<Scalars["Int"]["output"]>;
  rerunCount?: Maybe<Scalars["Int"]["output"]>;
  runStatus?: Maybe<Scalars["String"]["output"]>;
  totalEntries?: Maybe<Scalars["Int"]["output"]>;
  userEmail: Scalars["String"]["output"];
  userId: Scalars["String"]["output"];
};

export type FilterCard = EgdsCarouselItem & {
  __typename?: "FilterCard";
  actions: Array<EgdsButton>;
  filters: Array<EgdsButton>;
  heading: Scalars["String"]["output"];
  id?: Maybe<Scalars["String"]["output"]>;
  illustration: Illustration;
  pageIndex: Scalars["String"]["output"];
  subheading: Scalars["String"]["output"];
};

export type FilterIntentCarouselCard = EgdsCarouselItem & {
  __typename?: "FilterIntentCarouselCard";
  criteria: ShoppingSearchCriteria;
  heading: Scalars["String"]["output"];
  icon: Icon;
  id?: Maybe<Scalars["String"]["output"]>;
  list: EgdsTextIconList;
  selectedAnalytics?: Maybe<ClientSideAnalytics>;
  tapAnalytics?: Maybe<ClientSideAnalytics>;
};

export type FilterIntentMissingIntentCarouselCard = EgdsCarouselItem & {
  __typename?: "FilterIntentMissingIntentCarouselCard";
  filterIntentTypeInputSheet?: Maybe<FilterIntentTypeInputSheet>;
  id?: Maybe<Scalars["String"]["output"]>;
  selectedAnalytics: ClientSideAnalytics;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FilterIntentSheet = {
  __typename?: "FilterIntentSheet";
  button: UiPrimaryButton;
  carousel: EgdsCarousel;
  closeAnalytics: ClientSideAnalytics;
  presentedAnalytics: ClientSideAnalytics;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type FilterIntentTypeConfirmationSheet = {
  __typename?: "FilterIntentTypeConfirmationSheet";
  autoCloseTimeout?: Maybe<Scalars["Int"]["output"]>;
  closeAnalytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FilterIntentTypeInputSheet = {
  __typename?: "FilterIntentTypeInputSheet";
  closeAnalytics: ClientSideAnalytics;
  confirmationSheet?: Maybe<FilterIntentTypeConfirmationSheet>;
  input: EgdsTextInputField;
  inputAnalytics?: Maybe<ClientSideAnalytics>;
  primaryButton: UiPrimaryButton;
  secondaryButton: UiSecondaryButton;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FilterItem = {
  __typename?: "FilterItem";
  field: Scalars["String"]["output"];
  values: Array<Scalars["String"]["output"]>;
};

export type FilterOption = UiToggle & {
  __typename?: "FilterOption";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  filteredValues: Array<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
};

export type FilterOptionRange = {
  __typename?: "FilterOptionRange";
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
};

export type FilterPlacement = "OVERLAY_BOTTOM" | "OVERLAY_TOP" | "TOOLBAR";

export type FilterPolicyType = "NO_CHANGE_FEE";

export type FilterTravelAndBaggageType =
  | "CARRY_ON_BAG"
  | "FIRST_CHECKED_BAG"
  | "NO_CANCEL_FEE"
  | "NO_CHANGE_FEE"
  | "SEAT_CHOICE";

export type FilterViewModel = {
  __typename?: "FilterViewModel";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated No longer used, was part of a losing variant in tnl 42928 */
  dropdownOptions?: Maybe<ShowDropdownOptions>;
  graphic?: Maybe<UiGraphic>;
  initiatedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  isRange?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use title instead */
  name: Scalars["String"]["output"];
  options: Array<OptionViewModel>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This value has been refactored into showListOptions and is no longer used */
  seeMoreOptionsToggleThreshold?: Maybe<Scalars["Int"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  showListOptions?: Maybe<ExpandoPeekOptions>;
  step?: Maybe<Scalars["Int"]["output"]>;
  subLabel?: Maybe<Scalars["String"]["output"]>;
  submittedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FiltersWrapper = {
  __typename?: "FiltersWrapper";
  carousel: EgdsCarousel;
  heading: Scalars["String"]["output"];
};

export type FindAnswersDialog = {
  __typename?: "FindAnswersDialog";
  dialogName: Scalars["String"]["output"];
  header: LodgingDialogToolbar;
  trigger: FindAnswersDialogTrigger;
};

export interface FindAnswersDialogTrigger {
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
}

export type FindAnswersMessage = {
  __typename?: "FindAnswersMessage";
  dialog: FindAnswersDialog;
  supportingText?: Maybe<Scalars["String"]["output"]>;
};

export type FlavorType = "DEMO" | "INT" | "LAB" | "PROD" | "SANDBOX";

export type FlexAlignItems = "CENTER" | "END" | "START" | "STRETCH";

export type FlexDirection = "ROW";

export type FlexJustifyContent = "CENTER" | "END" | "SPACE_AROUND" | "SPACE_BETWEEN" | "START";

export type FlexWrap = "NOWRAP";

export type FlexibleDateSearchButton = {
  __typename?: "FlexibleDateSearchButton";
  button?: Maybe<UiSecondaryButton>;
  datePeriods?: Maybe<Array<DatePeriod>>;
};

export type FlexibleDateSearchCard = {
  __typename?: "FlexibleDateSearchCard";
  changeDatesButton?: Maybe<UiSecondaryButton>;
  content?: Maybe<EgdsStandardMessagingCard>;
  flexibleDateSearchButtons?: Maybe<Array<FlexibleDateSearchButton>>;
};

export type FlexibleSearchCriteria = {
  __typename?: "FlexibleSearchCriteria";
  flexibility?: Maybe<Array<DatePeriod>>;
  /** @deprecated Please use searchRange */
  flexibleSearchMonths?: Maybe<Array<Scalars["Int"]["output"]>>;
  flexibleSearchRequirement?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Please use searchRange */
  flexibleSearchTripDurationType?: Maybe<FlexibleSearchTripDurationType>;
  /** @deprecated Please use flexibility */
  numFlexibleDays?: Maybe<Scalars["Int"]["output"]>;
  searchRange?: Maybe<Array<DateRange>>;
};

export interface FlexibleSearchCriteriaInput {
  flexibility?: InputMaybe<Array<DatePeriodInput>>;
  flexibleSearchMonths?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  flexibleSearchRequirement?: InputMaybe<Array<Scalars["String"]["input"]>>;
  flexibleSearchTripDurationType?: InputMaybe<FlexibleSearchTripDurationType>;
  numFlexibleDays?: InputMaybe<Scalars["Int"]["input"]>;
  searchRange?: InputMaybe<Array<DateRangeInput>>;
}

export type FlexibleSearchTripDurationType = "MONTH" | "WEEK" | "WEEKEND";

export type FlightAmenities = {
  __typename?: "FlightAmenities";
  amenities?: Maybe<EgdsTextIconList>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  title: EgdsSpannableText;
};

export type FlightAncillariesPerTraveler = {
  __typename?: "FlightAncillariesPerTraveler";
  ancillaryOptions?: Maybe<Array<FlightSelectableAncillary>>;
  errorMessage?: Maybe<EgdsText>;
  /** @deprecated This must be replaced by travellerIdentifier because when there is only one traveller we do not show heading */
  heading: EgdsHeading;
  travelerIndex: Scalars["Int"]["output"];
  travellerIdentifier?: Maybe<EgdsText>;
};

export type FlightAncillaryBagSelection = {
  __typename?: "FlightAncillaryBagSelection";
  availableOptionsCount?: Maybe<Scalars["Int"]["output"]>;
  selectedBagIndex: Scalars["Int"]["output"];
  selectedBagPrice?: Maybe<Scalars["Float"]["output"]>;
};

export interface FlightAncillaryBagSelectionInput {
  availableOptionsCount?: InputMaybe<Scalars["Int"]["input"]>;
  selectedBagIndex: Scalars["Int"]["input"];
  selectedBagPrice?: InputMaybe<Scalars["Float"]["input"]>;
}

export type FlightAncillaryJourneySelection = {
  __typename?: "FlightAncillaryJourneySelection";
  applySameAncillaryForAllTravellers: Scalars["Boolean"]["output"];
  checkedBagIncluded?: Maybe<Scalars["Int"]["output"]>;
  flightAncillaryTravelerSelections: Array<FlightAncillaryTravelerSelection>;
  journeyIndex: Scalars["Int"]["output"];
};

export interface FlightAncillaryJourneySelectionInput {
  applySameAncillaryForAllTravellers: Scalars["Boolean"]["input"];
  checkedBagIncluded?: InputMaybe<Scalars["Int"]["input"]>;
  flightAncillaryTravelerSelections: Array<FlightAncillaryTravelerSelectionInput>;
  journeyIndex: Scalars["Int"]["input"];
}

export type FlightAncillaryTravelerSelection = {
  __typename?: "FlightAncillaryTravelerSelection";
  combinedAncillaryIndex?: Maybe<Scalars["Int"]["output"]>;
  flightAncillaryBagSelections: Array<FlightAncillaryBagSelection>;
  travelerIndex: Scalars["Int"]["output"];
};

export interface FlightAncillaryTravelerSelectionInput {
  combinedAncillaryIndex?: InputMaybe<Scalars["Int"]["input"]>;
  flightAncillaryBagSelections: Array<FlightAncillaryBagSelectionInput>;
  travelerIndex: Scalars["Int"]["input"];
}

export type FlightBaggageInformationDetail = {
  __typename?: "FlightBaggageInformationDetail";
  baggageDetail: Scalars["String"]["output"];
  /** @deprecated Will start sending all details for baggage in baggageDetail node */
  detail: Scalars["String"]["output"];
  topic: Scalars["String"]["output"];
  unit?: Maybe<Scalars["String"]["output"]>;
};

export type FlightCabinClassInfo = "BUSINESS" | "COACH" | "FIRST" | "PREMIUM_ECONOMY";

export type FlightCardDurationTimeline = {
  __typename?: "FlightCardDurationTimeline";
  /** @deprecated Use 'secondary' field to support different screen sizes */
  additionalInfo: Scalars["String"]["output"];
  end: DurationTimelineSideSection;
  /** @deprecated Use 'primary' field to support different screen sizes */
  heading: Scalars["String"]["output"];
  primary: EgdsStylizedText;
  secondary: EgdsTextSection;
  start: DurationTimelineSideSection;
  stops: Scalars["Int"]["output"];
};

export type FlightCost = {
  __typename?: "FlightCost";
  byDepartureMonth: Array<CostPercentilesByMonth>;
  yearly?: Maybe<CostPercentiles>;
};

export type FlightCreativeCard = {
  __typename?: "FlightCreativeCard";
  selector: EgdsSelect;
};

export interface FlightCreativeInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightCrossSellProductType = "CAR" | "HOTEL";

export type FlightDestinationInsights = {
  __typename?: "FlightDestinationInsights";
  nonStop: FlightDestinationResults;
  popular: Array<InsightsDestination>;
  underCost: FlightDestinationResults;
  underDuration: FlightDestinationResults;
};

export type FlightDestinationInsightsNonStopArgs = {
  destinationHLR?: InputMaybe<Scalars["String"]["input"]>;
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FlightDestinationInsightsPopularArgs = {
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<MonthId>;
};

export type FlightDestinationInsightsUnderCostArgs = {
  destinationHLR?: InputMaybe<Scalars["String"]["input"]>;
  maxCost: Scalars["Int"]["input"];
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FlightDestinationInsightsUnderDurationArgs = {
  destinationHLR?: InputMaybe<Scalars["String"]["input"]>;
  maxDuration: Scalars["Int"]["input"];
  maxResults?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FlightDestinationResults = {
  __typename?: "FlightDestinationResults";
  domestic: Array<Maybe<InsightsAirport>>;
  international: Array<Maybe<InsightsAirport>>;
};

export type FlightDetailInfoSection = {
  __typename?: "FlightDetailInfoSection";
  icon?: Maybe<Icon>;
  originDestinationAbbreviated?: Maybe<Scalars["String"]["output"]>;
  stops?: Maybe<Scalars["String"]["output"]>;
};

export type FlightDetailsConfig = {
  __typename?: "FlightDetailsConfig";
  paginationInfo: Array<Pagination>;
};

export type FlightInsights = {
  __typename?: "FlightInsights";
  cost?: Maybe<FlightCost>;
  purchaseWindowCost: Array<CostPercentilesByWeek>;
  shortestFlightSummary?: Maybe<FlightSummary>;
};

export type FlightInsightsCostArgs = {
  carrier?: InputMaybe<Scalars["String"]["input"]>;
};

export type FlightInsightsShortestFlightSummaryArgs = {
  carrier?: InputMaybe<Scalars["String"]["input"]>;
};

export type FlightJourneyAncillariesSection = {
  __typename?: "FlightJourneyAncillariesSection";
  ancillariesWrapper: FlightsCategorizedList;
  selectedItems?: Maybe<FlightsCategorizedList>;
};

export type FlightJourneyStatus = "FAILURE" | "SUCCESS";

export type FlightLeg = {
  __typename?: "FlightLeg";
  arrivalAirport?: Maybe<Scalars["String"]["output"]>;
  arrivalDate?: Maybe<ZonedDateTime>;
  departureAirport?: Maybe<Scalars["String"]["output"]>;
  departureDate?: Maybe<ZonedDateTime>;
  segmentList?: Maybe<Array<FlightSegment>>;
  stopCount?: Maybe<Scalars["Int"]["output"]>;
};

export type FlightMediaItem = MediaItem & {
  __typename?: "FlightMediaItem";
  media: Media;
};

export type FlightNaturalKey = {
  __typename?: "FlightNaturalKey";
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  offerToken: Scalars["String"]["output"];
  productTokens: Array<Scalars["String"]["output"]>;
  travelers: Array<TravelerDetails>;
};

export interface FlightNaturalKeyInput {
  ancillaryId?: InputMaybe<Scalars["String"]["input"]>;
  offerToken: Scalars["String"]["input"];
  productTokens: Array<Scalars["String"]["input"]>;
  travelers: Array<TravelerDetailsInput>;
}

export type FlightOfferItem = OfferItem & {
  __typename?: "FlightOfferItem";
  airlineImage?: Maybe<Image>;
  airlineName?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  destinationAirportCode?: Maybe<Scalars["String"]["output"]>;
  destinationCity?: Maybe<Scalars["String"]["output"]>;
  flightClass?: Maybe<Scalars["String"]["output"]>;
  flightOffer?: Maybe<OfferData>;
  image: Image;
  name: Scalars["String"]["output"];
  originAirportCode?: Maybe<Scalars["String"]["output"]>;
  originCity?: Maybe<Scalars["String"]["output"]>;
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
  tripType?: Maybe<Scalars["String"]["output"]>;
};

export type FlightPage =
  | "MULTIDEST_LEG_0"
  | "MULTIDEST_LEG_1"
  | "MULTIDEST_LEG_2"
  | "MULTIDEST_LEG_3"
  | "MULTIDEST_LEG_4"
  | "ONE_WAY"
  | "ROUNDTRIP_IN"
  | "ROUNDTRIP_OUT";

export type FlightPassengers = {
  __typename?: "FlightPassengers";
  adults?: Maybe<Scalars["Int"]["output"]>;
  agesOfChildren?: Maybe<Array<Scalars["Int"]["output"]>>;
  infantsInLap?: Maybe<Scalars["Int"]["output"]>;
  infantsInSeat?: Maybe<Scalars["Int"]["output"]>;
};

export type FlightProduct = {
  __typename?: "FlightProduct";
  airlineNames?: Maybe<Array<Scalars["String"]["output"]>>;
};

export interface FlightProductInput {
  airlineNames?: InputMaybe<Array<Scalars["String"]["input"]>>;
  flightType?: InputMaybe<FlightsTripType>;
}

export type FlightSearchCriteria = {
  __typename?: "FlightSearchCriteria";
  primary: PrimaryFlightCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface FlightSearchCriteriaInput {
  primary: PrimaryFlightCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type FlightSearchCriteriaInputType = {
  __typename?: "FlightSearchCriteriaInputType";
  primary: PrimaryFlightCriteriaInputType;
};

export type FlightSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "FlightSearchForm";
    actionURL: Scalars["String"]["output"];
    addLeg?: Maybe<EgdsButton>;
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    directFlightsOnly?: Maybe<EgdsBasicPill>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    flightTypeSelector: EgdsSearchFormInputField;
    legHeading?: Maybe<EgdsLocalizedText>;
    legs: Array<FlightSearchFormLeg>;
    localDateFormat?: Maybe<Scalars["String"]["output"]>;
    maxAllowedLegs?: Maybe<Scalars["Int"]["output"]>;
    playback?: Maybe<EgdsSearchPlayback>;
    removeLeg?: Maybe<EgdsButton>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    softPackages?: Maybe<SoftPackages>;
    valuePropMessage?: Maybe<SearchFormMessaging>;
  };

export type FlightSearchFormLeg = {
  __typename?: "FlightSearchFormLeg";
  date: EgdsSearchFormDatePickerField;
  id: Scalars["String"]["output"];
  locations: EgdsSearchFormLocationsField;
};

export type FlightSearchInputForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "FlightSearchInputForm";
    actionURL: Scalars["String"]["output"];
    addLeg?: Maybe<EgdsButton>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    flightTypeSelector: EgdsSearchFormInputField;
    legHeading?: Maybe<EgdsLocalizedText>;
    playback?: Maybe<EgdsSearchPlayback>;
    removeLeg?: Maybe<EgdsButton>;
    search?: Maybe<EgdsButton>;
  };

export type FlightSearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "FlightSearchMultiItemShoppingAction";
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  multiItemSearchContext: MultiItemSearchContext;
  shoppingContext: ShoppingContext;
};

export type FlightSearchPreferences = {
  __typename?: "FlightSearchPreferences";
  airline?: Maybe<Scalars["String"]["output"]>;
  cabinClass: FlightCabinClassInfo;
  isNonStopOnly: Scalars["Boolean"]["output"];
  isRefundableOnly: Scalars["Boolean"]["output"];
};

export type FlightSeatFeatures = {
  __typename?: "FlightSeatFeatures";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  features?: Maybe<EgdsUnorderedList>;
  title: EgdsSpannableText;
};

export type FlightSeatImages = {
  __typename?: "FlightSeatImages";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  images?: Maybe<EgdsCarousel>;
};

export type FlightSegment = {
  __typename?: "FlightSegment";
  airline?: Maybe<HistoryAirline>;
  arrivalAirport?: Maybe<Scalars["String"]["output"]>;
  arrivalDate?: Maybe<ZonedDateTime>;
  departureAirport?: Maybe<Scalars["String"]["output"]>;
  departureDate?: Maybe<ZonedDateTime>;
  flightNumber?: Maybe<Scalars["String"]["output"]>;
  totalDurationInMinutes?: Maybe<Scalars["String"]["output"]>;
};

export type FlightSelectableAncillary = EgdsRadioGroup & {
  __typename?: "FlightSelectableAncillary";
  baggageType: BaggageType;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupHeading: EgdsText;
  /** @deprecated Use groupHeading as this field needs to be bold */
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  options: Array<FlightSelectableAncillaryOption>;
  selected?: Maybe<Scalars["String"]["output"]>;
  subHeading: EgdsText;
};

export type FlightSelectableAncillaryOption = EgdsRadioButton & {
  __typename?: "FlightSelectableAncillaryOption";
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  labelSuffix?: Maybe<Scalars["String"]["output"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  price: Scalars["Float"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
  weight: Scalars["String"]["output"];
};

export type FlightSelectableAncillaryOptionValuesType = "NONE" | "VALID";

export type FlightSelectableBaggageAncillary = EgdsRadioGroup & {
  __typename?: "FlightSelectableBaggageAncillary";
  baggageType: BaggageType;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  options: Array<FlightSelectableBaggageAncillaryOption>;
  selected?: Maybe<Scalars["String"]["output"]>;
  subHeading: Scalars["String"]["output"];
};

export type FlightSelectableBaggageAncillaryOption = EgdsRadioButton & {
  __typename?: "FlightSelectableBaggageAncillaryOption";
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  labelSuffix?: Maybe<Scalars["String"]["output"]>;
  optionType: FlightSelectableAncillaryOptionValuesType;
  selectableAncillaryToken: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
  weight: Scalars["String"]["output"];
};

export type FlightSelectableBaggageCard = {
  __typename?: "FlightSelectableBaggageCard";
  ancillaryApplicability?: Maybe<EgdsGraphicText>;
  bagSelectionExpando?: Maybe<EgdsExpando>;
  baggageType: BaggageType;
  icon?: Maybe<Icon>;
  label?: Maybe<EgdsSpannableText>;
  selectableBaggageAncillary?: Maybe<FlightsBaggageCheckbox>;
  subLabel?: Maybe<EgdsSpannableText>;
  weightSelectionRadioGroup?: Maybe<FlightsBaggageRadioButtonGroup>;
};

export type FlightSelectedLegOption = {
  __typename?: "FlightSelectedLegOption";
  action: FlightsClientAction;
  label: Scalars["String"]["output"];
  selectedJourneyIndex: Scalars["Int"]["output"];
};

export type FlightServicingBookingEmptyState = {
  __typename?: "FlightServicingBookingEmptyState";
  presentation: UiEmptyState;
  primaryAction: FlightsClientAction;
};

export type FlightServicingBookingFailure = {
  __typename?: "FlightServicingBookingFailure";
  emptyState?: Maybe<FlightServicingBookingEmptyState>;
  encodedChallengeConfig?: Maybe<Scalars["String"]["output"]>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  onChallengeAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  placards?: Maybe<Array<FlightsUiPlacard>>;
};

export type FlightServicingBookingSuccess = {
  __typename?: "FlightServicingBookingSuccess";
  action: FlightsResourceRedirectAction;
};

export type FlightShoppingCardDurationTimeline = {
  __typename?: "FlightShoppingCardDurationTimeline";
  end: FlightShoppingDurationTimelineSideSection;
  /** @deprecated Use nullable field primaryMessage instead */
  primary: EgdsStylizedText;
  primaryMessage?: Maybe<EgdsStylizedText>;
  /** @deprecated Use nullable field secondaryMessage instead */
  secondary: EgdsTextSection;
  secondaryMessage?: Maybe<EgdsTextSection>;
  start: FlightShoppingDurationTimelineSideSection;
  stops: Scalars["Int"]["output"];
  theme: FlightShoppingCardDurationTimelineTheme;
};

export type FlightShoppingCardDurationTimelineTheme = "DEFAULT" | "MUTE" | "SUCCESS";

export type FlightShoppingDurationTimelineSideSection = {
  __typename?: "FlightShoppingDurationTimelineSideSection";
  differentDayArrival?: Maybe<Scalars["String"]["output"]>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<FlightsResponsiveMessage>;
  /** @deprecated Use nullable field secondary instead */
  secondaryMessage: FlightsResponsiveMessage;
};

export type FlightSortAndFilterAppliedFilter = ShoppingSortAndFilterAppliedFilter & {
  __typename?: "FlightSortAndFilterAppliedFilter";
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: UiRemovablePill;
};

export type FlightStepIndicatorChangeSelection = StepIndicatorChangeSelection & {
  __typename?: "FlightStepIndicatorChangeSelection";
  a11yLabel: Scalars["String"]["output"];
  action: UiLinkAction;
  journeyContinuationId: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  multiItemShoppingAction?: Maybe<FlightSearchMultiItemShoppingAction>;
};

export type FlightStepIndicatorLabel = StepIndicatorLabel & {
  __typename?: "FlightStepIndicatorLabel";
  destination: Scalars["String"]["output"];
  icon: Icon;
  origin: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type FlightSummary = {
  __typename?: "FlightSummary";
  carrier?: Maybe<Scalars["String"]["output"]>;
  flownDuration?: Maybe<Duration>;
  stops: Array<InsightsAirport>;
  tripDuration?: Maybe<Duration>;
};

export type FlightSummarySection = {
  __typename?: "FlightSummarySection";
  flightOriginDestinationExpanded?: Maybe<Scalars["String"]["output"]>;
  flightTripType?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
};

export interface FlightTripInfoDataInput {
  legNumber?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightWaiverTable = {
  __typename?: "FlightWaiverTable";
  bookingFeeCheckBox?: Maybe<EgdsCheckBox>;
  disclaimerText: Scalars["String"]["output"];
  resetTable: UiSecondaryButton;
  title: Scalars["String"]["output"];
  waiverTable: Scalars["String"]["output"];
};

export type FlightsAccessibilityMessage = {
  __typename?: "FlightsAccessibilityMessage";
  onCompletion?: Maybe<Scalars["String"]["output"]>;
  usage?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsAction = {
  __typename?: "FlightsAction";
  accessibilityActionMessage?: Maybe<FlightsAccessibilityMessage>;
  accessibilityCheckoutMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This will be replaced by accessibilityShowMoreMessage */
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  accessibilityUsageMessage?: Maybe<FlightsSimpleLocalizationTemplate>;
  /** @deprecated This will be replaced by analyticsList */
  analytics: FlightsAnalytics;
  /** @deprecated This will be replaced by clientSideAnalyticsList */
  analyticsList: Array<FlightsAnalytics>;
  cheapestListingInfo?: Maybe<FlightsCheapestListingInfo>;
  clientSideAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  displayAction: Scalars["String"]["output"];
  displayAnalytics?: Maybe<FlightsAnalytics>;
  displayType: FlightsActionDisplayType;
  enable?: Maybe<Scalars["Boolean"]["output"]>;
  externalURI?: Maybe<Uri>;
  /** @deprecated No longer in use, to be cleaned up,membersignin dialog will be removing from flight action and will be adding in to flight loaded price summary */
  flightMemberSignInAction?: Maybe<MemberSignInDialog>;
  flightsDetailCriteria?: Maybe<FlightsDetailCriteria>;
  icon?: Maybe<Icon>;
  journeySearchCriteria?: Maybe<FlightsJourneySearchCriteria>;
  relativePackageableURI?: Maybe<HttpUri>;
  relativeURI?: Maybe<HttpUri>;
  searchPagination?: Maybe<Pagination>;
  shoppingContext?: Maybe<ShoppingContext>;
  stepIndicator?: Maybe<FlightsStepIndicator>;
  switchToggle?: Maybe<FlightsSwitchToggle>;
  type: FlightsActionType;
};

export type FlightsActionAndLabel = {
  __typename?: "FlightsActionAndLabel";
  label: Scalars["String"]["output"];
  selectAction: FlightsAction;
};

export type FlightsActionButton = EgdsButton &
  EgdsElement & {
    __typename?: "FlightsActionButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
     *         or a union type with a manageable number of members
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    clientAction: FlightsClientAction;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    iconPosition?: Maybe<FlightsIconPosition>;
    primary?: Maybe<Scalars["String"]["output"]>;
    style: EgdsButtonStyle;
    width?: Maybe<FlightsButtonWidth>;
  };

export type FlightsActionCheckbox = EgdsCheckBox &
  EgdsElement & {
    __typename?: "FlightsActionCheckbox";
    checkedAccessibility?: Maybe<Scalars["String"]["output"]>;
    checkedAnalytics?: Maybe<ClientSideAnalytics>;
    clientAction: FlightsClientAction;
    description?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    enabled: Scalars["Boolean"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    label: EgdsText;
    name?: Maybe<Scalars["String"]["output"]>;
    required: Scalars["Boolean"]["output"];
    state: EgdsCheckBoxState;
    uncheckedAccessibility?: Maybe<Scalars["String"]["output"]>;
    uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type FlightsActionDisplayType =
  | "CHECKBOX"
  | "PRIMARY"
  | "RADIO_BUTTON"
  | "SECONDARY"
  | "SWITCH"
  | "TERTIARY"
  | "TOGGLE";

export type FlightsActionLink = EgdsElement & {
  __typename?: "FlightsActionLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: FlightsClientAction;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsActionType =
  | "ADD_PRICE_MATCH_PROMISE"
  | "ALL_SEATS_SELECTED_FOR_A_LEG"
  | "APPLY_FARE"
  | "CHOOSE_FARE"
  | "CLICKING_NEXT_FLIGHT_BEFORE_FINISHING_CURRENT_FLIGHT_SEAT_SELECTION_DIALOG"
  | "CONTINUE_BOOKING_WITHOUT_SELECTING_SEATS_DIALOG"
  | "COUPON_CREDIT"
  | "DISMISS"
  | "ENABLE_SUGGESTED_FILTER"
  | "FETCH_SEARCH_LISTINGS"
  | "GO_TO_AUTH"
  | "GO_TO_CHECKOUT"
  | "GO_TO_CONFIRMATION"
  | "GO_TO_DETAILS"
  | "GO_TO_MERCH_HUB"
  | "GO_TO_NEXT_JOURNEY"
  | "GO_TO_PACKAGES"
  | "GO_TO_SEARCH"
  | "GO_TO_STOREFRONT"
  | "GO_TO_TRIPS"
  | "LOAD_CROSS_SELL_DIALOG"
  | "LOAD_STANDARD_OFFER_FARE_RULE_MODULE"
  | "LOAD_TAX_BREAKDOWN_DIALOG"
  | "MEMBER_SIGNIN"
  | "NO_SEATS_SELECTED_FOR_A_LEG"
  | "OPEN_BAGS_SELECTION_ALERT_DIALOG_ON_CHECKOUT"
  | "OPEN_BAGS_SELECTION_SHEET"
  | "OPEN_BOOK_DIRECTLY_WITH_AIRLINES_DIALOG"
  | "OPEN_CREDIT_DIALOG"
  | "OPEN_FARE_SEAT_MAP_SEAT_SHEET"
  | "OPEN_FARE_SEAT_MAP_VIEW"
  | "OPEN_FARE_SEAT_SELECT_VIEW"
  | "OPEN_FLIGHT_LEG_DROP_DOWN_IN_SEAT_MAP_DIALOG"
  | "OPEN_LEGEND_INFO_SHEET_MOBILE"
  | "OPEN_PRICE_SUMMARY"
  | "OPEN_SEAT_SELECTION_ALERT_DIALOG_ON_CHECKOUT"
  | "REDIRECT_TO_AIRLINES_FOR_BOOKING"
  | "REFRESH"
  | "SEARCH_FORM_ADD_JOURNEY"
  | "SEARCH_FORM_CLOSE_BUTTON"
  | "SEARCH_FORM_REMOVE_JOURNEY"
  | "SEAT_MAP_CLOSE_WITHOUT_SELECTING_SEATS_DIALOG"
  | "SEAT_MAP_CONTINUE_BOOKING"
  | "SEAT_MAP_GO_TO_NEXT_FLIGHT"
  | "SEAT_MAP_NEXT_FLIGHT"
  | "SEAT_MAP_SAVE_AND_EXIT"
  | "SEAT_MAP_STAY_HERE"
  | "SEAT_MAP_STAY_ON_THIS_PAGE"
  | "SEAT_SELECT_DIALOG_CONTINUE_BOOKING"
  | "SEAT_SELECT_PREVIEW_FOR_TRAVELER"
  | "SELECT_FLIGHT_LEG_IN_SEAT_MAP_DIALOG"
  | "SELECT_SEAT"
  | "SHARE_FEEDBACK"
  | "SHOW_FREE_PRICE_MATCH_PROMISE_DETAILS"
  | "SHOW_PRICE_MATCH_PROMISE_DETAILS"
  | "SOME_SEATS_SELECTED_FOR_A_LEG"
  | "SUBSCRIBE_TO_EMAILS"
  | "TOGGLE_PRICE_ALERTS"
  | "UNSELECT_SEAT"
  | "UPGRADE_TO_NEXT_FARE";

export type FlightsActionableErrorMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsActionableErrorMessaging";
  action: FlightsAction;
  errorRecoveryButtons?: Maybe<Array<FlightsAction>>;
  icon: Icon;
  message: Scalars["String"]["output"];
  subMessage?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FlightsActionableIcon = {
  __typename?: "FlightsActionableIcon";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  analytics: FlightsAnalytics;
  displayText?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  type: FlightsActionType;
};

export type FlightsActionableLabel = {
  __typename?: "FlightsActionableLabel";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  active: Scalars["Boolean"]["output"];
  analytics?: Maybe<FlightsAnalytics>;
  label: Scalars["String"]["output"];
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type FlightsAdaptExAnalytics = {
  __typename?: "FlightsAdaptExAnalytics";
  campaignId: Scalars["String"]["output"];
  events: Array<FlightsAdaptExAnalyticsEvent>;
};

export type FlightsAdaptExAnalyticsEvent = {
  __typename?: "FlightsAdaptExAnalyticsEvent";
  banditDisplayed: Scalars["Boolean"]["output"];
  eventProperties: Array<AdaptExEventProperty>;
  eventTarget: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  payloadId: Scalars["String"]["output"];
};

export type FlightsAdvancedFilterType = "NONSTOP" | "REFUNDABLE";

export interface FlightsAirAncillarySelectionDetailInput {
  travelerIndex: Scalars["Int"]["input"];
  unselectedAirAncillaryProductToken: Scalars["String"]["input"];
}

export type FlightsAirlineAmenityGroup = {
  __typename?: "FlightsAirlineAmenityGroup";
  amenities?: Maybe<Array<FlightsIconAndLabel>>;
  headerText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsAirlineCreditsResponse = {
  __typename?: "FlightsAirlineCreditsResponse";
  flightsCreditSelectionPresentation: FlightsCreditSelectionPresentation;
};

export type FlightsAirportCodeFilterValue = {
  __typename?: "FlightsAirportCodeFilterValue";
  airportCode: Scalars["String"]["output"];
};

export interface FlightsAirportCodeFilterValueInput {
  airportCode: Scalars["String"]["input"];
}

export type FlightsAnalytics = {
  __typename?: "FlightsAnalytics";
  linkName: Scalars["String"]["output"];
  referrerId: Scalars["String"]["output"];
};

export type FlightsAncillaryAccessibilityMessage = {
  __typename?: "FlightsAncillaryAccessibilityMessage";
  accessibilityMessage: Scalars["String"]["output"];
  id: FlightsAncillarySelectionAccessibilityMsgType;
};

export type FlightsAncillaryAlertDialog = {
  __typename?: "FlightsAncillaryAlertDialog";
  alertDialog: FlightsDialogStandard;
  id: FlightsActionType;
};

export type FlightsAncillaryAvailabilityDetail = {
  __typename?: "FlightsAncillaryAvailabilityDetail";
  selectedJourneyIndex: Scalars["Int"]["output"];
  /** @deprecated It supports only text, to support suffix `- added` use staticAncillariesInfo */
  staticAncillaries: Array<EgdsIconText>;
  staticAncillariesInfo: Array<EgdsSpannableText>;
};

export type FlightsAncillaryAvailableFlightLegSelection = {
  __typename?: "FlightsAncillaryAvailableFlightLegSelection";
  additionalInfo?: Maybe<EgdsText>;
  ancillariesPerTravelerList?: Maybe<Array<FlightAncillariesPerTraveler>>;
  ancillaryNotAvailableMessage?: Maybe<FlightsAncillaryMessage>;
  /** @deprecated New node required for checkbox with analytics, replace with applySameAncillaryCheckBox */
  applySameAncillary?: Maybe<EgdsBasicCheckBox>;
  applySameAncillaryCheckBox?: Maybe<ActionCheckBox>;
  availabilityDetails?: Maybe<FlightsAncillaryAvailabilityDetail>;
  selectedJourneyIndex: Scalars["Int"]["output"];
};

export type FlightsAncillaryCard = {
  __typename?: "FlightsAncillaryCard";
  action?: Maybe<FlightsDialogTrigger>;
  content?: Maybe<FlightsCategorizedList>;
  /** @deprecated Use: val expandToggle: EGDSExpandoPeek? = null */
  displayToggle: EgdsExpandoPeek;
  expandToggle?: Maybe<EgdsExpandoPeek>;
  heading: Scalars["String"]["output"];
  highlightMessage?: Maybe<EgdsText>;
  /** @deprecated Use: val content: FlightsCategorizedList, all static and selected items are combined */
  sections?: Maybe<Array<FlightJourneyAncillariesSection>>;
};

export type FlightsAncillaryContinueAction = FlightsClientAction & {
  __typename?: "FlightsAncillaryContinueAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export interface FlightsAncillaryCriteriaInput {
  ancillaryContent?: InputMaybe<AncillaryContentInput>;
  ancillaryId?: InputMaybe<Scalars["String"]["input"]>;
  ancillaryJourneySelections?: InputMaybe<Array<AncillaryJourneySelectionsInput>>;
  ancillaryShoppingPath?: InputMaybe<AncillaryShoppingPath>;
  ancillaryType: FlightsAncillaryType;
  flightAncillaryJourneySelections?: InputMaybe<Array<FlightAncillaryJourneySelectionInput>>;
  flightsDetailComponentsCriteria?: InputMaybe<FlightsDetailComponentsCriteriaInput>;
  journeyContinuationId: Scalars["String"]["input"];
  selectedOfferToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightsAncillaryDetails = {
  __typename?: "FlightsAncillaryDetails";
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
  flightsAncillaryAlertDialogList?: Maybe<Array<FlightsDialog>>;
  /** @deprecated New implementation on Shared-UI requires new type, replace with flightsAncillaryAlertDialogList */
  flightsAncillaryAlertDialogs?: Maybe<Array<FlightsAncillaryAlertDialog>>;
  footer: FlightsAncillaryFooter;
  journeyAncillaryDetails: Array<FlightsAncillaryAvailableFlightLegSelection>;
  scrollAnalytics?: Maybe<ClientSideAnalytics>;
  selectedJourneyIndex: Scalars["Int"]["output"];
};

export type FlightsAncillaryDetailsDialog = {
  __typename?: "FlightsAncillaryDetailsDialog";
  flightsContinueBookingDialog: FlightsDialog;
  /** @deprecated Use flightsDialogCompleteSelectionClose  and flightsDialogCompleteSelectionClose instead */
  flightsDialogClose: FlightsDialog;
  flightsDialogCompleteSelectionClose: FlightsDialog;
  flightsDialogIncompleteSelectionClose: FlightsDialog;
};

export type FlightsAncillaryDetailsLoading = {
  __typename?: "FlightsAncillaryDetailsLoading";
  analytics?: Maybe<ClientSideAnalytics>;
  flightsAncillaryDialog: FlightsAncillaryDetailsDialog;
  footer: FlightsAncillaryFooter;
  heading: EgdsHeading;
  journeySelectionDropDown?: Maybe<JourneySelectionDropDown>;
  selectedJourneyIndex: Scalars["Int"]["output"];
};

export type FlightsAncillaryDetailsPrefetch = {
  __typename?: "FlightsAncillaryDetailsPrefetch";
  availabilityDetails?: Maybe<Array<FlightsAncillaryAvailabilityDetail>>;
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
};

export type FlightsAncillaryDisplayAction = "NONE" | "PREFETCH";

export type FlightsAncillaryErrorMessage = {
  __typename?: "FlightsAncillaryErrorMessage";
  accessibilityMessage?: Maybe<FlightsResponsiveAccessibilityMessage>;
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<EgdsStylizedText>;
  id: FlightsAncillarySelectionErrorType;
  message: EgdsStylizedText;
};

export type FlightsAncillaryFooter = {
  __typename?: "FlightsAncillaryFooter";
  globalFooter: FlightsAncillaryGlobalFooter;
  journeyFooter: Array<FlightsAncillaryJourneyFooter>;
};

export type FlightsAncillaryGlobalFooter = {
  __typename?: "FlightsAncillaryGlobalFooter";
  label: EgdsText;
  selectedCount: Scalars["Int"]["output"];
  /** @deprecated use selectedMessageTemplates for future development */
  selectedMessage: EgdsLocalizedTextTemplate;
  selectedMessageTemplates: Array<SelectedAncillaryTemplate>;
  templateAnalytics?: Maybe<Array<TemplateAnalytics>>;
  totalPrice: EgdsLocalizedTextTemplate;
};

export type FlightsAncillaryInformation = {
  __typename?: "FlightsAncillaryInformation";
  additionalInfo?: Maybe<EgdsPlainText>;
  flightsAncillaryAvailabilityDetails: FlightsAncillaryAvailabilityDetail;
  heading?: Maybe<EgdsHeading>;
  selectedAncillarySummary?: Maybe<Array<FlightsAncillaryTravelerSummaryItem>>;
};

export type FlightsAncillaryJourneyFooter = {
  __typename?: "FlightsAncillaryJourneyFooter";
  buttonAction: FlightsActionButton;
  buttonActionTypes: Array<FlightsLegAncillarySummaryActionType>;
  selectedJourneyIndex: Scalars["Int"]["output"];
  templateAnalytics?: Maybe<Array<TemplateAnalytics>>;
};

export type FlightsAncillaryMessage = {
  __typename?: "FlightsAncillaryMessage";
  accessibilityMessage?: Maybe<FlightsResponsiveAccessibilityMessage>;
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  heading?: Maybe<EgdsHeading>;
  id: FlightsAncillarySelectionErrorType;
  message: EgdsText;
  theme: TextTheme;
};

export type FlightsAncillaryResponse = {
  __typename?: "FlightsAncillaryResponse";
  ancillaryDetail?: Maybe<FlightsAncillaryDetails>;
  ancillarySummary?: Maybe<FlightsAncillarySummaryPresentation>;
  ancillarySummaryCard?: Maybe<FlightsAncillarySummaryCardPresentation>;
  baggageDetails?: Maybe<FlightsBaggageAncillaryDetailsPresentation>;
  baggageSelection?: Maybe<FlightsBaggageAncillaryDetailsPresentation>;
  seatAncillaryDetails?: Maybe<FlightsSeatAncillaryDetailsPresentation>;
  seatCellDetails?: Maybe<FlightsSeatCellDetailsPresentation>;
  seatSelection?: Maybe<FlightsSeatAncillaryDetailsPresentation>;
};

export type FlightsAncillaryResponseAncillarySummaryCardArgs = {
  subQueryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsAncillaryResponseBaggageDetailsArgs = {
  subQueryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsAncillaryResponseSeatAncillaryDetailsArgs = {
  subQueryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsAncillaryResponseSeatCellDetailsArgs = {
  subQueryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsAncillaryResponseSeatSelectionArgs = {
  subQueryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsAncillarySelect = {
  __typename?: "FlightsAncillarySelect";
  optionLabels: Array<Scalars["String"]["output"]>;
  selectOptions: EgdsSelect;
};

export type FlightsAncillarySelectedInformationStackedFooter = {
  __typename?: "FlightsAncillarySelectedInformationStackedFooter";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  selectedAncillaryCount?: Maybe<EgdsSpannableText>;
  totalPrice?: Maybe<FlightsPriceInfoSummary>;
};

export type FlightsAncillarySelectedItem = {
  __typename?: "FlightsAncillarySelectedItem";
  assignedAncillaryLabel: EgdsPlainText;
  price: EgdsPlainText;
};

export type FlightsAncillarySelectionAccessibilityMsgType = "SELECTION_FAILED" | "SELECTION_SUCCESS";

export type FlightsAncillarySelectionErrorType =
  | "BAGGAGE_DETAILS_LOADED_FAILED"
  | "DETAILS_LOADING_FAILED"
  | "PRE_SELECTION_FAILED"
  | "SELECTION_FAILED"
  | "SELECTION_UNAVAILABLE";

export interface FlightsAncillaryStackedFooter {
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  selectedAncillaryCount?: Maybe<EgdsSpannableText>;
  totalPrice?: Maybe<FlightsPriceInfoSummary>;
}

export type FlightsAncillarySummary = FlightsAncillarySummaryPresentation & {
  __typename?: "FlightsAncillarySummary";
  accessibilityMessages: Array<FlightsAncillaryAccessibilityMessage>;
  airOfferToken?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  ancillariesPerJourney?: Maybe<Array<FlightsAncillaryInformation>>;
  ancillaryDetailsLoading: FlightsAncillaryDetailsLoading;
  displayToggle: EgdsExpando;
  editAncillariesAction?: Maybe<FlightsActionLink>;
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
  flightAncillaryJourneySelections?: Maybe<Array<FlightAncillaryJourneySelection>>;
  flightsAncillaryDetailsPrefetch: FlightsAncillaryDetailsPrefetch;
  heading: EgdsHeading;
  highlightMessage?: Maybe<EgdsHeading>;
  loadAncillariesAction?: Maybe<FlightsActionButton>;
  scrollAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsAncillarySummaryAnalytics = {
  __typename?: "FlightsAncillarySummaryAnalytics";
  analyticsList: Array<ClientSideAnalytics>;
  scrollAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsAncillarySummaryCard = {
  __typename?: "FlightsAncillarySummaryCard";
  accessibilityMessages: Array<FlightsAncillaryAccessibilityMessage>;
  additionalShoppedAncillaries?: Maybe<Array<AdditionalShoppedAncillaries>>;
  analytics: FlightsAncillarySummaryAnalytics;
  ancillaryCard: FlightsAncillaryCard;
  errorMessages?: Maybe<Array<FlightsAncillaryMessage>>;
  onDisplayAction: FlightsAncillaryDisplayAction;
};

export type FlightsAncillarySummaryCardLoading = {
  __typename?: "FlightsAncillarySummaryCardLoading";
  errorMessage: EgdsStylizedText;
  heading: Scalars["String"]["output"];
};

export type FlightsAncillarySummaryCardPresentation = FlightsAncillarySummaryCard | FlightsAncillarySummaryCardLoading;

export type FlightsAncillarySummaryLoading = FlightsAncillarySummaryPresentation & {
  __typename?: "FlightsAncillarySummaryLoading";
  heading: EgdsHeading;
};

export interface FlightsAncillarySummaryPresentation {
  heading: EgdsHeading;
}

export type FlightsAncillaryTravelerSummaryItem = {
  __typename?: "FlightsAncillaryTravelerSummaryItem";
  ancillaryItemDetails: Array<FlightsAncillarySelectedItem>;
  label: EgdsStylizedText;
  travelerIndex: Scalars["Int"]["output"];
};

export type FlightsAncillaryType = "BAGS" | "BAGS_V2" | "SEATS";

export type FlightsAncillaryUpdateResponse = {
  __typename?: "FlightsAncillaryUpdateResponse";
  /** @deprecated This is not needed as it will be part of price summary */
  ancillaryFooter?: Maybe<FlightsAncillaryStackedFooter>;
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<FlightsErrorMessagingPresentation>;
  selectionToastMessage?: Maybe<EgdsToast>;
};

export type FlightsAppliedFilter = {
  __typename?: "FlightsAppliedFilter";
  accessibilityMessage: Scalars["String"]["output"];
  analytics: FlightsAnalytics;
  dismissIcon: Icon;
  dismissIconAccessibility?: Maybe<Scalars["String"]["output"]>;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  nextSearchSortFilterCriteria?: Maybe<FlightsSearchSortAndFilterCriteria>;
};

export type FlightsAppliedFiltersSummary = {
  __typename?: "FlightsAppliedFiltersSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  appliedFilters?: Maybe<Array<FlightsAppliedFilter>>;
};

export type FlightsArithmeticResponse = {
  __typename?: "FlightsArithmeticResponse";
  formattedResult: EgdsText;
};

export type FlightsAttributeTogglesPresentation = {
  __typename?: "FlightsAttributeTogglesPresentation";
  badgeMessage?: Maybe<FlightsBadgeMessage>;
  tileField: ShoppingMultiSelectionTileField;
};

export type FlightsAuthenticatedUser = {
  __typename?: "FlightsAuthenticatedUser";
  accessibility: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export interface FlightsAvailableFares {
  applySelection?: Maybe<FlightsAction>;
  dismissDetailedJourneyInformation: FlightsActionableIcon;
  fareChoiceInformation?: Maybe<FlightsFareChoiceInformation>;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  openAccessibility: Scalars["String"]["output"];
}

export type FlightsBadge = {
  __typename?: "FlightsBadge";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
  type: FlightsBadgeTheme;
};

export type FlightsBadgeMessage = {
  __typename?: "FlightsBadgeMessage";
  badge: EgdsStandardBadge;
  message: EgdsStylizedText;
  subtext?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsBadgePlacard = FlightsPlacard & {
  __typename?: "FlightsBadgePlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  badge: EgdsStandardBadge;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]["output"]>;
  headingWithMoreInfo?: Maybe<FlightsMoreInfoLink>;
  message: Scalars["String"]["output"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated Use onViewedAnalyticsList instead */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsBadgeTheme =
  | "BRAND"
  | "DEAL_BUNDLED"
  | "DEAL_GENERIC"
  | "DEAL_MEMBER"
  | "FAMILY_FRIENDLY"
  | "NOTIFICATION"
  | "SAVED"
  | "SPONSORED"
  | "SUPPLIER_PROMO"
  | "VIEWED"
  | "VIP";

export type FlightsBagSelectionToastMessages = {
  __typename?: "FlightsBagSelectionToastMessages";
  bagSelectionErrorMessage: EgdsBasicLocalizedText;
  bagSelectionMessage: EgdsBasicLocalizedText;
  bagUnselectMessage: EgdsBasicLocalizedText;
  bagsSelectionMessage: EgdsBasicLocalizedText;
};

export type FlightsBaggageAncillariesPerTraveler = {
  __typename?: "FlightsBaggageAncillariesPerTraveler";
  ancillaryOptions?: Maybe<Array<FlightSelectableBaggageAncillary>>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  travelerIndex: Scalars["Int"]["output"];
  travellerIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsBaggageAncillaryDetailsContent = {
  __typename?: "FlightsBaggageAncillaryDetailsContent";
  flightsAncillaryDialog?: Maybe<FlightsAncillaryDetailsDialog>;
  journeyAncillaryDetails: Array<FlightsJourneyBaggageSelectionPresentation>;
  journeySelect?: Maybe<FlightsAncillarySelect>;
};

export type FlightsBaggageAncillaryDetailsLoaded = {
  __typename?: "FlightsBaggageAncillaryDetailsLoaded";
  baggageDetailsWrapper: FlightsDialogSheet;
  content: FlightsBaggageAncillaryDetailsContent;
  footer: FlightsBaggageAncillaryFooter;
  offerIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsBaggageAncillaryDetailsLoading = {
  __typename?: "FlightsBaggageAncillaryDetailsLoading";
  baggageDetailsWrapper: FlightsDialogSheet;
  errorMessage: FlightsAncillaryErrorMessage;
};

export type FlightsBaggageAncillaryDetailsPresentation =
  | FlightsBaggageAncillaryDetailsLoaded
  | FlightsBaggageAncillaryDetailsLoading
  | FlightsBaggageAncillaryDetailsSelectionChange
  | FlightsBaggageDetailsLoaded
  | FlightsBaggageDetailsLoading;

export type FlightsBaggageAncillaryDetailsSelectionChange = {
  __typename?: "FlightsBaggageAncillaryDetailsSelectionChange";
  footer: FlightsBaggageAncillaryFooter;
};

export type FlightsBaggageAncillaryFooter = {
  __typename?: "FlightsBaggageAncillaryFooter";
  globalFooter: FlightsBaggageAncillaryGlobalFooter;
  journeyFooter: Array<FlightsBaggageAncillaryJourneyFooter>;
};

export type FlightsBaggageAncillaryGlobalFooter = {
  __typename?: "FlightsBaggageAncillaryGlobalFooter";
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  label: Scalars["String"]["output"];
  selectedMessage: Scalars["String"]["output"];
  totalPrice: Scalars["String"]["output"];
};

export type FlightsBaggageAncillaryJourneyFooter = {
  __typename?: "FlightsBaggageAncillaryJourneyFooter";
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  buttonAction: FlightsActionButton;
  selectedJourneyIndex: Scalars["Int"]["output"];
};

export type FlightsBaggageAvailableFlightLegSelection = {
  __typename?: "FlightsBaggageAvailableFlightLegSelection";
  additionalInfo?: Maybe<Scalars["String"]["output"]>;
  ancillariesPerTravelerList?: Maybe<Array<FlightsBaggageAncillariesPerTraveler>>;
  ancillaryNotAvailableMessage?: Maybe<FlightsAncillaryMessage>;
  applySameAncillaryCheckBox?: Maybe<FlightsActionCheckbox>;
  staticAncillaries?: Maybe<FlightsCategorizedList>;
  weightConceptMessage?: Maybe<EgdsSpannableText>;
};

export type FlightsBaggageAvailableJourneySelection = {
  __typename?: "FlightsBaggageAvailableJourneySelection";
  ancillariesPerTravelerList?: Maybe<Array<FlightsBaggagePerTraveler>>;
  applySameAncillaryToggleSwitch?: Maybe<EgdsStandardSwitch>;
};

export type FlightsBaggageCheckbox = {
  __typename?: "FlightsBaggageCheckbox";
  checkbox: EgdsCheckBox;
  label: EgdsSpannableText;
  subLabel: EgdsSpannableText;
};

export type FlightsBaggageDetailsContent = {
  __typename?: "FlightsBaggageDetailsContent";
  journeyAncillaryDetails: Array<FlightsJourneyBaggageSelectionPresentation>;
  journeyPills?: Maybe<Array<EgdsPill>>;
};

export type FlightsBaggageDetailsInformationError = {
  __typename?: "FlightsBaggageDetailsInformationError";
  errorIdentifier?: Maybe<FlightsStepperErrorMessagesKey>;
  errorMessage: FlightsErrorMessagingPresentation;
};

export type FlightsBaggageDetailsInformationSuccess = {
  __typename?: "FlightsBaggageDetailsInformationSuccess";
  bagSelectionToastMessages: FlightsBagSelectionToastMessages;
  content: FlightsBaggageDetailsContent;
  offerIdentifier: Scalars["String"]["output"];
};

export type FlightsBaggageDetailsLoaded = {
  __typename?: "FlightsBaggageDetailsLoaded";
  baggageInformation: FlightsBaggageDetailsLoadedContent;
};

export type FlightsBaggageDetailsLoadedContent =
  | FlightsBaggageDetailsInformationError
  | FlightsBaggageDetailsInformationSuccess;

export type FlightsBaggageDetailsLoading = {
  __typename?: "FlightsBaggageDetailsLoading";
  errorMessage: FlightsErrorMessagingPresentation;
};

export type FlightsBaggageInformation = {
  __typename?: "FlightsBaggageInformation";
  bagFeesMoreInfo: Array<FlightsPhrase>;
  defaultBagSelectionMessage?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<Array<FlightBaggageInformationDetail>>;
  dismissAction?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Will start sending all display analytics for baggage in displayAnalyticsItems node */
  displayAnalytics?: Maybe<FlightsAnalytics>;
  displayAnalyticsItems?: Maybe<Array<FlightsAnalytics>>;
  displayDetailsAction?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
};

export type FlightsBaggagePerTraveler = {
  __typename?: "FlightsBaggagePerTraveler";
  additionalInfo?: Maybe<EgdsSpannableText>;
  ancillaryOptions?: Maybe<Array<FlightSelectableBaggageCard>>;
  bagSelectionNotAvailableMessage?: Maybe<EgdsCard>;
  nextFlightCTA?: Maybe<FlightsExperienceActionButton>;
  sizeAndWeightLimitBottomSheet?: Maybe<FlightsExpandableBottomSheet>;
  sizeAndWeightLimitBottomSheetTrigger?: Maybe<EgdsStandardLink>;
  travelerIndex: Scalars["Int"]["output"];
  travellerIdentifier?: Maybe<EgdsSpannableText>;
};

export type FlightsBaggageRadioButton = {
  __typename?: "FlightsBaggageRadioButton";
  label: EgdsSpannableText;
  radioButton: EgdsRadioButton;
  subLabel: EgdsSpannableText;
  weightLabel: EgdsSpannableText;
};

export type FlightsBaggageRadioButtonGroup = EgdsRadioGroup & {
  __typename?: "FlightsBaggageRadioButtonGroup";
  baggageRadioButtons: Array<FlightsBaggageRadioButton>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<EgdsRadioButton>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsBannerError = FlightsOnLoadError & {
  __typename?: "FlightsBannerError";
  accessibility: Scalars["String"]["output"];
  action: FlightsActionLink;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type FlightsBannerPlacard = FlightsUiPlacard & {
  __typename?: "FlightsBannerPlacard";
  banner: UiBanner;
  displayType: FlightsPlacardDisplayType;
  /** @deprecated Use onViewedAnalyticsList for future use. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsBargainFareOffer = FlightsOffer & {
  __typename?: "FlightsBargainFareOffer";
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  accessibilityMessage: Scalars["String"]["output"];
  bargainPricingInformation?: Maybe<FlightsPricingInformation>;
  description: FlightsPhrase;
  flightsOfferAnalytics?: Maybe<FlightsOfferAnalytics>;
  graphic: UiGraphic;
  header: FlightsPhrase;
  headerImage?: Maybe<Image>;
  /** @deprecated Replaced with graphic to use UIGraphic */
  image: Image;
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  journeys: Array<FlightsOfferJourney>;
  onClickAnalyticsList: Array<FlightsAnalytics>;
  sanitizedOfferToken?: Maybe<Scalars["String"]["output"]>;
  selectOfferAction: FlightsAction;
  stepIndicatorJcid?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsBargainFareSelectedJourneyDetails = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsBargainFareSelectedJourneyDetails";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  responseMetric?: Maybe<FlightsResponseTimeMetrics>;
};

export type FlightsBargainJourneySummary = FlightsJourneySummary & {
  __typename?: "FlightsBargainJourneySummary";
  arrivalDayInformation: Scalars["String"]["output"];
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  formattedPrice?: Maybe<FlightsPhrase>;
  heading: FlightsMessageAndAccessibility;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
  totalPrice?: Maybe<Scalars["String"]["output"]>;
  tripTypePerTraveler?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsBooking = {
  __typename?: "FlightsBooking";
  action?: Maybe<FlightsActionButton>;
  links: EgdsLinkList;
  onViewedAnalytics: ClientSideAnalytics;
  /** @deprecated This will be replaced with action */
  primaryAction: FlightsBookingAction;
  /** @deprecated This will be replaced with action */
  primaryButton: UiPrimaryButton;
  /** @deprecated This will be replaced with primaryText */
  primaryCopy: Scalars["String"]["output"];
  primaryText?: Maybe<EgdsPlainText>;
  secondaryCopy: EgdsIconText;
};

export type FlightsBookingAction = FlightsClientAction & {
  __typename?: "FlightsBookingAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsBoundNavigation = FlightsClientAction & {
  __typename?: "FlightsBoundNavigation";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  jcid: Scalars["String"]["output"];
  mipt?: Maybe<Scalars["String"]["output"]>;
  pageId: Scalars["String"]["output"];
  stepIndicatorJcid: Scalars["String"]["output"];
};

export type FlightsButtonWidth = "DEFAULT" | "FULL_WIDTH";

export type FlightsCabinClass = "BUSINESS" | "COACH" | "FIRST" | "PREMIUM_ECONOMY";

export type FlightsCancellationType = {
  __typename?: "FlightsCancellationType";
  cancellationTypes: EgdsBasicRadioGroup;
  title: Scalars["String"]["output"];
};

export type FlightsCardBackgroundTheme = "HIGHLIGHT" | "PRIMARY" | "SECONDARY";

export type FlightsCardsDisplayFilterItem = FlightsFilterItem & {
  __typename?: "FlightsCardsDisplayFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  card: Icon;
  defaultSelected: Scalars["Boolean"]["output"];
  deselectAnalytics: FlightsAnalytics;
  details?: Maybe<Scalars["String"]["output"]>;
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selectAnalytics: FlightsAnalytics;
  selected: Scalars["Boolean"]["output"];
  subLabel: Scalars["String"]["output"];
  value: FlightsFilterValue;
};

export type FlightsCardsDisplayFilterOption = FlightsFilterOption & {
  __typename?: "FlightsCardsDisplayFilterOption";
  filterAppliedAnalytics?: Maybe<FlightsAnalytics>;
  items: Array<FlightsCardsDisplayFilterItem>;
  label: Scalars["String"]["output"];
  supportsMultipleSelection: Scalars["Boolean"]["output"];
  type: FlightsFilterType;
};

export type FlightsCarryOnBagBasedFilterValue = {
  __typename?: "FlightsCarryOnBagBasedFilterValue";
  carryOnBag: CarryOnBagType;
  displayToast?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface FlightsCarryOnBagBasedFilterValueInput {
  carryOnBag: CarryOnBagType;
  displayToast?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type FlightsCarryOverFiltersCard = {
  __typename?: "FlightsCarryOverFiltersCard";
  /** @deprecated Replaced with field that allows links or buttons, replace with actionElement */
  actionButton: FlightsActionButton;
  actionElement: FlightsClientActionElement;
  /** @deprecated Replaced with a lightweight solution, replace with message */
  content: EgdsStandardMessagingCard;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type FlightsCategorizedList = {
  __typename?: "FlightsCategorizedList";
  expandableSection?: Maybe<FlightsExpandableCategorizedListSection>;
  sections: Array<FlightsCategorizedListSection>;
};

export type FlightsCategorizedListItem = {
  __typename?: "FlightsCategorizedListItem";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  primary: EgdsSpannableText;
  /** @deprecated secondary replaces with secondaryContent as it supports EGDSSpannableText */
  secondary?: Maybe<Scalars["String"]["output"]>;
  secondaryContent?: Maybe<EgdsSpannableText>;
  tertiary?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsCategorizedListSection = {
  __typename?: "FlightsCategorizedListSection";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  additionalInfo?: Maybe<Array<Scalars["String"]["output"]>>;
  category?: Maybe<FlightsCategorizedListSectionType>;
  footnote?: Maybe<Array<Scalars["String"]["output"]>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  items: Array<FlightsCategorizedListItem>;
};

export type FlightsCategorizedListSectionType = "BAG" | "SEAT";

export type FlightsChangeAndCancellationFees = {
  __typename?: "FlightsChangeAndCancellationFees";
  changeAndCancellationDetails: Array<FlightsLabelAndValue>;
  displayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  heading: Scalars["String"]["output"];
};

export type FlightsCheapestListingInfo = {
  __typename?: "FlightsCheapestListingInfo";
  /** @deprecated We now need 2 nodes to represent 2 different types of analytics */
  analytics: FlightsAnalytics;
  deviationAnalytics?: Maybe<FlightsAnalytics>;
  nffAnalytics: FlightsAnalytics;
  price: Scalars["String"]["output"];
};

export type FlightsCheckedBagBasedFilterValue = {
  __typename?: "FlightsCheckedBagBasedFilterValue";
  displayToast?: Maybe<Scalars["Boolean"]["output"]>;
  numOfCheckedBags: Scalars["Int"]["output"];
};

export interface FlightsCheckedBagBasedFilterValueInput {
  displayToast?: InputMaybe<Scalars["Boolean"]["input"]>;
  numOfCheckedBags: Scalars["Int"]["input"];
}

export type FlightsClickAction = FlightsClientAction & {
  __typename?: "FlightsClickAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsClickStreamAnalytics = {
  __typename?: "FlightsClickStreamAnalytics";
  event: EgClickstreamEvent;
  payload: Scalars["AnalyticsPayload"]["output"];
};

export interface FlightsClientAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
}

export type FlightsClientActionElement = FlightsActionButton | FlightsActionLink;

export type FlightsClientMetadata = FlightsPwaMetadata;

export type FlightsConfirmation = {
  __typename?: "FlightsConfirmation";
  action?: Maybe<FlightsActionButton>;
  itinerary: EgdsStylizedText;
  label: EgdsPlainText;
  list: EgdsUnorderedList;
  /** @deprecated This will be replaced with action */
  primaryAction: FlightsBookingAction;
  /** @deprecated This will be replaced with action */
  primaryButton: UiPrimaryButton;
};

export type FlightsConnection = {
  __typename?: "FlightsConnection";
  aircraftModel?: Maybe<Scalars["String"]["output"]>;
  airlineDetails?: Maybe<FlightsMarkPhrases>;
  airlineInfo: Scalars["String"]["output"];
  badge: Array<JourneyDetailsBadge>;
  cabinClassAndBookingCode: Scalars["String"]["output"];
  connectionArrival: CoordinateInformation;
  connectionDeparture: CoordinateInformation;
  detailList: Array<FlightsLabelAndValue>;
  duration: Scalars["String"]["output"];
  durationAccessibilityMessage: Scalars["String"]["output"];
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  operatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsConnectionInformation = {
  __typename?: "FlightsConnectionInformation";
  flightsConnection: FlightsConnection;
  governmentApprovalMessage?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsCouponCredit = {
  __typename?: "FlightsCouponCredit";
  couponCreditAction: FlightsAction;
  imageListingLabel: FlightsImageListingLabel;
};

export type FlightsCreditClearAction = FlightsClientAction & {
  __typename?: "FlightsCreditClearAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsCreditEligibleFilterValue = {
  __typename?: "FlightsCreditEligibleFilterValue";
  creditEligible: CreditEligibleFilterType;
};

export interface FlightsCreditEligibleFilterValueInput {
  creditEligible: CreditEligibleFilterType;
}

export type FlightsCreditRedemptionHeadsUpContent =
  | FlightsCreditRedemptionHeadsUpError
  | FlightsCreditRedemptionHeadsUpSuccess;

export type FlightsCreditRedemptionHeadsUpContentResponse = {
  __typename?: "FlightsCreditRedemptionHeadsUpContentResponse";
  flightsCreditRedemptionHeadsUpContent: FlightsCreditRedemptionHeadsUpContent;
};

export type FlightsCreditRedemptionHeadsUpError = {
  __typename?: "FlightsCreditRedemptionHeadsUpError";
  error: Scalars["String"]["output"];
};

export type FlightsCreditRedemptionHeadsUpFooter = {
  __typename?: "FlightsCreditRedemptionHeadsUpFooter";
  button: UiPrimaryButton;
  text: Scalars["String"]["output"];
};

export interface FlightsCreditRedemptionHeadsUpInput {
  airlineName: Scalars["String"]["input"];
}

export type FlightsCreditRedemptionHeadsUpSuccess = {
  __typename?: "FlightsCreditRedemptionHeadsUpSuccess";
  contents: Array<EgdsBasicSectionHeading>;
  footer?: Maybe<FlightsCreditRedemptionHeadsUpFooter>;
  heading: Scalars["String"]["output"];
};

export type FlightsCreditResidualMessaging = {
  __typename?: "FlightsCreditResidualMessaging";
  analytics: Array<ClientSideAnalytics>;
  heading: EgdsHeading;
  messages: Array<EgdsElement>;
};

export type FlightsCreditSelectionAction = FlightsClientAction & {
  __typename?: "FlightsCreditSelectionAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  originalBookingId: Scalars["String"]["output"];
};

export type FlightsCreditSelectionElement = FlightsElementsWrapper | FlightsOfferCard;

export type FlightsCreditSelectionPresentation = {
  __typename?: "FlightsCreditSelectionPresentation";
  /** @deprecated Deprecated to support loading state, replace with airlineCreditsPresentation */
  airlineCreditsContent: AirlineCreditsPresentation;
  airlineCreditsPresentation: AirlineCreditsContentPresentation;
  airlineCreditsWrapper: FlightsDialogSheet;
  dialogs?: Maybe<Array<FlightsDialog>>;
  trigger: FlightsDialogTrigger;
};

export type FlightsCreditSelectionPresentationSection = {
  __typename?: "FlightsCreditSelectionPresentationSection";
  elements: Array<FlightsCreditSelectionElement>;
};

export type FlightsCreditSelectorOpenAction = FlightsClientAction & {
  __typename?: "FlightsCreditSelectorOpenAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsCreditSelectorOpenUiAction = UiAction & {
  __typename?: "FlightsCreditSelectorOpenUIAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type FlightsCreditsAvailableListing = {
  __typename?: "FlightsCreditsAvailableListing";
  immediateDialogAction?: Maybe<FlightsDialogTriggerUiAction>;
  messagingCardPlacard: FlightsMessagingCardPlacard;
  standardMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated This field is now deprecated., replace with toastNotification */
  toast: EgdsToast;
  toastNotification?: Maybe<EgdsToast>;
};

export type FlightsCriticalErrorMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsCriticalErrorMessaging";
  icon: Icon;
  message: Scalars["String"]["output"];
  primaryAction: FlightsExperienceActionButton;
  secondaryAction?: Maybe<FlightsExperienceActionButton>;
  title: Scalars["String"]["output"];
};

export type FlightsCrossSellMessage = {
  __typename?: "FlightsCrossSellMessage";
  /** @deprecated We don't need FlightsActionableLabel as this will be replaced with a shared-ui component */
  crossSellLabel: FlightsActionableLabel;
  /** @deprecated We don't need FlightsPhrase as this will be replaced with a shared-ui component */
  message: FlightsPhrase;
  multiItemFlightCrossSellPrimer: MultiItemFlightCrossSellPrimer;
};

export type FlightsCrossSellOffer = {
  __typename?: "FlightsCrossSellOffer";
  /** @deprecated Not in usage */
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Not in usage */
  accessibilityMessage: Scalars["String"]["output"];
  /** @deprecated Not in usage */
  displayDetailsAction: Scalars["String"]["output"];
  /** @deprecated Not in usage */
  displayDetailsActionCollapseAnalytics: FlightsAnalytics;
  /** @deprecated Not in usage */
  displayDetailsActionExpandAnalytics: FlightsAnalytics;
  /** @deprecated Not in usage */
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Not in usage */
  journeys: Array<FlightsOfferJourney>;
  multiItemFlightCrossSellOfferPrimer?: Maybe<MultiItemFlightCrossSellOfferPrimer>;
  /** @deprecated Not in usage */
  onClickAnalyticsList: Array<FlightsAnalytics>;
  /** @deprecated Not in usage */
  savings: FlightsSearchCrossSellOfferSavings;
  /** @deprecated Not in usage */
  selectOfferAction: FlightsAction;
};

export type FlightsCrossSortItem = {
  __typename?: "FlightsCrossSortItem";
  analytics?: Maybe<FlightsAnalytics>;
  defaultWeight: Scalars["Int"]["output"];
  label: FlightsIconAndLabel;
  type: FlightsCrossSortType;
};

export type FlightsCrossSortOption = {
  __typename?: "FlightsCrossSortOption";
  type: FlightsCrossSortType;
  weight: Scalars["Int"]["output"];
};

export interface FlightsCrossSortOptionInput {
  type: FlightsCrossSortType;
  weight: Scalars["Int"]["input"];
}

export type FlightsCrossSortOptionType = {
  __typename?: "FlightsCrossSortOptionType";
  crossSortOption: Array<FlightsCrossSortOption>;
};

export interface FlightsCrossSortOptionTypeInput {
  crossSortOption: Array<FlightsCrossSortOptionInput>;
}

export interface FlightsCrossSortPresentation {
  ceiling?: Maybe<Scalars["Int"]["output"]>;
  crossSortItems: Array<FlightsCrossSortItem>;
  floor?: Maybe<Scalars["Int"]["output"]>;
  heading: Scalars["String"]["output"];
  step?: Maybe<Scalars["Int"]["output"]>;
  subHeading: Scalars["String"]["output"];
}

export type FlightsCrossSortPresentationResult = FlightsCrossSortPresentation & {
  __typename?: "FlightsCrossSortPresentationResult";
  ceiling?: Maybe<Scalars["Int"]["output"]>;
  crossSortItems: Array<FlightsCrossSortItem>;
  floor?: Maybe<Scalars["Int"]["output"]>;
  heading: Scalars["String"]["output"];
  step?: Maybe<Scalars["Int"]["output"]>;
  subHeading: Scalars["String"]["output"];
};

export type FlightsCrossSortType =
  | "CABIN_CLASS"
  | "CANCELLATION"
  | "CARRY_ON_BAG"
  | "CHANGE_ALLOWED"
  | "CHECKED_BAG"
  | "DURATION"
  | "NUM_OF_STOPS"
  | "PRICE"
  | "SEAT_CHOICE";

export type FlightsDatePickerCellContent = {
  __typename?: "FlightsDatePickerCellContent";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  content: FlightsFareCalendarPrice;
  date: ZonedDateTime;
  selectAnalytics: FlightsAnalytics;
};

export interface FlightsDetailAncillaryUpdateCriteriaInput {
  airAncillaryDetails: Array<FlightsAirAncillarySelectionDetailInput>;
  ancillaryJourneySelections?: InputMaybe<Array<AncillaryJourneySelectionsInput>>;
  ancillaryShoppingPath?: InputMaybe<AncillaryShoppingPath>;
  ancillaryType?: InputMaybe<FlightsAncillaryType>;
  flightAncillaryJourneySelections?: InputMaybe<Array<FlightAncillaryJourneySelectionInput>>;
  flightsDetailComponentsCriteria?: InputMaybe<FlightsDetailComponentsCriteriaInput>;
  journeyContinuationId: Scalars["String"]["input"];
  journeyIdentifier: Scalars["String"]["input"];
}

export interface FlightsDetailComponentsCriteriaInput {
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  journeyIdentifier: Scalars["String"]["input"];
  originalPrice?: InputMaybe<MoneyInput>;
  queryParams?: InputMaybe<Array<GraphQlPairInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  travelerDetails: Array<FlightsTravelerDetailsInput>;
  upsellOfferToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightsDetailCriteria = {
  __typename?: "FlightsDetailCriteria";
  additionalShoppedAncillaries?: Maybe<Array<AdditionalShoppedAncillaries>>;
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  channelType?: Maybe<ChannelType>;
  currentStep?: Maybe<FlightsStep>;
  flightsOneClickFareUpgradeCardType?: Maybe<FlightsOneClickFareUpgradeCardType>;
  journeyContinuationId: Scalars["String"]["output"];
  /** @deprecated deprecating this for pastOfferTokens */
  lastSelectedOfferToken?: Maybe<Scalars["String"]["output"]>;
  lastSelectedOfferTokensInformation?: Maybe<FlightsOneClickFareLastSelectedTokens>;
  originalBookingId?: Maybe<Scalars["String"]["output"]>;
  upsellOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsDetailCriteriaInput {
  additionalShoppedAncillaries?: InputMaybe<Array<AdditionalShoppedAncillaries>>;
  ancillaryId?: InputMaybe<Scalars["String"]["input"]>;
  channelType?: InputMaybe<ChannelType>;
  currentStep?: InputMaybe<FlightsStep>;
  flightsOneClickFareUpgradeCardType?: InputMaybe<FlightsOneClickFareUpgradeCardType>;
  journeyContinuationId: Scalars["String"]["input"];
  lastSelectedOfferToken?: InputMaybe<Scalars["String"]["input"]>;
  lastSelectedOfferTokensInformation?: InputMaybe<FlightsOneClickFareLastSelectedTokensInput>;
  originalBookingId?: InputMaybe<Scalars["String"]["input"]>;
  upsellOfferToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightsDetailedJourneyInformation = {
  __typename?: "FlightsDetailedJourneyInformation";
  badges?: Maybe<Array<EgdsBadge>>;
  details: FlightsToggle;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  flightJourneyDetails: FlightsJourneyDetails;
  link?: Maybe<EgdsStandardLink>;
};

export type FlightsDetailsAndFaresContent = {
  __typename?: "FlightsDetailsAndFaresContent";
  dialogId: FlightsDialogId;
  displayAnalytics: Array<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  nestedDialogs?: Maybe<Array<FlightsDialog>>;
  priceMatchPromiseNestedDialogs?: Maybe<Array<FlightsDialog>>;
  sections: Array<FlightsDetailsAndFaresSection>;
  sideSheetTabs: DetailsAndFaresTabs;
};

export type FlightsDetailsAndFaresFooter = {
  __typename?: "FlightsDetailsAndFaresFooter";
  action: FlightsActionButton;
  dialogId: FlightsDialogId;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  priceLockup: EgdsPriceLockup;
};

export type FlightsDetailsAndFaresPresentation = {
  __typename?: "FlightsDetailsAndFaresPresentation";
  airlineTabs?: Maybe<EgdsBasicTabs>;
  content: FlightsDetailsAndFaresContent;
  flightsFaresWrapper: FlightsDialogSheet;
  footer?: Maybe<FlightsDetailsAndFaresFooter>;
  preloadedInfo?: Maybe<PreloadedInfo>;
  sideSheetInformationText?: Maybe<TextWrapper>;
  trigger: FlightsDialogTrigger;
};

export type FlightsDetailsAndFaresPresentationElements = {
  __typename?: "FlightsDetailsAndFaresPresentationElements";
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  journeyContinuationId: Scalars["String"]["output"];
};

export type FlightsDetailsAndFaresPriceSection = {
  __typename?: "FlightsDetailsAndFaresPriceSection";
  leadingPrice?: Maybe<EgdsSpannableText>;
  price: EgdsSpannableText;
  secondaryTexts?: Maybe<Array<EgdsSpannableText>>;
  trailingPrice?: Maybe<EgdsSpannableText>;
};

export type FlightsDetailsAndFaresSection =
  | FlightsFaresInformation
  | FlightsHygieneAmenitiesSection
  | FlightsJourneyDetailsInformation
  | FlightsJourneyHeaderInformation
  | FlightsJourneyWithDetails
  | FlightsMessagingCard
  | FlightsSustainabilityCard
  | FlightsSustainabilityMessaging
  | TextWrapper
  | TextWrapperFlightsPhrase;

export interface FlightsDevicePermissionsInput {
  pushNotificationsEnabled: Array<NotificationsType>;
}

export type FlightsDialog = {
  __typename?: "FlightsDialog";
  content?: Maybe<Array<EgdsElement>>;
  dialog: EgdsDialog;
  dialogId: FlightsDialogId;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
};

export type FlightsDialogAction = "CLOSE" | "OPEN" | "TOGGLE";

export type FlightsDialogAnimation = "FADE" | "NONE";

export type FlightsDialogChangeFlight = FlightsDialogPresentation & {
  __typename?: "FlightsDialogChangeFlight";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsDialogCloseAction = FlightsClientAction & {
  __typename?: "FlightsDialogCloseAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsDialogCloseUiAction = UiAction & {
  __typename?: "FlightsDialogCloseUIAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type FlightsDialogId =
  | "AIRLINE_CREDITS"
  | "BAGGAGE_CLOSE_COMPLETE_DIALOG"
  | "BAGGAGE_CLOSE_DIALOG"
  | "BAGGAGE_CLOSE_INCOMPLETE_DIALOG"
  | "BAGGAGE_CONTINUE_BOOKING"
  | "BAGGAGE_DETAILS_DIALOG"
  | "BAGS_POPUP_ON_CHECKOUT"
  | "CHECK_CREDITS_DIALOG"
  | "CKO_REASSURANCE_DISCLAIMER_DIALOG"
  | "FLIGHTS_DETAILS_AND_FARES"
  | "FLIGHTS_DETAILS_DIALOG"
  | "FORCED_CHOICE_MODAL"
  | "FSR_EXPIRED_DEALS_DIALOG"
  | "FSR_HOW_DEALS_WORK_SHEET"
  | "FSR_OUT_DISCLAIMER_DIALOG"
  | "HOW_CREDITS_WORK"
  | "LEAVE_TO_COUPONS"
  | "MEMBER_SIGN_IN_DIALOG"
  | "MERCHANDISING_SELECTIONS_BOOKING_LOADING"
  | "MERCHANDISING_SELECTIONS_EXPIRY"
  | "MOBILE_APP_DOWNLOAD_SHEET"
  | "ONE_CLICK_FARE_DOWNGRADE_DIALOG"
  | "PAID_BAGS_DIALOG"
  | "PMP_INFO_DIALOG"
  | "PMP_ONE_KEY_WEB_DIALOG"
  | "PMP_REMOVE_DIALOG"
  | "SEATS_POPUP_ON_CHECKOUT"
  | "SEAT_CELL_DETAILS_DIALOG"
  | "SEAT_CLOSE_COMPLETE_DIALOG"
  | "SEAT_CONTINUE_BOOKING"
  | "SEAT_LEGEND_DIALOG"
  | "SEAT_SELECTION_DIALOG"
  | "SKIP_TO_BAGS_SEATS_INCOMPLETE_SELECTION_DIALOG"
  | "SKIP_TO_BAGS_SEATS_NO_SELECTION_DIALOG"
  | "SKIP_TO_CKO_BAGS_DIALOG"
  | "SKIP_TO_CKO_SEATS_AND_BAGS_DIALOG"
  | "SKIP_TO_CKO_SEATS_DIALOG"
  | "TRAVELER_MISMATCH_DIALOG";

export type FlightsDialogMultiPassengerCredit = FlightsDialogPresentation & {
  __typename?: "FlightsDialogMultiPassengerCredit";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  secondaries?: Maybe<Array<EgdsStylizedText>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsDialogPreAction = "CLOSE_ALL_DIALOGS";

export interface FlightsDialogPresentation {
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
}

export type FlightsDialogServerError = FlightsDialogPresentation & {
  __typename?: "FlightsDialogServerError";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsDialogSheet = {
  __typename?: "FlightsDialogSheet";
  dialog: FlightsDialog;
  dialogId: FlightsDialogId;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use sheetInfo instead */
  sheet: FlightsSideSheet;
  sheetInfo?: Maybe<FlightsSheet>;
};

export type FlightsDialogStaleSession = FlightsDialogPresentation & {
  __typename?: "FlightsDialogStaleSession";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  timeoutInSeconds: Scalars["Int"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsDialogStandard = FlightsDialogPresentation & {
  __typename?: "FlightsDialogStandard";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsDialogTrigger = EgdsButton &
  EgdsElement & {
    __typename?: "FlightsDialogTrigger";
    /** @deprecated Will be provided in trigger field */
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
     *         or a union type with a manageable number of members
     *
     */
    action?: Maybe<UiAction>;
    /** @deprecated Will be provided in trigger field */
    analytics?: Maybe<ClientSideAnalytics>;
    dialogAction: FlightsDialogAction;
    dialogId: FlightsDialogId;
    dialogPreAction?: Maybe<FlightsDialogPreAction>;
    /** @deprecated Will be provided in trigger field */
    disabled: Scalars["Boolean"]["output"];
    dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Will be provided in trigger field */
    icon?: Maybe<Icon>;
    /** @deprecated Will be provided in trigger field */
    primary?: Maybe<Scalars["String"]["output"]>;
    trigger: FlightsDialogTriggerType;
  };

export type FlightsDialogTriggerAction = FlightsClientAction & {
  __typename?: "FlightsDialogTriggerAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialog: EgdsDialog;
  dialogContents: Array<EgdsElement>;
};

export type FlightsDialogTriggerType =
  | FlightsActionButton
  | FlightsActionLink
  | FlightsExperienceActionButton
  | FlightsExperienceActionLink;

export type FlightsDialogTriggerUiAction = UiAction & {
  __typename?: "FlightsDialogTriggerUIAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  dialog: EgdsDialog;
  dialogContents: Array<EgdsElement>;
};

export type FlightsDisplayComponent = "SMART_NFF_ALTERNATE_SUGGESTION";

export type FlightsDisplayComponentCountingValue = FlightsDisplayComponentValue & {
  __typename?: "FlightsDisplayComponentCountingValue";
  displayCount: Scalars["Int"]["output"];
  flightsDisplayComponent: FlightsDisplayComponent;
};

export interface FlightsDisplayComponentCountingValueInput {
  displayCount: Scalars["Int"]["input"];
  flightsDisplayComponent: FlightsDisplayComponent;
}

export interface FlightsDisplayComponentValue {
  flightsDisplayComponent: FlightsDisplayComponent;
}

export interface FlightsDisplayComponentValuesInput {
  displayCountingValues?: InputMaybe<Array<FlightsDisplayComponentCountingValueInput>>;
}

export type FlightsDurationBasedFilterValue = {
  __typename?: "FlightsDurationBasedFilterValue";
  maxDuration: Scalars["Int"]["output"];
};

export interface FlightsDurationBasedFilterValueInput {
  maxDuration: Scalars["Int"]["input"];
}

export type FlightsDurationTimeline = {
  __typename?: "FlightsDurationTimeline";
  /** @deprecated Use 'secondary' field to support different screen sizes */
  additionalInfo: Scalars["String"]["output"];
  end: DurationTimelineSideSection;
  /** @deprecated Use 'primary' field to support different screen sizes */
  heading: Scalars["String"]["output"];
  primary: FlightsResponsiveMessage;
  secondary: FlightsResponsiveMessage;
  start: DurationTimelineSideSection;
  stops: Scalars["Int"]["output"];
};

export interface FlightsDynamicElements {
  dynamicElementsGroupTrackEventAnalytics?: Maybe<FlightsDynamicElementsTrackEventAnalytics>;
  flightsJourneyDetailsDynamicElements?: Maybe<Array<FlightsJourneyDetailsDynamicElements>>;
}

export type FlightsDynamicElementsTrackEventAnalytics = {
  __typename?: "FlightsDynamicElementsTrackEventAnalytics";
  analyticsList: Array<FlightsAnalytics>;
  stringifiedAllFlightProducts: Scalars["String"]["output"];
};

export type FlightsElementsWrapper = {
  __typename?: "FlightsElementsWrapper";
  element: EgdsElement;
};

export type FlightsEmailSubscription = {
  __typename?: "FlightsEmailSubscription";
  icon: Icon;
  label: Scalars["String"]["output"];
  submitAction: FlightsAction;
  title: Scalars["String"]["output"];
};

export interface FlightsErrorMessagingPresentation {
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
}

export type FlightsErrorSummary = {
  __typename?: "FlightsErrorSummary";
  displayAnalytics: FlightsAnalytics;
  header: Scalars["String"]["output"];
  linkAnalytics: FlightsAnalytics;
  linkText: Scalars["String"]["output"];
};

export interface FlightsEventAnalytics {
  eventCategory: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  pageName: Scalars["String"]["output"];
}

export type FlightsExpandableBottomSheet = FlightsSheet & {
  __typename?: "FlightsExpandableBottomSheet";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  content?: Maybe<Array<EgdsElement>>;
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  sheetState?: Maybe<SheetState>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsExpandableCategorizedListSection = {
  __typename?: "FlightsExpandableCategorizedListSection";
  expando: EgdsExpandoLink;
  expandoContent: FlightsCategorizedListSection;
};

export type FlightsExpando = {
  __typename?: "FlightsExpando";
  contents: EgdsTextContainer;
  expando: EgdsExpando;
};

export type FlightsExperienceActionButton = EgdsButton & {
  __typename?: "FlightsExperienceActionButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: FlightsClientAction;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
  width?: Maybe<FlightsButtonWidth>;
};

export type FlightsExperienceActionLink = {
  __typename?: "FlightsExperienceActionLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: FlightsClientAction;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsExperienceBargainFareOffer = {
  __typename?: "FlightsExperienceBargainFareOffer";
  accessibilityMessage: Scalars["String"]["output"];
  bargainPricingInformation: FlightsSmartShoppingPriceSummary;
  description: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  offerDialogTrigger: FlightsExperienceDialogTrigger;
  sanitizedOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsExperienceDialog = {
  __typename?: "FlightsExperienceDialog";
  dialog: EgdsDialog;
  dialogId: FlightsDialogId;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
};

export type FlightsExperienceDialogSheet = {
  __typename?: "FlightsExperienceDialogSheet";
  /** @deprecated This will be replaced by experienceDialog */
  dialog: FlightsExperienceDialog;
  dialogId: FlightsDialogId;
  experienceDialog: FlightsDialog;
  experienceSheet: FlightsSideSheet;
  /** @deprecated This will be replaced by experienceSheet */
  sheet: FlightsExperienceSideSheet;
};

export type FlightsExperienceDialogTrigger = {
  __typename?: "FlightsExperienceDialogTrigger";
  dialogAction: FlightsDialogAction;
  dialogId: FlightsDialogId;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  trigger: FlightsDialogTriggerType;
};

export type FlightsExperienceSideSheet = {
  __typename?: "FlightsExperienceSideSheet";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFareCalendarPrice = {
  __typename?: "FlightsFareCalendarPrice";
  price?: Maybe<Money>;
  priceTheme: FlightsFareCalendarPriceTheme;
  valueText: Scalars["String"]["output"];
};

export type FlightsFareCalendarPriceTheme = "NEGATIVE" | "POSITIVE" | "UNDEFINED";

export type FlightsFareCalendarResponse = {
  __typename?: "FlightsFareCalendarResponse";
  coverageAnalytics: FlightsAnalytics;
  datePickerCellContents: Array<FlightsDatePickerCellContent>;
  disclaimerText: Scalars["String"]["output"];
  priceLockupText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFareChoiceInformation = {
  __typename?: "FlightsFareChoiceInformation";
  accessibleHeading: Scalars["String"]["output"];
  availableFaresCount: Scalars["Int"]["output"];
  convertAnalytics?: Maybe<Array<FlightsAnalytics>>;
  defaultBaggageInformation: FlightsBaggageInformation;
  dialogs?: Maybe<Array<FlightsDialog>>;
  /** @deprecated This field will be replaced by displayAnalyticsList */
  displayAnalytics: FlightsAnalytics;
  displayAnalyticsList: Array<FlightsAnalytics>;
  errorAnalytics?: Maybe<Array<FlightsAnalytics>>;
  fareTypes: Array<FlightsJourneyFare>;
  fareTypesSkeletonCount: Scalars["Int"]["output"];
  fares?: Maybe<Array<FlightsJourneyFareBase>>;
  faresTypesLoadingAnalytics: FaresTypesLoadingAnalytics;
  heading: FlightsResponsiveMessage;
  message: Scalars["String"]["output"];
  nextFareChoiceAccessibilityMessage: Scalars["String"]["output"];
  percentageDelaysAndCancellationLink?: Maybe<FlightsLinkAction>;
  previousFareChoiceAccessibilityMessage: Scalars["String"]["output"];
  priceMatchPromiseDialogs?: Maybe<Array<FlightsDialog>>;
  scrollAnalytics: FlightsAnalytics;
  sustainabilityInformation?: Maybe<FlightsIconPhrases>;
  tripTypePerTraveler: Scalars["String"]["output"];
};

export type FlightsFareRule = {
  __typename?: "FlightsFareRule";
  displayName: FlightsIconAndLabel;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFareTypeSelectionAction = FlightsClientAction & {
  __typename?: "FlightsFareTypeSelectionAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogId?: Maybe<Scalars["String"]["output"]>;
  nextPage: Uri;
  packagePage?: Maybe<Uri>;
};

export type FlightsFaresInformation = {
  __typename?: "FlightsFaresInformation";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of faresCarousel */
  carousel: EgdsCarouselContainer;
  displayAnalytics: Array<ClientSideAnalytics>;
  fares: Array<FareInformationCard>;
  faresCarousel: EgdsCarousel;
  faresLoadingAnalytics?: Maybe<FaresLoadingAnalytics>;
  faresScrollAnalytics?: Maybe<FlightsFaresScrollAnalytics>;
  faresScrollDirection?: Maybe<FlightsFaresScrollDirection>;
  heading?: Maybe<Scalars["String"]["output"]>;
  revealFaresToggle?: Maybe<EgdsExpandoPeek>;
  /** @deprecated Deprecated in favor of faresScrollAnalytics */
  scrollAnalytics: Array<ClientSideAnalytics>;
  /** @deprecated Moved field to be under FlightListingsAnalytics */
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  subheading?: Maybe<Scalars["String"]["output"]>;
  totalFareCount: Scalars["Int"]["output"];
};

export type FlightsFaresScrollAnalytics = {
  __typename?: "FlightsFaresScrollAnalytics";
  scrollDown: ClientSideAnalytics;
  scrollDownButtonClick?: Maybe<Array<ClientSideAnalytics>>;
  scrollLeft: ClientSideAnalytics;
  scrollRight: ClientSideAnalytics;
  scrollUp: ClientSideAnalytics;
};

export type FlightsFaresScrollDirection = "HORIZONTAL" | "VERTICAL";

export type FlightsFaresSeparationType =
  | "BASE_AND_UPSELL"
  | "NONSTOP_BASE_AND_UPSELL"
  | "NONSTOP_ONLY_BASE"
  | "NONSTOP_ONLY_UPSELL"
  | "ONLY_BASE"
  | "ONLY_UPSELL";

export type FlightsFederationSampleResponse = {
  __typename?: "FlightsFederationSampleResponse";
  id: Scalars["UUID"]["output"];
  queryVariable?: Maybe<QueryVariables>;
  resolverA?: Maybe<Scalars["String"]["output"]>;
  resolverB?: Maybe<Scalars["String"]["output"]>;
  resolverC?: Maybe<Scalars["String"]["output"]>;
  resolverD?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFeedback = {
  __typename?: "FlightsFeedback";
  feedbackLinkAction: FlightsAction;
  iconAndLabel: FlightsIconAndLabel;
};

export interface FlightsFilterItem {
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  defaultSelected: Scalars["Boolean"]["output"];
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: FlightsFilterValue;
}

export type FlightsFilterItemAnalytics = {
  __typename?: "FlightsFilterItemAnalytics";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  max?: Maybe<Scalars["String"]["output"]>;
  min?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFilterLoadedPresentation = FlightsFilterPresentation & {
  __typename?: "FlightsFilterLoadedPresentation";
  accessibility: FlightsAccessibilityMessage;
  emptyFiltersMessage?: Maybe<Scalars["String"]["output"]>;
  eventAnalytics: FlightsFiltersEventAnalytics;
  options?: Maybe<Array<FlightsFilterOption>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFilterLoadingPresentation = FlightsFilterPresentation & {
  __typename?: "FlightsFilterLoadingPresentation";
  accessibility: FlightsAccessibilityMessage;
  options: Array<FlightsFilterSkeletonOption>;
  skeletonCount: Scalars["Int"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsFilterOption {
  label: Scalars["String"]["output"];
  supportsMultipleSelection: Scalars["Boolean"]["output"];
  type: FlightsFilterType;
}

export interface FlightsFilterPresentation {
  accessibility: FlightsAccessibilityMessage;
  title?: Maybe<Scalars["String"]["output"]>;
}

export type FlightsFilterSkeletonOption = {
  __typename?: "FlightsFilterSkeletonOption";
  itemCount: Scalars["Int"]["output"];
  label: Scalars["String"]["output"];
  priceFromLabel?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFilterType =
  | "ARRIVAL_AIRPORT"
  | "ARRIVAL_TIME"
  | "ARRIVAL_TIME_SLIDER"
  | "CARRY_ON_BAG"
  | "CHECKED_BAG"
  | "CREDIT_ELIGIBLE"
  | "DEPARTURE_AIRPORT"
  | "DEPARTURE_TIME"
  | "DEPARTURE_TIME_SLIDER"
  | "DURATION"
  | "FLEXIBLE_CHANGE_POLICIES"
  | "LAYOVER_AIRPORT"
  | "LAYOVER_TIME_SLIDER"
  | "NO_CANCEL_FEE"
  | "NO_CHANGE_FEE"
  | "NUM_OF_STOPS"
  | "PREFERRED_AIRLINE"
  | "PRICE"
  | "SEAT_CHOICE"
  | "TRAVEL_AND_BAGGAGE"
  | "TRAVEL_TIME_SLIDER";

export type FlightsFilterValue =
  | FlightsAirportCodeFilterValue
  | FlightsCarryOnBagBasedFilterValue
  | FlightsCheckedBagBasedFilterValue
  | FlightsCreditEligibleFilterValue
  | FlightsDurationBasedFilterValue
  | FlightsFlexibleChangePolicyBasedFilterValue
  | FlightsNoCancelFeeBasedFilterValue
  | FlightsNoChangeFeeBasedFilterValue
  | FlightsPreferredAirlineFilterValue
  | FlightsSeatChoiceBasedFilterValue
  | FlightsSliderFilterValue
  | FlightsStopBasedFilterValue
  | FlightsTimeBasedFilterValue
  | FlightsTravelAndBaggageBasedFilterValue;

export type FlightsFiltersEventAnalytics = FlightsEventAnalytics & {
  __typename?: "FlightsFiltersEventAnalytics";
  eventCategory: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  filterAppliedEventName: Scalars["String"]["output"];
  filterRemovedEventName: Scalars["String"]["output"];
  pageName: Scalars["String"]["output"];
};

export type FlightsFinancialAdjustmentConfirmStep = {
  __typename?: "FlightsFinancialAdjustmentConfirmStep";
  close?: Maybe<UiPrimaryButton>;
  /** @deprecated Use close instead */
  closeButton?: Maybe<Scalars["String"]["output"]>;
  confirmationCard: BookingConfirmation;
};

export type FlightsFinancialAdjustmentError = {
  __typename?: "FlightsFinancialAdjustmentError";
  error?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsFinancialAdjustmentFormInput {
  airProviderLocator: Scalars["String"]["input"];
  orderNumber: Scalars["String"]["input"];
}

export type FlightsFinancialAdjustmentFormSteps =
  | FlightsFinancialAdjustmentRefundStep
  | FlightsFinancialAdjustmentReviewStep;

export type FlightsFinancialAdjustmentFormView =
  | FlightsFinancialAdjustmentFormViewError
  | FlightsFinancialAdjustmentFormViewSuccess;

export type FlightsFinancialAdjustmentFormViewError = {
  __typename?: "FlightsFinancialAdjustmentFormViewError";
  error?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFinancialAdjustmentFormViewSuccess = {
  __typename?: "FlightsFinancialAdjustmentFormViewSuccess";
  stepIndicator: AgentToolsStepIndicator;
  steps?: Maybe<Array<FlightsFinancialAdjustmentFormSteps>>;
  tripStage?: Maybe<TripStage>;
};

export interface FlightsFinancialAdjustmentInput {
  airProviderLocator: Scalars["String"]["input"];
  orderNumber: Scalars["String"]["input"];
}

export type FlightsFinancialAdjustmentRefundStep = {
  __typename?: "FlightsFinancialAdjustmentRefundStep";
  /** @deprecated No Longer required */
  refundSection?: Maybe<AgentToolsChangeOrCancelReason>;
  /** @deprecated No Longer required */
  reviewButton?: Maybe<Scalars["String"]["output"]>;
  tripId?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFinancialAdjustmentResponse =
  | FlightsFinancialAdjustmentConfirmStep
  | FlightsFinancialAdjustmentError;

export type FlightsFinancialAdjustmentReviewStep = {
  __typename?: "FlightsFinancialAdjustmentReviewStep";
  back?: Maybe<UiSecondaryButton>;
  /** @deprecated Use back instead */
  backButton?: Maybe<Scalars["String"]["output"]>;
  submit?: Maybe<UiPrimaryButton>;
  /** @deprecated Use submit instead */
  submitButton?: Maybe<Scalars["String"]["output"]>;
  updatePaymentInfo?: Maybe<UiSecondaryButton>;
  /** @deprecated Use updatePaymentInfo instead */
  updatePaymentInfoButton?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleChangePolicyBasedFilterValue = {
  __typename?: "FlightsFlexibleChangePolicyBasedFilterValue";
  policyName: FilterPolicyType;
};

export interface FlightsFlexibleChangePolicyBasedFilterValueInput {
  policyName: FilterPolicyType;
}

export type FlightsFlexibleGridType = "FLEX_SEARCH" | "FLEX_SEARCH_GRID" | "FLEX_SEARCH_NOT_APPLICABLE";

export interface FlightsFlexibleSearchCell {
  accessibility: FlightsFlexibleSearchCellAccessibility;
  labelText?: Maybe<Scalars["String"]["output"]>;
  valueText?: Maybe<Scalars["String"]["output"]>;
}

export type FlightsFlexibleSearchCellAccessibility = {
  __typename?: "FlightsFlexibleSearchCellAccessibility";
  departureDate?: Maybe<Scalars["String"]["output"]>;
  highlightDescription?: Maybe<Scalars["String"]["output"]>;
  imageDescription?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["String"]["output"]>;
  returnDate?: Maybe<Scalars["String"]["output"]>;
  selectionDescription?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchGridDateHeaders = {
  __typename?: "FlightsFlexibleSearchGridDateHeaders";
  returnDateHeaders: Array<FlightsFlexibleSearchCell>;
};

export type FlightsFlexibleSearchGridLegendBadge = {
  __typename?: "FlightsFlexibleSearchGridLegendBadge";
  accessibility: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  labelText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchGridTrigger = {
  __typename?: "FlightsFlexibleSearchGridTrigger";
  accessibility: FlightsAccessibilityMessage;
  calendarIcon?: Maybe<Icon>;
  collapseAction?: Maybe<Scalars["String"]["output"]>;
  collapseAnalytics: FlightsAnalytics;
  expandAction: Scalars["String"]["output"];
  expandAnalytics: FlightsAnalytics;
  flexGridTransitionIcon?: Maybe<Icon>;
  subText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchHeaderCell = FlightsFlexibleSearchCell & {
  __typename?: "FlightsFlexibleSearchHeaderCell";
  accessibility: FlightsFlexibleSearchCellAccessibility;
  labelText?: Maybe<Scalars["String"]["output"]>;
  theme: FlightsFlexibleSearchHeaderCellTheme;
  valueText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchHeaderCellTheme = "DEFAULT" | "SEARCHED_DATE_HEADER";

export type FlightsFlexibleSearchLegendCell = FlightsFlexibleSearchCell & {
  __typename?: "FlightsFlexibleSearchLegendCell";
  accessibility: FlightsFlexibleSearchCellAccessibility;
  accessibilityMessage?: Maybe<FlightsAccessibilityMessage>;
  icon?: Maybe<Icon>;
  labelText?: Maybe<Scalars["String"]["output"]>;
  theme: FlightsFlexibleSearchLegendCellTheme;
  valueText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchLegendCellTheme = "DEFAULT";

export type FlightsFlexibleSearchResponse = {
  __typename?: "FlightsFlexibleSearchResponse";
  accuracyDeviationAnalytics?: Maybe<FlightsAnalytics>;
  accuracyNffAnalytics?: Maybe<FlightsAnalytics>;
  analytics: FlightsFlexibleSearchResponseAnalytics;
  cheapestPrice?: Maybe<Scalars["String"]["output"]>;
  coverageAnalytics: FlightsAnalytics;
  coverageNotValidAnalytics?: Maybe<FlightsAnalytics>;
  departureLegend?: Maybe<FlightsFlexibleSearchGridLegendBadge>;
  dismiss?: Maybe<Icon>;
  dismissGridAccessibility: FlightsAccessibilityMessage;
  dismissGridAnalytics: FlightsAnalytics;
  displayAnalytics: Array<ClientSideAnalytics>;
  flexGridType: FlightsFlexibleGridType;
  gridTrigger?: Maybe<FlightsFlexibleSearchGridTrigger>;
  loadingStateAccessibility: Scalars["String"]["output"];
  resultsGrid: FlightsFlexibleSearchResultsGrid;
  returnDateHeaders?: Maybe<FlightsFlexibleSearchGridDateHeaders>;
  searchTriggerAction?: Maybe<Scalars["String"]["output"]>;
  showFlexGrid: Scalars["Boolean"]["output"];
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  titleAccessibility: Scalars["String"]["output"];
};

export type FlightsFlexibleSearchResponseAnalytics = {
  __typename?: "FlightsFlexibleSearchResponseAnalytics";
  priceFillRate: Scalars["String"]["output"];
};

export type FlightsFlexibleSearchResultAnalytics = {
  __typename?: "FlightsFlexibleSearchResultAnalytics";
  departureDateDifferential?: Maybe<Scalars["String"]["output"]>;
  priceDifferential?: Maybe<Scalars["String"]["output"]>;
  returnDateDifferential?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchResultCell = FlightsFlexibleSearchCell & {
  __typename?: "FlightsFlexibleSearchResultCell";
  accessibility: FlightsFlexibleSearchCellAccessibility;
  analytics?: Maybe<FlightsFlexibleSearchResultAnalytics>;
  domain?: Maybe<FlightsFlexibleSearchResultCellDomain>;
  isLink: Scalars["Boolean"]["output"];
  journeyDate?: Maybe<Scalars["String"]["output"]>;
  labelText?: Maybe<Scalars["String"]["output"]>;
  nextSearchCriteria?: Maybe<FlightsNextJourneyCriteria>;
  /** @deprecated Use selectAnalyticsList instead */
  selectAnalytics: FlightsAnalytics;
  selectAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  selectButtonActive?: Maybe<Scalars["Boolean"]["output"]>;
  summary?: Maybe<Scalars["String"]["output"]>;
  theme: FlightsFlexibleSearchResultCellTheme;
  valueText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsFlexibleSearchResultCellDomain = {
  __typename?: "FlightsFlexibleSearchResultCellDomain";
  price: Money;
};

export type FlightsFlexibleSearchResultCellTheme =
  | "DEFAULT_PRICE"
  | "HIGHEST_PRICE"
  | "LOWEST_PRICE"
  | "NO_PRICE"
  | "SEARCHED_DATE"
  | "SEARCHED_DATE_LOWEST_PRICE"
  | "SEARCHED_DATE_NO_PRICE"
  | "SEARCHED_DATE_UNAVAILABLE_FLIGHTS"
  | "UNAVAILABLE_FLIGHTS";

export type FlightsFlexibleSearchResultsGrid = {
  __typename?: "FlightsFlexibleSearchResultsGrid";
  results: Array<Array<FlightsFlexibleSearchCell>>;
};

export type FlightsForcedChoiceCrossSellDialog = FlightsDialogPresentation & {
  __typename?: "FlightsForcedChoiceCrossSellDialog";
  actions: Array<FlightsAction>;
  animation: FlightsDialogAnimation;
  annotation: FlightsPhrase;
  /** @deprecated Replaced with checkInDatePicker to use EGDSSearchFormDatePickerField */
  checkInDate?: Maybe<FlightsSearchFormDateField>;
  checkInDatePicker?: Maybe<EgdsSearchFormDatePickerField>;
  /** @deprecated Replaced with checkOutDatePicker to use EGDSSearchFormDatePickerField */
  checkOutDate?: Maybe<FlightsSearchFormDateField>;
  checkOutDatePicker?: Maybe<EgdsSearchFormDatePickerField>;
  displayBlockedAnalytics: FlightsAnalytics;
  displayCounter?: Maybe<Scalars["Int"]["output"]>;
  displayedAnalytics: FlightsAnalytics;
  /** @deprecated deprecating header to introduce a new nullable field for title/header of dialog */
  header: Scalars["String"]["output"];
  hotelDatesTitle?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  messageBody: Array<EgdsText>;
  recommendedHotels: Array<FlightsForcedChoiceCrossSellLodgingCard>;
  /** @deprecated Deprecating to introduce a new field of type EGDSText to handle different copy use cases */
  subHeader: FlightsIconAndPhrase;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsForcedChoiceCrossSellLodgingCard = {
  __typename?: "FlightsForcedChoiceCrossSellLodgingCard";
  callOut?: Maybe<EgdsText>;
  cardLink?: Maybe<UiLinkAction>;
  header: FlightsForcedChoiceCrossSellLodgingCardHeader;
  id: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  media: EgdsGalleryCarousel;
  summarySections: Array<FlightsForcedChoiceCrossSellLodgingCardProductSummary>;
};

export type FlightsForcedChoiceCrossSellLodgingCardHeader = {
  __typename?: "FlightsForcedChoiceCrossSellLodgingCardHeader";
  amenities: Array<EgdsGraphicText>;
  heading: Scalars["String"]["output"];
  messages: Array<EgdsText>;
  productRating?: Maybe<EgdsRating>;
};

export type FlightsForcedChoiceCrossSellLodgingCardPhrase = {
  __typename?: "FlightsForcedChoiceCrossSellLodgingCardPhrase";
  accessibilityLabel: Scalars["String"]["output"];
  phraseParts: Array<FlightsForcedChoiceCrossSellLodgingCardPhrasePart>;
};

export type FlightsForcedChoiceCrossSellLodgingCardPhrasePart = EgdsIconRating | EgdsPlainText | EgdsStylizedText;

export type FlightsForcedChoiceCrossSellLodgingCardProductSummary = {
  __typename?: "FlightsForcedChoiceCrossSellLodgingCardProductSummary";
  amenities: Array<EgdsGraphicText>;
  guestRatingSection?: Maybe<FlightsForcedChoiceCrossSellLodgingCardRating>;
  heading?: Maybe<EgdsHeading>;
  messages: Array<EgdsText>;
};

export type FlightsForcedChoiceCrossSellLodgingCardRating = {
  __typename?: "FlightsForcedChoiceCrossSellLodgingCardRating";
  badge?: Maybe<EgdsBadge>;
  phrases?: Maybe<Array<FlightsForcedChoiceCrossSellLodgingCardPhrase>>;
};

export type FlightsHeaderListing = {
  __typename?: "FlightsHeaderListing";
  contents: Array<EgdsSpannableText>;
  /** @deprecated Being replaced by contents */
  heading: Scalars["String"]["output"];
  headingSize: FlightsHeadingSize;
  /** @deprecated Being replaced by contents */
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsHeadingSize = "EIGHT" | "FIVE" | "FOUR" | "ONE" | "SEVEN" | "SIX" | "THREE";

export type FlightsHygieneAmenitiesPresentation = {
  __typename?: "FlightsHygieneAmenitiesPresentation";
  airlineAmenityGroups: Array<FlightsAirlineAmenityGroup>;
  amenitiesAction?: Maybe<FlightsTextSection>;
  disclaimerMessage: Scalars["String"]["output"];
  /** @deprecated This will be replaced by amenitiesAction */
  displayAction?: Maybe<FlightsTextSection>;
  flightInformationLink?: Maybe<FlightsLinkAction>;
};

export type FlightsHygieneAmenitiesSection = {
  __typename?: "FlightsHygieneAmenitiesSection";
  amenities: Array<EgdsIconText>;
  footer: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
};

export type FlightsIconAndAccessibility = {
  __typename?: "FlightsIconAndAccessibility";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
};

export type FlightsIconAndHeading = {
  __typename?: "FlightsIconAndHeading";
  heading: Scalars["String"]["output"];
  icons: Array<FlightsIconAndLabel>;
};

export type FlightsIconAndLabel = {
  __typename?: "FlightsIconAndLabel";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use displayAnalyticsList of type ClientSideAnalytics instead  of this */
  displayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  displayAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  icon: Icon;
  label: Scalars["String"]["output"];
  subLabel?: Maybe<Scalars["String"]["output"]>;
  subText?: Maybe<EgdsStylizedText>;
  theme: TextTheme;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsIconAndPhrase = {
  __typename?: "FlightsIconAndPhrase";
  icon: Icon;
  label: FlightsPhrase;
};

export type FlightsIconPhrases = FlightsText & {
  __typename?: "FlightsIconPhrases";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  text: Array<FlightsPhrase>;
};

export type FlightsIconPlacard = FlightsPlacard & {
  __typename?: "FlightsIconPlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]["output"]>;
  headingWithMoreInfo?: Maybe<FlightsMoreInfoLink>;
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated Use onViewedAnalyticsList instead */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList: Array<ClientSideAnalytics>;
  placardHeading?: Maybe<EgdsHeading>;
};

export type FlightsIconPosition = "LEADING" | "TRAILING";

export type FlightsImageAndLabel = {
  __typename?: "FlightsImageAndLabel";
  image: Image;
  /** @deprecated Everywhere in the code this phrase is styled as STANDARD, which should not be required by PWA, instead a simple string can be sent, for strings which need phrases use FlightsImagePhrases */
  label: FlightsPhrase;
  text: Scalars["String"]["output"];
};

export type FlightsImageListingLabel = {
  __typename?: "FlightsImageListingLabel";
  image: Image;
  label: Scalars["String"]["output"];
  subLabel?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsImagePlacard = FlightsPlacard & {
  __typename?: "FlightsImagePlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]["output"]>;
  image: Image;
  message: Scalars["String"]["output"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated Use onViewedAnalyticsList instead */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsImportantTripInfo = {
  __typename?: "FlightsImportantTripInfo";
  heading: EgdsHeading;
  rules: EgdsOrderedList;
};

export type FlightsInfoLink = {
  __typename?: "FlightsInfoLink";
  actionLink: FlightsExperienceActionLink;
  sheet?: Maybe<FlightsSheetType>;
};

export type FlightsInfoMessagingPresentation = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsInfoMessagingPresentation";
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type FlightsInformationAnalytics = {
  __typename?: "FlightsInformationAnalytics";
  footerOnViewedAnalytics?: Maybe<FlightsAnalytics>;
  onLoadAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
};

export type FlightsInformationClientMetadata = {
  __typename?: "FlightsInformationClientMetadata";
  pageMetadata?: Maybe<FlightsPwaMetadata>;
  selectedJourneyMetadata: SelectedJourneyMetadata;
  uiComponentsMetadata?: Maybe<UiComponentsMetadata>;
};

export type FlightsInformationDynamicElements = FlightsDynamicElements & {
  __typename?: "FlightsInformationDynamicElements";
  airOfferToken: Scalars["String"]["output"];
  dynamicElementsGroupTrackEventAnalytics?: Maybe<FlightsDynamicElementsTrackEventAnalytics>;
  fareChoiceInformationDynamicElement?: Maybe<FareChoiceInformationDynamicElement>;
  fareChoiceInformationDynamicElementWithPMP?: Maybe<FareChoiceInformationDynamicElement>;
  flightsJourneyDetailsDynamicElements?: Maybe<Array<FlightsJourneyDetailsDynamicElements>>;
  priceSummary: FlightsLoadedPriceSummary;
  priceSummaryWithPMP?: Maybe<FlightsLoadedPriceSummary>;
  totalPrice: Scalars["String"]["output"];
  totalPriceWithPMP?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsInformationDynamicElementsGroup = {
  __typename?: "FlightsInformationDynamicElementsGroup";
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  dynamicElements: FlightsDynamicElements;
  identifier: Scalars["String"]["output"];
};

export type FlightsInformationPriceMatchPromise = {
  __typename?: "FlightsInformationPriceMatchPromise";
  card?: Maybe<FlightsMessagingCardPlacard>;
  dialogs?: Maybe<Array<FlightsDialog>>;
  /** @deprecated Use placementCard instead */
  merchandisingToggleCard?: Maybe<PriceMatchPromiseToggleableCard>;
  placementCard?: Maybe<PriceDropProtectionPresentation>;
  surveyElementId?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsInformationResponse = {
  __typename?: "FlightsInformationResponse";
  /** @deprecated It was part of smart shopping, should not be used now onwards as the experiment stopped. */
  additionalShopping?: Maybe<Array<AdditionalShopping>>;
  analytics?: Maybe<FlightsInformationAnalytics>;
  banners?: Maybe<Array<FlightsPlacard>>;
  /** @deprecated No use case of it. Will be cleaned up once all clients stop using it. */
  baseOfferAncillaryID?: Maybe<Scalars["String"]["output"]>;
  brandPolicies?: Maybe<FlightsTextSection>;
  /** @deprecated Use flightsClientMetadata which is a list of supported types. clientMetadata will be removed. */
  clientMetadata?: Maybe<FlightsClientMetadata>;
  /** @deprecated Please do not use : Only for migration purpose */
  detailMigrationContext?: Maybe<DetailMigrationContext>;
  /** @deprecated dynamicElementsGroups is deprecated. Use DnF for sidesheet offers. */
  dynamicElementsGroups?: Maybe<Array<FlightsInformationDynamicElementsGroup>>;
  flightsClientMetadata: FlightsInformationClientMetadata;
  flightsInformationPriceMatchPromise?: Maybe<FlightsInformationPriceMatchPromise>;
  /** @deprecated flightsJourneyPlaybackExperience info migrated to clientMetadata -> UIComponentsPrimer */
  flightsJourneyPlaybackExperience?: Maybe<JourneyExperience>;
  flightsOneClickFareUpgrade?: Maybe<FlightsOneClickFareUpgradeCardPresentation>;
  /** @deprecated flightsOpinionLab is migrated and feedback form is powered by different component now */
  flightsOpinionLab?: Maybe<FlightsActionAndLabel>;
  flightsSelectedJourneyDetails?: Maybe<Array<FlightsSelectedJourneyDetails>>;
  flightsSelectedJourneyInformation?: Maybe<FlightsSelectedJourneyDetailsInformation>;
  /** @deprecated This will be replaced by FlightsSelectedJourneyDetails interface */
  flightsSelectedJourneyReview?: Maybe<Array<FlightsSelectedJourneyReview>>;
  flightsStepperToolbarTitles?: Maybe<FlightsStepperToolbarTitles>;
  /** @deprecated Supported ancillaries info migrated to clientMetadata -> UIComponentsPrimer */
  flightsSupportedAncillaries?: Maybe<FlightsSupportedAncillaryInfo>;
  freeCancellation?: Maybe<FlightsPlacard>;
  freePriceMatchPromise?: Maybe<FreePriceMatchPromise>;
  /** @deprecated Use GaiaIds instead of GaiaId. GaiaId will be removed. */
  gaiaId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated journey dates are migrated to clientMetadata -> SelectedJourneyMetadata */
  gaiaIds?: Maybe<Array<Scalars["String"]["output"]>>;
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  id: Scalars["UUID"]["output"];
  /** @deprecated jcid is migrated to clientMetadata -> SelectedJourneyMetadata */
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated journey dates are migrated to clientMetadata -> SelectedJourneyMetadata */
  journeyStartEndDates?: Maybe<Array<JourneyStartEndDates>>;
  messagingCards?: Maybe<Array<FlightsPlacard>>;
  /** @deprecated multiItemDynamicCrossSellPrimer info migrated to clientMetadata -> UIComponentsPrimer */
  multiItemDynamicCrossSellPrimer?: Maybe<MultiItemDynamicCrossSellPrimer>;
  pageTitle?: Maybe<FlightsPageTitle>;
  partnerLoyaltyEarnMessage?: Maybe<FlightsMarkPlacard>;
  priceSummary?: Maybe<FlightsPriceSummary>;
  sampleResolver?: Maybe<Scalars["String"]["output"]>;
  separateTicketBanner?: Maybe<FlightsIconPlacard>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  /** @deprecated Similar Flights Info migrated to clientMetadata -> SelectedJourneyMetadata */
  similarFlights?: Maybe<SimilarFlightsInfo>;
  /** @deprecated It was part of smart shopping, should not be used now onwards as the experiment stopped. */
  smartShoppingExperienceEligibility?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated stepIndicator info migrated to clientMetadata -> UIComponentsPrimer */
  stepIndicator?: Maybe<FlightsStepIndicator>;
};

export type FlightsInformationResponseBaseOfferAncillaryIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseBrandPoliciesArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseClientMetadataArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseDynamicElementsGroupsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsClientMetadataArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsOpinionLabArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsSelectedJourneyReviewArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseFlightsSupportedAncillariesArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseGaiaIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseGaiaIdsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseHygieneAmenitiesPresentationArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseJourneyContinuationIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseJourneyStartEndDatesArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseMultiItemDynamicCrossSellPrimerArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponsePageTitleArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponsePriceSummaryArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationResponseSimilarFlightsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsInformationText = {
  __typename?: "FlightsInformationText";
  content: Array<FlightsInformationTextItem>;
};

export type FlightsInformationTextItem =
  | BadgeWrapper
  | IconWrapper
  | LinkWrapper
  | MarkWrapper
  | SuperlativeText
  | TextWrapper
  | TextWrapperFlightsPhrase
  | SpecialOfferTypeMessagingWrapper;

export type FlightsJourneyAmenities = {
  __typename?: "FlightsJourneyAmenities";
  accessibilityMessage: Scalars["String"]["output"];
  amenities: Array<FlightsIconAndLabel>;
  analytics?: Maybe<FlightsAnalytics>;
  title?: Maybe<EgdsStylizedText>;
};

export type FlightsJourneyAmenitiesWithLabel = {
  __typename?: "FlightsJourneyAmenitiesWithLabel";
  label: Scalars["String"]["output"];
  rules: FlightsJourneyAmenities;
};

export type FlightsJourneyAmenityRules = {
  __typename?: "FlightsJourneyAmenityRules";
  amenities: Array<FlightsIconAndLabel>;
  itemsDefaultDisplayCount: Scalars["Int"]["output"];
};

export type FlightsJourneyAvailableFares = FlightsAvailableFares & {
  __typename?: "FlightsJourneyAvailableFares";
  applySelection: FlightsAction;
  dismissDetailedJourneyInformation: FlightsActionableIcon;
  fareChoiceInformation?: Maybe<FlightsFareChoiceInformation>;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  openAccessibility: Scalars["String"]["output"];
  signInMessagingCard?: Maybe<EgdsStandardMessagingCard>;
};

export type FlightsJourneyAvailableFaresInformationElements = {
  __typename?: "FlightsJourneyAvailableFaresInformationElements";
  flightsJourneyAvailableFaresInformation?: Maybe<FlightsJourneyAvailableFares>;
  journeyContinuationId: Scalars["String"]["output"];
  /** @deprecated Not in usage */
  stringifiedFlightsJourneyAvailableFaresInformation?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsJourneyBaggageSelectionContent = {
  __typename?: "FlightsJourneyBaggageSelectionContent";
  journeyContent: FlightsBaggageAvailableJourneySelection;
};

export type FlightsJourneyBaggageSelectionFailure = {
  __typename?: "FlightsJourneyBaggageSelectionFailure";
  errorMessage: FlightsAncillaryErrorMessage;
  staticAncillaries?: Maybe<FlightsCategorizedList>;
};

export type FlightsJourneyBaggageSelectionPresentation =
  | FlightsJourneyBaggageSelectionContent
  | FlightsJourneyBaggageSelectionFailure
  | FlightsJourneyBaggageSelectionSuccess;

export type FlightsJourneyBaggageSelectionSuccess = {
  __typename?: "FlightsJourneyBaggageSelectionSuccess";
  journeyContent: FlightsBaggageAvailableFlightLegSelection;
};

export type FlightsJourneyCriteria = {
  __typename?: "FlightsJourneyCriteria";
  arrivalDate?: Maybe<Date>;
  departureDate: Date;
  destination: Scalars["String"]["output"];
  destinationAirportLocationType?: Maybe<AirportLocationType>;
  /** @deprecated Use cabinClass in FlightsSearchPreferences, replace with FlightsSearchPreferences.CabinClass */
  flightsCabinClass?: Maybe<FlightsCabinClass>;
  origin: Scalars["String"]["output"];
  originAirportLocationType?: Maybe<AirportLocationType>;
};

export interface FlightsJourneyCriteriaInput {
  arrivalDate?: InputMaybe<DateInput>;
  departureDate: DateInput;
  destination: Scalars["String"]["input"];
  destinationAirportLocationType?: InputMaybe<AirportLocationType>;
  flightsCabinClass?: InputMaybe<FlightsCabinClass>;
  origin: Scalars["String"]["input"];
  originAirportLocationType?: InputMaybe<AirportLocationType>;
}

export type FlightsJourneyCriteriaInputType = {
  __typename?: "FlightsJourneyCriteriaInputType";
  arrivalDate?: Maybe<DateInputType>;
  departureDate: DateInputType;
  destination: Scalars["String"]["output"];
  destinationAirportLocationType?: Maybe<AirportLocationType>;
  flightsCabinClass?: Maybe<FlightsCabinClass>;
  origin: Scalars["String"]["output"];
  originAirportLocationType?: Maybe<AirportLocationType>;
};

export type FlightsJourneyDetails = {
  __typename?: "FlightsJourneyDetails";
  badge?: Maybe<JourneyDetailsBadge>;
  dialog?: Maybe<FlightsDialog>;
  journeyAmenities?: Maybe<Array<FlightsIconAndLabel>>;
  /** @deprecated This field will be replaced by journeySections */
  journeyParts: Array<JourneyPart>;
  journeySections: Array<JourneySection>;
  trigger?: Maybe<FlightsExperienceDialogTrigger>;
};

export type FlightsJourneyDetailsDynamicElements = {
  __typename?: "FlightsJourneyDetailsDynamicElements";
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<FlightsAction>;
  fareChangeAction?: Maybe<FlightsAction>;
  fareName?: Maybe<Scalars["String"]["output"]>;
  fareSeatMapDynamicElement?: Maybe<FareSeatMapDynamicElement>;
  flightsFareNudgeMessage?: Maybe<FlightsIconAndLabel>;
  journeyPartsDynamicElements: Array<JourneyPartsDynamicElements>;
  message?: Maybe<Scalars["String"]["output"]>;
  seatMapTitle?: Maybe<Scalars["String"]["output"]>;
  selectedSeatDetails?: Maybe<Array<FlightsSelectedSeatDetail>>;
  /** @deprecated This field will be replaced by seatMapTitle */
  title: Scalars["String"]["output"];
  upsellNudgingFeatureEnabled: Scalars["Boolean"]["output"];
};

export type FlightsJourneyDetailsInformation = {
  __typename?: "FlightsJourneyDetailsInformation";
  content: Array<FlightsJourneyDetailsInformationSection>;
  expando: EgdsExpandoLink;
  expandoContent: Array<FlightsJourneyDetailsSection>;
};

export type FlightsJourneyDetailsInformationSection = FlightsJourneyDetails;

export type FlightsJourneyDetailsSection = {
  __typename?: "FlightsJourneyDetailsSection";
  /** @deprecated Will be replaced by journeyAmenities */
  amenities?: Maybe<Array<EgdsGraphicText>>;
  badge?: Maybe<JourneyDetailsBadge>;
  decorator?: Maybe<Array<FlightsTextSectionDecorator>>;
  details: Array<EgdsText>;
  graphic?: Maybe<UiGraphic>;
  journeyAmenities?: Maybe<Array<EgdsText>>;
};

export type FlightsJourneyFare = FlightsJourneyFareBase & {
  __typename?: "FlightsJourneyFare";
  accessibilityMessage: Scalars["String"]["output"];
  amenityHierarchyRules?: Maybe<Array<FlightsJourneyAmenitiesWithLabel>>;
  amenityRules: FlightsJourneyAmenityRules;
  badges?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation: FlightsBaggageInformation;
  cabinClass: Scalars["String"]["output"];
  cabinClassAndBookingCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  changeCancellationMessages?: Maybe<FlightsTextSection>;
  /** @deprecated This will be replaced by selectFareAction */
  chooseFareAction: FlightsAction;
  /** @deprecated This will be replaced by selectFareActionAltText */
  chooseFareActionAltText?: Maybe<Scalars["String"]["output"]>;
  clickEventAnalytics: FlightsAnalytics;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  collapsedRules: FlightsJourneyAmenities;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  expandedRules: Array<FlightsJourneyAmenitiesWithLabel>;
  fareCardIdentifier: Scalars["String"]["output"];
  fareJourneyPolicies?: Maybe<FlightsTextSection>;
  fareScrollAnalytics: FlightsAnalytics;
  flightsOfferNaturalKeys?: Maybe<FlightsOfferNaturalKeys>;
  formattedMainPrice: FlightsPhrase;
  formattedPrice: FlightsPhrase;
  highlighted: Scalars["Boolean"]["output"];
  identifier: Scalars["String"]["output"];
  journeyFareTotalPrice?: Maybe<Scalars["String"]["output"]>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  multiItemAction?: Maybe<MultiItemAction>;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  packageOfferId?: Maybe<Scalars["String"]["output"]>;
  partnerLoyaltyEarnAward?: Maybe<FlightsMarkAndLabel>;
  priceAccessibilityMessage: Scalars["String"]["output"];
  priceLockup: EgdsPriceLockup;
  /** @deprecated Replaced by badges */
  recommendation?: Maybe<FlightsBadge>;
  safetyLocationAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  selectFareAction: FlightsAction;
  selectFareActionAltText?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  selectedFareAccessibilityMessage: Scalars["String"]["output"];
  showMoreAmenitiesToggle?: Maybe<FlightsToggle>;
  /** @deprecated Replaced by showMoreAmenitiesToggle */
  showMoreToggle: FlightsToggle;
  /** @deprecated Replaced by journeyFareTotalPrice */
  totalPrice: Scalars["String"]["output"];
  upsellOfferToken: Scalars["String"]["output"];
};

export interface FlightsJourneyFareBase {
  accessibilityMessage: Scalars["String"]["output"];
  amenityHierarchyRules?: Maybe<Array<FlightsJourneyAmenitiesWithLabel>>;
  amenityRules: FlightsJourneyAmenityRules;
  badges?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation: FlightsBaggageInformation;
  cabinClass: Scalars["String"]["output"];
  cabinClassAndBookingCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  changeCancellationMessages?: Maybe<FlightsTextSection>;
  /** @deprecated This will be replaced by selectFareAction */
  chooseFareAction: FlightsAction;
  /** @deprecated This will be replaced by selectFareActionAltText */
  chooseFareActionAltText?: Maybe<Scalars["String"]["output"]>;
  clickEventAnalytics: FlightsAnalytics;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  collapsedRules: FlightsJourneyAmenities;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  expandedRules: Array<FlightsJourneyAmenitiesWithLabel>;
  fareCardIdentifier: Scalars["String"]["output"];
  fareJourneyPolicies?: Maybe<FlightsTextSection>;
  fareScrollAnalytics: FlightsAnalytics;
  flightsOfferNaturalKeys?: Maybe<FlightsOfferNaturalKeys>;
  /** @deprecated Replaced by priceLockup */
  formattedMainPrice: FlightsPhrase;
  /** @deprecated Replaced by priceLockup */
  formattedPrice: FlightsPhrase;
  highlighted: Scalars["Boolean"]["output"];
  identifier: Scalars["String"]["output"];
  /** @deprecated Replaced by priceLockup */
  journeyFareTotalPrice?: Maybe<Scalars["String"]["output"]>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  multiItemAction?: Maybe<MultiItemAction>;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  packageOfferId?: Maybe<Scalars["String"]["output"]>;
  partnerLoyaltyEarnAward?: Maybe<FlightsMarkAndLabel>;
  priceAccessibilityMessage: Scalars["String"]["output"];
  priceLockup: EgdsPriceLockup;
  /** @deprecated Replaced by badges */
  recommendation?: Maybe<FlightsBadge>;
  safetyLocationAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  selectFareAction: FlightsAction;
  selectFareActionAltText?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  selectedFareAccessibilityMessage: Scalars["String"]["output"];
  showMoreAmenitiesToggle?: Maybe<FlightsToggle>;
  /** @deprecated Replaced by showMoreAmenitiesToggle */
  showMoreToggle: FlightsToggle;
  /** @deprecated Replaced by priceLockup */
  totalPrice: Scalars["String"]["output"];
  upsellOfferToken: Scalars["String"]["output"];
}

export type FlightsJourneyHeaderInformation = {
  __typename?: "FlightsJourneyHeaderInformation";
  /** @deprecated Use JourneyHeaderInformation please */
  content: Array<FlightsInformationText>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  headerInformation: Array<EgdsText>;
  journeyHeaderInformation?: Maybe<Array<FlightsJourneyHeaderInformationContent>>;
};

export type FlightsJourneyHeaderInformationContent = {
  __typename?: "FlightsJourneyHeaderInformationContent";
  badges?: Maybe<Array<EgdsBadge>>;
  link?: Maybe<EgdsStandardLink>;
  textContent?: Maybe<Array<EgdsText>>;
};

export type FlightsJourneyHeaders = {
  __typename?: "FlightsJourneyHeaders";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  changeFlight?: Maybe<FlightsAction>;
  flightsJourneySubheading: Scalars["String"]["output"];
  /** @deprecated Use instead flightsJourneySubheadingMark - new field using the EGDS Mark instead of Image */
  flightsJourneySubheadingImage?: Maybe<Image>;
  flightsJourneySubheadingMark?: Maybe<Mark>;
  heading: Scalars["String"]["output"];
  headingAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  journeyDurationAndStopsSubheading?: Maybe<FlightsMessageAndAccessibility>;
  journeyTimeAndStopsSubheading: FlightsJourneyTimeAndStops;
  specialOfferTypeMessaging?: Maybe<EgdsMoreInfoTrigger>;
};

export type FlightsJourneyHighlights = {
  __typename?: "FlightsJourneyHighlights";
  flightsJourneyHeaders: FlightsJourneyHeaders;
  /** @deprecated This is a non-nullable field and will be replaced by flightsJourneySummaryDetail */
  flightsJourneySummary: FlightsJourneySummary;
  flightsJourneySummaryDetail?: Maybe<FlightsJourneySummary>;
};

export type FlightsJourneyInfoSection = {
  __typename?: "FlightsJourneyInfoSection";
  airlineLogo?: Maybe<MarkWrapper>;
  airlineName?: Maybe<EgdsPlainText>;
  journeyTitle?: Maybe<EgdsHeading>;
};

export type FlightsJourneyRating = {
  __typename?: "FlightsJourneyRating";
  analytics: FlightsAnalytics;
  description: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type FlightsJourneyReviewCommon = {
  __typename?: "FlightsJourneyReviewCommon";
  changeFlight: FlightsAction;
  fareSummarySkeletonCount: Scalars["Int"]["output"];
  flightSummarySkeletonCount: Scalars["Int"]["output"];
};

export type FlightsJourneySearchCriteria = {
  __typename?: "FlightsJourneySearchCriteria";
  flightsSearchContext: FlightsSearchContext;
  journeyCriteria: Array<FlightsJourneyCriteria>;
  previousFlightSelections?: Maybe<Array<JourneyFlightSelection>>;
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelerDetails: Array<FlightsTravelerDetails>;
};

export type FlightsJourneySeatAncillaryCabinFailure = {
  __typename?: "FlightsJourneySeatAncillaryCabinFailure";
  errorMessage: FlightsMessagingCardPlacard;
};

export type FlightsJourneySeatAncillaryCabinSuccess = {
  __typename?: "FlightsJourneySeatAncillaryCabinSuccess";
  journeySeatContent: FlightsJourneySeatContent;
};

export type FlightsJourneySeatCabinFailure = {
  __typename?: "FlightsJourneySeatCabinFailure";
  errorMessage: FlightsErrorMessagingPresentation;
};

export type FlightsJourneySeatContent = {
  __typename?: "FlightsJourneySeatContent";
  cabinClass?: Maybe<EgdsSpannableText>;
  cabinToastMessages?: Maybe<FlightsSeatSelectionCabinToastMessages>;
  displayAnalytics: Array<ClientSideAnalytics>;
  seatMapGridDetails: FlightsSeatMapGridDetails;
  /** @deprecated Will be sent inside FlightsSeatAncillaryDetailsLoaded */
  seatSelectionMessages: FlightsSeatSelectionNotificationMessages;
  seatsConfirmedDetails?: Maybe<Array<FlightsSeatsConfirmedDetails>>;
};

export type FlightsJourneySeatMapCabinContent =
  | FlightsJourneySeatAncillaryCabinFailure
  | FlightsJourneySeatAncillaryCabinSuccess
  | FlightsJourneySeatCabinFailure;

export interface FlightsJourneySummary {
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  heading: FlightsMessageAndAccessibility;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
}

export type FlightsJourneyTimeAndStops = {
  __typename?: "FlightsJourneyTimeAndStops";
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  durationAndStops: FlightsMessageAndAccessibility;
  flightTimes: FlightsMessageAndAccessibility;
};

export type FlightsJourneyWithDetails = {
  __typename?: "FlightsJourneyWithDetails";
  details: Array<EgdsText>;
  footnotes: Array<EgdsText>;
  header: Scalars["String"]["output"];
  subheader: Scalars["String"]["output"];
};

export type FlightsLabelAndValue = {
  __typename?: "FlightsLabelAndValue";
  accessibilityMessage: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type FlightsLegAncillarySummaryActionType = {
  __typename?: "FlightsLegAncillarySummaryActionType";
  analyticsList: Array<ClientSideAnalytics>;
  id: FlightsActionType;
};

export type FlightsLegDropdownItemSelection = FlightsClientAction & {
  __typename?: "FlightsLegDropdownItemSelection";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsLinkAction = {
  __typename?: "FlightsLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: FlightsAnalytics;
  displayAction: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type FlightsLinkTarget = "EXTERNAL" | "INTERNAL";

export type FlightsListDisplayFilterItem = FlightsFilterItem & {
  __typename?: "FlightsListDisplayFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  analytics: FlightsFilterItemAnalytics;
  defaultSelected: Scalars["Boolean"]["output"];
  deselectAnalytics: FlightsAnalytics;
  flightsSearchFilterValues: FlightsSearchFilterValues;
  formattedMultiItemStartingPrice?: Maybe<Scalars["String"]["output"]>;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selectAnalytics: FlightsAnalytics;
  selectPositionAnalytics?: Maybe<FlightsAnalytics>;
  selected: Scalars["Boolean"]["output"];
  startingPrice?: Maybe<Money>;
  value: FlightsFilterValue;
};

export type FlightsListDisplayFilterOption = FlightsFilterOption & {
  __typename?: "FlightsListDisplayFilterOption";
  items: Array<FlightsListDisplayFilterItem>;
  itemsDefaultDisplayCount: Scalars["Int"]["output"];
  label: Scalars["String"]["output"];
  priceFromLabel?: Maybe<Scalars["String"]["output"]>;
  showMoreToggle?: Maybe<FlightsToggle>;
  supportsMultipleSelection: Scalars["Boolean"]["output"];
  type: FlightsFilterType;
};

export type FlightsListing =
  | EgdsElementWrapper
  | FlightsBargainFareOffer
  | FlightsCarryOverFiltersCard
  | FlightsCouponCredit
  | FlightsCreditsAvailableListing
  | FlightsCrossSellMessage
  | FlightsCrossSellOffer
  | FlightsElementsWrapper
  | FlightsEmailSubscription
  | FlightsFeedback
  | FlightsHeaderListing
  | FlightsListingMessagingCard
  | FlightsListingOffer
  | FlightsOfferCard
  | FlightsOneKeyBannerListing
  | FlightsPriceAlertsListing
  | FlightsShoppingStandardOfferCard
  | FlightsSocialProofMessage
  | FlightsSponsoredContentAdListing
  | FlightsStandardOffer
  | FlightsSuggestedFilterMessageListing
  | FlightsSustainabilityMessage
  | InsurtechPrimingCardListing;

export type FlightsListingMessagingCard = {
  __typename?: "FlightsListingMessagingCard";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  messagingCard: FlightsMessagingCardPlacard;
  standardMessagingCard?: Maybe<EgdsStandardMessagingCard>;
};

export type FlightsListingOffer = {
  __typename?: "FlightsListingOffer";
  card: FlightsOfferCard;
  detailsAndFares: FlightsDetailsAndFaresPresentation;
};

export type FlightsListingResult = FlightsLoadedListingResult | FlightsLoadingListingResult | FlightsProgressBarDetails;

export type FlightsListingsAnalytics = {
  __typename?: "FlightsListingsAnalytics";
  amenitiesFillRateAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated best bets sort is replaced by recommended sort */
  bestBetsDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  bestSortDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  fareCalendarAnalytics?: Maybe<FlightsAnalytics>;
  fareUpsellPercentageAnalytics?: Maybe<FlightsAnalytics>;
  moreListingsDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  moreListingsErrorAnalytics?: Maybe<FlightsAnalytics>;
  moreListingsLoadTimeAnalytics?: Maybe<FlightsAnalytics>;
  nonstopSectionDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated This is not needed. Handle last offer analytics in offer node itself */
  onViewedAnalytics: FlightsAnalytics;
  onViewedAnalyticsList: Array<FlightsAnalytics>;
  pinnedListingDisplayedAnalytics?: Maybe<FlightsAnalytics>;
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  urgencyMessageAnalytics?: Maybe<FlightsAnalytics>;
};

export type FlightsListingsPagination = {
  __typename?: "FlightsListingsPagination";
  faresSeparationType: FlightsFaresSeparationType;
  pagination: Pagination;
  tripTypeFilter?: Maybe<Array<FlightsTripType>>;
};

export type FlightsListingsViewType = "MIXED" | "SINGLE";

export type FlightsLoadAncillariesAction = FlightsClientAction & {
  __typename?: "FlightsLoadAncillariesAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsLoadedListingResult = {
  __typename?: "FlightsLoadedListingResult";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  appliedFilterAccessibility?: Maybe<Scalars["String"]["output"]>;
  appliedSortingAccessibility?: Maybe<Scalars["String"]["output"]>;
  flightsListingsAnalytics: FlightsListingsAnalytics;
  listings?: Maybe<Array<FlightsListing>>;
  listingsAction?: Maybe<FlightsAction>;
  moreListingsAvailable?: Maybe<Scalars["String"]["output"]>;
  pinnedListingUnavailableMessage?: Maybe<Scalars["String"]["output"]>;
  showMoreListingLoadedAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  toasts?: Maybe<Array<EgdsToast>>;
};

export type FlightsLoadedMerchandisingDetailsCard = FlightsMerchandisingDetailsCard & {
  __typename?: "FlightsLoadedMerchandisingDetailsCard";
  confirmationDetails?: Maybe<EgdsTextContainer>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  heading: EgdsHeading;
  journeyDetails: Array<JourneyDetails>;
  priceDetails?: Maybe<PricePresentation>;
  subHeading: EgdsPlainText;
};

export type FlightsLoadedMerchandisingPagePresentation = FlightsMerchandisingPagePresentation & {
  __typename?: "FlightsLoadedMerchandisingPagePresentation";
  backButtonAction?: Maybe<FlightsExperienceActionLink>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  header: FlightsMessaging;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
};

export type FlightsLoadedPriceSummary = FlightsPriceSummary & {
  __typename?: "FlightsLoadedPriceSummary";
  bannerMessage?: Maybe<PricePresentationLineItem>;
  bookWithAirlinesDialog?: Maybe<BookWithAirlinesDialog>;
  breakdown: Array<FlightsPriceSummaryGroup>;
  buttonAction: FlightsAction;
  /** @deprecated No longer in use, to be cleaned up */
  creditShoppingRedirectAction?: Maybe<FlightsAction>;
  /** @deprecated No longer in use, to be cleaned up */
  creditShoppingRedirectMessaging?: Maybe<Scalars["String"]["output"]>;
  disclaimers: Array<Scalars["String"]["output"]>;
  dismiss: FlightsActionableIcon;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  flightsAncillaryAlertDialogList?: Maybe<Array<FlightsDialog>>;
  /** @deprecated New implementation on Shared-UI requires new type, replace with flightsAncillaryAlertDialogList */
  flightsAncillaryAlertDialogs?: Maybe<Array<FlightsAncillaryAlertDialog>>;
  memberSignInDialog?: Maybe<MemberSignInDialog>;
  policies: FlightsTextSection;
  pricePresentation?: Maybe<PricePresentation>;
  responseMetric?: Maybe<FlightsResponseTimeMetrics>;
  selectedAncillaries?: Maybe<Array<FlightsAncillaryType>>;
  signInMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  stackedFooter?: Maybe<FlightsStepperStackedFooter>;
  stepperAction?: Maybe<FlightsExperienceActionButton>;
  stepperDialog?: Maybe<FlightsDialog>;
  stepperErrorMessages?: Maybe<Array<FlightsPriceSummaryStepperErrorMessages>>;
  title: Scalars["String"]["output"];
  totals?: Maybe<Array<FlightsPriceSummaryGroup>>;
  tripTotalDetails: FlightsPriceSummaryTripTotalItem;
  tripTotalLabel: Scalars["String"]["output"];
  urgencyMessage?: Maybe<EgdsBadge>;
  viewPriceSummary: FlightsAction;
};

export type FlightsLoadedSearchForm = FlightsSearchForm & {
  __typename?: "FlightsLoadedSearchForm";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  addJourneyAction?: Maybe<FlightsAction>;
  airportDropdownEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  appliedFilterCount?: Maybe<Scalars["Int"]["output"]>;
  availableRoutes?: Maybe<AvailableRoutes>;
  bookingChangeLink?: Maybe<FlightsResourceLinkAction>;
  closeActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  closeFormAction?: Maybe<FlightsAction>;
  collapseFormAnalytics: FlightsAnalytics;
  collapsedFormAccessibility?: Maybe<FlightsResponsiveAccessibilityMessage>;
  departureAndReturnDates?: Maybe<FlightsResponsiveMessage>;
  errorSummary: FlightsErrorSummary;
  expandFilterAction: FlightsIconAndLabel;
  expandFormAnalytics: FlightsAnalytics;
  expandSearchFormAction: Icon;
  journeys: Array<FlightsSearchFormJourneyCriteria>;
  /** @deprecated This field is now deprecated */
  mDestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  newJourneyTemplate: FlightsSearchFormJourneyCriteria;
  originAndDestinationLocations?: Maybe<Scalars["String"]["output"]>;
  searchAction?: Maybe<Scalars["String"]["output"]>;
  searchActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  searchActionActive: Scalars["Boolean"]["output"];
  searchActionAnalytics: FlightsAnalytics;
  searchOptions: Array<FlightsSearchFormOption>;
  travelersSummary: FlightsMultiPluralLocalizationTemplate;
};

export type FlightsLoadedSortAndFilterResult = FlightsSortAndFilterResult & {
  __typename?: "FlightsLoadedSortAndFilterResult";
  appliedFiltersSummary?: Maybe<FlightsAppliedFiltersSummary>;
  applyAction: FlightsSortAndFilterAction;
  clearAllSelectionLabel: FlightsActionableLabel;
  clearSelectionLabel: FlightsActionableLabel;
  dismiss: Icon;
  dismissAccessibility?: Maybe<Scalars["String"]["output"]>;
  dismissAnalytics: FlightsAnalytics;
  filterAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
  filterPresentation?: Maybe<FlightsFilterLoadedPresentation>;
  revealAction: FlightsSortAndFilterRevealAction;
  revealActionAnalytics: FlightsAnalytics;
  sortPresentation: FlightsSortLoadedPresentation;
  title: Scalars["String"]["output"];
};

export type FlightsLoadingListingResult = {
  __typename?: "FlightsLoadingListingResult";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  detailsConfig?: Maybe<FlightDetailsConfig>;
  flightsPlacard?: Maybe<FlightsPlacard>;
  image: Image;
  listingsSkeletonCount: Scalars["Int"]["output"];
  message: Scalars["String"]["output"];
  originDestination: Scalars["String"]["output"];
  paginationConfig?: Maybe<Array<FlightsListingsPagination>>;
  preFilterWrapper?: Maybe<FiltersWrapper>;
  /** @deprecated We don't need it now since we have created the Progress Details which will be null when not needed */
  progressBar: FlightsProgressBarDisplay;
  progressBarDetails?: Maybe<FlightsProgressBarDetails>;
  toast?: Maybe<EgdsToast>;
};

export type FlightsLoadingMerchandisingDetailsCard = FlightsMerchandisingDetailsCard & {
  __typename?: "FlightsLoadingMerchandisingDetailsCard";
  heading: EgdsHeading;
};

export type FlightsLoadingMerchandisingPagePresentation = FlightsMerchandisingPagePresentation & {
  __typename?: "FlightsLoadingMerchandisingPagePresentation";
  action?: Maybe<FlightsClientAction>;
  availableMerchandise: Array<FlightsAncillaryType>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
};

export type FlightsLoadingPriceSummary = FlightsPriceSummary & {
  __typename?: "FlightsLoadingPriceSummary";
  buttonAction: FlightsAction;
  errorMessage?: Maybe<FlightsErrorMessagingPresentation>;
  skeletonCount: Scalars["Int"]["output"];
  title: Scalars["String"]["output"];
  tripTotalLabel: Scalars["String"]["output"];
};

export type FlightsLoadingSearchForm = FlightsSearchForm & {
  __typename?: "FlightsLoadingSearchForm";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  addJourneyAction?: Maybe<FlightsAction>;
  airportDropdownEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  appliedFilterCount?: Maybe<Scalars["Int"]["output"]>;
  availableRoutes?: Maybe<AvailableRoutes>;
  bookingChangeLink?: Maybe<FlightsResourceLinkAction>;
  closeActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  closeFormAction?: Maybe<FlightsAction>;
  collapseFormAnalytics: FlightsAnalytics;
  collapsedFormAccessibility?: Maybe<FlightsResponsiveAccessibilityMessage>;
  departureAndReturnDates?: Maybe<FlightsResponsiveMessage>;
  errorSummary: FlightsErrorSummary;
  expandFilterAction: FlightsIconAndLabel;
  expandFormAnalytics: FlightsAnalytics;
  expandSearchFormAction: Icon;
  journeys: Array<FlightsSearchFormJourneyCriteria>;
  /** @deprecated This field is now deprecated */
  mDestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  newJourneyTemplate: FlightsSearchFormJourneyCriteria;
  originAndDestinationLocations?: Maybe<Scalars["String"]["output"]>;
  searchAction?: Maybe<Scalars["String"]["output"]>;
  searchActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  searchActionActive: Scalars["Boolean"]["output"];
  searchOptions: Array<FlightsSearchFormOption>;
  travelersSummary: FlightsMultiPluralLocalizationTemplate;
};

export type FlightsLoadingSortAndFilterResult = FlightsSortAndFilterResult & {
  __typename?: "FlightsLoadingSortAndFilterResult";
  filterPresentation: FlightsFilterLoadingPresentation;
  revealAction: FlightsSortAndFilterRevealAction;
  sortPresentation: FlightsSortLoadingPresentation;
  title: Scalars["String"]["output"];
};

export interface FlightsLocalizationTemplate {
  initialValue?: Maybe<Scalars["String"]["output"]>;
  tokens: Array<Scalars["String"]["output"]>;
}

export type FlightsLoyaltyPointsOption = {
  __typename?: "FlightsLoyaltyPointsOption";
  accessibility: Scalars["String"]["output"];
  formattedPoints: Scalars["String"]["output"];
  isPointsFirst: Scalars["Boolean"]["output"];
  isStrikeThroughFirst: Scalars["Boolean"]["output"];
  leadingCaption: Scalars["String"]["output"];
  overageAmount?: Maybe<Scalars["String"]["output"]>;
  strikeThroughFormattedPoints?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsMarkAndLabel = {
  __typename?: "FlightsMarkAndLabel";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  mark?: Maybe<Mark>;
  stylisedText?: Maybe<EgdsSpannableText>;
  subLabel?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsMarkPhrases = FlightsText & {
  __typename?: "FlightsMarkPhrases";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<Mark>;
  text: Array<FlightsPhrase>;
};

export type FlightsMarkPlacard = FlightsPlacard & {
  __typename?: "FlightsMarkPlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<Mark>;
  message: Scalars["String"]["output"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated Use onViewedAnalyticsList instead */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList: Array<ClientSideAnalytics>;
  stylisedText?: Maybe<EgdsSpannableText>;
};

export type FlightsMaximumAmenitiesCount = {
  __typename?: "FlightsMaximumAmenitiesCount";
  selectedFareAmenitiesMaxCount: Scalars["Int"]["output"];
  upgradeFareAmenitiesMaxCount: Scalars["Int"]["output"];
};

export type FlightsMerchandisingAvailCheckFailureResponse = FlightsMerchandisingAvailCheckResponse & {
  __typename?: "FlightsMerchandisingAvailCheckFailureResponse";
  action: FlightsClientAction;
  content?: Maybe<FlightsMerchandisingDialogAndSheet>;
  dynamicId?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsMerchandisingAvailCheckResponse {
  action: FlightsClientAction;
}

export type FlightsMerchandisingAvailCheckSuccessResponse = FlightsMerchandisingAvailCheckResponse & {
  __typename?: "FlightsMerchandisingAvailCheckSuccessResponse";
  action: FlightsClientAction;
  loadingDialog?: Maybe<FlightsDialog>;
};

export type FlightsMerchandisingBookAction = FlightsClientAction & {
  __typename?: "FlightsMerchandisingBookAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsMerchandisingBookFailureResponse = FlightsMerchandisingBookResponse & {
  __typename?: "FlightsMerchandisingBookFailureResponse";
  action: FlightsClientAction;
  dynamicId?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsMerchandisingBookResponse {
  action: FlightsClientAction;
}

export type FlightsMerchandisingBookSuccessResponse = FlightsMerchandisingBookResponse & {
  __typename?: "FlightsMerchandisingBookSuccessResponse";
  action: FlightsClientAction;
};

export interface FlightsMerchandisingCheckoutPagePresentation {
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
}

export type FlightsMerchandisingCheckoutResponse = {
  __typename?: "FlightsMerchandisingCheckoutResponse";
  actionButton: FlightsExperienceActionButton;
  expando?: Maybe<FlightsExpando>;
  loadingDialog: FlightsDialog;
  messagingCard: EgdsTextSection;
  pagePresentation?: Maybe<FlightsMerchandisingCheckoutPagePresentation>;
  payment: FlightsPayment;
  placards?: Maybe<Array<FlightsUiPlacard>>;
};

export interface FlightsMerchandisingConfirmationPagePresentation {
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
}

export type FlightsMerchandisingConfirmationResponse = {
  __typename?: "FlightsMerchandisingConfirmationResponse";
  actionButton: FlightsExperienceActionButton;
  confirmationCard?: Maybe<FlightsUiPlacard>;
  pagePresentation?: Maybe<FlightsMerchandisingConfirmationPagePresentation>;
  placards?: Maybe<Array<FlightsUiPlacard>>;
  priceDetails?: Maybe<PricePresentation>;
};

export interface FlightsMerchandisingDetailsCard {
  heading?: Maybe<EgdsHeading>;
}

export type FlightsMerchandisingDialogAndSheet = {
  __typename?: "FlightsMerchandisingDialogAndSheet";
  dialog?: Maybe<FlightsDialog>;
  dialogId: FlightsDialogId;
  sheet: FlightsMerchandisingSheet;
};

export type FlightsMerchandisingLoadedCheckoutPagePresentation = FlightsMerchandisingCheckoutPagePresentation & {
  __typename?: "FlightsMerchandisingLoadedCheckoutPagePresentation";
  /** @deprecated moved this outside of PagePresentation */
  buttonAction: FlightsAction;
  errors?: Maybe<Array<FlightsUiPlacard>>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export type FlightsMerchandisingLoadedConfirmationPagePresentation = FlightsMerchandisingConfirmationPagePresentation & {
  __typename?: "FlightsMerchandisingLoadedConfirmationPagePresentation";
  /** @deprecated moved this outside of PagePresentation */
  buttonAction: FlightsAction;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export type FlightsMerchandisingLoadingCheckoutPagePresentation = FlightsMerchandisingCheckoutPagePresentation & {
  __typename?: "FlightsMerchandisingLoadingCheckoutPagePresentation";
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export type FlightsMerchandisingLoadingConfirmationPagePresentation = FlightsMerchandisingConfirmationPagePresentation & {
  __typename?: "FlightsMerchandisingLoadingConfirmationPagePresentation";
  action?: Maybe<FlightsClientAction>;
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  title: FlightsMessaging;
};

export interface FlightsMerchandisingPagePresentation {
  flightsMetadata?: Maybe<FlightsMetadata>;
  serverErrorMessaging?: Maybe<FlightsErrorMessagingPresentation>;
}

export type FlightsMerchandisingResponse = {
  __typename?: "FlightsMerchandisingResponse";
  checkAvailability: FlightsMerchandisingAvailCheckResponse;
  messagingCards?: Maybe<Array<FlightsMessagingCardPlacard>>;
  pagePresentation?: Maybe<FlightsMerchandisingPagePresentation>;
  placards?: Maybe<Array<FlightsUiPlacard>>;
  priceSummary?: Maybe<FlightsPriceSummary>;
  title?: Maybe<FlightsMessaging>;
};

export type FlightsMerchandisingSheet = {
  __typename?: "FlightsMerchandisingSheet";
  content: FlightsMerchandisingSheetContent;
  initialState?: Maybe<FlightsDialogAction>;
  sheet: EgdsSheet;
};

export type FlightsMerchandisingSheetContent = {
  __typename?: "FlightsMerchandisingSheetContent";
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  primaryButton: FlightsExperienceActionButton;
  tertiaryButton?: Maybe<FlightsExperienceActionButton>;
};

export type FlightsMessageAndAccessibility = {
  __typename?: "FlightsMessageAndAccessibility";
  accessibilityMessage: Scalars["String"]["output"];
  size?: Maybe<Scalars["Int"]["output"]>;
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type FlightsMessaging = {
  __typename?: "FlightsMessaging";
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsMessagingCard = {
  __typename?: "FlightsMessagingCard";
  border: Scalars["Boolean"]["output"];
  cardContent: EgdsStandardMessagingCard;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsMessagingCardPlacard = FlightsUiPlacard & {
  __typename?: "FlightsMessagingCardPlacard";
  actionElement?: Maybe<FlightsClientActionElement>;
  /** @deprecated This field is now deprecated because it is becoming so overloaded., replace with FlightsActionButton.FlightsClientAction */
  actions?: Maybe<Array<FlightsAction>>;
  /** @deprecated Please from now on use FlightsClientActionElement for button if present */
  button?: Maybe<FlightsActionButton>;
  displayType: FlightsPlacardDisplayType;
  messagingCard: UiMessagingCard;
  /** @deprecated Use onViewedAnalyticsList for future use. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsMetadata = {
  __typename?: "FlightsMetadata";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  messaging?: Maybe<FlightsMessaging>;
  pageAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  pageName?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsMetricName =
  | "DETAILS_JOURNEY_SUMMARY_SUPPLY_RESPONSE_TIME"
  | "DETAILS_PRICE_SUMMARY_SUPPLY_RESPONSE_TIME"
  | "LISTINGS_SUPPLY_RESPONSE_TIME";

export type FlightsMoreInfoLink = {
  __typename?: "FlightsMoreInfoLink";
  action: FlightsClientAction;
  dialog?: Maybe<FlightsDialog>;
  icon: Icon;
  sheet?: Maybe<FlightsSheet>;
  text: EgdsText;
};

export type FlightsMultiPluralLocalizationTemplate = FlightsLocalizationTemplate & {
  __typename?: "FlightsMultiPluralLocalizationTemplate";
  fewTemplate: Scalars["String"]["output"];
  initialValue?: Maybe<Scalars["String"]["output"]>;
  manyTemplate: Scalars["String"]["output"];
  oneTemplate: Scalars["String"]["output"];
  otherTemplate: Scalars["String"]["output"];
  tokens: Array<Scalars["String"]["output"]>;
  twoTemplate: Scalars["String"]["output"];
  zeroTemplate: Scalars["String"]["output"];
};

export type FlightsNavigateToDetails = FlightsClientAction & {
  __typename?: "FlightsNavigateToDetails";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogId: Scalars["String"]["output"];
  jcid?: Maybe<Scalars["String"]["output"]>;
  nextPage: Uri;
  obid?: Maybe<Scalars["String"]["output"]>;
  packagesUrl?: Maybe<Uri>;
  stepIndicatorJcid?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsNextJourneyCriteria = FlightsNextJourneyCriteriaLink | FlightsNextJourneyCriteriaValues;

export type FlightsNextJourneyCriteriaLink = {
  __typename?: "FlightsNextJourneyCriteriaLink";
  link: Scalars["URL"]["output"];
};

export type FlightsNextJourneyCriteriaValues = {
  __typename?: "FlightsNextJourneyCriteriaValues";
  journeyCriteria: Array<Maybe<FlightsJourneyCriteria>>;
  searchPreferences: FlightSearchPreferences;
};

export type FlightsNoCancelFeeBasedFilterValue = {
  __typename?: "FlightsNoCancelFeeBasedFilterValue";
  noCancelFee: NoCancelFeeType;
};

export interface FlightsNoCancelFeeBasedFilterValueInput {
  noCancelFee: NoCancelFeeType;
}

export type FlightsNoChangeFeeBasedFilterValue = {
  __typename?: "FlightsNoChangeFeeBasedFilterValue";
  noChangeFee: NoChangeFeeType;
};

export interface FlightsNoChangeFeeBasedFilterValueInput {
  noChangeFee: NoChangeFeeType;
}

export type FlightsNoFlightsFoundMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsNoFlightsFoundMessaging";
  action?: Maybe<Array<FlightsExperienceActionButton>>;
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type FlightsNoFlightsFoundWithFilterMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsNoFlightsFoundWithFilterMessaging";
  /** @deprecated Conflicting with action in FlightsActionableErrorMessaging. Replaced with clearFiltersAction */
  action?: Maybe<FlightsAction>;
  clearFiltersAction?: Maybe<FlightsAction>;
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface FlightsOffer {
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  journeys: Array<FlightsOfferJourney>;
  onClickAnalyticsList: Array<FlightsAnalytics>;
  selectOfferAction: FlightsAction;
  stepIndicatorJcid?: Maybe<Scalars["String"]["output"]>;
}

export type FlightsOfferAnalytics = {
  __typename?: "FlightsOfferAnalytics";
  amenitiesInfoNotAvailableAnalytics?: Maybe<FlightsAnalytics>;
  onCollapseAnalytics: Array<ClientSideAnalytics>;
  onExpandAnalytics: Array<ClientSideAnalytics>;
  /** @deprecated This field is replaced by the onViewedAnalyticsList */
  onViewedAnalytics: FlightsAnalytics;
  onViewedAnalyticsList: Array<FlightsAnalytics>;
};

export type FlightsOfferBaggageFeesInformation = {
  __typename?: "FlightsOfferBaggageFeesInformation";
  analytics: FlightsAnalytics;
  icon: Icon;
  label: Scalars["String"]["output"];
  link?: Maybe<Scalars["URL"]["output"]>;
};

export type FlightsOfferCard = {
  __typename?: "FlightsOfferCard";
  additionalContentSections?: Maybe<Array<EgdsElement>>;
  clickAction?: Maybe<FlightsClientAction>;
  content: FlightsOfferContent;
  featuredHeader?: Maybe<FlightsOfferFeaturedHeader>;
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
  selected: Scalars["Boolean"]["output"];
};

export type FlightsOfferContent = {
  __typename?: "FlightsOfferContent";
  badges?: Maybe<Array<EgdsStandardBadge>>;
  priceSection: FlightsOfferPriceSection;
  primarySection: EgdsTextSection;
  secondarySection?: Maybe<EgdsTextSection>;
  tertiarySection?: Maybe<EgdsTextSection>;
};

export type FlightsOfferFareRulesPresentation = {
  __typename?: "FlightsOfferFareRulesPresentation";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  displayAction: FlightsToggle;
  fareTrayMessage?: Maybe<FlightsResponsiveMessage>;
  fareTypes: Array<FlightsOfferFareType>;
};

export type FlightsOfferFareType = {
  __typename?: "FlightsOfferFareType";
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["String"]["output"]>;
  rules: Array<FlightsFareRule>;
  selectFareAction: FlightsAction;
};

export type FlightsOfferFeaturedHeader = {
  __typename?: "FlightsOfferFeaturedHeader";
  heading: EgdsHeading;
  icon?: Maybe<Icon>;
};

export type FlightsOfferJourney = {
  __typename?: "FlightsOfferJourney";
  adjustedStayDatesMessage?: Maybe<FlightsIconPhrases>;
  airlineList: Array<EgdsGraphicText>;
  /** @deprecated deprecated in favor of airlineList */
  airlines: Array<FlightsImageAndLabel>;
  cabinClass: Scalars["String"]["output"];
  departureAndArrivalLocations: Scalars["String"]["output"];
  departureAndArrivalTime: FlightsPhrase;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  dialogSheet?: Maybe<FlightsDialogSheet>;
  differentDayArrival?: Maybe<Scalars["String"]["output"]>;
  differentDayArrivalAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  displayAction: Icon;
  durationAndStops: Scalars["String"]["output"];
  flightOperatedBy?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Please use detailsAndFares instead as this node is not nullable */
  flightsDetailsAndFaresPresentation: FlightsDetailsAndFaresPresentation;
  /** @deprecated Replaced with detailsAndFares */
  flightsJourneyAvailableFaresInformation?: Maybe<FlightsJourneyAvailableFares>;
  highlightRelevantFaresAnalytics?: Maybe<Array<FlightsAnalytics>>;
  hygieneAmenitiesMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This node will be moved to FlightsJourneyAvailableFares */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  journeyAmenitiesNotAvailableAnalytics?: Maybe<FlightsAnalytics>;
  label: Scalars["String"]["output"];
  layoverInformation?: Maybe<Scalars["String"]["output"]>;
  positiveHighlightsMessage?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<FlightsJourneyRating>;
  restrictiveAmenityIcons?: Maybe<Array<Icon>>;
  /** @deprecated Not in usage */
  stringifiedFlightsJourneyAvailableFaresInformation?: Maybe<Scalars["String"]["output"]>;
  trigger?: Maybe<FlightsExperienceDialogTrigger>;
  urgencyAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  urgencyMessage?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsOfferNaturalKeys = {
  __typename?: "FlightsOfferNaturalKeys";
  flightNaturalKey: FlightNaturalKey;
  packagedProductsNaturalKeys?: Maybe<Array<ProductNaturalKey>>;
};

export type FlightsOfferPriceSection = {
  __typename?: "FlightsOfferPriceSection";
  priceLockup: EgdsPriceLockup;
  primaryText?: Maybe<Array<EgdsStylizedText>>;
  subTexts?: Maybe<Array<EgdsText>>;
};

export type FlightsOfferSectionHeading = EgdsSectionHeading & {
  __typename?: "FlightsOfferSectionHeading";
  additionalIcon?: Maybe<Icon>;
  heading: EgdsHeading;
  mark?: Maybe<Mark>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsOfferSponsoredAirline = {
  __typename?: "FlightsOfferSponsoredAirline";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  adUnitID?: Maybe<Scalars["String"]["output"]>;
  advertiserID?: Maybe<Scalars["String"]["output"]>;
  airlineCode?: Maybe<Scalars["String"]["output"]>;
  airlineImage?: Maybe<Image>;
  airlineName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated replaced with sponsoredBeacons */
  clickTrackingURL?: Maybe<Scalars["String"]["output"]>;
  creativeID?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated replaced with sponsoredBeacons */
  delayedImpressionURL?: Maybe<Scalars["String"]["output"]>;
  lineItemID?: Maybe<Scalars["String"]["output"]>;
  orderID?: Maybe<Scalars["String"]["output"]>;
  sponsoredBeacons?: Maybe<SponsoredTrackingBeacons>;
  sponsoredHeader?: Maybe<Scalars["String"]["output"]>;
  sponsoredText?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsOfferSponsoredUpsell = {
  __typename?: "FlightsOfferSponsoredUpsell";
  action: FlightsDialogTrigger;
  badge: EgdsStandardBadge;
  beacons?: Maybe<SponsoredTrackingBeacons>;
  details: FlightsSponsoredFareInformation;
  expando: EgdsExpando;
  onViewedAnalytics: ClientSideAnalytics;
  sponsoredFareAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsOfferTag = {
  __typename?: "FlightsOfferTag";
  image: Image;
  message: Scalars["String"]["output"];
};

export interface FlightsOnLoadError {
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
}

export type FlightsOneClickFareLastSelectedTokens = {
  __typename?: "FlightsOneClickFareLastSelectedTokens";
  lastChangedOfferToken?: Maybe<Scalars["String"]["output"]>;
  lastSelectedOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsOneClickFareLastSelectedTokensInput {
  lastChangedOfferToken?: InputMaybe<Scalars["String"]["input"]>;
  lastSelectedOfferToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightsOneClickFareUpgradeCard = {
  __typename?: "FlightsOneClickFareUpgradeCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  cardStatus?: Maybe<CardStatus>;
  fareScrollIndex?: Maybe<Scalars["Int"]["output"]>;
  journeyInfoSection?: Maybe<FlightsJourneyInfoSection>;
  /** @deprecated Deprecating this node as we don't need this node */
  selectedFareHeading?: Maybe<EgdsHeading>;
  selectedFareSection?: Maybe<FlightsSelectedFareSection>;
  upgradeFareSection?: Maybe<FlightsUpgradeFareSection>;
};

export type FlightsOneClickFareUpgradeCardLoaded = FlightsOneClickFareUpgradeCardPresentation & {
  __typename?: "FlightsOneClickFareUpgradeCardLoaded";
  actionToast?: Maybe<EgdsToast>;
  /** @deprecated Deprecating this node as we need this every card and hence shifted this in FlightsOneClickFareUpgradeCard */
  cardStatus?: Maybe<CardStatus>;
  flightsMaximumAmenitiesCount?: Maybe<FlightsMaximumAmenitiesCount>;
  lastSelectedOfferTokensInformation?: Maybe<FlightsOneClickFareLastSelectedTokens>;
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
  scrollIndex: Scalars["Int"]["output"];
  /** @deprecated Deprecating this node as we don't need this node */
  selectedFareHeading?: Maybe<EgdsHeading>;
  /** @deprecated Moving tokens to FlightsOneClickFareUpgradeTokens node */
  selectedOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsOneClickFareUpgradeCardLoading = FlightsOneClickFareUpgradeCardPresentation & {
  __typename?: "FlightsOneClickFareUpgradeCardLoading";
  actionToast?: Maybe<EgdsToast>;
  lastSelectedOfferTokensInformation?: Maybe<FlightsOneClickFareLastSelectedTokens>;
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
  scrollIndex: Scalars["Int"]["output"];
  /** @deprecated Moving tokens to FlightsOneClickFareUpgradeTokens node */
  selectedOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export interface FlightsOneClickFareUpgradeCardPresentation {
  actionToast?: Maybe<EgdsToast>;
  lastSelectedOfferTokensInformation?: Maybe<FlightsOneClickFareLastSelectedTokens>;
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
  scrollIndex: Scalars["Int"]["output"];
  /** @deprecated Moving tokens to FlightsOneClickFareUpgradeTokens node */
  selectedOfferToken?: Maybe<Scalars["String"]["output"]>;
}

export type FlightsOneClickFareUpgradeCardType = "OCU" | "REFUNDABILITY";

export type FlightsOneClickRefundabilityFareUpgradeCardLoaded = FlightsOneClickFareUpgradeCardPresentation & {
  __typename?: "FlightsOneClickRefundabilityFareUpgradeCardLoaded";
  actionToast?: Maybe<EgdsToast>;
  lastSelectedOfferTokensInformation?: Maybe<FlightsOneClickFareLastSelectedTokens>;
  oneClickFareUpgrade?: Maybe<EgdsCarousel>;
  oneClickFareUpgradeCard?: Maybe<Array<FlightsOneClickFareUpgradeCard>>;
  scrollIndex: Scalars["Int"]["output"];
  /** @deprecated Moving tokens to FlightsOneClickFareUpgradeTokens node */
  selectedOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsOneKeyBannerListing = {
  __typename?: "FlightsOneKeyBannerListing";
  _noop?: Maybe<Scalars["Boolean"]["output"]>;
};

export type FlightsOpinionLab = {
  __typename?: "FlightsOpinionLab";
  analytics: FlightsAnalytics;
  iconAndLabel: FlightsIconAndLabel;
  link: Scalars["URL"]["output"];
};

export type FlightsPmpJourneyFare = FlightsJourneyFareBase & {
  __typename?: "FlightsPMPJourneyFare";
  accessibilityMessage: Scalars["String"]["output"];
  amenityHierarchyRules?: Maybe<Array<FlightsJourneyAmenitiesWithLabel>>;
  amenityRules: FlightsJourneyAmenityRules;
  badges?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation: FlightsBaggageInformation;
  cabinClass: Scalars["String"]["output"];
  cabinClassAndBookingCodes?: Maybe<Array<Scalars["String"]["output"]>>;
  changeCancellationMessages?: Maybe<FlightsTextSection>;
  /** @deprecated This will be replaced by selectFareAction */
  chooseFareAction: FlightsAction;
  /** @deprecated This will be replaced by selectFareActionAltText */
  chooseFareActionAltText?: Maybe<Scalars["String"]["output"]>;
  clickEventAnalytics: FlightsAnalytics;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  collapsedRules: FlightsJourneyAmenities;
  /** @deprecated Replaced by amenityRules to have UITK expando peek on client side */
  expandedRules: Array<FlightsJourneyAmenitiesWithLabel>;
  fareCardIdentifier: Scalars["String"]["output"];
  fareJourneyPolicies?: Maybe<FlightsTextSection>;
  fareScrollAnalytics: FlightsAnalytics;
  flightsOfferNaturalKeys?: Maybe<FlightsOfferNaturalKeys>;
  formattedMainPrice: FlightsPhrase;
  formattedPrice: FlightsPhrase;
  highlighted: Scalars["Boolean"]["output"];
  identifier: Scalars["String"]["output"];
  isPmpSelected: Scalars["Boolean"]["output"];
  journeyFareTotalPrice?: Maybe<Scalars["String"]["output"]>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  multiItemAction?: Maybe<MultiItemAction>;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  packageOfferId?: Maybe<Scalars["String"]["output"]>;
  partnerLoyaltyEarnAward?: Maybe<FlightsMarkAndLabel>;
  priceAccessibilityMessage: Scalars["String"]["output"];
  priceLockup: EgdsPriceLockup;
  priceLockupWithPMP: EgdsPriceLockup;
  priceMatchPromiseSelectedCardSection?: Maybe<PriceMatchPromiseSelectedCardSection>;
  priceMatchPromiseSelectionCardSection?: Maybe<PriceMatchPromiseSelectionCardSection>;
  priceMatchPromiseToggleCard?: Maybe<PriceMatchPromiseToggleableCard>;
  /** @deprecated Replaced by badges */
  recommendation?: Maybe<FlightsBadge>;
  safetyLocationAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  selectFareAction: FlightsAction;
  selectFareActionAltText?: Maybe<Scalars["String"]["output"]>;
  selectFareActionWithPMP: FlightsAction;
  selected: Scalars["Boolean"]["output"];
  selectedFareAccessibilityMessage: Scalars["String"]["output"];
  showMoreAmenitiesToggle?: Maybe<FlightsToggle>;
  /** @deprecated Replaced by showMoreAmenitiesToggle */
  showMoreToggle: FlightsToggle;
  /** @deprecated Replaced by journeyFareTotalPrice */
  totalPrice: Scalars["String"]["output"];
  upsellOfferToken: Scalars["String"]["output"];
};

export type FlightsPwaMetadata = {
  __typename?: "FlightsPWAMetadata";
  evaluatedExperiments?: Maybe<Array<Exposure>>;
  pageAnalytics?: Maybe<ClientSideAnalytics>;
  pageName: Scalars["String"]["output"];
  /** @deprecated This will be replaced with pageAnalytics */
  pageNameAnalytics: FlightsAnalytics;
  responseMetrics?: Maybe<Array<FlightsResponseTimeMetrics>>;
  responseTags?: Maybe<Array<ResponseTagType>>;
};

export type FlightsPageTitle = {
  __typename?: "FlightsPageTitle";
  date?: Maybe<FlightsResponsiveMessage>;
  disclaimerSubTitle?: Maybe<FlightsPhrase>;
  pageAccessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field is now deprecated., replace with disclaimerSubTitle */
  subTitle?: Maybe<FlightsResponsiveMessage>;
  title: FlightsResponsiveMessage;
};

export type FlightsPayment = {
  __typename?: "FlightsPayment";
  onAbsentPaymentAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  paymentPresentation?: Maybe<FlightsPaymentPresentation>;
};

export type FlightsPaymentPresentation = {
  __typename?: "FlightsPaymentPresentation";
  heading: Scalars["String"]["output"];
  iconMessages?: Maybe<Array<EgdsIconText>>;
  initializeConfig: Scalars["String"]["output"];
  onAvailablePaymentAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  onViewedPaymentAnalytics?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsPhrase = {
  __typename?: "FlightsPhrase";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  completeText: Scalars["String"]["output"];
  delimeter?: Maybe<Scalars["String"]["output"]>;
  items: Array<FlightsPhrasePart>;
  size?: Maybe<Scalars["Int"]["output"]>;
  theme?: Maybe<TextTheme>;
};

export type FlightsPhraseDialogNode = FlightsPhrasePart & {
  __typename?: "FlightsPhraseDialogNode";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionableIcon?: Maybe<Icon>;
  analytics: FlightsAnalytics;
  dialog: FlightsDialogPresentation;
  /** @deprecated This field will be replaced by actionableIcon */
  icon?: Maybe<Scalars["String"]["output"]>;
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"]["output"];
};

export type FlightsPhraseLinkNode = FlightsPhrasePart & {
  __typename?: "FlightsPhraseLinkNode";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: FlightsAnalytics;
  displayAnalytics?: Maybe<FlightsAnalytics>;
  icon?: Maybe<Scalars["String"]["output"]>;
  link: Scalars["URL"]["output"];
  scrollAnalytics?: Maybe<FlightsAnalytics>;
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"]["output"];
};

export interface FlightsPhrasePart {
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"]["output"];
}

export type FlightsPhrasePartStyle = "EMPHASIS" | "IMPORTANT" | "STANDARD";

export type FlightsPhraseTextNode = FlightsPhrasePart & {
  __typename?: "FlightsPhraseTextNode";
  styles: Array<FlightsPhrasePartStyle>;
  text: Scalars["String"]["output"];
};

export interface FlightsPlacard {
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated Use onViewedAnalyticsList instead */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList: Array<ClientSideAnalytics>;
}

export type FlightsPlacardDisplayType = "IMMEDIATE" | "SORT_FILTERS_SERVER_ERROR";

export type FlightsPlacardResponse = {
  __typename?: "FlightsPlacardResponse";
  placards?: Maybe<Array<FlightsUiPlacard>>;
};

export type FlightsPlacardTheme = "HIGHLIGHT" | "PRIMARY" | "SECONDARY" | "STANDARD";

export type FlightsPlacardType =
  | "FREE_CANCELLATION"
  | "HOTEL_ATTACH"
  | "LOYALTY_REWARD"
  | "NO_CHANGE_FEES"
  | "PASSPORT"
  | "PRICE_CHANGE"
  | "SPLIT_TICKET";

export type FlightsPreferredAirlineFilterValue = {
  __typename?: "FlightsPreferredAirlineFilterValue";
  carrierCode: Scalars["String"]["output"];
};

export interface FlightsPreferredAirlineFilterValueInput {
  carrierCode: Scalars["String"]["input"];
}

export type FlightsPriceAlertsListing = {
  __typename?: "FlightsPriceAlertsListing";
  priceAlerts: FlightsIconPlacard;
};

export type FlightsPriceAlertsOptInResponse = {
  __typename?: "FlightsPriceAlertsOptInResponse";
  optInStatus: Scalars["Boolean"]["output"];
  toast: EgdsToast;
};

export type FlightsPriceInfoSummary = {
  __typename?: "FlightsPriceInfoSummary";
  accessibilityInfo: Scalars["String"]["output"];
  analyticsList: Array<ClientSideAnalytics>;
  primaryText?: Maybe<EgdsSpannableText>;
  secondaryText?: Maybe<Array<EgdsSpannableText>>;
};

export interface FlightsPriceSummary {
  buttonAction: FlightsAction;
  title: Scalars["String"]["output"];
  tripTotalLabel: Scalars["String"]["output"];
}

export type FlightsPriceSummaryGroup = {
  __typename?: "FlightsPriceSummaryGroup";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  entries: Array<FlightsPriceSummaryItem>;
  title?: Maybe<FlightsPriceSummaryItem>;
};

export type FlightsPriceSummaryItem = {
  __typename?: "FlightsPriceSummaryItem";
  details?: Maybe<FlightsPriceSummaryItemDetails>;
  formattedPrice?: Maybe<EgdsSpannableText>;
  label: Scalars["String"]["output"];
  loyaltyPoints?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["String"]["output"];
  roundedPrice?: Maybe<Scalars["String"]["output"]>;
  styles?: Maybe<Array<FlightsPhrasePartStyle>>;
};

export type FlightsPriceSummaryItemDetails = {
  __typename?: "FlightsPriceSummaryItemDetails";
  action: FlightsActionableIcon;
  breakdown: FlightsPriceSummaryItemGroup;
};

export type FlightsPriceSummaryItemError = {
  __typename?: "FlightsPriceSummaryItemError";
  analytics: FlightsAnalytics;
  message: Scalars["String"]["output"];
};

export type FlightsPriceSummaryItemGroup = {
  __typename?: "FlightsPriceSummaryItemGroup";
  action: FlightsAction;
  entries: Array<FlightsPriceSummaryItem>;
  error?: Maybe<FlightsPriceSummaryItemError>;
  title: Scalars["String"]["output"];
};

export type FlightsPriceSummaryPaymentItem = {
  __typename?: "FlightsPriceSummaryPaymentItem";
  messages?: Maybe<Array<FlightsPhrase>>;
  title: FlightsPriceSummaryItem;
};

export type FlightsPriceSummaryStackedFooter = {
  __typename?: "FlightsPriceSummaryStackedFooter";
  dialog?: Maybe<FlightsDialog>;
  skipToCKOButton: FlightsExperienceActionButton;
};

export type FlightsPriceSummaryStepperErrorMessages = {
  __typename?: "FlightsPriceSummaryStepperErrorMessages";
  errorMessage: FlightsErrorMessagingPresentation;
  type: FlightsStepperErrorMessagesKey;
};

export type FlightsPriceSummaryTripTotalItem = {
  __typename?: "FlightsPriceSummaryTripTotalItem";
  ancillaryIncludesText?: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Array<FlightsTextSection>>;
  paymentBreakdown?: Maybe<Array<FlightsPriceSummaryPaymentItem>>;
  /** @deprecated This will be replaced by ratesCurrencyText */
  ratesCurrencyMessage: Scalars["String"]["output"];
  ratesCurrencyText?: Maybe<Scalars["String"]["output"]>;
  tripTotal: FlightsPriceSummaryItem;
};

export type FlightsPricingInformation = {
  __typename?: "FlightsPricingInformation";
  cancellationMessage?: Maybe<Scalars["String"]["output"]>;
  loyaltyEarnMessage?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated this is replaced by loyaltyEarnMessage */
  loyaltyMessage?: Maybe<FlightsImageAndLabel>;
  loyaltyPointsOption?: Maybe<FlightsLoyaltyPointsOption>;
  mainPrice: FlightsPhrase;
  partnerLoyaltyEarnMessage?: Maybe<FlightsMarkAndLabel>;
  /** @deprecated Being replaced with mainPrice to denote the main price shown on flight cards */
  price: FlightsPhrase;
  priceLockup: EgdsPriceLockup;
  pricePerTraveler: FlightsPhrase;
  pricingInformationDisclaimer?: Maybe<FlightsPricingInformationDisclaimer>;
  subPrice?: Maybe<FlightsPhrase>;
  subText?: Maybe<Array<Scalars["String"]["output"]>>;
  subTexts?: Maybe<Array<EgdsText>>;
  taxesLabel?: Maybe<Scalars["String"]["output"]>;
  totalLabel: Scalars["String"]["output"];
  totalPriceForAllTravelers?: Maybe<FlightsPhrase>;
  /** @deprecated Being replaced by subText */
  tripType?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsPricingInformationDisclaimer = {
  __typename?: "FlightsPricingInformationDisclaimer";
  a11yStrikeThroughDialogTriggerText: Scalars["String"]["output"];
  actionDialog: EgdsActionDialog;
  content: Scalars["String"]["output"];
  dialogId: FlightsDialogId;
  triggerAnalytics: Array<ClientSideAnalytics>;
};

export type FlightsProgressBarDetails = {
  __typename?: "FlightsProgressBarDetails";
  /** @deprecated We don't need it now since we have created the accessibilityMessage */
  accessibility: FlightsSimpleLocalizationTemplate;
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  listingsLatency: Scalars["Int"]["output"];
  sortAndFilterLatency: Scalars["Int"]["output"];
};

export type FlightsProgressBarDisplay = "DISABLED" | "ENABLED";

export type FlightsQueryState = "LOADED" | "LOADING";

export type FlightsRedirectOrDialog = FlightsClientAction & {
  __typename?: "FlightsRedirectOrDialog";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogId: Scalars["String"]["output"];
  nextPage: Uri;
};

export type FlightsRefreshAction = FlightsClientAction & {
  __typename?: "FlightsRefreshAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsRegulatoryStatements = {
  __typename?: "FlightsRegulatoryStatements";
  header?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Array<EgdsText>>;
};

export type FlightsRemovePriceMatchPromiseAction = FlightsClientAction & {
  __typename?: "FlightsRemovePriceMatchPromiseAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  journeyContinuationId: Scalars["String"]["output"];
};

export type FlightsResourceLinkAction = FlightsClientAction & {
  __typename?: "FlightsResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  egcsOnClickAnalytics?: Maybe<Array<FlightsClickStreamAnalytics>>;
  resource: Uri;
  target: FlightsLinkTarget;
};

export type FlightsResourceRedirectAction = FlightsClientAction & {
  __typename?: "FlightsResourceRedirectAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  resource: Uri;
};

export type FlightsResponseTimeMetrics = {
  __typename?: "FlightsResponseTimeMetrics";
  name: FlightsMetricName;
  value: Scalars["String"]["output"];
};

export type FlightsResponsiveAccessibilityMessage = {
  __typename?: "FlightsResponsiveAccessibilityMessage";
  longMessage?: Maybe<Scalars["String"]["output"]>;
  shortMessage?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsResponsiveIcon = {
  __typename?: "FlightsResponsiveIcon";
  largeView: Icon;
  smallView: Icon;
};

export type FlightsResponsiveMessage = {
  __typename?: "FlightsResponsiveMessage";
  longMessage: Scalars["String"]["output"];
  shortMessage?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsRestrictiveFareRules = {
  __typename?: "FlightsRestrictiveFareRules";
  dismissLabel: Scalars["String"]["output"];
  displayAction: FlightsIconAndPhrase;
  rules: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FlightsReturnToPreviousAction = FlightsClientAction & {
  __typename?: "FlightsReturnToPreviousAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export type FlightsReviewAndBook = {
  __typename?: "FlightsReviewAndBook";
  heading: EgdsHeading;
  /** @deprecated This field is now deprecated., replace with text */
  message?: Maybe<EgdsPlainText>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsReviewLoadingElements = {
  __typename?: "FlightsReviewLoadingElements";
  clientMetaData: FlightsPwaMetadata;
  flightsJourneyReviewCommon: Array<FlightsJourneyReviewCommon>;
  freeCancellation: FlightsPlacard;
  pageTitle: FlightsPageTitle;
  priceSummary: FlightsLoadingPriceSummary;
};

export type FlightsRewardsOption = {
  __typename?: "FlightsRewardsOption";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  /** @deprecated This field will be replaced by identifier */
  id: FlightsRewardsOptionId;
  identifier: RewardsOption;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
};

export type FlightsRewardsOptionId = "ALTAIR" | "AVION" | "NONAVION";

export type FlightsRewardsPricingInfo = {
  __typename?: "FlightsRewardsPricingInfo";
  loyaltyBanners?: Maybe<Array<FlightsPlacard>>;
  /** @deprecated Using stale version of components not used on UI, replace with loyaltyBanners */
  loyaltyMessagingCards?: Maybe<Array<FlightsMessagingCardPlacard>>;
  options?: Maybe<Array<FlightsRewardsOption>>;
  subText: Array<FlightsPhrase>;
  title: FlightsResponsiveMessage;
};

export type FlightsSearchAnalytics = {
  __typename?: "FlightsSearchAnalytics";
  universalSortAndFilterAnalytics?: Maybe<UniversalSortAndFilterAnalytics>;
};

export interface FlightsSearchComponentCriteriaInput {
  isMetaTraffic?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  queryParams?: InputMaybe<Array<GraphQlPairInput>>;
}

export type FlightsSearchContext = {
  __typename?: "FlightsSearchContext";
  hasCreditRedemptionIntent?: Maybe<Scalars["Boolean"]["output"]>;
  journeysContinuationId?: Maybe<Scalars["String"]["output"]>;
  originalBookingId?: Maybe<Scalars["String"]["output"]>;
  previousFlightSelections?: Maybe<Array<JourneyFlightSelection>>;
  previousOriginalBookingId?: Maybe<Scalars["String"]["output"]>;
  searchCriteriaMismatch?: Maybe<Array<FlightsSearchCriteriaMismatch>>;
  tripType: FlightsTripType;
};

export interface FlightsSearchContextInput {
  hasCreditRedemptionIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeysContinuationId?: InputMaybe<Scalars["String"]["input"]>;
  originalBookingId?: InputMaybe<Scalars["String"]["input"]>;
  previousFlightSelections?: InputMaybe<Array<JourneyFlightSelectionInput>>;
  previousOriginalBookingId?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteriaMismatch?: InputMaybe<Array<FlightsSearchCriteriaMismatch>>;
  tripType: FlightsTripType;
}

export type FlightsSearchCriteriaChangeAction = FlightsClientAction & {
  __typename?: "FlightsSearchCriteriaChangeAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  searchCriteria?: Maybe<FlightsJourneySearchCriteria>;
};

export type FlightsSearchCriteriaMismatch = "TRAVELER";

export type FlightsSearchCrossSellOffer = {
  __typename?: "FlightsSearchCrossSellOffer";
  multiItemFlightCrossSellOfferPrimer: MultiItemFlightSearchCrossSellOfferPrimer;
};

export type FlightsSearchCrossSellOfferSavings = {
  __typename?: "FlightsSearchCrossSellOfferSavings";
  description: Scalars["String"]["output"];
  dismissAnalytics: FlightsAnalytics;
  dismissLabel: Scalars["String"]["output"];
  displayAnalytics: FlightsAnalytics;
  tagline: Scalars["String"]["output"];
};

export type FlightsSearchExperienceType = "CODESHARE" | "FLIGHT_DETAILS" | "OFFER_LISTING";

export type FlightsSearchFilterValues = {
  __typename?: "FlightsSearchFilterValues";
  arrivalAirportCodeFilterValue?: Maybe<FlightsAirportCodeFilterValue>;
  arrivalTimeFilterValue?: Maybe<FlightsTimeBasedFilterValue>;
  arrivalTimeSliderFilterValue?: Maybe<FlightsSliderFilterValue>;
  carryOnBagBasedFilterValue?: Maybe<FlightsCarryOnBagBasedFilterValue>;
  checkedBagBasedFilterValue?: Maybe<FlightsCheckedBagBasedFilterValue>;
  creditEligibleFilterValue?: Maybe<FlightsCreditEligibleFilterValue>;
  departureAirportCodeFilterValue?: Maybe<FlightsAirportCodeFilterValue>;
  departureTimeFilterValue?: Maybe<FlightsTimeBasedFilterValue>;
  departureTimeSliderFilterValue?: Maybe<FlightsSliderFilterValue>;
  durationFilterValue?: Maybe<FlightsDurationBasedFilterValue>;
  flexibleChangePolicyBasedFilterValue?: Maybe<FlightsFlexibleChangePolicyBasedFilterValue>;
  layoverAirportCodeFilterValue?: Maybe<FlightsAirportCodeFilterValue>;
  layoverDurationFilterValue?: Maybe<FlightsSliderFilterValue>;
  noCancelFeeBasedFilterValue?: Maybe<FlightsNoCancelFeeBasedFilterValue>;
  /** @deprecated Depercated in favour of FlightsFilterType */
  noChangeFeeBasedFilterValue?: Maybe<FlightsNoChangeFeeBasedFilterValue>;
  numOfStopFilterValue?: Maybe<FlightsStopBasedFilterValue>;
  preferredAirlineFilterValue?: Maybe<FlightsPreferredAirlineFilterValue>;
  priceFilterValue?: Maybe<FlightsSliderFilterValue>;
  seatChoiceBasedFilterValue?: Maybe<FlightsSeatChoiceBasedFilterValue>;
  /** @deprecated Deprecating travelAndBaggageBasedFilterValue to introduce values in separated fields */
  travelAndBaggageBasedFilterValue?: Maybe<FlightsTravelAndBaggageBasedFilterValue>;
  travelTimeSliderFilterValue?: Maybe<FlightsSliderFilterValue>;
};

export interface FlightsSearchFilterValuesInput {
  arrivalAirportCodeFilterValue?: InputMaybe<FlightsAirportCodeFilterValueInput>;
  arrivalTimeFilterValue?: InputMaybe<FlightsTimeBasedFilterValueInput>;
  arrivalTimeSliderFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  carryOnBagBasedFilterValue?: InputMaybe<FlightsCarryOnBagBasedFilterValueInput>;
  checkedBagBasedFilterValue?: InputMaybe<FlightsCheckedBagBasedFilterValueInput>;
  creditEligibleFilterValue?: InputMaybe<FlightsCreditEligibleFilterValueInput>;
  departureAirportCodeFilterValue?: InputMaybe<FlightsAirportCodeFilterValueInput>;
  departureTimeFilterValue?: InputMaybe<FlightsTimeBasedFilterValueInput>;
  departureTimeSliderFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  durationFilterValue?: InputMaybe<FlightsDurationBasedFilterValueInput>;
  flexibleChangePolicyBasedFilterValue?: InputMaybe<FlightsFlexibleChangePolicyBasedFilterValueInput>;
  layoverAirportCodeFilterValue?: InputMaybe<FlightsAirportCodeFilterValueInput>;
  layoverDurationFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  noCancelFeeBasedFilterValue?: InputMaybe<FlightsNoCancelFeeBasedFilterValueInput>;
  noChangeFeeBasedFilterValue?: InputMaybe<FlightsNoChangeFeeBasedFilterValueInput>;
  numOfStopFilterValue?: InputMaybe<FlightsStopBasedFilterValueInput>;
  preferredAirlineFilterValue?: InputMaybe<FlightsPreferredAirlineFilterValueInput>;
  priceFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
  seatChoiceBasedFilterValue?: InputMaybe<FlightsSeatChoiceBasedFilterValueInput>;
  travelAndBaggageBasedFilterValue?: InputMaybe<FlightsTravelAndBaggageBasedFilterValueInput>;
  travelTimeSliderFilterValue?: InputMaybe<FlightsSliderFilterValueInput>;
}

export interface FlightsSearchForm {
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  addJourneyAction?: Maybe<FlightsAction>;
  airportDropdownEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  appliedFilterCount?: Maybe<Scalars["Int"]["output"]>;
  availableRoutes?: Maybe<AvailableRoutes>;
  bookingChangeLink?: Maybe<FlightsResourceLinkAction>;
  closeActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  closeFormAction?: Maybe<FlightsAction>;
  collapseFormAnalytics: FlightsAnalytics;
  collapsedFormAccessibility?: Maybe<FlightsResponsiveAccessibilityMessage>;
  departureAndReturnDates?: Maybe<FlightsResponsiveMessage>;
  errorSummary: FlightsErrorSummary;
  expandFilterAction: FlightsIconAndLabel;
  expandFormAnalytics: FlightsAnalytics;
  expandSearchFormAction: Icon;
  journeys: Array<FlightsSearchFormJourneyCriteria>;
  /** @deprecated This field is now deprecated */
  mDestEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  newJourneyTemplate: FlightsSearchFormJourneyCriteria;
  originAndDestinationLocations?: Maybe<Scalars["String"]["output"]>;
  searchAction?: Maybe<Scalars["String"]["output"]>;
  searchActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  searchActionActive: Scalars["Boolean"]["output"];
  searchOptions: Array<FlightsSearchFormOption>;
  travelersSummary: FlightsMultiPluralLocalizationTemplate;
}

export type FlightsSearchFormAirlineOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormAirlineOption";
  applyAnalytics: FlightsAnalytics;
  cancelAnalytics: FlightsAnalytics;
  closeIcon?: Maybe<FlightsIconAndAccessibility>;
  doneAction: Scalars["String"]["output"];
  doneActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  fieldIcon: Icon;
  icon: Icon;
  items: Array<FlightsSearchFormAirlineOptionItem>;
  label: Scalars["String"]["output"];
  labelAccessibility?: Maybe<Scalars["String"]["output"]>;
  openAnalytics: FlightsAnalytics;
  selectedItem: FlightsSearchFormAirlineOptionItem;
};

export type FlightsSearchFormAirlineOptionItem = {
  __typename?: "FlightsSearchFormAirlineOptionItem";
  airline: Scalars["String"]["output"];
  airlineSelectedAnalytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
};

export type FlightsSearchFormCabinClassOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormCabinClassOption";
  fieldIcon: Icon;
  icon: Icon;
  items: Array<FlightsSearchFormCabinClassOptionItem>;
  label: Scalars["String"]["output"];
  labelAccessibility?: Maybe<Scalars["String"]["output"]>;
  openAnalytics: FlightsAnalytics;
  selectedItem: FlightsSearchFormCabinClassOptionItem;
};

export type FlightsSearchFormCabinClassOptionItem = {
  __typename?: "FlightsSearchFormCabinClassOptionItem";
  cabinClass: FlightsCabinClass;
  cabinClassSelectedAnalytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
};

export type FlightsSearchFormDateField = {
  __typename?: "FlightsSearchFormDateField";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  analytics: FlightsAnalytics;
  currentError?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Date>;
  dateFormat: Scalars["String"]["output"];
  daysBookableInAdvance: Scalars["Int"]["output"];
  doneAction: Scalars["String"]["output"];
  handleClickAnalytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
  maxStayDurationAllowed?: Maybe<Scalars["Int"]["output"]>;
  placeholder: Scalars["String"]["output"];
  possibleErrors: Array<FlightsSearchFormError>;
  possibleErrorsHeader?: Maybe<FlightsIconAndPhrase>;
};

export type FlightsSearchFormError = {
  __typename?: "FlightsSearchFormError";
  analytics: FlightsAnalytics;
  message: Scalars["String"]["output"];
  type: FlightsSearchFormErrorType;
};

export type FlightsSearchFormErrorType =
  | "CHILD_AGE_MISSING"
  | "DATE_BEFORE_PREVIOUS_DATE"
  | "DATE_NOT_IN_VALID_RANGE"
  | "DATE_NOT_IN_VALID_RANGE_TO_DEFAULT_DATE"
  | "INFANT_AGE_MISSING"
  | "INVALID_DATE_FORMAT"
  | "INVALID_TRAVELER_COUNT"
  | "LESS_THAN_MINIMUM_ADULT"
  | "LOCATION_EMPTY"
  | "LOCATION_SAME"
  | "MAX_STAY_DURATION_ERROR"
  | "SAME_CITY"
  | "SPECIAL_CHARACTER_NOT_ALLOWED"
  | "TOO_SHORT_AIRPORT_STRING"
  | "UNATTENDED_INFANT_IN_LAP"
  | "UNATTENDED_INFANT_IN_SEAT";

export type FlightsSearchFormJourneyCriteria = {
  __typename?: "FlightsSearchFormJourneyCriteria";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  departureDate: FlightsSearchFormDateField;
  destinationLocation: FlightsSearchFormLocationField;
  label: FlightsSimpleLocalizationTemplate;
  originLocation: FlightsSearchFormLocationField;
  removeJourneyAction?: Maybe<FlightsAction>;
  returnDate?: Maybe<FlightsSearchFormDateField>;
  swapLocationsAction: FlightsSwapLocationAction;
};

export type FlightsSearchFormLocationField = {
  __typename?: "FlightsSearchFormLocationField";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  airportCode?: Maybe<Scalars["String"]["output"]>;
  airportLocationType?: Maybe<AirportLocationType>;
  analytics: FlightsAnalytics;
  currentError?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  label: Scalars["String"]["output"];
  nearbyAirports?: Maybe<FlightsSearchFormNearbyAirportsPresentation>;
  onTypeAnalytics: FlightsAnalytics;
  placeholder: Scalars["String"]["output"];
  possibleErrors: Array<FlightsSearchFormError>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsSearchFormMoreFiltersOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormMoreFiltersOption";
  doneAction: Scalars["String"]["output"];
  icon: Icon;
  items: Array<FlightsSearchFormMoreFiltersOptionItem>;
  label: Scalars["String"]["output"];
  labelAccessibility?: Maybe<Scalars["String"]["output"]>;
  openAnalytics: FlightsAnalytics;
};

export type FlightsSearchFormMoreFiltersOptionItem = {
  __typename?: "FlightsSearchFormMoreFiltersOptionItem";
  advancedFilterRemoveAnalytics: FlightsAnalytics;
  advancedFilterSelectedAnalytics: FlightsAnalytics;
  advancedFilterType: FlightsAdvancedFilterType;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
};

export type FlightsSearchFormNearbyAirportItem = {
  __typename?: "FlightsSearchFormNearbyAirportItem";
  airportName: Scalars["String"]["output"];
  analytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
};

export type FlightsSearchFormNearbyAirportsPresentation = {
  __typename?: "FlightsSearchFormNearbyAirportsPresentation";
  accessibility?: Maybe<FlightsMultiPluralLocalizationTemplate>;
  analytics: FlightsAnalytics;
  displayAction: Scalars["String"]["output"];
  header?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<FlightsSearchFormNearbyAirportItem>>;
};

export interface FlightsSearchFormOption {
  icon: Icon;
  label: Scalars["String"]["output"];
  labelAccessibility?: Maybe<Scalars["String"]["output"]>;
  openAnalytics: FlightsAnalytics;
}

export type FlightsSearchFormTravelerAgeOptionItem = {
  __typename?: "FlightsSearchFormTravelerAgeOptionItem";
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["Int"]["output"];
};

export type FlightsSearchFormTravelerInfantOptionItem = FlightsSearchFormTravelerItem & {
  __typename?: "FlightsSearchFormTravelerInfantOptionItem";
  caption?: Maybe<Scalars["String"]["output"]>;
  currentError?: Maybe<Scalars["String"]["output"]>;
  decreaseCountAccessibility?: Maybe<Scalars["String"]["output"]>;
  decreaseCountAnalytics: FlightsAnalytics;
  increaseCountAccessibility?: Maybe<Scalars["String"]["output"]>;
  increaseCountAnalytics: FlightsAnalytics;
  infantsOption: FlightsSearchFormTravelerInfantsOption;
  label: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  minCount: Scalars["Int"]["output"];
  travelerAgeSelectorTemplate?: Maybe<FlightsTravelerAgeSelector>;
  travelerAges?: Maybe<Array<FlightsTravelerAgeSelector>>;
  travelerCount: Scalars["Int"]["output"];
  type: FlightsTravelerType;
};

export type FlightsSearchFormTravelerInfantsOption = {
  __typename?: "FlightsSearchFormTravelerInfantsOption";
  items: Array<FlightsSearchFormTravelerInfantsSelection>;
  label: Scalars["String"]["output"];
};

export type FlightsSearchFormTravelerInfantsSelection = {
  __typename?: "FlightsSearchFormTravelerInfantsSelection";
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  type: FlightsTravelerType;
};

export interface FlightsSearchFormTravelerItem {
  caption?: Maybe<Scalars["String"]["output"]>;
  currentError?: Maybe<Scalars["String"]["output"]>;
  decreaseCountAccessibility?: Maybe<Scalars["String"]["output"]>;
  decreaseCountAnalytics: FlightsAnalytics;
  increaseCountAccessibility?: Maybe<Scalars["String"]["output"]>;
  increaseCountAnalytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  minCount: Scalars["Int"]["output"];
  travelerAgeSelectorTemplate?: Maybe<FlightsTravelerAgeSelector>;
  travelerAges?: Maybe<Array<FlightsTravelerAgeSelector>>;
  travelerCount: Scalars["Int"]["output"];
  type: FlightsTravelerType;
}

export type FlightsSearchFormTravelersOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormTravelersOption";
  adultSelectedAnalytics: FlightsAnalytics;
  ageRulesLink: FlightsLinkAction;
  applyAnalytics: FlightsAnalytics;
  cancelAction: Scalars["String"]["output"];
  cancelActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  cancelAnalytics: FlightsAnalytics;
  changeAnalytics: FlightsAnalytics;
  childrenSelectedAnalytics: FlightsAnalytics;
  closeIcon?: Maybe<FlightsIconAndAccessibility>;
  currentErrors?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  doneAction: Scalars["String"]["output"];
  doneActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  fieldIcon: Icon;
  icon: Icon;
  infantLapAnalytics: FlightsAnalytics;
  infantSeatAnalytics: FlightsAnalytics;
  infantSelectedAnalytics: FlightsAnalytics;
  items: Array<FlightsSearchFormTravelerItem>;
  label: Scalars["String"]["output"];
  labelAccessibility?: Maybe<Scalars["String"]["output"]>;
  openAnalytics: FlightsAnalytics;
  possibleErrors: Array<FlightsSearchFormError>;
  travelerLimitLabel: Scalars["String"]["output"];
  valueTemplate: FlightsMultiPluralLocalizationTemplate;
};

export type FlightsSearchFormTravelersOptionItem = FlightsSearchFormTravelerItem & {
  __typename?: "FlightsSearchFormTravelersOptionItem";
  caption?: Maybe<Scalars["String"]["output"]>;
  currentError?: Maybe<Scalars["String"]["output"]>;
  decreaseCountAccessibility?: Maybe<Scalars["String"]["output"]>;
  decreaseCountAnalytics: FlightsAnalytics;
  increaseCountAccessibility?: Maybe<Scalars["String"]["output"]>;
  increaseCountAnalytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  minCount: Scalars["Int"]["output"];
  travelerAgeSelectorTemplate?: Maybe<FlightsTravelerAgeSelector>;
  travelerAges?: Maybe<Array<FlightsTravelerAgeSelector>>;
  travelerCount: Scalars["Int"]["output"];
  type: FlightsTravelerType;
};

export type FlightsSearchFormTripTypeOption = FlightsSearchFormOption & {
  __typename?: "FlightsSearchFormTripTypeOption";
  icon: Icon;
  items: Array<FlightsSearchFormTripTypeOptionItem>;
  label: Scalars["String"]["output"];
  labelAccessibility?: Maybe<Scalars["String"]["output"]>;
  openAnalytics: FlightsAnalytics;
};

export type FlightsSearchFormTripTypeOptionItem = {
  __typename?: "FlightsSearchFormTripTypeOptionItem";
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  tripType: FlightsTripType;
  tripTypeSelectedAnalytics: FlightsAnalytics;
};

export type FlightsSearchListingHeaderContent = {
  __typename?: "FlightsSearchListingHeaderContent";
  type: FlightsSearchListingHeaderContentType;
};

export type FlightsSearchListingHeaderContentType = "ONE_KEY";

export type FlightsSearchPreferences = {
  __typename?: "FlightsSearchPreferences";
  advancedFilters?: Maybe<Array<FlightsAdvancedFilterType>>;
  airline?: Maybe<Scalars["String"]["output"]>;
  cabinClass: FlightsCabinClass;
};

export interface FlightsSearchPreferencesInput {
  advancedFilters?: InputMaybe<Array<FlightsAdvancedFilterType>>;
  airline?: InputMaybe<Scalars["String"]["input"]>;
  cabinClass: FlightsCabinClass;
}

export type FlightsSearchPreferencesInputType = {
  __typename?: "FlightsSearchPreferencesInputType";
  advancedFilters?: Maybe<Array<FlightsAdvancedFilterType>>;
  airline?: Maybe<Scalars["String"]["output"]>;
  cabinClass: FlightsCabinClass;
};

export type FlightsSearchResponse = {
  __typename?: "FlightsSearchResponse";
  allBanners?: Maybe<Array<FlightsPlacard>>;
  attributeButtonToggles?: Maybe<FlightsAttributeTogglesPresentation>;
  /** @deprecated This will be replaced by new node - detailsAndFaresInformation */
  availableFares?: Maybe<Array<FlightsJourneyAvailableFaresInformationElements>>;
  /** @deprecated This will be replaced by new node - allBanners */
  banners?: Maybe<Array<FlightsPlacard>>;
  cheapestListingInfo?: Maybe<FlightsCheapestListingInfo>;
  clientMetadata?: Maybe<FlightsClientMetadata>;
  crossSort?: Maybe<FlightsCrossSortPresentationResult>;
  detailsAndFaresInformation?: Maybe<Array<FlightsDetailsAndFaresPresentationElements>>;
  dialogs?: Maybe<Array<FlightsDialogPresentation>>;
  failedRequestMessaging?: Maybe<FlightsActionableErrorMessaging>;
  flexibleSearchResponse?: Maybe<FlightsFlexibleSearchResponse>;
  flightsOpinionLab?: Maybe<FlightsActionAndLabel>;
  flightsReviewLoadingElements?: Maybe<FlightsReviewLoadingElements>;
  flightsSearchAnalytics?: Maybe<FlightsSearchAnalytics>;
  flightsSearchListingHeader?: Maybe<Array<FlightsSearchListingHeaderContent>>;
  flightsSheets?: Maybe<Array<FlightsSheetType>>;
  freePriceMatchPromise?: Maybe<FreePriceMatchPromise>;
  gaiaId?: Maybe<Scalars["String"]["output"]>;
  journeysContinuationId?: Maybe<Scalars["String"]["output"]>;
  listingResult?: Maybe<FlightsListingResult>;
  listingsHeaderPlacards?: Maybe<Array<FlightsMessagingCardPlacard>>;
  /** @deprecated Use attributeButtonToggles instead */
  newAddOnFeatureBadgeMessage?: Maybe<FlightsBadgeMessage>;
  noListingMessaging?: Maybe<FlightsErrorMessagingPresentation>;
  /** @deprecated This will be replaced by new Opinion Lab button at the bottom of the listings */
  opinionLab?: Maybe<FlightsOpinionLab>;
  pageTitle?: Maybe<FlightsPageTitle>;
  rewardsPricingInfo?: Maybe<FlightsRewardsPricingInfo>;
  searchForm?: Maybe<FlightsSearchForm>;
  selectedJourneyPresentations?: Maybe<Array<FlightsSelectedJourneyPresentation>>;
  shoppingContext?: Maybe<ShoppingContext>;
  smartShoppingExperienceEligibility?: Maybe<Scalars["Boolean"]["output"]>;
  sortAndFilterResult?: Maybe<FlightsSortAndFilterResult>;
  stepIndicator?: Maybe<FlightsStepIndicator>;
  universalSortAndFilterResult?: Maybe<ShoppingSortAndFilters>;
};

export type FlightsSearchResponseCheapestListingInfoArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseClientMetadataArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseFlightsOpinionLabArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseFlightsReviewLoadingElementsArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseGaiaIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseJourneysContinuationIdArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseListingResultArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseNewAddOnFeatureBadgeMessageArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseOpinionLabArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponsePageTitleArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseRewardsPricingInfoArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseSearchFormArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseSortAndFilterResultArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResponseUniversalSortAndFilterResultArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsSearchResultsDynamicElements = FlightsDynamicElements & {
  __typename?: "FlightsSearchResultsDynamicElements";
  dynamicElementsGroupTrackEventAnalytics?: Maybe<FlightsDynamicElementsTrackEventAnalytics>;
  flightsJourneyDetailsDynamicElements?: Maybe<Array<FlightsJourneyDetailsDynamicElements>>;
};

export type FlightsSearchSortAndFilterCriteria = {
  __typename?: "FlightsSearchSortAndFilterCriteria";
  filterCriteria: Array<FlightsSearchFilterValues>;
};

export type FlightsSeatAncillaryDetailsContent = {
  __typename?: "FlightsSeatAncillaryDetailsContent";
  journeySeatMapCabinSections: Array<FlightsJourneySeatMapCabinContent>;
  journeySelectionDropDown: FlightsAncillarySelect;
  legendButton: UiFloatingActionButton;
  onLoadAccessibilityMessage: Scalars["String"]["output"];
  travelerInfo?: Maybe<EgdsBasicTabs>;
  /** @deprecated This field may get replaced by travelerInfo */
  travelerTabs: EgdsBasicTabs;
};

export type FlightsSeatAncillaryDetailsLoaded = {
  __typename?: "FlightsSeatAncillaryDetailsLoaded";
  content: FlightsSeatAncillaryDetailsContent;
  flightsAncillaryDialog: FlightsAncillaryDetailsDialog;
  flightsExperience: FlightsSeatsExperienceType;
  footer: FlightsSeatAncillaryFooter;
  offerIdentifier?: Maybe<Scalars["String"]["output"]>;
  seatDetailsWrapper: FlightsDialogSheet;
  seatSelectionToastMessages?: Maybe<FlightsSeatSelectionToastMessages>;
};

export type FlightsSeatAncillaryDetailsLoading = {
  __typename?: "FlightsSeatAncillaryDetailsLoading";
  errorMessage: FlightsMessagingCardPlacard;
  /** @deprecated Will be used in Ancillary summary card query */
  onDisplayAction: FlightsAncillaryDisplayAction;
  seatDetailsWrapper: FlightsDialogSheet;
};

export type FlightsSeatAncillaryDetailsOnSelection = {
  __typename?: "FlightsSeatAncillaryDetailsOnSelection";
  footer: FlightsSeatAncillaryFooter;
};

export type FlightsSeatAncillaryDetailsPresentation =
  | FlightsSeatAncillaryDetailsLoaded
  | FlightsSeatAncillaryDetailsLoading
  | FlightsSeatAncillaryDetailsOnSelection
  | FlightsSeatDetailsLoaded
  | FlightsSeatDetailsLoading;

export type FlightsSeatAncillaryFooter = {
  __typename?: "FlightsSeatAncillaryFooter";
  journeyFooters: Array<FlightsSeatAncillaryJourneyFooter>;
  legendFooters: Array<FlightsSeatAncillaryLegendDetailsFooter>;
};

export type FlightsSeatAncillaryJourneyFooter = {
  __typename?: "FlightsSeatAncillaryJourneyFooter";
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  buttonAction: FlightsActionButton;
  selectedSeatsSummary: FlightsSelectedSeatsSummary;
};

export type FlightsSeatAncillaryLegendDetailsFooter = {
  __typename?: "FlightsSeatAncillaryLegendDetailsFooter";
  close: FlightsActionButton;
  legendVariants: Array<FlightsSeatLegendVariant>;
  title: Scalars["String"]["output"];
};

export type FlightsSeatAncillaryMediaContent =
  | FlightsSeatAncillaryMediaContentError
  | FlightsSeatAncillaryMediaContentSuccess;

export type FlightsSeatAncillaryMediaContentError = {
  __typename?: "FlightsSeatAncillaryMediaContentError";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  icon: Icon;
  reloadButton: FlightsExperienceActionButton;
  subText: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type FlightsSeatAncillaryMediaContentSuccess = {
  __typename?: "FlightsSeatAncillaryMediaContentSuccess";
  airplaneAmenities?: Maybe<FlightAmenities>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  mediaContentNotAvailable?: Maybe<FlightsInfoMessagingPresentation>;
  seatFeatures?: Maybe<FlightSeatFeatures>;
  seatImages?: Maybe<FlightSeatImages>;
};

export type FlightsSeatCardStyles = "BORDER" | "WITHOUT_BORDER";

export type FlightsSeatCell = {
  __typename?: "FlightsSeatCell";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<FlightsAction>;
  clickAnalytics?: Maybe<Array<FlightsAnalytics>>;
  exitRowDialog?: Maybe<FlightsDialog>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]["output"]>;
  info?: Maybe<FlightsSeatInfo>;
  isEmpty: Scalars["Boolean"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  span?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use seatCellTheme instead */
  state?: Maybe<FlightsSeatCellState>;
  theme?: Maybe<FlightsSeatCellTheme>;
  variant: SeatVariant;
};

export type FlightsSeatCellDetailsLoaded = {
  __typename?: "FlightsSeatCellDetailsLoaded";
  content: FlightsSeatAncillaryMediaContent;
  seatDetailsWrapper: FlightsDialogSheet;
};

export type FlightsSeatCellDetailsLoading = {
  __typename?: "FlightsSeatCellDetailsLoading";
  error?: Maybe<FlightsSeatAncillaryMediaContentError>;
  seatDetailsWrapper: FlightsDialogSheet;
};

export type FlightsSeatCellDetailsPresentation = FlightsSeatCellDetailsLoaded | FlightsSeatCellDetailsLoading;

export type FlightsSeatCellState = "DEFAULT" | "HIGHLIGHTED";

export type FlightsSeatCellTheme = "HIGHLIGHTED" | "STANDARD";

export type FlightsSeatChoiceBasedFilterValue = {
  __typename?: "FlightsSeatChoiceBasedFilterValue";
  displayToast?: Maybe<Scalars["Boolean"]["output"]>;
  seatChoice: SeatChoiceType;
};

export interface FlightsSeatChoiceBasedFilterValueInput {
  displayToast?: InputMaybe<Scalars["Boolean"]["input"]>;
  seatChoice: SeatChoiceType;
}

export type FlightsSeatDetailsContent = {
  __typename?: "FlightsSeatDetailsContent";
  journeyPills?: Maybe<Array<EgdsPill>>;
  journeySeatMapCabinSections: Array<FlightsJourneySeatMapCabinContent>;
  legendButton: UiFloatingActionButton;
  onLoadAccessibilityMessage: Scalars["String"]["output"];
  seatCellTravelerLabel?: Maybe<Array<Scalars["String"]["output"]>>;
  travelerTabs?: Maybe<FlightsTravelerTabs>;
};

export type FlightsSeatDetailsLoaded = {
  __typename?: "FlightsSeatDetailsLoaded";
  content: FlightsSeatDetailsContent;
  errorIdentifier?: Maybe<FlightsStepperErrorMessagesKey>;
  errorMessage?: Maybe<FlightsErrorMessagingPresentation>;
  flightsExperience: FlightsSeatsExperienceType;
  footer: FlightsSeatFooter;
  offerIdentifier?: Maybe<Scalars["String"]["output"]>;
  seatSelectionToastMessages?: Maybe<FlightsSeatSelectionToastMessages>;
};

export type FlightsSeatDetailsLoading = {
  __typename?: "FlightsSeatDetailsLoading";
  errorMessage: FlightsErrorMessagingPresentation;
};

export type FlightsSeatFooter = {
  __typename?: "FlightsSeatFooter";
  legendFooters: Array<FlightsSeatAncillaryLegendDetailsFooter>;
  seatSelectionFooter: FlightsSeatSelectionFooter;
  /** @deprecated This node is deprecated as this will be part of price summary */
  selectedSeatsInformationFooter: FlightsSeatsSelectedInformationFooter;
};

export type FlightsSeatInfo = {
  __typename?: "FlightsSeatInfo";
  amenities?: Maybe<EgdsUnorderedList>;
  ancillaryToken?: Maybe<Scalars["String"]["output"]>;
  closeSeat: FlightsActionButton;
  priceInfoSummary?: Maybe<FlightsPriceInfoSummary>;
  /** @deprecated Use FlightsSeatPriceSummary instead */
  priceLockup: EgdsPriceLockup;
  selectSeat: FlightsActionButton;
  unselectSeat: FlightsActionButton;
};

export type FlightsSeatInformation = {
  __typename?: "FlightsSeatInformation";
  /** @deprecated Use seatDetailsTrigger instead of this */
  seatDetails: EgdsStandardLink;
  seatDetailsTrigger: FlightsExperienceActionButton;
  seatName: EgdsSpannableText;
  seatType: EgdsSpannableText;
};

export type FlightsSeatLegendCellCard = {
  __typename?: "FlightsSeatLegendCellCard";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  hasBorder: FlightsSeatCardStyles;
  icon?: Maybe<Icon>;
  text?: Maybe<EgdsStylizedText>;
};

export type FlightsSeatLegendVariant = {
  __typename?: "FlightsSeatLegendVariant";
  legendCard: FlightsSeatLegendCellCard;
  title: Scalars["String"]["output"];
};

export type FlightsSeatMapCellCard = {
  __typename?: "FlightsSeatMapCellCard";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsSeatMapGridDetails = {
  __typename?: "FlightsSeatMapGridDetails";
  cabinDimensionDetails: SeatMapCabinDimensionDetails;
  seatMapRows: Array<FlightsSeatMapRow>;
};

export type FlightsSeatMapLegSummary = {
  __typename?: "FlightsSeatMapLegSummary";
  buttonAction: FlightsAction;
  buttonActionTypes: Array<FlightsSeatMapLegSummaryActionType>;
  seatTotalDetails: FlightsSeatMapTotalDetails;
  travelerSummary: FlightsSeatMapTravelersSummary;
};

export type FlightsSeatMapLegSummaryActionType = {
  __typename?: "FlightsSeatMapLegSummaryActionType";
  analyticsList: Array<FlightsAnalytics>;
  id: FlightsActionType;
};

export type FlightsSeatMapLegendVariant = {
  __typename?: "FlightsSeatMapLegendVariant";
  legendCard: FlightsSeatMapCellCard;
  title: Scalars["String"]["output"];
};

export type FlightsSeatMapLegends = {
  __typename?: "FlightsSeatMapLegends";
  close: FlightsActionButton;
  legendVariants: Array<FlightsSeatMapLegendVariant>;
  title: Scalars["String"]["output"];
};

export type FlightsSeatMapRow = {
  __typename?: "FlightsSeatMapRow";
  seatMapRowSeats?: Maybe<Array<FlightsSeatCell>>;
};

export type FlightsSeatMapSeatDetails = {
  __typename?: "FlightsSeatMapSeatDetails";
  assignedSeat?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  price: FlightsSeatMapSeatPrice;
};

export type FlightsSeatMapSeatPrice = {
  __typename?: "FlightsSeatMapSeatPrice";
  amount?: Maybe<Scalars["Float"]["output"]>;
  default: Scalars["String"]["output"];
  selected?: Maybe<FlightsSimpleLocalizationTemplate>;
};

export type FlightsSeatMapTotalDetails = {
  __typename?: "FlightsSeatMapTotalDetails";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  label: FlightsResponsiveMessage;
  noSeatSelected: Scalars["String"]["output"];
  seatDelimiter: Scalars["String"]["output"];
  seatsSelectedMessage: FlightsSimpleLocalizationTemplate;
  selectedSeats: Array<FlightsSeatMapSeatDetails>;
  totalPrice: FlightsSimpleLocalizationTemplate;
};

export type FlightsSeatMapTravelerSummaryItem = {
  __typename?: "FlightsSeatMapTravelerSummaryItem";
  action: FlightsActionAndLabel;
  id: Scalars["Int"]["output"];
  seatDetails: FlightsSeatMapSeatDetails;
};

export type FlightsSeatMapTravelersSummary = {
  __typename?: "FlightsSeatMapTravelersSummary";
  title: FlightsResponsiveMessage;
  travelers: Array<FlightsSeatMapTravelerSummaryItem>;
};

export type FlightsSeatSelectionCabinToastMessages = {
  __typename?: "FlightsSeatSelectionCabinToastMessages";
  continueSeatSelectionMessage: EgdsBasicLocalizedText;
  noFreeSeatsAvailabilityMessage?: Maybe<EgdsBasicLocalizedText>;
  partialSeatsAvailabilityMessage?: Maybe<EgdsBasicLocalizedText>;
  selectPaidSeatForNextFlightMessage?: Maybe<EgdsBasicLocalizedText>;
  selectSeatForNextFlightMessage: EgdsBasicLocalizedText;
};

export type FlightsSeatSelectionErrorMessage = {
  __typename?: "FlightsSeatSelectionErrorMessage";
  analytics?: Maybe<Array<FlightsAnalytics>>;
  id: FlightsSeatSelectionErrorType;
  message: Scalars["String"]["output"];
  theme: TextTheme;
};

export type FlightsSeatSelectionErrorType = "SELECTION_FAILED" | "SELECTION_UNAVAILABLE";

export type FlightsSeatSelectionFooter = {
  __typename?: "FlightsSeatSelectionFooter";
  priceLockup: FlightsPriceInfoSummary;
  seatInfo: FlightsSeatInformation;
  selectButton?: Maybe<FlightsExperienceActionButton>;
  unselectButton?: Maybe<FlightsExperienceActionButton>;
};

export type FlightsSeatSelectionNotificationMessages = {
  __typename?: "FlightsSeatSelectionNotificationMessages";
  seatSelected: FlightsSimpleLocalizationTemplate;
  seatUnselected?: Maybe<FlightsSimpleLocalizationTemplate>;
  seatsSelectedForFlight: FlightsSimpleLocalizationTemplate;
  seatsSelectedForTrip: FlightsSimpleLocalizationTemplate;
};

export type FlightsSeatSelectionToastMessages = {
  __typename?: "FlightsSeatSelectionToastMessages";
  paidSeatSelectedMessage?: Maybe<EgdsBasicLocalizedText>;
  paidSeatSelectionForTripMessage?: Maybe<EgdsBasicLocalizedText>;
  seatSelectionErrorMessage: EgdsBasicLocalizedText;
  seatSelectionForTripMessage: EgdsBasicLocalizedText;
  seatUnselectionErrorMessage: EgdsBasicLocalizedText;
  /** @deprecated Use seatUnselectionSuccessMessage instead */
  seatUnselectionMessage: EgdsBasicLocalizedText;
  seatUnselectionSuccessMessage?: Maybe<EgdsBasicLocalizedText>;
  selectSeatForNextTravelerMessage: EgdsBasicLocalizedText;
};

export type FlightsSeatsConfirmedDetails = {
  __typename?: "FlightsSeatsConfirmedDetails";
  ancillaryToken: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  travelerIndex: Scalars["Int"]["output"];
};

export type FlightsSeatsExperienceType =
  | "CURRENT_SEATS_EXPERIENCE"
  | "NEW_SEATS_EXPERIENCE"
  | "SEATS_RICH_CONTENT_EXPERIENCE";

export type FlightsSeatsSelectedInformationFooter = FlightsAncillaryStackedFooter & {
  __typename?: "FlightsSeatsSelectedInformationFooter";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  selectedAncillaryCount?: Maybe<EgdsSpannableText>;
  totalPrice?: Maybe<FlightsPriceInfoSummary>;
};

export type FlightsSelectedFareSection = {
  __typename?: "FlightsSelectedFareSection";
  /** @deprecated Deprecating this so that we can use content node as it is more generic */
  amenities?: Maybe<FlightsCategorizedList>;
  analyticsList: Array<ClientSideAnalytics>;
  content?: Maybe<FlightsCategorizedList>;
  /** @deprecated Deprecating this because we might not need it */
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  title?: Maybe<FareSectionTitle>;
};

export type FlightsSelectedJourneyAvailableFares = FlightsAvailableFares & {
  __typename?: "FlightsSelectedJourneyAvailableFares";
  applySelection?: Maybe<FlightsAction>;
  dismissDetailedJourneyInformation: FlightsActionableIcon;
  fareChoiceInformation: FlightsFareChoiceInformation;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  openAccessibility: Scalars["String"]["output"];
  signInMessagingCard?: Maybe<EgdsStandardMessagingCard>;
};

export interface FlightsSelectedJourneyDetails {
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  responseMetric?: Maybe<FlightsResponseTimeMetrics>;
}

export type FlightsSelectedJourneyDetailsInformation = {
  __typename?: "FlightsSelectedJourneyDetailsInformation";
  flightsSelectedJourneyDetails?: Maybe<Array<FlightsSelectedJourneyDetails>>;
  flightsSelectedJourneyInformation?: Maybe<FlightsSelectedJourneySection>;
};

export type FlightsSelectedJourneyPill = {
  __typename?: "FlightsSelectedJourneyPill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  content: FlightsSelectedJourneyDetails;
  id: Scalars["String"]["output"];
  primary: FlightsMarkPhrases;
  selectAnalytics: Array<ClientSideAnalytics>;
  trigger?: Maybe<FlightsDialogTrigger>;
};

export type FlightsSelectedJourneyPresentation = {
  __typename?: "FlightsSelectedJourneyPresentation";
  airlines: Array<FlightsImageAndLabel>;
  airportStops: Scalars["String"]["output"];
  changeFlightLabelPresentation: ChangeFlightLabelPresentation;
  departureAndArrivalTime: FlightsPhrase;
  durationAndStops: Scalars["String"]["output"];
  fareRulesPresentation?: Maybe<FlightsOfferFareRulesPresentation>;
  flightOperatedBy: Scalars["String"]["output"];
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  pricingInformation: FlightsPricingInformation;
  title: FlightsPhrase;
};

export interface FlightsSelectedJourneyReview {
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  collapseAnalytics?: Maybe<FlightsAnalytics>;
  expandAnalytics?: Maybe<FlightsAnalytics>;
  fareDetails: FareDetails;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  /** @deprecated This field is now deprecated., replace with Use the sibling of FlightsSelectedJourneyReview */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyHighlights?: Maybe<Array<FlightsJourneyHighlights>>;
  journeyInformation?: Maybe<JourneyInformation>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
}

export type FlightsSelectedJourneySection = {
  __typename?: "FlightsSelectedJourneySection";
  detailPills: Array<FlightsSelectedJourneyPill>;
  dialog?: Maybe<FlightsDialog>;
  selectedId?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsSelectedSeatDetail = {
  __typename?: "FlightsSelectedSeatDetail";
  flightInformation?: Maybe<Scalars["String"]["output"]>;
  travelerSeatsInformation?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type FlightsSelectedSeatsSummary = {
  __typename?: "FlightsSelectedSeatsSummary";
  accessibilityMessage: Scalars["String"]["output"];
  label: EgdsSpannableText;
  message: EgdsStylizedText;
  totalPrice: EgdsPriceLockup;
};

export type FlightsSelectionAction = FlightsClientAction & {
  __typename?: "FlightsSelectionAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  value: Scalars["String"]["output"];
};

export type FlightsServerError = FlightsOnLoadError & {
  __typename?: "FlightsServerError";
  accessibility: Scalars["String"]["output"];
  action: FlightsActionButton;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type FlightsServicingBookingResponse = {
  __typename?: "FlightsServicingBookingResponse";
  failure?: Maybe<FlightServicingBookingFailure>;
  success?: Maybe<FlightServicingBookingSuccess>;
};

export interface FlightsServicingConfirmationCriteriaInput {
  journeyContinuationId?: InputMaybe<Scalars["String"]["input"]>;
  originalBookingId: Scalars["String"]["input"];
  tripId?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightsServicingConfirmationResponse = {
  __typename?: "FlightsServicingConfirmationResponse";
  clientMetadata: FlightsPwaMetadata;
  confirmationHeading: Scalars["String"]["output"];
  errorMessaging?: Maybe<FlightServicingBookingFailure>;
  hotelAttach?: Maybe<FlightsMessagingCardPlacard>;
  mainContent: FlightsConfirmation;
};

export interface FlightsServicingCriteriaInput {
  ancillaryId?: InputMaybe<Scalars["String"]["input"]>;
  isResumeChange?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyContinuationId: Scalars["String"]["input"];
  originalBookingId?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  upsellOfferToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type FlightsServicingResponse = {
  __typename?: "FlightsServicingResponse";
  addCollectRequired?: Maybe<Scalars["Boolean"]["output"]>;
  authenticatedUser: FlightsAuthenticatedUser;
  booking: FlightsBooking;
  clientMetadata: FlightsPwaMetadata;
  creditResidualMessaging?: Maybe<FlightsCreditResidualMessaging>;
  importantTripInfo: FlightsImportantTripInfo;
  payment: FlightsPayment;
  placards?: Maybe<Array<FlightsUiPlacard>>;
  priceSummary?: Maybe<FlightsPriceSummary>;
  regulatoryStatements: FlightsRegulatoryStatements;
  reviewAndBook: FlightsReviewAndBook;
  serverErrorMessaging?: Maybe<FlightServicingBookingFailure>;
  tripSummary?: Maybe<FlightsTripSummary>;
};

export type FlightsServicingResponsePriceSummaryArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export type FlightsServicingResponseTripSummaryArgs = {
  queryState?: InputMaybe<FlightsQueryState>;
};

export interface FlightsSheet {
  content?: Maybe<Array<EgdsElement>>;
}

export type FlightsSheetActionContent = {
  __typename?: "FlightsSheetActionContent";
  footer?: Maybe<Array<EgdsStylizedText>>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<EgdsHeading>;
  /** @deprecated Please use messages instead of message */
  message: Array<EgdsParagraph>;
  messages?: Maybe<EgdsTextList>;
  primaryButton?: Maybe<FlightsExperienceActionButton>;
};

export type FlightsSheetContent = FlightsSheetActionContent | FlightsSheetInfoContent;

export type FlightsSheetInfoContent = {
  __typename?: "FlightsSheetInfoContent";
  heading?: Maybe<EgdsHeading>;
  message: Array<EgdsParagraph>;
};

export type FlightsSheetType = {
  __typename?: "FlightsSheetType";
  content: FlightsSheetContent;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  egcsDisplayAnalytics?: Maybe<Array<FlightsClickStreamAnalytics>>;
  id: FlightsDialogId;
  initialState?: Maybe<FlightsDialogAction>;
  sheet: EgdsSheet;
  title?: Maybe<EgdsHeading>;
};

export type FlightsShoppingOfferContent = {
  __typename?: "FlightsShoppingOfferContent";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badges?: Maybe<Array<EgdsBadge>>;
  graphic?: Maybe<UiGraphic>;
  priceSection?: Maybe<FlightsShoppingPriceSummary>;
  primarySection: Array<FlightsShoppingPrimarySection>;
  secondaryBadges?: Maybe<Array<EgdsBadge>>;
  secondarySection: Array<FlightsShoppingSecondarySection>;
  tertiarySection: Array<FlightsShoppingTertiarySection>;
};

export type FlightsShoppingOfferFeaturedHeader = {
  __typename?: "FlightsShoppingOfferFeaturedHeader";
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
};

export type FlightsShoppingPriceSummary = {
  __typename?: "FlightsShoppingPriceSummary";
  leadingPrice?: Maybe<EgdsSpannableText>;
  price: EgdsSpannableText;
  primaryText?: Maybe<Array<EgdsSpannableText>>;
  secondaryText?: Maybe<Array<EgdsSpannableText>>;
  trailingPrice?: Maybe<EgdsSpannableText>;
};

export type FlightsShoppingPrimarySection = EgdsMoreInfoTrigger | EgdsTextSection;

export type FlightsShoppingSecondarySection = EgdsTextSection | FlightShoppingCardDurationTimeline;

export type FlightsShoppingStandardOfferCard = {
  __typename?: "FlightsShoppingStandardOfferCard";
  additionalContentSections: Array<FlightsSmartShoppingAdditionalContent>;
  content: FlightsShoppingOfferContent;
  detailsAndFares: FlightsDetailsAndFaresPresentation;
  featuredHeader?: Maybe<FlightsShoppingOfferFeaturedHeader>;
  journeyContinuationId: Scalars["String"]["output"];
  onDisplayAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  sponsoredListingBeacons?: Maybe<SponsoredTrackingBeacons>;
};

export type FlightsShoppingTertiarySection = EgdsGraphicText | EgdsTextSection | FlightShoppingCardDurationTimeline;

export type FlightsSideSheet = FlightsSheet & {
  __typename?: "FlightsSideSheet";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  actions?: Maybe<Array<EgdsToolbarActionButton>>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  closeText?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Array<EgdsElement>>;
  footer?: Maybe<Array<EgdsElement>>;
  initialState?: Maybe<FlightsDialogAction>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsSimpleLocalizationTemplate = FlightsLocalizationTemplate & {
  __typename?: "FlightsSimpleLocalizationTemplate";
  initialValue?: Maybe<Scalars["String"]["output"]>;
  template: Scalars["String"]["output"];
  tokens: Array<Scalars["String"]["output"]>;
};

export type FlightsSliderDisplayFilterOption = FlightsFilterOption & {
  __typename?: "FlightsSliderDisplayFilterOption";
  filterAppliedAnalytics?: Maybe<FlightsAnalytics>;
  items: Array<FlightsSliderFilterItem>;
  label: Scalars["String"]["output"];
  selectedIndex: Scalars["Int"]["output"];
  supportsMultipleSelection: Scalars["Boolean"]["output"];
  type: FlightsFilterType;
};

export type FlightsSliderFilterItem = FlightsFilterItem & {
  __typename?: "FlightsSliderFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  defaultSelected: Scalars["Boolean"]["output"];
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: FlightsFilterValue;
};

export type FlightsSliderFilterValue = {
  __typename?: "FlightsSliderFilterValue";
  max: Scalars["Int"]["output"];
  min: Scalars["Int"]["output"];
};

export interface FlightsSliderFilterValueInput {
  max: Scalars["Int"]["input"];
  min: Scalars["Int"]["input"];
}

export type FlightsSmartNoFlightsFoundMessaging = FlightsErrorMessagingPresentation & {
  __typename?: "FlightsSmartNoFlightsFoundMessaging";
  alternateAirportOptions?: Maybe<AlternateAirportOptions>;
  alternateDateOptions?: Maybe<AlternateDateOptions>;
  editSearchButton?: Maybe<FlightsAction>;
  flightsDisplayComponentCountingValue?: Maybe<FlightsDisplayComponentCountingValue>;
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type FlightsSmartShoppingActionButton = EgdsButton & {
  __typename?: "FlightsSmartShoppingActionButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  buttonClientAction: FlightsClientAction;
  buttonIconPosition?: Maybe<FlightsIconPosition>;
  buttonWidth?: Maybe<FlightsButtonWidth>;
  /** @deprecated deprecated, replace with buttonClientAction */
  clientAction: FlightsSmartShoppingClientAction;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  /** @deprecated deprecated, replace with buttonIconPosition */
  iconPosition?: Maybe<FlightsSmartShoppingIconPosition>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
  /** @deprecated deprecated, replace with buttonWidth */
  width?: Maybe<FlightsSmartShoppingButtonWidth>;
};

export type FlightsSmartShoppingActionLink = {
  __typename?: "FlightsSmartShoppingActionLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated deprecated, replace with linkClientAction */
  clientAction: FlightsSmartShoppingClientAction;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  /** @deprecated deprecated, replace with linkIconPosition */
  iconPosition?: Maybe<FlightsSmartShoppingIconPosition>;
  linkClientAction: FlightsClientAction;
  linkIconPosition?: Maybe<FlightsIconPosition>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsSmartShoppingAdditionalContent =
  | EgdsPlainText
  | FlightsExperienceActionLink
  | FlightsSmartShoppingActionLink;

export type FlightsSmartShoppingBannerError = FlightsOnLoadError & {
  __typename?: "FlightsSmartShoppingBannerError";
  accessibility: Scalars["String"]["output"];
  /** @deprecated deprecated, replace with errorAction */
  action: FlightsSmartShoppingActionLink;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  errorAction: FlightsExperienceActionLink;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type FlightsSmartShoppingButtonWidth = "DEFAULT" | "FULL_WIDTH";

export type FlightsSmartShoppingClickAction = FlightsSmartShoppingClientAction & {
  __typename?: "FlightsSmartShoppingClickAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
};

export interface FlightsSmartShoppingClientAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
}

export type FlightsSmartShoppingDialog = {
  __typename?: "FlightsSmartShoppingDialog";
  dialog: EgdsDialog;
  dialogId: FlightsSmartShoppingDialogId;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  experienceDialogId: FlightsDialogId;
  footer?: Maybe<Array<EgdsElement>>;
};

export type FlightsSmartShoppingDialogAction = "CLOSE" | "OPEN" | "TOGGLE";

export type FlightsSmartShoppingDialogId =
  | "AIRLINE_CREDITS"
  | "BAGGAGE_CLOSE_COMPLETE_DIALOG"
  | "BAGGAGE_CLOSE_INCOMPLETE_DIALOG"
  | "BAGGAGE_CONTINUE_BOOKING"
  | "BAGGAGE_DETAILS_DIALOG"
  | "BAGS_POPUP_ON_CHECKOUT"
  | "CHECK_CREDITS_DIALOG"
  | "FLIGHTS_DETAILS_AND_FARES"
  | "FORCED_CHOICE_MODAL"
  | "HOW_CREDITS_WORK"
  | "LEAVE_TO_COUPONS"
  | "PAID_BAGS_DIALOG"
  | "PMP_INFO_DIALOG"
  | "PMP_REMOVE_DIALOG"
  | "SEATS_POPUP_ON_CHECKOUT"
  | "SEAT_CLOSE_COMPLETE_DIALOG"
  | "SEAT_CONTINUE_BOOKING"
  | "SEAT_SELECTION_DIALOG"
  | "TRAVELER_MISMATCH_DIALOG";

export type FlightsSmartShoppingDialogSheet = {
  __typename?: "FlightsSmartShoppingDialogSheet";
  /** @deprecated deprecated, replace with experienceDialog */
  dialog: FlightsSmartShoppingDialog;
  /** @deprecated deprecated, replace with flightsDialogId */
  dialogId: FlightsSmartShoppingDialogId;
  experienceDialog: FlightsDialog;
  experienceSideSheet: FlightsSideSheet;
  /** @deprecated deprecated, replace with experienceDialog */
  flightsDialog: FlightsExperienceDialog;
  flightsDialogId: FlightsDialogId;
  /** @deprecated deprecated, replace with experienceSideSheet */
  flightsSideSheet: FlightsExperienceSideSheet;
  /** @deprecated deprecated, replace with experienceSideSheet */
  sheet: FlightsSmartShoppingSideSheet;
};

export type FlightsSmartShoppingDialogTrigger = {
  __typename?: "FlightsSmartShoppingDialogTrigger";
  /** @deprecated deprecated, replace with flightsDialogAction */
  dialogAction: FlightsSmartShoppingDialogAction;
  /** @deprecated deprecated, replace with flightsDialogId */
  dialogId: FlightsSmartShoppingDialogId;
  dialogTrigger: FlightsDialogTriggerType;
  dynamicDialogId?: Maybe<Scalars["String"]["output"]>;
  flightsDialogAction: FlightsDialogAction;
  flightsDialogId: FlightsDialogId;
  /** @deprecated deprecated, replace with dialogTrigger */
  trigger: FlightsSmartShoppingDialogTriggerType;
};

export type FlightsSmartShoppingDialogTriggerType = FlightsSmartShoppingActionButton | FlightsSmartShoppingActionLink;

export type FlightsSmartShoppingIconPosition = "LEADING" | "TRAILING";

export type FlightsSmartShoppingLinkTarget = "EXTERNAL" | "INTERNAL";

export type FlightsSmartShoppingListing =
  | EgdsStylizedText
  | FlightsExperienceBargainFareOffer
  | FlightsSearchCrossSellOffer
  | FlightsSmartShoppingOfferCard
  | SponsoredContentPlacement;

export type FlightsSmartShoppingListingsError = {
  __typename?: "FlightsSmartShoppingListingsError";
  errorResponse: FlightsOnLoadError;
};

export type FlightsSmartShoppingListingsResults =
  | FlightsSmartShoppingListingsError
  | FlightsSmartShoppingLoadedListings
  | FlightsSmartShoppingLoadingListings;

export type FlightsSmartShoppingLoadedListings = {
  __typename?: "FlightsSmartShoppingLoadedListings";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated An error response will be provided in FlightsSmartShoppingListingsError */
  errorResponse?: Maybe<FlightsOnLoadError>;
  /** @deprecated deprecated, replace with flightsSearchDetailsAndFaresWrapper */
  flightsDetailsAndFares?: Maybe<FlightsSmartShoppingDialogSheet>;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  /** @deprecated deprecated, replace with flightsSearchDetailsAndFaresWrapper */
  flightsDetailsAndFaresWrapper: FlightsExperienceDialogSheet;
  flightsListingsAnalytics: Array<ClientSideAnalytics>;
  flightsSearchDetailsAndFaresWrapper: FlightsDialogSheet;
  listings: Array<FlightsSmartShoppingListing>;
  pinnedListingUnavailableMessage?: Maybe<Scalars["String"]["output"]>;
  responseMetrics?: Maybe<Array<FlightsResponseTimeMetrics>>;
  responseTags?: Maybe<Array<ResponseTagType>>;
  toast?: Maybe<EgdsToast>;
};

export type FlightsSmartShoppingLoadingListings = {
  __typename?: "FlightsSmartShoppingLoadingListings";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated An error response will be provided in FlightsSmartShoppingListingsError */
  errorResponse: FlightsOnLoadError;
  image: Image;
  listingsSkeletonCount: Scalars["Int"]["output"];
  message: Scalars["String"]["output"];
  originDestination: Scalars["String"]["output"];
};

export type FlightsSmartShoppingOfferCard = {
  __typename?: "FlightsSmartShoppingOfferCard";
  additionalContentSections: Array<FlightsSmartShoppingAdditionalContent>;
  /** @deprecated deprecated, replace with offerClientAction */
  clientAction: FlightsSmartShoppingClientAction;
  content: FlightsSmartShoppingOfferContent;
  /** @deprecated deprecated, replace with offerDialogTrigger */
  dialogTrigger: FlightsSmartShoppingDialogTrigger;
  featuredHeader?: Maybe<FlightsSmartShoppingOfferFeaturedHeader>;
  journeyContinuationId: Scalars["String"]["output"];
  offerClientAction: FlightsClientAction;
  offerDialogTrigger: FlightsExperienceDialogTrigger;
  /** @deprecated deprecated, replace with onDisplayAnalyticsList */
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
  onDisplayAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  sanitizedOfferToken?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  sponsoredListingBeacons?: Maybe<SponsoredTrackingBeacons>;
};

export type FlightsSmartShoppingOfferContent = {
  __typename?: "FlightsSmartShoppingOfferContent";
  badges?: Maybe<Array<EgdsBadge>>;
  priceSection?: Maybe<FlightsSmartShoppingPriceSummary>;
  primarySection: Array<FlightsSmartShoppingPrimarySection>;
  secondaryBadges?: Maybe<Array<EgdsBadge>>;
  secondarySection: Array<FlightsSmartShoppingSecondarySection>;
  tertiarySection: Array<FlightsSmartShoppingTertiarySection>;
};

export type FlightsSmartShoppingOfferFeaturedHeader = {
  __typename?: "FlightsSmartShoppingOfferFeaturedHeader";
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
};

export type FlightsSmartShoppingOverFilteringError = FlightsOnLoadError & {
  __typename?: "FlightsSmartShoppingOverFilteringError";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated deprecated, replace with errorAction */
  button: FlightsSmartShoppingActionButton;
  errorAction: FlightsExperienceActionButton;
  filterPills: Array<ShoppingAppliedFilter>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type FlightsSmartShoppingPriceSummary = {
  __typename?: "FlightsSmartShoppingPriceSummary";
  leadingPrice?: Maybe<EgdsSpannableText>;
  price: EgdsSpannableText;
  primaryText?: Maybe<Array<EgdsSpannableText>>;
  secondaryText?: Maybe<Array<EgdsSpannableText>>;
  trailingPrice?: Maybe<EgdsSpannableText>;
};

export type FlightsSmartShoppingPrimarySection = EgdsTextSection;

export type FlightsSmartShoppingResourceLinkAction = FlightsSmartShoppingClientAction & {
  __typename?: "FlightsSmartShoppingResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  linkTarget: FlightsLinkTarget;
  resource: Uri;
  /** @deprecated deprecated, replace with linkTarget */
  target: FlightsSmartShoppingLinkTarget;
};

export type FlightsSmartShoppingSecondarySection = EgdsTextSection;

export type FlightsSmartShoppingSelectionAction = FlightsSmartShoppingClientAction & {
  __typename?: "FlightsSmartShoppingSelectionAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  value: Scalars["String"]["output"];
};

export type FlightsSmartShoppingServerError = FlightsOnLoadError & {
  __typename?: "FlightsSmartShoppingServerError";
  accessibility: Scalars["String"]["output"];
  /** @deprecated deprecated, replace with errorAction */
  action: FlightsSmartShoppingActionButton;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  errorAction: FlightsExperienceActionButton;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type FlightsSmartShoppingSideSheet = {
  __typename?: "FlightsSmartShoppingSideSheet";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<Array<EgdsElement>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsSmartShoppingTertiarySection =
  | EgdsGraphicText
  | EgdsTextSection
  | FlightCardDurationTimeline
  | FlightsDurationTimeline;

export interface FlightsSmartShoppingTravelerDetailsInput {
  ages?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count: Scalars["Int"]["input"];
  travelerType: TravelerDetailsType;
}

export type FlightsSocialProofMessage = {
  __typename?: "FlightsSocialProofMessage";
  icon: Icon;
  message: FlightsPhrase;
};

export type FlightsSortAndFilterAction = {
  __typename?: "FlightsSortAndFilterAction";
  accessibility: FlightsAccessibilityMessage;
  analytics: FlightsAnalytics;
  details?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
};

export interface FlightsSortAndFilterResult {
  filterPresentation?: Maybe<FlightsFilterPresentation>;
  revealAction: FlightsSortAndFilterRevealAction;
  sortPresentation: FlightsSortPresentation;
  title: Scalars["String"]["output"];
}

export type FlightsSortAndFilterRevealAction = {
  __typename?: "FlightsSortAndFilterRevealAction";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  appliedFilterCount?: Maybe<Scalars["Int"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  iconAndLabel: FlightsIconAndLabel;
};

export type FlightsSortLoadedPresentation = FlightsSortPresentation & {
  __typename?: "FlightsSortLoadedPresentation";
  defaultSelectedOption: FlightsSortOptionItem;
  label: Scalars["String"]["output"];
  options: Array<FlightsSortOptionItem>;
  selected: Icon;
  selectedOption: FlightsSortOptionItem;
  selectionActive: Scalars["Boolean"]["output"];
  unselected: Icon;
};

export type FlightsSortLoadingPresentation = FlightsSortPresentation & {
  __typename?: "FlightsSortLoadingPresentation";
  label: Scalars["String"]["output"];
  selectedOption: FlightsSortOptionItem;
  selectionActive: Scalars["Boolean"]["output"];
};

export type FlightsSortOptionItem = {
  __typename?: "FlightsSortOptionItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  analytics: FlightsAnalytics;
  label: Scalars["String"]["output"];
  sortType: FlightsSortOptionType;
};

export type FlightsSortOptionType = {
  __typename?: "FlightsSortOptionType";
  crossSortOptionList?: Maybe<Array<FlightsCrossSortOption>>;
  /** @deprecated Use crossSortOptionList instead */
  crossSortOptionType?: Maybe<FlightsCrossSortOptionType>;
  sortOrder: FlightsSortOrder;
  sortType: FlightsSortType;
};

export interface FlightsSortOptionTypeInput {
  crossSortOptionList?: InputMaybe<Array<FlightsCrossSortOptionInput>>;
  crossSortOptionType?: InputMaybe<FlightsCrossSortOptionTypeInput>;
  sortOrder: FlightsSortOrder;
  sortType: FlightsSortType;
}

export type FlightsSortOrder = "DECREASING" | "INCREASING";

export interface FlightsSortPresentation {
  label: Scalars["String"]["output"];
  selectedOption: FlightsSortOptionItem;
  selectionActive: Scalars["Boolean"]["output"];
}

export type FlightsSortType =
  | "ARRIVAL"
  | "BEST_BETS"
  | "BEST_SORT"
  | "CROSS_SORT"
  | "DEPARTURE"
  | "DURATION"
  | "NONSTOP"
  | "PRICE"
  | "RECOMMENDED";

export type FlightsSponsoredContentAdListing = {
  __typename?: "FlightsSponsoredContentAdListing";
  sponsoredContentPlacement: SponsoredContentPlacement;
};

export type FlightsSponsoredFareInformation = {
  __typename?: "FlightsSponsoredFareInformation";
  bulletpoints?: Maybe<EgdsUnorderedList>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<EgdsHeading>;
  mediaSection: FareMediaSection;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  /** @deprecated the tab click event will be used, instead of this view event */
  onViewedAnalytics?: Maybe<ClientSideAnalytics>;
  priceLockup?: Maybe<EgdsPriceLockup>;
  showMoreExpando?: Maybe<EgdsExpandoPeek>;
  sponsoredFareAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
};

export type FlightsStandardFareSelectedJourneyDetails = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsStandardFareSelectedJourneyDetails";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  fareDetails: FareDetails;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  responseMetric?: Maybe<FlightsResponseTimeMetrics>;
  upsellFareUpdatedMessage?: Maybe<TextWrapper>;
};

export type FlightsStandardFareSelectedJourneyDetailsNoFare = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsStandardFareSelectedJourneyDetailsNoFare";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  responseMetric?: Maybe<FlightsResponseTimeMetrics>;
  upsellFareUpdatedMessage?: Maybe<TextWrapper>;
};

export type FlightsStandardFareSelectedJourneyDetailsWithoutFareInformation = FlightsSelectedJourneyDetails & {
  __typename?: "FlightsStandardFareSelectedJourneyDetailsWithoutFareInformation";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFare?: Maybe<FlightsAction>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  flightsDetailsAndFaresError?: Maybe<FlightsOnLoadError>;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  journeyHighlights: Array<FlightsJourneyHighlights>;
  /** @deprecated onViewedAnalytics is deprecated. Please use onViewedAnalyticsList instead. */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  responseMetric?: Maybe<FlightsResponseTimeMetrics>;
};

export type FlightsStandardOffer = FlightsOffer & {
  __typename?: "FlightsStandardOffer";
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  additionalMessaging?: Maybe<FlightsPhrase>;
  /** @deprecated Use badgesList as that one uses EGDSBadge */
  badges?: Maybe<Array<FlightsBadge>>;
  badgesList?: Maybe<Array<EgdsBadge>>;
  baggageFeesInformation?: Maybe<FlightsOfferBaggageFeesInformation>;
  displayDetailsAction: FlightsToggle;
  flightsOfferAnalytics: FlightsOfferAnalytics;
  flightsOfferFareRulesPresentation?: Maybe<FlightsOfferFareRulesPresentation>;
  flightsShoppingOfferContent?: Maybe<FlightsShoppingOfferContent>;
  freeCancellation: FreeCancellationApplicability;
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  journeys: Array<FlightsOfferJourney>;
  offerIdentifier?: Maybe<Scalars["String"]["output"]>;
  offerInfoLink?: Maybe<FlightsInfoLink>;
  onClickAnalyticsList: Array<FlightsAnalytics>;
  pinnedOfferMessage?: Maybe<Scalars["String"]["output"]>;
  pricingInformation: FlightsPricingInformation;
  restrictiveFareRules?: Maybe<FlightsRestrictiveFareRules>;
  sanitizedOfferToken?: Maybe<Scalars["String"]["output"]>;
  selectOfferAction: FlightsAction;
  specialFareType?: Maybe<Scalars["String"]["output"]>;
  sponsoredAirline?: Maybe<FlightsOfferSponsoredAirline>;
  sponsoredUpsell?: Maybe<FlightsOfferSponsoredUpsell>;
  /** @deprecated Moved field to be under FlightListingsAnalytics */
  sponsoredUpsellAvailableAnalytics?: Maybe<ClientSideAnalytics>;
  stepIndicatorJcid?: Maybe<Scalars["String"]["output"]>;
  tag?: Maybe<FlightsOfferTag>;
};

export type FlightsStep = "BAGS" | "CHECKOUT" | "FLIGHT_DETAILS" | "SEATS";

export type FlightsStepIndicator = {
  __typename?: "FlightsStepIndicator";
  errorAnalytics: FlightsAnalytics;
  journeyContinuationId: Scalars["String"]["output"];
  skeletonCount?: Maybe<Scalars["Int"]["output"]>;
};

export type FlightsStepperAction = FlightsClientAction & {
  __typename?: "FlightsStepperAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  nextStep: FlightsStep;
};

export type FlightsStepperErrorMessagesKey =
  | "PREFETCH_BAGS"
  | "PREFETCH_SEATS"
  | "SUPPLY_SIDE_BAGS"
  | "SUPPLY_SIDE_SEATS";

export type FlightsStepperStackedFooter =
  | FlightsAncillarySelectedInformationStackedFooter
  | FlightsPriceSummaryStackedFooter;

export type FlightsStepperToolbarTitle = {
  __typename?: "FlightsStepperToolbarTitle";
  step: FlightsStep;
  title: Scalars["String"]["output"];
};

export type FlightsStepperToolbarTitles = {
  __typename?: "FlightsStepperToolbarTitles";
  defaultStep: FlightsStep;
  steps: Array<FlightsStepperToolbarTitle>;
};

export type FlightsStopBasedFilterValue = {
  __typename?: "FlightsStopBasedFilterValue";
  stopInfo: FlightsStopInfo;
};

export interface FlightsStopBasedFilterValueInput {
  stopInfo: FlightsStopInfoInput;
}

export type FlightsStopFilterOperation = "EQUAL" | "GREATER_THAN_EQUAL" | "LESS_THAN_EQUAL";

export type FlightsStopInfo = {
  __typename?: "FlightsStopInfo";
  numberOfStops: Scalars["Int"]["output"];
  stopFilterOperation: FlightsStopFilterOperation;
};

export interface FlightsStopInfoInput {
  numberOfStops: Scalars["Int"]["input"];
  stopFilterOperation: FlightsStopFilterOperation;
}

export type FlightsSuggestedFilterItem = FlightsFilterItem & {
  __typename?: "FlightsSuggestedFilterItem";
  accessibility?: Maybe<FlightsAccessibilityMessage>;
  defaultSelected: Scalars["Boolean"]["output"];
  flightsSearchFilterValues: FlightsSearchFilterValues;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  shoppingFilterValue: SelectedValue;
  type: FlightsFilterType;
  value: FlightsFilterValue;
};

export type FlightsSuggestedFilterMessageListing = {
  __typename?: "FlightsSuggestedFilterMessageListing";
  accessibilityMessage: Scalars["String"]["output"];
  suggestedFilter: FlightsSuggestedFilterItem;
  suggestedFilterDetails: FlightsImagePlacard;
};

export type FlightsSupportedAncillaryInfo = {
  __typename?: "FlightsSupportedAncillaryInfo";
  ancillaryTypes: Array<FlightsAncillaryType>;
  journeyContinuationId: Scalars["String"]["output"];
};

export type FlightsSustainabilityCard = {
  __typename?: "FlightsSustainabilityCard";
  cardContent: FlightsIconPhrases;
};

export type FlightsSustainabilityDialogDetails = {
  __typename?: "FlightsSustainabilityDialogDetails";
  closingText: FlightsTextSection;
  dismiss: FlightsAction;
  heading: Scalars["String"]["output"];
  iconRow: FlightsIconAndHeading;
  introduction: Array<FlightsPhrase>;
};

export type FlightsSustainabilityMessage = {
  __typename?: "FlightsSustainabilityMessage";
  details: FlightsSustainabilityDialogDetails;
  information: FlightsIconPlacard;
};

export type FlightsSustainabilityMessaging = {
  __typename?: "FlightsSustainabilityMessaging";
  messageContent: EgdsSpannableText;
};

export type FlightsSwapLocationAction = {
  __typename?: "FlightsSwapLocationAction";
  accessibilityMessage: Scalars["String"]["output"];
  accessibilityUsageMessage: FlightsSimpleLocalizationTemplate;
  analytics: FlightsAnalytics;
  icon: FlightsResponsiveIcon;
  multiDestAccessibilityMessage: FlightsSimpleLocalizationTemplate;
};

export type FlightsSwitchToggle = UiToggle & {
  __typename?: "FlightsSwitchToggle";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  clientAction: FlightsClientAction;
  enabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
};

export type FlightsSwitchToggleAction = FlightsClientAction & {
  __typename?: "FlightsSwitchToggleAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  checkedAccessibilityAnnouncement?: Maybe<Scalars["String"]["output"]>;
  uncheckedAccessibilityAnnouncement?: Maybe<Scalars["String"]["output"]>;
};

export type FlightsTab = EgdsTab & {
  __typename?: "FlightsTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  subLabel: Array<FlightsTravelerTabSubLabel>;
  tabId: Scalars["String"]["output"];
};

export type FlightsTabSelectionState = "CURRENT" | "SELECTED" | "UNSELECTED";

export interface FlightsText {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  text: Array<FlightsPhrase>;
}

export type FlightsTextContainerListItem = EgdsTextListItem & {
  __typename?: "FlightsTextContainerListItem";
  content: EgdsText;
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type FlightsTextPlacard = FlightsPlacard & {
  __typename?: "FlightsTextPlacard";
  actions?: Maybe<Array<FlightsAction>>;
  backgroundTheme?: Maybe<FlightsPlacardTheme>;
  displayType?: Maybe<FlightsPlacardDisplayType>;
  heading?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  onAvailableAnalytics?: Maybe<FlightsAnalytics>;
  /** @deprecated Use onViewedAnalyticsList instead */
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList: Array<ClientSideAnalytics>;
};

export type FlightsTextSection = {
  __typename?: "FlightsTextSection";
  displayAction?: Maybe<FlightsToggle>;
  displayAnalytics?: Maybe<FlightsAnalytics>;
  displayAnalyticsList?: Maybe<Array<FlightsAnalytics>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  messages: Array<FlightsPhrase>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
};

export type FlightsTextSectionDecorator = "BOTTOMSEPARATOR" | "TOPSEPARATOR";

export type FlightsTimeBasedFilterValue = {
  __typename?: "FlightsTimeBasedFilterValue";
  timeOfDay: FlightsTimeOfDay;
};

export interface FlightsTimeBasedFilterValueInput {
  timeOfDay: FlightsTimeOfDay;
}

export type FlightsTimeOfDay = "AFTERNOON" | "EARLY_MORNING" | "EVENING" | "MORNING";

export type FlightsTimePeriodOfDay = "AFTERNOON" | "EARLY_MORNING" | "EVENING" | "MORNING";

export interface FlightsToastContextInput {
  type?: InputMaybe<FlightsFilterType>;
}

export type FlightsToggle = {
  __typename?: "FlightsToggle";
  collapseActionable: TogglePhase;
  expandActionable: TogglePhase;
  expanded: Scalars["Boolean"]["output"];
};

export type FlightsTravelAndBaggageBasedFilterValue = {
  __typename?: "FlightsTravelAndBaggageBasedFilterValue";
  travelAndBaggageName: FilterTravelAndBaggageType;
};

export interface FlightsTravelAndBaggageBasedFilterValueInput {
  travelAndBaggageName: FilterTravelAndBaggageType;
}

export type FlightsTravelerAgeSelector = {
  __typename?: "FlightsTravelerAgeSelector";
  analytics: FlightsAnalytics;
  icon: Icon;
  items: Array<FlightsSearchFormTravelerAgeOptionItem>;
  label: FlightsSimpleLocalizationTemplate;
  placeholder: Scalars["String"]["output"];
};

export type FlightsTravelerDetails = {
  __typename?: "FlightsTravelerDetails";
  ages?: Maybe<Array<Scalars["Int"]["output"]>>;
  count: Scalars["Int"]["output"];
  travelerType: FlightsTravelerType;
};

export interface FlightsTravelerDetailsInput {
  ages?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count: Scalars["Int"]["input"];
  travelerType: FlightsTravelerType;
}

export type FlightsTravelerTabSubLabel = {
  __typename?: "FlightsTravelerTabSubLabel";
  selectionState: FlightsTabSelectionState;
  subLabelLocalized: EgdsBasicLocalizedText;
  /** @deprecated Use subLabelLocalized instead */
  subLabelText: Scalars["String"]["output"];
};

export type FlightsTravelerTabs = {
  __typename?: "FlightsTravelerTabs";
  selectedTabId: Scalars["String"]["output"];
  tab: Array<FlightsTab>;
};

export type FlightsTravelerType = "ADULT" | "CHILD" | "INFANT_IN_LAP" | "INFANT_IN_SEAT";

export type FlightsTriggerDialogOrSheetAction = FlightsClientAction & {
  __typename?: "FlightsTriggerDialogOrSheetAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  dialogAction: FlightsDialogAction;
  dialogId: FlightsDialogId;
};

export type FlightsTripSummary = {
  __typename?: "FlightsTripSummary";
  heading: Scalars["String"]["output"];
  journeys: Array<FlightsTripSummaryJourney>;
  subheading: Scalars["String"]["output"];
};

export type FlightsTripSummaryJourney = {
  __typename?: "FlightsTripSummaryJourney";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  badges?: Maybe<Array<EgdsBadge>>;
  /** @deprecated This will be replaced with items */
  contentItems: Array<FlightsInformationText>;
  header?: Maybe<EgdsHeading>;
  /** @deprecated This will be replaced by header & accessibility fields */
  heading: FlightsMessageAndAccessibility;
  items: Array<FlightsTripSummaryJourneyItem>;
};

export type FlightsTripSummaryJourneyExpandable = {
  __typename?: "FlightsTripSummaryJourneyExpandable";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  expando: EgdsExpandoLink;
  expandoContent: EgdsTextList;
};

export type FlightsTripSummaryJourneyItem = EgdsSpannableText | FlightsTripSummaryJourneyExpandable;

export type FlightsTripType = "MULTI_DESTINATION" | "ONE_WAY" | "ROUND_TRIP";

export interface FlightsUiPlacard {
  displayType: FlightsPlacardDisplayType;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  onViewedAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
}

export type FlightsUpgradeFareSection = {
  __typename?: "FlightsUpgradeFareSection";
  /** @deprecated Deprecating this so that we can use content node as it is more generic */
  amenities?: Maybe<FlightsCategorizedList>;
  analyticsList: Array<ClientSideAnalytics>;
  cardTheme?: Maybe<FlightsCardBackgroundTheme>;
  content?: Maybe<FlightsCategorizedList>;
  detailsAndFares?: Maybe<FlightsDetailsAndFaresPresentation>;
  title?: Maybe<FareSectionTitle>;
  /** @deprecated Deprecating this FlightActionButton inside FareAction is deprecated */
  upgradeAction?: Maybe<FareAction>;
  upgradeFarePrice?: Maybe<FlightsPriceInfoSummary>;
  upgradedAction?: Maybe<FlightsExperienceActionButton>;
};

export type FlightsWaiverCalculator = {
  __typename?: "FlightsWaiverCalculator";
  content: Array<FlightsWaiverCalculatorContent>;
  footer: FlightsWaiverCalculatorFooter;
  header: FlightsWaiverCalculatorHeader;
  validations?: Maybe<Array<EgdsRequiredInputValidation>>;
};

export type FlightsWaiverCalculatorContent = {
  __typename?: "FlightsWaiverCalculatorContent";
  airlineAmoutPaid: Scalars["Float"]["output"];
  pastRefund?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated use penaltyDetails instead */
  penalty?: Maybe<Scalars["Float"]["output"]>;
  penaltyDetails?: Maybe<EgdsNumberInputField>;
  penaltyWaiver?: Maybe<EgdsBasicCheckBox>;
  selectTravelers: EgdsBasicCheckBox;
  totalAmount?: Maybe<Scalars["Float"]["output"]>;
  travelerName: Scalars["String"]["output"];
};

export type FlightsWaiverCalculatorFooter = {
  __typename?: "FlightsWaiverCalculatorFooter";
  airlineAmoutPaid: Scalars["Float"]["output"];
  /** @deprecated use airlineAmoutPaid instead */
  airlineAmoutPaidTotal: Scalars["Float"]["output"];
  /** @deprecated use total instead */
  footer: Scalars["String"]["output"];
  pastRefund?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated use pastRefund instead */
  pastRefundTotal?: Maybe<Scalars["Float"]["output"]>;
  penalty?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated use penalty instead */
  penaltyTotal?: Maybe<Scalars["Float"]["output"]>;
  penaltyWaiver?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated use penaltyWaiver instead */
  penaltyWaiverTotal?: Maybe<Scalars["Float"]["output"]>;
  total: Scalars["String"]["output"];
  totalAmount?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated use totalAmount instead */
  totalAmountTotal?: Maybe<Scalars["Float"]["output"]>;
  totalSelected: Scalars["String"]["output"];
};

export type FlightsWaiverCalculatorHeader = {
  __typename?: "FlightsWaiverCalculatorHeader";
  airlineAmoutPaid: EgdsTextStandardListItem;
  pastRefund?: Maybe<EgdsTextStandardListItem>;
  penalty?: Maybe<EgdsTextStandardListItem>;
  penaltyWaiver?: Maybe<EgdsTextStandardListItem>;
  selectTravelers: EgdsBasicCheckBox;
  totalAmount?: Maybe<EgdsTextStandardListItem>;
  travelerName: Scalars["String"]["output"];
};

export type FlightsWaiverTable = {
  __typename?: "FlightsWaiverTable";
  bookingFee?: Maybe<BookingFee>;
  /** @deprecated Use bookingFee instead */
  bookingFeeCheckBox?: Maybe<EgdsCheckBox>;
  calculator: FlightsWaiverCalculator;
  disclaimerText: Scalars["String"]["output"];
  resetTable: UiSecondaryButton;
  title: Scalars["String"]["output"];
};

export type FloorPlanDialog = LodgingDialog & {
  __typename?: "FloorPlanDialog";
  images: Array<PropertyImage>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type Flow = "AUTOMATED_MANUAL" | "MANUAL";

export type FlowEntrypointIdentifier = "PROFILE" | "SETTINGS";

export type FlowType = "AUTOMATED" | "AUTOMATED_MANUAL" | "MANUAL";

export type Foo = {
  __typename?: "Foo";
  foo: Scalars["String"]["output"];
  something: Scalars["String"]["output"];
  somethingElse: Scalars["String"]["output"];
  somethingMore: Scalars["String"]["output"];
};

export type Footer = {
  __typename?: "Footer";
  condensed?: Maybe<Scalars["Boolean"]["output"]>;
  footerHeadingLabel?: Maybe<Scalars["String"]["output"]>;
  minimal?: Maybe<Scalars["Boolean"]["output"]>;
  sectionData: Array<Maybe<FooterSectionData>>;
};

export type FooterButton = {
  __typename?: "FooterButton";
  target?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  trackingEvent?: Maybe<Scalars["String"]["output"]>;
  type: FooterButtonType;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type FooterButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type FooterCobrandLogo = {
  __typename?: "FooterCobrandLogo";
  mark: Mark;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FooterData =
  | FooterButton
  | FooterCobrandLogo
  | FooterImage
  | FooterLink
  | FooterSeparator
  | FooterText
  | NorthstarFooterImage
  | NorthstarFooterSection
  | NorthstarFooterSectionLink
  | NorthstarFooterText;

export type FooterImage = {
  __typename?: "FooterImage";
  altText?: Maybe<Scalars["String"]["output"]>;
  imgSrc?: Maybe<Scalars["URL"]["output"]>;
  newWindowText?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type FooterLink = {
  __typename?: "FooterLink";
  target?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  trackingEvent?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type FooterSectionButtons = FooterSectionData & {
  __typename?: "FooterSectionButtons";
  data: Array<Maybe<FooterData>>;
};

export type FooterSectionCobrand = FooterSectionData & {
  __typename?: "FooterSectionCobrand";
  data: Array<Maybe<FooterCobrandLogo>>;
};

export interface FooterSectionData {
  data: Array<Maybe<FooterData>>;
}

export type FooterSectionImages = FooterSectionData & {
  __typename?: "FooterSectionImages";
  data: Array<Maybe<FooterImage>>;
};

export type FooterSectionLinks = FooterSectionData & {
  __typename?: "FooterSectionLinks";
  data: Array<Maybe<FooterLink>>;
};

export type FooterSectionText = FooterSectionData & {
  __typename?: "FooterSectionText";
  data: Array<Maybe<FooterText>>;
};

export type FooterSeparator = {
  __typename?: "FooterSeparator";
  type: FooterSeparatorType;
};

export type FooterSeparatorType = "HORIZONTAL_RULE";

export type FooterText = {
  __typename?: "FooterText";
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FormInput = "AD_GROUP" | "CAMPAIGN";

export type FormattedMoney = AccessibilityField & {
  __typename?: "FormattedMoney";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  formatted: Scalars["String"]["output"];
  /** @deprecated Use accessibilityLabel., replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
};

export type FormattedNumber = {
  __typename?: "FormattedNumber";
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["Float"]["output"];
};

export type FraudComponentDetails = {
  __typename?: "FraudComponentDetails";
  placement: Scalars["String"]["output"];
  reportingSegment: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  webSessionProviderMethod: Scalars["String"]["output"];
  webSessionProviderMethodParam: Scalars["String"]["output"];
};

export type FreeCancellationApplicability = "ALL_JOURNEYS" | "CURRENT_JOUNRNEY" | "NOT_APPLICABLE";

export type FreePmpDetailsModal = {
  __typename?: "FreePmpDetailsModal";
  cancellationRule: Scalars["String"]["output"];
  productFeatures: Array<FlightsIconAndLabel>;
  title: Scalars["String"]["output"];
};

export type FreePriceMatchPromise = {
  __typename?: "FreePriceMatchPromise";
  card: FlightsImagePlacard;
  detailsModal: FreePmpDetailsModal;
};

export type FrequentlyAskedQuestions = TravelExperienceContent & {
  __typename?: "FrequentlyAskedQuestions";
  id: Scalars["ID"]["output"];
  questionsAndAnswers: Array<QuestionAndAnswer>;
  seeLess: EgdsButton;
  seeMore: EgdsButton;
  seoStructuredData?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type FunnelLocation =
  | "CHECKOUT"
  | "CHECKOUT_ERROR"
  | "CONFIRMATION"
  | "DETAILS"
  | "ENTRY"
  | "FEATURES"
  | "PAYMENT_ERROR"
  | "POST_BOOKING"
  | "SEARCH_RESULTS"
  | "SYSTEM"
  | "USER";

export interface GdsBannerInput {
  flowType: FlowType;
  partner: Partner;
  refundMode: RefundMode;
  screenType: ScreenType;
}

export type GdsBannerView = {
  __typename?: "GDSBannerView";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  icon: Icon;
};

export type Gaia = {
  __typename?: "Gaia";
  destination: Scalars["Int"]["output"];
  origin?: Maybe<Scalars["Int"]["output"]>;
  pointOfInterest?: Maybe<Scalars["String"]["output"]>;
};

export type GenericDiscoveryCard = {
  __typename?: "GenericDiscoveryCard";
  additionalInformation?: Maybe<DiscoveryAdditionalInformation>;
  badges?: Maybe<DiscoveryCardBadges>;
  cardAction: UiLinkAction;
  closeCardAction?: Maybe<DiscoveryCloseCardAction>;
  detailsList?: Maybe<DiscoveryDetailsList>;
  heading?: Maybe<DiscoveryHeading>;
  id?: Maybe<Scalars["String"]["output"]>;
  impression: ClientSideAnalytics;
  legacyPrice?: Maybe<PropertyPrice>;
  location?: Maybe<DiscoveryCardLocation>;
  media?: Maybe<DiscoveryCardMedia>;
  packageInfo?: Maybe<PackageInfo>;
  rating?: Maybe<ProductRatingSummary>;
  render?: Maybe<ClientSideAnalytics>;
  reviewSnippet?: Maybe<DiscoveryReviewSnippet>;
  seoEntityUrl?: Maybe<Scalars["URL"]["output"]>;
  starRating?: Maybe<DiscoveryStarRating>;
  tripSaveItem?: Maybe<TripsSaveItem>;
  uniqueness?: Maybe<DiscoveryProductUniqueness>;
};

export type GenericHelpCenterSearchBarWithSearchButton = {
  __typename?: "GenericHelpCenterSearchBarWithSearchButton";
  searchBar: EgdsTypeaheadInputField;
  searchButton: UiPrimaryButton;
  searchType: ArticleSearchType;
};

export type GenericProductGroup = DiscoveryProductGroupMap;

export type GeoLocationResult = {
  __typename?: "GeoLocationResult";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon: Icon;
  isClickEnabled: Scalars["Boolean"]["output"];
  location: Scalars["String"]["output"];
  viewAnalytics?: Maybe<ClientSideAnalytics>;
};

export type GeoType = "AIRPORT" | "DESTINATION" | "PROPERTY";

export type GeoUri = Uri & {
  __typename?: "GeoURI";
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
  uncertainty?: Maybe<Scalars["Int"]["output"]>;
  value: Scalars["String"]["output"];
};

export type GeographicFeature = {
  __typename?: "GeographicFeature";
  coordinates: Coordinates;
  fullName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  type: RegionType;
};

export interface GetReviewQuestionsRequestInput {
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
  htid?: InputMaybe<Scalars["String"]["input"]>;
  itin?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  orderLineId?: InputMaybe<Scalars["String"]["input"]>;
  reviewId?: InputMaybe<Scalars["String"]["input"]>;
  tuid?: InputMaybe<Scalars["String"]["input"]>;
}

export type GiftCardPaymentFop = IPaymentFop & {
  __typename?: "GiftCardPaymentFop";
  id: Scalars["String"]["output"];
  paymentAmount?: Maybe<PaymentPriceDetails>;
  paymentGiftCard?: Maybe<PaymentGiftCard>;
  paymentMethod: PaymentMethodType;
  paymentSubMethods?: Maybe<Array<Scalars["String"]["output"]>>;
  rank?: Maybe<Scalars["Int"]["output"]>;
  splittable?: Maybe<Scalars["Boolean"]["output"]>;
  state: PaymentState;
};

export interface GiftCardRequestInput {
  sessionId: Scalars["String"]["input"];
}

export type GlobalNavigationLineOfBusiness = "ACTIVITY" | "CAR" | "CRUISE" | "FLIGHT" | "HOTEL" | "PACKAGE" | "UNKNOWN";

export type GoToTravelAds = {
  __typename?: "GoToTravelAds";
  description: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  headerImage?: Maybe<Image>;
  sidepanelCampaignAd?: Maybe<Scalars["String"]["output"]>;
  sidepanelCampaignDescription?: Maybe<Scalars["String"]["output"]>;
  sidepanelCampaignHeader?: Maybe<Scalars["String"]["output"]>;
  sidepanelCampaignImage?: Maybe<Image>;
  sidepanelCampaignName?: Maybe<Scalars["String"]["output"]>;
  sidepanelExpediaImage?: Maybe<Image>;
  sidepanelFootnote?: Maybe<Scalars["String"]["output"]>;
  sidepanelPreview?: Maybe<Image>;
  sidepanelSearchPlaceholder?: Maybe<Scalars["String"]["output"]>;
  sidepanelSearchPrompt?: Maybe<Scalars["String"]["output"]>;
  traveladsBullets: Array<TraveladsBullet>;
  traveladsButton: UiPrimaryButton;
  traveladsRedirectURL: Scalars["URL"]["output"];
  traveladsSecondaryButton?: Maybe<UiSecondaryButton>;
  traveladsSecondaryURL?: Maybe<Scalars["URL"]["output"]>;
};

export type GoogleOneTap = HeaderSharedUi & {
  __typename?: "GoogleOneTap";
  skipSsr: Scalars["Boolean"]["output"];
};

export type GraphQlPair = {
  __typename?: "GraphQLPair";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface GraphQlPairInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type GraphicText = {
  __typename?: "GraphicText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic: UiGraphic;
  primaryHeadings: Array<Scalars["String"]["output"]>;
};

export type GroundTransfersDirection = "FROM_AIRPORT" | "ROUNDTRIP" | "TO_AIRPORT";

export type GroundTransfersJourneyCriteria = {
  __typename?: "GroundTransfersJourneyCriteria";
  dateTime: DateTime;
  destination: GroundTransfersLocation;
  origin: GroundTransfersLocation;
};

export interface GroundTransfersJourneyCriteriaInput {
  dateTime: DateTimeInput;
  destination: GroundTransfersLocationInput;
  origin: GroundTransfersLocationInput;
}

export type GroundTransfersLocation = {
  __typename?: "GroundTransfersLocation";
  coordinates?: Maybe<Coordinates>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type: GeoType;
};

export interface GroundTransfersLocationInput {
  coordinates?: InputMaybe<CoordinatesInput>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  type: GeoType;
}

export type GroundTransfersNaturalKey = {
  __typename?: "GroundTransfersNaturalKey";
  offerToken: Scalars["String"]["output"];
};

export interface GroundTransfersNaturalKeyInput {
  offerToken: Scalars["String"]["input"];
}

export type GroundTransfersTravelerDetails = {
  __typename?: "GroundTransfersTravelerDetails";
  count: Scalars["Int"]["output"];
  type: GroundTransfersTravelerDetailsType;
};

export interface GroundTransfersTravelerDetailsInput {
  count: Scalars["Int"]["input"];
  type: GroundTransfersTravelerDetailsType;
}

export type GroundTransfersTravelerDetailsType = "ADULT" | "CHILD" | "INFANT";

export type GroundTransportationSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "GroundTransportationSearchForm";
    actionURL: Scalars["String"]["output"];
    airportLocation?: Maybe<EgdsSearchFormLocationField>;
    dates?: Maybe<EgdsSearchFormDatePickerField>;
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    dropoffTime?: Maybe<EgdsSelect>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    groundTransportationSelection?: Maybe<GroundTransportationSearchFormSelection>;
    hotelLocation?: Maybe<EgdsSearchFormLocationField>;
    pickupTime?: Maybe<EgdsSelect>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    travelers?: Maybe<EgdsSearchFormTravelersField>;
  };

export type GroundTransportationSearchFormSelection = {
  __typename?: "GroundTransportationSearchFormSelection";
  airportToHotel: EgdsBasicPill;
  hotelToAirport: EgdsBasicPill;
  roundTrip: EgdsBasicPill;
};

export type GroupedRate = {
  __typename?: "GroupedRate";
  cabinType: Scalars["String"]["output"];
  offlineRates?: Maybe<CruiseOrderSummaryOfflineRate>;
  overview?: Maybe<CruiseOrderSummaryRatesOverviewSection>;
  ratesSection?: Maybe<CruiseOrderSummaryRatesSection>;
};

export type GuideRecommendation = {
  __typename?: "GuideRecommendation";
  card: DestinationRecommendationCard;
  parentId: Array<Scalars["String"]["output"]>;
};

export type GuidesContent = ExploreGuidesByFilter;

export type HackathonVideo = {
  __typename?: "HackathonVideo";
  thumbnailUrl: Scalars["String"]["output"];
  videoUrl: Scalars["URL"]["output"];
};

export type Header = {
  __typename?: "Header";
  bsaBanner?: Maybe<HeaderBanner>;
  expandedNavigation?: Maybe<HeaderSection>;
  externalSharedUIComponents?: Maybe<Array<Maybe<HeaderSharedUi>>>;
  inverseThemePrimaryRow?: Maybe<Scalars["Boolean"]["output"]>;
  inverseThemeSecondaryRow?: Maybe<Scalars["Boolean"]["output"]>;
  languageToggle?: Maybe<HeaderSection>;
  logos?: Maybe<HeaderSection>;
  primaryNavigation?: Maybe<HeaderSection>;
  secondaryNavigation?: Maybe<HeaderSection>;
  subHeader?: Maybe<SubHeader>;
  viewSize: HeaderViewSize;
};

export interface HeaderAction {
  analytics: ClientSideAnalytics;
}

export type HeaderAlignment = "END" | "START";

export type HeaderBanner = {
  __typename?: "HeaderBanner";
  button?: Maybe<HeaderButton>;
  subText: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type HeaderButton = {
  __typename?: "HeaderButton";
  action: UiLinkAction;
  buttonType: HeaderButtonType;
  headerAction: HeaderAction;
  linkTarget: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type HeaderButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type HeaderCreditCardAccountPlacement = HeaderSharedUi & {
  __typename?: "HeaderCreditCardAccountPlacement";
  skipSsr: Scalars["Boolean"]["output"];
};

export type HeaderDialog = {
  __typename?: "HeaderDialog";
  icon?: Maybe<Icon>;
  iconPosition: HeaderItemPosition;
  id: Scalars["String"]["output"];
  items: Array<Maybe<HeaderMenuItem>>;
  text: Scalars["String"]["output"];
  trackingEvent: Scalars["String"]["output"];
};

export type HeaderItemPosition = "LEFT" | "RIGHT";

export type HeaderLayout = "DEFAULT" | "MINIMAL" | "SIMPLIFIED";

export type HeaderLinkAction = HeaderAction & {
  __typename?: "HeaderLinkAction";
  analytics: ClientSideAnalytics;
  isRelative: Scalars["Boolean"]["output"];
  linkTarget: Scalars["String"]["output"];
  resource: Uri;
};

export type HeaderLocaleLinkAction = HeaderAction & {
  __typename?: "HeaderLocaleLinkAction";
  analytics: ClientSideAnalytics;
  langid: Scalars["Int"]["output"];
  locale: Scalars["String"]["output"];
};

export type HeaderLogo = {
  __typename?: "HeaderLogo";
  action: UiLinkAction;
  altText: Scalars["String"]["output"];
  imgSrc: Image;
};

export type HeaderLoyaltyAccountSummaryWidget = HeaderSharedUi & {
  __typename?: "HeaderLoyaltyAccountSummaryWidget";
  skipSsr: Scalars["Boolean"]["output"];
};

export type HeaderLoyaltyMemberStatus = HeaderSharedUi & {
  __typename?: "HeaderLoyaltyMemberStatus";
  skipSsr: Scalars["Boolean"]["output"];
};

export interface HeaderMarketingInput {
  googleClickIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  metaDistributionPartnersDetails?: InputMaybe<Scalars["String"]["input"]>;
}

export type HeaderMemberWallet = HeaderSharedUi & {
  __typename?: "HeaderMemberWallet";
  skipSsr: Scalars["Boolean"]["output"];
};

export type HeaderMenu = {
  __typename?: "HeaderMenu";
  autoOpen: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  iconPosition: HeaderItemPosition;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<Maybe<HeaderMenuItem>>;
  /** @deprecated use triggerAnalytics instead */
  linkName?: Maybe<Scalars["String"]["output"]>;
  memberTheme?: Maybe<Scalars["String"]["output"]>;
  menuCookies: Array<NavigationCookie>;
  menuId: Scalars["String"]["output"];
  menuPosition: HeaderItemPosition;
  menuState?: Maybe<HeaderState>;
  signInLinkAction?: Maybe<UiLinkAction>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  /** @deprecated use triggerAnalytics instead */
  trackingEvent: Scalars["String"]["output"];
  triggerAnalytics?: Maybe<ClientSideAnalytics>;
};

export type HeaderMenuIllustration = {
  __typename?: "HeaderMenuIllustration";
  illustration: Illustration;
};

export type HeaderMenuImages = {
  __typename?: "HeaderMenuImages";
  images: Array<Image>;
};

export type HeaderMenuItem =
  | CommunicationCenter
  | GoogleOneTap
  | HeaderButton
  | HeaderCreditCardAccountPlacement
  | HeaderDialog
  | HeaderLoyaltyAccountSummaryWidget
  | HeaderLoyaltyMemberStatus
  | HeaderMemberWallet
  | HeaderMenuIllustration
  | HeaderMenuImages
  | HeaderMenuLink
  | HeaderPicker
  | HeaderSeparator
  | HeaderSignInButton
  | HeaderSignOutMenuLink
  | HeaderSignUpButton
  | HeaderText
  | MobileAppDownloadButton
  | NotificationCenter
  | PartnerLoyaltyPointsComponent
  | TripsNavigation;

export type HeaderMenuLink = {
  __typename?: "HeaderMenuLink";
  action: UiLinkAction;
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"]["output"];
  newTabText: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export interface HeaderMessage {
  value: Scalars["String"]["output"];
}

export type HeaderNavButton = {
  __typename?: "HeaderNavButton";
  action: UiLinkAction;
  active: Scalars["Boolean"]["output"];
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"]["output"];
  newTabText: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type HeaderNavLink = {
  __typename?: "HeaderNavLink";
  action: UiLinkAction;
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"]["output"];
  newTabText: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type HeaderNotificationCenterState = {
  __typename?: "HeaderNotificationCenterState";
  messageSeen: Scalars["Boolean"]["output"];
};

export type HeaderOpenVirtualAgentAction = HeaderAction & {
  __typename?: "HeaderOpenVirtualAgentAction";
  analytics: ClientSideAnalytics;
  conversationProperties?: Maybe<VaConversationProperties>;
  fallbackAction: HeaderAction;
};

export type HeaderPicker = HeaderSharedUi & {
  __typename?: "HeaderPicker";
  skipSsr: Scalars["Boolean"]["output"];
};

export type HeaderSection = {
  __typename?: "HeaderSection";
  alignment: HeaderAlignment;
  sectionData: Array<HeaderSectionData>;
};

export type HeaderSectionData =
  | CommunicationCenter
  | GoogleOneTap
  | HeaderButton
  | HeaderCreditCardAccountPlacement
  | HeaderLogo
  | HeaderLoyaltyAccountSummaryWidget
  | HeaderLoyaltyMemberStatus
  | HeaderMemberWallet
  | HeaderMenu
  | HeaderMenuLink
  | HeaderNavButton
  | HeaderNavLink
  | HeaderPicker
  | HeaderSignInButton
  | HeaderSignOutMenuLink
  | HeaderSignUpButton
  | MobileAppDownloadButton
  | NotificationCenter
  | PartnerLoyaltyPointsComponent
  | SubHeaderLogo
  | TripsNavigation;

export type HeaderSeparator = {
  __typename?: "HeaderSeparator";
  type: HeaderSeparatorType;
};

export type HeaderSeparatorType = "HORIZONTAL_RULE";

export interface HeaderSharedUi {
  skipSsr: Scalars["Boolean"]["output"];
}

export type HeaderSignInButton = {
  __typename?: "HeaderSignInButton";
  action: UiLinkAction;
  buttonType: HeaderButtonType;
  headerAction: HeaderAction;
  linkTarget: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type HeaderSignOutMenuLink = {
  __typename?: "HeaderSignOutMenuLink";
  action: UiLinkAction;
  headerAction: HeaderAction;
  icon?: Maybe<Icon>;
  linkTarget: Scalars["String"]["output"];
  newTabText: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type HeaderSignUpButton = {
  __typename?: "HeaderSignUpButton";
  action: UiLinkAction;
  buttonType: HeaderButtonType;
  headerAction: HeaderAction;
  linkTarget: Scalars["String"]["output"];
  testId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type HeaderState = HeaderNotificationCenterState;

export type HeaderText = {
  __typename?: "HeaderText";
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  textSize?: Maybe<HeaderTextSize>;
  textType: HeaderTextType;
};

export type HeaderTextSize = "LARGE" | "SMALL";

export type HeaderTextType = "HEADING" | "SUBHEADING";

export type HeaderViewSize = "EXTRA_LARGE" | "LARGE" | "MEDIUM" | "SMALL";

export type HeadsUpError = {
  __typename?: "HeadsUpError";
  content: BookingServicingRulesAndRestrictionsPresentation;
  graphic?: Maybe<UiGraphic>;
};

export type HeadsUpFooter = {
  __typename?: "HeadsUpFooter";
  button: BookingServicingButton;
  footerText: Scalars["String"]["output"];
};

export type HeadsUpResponse = HeadsUpError | HeadsUpSuccess;

export type HeadsUpSuccess = {
  __typename?: "HeadsUpSuccess";
  content: BookingServicingRulesAndRestrictionsPresentation;
  /** @deprecated Replacing with BookingServicingExitDialog under toolbar */
  exitDialog?: Maybe<BookingServicingCancelExitDialog>;
  footer: HeadsUpFooter;
};

export type HeartOutlineType = "NORMAL_OUTLINED" | "RED_OUTLINED";

export type HelpAndFeedbackHelpCenterOptionsType = "OWNER" | "PROPERTY_MANAGER" | "TRAVELER" | "TRUST_AND_SAFETY";

export type HelpAndFeedbackOptionsType =
  | "BRAND_CALL"
  | "CHAT_NOW"
  | "CREDIT_CARD"
  | "RATE_OUR_APP"
  | "SHARE_YOUR_FEEDBACK"
  | "VISIT_HELP_CENTER";

export type HelpArticle = {
  __typename?: "HelpArticle";
  helpArticleId: Scalars["String"]["output"];
  icon: Icon;
  product: Scalars["String"]["output"];
  productId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type HelpArticleCard = HelpArticlesLayout & {
  __typename?: "HelpArticleCard";
  helpArticleHeadingSection: HelpArticleHeadingSection;
  helpArticlesInCard: Array<HelpArticle>;
  moreHelpArticleLink?: Maybe<HelpArticleMoreSection>;
};

export type HelpArticleClickStreamAnalytics = {
  __typename?: "HelpArticleClickStreamAnalytics";
  article?: Maybe<HelpArticleEventPayload>;
  event: EgClickstreamEvent;
  experience: Experience;
  workFlowList: Array<WorkFlowItem>;
};

export type HelpArticleData = {
  __typename?: "HelpArticleData";
  articleId?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<HelpArticleHeadingSection>>;
  contactUsPreFill?: Maybe<ContactUsPreFill>;
  heading: HelpArticleHeadingSection;
  helpArticleClickStreamAnalytics: HelpArticleClickStreamAnalytics;
  helpArticleContent?: Maybe<HelpArticlePortableTextContent>;
  moreAboutCategory?: Maybe<Scalars["String"]["output"]>;
  productId: Scalars["String"]["output"];
  productTitle: Scalars["String"]["output"];
};

export type HelpArticleDataView = HelpArticleData | HelpArticleErrorView;

export type HelpArticleDialog = HelpArticlesLayout & {
  __typename?: "HelpArticleDialog";
  articlesList: Array<HelpArticle>;
  helpArticleHeadingSection: HelpArticleHeadingSection;
};

export type HelpArticleErrorView = {
  __typename?: "HelpArticleErrorView";
  errorHeading: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
};

export type HelpArticleEventPayload = {
  __typename?: "HelpArticleEventPayload";
  articleGroup?: Maybe<Scalars["String"]["output"]>;
  articleLink?: Maybe<Scalars["String"]["output"]>;
  articleLinkType?: Maybe<Scalars["String"]["output"]>;
  articleName?: Maybe<Scalars["String"]["output"]>;
  documentId?: Maybe<Scalars["String"]["output"]>;
  searchTerm?: Maybe<Scalars["String"]["output"]>;
};

export type HelpArticleFeedbackSection = {
  __typename?: "HelpArticleFeedbackSection";
  heading: Scalars["String"]["output"];
  negativeFeedbackIcon: Icon;
  negativeFeedbackSection: HelpArticleNegativeFeedbackSection;
  positiveFeedbackIcon: Icon;
  thankYouMessage: Scalars["String"]["output"];
  thankYouMessageDisplayTimeInMilliSec: Scalars["Int"]["output"];
};

export type HelpArticleHeadingSection = {
  __typename?: "HelpArticleHeadingSection";
  headingText: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
};

export type HelpArticleMoreSection = {
  __typename?: "HelpArticleMoreSection";
  accessibility: Scalars["String"]["output"];
  icon: Icon;
  linkName: Scalars["String"]["output"];
  product: Scalars["String"]["output"];
  productId: Scalars["String"]["output"];
  viewIcon: Icon;
};

export type HelpArticleNegativeFeedbackSection = {
  __typename?: "HelpArticleNegativeFeedbackSection";
  options: EgdsCheckBoxGroup;
  optionsTitle: Scalars["String"]["output"];
  submitButton: EgdsButton;
};

export type HelpArticlePortableTextContent = {
  __typename?: "HelpArticlePortableTextContent";
  helpArticlePortableTextContent: Array<HelpArticlePortableTextEgds>;
};

export type HelpArticlePortableTextEgds =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSearchFormSelectAction
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsUnorderedList
  | HelpArticleTable;

export type HelpArticleSearchErrorEgdsContent = EgdsBulletedList | EgdsPlainText;

export type HelpArticleSearchErrorView = {
  __typename?: "HelpArticleSearchErrorView";
  helpArticleSearchErrorMessage: Array<HelpArticleSearchErrorEgdsContent>;
  helpArticleSearchSubHeading: Scalars["String"]["output"];
  searchTerm?: Maybe<Scalars["String"]["output"]>;
};

export type HelpArticleSummarizationPoweredByLlm = {
  __typename?: "HelpArticleSummarizationPoweredByLLM";
  copyrightIcon?: Maybe<Icon>;
  copyrightText: Scalars["String"]["output"];
  feedbackClickstreamPayload: ArticlesSummarizationClickStreamAnalytics;
  feedbackLabel: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  negativeFeedbackIcon: Icon;
  positiveFeedbackIcon: Icon;
  summarizedResponseByLLM: Array<SummarizedResponseEgds>;
  thankYouMessage: EgdsGraphicText;
};

export type HelpArticleTable = {
  __typename?: "HelpArticleTable";
  body: HelpArticleTableBody;
  caption?: Maybe<Scalars["String"]["output"]>;
  header: Array<Scalars["String"]["output"]>;
};

export type HelpArticleTableBody = {
  __typename?: "HelpArticleTableBody";
  rows: Array<HelpArticleTableRow>;
};

export type HelpArticleTableCell = {
  __typename?: "HelpArticleTableCell";
  content: Array<HelpArticleTableCellContent>;
};

export type HelpArticleTableCellContent =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText;

export type HelpArticleTableRow = {
  __typename?: "HelpArticleTableRow";
  cells: Array<HelpArticleTableCell>;
};

export type HelpArticlesContactUsWidgetSection = {
  __typename?: "HelpArticlesContactUsWidgetSection";
  contactUsButton: UiSecondaryButton;
  headingText: Scalars["String"]["output"];
};

export interface HelpArticlesLayout {
  helpArticleHeadingSection: HelpArticleHeadingSection;
}

export type HelpArticlesResultsView = {
  __typename?: "HelpArticlesResultsView";
  allArticles: AllHelpArticlesView;
  articleFeedbackSection?: Maybe<HelpArticleFeedbackSection>;
  articlesById: HelpArticleDataView;
  articlesBySearchTerm: SearchHelpArticlesView;
  articlesSummarizationBySearchTerm: ArticlesSummarizationView;
  genericHelpCenterSearchBarWithSearchButton: GenericHelpCenterSearchBarWithSearchButton;
  helpArticleSearchBar: HelpCenterSearchBar;
  intelligentSearch: IntelligentSearchResultsM2View;
  moreArticles: MoreHelpArticlesView;
  relevantArticlesBySearchTerm: RelevantHelpArticlesView;
  summarizationFeedback: SummarizationFeedbackView;
};

export type HelpArticlesResultsViewArticlesByIdArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewArticlesBySearchTermArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewArticlesSummarizationBySearchTermArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewMoreArticlesArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export type HelpArticlesResultsViewRelevantArticlesBySearchTermArgs = {
  helpArticlesSearchRequest: HelpArticlesSearchRequestInput;
};

export interface HelpArticlesSearchRequestInput {
  category?: InputMaybe<Scalars["String"]["input"]>;
  helpArticleId?: InputMaybe<Scalars["String"]["input"]>;
  helpArticlesSearchTerm?: InputMaybe<Scalars["String"]["input"]>;
  product?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
}

export type HelpCenterBookingButtons = BookingActionButton & {
  __typename?: "HelpCenterBookingButtons";
  name: UiSecondaryButton;
};

export type HelpCenterCspArticlePair = {
  __typename?: "HelpCenterCSPArticlePair";
  cspArticleId: Scalars["String"]["output"];
  hcArticleId: Scalars["String"]["output"];
};

export type HelpCenterComponentsView = {
  __typename?: "HelpCenterComponentsView";
  helpCenterPersonalizedView: HelpCenterPersonalizationData;
  helpCenterSignInCTAView?: Maybe<SignInCta>;
  helpCenterTripView?: Maybe<HelpCenterTrip>;
  virtualAgent: VirtualAgentView;
};

export type HelpCenterIntentButtons = BookingActionButton & {
  __typename?: "HelpCenterIntentButtons";
  bookingActionsClickStreamAnalytics?: Maybe<BookingActionsClickStreamAnalytics>;
  name: UiPrimaryButton;
  payload?: Maybe<Scalars["String"]["output"]>;
  skillName: Scalars["String"]["output"];
  vaText?: Maybe<Scalars["String"]["output"]>;
};

export type HelpCenterLinkButton = BookingActionButton & {
  __typename?: "HelpCenterLinkButton";
  name: EgdsButton;
};

export type HelpCenterNativeWebVa = {
  __typename?: "HelpCenterNativeWebVA";
  entryPointButton?: Maybe<HelpCenterNativeWebVaEntryPointButton>;
  vaWebUrl?: Maybe<Scalars["String"]["output"]>;
};

export type HelpCenterNativeWebVaEntryPointButton = {
  __typename?: "HelpCenterNativeWebVAEntryPointButton";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type HelpCenterPersonalizationData = {
  __typename?: "HelpCenterPersonalizationData";
  banner: HelpCenterWelcomeBanner;
  contactUsButton?: Maybe<UiSecondaryButton>;
  cspToHelpCenterArticleMap?: Maybe<Array<HelpCenterCspArticlePair>>;
  helpArticleTitle: Scalars["String"]["output"];
  intentButtons?: Maybe<Array<HelpCenterIntentButtons>>;
  intentButtonsHeading: Scalars["String"]["output"];
  isVacEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  pageId?: Maybe<Scalars["String"]["output"]>;
  taapAcademyComponent?: Maybe<TaapAcademyComponent>;
  travelerTripDetailedOverview?: Maybe<HelpCenterTripDetailedOverview>;
  /** @deprecated Removed phase 1 code */
  travelerTripVAPersonalization?: Maybe<HelpCenterTripOverview>;
  /** @deprecated This is only temporary use until Help Center UI is onboarded to EGTnL */
  vaLayoutVariant?: Maybe<Scalars["Int"]["output"]>;
};

export type HelpCenterSearchBar = {
  __typename?: "HelpCenterSearchBar";
  searchBar: EgdsTextInputField;
  searchButton: UiPrimaryButton;
  searchResultViewTitle?: Maybe<Scalars["String"]["output"]>;
  searchType: ArticleSearchType;
  waitMessage?: Maybe<Scalars["String"]["output"]>;
};

export type HelpCenterTrip = {
  __typename?: "HelpCenterTrip";
  trip?: Maybe<HelpCenterTripDetailedOverview>;
};

export type HelpCenterTripDetailedOverview = {
  __typename?: "HelpCenterTripDetailedOverview";
  allTrips?: Maybe<Array<TripCategory>>;
  header?: Maybe<Scalars["String"]["output"]>;
};

export type HelpCenterTripInfo = {
  __typename?: "HelpCenterTripInfo";
  backToTripButton: Scalars["String"]["output"];
  bookingIcons?: Maybe<Array<Icon>>;
  header?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  numberOfBooking?: Maybe<Scalars["String"]["output"]>;
  timePeriod?: Maybe<Scalars["String"]["output"]>;
  tripBookings?: Maybe<Array<TripBooking>>;
  tripId?: Maybe<Scalars["String"]["output"]>;
  tripName?: Maybe<Scalars["String"]["output"]>;
};

export type HelpCenterTripOverview = {
  __typename?: "HelpCenterTripOverview";
  header: Scalars["String"]["output"];
  tripInfos?: Maybe<Array<HelpCenterTripInfo>>;
};

export type HelpCenterWelcomeBanner = {
  __typename?: "HelpCenterWelcomeBanner";
  heading: Scalars["String"]["output"];
  heroImage?: Maybe<Image>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type HelperConfigMap =
  | "And"
  | "CapitalizeFirst"
  | "DeviceTypeEvaluator"
  | "Equals"
  | "EvaluateExperiment"
  | "EvaluateExperimentAndLog"
  | "FormatDoubleNumber"
  | "FormatIntegerNumber"
  | "GetSystemTimeInMs"
  | "GetUsableURN"
  | "Greater"
  | "GreaterAndEquals"
  | "HTTPLink"
  | "ImpressionAnalyticsPayload"
  | "IsNotNullOrEmpty"
  | "IsQualifiedVrboProperty"
  | "Join"
  | "Limit"
  | "Localize"
  | "Not"
  | "NotEqual"
  | "Or"
  | "Substring";

export type HelperMetadata = {
  __typename?: "HelperMetadata";
  description?: Maybe<Scalars["String"]["output"]>;
  name: HelperConfigMap;
  usage?: Maybe<Scalars["String"]["output"]>;
};

export type HelperScreenControls = {
  __typename?: "HelperScreenControls";
  closeActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  openActionAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type HeroComponent = {
  __typename?: "HeroComponent";
  background?: Maybe<Image>;
  elements?: Maybe<Array<TripsComposableElement>>;
  icons?: Maybe<Array<Icon>>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type HighlightedAttribute = {
  __typename?: "HighlightedAttribute";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type HighlightedAttributesSection = {
  __typename?: "HighlightedAttributesSection";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  attributes?: Maybe<Array<HighlightedAttribute>>;
  backgroundTheme?: Maybe<EgdsCardBackgroundTheme>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
};

export type Highlights = {
  __typename?: "Highlights";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  reviewSummaryHighlight?: Maybe<ReviewSummaryHighlight>;
};

export type History = {
  __typename?: "History";
  items?: Maybe<Array<Maybe<HistoryItem>>>;
  type?: Maybe<HistoryType>;
};

export type HistoryActivityDetail = HistoryProductInfo & {
  __typename?: "HistoryActivityDetail";
  discount?: Maybe<Scalars["Int"]["output"]>;
  duration?: Maybe<Scalars["String"]["output"]>;
  freeCancellation?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  imageUrl?: Maybe<Image>;
  leadTicketType?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  referencePrice?: Maybe<Money>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryActivitySearch = HistoryProductInfo & {
  __typename?: "HistoryActivitySearch";
  numberOfResults?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryAirline = {
  __typename?: "HistoryAirline";
  airlineCode?: Maybe<Scalars["String"]["output"]>;
  airlineName?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryCarDetail = HistoryProductInfo & {
  __typename?: "HistoryCarDetail";
  carCategory?: Maybe<Scalars["String"]["output"]>;
  carType?: Maybe<Scalars["String"]["output"]>;
  carVendor?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  dropOffRegionDetail?: Maybe<GeographicFeature>;
  dropOffRegionId?: Maybe<Scalars["String"]["output"]>;
  imageURL?: Maybe<Image>;
  periodPrice?: Maybe<Money>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  pickUpRegionDetail?: Maybe<GeographicFeature>;
  piid?: Maybe<Scalars["String"]["output"]>;
  pricePeriod?: Maybe<Scalars["String"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  searchKey?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryCarSearch = HistoryProductInfo & {
  __typename?: "HistoryCarSearch";
  dailyPrice?: Maybe<Money>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  dropOffRegionDetail?: Maybe<GeographicFeature>;
  dropOffRegionId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  pickUpRegionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  searchKey?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryCruiseSearch = HistoryProductInfo & {
  __typename?: "HistoryCruiseSearch";
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryFlightDetail = HistoryProductInfo & {
  __typename?: "HistoryFlightDetail";
  cheapestOfferPerPerson?: Maybe<Money>;
  destinationAirportCode?: Maybe<Scalars["String"]["output"]>;
  destinationRegionId?: Maybe<Scalars["String"]["output"]>;
  destinationRegionName?: Maybe<Scalars["String"]["output"]>;
  flightLegList?: Maybe<Array<FlightLeg>>;
  flightPassengers?: Maybe<FlightPassengers>;
  localizedFlightName?: Maybe<Scalars["String"]["output"]>;
  originAirportCode?: Maybe<Scalars["String"]["output"]>;
  originRegionId?: Maybe<Scalars["String"]["output"]>;
  originRegionName?: Maybe<Scalars["String"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  routeType?: Maybe<Scalars["String"]["output"]>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryFlightSearch = HistoryProductInfo & {
  __typename?: "HistoryFlightSearch";
  cheapestOfferPerPerson?: Maybe<Money>;
  destinationAirportCode?: Maybe<Scalars["String"]["output"]>;
  destinationRegionId?: Maybe<Scalars["String"]["output"]>;
  destinationRegionName?: Maybe<Scalars["String"]["output"]>;
  flightPassengers?: Maybe<FlightPassengers>;
  localizedFlightName?: Maybe<Scalars["String"]["output"]>;
  originAirportCode?: Maybe<Scalars["String"]["output"]>;
  originRegionId?: Maybe<Scalars["String"]["output"]>;
  originRegionName?: Maybe<Scalars["String"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  routeType?: Maybe<Scalars["String"]["output"]>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryGroup = {
  __typename?: "HistoryGroup";
  displayData: SearchHistoryDisplayData;
  filterCriteria: SearchHistoryFilterCriteria;
};

export type HistoryItem = {
  __typename?: "HistoryItem";
  endDate: ZonedDateTime;
  id?: Maybe<Scalars["String"]["output"]>;
  lastViewedDate: ZonedDateTime;
  localizedAsOfDateTime?: Maybe<Scalars["String"]["output"]>;
  localizedDateRange?: Maybe<Scalars["String"]["output"]>;
  originalSearchPrice?: Maybe<Money>;
  productInfo?: Maybe<HistoryProductInfo>;
  startDate: ZonedDateTime;
};

export type HistoryMutationResponse = {
  __typename?: "HistoryMutationResponse";
  message?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryPackageSearch = HistoryProductInfo & {
  __typename?: "HistoryPackageSearch";
  arrivalAirport?: Maybe<Scalars["String"]["output"]>;
  departureAirport?: Maybe<Scalars["String"]["output"]>;
  infantsInLap: Scalars["Boolean"]["output"];
  localizedPackageName?: Maybe<Scalars["String"]["output"]>;
  localizedPackagesTemplate?: Maybe<Scalars["String"]["output"]>;
  localizedRegionName?: Maybe<Scalars["String"]["output"]>;
  nonStop: Scalars["Boolean"]["output"];
  numberOfInfantsInLap?: Maybe<Scalars["Int"]["output"]>;
  numberOfRooms?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  roomOccupants?: Maybe<Array<RoomOccupants>>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
};

export interface HistoryProductInfo {
  regionId?: Maybe<Scalars["String"]["output"]>;
}

export type HistoryPropertyDetail = HistoryProductInfo & {
  __typename?: "HistoryPropertyDetail";
  checkinDate: ZonedDateTime;
  /** @deprecated Checkin date in posix format. Ony to be used by federation queries */
  checkinDateRaw: Scalars["String"]["output"];
  checkoutDate: ZonedDateTime;
  /** @deprecated Checkout date in posix format. Ony to be used by federation queries */
  checkoutDateRaw: Scalars["String"]["output"];
  /** @deprecated DO NOT USE: The field exists for the federation migration only */
  deprecatedContext: DeprecatedCustomerContext;
  formattedRating: Scalars["String"]["output"];
  formattedReviewCount: Scalars["String"]["output"];
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyImageGallery */
  gallery?: Maybe<PropertyGallery>;
  numberOfRooms?: Maybe<Scalars["Int"]["output"]>;
  numberOfTravelers?: Maybe<Scalars["Int"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  propertyImageGallery?: Maybe<PropertyImageGallery>;
  /** @deprecated Deprecated field: don't use it */
  propertyReviews?: Maybe<PropertyReviews>;
  propertySummary?: Maybe<PropertySummary>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyReviews */
  reviewInfo?: Maybe<PropertyReviews>;
  roomOccupants?: Maybe<Array<RoomOccupants>>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertySummary */
  summary?: Maybe<PropertySummary>;
};

export type HistoryPropertyDetailPropertyImageGalleryArgs = {
  context: ContextInput;
};

export type HistoryPropertyDetailPropertyReviewsArgs = {
  context: ContextInput;
};

export type HistoryPropertyDetailPropertySummaryArgs = {
  context: ContextInput;
};

export type HistoryPropertySearch = HistoryProductInfo & {
  __typename?: "HistoryPropertySearch";
  localizedRoomsTemplate?: Maybe<Scalars["String"]["output"]>;
  numberOfRooms?: Maybe<Scalars["Int"]["output"]>;
  numberOfTravelers?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated We will be migrating region data into a text field for clients that does not expose the raw geo domain class */
  regionDetail?: Maybe<GeographicFeature>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  roomOccupants?: Maybe<Array<RoomOccupants>>;
  searchUrl?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryType =
  | "ACTIVITY_DETAIL"
  | "ACTIVITY_SEARCH"
  | "CAR_DETAIL"
  | "CAR_SEARCH"
  | "CRUISE_SEARCH"
  | "FLIGHT_DETAIL"
  | "FLIGHT_SEARCH"
  | "PACKAGE_SEARCH"
  | "PROPERTY_DETAIL"
  | "PROPERTY_SEARCH";

export type HomeCard = {
  __typename?: "HomeCard";
  buttons?: Maybe<Array<EgdsButton>>;
  clickAction?: Maybe<UiLinkAction>;
  dismissAction?: Maybe<HomeCardDismissAction>;
  heading?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  secondaries?: Maybe<Array<EgdsText>>;
  urgencyMessage?: Maybe<Scalars["String"]["output"]>;
};

export type HomeCardDismissAction = {
  __typename?: "HomeCardDismissAction";
  dismissAction: EgdsDismissAction;
  dismissIcon?: Maybe<Icon>;
};

export type HomeMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "HomeMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type HomeResponse = HeroComponent;

export type HomeTextLinkListItem = EgdsElement &
  EgdsTextListItem & {
    __typename?: "HomeTextLinkListItem";
    action: UiLinkAction;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Should not use Recursive datatype. Use custom lists. */
    nestedList?: Maybe<EgdsTextList>;
    style?: Maybe<EgdsTextListItemStyle>;
    subText?: Maybe<Scalars["String"]["output"]>;
    text: Scalars["String"]["output"];
  };

export type HotelAmenity = {
  __typename?: "HotelAmenity";
  code: Scalars["Int"]["output"];
};

export type HotelDetailsCard = {
  __typename?: "HotelDetailsCard";
  address: Scalars["String"]["output"];
  contactInfo: Array<LabeledValue>;
  name: Scalars["String"]["output"];
};

export type HotelOfferItem = OfferItem & {
  __typename?: "HotelOfferItem";
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discountBadge?: Maybe<CampaignOfferBadge>;
  freeCancellationMessage?: Maybe<Scalars["String"]["output"]>;
  guestReviewCount?: Maybe<Scalars["String"]["output"]>;
  guestReviewQualifierText?: Maybe<Scalars["String"]["output"]>;
  guestReviewRating?: Maybe<Scalars["String"]["output"]>;
  guestReviewRatingQualifierText?: Maybe<Scalars["String"]["output"]>;
  guestReviewText?: Maybe<Scalars["String"]["output"]>;
  image: Image;
  location?: Maybe<Scalars["String"]["output"]>;
  lodgingOffer?: Maybe<OfferData>;
  mod?: Maybe<ModItem>;
  name: Scalars["String"]["output"];
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
  starRating?: Maybe<Scalars["Float"]["output"]>;
  vapMessage?: Maybe<Scalars["String"]["output"]>;
  vipMessage?: Maybe<Scalars["String"]["output"]>;
};

export type HotelProduct = {
  __typename?: "HotelProduct";
  displayedAmenities: Array<HotelAmenity>;
  productId: Scalars["Int"]["output"];
  productType?: Maybe<Scalars["String"]["output"]>;
};

export interface HttpHeaderInput {
  name: Scalars["String"]["input"];
  values: Array<Scalars["String"]["input"]>;
}

export type HttpStatus =
  | "ACCEPTED"
  | "ALREADY_REPORTED"
  | "BAD_GATEWAY"
  | "BAD_REQUEST"
  | "BANDWIDTH_LIMIT_EXCEEDED"
  | "CHECKPOINT"
  | "CONFLICT"
  | "CONTINUE"
  | "CREATED"
  | "DESTINATION_LOCKED"
  | "EXPECTATION_FAILED"
  | "FAILED_DEPENDENCY"
  | "FORBIDDEN"
  | "FOUND"
  | "GATEWAY_TIMEOUT"
  | "GONE"
  | "HTTP_VERSION_NOT_SUPPORTED"
  | "IM_USED"
  | "INSUFFICIENT_SPACE_ON_RESOURCE"
  | "INSUFFICIENT_STORAGE"
  | "INTERNAL_SERVER_ERROR"
  | "I_AM_A_TEAPOT"
  | "LENGTH_REQUIRED"
  | "LOCKED"
  | "LOOP_DETECTED"
  | "METHOD_FAILURE"
  | "METHOD_NOT_ALLOWED"
  | "MOVED_PERMANENTLY"
  | "MOVED_TEMPORARILY"
  | "MULTIPLE_CHOICES"
  | "MULTI_STATUS"
  | "NETWORK_AUTHENTICATION_REQUIRED"
  | "NON_AUTHORITATIVE_INFORMATION"
  | "NOT_ACCEPTABLE"
  | "NOT_EXTENDED"
  | "NOT_FOUND"
  | "NOT_IMPLEMENTED"
  | "NOT_MODIFIED"
  | "NO_CONTENT"
  | "OK"
  | "PARTIAL_CONTENT"
  | "PAYLOAD_TOO_LARGE"
  | "PAYMENT_REQUIRED"
  | "PERMANENT_REDIRECT"
  | "PRECONDITION_FAILED"
  | "PRECONDITION_REQUIRED"
  | "PROCESSING"
  | "PROXY_AUTHENTICATION_REQUIRED"
  | "REQUESTED_RANGE_NOT_SATISFIABLE"
  | "REQUEST_ENTITY_TOO_LARGE"
  | "REQUEST_HEADER_FIELDS_TOO_LARGE"
  | "REQUEST_TIMEOUT"
  | "REQUEST_URI_TOO_LONG"
  | "RESET_CONTENT"
  | "SEE_OTHER"
  | "SERVICE_UNAVAILABLE"
  | "SWITCHING_PROTOCOLS"
  | "TEMPORARY_REDIRECT"
  | "TOO_EARLY"
  | "TOO_MANY_REQUESTS"
  | "UNAUTHORIZED"
  | "UNAVAILABLE_FOR_LEGAL_REASONS"
  | "UNPROCESSABLE_ENTITY"
  | "UNSUPPORTED_MEDIA_TYPE"
  | "UPGRADE_REQUIRED"
  | "URI_TOO_LONG"
  | "USE_PROXY"
  | "VARIANT_ALSO_NEGOTIATES";

export type HttpUri = Uri & {
  __typename?: "HttpURI";
  relativePath: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface IEgClickstreamData {
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
}

export type IFastTrackProductEntityAssetSelection = FastTrackImageAssetSelection;

export interface IPaymentFop {
  id: Scalars["String"]["output"];
  paymentAmount?: Maybe<PaymentPriceDetails>;
  paymentMethod: PaymentMethodType;
  state: PaymentState;
}

export interface IPriceInsightsDialog {
  closeAnalytics?: Maybe<EgClickstreamEvent>;
  /** @deprecated Use egcsAnalytics instead of displayAnalytics */
  displayAnalytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  footer: PriceInsightsTrackingDialogFooter;
  heading?: Maybe<Scalars["String"]["output"]>;
  paragraph: Scalars["String"]["output"];
}

export type IProductEntityAssetSelection = ImageAssetSelection;

export type IsaiSummary = {
  __typename?: "ISAISummary";
  summarizationDisclaimer?: Maybe<IsSummarizationDisclaimer>;
  summary: EgdsUnorderedList;
};

export type IsBookingCard = {
  __typename?: "ISBookingCard";
  actions?: Maybe<Array<IsFollowUpAction>>;
  badges?: Maybe<Array<EgdsStandardBadge>>;
  bookingDetails?: Maybe<Array<Scalars["String"]["output"]>>;
  image?: Maybe<Image>;
  intelligentSearchRequestPayload?: Maybe<IsRequestPayload>;
  title: Scalars["String"]["output"];
};

export type IsBookingSelectionView = {
  __typename?: "ISBookingSelectionView";
  bookings?: Maybe<Array<IsBookingCard>>;
  skipSelectionButton?: Maybe<EgdsButton>;
};

export type IsFeedback = {
  __typename?: "ISFeedback";
  feedbackClickstreamPayload: ArticlesSummarizationClickStreamAnalytics;
  feedbackLabel: Scalars["String"]["output"];
  negativeFeedbackIcon: Icon;
  positiveFeedbackIcon: Icon;
  thankYouMessage: EgdsGraphicText;
};

export interface IsFollowUpAction {
  button: EgdsButton;
}

export type IsFollowUpActions = {
  __typename?: "ISFollowUpActions";
  actions: Array<IsFollowUpAction>;
};

export type IsFollowUpActionsClickStreamAnalytics = {
  __typename?: "ISFollowUpActionsClickStreamAnalytics";
  event: Event;
  experience: Experience;
  workFlowList?: Maybe<Array<WorkFlow>>;
};

export type IsGenericAction = {
  __typename?: "ISGenericAction";
  actions: Array<IsFollowUpAction>;
};

export type IsGenericHeader = {
  __typename?: "ISGenericHeader";
  headers: Array<EgdsHeading>;
};

export type IsGenericText = {
  __typename?: "ISGenericText";
  texts: Array<Scalars["String"]["output"]>;
};

export type IsGenericView = {
  __typename?: "ISGenericView";
  sections: Array<IsGenericViewSection>;
};

export type IsGenericViewSection = {
  __typename?: "ISGenericViewSection";
  sections: Array<IsGenericViewType>;
};

export type IsGenericViewType = IsGenericAction | IsGenericHeader | IsGenericText;

export type IsLinkButton = IsFollowUpAction & {
  __typename?: "ISLinkButton";
  button: EgdsButton;
  clickStream: IsFollowUpActionsClickStreamAnalytics;
  url: UiLinkAction;
};

export type IsRequestPayload = {
  __typename?: "ISRequestPayload";
  orderItemUuid?: Maybe<Scalars["String"]["output"]>;
  searchTerm: Scalars["String"]["output"];
  servicePayload?: Maybe<Scalars["String"]["output"]>;
  viewType: IsViewType;
};

export interface IsRequestPayloadInput {
  orderItemUuid?: InputMaybe<Scalars["String"]["input"]>;
  searchTerm: Scalars["String"]["input"];
  servicePayload?: InputMaybe<Scalars["String"]["input"]>;
  viewType: IsViewType;
}

export type IsResultContent =
  | IsaiSummary
  | IsBookingCard
  | IsBookingSelectionView
  | IsFeedback
  | IsFollowUpActions
  | IsGenericView
  | IsTechnicalErrorView
  | IsUserInputErrorView;

export type IsSummarizationDisclaimer = {
  __typename?: "ISSummarizationDisclaimer";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type IsTechnicalErrorView = {
  __typename?: "ISTechnicalErrorView";
  action?: Maybe<IsFollowUpAction>;
  body?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<EgdsHeading>;
  icon?: Maybe<Icon>;
};

export type IsUserInputErrorView = {
  __typename?: "ISUserInputErrorView";
  message: Array<IsUserInputErrorViewErrorMessage>;
};

export type IsUserInputErrorViewErrorMessage = EgdsBulletedList | EgdsPlainText;

export type IsvaButton = IsFollowUpAction & {
  __typename?: "ISVAButton";
  button: EgdsButton;
  chatUrl?: Maybe<UiLinkAction>;
  clickStream: IsFollowUpActionsClickStreamAnalytics;
  intent?: Maybe<Scalars["String"]["output"]>;
  messageType?: Maybe<Scalars["String"]["output"]>;
  payload?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type IsViewType = "BOOKING_CONFIRM" | "BOOKING_SKIP" | "SEARCH";

export interface ISupplyPaymentLineItem {
  amount: SupplyPaymentDetailsAmountLineItem;
  footerNoteText?: Maybe<EgdsStylizedText>;
  lineItemText: EgdsStylizedText;
  originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
  sideNoteText?: Maybe<EgdsStylizedText>;
}

export interface ISupplyPaymentMoreInfo {
  tooltip?: Maybe<SupplyPaymentTooltip>;
}

export interface ISupplyReservationsPaymentLineItem {
  amount: SupplyReservationsPaymentDetailsAmountLineItem;
  footerNoteText?: Maybe<EgdsStylizedText>;
  lineItemText: EgdsStylizedText;
  originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
  sideNoteText?: Maybe<EgdsStylizedText>;
}

export interface ISupplyReservationsPaymentMoreInfo {
  tooltip?: Maybe<SupplyReservationsToolTip>;
}

export interface ISupplyScoreFactorPanelContent {
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
}

export type Icon = {
  __typename?: "Icon";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  size?: Maybe<IconSize>;
  spotLight?: Maybe<IconSpotLight>;
  theme?: Maybe<IconTheme>;
  title?: Maybe<Scalars["String"]["output"]>;
  token: Scalars["String"]["output"];
  withBackground?: Maybe<Scalars["Boolean"]["output"]>;
};

export type IconSize = "EXTRA_SMALL" | "LARGE" | "MEDIUM" | "MEDIUM_LARGE" | "SMALL" | "XLARGE";

export type IconSpotLight = "INFO" | "POSITIVE" | "STANDARD" | "WARNING";

export type IconTheme = "default" | "emphasis" | "inverse" | "negative" | "positive";

export type IconType = "DONE" | "ERROR";

export type IconWrapper = {
  __typename?: "IconWrapper";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  icons: Array<Icon>;
};

export type IdentifierMetadata = {
  __typename?: "IdentifierMetadata";
  description?: Maybe<Scalars["String"]["output"]>;
  name: IdentifierType;
};

export type IdentifierType =
  | "ACTIVITY_ID"
  | "CART_ID"
  | "CAR_OFFER_TOKEN"
  | "CHECKOUT_SESSION_ID"
  | "ENTITY_ID"
  | "LEGACY_ORDER_ID"
  | "OFFER_ID"
  | "ORDER_ID"
  | "PROPERTY_ID"
  | "SAILING_TOKEN";

export type Identity = {
  __typename?: "Identity";
  authState?: Maybe<AuthenticationState>;
  duaid: Scalars["UUID"]["output"];
  expUserId?: Maybe<Scalars["String"]["output"]>;
  tuid?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityAtoLink = {
  __typename?: "IdentityATOLink";
  loadBeforeWidget?: Maybe<Scalars["Boolean"]["output"]>;
  mimeType: Scalars["String"]["output"];
  section?: Maybe<Scalars["String"]["output"]>;
  uri: Scalars["String"]["output"];
};

export type IdentityAccountCreationState = "INVALID" | "NOT_UPGRADED" | "UPGRADED";

export type IdentityAccountCreationType = "INVALID" | "REGISTERED" | "UNREGISTERED";

export type IdentityAccountResponse = IdentityCreateAccountErrorResponse | IdentityCreateAccountSuccessResponse;

export type IdentityAccountTakeOverDevice = {
  __typename?: "IdentityAccountTakeOverDevice";
  payload: Scalars["String"]["output"];
  type: IdentityAccountTakeOverDeviceType;
};

export interface IdentityAccountTakeOverDeviceInput {
  payload: Scalars["String"]["input"];
  type: IdentityAccountTakeOverDeviceType;
}

export type IdentityAccountTakeOverDeviceType = "TRUST_WIDGET";

export interface IdentityAccountTakeOverInput {
  inputs: Array<GraphQlPairInput>;
  type: Scalars["String"]["input"];
}

export type IdentityAccountTakeOverShield = {
  __typename?: "IdentityAccountTakeOverShield";
  accountTakeOverTokens?: Maybe<Array<IdentityAccountTakeOverToken>>;
  crossSiteRequestForgeryData: IdentityCrossSiteRequestForgery;
  devices?: Maybe<Array<IdentityAccountTakeOverDevice>>;
  placement?: Maybe<Scalars["String"]["output"]>;
};

export interface IdentityAccountTakeOverShieldInput {
  accountTakeOverTokens?: InputMaybe<Array<IdentityAccountTakeOverTokenInput>>;
  crossSiteRequestForgeryData: IdentityCrossSiteRequestForgeryInput;
  devices?: InputMaybe<Array<IdentityAccountTakeOverDeviceInput>>;
  placement?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAccountTakeOverToken = {
  __typename?: "IdentityAccountTakeOverToken";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface IdentityAccountTakeOverTokenInput {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type IdentityAccountTakeOverType =
  | "CAPTCHA_ARKOSE_MOBILE_APP"
  | "CAPTCHA_SAFETYNET_ANDROID"
  | "CAPTCHA_WEB"
  | "CSRF"
  | "DEVICE_CHECK"
  | "TRUST_WIDGET";

export interface IdentityAccountTakeOverWidgetAction {
  inputKeys: Array<Scalars["String"]["output"]>;
  type: IdentityAccountTakeOverType;
}

export interface IdentityAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
}

export interface IdentityAddIdentifierByOtpFormRequestInput {
  verifyOTPFormContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAddIdentifierByOtpFormResponse = IdentityAddIdentifierByOtpFormSuccessResponse;

export type IdentityAddIdentifierByOtpFormSuccessResponse = {
  __typename?: "IdentityAddIdentifierByOTPFormSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  continueButton?: Maybe<IdentityPrimaryButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  oneTimePasscodeField?: Maybe<IdentityNumberInputField>;
  progressBar?: Maybe<IdentityProgressLoader>;
  resendCodeButton?: Maybe<IdentityResendCodeButton>;
  toolbar?: Maybe<IdentityToolbar>;
  verifyOTPFormContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityAddPhoneFormFailureResponse = {
  __typename?: "IdentityAddPhoneFormFailureResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
};

export type IdentityAddPhoneFormNoActionSuccessResponse = {
  __typename?: "IdentityAddPhoneFormNoActionSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  redirectionContext?: Maybe<Scalars["String"]["output"]>;
};

export interface IdentityAddPhoneFormRequestInput {
  flowType?: InputMaybe<IdentityFlowType>;
  loginType?: InputMaybe<IdentityLoginType>;
  optionalClientContext?: InputMaybe<Scalars["String"]["input"]>;
  pageName?: InputMaybe<IdentityPageName>;
}

export type IdentityAddPhoneFormResponse =
  | IdentityAddPhoneFormFailureResponse
  | IdentityAddPhoneFormNoActionSuccessResponse
  | IdentityAddPhoneFormSuccessResponse
  | IdentityAddPhoneVerifyOtpFormResponse;

export type IdentityAddPhoneFormSuccessResponse = {
  __typename?: "IdentityAddPhoneFormSuccessResponse";
  addPhoneFormContext?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  deletePhoneNumberButton?: Maybe<IdentityDeleteButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  marketingCheckBox?: Maybe<IdentityBasicCheckBox>;
  phoneNumberField?: Maybe<IdentityPhoneNumberField>;
  sendCodeButton?: Maybe<IdentityPrimaryButton>;
  skipNowButton?: Maybe<IdentityTertiaryButton>;
};

export type IdentityAddPhoneResendOtpSubmitFailureResponse = {
  __typename?: "IdentityAddPhoneResendOTPSubmitFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
  resendOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export interface IdentityAddPhoneResendOtpSubmitRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  resendOTPContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAddPhoneResendOtpSubmitResponse =
  | IdentityAddPhoneResendOtpSubmitFailureResponse
  | IdentityAddPhoneResendOtpSubmitSuccessResponse;

export type IdentityAddPhoneResendOtpSubmitSuccessResponse = {
  __typename?: "IdentityAddPhoneResendOTPSubmitSuccessResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  resendOTPContext?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type IdentityAddPhoneSendOtpSubmitFailureResponse = {
  __typename?: "IdentityAddPhoneSendOTPSubmitFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
};

export interface IdentityAddPhoneSendOtpSubmitRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  phoneNumber: Scalars["String"]["input"];
  sendOTPContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAddPhoneSendOtpSubmitResponse =
  | IdentityAddPhoneSendOtpSubmitFailureResponse
  | IdentityAddPhoneSendOtpSubmitSuccessResponse;

export type IdentityAddPhoneSendOtpSubmitSuccessResponse = {
  __typename?: "IdentityAddPhoneSendOTPSubmitSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  backButtonATOWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  continueButton?: Maybe<IdentityPrimaryButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  oneTimePasscodeField?: Maybe<IdentityNumberInputField>;
  progressBar?: Maybe<IdentityProgressLoader>;
  resendCodeButton?: Maybe<IdentityResendCodeButton>;
  sendOTPContext?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<IdentityToolbar>;
};

export type IdentityAddPhoneVerifyOtpFormResponse = {
  __typename?: "IdentityAddPhoneVerifyOTPFormResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  verifyOTPFormContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityAddPhoneVerifyOtpSubmitFailureResponse = {
  __typename?: "IdentityAddPhoneVerifyOTPSubmitFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
};

export interface IdentityAddPhoneVerifyOtpSubmitRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  oneTimePasscode: Scalars["String"]["input"];
  verifyOTPContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAddPhoneVerifyOtpSubmitResponse =
  | IdentityAddPhoneVerifyOtpSubmitFailureResponse
  | IdentityAddPhoneVerifyOtpSubmitSuccessResponse;

export type IdentityAddPhoneVerifyOtpSubmitSuccessResponse = {
  __typename?: "IdentityAddPhoneVerifyOTPSubmitSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  redirectionContext?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  toast?: Maybe<EgdsToast>;
};

export type IdentityAnalyticsEvent =
  | IdentityAnalyticsImpressionEvent
  | IdentityAnalyticsInteractionEvent
  | IdentityAnalyticsOutcomeEvent;

export type IdentityAnalyticsImpressionEvent = {
  __typename?: "IdentityAnalyticsImpressionEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type IdentityAnalyticsInteractionEvent = {
  __typename?: "IdentityAnalyticsInteractionEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type IdentityAnalyticsOutcomeEvent = {
  __typename?: "IdentityAnalyticsOutcomeEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type IdentityAnalyticsPayloadType = "EG_CLICK_STREAM" | "UIS_PRIME";

export interface IdentityAuthenticateByOtpFormRequestInput {
  emailInput?: InputMaybe<Scalars["String"]["input"]>;
  verifyOTPFormContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAuthenticateByOtpFormResponse = {
  __typename?: "IdentityAuthenticateByOTPFormResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  continueButton?: Maybe<IdentityPrimaryButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  oneTimePasscodeField?: Maybe<IdentityNumberInputField>;
  otherOptionsButton?: Maybe<IdentityOtherOptionsButton>;
  progressBar?: Maybe<IdentityProgressLoader>;
  rememberMeCheckbox?: Maybe<IdentityBasicCheckBox>;
  resendCodeButton?: Maybe<IdentityResendCodeButton>;
  toolbar?: Maybe<IdentityToolbar>;
  usePasswordButton?: Maybe<IdentityTertiaryButton>;
};

export interface IdentityAuthenticateByPasswordFormRequestInput {
  emailInput?: InputMaybe<Scalars["String"]["input"]>;
  loadPasswordFormContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityAuthenticateByPasswordFormResponse = {
  __typename?: "IdentityAuthenticateByPasswordFormResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  emailAuthButton?: Maybe<IdentityTertiaryButton>;
  emailLabel?: Maybe<Scalars["String"]["output"]>;
  emailValue?: Maybe<EgdsEmailInputField>;
  forgotPasswordLink?: Maybe<IdentitySpannableText>;
  heading?: Maybe<Scalars["String"]["output"]>;
  otherOptionsButton?: Maybe<IdentityOtherOptionsButton>;
  passwordInputField?: Maybe<IdentityPasswordInputField>;
  progressBar?: Maybe<IdentityProgressLoader>;
  rememberMeCheckbox?: Maybe<IdentityBasicCheckBox>;
  signInButton?: Maybe<IdentityPrimaryButton>;
  toolbar?: Maybe<IdentityToolbar>;
};

export type IdentityAuthenticationScenario = "CHECKOUT_SIGNIN" | "CHECKOUT_SIGNUP" | "PROFILE" | "SIGNIN" | "SIGNUP";

export type IdentityAvailabilityRule = {
  __typename?: "IdentityAvailabilityRule";
  elements: Array<Scalars["String"]["output"]>;
  initialAvailability: Scalars["Boolean"]["output"];
};

export type IdentityBasicBlock = EgdsElement &
  EgdsElements &
  IdentityBlock & {
    __typename?: "IdentityBasicBlock";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsElement>;
    properties?: Maybe<IdentityBlockProperties>;
  };

export type IdentityBasicBlockProperties = IdentityBlockProperties & {
  __typename?: "IdentityBasicBlockProperties";
  alignment?: Maybe<Alignment>;
  enabled: Scalars["Boolean"]["output"];
  hidden: Scalars["Boolean"]["output"];
  internalSpace?: Maybe<IdentitySpaceSize>;
  topSpace?: Maybe<IdentitySpaceSize>;
};

export type IdentityBasicCheckBox = {
  __typename?: "IdentityBasicCheckBox";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  input: EgdsBasicCheckBox;
};

export type IdentityBasicSelect = {
  __typename?: "IdentityBasicSelect";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  input: EgdsBasicSelect;
  validations?: Maybe<Array<IdentityValidationRule>>;
};

export interface IdentityBlock {
  properties?: Maybe<IdentityBlockProperties>;
}

export interface IdentityBlockProperties {
  hidden: Scalars["Boolean"]["output"];
  topSpace?: Maybe<IdentitySpaceSize>;
}

export type IdentityButton = EgdsElement & {
  __typename?: "IdentityButton";
  action: IdentityAction;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  element: EgdsButton;
  rules: Array<IdentityRule>;
};

export type IdentityButtonAction =
  | IdentityCancelAction
  | IdentityDeleteAction
  | IdentityLoadVerifyPasswordAction
  | IdentityResendEmailOtpAction
  | IdentityResendOtpAction
  | IdentityResendSmsotpAction
  | IdentitySendCodeAction
  | IdentitySendEmailOtpAction
  | IdentitySendSmsotpAction
  | IdentitySkipNowAction
  | IdentityVerifyEmailOtpAction
  | IdentityVerifyOtpAction
  | IdentityVerifyPasswordAction
  | IdentityVerifySmsotpAction;

export type IdentityButtonsSheet = {
  __typename?: "IdentityButtonsSheet";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  buttons?: Maybe<Array<IdentitySecondaryButton>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  sheet: EgdsSheet;
  toolbar?: Maybe<IdentityToolbar>;
};

export type IdentityCsrfAto = {
  __typename?: "IdentityCSRFAto";
  placement: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
  type: IdentityAccountTakeOverType;
};

export type IdentityCancelAction = {
  __typename?: "IdentityCancelAction";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
};

export type IdentityCaptchaArkoseMobileAppWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCaptchaArkoseMobileAppWidgetAction";
  inputKeys: Array<Scalars["String"]["output"]>;
  publicKey: Scalars["String"]["output"];
  type: IdentityAccountTakeOverType;
};

export type IdentityCaptchaSafetyNetAndroidWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCaptchaSafetyNetAndroidWidgetAction";
  inputKeys: Array<Scalars["String"]["output"]>;
  siteKey: Scalars["String"]["output"];
  type: IdentityAccountTakeOverType;
};

export type IdentityCaptchaWebWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCaptchaWebWidgetAction";
  inputKeys: Array<Scalars["String"]["output"]>;
  links?: Maybe<Array<IdentityAtoLink>>;
  placementContext?: Maybe<PlacementContext>;
  publicKey: Scalars["String"]["output"];
  type: IdentityAccountTakeOverType;
};

export type IdentityChannelType = "ANDROID" | "DESKTOP" | "IOS";

export interface IdentityClientContextInput {
  pageLocation?: InputMaybe<PageLocation>;
}

export interface IdentityClientInfoInput {
  appName: Scalars["String"]["input"];
  appVersion: Scalars["String"]["input"];
  channelType: IdentityChannelType;
  deviceOS: Scalars["String"]["input"];
  deviceType: DeviceType;
  pageLocation?: InputMaybe<PageLocation>;
}

export type IdentityCommonWordsValidation = EgdsInputValidation & {
  __typename?: "IdentityCommonWordsValidation";
  errorMessage: Scalars["String"]["output"];
};

export type IdentityComponentLayout =
  | IdentityForgotPasswordComponentLayout
  | IdentityResetPasswordComponentLayout
  | IdentitySingleColumnComponentLayout
  | IdentitySuccessComponentLayout;

export type IdentityConsentNestedCheckBox = EgdsElement & {
  __typename?: "IdentityConsentNestedCheckBox";
  egdsElementId: Scalars["String"]["output"];
  selectAllCheckbox: EgdsBasicCheckBox;
  subCheckboxes: Array<EgdsBasicCheckBox>;
  validationRules?: Maybe<Array<EgdsInputValidation>>;
};

export type IdentityCreateAccountErrorResponse = {
  __typename?: "IdentityCreateAccountErrorResponse";
  accountTakeOverShield: IdentityAccountTakeOverShield;
  error: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export interface IdentityCreateAccountRequestInput {
  accountTakeOverShield: IdentityAccountTakeOverShieldInput;
  channelType?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phoneDetails?: InputMaybe<IdentityPhoneDetailsInput>;
  rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type IdentityCreateAccountSuccessResponse = {
  __typename?: "IdentityCreateAccountSuccessResponse";
  accountTakeOverShield: IdentityAccountTakeOverShield;
  accountType: IdentityAccountCreationType;
  accountUpgradeStatus: IdentityAccountCreationState;
  egUserId: Scalars["String"]["output"];
};

export type IdentityCrossSiteRequestForgery = {
  __typename?: "IdentityCrossSiteRequestForgery";
  token: Scalars["String"]["output"];
};

export interface IdentityCrossSiteRequestForgeryInput {
  token: Scalars["String"]["input"];
}

export type IdentityCsrfWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityCsrfWidgetAction";
  inputKeys: Array<Scalars["String"]["output"]>;
  token: Scalars["String"]["output"];
  type: IdentityAccountTakeOverType;
};

export interface IdentityCustomValidationRule {
  message: Scalars["String"]["output"];
  theme: IdentityCustomValidationTheme;
}

export type IdentityCustomValidationTheme = "ERROR" | "INFO" | "WARNING";

export type IdentityDeleteAction = {
  __typename?: "IdentityDeleteAction";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  deleteContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityDeleteButton = {
  __typename?: "IdentityDeleteButton";
  button: UiTertiaryButton;
  clickAction?: Maybe<IdentityDeleteDialogAction>;
};

export type IdentityDeleteDialog = {
  __typename?: "IdentityDeleteDialog";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  cancelButton?: Maybe<IdentityTertiaryButton>;
  deleteButton?: Maybe<IdentityPrimaryButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityDeleteDialogAction = {
  __typename?: "IdentityDeleteDialogAction";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  dialog: IdentityDeleteDialog;
};

export type IdentityDeviceCheckWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityDeviceCheckWidgetAction";
  inputKeys: Array<Scalars["String"]["output"]>;
  type: IdentityAccountTakeOverType;
};

export type IdentityEmailInputField = EgdsElement & {
  __typename?: "IdentityEmailInputField";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  input?: Maybe<EgdsEmailInputField>;
};

export interface IdentityErrorField {
  elementId: Scalars["String"]["output"];
  errorText: Scalars["String"]["output"];
}

export type IdentityErrorResponse = IdentityResponse & {
  __typename?: "IdentityErrorResponse";
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  errorFields: Array<IdentityErrorField>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  updateAction?: Maybe<IdentityUpdateComponentAction>;
};

export type IdentityErrorSummaryBanner = {
  __typename?: "IdentityErrorSummaryBanner";
  summary?: Maybe<UiBanner>;
};

export type IdentityFlowType = "ADD" | "RE_AUTHENTICATE" | "UPDATE" | "VERIFY";

export type IdentityForbiddenInputsValidation = EgdsInputValidation & {
  __typename?: "IdentityForbiddenInputsValidation";
  errorMessage: Scalars["String"]["output"];
  inputIds: Array<Scalars["String"]["output"]>;
};

export type IdentityForgotPasswordComponentLayout = {
  __typename?: "IdentityForgotPasswordComponentLayout";
  description: EgdsSpannableText;
  emailInputField: EgdsEmailInputField;
  errorSummaryCard: EgdsStandardMessagingCard;
  heading: EgdsHeading;
  sendEmailButton: IdentityButton;
  toolbar?: Maybe<UiToolbar>;
};

export type IdentityGoogleOneTapAuthConfiguration = IdentitySocialAuthConfiguration & {
  __typename?: "IdentityGoogleOneTapAuthConfiguration";
  clientId?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityGoogleOneTapMutationResponse = IdentityResponse & {
  __typename?: "IdentityGoogleOneTapMutationResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  cmsToken?: Maybe<Scalars["String"]["output"]>;
  flow?: Maybe<Scalars["String"]["output"]>;
  scenario?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  variant?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityGoogleOneTapResponse = IdentityResponse & {
  __typename?: "IdentityGoogleOneTapResponse";
  action: IdentityOneTapSubmitAction;
  allowIntelligentTrackingPrevention?: Maybe<Scalars["Boolean"]["output"]>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  configuration: IdentitySocialAuthConfiguration;
  title: Scalars["String"]["output"];
};

export type IdentityImage = EgdsElement & {
  __typename?: "IdentityImage";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  overlayToolbar?: Maybe<UiToolbar>;
};

export type IdentityImageGallery = EgdsElement & {
  __typename?: "IdentityImageGallery";
  egdsElementId: Scalars["String"]["output"];
  markTokenList: Array<EgdsMark>;
  placement?: Maybe<Placement>;
};

export type IdentityInfoLinkComponent = EgdsElement & {
  __typename?: "IdentityInfoLinkComponent";
  contactUs: EgdsStandardLink;
  deleteData: EgdsStandardLink;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  traderInformation: IdentityTraderInfoLink;
};

export type IdentityInlineLink = IdentitySpannableTextItem & {
  __typename?: "IdentityInlineLink";
  action: IdentityLinkAction;
  text: Scalars["String"]["output"];
};

export type IdentityInlineLinkAction = {
  __typename?: "IdentityInlineLinkAction";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export interface IdentityInput {
  authState?: InputMaybe<AuthenticationState>;
  duaid: Scalars["UUID"]["input"];
  expUserId?: InputMaybe<Scalars["String"]["input"]>;
  tuid?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityLayout =
  | "APP_ONBOARDING"
  | "FORGOT_PASSWORD"
  | "GET_RESENT_OTP_ATO"
  | "GET_SEND_OTP_ATO"
  | "GET_SET_PASSWORD_ATO"
  | "GET_SIGN_IN_ATO"
  | "GET_SIGN_IN_VERIFY_OTP_ATO"
  | "GET_SIGN_UP_VERIFY_OTP_ATO"
  | "GET_SOCIAL_LOGIN_APPLE_ATO"
  | "GET_SOCIAL_LOGIN_FACEBOOK_ATO"
  | "GET_SOCIAL_LOGIN_GOOGLE_ATO"
  | "GET_USER_DETAILS_ATO"
  | "HYBRID_LOGIN"
  | "LAUNCH_ONE_IDENTITY"
  | "RESET_PASSWORD"
  | "SIGNIN"
  | "SIGNUP";

export type IdentityLayoutSection = {
  __typename?: "IdentityLayoutSection";
  blocks: Array<IdentityBlock>;
};

export type IdentityLegalConsentNestedCheckBox = EgdsElement & {
  __typename?: "IdentityLegalConsentNestedCheckBox";
  egdsElementId: Scalars["String"]["output"];
  rootCheckBox?: Maybe<EgdsBasicCheckBox>;
  subCheckboxes?: Maybe<Array<EgdsBasicCheckBox>>;
  validationRules?: Maybe<Array<EgdsInputValidation>>;
};

export type IdentityLegalConsentPopupComponent = EgdsElement & {
  __typename?: "IdentityLegalConsentPopupComponent";
  cancelButton?: Maybe<IdentityButton>;
  consentCheckBox?: Maybe<IdentityLegalConsentNestedCheckBox>;
  continueButton?: Maybe<IdentityButton>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  egdsSheet?: Maybe<EgdsSheet>;
};

export type IdentityLegalPopupAction = IdentityAction & {
  __typename?: "IdentityLegalPopupAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type IdentityLinkAction = IdentityInlineLinkAction;

export type IdentityLoadVerifyPasswordAction = {
  __typename?: "IdentityLoadVerifyPasswordAction";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  loadVerifyPasswordContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityLoginScenario = "SIGN_IN" | "SIGN_UP";

export type IdentityLoginType = "MANAGE" | "PROFILE" | "SETTINGS" | "SIGN_IN" | "SIGN_UP" | "UPDATE";

export type IdentityLogoutResponse = {
  __typename?: "IdentityLogoutResponse";
  status?: Maybe<LogoutStatus>;
};

export type IdentityMatchValidationRule = IdentityCustomValidationRule & {
  __typename?: "IdentityMatchValidationRule";
  matchString: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  theme: IdentityCustomValidationTheme;
};

export type IdentityNoErrorRule = {
  __typename?: "IdentityNoErrorRule";
  elements: Array<Scalars["String"]["output"]>;
};

export type IdentityNumberInputField = {
  __typename?: "IdentityNumberInputField";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  customValidations?: Maybe<Array<IdentityCustomValidationRule>>;
  input: EgdsNumberInputField;
  validations?: Maybe<Array<IdentityValidationRule>>;
};

export type IdentityOneTapSelection = {
  __typename?: "IdentityOneTapSelection";
  cancelAnalytics: ClientSideAnalytics;
  clientFailureAnalytics: ClientSideAnalytics;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  emailInputId: Scalars["String"]["output"];
  googleSocialButtonId?: Maybe<Scalars["String"]["output"]>;
  inputIdsMapping?: Maybe<IdentitySignInInputIdsMapping>;
  submitAction: IdentityAction;
  submitButtonId: Scalars["String"]["output"];
};

export type IdentityOneTapSubmitAction = IdentityAction & {
  __typename?: "IdentityOneTapSubmitAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  atoActions: Array<IdentityAccountTakeOverWidgetAction>;
  clickStreamAnalytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  context: Scalars["String"]["output"];
  inputIds: Array<Scalars["String"]["output"]>;
  nonce: Scalars["String"]["output"];
  requestInputPairList: Array<IdentityRequestInputPair>;
  socialType: IdentitySocialType;
};

export type IdentityOpenSheetAction = {
  __typename?: "IdentityOpenSheetAction";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  sheet: IdentityButtonsSheet;
};

export type IdentityOtherOptionsButton = {
  __typename?: "IdentityOtherOptionsButton";
  button: UiTertiaryButton;
  clickAction?: Maybe<IdentityOpenSheetAction>;
};

export type IdentityOtherOptionsSignInAction = IdentityAction & {
  __typename?: "IdentityOtherOptionsSignInAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  redirectionLayout?: Maybe<IdentityLayout>;
};

export type IdentityPageName = "PROFILE" | "SETTINGS";

export type IdentityPasswordInputField = {
  __typename?: "IdentityPasswordInputField";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  passwordInput: EgdsPasswordInputField;
};

export type IdentityPasswordStrengthComponent = EgdsElement & {
  __typename?: "IdentityPasswordStrengthComponent";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  defaultAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  errorAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  excludeValidationsList: IdentityValidationList;
  includeValidationsList: IdentityValidationList;
  passwordInput: EgdsPasswordInputField;
  progressBar: IdentityProgressBar;
};

export interface IdentityPhoneDetailsInput {
  countryCode: Scalars["String"]["input"];
  locale: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
}

export type IdentityPhoneNumberField = {
  __typename?: "IdentityPhoneNumberField";
  countryCode: IdentityBasicSelect;
  phoneNumber: IdentityNumberInputField;
};

export type IdentityPlainText = IdentitySpannableTextItem & {
  __typename?: "IdentityPlainText";
  text: Scalars["String"]["output"];
};

export type IdentityPrimaryButton = {
  __typename?: "IdentityPrimaryButton";
  action: IdentityButtonAction;
  button: UiPrimaryButton;
};

export type IdentityProgressBar = {
  __typename?: "IdentityProgressBar";
  states: Array<IdentityValidationState>;
  title: Scalars["String"]["output"];
};

export type IdentityProgressLoader = {
  __typename?: "IdentityProgressLoader";
  progressBar: EgdsProgressBar;
};

export type IdentityRefreshResponse = {
  __typename?: "IdentityRefreshResponse";
  action: RefreshAction;
  loyaltyMembershipInfo?: Maybe<LoyaltyMembershipInfo>;
  profile?: Maybe<Profile>;
};

export type IdentityRegexCustomValidation = EgdsInputValidation & {
  __typename?: "IdentityRegexCustomValidation";
  errorMessage: Scalars["String"]["output"];
  pattern: Scalars["String"]["output"];
  successAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type IdentityRegexValidationRule = IdentityValidationRule & {
  __typename?: "IdentityRegexValidationRule";
  errorMessage: Scalars["String"]["output"];
  regex: Scalars["String"]["output"];
};

export type IdentityRequestInputPair = {
  __typename?: "IdentityRequestInputPair";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type IdentityResendButton = EgdsElement & {
  __typename?: "IdentityResendButton";
  action: IdentityAction;
  buttonElement: EgdsButton;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  infoText?: Maybe<Scalars["String"]["output"]>;
  maxTimerInSeconds: Scalars["Int"]["output"];
  timerText: Scalars["String"]["output"];
};

export type IdentityResendCodeButton = {
  __typename?: "IdentityResendCodeButton";
  action: IdentityButtonAction;
  button: UiTertiaryButton;
  description?: Maybe<Scalars["String"]["output"]>;
  maxTimerInSeconds: Scalars["Int"]["output"];
  timerText: Scalars["String"]["output"];
};

export type IdentityResendEmailOtpAction = {
  __typename?: "IdentityResendEmailOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  resendEmailOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityResendOtpAction = {
  __typename?: "IdentityResendOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  resendOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityResendOtpAuthenticationFailureResponse = {
  __typename?: "IdentityResendOTPAuthenticationFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
};

export interface IdentityResendOtpAuthenticationRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  resendOTPContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityResendOtpAuthenticationResponse =
  | IdentityResendOtpAuthenticationFailureResponse
  | IdentityResendOtpAuthenticationSuccessResponse;

export type IdentityResendOtpAuthenticationSuccessResponse = {
  __typename?: "IdentityResendOTPAuthenticationSuccessResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  resendOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityResendPasscodeSubmitResponse = IdentityResponse & {
  __typename?: "IdentityResendPasscodeSubmitResponse";
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  updateAction?: Maybe<IdentityUpdateComponentAction>;
  updateBlock: Array<IdentityUpdateBlock>;
  updateFields: Array<IdentityUpdateSummary>;
};

export type IdentityResendSmsotpAction = {
  __typename?: "IdentityResendSMSOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  resendSMSOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityResetPasswordComponentLayout = {
  __typename?: "IdentityResetPasswordComponentLayout";
  errorSummaryCard: EgdsStandardMessagingCard;
  heading: EgdsHeading;
  passwordInputField: EgdsPasswordInputField;
  savePasswordButton: IdentityButton;
  toolbar?: Maybe<UiToolbar>;
};

export interface IdentityResponse {
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
}

export type IdentityRetrieveAtoResponse = IdentityResponse & {
  __typename?: "IdentityRetrieveATOResponse";
  atoActions?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  status: Scalars["Boolean"]["output"];
};

export type IdentityRule = IdentityAvailabilityRule | IdentityNoErrorRule | IdentityVisibilityRule;

export type IdentitySaveCredentialsInputIdsMapping = {
  __typename?: "IdentitySaveCredentialsInputIdsMapping";
  password: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

export type IdentitySecondaryButton = {
  __typename?: "IdentitySecondaryButton";
  action: IdentityButtonAction;
  button: UiSecondaryButton;
};

export type IdentitySendCodeAction = {
  __typename?: "IdentitySendCodeAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  sendCodeContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentitySendEmailOtpAction = {
  __typename?: "IdentitySendEmailOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  sendEmailOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentitySendOtpAuthenticationFailureResponse = {
  __typename?: "IdentitySendOTPAuthenticationFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
};

export interface IdentitySendOtpAuthenticationRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  sendOTPContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentitySendOtpAuthenticationResponse =
  | IdentitySendOtpAuthenticationFailureResponse
  | IdentitySendOtpAuthenticationSuccessResponse;

export type IdentitySendOtpAuthenticationSuccessResponse = {
  __typename?: "IdentitySendOTPAuthenticationSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  backButtonATOWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  sendOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentitySendSmsotpAction = {
  __typename?: "IdentitySendSMSOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  sendSMSOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentitySession = {
  __typename?: "IdentitySession";
  domain: Scalars["String"]["output"];
  expires?: Maybe<Scalars["String"]["output"]>;
  httpOnly: Scalars["Boolean"]["output"];
  maxAge: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
  secure: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export type IdentitySessionAction = IdentityAction & {
  __typename?: "IdentitySessionAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  session: Array<IdentitySession>;
};

export type IdentitySignInInputIdsMapping = {
  __typename?: "IdentitySignInInputIdsMapping";
  password: Scalars["String"]["output"];
  userName: Scalars["String"]["output"];
};

export type IdentitySingleColumnComponentLayout = EgdsElement & {
  __typename?: "IdentitySingleColumnComponentLayout";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  identityOneTapSelection?: Maybe<IdentityOneTapSelection>;
  main?: Maybe<IdentityLayoutSection>;
  popupLayoutSection?: Maybe<IdentityLayoutSection>;
  toolbar?: Maybe<UiToolbar>;
  top?: Maybe<IdentityLayoutSection>;
};

export type IdentitySkipNowAction = {
  __typename?: "IdentitySkipNowAction";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
};

export interface IdentitySocialAuthConfiguration {
  clientId?: Maybe<Scalars["String"]["output"]>;
}

export type IdentitySocialButton = EgdsElement & {
  __typename?: "IdentitySocialButton";
  action: IdentitySocialSubmitAction;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  inputIdsMapping?: Maybe<IdentitySocialSignInInputIdsMapping>;
  label: Scalars["String"]["output"];
  mark: Mark;
  type: IdentitySocialButtonType;
};

export type IdentitySocialButtonType = "PRIMARY" | "SECONDARY";

export interface IdentitySocialInput {
  nonce: Scalars["String"]["input"];
  socialType: IdentitySocialType;
  token: Scalars["String"]["input"];
  tokenType: IdentitySocialTokenType;
}

export type IdentitySocialSignInInputIdsMapping = {
  __typename?: "IdentitySocialSignInInputIdsMapping";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type IdentitySocialSubmitAction = IdentityAction & {
  __typename?: "IdentitySocialSubmitAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  atoActions: Array<IdentityAccountTakeOverWidgetAction>;
  cancelAnalytics: ClientSideAnalytics;
  clickStreamAnalytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  clientFailureAnalytics: ClientSideAnalytics;
  context: Scalars["String"]["output"];
  inputIds: Array<Scalars["String"]["output"]>;
  nonce: Scalars["String"]["output"];
  popupShownAnalytics?: Maybe<ClientSideAnalytics>;
  preAction?: Maybe<IdentitySubmitPreAction>;
  requestInputPairList: Array<IdentityRequestInputPair>;
  retrieveAtoLayout?: Maybe<Scalars["String"]["output"]>;
  socialType: IdentitySocialType;
  submitAnalytics: ClientSideAnalytics;
};

export type IdentitySocialTokenType = "Access" | "AuthCode" | "ID";

export type IdentitySocialType = "APPLE" | "FACEBOOK" | "GOOGLE";

export type IdentitySpaceSize =
  | "S0X"
  | "S0XH"
  | "S1X"
  | "S2X"
  | "S3X"
  | "S4X"
  | "S5X"
  | "S6X"
  | "S8X"
  | "S10X"
  | "S11X"
  | "S12X"
  | "S14X"
  | "S16X"
  | "S18X";

export type IdentitySpannableText = {
  __typename?: "IdentitySpannableText";
  inlineContent: Array<IdentitySpannableTextItem>;
};

export interface IdentitySpannableTextItem {
  text: Scalars["String"]["output"];
}

export type IdentitySubmitAction = IdentityAction & {
  __typename?: "IdentitySubmitAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  atoActions: Array<IdentityAccountTakeOverWidgetAction>;
  clickStreamAnalytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  context: Scalars["String"]["output"];
  inputIds: Array<Scalars["String"]["output"]>;
  requestInputPairList: Array<IdentityRequestInputPair>;
  retrieveAtoLayout?: Maybe<Scalars["String"]["output"]>;
  saveCredentialsInputIds?: Maybe<IdentitySaveCredentialsInputIdsMapping>;
};

export type IdentitySubmitPreAction = {
  __typename?: "IdentitySubmitPreAction";
  showLegalPopup?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface IdentitySubmitRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  context: Scalars["String"]["input"];
  input: Array<GraphQlPairInput>;
  socialInput?: InputMaybe<IdentitySocialInput>;
}

export type IdentitySuccessComponentLayout = {
  __typename?: "IdentitySuccessComponentLayout";
  icon: Icon;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type IdentitySuccessResponse = IdentityResponse & {
  __typename?: "IdentitySuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  analyticsPayloadType?: Maybe<IdentityAnalyticsPayloadType>;
  clientSideImpressionEventAnalytics: ClientSideImpressionEventAnalytics;
  identityComponentLayout?: Maybe<IdentityComponentLayout>;
  identityLoginScenario?: Maybe<IdentityLoginScenario>;
  identityRedirectAction?: Maybe<UiLinkAction>;
  identitySaveCredentials?: Maybe<Scalars["Boolean"]["output"]>;
  identitySessionAction?: Maybe<IdentitySessionAction>;
  identitySuccessType?: Maybe<IdentitySuccessType>;
  loyaltyMembershipInfo?: Maybe<LoyaltyMembershipInfo>;
  profile?: Maybe<Profile>;
};

export type IdentitySuccessType = "ONE_KEY_ONBOARDING" | "ORIGIN";

export type IdentityTertiaryButton = {
  __typename?: "IdentityTertiaryButton";
  action: IdentityButtonAction;
  button: UiTertiaryButton;
};

export type IdentityText = EgdsElement & {
  __typename?: "IdentityText";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  text: EgdsText;
};

export type IdentityToolbar = {
  __typename?: "IdentityToolbar";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  toolbar: UiToolbar;
};

export type IdentityTraderInfoAction = IdentityAction & {
  __typename?: "IdentityTraderInfoAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionResponse: IdentityTraderInfoComponent;
  analytics: ClientSideAnalytics;
};

export type IdentityTraderInfoComponent = {
  __typename?: "IdentityTraderInfoComponent";
  contents: Array<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
};

export type IdentityTraderInfoLink = {
  __typename?: "IdentityTraderInfoLink";
  action: IdentityTraderInfoAction;
  label: Scalars["String"]["output"];
};

export type IdentityTrustWidgetAction = IdentityAccountTakeOverWidgetAction & {
  __typename?: "IdentityTrustWidgetAction";
  brandName?: Maybe<Scalars["String"]["output"]>;
  inputKeys: Array<Scalars["String"]["output"]>;
  links?: Maybe<Array<IdentityAtoLink>>;
  placementId?: Maybe<Scalars["String"]["output"]>;
  type: IdentityAccountTakeOverType;
};

export type IdentityUpdateBlock = {
  __typename?: "IdentityUpdateBlock";
  block?: Maybe<IdentityBlock>;
  blockId: Scalars["String"]["output"];
  properties?: Maybe<IdentityBasicBlockProperties>;
};

export type IdentityUpdateComponentAction = {
  __typename?: "IdentityUpdateComponentAction";
  action: IdentityAction;
  actionForElement: Scalars["String"]["output"];
};

export type IdentityUpdateErrorSummary = IdentityErrorField & {
  __typename?: "IdentityUpdateErrorSummary";
  blockId: Scalars["String"]["output"];
  elementId: Scalars["String"]["output"];
  errorText: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityUpdateSummary = {
  __typename?: "IdentityUpdateSummary";
  blockId: Scalars["String"]["output"];
  elementId: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  updateText: Scalars["String"]["output"];
};

export type IdentityUserAuthenticatorsListNoActionResponse = {
  __typename?: "IdentityUserAuthenticatorsListNoActionResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
};

export interface IdentityUserAuthenticatorsListRequestInput {
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  optionalClientContext?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityUserAuthenticatorsListResponse =
  | IdentityUserAuthenticatorsListNoActionResponse
  | IdentityUserAuthenticatorsListSuccessResponse;

export type IdentityUserAuthenticatorsListSuccessResponse = {
  __typename?: "IdentityUserAuthenticatorsListSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  otherOptionsButton?: Maybe<IdentityOtherOptionsButton>;
  progressBar?: Maybe<IdentityProgressLoader>;
  sendCodeButton?: Maybe<IdentityPrimaryButton>;
  sendOTPContext?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<IdentityToolbar>;
};

export type IdentityValidationList = {
  __typename?: "IdentityValidationList";
  icon: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  validations: Array<EgdsInputValidation>;
};

export interface IdentityValidationRule {
  errorMessage: Scalars["String"]["output"];
}

export type IdentityValidationState = {
  __typename?: "IdentityValidationState";
  stateAccessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  strengthIndicator: Scalars["String"]["output"];
  validationSum: Scalars["Int"]["output"];
};

export type IdentityVerifyEmailOtpAction = {
  __typename?: "IdentityVerifyEmailOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  verifyEmailOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityVerifyOtpAction = {
  __typename?: "IdentityVerifyOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  verifyOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityVerifyOtpAuthenticationFailureResponse = {
  __typename?: "IdentityVerifyOTPAuthenticationFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
};

export interface IdentityVerifyOtpAuthenticationRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  oneTimePasscode: Scalars["String"]["input"];
  rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  verifyOTPContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityVerifyOtpAuthenticationResponse =
  | IdentityVerifyOtpAuthenticationFailureResponse
  | IdentityVerifyOtpAuthenticationSuccessResponse;

export type IdentityVerifyOtpAuthenticationSuccessResponse = {
  __typename?: "IdentityVerifyOTPAuthenticationSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  flowType?: Maybe<IdentityFlowType>;
  loginType?: Maybe<IdentityLoginType>;
  loyaltyMembershipInfo?: Maybe<LoyaltyMembershipInfo>;
  pageName?: Maybe<IdentityPageName>;
  profile?: Maybe<Profile>;
  redirectionContext?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type IdentityVerifyPasswordAction = {
  __typename?: "IdentityVerifyPasswordAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  verifyPasswordContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityVerifyPasswordAuthenticationFailureResponse = {
  __typename?: "IdentityVerifyPasswordAuthenticationFailureResponse";
  accountTakeOverWidgets?: Maybe<Array<IdentityAccountTakeOverWidgetAction>>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  banner?: Maybe<IdentityErrorSummaryBanner>;
};

export type IdentityVerifyPasswordAuthenticationResponse =
  | IdentityVerifyPasswordAuthenticationFailureResponse
  | IdentityVerifyPasswordAuthenticationSuccessResponse;

export type IdentityVerifyPasswordAuthenticationSuccessResponse = {
  __typename?: "IdentityVerifyPasswordAuthenticationSuccessResponse";
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  flowType?: Maybe<IdentityFlowType>;
  loginType?: Maybe<IdentityLoginType>;
  loyaltyMembershipInfo?: Maybe<LoyaltyMembershipInfo>;
  pageName?: Maybe<IdentityPageName>;
  profile?: Maybe<Profile>;
  redirectionContext?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface IdentityVerifyPasswordPAuthenticationRequestInput {
  atoInputs: Array<IdentityAccountTakeOverInput>;
  password: Scalars["String"]["input"];
  rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
  verifyPasswordContext?: InputMaybe<Scalars["String"]["input"]>;
}

export type IdentityVerifySmsotpAction = {
  __typename?: "IdentityVerifySMSOTPAction";
  accountTakeOverWidgets: Array<IdentityAccountTakeOverWidgetAction>;
  analytics?: Maybe<Array<IdentityAnalyticsEvent>>;
  verifySMSOTPContext?: Maybe<Scalars["String"]["output"]>;
};

export type IdentityVisibilityRule = {
  __typename?: "IdentityVisibilityRule";
  elements: Array<Scalars["String"]["output"]>;
  initialVisibility: Scalars["Boolean"]["output"];
};

export type IdleDelay = {
  __typename?: "IdleDelay";
  inMilliseconds: Scalars["Int"]["output"];
  inSeconds: Scalars["Int"]["output"];
};

export type Illustration = EgdsElement & {
  __typename?: "Illustration";
  description: Scalars["String"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type Image = {
  __typename?: "Image";
  /** @deprecated Use description instead */
  altText?: Maybe<Scalars["String"]["output"]>;
  aspectRatio?: Maybe<AspectRatio>;
  description: Scalars["String"]["output"];
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Scalars["URL"]["output"];
};

export type ImageUrlArgs = {
  dimensions?: InputMaybe<DimensionsInput>;
};

export type ImageAnalytics = {
  __typename?: "ImageAnalytics";
  analytics?: Maybe<ClientSideAnalytics>;
  imageId?: Maybe<Scalars["String"]["output"]>;
  subjectId?: Maybe<Scalars["Int"]["output"]>;
  trackingId?: Maybe<Scalars["String"]["output"]>;
};

export type ImageAssetSelection = {
  __typename?: "ImageAssetSelection";
  groupImages: Scalars["Boolean"]["output"];
  limit: Scalars["Int"]["output"];
};

export type ImageAutoSelectPrompt = {
  __typename?: "ImageAutoSelectPrompt";
  heading: Scalars["String"]["output"];
  icon: Icon;
  learnMoreLink: EgdsInlineLink;
  recommendedBadge: EgdsStandardBadge;
  text: EgdsSpannableText;
  toggleSwitch: EgdsStandardSwitch;
};

export interface ImageDimensionsInput {
  height?: InputMaybe<Scalars["Int"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
}

export type ImagePicker = {
  __typename?: "ImagePicker";
  availableImagesText: Scalars["String"]["output"];
  imageList: Array<EgdsImageCard>;
  selectedImage: EgdsImageCard;
  selectedImageText: Scalars["String"]["output"];
  sheet: EgdsSheet;
  sheetButtons: Array<PortalButton>;
};

export type ImagePosition = "LEFT" | "TOP";

export type ImmersiveViewControls = {
  __typename?: "ImmersiveViewControls";
  closeAccessibility?: Maybe<Scalars["String"]["output"]>;
  helperScreenControls: HelperScreenControls;
  moreOptionControls: MoreOptionControls;
  pagingControls: PagingControls;
  videoControls: VideoControls;
};

export type ImmersiveViewElement = {
  __typename?: "ImmersiveViewElement";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionAccessibility?: Maybe<Scalars["String"]["output"]>;
  affiliateProfile?: Maybe<AffiliateProfile>;
  immersiveViewControls?: Maybe<ImmersiveViewControls>;
  postSummary?: Maybe<PostSummary>;
  progressionBar?: Maybe<ProgressionBar>;
};

export type ImmersiveViewNavigationType = "SWIPE_HORIZONTAL" | "SWIPE_VERTICAL";

export type ImmersiveViewType = {
  __typename?: "ImmersiveViewType";
  autoPlayEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  helperScreenText?: Maybe<Scalars["String"]["output"]>;
  helperScreenUrl?: Maybe<Scalars["URL"]["output"]>;
  navigationType?: Maybe<ImmersiveViewNavigationType>;
  width?: Maybe<Scalars["Int"]["output"]>;
};

export type ImportantInfoContent = EgdsBulletedList | ImportantInfoLineItemList;

export type ImportantInfoDetails = {
  __typename?: "ImportantInfoDetails";
  importantInfoButton: LegalDialogLink;
  importantInfoSection?: Maybe<ImportantInfoSection>;
};

export type ImportantInfoLineItem = {
  __typename?: "ImportantInfoLineItem";
  content: Array<ImportantInfoLineItemContent>;
  subList?: Maybe<EgdsBulletedList>;
};

export type ImportantInfoLineItemContent = EgdsInlineLink | EgdsPlainText | EgdsStylizedText | LegalDialogLink;

export type ImportantInfoLineItemList = {
  __typename?: "ImportantInfoLineItemList";
  listItems: Array<ImportantInfoLineItem>;
};

export type ImportantInfoSection = {
  __typename?: "ImportantInfoSection";
  content: ImportantInfoContent;
  title: Scalars["String"]["output"];
};

export type InboxNotification = ContextualNotification & {
  __typename?: "InboxNotification";
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  displayEndDateTime?: Maybe<NotificationApiDateTime>;
  displayStartDateTime?: Maybe<NotificationApiDateTime>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  revealActions?: Maybe<Array<NotificationAction>>;
  subType?: Maybe<NotificationSubType>;
  timeStamp?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
};

export type InboxNotificationDeleteResponse = {
  __typename?: "InboxNotificationDeleteResponse";
  operationStatus: OperationStatus;
};

export type InboxNotificationUpdateResponse = {
  __typename?: "InboxNotificationUpdateResponse";
  messageType: NotificationMessageType;
  notificationId: Scalars["String"]["output"];
};

export type InboxNotificationUpdateStateResponse = {
  __typename?: "InboxNotificationUpdateStateResponse";
  notificationId: Scalars["String"]["output"];
  notificationState: NotificationState;
  operationStatus: OperationStatus;
};

export interface IncentiveContextInput {
  customerPlacementId: Scalars["String"]["input"];
  loginScenario?: InputMaybe<IdentityLoginScenario>;
}

export type IncentivesActionLink = {
  __typename?: "IncentivesActionLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favour of EG Clickstream. Use 'presentedAnalytics' or 'selectedAnalytics' instead. */
  analytics: ClientSideAnalytics;
  button?: Maybe<EgdsButton>;
  clickAnalytics: ClientSideAnalytics;
  impressionAnalytics: ClientSideAnalytics;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  resource: HttpUri;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesCollection = {
  __typename?: "IncentivesCollection";
  actionLink?: Maybe<IncentivesActionLink>;
  collectionBody?: Maybe<IncentivesCollectionBody>;
  collectionHeader?: Maybe<IncentivesCollectionHeader>;
  collectionItems?: Maybe<Array<IncentivesCollectionItem>>;
  egRecommendationResponseId?: Maybe<Scalars["String"]["output"]>;
  heroImage?: Maybe<CollectionHeroImage>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesCollectionBody =
  | CollectionCarouselHeroTilesBody
  | CollectionCarouselMediaTilesBody
  | CollectionGroupedMediaTilesBody
  | CollectionTabbedCarouselMediaTilesBody
  | CollectionTabbedCarouselTilesBody
  | IncentivesHeroTilesBody
  | IncentivesMediaTilesBody;

export type IncentivesCollectionError = {
  __typename?: "IncentivesCollectionError";
  action?: Maybe<IncentivesActionLink>;
  header?: Maybe<IncentivesCollectionHeader>;
  heroImage?: Maybe<CollectionHeroImage>;
};

export type IncentivesCollectionHeader = {
  __typename?: "IncentivesCollectionHeader";
  carouselCTAButton?: Maybe<IncentivesActionLink>;
  collectionGraphicLink?: Maybe<IncentivesMediaLink>;
  description?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  termsAndConditionsDialog?: Maybe<TermsAndConditions>;
  title: Scalars["String"]["output"];
};

export type IncentivesCollectionItem = IncentivesContentItem;

export type IncentivesCollectionSuccess = {
  __typename?: "IncentivesCollectionSuccess";
  collectionBody?: Maybe<IncentivesCollectionBody>;
  collectionItems?: Maybe<Array<IncentivesCollectionItem>>;
  egRecommendationResponseId?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<IncentivesCollectionHeader>;
  heroImage?: Maybe<CollectionHeroImage>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesCollectionView = IncentivesCollectionError | IncentivesCollectionSuccess;

export type IncentivesContentItem = {
  __typename?: "IncentivesContentItem";
  actionLink: IncentivesActionLink;
  cardSize: CardSize;
  description?: Maybe<Scalars["String"]["output"]>;
  incentivesImage: IncentivesImage;
  recommendationId?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type IncentivesHeroTile = {
  __typename?: "IncentivesHeroTile";
  action: IncentivesActionLink;
  content: IncentivesTileContent;
  image: Image;
  offers: Array<IncentivesOfferTile>;
};

export type IncentivesHeroTilesBody = {
  __typename?: "IncentivesHeroTilesBody";
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<IncentivesHeroTile>;
};

export type IncentivesImage = {
  __typename?: "IncentivesImage";
  attribution?: Maybe<IncentivesImageAttribution>;
  image: Image;
};

export type IncentivesImageAttribution = {
  __typename?: "IncentivesImageAttribution";
  dialog: IncentivesImageAttributionDialog;
  dialogTriggerAccessibility: Scalars["String"]["output"];
};

export type IncentivesImageAttributionDialog = {
  __typename?: "IncentivesImageAttributionDialog";
  closeText: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type IncentivesJourney = IncentivesTiles;

export type IncentivesMedia = {
  __typename?: "IncentivesMedia";
  badges: Array<EgdsBadge>;
  gallery: EgdsGalleryCarousel;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
  saveTripItem?: Maybe<CollectionTripsSaveItem>;
};

export type IncentivesMediaItem = MediaItem & {
  __typename?: "IncentivesMediaItem";
  media: Image;
  presentedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesMediaLink = {
  __typename?: "IncentivesMediaLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics: ClientSideAnalytics;
  image?: Maybe<Image>;
  resource: HttpUri;
  selectedAnalytics?: Maybe<CollectionClickstreamAnalytics>;
};

export type IncentivesMediaTile = {
  __typename?: "IncentivesMediaTile";
  action?: Maybe<IncentivesActionLink>;
  cardLink: IncentivesActionLink;
  content: IncentivesTileContent;
  details?: Maybe<EgdsSpannableList>;
  media: IncentivesMedia;
  price: IncentivesMediaTilePrice;
  productRatingSummary?: Maybe<ProductRatingSummary>;
  rating: EgdsSpannableText;
};

export type IncentivesMediaTilePrice = {
  __typename?: "IncentivesMediaTilePrice";
  badge?: Maybe<EgdsBadge>;
  propertyPrice?: Maybe<PropertyPrice>;
};

export type IncentivesMediaTilesBody = {
  __typename?: "IncentivesMediaTilesBody";
  loadMoreAction?: Maybe<CollectionPaginationAction>;
  summary?: Maybe<IncentivesResultsSummary>;
  tiles: Array<IncentivesMediaTile>;
};

export type IncentivesOfferTile = {
  __typename?: "IncentivesOfferTile";
  badge?: Maybe<EgdsStandardBadge>;
  cardLink: IncentivesActionLink;
  options: Array<IncentivesOfferTileOption>;
  price: EgdsSpannableText;
  timeFoundAgo?: Maybe<Scalars["String"]["output"]>;
};

export type IncentivesOfferTileOption = {
  __typename?: "IncentivesOfferTileOption";
  badge: EgdsStandardBadge;
  destination: EgdsGraphicText;
  info: EgdsGraphicText;
  origin: EgdsGraphicText;
  time: EgdsSpannableText;
};

export type IncentivesResultsSummary = {
  __typename?: "IncentivesResultsSummary";
  dealsDate: EgdsSpannableText;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type IncentivesTileContent = {
  __typename?: "IncentivesTileContent";
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type IncentivesTiles = {
  __typename?: "IncentivesTiles";
  tiles: Array<IncentivesCollectionItem>;
};

export type IncludedFeeFooter = {
  __typename?: "IncludedFeeFooter";
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type IndicatorStep = {
  __typename?: "IndicatorStep";
  a11yLabel: Scalars["String"]["output"];
  changeSelection?: Maybe<StepIndicatorChangeSelection>;
  currentStep: Scalars["Boolean"]["output"];
  label: StepIndicatorLabel;
};

export type InfoWishlistHeading = {
  __typename?: "InfoWishlistHeading";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  badge: EgdsBadge;
  button: EgdsButton;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type InformativeInput = EgdsNumberInputField | EgdsTextInputField;

export type InformativeTextInput = {
  __typename?: "InformativeTextInput";
  informativeInput?: Maybe<InformativeInput>;
  moreInfo: Icon;
  popupText: Scalars["String"]["output"];
};

export type InlineJustify = "END" | "SPACE" | "START";

export type InlineNotification = ContextualNotification & {
  __typename?: "InlineNotification";
  attributes?: Maybe<Array<NotificationAttribute>>;
  backgroundImage?: Maybe<Image>;
  bannerContainer?: Maybe<NotificationContainer>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  customQrCode?: Maybe<NotificationQrCode>;
  dismiss?: Maybe<NotificationDismiss>;
  featuredImages?: Maybe<Array<Image>>;
  form?: Maybe<NotificationForm>;
  icon?: Maybe<Icon>;
  legalText?: Maybe<NotificationPhrase>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  notificationLocation?: Maybe<NotificationLocationOnPage>;
  qrCode?: Maybe<Image>;
  revealActions?: Maybe<Array<NotificationAction>>;
  subBody?: Maybe<Array<BodyElements>>;
  switch?: Maybe<EgdsStandardSwitch>;
  /** @deprecated Use bannerContainer instead to define banner theme */
  theme?: Maybe<NotificationTheme>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
};

export type InlinePriceLineText = {
  __typename?: "InlinePriceLineText";
  inlineItems: Array<PriceLineText>;
};

export type InquiryActionButton = InquirySaveToTripAction | InquirySuccessDoneAction;

export type InquiryFormDialogAction = {
  __typename?: "InquiryFormDialogAction";
  dialogAnalytics?: Maybe<ClientSideAnalytics>;
  inquiryFormModal?: Maybe<InquiryFormLayout>;
};

export interface InquiryFormInput {
  petsIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  primaryPropertyCriteriaInput?: InputMaybe<PrimaryPropertyCriteriaInput>;
}

export type InquiryFormInputElement =
  | EgdsBasicCheckBox
  | EgdsBasicSelect
  | EgdsSearchFormTravelersField
  | EgdsTextAreaInputField
  | EgdsTextInputField
  | InquiryPhoneInputField;

export type InquiryFormLayout = {
  __typename?: "InquiryFormLayout";
  analytics?: Maybe<ClientSideAnalytics>;
  datePicker?: Maybe<EgdsDateRangePicker>;
  disclaimer?: Maybe<Array<EgdsText>>;
  featuredMessage?: Maybe<EgdsStandardMessagingCard>;
  formInput?: Maybe<Array<InquiryFormInputElement>>;
  header?: Maybe<Scalars["String"]["output"]>;
  submitButton?: Maybe<UiPrimaryButton>;
};

export type InquiryPhoneInputField = {
  __typename?: "InquiryPhoneInputField";
  countryCode: EgdsBasicSelect;
  phoneNumber: EgdsTextInputField;
};

export type InquiryProminence = "HIGH" | "LOW" | "MEDIUM";

export interface InquiryRequestInput {
  inquirerPhoneCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  inquirerPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  message: Scalars["String"]["input"];
  optInForMarketing?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  primaryPropertyCriteriaInput: PrimaryPropertyCriteriaInput;
}

export type InquiryResponse = {
  __typename?: "InquiryResponse";
  analytics?: Maybe<ClientSideAnalytics>;
  body?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Deprecated in favour of primaryCta */
  doneButton?: Maybe<UiPrimaryButton>;
  /** @deprecated Deprecated in favour of title */
  header?: Maybe<Scalars["String"]["output"]>;
  headerInfo?: Maybe<GraphicText>;
  primaryCta?: Maybe<InquiryActionButton>;
  title?: Maybe<EgdsGraphicText>;
};

export type InquirySaveToTripAction = {
  __typename?: "InquirySaveToTripAction";
  tripsSaveItem: TripsSaveItem;
};

export type InquirySuccessDoneAction = {
  __typename?: "InquirySuccessDoneAction";
  button: UiPrimaryButton;
};

export type InsightsAirport = {
  __typename?: "InsightsAirport";
  code: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type InsightsDestination = {
  __typename?: "InsightsDestination";
  regionId: Scalars["String"]["output"];
};

export interface InsightsLocationInput {
  airportCode?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type InstallmentPlan = {
  __typename?: "InstallmentPlan";
  totalPrice: Scalars["String"]["output"];
};

export type InstallmentPlanAttribute = {
  __typename?: "InstallmentPlanAttribute";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type InstallmentPlanDetails = AffirmInstallmentPlanDetails;

export type InstallmentPlanLink = {
  __typename?: "InstallmentPlanLink";
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
};

export type InstallmentPlanPlacement = {
  __typename?: "InstallmentPlanPlacement";
  subTextRequired: Scalars["Boolean"]["output"];
  totalPrice: Scalars["String"]["output"];
};

export type InstallmentPlanResponse = {
  __typename?: "InstallmentPlanResponse";
  impressionAnalytics: ClientSideAnalytics;
  installmentPlanDetails: InstallmentPlanDetails;
};

export type InsurTechComponentPlacement = {
  __typename?: "InsurTechComponentPlacement";
  placementContext: InsurTechComponentPlacementContext;
  position?: Maybe<Scalars["Int"]["output"]>;
};

export type InsurTechComponentPlacementContext = {
  __typename?: "InsurTechComponentPlacementContext";
  lob: LineOfBusinessDomain;
  packageType?: Maybe<PackageType>;
  path: PageLocation;
  placement?: Maybe<InsurTechPagePlacementType>;
};

export interface InsurTechComponentPlacementContextInput {
  lob: LineOfBusinessDomain;
  packageType?: InputMaybe<PackageType>;
  path: PageLocation;
  placement?: InputMaybe<InsurTechPagePlacementType>;
}

export interface InsurTechComponentPlacementInput {
  placementContext: InsurTechComponentPlacementContextInput;
  position?: InputMaybe<Scalars["Int"]["input"]>;
}

export type InsurTechDialogActionType = "CLOSE" | "OPEN" | "OPEN_QUERY";

export interface InsurTechDialogTriggerAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
}

export type InsurTechDialogTriggerClickAction = InsurTechDialogTriggerAction & {
  __typename?: "InsurTechDialogTriggerClickAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type InsurTechExperienceDialogTrigger = EgdsButton & {
  __typename?: "InsurTechExperienceDialogTrigger";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  clientAction: InsurTechDialogTriggerAction;
  dialogAction: InsurTechDialogActionType;
  dialogId: Scalars["String"]["output"];
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  triggerType: InsurTechExperienceDialogTriggerType;
};

export type InsurTechExperienceDialogTriggerType = "BUTTON" | "LINK";

export type InsurTechPagePlacementType = "LISTING" | "LISTING_ONE_WAY";

export type InsuranceAdditionalInfo = {
  __typename?: "InsuranceAdditionalInfo";
  infoDialog?: Maybe<InsuranceInfoDialog>;
};

export type InsuranceBadge = {
  __typename?: "InsuranceBadge";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export interface InsuranceBenefitDetailsQueryRequestInput {
  benefitId: Scalars["String"]["input"];
  isEmailSrc?: InputMaybe<Scalars["Boolean"]["input"]>;
  lob?: InputMaybe<Scalars["String"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsuranceBenefitDetailsQueryResponse = {
  __typename?: "InsuranceBenefitDetailsQueryResponse";
  attributes: TripCareAttributes;
  header: TripCareHeader;
  sections: Array<TripCareLayoutComponent>;
};

export interface InsuranceBookingCancellationRequestInput {
  orderId: Scalars["String"]["input"];
  orderLineId: Scalars["String"]["input"];
}

export type InsuranceBookingCancellationResponse = {
  __typename?: "InsuranceBookingCancellationResponse";
  errorCode?: Maybe<Scalars["String"]["output"]>;
  status: Scalars["String"]["output"];
};

export type InsuranceBookingOverviewErrorResponse = {
  __typename?: "InsuranceBookingOverviewErrorResponse";
  errorMessages: Array<TripCareError>;
};

export interface InsuranceBookingOverviewRequestInput {
  lob?: InputMaybe<Scalars["String"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsuranceBookingOverviewResponse = {
  __typename?: "InsuranceBookingOverviewResponse";
  attributes: TripCareAttributes;
  header: TripCareHeader;
  insuranceOverview: TripCareSectionComponent;
};

export interface InsuranceClaimDetailsRequestInput {
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsuranceClaimDetailsResponse = {
  __typename?: "InsuranceClaimDetailsResponse";
  attributes: TripCareAttributes;
  header: TripCareHeader;
  sections: Array<TripCareLayoutComponent>;
};

export type InsuranceCriteria = {
  __typename?: "InsuranceCriteria";
  freePmpCustomerPlacement?: Maybe<Scalars["String"]["output"]>;
  insuranceContinuationToken?: Maybe<Scalars["String"]["output"]>;
  insuranceOfferToken?: Maybe<Scalars["String"]["output"]>;
  selectedProductId?: Maybe<Scalars["String"]["output"]>;
};

export interface InsuranceCriteriaInput {
  freePmpCustomerPlacement?: InputMaybe<Scalars["String"]["input"]>;
  insuranceContinuationToken?: InputMaybe<Scalars["String"]["input"]>;
  insuranceOfferToken?: InputMaybe<Scalars["String"]["input"]>;
  selectedProductId?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsuranceDetailPrimingCard = {
  __typename?: "InsuranceDetailPrimingCard";
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  footer?: Maybe<InsuranceFooter>;
  header?: Maybe<InsuranceHeader>;
  products: Array<InsurancePrimingProduct>;
};

export interface InsuranceDetailPrimingForCarInput {
  carOfferToken: Scalars["String"]["input"];
  continuationContextualId?: InputMaybe<Scalars["String"]["input"]>;
  dropOffLocation?: InputMaybe<InsuranceLocationInput>;
  pickUpLocation: InsuranceLocationInput;
  rewardPointsSelection?: InputMaybe<InsuranceRewardsPointSelectionEnum>;
  selectedAccessories?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export interface InsuranceDetailPrimingRequestInput {
  carRequest: InsuranceDetailPrimingForCarInput;
}

export type InsuranceDialog = {
  __typename?: "InsuranceDialog";
  buttonPrimary: InsuranceDialogButton;
  header?: Maybe<InsuranceHeader>;
  id?: Maybe<Scalars["String"]["output"]>;
  texts: Array<Scalars["String"]["output"]>;
  type: InsuranceMessageType;
};

export type InsuranceDialogButton = {
  __typename?: "InsuranceDialogButton";
  text: Scalars["String"]["output"];
};

export type InsuranceDialogToggle = {
  __typename?: "InsuranceDialogToggle";
  analytics: ClientSideAnalytics;
  toggleText: Scalars["String"]["output"];
};

export interface InsuranceFileAClaimRequestInput {
  benefitId: Scalars["String"]["input"];
  isEmailSrc?: InputMaybe<Scalars["Boolean"]["input"]>;
  lob?: InputMaybe<Scalars["String"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsuranceFileAClaimResponse = {
  __typename?: "InsuranceFileAClaimResponse";
  header: TripCareHeader;
  sections: Array<TripCareLayoutComponent>;
};

export type InsuranceFooter = {
  __typename?: "InsuranceFooter";
  text: Scalars["String"]["output"];
};

export type InsuranceHeader = {
  __typename?: "InsuranceHeader";
  badge?: Maybe<InsuranceBadge>;
  icon?: Maybe<Icon>;
  illustration?: Maybe<Illustration>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type InsuranceInfoDialog = {
  __typename?: "InsuranceInfoDialog";
  dialog: InsuranceDialog;
  toggle: InsuranceDialogToggle;
};

export interface InsuranceLocationInput {
  regionId: Scalars["String"]["input"];
  searchTerm: Scalars["String"]["input"];
}

export type InsuranceMessageType = "ERROR" | "INFO" | "WARNING";

export type InsurancePrice = {
  __typename?: "InsurancePrice";
  subtextFirst?: Maybe<Scalars["String"]["output"]>;
  subtextStandard?: Maybe<Array<Scalars["String"]["output"]>>;
  value: Scalars["String"]["output"];
};

export type InsurancePrimingProduct = {
  __typename?: "InsurancePrimingProduct";
  additionalInfo?: Maybe<Array<InsuranceAdditionalInfo>>;
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  footer?: Maybe<InsuranceFooter>;
  header?: Maybe<InsuranceHeader>;
  price?: Maybe<InsurancePrice>;
  solicitations: Array<InsuranceSolicitation>;
};

export type InsuranceRewardsPointSelectionEnum = "APPLY_REWARD_POINTS" | "DO_NOT_APPLY_REWARD_POINTS";

export type InsuranceSolicitation = {
  __typename?: "InsuranceSolicitation";
  details?: Maybe<Array<Scalars["String"]["output"]>>;
  sentence: Scalars["String"]["output"];
};

export type InsuranceSwitchToggleAction = FlightsClientAction & {
  __typename?: "InsuranceSwitchToggleAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  checkedAccessibilityAnnouncement?: Maybe<Scalars["String"]["output"]>;
  insuranceCriteria?: Maybe<InsuranceCriteria>;
  jcid?: Maybe<Scalars["String"]["output"]>;
  uncheckedAccessibilityAnnouncement?: Maybe<Scalars["String"]["output"]>;
};

export interface InsurtechAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
}

export type InsurtechActionDialogFooter = {
  __typename?: "InsurtechActionDialogFooter";
  actions: Array<InsurtechActionElement>;
};

export type InsurtechActionElement = EgdsStandardLink | UiPrimaryButton;

export interface InsurtechAddTripRequestInput {
  offerId: Scalars["String"]["input"];
  price: Scalars["String"]["input"];
  responseId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
}

export type InsurtechAlert = {
  __typename?: "InsurtechAlert";
  actionLink?: Maybe<InsurtechLink>;
  icon?: Maybe<Icon>;
  message: InsurtechSpannableText;
  primaryAction?: Maybe<InsurtechPrimaryButton>;
  theme?: Maybe<InsurtechAlertTheme>;
};

export type InsurtechAlertTheme = "INFO" | "SUCCESS" | "WARNING";

export type InsurtechAnalyticsData = InsurtechClickstreamAnalytics;

export type InsurtechBadgeHeading = InsurtechHeadingTemplate & {
  __typename?: "InsurtechBadgeHeading";
  badge?: Maybe<EgdsBadge>;
  header: EgdsHeading;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechBanner = {
  __typename?: "InsurtechBanner";
  actions?: Maybe<Array<InsurtechLink>>;
  banner: UiBanner;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechBasicBenefitList = InsurtechBenefitListTemplate & {
  __typename?: "InsurtechBasicBenefitList";
  benefitList: InsurtechList;
  heading?: Maybe<EgdsHeading>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechBasicCardSection = InsurtechCardSection & {
  __typename?: "InsurtechBasicCardSection";
  body: Array<InsurtechPrimingCardElement>;
  content: Array<InsurtechCardElement>;
};

export type InsurtechBasicConfirmationModule = {
  __typename?: "InsurtechBasicConfirmationModule";
  confirmationDetails: InsurtechPurchaseConfirmationDetails;
  displayAnalytics?: Maybe<InsurtechAnalyticsData>;
  heading?: Maybe<InsurtechBasicHeading>;
  primaryAction?: Maybe<InsurtechButton>;
};

export type InsurtechBasicHeading = InsurtechHeading & {
  __typename?: "InsurtechBasicHeading";
  heading?: Maybe<Scalars["String"]["output"]>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechBasicListItem = InsurtechListItemTemplate & {
  __typename?: "InsurtechBasicListItem";
  description?: Maybe<EgdsPlainText>;
  graphic?: Maybe<UiGraphic>;
  label?: Maybe<EgdsPlainText>;
};

export type InsurtechBasicModuleDetail = InsurtechModuleDetailTemplate & {
  __typename?: "InsurtechBasicModuleDetail";
  id: Scalars["String"]["output"];
  selectedView: InsurtechView;
  views?: Maybe<Array<InsurtechViewDetail>>;
};

export type InsurtechBasicPaymentModule = {
  __typename?: "InsurtechBasicPaymentModule";
  heading?: Maybe<InsurtechBasicHeading>;
  informationSection?: Maybe<InsurtechDataSection>;
  initializeConfig?: Maybe<Scalars["String"]["output"]>;
  paymentBrands?: Maybe<Array<UiGraphic>>;
};

export type InsurtechBasicRadioButton = InsurtechRadioButton & {
  __typename?: "InsurtechBasicRadioButton";
  action: InsurtechAction;
  item: EgdsRadioButton;
};

export type InsurtechBasicSectionsData = InsurtechSectionsDataTemplate & {
  __typename?: "InsurtechBasicSectionsData";
  benefitList?: Maybe<Array<InsurtechBenefitListTemplate>>;
  heading?: Maybe<Array<InsurtechHeadingTemplate>>;
};

export type InsurtechBasicShoppingResponse = {
  __typename?: "InsurtechBasicShoppingResponse";
  data: InsurtechSectionsDataTemplate;
  modules: Array<InsurtechModuleDetailTemplate>;
};

export type InsurtechBenefitDialogContent = {
  __typename?: "InsurtechBenefitDialogContent";
  benefitSection: InsurtechBenefitSection;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  egClickstreamAnalyticsData?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  link?: Maybe<EgdsStandardLink>;
};

export type InsurtechBenefitDialogHeading = {
  __typename?: "InsurtechBenefitDialogHeading";
  graphic?: Maybe<UiGraphic>;
  header: Scalars["String"]["output"];
  subHeader?: Maybe<InsurtechSpannableText>;
};

export interface InsurtechBenefitListTemplate {
  benefitList: InsurtechList;
  id?: Maybe<Scalars["String"]["output"]>;
}

export type InsurtechBenefitSection = {
  __typename?: "InsurtechBenefitSection";
  dialogTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
  heading?: Maybe<Scalars["String"]["output"]>;
  list: InsurtechTextList;
};

export type InsurtechBookingError = InsurtechError & {
  __typename?: "InsurtechBookingError";
  message?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<InsurtechCommonErrorType>;
};

export interface InsurtechBookingRequestInput {
  edgeToken?: InputMaybe<Scalars["String"]["input"]>;
  insuranceRecordId?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface InsurtechBookingResponse {
  analytics?: Maybe<InsurtechAnalyticsData>;
}

export type InsurtechBottomInfoElement = InsurtechInfoSection;

export type InsurtechBreakupLineItem = {
  __typename?: "InsurtechBreakupLineItem";
  graphic?: Maybe<UiGraphic>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechBreakupSection = {
  __typename?: "InsurtechBreakupSection";
  content: Array<InsurtechBreakupLineItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface InsurtechButton {
  action: InsurtechAction;
}

export type InsurtechCardElement = EgdsStylizedText | EgdsTextIconList | InsurtechDialogTrigger;

export interface InsurtechCardSection {
  /** @deprecated use content field instead */
  body: Array<InsurtechPrimingCardElement>;
  content: Array<InsurtechCardElement>;
}

export type InsurtechCheckBoxWrapper = {
  __typename?: "InsurtechCheckBoxWrapper";
  action: InsurtechAction;
  checkBox: EgdsCheckBox;
  price?: Maybe<EgdsPriceLockup>;
};

export type InsurtechCheckboxAction = InsurtechAction & {
  __typename?: "InsurtechCheckboxAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  addInsuranceMessage?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  insuranceOfferToken?: Maybe<Scalars["String"]["output"]>;
  removeInsuranceMessage?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechClickableElement =
  | InsurtechLink
  | InsurtechPrimaryButton
  | InsurtechSecondaryButton
  | InsurtechTertiaryButton;

export type InsurtechClickstreamAnalytics = {
  __typename?: "InsurtechClickstreamAnalytics";
  egClickstreamAnalyticsData?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
};

export type InsurtechClickstreamAnalyticsData = {
  __typename?: "InsurtechClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]["output"]>;
  eventVersion?: Maybe<Scalars["String"]["output"]>;
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  referenceId?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechClientId = "CAR_INFO_SITE" | "EG_CHECKOUT" | "FCR_CHECKOUT";

export type InsurtechCommonErrorType =
  | "ANONYMOUS_TRIP_USER"
  | "BOOKING_CANCELLED"
  | "CHANGE_RESIDENCY_FAILURE"
  | "ERROR_CARD_LIMIT"
  | "ERROR_MAPPING_PAYMENT_INSTRUMENT_DETAILS"
  | "ERROR_OMS_RECOVERABLE"
  | "FAILURE_SELECT_INSURANCE_PRODUCT"
  | "INSURANCE_ADD_FAILURE"
  | "INSURANCE_ALREADY_PURCHASED"
  | "INSURANCE_OFFER_EXPIRED"
  | "INVALID_ACCOUNT_NUMBER"
  | "INVALID_EDGE_TOKEN"
  | "INVALID_EXPIRATION_DATE"
  | "INVALID_FORM"
  | "PAYMENT_CARD_ISSUE"
  | "PAYMENT_SESSION_SERVICE_ERROR"
  | "RESPONSE_ID_NOT_FOUND_IN_RECORD"
  | "UNKNOWN_ERROR"
  | "USER_ACCOUNT_MISMATCH"
  | "USER_BUCKETED_FOR_LEGACY_PAGE";

export type InsurtechCompletePurchaseAction = InsurtechAction & {
  __typename?: "InsurtechCompletePurchaseAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clickAnalytics?: Maybe<InsurtechAnalyticsData>;
  edgeToken?: Maybe<Scalars["String"]["output"]>;
  insuranceRecordId?: Maybe<Scalars["String"]["output"]>;
  parentTripId?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
};

export interface InsurtechComponentPositionContextInput {
  lob?: InputMaybe<LineOfBusinessDomain>;
  packageType?: InputMaybe<PackageType>;
  path?: InputMaybe<PageLocation>;
  placement?: InputMaybe<InsurTechPagePlacementType>;
}

export interface InsurtechComponentPositionInput {
  positionContext?: InputMaybe<InsurtechComponentPositionContextInput>;
}

export type InsurtechCompoundSelectModule = {
  __typename?: "InsurtechCompoundSelectModule";
  action: InsurtechAction;
  primarySelectList: EgdsBasicSelect;
  secondarySelectList?: Maybe<Array<InsurtechSecondaryPrimaryMap>>;
};

export type InsurtechConfirmationModule = InsurtechBasicConfirmationModule;

export type InsurtechConfirmationNotification = InsurtechNotificationSection & {
  __typename?: "InsurtechConfirmationNotification";
  analytics?: Maybe<InsurtechAnalyticsData>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<EgdsStylizedText>;
};

export type InsurtechConstantInfo = {
  __typename?: "InsurtechConstantInfo";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type InsurtechCustomError = {
  __typename?: "InsurtechCustomError";
  analytics?: Maybe<InsurtechClickstreamAnalytics>;
  commonError?: Maybe<InsurtechCommonErrorType>;
  message?: Maybe<Scalars["String"]["output"]>;
  type: InsurtechCustomErrorType;
};

export type InsurtechCustomErrorType =
  | "CACHED_PRODUCT_UNAVAILABLE"
  | "GENERIC_ERROR"
  | "INVALID_USER"
  | "USER_BUCKETED_FOR_LEGACY_PAGE";

export interface InsurtechDataSection {
  analytics?: Maybe<InsurtechAnalyticsData>;
  content?: Maybe<Array<InsurtechInformationElement>>;
}

export type InsurtechDialog = {
  __typename?: "InsurtechDialog";
  dialog: EgdsDialog;
  dialogContent: InsurtechDialogContent;
  dialogFooter?: Maybe<InsurtechDialogFooter>;
  dialogHeading?: Maybe<InsurtechDialogHeading>;
  dialogId: Scalars["String"]["output"];
  onCloseAnnounceMessage?: Maybe<Scalars["String"]["output"]>;
  onDisplayAnalytics?: Maybe<ClientSideAnalytics>;
  onOpenAnnounceMessage?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechDialogBasicHeading = {
  __typename?: "InsurtechDialogBasicHeading";
  heading: Scalars["String"]["output"];
};

export type InsurtechDialogContent =
  | InsurtechBenefitDialogContent
  | InsurtechHeadingTextDialog
  | InsurtechLearnMoreDialogContent
  | InsurtechPricePresentationDialogContent
  | InsurtechResidencyDialogContent
  | InsurtechTextDialog
  | TripSafetyGuideDialogContent;

export type InsurtechDialogFooter =
  | InsurtechActionDialogFooter
  | InsurtechSelectionButtonFooter
  | InsurtechSelectionFooter;

export type InsurtechDialogHeading = InsurtechBenefitDialogHeading | InsurtechDialogBasicHeading;

export type InsurtechDialogTrigger = {
  __typename?: "InsurtechDialogTrigger";
  action: InsurtechLink;
  dialog: EgdsDialog;
  dialogContent: InsurtechDialogContent;
  dialogId: Scalars["String"]["output"];
};

export type InsurtechDialogTriggerAction = InsurtechAction & {
  __typename?: "InsurtechDialogTriggerAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type InsurtechDomainInfo = {
  __typename?: "InsurtechDomainInfo";
  name: Scalars["String"]["output"];
  refId: Scalars["String"]["output"];
};

export type InsurtechElement =
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsTextIconList
  | EgdsUnorderedList
  | InsurTechExperienceDialogTrigger
  | InsurtechCheckBoxWrapper
  | InsurtechLink
  | InsurtechLinkList
  | InsurtechNotice
  | InsurtechRadioGroupWrapper
  | InsurtechSpannableText
  | InsurtechTextList
  | UiBanner;

export interface InsurtechError {
  message?: Maybe<Scalars["String"]["output"]>;
}

export interface InsurtechErrorResponse {
  analytics?: Maybe<Array<ClientSideAnalytics>>;
}

export interface InsurtechFooterSection {
  label: Scalars["String"]["output"];
}

export type InsurtechGenericAction = InsurtechAction & {
  __typename?: "InsurtechGenericAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionType: InsurtechGenericActionType;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type InsurtechGenericActionType = "REDIRECT_CHECKOUT_URL" | "RELOAD_PAGE";

export type InsurtechGraphicStylizedText = {
  __typename?: "InsurtechGraphicStylizedText";
  grapic: UiGraphic;
  stylizedText: EgdsStylizedText;
};

export interface InsurtechHeading {
  heading?: Maybe<Scalars["String"]["output"]>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
}

export interface InsurtechHeadingTemplate {
  header: EgdsHeading;
  id?: Maybe<Scalars["String"]["output"]>;
}

export type InsurtechHeadingTextDialog = {
  __typename?: "InsurtechHeadingTextDialog";
  content: Array<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
};

export type InsurtechIconListItem = InsurtechListItem & {
  __typename?: "InsurtechIconListItem";
  content: Array<EgdsText>;
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  icon: Icon;
  label: Scalars["String"]["output"];
  subLabel?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<InsurtechListItemTheme>;
};

export type InsurtechInfoSection = {
  __typename?: "InsurtechInfoSection";
  graphic?: Maybe<UiGraphic>;
  links?: Maybe<InsurtechStandardLinkList>;
  text: InsurtechSpannableText;
};

export type InsurtechInformationElement = InsurtechInformationSection;

export type InsurtechInformationSection = {
  __typename?: "InsurtechInformationSection";
  graphic?: Maybe<UiGraphic>;
  text: InsurtechSpannableText;
};

export type InsurtechLearnMoreDialogContent = {
  __typename?: "InsurtechLearnMoreDialogContent";
  content: Array<InsurtechTextElement>;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
};

export type InsurtechLegalInformationSection = InsurtechDataSection & {
  __typename?: "InsurtechLegalInformationSection";
  analytics?: Maybe<InsurtechAnalyticsData>;
  content?: Maybe<Array<InsurtechInformationElement>>;
};

export type InsurtechLink = {
  __typename?: "InsurtechLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: InsurtechAction;
  icon?: Maybe<Icon>;
  linkType: InsurtechLinkType;
  size: EgdsLinkSize;
  text: Scalars["String"]["output"];
};

export type InsurtechLinkList = {
  __typename?: "InsurtechLinkList";
  links: Array<InsurtechLink>;
};

export type InsurtechLinkType = "MORE_INFO" | "STANDARD_LINK";

export type InsurtechList = {
  __typename?: "InsurtechList";
  items: Array<InsurtechListItemTemplate>;
  type: InsurtechListType;
};

export interface InsurtechListItem {
  content: Array<EgdsText>;
  label: Scalars["String"]["output"];
}

export interface InsurtechListItemTemplate {
  graphic?: Maybe<UiGraphic>;
}

export type InsurtechListItemTheme = "HEADED_LIST_ITEM" | "LIST_ITEM";

export type InsurtechListType = "GRAPHIC_LIST";

export type InsurtechMessagingCard = {
  __typename?: "InsurtechMessagingCard";
  actions: InsurtechLinkList;
  egClickstreamAnalyticsData?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
};

export interface InsurtechMetadataInput {
  lineOfBusiness?: InputMaybe<LineOfBusinessDomain>;
  referredSource?: InputMaybe<Scalars["String"]["input"]>;
  userDetails?: InputMaybe<InsurtechUserDetailsInput>;
}

export type InsurtechModule = {
  __typename?: "InsurtechModule";
  badge?: Maybe<EgdsBadge>;
  contentSections: Array<InsurtechSection>;
  heading: Scalars["String"]["output"];
  residencySection: Array<InsurtechElement>;
};

export interface InsurtechModuleDetailTemplate {
  id: Scalars["String"]["output"];
}

export type InsurtechModuleHeading = {
  __typename?: "InsurtechModuleHeading";
  badge?: Maybe<EgdsBadge>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
  viewType?: Maybe<InsurtechModuleHeadingViewType>;
};

export type InsurtechModuleHeadingViewType = "INLINE_BADGE" | "PRIMARY";

export type InsurtechMutationStatus = "FAILED" | "SUCCESS";

export type InsurtechNotice = {
  __typename?: "InsurtechNotice";
  content: InsurtechSpannableText;
  icon: Icon;
};

export interface InsurtechNotificationSection {
  analytics?: Maybe<InsurtechAnalyticsData>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<EgdsStylizedText>;
}

export type InsurtechNudgeAcceptanceView = InsurtechViewModule & {
  __typename?: "InsurtechNudgeAcceptanceView";
  action?: Maybe<InsurtechButton>;
  benefits?: Maybe<InsurtechBenefitSection>;
  expando?: Maybe<EgdsExpandoCard>;
  footer?: Maybe<Array<InsurtechTextWrapperElement>>;
  heading?: Maybe<InsurtechModuleHeading>;
  id: InsurtechView;
  link?: Maybe<InsurtechStandardLinkWrapper>;
  residency?: Maybe<InsurtechResidencyModule>;
};

export type InsurtechNudgeRejectedView = InsurtechViewModule & {
  __typename?: "InsurtechNudgeRejectedView";
  action?: Maybe<InsurtechSelectionSection>;
  benefitSection?: Maybe<InsurtechBenefitSection>;
  expando?: Maybe<EgdsExpandoCard>;
  footer?: Maybe<Array<InsurtechTextWrapperElement>>;
  heading?: Maybe<InsurtechModuleHeading>;
  id: InsurtechView;
  message?: Maybe<InsurtechStandardMessagingCard>;
  residency?: Maybe<InsurtechResidencyModule>;
};

export type InsurtechOfferState = "CHANGED_OFFER" | "NOT_SELECTED" | "UNCHANGED_OFFER";

export interface InsurtechPageLevelErrorContentType {
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
}

export type InsurtechPageLevelErrorResponse = InsurtechErrorResponse & {
  __typename?: "InsurtechPageLevelErrorResponse";
  analytics?: Maybe<Array<ClientSideAnalytics>>;
  content?: Maybe<InsurtechPageLevelErrorContentType>;
  displayAnalytics?: Maybe<InsurtechAnalyticsData>;
};

export type InsurtechPageLevelInfo = {
  __typename?: "InsurtechPageLevelInfo";
  banners?: Maybe<Array<InsurtechBanner>>;
  dialogs?: Maybe<Array<InsurtechDialog>>;
  errors?: Maybe<Array<InsurtechCustomError>>;
  heading?: Maybe<InsurtechModuleHeading>;
  insuranceContinuationToken?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field is deprecated,DO NOT USE */
  insurtechContinuationToken?: Maybe<Scalars["String"]["output"]>;
  priceSummaries?: Maybe<Array<InsurtechPriceSummary>>;
  redirectionUrl?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<UiToolbar>;
  urgencySection?: Maybe<InsurtechUrgencySection>;
};

export type InsurtechPageMessagingCard = InsurtechPageLevelErrorContentType & {
  __typename?: "InsurtechPageMessagingCard";
  buttons: Array<InsurtechButton>;
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  graphic?: Maybe<UiGraphic>;
  heading: Scalars["String"]["output"];
};

export type InsurtechPaymentModule = InsurtechBasicPaymentModule;

export type InsurtechPopover = {
  __typename?: "InsurtechPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  content: InsurtechPopoverContent;
  icon: Icon;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechPopoverContent = InsurtechPopoverTextContent;

export type InsurtechPopoverTextContent = {
  __typename?: "InsurtechPopoverTextContent";
  content: Array<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechPostPurchaseBookingResponse = InsurtechBookingResponse & {
  __typename?: "InsurtechPostPurchaseBookingResponse";
  analytics?: Maybe<InsurtechAnalyticsData>;
  bookingDetails?: Maybe<BookingDetails>;
  errors?: Maybe<Array<InsurtechBookingError>>;
  message?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<InsurtechMutationStatus>;
};

export type InsurtechPricePresentation = {
  __typename?: "InsurtechPricePresentation";
  substring: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  totalPriceLabel?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechPricePresentationDialogContent = {
  __typename?: "InsurtechPricePresentationDialogContent";
  priceDetails: PricePresentation;
};

export type InsurtechPriceRadioButton = InsurtechRadioButton & {
  __typename?: "InsurtechPriceRadioButton";
  action?: Maybe<InsurtechAction>;
  item: EgdsRadioButton;
  price?: Maybe<InsurtechPricePresentation>;
};

export type InsurtechPriceSummary = {
  __typename?: "InsurtechPriceSummary";
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  dialogTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  isSticky?: Maybe<Scalars["Boolean"]["output"]>;
  price?: Maybe<InsurtechSpannableText>;
  priceSummaryId?: Maybe<Scalars["String"]["output"]>;
  primaryAction: InsurtechButton;
  secondaryAction: InsurtechButton;
};

export type InsurtechPrimaryButton = InsurtechButton & {
  __typename?: "InsurtechPrimaryButton";
  action: InsurtechAction;
  button: UiPrimaryButton;
};

export type InsurtechPrimaryProductDetails = {
  __typename?: "InsurtechPrimaryProductDetails";
  /** @deprecated Replaced by productSections */
  body?: Maybe<Array<InsurtechBreakupLineItem>>;
  expando?: Maybe<EgdsExpandoCard>;
  /** @deprecated Replaced by productSections */
  footer?: Maybe<Array<InsurtechBreakupLineItem>>;
  header?: Maybe<EgdsBasicSectionHeading>;
  /** @deprecated Replaced by header and productSummary */
  heading: EgdsImageCard;
  productSections?: Maybe<Array<InsurtechBreakupSection>>;
  productSummary?: Maybe<EgdsImageCard>;
};

export type InsurtechPrimingCarListingCard = {
  __typename?: "InsurtechPrimingCarListingCard";
  placement: InsurTechComponentPlacement;
};

export type InsurtechPrimingCard = {
  __typename?: "InsurtechPrimingCard";
  description?: Maybe<Scalars["String"]["output"]>;
  displayAnalytics?: Maybe<Array<EgClickstreamEvent>>;
  evaluatedExperiments?: Maybe<Array<Experiment>>;
  graphic?: Maybe<UiGraphic>;
  header?: Maybe<EgdsHeading>;
  /** @deprecated deprecate heading use header instead */
  heading?: Maybe<Scalars["String"]["output"]>;
  viewType: InsurtechPrimingCardViewType;
};

export type InsurtechPrimingCardElement = EgdsStylizedText | EgdsTextIconList | InsurtechDialogTrigger;

export type InsurtechPrimingCardListing = {
  __typename?: "InsurtechPrimingCardListing";
  placement: InsurTechComponentPlacement;
};

export interface InsurtechPrimingCardRequestInput {
  offer: OfferIdentityInput;
}

export type InsurtechPrimingCardResponse = {
  __typename?: "InsurtechPrimingCardResponse";
  body: Array<InsurtechCardSection>;
  footer: Scalars["String"]["output"];
  heading: EgdsHeading;
  illustration?: Maybe<UiGraphic>;
};

export type InsurtechPrimingCardViewType = "BASIC_PRIMING_CARD";

export type InsurtechPrimingQuery = {
  __typename?: "InsurtechPrimingQuery";
  card?: Maybe<InsurtechPrimingCard>;
};

export type InsurtechPrimingQueryCardArgs = {
  placement: InsurTechComponentPlacementInput;
};

export type InsurtechProductCarousel = {
  __typename?: "InsurtechProductCarousel";
  carousel: EgdsCarousel;
  heading?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<InsurtechProductCarouselCard>>;
};

export type InsurtechProductCarouselCard = InsurtechStandardMessagingCard;

export type InsurtechProductCollection = {
  __typename?: "InsurtechProductCollection";
  carousel?: Maybe<InsurtechProductCarousel>;
};

export type InsurtechProductSelectAction = InsurtechAction & {
  __typename?: "InsurtechProductSelectAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  clickAnalytics?: Maybe<InsurtechAnalyticsData>;
  insuranceRecordId?: Maybe<Scalars["String"]["output"]>;
  parentTripId?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechProductTypeInput = "CANCEL_FOR_ANY_REASON" | "INSURANCE" | "PRICE_DROP_PROTECTION";

export type InsurtechPurchaseConfirmationDetails = {
  __typename?: "InsurtechPurchaseConfirmationDetails";
  footer: InsurtechDataSection;
  notice?: Maybe<InsurtechConfirmationNotification>;
};

export interface InsurtechRadioButton {
  item: EgdsRadioButton;
}

export type InsurtechRadioGroup = {
  __typename?: "InsurtechRadioGroup";
  options: Array<InsurtechRadioButton>;
  radioGroup: EgdsRadioGroup;
};

export type InsurtechRadioGroupWrapper = InsurtechFooterSection & {
  __typename?: "InsurtechRadioGroupWrapper";
  /** @deprecated Migrating action from EGDSRadioGroup to EGDSRadioButton, action is now delegated to EGDSRadioButton's action field only */
  action: InsurtechAction;
  coveredOfferTokens?: Maybe<Array<OfferIdentity>>;
  insurtechRadioGroup: InsurtechRadioGroup;
  label: Scalars["String"]["output"];
  /** @deprecated Deprecated this in favor of using InsurtechRadioGroup */
  radioButtonGroup: EgdsRadioGroup;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  selectedOffer?: Maybe<OfferIdentity>;
  state?: Maybe<InsurtechOfferState>;
  targetOfferIdentity: OfferIdentity;
};

export interface InsurtechRemoveTripRequestInput {
  offerId: Scalars["String"]["input"];
  price: Scalars["String"]["input"];
  responseId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
}

export type InsurtechResidencyDialogContent = {
  __typename?: "InsurtechResidencyDialogContent";
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  residency: InsurtechResidencyRadioGroup;
};

export type InsurtechResidencyModule = {
  __typename?: "InsurtechResidencyModule";
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  egClickstreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]["output"]>;
  residencyDetails?: Maybe<InsurtechResidencySection>;
  residencyNotAvailable?: Maybe<InsurtechBanner>;
  toolTip?: Maybe<InsurTechExperienceDialogTrigger>;
};

export type InsurtechResidencyRadioGroup = {
  __typename?: "InsurtechResidencyRadioGroup";
  options: Array<InsurtechBasicRadioButton>;
  radioGroup: EgdsRadioGroup;
};

export type InsurtechResidencySection = InsurtechCompoundSelectModule | InsurtechLinkList | InsurtechSpannableText;

export type InsurtechResourceLinkAction = InsurtechAction & {
  __typename?: "InsurtechResourceLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  link: UiLinkAction;
};

export type InsurtechSecondaryButton = InsurtechButton & {
  __typename?: "InsurtechSecondaryButton";
  action: InsurtechAction;
  button: UiSecondaryButton;
};

export type InsurtechSecondaryPrimaryMap = {
  __typename?: "InsurtechSecondaryPrimaryMap";
  primarySelectCode: Scalars["String"]["output"];
  secondarySelect: EgdsBasicSelect;
};

export type InsurtechSection = {
  __typename?: "InsurtechSection";
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  elements: Array<InsurtechElement>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechSectionDetail = {
  __typename?: "InsurtechSectionDetail";
  id: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export interface InsurtechSectionsDataTemplate {
  heading?: Maybe<Array<InsurtechHeadingTemplate>>;
}

export type InsurtechSelectionButtonFooter = {
  __typename?: "InsurtechSelectionButtonFooter";
  selectionSection: InsurtechSelectionButtonSection;
};

export type InsurtechSelectionButtonSection = {
  __typename?: "InsurtechSelectionButtonSection";
  action: InsurtechButton;
  price: InsurtechPricePresentation;
};

export type InsurtechSelectionFooter = {
  __typename?: "InsurtechSelectionFooter";
  selectionSection: InsurtechSelectionSection;
};

export type InsurtechSelectionSection = {
  __typename?: "InsurtechSelectionSection";
  clickableElement: InsurtechClickableElement;
  price: InsurtechPricePresentation;
  selectedText?: Maybe<InsurtechGraphicStylizedText>;
};

export type InsurtechSetResidencyAction = InsurtechAction & {
  __typename?: "InsurtechSetResidencyAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  /** @deprecated deprecate this to use residencyState instead */
  isResidencyApplied?: Maybe<Scalars["Boolean"]["output"]>;
  residencyState?: Maybe<ResidencyState>;
  value?: Maybe<ResidencyDetails>;
};

export interface InsurtechShoppingCarouselCard {
  id?: Maybe<Scalars["String"]["output"]>;
}

export type InsurtechShoppingCarouselSection = {
  __typename?: "InsurtechShoppingCarouselSection";
  analytics?: Maybe<InsurtechClickstreamAnalytics>;
  carousel: InsurtechShoppingOffers;
  forceChoice?: Maybe<EgdsStylizedText>;
  heading?: Maybe<Scalars["String"]["output"]>;
  residencySection?: Maybe<InsurtechResidencyModule>;
};

export interface InsurtechShoppingCriteriaInput {
  identifier: InsurtechShoppingIdentifierInput;
  insuranceContinuationToken?: InputMaybe<Scalars["String"]["input"]>;
  productType?: InputMaybe<InsurtechProductTypeInput>;
  residencyDetails?: InputMaybe<ResidencyDetailsInput>;
}

export interface InsurtechShoppingIdentifierInput {
  type: InsurtechShoppingIdentifierType;
  value: Scalars["String"]["input"];
}

export type InsurtechShoppingIdentifierType =
  | "CHECKOUT_SESSION_ID"
  | "JCID"
  | "OFFER_TOKEN"
  | "RESPONSE_ID"
  | "TRIP_ID";

export type InsurtechShoppingModule = InsurtechShoppingCarouselCard & {
  __typename?: "InsurtechShoppingModule";
  content: Array<InsurtechShoppingModuleSection>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  footer?: Maybe<InsurtechShoppingModuleFooter>;
  heading: InsurtechModuleHeading;
  id?: Maybe<Scalars["String"]["output"]>;
  messageInfo?: Maybe<Array<InsurtechConstantInfo>>;
};

export type InsurtechShoppingModuleBottomInfo = {
  __typename?: "InsurtechShoppingModuleBottomInfo";
  disclaimers?: Maybe<Array<InsurtechBottomInfoElement>>;
  egClickstreamAnalyticsData?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  link?: Maybe<InsurtechStandardLinkWrapper>;
};

export type InsurtechShoppingModuleDisclaimerFooter = {
  __typename?: "InsurtechShoppingModuleDisclaimerFooter";
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  disclaimer: Scalars["String"]["output"];
};

export type InsurtechShoppingModuleFooter =
  | InsurtechShoppingModuleDisclaimerFooter
  | InsurtechShoppingModuleSelectionButtonFooter
  | InsurtechShoppingModuleSelectionFooter;

export type InsurtechShoppingModuleSection =
  | InsurtechBenefitSection
  | InsurtechRadioGroupWrapper
  | InsurtechSelectionButtonSection
  | InsurtechSelectionSection
  | InsurtechTestimonial
  | InsurtechTextWrapper;

export type InsurtechShoppingModuleSelectionButtonFooter = {
  __typename?: "InsurtechShoppingModuleSelectionButtonFooter";
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  selectionSection: InsurtechSelectionButtonSection;
};

export type InsurtechShoppingModuleSelectionFooter = {
  __typename?: "InsurtechShoppingModuleSelectionFooter";
  content?: Maybe<Array<InsurtechTextWrapperElement>>;
  selectionSection: InsurtechSelectionSection;
};

export type InsurtechShoppingOffers = {
  __typename?: "InsurtechShoppingOffers";
  carousel: EgdsCarousel;
  heading?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<InsurtechShoppingCarouselCard>>;
};

export type InsurtechShoppingRadioButton = InsurtechRadioButton & {
  __typename?: "InsurtechShoppingRadioButton";
  action?: Maybe<InsurtechAction>;
  body?: Maybe<InsurtechShoppingRadioButtonSection>;
  item: EgdsRadioButton;
  price?: Maybe<InsurtechPricePresentation>;
  testimonial?: Maybe<InsurtechTestimonial>;
};

export type InsurtechShoppingRadioButtonSection = {
  __typename?: "InsurtechShoppingRadioButtonSection";
  content: Array<InsurtechShoppingModuleSection>;
};

export type InsurtechShoppingResponse = {
  __typename?: "InsurtechShoppingResponse";
  error: Array<InsurtechCustomError>;
  module?: Maybe<InsurtechShoppingModule>;
  pageLevelInfo?: Maybe<InsurtechPageLevelInfo>;
  priceSummary?: Maybe<InsurtechPriceSummary>;
  primaryProductDetails?: Maybe<InsurtechPrimaryProductDetails>;
  selectionDetails?: Maybe<InsurtechShoppingSelectionDetails>;
  shoppingCarousel?: Maybe<InsurtechShoppingCarouselSection>;
  testimonial?: Maybe<InsurtechTestimonial>;
};

export type InsurtechShoppingResponseModuleArgs = {
  viewType: InsurtechShoppingViewType;
};

export type InsurtechShoppingResponsePrimaryProductDetailsArgs = {
  encodedProductDetails?: InputMaybe<Scalars["String"]["input"]>;
};

export type InsurtechShoppingResponseTemplate = InsurtechBasicShoppingResponse;

export type InsurtechShoppingSelectionDetails = {
  __typename?: "InsurtechShoppingSelectionDetails";
  isInsuranceAdded: Scalars["Boolean"]["output"];
  module?: Maybe<InsurtechShoppingSelectionModule>;
};

export type InsurtechShoppingSelectionModule = {
  __typename?: "InsurtechShoppingSelectionModule";
  contents: Array<InsurtechShoppingSelectionModuleElements>;
  heading?: Maybe<InsurtechModuleHeading>;
};

export type InsurtechShoppingSelectionModuleElements = InsurtechAlert;

export type InsurtechShoppingStaticCard = InsurtechShoppingCarouselCard & {
  __typename?: "InsurtechShoppingStaticCard";
  content?: Maybe<Array<InsurtechBenefitSection>>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  footer?: Maybe<Array<InsurtechInfoSection>>;
  heading?: Maybe<InsurtechModuleHeading>;
  id?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<InsurtechPricePresentation>;
};

export type InsurtechShoppingViewType =
  | "CHECKOUT_SHOPPING"
  | "DETAILS_SHOPPING"
  | "EXPWEB_CHECKOUT_SHOPPING"
  | "POST_PURCHASE"
  | "SUMMARY";

export type InsurtechSignal = {
  __typename?: "InsurtechSignal";
  createdDateTime?: Maybe<Scalars["String"]["output"]>;
  domainInfoList?: Maybe<Array<InsurtechDomainInfo>>;
  id?: Maybe<Scalars["String"]["output"]>;
  signal: SignalType;
};

export type InsurtechSpannableText = {
  __typename?: "InsurtechSpannableText";
  contents: Array<InsurtechSpannableTextElement>;
};

export type InsurtechSpannableTextElement =
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStylizedText
  | InsurtechDialogTrigger
  | InsurtechLink
  | InsurtechPopover
  | InsurtechPricePresentation;

export type InsurtechStandardLinkList = {
  __typename?: "InsurtechStandardLinkList";
  links?: Maybe<Array<Maybe<EgdsStandardLink>>>;
};

export type InsurtechStandardLinkWrapper = {
  __typename?: "InsurtechStandardLinkWrapper";
  egClickStreamAnalytics?: Maybe<InsurtechClickstreamAnalyticsData>;
  link: EgdsStandardLink;
};

export type InsurtechStandardMessagingCard = {
  __typename?: "InsurtechStandardMessagingCard";
  card: EgdsStandardMessagingCard;
  linkAction?: Maybe<UiLinkAction>;
};

export type InsurtechTertiaryButton = InsurtechButton & {
  __typename?: "InsurtechTertiaryButton";
  action: InsurtechAction;
  button: UiTertiaryButton;
};

export type InsurtechTestimonial = {
  __typename?: "InsurtechTestimonial";
  author?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<TestimonialHeadingElements>;
  /** @deprecated Use header instead */
  heading: EgdsIconText;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechTextDialog = {
  __typename?: "InsurtechTextDialog";
  text: Scalars["String"]["output"];
};

export type InsurtechTextElement = EgdsBulletedList | EgdsParagraph;

export type InsurtechTextList = {
  __typename?: "InsurtechTextList";
  items: Array<InsurtechListItem>;
};

export type InsurtechTextListWrapper = {
  __typename?: "InsurtechTextListWrapper";
  header?: Maybe<Scalars["String"]["output"]>;
  textList: EgdsTextList;
};

export type InsurtechTextWrapper = {
  __typename?: "InsurtechTextWrapper";
  text: InsurtechTextWrapperElement;
};

export type InsurtechTextWrapperElement =
  | InsurtechGraphicStylizedText
  | InsurtechSpannableText
  | InsurtechStandardLinkWrapper;

export type InsurtechToggleOfferAction = InsurtechAction & {
  __typename?: "InsurtechToggleOfferAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  failedMessage: Scalars["String"]["output"];
};

export interface InsurtechTransactionCriteriaInput {
  identifier?: InputMaybe<InsurtechTransactionIdentifierInput>;
  residencyDetails?: InputMaybe<ResidencyDetailsInput>;
}

export interface InsurtechTransactionIdentifierInput {
  type?: InputMaybe<InsurtechTransactionIdentifierType>;
  value?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsurtechTransactionIdentifierType = "RESPONSE_ID" | "TRIP_ID";

export type InsurtechUpdateOfferAction = InsurtechAction & {
  __typename?: "InsurtechUpdateOfferAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated successMessage field will take care for successful operation, replace with successMessage */
  addInsurtechOfferConfirmationMessage: Scalars["String"]["output"];
  analytics?: Maybe<ClientSideAnalytics>;
  egClickStreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  failedMessage: Scalars["String"]["output"];
  /** @deprecated migrating action from EGDSRadioGroup to EGDSRadioOptions, hence successful operation will use successMessage filed, replace with successMessage */
  removeInsurtechOfferConfirmationMessage: Scalars["String"]["output"];
  successMessage: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechUpdateOperationType = "ADD" | "MODAL" | "REMOVE";

export type InsurtechUpdateProductSelectionAction = InsurtechAction & {
  __typename?: "InsurtechUpdateProductSelectionAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  operationType: InsurtechUpdateOperationType;
  productId: Scalars["String"]["output"];
  removeDialogId?: Maybe<Scalars["String"]["output"]>;
  responseId: Scalars["String"]["output"];
};

export interface InsurtechUpdateProductSelectionRequestInput {
  operationType: InsurtechUpdateOperationType;
  productId: Scalars["String"]["input"];
  productType?: InputMaybe<InsurtechProductTypeInput>;
  responseId: Scalars["String"]["input"];
}

export type InsurtechUpdateProductSelectionResponse = {
  __typename?: "InsurtechUpdateProductSelectionResponse";
  banners?: Maybe<Array<InsurtechBanner>>;
  insuranceContinuationToken?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  status: InsurtechMutationStatus;
};

export type InsurtechUpdateTripAction = InsurtechAction & {
  __typename?: "InsurtechUpdateTripAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  addInsuranceError?: Maybe<Scalars["String"]["output"]>;
  addInsuranceText?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  offerId: Scalars["String"]["output"];
  operation: InsurtechUpdateOperationType;
  price: Scalars["String"]["output"];
  removeInsuranceError?: Maybe<Scalars["String"]["output"]>;
  removeInsuranceText?: Maybe<Scalars["String"]["output"]>;
  responseId?: Maybe<Scalars["String"]["output"]>;
};

export type InsurtechUrgencySection = InsurtechNotificationSection & {
  __typename?: "InsurtechUrgencySection";
  analytics?: Maybe<InsurtechAnalyticsData>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<EgdsStylizedText>;
};

export interface InsurtechUserDetailsInput {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  middleName?: InputMaybe<Scalars["String"]["input"]>;
}

export type InsurtechView = "DEFAULT_VIEW" | "NUDGE_ACCEPTANCE_VIEW" | "NUDGE_REJECTED_VIEW";

export type InsurtechViewDetail = {
  __typename?: "InsurtechViewDetail";
  id: Scalars["String"]["output"];
  sections?: Maybe<Array<InsurtechSectionDetail>>;
  type: Scalars["String"]["output"];
};

export type InsurtechViewHeading = InsurtechHeadingTemplate & {
  __typename?: "InsurtechViewHeading";
  badge?: Maybe<EgdsBadge>;
  header: EgdsHeading;
  id?: Maybe<Scalars["String"]["output"]>;
};

export interface InsurtechViewModule {
  footer?: Maybe<Array<InsurtechTextWrapperElement>>;
  heading?: Maybe<InsurtechModuleHeading>;
  id: InsurtechView;
  residency?: Maybe<InsurtechResidencyModule>;
}

export type IntelligentSearchResult = {
  __typename?: "IntelligentSearchResult";
  content: IsResultContent;
  header?: Maybe<EgdsHeading>;
};

export type IntelligentSearchResultsM2View = {
  __typename?: "IntelligentSearchResultsM2View";
  intelligentSearchByViewType: Array<IntelligentSearchResult>;
};

export type IntelligentSearchResultsM2ViewIntelligentSearchByViewTypeArgs = {
  requestPayload: IsRequestPayloadInput;
};

export type InteractionEventName = "BOOK" | "CHECKOUT_PAGE_LOAD" | "CONFIRMATION_PAGE_LOAD";

export type InteractionEventResponse = {
  __typename?: "InteractionEventResponse";
  feedbackMessage?: Maybe<EgdsToast>;
  uisPrimeMicroMessage?: Maybe<Scalars["String"]["output"]>;
};

export type InteractionMode = "FREE" | "RESTRICTED";

export type Interest = {
  __typename?: "Interest";
  desmetId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export interface InterestInput {
  desmetId?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
}

export type InventoryType =
  | "AICTRAVELGROUP"
  | "AMOMA"
  | "ANA"
  | "BESTBUYHOTEL"
  | "BONOTEL"
  | "CNTRAVEL"
  | "CTRIP"
  | "DESPEGAR"
  | "DIDATRAVEL"
  | "DIRECT_AGENCY"
  | "EUROPLAYAS"
  | "EXCITEHOLIDAYS"
  | "EZZEGO_1"
  | "GETAROOM"
  | "HOME_AWAY"
  | "HOTELDOBESTDAYTRAVEL"
  | "HOTEL_BEDS"
  | "HRS"
  | "JAC_TRAVEL"
  | "JAL"
  | "JALAN"
  | "JTB"
  | "JUMBOTOURS"
  | "MAGICROOMS"
  | "MERCHANT"
  | "MGBEDBANK"
  | "MIKI"
  | "MTS"
  | "NTA"
  | "NUITEE"
  | "OPAQUE"
  | "OSTROVOK"
  | "PEGASUS"
  | "RAKUTEN"
  | "RESTEL"
  | "ROOMER"
  | "SMYROOMS"
  | "SUNHOTELS"
  | "THOMASCOOK_EUR"
  | "THOMASCOOK_UK"
  | "TOURICO"
  | "TRAVELLANDA"
  | "TRAVELREPUBLIC"
  | "TRIPCOM"
  | "VENERE"
  | "VRBO"
  | "VRBO_MUVR"
  | "WELCOME_BEDS"
  | "WOORI"
  | "WORLDHOTELLINK"
  | "WORLDSPAN"
  | "WOTIF"
  | "YOUTRAVEL"
  | "ZUMATA";

export type ItemSummary = {
  __typename?: "ItemSummary";
  impressionAnalytics?: Maybe<TravellerClickAction>;
  itemHeader?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<ReviewSummary>>;
};

export type ItineraryCarousel = {
  __typename?: "ItineraryCarousel";
  buttonText: CruiseCarouselButtonText;
  cards: Array<CruiseItineraryItem>;
};

export type ItineraryDetails = {
  __typename?: "ItineraryDetails";
  apiKey: Scalars["String"]["output"];
  itineraryMapItems: Array<ItineraryItem>;
};

export type ItineraryDynamicMapDetails = {
  __typename?: "ItineraryDynamicMapDetails";
  close: Icon;
  itineraryCarousel: ItineraryCarousel;
  itineraryMap: ItineraryMap;
};

export type ItineraryDynamicMapItem = {
  __typename?: "ItineraryDynamicMapItem";
  button?: Maybe<EgdsOverlayButton>;
  coordinates: Coordinates;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type ItineraryItem = {
  __typename?: "ItineraryItem";
  coordinates: Coordinates;
  id: Scalars["String"]["output"];
  marker: Image;
};

export type ItineraryMap = {
  __typename?: "ItineraryMap";
  apiKey: Scalars["String"]["output"];
  markers: Array<ItineraryDynamicMapItem>;
};

export type ItineraryStaticMapDetails = {
  __typename?: "ItineraryStaticMapDetails";
  large: Image;
  medium: Image;
  small: Image;
};

export type JourneyConnectionInformation = AdditionalInformation | FlightsConnectionInformation;

export type JourneyDetails = {
  __typename?: "JourneyDetails";
  journeyHeader: EgdsTextContainer;
  merchandisingDetails: FlightsCategorizedList;
};

export type JourneyDetailsBadge = {
  __typename?: "JourneyDetailsBadge";
  badge: EgdsBadge;
  label?: Maybe<EgdsPlainText>;
  link?: Maybe<EgdsStandardLink>;
};

export type JourneyExperience = "NEW_JOURNEY_PLAYBACK" | "OLD_JOURNEY_PLAYBACK";

export type JourneyFlightSelection = {
  __typename?: "JourneyFlightSelection";
  journeyIndex: Scalars["Int"]["output"];
  offerIdentifier: Scalars["String"]["output"];
};

export interface JourneyFlightSelectionInput {
  journeyIndex: Scalars["Int"]["input"];
  offerIdentifier: Scalars["String"]["input"];
}

export type JourneyInformation = {
  __typename?: "JourneyInformation";
  destinationAirportCode: Scalars["String"]["output"];
  originAirportCode: Scalars["String"]["output"];
  tripType: Scalars["String"]["output"];
};

export type JourneyPart = {
  __typename?: "JourneyPart";
  connectionAdditionalInformation?: Maybe<AdditionalInformation>;
  flightsConnectionInformation: FlightsConnectionInformation;
};

export type JourneyPartsDynamicElements = {
  __typename?: "JourneyPartsDynamicElements";
  cabinClassAndBookingCode: Scalars["String"]["output"];
};

export type JourneySection = {
  __typename?: "JourneySection";
  journeyConnectionInformation: Array<JourneyConnectionInformation>;
  sectionLabel?: Maybe<EgdsPlainText>;
};

export type JourneySelectionDropDown = {
  __typename?: "JourneySelectionDropDown";
  icon: Icon;
  journeySelectionOptions: Array<FlightSelectedLegOption>;
};

export type JourneyStartEndDates = {
  __typename?: "JourneyStartEndDates";
  endDate: Scalars["String"]["output"];
  startDate: Scalars["String"]["output"];
};

export interface KcpConfigurationInput {
  encodedData?: InputMaybe<Scalars["String"]["input"]>;
  encodedInfo?: InputMaybe<Scalars["String"]["input"]>;
  resultCode?: InputMaybe<Scalars["String"]["input"]>;
  resultMessage?: InputMaybe<Scalars["String"]["input"]>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
}

export interface KcpPaymentConfigInput {
  encodedData?: InputMaybe<Scalars["String"]["input"]>;
  encodedInfo?: InputMaybe<Scalars["String"]["input"]>;
  resultCode?: InputMaybe<Scalars["String"]["input"]>;
  resultMessage?: InputMaybe<Scalars["String"]["input"]>;
  transactionCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type KeyPhrase = {
  __typename?: "KeyPhrase";
  text?: Maybe<Scalars["String"]["output"]>;
};

export type KeyPhrasesClientType = "DEFAULT" | "FILTER" | "SNIPPET";

export type KeyPhrasesComponent = {
  __typename?: "KeyPhrasesComponent";
  label?: Maybe<Scalars["String"]["output"]>;
  phrases?: Maybe<Array<KeyPhrase>>;
};

export type KlarnaAppDetails = {
  __typename?: "KlarnaAppDetails";
  link: EgdsStandardLink;
  logo: UiGraphic;
  secondaryText?: Maybe<EgdsPlainText>;
  text: EgdsStylizedText;
};

export type KlarnaDetails = {
  __typename?: "KlarnaDetails";
  analytics: ClientSideAnalytics;
  elementAttributes: Array<InstallmentPlanAttribute>;
  scriptAttributes: Array<InstallmentPlanAttribute>;
};

export type KnowYourCustomerAdditionalVerificationForm = {
  __typename?: "KnowYourCustomerAdditionalVerificationForm";
  description: Scalars["String"]["output"];
  form: KnowYourCustomerFormStep;
  prevLabel: Scalars["String"]["output"];
  submitLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type KnowYourCustomerAdditionalVerificationResponse =
  | KnowYourCustomerAdditionalVerificationForm
  | KnowYourCustomerFormError;

export interface KnowYourCustomerAddressInput {
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  street: Scalars["String"]["input"];
  street2: Scalars["String"]["input"];
}

export interface KnowYourCustomerAttestationInput {
  acceptanceTimestamp: Scalars["String"]["input"];
  acceptedBy: Scalars["String"]["input"];
  attestationReference: Scalars["String"]["input"];
  attestationVersion: Scalars["String"]["input"];
  ipAddress: Scalars["String"]["input"];
}

export interface KnowYourCustomerBusinessInfoInput {
  businessType?: InputMaybe<Scalars["String"]["input"]>;
  businessWebsite?: InputMaybe<Scalars["String"]["input"]>;
  confirmTaxId?: InputMaybe<Scalars["String"]["input"]>;
  country?: InputMaybe<Scalars["String"]["input"]>;
  dBA?: InputMaybe<Scalars["String"]["input"]>;
  registrationNumber?: InputMaybe<Scalars["String"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  website?: InputMaybe<Scalars["String"]["input"]>;
  yearsOfFormation?: InputMaybe<Scalars["String"]["input"]>;
}

export interface KnowYourCustomerDataInput {
  attestations?: InputMaybe<Array<KnowYourCustomerAttestationInput>>;
  businessAddress?: InputMaybe<KnowYourCustomerAddressInput>;
  businessInfo?: InputMaybe<KnowYourCustomerBusinessInfoInput>;
  confirmInfoAccuracy?: InputMaybe<Scalars["Boolean"]["input"]>;
  confirmSanctions?: InputMaybe<Scalars["Boolean"]["input"]>;
  keyPeople?: InputMaybe<Array<KnowYourCustomerKeyPersonInput>>;
  stockInfo?: InputMaybe<KnowYourCustomerStockInfoInput>;
  supportingDocuments?: InputMaybe<Array<KnowYourCustomerSupportingDocInput>>;
  taxId?: InputMaybe<KnowYourCustomerTaxIdInput>;
  trustPayload?: InputMaybe<Scalars["String"]["input"]>;
}

export type KnowYourCustomerForm = {
  __typename?: "KnowYourCustomerForm";
  breadcrumbs: Array<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  error?: Maybe<KnowYourCustomerFormError>;
  existingProfileInfo: KnowYourCustomerPaymentProfile;
  idVerification: KnowYourCustomerFormStep;
  nextLabel: Scalars["String"]["output"];
  prevLabel: Scalars["String"]["output"];
  submitLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type KnowYourCustomerFormError = {
  __typename?: "KnowYourCustomerFormError";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
};

export type KnowYourCustomerFormResponse = KnowYourCustomerForm | KnowYourCustomerFormError;

export type KnowYourCustomerFormStep = {
  __typename?: "KnowYourCustomerFormStep";
  dataSchema: Scalars["String"]["output"];
  uiSchema: Scalars["String"]["output"];
};

export type KnowYourCustomerInfoCard = {
  __typename?: "KnowYourCustomerInfoCard";
  leftText: Scalars["String"]["output"];
  rightText: Scalars["String"]["output"];
};

export interface KnowYourCustomerKeyPersonInput {
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  countryCode: Scalars["String"]["input"];
  dateOfBirth: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  phoneType: Scalars["String"]["input"];
  postalCode: Scalars["String"]["input"];
  role: Scalars["String"]["input"];
  state: Scalars["String"]["input"];
  street: Scalars["String"]["input"];
  street2: Scalars["String"]["input"];
  taxId: Scalars["String"]["input"];
  taxIdType: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
}

export type KnowYourCustomerMutation = {
  __typename?: "KnowYourCustomerMutation";
  submitAdditionalVerification: KnowYourCustomerSubmitResponse;
  submitForm: KnowYourCustomerSubmitResponse;
};

export type KnowYourCustomerMutationSubmitAdditionalVerificationArgs = {
  entityId: Scalars["String"]["input"];
  input: KnowYourCustomerDataInput;
};

export type KnowYourCustomerMutationSubmitFormArgs = {
  entityId: Scalars["String"]["input"];
  input: KnowYourCustomerDataInput;
};

export type KnowYourCustomerPaymentProfile = {
  __typename?: "KnowYourCustomerPaymentProfile";
  actionLabel: Scalars["String"]["output"];
  items: Array<KnowYourCustomerReviewItem>;
  name: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type KnowYourCustomerQuery = {
  __typename?: "KnowYourCustomerQuery";
  additionalVerification: KnowYourCustomerAdditionalVerificationResponse;
  form: KnowYourCustomerFormResponse;
  review: KnowYourCustomerReviewResponse;
};

export type KnowYourCustomerQueryAdditionalVerificationArgs = {
  entityId: Scalars["String"]["input"];
};

export type KnowYourCustomerQueryFormArgs = {
  entityId: Scalars["String"]["input"];
};

export type KnowYourCustomerQueryReviewArgs = {
  input: KnowYourCustomerDataInput;
};

export type KnowYourCustomerReview = {
  __typename?: "KnowYourCustomerReview";
  dataSchema: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  existingProfileInfo: KnowYourCustomerPaymentProfile;
  sections: Array<KnowYourCustomerReviewSection>;
  title: Scalars["String"]["output"];
  uiSchema: Scalars["String"]["output"];
};

export type KnowYourCustomerReviewItem = {
  __typename?: "KnowYourCustomerReviewItem";
  label: Scalars["String"]["output"];
  value: Array<Scalars["String"]["output"]>;
};

export type KnowYourCustomerReviewResponse = KnowYourCustomerFormError | KnowYourCustomerReview;

export type KnowYourCustomerReviewSection = {
  __typename?: "KnowYourCustomerReviewSection";
  card?: Maybe<KnowYourCustomerInfoCard>;
  items?: Maybe<Array<KnowYourCustomerReviewItem>>;
  table?: Maybe<DataTableBody>;
  title: Scalars["String"]["output"];
};

export interface KnowYourCustomerStockInfoInput {
  marketIdentifier: Scalars["String"]["input"];
  stockNumber: Scalars["String"]["input"];
  tickerSymbol: Scalars["String"]["input"];
}

export type KnowYourCustomerSubmitResponse = KnowYourCustomerFormError | KnowYourCustomerSubmitSuccess;

export type KnowYourCustomerSubmitSuccess = {
  __typename?: "KnowYourCustomerSubmitSuccess";
  description: Scalars["String"]["output"];
  icon: Icon;
  title: Scalars["String"]["output"];
};

export interface KnowYourCustomerSupportingDocInput {
  documentCreationTimestamp: Scalars["String"]["input"];
  documentFileFormat: Scalars["String"]["input"];
  documentType: Scalars["String"]["input"];
  documentUri: Scalars["String"]["input"];
}

export interface KnowYourCustomerTaxIdInput {
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type LlmSearchErrorView = {
  __typename?: "LLMSearchErrorView";
  errorMessage: Scalars["String"]["output"];
};

export type LslAdPreviewContent = {
  __typename?: "LSLAdPreviewContent";
  adCopy: Scalars["String"]["output"];
  adIndicatorBadge: EgdsStandardBadge;
  headline: Scalars["String"]["output"];
  locationName: Scalars["String"]["output"];
  moneyText: Scalars["String"]["output"];
  picture: EgdsImageCard;
  propertyName: Scalars["String"]["output"];
};

export interface LslCreativeInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type LabeledList = {
  __typename?: "LabeledList";
  elements: Array<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
};

export type LabeledValue = {
  __typename?: "LabeledValue";
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type LandingAirport = {
  __typename?: "LandingAirport";
  code?: Maybe<EgdsText>;
  name: EgdsText;
};

export type LandingAirportCard = {
  __typename?: "LandingAirportCard";
  airport: LandingAirport;
  badges: Array<EgdsBadge>;
  link: LandingAirportCardLink;
  proximity?: Maybe<LandingAirportCardProximity>;
};

export type LandingAirportCardLink = {
  __typename?: "LandingAirportCardLink";
  action: UiLinkAction;
  button?: Maybe<EgdsButton>;
};

export type LandingAirportCardProximity = {
  __typename?: "LandingAirportCardProximity";
  drivingDuration?: Maybe<EgdsText>;
};

export type LandingAirportsGroup = {
  __typename?: "LandingAirportsGroup";
  cards: Array<LandingAirportCard>;
  heading?: Maybe<LandingAirportsGroupHeading>;
  uisPrimeImpression?: Maybe<ClientSideAnalytics>;
};

export type LandingAirportsGroupHeading = {
  __typename?: "LandingAirportsGroupHeading";
  title: EgdsText;
};

export interface LandingAlternativeAirportsInput {
  maxNumberOfCard?: InputMaybe<Scalars["Int"]["input"]>;
}

export type LandingAlternativeAirportsResponse = LandingAirportsGroup;

export interface LandingDiscoveryContextInput {
  configurationId: Scalars["String"]["input"];
  destination?: InputMaybe<LandingDiscoveryDestinationContextInput>;
  lodging?: InputMaybe<LandingDiscoveryLodgingContextInput>;
  pageId: Scalars["String"]["input"];
}

export interface LandingDiscoveryDestinationContextInput {
  placeCoordinates?: InputMaybe<CoordinatesInput>;
  placeId?: InputMaybe<DiscoveryPlaceIdInput>;
}

export interface LandingDiscoveryLodgingContextInput {
  discoveryFlexFilters?: InputMaybe<DiscoveryFlexFiltersInput>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
}

export type LandingDiscoveryModule = {
  __typename?: "LandingDiscoveryModule";
  offers?: Maybe<LandingDiscoveryResult>;
};

export type LandingDiscoveryModuleOffersArgs = {
  context: ContextInput;
  discoveryContext: LandingDiscoveryContextInput;
};

export type LandingDiscoveryResult = DiscoveryItemGroups;

export type LandingFeaturedSupplier = LandingFeaturedSupplierLogo;

export type LandingFeaturedSupplierLogo = {
  __typename?: "LandingFeaturedSupplierLogo";
  image: Image;
  name?: Maybe<Scalars["String"]["output"]>;
  uisPrimeClick?: Maybe<ClientSideAnalytics>;
  uisPrimeImpression?: Maybe<ClientSideAnalytics>;
  url?: Maybe<HttpUri>;
};

export type LandingFeaturedSuppliers = {
  __typename?: "LandingFeaturedSuppliers";
  heading?: Maybe<LandingFeaturedSuppliersHeading>;
  suppliers: Array<LandingFeaturedSupplier>;
  uisPrimeImpression?: Maybe<ClientSideAnalytics>;
};

export type LandingFeaturedSuppliersSuppliersArgs = {
  maxSize?: InputMaybe<Scalars["Int"]["input"]>;
  minSize?: InputMaybe<Scalars["Int"]["input"]>;
};

export interface LandingFeaturedSuppliersFlightSearchCriteriaInput {
  departureDateOffsetInDays?: InputMaybe<Scalars["Int"]["input"]>;
  returnDepartureDateOffsetInDays?: InputMaybe<Scalars["Int"]["input"]>;
}

export type LandingFeaturedSuppliersHeading = {
  __typename?: "LandingFeaturedSuppliersHeading";
  subTitle?: Maybe<EgdsText>;
  title: EgdsText;
};

export interface LandingFeaturedSuppliersSearchCriteriaInput {
  flight?: InputMaybe<LandingFeaturedSuppliersFlightSearchCriteriaInput>;
}

export type LandingFlightDealsCard = {
  __typename?: "LandingFlightDealsCard";
  action: UiLinkAction;
  badge?: Maybe<EgdsBadge>;
  carrier: EgdsText;
  carrierAccessibility?: Maybe<Scalars["String"]["output"]>;
  dates: EgdsText;
  footer?: Maybe<EgdsText>;
  price: LandingPrice;
  timeline: LandingFlightTimeline;
};

export type LandingFlightDealsDisclaimer = {
  __typename?: "LandingFlightDealsDisclaimer";
  backgroundTheme?: Maybe<Scalars["String"]["output"]>;
  dialog?: Maybe<LandingFlightDealsDisclaimerDialog>;
  filters: Array<EgdsText>;
  trigger?: Maybe<LandingFlightDealsDisclaimerTrigger>;
};

export type LandingFlightDealsDisclaimerDialog = {
  __typename?: "LandingFlightDealsDisclaimerDialog";
  closeButton?: Maybe<EgdsButton>;
  description: EgdsText;
  title?: Maybe<EgdsText>;
};

export type LandingFlightDealsDisclaimerTrigger = {
  __typename?: "LandingFlightDealsDisclaimerTrigger";
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  text: EgdsText;
};

export type LandingFlightDealsGroup = {
  __typename?: "LandingFlightDealsGroup";
  disclaimer?: Maybe<LandingFlightDealsDisclaimer>;
  heading?: Maybe<EgdsText>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  items: Array<LandingFlightDealsGroupItem>;
  seeMoreDeals?: Maybe<LandingFlightSeeMoreDeals>;
};

export type LandingFlightDealsGroupItem = LandingFlightDealsCard;

export type LandingFlightDealsGroups = {
  __typename?: "LandingFlightDealsGroups";
  groups: Array<LandingFlightDealsGroup>;
  moduleLoadAnalytics?: Maybe<ClientSideAnalytics>;
};

export type LandingFlightSeeMoreDeals = {
  __typename?: "LandingFlightSeeMoreDeals";
  collapseButton: EgdsButton;
  expandButton: EgdsButton;
};

export type LandingFlightTimeline = {
  __typename?: "LandingFlightTimeline";
  airportsSeparatorIcon?: Maybe<Icon>;
  description: EgdsText;
  destination: LandingFlightTimelineEdge;
  duration?: Maybe<EgdsText>;
  legAccessibility?: Maybe<Scalars["String"]["output"]>;
  numberOfStops: Scalars["Int"]["output"];
  origin: LandingFlightTimelineEdge;
};

export type LandingFlightTimelineEdge = {
  __typename?: "LandingFlightTimelineEdge";
  airportDescription: EgdsText;
  date: EgdsText;
  differentDayArrival?: Maybe<EgdsText>;
  time: EgdsText;
  timelineAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type LandingPrice = {
  __typename?: "LandingPrice";
  price: PropertyPrice;
};

export type LanguageSelector = LocalePreferenceSelector & {
  __typename?: "LanguageSelector";
  heading: Scalars["String"]["output"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<LanguageSelectorOption>;
  queryParameterName: Scalars["String"]["output"];
  trackingEvent: Scalars["String"]["output"];
};

export type LanguageSelectorOption = LocaleSelectorOption & {
  __typename?: "LanguageSelectorOption";
  name: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export type LatestActivitySignal = {
  __typename?: "LatestActivitySignal";
  epochMilliSeconds?: Maybe<Scalars["String"]["output"]>;
  signal?: Maybe<UserActivitySignal>;
};

export type LayoutPosition = "CENTER" | "LEFT" | "TOP";

export type LearnMoreLink = {
  __typename?: "LearnMoreLink";
  text: Scalars["String"]["output"];
};

export type LedgerTab = EgdsTab & {
  __typename?: "LedgerTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  ledgerType: WalletLedgerTabType;
  tabId: Scalars["String"]["output"];
};

export interface LegacyPropertySearchCriteriaInput {
  amenities?: InputMaybe<Array<Scalars["String"]["input"]>>;
  guestRating?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  priceBuckets?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  privacyTrackingState?: InputMaybe<PrivacyTrackingState>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
  stars?: InputMaybe<Scalars["String"]["input"]>;
  structureTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type LegacyWidget = {
  __typename?: "LegacyWidget";
  icons?: Maybe<Array<Scalars["String"]["output"]>>;
  imageURL?: Maybe<Scalars["URL"]["output"]>;
  productDetailViewModels?: Maybe<Array<TripsWidgetTripProductDetailViewModel>>;
  productsCountText?: Maybe<Scalars["String"]["output"]>;
  relevance?: Maybe<TripsWidgetTripDetailItemRelevance>;
  subtitleText?: Maybe<Scalars["String"]["output"]>;
  textWithIconViewModels?: Maybe<Array<TripsWidgetTextWithIconViewModel>>;
  titleText?: Maybe<Scalars["String"]["output"]>;
  tripId?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type LegacyWidgetComponent = {
  __typename?: "LegacyWidgetComponent";
  trips: Array<LegacyWidget>;
};

export type LegalCheckBox = {
  __typename?: "LegalCheckBox";
  checkBox: EgdsBasicCheckBox;
  disclaimerMessage: LegalSection;
};

export type LegalCheckBoxSection = {
  __typename?: "LegalCheckBoxSection";
  childCheckBoxes?: Maybe<Array<LegalCheckBox>>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  parentCheckBox: LegalCheckBox;
};

export type LegalConcept =
  | "LEGAL_CONCEPT_COOKIES"
  | "LEGAL_CONCEPT_INVALID"
  | "LEGAL_CONCEPT_PRIVACY"
  | "LEGAL_CONCEPT_SORT_AND_FILTER"
  | "LEGAL_CONCEPT_TERMS_AND_CONDITIONS";

export type LegalDetails = {
  __typename?: "LegalDetails";
  /** @deprecated Use disclaimerMessages() instead */
  disclaimerMessage?: Maybe<Array<LegalSection>>;
  /** @deprecated Use disclaimerMessagesWithInsurance() instead */
  disclaimerMessageWithInsurance?: Maybe<LegalSection>;
  disclaimerMessages?: Maybe<Array<Section>>;
  disclaimerMessagesWithInsurance?: Maybe<Array<Section>>;
  isValidationRequired: Scalars["Boolean"]["output"];
};

export type LegalDialogErrorView = {
  __typename?: "LegalDialogErrorView";
  errorMessage: Scalars["String"]["output"];
  goBackText: Scalars["String"]["output"];
  tryAgainText: Scalars["String"]["output"];
};

export type LegalDialogLink = {
  __typename?: "LegalDialogLink";
  action: UiLinkAction;
  dialogId: Scalars["String"]["output"];
  elements?: Maybe<EgdsSpannableList>;
  errorViewDetails: LegalDialogErrorView;
  sheet?: Maybe<EgdsSheet>;
  text: Scalars["String"]["output"];
  toolbar?: Maybe<EgdsDialogToolbar>;
};

export type LegalElement = EgdsInlineLink | EgdsPlainText | LegalDialogLink;

export type LegalSection = {
  __typename?: "LegalSection";
  elements: Array<LegalElement>;
};

export type LevelOneList = {
  __typename?: "LevelOneList";
  items: Array<LevelOneListItem>;
  type: EgdsTextListType;
};

export type LevelOneListItem = {
  __typename?: "LevelOneListItem";
  children?: Maybe<LevelTwoList>;
  text: EgdsText;
};

export type LevelThreeList = {
  __typename?: "LevelThreeList";
  items: Array<LevelThreeListItem>;
  type: EgdsTextListType;
};

export type LevelThreeListItem = {
  __typename?: "LevelThreeListItem";
  text: EgdsText;
};

export type LevelTwoList = {
  __typename?: "LevelTwoList";
  items: Array<LevelTwoListItem>;
  type: EgdsTextListType;
};

export type LevelTwoListItem = {
  __typename?: "LevelTwoListItem";
  children?: Maybe<LevelThreeList>;
  text: EgdsText;
};

export type LikeSocialInteractionElement = {
  __typename?: "LikeSocialInteractionElement";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<SocialInteractionElementAnalyticEvent>;
  count?: Maybe<Scalars["Int"]["output"]>;
  isSelected: Scalars["Boolean"]["output"];
  primaryIcon: Icon;
  selectedIcon?: Maybe<Icon>;
};

export type LikeUnlikeSocialInteractionAction = "LIKE" | "UNLIKE";

export type LimitToCertainTravelersInput = {
  __typename?: "LimitToCertainTravelersInput";
  mutualExclusiveSwitches: Array<TravelerSwitchMutualExclusion>;
  travelersSwitchOptions: Array<SupplyRatePlanSwitch>;
};

export type LimitedResultMessageOnRecommendationCard = CarRecommendationCard & {
  __typename?: "LimitedResultMessageOnRecommendationCard";
  action?: Maybe<CarAction>;
  icon?: Maybe<Icon>;
  recommendationDialog?: Maybe<CarsDialog>;
};

export type LineOfBusinessDomain =
  | "ACTIVITIES"
  | "CAR"
  | "CRUISES"
  | "FLIGHT"
  | "GROUNDTRANSFERS"
  | "LODGING"
  | "PACKAGES"
  | "RAILS"
  | "UNCLASSIFIED";

export type LineOfBusinessType =
  | "ACTIVITY"
  | "AIR"
  | "CAR"
  | "CRUISE"
  | "FEE"
  | "INSURANCE"
  | "ITINERARY"
  | "LODGING"
  | "TRAIN";

export type LinkWrapper = {
  __typename?: "LinkWrapper";
  link: EgdsStandardLink;
};

export type Listings = "ADS_ONLY";

export type ListingsToggle = {
  __typename?: "ListingsToggle";
  action?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ListingsToggleAnalytics>;
  button?: Maybe<Scalars["String"]["output"]>;
  defaultItemsShown?: Maybe<Scalars["Int"]["output"]>;
  localizationMap?: Maybe<Array<GraphQlPair>>;
};

export type ListingsToggleAnalytics = {
  __typename?: "ListingsToggleAnalytics";
  toggled?: Maybe<ClientSideAnalytics>;
  untoggled?: Maybe<ClientSideAnalytics>;
};

export interface LiveAnnounce {
  liveAnnounceMessage?: Maybe<Scalars["String"]["output"]>;
  liveAnnouncePoliteness?: Maybe<LiveAnnouncePoliteness>;
}

export type LiveAnnouncePoliteness = "ASSERTIVE" | "OFF" | "POLITE";

export type LoadedFlightsSelectedJourneyReview = FlightsSelectedJourneyReview & {
  __typename?: "LoadedFlightsSelectedJourneyReview";
  badges?: Maybe<Array<EgdsBadge>>;
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  collapseAnalytics?: Maybe<FlightsAnalytics>;
  expandAnalytics?: Maybe<FlightsAnalytics>;
  fareDetails: FareDetails;
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  /** @deprecated This field is now deprecated., replace with Use the sibling of FlightsSelectedJourneyReview */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyHighlights?: Maybe<Array<FlightsJourneyHighlights>>;
  journeyInformation?: Maybe<JourneyInformation>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  upsellNudgingFeatureEnabled: Scalars["Boolean"]["output"];
};

export type LoadedFlightsStandardJourneySummary = FlightsJourneySummary & {
  __typename?: "LoadedFlightsStandardJourneySummary";
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  heading: FlightsMessageAndAccessibility;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
};

export type LoaderSize = "LARGE" | "MEDIUM" | "SMALL";

export type LoadingFlightsSelectedJourneyReview = FlightsSelectedJourneyReview & {
  __typename?: "LoadingFlightsSelectedJourneyReview";
  changeFlight: FlightsAction;
  changeFlightDialog?: Maybe<FlightsDialogChangeFlight>;
  collapseAnalytics?: Maybe<FlightsAnalytics>;
  expandAnalytics?: Maybe<FlightsAnalytics>;
  fareDetails: FareDetails;
  fareSummarySkeletonCount: Scalars["Int"]["output"];
  flightsJourneyHeaders: FlightsJourneyHeaders;
  flightsJourneyInformation?: Maybe<FlightsDetailedJourneyInformation>;
  flightsJourneyPolicies?: Maybe<FlightsTextSection>;
  flightsJourneySummary: FlightsJourneySummary;
  /** @deprecated This field is now deprecated., replace with Use the sibling of FlightsSelectedJourneyReview */
  hygieneAmenitiesPresentation?: Maybe<FlightsHygieneAmenitiesPresentation>;
  journeyHighlights?: Maybe<Array<FlightsJourneyHighlights>>;
  journeyInformation?: Maybe<JourneyInformation>;
  onViewedAnalytics?: Maybe<FlightsAnalytics>;
  upsellNudgingFeatureEnabled: Scalars["Boolean"]["output"];
};

export type LoadingFlightsStandardJourneySummary = FlightsJourneySummary & {
  __typename?: "LoadingFlightsStandardJourneySummary";
  basicFlightDetails: Array<FlightsMessageAndAccessibility>;
  differentDayArrival?: Maybe<FlightsMessageAndAccessibility>;
  heading: FlightsMessageAndAccessibility;
  journeyAmenities?: Maybe<FlightsJourneyAmenities>;
  redEyeMessage?: Maybe<FlightsIconAndLabel>;
  skeletonCount: Scalars["Int"]["output"];
};

export interface LocAirport {
  airportCode: Scalars["String"]["output"];
  airportName?: Maybe<Scalars["String"]["output"]>;
}

export type LocaleInfo = {
  __typename?: "LocaleInfo";
  baseName: Scalars["Locale"]["output"];
  textDirection: TextDirection;
};

export interface LocalePreferenceSelector {
  heading: Scalars["String"]["output"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<Maybe<LocaleSelectorOption>>;
  queryParameterName: Scalars["String"]["output"];
  /** @deprecated Replaced by onChangeAnalytics, better name and correct type */
  trackingEvent: Scalars["String"]["output"];
}

export interface LocaleSelectorOption {
  name: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
}

export type Location = "CHECKOUT_RIGHT_SIDE_PAGE" | "CHECKOUT_TOP_OF_PAGE" | "COUPON_IN_MODULE";

export type LocationExpando = {
  __typename?: "LocationExpando";
  expando: EgdsExpandoListItem;
  expandoContent: LocationExpandoContent;
  expandoSubTitle: ExpandoSubtitles;
};

export type LocationExpandoContent = {
  __typename?: "LocationExpandoContent";
  checkBoxGroup: EgdsCheckBoxGroup;
  clearButton: UiTertiaryButton;
  selectAllButton: UiTertiaryButton;
  title: Scalars["String"]["output"];
};

export type LocationRecommendationCard = CarRecommendationCard & {
  __typename?: "LocationRecommendationCard";
  action?: Maybe<CarAction>;
  city?: Maybe<CarPhraseText>;
  distanceText?: Maybe<CarPhraseText>;
  icon?: Maybe<Icon>;
  location?: Maybe<CarsRichText>;
  pickUpLocation?: Maybe<CarSearchLocation>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export interface LocationScoutOverlayRequestInput {
  postId: Scalars["String"]["input"];
  postType: Scalars["String"]["input"];
  productId: LocationScoutProductId;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  ratingScore?: InputMaybe<Scalars["Float"]["input"]>;
  screenId: Scalars["String"]["input"];
}

export type LocationScoutOverlayResponse = {
  __typename?: "LocationScoutOverlayResponse";
  socialInteractionElements: Array<Maybe<SocialInteractionElement>>;
};

export type LocationScoutProductId = "SRP" | "TRAVEL_SHOP";

export type LocationSubInfoMap = {
  __typename?: "LocationSubInfoMap";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingAdaptExAnalyticsEvent = {
  __typename?: "LodgingAdaptExAnalyticsEvent";
  campaignId: Scalars["String"]["output"];
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  events: Array<LodgingAdaptExEvent>;
};

export type LodgingAdaptExEvent = {
  __typename?: "LodgingAdaptExEvent";
  banditDisplayed: Scalars["Boolean"]["output"];
  eventTarget: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  payloadId: Scalars["String"]["output"];
};

export type LodgingAmenitiesDialog = LodgingDialog & {
  __typename?: "LodgingAmenitiesDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingBadgesGroup = {
  __typename?: "LodgingBadgesGroup";
  primaryBadge?: Maybe<EgdsStandardBadge>;
  secondaryBadge?: Maybe<EgdsStandardBadge>;
  tertiaryBadge?: Maybe<EgdsStandardBadge>;
};

export type LodgingBasicContentActionDialog = {
  __typename?: "LodgingBasicContentActionDialog";
  content: Array<Scalars["String"]["output"]>;
  dialog: EgdsActionDialog;
};

export type LodgingBookingPaymentDetails = {
  __typename?: "LodgingBookingPaymentDetails";
  amountPaid: Scalars["String"]["output"];
  amountPaidLabel: Scalars["String"]["output"];
  bookingBalance: Scalars["String"]["output"];
  bookingBalanceLabel: Scalars["String"]["output"];
  previouslyRefunded: Scalars["String"]["output"];
  previouslyRefundedLabel: Scalars["String"]["output"];
};

export type LodgingBookingPlayback = {
  __typename?: "LodgingBookingPlayback";
  changePlaybackSections?: Maybe<Array<LodgingChangePlaybackSection>>;
};

export type LodgingBookingPlaybackType = "COMBINED" | "NEW" | "ORIGINAL";

export type LodgingButton = {
  __typename?: "LodgingButton";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  adaptExSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  adaptExSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  analytics?: Maybe<ClientSideAnalytics>;
  lodgingClientSideAnalyticsSuccess?: Maybe<LodgingClientSideAnalyticsSuccess>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  text: Scalars["String"]["output"];
};

export interface LodgingButtonAction {
  analytics?: Maybe<ClientSideAnalytics>;
  text?: Maybe<Scalars["String"]["output"]>;
}

export type LodgingButtonsGroup = {
  __typename?: "LodgingButtonsGroup";
  primaryButton?: Maybe<AdTransparencyTrigger>;
  secondaryButton?: Maybe<AdTransparencyTrigger>;
  tertiaryButton?: Maybe<AdTransparencyTrigger>;
};

export type LodgingCalculator = {
  __typename?: "LodgingCalculator";
  bookingBalance: Scalars["Float"]["output"];
  bookingLineItems: Array<BookingLineItem>;
  currency: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
  footnote: Scalars["String"]["output"];
  header: BookingLineItemHeader;
  reset: UiSecondaryButton;
  summary: BookingSummaryItem;
};

export type LodgingCalculatorInput = {
  __typename?: "LodgingCalculatorInput";
  fullAmount: LodgingCalculatorInputItem;
  partialAmount: LodgingCalculatorInputItem;
  percentage: LodgingCalculatorInputItem;
};

export type LodgingCalculatorInputItem = {
  __typename?: "LodgingCalculatorInputItem";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  placeholder?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type LodgingCancelAdjustmentStep = {
  __typename?: "LodgingCancelAdjustmentStep";
  backButton: Scalars["String"]["output"];
  changeReason: AgentToolsChangeOrCancelReason;
  continueButton: Scalars["String"]["output"];
};

export type LodgingCancelCancelStep = {
  __typename?: "LodgingCancelCancelStep";
  applyWaiverButton?: Maybe<Scalars["String"]["output"]>;
  continueButton: Scalars["String"]["output"];
};

export type LodgingCancelConfirmStep = {
  __typename?: "LodgingCancelConfirmStep";
  closeButton: Scalars["String"]["output"];
  confirmationCard: BookingConfirmation;
};

export type LodgingCancelErrorBanners = {
  __typename?: "LodgingCancelErrorBanners";
  loadError: AgentToolsErrorBanner;
  submissionError: AgentToolsErrorBanner;
  timeoutError: AgentToolsErrorBanner;
};

export type LodgingCancelForm = {
  __typename?: "LodgingCancelForm";
  adjustmentStep?: Maybe<LodgingCancelAdjustmentStep>;
  bookingBalanceAmount?: Maybe<Scalars["Float"]["output"]>;
  cancelStep: LodgingCancelCancelStep;
  errorBanners: LodgingCancelErrorBanners;
  initialStepIndicator: AgentToolsStepIndicator;
  isOutsidePenalty: Scalars["Boolean"]["output"];
  itineraryNumber: Scalars["String"]["output"];
  loadingText: Scalars["String"]["output"];
  penaltyAmount?: Maybe<Scalars["Float"]["output"]>;
  reviewStep: LodgingCancelReviewStep;
  stepIndicatorWithAdjustment?: Maybe<AgentToolsStepIndicator>;
  stepIndicatorWithoutAdjustment: AgentToolsStepIndicator;
  tripId: Scalars["String"]["output"];
  tripStage: TripStage;
};

export type LodgingCancelMutationElement = LodgingCancelConfirmStep | LodgingCancelMutationError;

export type LodgingCancelMutationError = {
  __typename?: "LodgingCancelMutationError";
  detail?: Maybe<Scalars["String"]["output"]>;
};

export interface LodgingCancelMutationInput {
  approver?: InputMaybe<ApproverInput>;
  reasonCodes?: InputMaybe<ReasonCodesMutationInput>;
  waiverAmount?: InputMaybe<Scalars["Float"]["input"]>;
  waivingSource?: InputMaybe<WaivingSource>;
}

export type LodgingCancelMutationResponse = {
  __typename?: "LodgingCancelMutationResponse";
  element: LodgingCancelMutationElement;
  reportedEvents?: Maybe<Scalars["String"]["output"]>;
  status: LodgingCancelStatus;
};

export type LodgingCancelReviewStep = {
  __typename?: "LodgingCancelReviewStep";
  backButton: Scalars["String"]["output"];
  submitButton: Scalars["String"]["output"];
  updatePaymentInfoButton: Scalars["String"]["output"];
};

export type LodgingCancelStatus = "Failure" | "Success";

export type LodgingCancellationPolicyDialog = LodgingDialog & {
  __typename?: "LodgingCancellationPolicyDialog";
  content: Array<CancellationPolicyDialogContentEntry>;
  primaryUIButton?: Maybe<UiPrimaryButton>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingCancellationPolicyInfo = {
  __typename?: "LodgingCancellationPolicyInfo";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  policyContent: LodgingDetailedTimeline;
  title: EgdsStylizedText;
};

export type LodgingCard = {
  __typename?: "LodgingCard";
  adaptexSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  backgroundTheme?: Maybe<EgdsCardBackgroundTheme>;
  callOut?: Maybe<EgdsText>;
  /** @deprecated Deprecated in favor of cardClickAction */
  cardLink?: Maybe<UiLinkAction>;
  clickActionId?: Maybe<Scalars["String"]["output"]>;
  clickstreamEvents: LodgingCardClickstreamEvents;
  compareSection?: Maybe<LodgingCardCompareSection>;
  contents?: Maybe<Array<ShoppingProductCardContentContainer>>;
  dataAttributes: Array<GraphQlPair>;
  dateSection?: Maybe<LodgingCardDateSection>;
  featuredHeader?: Maybe<LodgingCardFeaturedHeader>;
  /** @deprecated Deprecated in favor of headingSection.featuredMessages */
  featuredMessages: Array<LodgingCardFeaturedMessage>;
  footer?: Maybe<ShoppingProductCardFooterSection>;
  footerActions: Array<LodgingCardFooterAction>;
  /** @deprecated replaced with footer */
  footers?: Maybe<Array<ShoppingProductCardFooter>>;
  /** @deprecated Deprecated cus this is a hackathon */
  hackathonVideo?: Maybe<HackathonVideo>;
  headingSection?: Maybe<LodgingCardHeadingSection>;
  id: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  mediaSection: LodgingCardMediaSection;
  priceSection?: Maybe<LodgingCardPriceSection>;
  /** @deprecated Deprecated in favor of headingSection.heading */
  propertyName: EgdsHeading;
  /** @deprecated Deprecated in favor of headingSection.productRating */
  propertyRating?: Maybe<EgdsRating>;
  renderAnalytics?: Maybe<ClientSideAnalytics>;
  shoppingInvokeFunctionParams?: Maybe<Array<ShoppingProductCardFunctionParametersContainers>>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
  summarySections: Array<LodgingCardSection>;
};

export type LodgingCardActionButton = LodgingPrimaryAltButton | LodgingScrollToButton;

export type LodgingCardBadgedRating = {
  __typename?: "LodgingCardBadgedRating";
  badge: EgdsBadge;
  phrases?: Maybe<Array<LodgingCardPhrase>>;
};

export type LodgingCardChangeAction = LodgingCardChangeUnitDialog | LodgingCardChangeUnitSheet;

export type LodgingCardChangeUnitDialog = {
  __typename?: "LodgingCardChangeUnitDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardChangeUnitDialogAction = {
  __typename?: "LodgingCardChangeUnitDialogAction";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardChangeUnitSheet = {
  __typename?: "LodgingCardChangeUnitSheet";
  sheet: EgdsSheet;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardChangeUnitSheetAction = {
  __typename?: "LodgingCardChangeUnitSheetAction";
  sheet: EgdsSheet;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardClickstreamEvents = {
  __typename?: "LodgingCardClickstreamEvents";
  productPresented?: Maybe<LodgingProductEvent>;
  productSelected?: Maybe<LodgingProductEvent>;
};

export type LodgingCardCompareAction = EgdsBasicCheckBox;

export type LodgingCardCompareSection = {
  __typename?: "LodgingCardCompareSection";
  compareAction: LodgingCardCompareAction;
};

export type LodgingCardDateSection = {
  __typename?: "LodgingCardDateSection";
  /** @deprecated Deprecated in favor of messages */
  date?: Maybe<LodgingCardDateSectionText>;
  messages?: Maybe<Array<LodgingCardDateSectionText>>;
};

export type LodgingCardDateSectionText = EgdsStylizedText;

export type LodgingCardDetailsAction = LodgingCardPropertyDetailsDialog | LodgingCardUnitDetailsDialog;

export type LodgingCardFeaturedHeader = {
  __typename?: "LodgingCardFeaturedHeader";
  header: EgdsHeading;
};

export type LodgingCardFeaturedMessage = {
  __typename?: "LodgingCardFeaturedMessage";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type LodgingCardFooterAction = LodgingCardLinkAction | ShoppingButton;

export type LodgingCardHeadingSection = {
  __typename?: "LodgingCardHeadingSection";
  amenities: Array<EgdsIconText>;
  featuredMessages: Array<LodgingCardFeaturedMessage>;
  heading: Scalars["String"]["output"];
  locationInfo?: Maybe<Array<ProductCardLocationInfo>>;
  messages: Array<EgdsText>;
  missingFilters?: Maybe<EgdsStylizedText>;
  perkBadges?: Maybe<Array<EgdsLoyaltyBadge>>;
  productRating?: Maybe<EgdsRating>;
  standardBadges?: Maybe<Array<EgdsStandardBadge>>;
  /** @deprecated Field for use by experimental features */
  tnlFields?: Maybe<Array<GraphQlPair>>;
};

export type LodgingCardLinkAction = {
  __typename?: "LodgingCardLinkAction";
  link: EgdsStandardLink;
};

export type LodgingCardMedia = EgdsGalleryCarousel & {
  __typename?: "LodgingCardMedia";
  accessibilityHeadingText: Scalars["String"]["output"];
  media: Array<MediaItem>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingCardMediaItem = MediaItem & {
  __typename?: "LodgingCardMediaItem";
  media: Media;
};

export type LodgingCardMediaSection = {
  __typename?: "LodgingCardMediaSection";
  /** @deprecated Deprecated in favor of mediaSection.badges.primaryBadge */
  badge?: Maybe<EgdsStandardBadge>;
  badges?: Maybe<LodgingBadgesGroup>;
  buttons?: Maybe<LodgingButtonsGroup>;
  gallery?: Maybe<LodgingGalleryCarousel>;
  /** @deprecated Deprecated in favor of saveTripItem */
  saveItem?: Maybe<LodgingSaveItem>;
  saveTripItem?: Maybe<TripsSaveItem>;
  /** @deprecated Deprecated in favor of mediaSection.badges.secondaryBadge */
  secondaryBadge?: Maybe<EgdsStandardBadge>;
};

export type LodgingCardMoreDetailsTrigger = {
  __typename?: "LodgingCardMoreDetailsTrigger";
  accessibilityLabel: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
};

export type LodgingCardPhrase = {
  __typename?: "LodgingCardPhrase";
  accessibilityLabel: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of phraseParts */
  parts: Array<EgdsText>;
  phraseParts: Array<LodgingCardPhrasePart>;
};

export type LodgingCardPhrasePart =
  | EgdsIconRating
  | EgdsPlainText
  | EgdsStylizedText
  | ShoppingActionableIcon
  | ShoppingLink;

export type LodgingCardPriceSection = {
  __typename?: "LodgingCardPriceSection";
  actionButton?: Maybe<LodgingCardActionButton>;
  badge?: Maybe<EgdsStandardBadge>;
  /** @deprecated Deprecated in favor of actionButton */
  button?: Maybe<LodgingPrimaryAltButton>;
  priceSummary?: Maybe<PropertyPrice>;
  standardBadge?: Maybe<LodgingStandardBadge>;
};

export type LodgingCardProductSummarySection = {
  __typename?: "LodgingCardProductSummarySection";
  amenities: Array<EgdsIconText>;
  /** @deprecated Deprecated in favor of changeActionDialog and changeActionSheet. */
  changeAction?: Maybe<LodgingCardChangeAction>;
  changeActionDialog?: Maybe<LodgingCardChangeUnitDialogAction>;
  changeActionSheet?: Maybe<LodgingCardChangeUnitSheetAction>;
  /** @deprecated Deprecated in favor of detailsLink */
  descriptionSection?: Maybe<LodgingCardPropertyDescriptionSection>;
  detailsAction?: Maybe<LodgingCardDetailsAction>;
  detailsLink?: Maybe<ShoppingLink>;
  /** @deprecated Deprecated in favor of highlightMessages */
  footerMessages?: Maybe<EgdsTextList>;
  /** @deprecated Deprecated: Deprecated in favor of guestRatingSection */
  guestRating?: Maybe<LodgingCardPhrase>;
  /** @deprecated Deprecated: Deprecated in favor of guestRatingSectionV2 */
  guestRatingSection?: Maybe<LodgingCardRatingSection>;
  guestRatingSectionV2?: Maybe<LodgingCardRatingSectionV2>;
  heading?: Maybe<EgdsHeading>;
  highlightMessages?: Maybe<EgdsSpannableList>;
  messages: Array<EgdsText>;
  /** @deprecated Field for use by experimental features */
  tnlFields?: Maybe<Array<GraphQlPair>>;
  /** @deprecated Deprecated in favor of footerMessages */
  valuePropositions?: Maybe<EgdsTextIconList>;
  vendorLogo?: Maybe<Image>;
};

export type LodgingCardPropertyDescriptionSection = {
  __typename?: "LodgingCardPropertyDescriptionSection";
  description?: Maybe<EgdsParagraph>;
  heading?: Maybe<EgdsHeading>;
  image?: Maybe<Image>;
  link?: Maybe<EgdsStandardLink>;
};

export type LodgingCardPropertyDetailsDialog = {
  __typename?: "LodgingCardPropertyDetailsDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCardRating = {
  __typename?: "LodgingCardRating";
  badge?: Maybe<EgdsBadge>;
  phrases?: Maybe<Array<LodgingCardPhrase>>;
};

export type LodgingCardRatingSection = LodgingCardBadgedRating | LodgingCardPhrase;

export type LodgingCardRatingSectionV2 = LodgingCardPhrase | LodgingCardRating;

export type LodgingCardSection = LodgingCardProductSummarySection;

export type LodgingCardUnitDetailsDialog = {
  __typename?: "LodgingCardUnitDetailsDialog";
  dialog: EgdsFullScreenDialog;
  trigger: LodgingCardMoreDetailsTrigger;
};

export type LodgingCarouselContainer = {
  __typename?: "LodgingCarouselContainer";
  carousel?: Maybe<EgdsCarousel>;
  heading: LodgingCarouselContainerHeading;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<LodgingCarouselContainerItem>;
  /** @deprecated Deprecated. This is meant to not be used outside of restricted scenarios. */
  seoRichContentMetaData?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingCarouselContainerHeading = EgdsExpandoCard | EgdsStylizedText;

export type LodgingCarouselContainerItem = LodgingCard | LodgingUnitCard;

export type LodgingCategorizedUnit = AccessibilityField & {
  __typename?: "LodgingCategorizedUnit";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  baseFeatures?: Maybe<Array<PropertyInfoItem>>;
  /** @deprecated Deprecated in favor of highlightedMessages */
  cancellationPolicy?: Maybe<RatePlanMessage>;
  /** @deprecated This is for a TNL, it is temporary. TNL ID 47076 */
  cardExpandAction?: Maybe<LodgingCategorizedUnitExpandAction>;
  /** @deprecated Deprecated in favor of PropertyUnit.detailsDialog */
  detailsDialog?: Maybe<LodgingDialog>;
  expandoItemsCard?: Maybe<LodgingExpandoItemsCard>;
  featureHeader?: Maybe<EgdsHeading>;
  features?: Maybe<Array<PropertyInfoItem>>;
  /** @deprecated Deprecated - do not use */
  footerActions?: Maybe<Array<UiPrimaryButton>>;
  footerButton?: Maybe<EgdsButton>;
  footerText?: Maybe<LodgingTrackingText>;
  /** @deprecated Experimental */
  generatedHighlights?: Maybe<Array<EgdsGraphicText>>;
  header?: Maybe<LodgingHeader>;
  highlightedMessages: Array<RatePlanMessage>;
  /** @deprecated Deprecated - do not use */
  includedPerks?: Maybe<PropertyInfoContent>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  lodgingReview?: Maybe<LodgingReviewSection>;
  primaryHeader?: Maybe<LodgingOfferSelectionHeader>;
  primarySelections?: Maybe<Array<LodgingPrimaryOfferSelection>>;
  roomDifferentiators?: Maybe<HighlightedAttributesSection>;
  secondaryHeader?: Maybe<LodgingOfferSelectionHeader>;
  tertiaryHeader?: Maybe<LodgingOfferSelectionHeader>;
  unitId?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingCategorizedUnitExpandAction = {
  __typename?: "LodgingCategorizedUnitExpandAction";
  /** @deprecated This is for a TNL, it is temporary. TNL ID 47076 */
  collapseButton?: Maybe<UiSecondaryButton>;
  /** @deprecated This is for a TNL, it is temporary. TNL ID 47076 */
  expandButton?: Maybe<UiPrimaryButton>;
  /** @deprecated This is for a TNL, it is temporary. TNL ID 47076 */
  expanded: Scalars["Boolean"]["output"];
};

export type LodgingChangeButton = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type LodgingChangeChallengePendingStep = {
  __typename?: "LodgingChangeChallengePendingStep";
  challengeConfig: Scalars["String"]["output"];
  resumeButton: Scalars["String"]["output"];
};

export type LodgingChangeChangeStep = {
  __typename?: "LodgingChangeChangeStep";
  backButton: Scalars["String"]["output"];
};

export type LodgingChangeCheckoutResponse = {
  __typename?: "LodgingChangeCheckoutResponse";
  isRefund: Scalars["Boolean"]["output"];
  refundOrPaymentAmount: Scalars["Float"]["output"];
  summaryCard: AgentToolsSummaryCard;
};

export type LodgingChangeCheckoutStep = {
  __typename?: "LodgingChangeCheckoutStep";
  backButton: Scalars["String"]["output"];
  changeReason?: Maybe<AgentToolsChangeOrCancelReason>;
  checkoutButton: Scalars["String"]["output"];
};

export type LodgingChangeConfirmStep = {
  __typename?: "LodgingChangeConfirmStep";
  closeButton: Scalars["String"]["output"];
  confirmationCard: BookingConfirmation;
};

export type LodgingChangeErrorBanners = {
  __typename?: "LodgingChangeErrorBanners";
  /** @deprecated expanding scope to Occupancy */
  dateUnavailableError: AgentToolsErrorBanner;
  inventoryUnavailableError: AgentToolsErrorBanner;
  loadError: AgentToolsErrorBanner;
  submissionError: AgentToolsErrorBanner;
};

export type LodgingChangeForm = {
  __typename?: "LodgingChangeForm";
  bookingBalanceAmount?: Maybe<Scalars["Float"]["output"]>;
  bookingInfo: BookingInfo;
  changeStep: LodgingChangeChangeStep;
  checkoutStep: LodgingChangeCheckoutStep;
  errorBanners: LodgingChangeErrorBanners;
  isAuthorizedToChangeFop?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Move to BookingInfo */
  isOutsidePenalty: Scalars["Boolean"]["output"];
  loadingText: Scalars["String"]["output"];
  orderLineGuid: Scalars["String"]["output"];
  penaltyAmount?: Maybe<Scalars["Float"]["output"]>;
  reviewStep: LodgingChangeReviewStep;
  stepIndicator: AgentToolsStepIndicator;
  /** @deprecated Move to BookingInfo */
  tripId: Scalars["String"]["output"];
  /** @deprecated Move to BookingInfo */
  tripStage: TripStage;
  updatePaymentStep?: Maybe<UpdatePaymentStep>;
};

export type LodgingChangeLanding = {
  __typename?: "LodgingChangeLanding";
  destinationCard: DestinationCard;
  orderLineGuid: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type LodgingChangeListings = {
  __typename?: "LodgingChangeListings";
  /** @deprecated No use after dialog implementation */
  banner?: Maybe<LodgingChangeShoppingBanner>;
  /** @deprecated Moved to comboChangeType */
  initialDisplayState?: Maybe<Scalars["String"]["output"]>;
  listings?: Maybe<Array<LodgingCategorizedUnit>>;
};

export type LodgingChangeMutationElement =
  | LodgingChangeChallengePendingStep
  | LodgingChangeConfirmStep
  | LodgingChangeMutationError;

export type LodgingChangeMutationError = {
  __typename?: "LodgingChangeMutationError";
  /** @deprecated use error for error banner */
  detail?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<AgentToolsErrorBanner>;
  isPaymentError?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface LodgingChangeMutationInput {
  approver?: InputMaybe<ApproverInput>;
  paymentAssistSessionId?: InputMaybe<Scalars["String"]["input"]>;
  reasonCodes?: InputMaybe<ReasonCodesMutationInput>;
  selectedOffer: PropertyNaturalKeyInput;
  waiverAmount?: InputMaybe<Scalars["Float"]["input"]>;
  waivingSource?: InputMaybe<WaivingSource>;
}

export type LodgingChangeMutationResponse = {
  __typename?: "LodgingChangeMutationResponse";
  element: LodgingChangeMutationElement;
  reportedEvents?: Maybe<Scalars["String"]["output"]>;
  status: LodgingChangeStatus;
};

export type LodgingChangePlaybackButton = {
  __typename?: "LodgingChangePlaybackButton";
  action: Scalars["String"]["output"];
  button: LodgingChangeButton;
  toggledText?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingChangePlaybackSection = {
  __typename?: "LodgingChangePlaybackSection";
  items?: Maybe<Array<LodgingChangePlaybackSectionItem>>;
  sectionId: Scalars["String"]["output"];
  separator?: Maybe<LodgingChangePlaybackSectionSeparator>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingChangePlaybackSectionItem =
  | LodgingChangeListings
  | LodgingChangePlaybackButton
  | LodgingChangePriceDetails
  | LodgingCriteriaPlaybackCard
  | LodgingMicroCard;

export type LodgingChangePlaybackSectionSeparator = {
  __typename?: "LodgingChangePlaybackSectionSeparator";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
};

export type LodgingChangePriceDetails = {
  __typename?: "LodgingChangePriceDetails";
  content?: Maybe<PricePresentation>;
  expando?: Maybe<LodgingPricePresentationExpando>;
};

export type LodgingChangeReasonCalculator = LodgingCalculator | LodgingWaiverCalculator;

export type LodgingChangeReviewStep = {
  __typename?: "LodgingChangeReviewStep";
  backButton: Scalars["String"]["output"];
  submitButton: Scalars["String"]["output"];
  updatePaymentInfoButton?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingChangeShoppingBanner = {
  __typename?: "LodgingChangeShoppingBanner";
  content?: Maybe<Array<LodgingChangeShoppingBannerText>>;
  graphic?: Maybe<UiGraphic>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingChangeShoppingBannerText = EgdsPlainText | EgdsStylizedText;

export type LodgingChangeStatus = "Failure" | "Pending" | "Success";

export type LodgingChatbot = {
  __typename?: "LodgingChatbot";
  action?: Maybe<UiPrimaryButton>;
  botNoLabel: Scalars["String"]["output"];
  botSkipLabel: Scalars["String"]["output"];
  botYesLabel: Scalars["String"]["output"];
  content?: Maybe<Scalars["String"]["output"]>;
  feedbackContentLabel: Scalars["String"]["output"];
  feedbackFormContentLabel: Scalars["String"]["output"];
  feedbackFormLabel: Scalars["String"]["output"];
  feedbackFormSubmitLabel: Scalars["String"]["output"];
  feedbackFormTitleLabel: Scalars["String"]["output"];
  header?: Maybe<LodgingHeader>;
};

export type LodgingClientSideAnalyticsSuccess = {
  __typename?: "LodgingClientSideAnalyticsSuccess";
  campaignId: Scalars["String"]["output"];
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  events: Array<LodgingClientSideAnalyticsSuccessEvents>;
};

export type LodgingClientSideAnalyticsSuccessEvents = {
  __typename?: "LodgingClientSideAnalyticsSuccessEvents";
  banditDisplayed: Scalars["Boolean"]["output"];
  eventTarget: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  payloadId: Scalars["String"]["output"];
};

export type LodgingCompareActionSheet = {
  __typename?: "LodgingCompareActionSheet";
  cancelAction?: Maybe<UiSecondaryButton>;
  compareAction?: Maybe<UiPrimaryButton>;
  compareActionPrompt?: Maybe<Scalars["String"]["output"]>;
  compareActionStatus?: Maybe<EgdsCardinalLocalizedText>;
  itemTemplate?: Maybe<LodgingCompareActionSheetItemTemplate>;
  maxCount: Scalars["Int"]["output"];
  sheet?: Maybe<EgdsSheet>;
  toolbar?: Maybe<LodgingCompareActionSheetToolbar>;
};

export type LodgingCompareActionSheetItemTemplate = {
  __typename?: "LodgingCompareActionSheetItemTemplate";
  accessibilityLabel: EgdsBasicLocalizedText;
  button?: Maybe<EgdsOverlayButton>;
};

export type LodgingCompareActionSheetToolbar = {
  __typename?: "LodgingCompareActionSheetToolbar";
  collapseAccessibilityLabel: Scalars["String"]["output"];
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  expandAccessibilityLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
};

export type LodgingCompareAttribute = {
  __typename?: "LodgingCompareAttribute";
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<EgdsStylizedText>;
};

export type LodgingCompareCell = {
  __typename?: "LodgingCompareCell";
  content?: Maybe<LodgingCompareCellContent>;
};

export type LodgingCompareCellContent =
  | EgdsIconRating
  | EgdsStandardBadge
  | LodgingCardPhrase
  | LodgingCardPriceSection
  | LodgingCompareTextItems;

export type LodgingCompareRemoveAction = {
  __typename?: "LodgingCompareRemoveAction";
  action?: Maybe<UiTertiaryButton>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingCompareRow = {
  __typename?: "LodgingCompareRow";
  cells: Array<Maybe<LodgingCompareCell>>;
  theme: LodgingCompareRowTheme;
};

export type LodgingCompareRowTheme = "COMBINE_NEXT" | "SPOTLIGHT" | "STANDARD";

export type LodgingCompareSection = {
  __typename?: "LodgingCompareSection";
  accessibilityHeaderRow: Array<Maybe<Scalars["String"]["output"]>>;
  compareRows: Array<Maybe<LodgingCompareRow>>;
  mediaRow?: Maybe<Array<Maybe<LodgingCardMediaSection>>>;
  removeRow?: Maybe<Array<Maybe<LodgingCompareRemoveAction>>>;
  viewRow?: Maybe<Array<Maybe<UiPrimaryButton>>>;
};

export type LodgingCompareSectionView = {
  __typename?: "LodgingCompareSectionView";
  content?: Maybe<LodgingCompareSection>;
  error?: Maybe<LodgingErrorResponse>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
};

export type LodgingCompareTextItems = {
  __typename?: "LodgingCompareTextItems";
  items?: Maybe<Array<Maybe<LodgingCompareAttribute>>>;
};

export type LodgingComplexDialog = LodgingDialog & {
  __typename?: "LodgingComplexDialog";
  content?: Maybe<Array<DialogContent>>;
  primaryUIButton?: Maybe<UiPrimaryButton>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingComplexLinkMessage = {
  __typename?: "LodgingComplexLinkMessage";
  actionLink: LodgingLinkMessage;
  text: LodgingPlainMessage;
};

export type LodgingCreativeCard = {
  __typename?: "LodgingCreativeCard";
  adPreviewButton: UiSecondaryButton;
  creativeDialog: CreativeDialog;
  imagePicker: ImagePicker;
  languageSelector: EgdsSelect;
  pictureCard: CreativePictureCard;
};

export interface LodgingCreativeInput {
  canOverrideImage: Scalars["Boolean"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  imageId?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type LodgingCriteriaPlaybackCard = {
  __typename?: "LodgingCriteriaPlaybackCard";
  criteria?: Maybe<Array<EgdsTextListItem>>;
  criteriaType?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingCrossSellProductType = "CAR";

export type LodgingDestinationResults = {
  __typename?: "LodgingDestinationResults";
  datedSearches: LodgingList;
};

export type LodgingDestinationResultsDatedSearchesArgs = {
  dataType: Scalars["String"]["input"];
  pageType: Scalars["String"]["input"];
};

export type LodgingDetailedTimeline = {
  __typename?: "LodgingDetailedTimeline";
  policies: Array<LodgingPolicy>;
  title?: Maybe<LodgingDetailedTimelineText>;
};

export type LodgingDetailedTimelineText = EgdsGraphicText | EgdsStylizedText;

export interface LodgingDialog {
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
}

export type LodgingDialogErrorResponse = {
  __typename?: "LodgingDialogErrorResponse";
  body: Scalars["String"]["output"];
  button: UiPrimaryButton;
};

export type LodgingDialogToolbar = {
  __typename?: "LodgingDialogToolbar";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingDialogTrigger = {
  __typename?: "LodgingDialogTrigger";
  actionDetails?: Maybe<DialogAction>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  dialogName: Scalars["String"]["output"];
  /** @deprecated Replaced with the more flexible ClientSideAnalytics type., replace with clientSideAnalytics.referrerId */
  referrerId?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingDialogTriggerMessage = FindAnswersDialogTrigger &
  HeaderMessage & {
    __typename?: "LodgingDialogTriggerMessage";
    accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
    adaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
    clientSideAnalytics?: Maybe<ClientSideAnalytics>;
    /** @deprecated Use this trigger message as part of a dialog. */
    dialogTrigger: LodgingDialogTrigger;
    icon?: Maybe<Icon>;
    mark?: Maybe<Mark>;
    secondaryValue?: Maybe<Scalars["String"]["output"]>;
    theme?: Maybe<LodgingMessageTheme>;
    value: Scalars["String"]["output"];
    valueWeight?: Maybe<TextWeight>;
  };

export type LodgingEnrichedMessage = AccessibilityField & {
  __typename?: "LodgingEnrichedMessage";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<Badge>;
  egdsMark?: Maybe<Mark>;
  icon?: Maybe<Icon>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<LodgingMark>;
  state?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use this field. Deprecated in favor of a new type that will include two message types that can be used to display main text and sub text. */
  subText?: Maybe<Scalars["String"]["output"]>;
  subtexts?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Use state to indicate theme. */
  theme?: Maybe<LodgingMessageTheme>;
  value: Scalars["String"]["output"];
};

export type LodgingErrorResponse = {
  __typename?: "LodgingErrorResponse";
  body?: Maybe<Scalars["String"]["output"]>;
  button: UiPrimaryButton;
  errorRecoveryButtons?: Maybe<Array<UiTertiaryButton>>;
  header?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
};

export interface LodgingExpando {
  collapseButton: LodgingButton;
  expandButton: LodgingButton;
  items?: Maybe<Scalars["Int"]["output"]>;
  lines?: Maybe<Scalars["Int"]["output"]>;
}

export type LodgingExpandoItemsCard = {
  __typename?: "LodgingExpandoItemsCard";
  background?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<EgdsExpando>;
  header?: Maybe<LodgingHeader>;
  items: Array<EgdsTextIconListItem>;
};

export type LodgingExpandoPeek = LodgingExpando & {
  __typename?: "LodgingExpandoPeek";
  collapseButton: LodgingButton;
  expandButton: LodgingButton;
  items?: Maybe<Scalars["Int"]["output"]>;
  lines?: Maybe<Scalars["Int"]["output"]>;
};

export type LodgingExperiment = {
  __typename?: "LodgingExperiment";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type LodgingExperimentOwner = {
  __typename?: "LodgingExperimentOwner";
  channels: Array<Scalars["String"]["output"]>;
  org: Scalars["String"]["output"];
  team: Scalars["String"]["output"];
};

export interface LodgingExperimentalFeature {
  description: Scalars["String"]["output"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
}

export type LodgingExperimentalMetaData = LodgingExperimentalFeature & {
  __typename?: "LodgingExperimentalMetaData";
  description: Scalars["String"]["output"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
};

export interface LodgingExperimentalSection {
  features: Array<LodgingExperimentalMetaData>;
}

export type LodgingExperimentalSectionFeaturesArgs = {
  createdAfter?: InputMaybe<DateInput>;
  createdBefore?: InputMaybe<DateInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  org?: InputMaybe<Scalars["String"]["input"]>;
  team?: InputMaybe<Scalars["String"]["input"]>;
};

export type LodgingFilterAttributes = {
  __typename?: "LodgingFilterAttributes";
  category: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingFilterClickstreamEvents = {
  __typename?: "LodgingFilterClickstreamEvents";
  filterDeselected?: Maybe<LodgingFilterEvent>;
  filterSelected?: Maybe<LodgingFilterEvent>;
};

export type LodgingFilterDialogPill = {
  __typename?: "LodgingFilterDialogPill";
  filterDialog?: Maybe<LodgingFilterSelectionDialog>;
  triggerPill?: Maybe<EgdsBasicTriggerPill>;
};

export type LodgingFilterEvent = {
  __typename?: "LodgingFilterEvent";
  event: EgClickstreamEvent;
  search_request: LodgingSearchRequest;
};

export type LodgingFilterPill = EgdsBasicPill | LodgingFilterDialogPill;

export type LodgingFilterSelectionDialog = {
  __typename?: "LodgingFilterSelectionDialog";
  clearAll?: Maybe<EgdsButton>;
  dialog: EgdsActionDialog;
  filterSection?: Maybe<ShoppingSortAndFilterField>;
  toolbar: EgdsDialogToolbar;
};

export type LodgingForm = {
  __typename?: "LodgingForm";
  action: Scalars["String"]["output"];
  inputs?: Maybe<Array<LodgingFormInput>>;
  method: LodgingFormMethod;
  submit: LodgingButton;
};

export type LodgingFormFieldSelector = EgdsBasicCheckBox | EgdsBasicSelect | EgdsBasicStepInput;

export type LodgingFormInput = LodgingTextInput;

export type LodgingFormMethod = "GET" | "POST";

export type LodgingFormSelector = {
  __typename?: "LodgingFormSelector";
  primary: LodgingFormFieldSelector;
  secondary?: Maybe<LodgingSelectorSection>;
};

export type LodgingGalleryCarousel = EgdsGalleryCarousel & {
  __typename?: "LodgingGalleryCarousel";
  accessibilityHeadingText: Scalars["String"]["output"];
  adaptExAnalyticsAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  adaptExAnalyticsSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  carouselScrimType?: Maybe<CarouselScrimType>;
  exitAnalytics?: Maybe<ClientSideAnalytics>;
  imageClickAnalytics?: Maybe<ClientSideAnalytics>;
  intersectionAnalytics?: Maybe<ClientSideAnalytics>;
  media: Array<LodgingMediaItem>;
  navClickAnalytics?: Maybe<ClientSideAnalytics>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingGalleryDialog = LodgingDialog & {
  __typename?: "LodgingGalleryDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingGraphic = EgdsStandardBadge | Icon | Illustration | Mark;

export type LodgingGuestGroup = {
  __typename?: "LodgingGuestGroup";
  adults: LodgingFormSelector;
  children?: Maybe<LodgingFormSelector>;
  maxChildren?: Maybe<Scalars["Int"]["output"]>;
  maxGuest: Scalars["Int"]["output"];
};

export type LodgingGuestGroups = LodgingGuestGroup | LodgingPetGroup;

export type LodgingHeader = AccessibilityField & {
  __typename?: "LodgingHeader";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use EGDSStandardBadge */
  badge?: Maybe<Badge>;
  egdsStandardBadge?: Maybe<EgdsStandardBadge>;
  headerImage?: Maybe<Image>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  label?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<Mark>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingHeading = {
  __typename?: "LodgingHeading";
  /** @deprecated No longer used in latest version of uitk-react-text */
  level: Scalars["Int"]["output"];
  size: Scalars["Int"]["output"];
  tag?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type LodgingIllustration = {
  __typename?: "LodgingIllustration";
  /** @deprecated Do not use. Use Illustration.url instead. */
  assetUri?: Maybe<HttpUri>;
  /** @deprecated Do not use. use Illustration.description instead. */
  description?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingInputDialogTrigger = FindAnswersDialogTrigger & {
  __typename?: "LodgingInputDialogTrigger";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  component: LodgingTextInput;
};

export type LodgingInputType = "EMAIL" | "HIDDEN" | "PASSWORD" | "TEL" | "TEXT" | "URL";

export type LodgingInstallmentPlan = {
  __typename?: "LodgingInstallmentPlan";
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  installmentPlanDetails: InstallmentPlanDetails;
};

export type LodgingInstallmentPlanOptions = {
  __typename?: "LodgingInstallmentPlanOptions";
  textRequired: Scalars["Boolean"]["output"];
  totalPrice: Scalars["String"]["output"];
};

export type LodgingIntentCard = {
  __typename?: "LodgingIntentCard";
  gallery: LodgingGalleryCarousel;
  headingTitle: Scalars["String"]["output"];
  impressionTracking: ClientSideImpressionEventAnalytics;
  message?: Maybe<Scalars["String"]["output"]>;
  primaryButton: UiPrimaryButton;
};

export interface LodgingJumpLink {
  icon?: Maybe<Icon>;
  localizedName: Scalars["String"]["output"];
  sectionName: Scalars["String"]["output"];
}

export type LodgingLeadingGraphic = LodgingGalleryCarousel | PropertyImage | Video;

export type LodgingLink = {
  __typename?: "LodgingLink";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Don't use this., replace with clientSideAnalytics */
  referrerId?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<Scalars["String"]["output"]>;
  uri: HttpUri;
  /** @deprecated use uri, replace with uri */
  url: Scalars["URL"]["output"];
};

export type LodgingLinkButton = {
  __typename?: "LodgingLinkButton";
  action?: Maybe<UiLinkAction>;
  button: EgdsButton;
};

export type LodgingLinkMessage = AccessibilityField &
  HeaderMessage & {
    __typename?: "LodgingLinkMessage";
    accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
    label?: Maybe<Scalars["String"]["output"]>;
    link: LodgingLink;
    value: Scalars["String"]["output"];
  };

export type LodgingList = {
  __typename?: "LodgingList";
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  items: Array<LodgingListLineItem>;
  title?: Maybe<LodgingHeader>;
};

export type LodgingListLineItem = {
  __typename?: "LodgingListLineItem";
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  label?: Maybe<EgdsText>;
  link?: Maybe<EgdsStandardLink>;
};

export type LodgingListingDivider = {
  __typename?: "LodgingListingDivider";
  actions: Array<EgdsButton>;
  backgroundTheme?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  subtitle?: Maybe<EgdsText>;
  title?: Maybe<EgdsHeading>;
};

export type LodgingMapDialog = LodgingDialog & {
  __typename?: "LodgingMapDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingMapMarker = EgdsMapMarker & {
  __typename?: "LodgingMapMarker";
  card?: Maybe<LodgingCard>;
  markerIconType: Array<Scalars["String"]["output"]>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type LodgingMapTrigger = {
  __typename?: "LodgingMapTrigger";
  /** @deprecated use ClientSideAnalytics on labels.EGDSInlineLink.action.analytics */
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  /** @deprecated use labels field instead */
  label?: Maybe<Scalars["String"]["output"]>;
  labels?: Maybe<Array<LodgingMapTriggerLabel>>;
  large?: Maybe<Image>;
  medium?: Maybe<Image>;
  small?: Maybe<Image>;
};

export type LodgingMapTriggerLabel = EgdsInlineLink | EgdsPlainText;

export type LodgingMark = {
  __typename?: "LodgingMark";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
};

export type LodgingMediaItem = MediaItem & {
  __typename?: "LodgingMediaItem";
  id?: Maybe<Scalars["String"]["output"]>;
  media: Media;
  mediaFit?: Maybe<Scalars["String"]["output"]>;
  trackingId?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingMemberSignInDialog = {
  __typename?: "LodgingMemberSignInDialog";
  actionDialog?: Maybe<EgdsActionDialog>;
  dialogContent?: Maybe<EgdsParagraph>;
  title?: Maybe<Scalars["String"]["output"]>;
  trigger?: Maybe<LodgingDialogTriggerMessage>;
};

export type LodgingMessageTheme = "AGGRESSIVE" | "EMPHASIS" | "HIGHLIGHT" | "STRONG";

export type LodgingMicroCard = {
  __typename?: "LodgingMicroCard";
  contents: Array<LodgingMicroCardText>;
  heading?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
};

export type LodgingMicroCardText = EgdsGraphicText | EgdsPlainText | EgdsStylizedText;

export type LodgingNotificationsCard = {
  __typename?: "LodgingNotificationsCard";
  header?: Maybe<LodgingHeader>;
  messages: Array<RatePlanMessage>;
};

export type LodgingOfferFilters = {
  __typename?: "LodgingOfferFilters";
  accessibilityHeader?: Maybe<Scalars["String"]["output"]>;
  filterPills?: Maybe<Array<LodgingFilterPill>>;
  heading?: Maybe<EgdsHeading>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  liveAnnounce?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingOfferListing = LodgingCategorizedUnit | LodgingHeader | MessageResult | TotalPriceReassuranceCard;

export type LodgingOfferOption = LiveAnnounce & {
  __typename?: "LodgingOfferOption";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  description: Scalars["String"]["output"];
  dialog?: Maybe<LodgingPlainDialog>;
  enabled: Scalars["Boolean"]["output"];
  liveAnnounceMessage?: Maybe<Scalars["String"]["output"]>;
  liveAnnouncePoliteness?: Maybe<LiveAnnouncePoliteness>;
  optionId?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["String"]["output"]>;
  selected: Scalars["Boolean"]["output"];
  /** @deprecated Deprecated for subTexts */
  stylizedSubTexts?: Maybe<Array<EgdsStylizedText>>;
  /** @deprecated Deprecated for stylizedSubTexts */
  subText?: Maybe<Scalars["String"]["output"]>;
  subTexts?: Maybe<Array<LodgingOfferOptionSubText>>;
};

export type LodgingOfferOptionSubText = EgdsGraphicText | EgdsStylizedText;

export type LodgingOfferSelectionHeader = {
  __typename?: "LodgingOfferSelectionHeader";
  complexDialog?: Maybe<LodgingOfferSelectionHeaderDialog>;
  /** @deprecated Use complexDialog instead */
  dialog?: Maybe<LodgingComplexDialog>;
  title: LodgingHeader;
};

export type LodgingOfferSelectionHeaderDialog = LodgingComplexDialog | LodgingPolicyDialog;

export type LodgingOneKeyBurnSwitch = {
  __typename?: "LodgingOneKeyBurnSwitch";
  graphic?: Maybe<UiGraphic>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  switch: EgdsStandardSwitch;
  themeProvider?: Maybe<EgdsThemeProvider>;
};

export type LodgingPagination = {
  __typename?: "LodgingPagination";
  paginationLabel?: Maybe<Scalars["String"]["output"]>;
  subSets?: Maybe<LodgingPaginationSubSets>;
  trigger?: Maybe<LodgingPaginationTrigger>;
};

export type LodgingPaginationNavigationButtons = {
  __typename?: "LodgingPaginationNavigationButtons";
  nextButton: UiPrimaryButton;
  previousButton: UiPrimaryButton;
};

export type LodgingPaginationSubSet = {
  __typename?: "LodgingPaginationSubSet";
  size: Scalars["Int"]["output"];
  startingIndex: Scalars["Int"]["output"];
};

export type LodgingPaginationSubSets = {
  __typename?: "LodgingPaginationSubSets";
  nextSubSet?: Maybe<LodgingPaginationSubSet>;
  previousSubset?: Maybe<LodgingPaginationSubSet>;
};

export type LodgingPaginationTrigger = LodgingPaginationNavigationButtons | LodgingScrollTrigger | UiPrimaryButton;

export type LodgingPaymentOptionsDialogContent = {
  __typename?: "LodgingPaymentOptionsDialogContent";
  messages: Array<EgdsGraphicText>;
};

export type LodgingPetGroup = {
  __typename?: "LodgingPetGroup";
  input: LodgingFormSelector;
};

export type LodgingPill = {
  __typename?: "LodgingPill";
  pillLabel: Scalars["String"]["output"];
  query?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingPlainDialog = LodgingDialog & {
  __typename?: "LodgingPlainDialog";
  content: Array<Scalars["String"]["output"]>;
  /** @deprecated use primaryUIButton, replace with primaryUIButton */
  primaryButton?: Maybe<LodgingButton>;
  primaryUIButton?: Maybe<UiPrimaryButton>;
  /** @deprecated use secondaryUIButton, replace with secondaryUIButton */
  secondaryButton?: Maybe<LodgingButton>;
  secondaryUIButton?: Maybe<UiSecondaryButton>;
  /** @deprecated use tertiaryUIButton, replace with tertiaryUIButton */
  tertiaryButton?: Maybe<LodgingButton>;
  tertiaryUIButton?: Maybe<UiTertiaryButton>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingPlainMessage = HeaderMessage & {
  __typename?: "LodgingPlainMessage";
  value: Scalars["String"]["output"];
};

export type LodgingPlottableMapMarker = LodgingMapMarker;

export type LodgingPolicy = {
  __typename?: "LodgingPolicy";
  /** @deprecated Deprecated in favor of policyClientSideAnalytics which is nullable */
  clientSideAnalytics: ClientSideAnalytics;
  messageSections: Array<MessageSection>;
  policyClientSideAnalytics?: Maybe<ClientSideAnalytics>;
  stepIndicator: LodgingStepIndicator;
  title?: Maybe<EgdsStylizedText>;
};

export type LodgingPolicyDialog = {
  __typename?: "LodgingPolicyDialog";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  policyContent: LodgingDetailedTimeline;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type LodgingPolicyPopover = {
  __typename?: "LodgingPolicyPopover";
  policyPrimaryText: EgdsInlineLink;
  policySecondaryText?: Maybe<EgdsStylizedText>;
  sheet?: Maybe<EgdsSheet>;
  sheetContent?: Maybe<Array<EgdsParagraph>>;
};

export type LodgingPrepareChangeCheckout = {
  __typename?: "LodgingPrepareChangeCheckout";
  orderLineGuid: Scalars["String"]["output"];
  orderNumber: Scalars["String"]["output"];
  propertyNaturalKey: PropertyNaturalKey;
};

export type LodgingPrepareChangeCheckoutAction = {
  __typename?: "LodgingPrepareChangeCheckoutAction";
  lodgingInput: LodgingPrepareChangeCheckout;
  options: Array<PrepareChangeCheckoutOption>;
  tripId: Scalars["String"]["output"];
};

export interface LodgingPrepareChangeCheckoutInput {
  orderLineGuid: Scalars["String"]["input"];
  orderNumber: Scalars["String"]["input"];
  propertyNaturalKey: PropertyNaturalKeyInput;
}

export type LodgingPrepareCheckout = {
  __typename?: "LodgingPrepareCheckout";
  action: LodgingPrepareCheckoutAction;
  checkoutButton: UiPrimaryButton;
};

export type LodgingPrepareCheckoutAction = {
  __typename?: "LodgingPrepareCheckoutAction";
  adaptExSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  /** @deprecated Use analyticsList instead */
  analytics?: Maybe<ClientSideAnalytics>;
  analyticsList?: Maybe<Array<ClientSideAnalytics>>;
  checkoutOptions?: Maybe<Array<CheckoutOptions>>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  moreDetailsAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  offerTokens?: Maybe<Array<OfferToken>>;
  propertyNaturalKeys: Array<PropertyNaturalKey>;
  totalPrice?: Maybe<Money>;
};

export type LodgingPriceBreakDown = {
  __typename?: "LodgingPriceBreakDown";
  /** @deprecated This field will hold button/CTA localized content and will be behind TnL - 34934,34935. */
  ctaLabel?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<LodgingPriceBreakDownEntry>;
  header: Scalars["String"]["output"];
  priceBreakDownEntries?: Maybe<Array<LodgingPriceBreakDownEntry>>;
  unbundledEntries?: Maybe<Array<LodgingPriceBreakDownEntry>>;
};

export type LodgingPriceBreakDownEntry = {
  __typename?: "LodgingPriceBreakDownEntry";
  amount?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  detail?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingPricePresentationExpando = {
  __typename?: "LodgingPricePresentationExpando";
  collapsedAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel?: Maybe<PricePresentationLineItem>;
  expandedAnalytics?: Maybe<ClientSideAnalytics>;
  expandedLabel?: Maybe<PricePresentationLineItem>;
};

export type LodgingPrimaryAltButton = {
  __typename?: "LodgingPrimaryAltButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiLinkAction>;
  adaptexSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  analytics?: Maybe<ClientSideAnalytics>;
  attemptEvents: Array<LodgingAdaptExAnalyticsEvent>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  successEvents: Array<LodgingAdaptExAnalyticsEvent>;
};

export type LodgingPrimaryOfferSelection = {
  __typename?: "LodgingPrimaryOfferSelection";
  primarySelection?: Maybe<LodgingOfferOption>;
  propertyUnit: PropertyUnit;
  secondarySelections?: Maybe<Array<LodgingSecondaryOfferSelection>>;
};

export type LodgingProduct = {
  __typename?: "LodgingProduct";
  product_id: Scalars["String"]["output"];
};

export type LodgingProductEvent = {
  __typename?: "LodgingProductEvent";
  event: EgClickstreamEvent;
  product_list: Array<LodgingProduct>;
  search_request: LodgingSearchRequest;
};

export type LodgingProductView = LodgingProductViewSuccess;

export type LodgingProductViewSuccess = {
  __typename?: "LodgingProductViewSuccess";
  card: LodgingCard;
};

export type LodgingPropertyActivitiesLinkButton = {
  __typename?: "LodgingPropertyActivitiesLinkButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  text: Scalars["String"]["output"];
};

export type LodgingPropertyCompare = {
  __typename?: "LodgingPropertyCompare";
  actionSheet?: Maybe<LodgingCompareActionSheet>;
  compareSection: LodgingCompareSectionView;
  dialog: EgdsFullScreenDialog;
};

export type LodgingPropertyCompareCompareSectionArgs = {
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  propertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripItems?: InputMaybe<TripItemsToCompareInput>;
};

export type LodgingRebound = {
  __typename?: "LodgingRebound";
  itemDetails?: Maybe<Array<LodgingReboundDetail>>;
  messagingCard?: Maybe<UiMessagingCard>;
  messagingDetails?: Maybe<MessagingDetails>;
};

export type LodgingReboundDetail = {
  __typename?: "LodgingReboundDetail";
  bookingDateRange?: Maybe<DateRange>;
  chainId?: Maybe<Scalars["String"]["output"]>;
  chainName?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  percentage: Scalars["Int"]["output"];
  propertyId?: Maybe<Scalars["String"]["output"]>;
  stayDateRange?: Maybe<DateRange>;
};

export type LodgingRecommendationClickstreamEvent = {
  __typename?: "LodgingRecommendationClickstreamEvent";
  recommendationResponseId?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingRefundError = {
  __typename?: "LodgingRefundError";
  error?: Maybe<AgentToolsErrorBanner>;
};

export type LodgingRefundErrorBanners = {
  __typename?: "LodgingRefundErrorBanners";
  timeoutError: AgentToolsErrorBanner;
};

export type LodgingRefundForm = {
  __typename?: "LodgingRefundForm";
  bookingInfo: BookingInfo;
  /** @deprecated Move to the AgentToolsChangeOrCancelReason */
  calculator: LodgingCalculator;
  /** @deprecated Cancel Button removed from the UI */
  cancelButton: UiSecondaryButton;
  closeButton: UiSecondaryButton;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  errorBanners: LodgingRefundErrorBanners;
  formUnAuthorizedErrorText?: Maybe<Scalars["String"]["output"]>;
  formValidationErrorText: Scalars["String"]["output"];
  goBackButton: UiSecondaryButton;
  /** @deprecated Change to unsupportedFopBanner */
  infoBanner?: Maybe<LodgingRefundInfoBanner>;
  isFopSupportedOverRefund?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Move to BookingInfo */
  isOutsidePenalty: Scalars["Boolean"]["output"];
  loadingText: Scalars["String"]["output"];
  paymentDetails: LodgingBookingPaymentDetails;
  reviewButton: UiSecondaryButton;
  sections: LodgingRefundFormSections;
  stepIndicator: AgentToolsStepIndicator;
  submitButton: UiSecondaryButton;
  /** @deprecated Move to BookingInfo */
  tripId: Scalars["String"]["output"];
  /** @deprecated Move to BookingInfo */
  tripStage: TripStage;
  unSupportedFopBanner?: Maybe<UiBanner>;
  unSupportedFopTooltip?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Change Type to UIBanner */
  unsupportedFopBanner?: Maybe<LodgingRefundInfoBanner>;
  updatePaymentInfoButton?: Maybe<Scalars["String"]["output"]>;
  updatePaymentStep?: Maybe<UpdatePaymentStep>;
};

export type LodgingRefundFormSections = {
  __typename?: "LodgingRefundFormSections";
  additionalRefundReason?: Maybe<AgentToolsChangeOrCancelReason>;
  /** @deprecated use additionalRefundReason */
  bookingAdditionalRefundEntry?: Maybe<BookingRefundEntry>;
  /** @deprecated use refundReason */
  bookingRefundEntry?: Maybe<BookingRefundEntry>;
  refundReason?: Maybe<AgentToolsChangeOrCancelReason>;
};

export type LodgingRefundInfoBanner = {
  __typename?: "LodgingRefundInfoBanner";
  icon: Icon;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type LodgingRefundMutationError = {
  __typename?: "LodgingRefundMutationError";
  error?: Maybe<AgentToolsErrorBanner>;
  isPaymentError?: Maybe<Scalars["Boolean"]["output"]>;
};

export type LodgingRefundView = LodgingRefundError | LodgingRefundForm;

export type LodgingReviewSection = {
  __typename?: "LodgingReviewSection";
  items?: Maybe<Array<EgdsTextListItem>>;
  itemsHeader?: Maybe<LodgingOfferSelectionHeader>;
  rating?: Maybe<LodgingCardRatingSectionV2>;
};

export type LodgingReviewSummary = {
  __typename?: "LodgingReviewSummary";
  checkInScore?: Maybe<SummaryUnit>;
  locationScore?: Maybe<SummaryUnit>;
  propertyId: Scalars["String"]["output"];
  roomsScore?: Maybe<Array<RoomSummary>>;
};

export type LodgingSaveItem = {
  __typename?: "LodgingSaveItem";
  analytics?: Maybe<ClientSideAnalytics>;
  initialChecked: Scalars["Boolean"]["output"];
  items: Array<LodgingSaveItemItem>;
  labels?: Maybe<LodgingSaveItemLabels>;
  messages?: Maybe<LodgingSaveItemMessages>;
};

export type LodgingSaveItemItem = {
  __typename?: "LodgingSaveItemItem";
  itemId: Scalars["String"]["output"];
};

export type LodgingSaveItemLabels = {
  __typename?: "LodgingSaveItemLabels";
  removeLabel?: Maybe<Scalars["String"]["output"]>;
  saveLabel?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingSaveItemMessages = {
  __typename?: "LodgingSaveItemMessages";
  removeMessage?: Maybe<Scalars["String"]["output"]>;
  saveMessage?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingScrollToButton = {
  __typename?: "LodgingScrollToButton";
  button: ScrollButton;
  targetRef: Scalars["String"]["output"];
};

export type LodgingScrollTrigger = {
  __typename?: "LodgingScrollTrigger";
  analytics?: Maybe<ClientSideAnalytics>;
  triggerAfterPosition?: Maybe<Scalars["Int"]["output"]>;
};

export type LodgingSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "LodgingSearchForm";
    actionURL: Scalars["String"]["output"];
    adaptExSuccessEvent?: Maybe<SearchFormAdaptExSuccessEvent>;
    button: EgdsButton;
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    hidePlayback?: Maybe<Scalars["Boolean"]["output"]>;
    lodgingSearchFormInputIds: LodgingSearchFormInputIds;
    playback?: Maybe<EgdsSearchPlayback>;
    popularFilters?: Maybe<SearchFormPopularFilters>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    softPackages?: Maybe<SoftPackages>;
    valuePropMessage?: Maybe<SearchFormMessaging>;
  };

export type LodgingSearchFormFixedInputs = EgdsSearchInputs & {
  __typename?: "LodgingSearchFormFixedInputs";
  shoppingSearchCriteria?: Maybe<ShoppingSearchCriteria>;
};

export type LodgingSearchFormInputIds = {
  __typename?: "LodgingSearchFormInputIds";
  dateRangeInputId: Scalars["String"]["output"];
  destinationInputId: Scalars["String"]["output"];
  shoppingSearchCriteriaIds?: Maybe<Array<Scalars["String"]["output"]>>;
  travelerSelectorId: Scalars["String"]["output"];
};

export type LodgingSearchRequest = {
  __typename?: "LodgingSearchRequest";
  filters?: Maybe<Array<LodgingFilterAttributes>>;
  search_id: Scalars["String"]["output"];
};

export type LodgingSearchResultHeaderMessage = EgdsPlainText | EgdsStandardLink | ShoppingLink;

export type LodgingSecondaryOfferSelection = {
  __typename?: "LodgingSecondaryOfferSelection";
  /** @deprecated Deprecated for TNL 41893 - ABS recommendations */
  recommendedSelection?: Maybe<Scalars["Boolean"]["output"]>;
  secondarySelection?: Maybe<LodgingOfferOption>;
  tertiarySelections?: Maybe<Array<LodgingOfferOption>>;
};

export type LodgingSelectorSection = {
  __typename?: "LodgingSelectorSection";
  inputs: Array<LodgingFormFieldSelector>;
  title?: Maybe<LodgingEnrichedMessage>;
};

export type LodgingShoppingAction = SelectPackageActionInput;

export type LodgingSimilarSelectionOptions = {
  __typename?: "LodgingSimilarSelectionOptions";
  options: Array<Maybe<LodgingLinkMessage>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingStandardBadge = {
  __typename?: "LodgingStandardBadge";
  impressionEventAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  standardBadge: EgdsStandardBadge;
};

export type LodgingStepIndicator = {
  __typename?: "LodgingStepIndicator";
  accessibilityLabel: Scalars["String"]["output"];
  steppers: Array<LodgingStepper>;
};

export type LodgingStepper = {
  __typename?: "LodgingStepper";
  state: LodgingStepperState;
  text: EgdsStylizedText;
  track?: Maybe<LodgingTrack>;
};

export type LodgingStepperState = "COMPLETE" | "CURRENT" | "INCOMPLETE";

export type LodgingStickyButton = LodgingButtonAction & {
  __typename?: "LodgingStickyButton";
  /** @deprecated deprecating to use LodgingButton with more properties, replace with PropertyLodgingStickyButton.button.adaptExSuccessActionTracking */
  adaptExSuccessActionTracking?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  /** @deprecated deprecating to use LodgingButton with more properties, replace with PropertyLodgingStickyButton.button.analytics */
  analytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated deprecating to use LodgingButton with more properties, replace with PropertyLodgingStickyButton.targetRef */
  targetRef?: Maybe<PropertySectionRef>;
  /** @deprecated deprecating to use LodgingButton with more properties, replace with PropertyLodgingStickyButton.button.text */
  text?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingSupplierRemittanceType = "GROSS" | "INVALID" | "NET";

export type LodgingSurveyTrigger = {
  __typename?: "LodgingSurveyTrigger";
  view: Scalars["String"]["output"];
};

export type LodgingTextInput = {
  __typename?: "LodgingTextInput";
  icon?: Maybe<Icon>;
  name?: Maybe<Scalars["String"]["output"]>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<LodgingInputType>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingToastMessage = {
  __typename?: "LodgingToastMessage";
  value: Scalars["String"]["output"];
};

export type LodgingToggle = UiToggle & {
  __typename?: "LodgingToggle";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics: ClientSideAnalytics;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics: ClientSideAnalytics;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
};

export type LodgingTrack = {
  __typename?: "LodgingTrack";
  position: LodgingTrackPosition;
  text?: Maybe<EgdsStylizedText>;
};

export type LodgingTrackPosition = "END" | "STANDARD" | "START";

export type LodgingTrackingText = {
  __typename?: "LodgingTrackingText";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingTripUpgrade = {
  __typename?: "LodgingTripUpgrade";
  callToAction?: Maybe<LodgingTripUpgradeCallToAction>;
  /** @deprecated Replaced by PrepareChangeCheckoutResponse.error */
  error?: Maybe<LodgingTripUpgradeError>;
  /** @deprecated Replaced by callToAction */
  form?: Maybe<LodgingForm>;
  heading?: Maybe<LodgingHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items?: Maybe<Array<LodgingTripUpgradeItem>>;
};

export type LodgingTripUpgradeAction = LodgingTripUpgradePrepareChangeCheckout | LodgingTripUpgradeRedirectAction;

export type LodgingTripUpgradeCallToAction = {
  __typename?: "LodgingTripUpgradeCallToAction";
  action?: Maybe<LodgingTripUpgradeAction>;
  button?: Maybe<EgdsButton>;
};

export type LodgingTripUpgradeError = {
  __typename?: "LodgingTripUpgradeError";
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  toast?: Maybe<EgdsToast>;
};

export type LodgingTripUpgradeItem = {
  __typename?: "LodgingTripUpgradeItem";
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingTripUpgradePrepareChangeCheckout = {
  __typename?: "LodgingTripUpgradePrepareChangeCheckout";
  lodgingInput: LodgingPrepareChangeCheckout;
  options: Array<PrepareChangeCheckoutOption>;
  tripId: Scalars["String"]["output"];
};

export type LodgingTripUpgradeRedirectAction = {
  __typename?: "LodgingTripUpgradeRedirectAction";
  url: Scalars["URL"]["output"];
};

export type LodgingTripUpgrades = {
  __typename?: "LodgingTripUpgrades";
  heading?: Maybe<Scalars["String"]["output"]>;
  upgrades?: Maybe<Array<LodgingTripUpgrade>>;
};

export type LodgingUnitCard = {
  __typename?: "LodgingUnitCard";
  /** @deprecated callout is deprecated, use trigger.value with theme:AGGRESSIVE */
  callOut?: Maybe<EgdsStylizedText>;
  /** @deprecated cardLink is deprecated, use unitId to open more-details-modal */
  cardLink?: Maybe<UiLinkAction>;
  header?: Maybe<EgdsHeading>;
  image?: Maybe<Image>;
  /** @deprecated link is deprecated, use trigger field to display pricing */
  link?: Maybe<EgdsInlineLink>;
  trigger?: Maybe<LodgingDialogTriggerMessage>;
  unitId?: Maybe<Scalars["String"]["output"]>;
};

export type LodgingUnitGroup = {
  __typename?: "LodgingUnitGroup";
  inputs: Array<LodgingGuestGroups>;
};

export type LodgingWaiverCalculator = {
  __typename?: "LodgingWaiverCalculator";
  amountInput: AgentToolsNumberInput;
  penaltyAmount: Scalars["Float"]["output"];
  percentageInput: AgentToolsNumberInput;
  waiverType: EgdsBasicRadioGroup;
};

export type LogoDimensionsInputForm = {
  __typename?: "LogoDimensionsInputForm";
  height?: Maybe<EgdsNumberInputField>;
  width?: Maybe<EgdsNumberInputField>;
};

export type LogoFormSection = {
  __typename?: "LogoFormSection";
  logoDimensions?: Maybe<LogoDimensionsInputForm>;
  logoSectionHeader?: Maybe<Scalars["String"]["output"]>;
  uploadBWLogoButton?: Maybe<UploadButton>;
  uploadLogoButton?: Maybe<UploadButton>;
};

export type LogoManagerMessage = {
  __typename?: "LogoManagerMessage";
  message: EgdsPlainText;
  subMessages: Array<EgdsPlainText>;
};

export type LogoManagerOption = {
  __typename?: "LogoManagerOption";
  detail: EgdsPlainText;
  id: Scalars["String"]["output"];
  option: EgdsPlainText;
  presignedUrl?: Maybe<Scalars["URL"]["output"]>;
};

export interface LogoManagerResponse {
  title: EgdsHeading;
}

export type LogoOption = {
  __typename?: "LogoOption";
  badge?: Maybe<EgdsStandardBadge>;
  deleteButton?: Maybe<UiTertiaryButton>;
  description?: Maybe<EgdsPlainText>;
  id: Scalars["String"]["output"];
  logo?: Maybe<Image>;
};

export type LogoutResponse = {
  __typename?: "LogoutResponse";
  logoutUrl: Scalars["String"]["output"];
  presenceResponse: UpdatePresenceResponse;
};

export type LogoutStatus = "FAILURE" | "SUCCESS";

export type Loyalty = {
  __typename?: "Loyalty";
  /** @deprecated Used with the legacy loyalty status only. Will be removed in the future */
  member: LoyaltyMember;
  /** @deprecated Used with the legacy loyalty status only. Will be removed in the future */
  memberStatus: Scalars["String"]["output"];
};

export type LoyaltyAccountBadge = {
  __typename?: "LoyaltyAccountBadge";
  creditCardIcon?: Maybe<UiGraphic>;
  separator?: Maybe<Scalars["String"]["output"]>;
  tier: EgdsStandardBadge;
};

export type LoyaltyAccountSummary = {
  __typename?: "LoyaltyAccountSummary";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  availableValueSection?: Maybe<LoyaltyRewardsValueSection>;
  badge?: Maybe<LoyaltyAccountBadge>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  theme?: Maybe<EgdsThemeProvider>;
  /** @deprecated tierBadge field is deprecated, use tier field instead., replace with tier */
  tierBadge?: Maybe<EgdsStandardBadge>;
  traveler?: Maybe<LoyaltyAccountTraveler>;
  viewRewardsLinkSection?: Maybe<LoyaltyViewRewardsLinkSection>;
};

export type LoyaltyAccountTraveler = {
  __typename?: "LoyaltyAccountTraveler";
  emailAddress: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type LoyaltyAmount = {
  __typename?: "LoyaltyAmount";
  amount?: Maybe<Scalars["String"]["output"]>;
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  formattedPrice?: Maybe<Scalars["String"]["output"]>;
  formattedWholePrice?: Maybe<Scalars["String"]["output"]>;
  roundedAmount?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyAnchoredCurrencyDescription = {
  __typename?: "LoyaltyAnchoredCurrencyDescription";
  balance: EgdsStylizedText;
  subtitle: EgdsStylizedText;
};

export type LoyaltyAnchoredCurrencySection = {
  __typename?: "LoyaltyAnchoredCurrencySection";
  anchoredBalance: LoyaltyAnchoredCurrencyDescription;
  posaBalance: LoyaltyAnchoredCurrencyDescription;
  transferIcon: Icon;
};

export type LoyaltyAnonymousOnboardingScreen = {
  __typename?: "LoyaltyAnonymousOnboardingScreen";
  dismissButton?: Maybe<LoyaltyInitialOnboardingButton>;
  dismissTertiaryButton?: Maybe<LoyaltyInitialOnboardingButton>;
  heroImage: Image;
  primaryButton: LoyaltyInitialOnboardingButton;
  subTitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type LoyaltyBlockingLaunchScreen = {
  __typename?: "LoyaltyBlockingLaunchScreen";
  screen: LoyaltyBlockingLaunchScreenResult;
};

export type LoyaltyBlockingLaunchScreenResult = LoyaltyHeritageBlockingScreen;

export type LoyaltyBlockingScreenButton = {
  __typename?: "LoyaltyBlockingScreenButton";
  action?: Maybe<LoyaltyBlockingScreenButtonAction>;
  button: LoyaltyBlockingScreenButtonElement;
};

export type LoyaltyBlockingScreenButtonAction = UiLinkAction;

export type LoyaltyBlockingScreenButtonElement = UiPrimaryButton;

export type LoyaltyBodyTypes = LoyaltyEarnCalculationWidgetContentWithCta | LoyaltyEarnCalculationWidgetSpannableText;

export type LoyaltyBookingContentSection = {
  __typename?: "LoyaltyBookingContentSection";
  content: Array<LoyaltyBookingSectionDetailsList>;
  /** @deprecated details field is deprecated, use content field instead (contains the OneKeyCash earn breakdown and pending date), replace with content */
  details: LoyaltyBookingSectionDetails;
  header: Scalars["String"]["output"];
};

export type LoyaltyBookingExpandoContent = {
  __typename?: "LoyaltyBookingExpandoContent";
  header?: Maybe<Scalars["String"]["output"]>;
  sections: Array<LoyaltyBookingContentSection>;
  /** @deprecated title field is deprecated, use header field instead (same but can be null)., replace with header */
  title: Scalars["String"]["output"];
};

export type LoyaltyBookingSectionDetails = EgdsPlainText | EgdsStandardLink;

export type LoyaltyBookingSectionDetailsList = EgdsPlainText | EgdsStandardLink | EgdsStylizedText;

export type LoyaltyClarityContent = LoyaltyTierProgressionBarElement | LoyaltyTierTripElementsDetails;

export type LoyaltyClarityDetails = {
  __typename?: "LoyaltyClarityDetails";
  contents: Array<LoyaltyClarityContent>;
};

export type LoyaltyConversionExpandoContent = {
  __typename?: "LoyaltyConversionExpandoContent";
  link?: Maybe<EgdsStandardLink>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type LoyaltyDialogContents =
  | EgdsHeading
  | EgdsLoyaltyBadge
  | EgdsSpannableList
  | EgdsStylizedText
  | LoyaltyDialogMarkGroup
  | LoyaltyDialogTextWithHeader;

export type LoyaltyDialogMarkGroup = {
  __typename?: "LoyaltyDialogMarkGroup";
  marks: Array<Mark>;
};

export type LoyaltyDialogSection = {
  __typename?: "LoyaltyDialogSection";
  contents: Array<LoyaltyDialogContents>;
  image?: Maybe<LoyaltyDialogSectionImage>;
  themeProvider?: Maybe<EgdsThemeProvider>;
};

export type LoyaltyDialogSectionImage = {
  __typename?: "LoyaltyDialogSectionImage";
  image: Image;
  position: LoyaltyDialogSectionImagePosition;
};

export type LoyaltyDialogSectionImagePosition = "BOTTOM" | "TOP";

export type LoyaltyDialogTextWithHeader = {
  __typename?: "LoyaltyDialogTextWithHeader";
  header: Scalars["String"]["output"];
  text: EgdsStylizedText;
};

export type LoyaltyDialogTrigger = EgdsStandardLink | LoyaltyMoreInfoBadgeTrigger;

export type LoyaltyDialogWithTrigger = {
  __typename?: "LoyaltyDialogWithTrigger";
  dialog: LoyaltyPopupDialog;
  trigger: LoyaltyDialogTrigger;
};

export type LoyaltyEarnCalculationDetailsCard = {
  __typename?: "LoyaltyEarnCalculationDetailsCard";
  backgroundTheme?: Maybe<Scalars["String"]["output"]>;
  body: LoyaltyBodyTypes;
  containerLink?: Maybe<EgdsInlineLink>;
  graphic?: Maybe<UiGraphic>;
  triggerIcon?: Maybe<Icon>;
  /** @deprecated userAccountIcon field is deprecated, use graphic field instead, replace with graphic */
  userAccountIcon?: Maybe<Icon>;
};

export type LoyaltyEarnCalculationWidgetCta = {
  __typename?: "LoyaltyEarnCalculationWidgetCTA";
  widgetButton: UiTertiaryButton;
  widgetContent: LoyaltyEarnCalculationWidgetCtaContent;
};

export type LoyaltyEarnCalculationWidgetCtaContent = {
  __typename?: "LoyaltyEarnCalculationWidgetCTAContent";
  list: EgdsBulletedList;
  title: EgdsHeading;
};

export type LoyaltyEarnCalculationWidgetContentWithCta = {
  __typename?: "LoyaltyEarnCalculationWidgetContentWithCTA";
  text: Array<LoyaltyEarnMessageSpannableTextTypes>;
  viewDetailsCTA?: Maybe<LoyaltyEarnCalculationWidgetCta>;
};

export type LoyaltyEarnCalculationWidgetSpannableText = {
  __typename?: "LoyaltyEarnCalculationWidgetSpannableText";
  contents: Array<LoyaltyEarnMessageSpannableTextTypes>;
  text: Scalars["String"]["output"];
};

export type LoyaltyEarnCalculationWidgetSubMenu = EgdsSubMenu & {
  __typename?: "LoyaltyEarnCalculationWidgetSubMenu";
  items: Array<EgdsMenuListItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyEarnMessageSpannableTextTypes = EgdsPlainText | EgdsStylizedText | EgdsTextMenu;

export type LoyaltyEducation = {
  __typename?: "LoyaltyEducation";
  action?: Maybe<LoyaltyEducationAction>;
  content: LoyaltyEducationContent;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyEducationAction = {
  __typename?: "LoyaltyEducationAction";
  href?: Maybe<Scalars["String"]["output"]>;
  rfrr?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyEducationContent = LoyaltyPointsEducation | LoyaltyProgramEducation;

export type LoyaltyEducationPerks = {
  __typename?: "LoyaltyEducationPerks";
  descriptions: Array<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyEducationSection = {
  __typename?: "LoyaltyEducationSection";
  description?: Maybe<Scalars["String"]["output"]>;
  perks?: Maybe<LoyaltyEducationPerks>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyExpandoCardItem = {
  __typename?: "LoyaltyExpandoCardItem";
  collapseAnalytics?: Maybe<ClickstreamAnalyticsData>;
  expandAnalytics?: Maybe<ClickstreamAnalyticsData>;
  expandoCard: EgdsExpandoCard;
  icon: Icon;
  overview?: Maybe<LoyaltyRewardStatusSection>;
  /** @deprecated The non-nullable status has been replaced with a nullable one to allow omitting it on the card, replace with overview */
  status: LoyaltyRewardStatusSection;
};

export type LoyaltyExperienceStrategy = "HIDE_TRAVELER_INFO_AND_REWARDS_LINK" | "SHOW_TRAVELER_INFO_AND_REWARDS_LINK";

export type LoyaltyGenericExpandoContent = {
  __typename?: "LoyaltyGenericExpandoContent";
  /** @deprecated content field is deprecated, use contents field instead, replace with contents */
  content: Scalars["String"]["output"];
  contents: Array<Scalars["String"]["output"]>;
  link?: Maybe<EgdsStandardLink>;
  title: Scalars["String"]["output"];
};

export type LoyaltyHeritageBlockingScreen = {
  __typename?: "LoyaltyHeritageBlockingScreen";
  brandLogo: UiGraphic;
  impressionEvent: ClientSideImpressionEventAnalytics;
  primaryButton: LoyaltyBlockingScreenButton;
  primaryLink: EgdsStandardLink;
  subtitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type LoyaltyIconAndDescription = {
  __typename?: "LoyaltyIconAndDescription";
  analytics?: Maybe<ClickstreamAnalyticsData>;
  iconInfoContentSection: LoyaltyIconInfoContentSection;
  infoIcon: Icon;
};

export type LoyaltyIconInfoContentSection = {
  __typename?: "LoyaltyIconInfoContentSection";
  /** @deprecated Replaced by closeButton */
  actionText: Scalars["String"]["output"];
  analytics?: Maybe<ClickstreamAnalyticsData>;
  closeButton?: Maybe<EgdsButton>;
  contents?: Maybe<Array<LoyaltyIconInfoOverlaySubContent>>;
  /** @deprecated Deprecated. Use contents instead */
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  impressionTracking?: Maybe<ClientSideAnalytics>;
};

export type LoyaltyIconInfoOverlaySubContent = LoyaltyAnchoredCurrencySection | LoyaltyStylizedText;

export type LoyaltyInitialOnboardingButton = {
  __typename?: "LoyaltyInitialOnboardingButton";
  action?: Maybe<LoyaltyInitialOnboardingButtonAction>;
  button: LoyaltyInitialOnboardingButtonElement;
};

export type LoyaltyInitialOnboardingButtonAction = EgdsDismissAction | UiLinkAction;

export type LoyaltyInitialOnboardingButtonElement = EgdsOverlayButton | UiPrimaryButton | UiTertiaryButton;

export type LoyaltyInitialOnboardingScreen = {
  __typename?: "LoyaltyInitialOnboardingScreen";
  screen: LoyaltyInitialOnboardingScreenResult;
};

export type LoyaltyInitialOnboardingScreenResult = LoyaltyAnonymousOnboardingScreen;

export type LoyaltyInlineLink = {
  __typename?: "LoyaltyInlineLink";
  analytics?: Maybe<ClickstreamAnalyticsData>;
  link: EgdsInlineLink;
};

export type LoyaltyLegacyOnboardingButton = {
  __typename?: "LoyaltyLegacyOnboardingButton";
  action: UiLinkAction;
  button: EgdsButton;
};

export type LoyaltyLegacyOnboardingGettingStarted = {
  __typename?: "LoyaltyLegacyOnboardingGettingStarted";
  headerBrandLogo?: Maybe<Image>;
  headerBrandLogoDark?: Maybe<Image>;
  heroImage?: Maybe<Image>;
  image?: Maybe<Image>;
  primaryButton: LoyaltyLegacyOnboardingButton;
  subTitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type LoyaltyLegacyOnboardingScreen = {
  __typename?: "LoyaltyLegacyOnboardingScreen";
  screen: LoyaltyLegacyOnboardingScreenResult;
};

export type LoyaltyLegacyOnboardingScreenResult = LoyaltyLegacyOnboardingGettingStarted;

export type LoyaltyMediaItem = MediaItem & {
  __typename?: "LoyaltyMediaItem";
  media: Media;
};

export type LoyaltyMember = {
  __typename?: "LoyaltyMember";
  /** @deprecated Used with the legacy loyalty status only. Will be removed in the future */
  firstName: Scalars["String"]["output"];
};

export type LoyaltyMembershipAnchorCurrencyLink = {
  __typename?: "LoyaltyMembershipAnchorCurrencyLink";
  dialog: LoyaltyIconInfoContentSection;
  link: EgdsInlineLink;
};

export type LoyaltyMembershipInfo = {
  __typename?: "LoyaltyMembershipInfo";
  bookingCurrency?: Maybe<Scalars["String"]["output"]>;
  currentLoyaltyTierCredits?: Maybe<TierCreditsDetails>;
  currentTierCredits?: Maybe<CurrentTierCredits>;
  currentTierCreditsEndDate?: Maybe<Scalars["String"]["output"]>;
  enrolledInRewardsDate?: Maybe<Scalars["String"]["output"]>;
  isAllowedToShopWithPoints?: Maybe<Scalars["Boolean"]["output"]>;
  isUserOneKey?: Maybe<Scalars["Boolean"]["output"]>;
  loyaltyAccountNumber?: Maybe<Scalars["String"]["output"]>;
  loyaltyAmountAvailable?: Maybe<Scalars["Float"]["output"]>;
  loyaltyMembershipActive: Scalars["Boolean"]["output"];
  loyaltyMembershipName?: Maybe<Scalars["String"]["output"]>;
  loyaltyMembershipNumber?: Maybe<Scalars["String"]["output"]>;
  loyaltyMonetaryValue?: Maybe<LoyaltyAmount>;
  loyaltyPointsAvailable?: Maybe<Scalars["Float"]["output"]>;
  loyaltyPointsPending?: Maybe<Scalars["Float"]["output"]>;
  membershipTierName?: Maybe<Scalars["String"]["output"]>;
  reqUpgradeCredits?: Maybe<TierMaintenanceAndProgressCriteria>;
  tierProgressionCredit?: Maybe<TierCreditsDetails>;
};

export type LoyaltyMessagingCard = {
  __typename?: "LoyaltyMessagingCard";
  card?: Maybe<EgdsStandardMessagingCard>;
};

export type LoyaltyMoreInfoBadgeTrigger = {
  __typename?: "LoyaltyMoreInfoBadgeTrigger";
  badge: EgdsLoyaltyBadge;
  badgeShimmer?: Maybe<Scalars["Boolean"]["output"]>;
  icon: Icon;
};

export type LoyaltyNoRecentActivity = {
  __typename?: "LoyaltyNoRecentActivity";
  description?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  header: Scalars["String"]["output"];
};

export type LoyaltyPaymentAttribute = {
  __typename?: "LoyaltyPaymentAttribute";
  balance?: Maybe<PaymentRewardValue>;
  redemptionAmount?: Maybe<PaymentRewardValue>;
};

export type LoyaltyPaymentFop = IPaymentFop & {
  __typename?: "LoyaltyPaymentFop";
  id: Scalars["String"]["output"];
  paymentAmount?: Maybe<PaymentPriceDetails>;
  paymentMethod: PaymentMethodType;
  paymentMethodAttribute?: Maybe<PaymentMethodAttribute>;
  paymentSubMethods?: Maybe<Array<Scalars["String"]["output"]>>;
  rank?: Maybe<Scalars["Int"]["output"]>;
  splittable?: Maybe<Scalars["Boolean"]["output"]>;
  state: PaymentState;
};

export type LoyaltyPointsEducation = {
  __typename?: "LoyaltyPointsEducation";
  description?: Maybe<Scalars["String"]["output"]>;
  heroImage?: Maybe<Image>;
  programFeatures: Array<LoyaltyEducationSection>;
};

export type LoyaltyPointsOption = {
  __typename?: "LoyaltyPointsOption";
  basePointsAmount?: Maybe<FormattedNumber>;
  formattedBasePoints?: Maybe<Scalars["String"]["output"]>;
  formattedPoints?: Maybe<Scalars["String"]["output"]>;
  formattedStrikePoints?: Maybe<Scalars["String"]["output"]>;
  formattedTaxPoints?: Maybe<Scalars["String"]["output"]>;
  leadingCaption: Scalars["String"]["output"];
  pointsAmount?: Maybe<FormattedNumber>;
  pointsFirst: Scalars["Boolean"]["output"];
  pointsUnit?: Maybe<Scalars["String"]["output"]>;
  strikePointsAmount?: Maybe<FormattedNumber>;
  taxPointsAmount?: Maybe<FormattedNumber>;
};

export type LoyaltyPopoverDialog = {
  __typename?: "LoyaltyPopoverDialog";
  closeAnalytics?: Maybe<ClickstreamAnalyticsData>;
  closeButton?: Maybe<EgdsButton>;
  contents?: Maybe<Array<LoyaltySheetContent>>;
  heading: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClickstreamAnalyticsData>;
};

export type LoyaltyPopupDialog = {
  __typename?: "LoyaltyPopupDialog";
  additionalLink?: Maybe<UiTertiaryButton>;
  closeButton?: Maybe<UiPrimaryButton>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  sections: Array<LoyaltyDialogSection>;
};

export type LoyaltyPrice = {
  __typename?: "LoyaltyPrice";
  amount?: Maybe<FormattedNumber>;
  promotionValue?: Maybe<Scalars["String"]["output"]>;
  totalStrikeOutPoints?: Maybe<FormattedNumber>;
  unit?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyProgramEducation = {
  __typename?: "LoyaltyProgramEducation";
  joinProgramText?: Maybe<Scalars["String"]["output"]>;
  perks: LoyaltyEducationPerks;
};

export type LoyaltyProgramTitle = {
  __typename?: "LoyaltyProgramTitle";
  infoIconAndDescription?: Maybe<LoyaltyIconAndDescription>;
  superScript?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type LoyaltyRewardStatusSection = {
  __typename?: "LoyaltyRewardStatusSection";
  amountChanged: EgdsStylizedText;
  description?: Maybe<EgdsStylizedText>;
  rewardsProgramGraphic?: Maybe<UiGraphic>;
  /** @deprecated Deprecated as this can be null now., replace with description */
  status: EgdsStylizedText;
};

export type LoyaltyRewardsActivity = {
  __typename?: "LoyaltyRewardsActivity";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  content: LoyaltyRewardsActivityHistoryContent;
  rewardFilter: EgdsSelect;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyRewardsActivityExpandoContent =
  | LoyaltyBookingExpandoContent
  | LoyaltyConversionExpandoContent
  | LoyaltyGenericExpandoContent;

export type LoyaltyRewardsActivityHistoryContent = LoyaltyNoRecentActivity | LoyaltyRewardsActivityItems;

export type LoyaltyRewardsActivityHistoryRecord = {
  __typename?: "LoyaltyRewardsActivityHistoryRecord";
  content: LoyaltyRewardsActivityExpandoContent;
  expando: LoyaltyExpandoCardItem;
};

export type LoyaltyRewardsActivityItems = {
  __typename?: "LoyaltyRewardsActivityItems";
  initialVisibleItems?: Maybe<Scalars["Int"]["output"]>;
  records: Array<LoyaltyRewardsActivityHistoryRecord>;
  showLessAnalytics?: Maybe<ClickstreamAnalyticsData>;
  showMoreAnalytics?: Maybe<ClickstreamAnalyticsData>;
  showMorePeek?: Maybe<EgdsExpandoPeek>;
};

export type LoyaltyRewardsAdditionalInformation = {
  __typename?: "LoyaltyRewardsAdditionalInformation";
  card: EgdsStandardMessagingCard;
  onClick: UiLinkAction;
  onClickAnalytics?: Maybe<ClickstreamAnalyticsData>;
};

export type LoyaltyRewardsAdditionalInformations = {
  __typename?: "LoyaltyRewardsAdditionalInformations";
  cards: Array<LoyaltyRewardsAdditionalInformation>;
  subtitles?: Maybe<Array<EgdsStylizedText>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LoyaltyRewardsHeading = {
  __typename?: "LoyaltyRewardsHeading";
  backButton?: Maybe<EgdsButton>;
  text: Scalars["String"]["output"];
};

export type LoyaltyRewardsResults = {
  __typename?: "LoyaltyRewardsResults";
  additionalInformation: Array<LoyaltyRewardsAdditionalInformations>;
  heading?: Maybe<LoyaltyRewardsHeading>;
};

export type LoyaltyRewardsValueSection = {
  __typename?: "LoyaltyRewardsValueSection";
  rewardsAmount: Scalars["String"]["output"];
  supportingMessages?: Maybe<Array<EgdsText>>;
  title: LoyaltyProgramTitle;
};

export type LoyaltySheetContent = EgdsStylizedText;

export type LoyaltySpannableText = {
  __typename?: "LoyaltySpannableText";
  accessibilityLabel: Scalars["String"]["output"];
  items: Array<LoyaltySpannableTextItemList>;
};

export type LoyaltySpannableTextItemList = EgdsStylizedText | LoyaltyInlineLink;

export type LoyaltyStandardLink = {
  __typename?: "LoyaltyStandardLink";
  analytics?: Maybe<ClickstreamAnalyticsData>;
  link: EgdsStandardLink;
};

export type LoyaltyStylizedText = {
  __typename?: "LoyaltyStylizedText";
  stylizedText: EgdsStylizedText;
};

export type LoyaltyTierBenefitsDetails = {
  __typename?: "LoyaltyTierBenefitsDetails";
  collapseAnalytics?: Maybe<ClickstreamAnalyticsData>;
  expandAnalytics?: Maybe<ClickstreamAnalyticsData>;
  expando?: Maybe<EgdsExpandoCard>;
  sections?: Maybe<Array<LoyaltyTierBenefitsSection>>;
};

export type LoyaltyTierBenefitsSection = {
  __typename?: "LoyaltyTierBenefitsSection";
  contents: Array<LoyaltyTierBenefitsSectionContent>;
};

export type LoyaltyTierBenefitsSectionContent = EgdsLoyaltyBadge | EgdsStylizedText | LoyaltyStandardLink;

export type LoyaltyTierProgression = {
  __typename?: "LoyaltyTierProgression";
  sections: Array<LoyaltyTierProgressionSection>;
};

export type LoyaltyTierProgressionBarElement = {
  __typename?: "LoyaltyTierProgressionBarElement";
  description?: Maybe<LoyaltySpannableText>;
  heading?: Maybe<Scalars["String"]["output"]>;
  popover?: Maybe<PopoverDetails>;
  radialBar?: Maybe<RadialProgressionBar>;
  /** @deprecated Subheading couldn't handle links in the description, replace with description */
  subheading?: Maybe<EgdsStylizedText>;
};

export type LoyaltyTierProgressionDetails = {
  __typename?: "LoyaltyTierProgressionDetails";
  description?: Maybe<Scalars["String"]["output"]>;
  tierProgressionDetails: EgdsProgressBar;
  /** @deprecated Deprecated as tierProgressionDetails.title is no longer null, replace with tierProgressionDetails.title */
  title: Scalars["String"]["output"];
};

export type LoyaltyTierProgressionSection = {
  __typename?: "LoyaltyTierProgressionSection";
  content: LoyaltyTierProgressionSectionContent;
};

export type LoyaltyTierProgressionSectionContent =
  | EgdsBasicSectionHeading
  | EgdsStandardLink
  | LoyaltyClarityDetails
  | LoyaltyTierBenefitsDetails
  | LoyaltyTierProgressionDetails
  | LoyaltyTierSectionHeading
  | StandardLink;

export type LoyaltyTierSectionHeading = {
  __typename?: "LoyaltyTierSectionHeading";
  heading: EgdsHeading;
  /** @deprecated use the tierSectionSubheading */
  link?: Maybe<EgdsInlineLink>;
  /** @deprecated use the tierSectionSubheading */
  subheading?: Maybe<Scalars["String"]["output"]>;
  tierSectionSubheading?: Maybe<EgdsSpannableText>;
};

export type LoyaltyTierTripElementsDetails = {
  __typename?: "LoyaltyTierTripElementsDetails";
  closeAnalytics?: Maybe<ClickstreamAnalyticsData>;
  details?: Maybe<RewardsTripElementsSheet>;
  modalButton?: Maybe<UiTertiaryButton>;
  openAnalytics?: Maybe<ClickstreamAnalyticsData>;
};

export type LoyaltyTripAttach = {
  __typename?: "LoyaltyTripAttach";
  tripAttachSection?: Maybe<TripAttachSection>;
};

export type LoyaltyViewRewardsLinkSection = {
  __typename?: "LoyaltyViewRewardsLinkSection";
  containerLink?: Maybe<EgdsInlineLink>;
  containerLinkAnalytics?: Maybe<ClickstreamAnalyticsData>;
  rewardsIcon?: Maybe<Icon>;
  rewardsText: Scalars["String"]["output"];
  triggerIcon: Icon;
};

export type MfaVerificationMethod = "CALL" | "SMS";

export type Moderation_Rule = "ADVISE" | "BLOCK";

export type Moderation_Type = "CHARACTER" | "WORD";

export type ModItem = {
  __typename?: "MODItem";
  modAvailabilityText?: Maybe<Scalars["String"]["output"]>;
  modSignInText?: Maybe<Scalars["String"]["output"]>;
  modTagText?: Maybe<Scalars["String"]["output"]>;
  signInUrl?: Maybe<HttpUri>;
};

export type ModItemSignInUrlArgs = {
  returnPath?: InputMaybe<Scalars["String"]["input"]>;
};

export type MreContents = {
  __typename?: "MREContents";
  body?: Maybe<EgdsStylizedText>;
  button?: Maybe<EgdsButton>;
  heading?: Maybe<EgdsStylizedText>;
  image?: Maybe<Image>;
  inLineLink?: Maybe<EgdsStandardLink>;
};

export type MailToUri = Uri & {
  __typename?: "MailToURI";
  emailAddress: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ManagedBannerContentResponse = {
  __typename?: "ManagedBannerContentResponse";
  additionalInfo?: Maybe<BannerAdditionalInfo>;
  bannerTheme?: Maybe<EgdsCardBackgroundTheme>;
  callToActions?: Maybe<BannerCallToActions>;
  contentTopic: Scalars["String"]["output"];
  description?: Maybe<BannerDescription>;
  graphic?: Maybe<UiGraphic>;
  header?: Maybe<EgdsHeading>;
  mediaAssets?: Maybe<BannerMediaComponents>;
  revealActions?: Maybe<BannerAnalytics>;
  subHeader?: Maybe<EgdsHeading>;
};

export type ManagementResponse = {
  __typename?: "ManagementResponse";
  date?: Maybe<Scalars["String"]["output"]>;
  displayLocale?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<LodgingHeader>;
  id?: Maybe<Scalars["String"]["output"]>;
  response?: Maybe<Scalars["String"]["output"]>;
  userNickname?: Maybe<Scalars["String"]["output"]>;
};

export type MandatoryFees = {
  __typename?: "MandatoryFees";
  dailyFees: Money;
  totalFees: Money;
  totalPriceWithFees?: Maybe<Money>;
};

export type ManualPriceCheckSection = {
  __typename?: "ManualPriceCheckSection";
  dialog: EgdsDialog;
  loader?: Maybe<PriceMatchPromiseLoader>;
  mainCopy: Array<EgdsComposableTextElement>;
  priceCheckButton?: Maybe<UiPrimaryButton>;
  secondaryCopy?: Maybe<Array<EgdsComposableTextElement>>;
};

export type MapCard = EgdsImageCard | LodgingCard;

export interface MapConfiguration {
  /** @deprecated Use accessToken from EGDSDynamicMapConfig */
  api: Scalars["String"]["output"];
  /** @deprecated Use the field from EGDSDynamicMapConfig */
  externalConfigEndpoint?: Maybe<HttpUri>;
  /** @deprecated Map provider api are provider by uitk-react-map or eg-maps in order to ensure that the version has been tested so dont use this field */
  url: Scalars["URL"]["output"];
}

export type MapFeatureEvent = EgdsMapMarker & {
  __typename?: "MapFeatureEvent";
  mapFeature: EgdsMapFeature;
  mapPinSelectedEvent: Scalars["AnalyticsPayload"]["output"];
  markerIconType?: Maybe<Array<Scalars["String"]["output"]>>;
  markerPosition: Coordinates;
  markerStatus?: Maybe<EgdsMapMarkerStatus>;
  markerType?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type MapIconType =
  | "AIRPORT"
  | "CIRCLE"
  | "GROCERY"
  | "HOTEL"
  | "LANDMARK"
  | "METRO"
  | "RESTAURANT"
  | "SOLD_OUT"
  | "TRAIN"
  | "VACATION_RENTAL";

export type MapMarker = {
  __typename?: "MapMarker";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  icon: MapIconType;
  label: Scalars["String"]["output"];
  latLong: Coordinates;
  type?: Maybe<MarkerType>;
};

export type MapToolbar = {
  __typename?: "MapToolbar";
  actions: Array<MapToolbarActionsType>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type MapToolbarActionsType = "FILTERS";

export type Mark = {
  __typename?: "Mark";
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  orientation?: Maybe<Orientation>;
  size?: Maybe<Scalars["String"]["output"]>;
  token: Scalars["String"]["output"];
  url?: Maybe<HttpUri>;
};

export type MarkItemPosition = "LEFT" | "RIGHT";

export type MarkWrapper = {
  __typename?: "MarkWrapper";
  mark: Mark;
};

export type MarkerType = "HOTEL" | "POI";

export type MarketGoal = {
  __typename?: "MarketGoal";
  goalHeader: Scalars["String"]["output"];
  goalSelect: EgdsSelect;
  goalSubText: Scalars["String"]["output"];
  goalTypes: Array<MarketGoalType>;
};

export type MarketGoalType = {
  __typename?: "MarketGoalType";
  description: EgdsSpannableText;
  name: Scalars["String"]["output"];
  pluralName: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type MarketingFeeDetails = {
  __typename?: "MarketingFeeDetails";
  egdsMark?: Maybe<Mark>;
  formattedFee?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<LodgingMark>;
  /** @deprecated Obtain through marketingFeeMessageDialog., replace with marketingFeeMessageDialog */
  marketingFeeMessage: LodgingPlainMessage;
  marketingFeeMessageDialog?: Maybe<LodgingBasicContentActionDialog>;
  /** @deprecated Obtain through the tierMessage field instead, replace with tierMessage */
  tierIcon: Scalars["String"]["output"];
  tierMessage: LodgingEnrichedMessage;
  /** @deprecated Obtain through the tierMessage field instead, replace with tierMessage */
  tierName: Scalars["String"]["output"];
};

export type MarketingFeedCardStatus = "ACTIVE" | "DELETED" | "INACTIVE";

export interface MarketingInfoInput {
  googleClickIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  metaDistributionPartnersChannelIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  metaDistributionPartnersDetails?: InputMaybe<Scalars["String"]["input"]>;
}

export type MarketingSection = {
  __typename?: "MarketingSection";
  /** @deprecated This is deprecated. Please use feeDialog instead */
  feeDetails: MarketingFeeDetails;
  feeDialog: LodgingPlainDialog;
  paymentDetails?: Maybe<LodgingEnrichedMessage>;
  title?: Maybe<LodgingHeader>;
};

export type MarkupText = {
  __typename?: "MarkupText";
  markupType: MarkupTextType;
  text: Scalars["String"]["output"];
};

export type MarkupTextType = "HTML";

export type MarqueeCardType = "CENTER_STAGE" | "COLLECTION" | "FULL_BLEED";

export type MaxRoomsFailure = FailureReason & {
  __typename?: "MaxRoomsFailure";
  description: Scalars["String"]["output"];
  groupLinkMessage: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type MealPlanFilterAmenity = "ALL_INCLUSIVE" | "FREE_BREAKFAST" | "FULL_BOARD" | "HALF_BOARD";

export type Media = EgdsPhotosphereImage | Image | Video;

export type MediaAsset = {
  __typename?: "MediaAsset";
  /** @deprecated This field is deprecated as it is a duplicate of what is in the mediaType */
  altText?: Maybe<Scalars["String"]["output"]>;
  caption?: Maybe<Scalars["String"]["output"]>;
  credit?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  media: EmMediaType;
  mediaAssetType?: Maybe<Scalars["String"]["output"]>;
  mediaLibraryId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field is deprecated as it is a duplicate please use media instead */
  mediaType: Media;
  targetURL?: Maybe<Scalars["String"]["output"]>;
  usage?: Maybe<Scalars["String"]["output"]>;
};

export type MediaContentData = {
  __typename?: "MediaContentData";
  fileName?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<MediaContentType>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type MediaContentType = "IMAGE" | "VIDEO";

export interface MediaItem {
  media: Media;
}

export type MediaTile = ActivityMediaItem | EgdsCarousel;

export type MediaType = "IMAGE" | "VIDEO";

export interface MediaUploadInput {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  hash: Scalars["String"]["input"];
}

export type MemberPricing = {
  __typename?: "MemberPricing";
  discountPercentage?: Maybe<Scalars["Int"]["output"]>;
  leadInMemberOffer?: Maybe<Scalars["Boolean"]["output"]>;
  memberOfferAvailable?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface MemberPricingInput {
  discountPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  leadInMemberOffer?: InputMaybe<Scalars["Boolean"]["input"]>;
  memberOfferAvailable?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type MemberProfile = {
  __typename?: "MemberProfile";
  action?: Maybe<UiLinkAction>;
  headerText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  memberTheme: Scalars["String"]["output"];
  subText?: Maybe<Scalars["String"]["output"]>;
};

export type MemberSignInDialog = {
  __typename?: "MemberSignInDialog";
  actionDialog: EgdsActionDialog;
  dialogContent?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  triggerMessage?: Maybe<DialogTriggerMessage>;
};

export type MemberWallet = {
  __typename?: "MemberWallet";
  content: MemberWalletContent;
  details: MemberWalletDetails;
  info: MemberWalletInfo;
  oneKeyUserEnabled: Scalars["Boolean"]["output"];
};

export type MemberWalletBadge = UiAction & {
  __typename?: "MemberWalletBadge";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  text: Scalars["String"]["output"];
  theme: MemberWalletBadgeTheme;
};

export type MemberWalletBadgeTheme = "LOYALTY_HIGH_TIER" | "LOYALTY_LOW_TIER" | "LOYALTY_MIDDLE_TIER";

export type MemberWalletCallToActionLink = {
  __typename?: "MemberWalletCallToActionLink";
  action: UiLinkAction;
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type MemberWalletContent = {
  __typename?: "MemberWalletContent";
  items: Array<MemberWalletContentItems>;
};

export type MemberWalletContentItems = MemberWalletCallToActionLink;

export type MemberWalletCoupons = {
  __typename?: "MemberWalletCoupons";
  label: Scalars["String"]["output"];
  linkAction?: Maybe<UiLinkAction>;
  text: Scalars["String"]["output"];
};

export type MemberWalletDetails = {
  __typename?: "MemberWalletDetails";
  items: Array<MemberWalletDetailsItems>;
  moreInfo?: Maybe<MemberWalletMoreInfo>;
};

export type MemberWalletDetailsItem = {
  __typename?: "MemberWalletDetailsItem";
  action?: Maybe<UiAction>;
  label: Scalars["String"]["output"];
  mark?: Maybe<Mark>;
  markPosition: MarkItemPosition;
  text: Scalars["String"]["output"];
};

export type MemberWalletDetailsItems = MemberWalletCoupons | MemberWalletDetailsItem | MemberWalletPoints;

export type MemberWalletHeading = {
  __typename?: "MemberWalletHeading";
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type MemberWalletInfo = {
  __typename?: "MemberWalletInfo";
  items: Array<MemberWalletInfoItems>;
};

export type MemberWalletInfoItems = MemberWalletBadge | MemberWalletHeading;

export type MemberWalletMoreInfo = {
  __typename?: "MemberWalletMoreInfo";
  moreInfoLinkAction?: Maybe<UiLinkAction>;
  moreInfoLinkText?: Maybe<Scalars["String"]["output"]>;
  moreInfoText: Scalars["String"]["output"];
};

export type MemberWalletPoints = {
  __typename?: "MemberWalletPoints";
  action?: Maybe<MemberWalletUiAction>;
  information: PointsInformation;
  label: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type MemberWalletUiAction = UiAction & {
  __typename?: "MemberWalletUIAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type MerchCoupons = {
  __typename?: "MerchCoupons";
  coupons: Array<CouponDetail>;
};

export type MerchandisingDetailsCardPath = "CHECKOUT" | "CONFIRMATION";

export type Message = {
  __typename?: "Message";
  alignment: CommunicationCenterMessageAlignment;
  attachments?: Maybe<Array<CommunicationCenterButton>>;
  bookingUpdates?: Maybe<Array<BookingUpdate>>;
  callToActionButtons?: Maybe<Array<CommunicationCenterButton>>;
  description?: Maybe<MessageDescription>;
  icon?: Maybe<Icon>;
  senderName?: Maybe<Scalars["String"]["output"]>;
  timeStamp: Scalars["String"]["output"];
  title?: Maybe<EgdsStylizedText>;
};

export interface MessageAttachmentsInput {
  attachmentIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type MessageContent = {
  __typename?: "MessageContent";
  actions?: Maybe<Array<MessageLink>>;
  analyticsKeyword: Scalars["String"]["output"];
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  theme: MessageContentTheme;
};

export type MessageContentTheme = "CRITICAL" | "ERROR";

export type MessageDescription = {
  __typename?: "MessageDescription";
  text?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<EgdsStylizedText>;
};

export type MessageInput = {
  __typename?: "MessageInput";
  input: EgdsTextAreaInputField;
  sendMessageButton: CommunicationCenterButton;
};

export type MessageLink = {
  __typename?: "MessageLink";
  target?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  uri: Scalars["URL"]["output"];
};

export type MessageModuleData = {
  __typename?: "MessageModuleData";
  checkoutMessages: Array<CheckoutMessage>;
  checkoutSubscriptions: Array<CheckoutSubscription>;
};

export type MessageModuleDetails = {
  __typename?: "MessageModuleDetails";
  messageModuleData: MessageModuleData;
};

export type MessageResponse = {
  __typename?: "MessageResponse";
  messageRC: Scalars["String"]["output"];
};

export type MessageResult = AccessibilityField & {
  __typename?: "MessageResult";
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.action.accessibility instead. */
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links instead. */
  action?: Maybe<MessagingResultAction>;
  /** @deprecated Do not use. Use PropertyListingsIconTextExpando.expandedContent instead. */
  content: Array<EgdsIconText>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.graphic instead. */
  featuredImage?: Maybe<Image>;
  footerText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. */
  index?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.message instead. */
  subtitle?: Maybe<MessagingResultTitle>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.heading instead. */
  title?: Maybe<MessagingResultTitle>;
  /** @deprecated This will be removed in the future. */
  type?: Maybe<MessageType>;
};

export type MessageSection = {
  __typename?: "MessageSection";
  primaryInfo: PrimaryInfo;
  secondaryInfo: SecondaryInfo;
};

export type MessageType =
  | "ADDON"
  | "BRANDED_THEME_ACCENT"
  | "BRANDED_THEME_DARK"
  | "BRANDED_THEME_LIGHT"
  | "COMPRESSION"
  | "COUPON"
  | "DOUBLE_POINTS"
  | "FREE_CANCELLATION"
  | "FREE_LX"
  | "HOTRATE"
  | "LEGAL"
  | "LOGIN"
  | "LOYALTY"
  | "NO_CC"
  | "OVERFILTERED"
  | "PARTNER_LINKOFF"
  | "VACATION_RENTALS";

export type MessagingAction = {
  __typename?: "MessagingAction";
  /** @deprecated Do not use. Use EGDSStandardLink.action instead. */
  actionDetails?: Maybe<ActionDetails>;
  /** @deprecated Do not use. Use EGDSStandardLink.action.analytics instead. */
  analytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use the linkUrl field instead, replace with linkUrl */
  href: Scalars["String"]["output"];
  /** @deprecated Do not use. Use EGDSStandardLink.icon instead. */
  icon?: Maybe<Icon>;
  /** @deprecated Do not use. Use EGDSStandardLink.action.resource instead. */
  linkUrl: Scalars["String"]["output"];
  /** @deprecated Use analytics */
  referrerId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use the referrerId field instead, replace with referrerId */
  rfrr?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. Use EGDSStandardLink.text instead. */
  text?: Maybe<Scalars["String"]["output"]>;
};

export type MessagingCardTheme = "HIGHLIGHT" | "PRIMARY" | "SECONDARY";

export type MessagingDetails = {
  __typename?: "MessagingDetails";
  elements?: Maybe<Array<CustomUiComposableElement>>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type MessagingResultAction = {
  __typename?: "MessagingResultAction";
  primary?: Maybe<MessagingAction>;
  secondary?: Maybe<MessagingAction>;
};

export type MessagingResultTitle = {
  __typename?: "MessagingResultTitle";
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.links.action.accessibility instead. */
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. Use Mark in EGDSStandardMessagingCard.graphic instead. */
  egdsMark?: Maybe<Mark>;
  /** @deprecated Do not use. Use Icon in EGDSStandardMessagingCard.graphic instead. */
  icon?: Maybe<Icon>;
  /** @deprecated Do not use. Use Illustration in EGDSStandardMessagingCard.graphic instead. */
  illustration?: Maybe<LodgingIllustration>;
  /** @deprecated Use egdsMark */
  mark?: Maybe<LodgingMark>;
  /** @deprecated Do not use. Use EGDSStandardMessagingCard.heading or EGDSStandardMessagingCard.message instead. */
  text?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Do not use. */
  theme?: Maybe<Theme>;
};

export interface MetaDataRequestInput {
  category: Scalars["String"]["input"];
  filename?: InputMaybe<Scalars["String"]["input"]>;
}

export type MetadataResponse = {
  __typename?: "MetadataResponse";
  values: Array<File>;
};

export type MetricFormat = {
  __typename?: "MetricFormat";
  amount?: Maybe<Scalars["Float"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
};

export type MigrateTestingDummy = {
  __typename?: "MigrateTestingDummy";
  testNum: Scalars["Int"]["output"];
  testString?: Maybe<Scalars["String"]["output"]>;
};

export type MigrationClientInfo = {
  __typename?: "MigrationClientInfo";
  name: Scalars["String"]["output"];
  version?: Maybe<Scalars["String"]["output"]>;
};

export type MigrationContextInput = {
  __typename?: "MigrationContextInput";
  clientInfo?: Maybe<MigrationClientInfo>;
  currency?: Maybe<Scalars["String"]["output"]>;
  debugContext?: Maybe<MigrationDebugContext>;
  deviceType?: Maybe<DeviceType>;
  eapid?: Maybe<Scalars["Int"]["output"]>;
  identity?: Maybe<MigrationIdentity>;
  locale?: Maybe<Scalars["String"]["output"]>;
  privacyTrackingState?: Maybe<PrivacyTrackingState>;
  siteId: Scalars["Int"]["output"];
  tpid?: Maybe<Scalars["Int"]["output"]>;
};

export type MigrationDebugContext = {
  __typename?: "MigrationDebugContext";
  abacusOverrides?: Maybe<Array<MigrationExposure>>;
};

export type MigrationExposure = {
  __typename?: "MigrationExposure";
  bucket?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["String"]["output"];
};

export type MigrationFlightsDetailCriteria = {
  __typename?: "MigrationFlightsDetailCriteria";
  ancillaryId?: Maybe<Scalars["String"]["output"]>;
  channelType?: Maybe<ChannelType>;
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  lastSelectedOfferToken?: Maybe<Scalars["String"]["output"]>;
  originalBookingId?: Maybe<Scalars["String"]["output"]>;
  upsellOfferToken?: Maybe<Scalars["String"]["output"]>;
};

export type MigrationFlightsQueryState = "LOADED" | "LOADING";

export type MigrationIdentity = {
  __typename?: "MigrationIdentity";
  authState?: Maybe<AuthenticationState>;
  duaid: Scalars["UUID"]["output"];
  expUserId?: Maybe<Scalars["String"]["output"]>;
  tuid?: Maybe<Scalars["String"]["output"]>;
};

export type MigrationVirtualAgentContext = {
  __typename?: "MigrationVirtualAgentContext";
  itineraryNumber: Scalars["String"]["output"];
  opaqueToken: Scalars["String"]["output"];
};

export type MinNightStayInputField = {
  __typename?: "MinNightStayInputField";
  stepInput?: Maybe<EgdsBasicStepInput>;
};

export type MinNightStayResult = {
  __typename?: "MinNightStayResult";
  minNightStayValue: Scalars["Int"]["output"];
};

export type MinValueInputValidation = EgdsInputValidation & {
  __typename?: "MinValueInputValidation";
  errorMessage: Scalars["String"]["output"];
  minValue: Scalars["Float"]["output"];
};

export type MishopDetailsUiAction = UiAction & {
  __typename?: "MishopDetailsUIAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  resource: Uri;
};

export type MishopDetailsUiPrimer = {
  __typename?: "MishopDetailsUIPrimer";
  bookByPhonePrimer?: Maybe<MishopUiBookByPhonePrimer>;
  changePackageWizardPrimer?: Maybe<MishopUiChangePackageFormPrimer>;
  checkoutButtonPrimer?: Maybe<MishopUiCheckoutButtonPrimer>;
  crossSellPrimer?: Maybe<MishopUiCrossSellPrimer>;
  error?: Maybe<MishopUiPrimerError>;
  heading?: Maybe<MishopUiHeaderText>;
  legalContentPrimer?: Maybe<MishopUiLegalContentPrimer>;
  notificationPrimer?: Maybe<MishopUiNotificationPrimer>;
  pricingDetailsPrimer?: Maybe<MishopUiPricingDetailsPrimer>;
  productContentPrimer?: Maybe<MishopUiProductContentPrimer>;
  state?: Maybe<MishopDetailsUiState>;
  stepIndicatorPrimer?: Maybe<MishopUiStepIndicatorPrimer>;
  tripSaveItemPrimer?: Maybe<PackageUiTripSaveItemPrimer>;
};

export type MishopDetailsUiState = {
  __typename?: "MishopDetailsUIState";
  multiItemSessionId: Scalars["String"]["output"];
};

export interface MishopRecoveryNaturalKeysInput {
  flightNaturalKeys: Array<FlightNaturalKeyInput>;
  propertyNaturalKeys: Array<PropertyNaturalKeyInput>;
}

export type MishopUiActivitiesCrossSellPrimer = {
  __typename?: "MishopUIActivitiesCrossSellPrimer";
  searchContext: PrimaryActivitiesCriteria;
};

export type MishopUiAffirmPromo = {
  __typename?: "MishopUIAffirmPromo";
  impressionAnalytics: ClientSideAnalytics;
  totalPrice: Scalars["Int"]["output"];
};

export type MishopUiAmenityGroup = {
  __typename?: "MishopUIAmenityGroup";
  icon: Icon;
  primary: MishopUiHeaderText;
  secondaries: Array<MishopUiText>;
};

export type MishopUiBanner = {
  __typename?: "MishopUIBanner";
  banner: UiBanner;
};

export type MishopUiBookByPhonePrimer = {
  __typename?: "MishopUIBookByPhonePrimer";
  propertyId: Scalars["String"]["output"];
};

export interface MishopUiButton {
  accessibility: Scalars["String"]["output"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
}

export type MishopUiCarContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUICarContentPrimer";
  naturalKey: CarNaturalKey;
};

export type MishopUiCarousel = {
  __typename?: "MishopUICarousel";
  analytics: ClientSideAnalytics;
  carouselActions: MishopUiCarouselActions;
  content: MishopUiCarouselContent;
  name: Scalars["String"]["output"];
};

export type MishopUiCarouselAction = {
  __typename?: "MishopUICarouselAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type MishopUiCarouselActions = {
  __typename?: "MishopUICarouselActions";
  next: MishopUiCarouselAction;
  previous: MishopUiCarouselAction;
};

export type MishopUiCarouselContent = PackageUiPreBundledCards;

export type MishopUiChangePackageConfirmationDialogContent = {
  __typename?: "MishopUIChangePackageConfirmationDialogContent";
  message: EgdsParagraph;
  primary: EgdsHeading;
};

export type MishopUiChangePackageForm = EgdsSearchForm & {
  __typename?: "MishopUIChangePackageForm";
  actionURL: Scalars["String"]["output"];
  changePackageCriteria: ChangePackageSearchCriteria;
  elements: Array<EgdsSearchFormElement>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  playback?: Maybe<EgdsSearchPlayback>;
  search?: Maybe<EgdsButton>;
  updatePricesDialog: ChangePackageConfirmationDialog;
};

export type MishopUiChangePackageFormPrimer = {
  __typename?: "MishopUIChangePackageFormPrimer";
  accessibility: Scalars["String"]["output"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  multiItemSessionId: Scalars["String"]["output"];
  searchCriteria: PackageSearchCriteria;
};

export interface MishopUiChangePackageFormPrimerInput {
  multiItemSessionId: Scalars["String"]["input"];
}

export type MishopUiCheckoutButtonPrimer = {
  __typename?: "MishopUICheckoutButtonPrimer";
  accessibility: Scalars["String"]["output"];
  checkoutOptions: Array<CheckoutOption>;
  clickAnalytics: ClientSideAnalytics;
  errorAnalytics: ClientSideAnalytics;
  products: MultiItemProducts;
  responseOptions: Array<PrepareCheckoutResponseOption>;
  text: Scalars["String"]["output"];
  totalPrice?: Maybe<Money>;
};

export type MishopUiCollapsableSection = {
  __typename?: "MishopUICollapsableSection";
  content: Array<EgdsElement>;
  title: EgdsText;
};

export interface MishopUiContentPrimer {
  naturalKey: ProductNaturalKey;
}

export type MishopUiCrossSellPrimer = {
  __typename?: "MishopUICrossSellPrimer";
  crossSellProductPrimers: Array<MishopUiCrossSellProductPrimer>;
};

export type MishopUiCrossSellProductPrimer = MishopUiActivitiesCrossSellPrimer | MishopUiGroundTransfersCrossSellPrimer;

export type MishopUiCustomerNotification = {
  __typename?: "MishopUICustomerNotification";
  regionId?: Maybe<Scalars["String"]["output"]>;
};

export type MishopUiDefaultNavigationBar = EgdsTabs & {
  __typename?: "MishopUIDefaultNavigationBar";
  selectedTabId?: Maybe<Scalars["String"]["output"]>;
  tabs: Array<MishopUiNavigationBarTab>;
};

export type MishopUiDetailGalleryMediaItem = MediaItem & {
  __typename?: "MishopUIDetailGalleryMediaItem";
  media: Media;
};

export type MishopUiDetailMediaGallery = EgdsDetailMediaGallery & {
  __typename?: "MishopUIDetailMediaGallery";
  closeAnalytics: ClientSideAnalytics;
  closeText: Scalars["String"]["output"];
  mediaItems: Array<MishopUiDetailGalleryMediaItem>;
  navigationAnalytics: ClientSideAnalytics;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
};

export type MishopUiDetailMediaGalleryAction = {
  __typename?: "MishopUIDetailMediaGalleryAction";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"]["output"];
  mediaDetailGallery: MishopUiDetailMediaGallery;
};

export interface MishopUiDialog {
  closeAnalytics: ClientSideAnalytics;
  content: MishopUiDialogContent;
  trigger: MishopUiDialogTrigger;
}

export type MishopUiDialogAutoOpenTrigger = MishopUiDialogTrigger & {
  __typename?: "MishopUIDialogAutoOpenTrigger";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"]["output"];
};

export type MishopUiDialogButtonType = "PRIMARY" | "TERTIARY";

export type MishopUiDialogCloseButton = MishopUiDialogFooterButton & {
  __typename?: "MishopUIDialogCloseButton";
  analytics: ClientSideAnalytics;
  text: Scalars["String"]["output"];
  type: MishopUiDialogButtonType;
};

export type MishopUiDialogContent = {
  __typename?: "MishopUIDialogContent";
  elements: Array<MishopUiDialogContentElement>;
};

export type MishopUiDialogContentElement =
  | EgdsStandardMessagingCard
  | MishopUiDialogErrorElement
  | MishopUiParagraphSection
  | MishopUiPricePresentation
  | MishopUiPropertyCleanliness
  | MishopUiReassuranceCard
  | MishopUiRoomDetails
  | MishopUiStrikeThroughPriceDisclaimer;

export type MishopUiDialogErrorElement = {
  __typename?: "MishopUIDialogErrorElement";
  primary: MishopUiHeaderText;
  secondaries: Array<MishopUiText>;
};

export type MishopUiDialogFooter = {
  __typename?: "MishopUIDialogFooter";
  buttons: Array<MishopUiDialogFooterButton>;
  stacked?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface MishopUiDialogFooterButton {
  analytics: ClientSideAnalytics;
  text: Scalars["String"]["output"];
  type: MishopUiDialogButtonType;
}

export type MishopUiDialogHomeButton = MishopUiDialogFooterButton & {
  __typename?: "MishopUIDialogHomeButton";
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  resource: Uri;
  text: Scalars["String"]["output"];
  type: MishopUiDialogButtonType;
};

export type MishopUiDialogLinkTrigger = MishopUiDialogTrigger & {
  __typename?: "MishopUIDialogLinkTrigger";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
};

export type MishopUiDialogPriceLockupTrigger = MishopUiDialogTrigger & {
  __typename?: "MishopUIDialogPriceLockupTrigger";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"]["output"];
  priceLockup?: Maybe<MishopUiPriceLockup>;
};

export type MishopUiDialogRetryButton = MishopUiDialogFooterButton & {
  __typename?: "MishopUIDialogRetryButton";
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  text: Scalars["String"]["output"];
  type: MishopUiDialogButtonType;
};

export interface MishopUiDialogTrigger {
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"]["output"];
}

export type MishopUiEmptyStateError = {
  __typename?: "MishopUIEmptyStateError";
  action?: Maybe<MishopUiButton>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsPlainText>;
};

export type MishopUiEmptyStateErrorElement = {
  __typename?: "MishopUIEmptyStateErrorElement";
  action: MishopUiButton;
  icon: Icon;
  primary: MishopUiHeaderText;
  secondaries: Array<MishopUiText>;
};

export type MishopUiExternalLinkButton = MishopUiButton & {
  __typename?: "MishopUIExternalLinkButton";
  accessibility: Scalars["String"]["output"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  resource?: Maybe<Uri>;
};

export type MishopUiFlightContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUIFlightContentPrimer";
  /** @deprecated Remove actionBar after migrating to shared-ui journey-summary */
  actionBar: Array<MishopDetailsUiAction>;
  contentHeader?: Maybe<MishopUiProductHeader>;
  detailsCriteria: MishopUiFlightDetailsCriteria;
  /** @deprecated Will be replaced in favor of errorFallback */
  errorMessage: MishopUiText;
  fallbackError: PackageUiMessagingCard;
  /** @deprecated Will be removed once 41511 is at 100% */
  flightChangeLink: Uri;
  naturalKey: FlightNaturalKey;
  /** @deprecated Nullable replacement for flightChangeLink introduced to not break client. Will be removed once 41511 is at 100% */
  overrideChangeFlightUrl?: Maybe<Uri>;
  supportedAncillaries?: Maybe<Array<FlightsAncillaryType>>;
  upsellOfferToken: Scalars["String"]["output"];
};

export type MishopUiFlightDetailsCriteria = {
  __typename?: "MishopUIFlightDetailsCriteria";
  journeyCriteria: Array<MishopUiFlightsJourneyCriteria>;
  shoppingContext: ShoppingContext;
  travelerDetails: Array<MishopUiTravelerDetails>;
  tripType: FlightsTripType;
};

export type MishopUiFlightsJourneyCriteria = {
  __typename?: "MishopUIFlightsJourneyCriteria";
  departureDate: Date;
  destination: Scalars["String"]["output"];
  flightsCabinClass: FlightsCabinClass;
  origin: Scalars["String"]["output"];
};

export type MishopUiFullscreenDialog = MishopUiDialog & {
  __typename?: "MishopUIFullscreenDialog";
  closeAnalytics: ClientSideAnalytics;
  content: MishopUiDialogContent;
  fillViewport: Scalars["Boolean"]["output"];
  toolbar: MishopUiToolbar;
  trigger: MishopUiDialogTrigger;
};

export type MishopUiGalleryCarousel = EgdsGalleryCarousel & {
  __typename?: "MishopUIGalleryCarousel";
  accessibilityHeadingText: Scalars["String"]["output"];
  galleryOverlayButton?: Maybe<MishopUiThumbnailMediaGalleryAction>;
  media: Array<MishopUiGalleryMediaItem>;
  mediaAction?: Maybe<MishopUiDetailMediaGalleryAction>;
  navigationAnalytics?: Maybe<ClientSideAnalytics>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
};

export type MishopUiGalleryMediaItem = MediaItem & {
  __typename?: "MishopUIGalleryMediaItem";
  media: Media;
  mediaAction?: Maybe<MishopUiDetailMediaGalleryAction>;
};

export type MishopUiGroundTransfersCrossSellPrimer = {
  __typename?: "MishopUIGroundTransfersCrossSellPrimer";
  searchContext: PrimaryGroundTransfersCriteria;
};

export type MishopUiHeaderText = {
  __typename?: "MishopUIHeaderText";
  text: Scalars["String"]["output"];
};

export type MishopUiInstallmentPlan = {
  __typename?: "MishopUIInstallmentPlan";
  clientId?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics: ClientSideAnalytics;
  locale?: Maybe<Scalars["String"]["output"]>;
  totalPrice: Scalars["String"]["output"];
};

export type MishopUiJumpLinkButton = {
  __typename?: "MishopUIJumpLinkButton";
  accessibility: Scalars["String"]["output"];
  jumpTo: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type MishopUiJumpLinkNavigationBar = EgdsTabs & {
  __typename?: "MishopUIJumpLinkNavigationBar";
  selectedTabId?: Maybe<Scalars["String"]["output"]>;
  tabs: Array<MishopUiNavigationBarTab>;
};

export type MishopUiLegalContentPrimer = {
  __typename?: "MishopUILegalContentPrimer";
  shoppedProducts: Array<ShoppedProduct>;
};

export type MishopUiLegalInformationView = MishopUiLegalInformationViewSuccess;

export type MishopUiLegalInformationViewSuccess = {
  __typename?: "MishopUILegalInformationViewSuccess";
  sections: Array<MishopUiCollapsableSection>;
};

export type MishopUiLinkedHeaderText = {
  __typename?: "MishopUILinkedHeaderText";
  action: MishopDetailsUiAction;
};

export type MishopUiNavigationBarTab = EgdsTab & {
  __typename?: "MishopUINavigationBarTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
};

export type MishopUiNonFullscreenDialog = MishopUiDialog & {
  __typename?: "MishopUINonFullscreenDialog";
  closeAnalytics: ClientSideAnalytics;
  content: MishopUiDialogContent;
  footer: MishopUiDialogFooter;
  trigger: MishopUiDialogTrigger;
};

export type MishopUiNotification = MishopUiCustomerNotification | MishopUiPlacardNotification;

export type MishopUiNotificationPrimer = {
  __typename?: "MishopUINotificationPrimer";
  notifications: Array<MishopUiNotification>;
};

export type MishopUiOneKeyBannerPrimer = {
  __typename?: "MishopUIOneKeyBannerPrimer";
  /** @deprecated Use priceToken field instead */
  multiItemPriceToken: Scalars["String"]["output"];
  priceToken?: Maybe<Scalars["String"]["output"]>;
  products: MultiItemProducts;
};

export type MishopUiOperationFailureDialog = {
  __typename?: "MishopUIOperationFailureDialog";
  content: EgdsParagraph;
  dialog: EgdsActionDialog;
};

export type MishopUiPackageSummaryContent = MishopUiPackageSummaryContentSuccess;

export type MishopUiPackageSummaryContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUIPackageSummaryContentPrimer";
  native?: Maybe<Scalars["Boolean"]["output"]>;
  naturalKey: PackageNaturalKey;
};

export interface MishopUiPackageSummaryContentPrimerInput {
  native?: InputMaybe<Scalars["Boolean"]["input"]>;
  naturalKey: PackageNaturalKeyInput;
}

export type MishopUiPackageSummaryContentSuccess = {
  __typename?: "MishopUIPackageSummaryContentSuccess";
  customizeButton: MishopUiJumpLinkButton;
  descriptions: Array<EgdsText>;
  gallery: MishopUiGalleryCarousel;
  heading: MishopUiSectionHeading;
  navigationBar: MishopUiJumpLinkNavigationBar;
};

export type MishopUiParagraph = {
  __typename?: "MishopUIParagraph";
  text: Scalars["String"]["output"];
};

export type MishopUiParagraphSection = {
  __typename?: "MishopUIParagraphSection";
  content: Array<MishopUiParagraph>;
  header: MishopUiHeaderText;
};

export type MishopUiPlacard = MishopUiBanner;

export type MishopUiPlacardNotification = {
  __typename?: "MishopUIPlacardNotification";
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  placardType: MishopUiPlacardType;
  priceDetailsOptions?: Maybe<PriceDetailsOptions>;
  shoppedProducts: Array<ShoppedProduct>;
};

export interface MishopUiPlacardNotificationInput {
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  placardType: MishopUiPlacardType;
  priceDetailsOptions?: InputMaybe<PriceDetailsOptionsInput>;
  shoppedProducts: Array<ShoppedProductInput>;
}

export type MishopUiPlacardType = "PRICE_CHANGE";

export type MishopUiPriceDetailView = MishopUiPriceDetailViewFailure | MishopUiPriceDetailViewSuccess;

export type MishopUiPriceDetailViewFailure = {
  __typename?: "MishopUIPriceDetailViewFailure";
  content: MishopUiEmptyStateErrorElement;
};

export type MishopUiPriceDetailViewSuccess = {
  __typename?: "MishopUIPriceDetailViewSuccess";
  /** @deprecated We will use installmentPlan for both Affirm and Klarna. */
  affirmPromo?: Maybe<MishopUiAffirmPromo>;
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  detail: MishopUiPricePresentation;
  earnMessage?: Maybe<EarnMessageContainerListItem>;
  flightsAncillaryAlertDialogList?: Maybe<Array<FlightsDialog>>;
  installmentPlan?: Maybe<MishopUiInstallmentPlan>;
  pricePresentationAndSummary: MishopUiPricePresentationAndSummary;
  reassuranceCard?: Maybe<EgdsStandardMessagingCard>;
  reassuranceExpandoCard?: Maybe<MishopUiReassuranceCard>;
  /** @deprecated Will be removed once all clients have stopped requesting for this field */
  tabTitles: Array<Scalars["String"]["output"]>;
};

export type MishopUiPriceLockup = {
  __typename?: "MishopUIPriceLockup";
  accessiblePrice: Scalars["String"]["output"];
  accessibleStrikeThroughDialogTrigger: Scalars["String"]["output"];
  strikeThroughPrice: Scalars["String"]["output"];
};

export type MishopUiPricePresentation = {
  __typename?: "MishopUIPricePresentation";
  pricePresentation: PricePresentation;
};

export type MishopUiPricePresentationAndSummary = {
  __typename?: "MishopUIPricePresentationAndSummary";
  pricePresentationDialog: MishopUiFullscreenDialog;
  priceSummary: MishopUiPriceSummary;
};

export type MishopUiPriceSummary = {
  __typename?: "MishopUIPriceSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  displayPrice: MishopUiText;
  strikeThroughPriceDialog?: Maybe<MishopUiNonFullscreenDialog>;
  title: MishopUiText;
};

export type MishopUiPriceView = {
  __typename?: "MishopUIPriceView";
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  detail: MishopUiPricePresentation;
  pricePresentationAndSummary: MishopUiPricePresentationAndSummary;
};

export type MishopUiPricingDetailsPrimer = {
  __typename?: "MishopUIPricingDetailsPrimer";
  errorMessage?: Maybe<MishopUiText>;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  multiItemSessionId: Scalars["String"]["output"];
  priceDetailsOptions: PriceDetailsOptions;
  shoppedProducts: Array<ShoppedProduct>;
};

export type MishopUiPrimerError = {
  __typename?: "MishopUIPrimerError";
  content: Array<MishopUiPrimerErrorContentElement>;
  icon: Icon;
};

export type MishopUiPrimerErrorContentElement =
  | MishopDetailsUiAction
  | MishopUiEmptyStateErrorElement
  | MishopUiHeaderText;

export type MishopUiProduct = {
  __typename?: "MishopUIProduct";
  content: Array<MishopUiProductContentElement>;
  header: MishopUiProductHeader;
};

export interface MishopUiProductCard {
  actionBar?: Maybe<Array<MishopDetailsUiAction>>;
}

export type MishopUiProductContentElement = MishopUiPropertyProductCard | MishopUiPropertyProductCardFailure;

export type MishopUiProductContentPrimer = {
  __typename?: "MishopUIProductContentPrimer";
  contentPrimers: Array<MishopUiContentPrimer>;
};

export type MishopUiProductHeader = {
  __typename?: "MishopUIProductHeader";
  icon?: Maybe<Icon>;
  primary: MishopUiHeaderText;
  referenceId: Scalars["String"]["output"];
  secondaries: Array<MishopUiText>;
};

export type MishopUiProductType = "ACTIVITY" | "CAR" | "FLIGHT" | "GROUND_TRANSFER" | "PROPERTY";

export type MishopUiPropertyCardContentElement =
  | MishopDetailsUiAction
  | MishopUiFullscreenDialog
  | MishopUiHeaderText
  | MishopUiLinkedHeaderText
  | MishopUiNonFullscreenDialog
  | MishopUiPropertyRating
  | MishopUiText;

export type MishopUiPropertyCardContentSection = {
  __typename?: "MishopUIPropertyCardContentSection";
  content: Array<MishopUiPropertyCardContentElement>;
};

export type MishopUiPropertyCleanliness = {
  __typename?: "MishopUIPropertyCleanliness";
  cleanlinessInformationSections: Array<MishopUiPropertyCleanlinessElement>;
  primary: MishopUiHeaderText;
};

export type MishopUiPropertyCleanlinessElement = MishopUiAmenityGroup | MishopUiText;

export type MishopUiPropertyContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUIPropertyContentPrimer";
  changePropertyAction: PropertySearchMultiItemShoppingAction;
  changeRoomAction: PropertyDetailsMultiItemShoppingAction;
  fallbackErrorCard: PackageUiMessagingCard;
  naturalKey: PropertyNaturalKey;
  /** @deprecated Use relativeChangePropertyLink or relativeChangeRoomLink */
  propertyChangeLink?: Maybe<Scalars["String"]["output"]>;
  propertyContentHeader: MishopUiProductHeader;
  relativeChangePropertyLink: Scalars["String"]["output"];
  relativeChangeRoomLink: Scalars["String"]["output"];
};

export interface MishopUiPropertyContentPrimerInput {
  changePropertyAction: PropertySearchMultiItemShoppingActionInput;
  changeRoomAction: PropertyDetailsMultiItemShoppingActionInput;
  naturalKey: PropertyNaturalKeyInput;
  relativeChangePropertyLink: Scalars["String"]["input"];
  relativeChangeRoomLink: Scalars["String"]["input"];
}

export type MishopUiPropertyProductCard = MishopUiProductCard & {
  __typename?: "MishopUIPropertyProductCard";
  actionBar?: Maybe<Array<MishopDetailsUiAction>>;
  contentSections: Array<MishopUiPropertyCardContentSection>;
  image?: Maybe<Image>;
};

export type MishopUiPropertyProductCardActionableError = {
  __typename?: "MishopUIPropertyProductCardActionableError";
  content: MishopUiEmptyStateErrorElement;
};

export type MishopUiPropertyProductCardFailure = MishopUiProductCard & {
  __typename?: "MishopUIPropertyProductCardFailure";
  actionBar?: Maybe<Array<MishopDetailsUiAction>>;
  actionableError: MishopUiPropertyProductCardActionableError;
  failureMsg: MishopUiText;
};

export type MishopUiPropertyRating = {
  __typename?: "MishopUIPropertyRating";
  numericRating: MishopUiText;
  reviewsText: MishopUiText;
};

export type MishopUiReassuranceCard = {
  __typename?: "MishopUIReassuranceCard";
  expando?: Maybe<ReassuranceExpando>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<EgdsSpannableText>;
};

export type MishopUiRoomDetails = {
  __typename?: "MishopUIRoomDetails";
  naturalKey: PropertyNaturalKey;
};

export type MishopUiSectionHeading = EgdsSectionHeading & {
  __typename?: "MishopUISectionHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type MishopUiSimplePropertyContentPrimer = MishopUiContentPrimer & {
  __typename?: "MishopUISimplePropertyContentPrimer";
  naturalKey: PropertyNaturalKey;
};

export type MishopUiStepIndicatorPrimer = {
  __typename?: "MishopUIStepIndicatorPrimer";
  multiItemSessionId: Scalars["String"]["output"];
};

export type MishopUiStrikeThroughPriceDisclaimer = {
  __typename?: "MishopUIStrikeThroughPriceDisclaimer";
  header?: Maybe<Scalars["String"]["output"]>;
  messageItems: Array<Scalars["String"]["output"]>;
  /** @deprecated DO NOT USE: Use messageItems field instead */
  text: MishopUiText;
};

export type MishopUiText = {
  __typename?: "MishopUIText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type MishopUiTextContainer = {
  __typename?: "MishopUITextContainer";
  elements: Array<EgdsText>;
};

export type MishopUiTextContainerListItem = EgdsTextListItem & {
  __typename?: "MishopUITextContainerListItem";
  element: MishopUiTextContainer;
  nestedList?: Maybe<EgdsTextList>;
  style?: Maybe<EgdsTextListItemStyle>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type MishopUiThumbnailMediaGallery = EgdsThumbnailMediaGallery & {
  __typename?: "MishopUIThumbnailMediaGallery";
  analytics: ClientSideAnalytics;
  mediaAction?: Maybe<MishopUiDetailMediaGalleryAction>;
  tabs?: Maybe<MishopUiDefaultNavigationBar>;
  thumbnails: Array<MishopUiGalleryMediaItem>;
  toolbar: EgdsDialogToolbar;
};

export type MishopUiThumbnailMediaGalleryAction = {
  __typename?: "MishopUIThumbnailMediaGalleryAction";
  analytics: ClientSideAnalytics;
  dialogName: Scalars["String"]["output"];
  mediaThumbnailGallery: MishopUiThumbnailMediaGallery;
};

export type MishopUiToolbar = {
  __typename?: "MishopUIToolbar";
  iconLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type MishopUiTravelerDetails = {
  __typename?: "MishopUITravelerDetails";
  ages?: Maybe<Array<Scalars["Int"]["output"]>>;
  count: Scalars["Int"]["output"];
  travelerType: TravelerDetailsType;
};

export type MobileAppDownloadButton = HeaderSharedUi & {
  __typename?: "MobileAppDownloadButton";
  skipSsr: Scalars["Boolean"]["output"];
};

export type MobileAppUri = Uri & {
  __typename?: "MobileAppURI";
  value: Scalars["String"]["output"];
};

export interface MobileNumberCompletenessModuleRequestInput {
  promptDeniedDate?: InputMaybe<Scalars["String"]["input"]>;
  promptDeniedTimes?: InputMaybe<Scalars["Int"]["input"]>;
}

export type ModalNavType = "BACK" | "CLOSE" | "EXPAND_DOWN" | "EXPAND_UP";

export type ModalSideSheetLayout = "EIGHT" | "FOUR" | "ONE" | "SIX" | "THREE" | "TWELVE" | "TWO";

export type ModelEntityMap = {
  __typename?: "ModelEntityMap";
  key: Scalars["String"]["output"];
  meta?: Maybe<ModelMetaInfo>;
  name: Scalars["String"]["output"];
};

export type ModelMetaInfo = {
  __typename?: "ModelMetaInfo";
  name?: Maybe<Scalars["String"]["output"]>;
  parentContainer?: Maybe<Scalars["String"]["output"]>;
  repeated: Scalars["Boolean"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ModuleName = "COUPON_MODULE";

export type ModuleNames = "DISCOVER" | "HOME";

export interface MojoContextInput {
  pageName: Scalars["String"]["input"];
  placementId: Scalars["String"]["input"];
}

export type MojoLandingSection = {
  __typename?: "MojoLandingSection";
  data?: Maybe<Scalars["String"]["output"]>;
};

export type MojoSection = {
  __typename?: "MojoSection";
  data: Scalars["String"]["output"];
};

export type Money = {
  __typename?: "Money";
  amount: Scalars["Float"]["output"];
  /** @deprecated Currency should be calculated from the context which may not be present so we need to make it nullable going forward, replace with currencyInfo */
  currency: Currency;
  /** @deprecated Use platform formatters and return localized strings instead */
  currencyInfo?: Maybe<Currency>;
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"]["output"];
  /** @deprecated Needed for backwards compatibility, replace with amount */
  raw: Scalars["Float"]["output"];
};

export type MoneyFormattedArgs = {
  formatWholeValue?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export interface MoneyInput {
  amount: Scalars["Float"]["input"];
  currency?: InputMaybe<Scalars["String"]["input"]>;
}

export type Month = {
  __typename?: "Month";
  id: MonthId;
  name: Scalars["String"]["output"];
  ordinal: Scalars["Int"]["output"];
};

export type MonthId =
  | "APRIL"
  | "AUGUST"
  | "DECEMBER"
  | "FEBRUARY"
  | "JANUARY"
  | "JULY"
  | "JUNE"
  | "MARCH"
  | "MAY"
  | "NOVEMBER"
  | "OCTOBER"
  | "SEPTEMBER";

export type MonthsForecastTab = {
  __typename?: "MonthsForecastTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  badges: Array<EgdsStandardBadge>;
  click: ClientSideAnalytics;
  description?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  monthName: Scalars["String"]["output"];
  temperature: DestinationTemperature;
};

export type MonthsForecastsTabs = {
  __typename?: "MonthsForecastsTabs";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  monthlyHotelCards?: Maybe<Array<DestinationPriceInsightModule>>;
  monthsTabs: Array<MonthsForecastTab>;
  overallMaxTemperature: Scalars["Int"]["output"];
  overallMinTemperature: Scalars["Int"]["output"];
};

export type MoreHelpArticles = HelpArticlesLayout & {
  __typename?: "MoreHelpArticles";
  articlesList: Array<HelpArticle>;
  helpArticleHeadingSection: HelpArticleHeadingSection;
  /** @deprecated refactored its props to this level.  */
  helpArticlesInDialog: Array<HelpArticleDialog>;
};

export type MoreHelpArticlesView = HelpArticleErrorView | MoreHelpArticles;

export type MoreInfoDialog = {
  __typename?: "MoreInfoDialog";
  dialog: LodgingDialogToolbar;
  trigger: LodgingDialogTriggerMessage;
};

export type MoreOptionControls = {
  __typename?: "MoreOptionControls";
  closeAccessibility?: Maybe<Scalars["String"]["output"]>;
  hideAccessibility?: Maybe<Scalars["String"]["output"]>;
  hideActionAccessibility?: Maybe<Scalars["String"]["output"]>;
  showAccessibility?: Maybe<Scalars["String"]["output"]>;
  showActionAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type MultiEmailValidation = EgdsInputValidation & {
  __typename?: "MultiEmailValidation";
  errorMessage: Scalars["String"]["output"];
};

export type MultiItemAction = MultiItemSelectPackageAction | MultiItemSelectProductsAction;

export type MultiItemAnalyticsMutationResponse = {
  __typename?: "MultiItemAnalyticsMutationResponse";
  success: Scalars["Boolean"]["output"];
};

export type MultiItemContext = {
  __typename?: "MultiItemContext";
  id: Scalars["String"]["output"];
  packageType: PackageType;
  priceToken?: Maybe<Scalars["String"]["output"]>;
};

export interface MultiItemContextInput {
  id: Scalars["String"]["input"];
  packageType: PackageType;
  priceToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type MultiItemCrossSellUiBadge = {
  __typename?: "MultiItemCrossSellUIBadge";
  text: Scalars["String"]["output"];
};

export type MultiItemCrossSellUiButtonAction = UiAction & {
  __typename?: "MultiItemCrossSellUIButtonAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  resource: Uri;
};

export type MultiItemCrossSellUiCheckoutButtonAction = UiAction & {
  __typename?: "MultiItemCrossSellUICheckoutButtonAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  responseOptions: Array<PrepareCheckoutResponseOption>;
  shoppedProducts: MultiItemProducts;
  totalPrice?: Maybe<Money>;
};

export type MultiItemCrossSellUiElement =
  | MultiItemCrossSellUiBadge
  | MultiItemCrossSellUiButtonAction
  | MultiItemCrossSellUiCheckoutButtonAction
  | MultiItemCrossSellUiHeaderText
  | MultiItemCrossSellUiImage
  | MultiItemCrossSellUiLinkAction
  | MultiItemCrossSellUiPriceDetails
  | MultiItemCrossSellUiText
  | PackageUiPrice;

export type MultiItemCrossSellUiHeaderText = {
  __typename?: "MultiItemCrossSellUIHeaderText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  level: Scalars["Int"]["output"];
  size: Scalars["Int"]["output"];
  text: Scalars["String"]["output"];
};

export type MultiItemCrossSellUiImage = {
  __typename?: "MultiItemCrossSellUIImage";
  image: Image;
};

export type MultiItemCrossSellUiLinkAction = UiAction & {
  __typename?: "MultiItemCrossSellUILinkAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  resource: Uri;
};

export type MultiItemCrossSellUiPriceDetails = {
  __typename?: "MultiItemCrossSellUIPriceDetails";
  accessiblePrice: Scalars["String"]["output"];
  brandedDealBadge?: Maybe<EgdsProgramBadge>;
  price: Scalars["String"]["output"];
  priceSubtext: Scalars["String"]["output"];
  priceSubtextStandard?: Maybe<Scalars["String"]["output"]>;
  strikethroughDisclaimer?: Maybe<CrossSellLegalDisclaimerDialog>;
  strikethroughPrice?: Maybe<Scalars["String"]["output"]>;
};

export type MultiItemCrossSellUiProductCard = {
  __typename?: "MultiItemCrossSellUIProductCard";
  content: Array<MultiItemCrossSellUiElement>;
};

export type MultiItemCrossSellUiProductHeader = {
  __typename?: "MultiItemCrossSellUIProductHeader";
  messageItems: Array<CrossSellSecondary>;
  primary: MultiItemCrossSellUiHeaderText;
  /** @deprecated use messageItems instead */
  secondaries: Array<MultiItemCrossSellUiText>;
};

export type MultiItemCrossSellUiResponse = {
  __typename?: "MultiItemCrossSellUIResponse";
  actionBar: Array<MultiItemCrossSellUiLinkAction>;
  header: MultiItemCrossSellUiProductHeader;
  impressionAnalytics: ClientSideAnalytics;
  productCards: Array<MultiItemCrossSellUiProductCard>;
};

export type MultiItemCrossSellUiText = {
  __typename?: "MultiItemCrossSellUIText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
};

export interface MultiItemDetailsPrimersQuery {
  primers: MishopDetailsUiPrimer;
}

export type MultiItemDetailsQuery = {
  __typename?: "MultiItemDetailsQuery";
  changePackageForm: MishopUiChangePackageForm;
  legalInformation: MishopUiLegalInformationView;
  messagingCard: PackageUiMessagingCardResponse;
  oneKeyBanner?: Maybe<EgdsStandardMessagingCard>;
  packageSummaryContent: MishopUiPackageSummaryContent;
  placard?: Maybe<MishopUiPlacard>;
  policies: PackageUiPolicies;
  priceDetails: MishopUiPriceDetailView;
  /** @deprecated Use the `priceDetails` query instead */
  pricingDetails: MishopUiPriceView;
  productChangedBanner: Array<MishopUiBanner>;
  productHeader?: Maybe<MishopUiProductHeader>;
  propertyContent: MishopUiProduct;
  seeAllPackagesLink: PackageUiSeeAllPackagesLink;
};

export type MultiItemDetailsQueryChangePackageFormArgs = {
  primer: MishopUiChangePackageFormPrimerInput;
};

export type MultiItemDetailsQueryLegalInformationArgs = {
  shoppedProducts: Array<ShoppedProductInput>;
};

export type MultiItemDetailsQueryMessagingCardArgs = {
  inputs: PackageUiMessagingCardInputsInput;
  type: PackageUiMessagingCardType;
};

export type MultiItemDetailsQueryOneKeyBannerArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  products?: InputMaybe<MultiItemProductsInput>;
};

export type MultiItemDetailsQueryPackageSummaryContentArgs = {
  packageSummaryContentPrimer: MishopUiPackageSummaryContentPrimerInput;
};

export type MultiItemDetailsQueryPlacardArgs = {
  placardNotificationPrimer: MishopUiPlacardNotificationInput;
};

export type MultiItemDetailsQueryPoliciesArgs = {
  products: MultiItemProductsInput;
};

export type MultiItemDetailsQueryPriceDetailsArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  priceDetailsOptions?: InputMaybe<PriceDetailsOptionsInput>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  shoppedProducts: Array<ShoppedProductInput>;
};

export type MultiItemDetailsQueryPricingDetailsArgs = {
  shoppedProducts: Array<ShoppedProductInput>;
};

export type MultiItemDetailsQueryProductChangedBannerArgs = {
  productChangedBannerPrimers: Array<PackageUiProductChangedBannerPrimerInput>;
};

export type MultiItemDetailsQueryProductHeaderArgs = {
  productHeaderPrimer: PackageUiProductHeaderPrimerInput;
};

export type MultiItemDetailsQueryPropertyContentArgs = {
  propertyContentPrimer: MishopUiPropertyContentPrimerInput;
};

export type MultiItemDetailsQuerySeeAllPackagesLinkArgs = {
  searchCriteria: PackageSearchCriteriaInput;
};

export type MultiItemDynamicCrossSellPrimer = {
  __typename?: "MultiItemDynamicCrossSellPrimer";
  multiItemSearchContext: MultiItemSearchContext;
  multiItemSelectedFlightProducts: MultiItemSelectedFlightProducts;
};

export type MultiItemFlightCrossSellOfferPrimer = {
  __typename?: "MultiItemFlightCrossSellOfferPrimer";
  brandedDealFallBack?: Maybe<BrandedDealCrossSellFallback>;
  multiItemPackageableProduct?: Maybe<MultiItemPackageableProduct>;
  multiItemPricedPackageableProducts?: Maybe<Array<MultiItemPricedPackageableProduct>>;
  multiItemSearchContext: MultiItemSearchContext;
  price?: Maybe<BrandedDealPrice>;
};

export type MultiItemFlightCrossSellPrimer = {
  __typename?: "MultiItemFlightCrossSellPrimer";
  flightCrossSellProductType?: Maybe<FlightCrossSellProductType>;
  multiItemSearchContext: MultiItemSearchContext;
};

export type MultiItemFlightSearchCrossSellOfferPrimer = {
  __typename?: "MultiItemFlightSearchCrossSellOfferPrimer";
  brandedDealFallBack?: Maybe<BrandedDealCrossSellFallback>;
  multiItemPackageableProduct?: Maybe<MultiItemPackageableProduct>;
  multiItemSearchContext: MultiItemSearchContext;
  price?: Maybe<BrandedDealSearchPrice>;
};

export type MultiItemMessage = {
  __typename?: "MultiItemMessage";
  message: Array<Scalars["String"]["output"]>;
};

export type MultiItemPackageableProduct = {
  __typename?: "MultiItemPackageableProduct";
  flights: Array<MultiItemSelectedFlightNaturalKey>;
};

export type MultiItemPickUpDropOffMessage = {
  __typename?: "MultiItemPickUpDropOffMessage";
  message: CarPhrase;
};

export type MultiItemPlayBackSecondaryMessage = {
  __typename?: "MultiItemPlayBackSecondaryMessage";
  accessibilityMessage: CarPhrase;
  longMessage: CarPhrase;
  shortMessage: CarPhrase;
};

export type MultiItemPlayBackTitleBar = {
  __typename?: "MultiItemPlayBackTitleBar";
  primary: CarPhrase;
  secondary: MultiItemPlayBackSecondaryMessage;
};

export type MultiItemPricedPackageableProduct = {
  __typename?: "MultiItemPricedPackageableProduct";
  brandedDealPrice?: Maybe<BrandedDealPrice>;
  flight: MultiItemSelectedFlightNaturalKey;
};

export type MultiItemProducts = {
  __typename?: "MultiItemProducts";
  activities?: Maybe<Array<ActivityNaturalKey>>;
  cars?: Maybe<Array<CarNaturalKey>>;
  flights?: Maybe<Array<FlightNaturalKey>>;
  groundTransfers?: Maybe<Array<GroundTransfersNaturalKey>>;
  packages?: Maybe<Array<PackageNaturalKey>>;
  properties?: Maybe<Array<PropertyNaturalKey>>;
};

export interface MultiItemProductsInput {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  packages?: InputMaybe<Array<PackageNaturalKeyInput>>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
}

export type MultiItemSearchContext = {
  __typename?: "MultiItemSearchContext";
  cars?: Maybe<Array<CarSearchCriteria>>;
  flights?: Maybe<Array<FlightSearchCriteria>>;
  properties?: Maybe<Array<PropertySearchCriteria>>;
};

export type MultiItemSearchContextActionableError = {
  __typename?: "MultiItemSearchContextActionableError";
  content: MultiItemSearchContextErrorContentElement;
};

export type MultiItemSearchContextCreatedResponse = {
  __typename?: "MultiItemSearchContextCreatedResponse";
  action: MultiItemShoppingAction;
  message?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["URL"]["output"]>;
  /** @deprecated Use action.shoppingContext */
  shoppingContext: ShoppingContext;
  target?: Maybe<UiLinkTarget>;
};

export type MultiItemSearchContextErrorContentElement = MishopUiEmptyStateErrorElement;

export type MultiItemSearchContextErrorResponse = {
  __typename?: "MultiItemSearchContextErrorResponse";
  /** @deprecated Use errorContent instead */
  actionableError: MultiItemSearchContextActionableError;
  /** @deprecated Use errorContent instead */
  content?: Maybe<MishopUiEmptyStateError>;
  /** @deprecated WIP - This error is meant to replace the existing error content on the page */
  errorContent?: Maybe<PackageUiEmptyStateError>;
  failureDialog?: Maybe<MishopUiOperationFailureDialog>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export interface MultiItemSearchContextInput {
  cars?: InputMaybe<Array<CarSearchCriteriaInput>>;
  flights?: InputMaybe<Array<FlightSearchCriteriaInput>>;
  properties?: InputMaybe<Array<PropertySearchCriteriaInput>>;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type MultiItemSearchContextResult = MultiItemSearchContextCreatedResponse | MultiItemSearchContextErrorResponse;

export type MultiItemSelectPackage = FlightsClientAction & {
  __typename?: "MultiItemSelectPackage";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  packageOfferId: Scalars["String"]["output"];
};

export type MultiItemSelectPackageAction = {
  __typename?: "MultiItemSelectPackageAction";
  packageOfferId: Scalars["String"]["output"];
};

export type MultiItemSelectProducts = FlightsClientAction & {
  __typename?: "MultiItemSelectProducts";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analyticsList: Array<ClientSideAnalytics>;
  flightsOfferNaturalKeys: FlightsOfferNaturalKeys;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  multiItemSessionId: Scalars["String"]["output"];
};

export type MultiItemSelectProductsAction = {
  __typename?: "MultiItemSelectProductsAction";
  flightsOfferNaturalKeys: FlightsOfferNaturalKeys;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  multiItemSessionId: Scalars["String"]["output"];
};

export type MultiItemSelectedFlightNaturalKey = {
  __typename?: "MultiItemSelectedFlightNaturalKey";
  offerToken: Scalars["String"]["output"];
  productTokens: Array<Scalars["String"]["output"]>;
  travelers: Array<TravelerDetails>;
};

export type MultiItemSelectedFlightProducts = {
  __typename?: "MultiItemSelectedFlightProducts";
  flights: Array<MultiItemSelectedFlightNaturalKey>;
};

export type MultiItemSessionMutations = {
  __typename?: "MultiItemSessionMutations";
  addProducts: MultiItemSearchContextResult;
  changePackage: MultiItemSearchContextResult;
  changeSelectedProduct: MultiItemSearchContextResult;
  initiate: MultiItemSearchContextResult;
  removeProducts: MultiItemSearchContextResult;
  selectPackage: MultiItemSearchContextResult;
  selectProducts: MultiItemSearchContextResult;
  updateProduct: MultiItemSearchContextResult;
};

export type MultiItemSessionMutationsAddProductsArgs = {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  sessionId: Scalars["String"]["input"];
};

export type MultiItemSessionMutationsChangePackageArgs = {
  searchCriteria: PackageSearchCriteriaInput;
  sessionId: Scalars["String"]["input"];
};

export type MultiItemSessionMutationsChangeSelectedProductArgs = {
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  changeAction: MultiItemShoppingActionType;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  priceToken?: InputMaybe<Scalars["String"]["input"]>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
  sessionId: Scalars["String"]["input"];
};

export type MultiItemSessionMutationsInitiateArgs = {
  searchContext: MultiItemSearchContextInput;
};

export type MultiItemSessionMutationsRemoveProductsArgs = {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  sessionId: Scalars["String"]["input"];
};

export type MultiItemSessionMutationsSelectPackageArgs = {
  packageOfferId: Scalars["String"]["input"];
  sessionId: Scalars["String"]["input"];
};

export type MultiItemSessionMutationsSelectProductsArgs = {
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  dealMarker?: InputMaybe<Scalars["String"]["input"]>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  priceToken?: InputMaybe<Scalars["String"]["input"]>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
  sessionId: Scalars["String"]["input"];
};

export type MultiItemSessionMutationsUpdateProductArgs = {
  activity?: InputMaybe<ActivityUpdateInput>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  priceToken?: InputMaybe<Scalars["String"]["input"]>;
  sessionId: Scalars["String"]["input"];
};

export interface MultiItemShoppingAction {
  shoppingContext: ShoppingContext;
}

export type MultiItemShoppingActionType = "CAR_SEARCH" | "FLIGHT_SEARCH" | "PROPERTY_SEARCH";

export type Mutation = {
  __typename?: "Mutation";
  abandonBooking: AbandonBookingResponse;
  abandonWorkflow: AbandonWorkflowMutationResponse;
  acceptConfirmation: BookingServicingAcceptConfirmation;
  acceptRequestToJoinTrip: TripsUiToast;
  accountFormSubmit: ProfileAccountFormComponent;
  addChatMessage: SupplyReservationsInteractionMessageGroup;
  addDestinationToWishlist?: Maybe<WishlistUpdateResponse>;
  addInsurtechOfferToTrip: UpdateInsurtechOfferResponse;
  addOrRemoveAffiliatesCollectionItem: AffiliatesAddOrRemoveCollectionItemResponse;
  addSupplyPhotos: SupplyPhotosAddExperience;
  agencyDeleteQuote: AgencyQuoteDeleteStatus;
  agencyQuoteBuilderAdd: AgencyQuoteBuilderResponse;
  agencyQuoteBuilderHandoff: AgencyQuoteBuilderResponse;
  agencyQuoteBuilderRemove: AgencyQuoteBuilderResponse;
  agencySaveQuote: AgencyQuoteSaveResponse;
  applyCoupon: AppliedCoupon;
  applyPaymentMethod: PaymentResponse;
  approveCancellationWaiver: SupplyCancellationWaiverApprovalResponseContent;
  askProductQuestion?: Maybe<Scalars["String"]["output"]>;
  assignSupplyPhotos: SupplyPhotosAssignExperience;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  batchUpdateInboxNotificationsByUserId: Array<CustomerNotification>;
  book: BookResponse;
  bookingServicingChangeLodgingDetails: BookingServicingChangeLodgingMutationResponse;
  bookingServicingConfirmation: BookingServicingConfirmationResponse;
  bulkAddInventoryUpdate: BulkAddInventoryResponse;
  cancelConfirmationLoaded: BookingServicingCancelConfirmationLoaded;
  capturePaymentInfo: CapturePaymentInfoMutationResponse;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  cartItems: CartMutations;
  changeNotificationState?: Maybe<RetailTravelAgentMutateNotificationResult>;
  chatGPTUpdateTrip: ChatGptUpdateTripResult;
  checkoutAvailabilityCheck: CheckoutSessionDetailsResponse;
  closeAffiliateToolbar: AffiliatesCloseToolbarResponse;
  confirmAffiliateAccountLink: AffiliatesConfirmAccountLinkResponse;
  connectExternalMailbox: TripsUiConnectExternalMailboxResponse;
  consentMgmtPrefSubmit: SavePrefResponse;
  contactUsOptions: ContactUsViewMutation;
  contentLikeInteraction: ContentLikeInteractionResponse;
  contentShareInteraction: ContentShareInteractionResponse;
  createAffiliateCustomLink: AffiliatesCreateCustomLinkResponse;
  createAffiliateLink: AffiliatesCreateLinkResponse;
  createAffiliateTag: AffiliatesCreateAffiliateTagResponse;
  createAffiliateVanityLink: AffiliatesCreateVanityLinkResponse;
  createAffiliatesCollection: AffiliatesCreateCollectionResponse;
  createMultiItemSession: MultiItemSearchContextResult;
  createPaymentTokenization: PaymentTokenResponse;
  createPriceTracking: PriceInsightsTrackingView;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  createSessionCart: Cart;
  createSupplyReservationsAccessInstructions: SupplyReservationsAccessInstructions;
  createTrip: TripsUiCreateTripResponse;
  createTripInvite: TripsUiCreateInviteResponse;
  createTripItineraryTemplate: TripsUiCreateTripItineraryTemplateResponse;
  creditCardApplicationFormUpdate: CreditCardApplicationStepResponse;
  creditCardCheckoutSessionValidate: CreditCardCheckoutSessionValidateResponse;
  declineCancellationWaiver: SupplyCancellationWaiverDeclineSuccessContent;
  deleteAffiliatesCollection: AffiliatesDeleteCollectionResponse;
  deleteDestinationFromWishlist?: Maybe<WishlistUpdateResponse>;
  deleteNotification: CommunicationCenterNotificationMutationResponse;
  deleteNotificationById: InboxNotificationDeleteResponse;
  deleteOwnersAccount: SupplyDeleteAccountMutationResponse;
  deleteSupplyPhotos: SupplyPhotosDeleteExperience;
  deleteSupplyReservationsAccessInstructions: Scalars["Boolean"]["output"];
  deleteTrip: TripsUiDeleteTripResponse;
  directFeedbackSubmit: DirectFeedbackFormValidation;
  disablePriceTracking: PriceInsightsTrackingView;
  discardAbandonedCheckout?: Maybe<AbandonedCheckoutDiscardStatus>;
  disconnectExternalMailboxes: TripsUiDisconnectExternalMailboxesResponse;
  editAffiliatesCollection: AffiliatesEditCollectionResponse;
  editAffiliatesCollectionItem: AffiliatesEditCollectionItemResponse;
  editTrip: TripsUiEditTripResponse;
  editUniversalProfile: EditUniversalProfileResponse;
  egLabsTripCollaborationCreateTrip: EgLabsCreateTripResponse;
  flightsAncillaryUpdate?: Maybe<FlightsAncillaryUpdateResponse>;
  flightsFinancialAdjustment: FlightsFinancialAdjustmentResponse;
  flightsMerchandisingBook: FlightsMerchandisingBookResponse;
  flightsServicingCompleteBooking: FlightsServicingBookingResponse;
  generateAffiliatesVideoUploadUrl: AffiliatesGenerateVideoUploadUrlResponse;
  googleOneTapSubmit?: Maybe<IdentityResponse>;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  hideAllSearchHistories?: Maybe<HistoryMutationResponse>;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  hideSearchHistory?: Maybe<HistoryMutationResponse>;
  identityAddPhoneResendOTPSubmit?: Maybe<IdentityAddPhoneResendOtpSubmitResponse>;
  identityAddPhoneSendOTPSubmit?: Maybe<IdentityAddPhoneSendOtpSubmitResponse>;
  identityAddPhoneVerifyOTPSubmit?: Maybe<IdentityAddPhoneVerifyOtpSubmitResponse>;
  identityCreateAccount: IdentityAccountResponse;
  identityFormSubmit?: Maybe<IdentityResponse>;
  identityLogout: IdentityLogoutResponse;
  identityMarkJourneyComplete: AccountMergeMutationResponse;
  identityResendOTPAuthenticateSubmit?: Maybe<IdentityResendOtpAuthenticationResponse>;
  identitySendOTPAuthenticateSubmit?: Maybe<IdentitySendOtpAuthenticationResponse>;
  identityVerifyOTPAuthenticationSubmit?: Maybe<IdentityVerifyOtpAuthenticationResponse>;
  identityVerifyPasswordAuthenticateSubmit?: Maybe<IdentityVerifyPasswordAuthenticationResponse>;
  initiateAddOrRemoveCollectionItemValidation: AffiliatesTextFieldsValidationResponse;
  insuranceBookingCancellation?: Maybe<InsuranceBookingCancellationResponse>;
  insurtechCompleteBooking?: Maybe<InsurtechBookingResponse>;
  insurtechUpdateProductSelection: InsurtechUpdateProductSelectionResponse;
  interactions: SupplyReservationsInteractionUpdateResponse;
  knowYourCustomerSubmit: KnowYourCustomerMutation;
  lodgingCancel: LodgingCancelMutationResponse;
  lodgingChange: LodgingChangeMutationResponse;
  lodgingChangeCheckout: LodgingChangeCheckoutResponse;
  lodgingChangeResumeOrder: LodgingChangeMutationResponse;
  lodgingRefund?: Maybe<RefundFormMutationResponse>;
  logout: LogoutResponse;
  manageSocialConnections: SocialConnectionsManageConnectionResponse;
  markAnswerHelpful?: Maybe<Scalars["Boolean"]["output"]>;
  markTripsCoachmarkAsSeen: TripsUiMarkCoachmarkAsSeenResponse;
  /** @deprecated this mutation is deprecated but required for UIS Prime, it may be removed with the move to eg clickstream */
  mergeMultiItemAnalytics: MultiItemAnalyticsMutationResponse;
  multiItemShopping: MultiItemSessionMutations;
  oneKeyCashBurnToggle: OneKeyCashBurnToggleResult;
  oneKeyOnboardingOperation: OneKeyOnboardingOperationResult;
  oneKeyUniversalOnboardingOperation: OneKeyUniversalOnboardingOperationResult;
  preloadPropertySearch: PropertySearchPreloadResults;
  prepareChangeCheckout: PrepareChangeCheckoutResponse;
  prepareCheckout: PrepareCheckoutResponse;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  prepareCheckoutByCartId: PrepareCheckoutResponse;
  prepareCheckoutBySessionId: PrepareCheckoutResponse;
  priceMatchPromiseCheckPrice?: Maybe<PriceCheckMutationResult>;
  publishCampaign: CampaignPublishResponse;
  publishInteractionEvent: InteractionEventResponse;
  rejectReview: Scalars["Boolean"]["output"];
  removeCoupon: CouponRedemption;
  removeInsurtechOfferFromTrip: UpdateInsurtechOfferResponse;
  removePaymentMethod: PaymentResponse;
  removeShortlistItem: RemoveItemResults;
  /** @deprecated Use updateTripItemTrip instead for removing an item from trip. */
  removeTripItem: TripsUiRemoveTripItemResponse;
  saveAnswers: ReviewAnswersResponse;
  saveBrandResultListingCreative?: Maybe<SaveBrandResultListingCreativeResponse>;
  saveExternalItemsToTrip: TripsUiSaveExternalItemsToTripResponse;
  saveItineraryAsTrip: TripsUiSaveItineraryAsTripResponse;
  savePropertyCancelPolicyExceptions: SavePropertyCancelPolicyExceptionsResponse;
  savePublicProfileSettings: SocialConnectionsSaveProfileSettingsResponse;
  saveReservationModifiersCancel: SupplyReservationModifiers;
  saveReservationModifiersChangeDates: SupplyReservationModifiers;
  saveReservationModifiersGuestSatisfactionRefund: SupplyReservationModifiers;
  saveReservationModifiersNoShow: SupplyReservationModifiers;
  saveReservationModifiersVRCancel: SupplyReservationModifiers;
  saveShortlistItem?: Maybe<SavedItem>;
  saveSupplyRatePlan: SupplyRatePlan;
  saveSupplyRegulatorySettings?: Maybe<SupplyRegulatoryFlow>;
  saveTripItemComment: TripsUiSaveTripItemCommentResponse;
  saveTripItems: TripsUiSaveTripItemsResponse;
  saveVideoInteraction: VideoExperienceSaveVideoInteractionResponse;
  searchLocationDeleteRecentSearch: SearchLocationActionResponse;
  selectPaymentMethod: SelectPaymentMethodDataModule;
  sendCommunicationCenterMessage: CommunicationCenterMessageMutationResponse;
  sendConversationEvent: VirtualAgentControlConversationEventResponse;
  sendMessage?: Maybe<VirtualAgentControlSendMessageResult>;
  sendPaymentAssist: SendPaymentAssistMutationResponse;
  sendPaymentVerification: SendPaymentVerificationMutationResponse;
  sendTextToDownloadApp: NotificationTextToDownloadAppResponse;
  sendTripInvite: TripsUiSendTripInviteResponse;
  setFlightPriceAlertOptIn?: Maybe<FlightsPriceAlertsOptInResponse>;
  setMinNightStay?: Maybe<MinNightStayResult>;
  smartForm: SmartFormMutation;
  submitAffiliatesVideo: AffiliatesSubmitVideoResponse;
  submitInquiry: InquiryResponse;
  supplyBookingOptionsUpdate?: Maybe<SupplyBookingOptionsUpdate>;
  supplyCoachingTipPostpone: SupplyPostponeSubmitResponse;
  supplyCoachingTipQuickActionSubmit: SupplyQuickActionSubmitResponse;
  supplyContentUpdateAdditionalInfo: SupplyContentDescriptionUpdate;
  supplyContentUpdateHeadlineDescription: SupplyContentDescriptionUpdate;
  supplyContentUpdateLocation: SupplyContentUpdateLocationResult;
  supplyContentUpdateManualTranslation: SupplyContentManualTranslationUpdate;
  supplyContentUpdateYourProperty: SupplyContentDescriptionUpdate;
  supplyContentUpdateYourStory: SupplyContentDescriptionUpdate;
  supplyContractingOffersAcceptance: SupplyContractingMutationResponse;
  supplyFeesUpdate: SupplyFees;
  supplyIndirectTaxNavigateBack: SupplyIndirectTaxNavigate;
  supplyIndirectTaxUpdate: SupplyIndirectTaxNavigate;
  supplyLoyalty?: Maybe<SupplyLoyaltyMutation>;
  supplyMarketplaceFeedItemUpdate: SupplyMarketplaceFeedItemUpdateResponse;
  supplyMarketplaceFeedUpdate: SupplyMarketplaceFeedDismiss;
  supplyMultiFactorCodeGeneration: SupplyMultiFactorAuthenticationExperience;
  supplyMultiFactorCodeVerification: SupplyMultiFactorAuthenticationExperience;
  supplyNavigationNotificationActivity: SupplyNavigationNotificationActivityNotificationIds;
  supplyOnboardingAvailabilityUpdate?: Maybe<SupplyOnboardingAvailabilityUpdate>;
  supplyOnboardingPublishListing?: Maybe<SupplyOnboardingPublishListingResult>;
  supplyOnboardingWelcomeNavigate: SupplyOnboardingWelcomeNavigationUpdate;
  supplyOnboardingWorkFlowNavigate: SupplyOnboardingWorkFlowNavigationUpdate;
  supplyProductCreationBaseRateManagement: SupplyProductCreationManagementResult;
  supplyProductCreationBreakfastRatePlanManagement: SupplyProductCreationRatePlanManagementResult;
  supplyProductCreationChildRatePlanManagement: SupplyProductCreationChildRatePlanManagementResult;
  supplyProductCreationDeleteRatePlans: SupplyProductCreationRatePlanManagementResult;
  supplyProductCreationDeleteRoom: SupplyProductCreationDeleteRoomResult;
  supplyProductCreationPricingModelUpdate?: Maybe<SupplyProductCreationManagementResult>;
  supplyProductCreationRoomInfoManagement: SupplyProductCreationManagementResult;
  supplyProductCreationRoomNameManagement: SupplyProductCreationRoomNameManagementResult;
  supplyProductCreationRoomNameUpdate: SupplyProductCreationRoomNameManagementResult;
  supplyProductCreationRoomOverviewUpdateStepStatus: SupplyProductCreationManagementResult;
  supplyProgramsOffersAcceptance: SupplyProgramsMutationResponse;
  supplyPromotionCreateTieredMembersOnlyDeals: SupplyPromotionMutationResponse;
  supplyPromotionUpdateStackingOptions: SupplyPromotionMutationResponse;
  supplyPromotionUpdateTieredMembersOnlyDeal: SupplyPromotionMutationResponse;
  supplyReservationsReactivateProperty: SupplyReservationsPropertySnoozedStatus;
  supplyReservationsUpdateBookingRequest: SupplyReservationsUpdateBookingContent;
  supplyRoomTypeMappingPropertyValidate: SupplyRoomTypeMappingSubmitResponse;
  supplyRoomTypeMappingSubmit: SupplyRoomTypeMappingSubmitResponse;
  supplyTaxesUpdate?: Maybe<SupplyTaxesEditor>;
  supplyUpdateNewListingOfferEnrollment: SupplyUpdateNewListingOfferEnrollment;
  supplyUpdatePartnerAttestation: SupplyOnboardingPartnerAttestationResult;
  supplyUpdatePartnerBillingAddress: SupplyOnboardingPartnerBillingAddress;
  supplyUpdatePartnerBusinessName: SupplyOnboardingPartnerBusinessNameResult;
  supplyUpdatePartnerHostType: SupplyOnboardingHostTypeResult;
  supplyUpdatePartnerLegalName: SupplyOnboardingPartnerLegalNameResult;
  supplyUpdatePartnerPayoutDeferredAccount: SupplyUpdatePartnerPayoutDeferredAccount;
  supplyUpdatePropertyRates: SupplyUpdatePropertyRates;
  sweepstakesDisenroll: SweepstakesDisenrollResponse;
  sweepstakesUpdate: SweepstakesDashboardQuery;
  sweepstakesUserWipe: SweepstakesUserWipeQuery;
  textToDownloadApp: CustomerNotificationTextToDownloadAppResponse;
  /** @deprecated Use userDismissTask mutation instead */
  topTaskDismiss: SupplyTopTaskDismiss;
  /** @deprecated Temporary mutation to track sponsored click beacons */
  trackSponsoredListingBeaconClick?: Maybe<Scalars["String"]["output"]>;
  trip: TripMutation;
  tripCollaborationChatAddParticipants: AddParticipantsToConversationResult;
  tripsRefreshItineraryItem: TripsUiTripItineraryItemResponse;
  tripsRemoveItineraryItem: TripsUiTripItineraryItemResponse;
  triviaStart: TriviaStartQuery;
  triviaSubmit: TriviaFinishedQuery;
  unassignSupplyPhotos: SupplyPhotosUnassignExperience;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  unhideAllSearchHistories?: Maybe<HistoryMutationResponse>;
  /** @deprecated Scream test: please contact #ask-trips-platform if you are still using TGS. */
  unhideSearchHistory?: Maybe<HistoryMutationResponse>;
  updateAdGroupCreatives: Array<AddCreativeResponse>;
  updateAffiliatesCollectionVisibility: AffiliatesUpdateCollectionVisibilityResponse;
  updateAgentPresence: UpdatePresence;
  updateAndBook: PaymentConfirmationRedirectResponse;
  updateApprovalStatus: UpdateApprovalStatusResponse;
  updateConnectivitySettingsProviderServices: SupplyConnectivitySettingsExperience;
  updateConversationState: CommunicationCenterConversationMutationResponse;
  updateInbox?: Maybe<Array<InboxNotificationUpdateResponse>>;
  /** @deprecated Please use https://github.expedia.biz/Brand-Expedia/notifications-spinner instead */
  updateInboxNotification: Array<CustomerNotification>;
  updateInboxState?: Maybe<Array<InboxNotificationUpdateStateResponse>>;
  updateInsurtechOffer: UpdateInsurtechOfferResponse;
  /** @deprecated Use interactions top level mutation instead */
  updateInteractions: SupplyReservationsInteractionsListItemActionResponse;
  updateMessageState: CommunicationCenterMutationResponse;
  updateNewListingOfferEnrollment: SupplyUpdateNewListingOfferEnrollment;
  updateNotificationsState: Array<CommunicationCenterNotificationMutationResponse>;
  updatePartnerPayoutDeferredAccount: SupplyUpdatePartnerPayoutDeferredAccount;
  updatePaymentAssistSessionCardType: UpdatePaymentAssistSessionCardTypeResponse;
  updatePaymentAssistSessionStatus: UpdatePaymentAssistSessionStatusResponse;
  updatePaymentMethod: UpdatePaymentMethodMutationResponse;
  updatePriceTracking: PriceInsightsTrackingView;
  updatePropertyRates: SupplyUpdatePropertyRates;
  updateSupplyAmenities: SupplyAmenitiesGroup;
  updateSupplyAttributes: SupplyAttributesAdminExperience;
  updateSupplyDismissibleCard: SupplyDismissibleCardResponse;
  updateSupplyReservationsAccessInstructions: SupplyReservationsAccessInstructions;
  updateSupplyReservationsPartnerNote: SupplyReservationsPartnerNotes;
  updateSupplySelectedWaivers: UpdateSupplySelectedWaiversResponse;
  updateSupplyUserProfile: SupplyUserProfileMutation;
  updateTripItemDates: TripsUiUpdateTripItemDatesResponse;
  updateTripItemPriceAlertStatus: TripsUiUpdateTripItemPriceAlertStatusResponse;
  updateTripItemTrip: TripsUiUpdateTripItemTripResponse;
  updateTripItemVote?: Maybe<TripsUiUpdateTripItemVoteResponse>;
  updateUserStateAndSendMessage: ConversationAuthenticationView;
  upsertAccelerators: AcceleratorUpsertResponse;
  /** @deprecated Use upvotePropertyReviewWithAccessibility */
  upvotePropertyReview: Scalars["String"]["output"];
  upvotePropertyReviewWithAccessibility: UpvotePropertyReviewWithAccessibility;
  userDismissTask: SupplyTopTaskDismiss;
  userProfileWizardSubmit: ProfileWizardResponse;
  userSettingFormSubmit: ProfileComponent;
  virtualAgentControlCreateConversation?: Maybe<CreateConversationResult>;
  virtualAgentControlSubmitSurvey?: Maybe<VirtualAgentControlSubmitSurveyResult>;
  virtualAgentFlightCheckout: VirtualAgentCheckoutResponse;
};

export type MutationAbandonBookingArgs = {
  checkoutSessionInfo: CheckoutSessionInfoInput;
  context: ContextInput;
};

export type MutationAbandonWorkflowArgs = {
  bookingItemId: Scalars["String"]["input"];
  orderNumber: Scalars["String"]["input"];
  transactionType: Scalars["String"]["input"];
};

export type MutationAcceptConfirmationArgs = {
  context: ContextInput;
  criteria: BookingServicingAcceptFlightInput;
};

export type MutationAcceptRequestToJoinTripArgs = {
  context: ContextInput;
  requestToJoinId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type MutationAccountFormSubmitArgs = {
  actionContext: Scalars["String"]["input"];
  context: ContextInput;
  inputs: Array<GraphQlPairInput>;
};

export type MutationAddChatMessageArgs = {
  clientData?: InputMaybe<SupplyReservationsInteractionMessagesClientData>;
  context: ContextInput;
  propertyContext: PropertyContext;
  request: SupplyReservationsInteractionAddChatMessageRequest;
};

export type MutationAddDestinationToWishlistArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type MutationAddInsurtechOfferToTripArgs = {
  context: ContextInput;
  insurtechAddTripRequest: InsurtechAddTripRequestInput;
};

export type MutationAddOrRemoveAffiliatesCollectionItemArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesAddOrRemoveCollectionItemRequestInput;
};

export type MutationAddSupplyPhotosArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  photos: Array<SupplyPhotosAddPhotoInput>;
  propertyContext: PropertyContext;
};

export type MutationAgencyDeleteQuoteArgs = {
  context: ContextInput;
  quoteDeleteInput: AgencyQuoteDeleteInput;
};

export type MutationAgencyQuoteBuilderAddArgs = {
  context: ContextInput;
  rateCard: AgencyQuoteBuilderRateCardInput;
};

export type MutationAgencyQuoteBuilderHandoffArgs = {
  context: ContextInput;
};

export type MutationAgencyQuoteBuilderRemoveArgs = {
  context: ContextInput;
  removeRateCards: AgencyQuoteBuilderRemoveInput;
};

export type MutationAgencySaveQuoteArgs = {
  context: ContextInput;
  quoteSaveInput: AgencyQuoteSaveInput;
};

export type MutationApplyCouponArgs = {
  context: ContextInput;
  coupons: Array<CouponIdentifierInput>;
  sessionId: Scalars["String"]["input"];
  sessionToken: Scalars["String"]["input"];
};

export type MutationApplyPaymentMethodArgs = {
  applyPaymentMethodRequest: ApplyPaymentMethodRequestInput;
  context?: InputMaybe<ContextInput>;
};

export type MutationApproveCancellationWaiverArgs = {
  approvalRequestContext: SupplyCancellationWaiverApprovalRequestContext;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationAskProductQuestionArgs = {
  context: ContextInput;
  email: Scalars["String"]["input"];
  productId: Scalars["String"]["input"];
  productQuestionTopic: ProductQuestionTopic;
  question: Scalars["String"]["input"];
  questioner?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAssignSupplyPhotosArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  photoIds: Array<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type MutationBatchUpdateInboxNotificationsByUserIdArgs = {
  context: ContextInput;
  expUserId: Scalars["String"]["input"];
  modifiableAttributes: CustomerNotificationModifiableAttributesInput;
};

export type MutationBookArgs = {
  checkoutSessionInfo: CheckoutSessionInfoInput;
  context: ContextInput;
  domainInfoList?: InputMaybe<Array<CheckoutDomainInfoInput>>;
};

export type MutationBookingServicingChangeLodgingDetailsArgs = {
  changeLodgingInput: BookingServicingChangeLodgingInput;
  context: ContextInput;
  travelerDetailsInput: BookingServicingTravelerDetailsInput;
};

export type MutationBookingServicingConfirmationArgs = {
  bookingServicingConfirmationInput: BookingServicingConfirmationInput;
  context: ContextInput;
  strategy: BookingServicingStrategyInput;
};

export type MutationBulkAddInventoryUpdateArgs = {
  addInventoryInput: BulkAddInventoryInput;
  propertyContext: PropertyContext;
};

export type MutationCancelConfirmationLoadedArgs = {
  context: ContextInput;
  criteriaInput: BookingServicingCancelCriteriaInput;
};

export type MutationCapturePaymentInfoArgs = {
  context: ContextInput;
  cpf?: InputMaybe<Scalars["String"]["input"]>;
  edgeTokenId?: InputMaybe<Scalars["String"]["input"]>;
  paymentAssistSessionId: Scalars["String"]["input"];
  paymentInstrumentId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCartItemsArgs = {
  context: ContextInput;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type MutationChangeNotificationStateArgs = {
  context: ContextInput;
  id: Scalars["ID"]["input"];
  state: Scalars["String"]["input"];
};

export type MutationChatGptUpdateTripArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext: ConversationContextInput;
  updateTripInput: ChatGptUpdateTripInput;
  variant?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCheckoutAvailabilityCheckArgs = {
  context: ContextInput;
  sessionID: Scalars["UUID"]["input"];
};

export type MutationCloseAffiliateToolbarArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type MutationConfirmAffiliateAccountLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type MutationConnectExternalMailboxArgs = {
  context: ContextInput;
  mailboxType: Scalars["String"]["input"];
  redirectUri: Scalars["String"]["input"];
};

export type MutationConsentMgmtPrefSubmitArgs = {
  context: ContextInput;
  request: SavePrefRequestInput;
};

export type MutationContactUsOptionsArgs = {
  context: ContextInput;
};

export type MutationContentLikeInteractionArgs = {
  context: ContextInput;
  request: ContentLikeInteractionRequestInput;
};

export type MutationContentShareInteractionArgs = {
  context: ContextInput;
  request: ContentShareInteractionRequestInput;
};

export type MutationCreateAffiliateCustomLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateCustomLinkRequestInput;
};

export type MutationCreateAffiliateLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateLinkRequestInput;
};

export type MutationCreateAffiliateTagArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateAffiliateTagRequestInput;
};

export type MutationCreateAffiliateVanityLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateVanityLinkRequestInput;
};

export type MutationCreateAffiliatesCollectionArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesCreateCollectionRequestInput;
};

export type MutationCreateMultiItemSessionArgs = {
  context: ContextInput;
  searchContext: MultiItemSearchContextInput;
};

export type MutationCreatePaymentTokenizationArgs = {
  context?: InputMaybe<ContextInput>;
  paymentTokenRequest: PaymentTokenRequestInput;
};

export type MutationCreatePriceTrackingArgs = {
  context: ContextInput;
  priceInsightsOperationData?: InputMaybe<PriceInsightsCreateMutationDataInput>;
  priceInsightsSearchContext: PriceInsightsSearchContextInput;
  priceShown?: InputMaybe<Scalars["String"]["input"]>;
  timeSeriesTierShown?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationCreateSessionCartArgs = {
  cartOptions: CartOptionsInput;
  context: ContextInput;
};

export type MutationCreateSupplyReservationsAccessInstructionsArgs = {
  accessIdentifier: SupplyReservationsAccessIdentifierInput;
  accessInstructions: SupplyReservationsAccessInstructionsInput;
  context: ContextInput;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type MutationCreateTripArgs = {
  context: ContextInput;
  description?: InputMaybe<Scalars["String"]["input"]>;
  itemId?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  operationType?: InputMaybe<Scalars["String"]["input"]>;
  tripContext?: InputMaybe<TripsUiTripContextInput>;
};

export type MutationCreateTripInviteArgs = {
  context: ContextInput;
  location?: InputMaybe<PageLocation>;
  tripId: Scalars["String"]["input"];
};

export type MutationCreateTripItineraryTemplateArgs = {
  context: ContextInput;
  preferences?: InputMaybe<TripsUiItineraryPreferencesInput>;
  tripId: Scalars["String"]["input"];
};

export type MutationCreditCardApplicationFormUpdateArgs = {
  context: ContextInput;
  input: CreditCardApplicationFormUpdateInput;
};

export type MutationCreditCardCheckoutSessionValidateArgs = {
  context: ContextInput;
  request: CreditCardCheckoutSessionValidateRequestInput;
};

export type MutationDeclineCancellationWaiverArgs = {
  context: ContextInput;
  declineRequestContext: CancellationWaiverDeclineRequestContext;
  propertyContext: PropertyContext;
};

export type MutationDeleteAffiliatesCollectionArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesDeleteCollectionRequestInput;
};

export type MutationDeleteDestinationFromWishlistArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type MutationDeleteNotificationArgs = {
  context: ContextInput;
  notificationId: Scalars["String"]["input"];
};

export type MutationDeleteNotificationByIdArgs = {
  context?: InputMaybe<ContextInput>;
  notificationId: Scalars["String"]["input"];
};

export type MutationDeleteOwnersAccountArgs = {
  context: ContextInput;
};

export type MutationDeleteSupplyPhotosArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  photoIds: Array<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type MutationDeleteSupplyReservationsAccessInstructionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationUuid: Scalars["UUID"]["input"];
};

export type MutationDeleteTripArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
};

export type MutationDirectFeedbackSubmitArgs = {
  context: ContextInput;
  feedback: DirectFeedbackValuesInput;
  id: Scalars["ID"]["input"];
};

export type MutationDisablePriceTrackingArgs = {
  context: ContextInput;
  notificationPreference?: InputMaybe<NotificationPreferenceType>;
  priceInsightsOperationData?: InputMaybe<PriceInsightsDisableMutationDataInput>;
  priceShown?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionId: Scalars["String"]["input"];
};

export type MutationDiscardAbandonedCheckoutArgs = {
  context: ContextInput;
};

export type MutationDisconnectExternalMailboxesArgs = {
  context: ContextInput;
  mailboxIds: Array<Scalars["String"]["input"]>;
};

export type MutationEditAffiliatesCollectionArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesEditCollectionRequestInput;
};

export type MutationEditAffiliatesCollectionItemArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesEditCollectionItemRequestInput;
};

export type MutationEditTripArgs = {
  context: ContextInput;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
};

export type MutationEditUniversalProfileArgs = {
  context: ContextInput;
  universalProfileClientInfo?: InputMaybe<UniversalProfileClientInfoInput>;
};

export type MutationEgLabsTripCollaborationCreateTripArgs = {
  context: ContextInput;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationFlightsAncillaryUpdateArgs = {
  context: ContextInput;
  detailAncillaryUpdateCriteria: FlightsDetailAncillaryUpdateCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type MutationFlightsFinancialAdjustmentArgs = {
  context: ContextInput;
  flightsFinancialAdjustmentInput: FlightsFinancialAdjustmentInput;
};

export type MutationFlightsMerchandisingBookArgs = {
  context: ContextInput;
  edgeToken?: InputMaybe<Scalars["String"]["input"]>;
  paymentSessionId?: InputMaybe<Scalars["String"]["input"]>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"]["input"];
};

export type MutationFlightsServicingCompleteBookingArgs = {
  context: ContextInput;
  edgeToken: Scalars["String"]["input"];
  flightsServicingCriteria: FlightsServicingCriteriaInput;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  paymentSessionId?: InputMaybe<Scalars["String"]["input"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type MutationGenerateAffiliatesVideoUploadUrlArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesGenerateVideoUploadUrlRequestInput;
};

export type MutationGoogleOneTapSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  submitRequest: IdentitySubmitRequestInput;
};

export type MutationHideAllSearchHistoriesArgs = {
  context: ContextInput;
};

export type MutationHideSearchHistoryArgs = {
  context: ContextInput;
  historyTypes?: InputMaybe<Array<HistoryType>>;
  interactionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationIdentityAddPhoneResendOtpSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  request: IdentityAddPhoneResendOtpSubmitRequestInput;
};

export type MutationIdentityAddPhoneSendOtpSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  request: IdentityAddPhoneSendOtpSubmitRequestInput;
};

export type MutationIdentityAddPhoneVerifyOtpSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  request: IdentityAddPhoneVerifyOtpSubmitRequestInput;
};

export type MutationIdentityCreateAccountArgs = {
  context: ContextInput;
  createAccountRequest: IdentityCreateAccountRequestInput;
};

export type MutationIdentityFormSubmitArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  submitRequest: IdentitySubmitRequestInput;
};

export type MutationIdentityLogoutArgs = {
  context: ContextInput;
};

export type MutationIdentityMarkJourneyCompleteArgs = {
  accountMergeContext?: InputMaybe<AccountMergeRequestContextInput>;
  context: ContextInput;
};

export type MutationIdentityResendOtpAuthenticateSubmitArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityResendOtpAuthenticationRequestInput;
};

export type MutationIdentitySendOtpAuthenticateSubmitArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentitySendOtpAuthenticationRequestInput;
};

export type MutationIdentityVerifyOtpAuthenticationSubmitArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityVerifyOtpAuthenticationRequestInput;
};

export type MutationIdentityVerifyPasswordAuthenticateSubmitArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityVerifyPasswordPAuthenticationRequestInput;
};

export type MutationInitiateAddOrRemoveCollectionItemValidationArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesValidateTextFieldRequestInput;
};

export type MutationInsuranceBookingCancellationArgs = {
  context: ContextInput;
  insuranceBookingCancellationRequest: InsuranceBookingCancellationRequestInput;
};

export type MutationInsurtechCompleteBookingArgs = {
  context: ContextInput;
  insurtechBookingRequest: InsurtechBookingRequestInput;
  insurtechMetadata?: InputMaybe<InsurtechMetadataInput>;
};

export type MutationInsurtechUpdateProductSelectionArgs = {
  context?: InputMaybe<ContextInput>;
  insuranceContinuationToken?: InputMaybe<Scalars["String"]["input"]>;
  insurtechUpdateProductSelectionRequest: InsurtechUpdateProductSelectionRequestInput;
};

export type MutationInteractionsArgs = {
  action: SupplyReservationsInteractionListItemAction;
  context: ContextInput;
  interactionIds: Array<Scalars["String"]["input"]>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type MutationKnowYourCustomerSubmitArgs = {
  context: ContextInput;
};

export type MutationLodgingCancelArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  formInput: LodgingCancelMutationInput;
  orderNumber: Scalars["String"]["input"];
};

export type MutationLodgingChangeArgs = {
  bookingItemId: Scalars["String"]["input"];
  changeAction: Scalars["String"]["input"];
  context: ContextInput;
  formInput: LodgingChangeMutationInput;
  orderNumber: Scalars["String"]["input"];
};

export type MutationLodgingChangeCheckoutArgs = {
  bookingItemId: Scalars["String"]["input"];
  changeAction: AgentToolsLodgingActionInput;
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
  penaltyWaiver?: InputMaybe<AgentToolsPenaltyWaiverInput>;
};

export type MutationLodgingChangeResumeOrderArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type MutationLodgingRefundArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  formInput: RefundFormMutationInput;
  orderNumber: Scalars["String"]["input"];
  step: Scalars["Int"]["input"];
};

export type MutationLogoutArgs = {
  context: ContextInput;
  flavor: FlavorType;
  idToken: Scalars["String"]["input"];
  partnerId: Scalars["String"]["input"];
  roleId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type MutationManageSocialConnectionsArgs = {
  clientContext: SocialConnectionsClientContextInput;
  context: ContextInput;
  request: SocialConnectionsManageConnectionRequestInput;
};

export type MutationMarkAnswerHelpfulArgs = {
  answerId: Scalars["String"]["input"];
  questionId: Scalars["String"]["input"];
};

export type MutationMarkTripsCoachmarkAsSeenArgs = {
  context: ContextInput;
  pageName: Scalars["String"]["input"];
};

export type MutationMergeMultiItemAnalyticsArgs = {
  context: ContextInput;
  messageType: Scalars["String"]["input"];
  traceIds: Array<Scalars["String"]["input"]>;
};

export type MutationMultiItemShoppingArgs = {
  context: ContextInput;
};

export type MutationOneKeyCashBurnToggleArgs = {
  checkoutSessionId: Scalars["String"]["input"];
  context: ContextInput;
};

export type MutationOneKeyOnboardingOperationArgs = {
  additionalContext?: InputMaybe<AdditionalOnboardingOperationContextInput>;
  context: ContextInput;
  onboardingOperation: Scalars["String"]["input"];
};

export type MutationOneKeyUniversalOnboardingOperationArgs = {
  context: ContextInput;
  onboardingOperation: Scalars["String"]["input"];
};

export type MutationPreloadPropertySearchArgs = {
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type MutationPrepareChangeCheckoutArgs = {
  context: ContextInput;
  lodgingInput?: InputMaybe<LodgingPrepareChangeCheckoutInput>;
  options?: InputMaybe<Array<PrepareChangeCheckoutOptionInput>>;
  tripId: Scalars["String"]["input"];
};

export type MutationPrepareCheckoutArgs = {
  activities?: InputMaybe<Array<ActivityNaturalKeyInput>>;
  cars?: InputMaybe<Array<CarNaturalKeyInput>>;
  checkoutOptions?: InputMaybe<Array<CheckoutOptionInput>>;
  context: ContextInput;
  cookies?: InputMaybe<Scalars["String"]["input"]>;
  flights?: InputMaybe<Array<FlightNaturalKeyInput>>;
  groundTransfers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  offerTokens?: InputMaybe<Array<OfferTokenInput>>;
  packages?: InputMaybe<Array<PackageNaturalKeyInput>>;
  properties?: InputMaybe<Array<PropertyNaturalKeyInput>>;
  responseOptions?: InputMaybe<Array<PrepareCheckoutResponseOption>>;
  totalPrice?: InputMaybe<MoneyInput>;
};

export type MutationPrepareCheckoutByCartIdArgs = {
  cartId: Scalars["UUID"]["input"];
  checkoutOptions?: InputMaybe<Array<CheckoutOptionInput>>;
  context: ContextInput;
  marketingChannelId?: InputMaybe<Scalars["Int"]["input"]>;
  totalPrice?: InputMaybe<MoneyInput>;
};

export type MutationPrepareCheckoutBySessionIdArgs = {
  checkoutOptions?: InputMaybe<Array<CheckoutOptionInput>>;
  context: ContextInput;
  cookies?: InputMaybe<Scalars["String"]["input"]>;
  multiItemSessionId: Scalars["String"]["input"];
};

export type MutationPriceMatchPromiseCheckPriceArgs = {
  context: ContextInput;
  tripContextToken?: InputMaybe<Scalars["String"]["input"]>;
  tripNumber: Scalars["String"]["input"];
};

export type MutationPublishCampaignArgs = {
  campaignRequestInput: CampaignRequestInput;
  productContext: ProductContext;
};

export type MutationPublishInteractionEventArgs = {
  context: ContextInput;
  errorToken?: InputMaybe<Scalars["String"]["input"]>;
  eventName: InteractionEventName;
  id?: InputMaybe<Scalars["String"]["input"]>;
  sessionId?: InputMaybe<Scalars["UUID"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationRejectReviewArgs = {
  brandType: Scalars["String"]["input"];
  context: ContextInput;
  flagCode: Array<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
};

export type MutationRemoveCouponArgs = {
  checkoutURL?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  coupons: Array<CouponIdentifierInput>;
  sessionId: Scalars["String"]["input"];
  sessionToken: Scalars["String"]["input"];
};

export type MutationRemoveInsurtechOfferFromTripArgs = {
  context: ContextInput;
  insurtechRemoveTripRequest: InsurtechRemoveTripRequestInput;
};

export type MutationRemovePaymentMethodArgs = {
  context?: InputMaybe<ContextInput>;
  removePaymentMethodRequest: RemovePaymentMethodRequestInput;
};

export type MutationRemoveShortlistItemArgs = {
  context: ContextInput;
  pageName?: InputMaybe<Scalars["String"]["input"]>;
  productId: Scalars["String"]["input"];
  productType: ProductType;
};

export type MutationRemoveTripItemArgs = {
  context: ContextInput;
  itemId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type MutationSaveAnswersArgs = {
  context: ContextInput;
  request: SaveReviewAnswersRequestInput;
};

export type MutationSaveBrandResultListingCreativeArgs = {
  request: SaveBrandResultListingCreativeInput;
};

export type MutationSaveExternalItemsToTripArgs = {
  context: ContextInput;
  itemIdsToAdd?: InputMaybe<Array<Scalars["String"]["input"]>>;
  itemIdsToRemove?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripId: Scalars["String"]["input"];
};

export type MutationSaveItineraryAsTripArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"]["input"];
};

export type MutationSavePropertyCancelPolicyExceptionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  selectedWaivers: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationSavePublicProfileSettingsArgs = {
  clientContext: SocialConnectionsClientContextInput;
  context: ContextInput;
  request: SocialConnectionsSaveProfileSettingsRequestInput;
};

export type MutationSaveReservationModifiersCancelArgs = {
  cancellationFee: Scalars["String"]["input"];
  cancellationReason: Scalars["String"]["input"];
  context: ContextInput;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  messageToGuest?: InputMaybe<Scalars["String"]["input"]>;
  originalBookedDates: Scalars["String"]["input"];
  originalBookingAmount: Scalars["String"]["input"];
  originalBookingCurrency: Scalars["String"]["input"];
  paymentMethod: Scalars["String"]["input"];
  previewChanges: Scalars["Boolean"]["input"];
  propertyContext: PropertyContext;
  remittanceType?: InputMaybe<LodgingSupplierRemittanceType>;
  reservationId: Scalars["String"]["input"];
  reservationModifiersVRCancelRefundLevel?: InputMaybe<ReservationModifiersVrCancelRefundLevel>;
  travelerName: Scalars["String"]["input"];
};

export type MutationSaveReservationModifiersChangeDatesArgs = {
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]["input"]>;
  newCheckInDate: Scalars["String"]["input"];
  newCheckOutDate: Scalars["String"]["input"];
  originalBookedDates: Scalars["String"]["input"];
  originalBookingAmount: Scalars["String"]["input"];
  originalBookingCurrency: Scalars["String"]["input"];
  paymentMethod: Scalars["String"]["input"];
  previewChanges: Scalars["Boolean"]["input"];
  propertyContext: PropertyContext;
  reasonForChange: Scalars["String"]["input"];
  remittanceType?: InputMaybe<LodgingSupplierRemittanceType>;
  reservationId: Scalars["String"]["input"];
  travelerName: Scalars["String"]["input"];
};

export type MutationSaveReservationModifiersGuestSatisfactionRefundArgs = {
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]["input"]>;
  originalBookedDates: Scalars["String"]["input"];
  originalBookingAmount: Scalars["String"]["input"];
  originalBookingCurrency: Scalars["String"]["input"];
  paymentMethod: Scalars["String"]["input"];
  previewChanges: Scalars["Boolean"]["input"];
  propertyContext: PropertyContext;
  reasonForRefund: Scalars["String"]["input"];
  refundAmount: Scalars["String"]["input"];
  remittanceType?: InputMaybe<LodgingSupplierRemittanceType>;
  reservationId: Scalars["String"]["input"];
  travelerName: Scalars["String"]["input"];
};

export type MutationSaveReservationModifiersNoShowArgs = {
  cancellationFee: Scalars["String"]["input"];
  context: ContextInput;
  messageToGuest?: InputMaybe<Scalars["String"]["input"]>;
  originalBookedDates: Scalars["String"]["input"];
  originalBookingAmount: Scalars["String"]["input"];
  originalBookingCurrency: Scalars["String"]["input"];
  paymentMethod: Scalars["String"]["input"];
  previewChanges: Scalars["Boolean"]["input"];
  propertyContext: PropertyContext;
  remittanceType?: InputMaybe<LodgingSupplierRemittanceType>;
  reservationId: Scalars["String"]["input"];
  travelerName: Scalars["String"]["input"];
};

export type MutationSaveReservationModifiersVrCancelArgs = {
  cancellationReason: Scalars["String"]["input"];
  context: ContextInput;
  messageToGuest: Scalars["String"]["input"];
  previewChanges: Scalars["Boolean"]["input"];
  propertyContext: PropertyContext;
  remittanceType?: InputMaybe<LodgingSupplierRemittanceType>;
  reservationId: Scalars["String"]["input"];
  reservationModifiersVRCancelRefundLevel: ReservationModifiersVrCancelRefundLevel;
};

export type MutationSaveShortlistItemArgs = {
  context: ContextInput;
  metadata?: InputMaybe<Array<ShortlistMetadataInput>>;
  pageName?: InputMaybe<Scalars["String"]["input"]>;
  productId: Scalars["String"]["input"];
  productType: ProductType;
};

export type MutationSaveSupplyRatePlanArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  ratePlanInput: SupplyRatePlanInput;
};

export type MutationSaveSupplyRegulatorySettingsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  regulatoryType?: InputMaybe<Scalars["String"]["input"]>;
  settings: Array<SupplyRegulatorySettingInput>;
};

export type MutationSaveTripItemCommentArgs = {
  comment: Scalars["String"]["input"];
  commentId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  itemId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type MutationSaveTripItemsArgs = {
  context: ContextInput;
  criteria: TripsUiSaveTripItemsCriteriaInput;
  pageLocation?: InputMaybe<PageLocation>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSaveVideoInteractionArgs = {
  context: ContextInput;
  request: VideoExperienceSaveVideoInteractionRequestInput;
};

export type MutationSearchLocationDeleteRecentSearchArgs = {
  context: ContextInput;
  gaiaIds: Array<Scalars["String"]["input"]>;
  lob: Scalars["String"]["input"];
};

export type MutationSelectPaymentMethodArgs = {
  context?: InputMaybe<ContextInput>;
  selectPaymentMethodRequest: SelectPaymentMethodRequestInput;
};

export type MutationSendCommunicationCenterMessageArgs = {
  attachments?: InputMaybe<MessageAttachmentsInput>;
  context: ContextInput;
  conversationId: Scalars["String"]["input"];
  messageText: Scalars["String"]["input"];
};

export type MutationSendConversationEventArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext: ConversationContextInput;
  event: VirtualAgentControlConversationEventInput;
};

export type MutationSendMessageArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext: ConversationContextInput;
  messageInput: VirtualAgentControlMessageInput;
};

export type MutationSendPaymentAssistArgs = {
  channelType: AgentToolsChannelType;
  contactInformation?: InputMaybe<AgentToolsTravellerContactInformationInput>;
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
  webviewUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendPaymentVerificationArgs = {
  channelType: AgentToolsChannelType;
  contactInformation?: InputMaybe<AgentToolsTravellerContactInformationInput>;
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSendTextToDownloadAppArgs = {
  context: ContextInput;
  customerInput: NotificationUserInput;
  placementId: Scalars["String"]["input"];
};

export type MutationSendTripInviteArgs = {
  context: ContextInput;
  message: Scalars["String"]["input"];
  recipients: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type MutationSetFlightPriceAlertOptInArgs = {
  context: ContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  lowestPrice: Scalars["String"]["input"];
  optInStatus: Scalars["Boolean"]["input"];
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  travelerDetails?: InputMaybe<Array<FlightsTravelerDetailsInput>>;
};

export type MutationSetMinNightStayArgs = {
  context: ContextInput;
  minNightStayValue: Scalars["Int"]["input"];
  propertyContext: PropertyContext;
};

export type MutationSmartFormArgs = {
  context: ContextInput;
  sessionId: Scalars["String"]["input"];
  sessionToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSubmitAffiliatesVideoArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesSubmitVideoRequestInput;
};

export type MutationSubmitInquiryArgs = {
  context: ContextInput;
  inquiryRequest?: InputMaybe<InquiryRequestInput>;
};

export type MutationSupplyBookingOptionsUpdateArgs = {
  bookingOptionsInput: BookingOptionsInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyCoachingTipPostponeArgs = {
  context: ContextInput;
  dismiss?: InputMaybe<Scalars["Boolean"]["input"]>;
  duration?: InputMaybe<Scalars["String"]["input"]>;
  postponeContext: Array<SupplyQuickActionFormContextInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyCoachingTipQuickActionSubmitArgs = {
  actionInputs?: InputMaybe<Array<SupplyQuickActionFormContextInput>>;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateAdditionalInfoArgs = {
  context: ContextInput;
  params: SupplyContentAdditionalInfoUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateHeadlineDescriptionArgs = {
  context: ContextInput;
  params?: InputMaybe<SupplyContentHeadlineDescriptionUpdateInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateLocationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  updateLocationInput: SupplyContentUpdateLocationInput;
};

export type MutationSupplyContentUpdateManualTranslationArgs = {
  context: ContextInput;
  params: SupplyContentManualTranslationUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateYourPropertyArgs = {
  context: ContextInput;
  params: SupplyContentYourPropertyUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContentUpdateYourStoryArgs = {
  context: ContextInput;
  params: SupplyContentYourStoryUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyContractingOffersAcceptanceArgs = {
  context: ContextInput;
  offerIds: Array<Scalars["String"]["input"]>;
  program?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type MutationSupplyFeesUpdateArgs = {
  context: ContextInput;
  feesInput: Array<SupplyFeesInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyIndirectTaxNavigateBackArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyIndirectTaxUpdateArgs = {
  context: ContextInput;
  indirectTaxInput: SupplyIndirectTaxInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyLoyaltyArgs = {
  context: ContextInput;
};

export type MutationSupplyMarketplaceFeedItemUpdateArgs = {
  context: ContextInput;
  input: SupplyMarketplaceFeedItemInput;
};

export type MutationSupplyMarketplaceFeedUpdateArgs = {
  actionInputs: SupplyMarketplaceFeedInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyMultiFactorCodeGenerationArgs = {
  context: ContextInput;
  mfaCodeGenerationInput: SupplyMultiFactorCodeGenerationInput;
};

export type MutationSupplyMultiFactorCodeVerificationArgs = {
  context: ContextInput;
  mfaCodeVerificationInput: SupplyMultiFactorCodeVerificationInput;
};

export type MutationSupplyNavigationNotificationActivityArgs = {
  context: ContextInput;
  input: SupplyNavigationNotificationActivityInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingAvailabilityUpdateArgs = {
  availabilityType?: InputMaybe<SupplyOnboardingAvailabilityType>;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingPublishListingArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  trustPayload?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationSupplyOnboardingWelcomeNavigateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyOnboardingWorkFlowNavigateArgs = {
  context: ContextInput;
  currentStep: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  trustPayload?: InputMaybe<Scalars["String"]["input"]>;
  type: SupplyOnboardingButtonType;
};

export type MutationSupplyProductCreationBaseRateManagementArgs = {
  context: ContextInput;
  params: SupplyProductCreationBaseRateManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationBreakfastRatePlanManagementArgs = {
  context: ContextInput;
  params: SupplyProductCreationIndependentRateManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationChildRatePlanManagementArgs = {
  context: ContextInput;
  params: SupplyProductCreationChildRateManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationDeleteRatePlansArgs = {
  context: ContextInput;
  params: SupplyProductCreationDeleteRatePlansInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationDeleteRoomArgs = {
  context: ContextInput;
  input: SupplyProductCreationDeleteRoomInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationPricingModelUpdateArgs = {
  context: ContextInput;
  pricingType: PricingType;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomInfoManagementArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomInfoManagementInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomNameManagementArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameAttributesInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomNameUpdateArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProductCreationRoomOverviewUpdateStepStatusArgs = {
  context: ContextInput;
  input: SupplyProductCreationUpdateStepStatusInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyProgramsOffersAcceptanceArgs = {
  acceptanceInput: SupplyProgramsOffersAcceptanceInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyPromotionCreateTieredMembersOnlyDealsArgs = {
  context: ContextInput;
  membersOnlyDealsTiersInput: SupplyPromotionCreateMembersOnlyDealsTiersInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyPromotionUpdateStackingOptionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  stackingOptions: Array<StackingOptionInput>;
};

export type MutationSupplyPromotionUpdateTieredMembersOnlyDealArgs = {
  context: ContextInput;
  membersOnlyDealsTiersInput: SupplyPromotionUpdateMembersOnlyDealsTiersInput;
  promotionId: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type MutationSupplyReservationsReactivatePropertyArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyReservationsUpdateBookingRequestArgs = {
  acceptPaymentType?: InputMaybe<SupplyReservationsPayment>;
  context: ContextInput;
  declineOption?: InputMaybe<SupplyReservationsDeclineOption>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  reasonCode: Scalars["String"]["input"];
  reservationContext: ReservationContext;
};

export type MutationSupplyRoomTypeMappingPropertyValidateArgs = {
  actionContext: SupplyRoomTypeMappingPropertyValidationInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyRoomTypeMappingSubmitArgs = {
  actionContext: SupplyRoomTypeMappingActionContext;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyTaxesUpdateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  taxesInput?: InputMaybe<SupplyTaxesInput>;
};

export type MutationSupplyUpdateNewListingOfferEnrollmentArgs = {
  context: ContextInput;
  input?: InputMaybe<SupplyNewListingOfferEnrollmentInput>;
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePartnerAttestationArgs = {
  attestationInput: SupplyOnboardingPartnerAttestationInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePartnerBillingAddressArgs = {
  billingAddressInput: SupplyOnboardingPartnerBillingAddressInput;
  context: ContextInput;
};

export type MutationSupplyUpdatePartnerBusinessNameArgs = {
  businessNameInput: SupplyOnboardingPartnerBusinessNameInput;
  context: ContextInput;
};

export type MutationSupplyUpdatePartnerHostTypeArgs = {
  context: ContextInput;
  hostType: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePartnerLegalNameArgs = {
  context: ContextInput;
  legalNameInput: SupplyOnboardingPartnerLegalNameInput;
};

export type MutationSupplyUpdatePartnerPayoutDeferredAccountArgs = {
  context: ContextInput;
  input: SupplyPartnerPayoutDeferredAccountInput;
  propertyContext: PropertyContext;
};

export type MutationSupplyUpdatePropertyRatesArgs = {
  context: ContextInput;
  input: SupplyPropertyRateInput;
  propertyContext: PropertyContext;
};

export type MutationSweepstakesDisenrollArgs = {
  context: ContextInput;
};

export type MutationSweepstakesUpdateArgs = {
  context: ContextInput;
  updateAction: UpdateAction;
};

export type MutationSweepstakesUserWipeArgs = {
  apiKey: Scalars["String"]["input"];
  campaignId: Scalars["String"]["input"];
};

export type MutationTextToDownloadAppArgs = {
  context: ContextInput;
  customerInput: CustomerNotificationUserInput;
  placementId: Scalars["String"]["input"];
};

export type MutationTopTaskDismissArgs = {
  actionInputs: TopTaskCardAction;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationTrackSponsoredListingBeaconClickArgs = {
  context: ContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type MutationTripArgs = {
  context: ContextInput;
};

export type MutationTripCollaborationChatAddParticipantsArgs = {
  addParticipantsToConversationInput: AddParticipantsToConversationInput;
  context: ContextInput;
  conversationContext: ConversationContextInput;
};

export type MutationTripsRefreshItineraryItemArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"]["input"];
  itineraryItemId: Scalars["String"]["input"];
};

export type MutationTripsRemoveItineraryItemArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"]["input"];
  itineraryItemId: Scalars["String"]["input"];
};

export type MutationTriviaStartArgs = {
  context: ContextInput;
  request: TriviaStartRequestInput;
};

export type MutationTriviaSubmitArgs = {
  context: ContextInput;
  request: TriviaSubmitRequestInput;
};

export type MutationUnassignSupplyPhotosArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  photoIds: Array<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type MutationUnhideAllSearchHistoriesArgs = {
  context: ContextInput;
};

export type MutationUnhideSearchHistoryArgs = {
  context: ContextInput;
  historyTypes?: InputMaybe<Array<HistoryType>>;
  interactionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateAdGroupCreativesArgs = {
  input: UpdateAdGroupCreativesInput;
};

export type MutationUpdateAffiliatesCollectionVisibilityArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesUpdateCollectionVisibilityRequestInput;
};

export type MutationUpdateAgentPresenceArgs = {
  context: ContextInput;
  flavor: FlavorType;
  partnerId: Scalars["String"]["input"];
  roleId: Scalars["String"]["input"];
  updatePresenceTo: AgentStateInput;
  userId: Scalars["String"]["input"];
};

export type MutationUpdateAndBookArgs = {
  checkoutSessionInfo: CheckoutSessionInfoInput;
  checkoutUrl: Scalars["String"]["input"];
  context: ContextInput;
  domainInfoList?: InputMaybe<Array<CheckoutDomainInfoInput>>;
  paymentMethodConfig: PaymentMethodConfigInput;
};

export type MutationUpdateApprovalStatusArgs = {
  input: UpdateApprovalStatusInput;
};

export type MutationUpdateConnectivitySettingsProviderServicesArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  providerServiceUpdates: UpdateProviderServicesInput;
  workflow?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateConversationStateArgs = {
  author?: InputMaybe<Scalars["String"]["input"]>;
  authorChannel?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  conversationId: Scalars["String"]["input"];
  sentDateTime?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateInboxArgs = {
  context?: InputMaybe<ContextInput>;
  modifiableAttributes: Array<NotificationSpecificationsInput>;
};

export type MutationUpdateInboxNotificationArgs = {
  modifiableAttributes: Array<CustomerNotificationModifiableAttributesInput>;
};

export type MutationUpdateInboxStateArgs = {
  context?: InputMaybe<ContextInput>;
  notificationIds: Array<Scalars["String"]["input"]>;
  state: NotificationState;
};

export type MutationUpdateInsurtechOfferArgs = {
  context: ContextInput;
  updateInsurtechOfferRequest: UpdateInsurtechOfferRequestInput;
};

export type MutationUpdateInteractionsArgs = {
  action: SupplyReservationsInteractionListItemAction;
  context: ContextInput;
  interactionIds: Array<Scalars["String"]["input"]>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type MutationUpdateMessageStateArgs = {
  messageId: Scalars["String"]["input"];
  state: CommunicationCenterMessagePreviewState;
};

export type MutationUpdateNewListingOfferEnrollmentArgs = {
  context: ContextInput;
  input?: InputMaybe<SupplyNewListingOfferEnrollmentInput>;
  propertyContext: PropertyContext;
};

export type MutationUpdateNotificationsStateArgs = {
  context: ContextInput;
  notificationIds: Array<Scalars["String"]["input"]>;
  state: CommunicationCenterNotificationState;
};

export type MutationUpdatePartnerPayoutDeferredAccountArgs = {
  context: ContextInput;
  input: SupplyPartnerPayoutDeferredAccountInput;
  propertyContext: PropertyContext;
};

export type MutationUpdatePaymentAssistSessionCardTypeArgs = {
  cardType: PaymentAssistCardTypeInput;
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
  verificationRequired: Scalars["Boolean"]["input"];
};

export type MutationUpdatePaymentAssistSessionStatusArgs = {
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
  status: Scalars["String"]["input"];
};

export type MutationUpdatePaymentMethodArgs = {
  context?: InputMaybe<ContextInput>;
  updatePaymentMethodRequest: UpdatePaymentMethodMutationRequestInput;
};

export type MutationUpdatePriceTrackingArgs = {
  context: ContextInput;
  priceInsightsOperationData?: InputMaybe<PriceInsightsUpdateMutationDataInput>;
  priceInsightsSearchContext?: InputMaybe<PriceInsightsSearchContextInput>;
  priceShown?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionId: Scalars["String"]["input"];
};

export type MutationUpdatePropertyRatesArgs = {
  context: ContextInput;
  input: SupplyPropertyRateInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyAmenitiesArgs = {
  context: ContextInput;
  input: SupplyAmenitiesUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyAttributesArgs = {
  context: ContextInput;
  input: SupplyAttributesUpdateInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyDismissibleCardArgs = {
  context: ContextInput;
  input: SupplyDismissibleCardInput;
  propertyContext: PropertyContext;
};

export type MutationUpdateSupplyReservationsAccessInstructionsArgs = {
  accessIdentifier: SupplyReservationsAccessIdentifierInput;
  accessInstructions: SupplyReservationsAccessInstructionsInput;
  context: ContextInput;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
  reservationUuid: Scalars["UUID"]["input"];
};

export type MutationUpdateSupplyReservationsPartnerNoteArgs = {
  context: ContextInput;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  note: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  reservationId: Scalars["String"]["input"];
};

export type MutationUpdateSupplySelectedWaiversArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  selectedWaivers: Array<InputMaybe<Scalars["Int"]["input"]>>;
};

export type MutationUpdateTripItemDatesArgs = {
  context: ContextInput;
  dateRange: DateRangeInput;
  itemId?: InputMaybe<Scalars["String"]["input"]>;
  itemIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripId: Scalars["String"]["input"];
};

export type MutationUpdateTripItemPriceAlertStatusArgs = {
  alertEnabled: Scalars["Boolean"]["input"];
  context: ContextInput;
  itemId: Scalars["String"]["input"];
  subscriptionId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type MutationUpdateTripItemTripArgs = {
  context: ContextInput;
  itemId: Scalars["String"]["input"];
  operationType: Scalars["String"]["input"];
  pageLocation?: InputMaybe<PageLocation>;
  tripContext: TripsUiTripContextInput;
};

export type MutationUpdateTripItemVoteArgs = {
  context: ContextInput;
  itemId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type MutationUpdateUserStateAndSendMessageArgs = {
  context: ContextInput;
};

export type MutationUpsertAcceleratorsArgs = {
  configurations: AcceleratorsUpsertInput;
  context: ContextInput;
};

export type MutationUpvotePropertyReviewArgs = {
  id: Scalars["String"]["input"];
};

export type MutationUpvotePropertyReviewWithAccessibilityArgs = {
  context: ContextInput;
  index: Scalars["Int"]["input"];
  reviewId: Scalars["String"]["input"];
};

export type MutationUserDismissTaskArgs = {
  actionInputs: TopTaskAction;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type MutationUserProfileWizardSubmitArgs = {
  collectedActionContexts: Array<Scalars["String"]["input"]>;
  context: ContextInput;
  wizardContext: ProfileWizardContextInput;
};

export type MutationUserSettingFormSubmitArgs = {
  actionContext: Scalars["String"]["input"];
  context: ContextInput;
  inputs: Array<GraphQlPairInput>;
};

export type MutationVirtualAgentControlCreateConversationArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationInitializationConfigs: ConversationInitializationConfigsInput;
};

export type MutationVirtualAgentControlSubmitSurveyArgs = {
  context: ContextInput;
  surveyContext: VirtualAgentControlSubmitSurveyInput;
};

export type MutationVirtualAgentFlightCheckoutArgs = {
  context: ContextInput;
  flightsDetailsCriteria: FlightsDetailCriteriaInput;
  virtualAgentContext: VirtualAgentContextInput;
};

export type NameCard = {
  __typename?: "NameCard";
  nameTextInput?: Maybe<EgdsInputField>;
};

export type NavTab = {
  __typename?: "NavTab";
  analytics: CarAnalytics;
  name: Scalars["String"]["output"];
};

export type NaverPayPaymentFop = IPaymentFop & {
  __typename?: "NaverPayPaymentFop";
  id: Scalars["String"]["output"];
  paymentAmount?: Maybe<PaymentPriceDetails>;
  paymentMethod: PaymentMethodType;
  paymentMethodAttribute?: Maybe<PaymentMethodAttribute>;
  paymentSubMethods?: Maybe<Array<Scalars["String"]["output"]>>;
  rank?: Maybe<Scalars["Int"]["output"]>;
  splittable?: Maybe<Scalars["Boolean"]["output"]>;
  state: PaymentState;
};

export type NaverPayPaymentMethodAttribute = {
  __typename?: "NaverPayPaymentMethodAttribute";
  flowType?: Maybe<Scalars["String"]["output"]>;
  kcpRedirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type NavigationCookie = {
  __typename?: "NavigationCookie";
  expires: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type NavigationTab = {
  __typename?: "NavigationTab";
  /** @deprecated Use clickAction instead */
  analytics?: Maybe<ActivityAnalytics>;
  clickAction?: Maybe<ActivityClickAction>;
  label: Scalars["String"]["output"];
  /** @deprecated Use clickAction instead */
  sectionRef: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type NavigationTabs = {
  __typename?: "NavigationTabs";
  tabs: Array<PropertyNavigationBarTab>;
};

export type NavigationType = "COLLAPSE" | "EXPAND";

export type NearbyAirportOption = {
  __typename?: "NearbyAirportOption";
  action?: Maybe<UiLinkAction>;
  badge?: Maybe<EgdsBadge>;
  button?: Maybe<EgdsButton>;
  multiItemShoppingAction?: Maybe<MultiItemShoppingAction>;
  primary: EgdsHeading;
  secondaries: Array<EgdsStylizedText>;
  tertiaries: Array<EgdsStylizedText>;
};

export type NearbyAirportSection = {
  __typename?: "NearbyAirportSection";
  airports: Array<NearbyAirportOption>;
  title: Scalars["String"]["output"];
};

export type NearbyAirportSelection = {
  __typename?: "NearbyAirportSelection";
  sections: Array<NearbyAirportSection>;
  sheet: EgdsSheet;
  title: Scalars["String"]["output"];
};

export type NearbyAirportsSuggestion = {
  __typename?: "NearbyAirportsSuggestion";
  card?: Maybe<EgdsStandardMessagingCard>;
  content: PackageUiNearbyAirports;
  expando: EgdsExpandoCard;
  sheet?: Maybe<NearbyAirportSelection>;
};

export type Neighborhood = {
  __typename?: "Neighborhood";
  name: Scalars["String"]["output"];
  regionId: Scalars["String"]["output"];
};

export type NestedListingContainer = {
  __typename?: "NestedListingContainer";
  heading?: Maybe<ShoppingListingContainerHeading>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<NestedListingContainerItems>;
  layout: NestedListingContainerLayout;
};

export type NestedListingContainerItems = ShoppingListingContainer | ShoppingRangeIndicatorCard;

export type NestedListingContainerLayout = EgdsBasicTabs | ShoppingListingExpandoCardContainer;

export type NightlyRate = {
  __typename?: "NightlyRate";
  available: Scalars["Boolean"]["output"];
  date?: Maybe<DateTime>;
  displayPrice?: Maybe<Money>;
  strikeOutPrice?: Maybe<Money>;
};

export type NoCancelFeeType = "NO_CANCEL_FEE";

export type NoChangeFeeType = "NO_CHANGE_FEE";

export type NoMatchesFoundComponent = {
  __typename?: "NoMatchesFoundComponent";
  analytics: ClientSideAnalytics;
  filterPills?: Maybe<Array<ShoppingAppliedFilter>>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<Scalars["String"]["output"]>;
  removeAllFiltersAction?: Maybe<ActivityLinkClickAction>;
  removeAllFiltersButton?: Maybe<EgdsButton>;
  title: Scalars["String"]["output"];
};

export type NoMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "NoMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type NoResultsMessage = UiEmptyState | UiMessagingCard;

export type NorthstarFooterImage = {
  __typename?: "NorthstarFooterImage";
  altText?: Maybe<Scalars["String"]["output"]>;
  imgSrc?: Maybe<Scalars["URL"]["output"]>;
  newWindowText?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type NorthstarFooterSection = {
  __typename?: "NorthstarFooterSection";
  data?: Maybe<Array<NorthstarFooterSectionLink>>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type NorthstarFooterSectionImages = FooterSectionData & {
  __typename?: "NorthstarFooterSectionImages";
  data: Array<Maybe<NorthstarFooterImage>>;
};

export type NorthstarFooterSectionLink = {
  __typename?: "NorthstarFooterSectionLink";
  target?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  trackingEvent?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type NorthstarFooterSectionLinks = FooterSectionData & {
  __typename?: "NorthstarFooterSectionLinks";
  data: Array<Maybe<FooterData>>;
  minimal?: Maybe<Scalars["Boolean"]["output"]>;
};

export type NorthstarFooterSectionText = FooterSectionData & {
  __typename?: "NorthstarFooterSectionText";
  data: Array<Maybe<NorthstarFooterText>>;
  minimal?: Maybe<Scalars["Boolean"]["output"]>;
};

export type NorthstarFooterText = {
  __typename?: "NorthstarFooterText";
  text?: Maybe<Scalars["String"]["output"]>;
};

export type NorthstarMinimalFooterSectionLinks = FooterSectionData & {
  __typename?: "NorthstarMinimalFooterSectionLinks";
  data: Array<Maybe<FooterData>>;
};

export interface NotificationAccessibilityField {
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
}

export type NotificationAction =
  | NotificationAnalytics
  | NotificationAnalyticsEgClickstream
  | NotificationCookie
  | NotificationMarketing
  | NotificationSchema
  | NotificationSpecifications;

export type NotificationAnalytics = {
  __typename?: "NotificationAnalytics";
  description?: Maybe<Scalars["String"]["output"]>;
  referrerId: Scalars["String"]["output"];
  schema?: Maybe<Array<NotificationSchema>>;
};

export type NotificationAnalyticsEgClickstream = {
  __typename?: "NotificationAnalyticsEgClickstream";
  description?: Maybe<Scalars["String"]["output"]>;
  event: EgClickstreamEvent;
  schema?: Maybe<Array<NotificationSchema>>;
};

export type NotificationAnalyticsSchemaAttribute = {
  __typename?: "NotificationAnalyticsSchemaAttribute";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type NotificationApiDateTime = {
  __typename?: "NotificationApiDateTime";
  day: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  epochSeconds: Scalars["String"]["output"];
  formatted: Scalars["String"]["output"];
  fullDateFormat?: Maybe<Scalars["String"]["output"]>;
  hour: Scalars["Int"]["output"];
  longDateFormat?: Maybe<Scalars["String"]["output"]>;
  mediumDateFormat?: Maybe<Scalars["String"]["output"]>;
  mediumTimeFormat?: Maybe<Scalars["String"]["output"]>;
  minute: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["String"]["output"];
  second: Scalars["Int"]["output"];
  shortDateFormat?: Maybe<Scalars["String"]["output"]>;
  shortTimeFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use ZonedDateTime if you want time zone information, replace with ZonedDateTime */
  timeZoneOffsetSeconds: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type NotificationApiDateTimeFormattedArgs = {
  format: Scalars["String"]["input"];
};

export type NotificationAttribute = {
  __typename?: "NotificationAttribute";
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type NotificationBannerTheme = {
  __typename?: "NotificationBannerTheme";
  themeValue?: Maybe<BannerTheme>;
};

export type NotificationCardTheme = {
  __typename?: "NotificationCardTheme";
  cardBorder?: Maybe<Scalars["Boolean"]["output"]>;
  personalizedTheme?: Maybe<PersonalizedTheme>;
  themeValue?: Maybe<CardTheme>;
};

export type NotificationCenter = HeaderSharedUi & {
  __typename?: "NotificationCenter";
  skipSsr: Scalars["Boolean"]["output"];
};

export type NotificationCenterItem = {
  __typename?: "NotificationCenterItem";
  deleteActionForm?: Maybe<NotificationForm>;
  emptyState?: Maybe<NotificationEmptyState>;
  icon?: Maybe<Icon>;
  notifications?: Maybe<Array<NotificationInbox>>;
  redirectPath?: Maybe<Scalars["String"]["output"]>;
  revealActions?: Maybe<Array<NotificationAction>>;
  title?: Maybe<NotificationPhrase>;
  unseen?: Maybe<Scalars["Int"]["output"]>;
};

export type NotificationContainer = {
  __typename?: "NotificationContainer";
  layout: LayoutPosition;
  theme?: Maybe<NotificationTheme>;
  view?: Maybe<NotificationView>;
};

export type NotificationContainerLink = NotificationLink & {
  __typename?: "NotificationContainerLink";
  actions?: Maybe<Array<NotificationAction>>;
  id?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<NotificationLinkTarget>;
  uri?: Maybe<Uri>;
};

export type NotificationCookie = {
  __typename?: "NotificationCookie";
  expires: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type NotificationDismiss = {
  __typename?: "NotificationDismiss";
  actions?: Maybe<Array<NotificationAction>>;
  icon: Icon;
};

export type NotificationEgdsItems = NotificationEgdsLinkNode | NotificationEgdsTextNode;

export type NotificationEgdsLinkNode = {
  __typename?: "NotificationEGDSLinkNode";
  action: UiLinkAction;
  disabled: Scalars["Boolean"]["output"];
  size: EgdsLinkSize;
  text: Scalars["String"]["output"];
};

export type NotificationEgdsTextNode = {
  __typename?: "NotificationEGDSTextNode";
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type NotificationEmptyState = {
  __typename?: "NotificationEmptyState";
  body?: Maybe<Array<NotificationPhrase>>;
  icon?: Maybe<Icon>;
  title?: Maybe<NotificationPhrase>;
};

export type NotificationForm = {
  __typename?: "NotificationForm";
  errorFormAnalytics?: Maybe<Array<NotificationAction>>;
  feedbackMessages?: Maybe<Array<NotificationMessage>>;
  sections: Array<NotificationFormSection>;
  successFormAnalytics?: Maybe<Array<NotificationAction>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type NotificationFormButtonType =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export interface NotificationFormField {
  label: Scalars["String"]["output"];
}

export type NotificationFormSection = {
  __typename?: "NotificationFormSection";
  fields: Array<NotificationFormField>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface NotificationInModuleMessageInput {
  moduleName?: InputMaybe<NotificationInModuleModuleName>;
  name: NotificationInModuleMessageName;
}

export type NotificationInModuleMessageName =
  | "COUPON_REMOVAL_FAILED_MESSAGE"
  | "CPM_CC_ON_FILE_MESSAGE"
  | "CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE"
  | "CPM_NO_PAYMENT_DUE_MESSAGE"
  | "CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE"
  | "OKC_ADD_FAILED_MESSAGE"
  | "OKC_REMOVAL_FAILED_MESSAGE"
  | "PAY_NOW_CC_ON_FILE_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_CC_ON_FILE_OKC_TOTAL_AMOUNT"
  | "PAY_NOW_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_GIFT_CARD_COUPON_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_TOTAL_AMOUNT"
  | "PROMOTION_CHANGED_MESSAGE";

export type NotificationInModuleModuleName =
  | "COMMON_PAYMENT_MODULE"
  | "COUPON_MODULE"
  | "LOYALTY_BURN_MODULE"
  | "PROMOTION_CHANGED_MODULE";

export type NotificationInbox = ContextualNotification & {
  __typename?: "NotificationInbox";
  backgroundImage?: Maybe<Image>;
  body?: Maybe<Array<NotificationPhrase>>;
  containerLink?: Maybe<NotificationContainerLink>;
  icon?: Maybe<Icon>;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  logo?: Maybe<Image>;
  notificationId?: Maybe<Scalars["String"]["output"]>;
  revealActions?: Maybe<Array<NotificationAction>>;
  state: NotificationState;
  subType?: Maybe<NotificationSubType>;
  timeStamp?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<NotificationPhrase>;
  type: NotificationType;
  userActions?: Maybe<Array<NotificationUserAction>>;
};

export interface NotificationLink {
  actions?: Maybe<Array<NotificationAction>>;
  id?: Maybe<Scalars["String"]["output"]>;
  target?: Maybe<NotificationLinkTarget>;
  uri?: Maybe<Uri>;
}

export type NotificationLinkTarget = "NEW_TAB" | "SAME_TAB";

export type NotificationLinkType =
  | "ANCHOR"
  | "APP_DOWNLOAD_LINK"
  | "BUTTON"
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export type NotificationLocation =
  | "AFTER_HEADER"
  | "AFTER_WIZARD"
  | "FOOTER"
  | "IN_PAGE"
  | "IN_PAGE_AD"
  | "LEGAL"
  | "POPUP"
  | "PRE_HEADER";

export type NotificationLocationOnPage =
  | "AFTER_HEADER"
  | "AFTER_WIZARD"
  | "ERROR"
  | "FOOTER"
  | "FULL_PAGE"
  | "IN_MODAL"
  | "IN_MODULE"
  | "IN_PAGE"
  | "IN_PAGE_AD"
  | "LEGAL"
  | "POPUP"
  | "PRE_HEADER";

export type NotificationMarketing = {
  __typename?: "NotificationMarketing";
  marketingCode: Scalars["String"]["output"];
  marketingCodeDetail: Scalars["String"]["output"];
};

export type NotificationMessage = {
  __typename?: "NotificationMessage";
  displayType: NotificationMessageDisplayType;
  level: NotificationMessageLevel;
  name: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type NotificationMessageCard = {
  __typename?: "NotificationMessageCard";
  body: Array<NotificationPhrase>;
  graphicElement?: Maybe<UiGraphic>;
  layout: NotificationMessageCardLayout;
  links?: Maybe<Array<NotificationPhraseLinkNode>>;
  title: NotificationPhrase;
};

export type NotificationMessageCardLayout = "ICON_ON_SIDE" | "ICON_ON_TOP" | "WITH_BORDER";

export type NotificationMessageDisplayType = "FORM" | "PAGE";

export type NotificationMessageLevel = "ERROR" | "INFO" | "SUCCESS" | "WARNING";

export type NotificationMessageType = "INVALID_INPUT" | "SERVICE_ERROR" | "SUCCESS";

export type NotificationMultiBody = {
  __typename?: "NotificationMultiBody";
  body: Array<NotificationPhrase>;
  title: NotificationPhrase;
};

export interface NotificationOptionalContextInput {
  additionalLink?: InputMaybe<Scalars["String"]["input"]>;
  calculateAward?: InputMaybe<CalculateAwardInput>;
  errorToken?: InputMaybe<Scalars["String"]["input"]>;
  inModuleMessage?: InputMaybe<NotificationInModuleMessageInput>;
  itineraryNumber?: InputMaybe<Scalars["String"]["input"]>;
  journeyCriterias?: InputMaybe<Array<NotificationOptionalContextJourneyCriteriaInput>>;
  rafInfo?: InputMaybe<NotificationRafInfoInput>;
  referer?: InputMaybe<Scalars["URL"]["input"]>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  travelApiId?: InputMaybe<Scalars["String"]["input"]>;
  tripFolderId?: InputMaybe<Scalars["String"]["input"]>;
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  userEmail?: InputMaybe<Scalars["String"]["input"]>;
}

export interface NotificationOptionalContextJourneyCriteriaFiltersInput {
  airlinesFilter?: InputMaybe<Array<Scalars["String"]["input"]>>;
  amenitiesFilter?: InputMaybe<Array<AmenityFilter>>;
  arrivalTimeFilter?: InputMaybe<Array<FlightsTimePeriodOfDay>>;
  departureTimeFilter?: InputMaybe<Array<FlightsTimePeriodOfDay>>;
  numberOfStopsFilter?: InputMaybe<Array<Scalars["Int"]["input"]>>;
}

export interface NotificationOptionalContextJourneyCriteriaInput {
  cabinClass?: InputMaybe<FlightsCabinClass>;
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<NotificationOptionalContextJourneyCriteriaLocationInput>;
  filter?: InputMaybe<Array<NotificationOptionalContextJourneyCriteriaFiltersInput>>;
  origin?: InputMaybe<NotificationOptionalContextJourneyCriteriaLocationInput>;
  scheduleChangeStatus?: InputMaybe<TripScheduleChangeStatus>;
  travelerDetail?: InputMaybe<Array<TravelerDetailsInput>>;
  tripType?: InputMaybe<FlightsTripType>;
  tripsData?: InputMaybe<TripsDataInput>;
}

export interface NotificationOptionalContextJourneyCriteriaLocationInput {
  airportTLA?: InputMaybe<Scalars["String"]["input"]>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type NotificationPhrase = NotificationAccessibilityField & {
  __typename?: "NotificationPhrase";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  completeText: Scalars["String"]["output"];
  egdsItems: Array<NotificationEgdsItems>;
  egdsTextItems: Array<EgdsComposableTextElement>;
  items: Array<NotificationPhrasePart>;
};

export type NotificationPhraseLinkNode = NotificationLink &
  NotificationPhrasePart & {
    __typename?: "NotificationPhraseLinkNode";
    actions?: Maybe<Array<NotificationAction>>;
    icon?: Maybe<Icon>;
    id?: Maybe<Scalars["String"]["output"]>;
    linkStyle?: Maybe<NotificationPhraseLinkStyle>;
    linkTheme?: Maybe<NotificationPhraseLinkTheme>;
    styles: Array<NotificationPhrasePartStyle>;
    target?: Maybe<NotificationLinkTarget>;
    text: Scalars["String"]["output"];
    type: NotificationLinkType;
    uri?: Maybe<Uri>;
  };

export type NotificationPhraseLinkStyle =
  | "FLOATING_ACTION_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON";

export type NotificationPhraseLinkTheme = "DEFAULT" | "INVERSE";

export interface NotificationPhrasePart {
  styles: Array<NotificationPhrasePartStyle>;
  text: Scalars["String"]["output"];
}

export type NotificationPhrasePartStyle = "EMPHASIS" | "IMPORTANT" | "MEDIUM" | "STANDARD";

export type NotificationPhraseTextNode = NotificationPhrasePart & {
  __typename?: "NotificationPhraseTextNode";
  styles: Array<NotificationPhrasePartStyle>;
  text: Scalars["String"]["output"];
};

export type NotificationPreferenceType = "EMAIL" | "PUSH";

export type NotificationQrCode = {
  __typename?: "NotificationQRCode";
  encodedImage?: Maybe<Scalars["String"]["output"]>;
  image: Image;
  title: Scalars["String"]["output"];
};

export type NotificationQuery = {
  __typename?: "NotificationQuery";
  claimIncentive: ClaimIncentive;
  inboxNotification: Array<InboxNotification>;
  inlineNotification: InlineNotification;
  mobileAppDownloadButton: AppDownloadButton;
  notificationCenter: NotificationCenterItem;
};

export type NotificationQueryClaimIncentiveArgs = {
  optionalContext?: InputMaybe<IncentiveContextInput>;
  optionalPageId?: InputMaybe<Scalars["String"]["input"]>;
};

export type NotificationQueryInlineNotificationArgs = {
  lineOfBusiness: LineOfBusinessDomain;
  notificationLocation: NotificationLocationOnPage;
  optionalContext?: InputMaybe<NotificationOptionalContextInput>;
  optionalPageId?: InputMaybe<Scalars["String"]["input"]>;
  pageLocation: PageLocation;
};

export type NotificationQueryMobileAppDownloadButtonArgs = {
  optionalPageId?: InputMaybe<Scalars["String"]["input"]>;
  referrer?: InputMaybe<Scalars["String"]["input"]>;
};

export interface NotificationRafInfoInput {
  rafLoginScenario?: InputMaybe<IdentityLoginScenario>;
  rafPlacementId?: InputMaybe<Scalars["String"]["input"]>;
  refcode: Scalars["String"]["input"];
}

export type NotificationSchema = {
  __typename?: "NotificationSchema";
  messageContent: Array<NotificationAnalyticsSchemaAttribute>;
  name: Scalars["String"]["output"];
};

export type NotificationSelectionField = NotificationFormField & {
  __typename?: "NotificationSelectionField";
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  options?: Maybe<Array<NotificationSelectionOption>>;
};

export type NotificationSelectionOption = {
  __typename?: "NotificationSelectionOption";
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  shortLabel?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type NotificationSimpleBody = {
  __typename?: "NotificationSimpleBody";
  title: NotificationPhrase;
};

export type NotificationSpecifications = {
  __typename?: "NotificationSpecifications";
  dismiss?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  read?: Maybe<Scalars["Boolean"]["output"]>;
  seen?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface NotificationSpecificationsInput {
  dismiss?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  read?: InputMaybe<Scalars["Boolean"]["input"]>;
  seen?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type NotificationState = "READ" | "SEEN" | "UNKNOWN" | "UNSEEN";

export type NotificationSubType = "COUPON_CAMPAIGN" | "DEFAULT";

export type NotificationSubmitButton = NotificationFormField & {
  __typename?: "NotificationSubmitButton";
  actions?: Maybe<Array<NotificationAction>>;
  buttonType?: Maybe<NotificationFormButtonType>;
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type NotificationTextInputField = NotificationFormField & {
  __typename?: "NotificationTextInputField";
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  placeholder: Scalars["String"]["output"];
};

export type NotificationTextToDownloadAppResponse = {
  __typename?: "NotificationTextToDownloadAppResponse";
  invalidFields?: Maybe<Array<Scalars["String"]["output"]>>;
  messageBody?: Maybe<Scalars["String"]["output"]>;
  messageType: NotificationMessageType;
};

export type NotificationTheme = NotificationBannerTheme | NotificationCardTheme;

export type NotificationType =
  | "ALERT"
  | "APP_DOWNLOAD"
  | "APP_HERO"
  | "BOTTOM_SHEET"
  | "CENTERED_SHEET"
  | "CITI_COBRAND"
  | "CITI_COBRAND_VERTICAL"
  | "CLEANLINESS"
  | "CONFIGURABLE"
  | "ERROR"
  | "FULL_PAGE"
  | "FULL_SHEET"
  | "FULL_SHEET_MEDIA"
  | "GENERIC"
  | "HEADER"
  | "INBOX"
  | "MAD_MARQUEE"
  | "NONE"
  | "ONETRUST"
  | "ONE_KEY"
  | "OSANO"
  | "POPOVER"
  | "RAF_BANNER"
  | "TAKEOVER"
  | "TRAVEL_ADVISORY"
  | "TRUST_MSG_BANNER"
  | "UNRECOVERABLE_ERROR";

export type NotificationUserAction = {
  __typename?: "NotificationUserAction";
  actions?: Maybe<Array<NotificationAction>>;
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
  type: NotificationUserActionType;
};

export type NotificationUserActionType = "DELETE" | "MARK_AS_READ" | "MARK_AS_UNREAD" | "UNKNOWN";

export interface NotificationUserInput {
  inputs: Array<NotificationUserInputElementInput>;
}

export interface NotificationUserInputElementInput {
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type NotificationView = "CAROUSEL" | "GRID";

export type NotificationsType = "ALL";

export type NumberValue = {
  __typename?: "NumberValue";
  id: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

export interface NumberValueInput {
  id: Scalars["String"]["input"];
  value: Scalars["Int"]["input"];
}

export type Offer = {
  __typename?: "Offer";
  action?: Maybe<LodgingShoppingAction>;
  availability?: Maybe<PropertyAvailability>;
  /** @deprecated deprecated in favor of offerBookButton */
  bookButton?: Maybe<LodgingForm>;
  cancellationPolicy?: Maybe<PropertyCancellationPolicy>;
  changeOfferCallToAction?: Maybe<ChangeOfferCallToAction>;
  dataAttributes?: Maybe<GraphQlPair>;
  dealMarker?: Maybe<Scalars["String"]["output"]>;
  deposit?: Maybe<Money>;
  depositPolicies: Array<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dynamicRateRule?: Maybe<DynamicRateRule>;
  etpModalPolicies?: Maybe<Array<Scalars["String"]["output"]>>;
  feeMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated FeeMessages will contain the messaging previously found here */
  fees: Array<Fees>;
  hotelCollect?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated deprecated - adding tempararily for pwa support, logic will be moved serverside */
  installmentPayable: Scalars["Boolean"]["output"];
  lodgingPrepareCheckout?: Maybe<LodgingPrepareCheckout>;
  /** @deprecated deprecated in favour of priceBreakDownSummary, replace with priceBreakDownSummary */
  lodgingPriceBreakDown?: Maybe<LodgingPriceBreakDown>;
  loyaltyMessage?: Maybe<LodgingEnrichedMessage>;
  mandatoryFees?: Maybe<MandatoryFees>;
  /** @deprecated Look for minRoomsLeft in availability.minRoomsLeft */
  minRoomsLeft?: Maybe<Scalars["Int"]["output"]>;
  nightlyRates: Array<NightlyRate>;
  noCreditCard: Scalars["Boolean"]["output"];
  offerBookButton?: Maybe<LodgingForm>;
  optionTitle?: Maybe<LodgingHeader>;
  paymentChoice?: Maybe<Scalars["Boolean"]["output"]>;
  paymentModel?: Maybe<PropertyPaymentModel>;
  pointsApplied?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<PropertyPrice>;
  priceAfterLoyaltyPointsApplied?: Maybe<PropertyPrice>;
  priceBreakDownSummary?: Maybe<PriceSummary>;
  priceLabel?: Maybe<Scalars["String"]["output"]>;
  pricePresentation?: Maybe<PricePresentation>;
  pricePresentationDialog?: Maybe<PricePresentationDialog>;
  pricingScheme?: Maybe<PricingScheme>;
  /** @deprecated deprecated in favor of propertyNaturalKeys to support MRP offer, replace with propertyNaturalKeys */
  propertyNaturalKey?: Maybe<PropertyNaturalKey>;
  propertyNaturalKeys: Array<PropertyNaturalKey>;
  providerId?: Maybe<Scalars["Int"]["output"]>;
  ratePlanId?: Maybe<Scalars["String"]["output"]>;
  roomTypeId?: Maybe<Scalars["String"]["output"]>;
  saleCurrency?: Maybe<Scalars["String"]["output"]>;
  showNightlyRateMessage?: Maybe<Scalars["Boolean"]["output"]>;
  showTaxesAndFeesIncludedMessage?: Maybe<Scalars["Boolean"]["output"]>;
  showTotalPrice?: Maybe<Scalars["Boolean"]["output"]>;
  sourceType?: Maybe<SourceType>;
  supplyCurrency?: Maybe<Scalars["String"]["output"]>;
  totalPriceMessage?: Maybe<Scalars["String"]["output"]>;
  variableMargin?: Maybe<Scalars["Int"]["output"]>;
};

export type OfferActionLink = {
  __typename?: "OfferActionLink";
  href: Scalars["String"]["output"];
  referrerId?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type OfferAttribute = {
  __typename?: "OfferAttribute";
  icon?: Maybe<Icon>;
  type?: Maybe<OfferAttributeType>;
};

export type OfferAttributeType = "FREE_BREAKFAST" | "FREE_CANCELLATION" | "NO_CC_REQUIRED" | "PAYMENT_CHOICE" | "VIP";

export type OfferBadge = {
  __typename?: "OfferBadge";
  primary?: Maybe<Badge>;
  secondary?: Maybe<Badge>;
  tertiary?: Maybe<Badge>;
};

export type OfferCategoriesSection = {
  __typename?: "OfferCategoriesSection";
  allOffersSection?: Maybe<AllOffersSection>;
  heading?: Maybe<Scalars["String"]["output"]>;
  offerCategories?: Maybe<Array<OfferCategory>>;
  overlayContents?: Maybe<Array<OfferCategoriesSectionOverlayContentsContainer>>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type OfferCategoriesSectionOverlayContents = OfferCategoryOverlayContent;

export type OfferCategoriesSectionOverlayContentsContainer = {
  __typename?: "OfferCategoriesSectionOverlayContentsContainer";
  content: OfferCategoriesSectionOverlayContents;
  contentId: Scalars["String"]["output"];
};

export type OfferCategory = {
  __typename?: "OfferCategory";
  actionId: Scalars["String"]["output"];
  heading: OfferCategoryHeading;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  media?: Maybe<OfferCategoryMedia>;
  messages?: Maybe<Array<OfferCategoryMessage>>;
};

export type OfferCategoryHeading = {
  __typename?: "OfferCategoryHeading";
  text: Scalars["String"]["output"];
};

export type OfferCategoryMedia = Image;

export type OfferCategoryMessage = {
  __typename?: "OfferCategoryMessage";
  icon?: Maybe<Icon>;
  message?: Maybe<EgdsStylizedText>;
};

export type OfferCategoryOverlayContent = {
  __typename?: "OfferCategoryOverlayContent";
  body?: Maybe<OfferCategoryOverlayContentBody>;
  heading?: Maybe<OfferCategoryOverlayContentHeading>;
};

export type OfferCategoryOverlayContentBody = OfferCategoryOverlayContentBodyCategorizedOffers;

export type OfferCategoryOverlayContentBodyCategorizedOffers = {
  __typename?: "OfferCategoryOverlayContentBodyCategorizedOffers";
  messages?: Maybe<Array<OfferCategoryMessage>>;
  offerFilters?: Maybe<LodgingOfferFilters>;
  offerIdentifiers?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type OfferCategoryOverlayContentHeading = OfferCategoryHeading;

export type OfferData = {
  __typename?: "OfferData";
  actionLink: OfferActionLink;
  price: OfferPrice;
  strikeThroughPrice?: Maybe<OfferPrice>;
};

export type OfferDetails = {
  __typename?: "OfferDetails";
  alternateAvailabilityMsg?: Maybe<LodgingComplexLinkMessage>;
  alternateDates?: Maybe<AlternateDatesCard>;
  categorizedListings?: Maybe<Array<LodgingOfferListing>>;
  clickstreamEvents: Array<Scalars["AnalyticsPayload"]["output"]>;
  errorMessage?: Maybe<MessageResult>;
  /** @deprecated  Experimental Offer Categories for Room&Rates Customization[TNL: 53884] */
  experimentalOfferCategoriesSection?: Maybe<OfferCategoriesSection>;
  filterPills?: Maybe<Array<LodgingPill>>;
  highlightedBenefits?: Maybe<EgdsTextIconList>;
  id: Scalars["String"]["output"];
  includedPerks?: Maybe<PropertyInfoContent>;
  insurtechPrimingCardCategorizedListing?: Maybe<InsurTechComponentPlacement>;
  legalBanner?: Maybe<MessageResult>;
  legalDisclaimer?: Maybe<DisclaimerContentMarkup>;
  /** @deprecated Use listingsToggle */
  limitedListingsToggle?: Maybe<EgdsToggleButton>;
  listings?: Maybe<Array<PropertyInfoOffersListing>>;
  listingsHeader: LodgingHeader;
  listingsToggle?: Maybe<ListingsToggle>;
  loading?: Maybe<OfferDetailsLoading>;
  lodgingClientSideAnalyticsAttempt?: Maybe<LodgingAdaptExAnalyticsEvent>;
  lodgingOneKeyBurnSwitch?: Maybe<LodgingOneKeyBurnSwitch>;
  loyaltyDiscount?: Maybe<PropertyLoyaltyDiscount>;
  marketingChannelId: Scalars["Int"]["output"];
  offerAdaptExSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  offerFilters?: Maybe<LodgingOfferFilters>;
  offerLevelMessages: Array<MessageResult>;
  partnerPropertySearchLink?: Maybe<LodgingLinkMessage>;
  paymentPolicy: Array<PropertyPaymentPolicyInfo>;
  priceInsight: PriceInsight;
  pricingFrequentlyAskedQuestions?: Maybe<Array<PropertyFaq>>;
  propertyContentSectionGroup?: Maybe<PropertyContentSectionGroup>;
  propertyHighlightSection?: Maybe<PropertyHighlightSection>;
  propertyLevelOffersCard?: Maybe<EgdsStandardMessagingCard>;
  propertyLevelOffersMessage?: Maybe<MessageResult>;
  propertySearchLink?: Maybe<LodgingLinkMessage>;
  searchCriteria?: Maybe<PropertySearchCriteria>;
  serpMetadata?: Maybe<SerpMetadata>;
  shoppingContext?: Maybe<ShoppingContext>;
  singleUnitOffer?: Maybe<SingleUnitOfferDetails>;
  singleUnitOfferDialog?: Maybe<SingleUnitOfferDialog>;
  soldOut: Scalars["Boolean"]["output"];
  spaceDetails?: Maybe<SpaceDetails>;
  stickyBar?: Maybe<PropertyDetailsStickyBar>;
  toastMessage?: Maybe<LodgingToastMessage>;
  tripSummary?: Maybe<TripSummaryContent>;
  units: Array<PropertyUnit>;
  unitsCarousel?: Maybe<LodgingCarouselContainer>;
};

export type OfferDetailsLoading = AccessibilityField & {
  __typename?: "OfferDetailsLoading";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
};

export type OfferIdentity = {
  __typename?: "OfferIdentity";
  type: OfferIdentityType;
  value: Scalars["String"]["output"];
};

export interface OfferIdentityInput {
  type: OfferIdentityType;
  value: Scalars["String"]["input"];
}

export type OfferIdentityType = "EG_OFFER_ID" | "EG_OFFER_TOKEN";

export interface OfferItem {
  badge?: Maybe<CampaignOfferBadge>;
  description?: Maybe<Scalars["String"]["output"]>;
  image: Image;
  name: Scalars["String"]["output"];
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
}

export type OfferPrice = {
  __typename?: "OfferPrice";
  additionalPriceInformation?: Maybe<Scalars["String"]["output"]>;
  allyText?: Maybe<Scalars["String"]["output"]>;
  checkPriceAltText?: Maybe<Scalars["String"]["output"]>;
  checkPriceText?: Maybe<Scalars["String"]["output"]>;
  disclaimerA11yText?: Maybe<Scalars["String"]["output"]>;
  disclaimerCloseDialogText?: Maybe<Scalars["String"]["output"]>;
  disclaimerText: Scalars["String"]["output"];
  includesTaxesAndFeesText?: Maybe<Scalars["String"]["output"]>;
  prefixText?: Maybe<Scalars["String"]["output"]>;
  qualifierText: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type OfferSummary = {
  __typename?: "OfferSummary";
  attributes?: Maybe<Array<OfferAttribute>>;
  /** @deprecated Use messages: List< OfferSummaryMessage> to get multiple messages. */
  message?: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Array<OfferSummaryMessage>>;
  /** @deprecated Use messages: List< OfferSummaryMessage> to get multiple themes. */
  theme?: Maybe<Theme>;
};

export type OfferSummaryMessage = {
  __typename?: "OfferSummaryMessage";
  mark?: Maybe<Mark>;
  message?: Maybe<Scalars["String"]["output"]>;
  moreInfoDialog?: Maybe<LodgingPlainDialog>;
  replacementText?: Maybe<Scalars["String"]["output"]>;
  strikethroughText?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Theme>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type OfferToken = {
  __typename?: "OfferToken";
  lineOfBusiness: LineOfBusinessDomain;
  token: Scalars["String"]["output"];
};

export type OfferTokenDetails = {
  __typename?: "OfferTokenDetails";
  checkIn: Scalars["String"]["output"];
  checkOut: Scalars["String"]["output"];
  offerToken: Scalars["String"]["output"];
  propertyId: Scalars["String"]["output"];
  ratePlanId: Scalars["String"]["output"];
  unitTypeId: Scalars["String"]["output"];
};

export interface OfferTokenInput {
  lineOfBusiness: LineOfBusinessDomain;
  token: Scalars["String"]["input"];
}

export type OffersContactHostAction = OffersInquiryFormDialogAction | UiLinkAction;

export type OffersContactHostButton = {
  __typename?: "OffersContactHostButton";
  action?: Maybe<OffersContactHostAction>;
  button?: Maybe<OffersContactHostButtonType>;
};

export type OffersContactHostButtonType = UiSecondaryButton | UiTertiaryButton;

export type OffersContactHostSection = {
  __typename?: "OffersContactHostSection";
  cta?: Maybe<OffersContactHostButton>;
  header?: Maybe<Scalars["String"]["output"]>;
};

export type OffersInquiryFormBookNowMessaging = {
  __typename?: "OffersInquiryFormBookNowMessaging";
  availabilityMessage: LodgingEnrichedMessage;
  availabilityMessageSubText?: Maybe<LodgingEnrichedMessage>;
  lodgingPrepareCheckout?: Maybe<LodgingPrepareCheckout>;
  reserveSubText?: Maybe<RatePlanMessage>;
};

export type OffersInquiryFormDialogAction = {
  __typename?: "OffersInquiryFormDialogAction";
  dialogAnalytics?: Maybe<ClientSideAnalytics>;
  inquiryFormModal?: Maybe<OffersInquiryFormLayout>;
};

export interface OffersInquiryFormInput {
  petsIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  primaryPropertyCriteriaInput?: InputMaybe<PrimaryPropertyCriteriaInput>;
}

export type OffersInquiryFormInputElement =
  | EgdsBasicCheckBox
  | EgdsBasicSelect
  | EgdsTextAreaInputField
  | EgdsTextInputField
  | OffersInquiryPhoneInputField;

export type OffersInquiryFormLayout = {
  __typename?: "OffersInquiryFormLayout";
  analytics?: Maybe<ClientSideAnalytics>;
  bookNowMessaging?: Maybe<OffersInquiryFormBookNowMessaging>;
  /** @deprecated Using availability calendar full time in form */
  datePicker?: Maybe<EgdsDateRangePicker>;
  disclaimer?: Maybe<Array<EgdsText>>;
  featuredMessage?: Maybe<EgdsStandardMessagingCard>;
  formInput?: Maybe<Array<OffersInquiryFormInputElement>>;
  header?: Maybe<Scalars["String"]["output"]>;
  submitButton?: Maybe<UiPrimaryButton>;
};

export type OffersInquiryPhoneInputField = {
  __typename?: "OffersInquiryPhoneInputField";
  countryCode: EgdsBasicSelect;
  phoneNumber: EgdsTextInputField;
};

export type OffersInquiryProminence = "HIGH" | "LOW" | "MEDIUM";

export interface OffersRecommendationAvailabilityFilterInput {
  dates?: InputMaybe<OptionalDateTimeRangeInput>;
  destination?: InputMaybe<Scalars["String"]["input"]>;
  origin?: InputMaybe<Scalars["String"]["input"]>;
  travelMemberGroups?: InputMaybe<Array<TravelMemberGroupInput>>;
}

export type OffersRecommendationBadges = {
  __typename?: "OffersRecommendationBadges";
  heading: Array<EgdsStandardBadge>;
  price?: Maybe<EgdsStandardBadge>;
};

export type OffersRecommendationCard = {
  __typename?: "OffersRecommendationCard";
  badges?: Maybe<OffersRecommendationBadges>;
  cardAction: UiLinkAction;
  details: Array<OffersRecommendationCardDetail>;
  heading: OffersRecommendationHeading;
  impression: ClientSideAnalytics;
  media: OffersRecommendationCardMedia;
  price: PropertyPrice;
  tripSaveItem?: Maybe<TripsSaveItem>;
};

export type OffersRecommendationCardDetail = {
  __typename?: "OffersRecommendationCardDetail";
  content: OffersRecommendationCardDetailContent;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type OffersRecommendationCardDetailContent = EgdsParagraph | EgdsTextIconList | ProductRatingSummary;

export type OffersRecommendationCardMedia = Image | RecommendationImageGallery | RecommendationMediaItem;

export type OffersRecommendationContainer = EgdsCarousel;

export type OffersRecommendationContainerType = "CAROUSEL" | "GRID";

export type OffersRecommendationGroup = {
  __typename?: "OffersRecommendationGroup";
  cards: Array<OffersRecommendationCard>;
  container?: Maybe<OffersRecommendationContainer>;
  heading?: Maybe<OffersRecommendationHeading>;
  impression?: Maybe<ClientSideAnalytics>;
  moreOffers?: Maybe<EgdsStandardLink>;
};

export type OffersRecommendationHeading = {
  __typename?: "OffersRecommendationHeading";
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export interface OffersRecommendationProductIdentifierInput {
  lineOfBusiness: LineOfBusinessDomain;
  productId: Scalars["String"]["input"];
}

export type OffersRecommendationStrategy = "ALTERNATE" | "ATTACH" | "CO_CLICK" | "NEARBY" | "POPULAR" | "TRENDING";

export type OffersTravelerSelector = {
  __typename?: "OffersTravelerSelector";
  travelers?: Maybe<EgdsSearchFormTravelersField>;
};

export type OffersUrl = {
  __typename?: "OffersUrl";
  type: RecommendedType;
  url: Scalars["URL"]["output"];
};

export type OfflineRateDetails = {
  __typename?: "OfflineRateDetails";
  phoneNumber?: Maybe<CruisePhoneNumber>;
  text: Scalars["String"]["output"];
};

export type OfflineTrip = {
  __typename?: "OfflineTrip";
  offlineTripItems: Array<OfflineTripItem>;
  tripViewId: Scalars["String"]["output"];
};

export type OfflineTripItem = {
  __typename?: "OfflineTripItem";
  refreshIntervalInMinutes: Scalars["Int"]["output"];
  tripItemId: Scalars["String"]["output"];
};

export interface OffsetInput {
  pitId: Scalars["String"]["input"];
  pitKeepAlive: Scalars["String"]["input"];
}

export type OnboardStep = "PARTNER" | "PAYMENT" | "REVIEW";

export type OnboardingCard = {
  __typename?: "OnboardingCard";
  cardContent?: Maybe<OnboardingCardContent>;
  cardHeading?: Maybe<Scalars["String"]["output"]>;
  cardSubHeading?: Maybe<Scalars["String"]["output"]>;
};

export type OnboardingCardContent = AdvertiserPortalSharedUiCard | OnboardingLandingCard | OnboardingReviewCard;

export interface OnboardingContext {
  onboardingSource: Scalars["String"]["input"];
}

export interface OnboardingInput {
  currentStep?: InputMaybe<Scalars["Int"]["input"]>;
  newPartner: Scalars["Boolean"]["input"];
  partnerAccountId: Scalars["String"]["input"];
  paymentAccountId: Scalars["String"]["input"];
  paymentProfileId: Scalars["String"]["input"];
  productIdsToOnboard: Array<Scalars["String"]["input"]>;
}

export type OnboardingLandingCard = {
  __typename?: "OnboardingLandingCard";
  cardFooterText: Scalars["String"]["output"];
  cardStepItems: Array<Scalars["String"]["output"]>;
  cardSubtitle: EgdsSpannableText;
  cardTermsAndConditionsLink: EgdsInlineLink;
  cardTitle: Scalars["String"]["output"];
  getStartedButton: UiPrimaryButton;
};

export type OnboardingOperationAction = {
  __typename?: "OnboardingOperationAction";
  actionType: Scalars["String"]["output"];
};

export type OnboardingReviewCard = {
  __typename?: "OnboardingReviewCard";
  editLink?: Maybe<Scalars["String"]["output"]>;
  sections: Array<OnboardingReviewSection>;
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type OnboardingReviewSection = {
  __typename?: "OnboardingReviewSection";
  editLink?: Maybe<Scalars["String"]["output"]>;
  items: Array<ReviewLineItem>;
  title: Scalars["String"]["output"];
};

export type OnboardingSection = {
  __typename?: "OnboardingSection";
  icon: Icon;
  label: Scalars["String"]["output"];
};

export type OneIdentityOnboardingClientAction = "IDENTITY_MERGE" | "LOGOUT" | "NONE" | "NONE_JOURNEY_IS_COMPLETE";

export type OneIdentityOnboardingResponse = {
  __typename?: "OneIdentityOnboardingResponse";
  responseType: OneIdentityOnboardingClientAction;
};

export type OneKeyBurnSwitch = {
  __typename?: "OneKeyBurnSwitch";
  graphic?: Maybe<UiGraphic>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  switch: EgdsStandardSwitch;
};

export type OneKeyCashBurnToggleResult = {
  __typename?: "OneKeyCashBurnToggleResult";
  success: Scalars["Boolean"]["output"];
};

export interface OneKeyCashLoginCardDataInput {
  oneKeyCash: Scalars["String"]["input"];
}

export type OneKeyCheckoutBurn = {
  __typename?: "OneKeyCheckoutBurn";
  burnAmount?: Maybe<Scalars["String"]["output"]>;
  element: OneKeyCheckoutBurnElementTypes;
  giftCardConflictPopup?: Maybe<OneKeyGiftCardConflictPopup>;
  title: Scalars["String"]["output"];
};

export type OneKeyCheckoutBurnElementTypes = EgdsStandardMessagingCard | EgdsStandardSwitch;

export type OneKeyContextualOnboarding = {
  __typename?: "OneKeyContextualOnboarding";
  brandLogos: Array<UiGraphic>;
  headerBrandLogo?: Maybe<UiGraphic>;
  link: EgdsStandardLink;
  oneKeyCard: EgdsCard;
  primaryButton: OneKeyOnboardingButton;
  subText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  titleImage: UiGraphic;
};

export type OneKeyDetails = {
  __typename?: "OneKeyDetails";
  analytics?: Maybe<CarAnalytics>;
  earnBanner?: Maybe<CarOfferBadge>;
  isOfferEligibleForBurn: Scalars["Boolean"]["output"];
  isOneKeyEnrolled: Scalars["Boolean"]["output"];
};

export type OneKeyGiftCardConflictPopup = {
  __typename?: "OneKeyGiftCardConflictPopup";
  choiceButtons: Array<OneKeyGiftCardConflictPopupButtons>;
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type OneKeyGiftCardConflictPopupButtons = UiPrimaryButton | UiTertiaryButton;

export type OneKeyInfo = {
  __typename?: "OneKeyInfo";
  isOneKeyEnrolled: Scalars["Boolean"]["output"];
};

export type OneKeyLoyaltyBanner = {
  __typename?: "OneKeyLoyaltyBanner";
  element: OneKeyLoyaltyBannerTypes;
};

export type OneKeyLoyaltyBannerTypes =
  | EgdsStandardMessagingCard
  | LoyaltyMessagingCard
  | OneKeyBurnSwitch
  | OneKeyMessagingCard;

export type OneKeyMessagingCard = {
  __typename?: "OneKeyMessagingCard";
  buttons?: Maybe<Array<EgdsButton>>;
  disclaimer?: Maybe<OneKeyMessagingCardDisclaimer>;
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  message: OneKeyMessagingCardSpannableText;
  themeProvider?: Maybe<EgdsThemeProvider>;
};

export type OneKeyMessagingCardDisclaimer = EgdsStandardLink | EgdsStylizedText;

export type OneKeyMessagingCardSpannableText = {
  __typename?: "OneKeyMessagingCardSpannableText";
  contents: Array<OneKeyMessagingCardSpannableTextItem>;
  text: Scalars["String"]["output"];
};

export type OneKeyMessagingCardSpannableTextItem = EgdsStylizedText | LoyaltyMembershipAnchorCurrencyLink;

export type OneKeyOnboardingAccountMerge = {
  __typename?: "OneKeyOnboardingAccountMerge";
  headerBrandLogo?: Maybe<UiGraphic>;
  images: Array<UiGraphic>;
  link?: Maybe<EgdsStandardLink>;
  primaryButton: OneKeyOnboardingButton;
  subTitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type OneKeyOnboardingButton = {
  __typename?: "OneKeyOnboardingButton";
  action?: Maybe<OneKeyOnboardingButtonAction>;
  button: EgdsButton;
};

export type OneKeyOnboardingButtonAction = EgdsDismissAction | OnboardingOperationAction | UiLinkAction;

export type OneKeyOnboardingClaimIncentive = {
  __typename?: "OneKeyOnboardingClaimIncentive";
  bannerImage?: Maybe<Image>;
  brandLogos: Array<UiGraphic>;
  headerBrandLogo?: Maybe<UiGraphic>;
  link: EgdsStandardLink;
  primaryButton: OneKeyOnboardingButton;
  subText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  titleImage: UiGraphic;
};

export type OneKeyOnboardingGettingStarted = {
  __typename?: "OneKeyOnboardingGettingStarted";
  carousel?: Maybe<EgdsCarousel>;
  consentText?: Maybe<Scalars["String"]["output"]>;
  dismissButton?: Maybe<EgdsButton>;
  headerBrandLogo?: Maybe<UiGraphic>;
  headerImage?: Maybe<Image>;
  images: Array<UiGraphic>;
  link: EgdsStandardLink;
  primaryButton: OneKeyOnboardingButton;
  subTitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type OneKeyOnboardingInterstitialLoading = {
  __typename?: "OneKeyOnboardingInterstitialLoading";
  brandLogos: Array<UiGraphic>;
  headerBrandLogo?: Maybe<UiGraphic>;
  onLoadOperation: OnboardingOperationAction;
  primaryButton: OneKeyOnboardingButton;
  timeoutMessage: Scalars["String"]["output"];
  titleImage: UiGraphic;
  titleMessage: Scalars["String"]["output"];
  waitingMessage: Scalars["String"]["output"];
  welcomeMessage: Scalars["String"]["output"];
};

export type OneKeyOnboardingOperationResult = {
  __typename?: "OneKeyOnboardingOperationResult";
  operationResult?: Maybe<OneKeyOperationResult>;
  operationStatus: OneKeyOnboardingOperationStatus;
};

export type OneKeyOnboardingOperationStatus = "FAILED" | "SUCCEEDED" | "TIMED_OUT";

export type OneKeyOnboardingResult =
  | OneKeyContextualOnboarding
  | OneKeyOnboardingAccountMerge
  | OneKeyOnboardingClaimIncentive
  | OneKeyOnboardingGettingStarted
  | OneKeyOnboardingInterstitialLoading
  | OneKeyOnboardingTermsAndConditions;

export type OneKeyOnboardingSheet = {
  __typename?: "OneKeyOnboardingSheet";
  element: OneKeyOnboardingResult;
};

export type OneKeyOnboardingTermsAndConditions = {
  __typename?: "OneKeyOnboardingTermsAndConditions";
  additionalText: Scalars["String"]["output"];
  dismissButton?: Maybe<EgdsButton>;
  headerBrandLogo?: Maybe<UiGraphic>;
  images: Array<UiGraphic>;
  links: Array<EgdsStandardLink>;
  onDismissOperation?: Maybe<OnboardingOperationAction>;
  primaryButton: OneKeyOnboardingButton;
  secondaryButton?: Maybe<EgdsButton>;
  subText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type OneKeyOperationResult =
  | EgdsDismissAction
  | OneKeyContextualOnboarding
  | OneKeyOnboardingInterstitialLoading
  | UiLinkAction;

export type OneKeyUniversalContextualOnboardingBalance = {
  __typename?: "OneKeyUniversalContextualOnboardingBalance";
  balance: Scalars["String"]["output"];
  subTitles: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type OneKeyUniversalContextualOnboardingBenefit = {
  __typename?: "OneKeyUniversalContextualOnboardingBenefit";
  description: Scalars["String"]["output"];
  icon: Icon;
};

export type OneKeyUniversalOnboardingBrandAssetCollection = {
  __typename?: "OneKeyUniversalOnboardingBrandAssetCollection";
  brandLogos: Array<UiGraphic>;
  titleImage: UiGraphic;
};

export type OneKeyUniversalOnboardingButton = {
  __typename?: "OneKeyUniversalOnboardingButton";
  action?: Maybe<OneKeyUniversalOnboardingButtonAction>;
  button: OneKeyUniversalOnboardingButtonElement;
  secondaryAction?: Maybe<OneKeyUniversalOnboardingButtonAction>;
};

export type OneKeyUniversalOnboardingButtonAction =
  | EgdsDismissAction
  | OneKeyUniversalOnboardingOperationAction
  | UiLinkAction;

export type OneKeyUniversalOnboardingButtonElement = EgdsOverlayButton | UiPrimaryButton | UiTertiaryButton;

export type OneKeyUniversalOnboardingContextualScreen = {
  __typename?: "OneKeyUniversalOnboardingContextualScreen";
  ariaLabel: Scalars["String"]["output"];
  balanceSection?: Maybe<OneKeyUniversalContextualOnboardingBalance>;
  benefits?: Maybe<Array<OneKeyUniversalContextualOnboardingBenefit>>;
  brandAssets?: Maybe<OneKeyUniversalOnboardingBrandAssetCollection>;
  oneKeyCashInfoSection?: Maybe<OneKeyUniversalOnboardingInfoSection>;
  primaryButton: OneKeyUniversalOnboardingButton;
  primaryLink?: Maybe<OneKeyUniversalOnboardingLink>;
  tierBadge: EgdsLoyaltyBadge;
  title: Scalars["String"]["output"];
};

export type OneKeyUniversalOnboardingInfoDialog = {
  __typename?: "OneKeyUniversalOnboardingInfoDialog";
  ariaLabel: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  primaryButton: OneKeyUniversalOnboardingButton;
};

export type OneKeyUniversalOnboardingInfoSection = {
  __typename?: "OneKeyUniversalOnboardingInfoSection";
  dialog: OneKeyUniversalOnboardingInfoDialog;
  infoButton: OneKeyUniversalOnboardingButton;
};

export type OneKeyUniversalOnboardingLink = {
  __typename?: "OneKeyUniversalOnboardingLink";
  link: EgdsStandardLink;
};

export type OneKeyUniversalOnboardingOperationAction = {
  __typename?: "OneKeyUniversalOnboardingOperationAction";
  actionType: Scalars["String"]["output"];
};

export type OneKeyUniversalOnboardingOperationResult = {
  __typename?: "OneKeyUniversalOnboardingOperationResult";
  operationStatus: OneKeyUniversalOnboardingOperationStatus;
};

export type OneKeyUniversalOnboardingOperationStatus = "FAILED" | "FAILED_BLOCKING" | "SUCCEEDED" | "UNKNOWN_OPERATION";

export type OneKeyUniversalOnboardingScreen = {
  __typename?: "OneKeyUniversalOnboardingScreen";
  screen: OneKeyUniversalOnboardingScreenResult;
};

export type OneKeyUniversalOnboardingScreenResult =
  | OneKeyUniversalOnboardingContextualScreen
  | OneKeyUniversalOnboardingWelcomeScreen;

export type OneKeyUniversalOnboardingWelcomeScreen = {
  __typename?: "OneKeyUniversalOnboardingWelcomeScreen";
  ariaLabel: Scalars["String"]["output"];
  brandAssets?: Maybe<OneKeyUniversalOnboardingBrandAssetCollection>;
  consentText?: Maybe<Scalars["String"]["output"]>;
  dismissButton?: Maybe<OneKeyUniversalOnboardingButton>;
  heroImage: Image;
  learnMoreLink: OneKeyUniversalOnboardingLink;
  onLoadOperation?: Maybe<OneKeyUniversalOnboardingOperationAction>;
  primaryButton: OneKeyUniversalOnboardingButton;
  privacyLink?: Maybe<OneKeyUniversalOnboardingLink>;
  subTitles: Array<Scalars["String"]["output"]>;
  termsAndConditionsLink?: Maybe<OneKeyUniversalOnboardingLink>;
  title: Scalars["String"]["output"];
};

export type OpenDurationSpanAction = {
  __typename?: "OpenDurationSpanAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  durationSpan: DurationSpan;
};

export type OpenTypeaheadAction = UiAction & {
  __typename?: "OpenTypeaheadAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use info.allowExactMatch */
  allowExactMatch: Scalars["Boolean"]["output"];
  analytics: ClientSideAnalytics;
  /** @deprecated Use info.categorize */
  categorize: Scalars["Boolean"]["output"];
  /** @deprecated Use info.typeaheadMultiSelect.clearAllText */
  clearAllText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use info.dismissButtonText */
  dismissButtonText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use info.emptyResultsPlaceholder */
  emptyResultsPlaceholder?: Maybe<Scalars["String"]["output"]>;
  info: TypeaheadInfo;
  /** @deprecated Use info.listTitle */
  listTitle: Scalars["Boolean"]["output"];
  /** @deprecated Use info.typeaheadMultiSelect.selectedItemHeading */
  selectedItemHeading?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use info.typeaheadMultiSelect.showPills */
  showPills: Scalars["Boolean"]["output"];
};

export type Operation = "ADDITION" | "MULTIPLICATION";

export type OperationStatus = "FAILURE" | "INVALID_INPUT" | "SUCCESS";

export type OperationType = "SMART_FORM_LOAD" | "SMART_FORM_SUBMIT";

export type OpinionLabFeedback = {
  __typename?: "OpinionLabFeedback";
  opinionSurveyTitle: Scalars["String"]["output"];
  options: Array<FeedbackOption>;
  submit: CarActionableItem;
  submitConfirmation: Scalars["String"]["output"];
};

export type OptionViewModel = {
  __typename?: "OptionViewModel";
  ariaLabel?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  clickstreamEvents?: Maybe<LodgingFilterClickstreamEvents>;
  count?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  /** @deprecated Don't get/set value from this field, replace with optionValue */
  enumValue?: Maybe<Scalars["String"]["output"]>;
  filterCategory?: Maybe<Scalars["String"]["output"]>;
  filterType?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  icon?: Maybe<Scalars["String"]["output"]>;
  isSelected: Scalars["Boolean"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  optionValue?: Maybe<Scalars["String"]["output"]>;
  range?: Maybe<FilterOptionRange>;
  selectedLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Don't get/set value from this field, replace with optionValue */
  value?: Maybe<Scalars["String"]["output"]>;
};

export interface OptionalContextInput {
  dropdownView?: InputMaybe<Scalars["Boolean"]["input"]>;
  isDarkMode?: InputMaybe<Scalars["Boolean"]["input"]>;
  messageCountEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  pushEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  tripInfo?: InputMaybe<TripInfoContextInput>;
}

export type OptionalDateTimeRange = {
  __typename?: "OptionalDateTimeRange";
  end?: Maybe<DateTime>;
  start?: Maybe<DateTime>;
};

export interface OptionalDateTimeRangeInput {
  end?: InputMaybe<DateTimeInput>;
  start?: InputMaybe<DateTimeInput>;
}

export interface OrderInfoInput {
  orderId: Scalars["String"]["input"];
  orderLine?: InputMaybe<Scalars["String"]["input"]>;
}

export type Orientation = "LANDSCAPE" | "PORTRAIT";

export type OriginAirport = LocAirport & {
  __typename?: "OriginAirport";
  airportCode: Scalars["String"]["output"];
  airportName?: Maybe<Scalars["String"]["output"]>;
  destinationCountries: Array<DestinationCountry>;
};

export type OriginCountry = {
  __typename?: "OriginCountry";
  countryName?: Maybe<Scalars["String"]["output"]>;
  origins: Array<OriginAirport>;
};

export type OverRefundSectionContent = {
  __typename?: "OverRefundSectionContent";
  copy?: Maybe<UiSecondaryButton>;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  overRefundAmount?: Maybe<EgdsNumberInputField>;
  reset?: Maybe<UiSecondaryButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type Overflow = "TRUNCATE" | "TRUNCATE_FOUR_LINES" | "TRUNCATE_THREE_LINES" | "TRUNCATE_TWO_LINES" | "WRAP";

export type OverviewTableColumn = {
  __typename?: "OverviewTableColumn";
  align: Scalars["String"]["output"];
  border?: Maybe<CampaignOverviewBorder>;
  fieldName: Scalars["String"]["output"];
  frozenColumn?: Maybe<Scalars["String"]["output"]>;
  headerName: Scalars["String"]["output"];
  sortIcon?: Maybe<Icon>;
  sortable: Scalars["Boolean"]["output"];
};

export type PackageCard = {
  __typename?: "PackageCard";
  accessibility: Scalars["String"]["output"];
  bundleDetails: Array<PackageCardProductDetails>;
  cardAction?: Maybe<PackageUiCardAction>;
  headerSection?: Maybe<PackageUiHeaderSection>;
  identifier: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  mediaSection: PackageCardMediaSection;
  priceSection: PackageCardPriceSection;
};

export type PackageCardFlightSection = {
  __typename?: "PackageCardFlightSection";
  productDetails: Array<PackageUiProductDetail>;
  productTitle: EgdsGraphicText;
};

export type PackageCardLodgingSection = {
  __typename?: "PackageCardLodgingSection";
  productDetails: Array<PackageUiProductDetail>;
  productTitle: EgdsGraphicText;
};

export type PackageCardMediaSection = {
  __typename?: "PackageCardMediaSection";
  badge?: Maybe<EgdsBadge>;
  gallery: MishopUiGalleryCarousel;
};

export type PackageCardPriceSection = {
  __typename?: "PackageCardPriceSection";
  badge?: Maybe<EgdsBadge>;
  priceSummary: PackageUiPrice;
};

export type PackageCardProductDetails = PackageCardFlightSection | PackageCardLodgingSection;

export interface PackageDestinationContextInput {
  placeCoordinates?: InputMaybe<CoordinatesInput>;
  placeId: DiscoveryPlaceIdInput;
}

export type PackageDestinationQueries = {
  __typename?: "PackageDestinationQueries";
  morePackageDestinations: PackageUiMoreDestinationsResponse;
  packageDestinationSummary: PackageUiDestinationSummaryResponse;
};

export type PackageDestinationQueriesMorePackageDestinationsArgs = {
  searchCriteria: PackageSearchCriteriaInput;
};

export type PackageDetailsMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PackageDetailsMultiItemShoppingAction";
  priceToken?: Maybe<Scalars["String"]["output"]>;
  shoppingContext: ShoppingContext;
};

export type PackageDetailsPrimersError = {
  __typename?: "PackageDetailsPrimersError";
  content: PackageUiEmptyStateError;
};

export type PackageDetailsProductContentPrimer = {
  __typename?: "PackageDetailsProductContentPrimer";
  headerPrimer: PackageUiProductHeaderPrimer;
  productChangedBannerPrimer?: Maybe<PackageUiProductChangedBannerPrimer>;
  productContentPrimer: PackageUiProductContentPrimer;
};

export interface PackageFiltersInput {
  flightClass?: InputMaybe<Scalars["String"]["input"]>;
  starRating?: InputMaybe<Scalars["String"]["input"]>;
  tripLengthInNights?: InputMaybe<Scalars["String"]["input"]>;
}

export type PackageFlightsSection = {
  __typename?: "PackageFlightsSection";
  flightDetailInfoSection: Array<FlightDetailInfoSection>;
  flightSummarySection: FlightSummarySection;
};

export type PackageInfo = {
  __typename?: "PackageInfo";
  flightSection: PackageFlightsSection;
  packagePriceSection: PackagePriceSummarySection;
};

export type PackageLocation = {
  __typename?: "PackageLocation";
  airportCode: Scalars["String"]["output"];
  coordinates?: Maybe<Coordinates>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
};

export interface PackageLocationInput {
  airportCode: Scalars["String"]["input"];
  coordinates?: InputMaybe<CoordinatesInput>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  regionName?: InputMaybe<Scalars["String"]["input"]>;
}

export type PackageNaturalKey = {
  __typename?: "PackageNaturalKey";
  offerToken: Scalars["String"]["output"];
};

export interface PackageNaturalKeyInput {
  offerToken: Scalars["String"]["input"];
}

export type PackageOfferItem = OfferItem & {
  __typename?: "PackageOfferItem";
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  flightOfferItem?: Maybe<FlightOfferItem>;
  hotelOfferItem?: Maybe<HotelOfferItem>;
  image: Image;
  name: Scalars["String"]["output"];
  packageOffer?: Maybe<OfferData>;
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
};

export type PackageOfferingType = "FLIGHT_CAR" | "FLIGHT_HOTEL" | "FLIGHT_HOTEL_CAR" | "HOTEL_CAR";

export type PackagePriceSummarySection = {
  __typename?: "PackagePriceSummarySection";
  badgeText?: Maybe<Scalars["String"]["output"]>;
  badgeVariant?: Maybe<Scalars["String"]["output"]>;
  disclaimerCloseButton?: Maybe<UiTertiaryButton>;
  disclaimerText?: Maybe<Scalars["String"]["output"]>;
  discoveryText?: Maybe<Scalars["String"]["output"]>;
  displayPrice?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  packageDates?: Maybe<Scalars["String"]["output"]>;
  priceQualifier?: Maybe<Scalars["String"]["output"]>;
  savings?: Maybe<Scalars["String"]["output"]>;
  strikeOutPrice?: Maybe<Scalars["String"]["output"]>;
};

export type PackagePrimersQueries = {
  __typename?: "PackagePrimersQueries";
  dynamicPackageDetailsPrimers: DynamicPackageDetailsPrimers;
  prebundlePackageDetailsPrimers: PrebundlePackageDetailsPrimers;
};

export type PackagePrimersQueriesDynamicPackageDetailsPrimersArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  multiItemSessionId: Scalars["String"]["input"];
  recoveryNaturalKeys?: InputMaybe<MishopRecoveryNaturalKeysInput>;
};

export type PackagePrimersQueriesPrebundlePackageDetailsPrimersArgs = {
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  multiItemSessionId: Scalars["String"]["input"];
};

export type PackageProductCard = SelectedBundleProductWrapper;

export type PackageSearchCriteria = {
  __typename?: "PackageSearchCriteria";
  primary: PrimaryPackageSearchCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface PackageSearchCriteriaInput {
  primary: PrimaryPackageSearchCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type PackageSearchForm = EgdsSearchForm & {
  __typename?: "PackageSearchForm";
  actionURL: Scalars["String"]["output"];
  elements: Array<EgdsSearchFormElement>;
  errorSummary?: Maybe<EgdsErrorSummary>;
  playback?: Maybe<EgdsSearchPlayback>;
  search?: Maybe<EgdsButton>;
  searchCriteria: PackageSearchCriteria;
};

export type PackageSearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PackageSearchMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type PackageSearchResultsErrorResponse = {
  __typename?: "PackageSearchResultsErrorResponse";
  errorAction: PackageUiButton;
  heading: Scalars["String"]["output"];
  icon: Icon;
  secondaries: Array<EgdsText>;
};

export type PackageSearchResultsListing =
  | EgdsHeading
  | EgdsStandardMessagingCard
  | PackageCard
  | PackageUiClientIllustrationCard
  | PackageUiMessagingCard;

export type PackageSearchResultsQueries = {
  __typename?: "PackageSearchResultsQueries";
  packageSearch: PackageSearchResultsResponse;
  sortAndFilter?: Maybe<ShoppingSortAndFilters>;
};

export type PackageSearchResultsQueriesPackageSearchArgs = {
  searchContext: MultiItemSearchContextInput;
};

export type PackageSearchResultsQueriesSortAndFilterArgs = {
  searchContext: MultiItemSearchContextInput;
};

export type PackageSearchResultsResponse = PackageSearchResultsErrorResponse | PackageSearchResultsSuccessResponse;

export type PackageSearchResultsSuccessResponse = {
  __typename?: "PackageSearchResultsSuccessResponse";
  adaptExEvents: Array<EgUisMicroMessage>;
  disclaimer: Scalars["String"]["output"];
  mapAction?: Maybe<PackagesOpenMapAction>;
  packageSearchResults: Array<PackageSearchResultsListing>;
  recommendedPropertiesSelectionInfo?: Maybe<EgdsStandardLink>;
  searchContext: MultiItemSearchContext;
};

export type PackageSort = "PRICE_HIGH_TO_LOW" | "PRICE_LOW_TO_HIGH" | "RECOMMENDED";

export type PackageType = "FLIGHT_CAR" | "FLIGHT_HOTEL" | "FLIGHT_HOTEL_CAR" | "HOTEL_ACTIVITY" | "HOTEL_CAR";

export type PackageUiActionDialogFooterButton = EgdsButton & {
  __typename?: "PackageUIActionDialogFooterButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  dialogAction: PackageUiActionDialogFooterButtonAction;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
};

export type PackageUiActionDialogFooterButtonAction =
  | PackageUiActionDialogFooterButtonCloseAction
  | PackageUiActionDialogSearchLinkAction;

export type PackageUiActionDialogFooterButtonCloseAction = {
  __typename?: "PackageUIActionDialogFooterButtonCloseAction";
  analytics: ClientSideAnalytics;
};

export type PackageUiActionDialogSearchLinkAction = {
  __typename?: "PackageUIActionDialogSearchLinkAction";
  analytics: ClientSideAnalytics;
  newTabAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  resource: Uri;
  target: UiLinkTarget;
};

export type PackageUiActivityHeaderPrimer = {
  __typename?: "PackageUIActivityHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiActivityHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiButton = {
  __typename?: "PackageUIButton";
  action: PackageUiButtonAction;
  button: EgdsButton;
};

export type PackageUiButtonAction = PackageUiHomepageAction | PackageUiResetFiltersAction | UiLinkAction;

export type PackageUiCardAction = {
  __typename?: "PackageUICardAction";
  accessibility: Scalars["String"]["output"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
};

export type PackageUiCarouselByTheme = {
  __typename?: "PackageUICarouselByTheme";
  carousel: MishopUiCarousel;
  recommendationsAnalytics: Array<RecommendationAnalytics>;
  theme: Scalars["String"]["output"];
};

export type PackageUiChangePackageFormPrimer = {
  __typename?: "PackageUIChangePackageFormPrimer";
  accessibility: Scalars["String"]["output"];
  searchCriteria: PackageSearchCriteria;
};

export type PackageUiClientIllustrationCard = {
  __typename?: "PackageUIClientIllustrationCard";
  heading?: Maybe<Scalars["String"]["output"]>;
  illustration: ClientSideIllustrationAsset;
};

export type PackageUiDescriptionExpando = {
  __typename?: "PackageUIDescriptionExpando";
  content: Scalars["String"]["output"];
  item: EgdsExpandoPeek;
};

export type PackageUiDestination = {
  __typename?: "PackageUIDestination";
  imageAction: PackageUiDestinationAction;
  name: Scalars["String"]["output"];
};

export type PackageUiDestinationAction = {
  __typename?: "PackageUIDestinationAction";
  imageLink: EgdsImageLink;
};

export type PackageUiDestinationEditorial = {
  __typename?: "PackageUIDestinationEditorial";
  /**
   * @deprecated
   *         This is a temporary component with unformatted html content that we are using for our web launch of prebundled packages.
   *
   *         A new component is being worked on that will have structured content that we will use to replace this html content when it's available for both web and app.
   *
   *         https://jira.expedia.biz/browse/POT-19295
   *
   *         https://pages.github.expedia.biz/Expedia-UI/shared-ui/components/retail/travel-content/editorial/editorial
   *
   */
  htmlContent: Scalars["String"]["output"];
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type PackageUiDestinationEditorialExpando = {
  __typename?: "PackageUIDestinationEditorialExpando";
  /**
   * @deprecated
   *         This is a temporary component with unformatted html content that we are using for our web launch of prebundled packages.
   *
   *         A new component is being worked on that will have structured content that we will use to replace this html content when it's available for both web and app.
   *
   *         https://jira.expedia.biz/browse/POT-19295
   *
   *         https://pages.github.expedia.biz/Expedia-UI/shared-ui/components/retail/travel-content/editorial/editorial
   *
   */
  htmlContent: Scalars["String"]["output"];
  item: EgdsExpandoListItem;
};

export type PackageUiDestinationSummary = {
  __typename?: "PackageUIDestinationSummary";
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  editorialExpandos: Array<PackageUiDestinationEditorialExpando>;
  editorials: Array<PackageUiDestinationEditorial>;
  header: PackageUiDestinationSummaryHeader;
  travelGuide?: Maybe<PackageUiTravelGuideButton>;
};

export type PackageUiDestinationSummaryHeader = {
  __typename?: "PackageUIDestinationSummaryHeader";
  image: Image;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type PackageUiDestinationSummaryResponse = PackageUiDestinationSummary;

export type PackageUiDisclaimerDialog = {
  __typename?: "PackageUIDisclaimerDialog";
  analytics: ClientSideAnalytics;
  dialog: EgdsActionDialog;
  graphic: UiGraphic;
  text: Scalars["String"]["output"];
};

export interface PackageUiEmptyStateError {
  errorAction?: Maybe<PackageUiErrorAction>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
}

export type PackageUiEntryCardAction = PackageUiButton | PackageUiFloatingButton;

export type PackageUiEntryCardSubHeading = EgdsPlainText;

export interface PackageUiErrorAction {
  analytics: ClientSideAnalytics;
  button: EgdsButton;
}

export type PackageUiExternalLinkAction = PackageUiErrorAction & {
  __typename?: "PackageUIExternalLinkAction";
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  button: EgdsButton;
  resource?: Maybe<Uri>;
};

export type PackageUiExternalLinkEmptyStateError = PackageUiEmptyStateError & {
  __typename?: "PackageUIExternalLinkEmptyStateError";
  errorAction?: Maybe<PackageUiExternalLinkAction>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
};

export type PackageUiFlightHeaderPrimer = {
  __typename?: "PackageUIFlightHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiFlightHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiFloatingButton = {
  __typename?: "PackageUIFloatingButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: PackageUiButtonAction;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type PackageUiGroundTransferHeaderPrimer = {
  __typename?: "PackageUIGroundTransferHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiGroundTransferHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiHeaderSection = PackageUiMerchandisingHeaderSection;

export type PackageUiHideSearchFormAction = EgdsButton & {
  __typename?: "PackageUIHideSearchFormAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
};

export type PackageUiHomepageAction = {
  __typename?: "PackageUIHomepageAction";
  analytics: ClientSideAnalytics;
};

export type PackageUiIncludedProducts = PackageUiIncludedProductsTitles;

export type PackageUiIncludedProductsDetails = {
  __typename?: "PackageUIIncludedProductsDetails";
  includedProducts: PackageUiIncludedProducts;
  title: EgdsPlainText;
};

export type PackageUiIncludedProductsTitles = {
  __typename?: "PackageUIIncludedProductsTitles";
  titles: EgdsTextIconList;
};

export type PackageUiMerchandisingHeaderSection = {
  __typename?: "PackageUIMerchandisingHeaderSection";
  badge?: Maybe<EgdsBadge>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type PackageUiMessageItem = EgdsPlainText | EgdsStylizedText;

export type PackageUiMessagingCard = {
  __typename?: "PackageUIMessagingCard";
  action?: Maybe<PackageUiButton>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<EgdsHeading>;
  secondaries: Array<EgdsText>;
};

export type PackageUiMessagingCardInputs = {
  __typename?: "PackageUIMessagingCardInputs";
  destinationTla?: Maybe<Scalars["String"]["output"]>;
  hotelId?: Maybe<Scalars["String"]["output"]>;
};

export interface PackageUiMessagingCardInputsInput {
  destinationTla?: InputMaybe<Scalars["String"]["input"]>;
  hotelId?: InputMaybe<Scalars["String"]["input"]>;
}

export type PackageUiMessagingCardResponse = {
  __typename?: "PackageUIMessagingCardResponse";
  cards: Array<EgdsStandardMessagingCard>;
};

export type PackageUiMessagingCardType = "GT_JUMP_LINK" | "INVALID";

export type PackageUiMessagingPrimer = {
  __typename?: "PackageUIMessagingPrimer";
  inputs: PackageUiMessagingCardInputs;
  type: PackageUiMessagingCardType;
};

export type PackageUiMoreDestinations = {
  __typename?: "PackageUIMoreDestinations";
  destinations: Array<PackageUiDestination>;
  title: Scalars["String"]["output"];
};

export type PackageUiMoreDestinationsResponse = PackageUiMoreDestinations;

export type PackageUiNearbyAirports = {
  __typename?: "PackageUINearbyAirports";
  airports: Array<EgdsStandardLink>;
  primary: Scalars["String"]["output"];
};

export type PackageUiPolicies = PackageUiPoliciesSuccess;

export type PackageUiPoliciesPrimer = {
  __typename?: "PackageUIPoliciesPrimer";
  products: MultiItemProducts;
};

export type PackageUiPoliciesSuccess = {
  __typename?: "PackageUIPoliciesSuccess";
  policyContentSections: Array<PackageUiPolicyContentSection>;
  referenceId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PackageUiPoliciesText = EgdsIconText | EgdsPlainText;

export type PackageUiPolicyContentErrorSection = PackageUiPolicyContentSection & {
  __typename?: "PackageUIPolicyContentErrorSection";
  errorMessage: EgdsStylizedText;
  sectionTitle: Scalars["String"]["output"];
};

export interface PackageUiPolicyContentSection {
  sectionTitle: Scalars["String"]["output"];
}

export type PackageUiPolicyContentSuccessSection = PackageUiPolicyContentSection & {
  __typename?: "PackageUIPolicyContentSuccessSection";
  productPolicies: Array<PackageUiProductPolicy>;
  sectionTitle: Scalars["String"]["output"];
};

export type PackageUiPolicyOverview = PackageUiPolicyContentSection & {
  __typename?: "PackageUIPolicyOverview";
  description: Scalars["String"]["output"];
  sectionTitle: Scalars["String"]["output"];
};

export type PackageUiPreBundledCard = {
  __typename?: "PackageUIPreBundledCard";
  action: PackageUiSelectPackageButton;
  analytics: ClientSideAnalytics;
  /** @deprecated Displaying only 1 image per card so deprecating this node in favor of the image node */
  gallery: MishopUiGalleryCarousel;
  image?: Maybe<Image>;
  includedProductsDetails: PackageUiIncludedProductsDetails;
  /** @deprecated Deprecating this node in favor of priceSummary node */
  price: EgdsPriceLockup;
  priceSummary: PackageUiPrice;
  theme: EgdsHeading;
  title: EgdsHeading;
};

export type PackageUiPreBundledCards = {
  __typename?: "PackageUIPreBundledCards";
  cards: Array<PackageUiPreBundledCard>;
};

export type PackageUiPreBundledPackageSearchEntryContent = EgdsBulletedList | EgdsParagraph;

export type PackageUiPrice = {
  __typename?: "PackageUIPrice";
  accessibility: Scalars["String"]["output"];
  badge?: Maybe<EgdsBadge>;
  messageItems: Array<PackageUiMessageItem>;
  priceDisplay: PackageUiPriceDisplay;
};

export type PackageUiPriceDisplay = {
  __typename?: "PackageUIPriceDisplay";
  price: Scalars["String"]["output"];
  strikethroughPrice?: Maybe<PackageUiStrikethroughPrice>;
};

export type PackageUiProductChangedBannerPrimer = {
  __typename?: "PackageUIProductChangedBannerPrimer";
  currentPackageOfferId: PackageNaturalKey;
  previousOffers: MultiItemProducts;
  previousPackageOfferId: PackageNaturalKey;
};

export interface PackageUiProductChangedBannerPrimerInput {
  currentPackageOfferId: PackageNaturalKeyInput;
  previousOffers: MultiItemProductsInput;
  previousPackageOfferId: PackageNaturalKeyInput;
}

export type PackageUiProductContentPrimer =
  | MishopUiActivitiesCrossSellPrimer
  | MishopUiCarContentPrimer
  | MishopUiFlightContentPrimer
  | MishopUiGroundTransfersCrossSellPrimer
  | MishopUiLegalContentPrimer
  | MishopUiPropertyContentPrimer
  | MishopUiSimplePropertyContentPrimer;

export type PackageUiProductDetail = EgdsPlainText | EgdsSpannableText;

export type PackageUiProductHeaderPrimer = {
  __typename?: "PackageUIProductHeaderPrimer";
  activityHeaderPrimer?: Maybe<PackageUiActivityHeaderPrimer>;
  flightHeaderPrimer?: Maybe<PackageUiFlightHeaderPrimer>;
  groundTransferHeaderPrimer?: Maybe<PackageUiGroundTransferHeaderPrimer>;
  propertyHeaderPrimer?: Maybe<PackageUiPropertyHeaderPrimer>;
};

export interface PackageUiProductHeaderPrimerInput {
  activityHeaderPrimer?: InputMaybe<PackageUiActivityHeaderPrimerInput>;
  flightHeaderPrimer?: InputMaybe<PackageUiFlightHeaderPrimerInput>;
  groundTransferHeaderPrimer?: InputMaybe<PackageUiGroundTransferHeaderPrimerInput>;
  propertyHeaderPrimer?: InputMaybe<PackageUiPropertyHeaderPrimerInput>;
}

export type PackageUiProductPolicy = {
  __typename?: "PackageUIProductPolicy";
  policyDescriptions: Array<PackageUiPoliciesText>;
  productTitle: Scalars["String"]["output"];
};

export type PackageUiPropertyHeaderPrimer = {
  __typename?: "PackageUIPropertyHeaderPrimer";
  productType: MishopUiProductType;
};

export interface PackageUiPropertyHeaderPrimerInput {
  productType: MishopUiProductType;
}

export type PackageUiResetFiltersAction = {
  __typename?: "PackageUIResetFiltersAction";
  analytics: ClientSideAnalytics;
};

export type PackageUiSeeAllPackagesLink = PackageUiSeeAllPackagesLinkSuccess;

export type PackageUiSeeAllPackagesLinkPrimer = {
  __typename?: "PackageUISeeAllPackagesLinkPrimer";
  searchCriteria: PackageSearchCriteria;
};

export type PackageUiSeeAllPackagesLinkSuccess = {
  __typename?: "PackageUISeeAllPackagesLinkSuccess";
  seeAllPackages: EgdsStandardLink;
};

export type PackageUiSelectPackageButton = MishopUiButton & {
  __typename?: "PackageUISelectPackageButton";
  accessibility: Scalars["String"]["output"];
  action: MultiItemShoppingAction;
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  recommendationsAnalytics: Array<RecommendationAnalytics>;
};

export type PackageUiStrikethroughPrice = {
  __typename?: "PackageUIStrikethroughPrice";
  disclaimer: PackageUiDisclaimerDialog;
  price: Scalars["String"]["output"];
};

export type PackageUiTravelGuideAction = {
  __typename?: "PackageUITravelGuideAction";
  regionId: Scalars["String"]["output"];
  resource: Uri;
};

export type PackageUiTravelGuideButton = EgdsButton & {
  __typename?: "PackageUITravelGuideButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  buttonAction: PackageUiTravelGuideAction;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
};

export type PackageUiTripSaveItemPrimer = {
  __typename?: "PackageUITripSaveItemPrimer";
  encodedItemId: Scalars["String"]["output"];
};

export interface PackagesInputCriteriaInput {
  inputContext: PackagesListingInputContextInput;
}

export interface PackagesListingInputContextInput {
  configurationId: Scalars["String"]["input"];
  destination: PackageDestinationContextInput;
  packageFilters?: InputMaybe<PackageFiltersInput>;
  packageType: PackageOfferingType;
  pageId: Scalars["String"]["input"];
}

export type PackagesMap = {
  __typename?: "PackagesMap";
  cards: Array<PackageCard>;
  closeAnalytics: ClientSideAnalytics;
  map: EgdsBasicMap;
};

export type PackagesOpenMapAction = {
  __typename?: "PackagesOpenMapAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  map: PackagesMap;
  openAnalytics: ClientSideAnalytics;
};

export type PackagesSearchForm = EgdsElement &
  EgdsSearchForm & {
    __typename?: "PackagesSearchForm";
    actionURL: Scalars["String"]["output"];
    /** @deprecated Use searchLocation */
    destination?: Maybe<SearchLocation>;
    directFlightsOnly?: Maybe<EgdsBasicPill>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsSearchFormElement>;
    errorSummary?: Maybe<EgdsErrorSummary>;
    packageSelection?: Maybe<PackagesSearchFormSelection>;
    partialStay?: Maybe<SearchFormPartialStay>;
    playback?: Maybe<EgdsSearchPlayback>;
    search?: Maybe<EgdsButton>;
    searchLocation?: Maybe<SearchLocation>;
    valuePropMessage?: Maybe<SearchFormMessaging>;
  };

export type PackagesSearchFormSelection = {
  __typename?: "PackagesSearchFormSelection";
  car?: Maybe<EgdsBasicPill>;
  flight?: Maybe<EgdsBasicPill>;
  noSelectionMessage: UiEmptyState;
  stay?: Maybe<EgdsBasicPill>;
};

export interface PageContextInput {
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  componentName?: InputMaybe<Scalars["String"]["input"]>;
  propertySearch?: InputMaybe<PropertySearchCriteriaInput>;
}

export type PageLayoutExperimentalFeature = LodgingExperimentalFeature & {
  __typename?: "PageLayoutExperimentalFeature";
  description: Scalars["String"]["output"];
  experiment: LodgingExperiment;
  implementationDate: Date;
  owner: LodgingExperimentOwner;
  templateName?: Maybe<Scalars["String"]["output"]>;
};

export type PageLocation =
  | "CFAR_DETAILS"
  | "CHECKOUT"
  | "CHECKOUT_ERROR"
  | "CONFIRMATION"
  | "DETAILS"
  | "ENTRY"
  | "FEATURES"
  | "INSURANCE_DETAILS"
  | "ITINERARY"
  | "PAYMENT_ERROR"
  | "POST_BOOKING"
  | "SEARCH_RESULTS"
  | "SYSTEM"
  | "USER";

export interface PageLocationContextInput {
  flightPage?: InputMaybe<FlightPage>;
}

export interface PageMetadataInput {
  offsetMetadata: OffsetInput;
  offsetValue: Scalars["String"]["input"];
}

export type PageType = "DISCOVER_TAB" | "HOME_PAGE" | "TRAVEL_GUIDE" | "TRIP_OVERVIEW" | "WISH_LIST";

export type Pagination = {
  __typename?: "Pagination";
  size?: Maybe<Scalars["Int"]["output"]>;
  startingIndex?: Maybe<Scalars["Int"]["output"]>;
};

export type PaginationDetails = {
  __typename?: "PaginationDetails";
  next?: Maybe<Scalars["Int"]["output"]>;
  pageSize?: Maybe<Scalars["Int"]["output"]>;
  previous?: Maybe<Scalars["Int"]["output"]>;
};

export interface PaginationDetailsInput {
  next?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  previous?: InputMaybe<Scalars["Int"]["input"]>;
}

export interface PaginationInput {
  size?: InputMaybe<Scalars["Int"]["input"]>;
  startingIndex?: InputMaybe<Scalars["Int"]["input"]>;
}

export type PagingControls = {
  __typename?: "PagingControls";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  nextAccessibility?: Maybe<Scalars["String"]["output"]>;
  nextDisabledAccessibility?: Maybe<Scalars["String"]["output"]>;
  previousAccessibility?: Maybe<Scalars["String"]["output"]>;
  previousDisabledAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type PaidBagsTemplateAnalytics = TemplateAnalytics & {
  __typename?: "PaidBagsTemplateAnalytics";
  analytics: ClientSideAnalytics;
  analyticsIdentifier: AnalyticsKey;
  bagsQuantity?: Maybe<BagsSelectionType>;
};

export interface ParticipantInput {
  participantId?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type Partner = "AGENCY" | "MERCHANT" | "MERCHANT_AGENCY";

export interface PartnerContextInput {
  detailsSiteConfiguration?: InputMaybe<TravelStoreV1CarDetailsInput>;
  partnerAccountId: Scalars["String"]["input"];
  searchSiteConfiguration?: InputMaybe<TravelStoreV1CarSearchInput>;
}

export type PartnerLoyaltyPoints = {
  __typename?: "PartnerLoyaltyPoints";
  balance?: Maybe<EgdsStylizedText>;
  description?: Maybe<EgdsStylizedText>;
  shortDescription?: Maybe<EgdsStylizedText>;
  value?: Maybe<EgdsStylizedText>;
};

export type PartnerLoyaltyPointsComponent = HeaderSharedUi & {
  __typename?: "PartnerLoyaltyPointsComponent";
  skipSsr: Scalars["Boolean"]["output"];
};

export type PartnerMemberMessageCard = {
  __typename?: "PartnerMemberMessageCard";
  graphic?: Maybe<UiGraphic>;
  heading?: Maybe<EgdsStylizedText>;
  links?: Maybe<Array<EgdsStandardLink>>;
  subHeading?: Maybe<EgdsStylizedText>;
  themeProvider?: Maybe<EgdsThemeProvider>;
};

export type PartnerRecommendationCard = CarRecommendationCard & {
  __typename?: "PartnerRecommendationCard";
  action?: Maybe<CarAction>;
  analytics?: Maybe<CarAnalytics>;
  button?: Maybe<CarActionableItem>;
  dialog?: Maybe<PartnerRecommendationDialog>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  subText?: Maybe<CarPhrase>;
  title?: Maybe<CarPhrase>;
};

export type PartnerRecommendationDialog = {
  __typename?: "PartnerRecommendationDialog";
  button: CarActionableItem;
  closeDialog: CarAction;
  content: PartnerRecommendationDialogContent;
  image: Image;
  subTitle: CarPhrase;
  text: Array<Array<CarPhrase>>;
  title: CarPhrase;
};

export type PartnerRecommendationDialogContent = {
  __typename?: "PartnerRecommendationDialogContent";
  body: Array<Array<CarPhrase>>;
  confidenceMessage: Array<CarPhrase>;
  title: CarPhrase;
};

export type PartnerType = "AGENCY" | "MERCHANT" | "MERCHANT_AGENCY";

export type PassengerFareDetails = {
  __typename?: "PassengerFareDetails";
  roomPrice: Scalars["String"]["output"];
  roomType: Scalars["String"]["output"];
  serviceChargeDetail?: Maybe<ServiceChargeDetail>;
  taxesAndFees: Scalars["String"]["output"];
  taxesAndFeesLabel: Scalars["String"]["output"];
  totalChargesAccessibleText?: Maybe<Scalars["String"]["output"]>;
  travelerLabel: Scalars["String"]["output"];
  travelerPrice: Scalars["String"]["output"];
};

export type PasswordWithStrengthMeter = {
  __typename?: "PasswordWithStrengthMeter";
  hints: Array<PasswordWithStrengthMeterHint>;
  password: PasswordWithStrengthMeterInputField;
  progressBar: PasswordWithStrengthMeterProgressBar;
};

export type PasswordWithStrengthMeterCustomInputValidation = EgdsInputValidation & {
  __typename?: "PasswordWithStrengthMeterCustomInputValidation";
  errorMessage: Scalars["String"]["output"];
  pattern: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
};

export type PasswordWithStrengthMeterHint = {
  __typename?: "PasswordWithStrengthMeterHint";
  state: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type PasswordWithStrengthMeterInputField = {
  __typename?: "PasswordWithStrengthMeterInputField";
  analytics: Array<EgClickstreamEvent>;
  password: EgdsPasswordInputField;
};

export type PasswordWithStrengthMeterInputStrength = "STRONG" | "VERY_STRONG" | "WEAK";

export type PasswordWithStrengthMeterProgressBar = {
  __typename?: "PasswordWithStrengthMeterProgressBar";
  progressBar: EgdsProgressBar;
  progressBarCriteria: Array<PasswordWithStrengthMeterProgressBarCriteria>;
};

export type PasswordWithStrengthMeterProgressBarCriteria = {
  __typename?: "PasswordWithStrengthMeterProgressBarCriteria";
  description: Scalars["String"]["output"];
  strength: PasswordWithStrengthMeterInputStrength;
  theme: PasswordWithStrengthMeterProgressBarTheme;
};

export type PasswordWithStrengthMeterProgressBarTheme = "MUTED" | "POSITIVE";

export type Pattern = {
  __typename?: "Pattern";
  description: Scalars["String"]["output"];
  hasChildren: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  type: ExperimentalElements;
};

export interface PaymentAction {
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
}

export interface PaymentActionableComponent {
  hideOnAction?: Maybe<Scalars["String"]["output"]>;
  showOnAction?: Maybe<Scalars["String"]["output"]>;
}

export type PaymentAdditionalMessage = {
  __typename?: "PaymentAdditionalMessage";
  messages?: Maybe<Array<PaymentTypeMessage>>;
};

export type PaymentAffirmDetails = PaymentFopDetails & {
  __typename?: "PaymentAffirmDetails";
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  metadata?: Maybe<ContextualInfo>;
  priceDetails?: Maybe<PaymentPriceDetails>;
  title: Scalars["String"]["output"];
};

export type PaymentAffirmDetailsMessageCard = {
  __typename?: "PaymentAffirmDetailsMessageCard";
  content: UiMessagingCard;
};

export type PaymentAllowedBrandValidation = EgdsInputValidation & {
  __typename?: "PaymentAllowedBrandValidation";
  allowedValues: Array<Scalars["String"]["output"]>;
  errorMessage: Scalars["String"]["output"];
};

export interface PaymentApplePayAuthorisationInput {
  token: PaymentApplePayTokenDataInput;
}

export interface PaymentApplePayConfigurationInput {
  merchantValidation?: InputMaybe<PaymentApplePayMerchantValidationInput>;
  paymentAuthorisation?: InputMaybe<PaymentApplePayAuthorisationInput>;
}

export type PaymentApplePayDetails = PaymentFopDetails & {
  __typename?: "PaymentApplePayDetails";
  bookingParameters: Array<PaymentConfigurationInfo>;
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  metaData: ContextualInfo;
  paymentRequest: PaymentApplePayRequest;
  priceDetails?: Maybe<PaymentPriceDetails>;
  title: Scalars["String"]["output"];
  views?: Maybe<Array<PaymentInstrumentView>>;
};

export type PaymentApplePayMerchantDetails = {
  __typename?: "PaymentApplePayMerchantDetails";
  merchantCapabilities: Array<Scalars["String"]["output"]>;
  merchantIdentifier: Scalars["String"]["output"];
};

export interface PaymentApplePayMerchantValidationInput {
  initiativeContext: Scalars["String"]["input"];
  validationUrl: Scalars["String"]["input"];
}

export interface PaymentApplePayMethodInput {
  displayName: Scalars["String"]["input"];
}

export type PaymentApplePayRequest = {
  __typename?: "PaymentApplePayRequest";
  countryCode: Scalars["String"]["output"];
  currencyCode: Scalars["String"]["output"];
  merchantDetails: PaymentApplePayMerchantDetails;
  paymentSummaryItems: Array<PaymentPriceDetails>;
  requiredBillingContactFields: Array<Scalars["String"]["output"]>;
  requiredShippingContactFields: Array<Scalars["String"]["output"]>;
  supportedNetworks: Array<Scalars["String"]["output"]>;
};

export interface PaymentApplePayTokenDataInput {
  paymentData: Scalars["String"]["input"];
  paymentMethod: PaymentApplePayMethodInput;
}

export type PaymentApplyErrorMessageFallback = PaymentInModuleFormError & {
  __typename?: "PaymentApplyErrorMessageFallback";
  error?: Maybe<MessageContent>;
};

export type PaymentApplyErrorMessageFallbackAction = PaymentAction & {
  __typename?: "PaymentApplyErrorMessageFallbackAction";
  /** @deprecated Not in use now. Will be removed in future */
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  /** @deprecated Not in use now. Will be removed in future */
  error: MessageContent;
};

export type PaymentApplyMultipleValidationFailure = PaymentInModuleFormError & {
  __typename?: "PaymentApplyMultipleValidationFailure";
  error?: Maybe<MessageContent>;
};

export type PaymentApplySingleValidationFailure = PaymentInModuleFormError & {
  __typename?: "PaymentApplySingleValidationFailure";
  error?: Maybe<MessageContent>;
};

export interface PaymentAssistBookingInput {
  agentEmailAddress?: InputMaybe<Scalars["String"]["input"]>;
  amount: Scalars["Float"]["input"];
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  conversationPartnerId?: InputMaybe<Scalars["String"]["input"]>;
  currencyCode: Scalars["String"]["input"];
  expUserId?: InputMaybe<Scalars["String"]["input"]>;
  inventoryType: Scalars["String"]["input"];
  languageId?: InputMaybe<Scalars["String"]["input"]>;
  lineOfBusinessType: LineOfBusinessType;
  paymentAssistTransactionType: PaymentAssistTransactionTypeInput;
  paymentType: AgencyBusinessModel;
  supplierId?: InputMaybe<Scalars["String"]["input"]>;
  tuid?: InputMaybe<Scalars["String"]["input"]>;
}

export type PaymentAssistCardTypeInput = "NEW" | "ORIGINAL" | "SAVED";

export type PaymentAssistTransactionTypeInput = "CHARGE" | "REFUND";

export type PaymentAssistTransactionTypeOutput = "CHARGE" | "REFUND";

export type PaymentAssistVendorBanners = {
  __typename?: "PaymentAssistVendorBanners";
  genericErrorWithReload: AgentToolsErrorBanner;
  genericErrorWithoutReload: AgentToolsErrorBanner;
  verifyNewCardOnChatError: AgentToolsErrorBanner;
  verifyNewCardOnVoiceError: AgentToolsErrorBanner;
  verifySavedCardError: AgentToolsErrorBanner;
  verifySavedCardOnVoiceError: AgentToolsErrorBanner;
};

export type PaymentCard = {
  __typename?: "PaymentCard";
  card: EgdsStandardMessagingCard;
  cardActionLinks?: Maybe<Array<PaymentLink>>;
  cardContent?: Maybe<Array<PaymentText>>;
  clickAction?: Maybe<PaymentAction>;
  leftIcon?: Maybe<Icon>;
  leftMark?: Maybe<Mark>;
};

export type PaymentCardCvvConfiguration = {
  __typename?: "PaymentCardCVVConfiguration";
  maxLength: Scalars["Int"]["output"];
  minLength?: Maybe<Scalars["Int"]["output"]>;
};

export type PaymentCardConfiguration = {
  __typename?: "PaymentCardConfiguration";
  cardPatterns?: Maybe<PaymentCardFormatPatterns>;
  maxLength: Scalars["Int"]["output"];
  pattern: Scalars["String"]["output"];
};

export type PaymentCardDetailsBillingZipCodeField = {
  __typename?: "PaymentCardDetailsBillingZipCodeField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export type PaymentCardDetailsCvvField = PaymentDetailsEmbeddedUiElement & {
  __typename?: "PaymentCardDetailsCVVField";
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
  interactiveIcon: PaymentInteractiveIcon;
};

export type PaymentCardDetailsExpirationMonthField = {
  __typename?: "PaymentCardDetailsExpirationMonthField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsBasicSelect;
  validations: Array<EgdsInputValidation>;
};

export type PaymentCardDetailsExpirationYearField = {
  __typename?: "PaymentCardDetailsExpirationYearField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsBasicSelect;
  validations: Array<EgdsInputValidation>;
};

export type PaymentCardDetailsNameField = {
  __typename?: "PaymentCardDetailsNameField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export type PaymentCardDetailsNumberField = PaymentDetailsEmbeddedUiElement & {
  __typename?: "PaymentCardDetailsNumberField";
  brandNameValidations: Array<EgdsInputValidation>;
  buildContentForPaymentCardFormatPatterns: PaymentCardFormatPatterns;
  buildContentForPaymentCardTypePattern: Array<PaymentCardTypePattern>;
  cardConfig: PaymentCardConfiguration;
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
};

export type PaymentCardDetailsTokenizationField = PaymentDetailsEmbeddedUiElement & {
  __typename?: "PaymentCardDetailsTokenizationField";
  accessTokenForCardTokenization: Scalars["String"]["output"];
  cardTokenizationServiceURI: Scalars["String"]["output"];
  config: PaymentEmbeddedElementConfig;
};

export type PaymentCardExpirationDateValidation = EgdsInputValidation & {
  __typename?: "PaymentCardExpirationDateValidation";
  errorMessage: Scalars["String"]["output"];
  expirationMonthId: Scalars["String"]["output"];
  expirationYearId: Scalars["String"]["output"];
};

export type PaymentCardFormatPatterns = {
  __typename?: "PaymentCardFormatPatterns";
  default: Scalars["String"]["output"];
  patterns: Array<PaymentCardTypePattern>;
};

export type PaymentCardNumberLuhnValidation = EgdsInputValidation & {
  __typename?: "PaymentCardNumberLuhnValidation";
  errorMessage: Scalars["String"]["output"];
};

export type PaymentCardTypePattern = {
  __typename?: "PaymentCardTypePattern";
  pattern: Scalars["String"]["output"];
  regex: Scalars["String"]["output"];
};

export type PaymentCheckboxField = {
  __typename?: "PaymentCheckboxField";
  content: EgdsBasicCheckBox;
};

export type PaymentCheckoutElement = {
  __typename?: "PaymentCheckoutElement";
  fopModule: Array<PaymentUiComponent>;
};

export type PaymentCheckoutIdentifier = {
  __typename?: "PaymentCheckoutIdentifier";
  sessionId?: Maybe<Scalars["String"]["output"]>;
  sessionToken?: Maybe<Scalars["String"]["output"]>;
};

export interface PaymentCheckoutIdentifierInput {
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  sessionToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type PaymentClickstreamAnalyticsData = {
  __typename?: "PaymentClickstreamAnalyticsData";
  eventName?: Maybe<Scalars["String"]["output"]>;
  eventVersion?: Maybe<Scalars["String"]["output"]>;
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type PaymentConfidenceNotes = {
  __typename?: "PaymentConfidenceNotes";
  confidenceNotes: Array<PaymentIconAndDescription>;
};

export type PaymentConfigurationInfo = {
  __typename?: "PaymentConfigurationInfo";
  entryUrn: PaymentEntryUrn;
  key: Scalars["String"]["output"];
};

export interface PaymentConfigurationItemInput {
  entryUrn: PaymentEntryUrn;
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type PaymentConfirmationRedirectResponse = {
  __typename?: "PaymentConfirmationRedirectResponse";
  actions: Array<CheckoutAction>;
  signals: Array<Maybe<CheckoutUiSignal>>;
};

export interface PaymentContextualInfoInput {
  bin?: InputMaybe<Scalars["String"]["input"]>;
  encodedBrowserMetadata?: InputMaybe<Scalars["String"]["input"]>;
  etzToken?: InputMaybe<Scalars["String"]["input"]>;
  providerConfirmationStatus?: InputMaybe<Scalars["String"]["input"]>;
}

export type PaymentCreditCardDetails = PaymentFopDetails & {
  __typename?: "PaymentCreditCardDetails";
  authenticationMethods?: Maybe<Array<Scalars["String"]["output"]>>;
  authenticationResources?: Maybe<Array<PaymentThreeDsResource>>;
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  /** @deprecated Will be used as list of viewType to support native */
  newCardView?: Maybe<PaymentInstrumentView>;
  priceDetails?: Maybe<PaymentPriceDetails>;
  /** @deprecated Will be used as list of viewType to support native */
  savedCardView?: Maybe<PaymentInstrumentView>;
  /** @deprecated Will be used as list of viewType to support native */
  savedCardViewWithNoCvv?: Maybe<PaymentInstrumentView>;
  title: Scalars["String"]["output"];
  views?: Maybe<Array<PaymentInstrumentView>>;
};

export interface PaymentDetailsEmbeddedUiElement {
  config: PaymentEmbeddedElementConfig;
}

export type PaymentDetailsUiElement =
  | PaymentAffirmDetailsMessageCard
  | PaymentCardDetailsBillingZipCodeField
  | PaymentCardDetailsCvvField
  | PaymentCardDetailsExpirationMonthField
  | PaymentCardDetailsExpirationYearField
  | PaymentCardDetailsNameField
  | PaymentCardDetailsNumberField
  | PaymentCardDetailsTokenizationField
  | PaymentCheckboxField
  | PaymentEvenColumnsField
  | PaymentInformativeInputField
  | PaymentInputCheckBoxField
  | PaymentInputSelectField
  | PaymentInputSelectFieldWithValidation
  | PaymentInputTextEmbeddedField
  | PaymentInputTextEmbeddedFieldWithConfiguration
  | PaymentInputTextField
  | PaymentInputTextFieldGc
  | PaymentInputTextFieldWithConfiguration
  | PaymentNaverPayForm
  | StoredInstrumentBillingZipCodeField;

export type PaymentDeviceType =
  | "APP_PHONE"
  | "APP_TABLET"
  | "DESKTOP"
  | "DEVICE_TYPE_INVALID"
  | "MOBILE_PHONE"
  | "MOBILE_TABLET";

export type PaymentDialog = {
  __typename?: "PaymentDialog";
  elements: Array<PaymentUiComponent>;
};

export type PaymentDialogButton = {
  __typename?: "PaymentDialogButton";
  /** @deprecated Not in use now. Will be removed in future */
  action?: Maybe<PaymentDialogButtonAction>;
  text: Scalars["String"]["output"];
  type: EgdsButtonStyle;
};

export type PaymentDialogButtonAction = UiAction & {
  __typename?: "PaymentDialogButtonAction";
  /** @deprecated Not in use now. Will be removed in future */
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionIdentifier?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Not in use now. Will be removed in future */
  analytics: ClientSideAnalytics;
};

export interface PaymentDialogContent {
  identifier: Scalars["String"]["output"];
}

export type PaymentDialogElement = {
  __typename?: "PaymentDialogElement";
  buttons: Array<PaymentDialogButton>;
  closeButton: EgdsButton;
  content: Array<PaymentDialogContent>;
  identifier: Scalars["String"]["output"];
};

export type PaymentEmbeddedElementConfig = {
  __typename?: "PaymentEmbeddedElementConfig";
  url?: Maybe<Scalars["String"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PaymentEntryUrn =
  | "PAYMENT_APPLE_PAY_COUNTRY_CODE"
  | "PAYMENT_APPLE_PAY_EMAIL_ADDRESS"
  | "PAYMENT_APPLE_PAY_FIRST_NAME"
  | "PAYMENT_APPLE_PAY_LAST_NAME"
  | "PAYMENT_APPLE_PAY_PHONE_NUMBER"
  | "PAYMENT_APPLE_PAY_POSTAL_CODE"
  | "PAYMENT_BILLING_ZIPCODE"
  | "PAYMENT_CONTACT_EMAIL"
  | "PAYMENT_CONTACT_PHONE"
  | "UNKNOWN";

export type PaymentEvenColumnsField = {
  __typename?: "PaymentEvenColumnsField";
  components: Array<PaymentDetailsUiElement>;
  /** @deprecated Use components instead of elements */
  elements: Array<PaymentDetailsUiElement>;
};

export type PaymentFopSelector = {
  __typename?: "PaymentFOPSelector";
  bottomPopoverTitle: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selectOptions: Array<PaymentInstrumentContainer>;
};

export interface PaymentFopDetails {
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  priceDetails?: Maybe<PaymentPriceDetails>;
  title: Scalars["String"]["output"];
}

export type PaymentFopModuleDetail = {
  __typename?: "PaymentFopModuleDetail";
  fopSpecificDetails: Array<PaymentFopDetails>;
};

export type PaymentForm = {
  __typename?: "PaymentForm";
  elements: Array<PaymentUiComponent>;
  errors: Array<PaymentInModuleFormError>;
  formId: Scalars["String"]["output"];
};

export type PaymentGiftCard = {
  __typename?: "PaymentGiftCard";
  balance?: Maybe<PaymentPriceDetails>;
  cardNumber?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentGiftCardApplyAction = PaymentAction & {
  __typename?: "PaymentGiftCardApplyAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentGiftCardClearAction = PaymentAction & {
  __typename?: "PaymentGiftCardClearAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentGiftCardRemoveAction = PaymentAction & {
  __typename?: "PaymentGiftCardRemoveAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHeading = {
  __typename?: "PaymentHeading";
  heading: EgdsHeading;
};

export type PaymentHideChallengeSheetAction = PaymentAction & {
  __typename?: "PaymentHideChallengeSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHideGiftCardSheetAction = PaymentAction & {
  __typename?: "PaymentHideGiftCardSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHideHelpSheetAction = PaymentAction & {
  __typename?: "PaymentHideHelpSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHideWarningSheetAction = PaymentAction & {
  __typename?: "PaymentHideWarningSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentHorizontalLine = {
  __typename?: "PaymentHorizontalLine";
  visible: Scalars["Boolean"]["output"];
};

export type PaymentIcon = {
  __typename?: "PaymentIcon";
  action?: Maybe<PaymentAction>;
  icon: Icon;
};

export type PaymentIconAndDescription = {
  __typename?: "PaymentIconAndDescription";
  description: Scalars["String"]["output"];
};

export type PaymentIconDialog = PaymentInteractiveIcon & {
  __typename?: "PaymentIconDialog";
  dialog: PaymentDialogElement;
  icon: Icon;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentIframe = {
  __typename?: "PaymentIframe";
  id?: Maybe<Scalars["String"]["output"]>;
  src?: Maybe<Scalars["String"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface PaymentInModuleFormError {
  error?: Maybe<MessageContent>;
}

export type PaymentInformativeInputField = PaymentInputFieldNamed & {
  __typename?: "PaymentInformativeInputField";
  content: EgdsTextInputField;
  interactiveIcon?: Maybe<PaymentIcon>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentInlineMessageModule = {
  __typename?: "PaymentInlineMessageModule";
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PaymentInputCheckBoxField = {
  __typename?: "PaymentInputCheckBoxField";
  content: EgdsBasicCheckBox;
};

export interface PaymentInputFieldNamed {
  name?: Maybe<Scalars["String"]["output"]>;
}

export type PaymentInputSelectField = {
  __typename?: "PaymentInputSelectField";
  content: EgdsBasicSelect;
};

export type PaymentInputSelectFieldWithValidation = {
  __typename?: "PaymentInputSelectFieldWithValidation";
  content: EgdsBasicSelect;
  validations: Array<PaymentInputValidation>;
};

export type PaymentInputTextEmbeddedField = {
  __typename?: "PaymentInputTextEmbeddedField";
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
  interactiveIcon: PaymentInteractiveIcon;
};

export type PaymentInputTextEmbeddedFieldWithConfiguration = {
  __typename?: "PaymentInputTextEmbeddedFieldWithConfiguration";
  cardConfig?: Maybe<CardConfiguration>;
  config: PaymentEmbeddedElementConfig;
  content: EgdsTextInputField;
  interactiveIcon: PaymentInteractiveIcon;
};

export type PaymentInputTextField = {
  __typename?: "PaymentInputTextField";
  content: EgdsTextInputField;
};

export type PaymentInputTextFieldGc = PaymentInputFieldNamed & {
  __typename?: "PaymentInputTextFieldGC";
  content: EgdsTextInputField;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentInputTextFieldWithConfiguration = {
  __typename?: "PaymentInputTextFieldWithConfiguration";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export type PaymentInputValidation = {
  __typename?: "PaymentInputValidation";
  validation: EgdsInputValidation;
};

export type PaymentInstrument = {
  __typename?: "PaymentInstrument";
  component: Scalars["String"]["output"];
  elementId?: Maybe<Scalars["String"]["output"]>;
  fopId: Scalars["String"]["output"];
  fopText: EgdsPlainText;
  isDefault: Scalars["Boolean"]["output"];
  isExpired?: Maybe<Scalars["Boolean"]["output"]>;
  isStoredCard?: Maybe<Scalars["Boolean"]["output"]>;
  logo: PaymentTypeLogo;
  paymentInstrumentType?: Maybe<Scalars["String"]["output"]>;
  paymentMethod?: Maybe<PaymentMethodType>;
  paymentMethodUrn?: Maybe<Scalars["String"]["output"]>;
  state: PaymentState;
  storedCardConfig?: Maybe<StoredCardConfig>;
  viewType?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentInstrumentContainer = {
  __typename?: "PaymentInstrumentContainer";
  instruments?: Maybe<Array<PaymentInstrument>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentInstrumentView = {
  __typename?: "PaymentInstrumentView";
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  label?: Maybe<Scalars["String"]["output"]>;
  required: Scalars["Boolean"]["output"];
  viewType?: Maybe<Scalars["String"]["output"]>;
};

export interface PaymentInteractiveIcon {
  icon: Icon;
}

export type PaymentLink = {
  __typename?: "PaymentLink";
  accessibility: Scalars["String"]["output"];
  action?: Maybe<PaymentAction>;
  text: Scalars["String"]["output"];
};

export type PaymentList = {
  __typename?: "PaymentList";
  list: EgdsBulletedList;
};

export type PaymentMark = {
  __typename?: "PaymentMark";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type PaymentMessageMetaData = {
  __typename?: "PaymentMessageMetaData";
  insuranceAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type PaymentMetaInfoParameters = {
  __typename?: "PaymentMetaInfoParameters";
  cardOnFile: Scalars["Boolean"]["output"];
  hasStoredCards: Scalars["Boolean"]["output"];
  insuranceDueNow: Scalars["Boolean"]["output"];
  paymentPayType: PaymentPayType;
};

export type PaymentMethodAttribute =
  | AffirmPaymentMethodAttribute
  | ApplePayPaymentAttribute
  | CreditCardPaymentMethodAttribute
  | LoyaltyPaymentAttribute
  | NaverPayPaymentMethodAttribute;

export interface PaymentMethodAttributeInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export interface PaymentMethodConfigInput {
  kcpPaymentConfig: KcpPaymentConfigInput;
}

export interface PaymentMethodConfigurationInput {
  applePayConfiguration?: InputMaybe<PaymentApplePayConfigurationInput>;
  bin?: InputMaybe<Scalars["String"]["input"]>;
  challenge3DsResult?: InputMaybe<Scalars["String"]["input"]>;
  encodedBrowserMetadata?: InputMaybe<Scalars["String"]["input"]>;
  etzToken?: InputMaybe<Scalars["String"]["input"]>;
  kcpConfiguration?: InputMaybe<KcpConfigurationInput>;
  providerConfirmationStatus?: InputMaybe<Scalars["String"]["input"]>;
  storedCardInstrument?: InputMaybe<StoredCardInstrumentInput>;
}

export interface PaymentMethodInput {
  data?: InputMaybe<Array<PaymentMethodAttributeInput>>;
  paymentMethodType: PaymentMethodType;
}

export type PaymentMethodSubmittingDialog = {
  __typename?: "PaymentMethodSubmittingDialog";
  bookingDialogContents: Array<BookingDialogContent>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentMethodSwitchAction = PaymentAction & {
  __typename?: "PaymentMethodSwitchAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  paymentMethodUrn: Scalars["String"]["output"];
  uiSignal: Scalars["String"]["output"];
};

export type PaymentMethodType =
  | "AFFIRM"
  | "APPLE_PAY"
  | "CREDIT_CARD"
  | "GIFT_CARD"
  | "INVALID"
  | "LOYALTY"
  | "NAVER_PAY"
  | "UNRECOGNIZED";

export type PaymentModule = {
  __typename?: "PaymentModule";
  accessTokenForCardTokenization: Scalars["String"]["output"];
  checkoutIdentifier: PaymentCheckoutIdentifier;
  components: Array<PaymentUiComponent>;
  metaData: PaymentModuleMetaData;
};

export type PaymentModuleMetaData = {
  __typename?: "PaymentModuleMetaData";
  countryCode: Scalars["String"]["output"];
  deviceType: PaymentDeviceType;
  locale: Scalars["String"]["output"];
  paymentParameters: PaymentMetaInfoParameters;
};

export type PaymentNaverPayDetail = PaymentFopDetails & {
  __typename?: "PaymentNaverPayDetail";
  components?: Maybe<Array<PaymentDetailsUiElement>>;
  naverPayMetadata: PaymentNaverPayMetadata;
  priceDetails?: Maybe<PaymentPriceDetails>;
  title: Scalars["String"]["output"];
  views?: Maybe<Array<PaymentInstrumentView>>;
};

export type PaymentNaverPayForm = {
  __typename?: "PaymentNaverPayForm";
  action: Scalars["String"]["output"];
  clientSuppliedElements: Array<EgdsTextInputField>;
  formElements: Array<EgdsTextInputField>;
  method: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type PaymentNaverPayMetadata = {
  __typename?: "PaymentNaverPayMetadata";
  flowType: Scalars["String"]["output"];
  script: Scalars["String"]["output"];
};

export type PaymentNavigateToExternalLink = PaymentAction & {
  __typename?: "PaymentNavigateToExternalLink";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  navigationLink: Scalars["String"]["output"];
};

export type PaymentNoCpmView = {
  __typename?: "PaymentNoCPMView";
  noView: Scalars["Boolean"]["output"];
};

export type PaymentNotificationContext = {
  __typename?: "PaymentNotificationContext";
  messages: Array<PaymentNotificationMessage>;
  /** @deprecated notificationModuleName will be used */
  moduleName: PaymentNotificationModuleName;
  notificationModuleName: Scalars["String"]["output"];
};

export type PaymentNotificationLocation = "IN_MODULE";

export type PaymentNotificationMessage = {
  __typename?: "PaymentNotificationMessage";
  messageIdentifier: Scalars["String"]["output"];
  /** @deprecated To be revisited later with dynamic messaging by MM */
  metaData: Array<PaymentMessageMetaData>;
  /** @deprecated messageIdentifier to be used as String */
  name: PaymentNotificationMessageName;
};

export type PaymentNotificationMessageName =
  | "CPM_CC_ON_FILE_MESSAGE"
  | "CPM_CC_ON_FILE_ONLY_INSURANCE_PAYMENT_MESSAGE"
  | "CPM_NO_PAYMENT_DUE_MESSAGE"
  | "CPM_ONLY_INSURANCE_PAYMENT_DUE_MESSAGE"
  | "INVALID_MESSAGE"
  | "PAY_NOW_GIFT_CARD_TOTAL_AMOUNT"
  | "PAY_NOW_OKC_TOTAL_AMOUNT";

export type PaymentNotificationMetadata = {
  __typename?: "PaymentNotificationMetadata";
  cardOnFile: Scalars["Boolean"]["output"];
  fullAmountCoveredByGiftCard?: Maybe<Scalars["Boolean"]["output"]>;
  fullAmountCoveredByLoyalty?: Maybe<Scalars["Boolean"]["output"]>;
  insuranceDueNow: Scalars["Boolean"]["output"];
  paymentPayType: PaymentPayType;
};

export type PaymentNotificationModuleName = "COMMON_PAYMENT_MODULE";

export type PaymentNotificationSheet = {
  __typename?: "PaymentNotificationSheet";
  accessibility: Scalars["String"]["output"];
  button: UiPrimaryButton;
  heading: EgdsHeading;
  paragraph: EgdsParagraph;
};

export type PaymentOptionsLogoList = {
  __typename?: "PaymentOptionsLogoList";
  paymentLogos: Array<PaymentTypeLogo>;
};

export type PaymentParagraph = {
  __typename?: "PaymentParagraph";
  egdsText: EgdsText;
};

export type PaymentPayType = "PAY_LATER" | "PAY_NOW";

export type PaymentPriceDetails = {
  __typename?: "PaymentPriceDetails";
  amount: Scalars["Float"]["output"];
  amountText?: Maybe<Scalars["String"]["output"]>;
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  decimalPlaces: Scalars["Int"]["output"];
  fieldType?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentPrimaryButton = {
  __typename?: "PaymentPrimaryButton";
  action?: Maybe<PaymentAction>;
  button: UiPrimaryButton;
};

export type PaymentPublishAnalyticsAction = PaymentAction & {
  __typename?: "PaymentPublishAnalyticsAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentPublishSignalAction = PaymentAction & {
  __typename?: "PaymentPublishSignalAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  signals: Array<PaymentUiSignalWrapper>;
};

export type PaymentQuery = {
  __typename?: "PaymentQuery";
  giftCard: PaymentResponse;
};

export type PaymentQueryGiftCardArgs = {
  giftCardRequest: GiftCardRequestInput;
};

export type PaymentResponse = {
  __typename?: "PaymentResponse";
  actions?: Maybe<Array<PaymentAction>>;
  elements?: Maybe<Array<PaymentUiComponent>>;
};

export type PaymentRewardValue = {
  __typename?: "PaymentRewardValue";
  rewardAmount?: Maybe<PaymentPriceDetails>;
  rewardCurrencyUrn?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentSheet = PaymentActionableComponent & {
  __typename?: "PaymentSheet";
  content?: Maybe<Array<PaymentUiComponent>>;
  footer?: Maybe<Array<PaymentUiComponent>>;
  header?: Maybe<Array<PaymentUiComponent>>;
  hideOnAction?: Maybe<Scalars["String"]["output"]>;
  isVisible: Scalars["Boolean"]["output"];
  sheetId: Scalars["String"]["output"];
  showOnAction?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentShowDialogAction = PaymentAction & {
  __typename?: "PaymentShowDialogAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  dialog: PaymentDialog;
};

export type PaymentShowErrorMessageAction = PaymentAction & {
  __typename?: "PaymentShowErrorMessageAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  error: MessageContent;
};

export type PaymentShowGiftCardSheetAction = PaymentAction & {
  __typename?: "PaymentShowGiftCardSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentShowHelpSheetAction = PaymentAction & {
  __typename?: "PaymentShowHelpSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentShowToastMessageAction = PaymentAction & {
  __typename?: "PaymentShowToastMessageAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
  toast: EgdsToast;
};

export type PaymentShowWarningAction = PaymentAction & {
  __typename?: "PaymentShowWarningAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentShowWarningSheetAction = PaymentAction & {
  __typename?: "PaymentShowWarningSheetAction";
  analytics?: Maybe<PaymentClickstreamAnalyticsData>;
};

export type PaymentState =
  | "STATE_CANCELLED"
  | "STATE_DISABLED"
  | "STATE_INVALID"
  | "STATE_REMOVED"
  | "STATE_SELECTED"
  | "STATE_UNSELECTED"
  | "UNRECOGNIZED";

export type PaymentTertiaryButton = {
  __typename?: "PaymentTertiaryButton";
  action?: Maybe<PaymentAction>;
  button: UiTertiaryButton;
};

export type PaymentText = {
  __typename?: "PaymentText";
  egdsText: EgdsText;
};

export type PaymentThreeDsAuthenticationSpec = {
  __typename?: "PaymentThreeDSAuthenticationSpec";
  authenticationMethod?: Maybe<Scalars["String"]["output"]>;
  challengeConfig?: Maybe<Scalars["String"]["output"]>;
  encodedInitConfig?: Maybe<Scalars["String"]["output"]>;
  pasSessionId?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentThreeDsResource = PaymentThreeDsResourceWeb;

export type PaymentThreeDsResourceWeb = {
  __typename?: "PaymentThreeDSResourceWeb";
  authenticateMethod?: Maybe<Scalars["String"]["output"]>;
  authenticationSpec?: Maybe<PaymentThreeDsAuthenticationSpec>;
  challengeConfig: Scalars["String"]["output"];
  challengeResultUpdateFallbackAction?: Maybe<Array<PaymentAction>>;
  components?: Maybe<Array<PaymentUiComponent>>;
  iframeId?: Maybe<Scalars["String"]["output"]>;
  iframeOriginUrl?: Maybe<Scalars["String"]["output"]>;
  javascriptUrl?: Maybe<Scalars["String"]["output"]>;
  pasSessionId: Scalars["String"]["output"];
};

export type PaymentTitle = {
  __typename?: "PaymentTitle";
  title: Scalars["String"]["output"];
};

export type PaymentTitledImageDialogContent = PaymentDialogContent & {
  __typename?: "PaymentTitledImageDialogContent";
  identifier: Scalars["String"]["output"];
  imageContent: Image;
  textContent: Scalars["String"]["output"];
};

export interface PaymentTokenRequestInput {
  sessionId: Scalars["String"]["input"];
  storedPaymentInstrumentId: Scalars["String"]["input"];
}

export type PaymentTokenResponse = {
  __typename?: "PaymentTokenResponse";
  storedInstrumentToken: Scalars["String"]["output"];
};

export type PaymentToolbar = {
  __typename?: "PaymentToolbar";
  leftIcon?: Maybe<PaymentIcon>;
  rightIcon?: Maybe<PaymentIcon>;
  title?: Maybe<PaymentText>;
};

export type PaymentTravelerNotification = {
  __typename?: "PaymentTravelerNotification";
  context: PaymentNotificationContext;
  /** @deprecated Will be used as string to support native, replace with PaymentNotificationLocation.IN_MODULE */
  location: PaymentNotificationLocation;
  /** @deprecated Redundant object - already present at the components level */
  metaData: PaymentNotificationMetadata;
  notificationLocation: Scalars["String"]["output"];
};

export type PaymentType =
  | "COUPON_ELIGIBLE"
  | "FREE_CANCELLATION"
  | "GIFT_CARD"
  | "PAY_LATER"
  | "PAY_LATER_WITH_DEPOSIT"
  | "PAY_WITHOUT_CREDITCARD"
  | "PAY_WITH_AFFIRM"
  | "PAY_WITH_AFTERPAY"
  | "PAY_WITH_KLARNA";

export type PaymentTypeLogo = {
  __typename?: "PaymentTypeLogo";
  icon?: Maybe<Icon>;
  mark?: Maybe<Mark>;
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  size: PaymentTypeLogoSize;
};

export type PaymentTypeLogoSize = "LARGE" | "SMALL";

export type PaymentTypeMessage = {
  __typename?: "PaymentTypeMessage";
  components?: Maybe<Array<PaymentUiComponent>>;
  paymentType?: Maybe<Scalars["String"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PaymentUiComponent =
  | PaymentAdditionalMessage
  | PaymentCard
  | PaymentCheckoutElement
  | PaymentConfidenceNotes
  | PaymentDialog
  | PaymentFopSelector
  | PaymentFopModuleDetail
  | PaymentForm
  | PaymentHeading
  | PaymentHorizontalLine
  | PaymentIcon
  | PaymentIframe
  | PaymentInformativeInputField
  | PaymentInlineMessageModule
  | PaymentInputCheckBoxField
  | PaymentInputSelectField
  | PaymentInputSelectFieldWithValidation
  | PaymentInputTextEmbeddedField
  | PaymentInputTextEmbeddedFieldWithConfiguration
  | PaymentInputTextField
  | PaymentInputTextFieldGc
  | PaymentInputTextFieldWithConfiguration
  | PaymentLink
  | PaymentList
  | PaymentNoCpmView
  | PaymentNotificationSheet
  | PaymentOptionsLogoList
  | PaymentParagraph
  | PaymentPrimaryButton
  | PaymentSheet
  | PaymentTertiaryButton
  | PaymentText
  | PaymentTitle
  | PaymentToolbar
  | PaymentTravelerNotification
  | PaymentTypeMessage;

export type PaymentUiSignal = {
  __typename?: "PaymentUISignal";
  domainInfoList?: Maybe<Array<DomainInfo>>;
  signalDetails?: Maybe<Array<SignalDetails>>;
  type?: Maybe<SignalType>;
  urn: Scalars["String"]["output"];
};

export type PaymentUiSignalWrapper = {
  __typename?: "PaymentUiSignalWrapper";
  mappedUiSignal: Scalars["String"]["output"];
  signal: PaymentUiSignal;
};

export type PenaltiesAndRestrictions = {
  __typename?: "PenaltiesAndRestrictions";
  content: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export interface PerformanceDateRangeInput {
  endDate: Scalars["String"]["input"];
  startDate: Scalars["String"]["input"];
}

export type PerformanceOverviewCampaignSelectionResponse = {
  __typename?: "PerformanceOverviewCampaignSelectionResponse";
  campaignSelector: EgdsBasicSelect;
};

export type PerformanceOverviewCarouselCard = {
  __typename?: "PerformanceOverviewCarouselCard";
  badge?: Maybe<EgdsStandardBadge>;
  metricToolTip: EgdsMoreInfoTrigger;
  metricValue: PerformanceOverviewMetric;
};

export type PerformanceOverviewCarouselResponse = {
  __typename?: "PerformanceOverviewCarouselResponse";
  carouselCards: Array<PerformanceOverviewCarouselCard>;
  noDataPlaceholder: Scalars["String"]["output"];
};

export type PerformanceOverviewChartResponse = {
  __typename?: "PerformanceOverviewChartResponse";
  dataPoints?: Maybe<Array<DataPoint>>;
  dateSelector: EgdsBasicSelect;
  monthNames: Array<Scalars["String"]["output"]>;
  noDataIcon: Icon;
  noDataPlaceholder: Scalars["String"]["output"];
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PerformanceOverviewMetric = {
  __typename?: "PerformanceOverviewMetric";
  amount?: Maybe<Scalars["Float"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
};

export type PersonalWishlistHeading = {
  __typename?: "PersonalWishlistHeading";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  link: EgdsStandardLink;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PersonalizedModule = DiscoveryItemsGroup | DiscoveryMultiModule;

export type PersonalizedOffersRecommendations = {
  __typename?: "PersonalizedOffersRecommendations";
  offers: Array<OffersRecommendationGroup>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PersonalizedTheme =
  | "BRIGHT_1"
  | "DARK_1"
  | "GLOBAL_LOYALTY_EXTRA_HIGH"
  | "GLOBAL_LOYALTY_HIGH"
  | "GLOBAL_LOYALTY_LOW"
  | "GLOBAL_LOYALTY_MID"
  | "LIGHT_1";

export type PhoneNumberData = {
  __typename?: "PhoneNumberData";
  call?: Maybe<UiPrimaryButton>;
  colon: Scalars["String"]["output"];
  phoneNumber: Scalars["String"]["output"];
  phoneType: Scalars["String"]["output"];
  region?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated replaced with colon */
  separator: Icon;
};

export type PhotosSocialInteractionElement = {
  __typename?: "PhotosSocialInteractionElement";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<SocialInteractionElementAnalyticEvent>;
  primaryIcon: Icon;
  primaryText: Scalars["String"]["output"];
  propertyId?: Maybe<Scalars["String"]["output"]>;
};

export type Picker = {
  __typename?: "Picker";
  bannerText?: Maybe<Scalars["String"]["output"]>;
  closeDialogText: Scalars["String"]["output"];
  /** @deprecated Replaced by urlToSubmit and queryParamsToSubmit for better granularity */
  domainUrlToSubmit?: Maybe<Scalars["URL"]["output"]>;
  heading: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  oneKeyMessagingHeading?: Maybe<Scalars["String"]["output"]>;
  oneKeyMessagingSubHeading?: Maybe<Scalars["String"]["output"]>;
  preferences: Array<Maybe<LocalePreferenceSelector>>;
  queryParamsToSubmit?: Maybe<Array<GraphQlPair>>;
  /** @deprecated Replaced by bannerText, better named and optional */
  regionBannerText: Scalars["String"]["output"];
  saveAnalytics?: Maybe<ClientSideAnalytics>;
  submitText: Scalars["String"]["output"];
  submitTrackingEvent: Scalars["String"]["output"];
  trigger: PickerTrigger;
  urlToSubmit?: Maybe<Scalars["URL"]["output"]>;
};

export type PickerTrigger = {
  __typename?: "PickerTrigger";
  displayType: PickerTriggerDisplayType;
  flagName?: Maybe<Scalars["String"]["output"]>;
  iconName?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  trackingEvent: Scalars["String"]["output"];
};

export type PickerTriggerDisplayType = "GLOBAL_NAV_BUTTON" | "GLOBAL_NAV_LINK";

export type PickerTriggerPlacementType = "MENU_ITEM" | "NAV_LINK";

export type Pinned = {
  __typename?: "Pinned";
  tileBottom?: Maybe<Icon>;
  tileTop?: Maybe<Icon>;
};

export type PinnedPropertyModel = {
  __typename?: "PinnedPropertyModel";
  heading: Scalars["String"]["output"];
};

export type PlaceCardDetails = {
  __typename?: "PlaceCardDetails";
  closeAction?: Maybe<UiTertiaryButton>;
  description?: Maybe<EgdsText>;
  directionsPath?: Maybe<Array<DirectionsPath>>;
  footerText?: Maybe<EgdsText>;
  id: Scalars["String"]["output"];
  media?: Maybe<PlaceCardMedia>;
  subtitle?: Maybe<EgdsText>;
  title: EgdsText;
  tripsSaveItem?: Maybe<TripsSaveItem>;
};

export type PlaceCardMedia = EgdsCarousel | Image;

export type Placement = "Horizontal" | "Vertical";

export type PlacementContext = {
  __typename?: "PlacementContext";
  ajaxAutoReset?: Maybe<Scalars["Boolean"]["output"]>;
  jsNameSpace?: Maybe<Scalars["String"]["output"]>;
  languageCode?: Maybe<Scalars["String"]["output"]>;
  placementId?: Maybe<Scalars["String"]["output"]>;
  publicKey?: Maybe<Scalars["String"]["output"]>;
};

export interface PlacementInput {
  name: Scalars["String"]["input"];
  protectionSetId: Scalars["String"]["input"];
}

export type PlacesDialog = {
  __typename?: "PlacesDialog";
  dialog?: Maybe<EgdsFullScreenDialog>;
  placesDialogEGDSTextsContentSection?: Maybe<PlacesDialogEgdsTextsContentSection>;
  placesDialogTextInputFieldContentSection?: Maybe<PlacesDialogTextInputFieldContentSection>;
  placesDialogTypeAheadContentSection?: Maybe<PlacesDialogTypeAheadContentSection>;
  placesDialogUITogglesContentSection?: Maybe<PlacesDialogUiTogglesContentSection>;
  trigger?: Maybe<EgdsOverlayButton>;
};

export type PlacesDialogEgdsTextsContentSection = {
  __typename?: "PlacesDialogEGDSTextsContentSection";
  clearSavedPlaceIcon?: Maybe<Icon>;
  content?: Maybe<Array<EgdsText>>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlacesDialogTextInputFieldContentSection = {
  __typename?: "PlacesDialogTextInputFieldContentSection";
  content?: Maybe<EgdsTextInputField>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlacesDialogTypeAheadContentSection = {
  __typename?: "PlacesDialogTypeAheadContentSection";
  content?: Maybe<EgdsTypeaheadInputField>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlacesDialogUiTogglesContentSection = {
  __typename?: "PlacesDialogUITogglesContentSection";
  content?: Maybe<Array<UiToggle>>;
  heading?: Maybe<EgdsHeading>;
  subHeading?: Maybe<EgdsText>;
};

export type PlatformType = "ANDROID" | "IOS" | "WEB";

export type PlaygroundCurrencyFormat = {
  __typename?: "PlaygroundCurrencyFormat";
  currencySymbol: Scalars["String"]["output"];
  formattedAmount: Scalars["String"]["output"];
  negativePattern: Scalars["String"]["output"];
  positivePattern: Scalars["String"]["output"];
  roundedNegativePattern: Scalars["String"]["output"];
  roundedPositivePattern: Scalars["String"]["output"];
};

export type PlaygroundDateTimeFormat = {
  __typename?: "PlaygroundDateTimeFormat";
  calendarPlaceholder: Scalars["String"]["output"];
  dateSeparators: Scalars["String"]["output"];
  fullDateFormat: Scalars["String"]["output"];
  fullDateFormatPattern: Scalars["String"]["output"];
  fullDayOfWeekFormat: Scalars["String"]["output"];
  fullTimeFormat: Scalars["String"]["output"];
  fullTimeFormatPattern: Scalars["String"]["output"];
  gmtString: Scalars["String"]["output"];
  iso8601String: Scalars["String"]["output"];
  longDateFormat: Scalars["String"]["output"];
  longDateFormatPattern: Scalars["String"]["output"];
  longTimeFormat: Scalars["String"]["output"];
  longTimeFormatPattern: Scalars["String"]["output"];
  mediumDateFormat: Scalars["String"]["output"];
  mediumDateFormatPattern: Scalars["String"]["output"];
  mediumTimeFormat: Scalars["String"]["output"];
  mediumTimeFormatPattern: Scalars["String"]["output"];
  shortDateFormat: Scalars["String"]["output"];
  shortDateFormatPattern: Scalars["String"]["output"];
  shortDayOfWeekFormat: Scalars["String"]["output"];
  shortTimeFormatPattern: Scalars["String"]["output"];
  startDayOfWeek: Scalars["String"]["output"];
};

export type PlaygroundDevice = {
  __typename?: "PlaygroundDevice";
  appName?: Maybe<Scalars["String"]["output"]>;
  appVersion?: Maybe<Scalars["String"]["output"]>;
  brandName?: Maybe<Scalars["String"]["output"]>;
  deviceOs: Scalars["String"]["output"];
  deviceOsVersion?: Maybe<Scalars["String"]["output"]>;
  isAndroid: Scalars["Boolean"]["output"];
  isApp: Scalars["Boolean"]["output"];
  isIos: Scalars["Boolean"]["output"];
  isMobile: Scalars["Boolean"]["output"];
  isTablet: Scalars["Boolean"]["output"];
  mobileBrowser?: Maybe<Scalars["String"]["output"]>;
  mobileBrowserVersion?: Maybe<Scalars["String"]["output"]>;
  modelName?: Maybe<Scalars["String"]["output"]>;
  physicalScreenHeight?: Maybe<Scalars["Int"]["output"]>;
  physicalScreenWidth?: Maybe<Scalars["Int"]["output"]>;
  resolutionHeight?: Maybe<Scalars["Int"]["output"]>;
  resolutionWidth?: Maybe<Scalars["Int"]["output"]>;
  type: Scalars["String"]["output"];
};

export type PlaygroundLocation = {
  __typename?: "PlaygroundLocation";
  city?: Maybe<Scalars["String"]["output"]>;
  continent?: Maybe<Scalars["String"]["output"]>;
  country?: Maybe<Scalars["String"]["output"]>;
  dmaCode?: Maybe<Scalars["String"]["output"]>;
  latitude?: Maybe<Scalars["Float"]["output"]>;
  longitude?: Maybe<Scalars["Float"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
  timeZoneOffset?: Maybe<Scalars["String"]["output"]>;
  zipCodes: Array<Scalars["String"]["output"]>;
};

export type PlaygroundSiteContext = {
  __typename?: "PlaygroundSiteContext";
  eapid: Scalars["Int"]["output"];
  resolvedCurrency: Scalars["String"]["output"];
  resolvedLocale: Scalars["String"]["output"];
  siteId: Scalars["Int"]["output"];
  tpid: Scalars["Int"]["output"];
};

export type PlaygroundUser = {
  __typename?: "PlaygroundUser";
  emailAddress?: Maybe<Scalars["String"]["output"]>;
  expUserId?: Maybe<Scalars["Int"]["output"]>;
  firstName?: Maybe<Scalars["String"]["output"]>;
  isAnonymous: Scalars["Boolean"]["output"];
  isAuthenticated: Scalars["Boolean"]["output"];
  isIdentified: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  tuid?: Maybe<Scalars["Int"]["output"]>;
};

export interface Plottable {
  mapMarker?: Maybe<MapMarker>;
}

export type PluralCategory = "FEW" | "MANY" | "ONE" | "OTHER" | "TWO" | "ZERO";

export type PoiContent = {
  __typename?: "PoiContent";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  image?: Maybe<Image>;
};

export type PointOfInterest = Plottable &
  PropertyDetailPlottable & {
    __typename?: "PointOfInterest";
    /** @deprecated For PWA use only as a part of PDP migration */
    id?: Maybe<Scalars["String"]["output"]>;
    mapMarker: MapMarker;
    poiContent?: Maybe<PoiContent>;
    subtitle?: Maybe<Scalars["String"]["output"]>;
    title: Scalars["String"]["output"];
  };

export type Points = "SHOP_WITHOUT_POINTS" | "SHOP_WITH_POINTS";

export type PointsInformation = {
  __typename?: "PointsInformation";
  closeLabel: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type Policies = {
  __typename?: "Policies";
  content: ShoppingProductContentSectionGroup;
};

export type PolicyContent = EgdsBulletedList | EgdsPlainText | PolicyHtmlContent;

export type PolicyContentSection = EgdsBulletedList | EgdsStandardLink | PolicyExpandoList;

export type PolicyDetailsSection = {
  __typename?: "PolicyDetailsSection";
  policyA11yText?: Maybe<Scalars["String"]["output"]>;
  theme: Scalars["String"]["output"];
  title: EgdsStylizedText;
  tooltipContent?: Maybe<Array<Scalars["String"]["output"]>>;
  tooltipIcon?: Maybe<Icon>;
};

export type PolicyExpandoList = {
  __typename?: "PolicyExpandoList";
  expandoSections: Array<PolicyExpandoSection>;
};

export type PolicyExpandoSection = {
  __typename?: "PolicyExpandoSection";
  content: Array<PolicyContent>;
  heading: EgdsExpando;
  urn: Scalars["String"]["output"];
};

export interface PolicyFetchParamsInput {
  cartId?: InputMaybe<Scalars["String"]["input"]>;
  offerToken?: InputMaybe<OfferTokenInput>;
  orderInfo?: InputMaybe<OrderInfoInput>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type PolicyHtmlContent = {
  __typename?: "PolicyHTMLContent";
  htmlContent: Array<Scalars["String"]["output"]>;
};

export type PolicyHeaderSection = {
  __typename?: "PolicyHeaderSection";
  productLogoUrl?: Maybe<Scalars["String"]["output"]>;
  productName: Scalars["String"]["output"];
  productSummary?: Maybe<Array<ProductSummary>>;
};

export type PopOverContent = {
  __typename?: "PopOverContent";
  heading: Scalars["String"]["output"];
  paragraph: Scalars["String"]["output"];
};

export type PopUpMessage = {
  __typename?: "PopUpMessage";
  close?: Maybe<CloseType>;
  content?: Maybe<Array<ProductSupportingMessage>>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PopoverDetails = {
  __typename?: "PopoverDetails";
  content?: Maybe<LoyaltyPopoverDialog>;
  impressionAnalytics?: Maybe<ClickstreamAnalyticsData>;
  label?: Maybe<Scalars["String"]["output"]>;
  openAnalytics?: Maybe<ClickstreamAnalyticsData>;
};

export type PopoverSheet = {
  __typename?: "PopoverSheet";
  clickableLink?: Maybe<ActionLink>;
  icon?: Maybe<Icon>;
  popupInfo?: Maybe<PopupComponent>;
};

export type PopupComponent = {
  __typename?: "PopupComponent";
  analytics?: Maybe<ClientSideAnalytics>;
  button?: Maybe<EgdsButton>;
  /** @deprecated Replacing with messages */
  message?: Maybe<EgdsText>;
  messages?: Maybe<Messages>;
};

export type PortableTextHtmlTableSection = {
  __typename?: "PortableTextHTMLTableSection";
  rows?: Maybe<Array<Maybe<SectionRow>>>;
};

export type PortableTextWithLink = {
  __typename?: "PortableTextWithLink";
  fileLink?: Maybe<EgdsStandardLink>;
  portableTextContent: Array<PortableTextContent>;
};

export type PortalBanner = {
  __typename?: "PortalBanner";
  bannerHeadline: Scalars["String"]["output"];
  bannerTag: BannerTag;
  bannerText: Scalars["String"]["output"];
  bannerType: BannerType;
};

export type PortalButton = {
  __typename?: "PortalButton";
  button?: Maybe<PortalButtonType>;
};

export type PortalButtonType = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type PosSelector = LocalePreferenceSelector & {
  __typename?: "PosSelector";
  heading: Scalars["String"]["output"];
  onChangeAnalytics?: Maybe<ClientSideAnalytics>;
  options: Array<PosSelectorOption>;
  queryParameterName: Scalars["String"]["output"];
  trackingEvent: Scalars["String"]["output"];
};

export type PosSelectorOption = LocaleSelectorOption & {
  __typename?: "PosSelectorOption";
  flag: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export interface PostContentOverlayQueryRequestInput {
  creatorId?: InputMaybe<Scalars["String"]["input"]>;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
  postId: Scalars["String"]["input"];
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  screenId: Scalars["String"]["input"];
}

export type PostContentOverlayQueryResponse = {
  __typename?: "PostContentOverlayQueryResponse";
  postSummary?: Maybe<PostSummary>;
  progressionBar?: Maybe<ProgressionBar>;
};

export type PostSummary = {
  __typename?: "PostSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Price>;
  primaryText?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Rating>;
  secondaryText?: Maybe<Scalars["String"]["output"]>;
};

export type PreBundledCuratedCardsEntryResponse = PreBundledCuratedCardsEntrySuccessResponse;

export type PreBundledCuratedCardsEntrySuccessResponse = {
  __typename?: "PreBundledCuratedCardsEntrySuccessResponse";
  action: PackageUiEntryCardAction;
  description: PackageUiDescriptionExpando;
  gallery: MishopUiGalleryCarousel;
  header: Scalars["String"]["output"];
  includedProductsDetails: PackageUiIncludedProductsDetails;
  theme: Scalars["String"]["output"];
};

export type PreBundledPackageEntryQueries = {
  __typename?: "PreBundledPackageEntryQueries";
  prebundledPackageEntryCuratedCard?: Maybe<PreBundledCuratedCardsEntryResponse>;
};

export type PreBundledPackageEntryQueriesPrebundledPackageEntryCuratedCardArgs = {
  templateId: Scalars["String"]["input"];
};

export type PreBundledPackageSearchCarouselsSuccessResponse = {
  __typename?: "PreBundledPackageSearchCarouselsSuccessResponse";
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  carousels: Array<PackageUiCarouselByTheme>;
};

export type PreBundledPackageSearchEmptyStateErrorResponse = PackageUiEmptyStateError & {
  __typename?: "PreBundledPackageSearchEmptyStateErrorResponse";
  errorAction?: Maybe<PackageUiExternalLinkAction>;
  icon: Icon;
  primary: EgdsHeading;
  secondaries: Array<EgdsText>;
};

export type PreBundledPackageSearchEntryCardResponse = {
  __typename?: "PreBundledPackageSearchEntryCardResponse";
  action: PackageUiEntryCardAction;
  heading: Scalars["String"]["output"];
  images: Array<Image>;
  impressionAnalytics: ClientSideAnalytics;
  subHeadings: Array<PackageUiEntryCardSubHeading>;
};

export type PreBundledPackageSearchEntryResponse = {
  __typename?: "PreBundledPackageSearchEntryResponse";
  contentSections: Array<PackageUiPreBundledPackageSearchEntryContent>;
  dialog: EgdsActionDialog;
  header: Scalars["String"]["output"];
  image?: Maybe<Image>;
  impressionAnalytics: ClientSideAnalytics;
};

export type PreBundledPackageSearchResponse =
  | PreBundledPackageSearchCarouselsSuccessResponse
  | PreBundledPackageSearchEmptyStateErrorResponse
  | PreBundledPackageSearchSuccessResponse;

export type PreBundledPackageSearchSuccessResponse = {
  __typename?: "PreBundledPackageSearchSuccessResponse";
  accessibilityHeading?: Maybe<Scalars["String"]["output"]>;
  cards: Array<PackageUiPreBundledCard>;
  carousel: MishopUiCarousel;
  header: EgdsHeading;
  recommendationsAnalytics: Array<RecommendationAnalytics>;
  subHeader: EgdsPlainText;
};

export type PrebundlePackageDetailsPrimers = PackageDetailsPrimersError | PrebundlePackageDetailsPrimersSuccess;

export type PrebundlePackageDetailsPrimersSuccess = {
  __typename?: "PrebundlePackageDetailsPrimersSuccess";
  criticalModuleFailureError: PackageUiExternalLinkEmptyStateError;
  heading: EgdsHeading;
  primers: PrebundlePackageDetailsSuccessPrimers;
  state: MishopDetailsUiState;
};

export type PrebundlePackageDetailsSuccessPrimers = {
  __typename?: "PrebundlePackageDetailsSuccessPrimers";
  /** @deprecated Deprecate in favor of nullable searchWizardPrimer */
  changePackageWizardPrimer: MishopUiChangePackageFormPrimer;
  checkoutButtonPrimer: MishopUiCheckoutButtonPrimer;
  notificationPrimer: MishopUiNotificationPrimer;
  oneKeyBannerPrimer?: Maybe<MishopUiOneKeyBannerPrimer>;
  packageSummaryContentPrimer: MishopUiPackageSummaryContentPrimer;
  policiesPrimer: PackageUiPoliciesPrimer;
  pricingDetailsPrimer: MishopUiPricingDetailsPrimer;
  productContentPrimers: Array<PackageDetailsProductContentPrimer>;
  searchWizardPrimer?: Maybe<PackageUiChangePackageFormPrimer>;
  seeAllPackagesLinkPrimer?: Maybe<PackageUiSeeAllPackagesLinkPrimer>;
};

export type PreloadedInfo = {
  __typename?: "PreloadedInfo";
  durationAndStops?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  journeyDate?: Maybe<Scalars["String"]["output"]>;
};

export type PremierHostCriteriaSnapshot = {
  __typename?: "PremierHostCriteriaSnapshot";
  datesSelect?: Maybe<EgdsSelect>;
  snapshots?: Maybe<Array<PremierHostSnapshot>>;
};

export type PremierHostDownloadCard = {
  __typename?: "PremierHostDownloadCard";
  /** @deprecated No longer used */
  action?: Maybe<UiLinkAction>;
  /** @deprecated Rather use supplyLoyalty.premierHost.download instead */
  data?: Maybe<Scalars["String"]["output"]>;
  filename?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<Array<PremierHostText>>;
  type?: Maybe<SupplyPremierHostDownloadType>;
};

export type PremierHostDownloads = {
  __typename?: "PremierHostDownloads";
  downloadCards?: Maybe<Array<PremierHostDownloadCard>>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostErrorCard = {
  __typename?: "PremierHostErrorCard";
  graphic?: Maybe<UiGraphic>;
  message?: Maybe<Array<PremierHostText>>;
};

export type PremierHostProgress = {
  __typename?: "PremierHostProgress";
  label?: Maybe<PremierHostText>;
  progressBar?: Maybe<EgdsProgressBar>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostScorecard = {
  __typename?: "PremierHostScorecard";
  criteriaSnapshot?: Maybe<PremierHostCriteriaSnapshot>;
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  downloads?: Maybe<PremierHostDownloads>;
  error?: Maybe<PremierHostErrorCard>;
  progress?: Maybe<PremierHostProgress>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostSnapshot = {
  __typename?: "PremierHostSnapshot";
  cards?: Maybe<Array<PremierHostSnapshotCard>>;
  label?: Maybe<PremierHostText>;
};

export type PremierHostSnapshotCard = {
  __typename?: "PremierHostSnapshotCard";
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  status?: Maybe<PremierHostText>;
  title?: Maybe<Array<PremierHostText>>;
};

export type PremierHostStatus = {
  __typename?: "PremierHostStatus";
  badge?: Maybe<EgdsBadge>;
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  error?: Maybe<PremierHostErrorCard>;
  summary?: Maybe<PremierHostText>;
  title?: Maybe<PremierHostText>;
};

export type PremierHostText = {
  __typename?: "PremierHostText";
  /** @deprecated Replaced by supplyLoyaltyText.text */
  text?: Maybe<EgdsText>;
};

export type PrepareChangeCheckoutOption = {
  __typename?: "PrepareChangeCheckoutOption";
  type: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface PrepareChangeCheckoutOptionInput {
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type PrepareChangeCheckoutResponse = {
  __typename?: "PrepareChangeCheckoutResponse";
  checkoutUrl?: Maybe<Scalars["URL"]["output"]>;
  error?: Maybe<BookingServicingError>;
  toolbar?: Maybe<EgdsDialogToolbar>;
};

export type PrepareCheckoutResponse = {
  __typename?: "PrepareCheckoutResponse";
  checkoutErrorToken?: Maybe<Scalars["String"]["output"]>;
  checkoutSessionId?: Maybe<Scalars["String"]["output"]>;
  checkoutSessionToken?: Maybe<Scalars["String"]["output"]>;
  checkoutUrl?: Maybe<Scalars["URL"]["output"]>;
  failureReason?: Maybe<FailureReason>;
  tripId?: Maybe<Scalars["UUID"]["output"]>;
};

export type PrepareCheckoutResponseOption = "ALTERNATIVE_ACTION_ON_FAILURE";

export type Price = {
  __typename?: "Price";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  duration?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Scalars["String"]["output"]>;
  priceDescription?: Maybe<Scalars["String"]["output"]>;
  totalPrice?: Maybe<Scalars["String"]["output"]>;
};

export type PriceCheckHistory = {
  __typename?: "PriceCheckHistory";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
  heading: EgdsHeading;
  message?: Maybe<PriceMatchPromiseMessage>;
  priceMatchPromiseTabs: Array<PriceMatchPromiseTab>;
  subHeading?: Maybe<InsurtechSpannableText>;
};

export type PriceCheckMutationResult = {
  __typename?: "PriceCheckMutationResult";
  message?: Maybe<Array<EgdsComposableTextElement>>;
  priceMatchPromiseDetails?: Maybe<PriceMatchPromiseDetails>;
  toast?: Maybe<PriceMatchPromiseToast>;
};

export type PriceDetails = {
  __typename?: "PriceDetails";
  analyticsData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  pricePresentation: PricePresentation;
  /** @deprecated Used only for tracking purposes */
  trackingData?: Maybe<TrackingData>;
};

export type PriceDetailsOptions = {
  __typename?: "PriceDetailsOptions";
  loyaltyRewardsId?: Maybe<Scalars["String"]["output"]>;
  metaDeeplink: Scalars["Boolean"]["output"];
  native?: Maybe<Scalars["Boolean"]["output"]>;
  pathEntry?: Maybe<Scalars["String"]["output"]>;
};

export interface PriceDetailsOptionsInput {
  loyaltyRewardsId?: InputMaybe<Scalars["String"]["input"]>;
  metaDeeplink: Scalars["Boolean"]["input"];
  native?: InputMaybe<Scalars["Boolean"]["input"]>;
  pathEntry?: InputMaybe<Scalars["String"]["input"]>;
}

export type PriceDisclaimer = {
  __typename?: "PriceDisclaimer";
  content: Scalars["String"]["output"];
  priceDisclaimerTrigger: PriceDisclaimerTrigger;
  tertiaryButton: UiTertiaryButton;
};

export type PriceDisclaimerTrigger = {
  __typename?: "PriceDisclaimerTrigger";
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type PriceDisplayChangeOnboardingAction = {
  __typename?: "PriceDisplayChangeOnboardingAction";
  actionId: Scalars["String"]["output"];
};

export type PriceDisplayChangeOnboardingContent = {
  __typename?: "PriceDisplayChangeOnboardingContent";
  button?: Maybe<ShoppingButton>;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated we will use ShoppingBasicFunctionParams to get the identifier */
  identifier?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PriceDisplayMessage = {
  __typename?: "PriceDisplayMessage";
  lineItems: Array<PriceDisplayMessageItem>;
};

export type PriceDisplayMessageItem = DisplayPrice | LodgingEnrichedMessage;

export type PriceDropProtectionAdditionalInfoPopover = {
  __typename?: "PriceDropProtectionAdditionalInfoPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  content: Array<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  icon: Icon;
};

export type PriceDropProtectionInfoExpandoSection = {
  __typename?: "PriceDropProtectionInfoExpandoSection";
  expandedContent: PriceDropProtectionInfoSection;
  header: EgdsExpandoListItem;
};

export type PriceDropProtectionInfoSection = {
  __typename?: "PriceDropProtectionInfoSection";
  additionalInfo?: Maybe<PriceDropProtectionAdditionalInfoPopover>;
  content: Array<InsurtechTextListWrapper>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use additionalInfo which supports direct use case of new presentation */
  infoTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
};

export type PriceDropProtectionPresentation =
  | FarePriceMatchPromiseSelectableCard
  | FlightsMessagingCard
  | PriceMatchPromiseToggleableCard;

export type PriceFilterPills = {
  __typename?: "PriceFilterPills";
  aggregate?: Maybe<PriceFilterPillsAggregate>;
  buckets?: Maybe<Array<Scalars["Int"]["output"]>>;
};

export type PriceFilterPillsAggregate = {
  __typename?: "PriceFilterPillsAggregate";
  label?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type PriceInsight = {
  __typename?: "PriceInsight";
  /** @deprecated Use analyticsPayload instead. We are moving to EGClickstream event tracking */
  analytics: PriceInsightAnalytics;
  analyticsPayload: Scalars["AnalyticsPayload"]["output"];
  card?: Maybe<PriceInsightCard>;
  disclaimer?: Maybe<PriceInsightDisclaimer>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
};

export type PriceInsightAnalytics = {
  __typename?: "PriceInsightAnalytics";
  content?: Maybe<PriceInsightClickstreamContent>;
  pageLoadEvent: EgClickstreamEvent;
};

export type PriceInsightCard = {
  __typename?: "PriceInsightCard";
  expando: EgdsExpandoCard;
  footer?: Maybe<EgdsPlainText>;
  rangeIndicator: RangeIndicator;
};

export type PriceInsightClickstreamContent = {
  __typename?: "PriceInsightClickstreamContent";
  currency: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  price: Scalars["String"]["output"];
  priceDiffPercentage: Scalars["String"]["output"];
};

export type PriceInsightDisclaimer = {
  __typename?: "PriceInsightDisclaimer";
  closeEvent: ClientSideAnalytics;
  description: EgdsStylizedText;
  openEvent: ClientSideAnalytics;
  title: EgdsStylizedText;
};

export type PriceInsights = {
  __typename?: "PriceInsights";
  priceInsightsCard?: Maybe<PriceInsightsCard>;
  /** @deprecated This resolver is deprecated, use priceInsightsCard resolver instead. */
  priceInsightsCardHeader?: Maybe<PriceInsightsCardHeader>;
  priceInsightsTrackingCarousel?: Maybe<PriceInsightsTrackingCarouselContainer>;
  priceInsightsTrackingLivePrice?: Maybe<Array<PriceInsightsTrackingLivePrice>>;
  priceInsightsTrackingView?: Maybe<PriceInsightsTrackingView>;
};

export type PriceInsightsPriceInsightsCardArgs = {
  deepLinkQueryParams?: InputMaybe<Array<PriceInsightsDeepLinkQueryParamInput>>;
  searchContext: PriceInsightsSearchContextInput;
};

export type PriceInsightsPriceInsightsTrackingLivePriceArgs = {
  displayedIndex?: InputMaybe<Scalars["Int"]["input"]>;
  subscriptionIds: Array<Scalars["String"]["input"]>;
};

export type PriceInsightsPriceInsightsTrackingViewArgs = {
  deepLinkQueryParams?: InputMaybe<Array<PriceInsightsDeepLinkQueryParamInput>>;
  searchContext: PriceInsightsSearchContextInput;
};

export interface PriceInsightsAnalytics {
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
}

export interface PriceInsightsButtonAction {
  /** @deprecated Use egcsAnalytics instead of analytics */
  analytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
}

export type PriceInsightsCard = PriceInsightsAnalytics & {
  __typename?: "PriceInsightsCard";
  accessibility?: Maybe<ClientAccessibility>;
  content?: Maybe<PriceInsightsCardContent>;
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  errorMessaging?: Maybe<ErrorMessagingPresentation>;
  forecast?: Maybe<PriceInsightsCardForecast>;
  header?: Maybe<PriceInsightsCardHeader>;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  priceInsightsTrackingToast?: Maybe<PriceInsightsTrackingToast>;
  /** @deprecated Taken as separate component 'PriceInsightsTrackingView' */
  priceTrackingToggle?: Maybe<PriceInsightsTrackingToggle>;
  /** @deprecated Toast has been moved in separate component 'PriceInsightsTrackingView' */
  toast?: Maybe<EgdsToast>;
  trackingDialog?: Maybe<IPriceInsightsDialog>;
};

export interface PriceInsightsCardContent {
  accessibility?: Maybe<ClientAccessibility>;
  legend: PriceInsightsGraphLegend;
  trackingToggleActionData?: Maybe<PriceInsightsTrackingToggleActionData>;
}

export type PriceInsightsCardDisclaimerSheet = {
  __typename?: "PriceInsightsCardDisclaimerSheet";
  content: Array<PriceInsightsCardDisclaimerSheetSubContent>;
  sheet: EgdsSheet;
};

export type PriceInsightsCardDisclaimerSheetSubContent = {
  __typename?: "PriceInsightsCardDisclaimerSheetSubContent";
  content: EgdsStylizedText;
  heading?: Maybe<EgdsHeading>;
  /** @deprecated Use heading instead of title */
  title: EgdsHeading;
};

export type PriceInsightsCardDisclaimerToolTip = {
  __typename?: "PriceInsightsCardDisclaimerToolTip";
  accessibility?: Maybe<ClientAccessibility>;
  action: PriceInsightsCardDisclaimerToolTipAction;
  icon: Icon;
};

export type PriceInsightsCardDisclaimerToolTipAction = UiAction & {
  __typename?: "PriceInsightsCardDisclaimerToolTipAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  disclaimer: PriceInsightsCardDisclaimerSheet;
};

export type PriceInsightsCardErrorMessaging = ErrorMessagingPresentation & {
  __typename?: "PriceInsightsCardErrorMessaging";
  egcsErrorAnalytics: EgClickstreamEvent;
  errorAnalytics: ClientSideImpressionEventAnalytics;
};

export type PriceInsightsCardForecast = {
  __typename?: "PriceInsightsCardForecast";
  message: EgdsIconText;
};

export type PriceInsightsCardHeader = {
  __typename?: "PriceInsightsCardHeader";
  accessibility?: Maybe<ClientAccessibility>;
  cardIllustration?: Maybe<Illustration>;
  cardSubHeading?: Maybe<EgdsHeading>;
  /** @deprecated This field is replaced with cardSubHeading */
  cardSubTitle: EgdsHeading;
  /** @deprecated This field is replaced with cardSubTitleDescriptionText */
  cardSubTitleDescription: EgdsStylizedText;
  cardSubTitleDescriptionText?: Maybe<EgdsStylizedText>;
  cardSubTitleText?: Maybe<EgdsSpannableText>;
  cardTitle: EgdsHeading;
  cardTitleDescription?: Maybe<EgdsSpannableText>;
  disclaimerToolTip?: Maybe<PriceInsightsCardDisclaimerToolTip>;
  /** @deprecated This field is replaced with cardSubTitleDescription */
  subTitle: EgdsStylizedText;
  /** @deprecated This field is replaced with cardSubTitle */
  title: EgdsStylizedText;
};

export type PriceInsightsCardMessage = {
  __typename?: "PriceInsightsCardMessage";
  accessibility?: Maybe<ClientAccessibility>;
  message: EgdsIconText;
};

export type PriceInsightsCarouselTrackingCard = PriceInsightsAnalytics & {
  __typename?: "PriceInsightsCarouselTrackingCard";
  accessibility: ClientAccessibility;
  action?: Maybe<UiLinkAction>;
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  displayIndex?: Maybe<Scalars["Int"]["output"]>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  livePriceUnavailableAccessibility: ClientAccessibility;
  livePriceUnavailableLabel: EgdsIconText;
  loadingLivePriceAccessibility: ClientAccessibility;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
  subscriptionId: Scalars["String"]["output"];
};

export type PriceInsightsCloseDialogButtonAction = PriceInsightsButtonAction & {
  __typename?: "PriceInsightsCloseDialogButtonAction";
  /** @deprecated Use egcsAnalytics instead of analytics */
  analytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
};

export interface PriceInsightsCreateMutationDataInput {
  lowestPredictedPrice?: InputMaybe<Scalars["Float"]["input"]>;
  lowestPredictedPriceDate?: InputMaybe<Scalars["String"]["input"]>;
  priceTrend?: InputMaybe<Scalars["String"]["input"]>;
  timeSeriesTierShown: Scalars["Int"]["input"];
}

export interface PriceInsightsDeepLinkQueryParamInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type PriceInsightsDialog = IPriceInsightsDialog & {
  __typename?: "PriceInsightsDialog";
  closeAnalytics?: Maybe<EgClickstreamEvent>;
  /** @deprecated Use egcsAnalytics instead of displayAnalytics */
  displayAnalytics?: Maybe<EgClickstreamEvent>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  footer: PriceInsightsTrackingDialogFooter;
  heading?: Maybe<Scalars["String"]["output"]>;
  paragraph: Scalars["String"]["output"];
};

export interface PriceInsightsDisableMutationDataInput {
  lowestPredictedPrice?: InputMaybe<Scalars["Float"]["input"]>;
  lowestPredictedPriceDate?: InputMaybe<Scalars["String"]["input"]>;
  priceTrend?: InputMaybe<Scalars["String"]["input"]>;
  timeSeriesTierShown: Scalars["Int"]["input"];
}

export type PriceInsightsExistingDialogButtonAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleActionData &
  UiAction & {
    __typename?: "PriceInsightsExistingDialogButtonAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    egcsOnClickAnalytics?: Maybe<EgClickstreamEvent>;
    lowestPredictedPrice?: Maybe<Scalars["Float"]["output"]>;
    lowestPredictedPriceDate?: Maybe<Scalars["String"]["output"]>;
    priceShown: Scalars["String"]["output"];
    priceTrend?: Maybe<Scalars["String"]["output"]>;
    subscriptionId: Scalars["String"]["output"];
    timeSeriesTierShown: Scalars["Int"]["output"];
  };

export type PriceInsightsExistingTrackingDialogAction = EgdsDialog &
  PriceInsightsTrackingToggleAction & {
    __typename?: "PriceInsightsExistingTrackingDialogAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    closeAnalytics?: Maybe<ClientSideAnalytics>;
    description: Scalars["String"]["output"];
    dialogFooter: EgdsInlineDialogFooter;
    displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    egcsDisplayAnalytics?: Maybe<EgClickstreamEvent>;
    title: Scalars["String"]["output"];
  };

export type PriceInsightsGraphArea = {
  __typename?: "PriceInsightsGraphArea";
  bottomLeft: PriceInsightsGraphPoint;
  bottomRight: PriceInsightsGraphPoint;
  topLeft: PriceInsightsGraphPoint;
  topRight: PriceInsightsGraphPoint;
};

export type PriceInsightsGraphBasedContent = PriceInsightsCardContent & {
  __typename?: "PriceInsightsGraphBasedContent";
  accessibility?: Maybe<ClientAccessibility>;
  data: Array<PriceInsightsGraphData>;
  legend: PriceInsightsGraphLegend;
  maxValue: Scalars["Int"]["output"];
  minValue: Scalars["Int"]["output"];
  shading?: Maybe<Array<PriceInsightsGraphShading>>;
  totalHorizontalPoints: Scalars["Int"]["output"];
  trackingToggleActionData?: Maybe<PriceInsightsTrackingToggleActionData>;
};

export type PriceInsightsGraphData =
  | PriceInsightsGraphDataDottedLine
  | PriceInsightsGraphDataMarker
  | PriceInsightsGraphDataSolidLine;

export type PriceInsightsGraphDataDottedLine = {
  __typename?: "PriceInsightsGraphDataDottedLine";
  points: Array<PriceInsightsGraphPoint>;
};

export type PriceInsightsGraphDataMarker = {
  __typename?: "PriceInsightsGraphDataMarker";
  points: Array<PriceInsightsGraphPoint>;
};

export type PriceInsightsGraphDataSolidLine = {
  __typename?: "PriceInsightsGraphDataSolidLine";
  points: Array<PriceInsightsGraphPoint>;
};

export type PriceInsightsGraphLabel = {
  __typename?: "PriceInsightsGraphLabel";
  content: EgdsStylizedText;
  normalisedPosition: Scalars["Float"]["output"];
  /** @deprecated This field is deprecated and replaced with field named content. */
  text: EgdsStylizedText;
};

export type PriceInsightsGraphLegend = {
  __typename?: "PriceInsightsGraphLegend";
  averagePriceLabel?: Maybe<PriceInsightsGraphLabel>;
  horizontalLabels?: Maybe<Array<PriceInsightsGraphLabel>>;
  pinLabel?: Maybe<EgdsText>;
  verticalLabels: Array<PriceInsightsGraphLabel>;
};

export type PriceInsightsGraphPoint = {
  __typename?: "PriceInsightsGraphPoint";
  normalizedValue: Scalars["Float"]["output"];
  offsetHorizontal: Scalars["Int"]["output"];
  value: Scalars["Int"]["output"];
};

export type PriceInsightsGraphShading = PriceInsightsGraphShadingHatch | PriceInsightsGraphShadingSolid;

export type PriceInsightsGraphShadingHatch = {
  __typename?: "PriceInsightsGraphShadingHatch";
  area: Array<PriceInsightsGraphArea>;
};

export type PriceInsightsGraphShadingSolid = {
  __typename?: "PriceInsightsGraphShadingSolid";
  area: Array<PriceInsightsGraphArea>;
};

export type PriceInsightsMobileAppDownloadSheet = PriceInsightsTrackingToggleAction & {
  __typename?: "PriceInsightsMobileAppDownloadSheet";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  closeAction: PriceInsightsTrackingMadSheetAction;
  closeAnalytics: Array<EgClickstreamAnalytics>;
  sheet: FlightsSheetType;
};

export type PriceInsightsOptOutDialogButtonAction = PriceInsightsButtonAction & {
  __typename?: "PriceInsightsOptOutDialogButtonAction";
  /** @deprecated Use egcsAnalytics instead of analytics */
  analytics?: Maybe<EgClickstreamEvent>;
  clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  operationData: PriceInsightsOptOutOperationData;
};

export type PriceInsightsOptOutOperationData = {
  __typename?: "PriceInsightsOptOutOperationData";
  notificationPreferenceType: NotificationPreferenceType;
  subscriptionId: Scalars["String"]["output"];
};

export type PriceInsightsRetryTrackingDialogButtonAction = UiAction & {
  __typename?: "PriceInsightsRetryTrackingDialogButtonAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionType: DialogButtonActionType;
  analytics: ClientSideAnalytics;
};

export interface PriceInsightsSearchContextInput {
  carSearchCriteria?: InputMaybe<CarSearchCriteriaInput>;
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
  propertySearchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
}

export type PriceInsightsSubscriptionSeenAction = {
  __typename?: "PriceInsightsSubscriptionSeenAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  priceValue: Scalars["String"]["output"];
  subscriptionId: Scalars["String"]["output"];
};

export type PriceInsightsTextBasedContent = PriceInsightsCardContent & {
  __typename?: "PriceInsightsTextBasedContent";
  /** @deprecated Changed to optIn and optOut accessibility content */
  accessibility?: Maybe<ClientAccessibility>;
  initialCardMessage: PriceInsightsCardMessage;
  legend: PriceInsightsGraphLegend;
  /** @deprecated This will be replaced by optInMessage and optOutMessage */
  message: EgdsIconText;
  optInCardMessage: PriceInsightsCardMessage;
  /** @deprecated Changed to provide accessibility */
  optInMessage: EgdsIconText;
  optOutCardMessage: PriceInsightsCardMessage;
  /** @deprecated Changed to provide accessibility */
  optOutMessage: EgdsIconText;
  trackingToggleActionData?: Maybe<PriceInsightsTrackingToggleActionData>;
};

export type PriceInsightsTrackingCarouselContainer = {
  __typename?: "PriceInsightsTrackingCarouselContainer";
  elements: Array<PriceInsightsCarouselTrackingCard>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type PriceInsightsTrackingDialogFooter = {
  __typename?: "PriceInsightsTrackingDialogFooter";
  buttons: Array<PriceInsightsUiButton>;
};

export type PriceInsightsTrackingLivePrice = PriceInsightsAnalytics & {
  __typename?: "PriceInsightsTrackingLivePrice";
  accessibility: ClientAccessibility;
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsDisplayAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsDisplayAnalyticsEvents instead of egcsDisplayAnalyticsList */
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsOnClickAnalyticsEvents?: Maybe<Array<EgClickstreamAnalytics>>;
  /** @deprecated Use egcsOnClickAnalyticsEvents instead of egcsOnClickAnalyticsList */
  egcsOnClickAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  lastSeenPriceLabel?: Maybe<EgdsText>;
  livePriceLabel?: Maybe<EgdsText>;
  livePriceUnavailableLabel?: Maybe<EgdsIconText>;
  onClickAnalyticsList?: Maybe<Array<ClientSideAnalytics>>;
  seenAction?: Maybe<PriceInsightsSubscriptionSeenAction>;
  subscriptionId: Scalars["String"]["output"];
};

export type PriceInsightsTrackingMadSheetAction =
  | UiCreatePriceInsightsTrackingAction
  | UiValidateTravelerLoggedInAction;

export type PriceInsightsTrackingRetryDialogAction = EgdsDialog &
  PriceInsightsTrackingToggleAction & {
    __typename?: "PriceInsightsTrackingRetryDialogAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    closeAnalytics?: Maybe<ClientSideAnalytics>;
    content: Scalars["String"]["output"];
    dialogFooter: EgdsInlineDialogFooter;
    displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
    heading: Scalars["String"]["output"];
    paragraph: Scalars["String"]["output"];
  };

export type PriceInsightsTrackingToast = {
  __typename?: "PriceInsightsTrackingToast";
  actionAnalytics?: Maybe<EgClickstreamAnalytics>;
  autoDismissDuration?: Maybe<ToastDismissDuration>;
  egcsAnalytics: EgClickstreamAnalytics;
  toast: EgdsToast;
};

export type PriceInsightsTrackingToggle = UiToggle & {
  __typename?: "PriceInsightsTrackingToggle";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityDescription?: Maybe<Scalars["String"]["output"]>;
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription: Scalars["String"]["output"];
  checkedLabel: Scalars["String"]["output"];
  displayAnalyticsList?: Maybe<Array<ClientSideImpressionEventAnalytics>>;
  egcsCheckedAnalytics?: Maybe<EgClickstreamEvent>;
  egcsDisplayAnalyticsList?: Maybe<Array<EgClickstreamEvent>>;
  egcsUnCheckedAnalytics?: Maybe<EgClickstreamEvent>;
  enabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  toggleOffAction: PriceInsightsTrackingToggleOffAction;
  toggleOnAction: PriceInsightsTrackingToggleOnAction;
  uncheckedAccessibilityDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription: Scalars["String"]["output"];
  uncheckedLabel: Scalars["String"]["output"];
};

export interface PriceInsightsTrackingToggleAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
}

export interface PriceInsightsTrackingToggleActionData {
  lowestPredictedPrice?: Maybe<Scalars["Float"]["output"]>;
  lowestPredictedPriceDate?: Maybe<Scalars["String"]["output"]>;
  priceTrend?: Maybe<Scalars["String"]["output"]>;
  timeSeriesTierShown: Scalars["Int"]["output"];
}

export type PriceInsightsTrackingToggleActionMetadata = PriceInsightsTrackingToggleActionData & {
  __typename?: "PriceInsightsTrackingToggleActionMetadata";
  lowestPredictedPrice?: Maybe<Scalars["Float"]["output"]>;
  lowestPredictedPriceDate?: Maybe<Scalars["String"]["output"]>;
  priceTrend?: Maybe<Scalars["String"]["output"]>;
  timeSeriesTierShown: Scalars["Int"]["output"];
};

export type PriceInsightsTrackingToggleOffAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleAction &
  PriceInsightsTrackingToggleActionData & {
    __typename?: "PriceInsightsTrackingToggleOffAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    lowestPredictedPrice?: Maybe<Scalars["Float"]["output"]>;
    lowestPredictedPriceDate?: Maybe<Scalars["String"]["output"]>;
    priceShown: Scalars["String"]["output"];
    priceTrend?: Maybe<Scalars["String"]["output"]>;
    subscriptionId: Scalars["String"]["output"];
    timeSeriesTierShown: Scalars["Int"]["output"];
  };

export type PriceInsightsTrackingToggleOnAction =
  | PriceInsightsExistingTrackingDialogAction
  | PriceInsightsMobileAppDownloadSheet
  | UiCreatePriceInsightsTrackingAction
  | UiValidateTravelerLoggedInAction;

export type PriceInsightsTrackingView = {
  __typename?: "PriceInsightsTrackingView";
  egcsAnalytics?: Maybe<EgClickstreamAnalytics>;
  priceInsightsTrackingToast?: Maybe<PriceInsightsTrackingToast>;
  priceTrackingToggle?: Maybe<PriceInsightsTrackingToggle>;
  /** @deprecated Use 'trackingDialog' instead of retryTrackingDialog */
  retryTrackingDialog?: Maybe<PriceInsightsTrackingRetryDialogAction>;
  /** @deprecated Use PriceInsightsTrackingToast instead of toast */
  toast?: Maybe<EgdsToast>;
  trackingDialog?: Maybe<PriceInsightsUiDialog>;
};

export interface PriceInsightsUiButton {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: PriceInsightsButtonAction;
  analytics?: Maybe<EgClickstreamEvent>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
}

export type PriceInsightsUiDialog = PriceInsightsDialog | PriceInsightsTrackingRetryDialogAction;

export type PriceInsightsUiPrimaryButton = PriceInsightsUiButton & {
  __typename?: "PriceInsightsUIPrimaryButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: PriceInsightsButtonAction;
  analytics?: Maybe<EgClickstreamEvent>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type PriceInsightsUiTertiaryButton = PriceInsightsUiButton & {
  __typename?: "PriceInsightsUITertiaryButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: PriceInsightsButtonAction;
  analytics?: Maybe<EgClickstreamEvent>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export interface PriceInsightsUpdateMutationDataInput {
  lowestPredictedPrice?: InputMaybe<Scalars["Float"]["input"]>;
  lowestPredictedPriceDate?: InputMaybe<Scalars["String"]["input"]>;
  priceTrend?: InputMaybe<Scalars["String"]["input"]>;
  timeSeriesTierShown: Scalars["Int"]["input"];
}

export type PriceLineBadge = {
  __typename?: "PriceLineBadge";
  badge?: Maybe<EgdsStandardBadge>;
  programBadge?: Maybe<EgdsProgramBadge>;
};

export type PriceLineHeading = {
  __typename?: "PriceLineHeading";
  /** @deprecated Use `additionalInformation` instead */
  additionalInfo?: Maybe<AdditionalInformationPopover>;
  additionalInformation?: Maybe<PricePresentationAdditionalInformation>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  size: Scalars["String"]["output"];
  tag: Scalars["String"]["output"];
};

export type PriceLineText = {
  __typename?: "PriceLineText";
  /** @deprecated Use `additionalInformation` instead */
  additionalInfo?: Maybe<AdditionalInformationPopover>;
  additionalInformation?: Maybe<PricePresentationAdditionalInformation>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use `graphic` instead. */
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
  weight?: Maybe<TextWeight>;
};

export type PriceMatchPromiseChartType = "BAR" | "LINE";

export type PriceMatchPromiseDetails = {
  __typename?: "PriceMatchPromiseDetails";
  dialogs?: Maybe<Array<InsurtechDialog>>;
  headingCopy?: Maybe<Array<EgdsComposableTextElement>>;
  infoSection?: Maybe<PriceMatchPromiseInfo>;
  krazyGlueScriptUrl?: Maybe<Scalars["String"]["output"]>;
  manualPriceCheck?: Maybe<ManualPriceCheckSection>;
  message?: Maybe<PriceMatchPromiseMessage>;
  priceCheckHistory?: Maybe<PriceCheckHistory>;
  recap?: Maybe<PriceMatchPromiseRecap>;
  tripContextToken?: Maybe<Scalars["String"]["output"]>;
};

export type PriceMatchPromiseHistoryChart = {
  __typename?: "PriceMatchPromiseHistoryChart";
  chartType: PriceMatchPromiseChartType;
  data: Array<PriceMatchPromiseHistoryChartDataPoint>;
  horizontalLabelFormatter?: Maybe<Scalars["String"]["output"]>;
  horizontalScaleType: PriceMatchPromiseScaleType;
  verticalValueFormatter?: Maybe<Scalars["String"]["output"]>;
};

export type PriceMatchPromiseHistoryChartDataPoint = {
  __typename?: "PriceMatchPromiseHistoryChartDataPoint";
  x: Scalars["String"]["output"];
  y: Scalars["String"]["output"];
};

export type PriceMatchPromiseHistoryList = {
  __typename?: "PriceMatchPromiseHistoryList";
  highlight?: Maybe<PriceMatchPromiseHistoryListHighlight>;
  priceChecks: Array<PriceMatchPromiseHistoryListItem>;
  showLessAction?: Maybe<EgdsStandardLink>;
  showMoreAction?: Maybe<EgdsStandardLink>;
};

export type PriceMatchPromiseHistoryListHighlight = {
  __typename?: "PriceMatchPromiseHistoryListHighlight";
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type PriceMatchPromiseHistoryListItem = {
  __typename?: "PriceMatchPromiseHistoryListItem";
  additionalPriceChecks?: Maybe<Array<PriceMatchPromiseHistoryListItem>>;
  additionalPriceChecksDialogTitle?: Maybe<Scalars["String"]["output"]>;
  additionalPriceChecksLink?: Maybe<EgdsStandardLink>;
  copy: Array<EgdsComposableTextElement>;
  priceDelta?: Maybe<PriceMatchPromisePriceDelta>;
};

export type PriceMatchPromiseInfo = {
  __typename?: "PriceMatchPromiseInfo";
  /** @deprecated Deprecating due to latest changes for US Content, will be using expandoSections */
  copy: Array<EgdsComposableTextElement>;
  expandoSections?: Maybe<Array<PriceDropProtectionInfoExpandoSection>>;
  footnotes?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Deprecating due to latest changes for US Content, will be using expandoSections */
  heading: EgdsHeading;
  links?: Maybe<EgdsLinkList>;
};

export type PriceMatchPromiseLoader = {
  __typename?: "PriceMatchPromiseLoader";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
};

export type PriceMatchPromiseMessage = {
  __typename?: "PriceMatchPromiseMessage";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
  icon?: Maybe<Icon>;
};

export type PriceMatchPromisePriceDelta = {
  __typename?: "PriceMatchPromisePriceDelta";
  icon?: Maybe<Icon>;
  price: Scalars["String"]["output"];
};

export type PriceMatchPromisePriceHighlight = {
  __typename?: "PriceMatchPromisePriceHighlight";
  description?: Maybe<Scalars["String"]["output"]>;
  price: Scalars["String"]["output"];
  textTheme?: Maybe<TextTheme>;
};

export type PriceMatchPromisePriceHighlightsSection = {
  __typename?: "PriceMatchPromisePriceHighlightsSection";
  content?: Maybe<InsurtechSpannableText>;
  /** @deprecated deprecating in favor of InsurtechSpannableText */
  copy?: Maybe<EgdsSpannableText>;
  priceHighlights?: Maybe<Array<PriceMatchPromisePriceHighlight>>;
};

export type PriceMatchPromiseRecap = {
  __typename?: "PriceMatchPromiseRecap";
  copy?: Maybe<Array<EgdsComposableTextElement>>;
  priceHighlightsSection?: Maybe<PriceMatchPromisePriceHighlightsSection>;
};

export type PriceMatchPromiseScaleType = "DATE" | "LABEL" | "TIME";

export type PriceMatchPromiseSelectedCardSection = {
  __typename?: "PriceMatchPromiseSelectedCardSection";
  description: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  icon: Icon;
  onDisplayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  removeActionLink?: Maybe<FlightsActionLink>;
};

export type PriceMatchPromiseSelectionCardSection = {
  __typename?: "PriceMatchPromiseSelectionCardSection";
  description: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  icon: Icon;
  infoTrigger?: Maybe<FlightsDialogTrigger>;
  onDisplayAnalytics?: Maybe<Array<FlightsAnalytics>>;
  price?: Maybe<Scalars["String"]["output"]>;
  toggleCheckbox?: Maybe<FlightsActionCheckbox>;
};

export type PriceMatchPromiseState = "REMOVABLE" | "SELECTABLE" | "STATIC" | "TOGGLEABLE";

export type PriceMatchPromiseTab = {
  __typename?: "PriceMatchPromiseTab";
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"]["output"];
  tabData: PriceMatchPromiseTabData;
};

export type PriceMatchPromiseTabData = PriceMatchPromiseHistoryChart | PriceMatchPromiseHistoryList;

export type PriceMatchPromiseToast = {
  __typename?: "PriceMatchPromiseToast";
  message: Scalars["String"]["output"];
};

export type PriceMatchPromiseToggleableCard = FarePriceMatchPromiseCard & {
  __typename?: "PriceMatchPromiseToggleableCard";
  contentSection: ToggleableCardContentSection;
  /** @deprecated Use headingSection instead of header */
  header: EgdsGraphicText;
  /** @deprecated Use headingSection instead of heading */
  heading: EgdsIconText;
  headingSection: ToggleableCardHeadingSection;
  onDisplayAnalytics: Array<ClientSideAnalytics>;
  priceMatchPromiseState: PriceMatchPromiseState;
  /** @deprecated Use subHeading from headingSection instead of subHeading */
  subHeading: Scalars["String"]["output"];
};

export type PricePresentation = {
  __typename?: "PricePresentation";
  footer?: Maybe<PricePresentationFooter>;
  /** @deprecated Use title of type PricePresentationTitle */
  header: PricePresentationHeader;
  sections: Array<PricePresentationSection>;
  title?: Maybe<PricePresentationTitle>;
};

export interface PricePresentationAdditionalInformation {
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
}

export type PricePresentationAdditionalInformationDialog = PricePresentationAdditionalInformation & {
  __typename?: "PricePresentationAdditionalInformationDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  footer?: Maybe<EgdsDialogFooter>;
  icon?: Maybe<Icon>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
};

export type PricePresentationAdditionalInformationPopover = PricePresentationAdditionalInformation & {
  __typename?: "PricePresentationAdditionalInformationPopover";
  analytics?: Maybe<ClientSideAnalytics>;
  closeLabel?: Maybe<Scalars["String"]["output"]>;
  enrichedSecondaries: Array<AdditionalInformationPopoverSection>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type PricePresentationDialog = LodgingDialog & {
  __typename?: "PricePresentationDialog";
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type PricePresentationFooter = {
  __typename?: "PricePresentationFooter";
  header?: Maybe<Scalars["String"]["output"]>;
  messages: Array<PricePresentationLineItemMessage>;
};

export type PricePresentationHeader = {
  __typename?: "PricePresentationHeader";
  primary: Scalars["String"]["output"];
};

export type PricePresentationLineItem = {
  __typename?: "PricePresentationLineItem";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  enrichedValue?: Maybe<PricePresentationLineItemEntry>;
  name: PricePresentationLineItemEntry;
  /** @deprecated Use enrichedValue of type PricePresentationLineItemEntry */
  value?: Maybe<PriceLineText>;
};

export type PricePresentationLineItemEntry = {
  __typename?: "PricePresentationLineItemEntry";
  primaryMessage: PricePresentationLineItemMessage;
  secondaryMessages: Array<PricePresentationLineItemMessage>;
};

export type PricePresentationLineItemMessage = InlinePriceLineText | PriceLineBadge | PriceLineHeading | PriceLineText;

export type PricePresentationSection = {
  __typename?: "PricePresentationSection";
  header?: Maybe<PricePresentationLineItem>;
  subSections: Array<PricePresentationSubSection>;
};

export type PricePresentationSubSection = {
  __typename?: "PricePresentationSubSection";
  header?: Maybe<PricePresentationLineItem>;
  items: Array<PricePresentationLineItem>;
};

export type PricePresentationTitle = {
  __typename?: "PricePresentationTitle";
  primary: Scalars["String"]["output"];
};

export type PriceRange = CriteriaRange & {
  __typename?: "PriceRange";
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
};

export interface PriceRangeInput {
  max?: InputMaybe<Scalars["Float"]["input"]>;
  min?: InputMaybe<Scalars["Float"]["input"]>;
}

export type PriceSummary = {
  __typename?: "PriceSummary";
  disclaimers?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Use PriceSummarySection::sectionFooter instead */
  footer?: Maybe<PriceSummaryFooter>;
  /** @deprecated Use priceSummaryHeading instead */
  heading: Scalars["String"]["output"];
  priceSummaryHeading?: Maybe<LodgingEnrichedMessage>;
  sections: Array<PriceSummarySection>;
};

export type PriceSummaryFooter = {
  __typename?: "PriceSummaryFooter";
  /** @deprecated Use PriceSummary.disclaimers instead */
  disclaimerMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  footerHeading?: Maybe<LodgingEnrichedMessage>;
  footerMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Use footerHeading instead */
  heading?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use footerMessages instead */
  messages?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type PriceSummaryLineItem = {
  __typename?: "PriceSummaryLineItem";
  name: PriceSummaryLineItemEntry;
  value: PriceSummaryLineItemEntry;
};

export type PriceSummaryLineItemEntry = {
  __typename?: "PriceSummaryLineItemEntry";
  /** @deprecated Use primaryMessage instead */
  primary: Scalars["String"]["output"];
  primaryMessage: LodgingEnrichedMessage;
  /** @deprecated Use secondaryMessages instead */
  secondary?: Maybe<Array<Scalars["String"]["output"]>>;
  secondaryMessages?: Maybe<Array<LodgingEnrichedMessage>>;
};

export type PriceSummarySection = {
  __typename?: "PriceSummarySection";
  /** @deprecated Use sectionFooter instead */
  footer?: Maybe<PriceSummaryLineItem>;
  /** @deprecated Use priceSummaryHeading instead */
  heading?: Maybe<Scalars["String"]["output"]>;
  items: Array<PriceSummaryLineItem>;
  sectionFooter?: Maybe<PriceSummaryFooter>;
  sectionHeading?: Maybe<LodgingEnrichedMessage>;
};

export type PricingScheme = {
  __typename?: "PricingScheme";
  type: PricingSchemeType;
};

export type PricingSchemeType = "DAILY_RATE" | "TOTAL_RATE";

export type PricingType = "OccupancyBasedPricing" | "PerDayPricing";

export type PrimaryActivitiesCriteria = {
  __typename?: "PrimaryActivitiesCriteria";
  dateRange?: Maybe<ActivityDateRange>;
  destination?: Maybe<ActivityDestination>;
  filters?: Maybe<Array<ActivityFilter>>;
  pinnedActivityIds?: Maybe<Array<Scalars["String"]["output"]>>;
  selectedOffers?: Maybe<Array<ActivityOfferNaturalKey>>;
};

export interface PrimaryActivitySearchCriteriaInput {
  dateRange?: InputMaybe<ActivitySearchCriteriaDateRangeInput>;
  destination?: InputMaybe<ActivitySearchCriteriaLocationInput>;
}

export type PrimaryCarCriteria = {
  __typename?: "PrimaryCarCriteria";
  dropOffDateTime: DateTime;
  dropOffLocation?: Maybe<CarRentalLocation>;
  pickUpDateTime: DateTime;
  pickUpLocation: CarRentalLocation;
};

export interface PrimaryCarCriteriaInput {
  dropOffDateTime: DateTimeInput;
  dropOffLocation?: InputMaybe<CarRentalLocationInput>;
  pickUpDateTime: DateTimeInput;
  pickUpLocation: CarRentalLocationInput;
}

export interface PrimaryCarOfferCriteriaInput {
  options: Array<CarOfferOptionInput>;
}

export interface PrimaryCruiseCriteriaInput {
  adultCount?: InputMaybe<Scalars["Int"]["input"]>;
  childCount?: InputMaybe<Scalars["Int"]["input"]>;
  children?: InputMaybe<Array<CruiseChildInput>>;
  departureDateRange?: InputMaybe<DateRangeInput>;
  destinations?: InputMaybe<Array<Scalars["String"]["input"]>>;
  durationRange?: InputMaybe<CruiseDurationRangeInput>;
}

export interface PrimaryCruiseSailingDetailsCriteriaInput {
  adultCount: Scalars["Int"]["input"];
  cabinExperience?: InputMaybe<Scalars["String"]["input"]>;
  cabinType?: InputMaybe<Scalars["String"]["input"]>;
  childCount: Scalars["Int"]["input"];
  children?: InputMaybe<Array<CruiseChildInput>>;
  sailingToken: Scalars["String"]["input"];
}

export type PrimaryDealsShoppingCriteria = {
  __typename?: "PrimaryDealsShoppingCriteria";
  flight?: Maybe<PrimaryFlightDealsCriteria>;
};

export interface PrimaryDealsShoppingCriteriaInput {
  flight?: InputMaybe<PrimaryFlightDealsCriteriaInput>;
}

export type PrimaryFlightCriteria = {
  __typename?: "PrimaryFlightCriteria";
  journeyCriterias: Array<FlightsJourneyCriteria>;
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelers: Array<TravelerDetails>;
  tripType: FlightsTripType;
};

export interface PrimaryFlightCriteriaInput {
  journeyCriterias: Array<FlightsJourneyCriteriaInput>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  travelers: Array<TravelerDetailsInput>;
  tripType: FlightsTripType;
}

export type PrimaryFlightCriteriaInputType = {
  __typename?: "PrimaryFlightCriteriaInputType";
  journeyCriterias: Array<FlightsJourneyCriteriaInputType>;
  searchPreferences?: Maybe<FlightsSearchPreferencesInputType>;
  travelers: Array<TravelerDetailsInputType>;
  tripType: FlightsTripType;
};

export type PrimaryFlightDealsCriteria = {
  __typename?: "PrimaryFlightDealsCriteria";
  originAirportCode?: Maybe<Scalars["String"]["output"]>;
  travelers: Array<TravelerDetails>;
};

export interface PrimaryFlightDealsCriteriaInput {
  originAirportCode?: InputMaybe<Scalars["String"]["input"]>;
  travelers: Array<TravelerDetailsInput>;
}

export type PrimaryGroundTransfersCriteria = {
  __typename?: "PrimaryGroundTransfersCriteria";
  /** @deprecated Use journeyCriteria instead */
  dateRange: OptionalDateTimeRange;
  /** @deprecated Use journeyCriteria instead */
  direction: GroundTransfersDirection;
  journeyCriteria?: Maybe<Array<GroundTransfersJourneyCriteria>>;
  /** @deprecated Use journeyCriteria instead */
  locations: Array<GroundTransfersLocation>;
  /** @deprecated Use pinnedOfferIds instead */
  pinnedOffers?: Maybe<Array<GroundTransfersNaturalKey>>;
  pinnedProductIds?: Maybe<Array<Scalars["String"]["output"]>>;
  selectedOffers?: Maybe<Array<GroundTransfersNaturalKey>>;
  shoppingPath?: Maybe<ShoppingPathType>;
  travelerDetails: Array<GroundTransfersTravelerDetails>;
};

export interface PrimaryGroundTransfersCriteriaInput {
  dateRange: OptionalDateTimeRangeInput;
  direction: GroundTransfersDirection;
  journeyCriteria?: InputMaybe<Array<GroundTransfersJourneyCriteriaInput>>;
  locations: Array<GroundTransfersLocationInput>;
  pinnedOffers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  pinnedProductIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  selectedOffers?: InputMaybe<Array<GroundTransfersNaturalKeyInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
  travelerDetails: Array<GroundTransfersTravelerDetailsInput>;
}

export type PrimaryInfo = {
  __typename?: "PrimaryInfo";
  heading: EgdsStylizedText;
  subheading: EgdsStylizedText;
};

export type PrimaryPackageSearchCriteria = {
  __typename?: "PrimaryPackageSearchCriteria";
  cabinClass?: Maybe<FlightsCabinClass>;
  dateRange?: Maybe<DateRange>;
  destination?: Maybe<PackageLocation>;
  origin?: Maybe<PackageLocation>;
  travelerOccupancyGroups?: Maybe<Array<TravelerOccupancyGroup>>;
};

export interface PrimaryPackageSearchCriteriaInput {
  cabinClass?: InputMaybe<FlightsCabinClass>;
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<PackageLocationInput>;
  origin?: InputMaybe<PackageLocationInput>;
  partialStayDateRange?: InputMaybe<DateRangeInput>;
  travelerOccupancyGroups?: InputMaybe<Array<TravelerOccupancyGroupInput>>;
}

export interface PrimaryProductShoppingCriteriaInput {
  car?: InputMaybe<PrimaryCarCriteriaInput>;
  cruise?: InputMaybe<PrimaryCruiseCriteriaInput>;
  flight?: InputMaybe<PrimaryFlightCriteriaInput>;
  groundTransportation?: InputMaybe<PrimaryGroundTransfersCriteriaInput>;
  property?: InputMaybe<PrimaryPropertyCriteriaInput>;
}

export type PrimaryPropertyCriteria = {
  __typename?: "PrimaryPropertyCriteria";
  dateRange?: Maybe<PropertyDateRange>;
  destination: Destination;
  flexSearchCriteria?: Maybe<FlexibleSearchCriteria>;
  rooms: Array<Room>;
};

export interface PrimaryPropertyCriteriaInput {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  destination: DestinationInput;
  flexSearchCriteria?: InputMaybe<FlexibleSearchCriteriaInput>;
  rooms: Array<RoomInput>;
}

export type PrivacySellState = "CAN_NOT_SELL" | "CAN_SELL";

export type PrivacyTrackingState = "CAN_NOT_TRACK" | "CAN_TRACK";

export type ProductAmenitiesSection = ProductDetailsElement & {
  __typename?: "ProductAmenitiesSection";
  /** @deprecated Replaced with `list`. Will implement EGDSTextList to support broader use case, replace with list */
  amenities?: Maybe<EgdsTextIconList>;
  elementID: Scalars["String"]["output"];
  list?: Maybe<EgdsTextList>;
  title?: Maybe<EgdsStylizedText>;
};

export type ProductAttributesSection = ProductDetailsElement & {
  __typename?: "ProductAttributesSection";
  attributes: EgdsTextIconList;
  elementID: Scalars["String"]["output"];
};

export type ProductBenefitsSection = ProductDetailsElement & {
  __typename?: "ProductBenefitsSection";
  accessibilityText?: Maybe<Scalars["String"]["output"]>;
  badges: Array<EgdsStandardBadge>;
  /** @deprecated Use 'details' instead */
  benefits: Array<EgdsStylizedText>;
  elementID: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  summary: Array<EgdsTextIconListItem>;
};

export type ProductCardLocationInfo = {
  __typename?: "ProductCardLocationInfo";
  primaryText?: Maybe<EgdsGraphicText>;
  subText?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ProductCarousel = {
  __typename?: "ProductCarousel";
  items?: Maybe<Array<ProductCarouselCard>>;
  nextButton?: Maybe<EgdsButton>;
  previousButton?: Maybe<EgdsButton>;
};

export interface ProductCarouselCard {
  id?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
}

export type ProductCarouselContainer = LodgingCarouselContainer;

export type ProductCarouselResults = {
  __typename?: "ProductCarouselResults";
  carousels: Array<ProductCarouselContainer>;
};

export type ProductCategorizedImages = {
  __typename?: "ProductCategorizedImages";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  categoryId: Scalars["String"]["output"];
  categoryName: Scalars["String"]["output"];
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  galleryButton?: Maybe<ProductDialogTriggerButton>;
  images: Array<ProductImageInfo>;
};

export type ProductContext = "BRAND_RESULT_LISTING" | "FLIGHT_SPONSORED_LISTING" | "LODGING_SPONSORED_LISTING";

export type ProductDescriptionContent = {
  __typename?: "ProductDescriptionContent";
  content?: Maybe<ShoppingProductContentSection>;
};

export interface ProductDetailsElement {
  elementID: Scalars["String"]["output"];
}

export type ProductDetailsHeader = {
  __typename?: "ProductDetailsHeader";
  allImages?: Maybe<Array<ProductImage>>;
  description?: Maybe<Scalars["String"]["output"]>;
  headerImage?: Maybe<ProductImage>;
  heading?: Maybe<EgdsHeading>;
  /** @deprecated Deprecated for type 'ProductImage' to support width, replace with headerImage */
  image?: Maybe<Image>;
  /** @deprecated Deprecated for type 'ProductRatingSummary', replace with ratingSummary */
  rating?: Maybe<EgdsProductRatingSummary>;
  ratingSummary?: Maybe<ProductRatingSummary>;
};

export type ProductDetailsLayout = {
  __typename?: "ProductDetailsLayout";
  body?: Maybe<Array<ProductDetailsElement>>;
  elements: Array<Maybe<ProductDetailsElement>>;
  footer: Array<Maybe<ProductDetailsElement>>;
  header: ProductDetailsHeader;
};

export type ProductDialogToolbar = {
  __typename?: "ProductDialogToolbar";
  analytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  secondaryIcon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProductDialogTriggerButton = {
  __typename?: "ProductDialogTriggerButton";
  button?: Maybe<DialogTriggerButton>;
  dialogId: Scalars["String"]["output"];
};

export type ProductDialogTriggerMessage = {
  __typename?: "ProductDialogTriggerMessage";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  dialogName?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  value: Scalars["String"]["output"];
};

export type ProductEntityAssetSelection =
  | "ABOUT_PROPERTY"
  | "ACCESSIBILITY_CONTENT"
  | "AMENITY"
  | "AT_A_GLANCE_AMENITIES"
  | "EXPERIENCE_SCORE"
  | "EXPERIENCE_SCORE_WITH_TOP_RATED_ATTRIBUTES"
  | "FAMILY_HIGHLIGHTS"
  | "GUEST_CHOICE"
  | "HEADLINE"
  | "IMAGE"
  | "IMAGE_RICH_AMENITY"
  | "LOCATION_DESCRIPTION"
  | "LUXURY_STAYS"
  | "PLACE"
  | "POLICY"
  | "PRODUCT_ATTRIBUTE"
  | "PRODUCT_HIGHLIGHTS"
  | "PRODUCT_UNIQUENESS_HIGHLIGHTS"
  | "PROPERTY_AMENITY_GROUPS"
  | "RELEVANT_AMENITY_DETAILS"
  | "REVIEW_DETAILS"
  | "REVIEW_IMAGES"
  | "REVIEW_SUMMARY"
  | "REVIEW_SUMMARY_HIGHLIGHTS"
  | "SUPPLY_SCORE"
  | "TOP_RATED_DETAILS"
  | "VIRTUAL_TOUR";

export type ProductEntityAssetSelectionParameters = {
  __typename?: "ProductEntityAssetSelectionParameters";
  limit?: Maybe<Scalars["Int"]["output"]>;
  refinements?: Maybe<Array<ProductEntityRefinementSelection>>;
};

export type ProductEntityRefinementSelection = "EMBARGO";

export type ProductGalleryDialog = {
  __typename?: "ProductGalleryDialog";
  nextButton?: Maybe<UiFloatingActionButton>;
  previousButton?: Maybe<UiFloatingActionButton>;
  primaryButton?: Maybe<ProductScrollToButton>;
  similarImageDialog?: Maybe<SimilarImageDialog>;
  similarImagesOnboarding?: Maybe<PopUpMessage>;
  toolbar?: Maybe<ProductDialogToolbar>;
  trigger?: Maybe<ProductDialogTriggerMessage>;
};

export interface ProductGroupContextInput {
  affinityURNs?: InputMaybe<Array<Scalars["String"]["input"]>>;
  configurationIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
  lineOfBusinessDomain?: InputMaybe<LineOfBusinessDomain>;
  marketingChannelTypeCode?: InputMaybe<Scalars["Int"]["input"]>;
  userLocation?: InputMaybe<CoordinatesInput>;
}

export type ProductGroupsContainer = ProductListComponent | TopTenProductsCarouselComponent;

export type ProductHeadline = {
  __typename?: "ProductHeadline";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  featuredMessages: Array<ProductSupportingMessage>;
  primary: Scalars["String"]["output"];
  rating?: Maybe<EgdsIconRating>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  seoStructuredData?: Maybe<Scalars["String"]["output"]>;
  supportingMessages: Array<ProductSupportingMessage>;
};

export type ProductHighlights = ProductHighlightsSection | ProductUniqueness;

export type ProductHighlightsSection = {
  __typename?: "ProductHighlightsSection";
  adaptExCampaignEvents?: Maybe<ShoppingAdaptExCampaignEvents>;
  content?: Maybe<ShoppingProductContent>;
  highlightsType: Scalars["String"]["output"];
};

export interface ProductIdentifierInput {
  id: Scalars["String"]["input"];
  productLine?: InputMaybe<ProductLine>;
  searchCriteriaSelections?: InputMaybe<Array<SearchCriteriaSelectionsInput>>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelSearchCriteria?: InputMaybe<TravelSearchCriteriaInput>;
  type: IdentifierType;
}

export type ProductImage = {
  __typename?: "ProductImage";
  /** @deprecated Deprecated for 'description' */
  altText?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  aspectRatio?: Maybe<AspectRatio>;
  description?: Maybe<Scalars["String"]["output"]>;
  fitment: ProductImageFitment;
  url?: Maybe<Scalars["URL"]["output"]>;
  width?: Maybe<ProductImageWidth>;
};

export type ProductImageFitment = "CONTAIN" | "COVER";

export type ProductImageGallery = {
  __typename?: "ProductImageGallery";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  categorizedImages: Array<ProductCategorizedImages>;
  categoryIdAll: Scalars["String"]["output"];
  featured?: Maybe<ProductImageInfo>;
  galleryButtons?: Maybe<Array<EgdsOverlayButton>>;
  mediaGalleryDialog?: Maybe<ProductGalleryDialog>;
  productIdentifierId?: Maybe<Scalars["String"]["output"]>;
  thumbnailGalleryDialog?: Maybe<ProductGalleryDialog>;
};

export type ProductImageInfo = {
  __typename?: "ProductImageInfo";
  actionAccessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ImageAnalytics>;
  description?: Maybe<Scalars["String"]["output"]>;
  image: ProductImage;
  lowResImage?: Maybe<ProductImage>;
  thumbnail?: Maybe<ProductImage>;
};

export type ProductImageWidth = "FULL_WIDTH" | "HALF_WIDTH" | "QUARTER_WIDTH" | "THIRD_WIDTH";

export interface ProductInfo {
  type?: Maybe<ProductType>;
}

export type ProductLine =
  | "ACTIVITY"
  | "CAR"
  | "CRUISE"
  | "FLIGHT"
  | "GROUND_TRANSFER"
  | "INSURANCE"
  | "LODGING"
  | "PACKAGE"
  | "PRODUCT_LINE_INVALID"
  | "RAIL"
  | "UNRECOGNIZED";

export type ProductListComponent = {
  __typename?: "ProductListComponent";
  cards: Array<ActivityCard>;
  heading?: Maybe<Scalars["String"]["output"]>;
  loadMoreAction?: Maybe<ActivityClickAction>;
  loadMoreButton?: Maybe<EgdsButton>;
};

export type ProductListContentEventAttributes = {
  __typename?: "ProductListContentEventAttributes";
  productId: Scalars["String"]["output"];
  productLine: Scalars["String"]["output"];
};

export type ProductLocation = {
  __typename?: "ProductLocation";
  address?: Maybe<ProductLocationAddress>;
  contents?: Maybe<Array<ProductLocationContentContainer>>;
  header?: Maybe<ShoppingProductHeader>;
  mapSection?: Maybe<ProductLocationStaticMap>;
  placesSection?: Maybe<ProductLocationPlacesSection>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type ProductLocationAddress = {
  __typename?: "ProductLocationAddress";
  address?: Maybe<Array<Scalars["String"]["output"]>>;
  coordinates?: Maybe<ProductLocationCoordinates>;
  seoData?: Maybe<Array<SeoStructuredData>>;
};

export type ProductLocationContent =
  | ProductLocationLandmarksContent
  | ProductLocationMapContent
  | ProductLocationPlacesDialogContent;

export type ProductLocationContentContainer = {
  __typename?: "ProductLocationContentContainer";
  content: ProductLocationContent;
  contentId: Scalars["String"]["output"];
};

export type ProductLocationCoordinates = {
  __typename?: "ProductLocationCoordinates";
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

export type ProductLocationLandmarksContent = {
  __typename?: "ProductLocationLandmarksContent";
  editorial?: Maybe<Array<Scalars["String"]["output"]>>;
  galleryCarousel?: Maybe<EgdsCarousel>;
  header?: Maybe<ShoppingProductHeader>;
  landmarks?: Maybe<Array<ShoppingProductContent>>;
};

export type ProductLocationMapAction = ShoppingButton | ShoppingLink;

export type ProductLocationMapContent = {
  __typename?: "ProductLocationMapContent";
  actions?: Maybe<Array<ProductLocationMapAction>>;
  egdsBasicMap: EgdsBasicMap;
  mapInfoCards: Array<EgdsImageCard>;
};

export type ProductLocationPlacesDialogContent = {
  __typename?: "ProductLocationPlacesDialogContent";
  placesDialogEGDSTextsContentSection?: Maybe<PlacesDialogEgdsTextsContentSection>;
  placesDialogTextInputFieldContentSection?: Maybe<PlacesDialogTextInputFieldContentSection>;
  placesDialogTypeAheadContentSection?: Maybe<PlacesDialogTypeAheadContentSection>;
  placesDialogUITogglesContentSection?: Maybe<PlacesDialogUiTogglesContentSection>;
};

export type ProductLocationPlacesSection = {
  __typename?: "ProductLocationPlacesSection";
  action?: Maybe<ShoppingLink>;
  items?: Maybe<ShoppingProductContent>;
};

export type ProductLocationStaticMap = {
  __typename?: "ProductLocationStaticMap";
  actions?: Maybe<Array<ProductLocationStaticMapAction>>;
};

export type ProductLocationStaticMapAction = ShoppingButton | ShoppingImage | ShoppingLink;

export type ProductNaturalKey =
  | ActivityNaturalKey
  | CarNaturalKey
  | FlightNaturalKey
  | GroundTransfersNaturalKey
  | PackageNaturalKey
  | PropertyNaturalKey;

export type ProductNavigationBar = {
  __typename?: "ProductNavigationBar";
  tabs: Array<ProductNavigationBarTab>;
};

export type ProductNavigationBarTab = {
  __typename?: "ProductNavigationBarTab";
  analytics?: Maybe<ClientSideAnalytics>;
  sectionName: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type ProductPoliciesSection = ProductDetailsElement & {
  __typename?: "ProductPoliciesSection";
  details?: Maybe<ProductTextInfoSection>;
  elementID: Scalars["String"]["output"];
  policyDescription?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated to support icon and tooltip too, replace with details */
  policyDetails: PolicyDetailsSection;
};

export type ProductPolicy = {
  __typename?: "ProductPolicy";
  policyContentSection: PolicyContentSection;
  policyHeaderSection: PolicyHeaderSection;
};

export type ProductPrimaryFloatingActionButton = {
  __typename?: "ProductPrimaryFloatingActionButton";
  button: UiPrimaryFloatingActionButton;
  clickAnalytics?: Maybe<EgClickstreamEventWithContext>;
  impressionAnalytics?: Maybe<EgClickstreamEventWithContext>;
};

export interface ProductQuestionFiltersInput {
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}

export type ProductQuestionTopic = "HOTEL";

export type ProductQuestions = {
  __typename?: "ProductQuestions";
  questions: Array<UserQuestion>;
  totalCount?: Maybe<FormattedNumber>;
};

export type ProductRating = {
  __typename?: "ProductRating";
  impressionAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  info?: Maybe<ProductTextInfoSection>;
  metadata: ProductRatingMetadata;
  sectionHeadingAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  summary: ProductRatingSummary;
};

export type ProductRatingLink = {
  __typename?: "ProductRatingLink";
  action: ProductRatingShowTextAction;
  link: EgdsStandardLink;
};

export type ProductRatingMetadata = {
  __typename?: "ProductRatingMetadata";
  seoData: Array<SeoStructuredData>;
};

export type ProductRatingShowTextAction = {
  __typename?: "ProductRatingShowTextAction";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProductRatingSummary = {
  __typename?: "ProductRatingSummary";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  supportingMessages: Array<ProductRatingSupportingMessage>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type ProductRatingSupportingMessage = EgdsPlainText | ProductRatingLink;

export type ProductResultsSummaryContent = PriceDisplayChangeOnboardingContent | ProductResultsSummaryTextContent;

export type ProductResultsSummaryContentContainer = {
  __typename?: "ProductResultsSummaryContentContainer";
  content: ProductResultsSummaryContent;
  contentId: Scalars["String"]["output"];
};

export type ProductResultsSummaryTextContent = {
  __typename?: "ProductResultsSummaryTextContent";
  textList?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProductReviewDetails = {
  __typename?: "ProductReviewDetails";
  disclaimer?: Maybe<ProductTextInfoSection>;
  progressBarGroup?: Maybe<EgdsProgressBarGroup>;
  summary?: Maybe<ProductRatingSummary>;
};

export type ProductScrollToButton = {
  __typename?: "ProductScrollToButton";
  button: ScrollToButton;
  targetRef: Scalars["String"]["output"];
};

export interface ProductShoppingCriteriaInput {
  primarySearchCriteria: PrimaryProductShoppingCriteriaInput;
  secondaryCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type ProductSummary = EgdsInlineLink | EgdsPlainText;

export interface ProductSummaryInput {
  id: Scalars["String"]["input"];
  idType: IdentifierType;
  selections?: InputMaybe<Array<SelectedValueInput>>;
}

export type ProductSupplierSection = ProductDetailsElement & {
  __typename?: "ProductSupplierSection";
  description?: Maybe<Scalars["String"]["output"]>;
  elementID: Scalars["String"]["output"];
  logo: Image;
};

export type ProductSupportingMessage =
  | EgdsGraphicText
  | EgdsInlineLink
  | EgdsParagraph
  | EgdsPlainText
  | EgdsStandardBadge
  | LoyaltyDialogWithTrigger;

export type ProductTextInfoSection = {
  __typename?: "ProductTextInfoSection";
  accessibilityText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  message?: Maybe<PopUpMessage>;
  /** @deprecated Deprecated for a more generic type, replace with accessibilityText */
  policyA11yText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated for a shared type, replace with theme */
  policyTheme: Scalars["String"]["output"];
  theme: IconTheme;
  title: EgdsStylizedText;
  /** @deprecated Deprecated for a more generic type, replace with message */
  tooltipContent?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Deprecated for a more generic type, replace with icon */
  tooltipIcon?: Maybe<Icon>;
};

export type ProductTravelScheduleSection = ProductDetailsElement & {
  __typename?: "ProductTravelScheduleSection";
  bulletins?: Maybe<Array<ProductTextInfoSection>>;
  elementID: Scalars["String"]["output"];
  label: EgdsStylizedText;
  /** @deprecated Deprecated to support icon and tooltip too, replace with bulletins */
  messages: Array<EgdsStylizedText>;
};

export type ProductType =
  | "ARTICLE"
  | "CAR_DETAILS"
  | "DESTINATION"
  | "DESTINATION_EXPERIENCE"
  | "EXPERIENCE"
  | "EXPERT_TIP"
  | "FLIGHT_SEARCH"
  | "LX_DETAILS"
  | "NEIGHBORHOOD"
  | "POI_GAIA"
  | "PROPERTY_DETAILS";

export type ProductUniqueness = {
  __typename?: "ProductUniqueness";
  details?: Maybe<EgdsSpannableList>;
  impression: ClientSideImpressionEventAnalytics;
  title?: Maybe<EgdsGraphicText>;
};

export type ProductsPlayback = BookingServicingOfferCard;

export type Profile = {
  __typename?: "Profile";
  emailAddress: Scalars["String"]["output"];
  expUserId: Scalars["Int"]["output"];
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
  middleName: Scalars["String"]["output"];
  tuid: Scalars["Int"]["output"];
};

export type ProfileAccountFormBottomSheetComponent = {
  __typename?: "ProfileAccountFormBottomSheetComponent";
  bottomSheetElements: Array<ProfileBottomSheetElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ProfileAccountFormCenteredElement = UiEmptyState;

export type ProfileAccountFormCenteredVerticalComponent = {
  __typename?: "ProfileAccountFormCenteredVerticalComponent";
  centeredElements: Array<ProfileAccountFormCenteredElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ProfileAccountFormComponent =
  | ProfileAccountFormBottomSheetComponent
  | ProfileAccountFormCenteredVerticalComponent
  | ProfileAccountFormVerticalComponent
  | ProfileActionDialogComponent
  | ProfilePopUiToRoot
  | ProfilePresentUi
  | ProfilePushUi;

export type ProfileAccountFormContent =
  | ProfileAccountFormBottomSheetComponent
  | ProfileAccountFormCenteredVerticalComponent
  | ProfileAccountFormVerticalComponent
  | ProfileActionDialogComponent;

export type ProfileAccountFormElement =
  | EgdsBasicSelect
  | EgdsCheckBoxGroup
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsParagraph
  | EgdsTypeaheadInputField
  | ProfileAccountFormSectionContainer
  | ProfileEgdsSectionContainer
  | ProfileFormSection
  | ProfileSlimCard
  | UiEmptyState
  | UiMessagingCard
  | UiPrimaryButton;

export type ProfileAccountFormSectionContainer = {
  __typename?: "ProfileAccountFormSectionContainer";
  heading?: Maybe<Scalars["String"]["output"]>;
  sectionContainerElements: Array<ProfileAccountFormSectionElement>;
};

export type ProfileAccountFormSectionElement = Illustration | UiPrimaryButton | UiTertiaryButton;

export type ProfileAccountFormSubmitAction = ProfileAction &
  UiAction & {
    __typename?: "ProfileAccountFormSubmitAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    actionContext: Scalars["String"]["output"];
    analytics: ClientSideAnalytics;
    inputIds: Array<Scalars["String"]["output"]>;
  };

export type ProfileAccountFormVerticalComponent = {
  __typename?: "ProfileAccountFormVerticalComponent";
  /** @deprecated Use anchoredButtons instead since that supports multiple buttons */
  anchoredButton?: Maybe<ProfileButton>;
  anchoredButtons?: Maybe<Array<ProfileButton>>;
  anchoredButtonsLayout?: Maybe<ProfileButtonLayout>;
  elements: Array<ProfileAccountFormElement>;
  /** @deprecated Use anchoredButton profile component instead */
  floatingButton?: Maybe<UiPrimaryButton>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  toast?: Maybe<EgdsToast>;
};

export type ProfileAccountLink = EgdsElement & {
  __typename?: "ProfileAccountLink";
  action: ProfileAction;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  size: EgdsLinkSize;
  text: Scalars["String"]["output"];
};

export interface ProfileAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
}

export type ProfileActionButtonCard = EgdsElement & {
  __typename?: "ProfileActionButtonCard";
  cardAction?: Maybe<ProfileAction>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  impressionTracking?: Maybe<ClientSideImpressionEventAnalytics>;
  leftIcon?: Maybe<Icon>;
  primaries?: Maybe<Array<EgdsStylizedText>>;
  rightButton?: Maybe<ProfileButton>;
  secondaries?: Maybe<Array<EgdsStylizedText>>;
  tertiaries?: Maybe<Array<EgdsStylizedText>>;
};

export type ProfileActionDialogComponent = {
  __typename?: "ProfileActionDialogComponent";
  actionButtonLayout: ProfileButtonLayout;
  actionButtons: Array<ProfileButton>;
  content?: Maybe<Array<EgdsText>>;
  disableBackground: Scalars["Boolean"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ProfileAddMembershipAction = UiAction & {
  __typename?: "ProfileAddMembershipAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileAddPassportAction = UiAction & {
  __typename?: "ProfileAddPassportAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileBottomSheetElement =
  | EgdsBasicSelect
  | EgdsErrorSummary
  | EgdsHeading
  | EgdsTextInputField
  | UiPrimaryButton
  | UiSecondaryButton;

export type ProfileBulletedList = EgdsElement & {
  __typename?: "ProfileBulletedList";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  listItems: Array<EgdsText>;
};

export type ProfileButton = {
  __typename?: "ProfileButton";
  action: ProfileAction;
  button: EgdsButton;
};

export type ProfileButtonLayout = "INLINE" | "STACKED";

export type ProfileCarouselContainer = ProfileContainer & {
  __typename?: "ProfileCarouselContainer";
  elements: Array<ProfileComposableElement>;
  scrollToSelectedItem: Scalars["Int"]["output"];
};

export type ProfileComponent = {
  __typename?: "ProfileComponent";
  elements: Array<EgdsElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  toast?: Maybe<EgdsToast>;
};

export type ProfileComposableElement =
  | ProfileCarouselContainer
  | ProfileContentCard
  | ProfileFlexContainer
  | ProfileFullBleedImageCard
  | ProfileSectionContainer
  | ProfileSlimCard
  | ProfileUiPill;

export interface ProfileContainer {
  elements: Array<ProfileComposableElement>;
}

export interface ProfileContent {
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
}

export type ProfileContentCard = {
  __typename?: "ProfileContentCard";
  contentPrimary?: Maybe<Scalars["String"]["output"]>;
  rows: Array<ProfileContent>;
};

export type ProfileContentItem = ProfileContentTextItem;

export type ProfileContentParagraph = ProfileContent & {
  __typename?: "ProfileContentParagraph";
  items?: Maybe<Array<ProfileContentItem>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ProfileContentTextItem = {
  __typename?: "ProfileContentTextItem";
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type ProfileDismissAction = ProfileAction & {
  __typename?: "ProfileDismissAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileEgdsSectionContainer = EgdsElement &
  EgdsElements & {
    __typename?: "ProfileEGDSSectionContainer";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    elements: Array<EgdsElement>;
    heading?: Maybe<Scalars["String"]["output"]>;
    theme?: Maybe<ContainerTheme>;
  };

export type ProfileExitFlowAction = ProfileAction & {
  __typename?: "ProfileExitFlowAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileExitFlowAndSignOutAction = ProfileAction & {
  __typename?: "ProfileExitFlowAndSignOutAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileFlexContainer = {
  __typename?: "ProfileFlexContainer";
  elements: Array<ProfileComposableElement>;
};

export type ProfileFormHorizontalContainer = EgdsElement & {
  __typename?: "ProfileFormHorizontalContainer";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  elements: Array<EgdsElement>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
};

export type ProfileFormSection = EgdsElement & {
  __typename?: "ProfileFormSection";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  elements: Array<EgdsElement>;
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type ProfileFormSubmitAction = UiAction & {
  __typename?: "ProfileFormSubmitAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  inputIds: Array<Scalars["String"]["output"]>;
};

export type ProfileFullBleedImageCard = {
  __typename?: "ProfileFullBleedImageCard";
  action?: Maybe<UiAction>;
  background?: Maybe<Image>;
  fullBleedPrimary?: Maybe<Scalars["String"]["output"]>;
  icons?: Maybe<Array<Icon>>;
  impressionTracking?: Maybe<ClientSideImpressionEventAnalytics>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ProfileLink = EgdsElement & {
  __typename?: "ProfileLink";
  action: UiAction;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  size: EgdsLinkSize;
  text: Scalars["String"]["output"];
};

export type ProfileLinkAction = UiAction & {
  __typename?: "ProfileLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: ProfileLinkTarget;
};

export type ProfileLinkTarget = "EXTERNAL" | "INTERNAL";

export type ProfileNumberField = EgdsElement &
  EgdsInputField & {
    __typename?: "ProfileNumberField";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    hasError: Scalars["Boolean"]["output"];
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required: Scalars["Boolean"]["output"];
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type ProfilePassportFormAction = ProfileAction & {
  __typename?: "ProfilePassportFormAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type ProfilePhoneNumberField = EgdsElement &
  EgdsInputField & {
    __typename?: "ProfilePhoneNumberField";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    hasError: Scalars["Boolean"]["output"];
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required: Scalars["Boolean"]["output"];
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type ProfilePopUiToRoot = {
  __typename?: "ProfilePopUIToRoot";
  content: ProfileAccountFormContent;
};

export type ProfilePresentUi = {
  __typename?: "ProfilePresentUI";
  content: ProfileAccountFormContent;
};

export type ProfilePushUi = {
  __typename?: "ProfilePushUI";
  content: ProfileAccountFormContent;
  title: Scalars["String"]["output"];
};

export type ProfileRadioButton = EgdsElement &
  EgdsRadioButton & {
    __typename?: "ProfileRadioButton";
    description?: Maybe<Scalars["String"]["output"]>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    label: Scalars["String"]["output"];
    labelSuffix?: Maybe<Scalars["String"]["output"]>;
    submitAnalytics?: Maybe<ClientSideAnalytics>;
    value: Scalars["String"]["output"];
  };

export type ProfileRadioButtonGroup = EgdsElement &
  EgdsRadioGroup & {
    __typename?: "ProfileRadioButtonGroup";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    groupName: Scalars["String"]["output"];
    label?: Maybe<Scalars["String"]["output"]>;
    options: Array<EgdsRadioButton>;
    selected?: Maybe<Scalars["String"]["output"]>;
  };

export type ProfileReloadAction = UiAction & {
  __typename?: "ProfileReloadAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileRewardProgramFormAction = UiAction & {
  __typename?: "ProfileRewardProgramFormAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type ProfileSectionContainer = ProfileContainer & {
  __typename?: "ProfileSectionContainer";
  elements: Array<ProfileComposableElement>;
  heading?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<ContainerTheme>;
};

export type ProfileSelectField = EgdsElement & {
  __typename?: "ProfileSelectField";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  hasError: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  options: Array<ProfileSwitchOption>;
};

export type ProfileSignOutAction = ProfileAction & {
  __typename?: "ProfileSignOutAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type ProfileSlimCard = EgdsElement & {
  __typename?: "ProfileSlimCard";
  /** @deprecated Use profileAction instead */
  action?: Maybe<UiAction>;
  alertMessages?: Maybe<Array<Scalars["String"]["output"]>>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  impressionTracking?: Maybe<ClientSideImpressionEventAnalytics>;
  profileAction?: Maybe<ProfileAction>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  slimCardPrimary?: Maybe<Scalars["String"]["output"]>;
  tertiaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ProfileSwitchOption = EgdsElement & {
  __typename?: "ProfileSwitchOption";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export type ProfileTextField = EgdsElement &
  EgdsInputField & {
    __typename?: "ProfileTextField";
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required: Scalars["Boolean"]["output"];
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type ProfileUiPill = UiPill & {
  __typename?: "ProfileUIPill";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use profileAction instead */
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  profileAction?: Maybe<ProfileAction>;
  selected: Scalars["Boolean"]["output"];
};

export type ProfileUpdateMembershipAction = UiAction & {
  __typename?: "ProfileUpdateMembershipAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type ProfileUpdatePassportAction = UiAction & {
  __typename?: "ProfileUpdatePassportAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type ProfileWizardComponent = {
  __typename?: "ProfileWizardComponent";
  elements: Array<ProfileComposableElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export interface ProfileWizardContextInput {
  lineOfBusiness: LineOfBusinessDomain;
  pageLocation: PageLocation;
}

export type ProfileWizardResponse = ProfileWizardComponent | WizardAdditionalUserInputRequiredAction;

export type ProgressionBar = {
  __typename?: "ProgressionBar";
  accessibility: Scalars["String"]["output"];
  action: ProgressionBarAction;
  button: EgdsButton;
  deeplink?: Maybe<HttpUri>;
};

export type ProgressionBarAction = "PDP" | "REVIEWS" | "TRAVEL_GUIDE";

export type Property = Plottable & {
  __typename?: "Property";
  addressMessaging?: Maybe<Scalars["String"]["output"]>;
  amenities: Array<PropertyAmenityValue>;
  availability: PropertyAvailability;
  clickstreamEvents: LodgingCardClickstreamEvents;
  dataAttributes?: Maybe<GraphQlPair>;
  destinationInfo: PropertyDestinationInfo;
  /** @deprecated Look for errors in response.errors instead */
  errors?: Maybe<Array<Scalars["String"]["output"]>>;
  featuredCheckinDate?: Maybe<Scalars["String"]["output"]>;
  featuredCheckoutDate?: Maybe<Scalars["String"]["output"]>;
  featuredMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  /** @deprecated Deprecated, replace with propertyGallery */
  gallery?: Maybe<Array<Image>>;
  /** @deprecated Use highlightedPropertyDetails */
  highlightedPropertyDetail?: Maybe<Scalars["String"]["output"]>;
  highlightedPropertyDetails: Array<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  /** @deprecated replaced by propertyImage */
  image?: Maybe<Image>;
  imageGallery?: Maybe<PropertyImageGallery>;
  /** @deprecated Don't use this anymore., replace with propertyDetailsLink */
  infositeURL: Scalars["String"]["output"];
  /** @deprecated Moved to mapMarker.latLong */
  latLong?: Maybe<Coordinates>;
  legalDisclaimer?: Maybe<PropertyLegalDisclaimer>;
  listingFooter?: Maybe<MessagingAction>;
  /** @deprecated deprecated in favour of priceBreakDownSummary, replace with priceBreakDownSummary */
  lodgingPriceBreakDown?: Maybe<LodgingPriceBreakDown>;
  mapMarker?: Maybe<MapMarker>;
  name: Scalars["String"]["output"];
  neighborhood?: Maybe<Region>;
  offerBadge?: Maybe<OfferBadge>;
  offerSummary: OfferSummary;
  pinnedDetails?: Maybe<PinnedPropertyModel>;
  price?: Maybe<PropertyPrice>;
  priceAfterLoyaltyPointsApplied?: Maybe<PropertyPrice>;
  priceBreakDownSummary?: Maybe<PriceSummary>;
  priceMetadata: PropertyPriceMetadata;
  propertyDetailsLink?: Maybe<LodgingLink>;
  propertyFees?: Maybe<Array<Fees>>;
  /** @deprecated Use imageGallery */
  propertyGallery?: Maybe<Array<PropertyImage>>;
  propertyImage?: Maybe<PropertyImage>;
  propertyPaymentFlags: Array<PropertyPaymentFlags>;
  propertySource?: Maybe<PropertySource>;
  /** @deprecated Experiment stopped and field is no more supported */
  propertyTypeBadge?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["String"]["output"]>;
  ratePlanId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use featuredMessages */
  recentlyViewed?: Maybe<Icon>;
  regionId: Scalars["String"]["output"];
  reviews: PropertyReviewsSummary;
  roomTypeId?: Maybe<Scalars["String"]["output"]>;
  saveTripItem?: Maybe<TripsSaveItem>;
  /** @deprecated Deprecated in favor of saveTripItem */
  shortList?: Maybe<ShortList>;
  sponsoredListing?: Maybe<SponsoredListing>;
  star?: Maybe<Scalars["Float"]["output"]>;
  starRatingIcon: Scalars["String"]["output"];
  /** @deprecated Discontinued due to feature being dismissed after tnl 44162 */
  starRatingText?: Maybe<Scalars["String"]["output"]>;
  structureType: StructureType;
  supportingMessages?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Moved to image. This field does not return a thumbor image. */
  thumborImage?: Maybe<Image>;
  /** @deprecated Use vipMessaging */
  vip: Scalars["Boolean"]["output"];
  vipMessaging?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyActivitiesCrossSellAction = {
  __typename?: "PropertyActivitiesCrossSellAction";
  actionType: PropertyActivitiesCrossSellActionType;
  activityId?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type PropertyActivitiesCrossSellActionType = "DISMISS" | "EDIT" | "OPEN" | "REMOVE";

export type PropertyActivitiesCrossSellDetails = {
  __typename?: "PropertyActivitiesCrossSellDetails";
  accessibility: Scalars["String"]["output"];
  actions: Array<PropertyActivitiesCrossSellLinkButton>;
  activityId: Scalars["String"]["output"];
  description: EgdsPlainText;
  heading: LodgingHeader;
  liveAnnounceMessage: Scalars["String"]["output"];
  offerActivityDescription?: Maybe<EgdsSpannableText>;
  price?: Maybe<DisplayPrice>;
  showTracking: ClientSideAnalytics;
  sideSheet?: Maybe<PropertyActivitiesCrossSellSideSheet>;
};

export type PropertyActivitiesCrossSellLinkButton = {
  __typename?: "PropertyActivitiesCrossSellLinkButton";
  action: Array<PropertyActivitiesCrossSellAction>;
  button: LodgingPropertyActivitiesLinkButton;
};

export type PropertyActivitiesCrossSellSideSheet = {
  __typename?: "PropertyActivitiesCrossSellSideSheet";
  dismissButtonAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyAddress = {
  __typename?: "PropertyAddress";
  addressLine?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  firstAddressLine?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  province?: Maybe<Scalars["String"]["output"]>;
  secondAddressLine?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyAmenities = {
  __typename?: "PropertyAmenities";
  /** @deprecated Only used on Mobile app, moved into amenities field */
  allUnits?: Maybe<PropertyInfoSection>;
  /** @deprecated Property and Room amenities are deprecated. Make use of propertyAmenities and roomAmenities inside propertyContentSectionGroups */
  amenities: Array<PropertyInfoSection>;
  amenitiesAdaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  /** @deprecated The summary structure should be refactored, prevent use that structure on future implementation */
  amenitiesDialog?: Maybe<LodgingAmenitiesDialog>;
  /** @deprecated Only used on Mobile app, moved into amenities field */
  property?: Maybe<PropertyInfoSection>;
  propertyContentPreferences?: Maybe<PropertyContent>;
  relevantAmenities: Array<PropertyInfoSection>;
  /** @deprecated Use amenitiesAdaptExSuccessEvents */
  reserveAdaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  tabs?: Maybe<ShoppingTabs>;
  takeover?: Maybe<PropertyTakeoverAmenities>;
  /** @deprecated Use topAmenities */
  top: Array<PropertyInfoItem>;
  topAmenities?: Maybe<PropertyInfoContent>;
  /** @deprecated This list may send attempt events from wrong section */
  viewAmenitiesAdaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
};

export type PropertyAmenity = {
  __typename?: "PropertyAmenity";
  icon?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type PropertyAmenityCategory = {
  __typename?: "PropertyAmenityCategory";
  /** @deprecated Use type instead of code */
  code?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  type?: Maybe<PropertyAmenityCategoryType>;
};

export type PropertyAmenityCategoryType =
  | "ACCESSIBLE_ROLL_IN_SHOWER"
  | "AIR_CONDITIONING"
  | "ALL_INCLUSIVE"
  | "ARCADE"
  | "BABYSITTING"
  | "BAR"
  | "BOWLING"
  | "BREAKFAST_AVAILABLE"
  | "BUSINESS_SERVICES"
  | "CHILDREN_CLUB"
  | "DAILY_HOUSEKEEPING"
  | "EXTRA_BED"
  | "FITNESS_EQUIPMENT"
  | "FREE_AIRPORT_TRANSPORTATION"
  | "FREE_BREAKFAST"
  | "FREE_PARKING"
  | "FREE_WELCOME_DRINK"
  | "FRONT_DESK_24_HOURS"
  | "GROCERY"
  | "HIGH_SPEED_INTERNET"
  | "HOT_TUB"
  | "IN_ROOM_INTERNET"
  | "KIDS_POOL"
  | "KITCHEN"
  | "LAUNDRY"
  | "LIVING_AREA"
  | "MICROWAVE"
  | "MINI_GOLF"
  | "PARKING"
  | "PETS_ALLOWED"
  | "PLAYGROUND"
  | "POOL"
  | "RESTAURANT_IN_HOTEL"
  | "ROOM_SERVICE"
  | "SMOKE_FREE"
  | "SOUNDPROOF_ROOM"
  | "SPA_SERVICES_ON_SITE"
  | "SPECIAL_DEAL"
  | "TENNIS_COURT"
  | "TOYS"
  | "WATERSLIDE";

export type PropertyAmenitySection = {
  __typename?: "PropertyAmenitySection";
  amenities: Array<PropertyAmenity>;
  description: Array<Scalars["String"]["output"]>;
  subType?: Maybe<PropertyAmenityType>;
  title: Scalars["String"]["output"];
  type: PropertyAmenityType;
};

export type PropertyAmenityType =
  | "ACCESSIBILITY"
  | "BATHROOM"
  | "BEDROOM"
  | "BUSINESS_SERVICES"
  | "CONVENIENCES"
  | "ENTERTAINMENT"
  | "FAMILY_FRIENDLY"
  | "FOOD_DRINK"
  | "GUEST_SERVICES"
  | "INTERNET"
  | "MORE"
  | "OUTDOOR"
  | "OUTDOOR_SPACE"
  | "PARKING"
  | "PROPERTY"
  | "ROOM"
  | "SPA"
  | "THINGS_TO_DO";

export type PropertyAmenityValue = {
  __typename?: "PropertyAmenityValue";
  icon: Scalars["String"]["output"];
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type PropertyAvailability = {
  __typename?: "PropertyAvailability";
  available: Scalars["Boolean"]["output"];
  minRoomsLeft?: Maybe<Scalars["Int"]["output"]>;
  scarcityMessage?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Theme>;
  unavailableReasons: Array<PropertyUnavailableValue>;
};

export type PropertyCalendar = {
  __typename?: "PropertyCalendar";
  disclaimer: LodgingPlainMessage;
  legend?: Maybe<Array<PropertyCalendarLegend>>;
  propertyCalendarRates: Array<PropertyCalendarRate>;
};

export interface PropertyCalendarLegend {
  label: Scalars["String"]["output"];
}

export type PropertyCalendarPriceTrend = "NEGATIVE" | "POSITIVE";

export type PropertyCalendarPriceTrendLegend = PropertyCalendarLegend & {
  __typename?: "PropertyCalendarPriceTrendLegend";
  label: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
  trend: PropertyCalendarPriceTrend;
};

export type PropertyCalendarRate = {
  __typename?: "PropertyCalendarRate";
  accessibilityLabel: Scalars["String"]["output"];
  date: Date;
  displayPrice: Scalars["String"]["output"];
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favour of displayPrice */
  price?: Maybe<Money>;
  theme?: Maybe<LodgingMessageTheme>;
};

export type PropertyCancellationPolicy = {
  __typename?: "PropertyCancellationPolicy";
  /** @deprecated Use cancellationWindow since the date time can be nullable */
  cancellationDateTime: ZonedDateTime;
  cancellationWindow?: Maybe<ZonedDateTime>;
  text: Scalars["String"]["output"];
  type: PropertyCancellationPolicyType;
};

export type PropertyCancellationPolicyType =
  | "NON_REFUNDABLE"
  | "REFUNDABLE_WITH_NO_PENALTY"
  | "REFUNDABLE_WITH_PENALTY";

export type PropertyCartItem = CartItem & {
  __typename?: "PropertyCartItem";
  /** @deprecated DO NOT USE: This is required for the federation migration. Clients should only ever pass the context as input */
  deprecatedContext: DeprecatedMultiItemContext;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertyImageGallery */
  gallery?: Maybe<PropertyGallery>;
  id: Scalars["UUID"]["output"];
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  offers: Array<PropertyOffer>;
  /** @deprecated Replaced by the type PropertyImageGallery as part of BFF to BEX-API migration, replace with propertyImageGallery */
  propertyGallery?: Maybe<PropertyGallery>;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  propertyId: Scalars["String"]["output"];
  propertyImageGallery?: Maybe<PropertyImageGallery>;
  propertySummary?: Maybe<PropertySummary>;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  searchContext: CartPropertySearchContext;
  /** @deprecated Added for stitching migration purposes only. Can be deleted once all clients migrate., replace with propertySummary */
  summary?: Maybe<PropertySummary>;
};

export type PropertyCartItemPropertyGalleryArgs = {
  context: ContextInput;
};

export type PropertyCartItemPropertyImageGalleryArgs = {
  context: ContextInput;
};

export type PropertyCartItemPropertySummaryArgs = {
  context: ContextInput;
};

export type PropertyContent = {
  __typename?: "PropertyContent";
  /** @deprecated replaced by expandoPeek */
  expando?: Maybe<LodgingExpando>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<LodgingHeader>;
  items: Array<PropertyContentItem>;
  leadingGraphic?: Maybe<LodgingLeadingGraphic>;
  maxColumns?: Maybe<Scalars["Int"]["output"]>;
  propertyContentRef?: Maybe<Scalars["String"]["output"]>;
  structuredData?: Maybe<SeoStructuredData>;
};

export type PropertyContentElement = EgdsStandardMessagingCard | PropertyContent;

export type PropertyContentElementGroup = {
  __typename?: "PropertyContentElementGroup";
  elements: Array<PropertyContentElement>;
  maxColumns: Scalars["Int"]["output"];
};

export type PropertyContentItem =
  | LodgingLinkMessage
  | PropertyContentItemGraphics
  | PropertyContentItemImages
  | PropertyContentItemMarkup
  | PropertyContentItemText
  | PropertyContentItemTexts;

export type PropertyContentItemGraphics = {
  __typename?: "PropertyContentItemGraphics";
  /** @deprecated Use graphicsV2, replace with graphicsV2 */
  graphics: Array<UiGraphic>;
  graphicsV2: Array<LodgingGraphic>;
};

export type PropertyContentItemImages = {
  __typename?: "PropertyContentItemImages";
  contents: Array<PropertyImage>;
};

export type PropertyContentItemMarkup = {
  __typename?: "PropertyContentItemMarkup";
  content: MarkupText;
};

export type PropertyContentItemText = {
  __typename?: "PropertyContentItemText";
  content: PropertyContentText;
  expando?: Maybe<EgdsExpandoPeek>;
};

export type PropertyContentItemTexts = {
  __typename?: "PropertyContentItemTexts";
  contents: Array<PropertyContentText>;
};

export type PropertyContentSection = {
  __typename?: "PropertyContentSection";
  action?: Maybe<PropertyContentSectionAction>;
  /** @deprecated Do not use; please follow same pattern as PropertyContentSectionGroup., replace with sectionName */
  anchor?: Maybe<Scalars["String"]["output"]>;
  bodySubSections: Array<PropertyContentSubSection>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated deprecated in favour of expando, replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<LodgingHeader>;
  headingContentElements: Array<PropertyContentElement>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sectionId?: Maybe<Scalars["String"]["output"]>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyContentSectionAction =
  | LodgingLinkMessage
  | PropertyContentSectionDetailedDialog
  | PropertyContentSectionDialog
  | PropertyContentSectionListTypeDialog;

export type PropertyContentSectionDetailedDialog = {
  __typename?: "PropertyContentSectionDetailedDialog";
  dialog: EgdsDialog;
  trigger: PropertyContentSectionDialogTrigger;
};

export type PropertyContentSectionDialog = {
  __typename?: "PropertyContentSectionDialog";
  content: EgdsSpannableText;
  dialog: EgdsDialog;
  trigger: PropertyContentSectionDialogTrigger;
};

export type PropertyContentSectionDialogTrigger = {
  __typename?: "PropertyContentSectionDialogTrigger";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
};

export type PropertyContentSectionGroup = {
  __typename?: "PropertyContentSectionGroup";
  action?: Maybe<PropertyContentSectionAction>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated deprecated in favour of expando, replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sectionId?: Maybe<Scalars["String"]["output"]>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
  sections: Array<PropertyContentSection>;
  themeProvider?: Maybe<EgdsThemeProvider>;
};

export type PropertyContentSectionGroups = {
  __typename?: "PropertyContentSectionGroups";
  aboutTheLocation?: Maybe<PropertyContentSectionGroup>;
  aboutThisHost?: Maybe<PropertyContentSectionGroup>;
  aboutThisProperty?: Maybe<PropertyContentSectionGroup>;
  amenities?: Maybe<PropertyContentSectionGroup>;
  /** @deprecated cleanliness is deprecated. This content should be part of aboutThisProperty sectionGroup */
  cleanliness?: Maybe<PropertyContentSectionGroup>;
  closures?: Maybe<PropertyContentSectionGroup>;
  feesInformation?: Maybe<PropertyContentSectionGroup>;
  importantInfo?: Maybe<PropertyContentSectionGroup>;
  policies?: Maybe<PropertyContentSectionGroup>;
  specialFeatures?: Maybe<PropertyContentSectionGroup>;
};

export type PropertyContentSectionListTypeDialog = {
  __typename?: "PropertyContentSectionListTypeDialog";
  content: Array<PropertyInfoContent>;
  dialog: EgdsDialog;
  trigger: PropertyContentSectionDialogTrigger;
};

export type PropertyContentSectionRef =
  | "AVAILABILITY_CALENDAR_START_DATE"
  | "PRICE_DISPLAY_SERVICE_RESPONSE"
  | "RECOMMENDED_OFFER"
  | "ROOMS_AND_RATES";

export type PropertyContentSubSection = {
  __typename?: "PropertyContentSubSection";
  actions?: Maybe<Array<PropertyContentSectionAction>>;
  /** @deprecated Use elementsV2., replace with elementsV2 */
  contents: Array<PropertyContent>;
  elements: Array<PropertyContentElement>;
  elementsV2: Array<PropertyContentElementGroup>;
  expando?: Maybe<LodgingExpando>;
  header?: Maybe<LodgingHeader>;
  maxColumns: Scalars["Int"]["output"];
};

export type PropertyContentText = {
  __typename?: "PropertyContentText";
  primary: LodgingEnrichedMessage;
  secondary?: Maybe<LodgingPlainMessage>;
};

export type PropertyContentType = "DETAILED" | "SUMMARY";

export interface PropertyContext {
  propertyId: Scalars["String"]["input"];
}

export interface PropertyContextInput {
  propertyId: Scalars["String"]["input"];
}

export type PropertyDateRange = {
  __typename?: "PropertyDateRange";
  checkInDate: Date;
  checkOutDate: Date;
};

export interface PropertyDateRangeInput {
  checkInDate: DateInput;
  checkOutDate: DateInput;
}

export type PropertyDestinationInfo = {
  __typename?: "PropertyDestinationInfo";
  distanceFromDestination?: Maybe<Distance>;
  distanceFromMessaging?: Maybe<Scalars["String"]["output"]>;
  includedFromSupplementalSearch: Scalars["Boolean"]["output"];
  latLong?: Maybe<Coordinates>;
  regionId: Scalars["String"]["output"];
};

export interface PropertyDetailPlottable {
  mapMarker: MapMarker;
}

export type PropertyDetails = {
  __typename?: "PropertyDetails";
  contentSections: Array<PropertyInfoContent>;
  description?: Maybe<EgdsParagraph>;
  gallery?: Maybe<PropertyImageGallery>;
  heading: EgdsHeading;
  rating?: Maybe<EgdsRating>;
  reviewSummary?: Maybe<PropertyReviewSummary>;
};

export type PropertyDetailsExperimentalSection = LodgingExperimentalSection & {
  __typename?: "PropertyDetailsExperimentalSection";
  features: Array<LodgingExperimentalMetaData>;
  pageLayoutExperimentalFeature: PageLayoutExperimentalFeature;
};

export type PropertyDetailsExperimentalSectionFeaturesArgs = {
  createdAfter?: InputMaybe<DateInput>;
  createdBefore?: InputMaybe<DateInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  org?: InputMaybe<Scalars["String"]["input"]>;
  team?: InputMaybe<Scalars["String"]["input"]>;
};

export type PropertyDetailsMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PropertyDetailsMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export interface PropertyDetailsMultiItemShoppingActionInput {
  shoppingContext: ShoppingContextInput;
}

export type PropertyDetailsPropertyType = "HOTEL" | "VACATION_RENTAL";

export type PropertyDetailsStickyBar = {
  __typename?: "PropertyDetailsStickyBar";
  /** @deprecated Use propertyPrice instead */
  displayPrice?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use propertyPrice instead */
  price?: Maybe<PropertyPriceOption>;
  pricePresentation?: Maybe<PricePresentation>;
  pricePresentationDialog?: Maybe<PricePresentationDialog>;
  propertyPrice?: Maybe<PropertyPrice>;
  /** @deprecated Use propertyPrice instead */
  qualifier?: Maybe<Scalars["String"]["output"]>;
  stickyButton?: Maybe<LodgingStickyButton>;
  structuredData?: Maybe<PropertySeoStructuredData>;
  /** @deprecated Use propertyPrice instead */
  subText?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyEditorial = {
  __typename?: "PropertyEditorial";
  content: Array<Scalars["String"]["output"]>;
  /** @deprecated Use crossLinks, replace with crossLinks */
  crossLink?: Maybe<LodgingLinkMessage>;
  crossLinks?: Maybe<Array<LodgingLinkMessage>>;
  title: Scalars["String"]["output"];
};

export type PropertyFaq = {
  __typename?: "PropertyFAQ";
  answer: PropertyFaqContent;
  question: PropertyFaqContent;
};

export type PropertyFaqContent = {
  __typename?: "PropertyFAQContent";
  content: MarkupText;
  /** @deprecated Will be remove in favor of content property which it can contains markup */
  text: Scalars["String"]["output"];
  tracking?: Maybe<ClientSideAnalytics>;
};

export type PropertyFaQs = {
  __typename?: "PropertyFAQs";
  /** @deprecated Property FAQs action. No longer in use */
  action?: Maybe<Array<SpannableTextDialog>>;
  /** @deprecated Describes the ask a question button. No longer in use */
  askAQuestion?: Maybe<UiSecondaryButton>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  faqs: Array<PropertyFaq>;
  title: LodgingHeader;
};

export type PropertyFees = {
  __typename?: "PropertyFees";
  mandatory?: Maybe<PropertyInfoSubSection>;
  optional?: Maybe<PropertyInfoSubSection>;
};

export type PropertyFilterAccessibility =
  | "ACCESSIBLE_BATHROOM"
  | "ACCESSIBLE_PARKING"
  | "ELEVATOR"
  | "IN_ROOM_ACCESSIBLE"
  | "ROLL_IN_SHOWER"
  | "SERVICE_ANIMAL"
  | "SIGN_LANGUAGE_INTERPRETER"
  | "STAIR_FREE_PATH";

export type PropertyFilterAmenity =
  | "AIR_CONDITIONING"
  | "ALL_INCLUSIVE"
  | "BALCONY_OR_TERRACE"
  | "BAR"
  | "BEACH"
  | "BEACH_FRONT"
  | "BED_LINENS"
  | "CASINO"
  | "CRIB"
  | "DISHWASHER"
  | "DRYER"
  | "ELECTRIC_CAR"
  | "FIREPLACE"
  | "FREE_AIRPORT_TRANSPORTATION"
  | "FREE_BREAKFAST"
  | "FREE_PARKING"
  | "GOLF"
  | "GOLF_COURSE"
  | "GYM"
  | "HOT_SPRING"
  | "HOT_TUB"
  | "KITCHEN_KITCHEN"
  | "KITCHEN_KITCHENETTE"
  | "OCEAN_VIEW"
  | "PARKING"
  | "PETS"
  | "POOL"
  | "RESTAURANT_IN_HOTEL"
  | "SPA_ON_SITE"
  | "TELEVISION"
  | "WASHER"
  | "WASHER_DRYER"
  | "WATER_PARK"
  | "WIFI";

export type PropertyFilterMetadata = {
  __typename?: "PropertyFilterMetadata";
  amenities: Array<PropertyAmenityValue>;
  neighborhoods: Array<Neighborhood>;
  priceRange: PriceRange;
  structureTypes: Array<StructureTypeValue>;
};

export type PropertyFilteredReviewsDialog = {
  __typename?: "PropertyFilteredReviewsDialog";
  ratingPercentage: EgdsFullScreenDialog;
  trigger: LodgingDialogTriggerMessage;
};

export type PropertyGallery = {
  __typename?: "PropertyGallery";
  featured?: Maybe<Image>;
  images: Array<Image>;
};

export type PropertyGalleryCategory = {
  __typename?: "PropertyGalleryCategory";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  images: Array<PropertyImage>;
  name: Scalars["String"]["output"];
};

export type PropertyHeading = EgdsSectionHeading & {
  __typename?: "PropertyHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyHighlightSection = AccessibilityField & {
  __typename?: "PropertyHighlightSection";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  footerLink?: Maybe<LodgingLinkMessage>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  subSections: Array<PropertyHighlightSubSection>;
};

export type PropertyHighlightSubSection = AccessibilityField & {
  __typename?: "PropertyHighlightSubSection";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  contents: Array<LodgingEnrichedMessage>;
  description?: Maybe<Scalars["String"]["output"]>;
  footerLink?: Maybe<LodgingLinkMessage>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<LodgingEnrichedMessage>;
};

export type PropertyImage = {
  __typename?: "PropertyImage";
  actionAccessibility?: Maybe<Scalars["String"]["output"]>;
  alt?: Maybe<Scalars["String"]["output"]>;
  fallbackImage?: Maybe<Image>;
  image: Image;
  imageId?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  lowResImage?: Maybe<Image>;
  onClickAction?: Maybe<PropertyImageAction>;
  previewImage?: Maybe<Image>;
  subjectId?: Maybe<Scalars["Int"]["output"]>;
  thumbnail?: Maybe<Image>;
  trackingId?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyImageAction = PropertyImageDetailDialogAction;

export type PropertyImageContent = {
  __typename?: "PropertyImageContent";
  id: Scalars["String"]["output"];
  imageInfo: Image;
};

export type PropertyImageDetailDialogAction = {
  __typename?: "PropertyImageDetailDialogAction";
  scrollPageToRef?: Maybe<Scalars["String"]["output"]>;
  trigger: PropertyImageDialogTrigger;
};

export type PropertyImageDialogTrigger = {
  __typename?: "PropertyImageDialogTrigger";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type PropertyImageGallery = AccessibilityField & {
  __typename?: "PropertyImageGallery";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  categories?: Maybe<Array<PropertyGalleryCategory>>;
  featured?: Maybe<PropertyImage>;
  galleryButtons?: Maybe<Array<EgdsOverlayButton>>;
  images: Array<PropertyImage>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  mediaGalleryDialog?: Maybe<LodgingGalleryDialog>;
  primaryButton?: Maybe<PropertyLodgingStickyButton>;
  thumbnailGalleryDialog?: Maybe<LodgingGalleryDialog>;
};

export type PropertyImagesGraphQlResponse = {
  __typename?: "PropertyImagesGraphQLResponse";
  propertyImages: Array<PropertyImageContent>;
};

export type PropertyInfo = {
  __typename?: "PropertyInfo";
  /** @deprecated for federation use.  Do not use this. */
  deprecatedPropertyContext: DeprecatedPropertyContext;
  /** @deprecated for federation use.  Do not use this. */
  deprecatedShoppingContext?: Maybe<DeprecatedShoppingContext>;
  directFeedback?: Maybe<DirectFeedback>;
  /** @deprecated Deprecated. Contents are volatile and subject to change at any moment. Use at your own risk! */
  experimental: PropertyDetailsExperimentalSection;
  feedback: Feedback;
  frequentlyAskedQuestions?: Maybe<PropertyFaQs>;
  /** @deprecated Replaced by propertyGallery as part of BFF to BEX-API migration */
  gallery?: Maybe<PropertyGallery>;
  id: Scalars["String"]["output"];
  /** @deprecated Use the one in Offers */
  infositeUrl?: Maybe<Scalars["URL"]["output"]>;
  lodgingProduct: LodgingProductView;
  offer?: Maybe<OfferDetails>;
  /** @deprecated do not use. This is for federation use */
  offerOptions?: Maybe<Array<PropertyInfoOptions>>;
  offers?: Maybe<OfferDetails>;
  propertyCalendar: PropertyCalendar;
  propertyContentSectionGroups: PropertyContentSectionGroups;
  propertyDetails: PropertyDetails;
  /** @deprecated Replaced by productGallery as part of Product overview migration to product-details-experience-api */
  propertyGallery?: Maybe<PropertyImageGallery>;
  /** @deprecated Moved to PropertySummary, replace with PropertySummary.spaceOverview: PropertyInfoContent? */
  propertyHighlightedDetails?: Maybe<PropertyInfoContent>;
  propertyIdDisplay?: Maybe<EgdsSpannableText>;
  /** @deprecated do not use. This is for federation use */
  propertyName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated do not use. This is for federation use */
  referrer?: Maybe<Scalars["String"]["output"]>;
  review?: Maybe<PropertyReview>;
  reviewInfo: PropertyReviews;
  saveTripItem?: Maybe<TripsSaveItem>;
  shareInfo?: Maybe<ShareInfo>;
  shoppingShareLinks?: Maybe<ShoppingShareLinks>;
  /** @deprecated Deprecated in favor of tripsSaveItem */
  shortList?: Maybe<ShortList>;
  similarProperties?: Maybe<SimilarProperties>;
  summary?: Maybe<PropertySummary>;
  telesales?: Maybe<Telesales>;
  topReviews?: Maybe<PropertyTopReviews>;
};

export type PropertyInfoInfositeUrlArgs = {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  roomInput?: InputMaybe<Array<RoomInput>>;
};

export type PropertyInfoLodgingProductArgs = {
  searchCriteria: PropertySearchCriteriaInput;
  searchOffer: SearchOfferInput;
};

export type PropertyInfoOfferArgs = {
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  naturalKeys: Array<PropertyNaturalKeyInput>;
};

export type PropertyInfoOffersArgs = {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  roomInput?: InputMaybe<Array<RoomInput>>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type PropertyInfoPropertyCalendarArgs = {
  dateRange: PropertyDateRangeInput;
  roomInput: Array<RoomInput>;
};

export type PropertyInfoPropertyContentSectionGroupsArgs = {
  amenitiesPropertyContentType?: InputMaybe<PropertyContentType>;
  damageLiability?: InputMaybe<DamageLiability>;
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoPropertyDetailsArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoPropertyIdDisplayArgs = {
  identifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type PropertyInfoReviewArgs = {
  id: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["Locale"]["input"]>;
};

export type PropertyInfoReviewInfoArgs = {
  end?: InputMaybe<Scalars["Int"]["input"]>;
  filters?: InputMaybe<PropertyReviewFiltersInput>;
  pagination?: InputMaybe<PaginationInput>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  sortBy?: InputMaybe<PropertyReviewSort>;
  start?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PropertyInfoSaveTripItemArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoShoppingShareLinksArgs = {
  context?: InputMaybe<ContextInput>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoSimilarPropertiesArgs = {
  propertyRecommendationStrategy?: InputMaybe<PropertyRecommendationStrategy>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoTopReviewsArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertyInfoAction = PropertyInfoJumpLink;

export type PropertyInfoContent = {
  __typename?: "PropertyInfoContent";
  adaptExAttemptEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  adaptExSuccessEvents?: Maybe<Array<LodgingAdaptExAnalyticsEvent>>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Use header instead */
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  iconAnalytics?: Maybe<ClientSideAnalytics>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<PropertyInfoItem>;
  jumpLink?: Maybe<PropertyInfoJumpLink>;
  sectionId?: Maybe<Scalars["String"]["output"]>;
  seeMoreAction?: Maybe<SeeMoreAction>;
  structuredData?: Maybe<SeoStructuredData>;
  /** @deprecated UI needs a nullable label. Use heading instead */
  title: Scalars["String"]["output"];
};

export type PropertyInfoItem = AccessibilityField & {
  __typename?: "PropertyInfoItem";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use primary field instead */
  graphic?: Maybe<UiGraphic>;
  highlights?: Maybe<Highlights>;
  /** @deprecated Use primary field instead */
  icon?: Maybe<Icon>;
  /** @deprecated Use primary field instead */
  label?: Maybe<Scalars["String"]["output"]>;
  moreInfo?: Maybe<Scalars["String"]["output"]>;
  moreInfoDialog?: Maybe<LodgingPlainDialog>;
  primary?: Maybe<EgdsGraphicText>;
  state?: Maybe<Scalars["String"]["output"]>;
  structuredData?: Maybe<SeoStructuredData>;
  subItems?: Maybe<EgdsTextIconList>;
  text: Scalars["String"]["output"];
  theme?: Maybe<TextTheme>;
};

export type PropertyInfoJumpLink = LodgingJumpLink & {
  __typename?: "PropertyInfoJumpLink";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  localizedName: Scalars["String"]["output"];
  sectionName: Scalars["String"]["output"];
};

export type PropertyInfoMap = LodgingDialog & {
  __typename?: "PropertyInfoMap";
  /** @deprecated No longer used, replace with egdsBasicMap.bounds */
  bounds?: Maybe<Bounds>;
  callToAction?: Maybe<PropertyInfoMapButton>;
  /** @deprecated No longer used, replace with egdsBasicMap.center */
  center?: Maybe<Coordinates>;
  dialogs?: Maybe<Array<PlacesDialog>>;
  egdsBasicMap: EgdsBasicMap;
  isPrebundledPath: Scalars["Boolean"]["output"];
  mapCards: Array<MapCard>;
  /** @deprecated This will be deprecated in favor of mapCards */
  mapInfoCards: Array<EgdsImageCard>;
  /** @deprecated No longer used */
  markerPills: Array<PropertyInfoMapPill>;
  /** @deprecated No longer used, replace with egdsBasicMap.markers */
  markers?: Maybe<Array<PropertyDetailPlottable>>;
  /** @deprecated No longer used */
  staticMapSrc?: Maybe<Scalars["String"]["output"]>;
  toolbar: LodgingDialogToolbar;
  /** @deprecated No longer used */
  trigger: LodgingDialogTriggerMessage;
  /** @deprecated No longer used, replace with egdsBasicMap.zoom */
  zoom?: Maybe<Scalars["Int"]["output"]>;
};

export type PropertyInfoMapAction = {
  __typename?: "PropertyInfoMapAction";
  targetRef?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyInfoMapButton = {
  __typename?: "PropertyInfoMapButton";
  action?: Maybe<PropertyInfoMapAction>;
  button: UiPrimaryFloatingActionButton;
  event?: Maybe<EgClickstreamEvent>;
};

export type PropertyInfoMapPill = {
  __typename?: "PropertyInfoMapPill";
  isSelected: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type PropertyInfoOffersListing = MessageResult | PropertyUnit | RecommendedOffer;

export type PropertyInfoOptions = "SHOP_WITH_POINTS";

export type PropertyInfoSection = {
  __typename?: "PropertyInfoSection";
  action?: Maybe<PropertyInfoAction>;
  contents: Array<PropertyInfoContent>;
  /** @deprecated Label used by the PWA to drive automation testing */
  dataLabel?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated deprecated in favour of expando, replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<LodgingHeader>;
  sectionId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated PropertyInfoSubSection is competing similar model as PropertyInfoContent but is less flexible. Replaced by contents field */
  sections: Array<PropertyInfoSubSection>;
  /** @deprecated Replaced by the more flexible LodgingHeader type, replace with header.text */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyInfoSubSection = {
  __typename?: "PropertyInfoSubSection";
  body: Array<Scalars["String"]["output"]>;
  descriptions: Array<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyLegalDisclaimer = {
  __typename?: "PropertyLegalDisclaimer";
  disclaimerContents?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyListingPlaceholderMetadata = {
  __typename?: "PropertyListingPlaceholderMetadata";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type PropertyListingsIconTextExpando = {
  __typename?: "PropertyListingsIconTextExpando";
  expandedContent?: Maybe<Array<EgdsGraphicText>>;
  expandoCard?: Maybe<EgdsExpandoCard>;
  graphic?: Maybe<UiGraphic>;
};

export type PropertyLocation = {
  __typename?: "PropertyLocation";
  address?: Maybe<PropertyAddress>;
  coordinates?: Maybe<Coordinates>;
  /** @deprecated The summary structure should be refactored, prevent use that structure on future implementation */
  mapDialog?: Maybe<LodgingMapDialog>;
  mapTrigger?: Maybe<LodgingMapTrigger>;
  multiCityRegion?: Maybe<Region>;
  /** @deprecated Replaced by 'staticImage' field. */
  overviewStaticMapSrc?: Maybe<Scalars["String"]["output"]>;
  parentRegion?: Maybe<Region>;
  /** @deprecated PROPEXP-1830 removed as part of TNL finalisation */
  ratings?: Maybe<ProductRatingSummary>;
  /** @deprecated Replaced by 'mapTrigger' field. */
  staticImage?: Maybe<Image>;
  video?: Maybe<Video>;
  whatsAround?: Maybe<PropertyWhatsAround>;
};

export type PropertyLodgingStickyButton = {
  __typename?: "PropertyLodgingStickyButton";
  button?: Maybe<LodgingButton>;
  propertyTargetRef?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated deprecating to allow for more flexibility on the section on the page to scroll to, replace with propertyTargetRef */
  targetRef?: Maybe<PropertyContentSectionRef>;
};

export type PropertyLoyaltyDiscount = {
  __typename?: "PropertyLoyaltyDiscount";
  /** @deprecated Legacy message used on HSR for showing loyalty points, replace with saveWithPointsMessage, saveWithPointsActionMessage */
  pointsAppliedMessage?: Maybe<Scalars["String"]["output"]>;
  pointsDiscount?: Maybe<Money>;
  pointsToggle?: Maybe<UiToggle>;
  pointsVIPDiscount?: Maybe<Money>;
  saveWithPointsActionMessage?: Maybe<Scalars["String"]["output"]>;
  saveWithPointsMessage?: Maybe<Scalars["String"]["output"]>;
  tierName?: Maybe<Scalars["String"]["output"]>;
  toggleSubText?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyLoyaltyMessage = {
  __typename?: "PropertyLoyaltyMessage";
  badge?: Maybe<EgdsLoyaltyBadge>;
  linkMessage?: Maybe<LodgingLinkMessage>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyMarketingInfo = {
  __typename?: "PropertyMarketingInfo";
  affcid?: Maybe<Scalars["String"]["output"]>;
  afflid?: Maybe<Scalars["String"]["output"]>;
  afmcid?: Maybe<Scalars["String"]["output"]>;
  brandcid?: Maybe<Scalars["String"]["output"]>;
  emlcid?: Maybe<Scalars["String"]["output"]>;
  emldtl?: Maybe<Scalars["String"]["output"]>;
  gclid?: Maybe<Scalars["String"]["output"]>;
  icmcid?: Maybe<Scalars["String"]["output"]>;
  icmdtl?: Maybe<Scalars["String"]["output"]>;
  k_user_id?: Maybe<Scalars["String"]["output"]>;
  kword?: Maybe<Scalars["String"]["output"]>;
  lnkloc?: Maybe<Scalars["String"]["output"]>;
  marketingChannels?: Maybe<Array<PropertyMarketingTag>>;
  marketingPrices?: Maybe<Array<PropertyMarketingTag>>;
  mctc?: Maybe<Scalars["Int"]["output"]>;
  mdpcid?: Maybe<Scalars["String"]["output"]>;
  mdpdtl?: Maybe<Scalars["String"]["output"]>;
  olacid?: Maybe<Scalars["String"]["output"]>;
  oladtl?: Maybe<Scalars["String"]["output"]>;
  semcid?: Maybe<Scalars["String"]["output"]>;
  semdtl?: Maybe<Scalars["String"]["output"]>;
  seocid?: Maybe<Scalars["String"]["output"]>;
};

export interface PropertyMarketingInfoInput {
  affcid?: InputMaybe<Scalars["String"]["input"]>;
  afflid?: InputMaybe<Scalars["String"]["input"]>;
  afmcid?: InputMaybe<Scalars["String"]["input"]>;
  brandcid?: InputMaybe<Scalars["String"]["input"]>;
  emlcid?: InputMaybe<Scalars["String"]["input"]>;
  emldtl?: InputMaybe<Scalars["String"]["input"]>;
  gclid?: InputMaybe<Scalars["String"]["input"]>;
  icmcid?: InputMaybe<Scalars["String"]["input"]>;
  icmdtl?: InputMaybe<Scalars["String"]["input"]>;
  k_user_id?: InputMaybe<Scalars["String"]["input"]>;
  kword?: InputMaybe<Scalars["String"]["input"]>;
  lnkloc?: InputMaybe<Scalars["String"]["input"]>;
  marketingChannels?: InputMaybe<Array<PropertyMarketingTagInput>>;
  marketingPrices?: InputMaybe<Array<PropertyMarketingTagInput>>;
  mctc?: InputMaybe<Scalars["Int"]["input"]>;
  mdpcid?: InputMaybe<Scalars["String"]["input"]>;
  mdpdtl?: InputMaybe<Scalars["String"]["input"]>;
  olacid?: InputMaybe<Scalars["String"]["input"]>;
  oladtl?: InputMaybe<Scalars["String"]["input"]>;
  semcid?: InputMaybe<Scalars["String"]["input"]>;
  semdtl?: InputMaybe<Scalars["String"]["input"]>;
  seocid?: InputMaybe<Scalars["String"]["input"]>;
}

export type PropertyMarketingTag = {
  __typename?: "PropertyMarketingTag";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface PropertyMarketingTagInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type PropertyNaturalKey = {
  __typename?: "PropertyNaturalKey";
  businessModelType?: Maybe<BusinessModelType>;
  checkIn: Date;
  checkOut: Date;
  id: Scalars["String"]["output"];
  inventoryType: InventoryType;
  noCreditCard?: Maybe<Scalars["Boolean"]["output"]>;
  petsIncluded?: Maybe<Scalars["Boolean"]["output"]>;
  ratePlanId: Scalars["String"]["output"];
  ratePlanType?: Maybe<Scalars["String"]["output"]>;
  roomTypeId: Scalars["String"]["output"];
  rooms: Array<PropertyRoom>;
  shoppingPath?: Maybe<ShoppingPathType>;
};

export interface PropertyNaturalKeyInput {
  businessModelType?: InputMaybe<BusinessModelType>;
  checkIn: DateInput;
  checkOut: DateInput;
  id: Scalars["String"]["input"];
  inventoryType: InventoryType;
  noCreditCard?: InputMaybe<Scalars["Boolean"]["input"]>;
  petsIncluded?: InputMaybe<Scalars["Boolean"]["input"]>;
  ratePlanId: Scalars["String"]["input"];
  ratePlanType?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId: Scalars["String"]["input"];
  rooms: Array<PropertyRoomInput>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
}

export type PropertyNavigationBarTab = LodgingJumpLink & {
  __typename?: "PropertyNavigationBarTab";
  icon?: Maybe<Icon>;
  localizedName: Scalars["String"]["output"];
  sectionName: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  tabLink?: Maybe<LodgingLinkMessage>;
};

export type PropertyOffer = {
  __typename?: "PropertyOffer";
  /** @deprecated DO NOT USE: This is required for the federation migration */
  deprecatedContext?: Maybe<DeprecatedMultiItemContext>;
  /** @deprecated DO NOT USE: This is required for the federation migration */
  federationNaturalKeys?: Maybe<Array<FederationPropertyNaturalKey>>;
  naturalKeys: Array<PropertyNaturalKey>;
  offerIndex: Scalars["Int"]["output"];
  propertyId?: Maybe<Scalars["String"]["output"]>;
};

export interface PropertyOfferInput {
  naturalKeys: Array<PropertyNaturalKeyInput>;
  offerIndex: Scalars["Int"]["input"];
}

export type PropertyOfferItem = OfferItem & {
  __typename?: "PropertyOfferItem";
  badge?: Maybe<CampaignOfferBadge>;
  dates?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discountBadge?: Maybe<CampaignOfferBadge>;
  freeCancellationMessage?: Maybe<Scalars["String"]["output"]>;
  guestReviewCount?: Maybe<Scalars["String"]["output"]>;
  guestReviewQualifierText?: Maybe<Scalars["String"]["output"]>;
  guestReviewRating?: Maybe<Scalars["String"]["output"]>;
  guestReviewRatingQualifierText?: Maybe<Scalars["String"]["output"]>;
  guestReviewText?: Maybe<Scalars["String"]["output"]>;
  image: Image;
  location?: Maybe<Scalars["String"]["output"]>;
  lodgingOffer?: Maybe<OfferData>;
  mod?: Maybe<ModItem>;
  name: Scalars["String"]["output"];
  secondaryContent?: Maybe<Scalars["String"]["output"]>;
  starRating?: Maybe<Scalars["Float"]["output"]>;
  vapMessage?: Maybe<Scalars["String"]["output"]>;
  vipMessage?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyOverview = AccessibilityField & {
  __typename?: "PropertyOverview";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  inventoryType?: Maybe<InventoryType>;
  label?: Maybe<Scalars["String"]["output"]>;
  propertyRating?: Maybe<EgdsIconRating>;
  propertyTagline?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use propertyRating */
  starRating?: Maybe<Scalars["Float"]["output"]>;
  vipMessaging?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyPaymentFlags = "ARTIFICIALLY_FENCED_RATE" | "MIXED_RATE_PLAN" | "NO_CREDIT_CARD";

export type PropertyPaymentModel = "PAY_LATER" | "PAY_LATER_WITH_DEPOSIT" | "PAY_NOW";

export type PropertyPaymentOption = {
  __typename?: "PropertyPaymentOption";
  image?: Maybe<Scalars["URL"]["output"]>;
  name: Scalars["String"]["output"];
};

export type PropertyPaymentPolicyInfo = {
  __typename?: "PropertyPaymentPolicyInfo";
  descriptions: Array<PropertyInfoContent>;
  heading: Scalars["String"]["output"];
  paymentType: PropertyPaymentType;
  price?: Maybe<PropertyPrice>;
};

export type PropertyPaymentType = "PAY_LATER" | "PAY_LATER_WITH_DEPOSIT" | "PAY_NOW";

export type PropertyPolicies = {
  __typename?: "PropertyPolicies";
  checkinEnd?: Maybe<Scalars["String"]["output"]>;
  checkinInstructions: Array<Scalars["String"]["output"]>;
  checkinMinAge?: Maybe<Scalars["String"]["output"]>;
  checkinPolicies: Array<Scalars["String"]["output"]>;
  checkinStart?: Maybe<Scalars["String"]["output"]>;
  checkoutPolicies: Array<Scalars["String"]["output"]>;
  checkoutTime?: Maybe<Scalars["String"]["output"]>;
  childAndBed?: Maybe<PropertyInfoSubSection>;
  needToKnow?: Maybe<PropertyInfoSubSection>;
  paymentOptions: Array<PropertyPaymentOption>;
  pets?: Maybe<PropertyInfoSubSection>;
  shouldMention?: Maybe<PropertyInfoSubSection>;
};

export type PropertyPrice = {
  __typename?: "PropertyPrice";
  /** @deprecated UI should determine the alignment */
  alignment?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Legal disclaimer, replace with disclaimer in PropertyPriceOption */
  disclaimer?: Maybe<LodgingPlainMessage>;
  displayMessages?: Maybe<Array<PriceDisplayMessage>>;
  /** @deprecated Replace with list of options */
  lead?: Maybe<Money>;
  marketingFeeDetails?: Maybe<MarketingFeeDetails>;
  multiItemPriceToken?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<PropertyPriceOption>>;
  /** @deprecated Use priceMessaging instead */
  priceMessages?: Maybe<Array<LodgingPlainMessage>>;
  priceMessaging?: Maybe<Array<LodgingEnrichedMessage>>;
  reassuranceMessage?: Maybe<LodgingEnrichedMessage>;
  /** @deprecated Replace with list of priceMessages */
  roomNightMessage?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Replace with list of options */
  strikeOut?: Maybe<Money>;
  /** @deprecated Replace with list of options */
  strikeOutType?: Maybe<StrikeOutType>;
  total?: Maybe<Money>;
};

export type PropertyPriceMetadata = {
  __typename?: "PropertyPriceMetadata";
  discountType?: Maybe<RateDiscountType>;
  rateDiscount?: Maybe<RateDiscount>;
  saleCurrency?: Maybe<Scalars["String"]["output"]>;
  supplyCurrency?: Maybe<Scalars["String"]["output"]>;
  totalDiscountPercentage?: Maybe<Scalars["Int"]["output"]>;
};

export type PropertyPriceOption = AccessibilityField & {
  __typename?: "PropertyPriceOption";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This is deprecated. Please use priceDisclaimer instead */
  disclaimer?: Maybe<LodgingPlainMessage>;
  displayPrice?: Maybe<Money>;
  formattedDisplayPrice?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  leadingCaption?: Maybe<Scalars["String"]["output"]>;
  loyaltyPrice?: Maybe<LoyaltyPrice>;
  priceDisclaimer?: Maybe<LodgingPlainDialog>;
  priceHeaderDialog?: Maybe<LodgingPlainDialog>;
  strikeOut?: Maybe<Money>;
};

export type PropertyRatesDateSelector = {
  __typename?: "PropertyRatesDateSelector";
  analyticsPayloads: Array<DateSelectorAnalyticsData>;
  configuration: PropertyRatesDateSelectorConfiguration;
  days: Array<PropertyCalendarRate>;
  disclaimer: LodgingPlainMessage;
  submitButtonLabel: Scalars["String"]["output"];
  validationMessage: Scalars["String"]["output"];
};

export type PropertyRatesDateSelectorConfiguration = {
  __typename?: "PropertyRatesDateSelectorConfiguration";
  beginDate: Date;
  currentDate: Date;
  endDate: Date;
};

export type PropertyRating = EgdsRating & {
  __typename?: "PropertyRating";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  rating: Scalars["Float"]["output"];
};

export type PropertyRecommendationStrategy = "NEARBY_PROPERTIES" | "SIMILAR_PROPERTIES";

export type PropertyRenovations = {
  __typename?: "PropertyRenovations";
  header?: Maybe<Scalars["String"]["output"]>;
  renovations?: Maybe<Array<Scalars["String"]["output"]>>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyResultsSummary = {
  __typename?: "PropertyResultsSummary";
  availablePropertiesSize: Scalars["Int"]["output"];
  contents?: Maybe<Array<ProductResultsSummaryContentContainer>>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Replaces with generic message models., replace with resultsSummary */
  headerMessages?: Maybe<Array<ResultsHeaderMessage>>;
  /** @deprecated Deprecating as we are moving away from dialogs style models along with its' contents */
  loyaltyEducation?: Maybe<LoyaltyEducation>;
  loyaltyInfo: PropertyLoyaltyDiscount;
  marketSize: Scalars["Int"]["output"];
  matchedPropertiesSize: Scalars["Int"]["output"];
  /** @deprecated Use PropertySearchResults.propertySearchListings - noop */
  messages?: Maybe<Array<MessageResult>>;
  messagingCard?: Maybe<SearchResultMessagingCard>;
  /** @deprecated Functionality has been removed after experiment rolled back */
  messagingModule?: Maybe<SearchResultMessagingModule>;
  messagingSheet?: Maybe<SearchResultMessagingSheet>;
  /** @deprecated Not needed, pageView beacon is fired in experience-api-lodging-search */
  pageViewBeacon: Scalars["String"]["output"];
  priceDisplayChangeOnboardingAction?: Maybe<PriceDisplayChangeOnboardingAction>;
  pricedHotelSize: Scalars["Int"]["output"];
  pricingScheme?: Maybe<PricingScheme>;
  propertyTypeCarousel?: Maybe<SearchCarousel>;
  region?: Maybe<Region>;
  /** @deprecated Unavailable now that call to Urgency Service has been removed */
  regionCompression?: Maybe<RegionCompression>;
  resultMessages: Array<LodgingSearchResultHeaderMessage>;
  /** @deprecated Use PropertySearchResults.propertySearchListings */
  resultSectionHeadings?: Maybe<Array<ResultSectionHeading>>;
  /** @deprecated Use header to support EGDS types than simple string */
  resultsHeading?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use resultMessages, replace with resultMessages */
  resultsSummary?: Maybe<Array<HeaderMessage>>;
  /** @deprecated Need to return a model instead of a string, replace with resultsSummary */
  resultsTitle?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Need to return a list of models instead of a single title, replace with resultsSummary */
  resultsTitleModel?: Maybe<ResultTitleModel>;
  shoppingInvokeFunctionParams?: Maybe<Array<ShoppingProductCardFunctionParametersContainers>>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type PropertyReview = {
  __typename?: "PropertyReview";
  /** @deprecated Deprecated along with the userLocation in favor of the reviewAuthorAttribution which combines them */
  author?: Maybe<Scalars["String"]["output"]>;
  brandType?: Maybe<Scalars["String"]["output"]>;
  contentDirectFeedbackPromptId?: Maybe<Scalars["ID"]["output"]>;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  feedbackIndicator?: Maybe<FeedbackIndicator>;
  /** @deprecated Deprecated in favor of the reviewInteractionSections */
  helpfulReviewVotes?: Maybe<Scalars["String"]["output"]>;
  highlightedText?: Maybe<EgdsSpannableText>;
  id: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  locale: Scalars["Locale"]["output"];
  locationRemarks?: Maybe<Scalars["String"]["output"]>;
  managementResponses: Array<ManagementResponse>;
  negativeRemarks?: Maybe<Scalars["String"]["output"]>;
  photoSection?: Maybe<LodgingGalleryCarousel>;
  photos: Array<Image>;
  positiveRemarks?: Maybe<Scalars["String"]["output"]>;
  propertyReviewSource?: Maybe<PropertyReviewSource>;
  ratingOverall: Scalars["Float"]["output"];
  ratingOverallLabel: LodgingEnrichedMessage;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  readMore?: Maybe<EgdsStandardLink>;
  reviewAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reviewAuthorAttribution?: Maybe<LodgingHeader>;
  reviewFooter?: Maybe<PropertyReviewFooterSection>;
  reviewInteractionSections?: Maybe<Array<PropertyReviewInteraction>>;
  /** @deprecated Deprecated in favor of the PropertyReviewFooterSection */
  reviewRegion?: Maybe<Mark>;
  reviewScoreWithDescription?: Maybe<LodgingEnrichedMessage>;
  reviewerVerified: Scalars["Boolean"]["output"];
  seeMoreAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Deprecated in favor of the PropertyReviewFooterSection */
  stayDuration?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated submissionTime is deprecated. Use submissionTimeLocalized. */
  submissionTime: DateTime;
  submissionTimeLocalized: Scalars["String"]["output"];
  superlative?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  themes?: Maybe<Array<ReviewThemes>>;
  title: Scalars["String"]["output"];
  translationInfo?: Maybe<PropertyReviewTranslationInfo>;
  travelers: Array<Scalars["String"]["output"]>;
  /** @deprecated Consolidated into author field, replace with author */
  userDisplayName: Scalars["String"]["output"];
  userLocation: Scalars["String"]["output"];
  /** @deprecated Consolidated into author field, replace with author */
  userNickname: Scalars["String"]["output"];
};

export type PropertyReviewAggregates = {
  __typename?: "PropertyReviewAggregates";
  popularMentions?: Maybe<KeyPhrasesComponent>;
  propertySentiment?: Maybe<Array<PropertySentiment>>;
  propertySummaries?: Maybe<Array<LodgingReviewSummary>>;
};

export type PropertyReviewAggregatesPopularMentionsArgs = {
  client?: InputMaybe<KeyPhrasesClientType>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PropertyReviewCategory =
  | "BUSINESS_TRAVELERS"
  | "COUPLES"
  | "EVERYONE"
  | "FAMILIES"
  | "GETAWAY_WITH_FRIENDS"
  | "GOLF"
  | "OTHER"
  | "OVERNIGHT_STAY"
  | "PERSONAL_EVENT"
  | "SHOPPING"
  | "SPA"
  | "STUDENTS";

export type PropertyReviewContent = {
  __typename?: "PropertyReviewContent";
  submissionDetails: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type PropertyReviewCountDetails = {
  __typename?: "PropertyReviewCountDetails";
  fullDescription?: Maybe<Scalars["String"]["output"]>;
  shortDescription?: Maybe<Scalars["String"]["output"]>;
};

export interface PropertyReviewFiltersInput {
  category?: InputMaybe<PropertyReviewCategory>;
  includeRatingsOnlyReviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeRecentReviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["Locale"]["input"]>;
  popularMention?: InputMaybe<Scalars["String"]["input"]>;
  ratings?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  reviewType?: InputMaybe<PropertyReviewType>;
  roomTypeId?: InputMaybe<Scalars["Int"]["input"]>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  sortRating?: InputMaybe<Array<Scalars["String"]["input"]>>;
  travelCompanion?: InputMaybe<Array<Scalars["String"]["input"]>>;
  travelerType?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripReason?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type PropertyReviewFlagDialog = LodgingDialog & {
  __typename?: "PropertyReviewFlagDialog";
  content: PropertyReviewFlagDialogContent;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type PropertyReviewFlagDialogContent = {
  __typename?: "PropertyReviewFlagDialogContent";
  communityInfo: Scalars["String"]["output"];
  flagOptions: FilterViewModel;
  partnerInfo: Scalars["String"]["output"];
  partnerLink: LodgingLinkMessage;
  primaryUIButton: UiPrimaryButton;
};

export type PropertyReviewFooterMessage = {
  __typename?: "PropertyReviewFooterMessage";
  seoStructuredData?: Maybe<SeoStructuredData>;
  text: PropertyReviewFooterMessageText;
};

export type PropertyReviewFooterMessageText = EgdsGraphicText | EgdsPlainText;

export type PropertyReviewFooterSection = {
  __typename?: "PropertyReviewFooterSection";
  messages: Array<PropertyReviewFooterMessage>;
};

export type PropertyReviewInteraction = AccessibilityField & {
  __typename?: "PropertyReviewInteraction";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  feedbackAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  primaryDisplayString?: Maybe<Scalars["String"]["output"]>;
  reviewInteractionType: ReviewInteractionType;
};

export type PropertyReviewRatingCount = AccessibilityField & {
  __typename?: "PropertyReviewRatingCount";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  count: FormattedNumber;
  description: Scalars["String"]["output"];
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  percent: Scalars["Int"]["output"];
  rating: Scalars["Int"]["output"];
};

export type PropertyReviewSort =
  | "HIGHEST_TO_LOWEST_RATED"
  | "LOWEST_TO_HIGHEST_RATED"
  | "NEWEST_TO_OLDEST"
  | "NEWEST_TO_OLDEST_BY_LANGUAGE"
  | "NEWEST_TO_OLDEST_BY_RELEVANCY";

export type PropertyReviewSource = AccessibilityField & {
  __typename?: "PropertyReviewSource";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<Mark>;
  label?: Maybe<Scalars["String"]["output"]>;
  text: LodgingPlainMessage;
};

export type PropertyReviewSummary = AccessibilityField & {
  __typename?: "PropertyReviewSummary";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  amenityScore?: Maybe<FormattedNumber>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  amenityScoreOverMax?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  amenityScorePercent?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated use averageOverallRatingSection, replace with averageOverallRatingSection */
  averageOverallRating: FormattedNumber;
  averageOverallRatingSection?: Maybe<LodgingEnrichedMessage>;
  /** @deprecated use averageOverallRatingSection, replace with averageOverallRatingSection */
  averageOverallRatingValue?: Maybe<FormattedNumber>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  cleanliness: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  cleanlinessOverMax?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use localized and generic alternative, replace with reviewSummaryDetails */
  cleanlinessPercent: Scalars["Int"]["output"];
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  convenienceOfLocation: FormattedNumber;
  highlightMessage?: Maybe<MessageResult>;
  highlightMessageCard?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  hotelCondition: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  hotelConditionOverMax?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  hotelConditionPercent: Scalars["Int"]["output"];
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  interactionAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  lastIndex: Scalars["Int"]["output"];
  neighborhoodSatisfaction: FormattedNumber;
  /** @deprecated overall score with description, instead use overallScoreWithDescriptionA11y , replace with overallScoreWithDescriptionA11y */
  overallScoreWithDescription?: Maybe<Scalars["String"]["output"]>;
  overallScoreWithDescriptionA11y?: Maybe<LodgingEnrichedMessage>;
  paginateAction?: Maybe<LodgingButton>;
  propertyId: Scalars["String"]["output"];
  propertyReviewCountDetails?: Maybe<PropertyReviewCountDetails>;
  ratingCounts?: Maybe<Array<PropertyReviewRatingCount>>;
  /** @deprecated The data is no longer available */
  recommendedPercent: FormattedNumber;
  /** @deprecated This data should be accessed from the propertyReviewCountDetails property, replace with propertyReviewCountDetails */
  reviewCountLocalized?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This can only handle the case when the reviewDisclaimer is a single string and not multiline. Multiline cases can be handled by reviewDisclaimerValues */
  reviewDisclaimer?: Maybe<Scalars["String"]["output"]>;
  reviewDisclaimerAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  reviewDisclaimerAnalytics?: Maybe<ClientSideAnalytics>;
  reviewDisclaimerHeading?: Maybe<Scalars["String"]["output"]>;
  reviewDisclaimerLabel?: Maybe<Scalars["String"]["output"]>;
  reviewDisclaimerUrl?: Maybe<LodgingLinkMessage>;
  reviewDisclaimerValues?: Maybe<Array<EgdsStylizedText>>;
  reviewHighlightingFeatureCard?: Maybe<EgdsStandardMessagingCard>;
  reviewHighlights?: Maybe<PropertyInfoContent>;
  reviewRating?: Maybe<LodgingCardRatingSection>;
  reviewSummaryDetails?: Maybe<Array<ReviewSummaryDetail>>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  roomComfort: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  roomComfortOverMax?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  roomComfortPercent: Scalars["Int"]["output"];
  seeAllAction?: Maybe<PropertyFilteredReviewsDialog>;
  seeAllReviewsCallToActionLabel?: Maybe<Scalars["String"]["output"]>;
  seoData: Array<SeoStructuredData>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  serviceAndStaff: FormattedNumber;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  serviceAndStaffOverMax?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use the generic list of review summaries, replace with reviewSummaryDetails */
  serviceAndStaffPercent: Scalars["Int"]["output"];
  source?: Maybe<LodgingEnrichedMessage>;
  strategy?: Maybe<ReviewSource>;
  superlative?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated For use with TnL #50058 - Recent reviews on native PDP */
  title?: Maybe<Scalars["String"]["output"]>;
  totalCount: FormattedNumber;
  /** @deprecated Raw number only, instead use FormattedNumber in totalCount, replace with totalCount */
  totalReviewCount: Scalars["Int"]["output"];
};

export type PropertyReviewTranslationInfo = {
  __typename?: "PropertyReviewTranslationInfo";
  loadingTranslationText: Scalars["String"]["output"];
  originalReviewLocale: Scalars["Locale"]["output"];
  seeOriginalText: Scalars["String"]["output"];
  targetLocale?: Maybe<Scalars["Locale"]["output"]>;
  translatedBy: Mark;
  translationCallToActionLabel: Scalars["String"]["output"];
};

export type PropertyReviewType = "CRITICAL" | "POSITIVE" | "RECENT";

export type PropertyReviews = AccessibilityField & {
  __typename?: "PropertyReviews";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  noResultsMessage?: Maybe<NoResultsMessage>;
  reviewFlag?: Maybe<ReviewFlag>;
  reviews: Array<PropertyReview>;
  reviewsExpando?: Maybe<EgdsExpandoPeek>;
  sortAndFilter: SortAndFilterViewModel;
  summary: PropertyReviewSummary;
  writeReviewLink?: Maybe<LodgingLinkMessage>;
};

export type PropertyReviewsSummary = {
  __typename?: "PropertyReviewsSummary";
  egdsMark?: Maybe<Mark>;
  localizedScore?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Localized subtitle, instead use localizedSubtitleA11y , replace with localizedSubtitleA11y */
  localizedSubtitle?: Maybe<Scalars["String"]["output"]>;
  localizedSubtitleA11y?: Maybe<LodgingEnrichedMessage>;
  localizedTotal?: Maybe<Scalars["String"]["output"]>;
  mark?: Maybe<LodgingMark>;
  relevantRecentReview?: Maybe<PropertyReviewContent>;
  score?: Maybe<Scalars["Float"]["output"]>;
  scoreDenominator: Scalars["Int"]["output"];
  superlative?: Maybe<Scalars["String"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type PropertyRoom = {
  __typename?: "PropertyRoom";
  childAges: Array<Scalars["Int"]["output"]>;
  numberOfAdults: Scalars["Int"]["output"];
  pets?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PropertyRoomCard = {
  __typename?: "PropertyRoomCard";
  cancellationPolicy: AgentQuoteSwitchableMessage;
  extras?: Maybe<EgdsTextSection>;
  messages?: Maybe<Array<EgdsText>>;
  priceSummary: AgentQuoteSwitchablePriceSummary;
  propertyAmenities?: Maybe<Array<EgdsIconText>>;
  propertyGallery: Array<Image>;
  propertyName: EgdsHeading;
  propertyRating?: Maybe<EgdsRating>;
  roomFeatures?: Maybe<Array<EgdsIconText>>;
  roomSummary: EgdsTextSection;
};

export interface PropertyRoomInput {
  childAges: Array<Scalars["Int"]["input"]>;
  numberOfAdults: Scalars["Int"]["input"];
  pets?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type PropertySeoStructuredData = {
  __typename?: "PropertySEOStructuredData";
  content?: Maybe<Scalars["String"]["output"]>;
  itemprop: Scalars["String"]["output"];
  itemscope: Scalars["Boolean"]["output"];
  itemtype?: Maybe<Scalars["URL"]["output"]>;
};

export type PropertySearchCriteria = {
  __typename?: "PropertySearchCriteria";
  primary: PrimaryPropertyCriteria;
  secondary?: Maybe<ShoppingSearchCriteria>;
};

export interface PropertySearchCriteriaInput {
  primary: PrimaryPropertyCriteriaInput;
  secondary?: InputMaybe<ShoppingSearchCriteriaInput>;
}

export type PropertySearchFilters = {
  __typename?: "PropertySearchFilters";
  accessibility?: Maybe<Array<PropertyFilterAccessibility>>;
  agencyBusinessModels?: Maybe<Array<AgencyBusinessModel>>;
  amenities?: Maybe<Array<PropertyFilterAmenity>>;
  bedroomFilter?: Maybe<Array<Scalars["Int"]["output"]>>;
  chain?: Maybe<Scalars["String"]["output"]>;
  cleaningAndSafetyPractices?: Maybe<Array<CleaningAndSafetyPractice>>;
  commissionTiers?: Maybe<Array<CommissionTier>>;
  deals?: Maybe<Array<DealType>>;
  mealPlan?: Maybe<Array<MealPlanFilterAmenity>>;
  neighborhood?: Maybe<Scalars["String"]["output"]>;
  paymentType?: Maybe<Array<PaymentType>>;
  poi?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<PriceRange>;
  priceBuckets?: Maybe<Array<Scalars["Int"]["output"]>>;
  propertyName?: Maybe<Scalars["String"]["output"]>;
  propertyStyles?: Maybe<Array<PropertyStyle>>;
  reviewScore?: Maybe<ReviewScoreRange>;
  rewards?: Maybe<Array<Scalars["String"]["output"]>>;
  showAvailableOnly?: Maybe<Scalars["Boolean"]["output"]>;
  starList?: Maybe<Array<Scalars["Float"]["output"]>>;
  stars?: Maybe<StarRange>;
  stayTypes?: Maybe<Array<StayType>>;
  structureTypes?: Maybe<Array<StructureType>>;
  theme?: Maybe<Scalars["String"]["output"]>;
  travelerType?: Maybe<Array<TravelerType>>;
  unmappedFilters?: Maybe<Array<UnmappedFilter>>;
  vipOnly?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface PropertySearchFiltersInput {
  accessibility?: InputMaybe<Array<PropertyFilterAccessibility>>;
  agencyBusinessModels?: InputMaybe<Array<AgencyBusinessModel>>;
  amenities?: InputMaybe<Array<PropertyFilterAmenity>>;
  bedroomFilter?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  chain?: InputMaybe<Scalars["String"]["input"]>;
  cleaningAndSafetyPractices?: InputMaybe<Array<CleaningAndSafetyPractice>>;
  commissionTiers?: InputMaybe<Array<CommissionTier>>;
  deals?: InputMaybe<Array<DealType>>;
  mealPlan?: InputMaybe<Array<MealPlanFilterAmenity>>;
  neighborhood?: InputMaybe<Scalars["String"]["input"]>;
  paymentType?: InputMaybe<Array<PaymentType>>;
  poi?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<PriceRangeInput>;
  priceBuckets?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  propertyName?: InputMaybe<Scalars["String"]["input"]>;
  propertyStyles?: InputMaybe<Array<PropertyStyle>>;
  reviewScore?: InputMaybe<ReviewScoreRangeInput>;
  rewards?: InputMaybe<Array<Scalars["String"]["input"]>>;
  showAvailableOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  starList?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  stars?: InputMaybe<StarRangeInput>;
  stayTypes?: InputMaybe<Array<StayType>>;
  structureTypes?: InputMaybe<Array<StructureType>>;
  theme?: InputMaybe<Scalars["String"]["input"]>;
  travelerType?: InputMaybe<Array<TravelerType>>;
  unmappedFilters?: InputMaybe<Array<UnmappedFilterInput>>;
  vipOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type PropertySearchListing =
  | EgdsStandardMessagingCard
  | FlexibleDateSearchCard
  | LodgingCard
  | LodgingHeading
  | LodgingIntentCard
  | LodgingListingDivider
  | LodgingSurveyTrigger
  | MessageResult
  | NestedListingContainer
  | Property
  | PropertyListingsIconTextExpando
  | PropertySearchListingPlaceholder
  | PropertySearchListingsOverfilteredCard
  | PropertySearchListingsSoldOutCard
  | RecentlyViewedPropertiesCarouselWrapper
  | ShoppingListingContainer
  | SponsoredContentPlacement
  | StaticLodgingMultiItemCrossSell
  | TotalPriceReassuranceCard;

export type PropertySearchListingPlaceholder = {
  __typename?: "PropertySearchListingPlaceholder";
  loadingComponent: Scalars["String"]["output"];
  placeholderMetadata?: Maybe<Array<PropertyListingPlaceholderMetadata>>;
};

export type PropertySearchListingsOverfilteredCard = {
  __typename?: "PropertySearchListingsOverfilteredCard";
  action?: Maybe<LodgingLinkButton>;
  content?: Maybe<EgdsStandardMessagingCard>;
  filterPills?: Maybe<Array<ShoppingAppliedFilter>>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type PropertySearchListingsSoldOutCard = {
  __typename?: "PropertySearchListingsSoldOutCard";
  actionButton?: Maybe<EgdsButton>;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  content?: Maybe<EgdsStandardMessagingCard>;
};

export type PropertySearchMap = {
  __typename?: "PropertySearchMap";
  action?: Maybe<UiPrimaryButton>;
  bounds: Bounds;
  center: Coordinates;
  mapMarkers?: Maybe<Array<LodgingPlottableMapMarker>>;
  markers: Array<Plottable>;
  messagingModel?: Maybe<MessageResult>;
  staticMapSrc?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  zoom: Scalars["Int"]["output"];
};

export type PropertySearchMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "PropertySearchMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export interface PropertySearchMultiItemShoppingActionInput {
  shoppingContext: ShoppingContextInput;
}

export type PropertySearchOptions = "ADS_ONLY" | "SHOP_WITH_POINTS";

export type PropertySearchPreloadResults = {
  __typename?: "PropertySearchPreloadResults";
  criteria: PropertySearchCriteria;
};

export type PropertySearchResults = {
  __typename?: "PropertySearchResults";
  clickstream: SearchClickstreamEvents;
  criteria: PropertySearchCriteria;
  dialogErrorResponse?: Maybe<LodgingDialogErrorResponse>;
  dynamicMap?: Maybe<EgDynamicMap>;
  errorResponse?: Maybe<LodgingErrorResponse>;
  /** @deprecated This is to support the comet exit intent without impacting the migration effort */
  exitIntent?: Maybe<ExitIntent>;
  /** @deprecated This is to support the native apps. Do not use, replace with sortAndFilter */
  filterMetadata: PropertyFilterMetadata;
  legalDisclaimer?: Maybe<DisclaimerContentMarkup>;
  /** @deprecated Use dynamicMap instead */
  map?: Maybe<PropertySearchMap>;
  mapTrigger: LodgingMapTrigger;
  multiItemSearchContext?: Maybe<MultiItemSearchContext>;
  navigationTabs?: Maybe<NavigationTabs>;
  packageProductCard?: Maybe<PackageProductCard>;
  pagination?: Maybe<LodgingPagination>;
  /** @deprecated Use propertySearchListings */
  properties: Array<Property>;
  propertyListingAdaptexAnalyticsSuccessEvents: Array<LodgingAdaptExAnalyticsEvent>;
  /** @deprecated For use with the experimental enhanced neighborhood filter experience */
  propertyListingsToast?: Maybe<EgdsToast>;
  propertySearchListings: Array<PropertySearchListing>;
  searchCriteria: SearchCriteria;
  shoppingContext?: Maybe<ShoppingContext>;
  shoppingSearchListingHeader: Array<ShoppingSearchListingHeader>;
  sortAndFilter: SortAndFilter;
  summary: PropertyResultsSummary;
  universalSortAndFilter: ShoppingSortAndFilters;
};

export type PropertySectionHeading = EgdsSectionHeading & {
  __typename?: "PropertySectionHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type PropertySectionRef =
  | "AVAILABILITY_CALENDAR_START_DATE"
  | "PRICE_DISPLAY_SERVICE_RESPONSE"
  | "RECOMMENDED_OFFER"
  | "ROOMS_AND_RATES";

export type PropertySentiment = {
  __typename?: "PropertySentiment";
  subtext?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type PropertyShopOptions = {
  __typename?: "PropertyShopOptions";
  listings?: Maybe<Listings>;
  paandiRedirection?: Maybe<Scalars["String"]["output"]>;
  points?: Maybe<Points>;
  typeaheadRegionType?: Maybe<Scalars["Int"]["output"]>;
};

export interface PropertyShopOptionsInput {
  listings?: InputMaybe<Listings>;
  paandiRedirection?: InputMaybe<Scalars["String"]["input"]>;
  points?: InputMaybe<Points>;
  typeaheadRegionType?: InputMaybe<Scalars["Int"]["input"]>;
}

export type PropertySort =
  | "AARP_MEMBER_DISCOUNT"
  | "BEST_DEAL"
  | "DEPARTURE_EARLY_TO_LATE"
  | "DEPARTURE_LATE_TO_EARLY"
  | "DISTANCE"
  | "DURATION_LONG_TO_SHORT"
  | "DURATION_SHORT_TO_LONG"
  | "MAXIMUM_SAVINGS"
  | "MAXIMUM_SAVINGS_PERCENT"
  | "MEMBER_PRICING"
  | "PACKAGE_SAVINGS"
  | "PRICE_HIGH_TO_LOW"
  | "PRICE_LOW_TO_HIGH"
  | "PRICE_RELEVANT"
  | "PROPERTY_CLASS"
  | "PROPERTY_NAME"
  | "RECOMMENDED"
  | "REVIEW"
  | "REVIEW_COUNT"
  | "REVIEW_PRICE"
  | "REVIEW_RELEVANT"
  | "VACATION_RENTAL";

export type PropertySource = AccessibilityField & {
  __typename?: "PropertySource";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  label?: Maybe<Scalars["String"]["output"]>;
  text: LodgingPlainMessage;
};

export type PropertyStarRating = EgdsRating & {
  __typename?: "PropertyStarRating";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field will be replaced with ratingIcon field, so we can support more than two icons */
  isStarRating: Scalars["Boolean"]["output"];
  rating: Scalars["Float"]["output"];
  ratingIcon: Scalars["String"]["output"];
};

export type PropertyStyle = "BOUTIQUE" | "HOMES" | "HOTELS" | "LGBT" | "LUXURY" | "OUTDOOR" | "UNIQUE";

export type PropertySummary = {
  __typename?: "PropertySummary";
  amenities?: Maybe<PropertyAmenities>;
  /** @deprecated Deprecating for amenities.top, icons to be passed directly rather than amenity categories, replace with amenities.top */
  amenityCategories: Array<PropertyAmenityCategory>;
  /** @deprecated No longer used, replace with amenities */
  amenitySections: Array<PropertyAmenitySection>;
  /** @deprecated Deprecating for bannerMessageCard for bannerMessageCard that uses the standardized EGDSStandardMessagingCard type, replace with bannerMessageCard */
  bannerMessage?: Maybe<MessageResult>;
  bannerMessageCard?: Maybe<EgdsStandardMessagingCard>;
  cleaningAndSafety?: Maybe<PropertyInfoContent>;
  detailsTitle?: Maybe<Scalars["String"]["output"]>;
  featuredMessages?: Maybe<Array<LodgingEnrichedMessage>>;
  fees?: Maybe<PropertyFees>;
  /** @deprecated Intent: Move to Reviews when orchestration permits. */
  findAnswersDialog?: Maybe<FindAnswersDialog>;
  findAnswersMessage?: Maybe<FindAnswersMessage>;
  id: Scalars["String"]["output"];
  latinAlphabetName: Scalars["String"]["output"];
  location?: Maybe<PropertyLocation>;
  lodgingChatbot?: Maybe<LodgingChatbot>;
  map?: Maybe<PropertyInfoMap>;
  messages: Array<PropertySummaryMessages>;
  name: Scalars["String"]["output"];
  navigationBarTabs?: Maybe<Array<PropertyNavigationBarTab>>;
  nearby?: Maybe<PropertyInfoSection>;
  nearbyPOIs?: Maybe<PropertyInfoContent>;
  overview?: Maybe<PropertyOverview>;
  /** @deprecated No longer used, replace with propertyContentSectionGroups.policies */
  policies?: Maybe<PropertyPolicies>;
  propertyHeaderMessages?: Maybe<Array<HeaderMessage>>;
  propertySource?: Maybe<PropertyInfoItem>;
  /** @deprecated Use featuredMessages */
  propertyTypeBadge?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated No longer used, replace with propertyContentSectionGroups.policies.sections */
  renovations?: Maybe<PropertyRenovations>;
  spaceOverview?: Maybe<PropertyInfoContent>;
  starRatingIcon?: Maybe<Icon>;
  structure?: Maybe<StructureType>;
  tagline?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated No longer used, replace with propertyInfo.telesales */
  telesalesPhoneNumber: Scalars["String"]["output"];
  /** @deprecated Only use for TnL 49135 */
  vipAccessMinimizeModule?: Maybe<PropertyLoyaltyMessage>;
};

export type PropertySummaryAmenitiesArgs = {
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertySummaryMapArgs = {
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type PropertySummaryMessages = EgdsPlainText;

export type PropertyTakeoverAmenities = {
  __typename?: "PropertyTakeoverAmenities";
  amenityClosures?: Maybe<PropertyInfoContent>;
  highlight: Array<PropertyInfoContent>;
  property: Array<PropertyInfoContent>;
  propertyHeader?: Maybe<LodgingHeader>;
};

export type PropertyTopReviews = {
  __typename?: "PropertyTopReviews";
  critical?: Maybe<PropertyReview>;
  highlighted?: Maybe<LodgingEnrichedMessage>;
  positive?: Maybe<PropertyReview>;
};

export type PropertyTravelAdTrackingInfo = {
  __typename?: "PropertyTravelAdTrackingInfo";
  beaconIssued?: Maybe<Scalars["String"]["output"]>;
  candidateHmGuid?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["String"]["output"]>;
  rank?: Maybe<Scalars["String"]["output"]>;
  slots?: Maybe<Scalars["String"]["output"]>;
  testVersionOverride?: Maybe<Scalars["String"]["output"]>;
  trackingData: Scalars["String"]["output"];
};

export interface PropertyTravelAdTrackingInfoInput {
  beaconIssued?: InputMaybe<Scalars["String"]["input"]>;
  candidateHmGuid?: InputMaybe<Scalars["String"]["input"]>;
  position?: InputMaybe<Scalars["String"]["input"]>;
  rank?: InputMaybe<Scalars["String"]["input"]>;
  slots?: InputMaybe<Scalars["String"]["input"]>;
  testVersionOverride?: InputMaybe<Scalars["String"]["input"]>;
  trackingData: Scalars["String"]["input"];
}

export type PropertyTypesCarouselSection = {
  __typename?: "PropertyTypesCarouselSection";
  carousel: EgdsCarousel;
  heading: EgdsHeading;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  subHeading?: Maybe<EgdsHeading>;
};

export type PropertyUnavailableValue = {
  __typename?: "PropertyUnavailableValue";
  code: Scalars["Int"]["output"];
  reason: Scalars["String"]["output"];
};

export type PropertyUnit = AccessibilityField & {
  __typename?: "PropertyUnit";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  availabilityCallToAction?: Maybe<AvailabilityCallToAction>;
  baseFeatures: Array<PropertyInfoItem>;
  /** @deprecated This data is now contained in the schema for components where it's used, e.g features, detailsDialog */
  bedOptions: Array<BedOptions>;
  bookingUrl?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  detailsDialog?: Maybe<LodgingDialog>;
  features: Array<PropertyInfoItem>;
  /** @deprecated Deprecated in favor of more flexible PropertyImageGallery type, replace with unitGallery */
  gallery: Array<PropertyImage>;
  header?: Maybe<LodgingHeader>;
  id: Scalars["String"]["output"];
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  lodgingReview?: Maybe<LodgingReviewSection>;
  matchingFilterIds?: Maybe<Array<Scalars["String"]["output"]>>;
  maxOccupancy?: Maybe<UnitOccupancy>;
  /** @deprecated Deprecated in favor of more flexible LodgingHeader type, replace with header.text */
  name?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of more flexible PropertyImageGallery type, replace with unitGallery */
  photos: Array<Image>;
  ratePlans: Array<RatePlan>;
  ratePlansExpando: RatePlansExpando;
  roomAmenities?: Maybe<PropertyContentSection>;
  roomHighlights?: Maybe<PropertyInfoContent>;
  roomHighlightsV2?: Maybe<HighlightedAttributesSection>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
  size?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated - use spaceDetails */
  sleepingArrangement?: Maybe<SleepingArrangement>;
  spaceDetails?: Maybe<SpaceDetails>;
  /** @deprecated Deprecated in favor of availabilityCallToAction, replace with availabilityCallToAction */
  unavailableRatePlans: Array<RatePlan>;
  unitGallery?: Maybe<PropertyImageGallery>;
};

export type PropertyUnitCard = {
  __typename?: "PropertyUnitCard";
  agencyPropertyUnitKey: AgencyPropertyUnitKey;
  cancellationPolicy: AgencyQuoteSwitchableMessage;
  detailsLink?: Maybe<EgdsStandardLink>;
  extras?: Maybe<EgdsTextSection>;
  messages?: Maybe<Array<EgdsText>>;
  priceSummary: AgencyQuoteSwitchablePriceSummary;
  propertyAmenities?: Maybe<Array<EgdsIconText>>;
  propertyGallery: Array<Image>;
  propertyName: EgdsHeading;
  propertyRating?: Maybe<EgdsRating>;
  regulatoryMessages?: Maybe<Array<EgdsText>>;
  removeRateLink?: Maybe<EgdsStandardLink>;
  roomFeatures?: Maybe<Array<EgdsIconText>>;
  roomSummary: EgdsTextSection;
  tripSummary: EgdsTextSection;
};

export type PropertyUnitCardFailure = UnitCard & {
  __typename?: "PropertyUnitCardFailure";
  message: EgdsText;
  removeLink?: Maybe<EgdsStandardLink>;
};

export type PropertyUnitCardSuccess = UnitCard & {
  __typename?: "PropertyUnitCardSuccess";
  agencyPropertyUnitKey: AgencyPropertyUnitKey;
  cancellationPolicy: AgencyQuoteSwitchableMessage;
  confirmationDialog?: Maybe<ConfirmationDialog>;
  detailsLink?: Maybe<EgdsStandardLink>;
  extras?: Maybe<EgdsTextSection>;
  messages?: Maybe<Array<EgdsText>>;
  packageRateDisclaimer?: Maybe<DisclaimerMessage>;
  priceSummary: AgencyQuoteSwitchablePriceSummary;
  propertyAmenities?: Maybe<Array<EgdsIconText>>;
  propertyGallery: Array<Image>;
  propertyName: EgdsHeading;
  propertyRating?: Maybe<EgdsRating>;
  regulatoryMessages?: Maybe<Array<EgdsText>>;
  removeLink?: Maybe<EgdsStandardLink>;
  roomFeatures?: Maybe<Array<EgdsIconText>>;
  roomSummary: EgdsTextSection;
  tripSummary: EgdsTextSection;
};

export type PropertyUnitDetails = {
  __typename?: "PropertyUnitDetails";
  baseDialogFeatures?: Maybe<EgdsTextIconList>;
  details?: Maybe<PropertyInfoSection>;
  dialogFeatures?: Maybe<EgdsTextIconList>;
  gallery: Array<PropertyImage>;
  ratePlanStickyBars: Array<RatePlanStickyBar>;
  ratePlanTitle?: Maybe<LodgingHeader>;
  ratePlans: Array<RatePlan>;
  /** @deprecated Sleeping Arrangements details for properties with multiple units. Use PropertyUnit.spaceDetails */
  sleepingArrangement?: Maybe<SleepingArrangement>;
};

export type PropertyUnitDetailsDialog = LodgingDialog & {
  __typename?: "PropertyUnitDetailsDialog";
  content: PropertyUnitDetails;
  toolbar?: Maybe<LodgingDialogToolbar>;
  trigger: LodgingDialogTriggerMessage;
};

export type PropertyWhatsAround = {
  __typename?: "PropertyWhatsAround";
  editorial?: Maybe<PropertyEditorial>;
  expando?: Maybe<LodgingExpando>;
  /** @deprecated Do not use; deprecating in favour of expando., replace with expando */
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  /** @deprecated The summary structure should be refactored, prevent use that structure on future implementation */
  mapDialog?: Maybe<LodgingMapDialog>;
  mapTrigger?: Maybe<LodgingMapTrigger>;
  nearbyPOIs: Array<PropertyInfoContent>;
  /** @deprecated Replaced by 'mapTrigger' field. */
  staticImage?: Maybe<Image>;
  /** @deprecated Replaced by 'staticImage' field. */
  staticMapSrc?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type Protection = {
  __typename?: "Protection";
  captchaProtection?: Maybe<CaptchaProtection>;
  csrfProtection?: Maybe<CsrfProtection>;
  isInitialized: Scalars["Boolean"]["output"];
  placementName: Scalars["String"]["output"];
  protectionSetId: Scalars["String"]["output"];
};

export type ProtectionsInitializedResponse = {
  __typename?: "ProtectionsInitializedResponse";
  error?: Maybe<ErrorResponse>;
  protectionsInitialized?: Maybe<Array<Protection>>;
};

export interface PushNotificationPreferencesCompletenessModuleRequestInput {
  isPushDeviceSettingsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  promptDeniedDate?: InputMaybe<Scalars["String"]["input"]>;
  promptDeniedTimes?: InputMaybe<Scalars["Int"]["input"]>;
}

export type QualtricsConfig = {
  __typename?: "QualtricsConfig";
  zoneId: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  SupplyConnectivityProviders: SupplyConnectivityProvidersResponse;
  SupplyConnectivitySettingProviderService: SupplyConnectivitySettingProviderServiceView;
  SupplyConnectivitySettings: SupplyConnectivitySettingsExperience;
  abandonedCheckout?: Maybe<AbandonedCheckout>;
  abandonedCheckoutPriceDetails?: Maybe<AbandonedCheckoutPriceDetails>;
  aboutThisProduct?: Maybe<ShoppingProductContentSectionGroup>;
  acceleratorCoaching: AcceleratorCoaching;
  acceptRequestToJoinTrip: TripsUiAcceptRequestToJoinTripResponse;
  accessibilityContent?: Maybe<ContentSectionDetails>;
  accountMerge: AccountMerge;
  activeSessionsForm: ProfileAccountFormComponent;
  activityCrossSellSearch: ActivityCrossSellSearchResults;
  activityInfo: ActivityInfo;
  activityModules: ActivityModulesResponse;
  activityReviews: ActivityReviewsInfo;
  activitySearch: ActivitySearchResults;
  activitySeoContent: ActivitySeoContent;
  adGroupTypeaheadForm?: Maybe<AdsPortalAdGroupFocusTypeahead>;
  adGroupsTable?: Maybe<AdsPortalAdGroupTableResponse>;
  adTable?: Maybe<AdsPortalAdTableResponse>;
  adsPortalTable?: Maybe<CampaignOverviewTableResponse>;
  adsPortalTypeaheadForm?: Maybe<CampaignOverviewFocusTypeahead>;
  advertiserPortalOnboarding: AdvertiserPortalOnboarding;
  advertiserReportingForm: AdvertiserReportingForm;
  advertiserReportingTable: AdvertiserReportingTableResponse;
  affiliatesAccount: AffiliatesAccountResponse;
  affiliatesCreatorTooling: AffiliatesCreatorToolingResponse;
  affiliatesInitiateAccountLink: AffiliatesInitiateAccountLinkResponse;
  affiliatesStateCheck: AffiliatesStateCheckResponse;
  affiliatesStats: AffiliatesStatsResponse;
  affiliatesStripe?: Maybe<AffiliatesStripeResponse>;
  affiliatesTravelerCollectionDetails: AffiliatesTravelerCollectionDetailsResponse;
  affiliatesTravelerShopDetails: AffiliatesTravelerShopDetailsResponse;
  affiliatesVideo: AffiliatesVideoResponse;
  agencyConfirmationDialog: ConfirmationDialog;
  agencyQuoteBuilder: AgencyQuoteBuilderResponse;
  agencyQuoteInfo: AgencyQuoteDetails;
  agencyQuoteList: AgencyQuoteListResponse;
  agentQuoteInfo: AgentQuoteDetails;
  agentToolsLodgingCostBreakdown: AgentToolsLodgingCostBreakdown;
  agentToolsSummaryCard: AgentToolsSummaryCard;
  amenities: ShoppingProductContentSectionGroup;
  amountPaidQuery: AmountPaidView;
  annualSummary?: Maybe<AnnualSummaryResult>;
  annualSummaryHome?: Maybe<AnnualSummaryHomeResult>;
  answeringTravellerQuestions?: Maybe<TravellerSearch>;
  aqyilaMetaData: MetadataResponse;
  associatedPropertySearch: SupplyPropertySearchResultContent;
  atAGlance?: Maybe<AtAGlance>;
  auditHistory: AuditHistoryQuery;
  bestTimeToGo?: Maybe<BestTimeToGo>;
  bookingCredits: BookingServicingCreditsResult;
  bookingDetails: BookingServiceDetails;
  bookingServicingAccept: BookingServicingAccept;
  bookingServicingCancel: BookingServiceCancel;
  bookingServicingChange: BookingServiceChange;
  bookingServicingChangeLodging: BookingServicingChangeLodging;
  bookingServicingHeadsUp: BookingServicingHeadsUpPresentation;
  bookingServicingReview: BookingServicingReviewPresentation;
  bookingServicingSearch: BookingServicingSearchPresentation;
  breadcrumbs: Breadcrumbs;
  bulkAddInventory: SupplyBulkAddInventoryResponse;
  bulkReviewAddInventory: SupplyBulkReviewAddInventoryResponse;
  campaignManagement: CampaignManagementQuery;
  campaignRecommendations?: Maybe<RecommendedCampaigns>;
  campaignTables?: Maybe<AdsPortalCampaignTableResponse>;
  campaignTypeaheadForm?: Maybe<AdsPortalCampaignFocusTypeahead>;
  campaignWizard: TopLevelCampaignWizard;
  cancellationPolicyInfo?: Maybe<LodgingCancellationPolicyInfo>;
  cancellationWaiverApproval: SupplyCancellationWaiverApprovalSection;
  cancellationWaiverDetails: SupplyCancellationWaiverCardDetails;
  carDetail: CarDetailsResult;
  carDropOffSearch: DropOffSearchResults;
  carRulesAndRestrictions: CarRulesAndRestrictionsResult;
  carSearchOrRecommendations: CarsSearchResultsWrapper;
  /** @deprecated DO NOT USE: Will be removed permanently soon */
  cart: Cart;
  categoricalRecommendationsTabs: IncentivesCollection;
  changeOffers: ChangeOfferDetails;
  channelInformation: AgentToolsChannelInformation;
  chatGPTGetConversationByTrip: ChatGptExperienceGetConversationResult;
  chatbotConfig?: Maybe<ChatbotConfig>;
  checkZuora: MessageResponse;
  checkout: CheckoutDetails;
  checkoutAccountCreation: CheckoutAccountCreationResponse;
  clickToClaimToast?: Maybe<ClickToClaimToast>;
  coBrandBanner: CoBrandBannerData;
  collectionFlightsCarousel: IncentivesCollection;
  collectionLodgingCarousel: IncentivesCollection;
  collectionPackagesCarousel: IncentivesCollection;
  communicationCenter: CommunicationCenterQuery;
  communicationCenterEntryPoint: CommunicationCenterEntryPoint;
  communicationCenterPreview: CommunicationCenterPreview;
  comparableDeals: ComparableDealsResultsWrapper;
  confirmationSummary: ConfirmationSummary;
  consentMgmtPage: ConsentMgmt;
  /** @deprecated Query has been migrated, use offersContactHost instead */
  contactHost?: Maybe<ContactHostSection>;
  contactUsOptions: ContactUsView;
  contractTerms: SupplyContractTerms;
  couponCampaign?: Maybe<CouponCampaign>;
  couponRedemption: CouponRedemption;
  couponsAndCredits: CouponsAndCredits;
  couponsTermsAndConditions: TermsAndConditionsContent;
  creativeForm: CreativeFormQuery;
  creditCardAccountPlacement?: Maybe<CreditCardAccountPlacement>;
  creditCardApplicationForm?: Maybe<CreditCardApplicationForm>;
  creditCardApplicationFormSdk?: Maybe<CreditCardApplicationFormSdk>;
  creditCardApplicationOfferScreen?: Maybe<CreditCardApplicationOfferScreen>;
  creditCardApplicationPlacement?: Maybe<CreditCardApplicationPlacement>;
  creditCardApplicationPrefillScreen?: Maybe<CreditCardApplicationPrefillScreen>;
  creditCardApplicationSdkEarlyExitDialogs?: Maybe<Array<CreditCardApplicationSdkEarlyExitDialog>>;
  creditCardApplicationThankYouScreen?: Maybe<CreditCardApplicationThankYouScreen>;
  creditCardAwarenessPlacement?: Maybe<CreditCardAwarenessPlacement>;
  creditCardBenefitsSection?: Maybe<CreditCardBenefitsSection>;
  creditCardComparisonHeader?: Maybe<CreditCardComparisonHeader>;
  creditCardComparisonTableSection?: Maybe<CreditCardComparisonTableSection>;
  creditCardEncodedContent?: Maybe<CreditCardEncodedContent>;
  creditCardErrorScreen?: Maybe<CreditCardErrorScreen>;
  creditCardFAQSection?: Maybe<CreditCardFaqSection>;
  creditCardFraudOTPScreen?: Maybe<CreditCardFraudOtpScreen>;
  creditCardIllustrationSection?: Maybe<CreditCardIllustrationSection>;
  creditCardIneligibleMessageBanner?: Maybe<CreditCardIneligibleMessageBanner>;
  creditCardOneKeyCashSection?: Maybe<CreditCardOneKeyCashSection>;
  creditCardRewardsActivity?: Maybe<CreditCardRewardsActivity>;
  creditCardRewardsBenefits?: Maybe<CreditCardRewardsBenefits>;
  creditCardTermsAndConditions?: Maybe<CreditCardTermsAndConditions>;
  creditRedemptionInsurtechOffers?: Maybe<CreditRedemptionInsurtechOffersResponse>;
  crossSell: CrossSellQueries;
  crosslinkList?: Maybe<CrossLinkList>;
  crosslinkLocations?: Maybe<CrossLinkLocations>;
  crosslinksGroups?: Maybe<CrossLinksGroups>;
  cruiseBundleDetails: CruiseBundleDetailsResults;
  cruiseConsultation: CruiseConsultationResults;
  cruiseOrderSummaryRoomUnavailableView: CruiseOrderSummaryRoomUnavailableViewResults;
  cruisePromotionsTermsAndConditionsDetails: CruisePromotionsTermsAndConditionsResult;
  cruiseRates: CruiseOrderSummaryRatesResults;
  cruiseSearch: CruiseSearchResults;
  customer: Customer;
  customerProfile: CustomerProfile;
  dashboardHeader: SupplyDashboardHeader;
  dealsGroup?: Maybe<DealsGroupResponse>;
  dealsMessagingCard?: Maybe<DealsMessagingCard>;
  deferredPaymentReport?: Maybe<DeferredPaymentReport>;
  deleteAccountForm: ProfileAccountFormComponent;
  destinationBestTimeToGoInformation?: Maybe<DestinationBestTimeToGoInformation>;
  destinationCategory?: Maybe<DestinationRecommendationGroup>;
  destinationExpertTips: DestinationExpertTips;
  destinationFrequentlyAskedQuestions?: Maybe<DestinationFrequentlyAskedQuestionsResponse>;
  destinationHeading?: Maybe<DestinationHeading>;
  destinationHeadingTip?: Maybe<DestinationHeadingTip>;
  destinationHero?: Maybe<TravelExperienceContent>;
  destinationHeroMedia: DestinationHeroMedia;
  destinationInfoPreview?: Maybe<DestinationInfoPreviewResponse>;
  destinationInsights: DestinationInsights;
  destinationNeighborhoods?: Maybe<DestinationRecommendationGroup>;
  destinationPriceInsights?: Maybe<DestinationPriceInsights>;
  destinationPropertiesCarousel?: Maybe<LodgingCarouselContainer>;
  destinationRecommendation?: Maybe<DestinationRecommendationGroup>;
  destinationThemeVacationsCarousel: DestinationThemeVacationsCarousel;
  destinationWishlist: DestinationWishlist;
  diagnoseError?: Maybe<Scalars["String"]["output"]>;
  directFeedbackPromptById?: Maybe<DirectFeedbackPrompt>;
  discoverDestinations?: Maybe<DestinationRecommendationGroup>;
  discoveryAlternativeLinkCardGroup: DiscoveryAlternativeLinkCardGroup;
  discoveryOverlayModule: DiscoveryOverlay;
  /** @deprecated Use interactions top level query instead */
  downloadInteractions: Scalars["String"]["output"];
  dynamicCardAndroidParse?: Maybe<DynamicCard>;
  egLabsTripCollaborationOnboardingScreen?: Maybe<TripCollaborationOnboardingScreenResponse>;
  egMagazine?: Maybe<EgMagazineResponse>;
  encodeOfferToken: OfferTokenDetails;
  evcManagement: SupplyReservationsEvcManagerContent;
  evcManagementCardData: SupplyReservationsEvcManagerSearchResults;
  evcManagementTableData: SupplyReservationsEvcManagerTable;
  eventsRecommendation: TripsUiEventsRecommendationsResponse;
  experienceScoreInfo?: Maybe<ExperienceScoreInfo>;
  exploreAllGuides?: Maybe<DestinationRecommendationGroup>;
  exploreDestination?: Maybe<ExploreResults>;
  exploreGuides?: Maybe<ExploreGuidesResponse>;
  fastTrack: FastTrackResponse;
  fastTrackDomain: FastTrackDomain;
  fastTrackLSCS: FastTrackResponse;
  fastTrackSetup?: Maybe<FastTrackSetup>;
  fcrHeadsUpQuery: FlightsCreditRedemptionHeadsUpContentResponse;
  financeWidget: SupplyPerformanceSummaryFinanceWidget;
  flexibleSearch?: Maybe<FlightsFlexibleSearchResponse>;
  flightDestinationInsights: FlightDestinationInsights;
  flightInsights: FlightInsights;
  flightPreferencesForm: ProfileAccountFormComponent;
  flightsAirlineCredits: FlightsAirlineCreditsResponse;
  flightsAncillary: FlightsAncillaryResponse;
  /** @deprecated This is a stop-gap solution for finding the sum total of bag selections on merchandising hub.The ideal approach is to let backend figure out the price of selected bags using identifiers and not expose this operation to client.Refer https://jira.expedia.biz/browse/AIRMRCH-1453 for details. */
  flightsBaggagePriceCalculations: FlightsArithmeticResponse;
  flightsDetail: FlightsInformationResponse;
  flightsFareCalendar?: Maybe<FlightsFareCalendarResponse>;
  flightsFinancialAdjustmentForm: FlightsFinancialAdjustmentFormView;
  flightsListings: FlightsSmartShoppingListingsResults;
  flightsMerchandising: FlightsMerchandisingResponse;
  flightsMerchandisingCheckout: FlightsMerchandisingCheckoutResponse;
  flightsMerchandisingConfirmation: FlightsMerchandisingConfirmationResponse;
  flightsMerchandisingDetailsCard?: Maybe<FlightsMerchandisingDetailsCard>;
  /** @deprecated Placards info will be find on specific queries */
  flightsPlacard: FlightsPlacardResponse;
  flightsPriceCalendar?: Maybe<EgdsPriceCalendar>;
  flightsSample: FlightsFederationSampleResponse;
  flightsSearch: FlightsSearchResponse;
  flightsSearchMetadata: FlightsMetadata;
  flightsServicing: FlightsServicingResponse;
  flightsServicingConfirmation: FlightsServicingConfirmationResponse;
  flightsUserAuthentication: UserAuthenticationResponse;
  foo: Foo;
  frequentlyAskedQuestions?: Maybe<Array<Maybe<TravelExperienceContent>>>;
  gdsBannerQuery: GdsBannerView;
  generateNumber: Scalars["Int"]["output"];
  geoLocation?: Maybe<GeoLocationResult>;
  getAgentPresence: AgentPresence;
  getAllCampaigns: Array<Campaign>;
  getAuditHistoryDataTableView?: Maybe<AuditHistoryDataTableView>;
  getDefaultIcon: Icon;
  /** @deprecated Improper naming, use evcManagementCardData instead */
  getEvcCardData: SupplyReservationsEvcManagerSearchResults;
  /** @deprecated Improper naming, use evcManagementTableData instead */
  getEvcTableData: SupplyReservationsEvcManagerTable;
  getMigrateTestingDummy: MigrateTestingDummy;
  getPropertyImages: PropertyImagesGraphQlResponse;
  getSupplyDismissibleCard: SupplyDismissibleCard;
  getSupplyOnboardingPageTitle?: Maybe<SupplyOnboardingPageTitle>;
  getTravelerEmail: Scalars["String"]["output"];
  getTravelerPhoneNumber: Scalars["String"]["output"];
  getUploadLogoStatus: UploadLogoStatusResponse;
  globalFooter: Footer;
  globalHeader: Header;
  googleOneTap?: Maybe<IdentityResponse>;
  hello: Scalars["String"]["output"];
  helloShowcase: Scalars["String"]["output"];
  helloShowcaseWithGraphQLContext: Scalars["String"]["output"];
  helloWithGraphQLContext: Scalars["String"]["output"];
  helloWithGraphQLContextaqyilatransformerexperienceapi: Scalars["String"]["output"];
  helloWithGraphQLContextexperienceapiadvertiser: Scalars["String"]["output"];
  helloWithGraphQLContextexperiencetest: Scalars["String"]["output"];
  helloaqyilatransformerexperienceapi: Scalars["String"]["output"];
  helloexperienceapiadvertiser: Scalars["String"]["output"];
  helloexperiencetest: Scalars["String"]["output"];
  hellotestsubgraph: Scalars["String"]["output"];
  helpCenterPersonalization: HelpCenterComponentsView;
  hotelDetailsCard: HotelDetailsCard;
  identityAddIdentifierByOTPForm: IdentityAddIdentifierByOtpFormResponse;
  identityAddPhoneCredentialForm: IdentityAddPhoneFormResponse;
  identityAuthenticateByOTPForm: IdentityAuthenticateByOtpFormResponse;
  identityAuthenticateByPasswordForm: IdentityAuthenticateByPasswordFormResponse;
  identityLayout: IdentityResponse;
  identityRefresh?: Maybe<IdentityRefreshResponse>;
  identityUserAuthenticatorsList: IdentityUserAuthenticatorsListResponse;
  importantInfo: ImportantInfoDetails;
  incentivesCollection: IncentivesCollection;
  incentivesCollectionView: IncentivesCollectionView;
  incentivesJourney: IncentivesJourney;
  initializeProtectionsExperience: ProtectionsInitializedResponse;
  installmentPlan?: Maybe<InstallmentPlanResponse>;
  insuranceBenefitDetails?: Maybe<InsuranceBenefitDetailsQueryResponse>;
  insuranceBookingOverview?: Maybe<InsuranceBookingOverviewResponse>;
  insuranceBookingOverviewError?: Maybe<InsuranceBookingOverviewErrorResponse>;
  insuranceClaimDetails?: Maybe<InsuranceClaimDetailsResponse>;
  insuranceDetailPriming?: Maybe<InsuranceDetailPrimingCard>;
  insuranceFileAClaim?: Maybe<InsuranceFileAClaimResponse>;
  insurtechConfirmationPage: InsurtechConfirmationModule;
  insurtechDialog?: Maybe<InsurtechDialog>;
  insurtechPageLevelError: InsurtechPageLevelErrorResponse;
  insurtechPayment?: Maybe<InsurtechPaymentModule>;
  insurtechPriming: InsurtechPrimingQuery;
  insurtechPrimingCard: InsurtechPrimingCardResponse;
  insurtechProductCollection: InsurtechProductCollection;
  insurtechShopping: InsurtechShoppingResponse;
  insurtechShoppingWithViewResolution?: Maybe<InsurtechShoppingResponseTemplate>;
  /** @deprecated Use interactions top level query instead */
  interactionList: SupplyReservationsInteractionView;
  interactionMessages: SupplyReservationsInteractionMessagesResponse;
  interactions: SupplyReservationsInteractionResponse;
  inviteToTripSheet: TripsUiInviteToTripSheetResponse;
  isSiteWLT: Scalars["Boolean"]["output"];
  itineraryItems?: Maybe<TripItemsResponse>;
  itineraryMap?: Maybe<TripMapResponse>;
  knowYourCustomer: KnowYourCustomerQuery;
  landingAlternativeAirports: LandingAlternativeAirportsResponse;
  landingDiscoveryModule?: Maybe<LandingDiscoveryModule>;
  landingFeaturedSuppliers: LandingFeaturedSuppliers;
  landingFlightDeals: LandingFlightDealsGroups;
  landingPackagesListing?: Maybe<DiscoveryItemsGroup>;
  latestActivitySignal?: Maybe<LatestActivitySignal>;
  /** @deprecated Only to be used by iOS to move from legacy to bex-api, will be removed in future */
  legacyWidget: WidgetResponse;
  legal: LegalDetails;
  legalDisclaimer: DisclaimerContent;
  localePreferences: Picker;
  location: ProductLocation;
  locationScoutOverlayQuery: LocationScoutOverlayResponse;
  lodgingBookingPlayback?: Maybe<LodgingBookingPlayback>;
  lodgingCancelForm: LodgingCancelForm;
  lodgingChangeForm?: Maybe<LodgingChangeForm>;
  lodgingChangeLanding: LodgingChangeLanding;
  lodgingDestination: LodgingDestinationResults;
  lodgingRefundForm?: Maybe<LodgingRefundView>;
  lodgingTripUpgrades?: Maybe<LodgingTripUpgrades>;
  logoManager: LogoManagerResponse;
  /** @deprecated Only used for the legacy loyalty experience.  Will be replaced in the near future with the memberWallet query */
  loyalty: Loyalty;
  loyaltyAccountSummary?: Maybe<LoyaltyAccountSummary>;
  loyaltyBlockingLaunchScreens: Array<LoyaltyBlockingLaunchScreen>;
  loyaltyEarnCalculationDetails?: Maybe<LoyaltyEarnCalculationDetailsCard>;
  loyaltyInitialOnboardingScreens: Array<LoyaltyInitialOnboardingScreen>;
  loyaltyLegacyOnboarding: Array<LoyaltyLegacyOnboardingScreen>;
  loyaltyRewards: LoyaltyRewardsResults;
  loyaltyRewardsActivity?: Maybe<LoyaltyRewardsActivity>;
  loyaltyTierProgression: LoyaltyTierProgression;
  loyaltyTripAttach: LoyaltyTripAttach;
  manageAccelerator: AcceleratorManageComponents;
  managedBannerContent?: Maybe<ManagedBannerContentResponse>;
  mapConfig: EgdsDynamicMapConfig;
  memberMessageCards?: Maybe<Array<PartnerMemberMessageCard>>;
  memberProfile?: Maybe<MemberProfile>;
  memberWallet: MemberWallet;
  merchCoupons?: Maybe<MerchCoupons>;
  messageModule: MessageModuleDetails;
  mojoLandingSection?: Maybe<MojoLandingSection>;
  mojoSection?: Maybe<MojoSection>;
  mreQuery?: Maybe<MreContents>;
  multiItemStepIndicator: StepIndicator;
  nearbyAirportsSuggestion?: Maybe<NearbyAirportsSuggestion>;
  notification: NotificationQuery;
  occupantsIncludedOptions: Array<SupplyRatePlanSelectFieldOption>;
  offersContactHost?: Maybe<OffersContactHostSection>;
  offersRecommendationByProduct?: Maybe<OffersRecommendationGroup>;
  offersTravelerSelector?: Maybe<OffersTravelerSelector>;
  /** @deprecated Deprecated in favor of supplyOnboardingPropertyRatesExperience */
  onboardingPropertyRatesExperience: SupplyOnboardingPropertyRatesExperience;
  oneIdentityOnboarding?: Maybe<OneIdentityOnboardingResponse>;
  oneKeyCheckoutBurn?: Maybe<OneKeyCheckoutBurn>;
  oneKeyLoyaltyBanner?: Maybe<OneKeyLoyaltyBanner>;
  oneKeyLoyaltyMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  oneKeyOnboarding: Array<OneKeyOnboardingSheet>;
  oneKeyUniversalOnboarding: Array<OneKeyUniversalOnboardingScreen>;
  /** @deprecated Work in progress: Do not use! Still subject to breaking changes */
  packageDestination: PackageDestinationQueries;
  packageDetails: MultiItemDetailsQuery;
  packagePrimers: PackagePrimersQueries;
  packageSearchResults: PackageSearchResultsQueries;
  partnerLoyaltyPoints: PartnerLoyaltyPoints;
  passportsForm: ProfileAccountFormComponent;
  passwordStrengthMeter: PasswordWithStrengthMeter;
  payment: PaymentQuery;
  paymentAssist: AgentToolsPaymentAssist;
  paymentDetails: SupplyPaymentDetails;
  paymentModule: PaymentModule;
  penaltiesAndRestrictions: PenaltiesAndRestrictions;
  performanceOverviewCampaignSelection: PerformanceOverviewCampaignSelectionResponse;
  performanceOverviewCarousel: PerformanceOverviewCarouselResponse;
  performanceOverviewChart: PerformanceOverviewChartResponse;
  performanceSummary: SupplyPerformanceSummary;
  personalInfoForm: ProfileComponent;
  personalizedModuleList: DiscoveryPersonalizedModuleList;
  personalizedOffersRecommendation?: Maybe<PersonalizedOffersRecommendations>;
  placeCardDetails?: Maybe<PlaceCardDetails>;
  playgroundCurrencyFormat?: Maybe<PlaygroundCurrencyFormat>;
  playgroundDateTimeFormat?: Maybe<PlaygroundDateTimeFormat>;
  playgroundDevice?: Maybe<PlaygroundDevice>;
  playgroundLocation?: Maybe<PlaygroundLocation>;
  playgroundSiteContext?: Maybe<PlaygroundSiteContext>;
  playgroundUser?: Maybe<PlaygroundUser>;
  policies: Policies;
  postContentOverlay: PostContentOverlayQueryResponse;
  preBundledPackageEntry: PreBundledPackageEntryQueries;
  preBundledPackageSearch: PreBundledPackageSearchResponse;
  preBundledPackageSearchEntry?: Maybe<PreBundledPackageSearchEntryResponse>;
  preBundledPackageSearchEntryCard?: Maybe<PreBundledPackageSearchEntryCardResponse>;
  preBundledPackageSearchForm: PackageSearchForm;
  priceDetails: PriceDetails;
  priceInsights?: Maybe<PriceInsights>;
  priceMatchPromise?: Maybe<PriceMatchPromiseDetails>;
  productDescription?: Maybe<ProductDescriptionContent>;
  productGallery?: Maybe<ProductImageGallery>;
  productGroup?: Maybe<GenericProductGroup>;
  productHeadline?: Maybe<ProductHeadline>;
  productHighlights?: Maybe<ProductHighlights>;
  /** @deprecated Only for Hackathon purposes, do not use */
  productListingCarousels?: Maybe<ProductCarouselResults>;
  productNavigationBar?: Maybe<ProductNavigationBar>;
  productQuestions?: Maybe<ProductQuestions>;
  productRatingSummary?: Maybe<ProductRating>;
  productReviewDetails?: Maybe<ProductReviewDetails>;
  productReviewSummary?: Maybe<SummarizedReviews>;
  productShoppingCrossSellDetails?: Maybe<LodgingCarouselContainer>;
  productSummary?: Maybe<ProductDetailsLayout>;
  propertyActivitiesCrossSell?: Maybe<PropertyActivitiesCrossSellDetails>;
  propertyAvailabilityCalendars?: Maybe<Array<AvailabilityCalendar>>;
  propertyCompare?: Maybe<LodgingPropertyCompare>;
  propertyFilter: SupplyPropertyFilterSection;
  propertyFilterIntentSheet: FilterIntentSheet;
  propertyInfo: PropertyInfo;
  propertyOffers?: Maybe<OfferDetails>;
  propertyRatesDateSelector: PropertyRatesDateSelector;
  propertyReview?: Maybe<PropertyReview>;
  propertyReviewAggregates: PropertyReviewAggregates;
  propertyReviewInfo: PropertyReviews;
  propertyReviewSummaries: Array<PropertyReviewSummary>;
  propertySearch: PropertySearchResults;
  propertySpaces?: Maybe<PropertyContentSectionGroup>;
  propertyTopReviews?: Maybe<PropertyTopReviews>;
  propertyTypesCarousel?: Maybe<PropertyTypesCarouselSection>;
  publicItineraryHeader?: Maybe<TripsUiPublicItineraryHeaderResponse>;
  qualtricsConfig?: Maybe<QualtricsConfig>;
  quickActionSheetFromTemplate: SupplyQuickActionSheet;
  randomAccessOne: RandomAccessOneContentResponse;
  randomAccessOneAPIDiscovery: RandomAccessOneApiDiscovery;
  randomAccessOneDomain: RandomAccessOneDomain;
  randomAccessOneSetup?: Maybe<RandomAccessOneSetup>;
  reasonCodes: ReasonCodesView;
  recentActivitiesModule?: Maybe<DiscoveryItemsGroup>;
  recentlyViewedPropertiesCarousel?: Maybe<LodgingCarouselContainer>;
  recommendationCarousel?: Maybe<DestinationChatCarousel>;
  recommendationsModule?: Maybe<DiscoveryItemsGroup>;
  redirectBanner?: Maybe<RedirectBanner>;
  refundCreditsLedger: RefundCreditsLedger;
  regulatoryContent: RegulatoryContentDetails;
  relevantContent?: Maybe<RelevantContent>;
  requestContext: ClientRequestContext;
  requestToJoinTrip?: Maybe<TripsUiRequestToJoinTripResponse>;
  requestToJoinTripSheet: TripsUiRequestToJoinTripSheetResponse;
  reservationDetails: SupplyReservationsDetailsContent;
  reservationModifiers: SupplyReservationModifiers;
  retailTravelAgentNotificationCenter: RetailTravelAgentNotificationResult;
  retailTravelAgentRewards?: Maybe<RetailTravelAgentRewardsResult>;
  reviewQuestions: ReviewsCollectionForm;
  reviewsOverview?: Maybe<ReviewsOverview>;
  rewardProgramsForm: ProfileAccountFormComponent;
  rulesAndRestrictions: RnRDetails;
  sailingCabinCategories: SailingCabinCategories;
  sailingCancellation: SailingCancellationResults;
  sailingDeals: SailingDeals;
  sailingDetails: SailingDetailsResults;
  sailingOffers: CruiseOffersResults;
  savedCardDetails: SavedCardDetails;
  savedCardSelect: AgentToolsSavedCardSelect;
  searchForm: SearchFormResults;
  searchFormPriceInsights: SearchFormPriceInsightsResults;
  searchHelpArticles: HelpArticlesResultsView;
  searchLocation?: Maybe<SearchLocationResults>;
  serviceAutomation: ServiceAutomationQuery;
  shopInsurtechOffers: ShopInsurtechOffersResponse;
  shoppingPathPrimers: MultiItemDetailsPrimersQuery;
  shortlist: Shortlist;
  similarDestinations?: Maybe<DestinationRecommendationGroup>;
  /** @deprecated DO NOT USE: TNL 40644 is being rolled-back, clean-up instruction here https://confluence.expedia.biz/display/EWM/Similar+Properties+with+Deals */
  similarPropertiesDeals: SimilarPropertiesDeals;
  siteConfigurations: Array<SiteConfiguration>;
  smartForm: SmartFormQuery;
  socialConnections: SocialConnectionsResponse;
  socialShareAction: SocialShareActionResponse;
  socialShareButton: SocialShareButtonResponse;
  socialShareSheet: SocialShareSheetResponse;
  sponsoredContent: SponsoredContentQuery;
  sponsoredPropertiesCarousel?: Maybe<LodgingCarouselContainer>;
  staticMultiItemCrossSell: StaticMultiItemCrossSellResponse;
  stepReviewInlineEditingSheet?: Maybe<StepReviewInlineEditingSheet>;
  supplyAmenitiesGroup: SupplyAmenitiesGroup;
  supplyAttributesAdminExperience: SupplyAttributesAdminExperience;
  supplyAutoCancelWaiver: SupplyAutoCancelWaiver;
  supplyBookingOptions?: Maybe<SupplyBookingOptions>;
  supplyBusinessPartnerInformation: SupplyPartnerInformation;
  supplyCampaign?: Maybe<SupplyCampaign>;
  supplyCancelPolicyExceptions: SupplyCancelPolicyExceptions;
  supplyCancellationWaiverDecline: SupplyCancellationWaiverDeclineFlowContent;
  supplyCoachingActionPanel?: Maybe<SupplyCoachingActionPanel>;
  supplyCoachingTipBlockTabs?: Maybe<SupplyCoachingTipBlockTabs>;
  supplyCoachingTipCounts?: Maybe<SupplyCoachingTipCounts>;
  supplyCoachingTipPropertyFinderSearch: SupplyPropertyFinderSearchResponse;
  supplyCoachingTipsBlock: SupplyCoachingTipsBlock;
  supplyCoachingTipsPostponeForm: SupplyCoachingTipPostponeForm;
  supplyCoachingTipsPropertyFinder: SupplyCoachingTipPropertyFinder;
  supplyCoachingTipsResultDetail: SupplyCoachingTipResultDetails;
  supplyCoachingTipsResults: SupplyCoachingTipsResults;
  supplyContentAdditionalInfo: SupplyContentAdditionalInfo;
  supplyContentCoachingActionPanel: SupplyContentCoachingActionPanel;
  supplyContentGenAIHeadlineDescription: SupplyContentGenAiHeadlineDescription;
  supplyContentHeadlineDescription: SupplyContentHeadlineDescription;
  supplyContentHomePage?: Maybe<SupplyContentHomePage>;
  supplyContentHub?: Maybe<SupplyContentHub>;
  supplyContentHubNavigation?: Maybe<SupplyContentHubNavigation>;
  supplyContentLocationSharing: SupplyContentLocationSharing;
  supplyContentManualTranslationSetting: SupplyContentManualTranslationSettingDialog;
  supplyContentSaveButton: SupplyContentSaveButton;
  supplyContentScore?: Maybe<SupplyContentScoreResp>;
  supplyContentYourProperty: SupplyContentYourProperty;
  supplyContentYourStory: SupplyContentYourStory;
  supplyContractingAgreement: SupplyContractingAgreementExperience;
  supplyContractingLicensePlate: SupplyContractingLicensePlate;
  supplyContractingProgramEnrollment: SupplyContractingProgramEnrollment;
  supplyDashboardAdditionalDestinations: SupplyNavigationDestinations;
  supplyDashboardReservationsOverview: SupplyReservationOverview;
  supplyDeleteAccount: SupplyDeleteAccount;
  supplyFees: SupplyFees;
  supplyHelpContactUs: SupplyHelpContactUs;
  supplyHomepage: SupplyHomepage;
  supplyHomepageGuestExperience: CtResponse;
  supplyIndirectTax: SupplyIndirectTax;
  supplyLoyalty?: Maybe<SupplyLoyalty>;
  supplyMarketMakerGrid: SupplyMarketMakerGrid;
  supplyMarketplaceFeed: SupplyMarketplaceFeed;
  supplyMarketplaceFeedGetAll: SupplyMarketplaceFeed;
  supplyMultiFactorAuthenticationView: SupplyMultiFactorAuthenticationExperience;
  supplyNavigation: SupplyNavigation;
  supplyNavigationAsyncNotificationsButton?: Maybe<SupplyNavigationHeaderButton>;
  supplyNavigationLinkItemBadge: SupplyNavigationItemLinkBadge;
  supplyNavigationNotification: SupplyNavigationNotificationContent;
  supplyNavigationPropertyDetails: SupplyNavigationMenuContent;
  supplyNavigationReservationSearchContent: SupplyNavigationReservationSearchContent;
  supplyNavigationUserImpersonationBanner?: Maybe<SupplyNavigationUserImpersonationBanner>;
  supplyOnboardingAvailability?: Maybe<SupplyOnboardingAvailability>;
  supplyOnboardingNavigationHeader?: Maybe<SupplyOnboardingNavigationHeader>;
  supplyOnboardingPaymentTermsAndConditions: SupplyOnboardingPaymentTermsAndConditionsView;
  supplyOnboardingPropertyRatesExperience: SupplyOnboardingPropertyRatesExperience;
  supplyOnboardingPublishListing?: Maybe<SupplyOnboardingPublishListing>;
  supplyOnboardingReadiness?: Maybe<SupplyOnboardingReadiness>;
  supplyOnboardingReview?: Maybe<SupplyOnboardingReviewExperience>;
  supplyOnboardingTipCard: SupplyOnboardingTipCard;
  supplyOnboardingUnfinishedSteps?: Maybe<StepReviewList>;
  supplyOnboardingWelcome?: Maybe<SupplyOnboardingWelcome>;
  supplyOnboardingWorkFlowNavigation?: Maybe<SupplyOnboardingWorkFlowNavigation>;
  supplyOpportunityHubLicensePlate?: Maybe<SupplyOpportunityHubLicensePlate>;
  supplyPCRoomManagementWorkflow: SupplyPcRoomManagementWorkflow;
  supplyPageLevelBanner: SupplyPageLevelBanner;
  supplyPartnerSelect: SupplyPartnerSelectResponse;
  supplyPartnerSignUpEstimateResults: SupplyPartnerSignUpEstimateResults;
  supplyPhotoAssignExperience: SupplyPhotoAssignExperience;
  supplyPhotoSpacesExperience: SupplyPhotoSpacesExperience;
  supplyPhotoUploaderExperience: SupplyPhotoUploaderExperience;
  supplyPhotosExperience: SupplyPhotosExperience;
  supplyProductCreationExperience: SupplyProductCreationExperience;
  supplyProductCreationPreviewRates?: Maybe<SupplyProductCreationPreviewRates>;
  supplyProductCreationPreviewRoomName: SupplyPreviewRoomNameResult;
  supplyProductCreationPricingModel: SupplyProductCreationPricingModel;
  supplyProductCreationRatePlanSideSheet: SupplyProductCreationRatePlanSideSheet;
  supplyProductCreationRoomInfoExperience: SupplyProductCreationRoomInfoExperience;
  supplyProductCreationRoomNameExperience: SupplyProductCreationRoomNameExperience;
  supplyProductCreationRoomOverviewExperience: SupplyProductCreationRoomOverviewExperience;
  supplyProductCreationTipCard: SupplyProductCreationTipCard;
  supplyProgramsEnrollment: SupplyProgramsEnrollmentExperience;
  supplyPromotionCopyTieredMembersOnlyDeal: SupplyPromotionCreateTieredMembersOnlyDeal;
  supplyPromotionCreateTieredMembersOnlyDeal: SupplyPromotionCreateTieredMembersOnlyDeal;
  supplyPromotionPlayground: SupplyPromotionPlayground;
  supplyPromotionPlaygroundGetBestDiscount: SupplyPromotionPlaygroundResult;
  supplyPromotionStackingOptionsSheet: SupplyPromotionStackingOptionsSheet;
  supplyPromotionUpdateTieredMembersOnlyDeal: SupplyPromotionUpdateTieredMembersOnlyDeal;
  supplyPropertyEditorPage?: Maybe<SupplyPropertyEditorPage>;
  supplyQuickActionFromTemplate: SupplyQuickActionFromTemplateResponse;
  supplyQuickActionSubmitPanelFromTemplate: SupplyQuickActionSubmitPanelFromTemplateResponse;
  supplyRateAvailabilityObservations: SupplyRateAvailabilityObservations;
  supplyRatePlan: SupplyRatePlan;
  supplyRatePlanRightRail: SupplyRatePlanRightRail;
  supplyRegulatoryFlow: SupplyRegulatoryFlow;
  supplyRegulatoryFlowThirdPartyValidation: SupplyRegulatoryFlowThirdPartyValidation;
  /** @deprecated Move title to SupplyRegulatoryFlow and deprecated SupplyRegulatoryFlowTitle */
  supplyRegulatoryFlowTitle: SupplyRegulatoryFlowTitle;
  supplyReservationsBookingRequest: SupplyReservationsBookingDetailsContent;
  supplyReservationsDeclineRequest: SupplyReservationsDeclineContent;
  supplyReservationsHeader: SupplyReservationsHeader;
  supplyReviews: SupplyReviewsExperience;
  supplyRoomTypeMapping: SupplyRoomTypeMappingExperience;
  supplyRoomTypeMappingStatusCard: SupplyRoomTypeMappingStatusCard;
  supplySafetyDevicesConfirm: SupplySafetyDevicesConfirm;
  supplyScoreBenefitsLadder: SupplyScoreBenefitsLadder;
  supplyScoreDetails: SupplyScoreDetails;
  supplyScoreExternalModuleOverview: SupplyScoreExternalModuleOverview;
  supplyScoreFactorAuditTrail: SupplyScoreFactorAuditTrail;
  supplyTaxes: SupplyTaxes;
  supplyUserProfile: SupplyUserProfileQuery;
  supplyUserSettingsDashboardHeader: SupplyUserSettingsDashboardHeaderResponse;
  /** @deprecated use travelTriviaBanner */
  sweepstakesBanner: SweepstakesBannerQuery;
  sweepstakesDashboard: SweepstakesDashboardQuery;
  sweepstakesUserActionHistoryQuery: SweepstakesUserActionHistoryQuery;
  /** @deprecated This function is being deprecated as we are renaming it to a more descriptive name */
  termsAndConditions: TermsAndConditionsContent;
  testAirlineEntityService: Scalars["String"]["output"];
  thingsToDo?: Maybe<DestinationRecommendationGroups>;
  topRatedAttribute?: Maybe<ExperienceScoreInfo>;
  topTasks: SupplyTopTask;
  travelAgentAuthenticationOptions: TravelAgentAuthenticationOptionsResponse;
  travelAgentDashboard?: Maybe<TravelAgentDashboard>;
  /** @deprecated This does not facilitate server-driven UI, not a good basis for future schema. */
  travelAgentSearchWizardConfig?: Maybe<TravelAgentSearchWizardConfig>;
  travelContentEditorial?: Maybe<Array<Maybe<TravelExperienceContent>>>;
  travelExperienceContent?: Maybe<Array<Maybe<TravelExperienceContent>>>;
  travelExperienceManagerContent: TravelExperienceContentResults;
  travelGuideRecommendation?: Maybe<DestinationRecommendationGroup>;
  travelShopCollections: TravelShopCollectionsResponse;
  travelStoryCarousel?: Maybe<TravelStoryCarousel>;
  travelStoryVlogs?: Maybe<Array<TravelStoryVlog>>;
  travelTriviaBanner?: Maybe<TravelTriviaBannerQuery>;
  traveladsPageBody: TraveladsBody;
  traveladsPageHeader: TraveladsHeader;
  travelerCoupons: TravelerCoupons;
  /** @deprecated Use travelerSelectorInput instead. */
  travelerSelector?: Maybe<TravelerSelectorForm>;
  travellerSearch?: Maybe<TravellerSearchResultsResponse>;
  trip: Trip;
  tripAssistGoal: TripAssistGoalResponse;
  tripCollaborationChatEntryPoint?: Maybe<TripCollaborationChatEntryPointResponse>;
  tripCollaborationChatEntryPointButton?: Maybe<TripCollaborationChatEntryPointButtonResponse>;
  tripCollaborationChatHeader?: Maybe<TripCollaborationChatHeaderResponse>;
  tripCustomerSupport: TripsCustomerSupportResponse;
  tripExperienceTemplate: TripItemTemplateResponse;
  tripItemCardContextualContent?: Maybe<TripsUiItemCardContextualContentResponse>;
  tripItemCommentsAndVotes: TripsUiCommentsAndVotesResponse;
  tripItemContextualCards: TripItemContextualCardsResponse;
  tripItemImageGallery: TripsUiImageGalleryResponse;
  tripItemPrice: TripsUiItemPriceResponse;
  tripItems?: Maybe<TripItemsResponse>;
  tripItinerary: TripsUiItineraryResponse;
  tripManageExternalItems?: Maybe<TripsUiManageExternalItemsResponse>;
  tripManageParticipants: TripsUiManageParticipantsResponse;
  tripMap?: Maybe<TripMapResponse>;
  tripOverviewTabs: TripsUiOverviewTabs;
  tripPlanning: TripsUiPlanningResponse;
  tripSaveItem: TripsUiSaveItemResponse;
  trips: Trips;
  tripsAttachSavings?: Maybe<TripsUiAttachSavings>;
  tripsCancellationTimeline: TripsCancellationTimelineResponse;
  tripsChatGPTEntrypoint?: Maybe<TripsUiChatGptEntrypointResponse>;
  tripsCollection: TripsCollection;
  tripsForOfflineView: TripsForOfflineViewResponse;
  tripsMultiItemPurchase?: Maybe<TripsUiAttachSavingsBanner>;
  tripsNotAuthorizedView: TripsNotAuthorizedView;
  tripsPageHeader: TripsUiPageHeaderResponse;
  tripsPrimer: TripsUiPrimerQuery;
  tripsReviewCollection: TripsReviewCollectionResponse;
  tripsWebView: TripsWebView;
  triviaHowTo: TriviaHowToQuery;
  tsaForm: ProfileComponent;
  universalProfile: UniversalProfileResponse;
  /** @deprecated Use interactions top level query instead */
  upcomingInteractions: SupplyReservationsInteractionsUpcomingView;
  updateJourneyContinuationId: UpdateJourneyContinuationIdResponse;
  updatePayment?: Maybe<UpdatePaymentView>;
  userProfileNextWizard: ProfileWizardComponent;
  vacChatContent?: Maybe<VirtualAgentControlChatContent>;
  vacInboundMessageAndroidParse?: Maybe<VirtualAgentControlAndroidEvent>;
  vacOrchestrator: VacOrchestrator;
  valuePropositionBanner?: Maybe<ValuePropositionBanner>;
  versions: Array<Maybe<Spinner>>;
  videoExperienceCarousal?: Maybe<VideoExperienceCarousalResponse>;
  videoPostOverlay: VideoPostOverlayResponse;
  virtualAgentControl?: Maybe<VirtualAgentControl>;
  virtualAgentControlChatbotPopup: VirtualAgentControlPopup;
  virtualAgentControlCoachmark: VirtualAgentControlCoachmark;
  virtualAgentControlConfiguration: VirtualAgentControlConfig;
  virtualAgentControlGetSurvey: VirtualAgentControlGetSurveyResult;
  virtualAgentControlMessages?: Maybe<VirtualAgentControlMessages>;
  voiceOfTheCustomer: VoiceOfTheCustomer;
  voiceOfTheCustomerOptional?: Maybe<VoiceOfTheCustomer>;
  waiverSectionQuery: WaiverSectionView;
  walletFAQSection?: Maybe<WalletFaqScreen>;
  walletLedgerScreen?: Maybe<WalletLedgerScreen>;
  worker: WorkerInfo;
};

export type QuerySupplyConnectivityProvidersArgs = {
  connectedProviderIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  context: ContextInput;
  propertyContext: PropertyContext;
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySupplyConnectivitySettingProviderServiceArgs = {
  context: ContextInput;
  newProviderId?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  serviceIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  stepName: Scalars["String"]["input"];
  workflow?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySupplyConnectivitySettingsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryAbandonedCheckoutArgs = {
  context: ContextInput;
};

export type QueryAbandonedCheckoutPriceDetailsArgs = {
  abandonedCheckoutId: Scalars["String"]["input"];
  context: ContextInput;
};

export type QueryAboutThisProductArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryAcceleratorCoachingArgs = {
  context: ContextInput;
  propertyContext: PropertyContextInput;
};

export type QueryAcceptRequestToJoinTripArgs = {
  context: ContextInput;
  requestToJoinId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type QueryAccessibilityContentArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryAccountMergeArgs = {
  accountMergeRequest?: InputMaybe<AccountMergeRequestContextInput>;
  context: ContextInput;
};

export type QueryActiveSessionsFormArgs = {
  context: ContextInput;
};

export type QueryActivityCrossSellSearchArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
  filters?: InputMaybe<ActivitySearchFiltersInput>;
  pinnedActivityIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  selectedOffers?: InputMaybe<Array<ActivityOfferNaturalKeyInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
};

export type QueryActivityInfoArgs = {
  activityId: Scalars["String"]["input"];
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
  detailsViewOptions?: InputMaybe<Array<ActivityDetailViewOptions>>;
  marketing?: InputMaybe<ActivityMarketingInfoInput>;
  offerFilters?: InputMaybe<ActivityOfferFilterInput>;
  packageType?: InputMaybe<PackageType>;
  priceOptions?: InputMaybe<Array<ActivitySearchOptions>>;
  selectedOffers?: InputMaybe<Array<ActivityOfferNaturalKeyInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
  travelerDetails?: InputMaybe<Array<ActivityTravelerDetailsInput>>;
  viewOptions?: InputMaybe<Array<ActivitySearchOptions>>;
};

export type QueryActivityModulesArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
};

export type QueryActivityReviewsArgs = {
  activityId: Scalars["String"]["input"];
  context: ContextInput;
  pagination?: InputMaybe<PaginationInput>;
  selections?: InputMaybe<Array<ActivitySelectedValueInput>>;
  shoppingPath?: InputMaybe<ShoppingPathType>;
};

export type QueryActivitySearchArgs = {
  context: ContextInput;
  dateRange?: InputMaybe<ActivityDateRangeInput>;
  destination?: InputMaybe<ActivityDestinationInput>;
  filters?: InputMaybe<ActivitySearchFiltersInput>;
  groupType?: InputMaybe<Array<ActivityGroupType>>;
  marketing?: InputMaybe<ActivityMarketingInfoInput>;
  pagination?: InputMaybe<PaginationInput>;
  searchOptions?: InputMaybe<Array<ActivitySearchOptions>>;
  searchViewOptions?: InputMaybe<Array<ActivitySearchViewOptions>>;
  sort?: InputMaybe<ActivitySort>;
  travelerInfo?: InputMaybe<ActivityTravelerInfoInput>;
};

export type QueryActivitySeoContentArgs = {
  activityId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  pageType: ActivityPageType;
};

export type QueryAdGroupTypeaheadFormArgs = {
  campaignId?: InputMaybe<Scalars["UUID"]["input"]>;
  productContext: ProductContext;
};

export type QueryAdGroupsTableArgs = {
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
};

export type QueryAdTableArgs = {
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
};

export type QueryAdsPortalTableArgs = {
  campaignId?: InputMaybe<Scalars["UUID"]["input"]>;
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
  tableInput: TableInput;
};

export type QueryAdsPortalTypeaheadFormArgs = {
  campaignId?: InputMaybe<Scalars["UUID"]["input"]>;
  formInput: FormInput;
  productContext: ProductContext;
};

export type QueryAdvertiserReportingFormArgs = {
  reportingContext: AdvertiserReportingContext;
};

export type QueryAdvertiserReportingTableArgs = {
  reportingContext: AdvertiserReportingContext;
  reportingOptions: AdvertiserReportingOptionsInput;
};

export type QueryAffiliatesAccountArgs = {
  context: ContextInput;
};

export type QueryAffiliatesCreatorToolingArgs = {
  context: ContextInput;
};

export type QueryAffiliatesInitiateAccountLinkArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAffiliatesStateCheckArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAffiliatesStatsArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesStatsRequestInput;
};

export type QueryAffiliatesStripeArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAffiliatesTravelerCollectionDetailsArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesTravelerCollectionDetailsRequestInput;
};

export type QueryAffiliatesTravelerShopDetailsArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
  request: AffiliatesTravelerShopDetailsRequestInput;
};

export type QueryAffiliatesVideoArgs = {
  clientContext: AffiliatesClientContextInput;
  context: ContextInput;
};

export type QueryAgencyConfirmationDialogArgs = {
  confirmationDialogInput: ConfirmationDialogInput;
  context: ContextInput;
};

export type QueryAgencyQuoteBuilderArgs = {
  context: ContextInput;
};

export type QueryAgencyQuoteInfoArgs = {
  agencyServiceCharge?: InputMaybe<AgencyServiceChargeInput>;
  cacheId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  naturalKey?: InputMaybe<AgencyQuoteNaturalKeyInput>;
  quoteId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryAgencyQuoteListArgs = {
  context: ContextInput;
  retrieveInput: AgencyQuoteListInput;
};

export type QueryAgentQuoteInfoArgs = {
  context: ContextInput;
  naturalKey: AgentQuoteNaturalKeyInput;
};

export type QueryAgentToolsLodgingCostBreakdownArgs = {
  bookingItemId: Scalars["String"]["input"];
  changeAction?: InputMaybe<AgentToolsLodgingActionInput>;
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryAgentToolsSummaryCardArgs = {
  airProviderLocator?: InputMaybe<Scalars["String"]["input"]>;
  airTicketNumberList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  bookingItemId?: InputMaybe<Scalars["String"]["input"]>;
  changeAction?: InputMaybe<AgentToolsLodgingActionInput>;
  componentType: AgentToolsSummaryCardComponentType;
  context: ContextInput;
  futureTravelCredit?: InputMaybe<AgentToolsFutureTravelCreditInput>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  paymentAmount?: InputMaybe<Scalars["Float"]["input"]>;
  penaltyWaiver?: InputMaybe<AgentToolsPenaltyWaiverInput>;
  refundAmount?: InputMaybe<Scalars["Float"]["input"]>;
};

export type QueryAmenitiesArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryAmountPaidQueryArgs = {
  amountPaidInput: AmountPaidInput;
  context: ContextInput;
};

export type QueryAnnualSummaryArgs = {
  context: ContextInput;
};

export type QueryAnnualSummaryHomeArgs = {
  context: ContextInput;
};

export type QueryAnsweringTravellerQuestionsArgs = {
  context: ContextInput;
  productIdentifier?: InputMaybe<ProductIdentifierInput>;
};

export type QueryAqyilaMetaDataArgs = {
  aqyilaMetaDataRequest: MetaDataRequestInput;
};

export type QueryAssociatedPropertySearchArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  keyword: Scalars["String"]["input"];
  temporaryPartnerContextOverride?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryAtAGlanceArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryAuditHistoryArgs = {
  context?: InputMaybe<ContextInput>;
};

export type QueryBestTimeToGoArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryBookingCreditsArgs = {
  context: ContextInput;
};

export type QueryBookingDetailsArgs = {
  context: ContextInput;
};

export type QueryBookingServicingAcceptArgs = {
  context: ContextInput;
  criteria: BookingServicingAcceptFlightInput;
};

export type QueryBookingServicingCancelArgs = {
  context: ContextInput;
};

export type QueryBookingServicingChangeArgs = {
  context: ContextInput;
};

export type QueryBookingServicingChangeLodgingArgs = {
  context: ContextInput;
};

export type QueryBookingServicingHeadsUpArgs = {
  context: ContextInput;
};

export type QueryBreadcrumbsArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]["input"]>;
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryBulkAddInventoryArgs = {
  context: ContextInput;
  opportunityId?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type QueryBulkReviewAddInventoryArgs = {
  addInventoryInput: BulkAddInventoryInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryCampaignManagementArgs = {
  campaignId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCampaignRecommendationsArgs = {
  campaignIds?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  campaignParameters?: InputMaybe<CampaignParametersInput>;
  context: ContextInput;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
  destinationImageDimensions?: InputMaybe<DestinationImageDimensionsInput>;
  displayPropertyCount?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableLivePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  enableTripAttach?: InputMaybe<Scalars["Boolean"]["input"]>;
  includeDeals?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxNumberOfCampaigns?: InputMaybe<Scalars["Int"]["input"]>;
  modulePlacement?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCampaigns?: InputMaybe<Scalars["Int"]["input"]>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  productType?: InputMaybe<Array<InputMaybe<CampaignProductType>>>;
  recommendationType?: InputMaybe<Array<InputMaybe<RecommendedType>>>;
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  userLocation?: InputMaybe<UserLocationInput>;
  userTripState?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCampaignTablesArgs = {
  productContext: ProductContext;
  sortOptions: CampaignOverviewTableSortingInput;
};

export type QueryCampaignTypeaheadFormArgs = {
  productContext: ProductContext;
};

export type QueryCancellationPolicyInfoArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"]["input"];
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryCancellationWaiverApprovalArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: SupplyCancellationWaiverReservationContext;
};

export type QueryCancellationWaiverDetailsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: SupplyCancellationWaiverReservationContext;
};

export type QueryCarDetailArgs = {
  carDetailContext: CarDetailContextInput;
  carsDomainRequestContext?: InputMaybe<CarsDomainRequestContextInput>;
  context: ContextInput;
};

export type QueryCarDropOffSearchArgs = {
  context: ContextInput;
  primaryCarSearchCriteria: PrimaryCarCriteriaInput;
  secondaryCriteria: ShoppingSearchCriteriaInput;
};

export type QueryCarRulesAndRestrictionsArgs = {
  carRulesAndRestrictionsCriteria: CarRulesAndRestrictionsCriteriaInput;
  context: ContextInput;
};

export type QueryCarSearchOrRecommendationsArgs = {
  context: ContextInput;
  primaryCarSearchCriteria: PrimaryCarCriteriaInput;
  secondaryCriteria: ShoppingSearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryCartArgs = {
  context: ContextInput;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type QueryCategoricalRecommendationsTabsArgs = {
  collectionId: Scalars["String"]["input"];
  context: ContextInput;
};

export type QueryChangeOffersArgs = {
  changeType?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  orderLineNumber?: InputMaybe<Scalars["String"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria: PropertySearchCriteriaInput;
  tripId: Scalars["String"]["input"];
};

export type QueryChannelInformationArgs = {
  channelTypeOverride?: InputMaybe<AgentToolsChannelType>;
  context: ContextInput;
  flow: AgentToolsPaymentFlow;
  paymentAssistSessionId: Scalars["String"]["input"];
};

export type QueryChatGptGetConversationByTripArgs = {
  context: ContextInput;
  getConversationInput: ChatGptExperienceGetConversationInput;
};

export type QueryChatbotConfigArgs = {
  applicationName: Scalars["String"]["input"];
  context: ContextInput;
  deviceIdentifiers?: InputMaybe<ChatbotDeviceIdentifiersInput>;
  hotelId?: InputMaybe<Scalars["String"]["input"]>;
  intentMessage?: InputMaybe<Array<ChatbotIntentValueInput>>;
  pagePath?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCheckoutArgs = {
  checkoutURL?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
};

export type QueryCheckoutAccountCreationArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
};

export type QueryClickToClaimToastArgs = {
  context: ContextInput;
  offerCode: Scalars["String"]["input"];
};

export type QueryCoBrandBannerArgs = {
  context: ContextInput;
};

export type QueryCollectionFlightsCarouselArgs = {
  collectionGroupsPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  collectionId: Scalars["String"]["input"];
  collectionItemsPerGroupPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  context: ContextInput;
  displayFlightDealScoreBadge?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext?: InputMaybe<CollectionPaginationContextInput>;
};

export type QueryCollectionLodgingCarouselArgs = {
  collectionId: Scalars["String"]["input"];
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  context: ContextInput;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext: CollectionPaginationContextInput;
};

export type QueryCollectionPackagesCarouselArgs = {
  collectionGroupsPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  collectionId: Scalars["String"]["input"];
  collectionItemsPerGroupPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  context: ContextInput;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext: CollectionPaginationContextInput;
};

export type QueryCommunicationCenterArgs = {
  context: ContextInput;
};

export type QueryCommunicationCenterEntryPointArgs = {
  context: ContextInput;
  optionalContext?: InputMaybe<OptionalContextInput>;
};

export type QueryCommunicationCenterPreviewArgs = {
  context: ContextInput;
  optionalContext?: InputMaybe<OptionalContextInput>;
  selectedTab?: InputMaybe<Scalars["String"]["input"]>;
  unseenCountForUnselected?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryComparableDealsArgs = {
  carOfferToken: Scalars["String"]["input"];
  comparableDealsToken: Scalars["String"]["input"];
  context: ContextInput;
  primaryCarSearchCriteria: PrimaryCarCriteriaInput;
  secondaryCriteria: ShoppingSearchCriteriaInput;
};

export type QueryConfirmationSummaryArgs = {
  context: ContextInput;
  orderId: Scalars["String"]["input"];
};

export type QueryConsentMgmtPageArgs = {
  context: ContextInput;
  needEditorial: Scalars["Boolean"]["input"];
};

export type QueryContactHostArgs = {
  context: ContextInput;
  inquiryFormInput?: InputMaybe<InquiryFormInput>;
  prominence?: InputMaybe<InquiryProminence>;
};

export type QueryContactUsOptionsArgs = {
  context: ContextInput;
};

export type QueryContractTermsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryCouponCampaignArgs = {
  contentTopic: Scalars["String"]["input"];
  context: ContextInput;
  egPlatformURL: Scalars["String"]["input"];
  preview: Scalars["Boolean"]["input"];
  requestDate?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCouponRedemptionArgs = {
  checkoutURL?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  sessionId: Scalars["String"]["input"];
};

export type QueryCouponsAndCreditsArgs = {
  context: ContextInput;
  sourceReferrer?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCouponsTermsAndConditionsArgs = {
  context: ContextInput;
  couponInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  rewardId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCreditCardAccountPlacementArgs = {
  context: ContextInput;
  sourcePage: CreditCardAccountPlacementSourcePage;
  variant: CreditCardAccountPlacementVariant;
};

export type QueryCreditCardApplicationFormArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardApplicationFormQueryInput>;
};

export type QueryCreditCardApplicationFormSdkArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  landingUrl?: InputMaybe<Scalars["String"]["input"]>;
  sourcePage: CreditCardSourcePage;
};

export type QueryCreditCardApplicationOfferScreenArgs = {
  context: ContextInput;
  input: CreditCardApplicationOfferInput;
};

export type QueryCreditCardApplicationPlacementArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
};

export type QueryCreditCardApplicationPrefillScreenArgs = {
  context: ContextInput;
  input: CreditCardApplicationPrefillQueryInput;
};

export type QueryCreditCardApplicationSdkEarlyExitDialogsArgs = {
  context: ContextInput;
  sourcePage: CreditCardSourcePage;
};

export type QueryCreditCardApplicationThankYouScreenArgs = {
  context: ContextInput;
  input: CreditCardApplicationThankYouInput;
};

export type QueryCreditCardAwarenessPlacementArgs = {
  context: ContextInput;
};

export type QueryCreditCardBenefitsSectionArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardBenefitsInput>;
};

export type QueryCreditCardComparisonHeaderArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardComparisonHeaderInput>;
};

export type QueryCreditCardComparisonTableSectionArgs = {
  context: ContextInput;
  input?: InputMaybe<CreditCardComparisonTableInput>;
};

export type QueryCreditCardEncodedContentArgs = {
  context: ContextInput;
  input: CreditCardEncodedContentInput;
};

export type QueryCreditCardErrorScreenArgs = {
  context: ContextInput;
  input: CreditCardErrorScreenInput;
};

export type QueryCreditCardFaqSectionArgs = {
  context: ContextInput;
};

export type QueryCreditCardFraudOtpScreenArgs = {
  context: ContextInput;
  input: CreditCardFraudOtpInput;
};

export type QueryCreditCardIllustrationSectionArgs = {
  context: ContextInput;
};

export type QueryCreditCardIneligibleMessageBannerArgs = {
  context: ContextInput;
};

export type QueryCreditCardOneKeyCashSectionArgs = {
  context: ContextInput;
};

export type QueryCreditCardRewardsActivityArgs = {
  context: ContextInput;
};

export type QueryCreditCardRewardsBenefitsArgs = {
  context: ContextInput;
};

export type QueryCreditCardTermsAndConditionsArgs = {
  context: ContextInput;
  input: CreditCardEncodedContentInput;
};

export type QueryCreditRedemptionInsurtechOffersArgs = {
  clientId: InsurtechClientId;
  context: ContextInput;
  residencyInformation: ResidencyDetailsInput;
  tripId: Scalars["String"]["input"];
};

export type QueryCrossSellArgs = {
  context: ContextInput;
  searchContext: MultiItemSearchContextInput;
  selectedProducts?: InputMaybe<MultiItemProductsInput>;
};

export type QueryCrosslinkListArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  parameters?: InputMaybe<Array<CrossLinksParameterInput>>;
  strategyName: Scalars["String"]["input"];
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryCrosslinkLocationsArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  parameters?: InputMaybe<Array<CrossLinksParameterInput>>;
  strategyName: Scalars["String"]["input"];
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryCrosslinksGroupsArgs = {
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  parameters?: InputMaybe<Array<CrossLinksParameterInput>>;
  strategyName: Scalars["String"]["input"];
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryCruiseBundleDetailsArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  promotionCodes: Array<Scalars["String"]["input"]>;
};

export type QueryCruiseConsultationArgs = {
  adultCount?: InputMaybe<Scalars["Int"]["input"]>;
  cabinType?: InputMaybe<Scalars["String"]["input"]>;
  childCount?: InputMaybe<Scalars["Int"]["input"]>;
  context: ContextInput;
  cruiseConsultationFormInput?: InputMaybe<CruiseConsultationFormInput>;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCruiseOrderSummaryRoomUnavailableViewArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  orderSummaryRatesInput: CruiseOrderSummaryRatesInput;
};

export type QueryCruisePromotionsTermsAndConditionsDetailsArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  promotionCodesInput: Array<Scalars["String"]["input"]>;
};

export type QueryCruiseRatesArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  orderSummaryRatesInput: CruiseOrderSummaryRatesInput;
  pageDataInput?: InputMaybe<CruiseOrderSummaryRatesPageDataInput>;
};

export type QueryCruiseSearchArgs = {
  context?: InputMaybe<ContextInput>;
  searchInput: CruiseSearchCriteriaInput;
  sortBy?: InputMaybe<Scalars["String"]["input"]>;
  sortOrder?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCustomerArgs = {
  context: ContextInput;
  semdtl?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryCustomerProfileArgs = {
  context: ContextInput;
};

export type QueryDashboardHeaderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryDealsGroupArgs = {
  context: ContextInput;
  dealsShoppingCriteria: DealsShoppingCriteriaInput;
};

export type QueryDealsMessagingCardArgs = {
  context: ContextInput;
};

export type QueryDeferredPaymentReportArgs = {
  context: ContextInput;
};

export type QueryDeleteAccountFormArgs = {
  context: ContextInput;
};

export type QueryDestinationBestTimeToGoInformationArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryDestinationCategoryArgs = {
  categoryId: Scalars["String"]["input"];
  context: ContextInput;
};

export type QueryDestinationExpertTipsArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryDestinationFrequentlyAskedQuestionsArgs = {
  context: ContextInput;
  egPlatformURL: Scalars["String"]["input"];
};

export type QueryDestinationHeadingArgs = {
  context: ContextInput;
  headingCriteria: DestinationHeadingCriteriaInput;
};

export type QueryDestinationHeadingTipArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryDestinationHeroArgs = {
  context: ContextInput;
  travelContentAttributes?: InputMaybe<TravelContentAttributesInput>;
  uriContext: UriContextInput;
};

export type QueryDestinationHeroMediaArgs = {
  context: ContextInput;
  destinationHeroMediaCriteria: DestinationHeroMediaCriteriaInput;
};

export type QueryDestinationInfoPreviewArgs = {
  context: ContextInput;
  coordinates?: InputMaybe<CoordinatesInput>;
  pageType?: InputMaybe<PageType>;
};

export type QueryDestinationInsightsArgs = {
  context: ContextInput;
  regionId: Scalars["String"]["input"];
};

export type QueryDestinationNeighborhoodsArgs = {
  context: ContextInput;
  destinationNeighborhoodsContextInput?: InputMaybe<DestinationNeighborhoodsContextInput>;
};

export type QueryDestinationPriceInsightsArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryDestinationPropertiesCarouselArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  dataType?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDestinationRecommendationArgs = {
  context: ContextInput;
  recommendationCriteria: DestinationRecommendationCriteriaInput;
};

export type QueryDestinationThemeVacationsCarouselArgs = {
  affinity?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  destinationId: Scalars["String"]["input"];
};

export type QueryDestinationWishlistArgs = {
  context: ContextInput;
  wishlistPageType?: InputMaybe<WishlistPageType>;
};

export type QueryDiagnoseErrorArgs = {
  context: ContextInput;
};

export type QueryDirectFeedbackPromptByIdArgs = {
  context: ContextInput;
  id: Scalars["ID"]["input"];
};

export type QueryDiscoverDestinationsArgs = {
  context: ContextInput;
  pageType?: InputMaybe<PageType>;
};

export type QueryDiscoveryAlternativeLinkCardGroupArgs = {
  context: ContextInput;
  discoveryAlternativeLinkCardCriteria: DiscoveryAlternativeLinkCardCriteriaInput;
};

export type QueryDiscoveryOverlayModuleArgs = {
  context: ContextInput;
  placeId?: InputMaybe<Scalars["String"]["input"]>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryDownloadInteractionsArgs = {
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]["input"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type QueryEgLabsTripCollaborationOnboardingScreenArgs = {
  context: ContextInput;
};

export type QueryEgMagazineArgs = {
  context: ContextInput;
  pageType?: InputMaybe<PageType>;
};

export type QueryEncodeOfferTokenArgs = {
  propertyId: Scalars["String"]["input"];
  ratePlanId: Scalars["String"]["input"];
  startDaysInAdvance: Scalars["Int"]["input"];
  unitTypeId: Scalars["String"]["input"];
};

export type QueryEvcManagementArgs = {
  context: ContextInput;
  defaultView?: InputMaybe<SupplyReservationsEvcManagerTabType>;
  evcSearchInput?: InputMaybe<SupplyReservationsEvcSearchInput>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QueryEvcManagementCardDataArgs = {
  context: ContextInput;
  evcSearchInput: SupplyReservationsEvcSearchInput;
};

export type QueryEvcManagementTableDataArgs = {
  context: ContextInput;
  paginationInput?: InputMaybe<SupplyReservationsEvcSearchPageInput>;
  propertyContext: PropertyContext;
  tableType?: InputMaybe<SupplyReservationsEvcManagerTableType>;
};

export type QueryEventsRecommendationArgs = {
  context: ContextInput;
  eventsRecommendationRequest: EventsRecommendationRequestInput;
};

export type QueryExperienceScoreInfoArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryExploreAllGuidesArgs = {
  context: ContextInput;
  pageType?: InputMaybe<PageType>;
};

export type QueryExploreDestinationArgs = {
  context: ContextInput;
  destinationCriteria: ExploreDestinationCriteriaInput;
};

export type QueryExploreGuidesArgs = {
  context: ContextInput;
};

export type QueryFastTrackArgs = {
  context: ContextInput;
  featureId: Scalars["String"]["input"];
  productIdentifier: ProductIdentifierInput;
};

export type QueryFastTrackDomainArgs = {
  assets: Array<FastTrackProductEntityAssetSelection>;
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryFastTrackLscsArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryFastTrackSetupArgs = {
  context: ContextInput;
};

export type QueryFcrHeadsUpQueryArgs = {
  context: ContextInput;
  flightsCreditRedemptionHeadsUpInput: FlightsCreditRedemptionHeadsUpInput;
};

export type QueryFinanceWidgetArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryFlexibleSearchArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  rewardsOption?: InputMaybe<RewardsOption>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightDestinationInsightsArgs = {
  context: ContextInput;
  origin: InsightsLocationInput;
};

export type QueryFlightInsightsArgs = {
  context: ContextInput;
  destination: InsightsLocationInput;
  origin: InsightsLocationInput;
};

export type QueryFlightPreferencesFormArgs = {
  context: ContextInput;
};

export type QueryFlightsAirlineCreditsArgs = {
  context: ContextInput;
  flightsSearchContext?: InputMaybe<FlightsSearchContextInput>;
  journeyCriteria?: InputMaybe<Array<FlightsJourneyCriteriaInput>>;
  originalBookingId?: InputMaybe<Scalars["String"]["input"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelerDetails?: InputMaybe<Array<FlightsTravelerDetailsInput>>;
};

export type QueryFlightsAncillaryArgs = {
  ancillaryCriteria: FlightsAncillaryCriteriaInput;
  context: ContextInput;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsBaggagePriceCalculationsArgs = {
  arithmeticOperationsCriteria: ArithmeticOperationsCriteriaInput;
  context: ContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsDetailArgs = {
  context: ContextInput;
  flightsDetailComponentsCriteria?: InputMaybe<FlightsDetailComponentsCriteriaInput>;
  flightsDetailCriteria?: InputMaybe<FlightsDetailCriteriaInput>;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  sideSheetQueryContext?: InputMaybe<SideSheetQueryContextInput>;
  virtualAgentContext?: InputMaybe<VirtualAgentContextInput>;
};

export type QueryFlightsFareCalendarArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsFinancialAdjustmentFormArgs = {
  context: ContextInput;
  flightsFinancialAdjustmentFormInput: FlightsFinancialAdjustmentFormInput;
};

export type QueryFlightsListingsArgs = {
  context: ContextInput;
  flightSearchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  requestToast?: InputMaybe<FlightsFilterType>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  shoppingSearchCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
  sortOption?: InputMaybe<FlightsSortOptionTypeInput>;
  travelerDetails: Array<FlightsSmartShoppingTravelerDetailsInput>;
};

export type QueryFlightsMerchandisingArgs = {
  ancillaryCriteria: FlightsAncillaryCriteriaInput;
  context: ContextInput;
  queryState?: InputMaybe<FlightsQueryState>;
  requestedAncillaries?: InputMaybe<Array<FlightsAncillaryType>>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripInfoData?: InputMaybe<FlightTripInfoDataInput>;
};

export type QueryFlightsMerchandisingCheckoutArgs = {
  context: ContextInput;
  merchandisingId?: InputMaybe<Scalars["String"]["input"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"]["input"];
};

export type QueryFlightsMerchandisingConfirmationArgs = {
  context: ContextInput;
  merchandisingId?: InputMaybe<Scalars["String"]["input"]>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"]["input"];
};

export type QueryFlightsMerchandisingDetailsCardArgs = {
  context: ContextInput;
  merchandisingDetailsCardPath: MerchandisingDetailsCardPath;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  tripId: Scalars["String"]["input"];
};

export type QueryFlightsPlacardArgs = {
  context: ContextInput;
  flightsDetailComponentsCriteria?: InputMaybe<FlightsDetailComponentsCriteriaInput>;
  flightsDetailCriteria?: InputMaybe<FlightsDetailCriteriaInput>;
  flightsPlacardsTypeList: Array<FlightsPlacardType>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryFlightsPriceCalendarArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
};

export type QueryFlightsSampleArgs = {
  queryVariableA?: InputMaybe<Scalars["String"]["input"]>;
  queryVariableB?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryFlightsSearchArgs = {
  context: ContextInput;
  fareCalendarPrice?: InputMaybe<Scalars["String"]["input"]>;
  faresSeparationType?: InputMaybe<FlightsFaresSeparationType>;
  flightsDevicePermissionsInput?: InputMaybe<FlightsDevicePermissionsInput>;
  flightsDisplayComponentValues?: InputMaybe<FlightsDisplayComponentValuesInput>;
  flightsListingsViewType?: InputMaybe<FlightsListingsViewType>;
  flightsSearchComponentCriteria?: InputMaybe<FlightsSearchComponentCriteriaInput>;
  flightsSearchContext?: InputMaybe<FlightsSearchContextInput>;
  flightsSearchExperienceType?: InputMaybe<FlightsSearchExperienceType>;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  journeyCriteria: Array<FlightsJourneyCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  rewardsOption?: InputMaybe<RewardsOption>;
  searchFilterValuesList?: InputMaybe<Array<FlightsSearchFilterValuesInput>>;
  searchPagination?: InputMaybe<PaginationInput>;
  searchPreferences?: InputMaybe<FlightsSearchPreferencesInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  shoppingSearchCriteria?: InputMaybe<ShoppingSearchCriteriaInput>;
  sideSheetQueryContext?: InputMaybe<SideSheetQueryContextInput>;
  similarFlightsContext?: InputMaybe<SimilarFlightsContextInput>;
  sortOption?: InputMaybe<FlightsSortOptionTypeInput>;
  toastContext?: InputMaybe<FlightsToastContextInput>;
  travelerDetails?: InputMaybe<Array<FlightsTravelerDetailsInput>>;
  virtualAgentContext?: InputMaybe<VirtualAgentContextInput>;
};

export type QueryFlightsSearchMetadataArgs = {
  context: ContextInput;
  flightsSearchContext: FlightsSearchContextInput;
  journeyCriteria: FlightsJourneyCriteriaInput;
};

export type QueryFlightsServicingArgs = {
  context: ContextInput;
  flightsServicingCriteria: FlightsServicingCriteriaInput;
  insuranceCriteria?: InputMaybe<InsuranceCriteriaInput>;
  queryState?: InputMaybe<FlightsQueryState>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  virtualAgentContext?: InputMaybe<VirtualAgentContextInput>;
};

export type QueryFlightsServicingConfirmationArgs = {
  context: ContextInput;
  flightsServicingConfirmationCriteria: FlightsServicingConfirmationCriteriaInput;
  queryState?: InputMaybe<FlightsQueryState>;
};

export type QueryFlightsUserAuthenticationArgs = {
  ancillaryCriteria?: InputMaybe<FlightsAncillaryCriteriaInput>;
  context: ContextInput;
  shoppingPath: Scalars["String"]["input"];
  tripIdentifier: Scalars["String"]["input"];
};

export type QueryFrequentlyAskedQuestionsArgs = {
  contentTopic?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  limit: Scalars["Int"]["input"];
  uriContext?: InputMaybe<TravelContentUriContextInput>;
  uriContextV1: UriContextInput;
};

export type QueryGdsBannerQueryArgs = {
  context: ContextInput;
  gdsBannerInput: GdsBannerInput;
};

export type QueryGeoLocationArgs = {
  context: ContextInput;
  coordinates?: InputMaybe<CoordinatesInput>;
};

export type QueryGetAgentPresenceArgs = {
  context: ContextInput;
  flavor: FlavorType;
  partnerId: Scalars["String"]["input"];
  roleId: Scalars["String"]["input"];
  userId: Scalars["String"]["input"];
};

export type QueryGetAuditHistoryDataTableViewArgs = {
  paginationMetadata: PageMetadataInput;
  partnerAccountID: Scalars["String"]["input"];
  productID: Scalars["String"]["input"];
};

export type QueryGetDefaultIconArgs = {
  cardBrand?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetEvcCardDataArgs = {
  context: ContextInput;
  evcSearchInput: SupplyReservationsEvcSearchInput;
};

export type QueryGetEvcTableDataArgs = {
  context: ContextInput;
  paginationInput?: InputMaybe<SupplyReservationsEvcSearchPageInput>;
  tableType?: InputMaybe<SupplyReservationsEvcManagerTableType>;
};

export type QueryGetPropertyImagesArgs = {
  context: ContextInput;
  propertyId: Scalars["Int"]["input"];
};

export type QueryGetSupplyDismissibleCardArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryGetSupplyOnboardingPageTitleArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryGetTravelerEmailArgs = {
  paymentAssistSessionId: Scalars["String"]["input"];
};

export type QueryGetTravelerPhoneNumberArgs = {
  paymentAssistSessionId: Scalars["String"]["input"];
};

export type QueryGetUploadLogoStatusArgs = {
  context: ContextInput;
  key: Scalars["String"]["input"];
};

export type QueryGlobalFooterArgs = {
  condensed?: InputMaybe<Scalars["Boolean"]["input"]>;
  context: ContextInput;
  minimal?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryGlobalHeaderArgs = {
  context: ContextInput;
  layout?: InputMaybe<HeaderLayout>;
  lineOfBusiness?: InputMaybe<GlobalNavigationLineOfBusiness>;
  marketing?: InputMaybe<HeaderMarketingInput>;
  minimal?: InputMaybe<Scalars["Boolean"]["input"]>;
  theme?: InputMaybe<Scalars["String"]["input"]>;
  viewSize?: InputMaybe<HeaderViewSize>;
};

export type QueryGoogleOneTapArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
};

export type QueryHelloArgs = {
  name: Scalars["String"]["input"];
};

export type QueryHelloShowcaseArgs = {
  name: Scalars["String"]["input"];
};

export type QueryHelloShowcaseWithGraphQlContextArgs = {
  context: ContextInput;
  name: Scalars["String"]["input"];
};

export type QueryHelloWithGraphQlContextArgs = {
  context: ContextInput;
  name: Scalars["String"]["input"];
};

export type QueryHelloWithGraphQlContextaqyilatransformerexperienceapiArgs = {
  context: ContextInput;
};

export type QueryHelloWithGraphQlContextexperienceapiadvertiserArgs = {
  context: ContextInput;
};

export type QueryHelloWithGraphQlContextexperiencetestArgs = {
  context: ContextInput;
};

export type QueryHellotestsubgraphArgs = {
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryHelpCenterPersonalizationArgs = {
  context: ContextInput;
};

export type QueryHotelDetailsCardArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryIdentityAddIdentifierByOtpFormArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityAddIdentifierByOtpFormRequestInput;
};

export type QueryIdentityAddPhoneCredentialFormArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  request: IdentityAddPhoneFormRequestInput;
};

export type QueryIdentityAuthenticateByOtpFormArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityAuthenticateByOtpFormRequestInput;
};

export type QueryIdentityAuthenticateByPasswordFormArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityAuthenticateByPasswordFormRequestInput;
};

export type QueryIdentityLayoutArgs = {
  context: ContextInput;
  identityClientInfo?: InputMaybe<IdentityClientInfoInput>;
  layout: IdentityLayout;
  pageLocation?: InputMaybe<PageLocation>;
};

export type QueryIdentityRefreshArgs = {
  context: ContextInput;
};

export type QueryIdentityUserAuthenticatorsListArgs = {
  context: ContextInput;
  identityClientContext?: InputMaybe<IdentityClientContextInput>;
  request: IdentityUserAuthenticatorsListRequestInput;
};

export type QueryImportantInfoArgs = {
  cartId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryIncentivesCollectionArgs = {
  collectionGroupsPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  collectionId: Scalars["String"]["input"];
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  collectionItemsPerGroupPageSize?: InputMaybe<Scalars["Int"]["input"]>;
  context: ContextInput;
  displayFlightDealScoreBadge?: InputMaybe<Scalars["Boolean"]["input"]>;
  expectedCollectionType?: InputMaybe<CollectionType>;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext?: InputMaybe<CollectionPaginationContextInput>;
};

export type QueryIncentivesCollectionViewArgs = {
  collectionId: Scalars["String"]["input"];
  collectionItemClickthrough?: InputMaybe<CollectionItemClickthrough>;
  context: ContextInput;
  pageContext?: InputMaybe<PageContextInput>;
  paginationContext?: InputMaybe<CollectionPaginationContextInput>;
};

export type QueryIncentivesJourneyArgs = {
  context: ContextInput;
  selectedIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryInitializeProtectionsExperienceArgs = {
  context: ContextInput;
  placements: Array<PlacementInput>;
  platform: PlatformType;
};

export type QueryInstallmentPlanArgs = {
  context: ContextInput;
  installmentPriceAmount?: InputMaybe<Scalars["String"]["input"]>;
  multiItemSessionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryInsuranceBenefitDetailsArgs = {
  context: ContextInput;
  insuranceBenefitDetailsRequest: InsuranceBenefitDetailsQueryRequestInput;
};

export type QueryInsuranceBookingOverviewArgs = {
  context: ContextInput;
  insuranceBookingOverviewRequest: InsuranceBookingOverviewRequestInput;
};

export type QueryInsuranceBookingOverviewErrorArgs = {
  context: ContextInput;
};

export type QueryInsuranceClaimDetailsArgs = {
  context: ContextInput;
  insuranceClaimDetailsRequest: InsuranceClaimDetailsRequestInput;
};

export type QueryInsuranceDetailPrimingArgs = {
  context: ContextInput;
  insuranceDetailPrimingRequest: InsuranceDetailPrimingRequestInput;
};

export type QueryInsuranceFileAClaimArgs = {
  context: ContextInput;
  insuranceFileAClaimRequest: InsuranceFileAClaimRequestInput;
};

export type QueryInsurtechConfirmationPageArgs = {
  context: ContextInput;
  metadata?: InputMaybe<InsurtechMetadataInput>;
  placement: InsurtechComponentPositionInput;
  transactionCriteria: InsurtechTransactionCriteriaInput;
};

export type QueryInsurtechDialogArgs = {
  context: ContextInput;
  dialogId: Scalars["String"]["input"];
};

export type QueryInsurtechPageLevelErrorArgs = {
  context: ContextInput;
  errorType: InsurtechCommonErrorType;
  metadata?: InputMaybe<InsurtechMetadataInput>;
  placement: InsurtechComponentPositionInput;
  transactionCriteria: InsurtechTransactionCriteriaInput;
};

export type QueryInsurtechPaymentArgs = {
  context: ContextInput;
  insurtechComponentPosition: InsurtechComponentPositionInput;
  insurtechTransactionCriteria: InsurtechTransactionCriteriaInput;
};

export type QueryInsurtechPrimingArgs = {
  context: ContextInput;
};

export type QueryInsurtechPrimingCardArgs = {
  context: ContextInput;
  insurtechPrimingCardRequest: InsurtechPrimingCardRequestInput;
};

export type QueryInsurtechProductCollectionArgs = {
  context: ContextInput;
};

export type QueryInsurtechShoppingArgs = {
  context: ContextInput;
  insurtechViewType?: InputMaybe<InsurtechShoppingViewType>;
  metadata?: InputMaybe<InsurtechMetadataInput>;
  placement: InsurTechComponentPlacementInput;
  shoppingCriteria: InsurtechShoppingCriteriaInput;
};

export type QueryInsurtechShoppingWithViewResolutionArgs = {
  context: ContextInput;
  insurtechViewType?: InputMaybe<InsurtechShoppingViewType>;
  metadata?: InputMaybe<InsurtechMetadataInput>;
  placement: InsurTechComponentPlacementInput;
  shoppingCriteria: InsurtechShoppingCriteriaInput;
};

export type QueryInteractionListArgs = {
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]["input"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type QueryInteractionMessagesArgs = {
  clientData?: InputMaybe<SupplyReservationsInteractionMessagesClientData>;
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext?: InputMaybe<ReservationContext>;
};

export type QueryInteractionsArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]["input"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type QueryInviteToTripSheetArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
};

export type QueryIsSiteWltArgs = {
  siteId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryItineraryItemsArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"]["input"];
};

export type QueryItineraryMapArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"]["input"];
};

export type QueryKnowYourCustomerArgs = {
  context: ContextInput;
};

export type QueryLandingAlternativeAirportsArgs = {
  context: ContextInput;
  input?: InputMaybe<LandingAlternativeAirportsInput>;
  uriContext: UriContextInput;
};

export type QueryLandingFeaturedSuppliersArgs = {
  context: ContextInput;
  searchCriteria?: InputMaybe<LandingFeaturedSuppliersSearchCriteriaInput>;
  uriContext: UriContextInput;
  view?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLandingFlightDealsArgs = {
  context: ContextInput;
  maxGroupItems?: InputMaybe<Scalars["Int"]["input"]>;
  minGroupItems?: InputMaybe<Scalars["Int"]["input"]>;
  uriContext: UriContextInput;
};

export type QueryLandingPackagesListingArgs = {
  context: ContextInput;
  inputCriteria: PackagesInputCriteriaInput;
};

export type QueryLatestActivitySignalArgs = {
  context: ContextInput;
};

export type QueryLegacyWidgetArgs = {
  context: ContextInput;
};

export type QueryLegalArgs = {
  cartId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  messageId?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLegalDisclaimerArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryLocalePreferencesArgs = {
  context: ContextInput;
  lineOfBusiness?: InputMaybe<GlobalNavigationLineOfBusiness>;
  pickerTriggerPlacementType?: InputMaybe<PickerTriggerPlacementType>;
  selectedSiteId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLocationArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryLocationScoutOverlayQueryArgs = {
  context: ContextInput;
  locationScoutOverlayRequest: LocationScoutOverlayRequestInput;
};

export type QueryLodgingBookingPlaybackArgs = {
  changeType?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  offerKey?: InputMaybe<PropertyNaturalKeyInput>;
  playbackType?: InputMaybe<LodgingBookingPlaybackType>;
  tripId: Scalars["String"]["input"];
};

export type QueryLodgingCancelFormArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryLodgingChangeFormArgs = {
  bookingItemId: Scalars["String"]["input"];
  changeType: ChangeType;
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryLodgingChangeLandingArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryLodgingDestinationArgs = {
  context: ContextInput;
  destination: DestinationInput;
  filters: PropertySearchFiltersInput;
};

export type QueryLodgingRefundFormArgs = {
  bookingItemId: Scalars["String"]["input"];
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryLodgingTripUpgradesArgs = {
  context: ContextInput;
  itineraryNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLogoManagerArgs = {
  context: ContextInput;
  getUploadResponseOverride: Scalars["Boolean"]["input"];
  trackingCode: Scalars["String"]["input"];
};

export type QueryLoyaltyArgs = {
  context: ContextInput;
};

export type QueryLoyaltyAccountSummaryArgs = {
  context: ContextInput;
  strategy: LoyaltyExperienceStrategy;
  viewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLoyaltyBlockingLaunchScreensArgs = {
  context: ContextInput;
};

export type QueryLoyaltyEarnCalculationDetailsArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]["input"]>;
  checkoutURL?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  loyaltyDomainId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryLoyaltyInitialOnboardingScreensArgs = {
  context: ContextInput;
};

export type QueryLoyaltyLegacyOnboardingArgs = {
  context: ContextInput;
};

export type QueryLoyaltyRewardsArgs = {
  context: ContextInput;
};

export type QueryLoyaltyRewardsActivityArgs = {
  context: ContextInput;
  rewardFilterSelection?: InputMaybe<Scalars["String"]["input"]>;
  useHardCodedTestAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  useMockData?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryLoyaltyTierProgressionArgs = {
  context: ContextInput;
};

export type QueryLoyaltyTripAttachArgs = {
  context: ContextInput;
};

export type QueryManageAcceleratorArgs = {
  acceleratorId?: InputMaybe<Scalars["String"]["input"]>;
  acceleratorSettingsInput?: InputMaybe<AcceleratorSettingsInput>;
  context: ContextInput;
  propertyContext: PropertyContextInput;
};

export type QueryManagedBannerContentArgs = {
  contentTopic: Scalars["String"]["input"];
  context: ContextInput;
};

export type QueryMapConfigArgs = {
  context: ContextInput;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  pageLocation: PageLocation;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryMemberMessageCardsArgs = {
  context: ContextInput;
  lineOfBusiness: Scalars["String"]["input"];
  pageType: Scalars["String"]["input"];
};

export type QueryMemberProfileArgs = {
  context: ContextInput;
};

export type QueryMemberWalletArgs = {
  context: ContextInput;
};

export type QueryMerchCouponsArgs = {
  context: ContextInput;
  couponIds: Array<Scalars["String"]["input"]>;
};

export type QueryMessageModuleArgs = {
  context: ContextInput;
  location: Location;
  sessionId: Scalars["String"]["input"];
  uiSignalPayload?: InputMaybe<Array<UiSignalPayloadInput>>;
};

export type QueryMojoLandingSectionArgs = {
  context: ContextInput;
  mojoContext: MojoContextInput;
  url: Scalars["URL"]["input"];
};

export type QueryMojoSectionArgs = {
  context: ContextInput;
  mojoContext: MojoContextInput;
};

export type QueryMreQueryArgs = {
  context: ContextInput;
  lob: Scalars["String"]["input"];
  pageType: Scalars["String"]["input"];
};

export type QueryMultiItemStepIndicatorArgs = {
  additionalChangeQueryParams?: InputMaybe<AdditionalChangeQueryParamsInput>;
  context: ContextInput;
  journeyContinuationId?: InputMaybe<Scalars["String"]["input"]>;
  lockupPrice?: InputMaybe<MoneyInput>;
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  multiItemSessionId?: InputMaybe<Scalars["String"]["input"]>;
  originalBookingId?: InputMaybe<Scalars["String"]["input"]>;
  savingsPrice?: InputMaybe<MoneyInput>;
};

export type QueryNearbyAirportsSuggestionArgs = {
  context: ContextInput;
  sessionId: Scalars["String"]["input"];
};

export type QueryNotificationArgs = {
  context: ContextInput;
};

export type QueryOccupantsIncludedOptionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  roomTypeIDs: Array<Scalars["String"]["input"]>;
};

export type QueryOffersContactHostArgs = {
  context: ContextInput;
  inquiryFormInput?: InputMaybe<OffersInquiryFormInput>;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  prominence?: InputMaybe<OffersInquiryProminence>;
  propertyId: Scalars["String"]["input"];
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryOffersRecommendationByProductArgs = {
  availabilityFilter?: InputMaybe<OffersRecommendationAvailabilityFilterInput>;
  configurationId?: InputMaybe<Scalars["String"]["input"]>;
  containerType?: InputMaybe<OffersRecommendationContainerType>;
  context: ContextInput;
  identifier?: InputMaybe<OffersRecommendationProductIdentifierInput>;
  outputRecommendationProductLine?: InputMaybe<LineOfBusinessDomain>;
  productId?: InputMaybe<Scalars["String"]["input"]>;
  productLine?: InputMaybe<LineOfBusinessDomain>;
  strategy?: InputMaybe<OffersRecommendationStrategy>;
};

export type QueryOffersTravelerSelectorArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"]["input"];
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryOnboardingPropertyRatesExperienceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryOneIdentityOnboardingArgs = {
  context: ContextInput;
};

export type QueryOneKeyCheckoutBurnArgs = {
  checkoutSessionId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  legacyUrl?: InputMaybe<Scalars["String"]["input"]>;
  oneKeyCashApplied?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryOneKeyLoyaltyBannerArgs = {
  context: ContextInput;
  lineOfBusiness: LineOfBusinessDomain;
  marketing?: InputMaybe<MarketingInfoInput>;
  oneKeyCashLoginCardData?: InputMaybe<OneKeyCashLoginCardDataInput>;
  packageLOB?: InputMaybe<LineOfBusinessDomain>;
  packageType?: InputMaybe<PackageType>;
  pageLocation: PageLocation;
  pageLocationContext?: InputMaybe<PageLocationContextInput>;
  searchContext?: InputMaybe<SearchContextInput>;
  useLoyaltyCurrency?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryOneKeyLoyaltyMessagingCardArgs = {
  context: ContextInput;
  lineOfBusiness: LineOfBusinessDomain;
  pageLocation: PageLocation;
  useLoyaltyCurrency?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryOneKeyOnboardingArgs = {
  additionalContext?: InputMaybe<AdditionalOnboardingContextInput>;
  context: ContextInput;
};

export type QueryOneKeyUniversalOnboardingArgs = {
  context: ContextInput;
  originUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPackageDestinationArgs = {
  context: ContextInput;
  regionId: Scalars["String"]["input"];
};

export type QueryPackageDetailsArgs = {
  context: ContextInput;
};

export type QueryPackagePrimersArgs = {
  context: ContextInput;
};

export type QueryPackageSearchResultsArgs = {
  context: ContextInput;
};

export type QueryPartnerLoyaltyPointsArgs = {
  context: ContextInput;
};

export type QueryPassportsFormArgs = {
  context: ContextInput;
  formContext?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPasswordStrengthMeterArgs = {
  context: ContextInput;
};

export type QueryPaymentArgs = {
  context: ContextInput;
};

export type QueryPaymentAssistArgs = {
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
};

export type QueryPaymentDetailsArgs = {
  bookingId: Scalars["String"]["input"];
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryPaymentModuleArgs = {
  context?: InputMaybe<ContextInput>;
  paymentSessionId?: InputMaybe<Scalars["String"]["input"]>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPenaltiesAndRestrictionsArgs = {
  bookingItemId: Scalars["String"]["input"];
  changeAction?: InputMaybe<AgentToolsLodgingActionInput>;
  context: ContextInput;
  orderNumber: Scalars["String"]["input"];
};

export type QueryPerformanceOverviewCarouselArgs = {
  campaignId: Scalars["String"]["input"];
  performanceDateRange: PerformanceDateRangeInput;
};

export type QueryPerformanceOverviewChartArgs = {
  campaignId: Scalars["String"]["input"];
  metric: Scalars["String"]["input"];
  performanceDateRange: PerformanceDateRangeInput;
};

export type QueryPerformanceSummaryArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryPersonalInfoFormArgs = {
  context: ContextInput;
};

export type QueryPersonalizedModuleListArgs = {
  contentSize: DiscoveryCardContentSize;
  context: ContextInput;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  personalizedContext?: InputMaybe<DiscoveryPersonalizedModuleListContextInput>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPersonalizedOffersRecommendationArgs = {
  configurationId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPlaceCardDetailsArgs = {
  context: ContextInput;
  directionsTo?: InputMaybe<DirectionsReferenceInput>;
  placeId: Scalars["String"]["input"];
};

export type QueryPlaygroundCurrencyFormatArgs = {
  amount: Scalars["Float"]["input"];
  context: ContextInput;
  currencyCode: Scalars["String"]["input"];
};

export type QueryPlaygroundDateTimeFormatArgs = {
  context: ContextInput;
};

export type QueryPlaygroundDeviceArgs = {
  context: ContextInput;
};

export type QueryPlaygroundLocationArgs = {
  context: ContextInput;
};

export type QueryPlaygroundSiteContextArgs = {
  context: ContextInput;
};

export type QueryPlaygroundUserArgs = {
  context: ContextInput;
};

export type QueryPoliciesArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryPostContentOverlayArgs = {
  context: ContextInput;
  postOverlayRequest: PostContentOverlayQueryRequestInput;
};

export type QueryPreBundledPackageEntryArgs = {
  context: ContextInput;
};

export type QueryPreBundledPackageSearchArgs = {
  context: ContextInput;
  searchCriteria: PackageSearchCriteriaInput;
  sort?: InputMaybe<PackageSort>;
};

export type QueryPreBundledPackageSearchEntryArgs = {
  context: ContextInput;
  sessionId: Scalars["String"]["input"];
};

export type QueryPreBundledPackageSearchEntryCardArgs = {
  context: ContextInput;
  sessionId: Scalars["String"]["input"];
};

export type QueryPreBundledPackageSearchFormArgs = {
  context: ContextInput;
  searchCriteria: PackageSearchCriteriaInput;
};

export type QueryPriceDetailsArgs = {
  cartId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  entityToken?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPriceInsightsArgs = {
  context: ContextInput;
};

export type QueryPriceMatchPromiseArgs = {
  context: ContextInput;
  tripNumber: Scalars["String"]["input"];
};

export type QueryProductDescriptionArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductGalleryArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductGroupArgs = {
  context: ContextInput;
  productGroupContextInput?: InputMaybe<ProductGroupContextInput>;
};

export type QueryProductHeadlineArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductHighlightsArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductListingCarouselsArgs = {
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
};

export type QueryProductNavigationBarArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductQuestionsArgs = {
  context: ContextInput;
  filters?: InputMaybe<ProductQuestionFiltersInput>;
  productId: Scalars["String"]["input"];
  productQuestionTopic: ProductQuestionTopic;
  questionPagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<QuestionSortType>;
};

export type QueryProductRatingSummaryArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductReviewDetailsArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductReviewSummaryArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryProductShoppingCrossSellDetailsArgs = {
  context: ContextInput;
  productShoppingCriteriaInput: ProductShoppingCriteriaInput;
};

export type QueryProductSummaryArgs = {
  context: ContextInput;
  productSummaryInput: ProductSummaryInput;
};

export type QueryPropertyActivitiesCrossSellArgs = {
  activityNaturalKey?: InputMaybe<ActivityNaturalKeyInput>;
  context: ContextInput;
  propertyId: Scalars["String"]["input"];
  searchCriteria: PropertySearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryPropertyAvailabilityCalendarsArgs = {
  context?: InputMaybe<ContextInput>;
  dateRange?: InputMaybe<DateRangeInput>;
  eid: Scalars["ID"]["input"];
};

export type QueryPropertyCompareArgs = {
  context: ContextInput;
};

export type QueryPropertyFilterArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  listingLifecycleStates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  propertyContext?: InputMaybe<PropertyContext>;
  showSeeAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  temporaryPartnerContextOverride?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QueryPropertyFilterIntentSheetArgs = {
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
  dateRange: PropertyDateRangeInput;
};

export type QueryPropertyInfoArgs = {
  context: ContextInput;
  offerOptions?: InputMaybe<Array<PropertyInfoOptions>>;
  propertyId: Scalars["String"]["input"];
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryPropertyOffersArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  productIdentifier?: InputMaybe<ProductIdentifierInput>;
  propertyId: Scalars["String"]["input"];
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryPropertyRatesDateSelectorArgs = {
  context: ContextInput;
  dateRange: DateRangeInput;
  eid: Scalars["ID"]["input"];
};

export type QueryPropertyReviewArgs = {
  context: ContextInput;
  id: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["Locale"]["input"]>;
};

export type QueryPropertyReviewAggregatesArgs = {
  context: ContextInput;
  propertyIds: Array<Scalars["String"]["input"]>;
};

export type QueryPropertyReviewInfoArgs = {
  context: ContextInput;
  propertyId: Scalars["String"]["input"];
  propertyName?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryPropertyReviewSummariesArgs = {
  context: ContextInput;
  propertyIds: Array<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryPropertySearchArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  dateRange?: InputMaybe<PropertyDateRangeInput>;
  destination: DestinationInput;
  filters?: InputMaybe<PropertySearchFiltersInput>;
  legacyCriteria?: InputMaybe<LegacyPropertySearchCriteriaInput>;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  productShoppingCriteriaInput?: InputMaybe<ProductShoppingCriteriaInput>;
  propertyShopOptions?: InputMaybe<PropertyShopOptionsInput>;
  pwaLegacySort?: InputMaybe<Scalars["String"]["input"]>;
  returnPropertyType?: InputMaybe<Scalars["Boolean"]["input"]>;
  rooms?: InputMaybe<Array<RoomInput>>;
  searchIntent?: InputMaybe<SearchIntentInput>;
  searchOptions?: InputMaybe<Array<PropertySearchOptions>>;
  searchPagination?: InputMaybe<PaginationInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  sort?: InputMaybe<PropertySort>;
};

export type QueryPropertySpacesArgs = {
  context: ContextInput;
  propertyContentType: PropertyContentType;
  propertyId: Scalars["String"]["input"];
};

export type QueryPropertyTopReviewsArgs = {
  context: ContextInput;
  propertyId: Scalars["String"]["input"];
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryPropertyTypesCarouselArgs = {
  configurationId?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPublicItineraryHeaderArgs = {
  context: ContextInput;
  itineraryId: Scalars["String"]["input"];
};

export type QueryQualtricsConfigArgs = {
  context: ContextInput;
};

export type QueryQuickActionSheetFromTemplateArgs = {
  actionPanelTemplate: Scalars["String"]["input"];
  coachingTip: Scalars["String"]["input"];
  context: ContextInput;
  dynamicContent: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QueryRandomAccessOneArgs = {
  context: ContextInput;
  featureId: Scalars["String"]["input"];
  productIdentifier: ProductIdentifierInput;
};

export type QueryRandomAccessOneApiDiscoveryArgs = {
  context: ContextInput;
  filter?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRandomAccessOneDomainArgs = {
  assets: Array<ProductEntityAssetSelection>;
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryRandomAccessOneSetupArgs = {
  context: ContextInput;
};

export type QueryReasonCodesArgs = {
  context: ContextInput;
  reasonCodesInput: ReasonCodesInput;
};

export type QueryRecentActivitiesModuleArgs = {
  configurationId?: InputMaybe<Scalars["String"]["input"]>;
  containerType?: InputMaybe<DiscoveryContainerType>;
  contentSize?: InputMaybe<DiscoveryCardContentSize>;
  context: ContextInput;
  discoveryRecentActivityContext?: InputMaybe<DiscoveryRecentActivityContextInput>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  typeFilter?: InputMaybe<Array<RecentActivityType>>;
};

export type QueryRecentlyViewedPropertiesCarouselArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type QueryRecommendationCarouselArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryRecommendationsModuleArgs = {
  configurationIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  containerType?: InputMaybe<DiscoveryContainerType>;
  contentSize: DiscoveryCardContentSize;
  context: ContextInput;
  input: Array<DiscoveryRecommendationInputContext>;
  offeringType: DiscoveryOfferingType;
  placementId: Scalars["String"]["input"];
  priceStrategy?: InputMaybe<DiscoveryPriceStrategy>;
  recommendationContext: DiscoveryRecommendationContextInput;
  strategy: DiscoveryRecommendationStrategy;
};

export type QueryRedirectBannerArgs = {
  context: ContextInput;
  pageSearch?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRefundCreditsLedgerArgs = {
  context: ContextInput;
};

export type QueryRegulatoryContentArgs = {
  context: ContextInput;
  orderId: Scalars["String"]["input"];
  sessionId?: InputMaybe<Scalars["String"]["input"]>;
  timestamp: Scalars["String"]["input"];
};

export type QueryRelevantContentArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryRequestContextArgs = {
  clientRequest: ClientRequestInput;
};

export type QueryRequestToJoinTripArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
};

export type QueryRequestToJoinTripSheetArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
};

export type QueryReservationDetailsArgs = {
  components?: InputMaybe<Array<InputMaybe<SupplyReservationDetailsComponentType>>>;
  context: ContextInput;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
  reservationContext?: InputMaybe<ReservationContext>;
  reservationId?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type QueryReservationModifiersArgs = {
  context: ContextInput;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  isAutoPay?: InputMaybe<Scalars["Boolean"]["input"]>;
  propertyContext: PropertyContext;
  reservationId: Scalars["String"]["input"];
  reservationModifiersType?: InputMaybe<ReservationModifiersType>;
};

export type QueryRetailTravelAgentNotificationCenterArgs = {
  context: ContextInput;
};

export type QueryRetailTravelAgentRewardsArgs = {
  context: ContextInput;
};

export type QueryReviewQuestionsArgs = {
  context?: InputMaybe<ContextInput>;
  request: GetReviewQuestionsRequestInput;
};

export type QueryReviewsOverviewArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryRewardProgramsFormArgs = {
  context: ContextInput;
  formContext?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryRulesAndRestrictionsArgs = {
  context: ContextInput;
  policyFetchParams: PolicyFetchParamsInput;
};

export type QuerySailingCabinCategoriesArgs = {
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingCabinCategoriesInput: CruiseSailingCabinCategoriesInput;
  sailingToken: Scalars["String"]["input"];
};

export type QuerySailingCancellationArgs = {
  context: ContextInput;
};

export type QuerySailingDealsArgs = {
  context?: InputMaybe<ContextInput>;
  cruiseRequestContextInput?: InputMaybe<CruiseRequestContextInput>;
  cruiseSearchFiltersInput?: InputMaybe<CruiseSearchFiltersInput>;
  cruiseSearchOptionsInput: CruiseSearchOptionsInput;
};

export type QuerySailingDetailsArgs = {
  adults: Scalars["Int"]["input"];
  cabinType?: InputMaybe<Scalars["String"]["input"]>;
  children: Scalars["Int"]["input"];
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingToken: Scalars["String"]["input"];
};

export type QuerySailingOffersArgs = {
  adultCount: Scalars["Int"]["input"];
  cabinExperience?: InputMaybe<Scalars["String"]["input"]>;
  cabinType?: InputMaybe<Scalars["String"]["input"]>;
  childAges?: InputMaybe<Array<CruiseChildInput>>;
  childCount: Scalars["Int"]["input"];
  context: ContextInput;
  cruiseRequestContextInput: CruiseRequestContextInput;
  sailingToken: Scalars["String"]["input"];
};

export type QuerySavedCardDetailsArgs = {
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
  paymentInstrumentId: Scalars["String"]["input"];
};

export type QuerySavedCardSelectArgs = {
  context: ContextInput;
  paymentAssistSessionId: Scalars["String"]["input"];
};

export type QuerySearchFormArgs = {
  context: ContextInput;
};

export type QuerySearchFormPriceInsightsArgs = {
  context: ContextInput;
};

export type QuerySearchHelpArticlesArgs = {
  context: ContextInput;
};

export type QuerySearchLocationArgs = {
  context: ContextInput;
  searchLocationCriteria: SearchLocationCriteriaInput;
};

export type QueryServiceAutomationArgs = {
  context: ContextInput;
};

export type QueryShopInsurtechOffersArgs = {
  context: ContextInput;
  shopInsurtechOffersRequest: ShopInsurtechOffersRequestInput;
};

export type QueryShoppingPathPrimersArgs = {
  context: ContextInput;
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  multiItemSessionId: Scalars["String"]["input"];
  recoveryNaturalKeys?: InputMaybe<MishopRecoveryNaturalKeysInput>;
};

export type QueryShortlistArgs = {
  context: ContextInput;
};

export type QuerySimilarDestinationsArgs = {
  context: ContextInput;
  similarDestinationsCriteria: SimilarDestinationsCriteriaInput;
};

export type QuerySimilarPropertiesDealsArgs = {
  campaignParameters: CampaignParametersInput;
  context: ContextInput;
};

export type QuerySiteConfigurationsArgs = {
  request: SiteConfigurationsRequestInput;
};

export type QuerySmartFormArgs = {
  checkoutUrl?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  sessionId: Scalars["String"]["input"];
  type?: InputMaybe<SmartFormType>;
};

export type QuerySocialConnectionsArgs = {
  clientContext: SocialConnectionsClientContextInput;
  context: ContextInput;
};

export type QuerySocialShareActionArgs = {
  context: ContextInput;
  destinationContext: ShareDestinationContextInput;
  lineOfBusiness: LineOfBusinessDomain;
  optionalPageId?: InputMaybe<Scalars["String"]["input"]>;
  referrerUrl?: InputMaybe<Scalars["String"]["input"]>;
  strategy: SocialShareStrategy;
};

export type QuerySocialShareButtonArgs = {
  context: ContextInput;
  strategy: SocialShareStrategy;
};

export type QuerySocialShareSheetArgs = {
  context: ContextInput;
  shareContextInput: ShareContextInput;
};

export type QuerySponsoredPropertiesCarouselArgs = {
  context: ContextInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type QueryStaticMultiItemCrossSellArgs = {
  context: ContextInput;
  crossSellProduct: CrossSellProductType;
  searchContext: MultiItemSearchContextInput;
};

export type QueryStepReviewInlineEditingSheetArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyAmenitiesGroupArgs = {
  context: ContextInput;
  input: SupplyAmenitiesGroupInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyAttributesAdminExperienceArgs = {
  context: ContextInput;
  input: SupplyAttributesAdminExperienceInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyAutoCancelWaiverArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyBookingOptionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyBusinessPartnerInformationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  requestedBillingCountryCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySupplyCampaignArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCancelPolicyExceptionsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCancellationWaiverDeclineArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: SupplyCancellationWaiverReservationContext;
};

export type QuerySupplyCoachingActionPanelArgs = {
  contentActionPanelInput: ContentActionPanelInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipBlockTabsArgs = {
  analyticsContext?: InputMaybe<SupplyCoachingTipBlockTabsAnalyticsContext>;
  coachingTipStates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  coachingTipTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipCountsArgs = {
  coachingTipTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipPropertyFinderSearchArgs = {
  coachingTipType: Scalars["String"]["input"];
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipsBlockArgs = {
  coachingTipStates?: InputMaybe<Array<Scalars["String"]["input"]>>;
  coachingTipTypes?: InputMaybe<Array<Scalars["String"]["input"]>>;
  context: ContextInput;
  propertyContext: PropertyContext;
  view?: InputMaybe<CoachingTipsView>;
};

export type QuerySupplyCoachingTipsPostponeFormArgs = {
  coachingTipType: Scalars["String"]["input"];
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipsPropertyFinderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipsResultDetailArgs = {
  coachingParameters: SupplyCoachingTipsResultDetailParameters;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyCoachingTipsResultsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentAdditionalInfoArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentCoachingActionPanelArgs = {
  actionPanelContext: ActionPanelContext;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentGenAiHeadlineDescriptionArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHeadlineDescriptionArgs = {
  context: ContextInput;
  navigationLevel?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHomePageArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  queryIdentifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySupplyContentHubArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentHubNavigationArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentLocationSharingArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentManualTranslationSettingArgs = {
  context: ContextInput;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentSaveButtonArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyContentScoreArgs = {
  contentScoreInput: ContentScoreInput;
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentYourPropertyArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContentYourStoryArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContractingAgreementArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyContractingLicensePlateArgs = {
  context: ContextInput;
  page?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyContractingProgramEnrollmentArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyDashboardAdditionalDestinationsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyDashboardReservationsOverviewArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyDeleteAccountArgs = {
  context: ContextInput;
};

export type QuerySupplyFeesArgs = {
  context: ContextInput;
  feeFilter?: InputMaybe<SupplyFeeFilterInput>;
  propertyContext: PropertyContext;
};

export type QuerySupplyHelpContactUsArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
  supportTaxonomy?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySupplyHomepageArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyHomepageGuestExperienceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyIndirectTaxArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyLoyaltyArgs = {
  context: ContextInput;
};

export type QuerySupplyMarketMakerGridArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyMarketplaceFeedArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyMarketplaceFeedGetAllArgs = {
  context: ContextInput;
};

export type QuerySupplyMultiFactorAuthenticationViewArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  navigationContext?: InputMaybe<SupplyNavigationContext>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationAsyncNotificationsButtonArgs = {
  context: ContextInput;
  currentPageContext?: InputMaybe<SupplyCurrentPageContext>;
  navigationContext?: InputMaybe<SupplyNavigationContext>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationLinkItemBadgeArgs = {
  badgeType: Scalars["String"]["input"];
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationNotificationArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyNavigationPropertyDetailsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyNavigationReservationSearchContentArgs = {
  context: ContextInput;
  keyword?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
};

export type QuerySupplyNavigationUserImpersonationBannerArgs = {
  context: ContextInput;
  temporaryPartnerContextOverride?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type QuerySupplyOnboardingAvailabilityArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingNavigationHeaderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingPaymentTermsAndConditionsArgs = {
  context: ContextInput;
};

export type QuerySupplyOnboardingPropertyRatesExperienceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingPublishListingArgs = {
  context: ContextInput;
  isPublished?: InputMaybe<Scalars["Boolean"]["input"]>;
  onboardingContext?: InputMaybe<OnboardingContext>;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingReadinessArgs = {
  context: ContextInput;
  onboardingContext?: InputMaybe<OnboardingContext>;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingReviewArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingTipCardArgs = {
  context: ContextInput;
  tipCardId?: InputMaybe<SupplyOnboardingTipCardId>;
};

export type QuerySupplyOnboardingUnfinishedStepsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingWelcomeArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOnboardingWorkFlowNavigationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyOpportunityHubLicensePlateArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPcRoomManagementWorkflowArgs = {
  context: ContextInput;
  params: SupplyPcRoomManagementWorkflowInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPageLevelBannerArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyPartnerSelectArgs = {
  context: ContextInput;
};

export type QuerySupplyPartnerSignUpEstimateResultsArgs = {
  context: ContextInput;
  estimateResultsInput: SupplyPartnerSignUpEstimateResultsInput;
};

export type QuerySupplyPhotoAssignExperienceArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  propertyContext: PropertyContext;
};

export type QuerySupplyPhotoSpacesExperienceArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  propertyContext: PropertyContext;
};

export type QuerySupplyPhotoUploaderExperienceArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  propertyContext: PropertyContext;
};

export type QuerySupplyPhotosExperienceArgs = {
  context: ContextInput;
  experienceContext: SupplyPhotosExperienceContext;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationExperienceArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationPreviewRatesArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  roomTypeKey: Scalars["String"]["input"];
};

export type QuerySupplyProductCreationPreviewRoomNameArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameAttributesInput;
};

export type QuerySupplyProductCreationPricingModelArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationRatePlanSideSheetArgs = {
  context: ContextInput;
  params: SupplyProductCreationRatePlanSideSheetInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationRoomInfoExperienceArgs = {
  context: ContextInput;
  previewBedConfigurationInput?: InputMaybe<SupplyProductCreationPreviewBedConfigurationInput>;
  propertyContext: PropertyContext;
  roomInfoInput?: InputMaybe<SupplyProductCreationRoomInfoInput>;
};

export type QuerySupplyProductCreationRoomNameExperienceArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationRoomOverviewExperienceArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyProductCreationTipCardArgs = {
  context: ContextInput;
  input: SupplyProductCreationTipCardInput;
};

export type QuerySupplyProgramsEnrollmentArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionCopyTieredMembersOnlyDealArgs = {
  context: ContextInput;
  promotionId: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionCreateTieredMembersOnlyDealArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionPlaygroundArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionPlaygroundGetBestDiscountArgs = {
  context: ContextInput;
  playgroundInputs?: InputMaybe<SupplyPromotionPlaygroundInputs>;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionStackingOptionsSheetArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyPromotionUpdateTieredMembersOnlyDealArgs = {
  context: ContextInput;
  promotionId: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyPropertyEditorPageArgs = {
  context: ContextInput;
  page: Scalars["String"]["input"];
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QuerySupplyQuickActionFromTemplateArgs = {
  actionPanelTemplate: Scalars["String"]["input"];
  coachingTip: Scalars["String"]["input"];
  context: ContextInput;
  dynamicContent: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyQuickActionSubmitPanelFromTemplateArgs = {
  coachingTip: Scalars["String"]["input"];
  context: ContextInput;
  dynamicContent: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  submitPanelTemplate: Scalars["String"]["input"];
};

export type QuerySupplyRateAvailabilityObservationsArgs = {
  context: ContextInput;
  options?: InputMaybe<SupplyRateAvailabilityObservationsOptions>;
  propertyContext: PropertyContext;
};

export type QuerySupplyRatePlanArgs = {
  context: ContextInput;
  params?: InputMaybe<SupplyRatePlanQueryInput>;
  propertyContext: PropertyContext;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QuerySupplyRatePlanRightRailArgs = {
  context: ContextInput;
  params: SupplyRatePlanRightRailInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyRegulatoryFlowArgs = {
  context: ContextInput;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  propertyContext: PropertyContext;
  regulatoryType?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySupplyRegulatoryFlowThirdPartyValidationArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  validation: SupplyRegulatoryFlowThirdPartyValidationInput;
};

export type QuerySupplyRegulatoryFlowTitleArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyReservationsBookingRequestArgs = {
  context: ContextInput;
  name: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  requestType: Scalars["String"]["input"];
  reservationContext: ReservationContext;
};

export type QuerySupplyReservationsDeclineRequestArgs = {
  context: ContextInput;
  name: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  reservationContext: ReservationContext;
  step: SupplyReservationsDeclineStep;
};

export type QuerySupplyReservationsHeaderArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientInput>;
  context: ContextInput;
  propertyContext: PropertyContext;
  reservationContext: ReservationContext;
};

export type QuerySupplyReviewsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  reviewsContext?: InputMaybe<SupplyReviewsContext>;
};

export type QuerySupplyRoomTypeMappingArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  viewOption?: InputMaybe<SupplyRoomTypeMappingViewOption>;
};

export type QuerySupplyRoomTypeMappingStatusCardArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplySafetyDevicesConfirmArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyScoreBenefitsLadderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyScoreDetailsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyScoreExternalModuleOverviewArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyScoreFactorAuditTrailArgs = {
  context: ContextInput;
  factorType: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type QuerySupplyTaxesArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QuerySupplyUserProfileArgs = {
  context: ContextInput;
};

export type QuerySupplyUserSettingsDashboardHeaderArgs = {
  context: ContextInput;
};

export type QuerySweepstakesBannerArgs = {
  context: ContextInput;
};

export type QuerySweepstakesDashboardArgs = {
  context: ContextInput;
};

export type QuerySweepstakesUserActionHistoryQueryArgs = {
  apiKey: Scalars["String"]["input"];
  campaignId: Scalars["String"]["input"];
};

export type QueryTermsAndConditionsArgs = {
  context: ContextInput;
  couponInstanceId?: InputMaybe<Scalars["String"]["input"]>;
  rewardId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTestAirlineEntityServiceArgs = {
  context: ContextInput;
};

export type QueryThingsToDoArgs = {
  context: ContextInput;
  placeId: Scalars["String"]["input"];
};

export type QueryTopRatedAttributeArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
};

export type QueryTopTasksArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type QueryTravelAgentAuthenticationOptionsArgs = {
  context: ContextInput;
};

export type QueryTravelAgentDashboardArgs = {
  context: ContextInput;
  dashboardType?: InputMaybe<DashboardType>;
};

export type QueryTravelAgentSearchWizardConfigArgs = {
  context: ContextInput;
};

export type QueryTravelContentEditorialArgs = {
  contentIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  contentPurpose?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  pageType?: InputMaybe<Scalars["String"]["input"]>;
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryTravelExperienceContentArgs = {
  context: ContextInput;
  ids: Array<Scalars["String"]["input"]>;
  pageType: Scalars["String"]["input"];
};

export type QueryTravelExperienceManagerContentArgs = {
  contentTopic?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  egPlatformURL?: InputMaybe<Scalars["String"]["input"]>;
  experienceType?: InputMaybe<Scalars["String"]["input"]>;
  preview?: InputMaybe<Scalars["Boolean"]["input"]>;
  requestDate?: InputMaybe<Scalars["String"]["input"]>;
  uriContext?: InputMaybe<UriContextInput>;
};

export type QueryTravelGuideRecommendationArgs = {
  context: ContextInput;
  policy: DestinationTravelGuidePolicy;
  travelGuidePageContextInput?: InputMaybe<TravelGuidePageContextInput>;
};

export type QueryTravelShopCollectionsArgs = {
  context: ContextInput;
  travelShopType?: InputMaybe<TravelShopType>;
};

export type QueryTravelStoryCarouselArgs = {
  context: ContextInput;
  moduleName?: InputMaybe<ModuleNames>;
};

export type QueryTravelStoryVlogsArgs = {
  context: ContextInput;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  videoId: Scalars["String"]["input"];
};

export type QueryTravelTriviaBannerArgs = {
  context: ContextInput;
};

export type QueryTraveladsPageBodyArgs = {
  context: ContextInput;
  propertyId: Scalars["Int"]["input"];
};

export type QueryTraveladsPageHeaderArgs = {
  context: ContextInput;
  propertyId: Scalars["Int"]["input"];
};

export type QueryTravelerCouponsArgs = {
  context: ContextInput;
};

export type QueryTravelerSelectorArgs = {
  context: ContextInput;
  marketing?: InputMaybe<PropertyMarketingInfoInput>;
  propertyId: Scalars["String"]["input"];
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  searchOffer?: InputMaybe<SearchOfferInput>;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
  travelAdTrackingInfo?: InputMaybe<PropertyTravelAdTrackingInfoInput>;
};

export type QueryTravellerSearchArgs = {
  context: ContextInput;
  productIdentifier: ProductIdentifierInput;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTripArgs = {
  context: ContextInput;
};

export type QueryTripAssistGoalArgs = {
  context: ContextInput;
  tripAssistGoalType: TripAssistGoalType;
  tripViewId: Scalars["String"]["input"];
};

export type QueryTripCollaborationChatEntryPointArgs = {
  context: ContextInput;
  tripCollaborationChatEntryPointRequest: TripCollaborationChatEntryPointRequestInput;
};

export type QueryTripCollaborationChatEntryPointButtonArgs = {
  context: ContextInput;
  tripCollaborationChatEntryPointButtonRequest: TripCollaborationChatEntryPointButtonRequestInput;
};

export type QueryTripCollaborationChatHeaderArgs = {
  context: ContextInput;
  tripCollaborationChatHeaderRequest: TripCollaborationChatHeaderRequestInput;
};

export type QueryTripCustomerSupportArgs = {
  context: ContextInput;
  primaryId: TripViewIdInput;
  secondaryId?: InputMaybe<TripViewIdInput>;
};

export type QueryTripExperienceTemplateArgs = {
  context: ContextInput;
  tripItemId: Scalars["String"]["input"];
  tripViewId: Scalars["String"]["input"];
};

export type QueryTripItemCardContextualContentArgs = {
  context: ContextInput;
  itemId: Scalars["String"]["input"];
};

export type QueryTripItemCommentsAndVotesArgs = {
  context: ContextInput;
  defaultTab?: InputMaybe<Scalars["String"]["input"]>;
  itemId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type QueryTripItemContextualCardsArgs = {
  context: ContextInput;
  tripItemId: Scalars["String"]["input"];
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTripItemImageGalleryArgs = {
  context: ContextInput;
  itemId: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type QueryTripItemPriceArgs = {
  context: ContextInput;
  itemId: Scalars["String"]["input"];
};

export type QueryTripItemsArgs = {
  context: ContextInput;
  groups: Array<TripsItemsGroupInput>;
  tripId: Scalars["String"]["input"];
};

export type QueryTripItineraryArgs = {
  context: ContextInput;
  isRecommendationsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  tripId: Scalars["String"]["input"];
};

export type QueryTripManageExternalItemsArgs = {
  context: ContextInput;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTripManageParticipantsArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
};

export type QueryTripMapArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTripOverviewTabsArgs = {
  context: ContextInput;
  selectedTabId?: InputMaybe<Scalars["String"]["input"]>;
  tripId: Scalars["String"]["input"];
};

export type QueryTripPlanningArgs = {
  context: ContextInput;
  tripItemContext?: InputMaybe<TripsUiTripItemContextInput>;
};

export type QueryTripSaveItemArgs = {
  context: ContextInput;
  encodedItemId: Scalars["String"]["input"];
};

export type QueryTripsArgs = {
  context: ContextInput;
};

export type QueryTripsAttachSavingsArgs = {
  context: ContextInput;
  lob?: InputMaybe<ExpLineOfBusiness>;
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  qualificationType?: InputMaybe<TripsUiAttachQualificationType>;
  tripId: Scalars["String"]["input"];
  variant?: InputMaybe<TripsUiAttachSavingsVariant>;
};

export type QueryTripsCancellationTimelineArgs = {
  context: ContextInput;
  primaryId: Scalars["String"]["input"];
  primaryIdType: TripsCancellationTimelineIdType;
};

export type QueryTripsChatGptEntrypointArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
  tripSource?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryTripsCollectionArgs = {
  context: ContextInput;
};

export type QueryTripsForOfflineViewArgs = {
  context: ContextInput;
};

export type QueryTripsMultiItemPurchaseArgs = {
  context: ContextInput;
  tripId: Scalars["String"]["input"];
};

export type QueryTripsNotAuthorizedViewArgs = {
  context: ContextInput;
  input?: InputMaybe<TripsNotAuthorizedInput>;
};

export type QueryTripsPageHeaderArgs = {
  context: ContextInput;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripsScreen: TripsScreen;
};

export type QueryTripsPrimerArgs = {
  context: ContextInput;
  tripsScreen: TripsScreen;
};

export type QueryTripsReviewCollectionArgs = {
  context: ContextInput;
  ctaLocation?: InputMaybe<ReviewsCarouselCtaLocation>;
};

export type QueryTripsWebViewArgs = {
  context: ContextInput;
  input: TripsWebViewInput;
};

export type QueryTriviaHowToArgs = {
  context: ContextInput;
  request: TriviaStartRequestInput;
};

export type QueryTsaFormArgs = {
  context: ContextInput;
};

export type QueryUniversalProfileArgs = {
  context: ContextInput;
  universalProfileClientInfo?: InputMaybe<UniversalProfileClientInfoInput>;
};

export type QueryUpcomingInteractionsArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type QueryUpdateJourneyContinuationIdArgs = {
  updateJourneyContinuationIdRequest: UpdateJourneyContinuationIdRequestInput;
};

export type QueryUpdatePaymentArgs = {
  context: ContextInput;
  paymentAssistBookingInput: PaymentAssistBookingInput;
};

export type QueryUserProfileNextWizardArgs = {
  context: ContextInput;
  wizardContext: ProfileWizardContextInput;
};

export type QueryVacChatContentArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  clientContext?: InputMaybe<ClientContextInput>;
  context: ContextInput;
  conversationContext?: InputMaybe<ConversationContextInput>;
};

export type QueryVacOrchestratorArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  clientId: Scalars["String"]["input"];
  context: ContextInput;
};

export type QueryValuePropositionBannerArgs = {
  context: ContextInput;
  placementId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryVideoExperienceCarousalArgs = {
  context: ContextInput;
  videoExperienceCarousalRequest: VideoExperienceCarousalRequestInput;
};

export type QueryVideoPostOverlayArgs = {
  context: ContextInput;
  videoPostOverlayRequest: VideoPostOverlayRequestInput;
};

export type QueryVirtualAgentControlArgs = {
  authenticationConfig?: InputMaybe<AuthenticationConfigInput>;
  context: ContextInput;
  conversationContext?: InputMaybe<ConversationContextInput>;
  embedded?: InputMaybe<Scalars["Boolean"]["input"]>;
  variant?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryVirtualAgentControlChatbotPopupArgs = {
  authenticationConfig: AuthenticationConfigInput;
  context: ContextInput;
  conversationContext: ConversationContextInput;
};

export type QueryVirtualAgentControlCoachmarkArgs = {
  authenticationConfig: AuthenticationConfigInput;
  context: ContextInput;
  conversationContext: ConversationContextInput;
};

export type QueryVirtualAgentControlConfigurationArgs = {
  context: ContextInput;
};

export type QueryVirtualAgentControlGetSurveyArgs = {
  context: ContextInput;
  surveyContext: VirtualAgentControlGetSurveyInput;
};

export type QueryVirtualAgentControlMessagesArgs = {
  authenticationConfig: AuthenticationConfigInput;
  clientContext?: InputMaybe<ClientContextInput>;
  context: ContextInput;
  conversationContext: ConversationContextInput;
};

export type QueryVoiceOfTheCustomerArgs = {
  context: ContextInput;
};

export type QueryVoiceOfTheCustomerOptionalArgs = {
  additionalParams?: InputMaybe<Array<GraphQlPairInput>>;
  context: ContextInput;
  surveyContext?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryWaiverSectionQueryArgs = {
  context: ContextInput;
  waiverSectionInput: WaiverSectionInput;
};

export type QueryWalletFaqSectionArgs = {
  context: ContextInput;
};

export type QueryWalletLedgerScreenArgs = {
  context: ContextInput;
};

export type QueryVariables = {
  __typename?: "QueryVariables";
  queryVariableA?: Maybe<Scalars["String"]["output"]>;
  queryVariableB?: Maybe<Scalars["String"]["output"]>;
};

export type QuestionAndAnswer = {
  __typename?: "QuestionAndAnswer";
  analytics: ClientSideAnalytics;
  /** @deprecated contains raw html */
  answer: Scalars["String"]["output"];
  question: Scalars["String"]["output"];
};

export type QuestionSortType = "NEWEST_TO_OLDEST" | "OLDEST_TO_NEWEST";

export type QuoteAction = UiAction & {
  __typename?: "QuoteAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionIdentifier: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type QuoteBuilderType = "BOTTOM" | "RIGHT_SIDE" | "SIDE";

export type RadialProgressionBar = {
  __typename?: "RadialProgressionBar";
  accessibilityLabel: Scalars["String"]["output"];
  percent: Scalars["Int"]["output"];
  secondaryPercent?: Maybe<Scalars["Int"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type RandomAccessConfig = {
  __typename?: "RandomAccessConfig";
  features: Array<FeatureTest>;
};

export type RandomAccessOneApiDiscovery = {
  __typename?: "RandomAccessOneAPIDiscovery";
  productEntity: Array<ModelEntityMap>;
};

export type RandomAccessOneContentResponse = {
  __typename?: "RandomAccessOneContentResponse";
  id: Scalars["String"]["output"];
  mapping: Array<ExperimentalComponentWrapper>;
  metadata?: Maybe<ExperimentalMetadata>;
};

export type RandomAccessOneDomain = {
  __typename?: "RandomAccessOneDomain";
  productEntity?: Maybe<Scalars["String"]["output"]>;
};

export type RandomAccessOneSetup = {
  __typename?: "RandomAccessOneSetup";
  assets: Array<ProductEntityAssetSelection>;
  featureIds: RandomAccessConfig;
  helpers: Array<HelperMetadata>;
  identifiers: Array<IdentifierMetadata>;
  patterns: Array<Pattern>;
};

export type RangeIndicator = {
  __typename?: "RangeIndicator";
  accessibility: Scalars["String"]["output"];
  milestones: Array<RangeIndicatorMilestone>;
  segments: Array<RangeIndicatorSegment>;
};

export type RangeIndicatorMilestone = {
  __typename?: "RangeIndicatorMilestone";
  label: EgdsStylizedText;
};

export type RangeIndicatorPin = {
  __typename?: "RangeIndicatorPin";
  badge: EgdsStandardBadge;
  icon?: Maybe<UiGraphic>;
  percentage: Scalars["Int"]["output"];
};

export type RangeIndicatorSegment = {
  __typename?: "RangeIndicatorSegment";
  pins: Array<RangeIndicatorPin>;
  style?: Maybe<RangeIndicatorSegmentStyle>;
  theme: RangeIndicatorSegmentTheme;
};

export type RangeIndicatorSegmentStyle = "EMPHASIS";

export type RangeIndicatorSegmentTheme = "NEGATIVE" | "NEUTRAL" | "POSITIVE" | "VERY_POSITIVE";

export type RangeValue = {
  __typename?: "RangeValue";
  id: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  min: Scalars["Int"]["output"];
};

export interface RangeValueInput {
  id: Scalars["String"]["input"];
  max: Scalars["Int"]["input"];
  min: Scalars["Int"]["input"];
}

export type RateAdjustmentType = "Amount" | "Percent";

export type RateCategory = {
  __typename?: "RateCategory";
  groupRate?: Maybe<Scalars["Boolean"]["output"]>;
};

export interface RateCategoryInput {
  groupRate?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type RateDiscount = {
  __typename?: "RateDiscount";
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionId: Scalars["String"]["output"];
  expiration: Duration;
  sameDay: Scalars["Boolean"]["output"];
};

export type RateDiscountType = "MEMBER_DEAL" | "MOBILE_EXCLUSIVE" | "REGULAR" | "TONIGHT_ONLY";

export type RatePlan = {
  __typename?: "RatePlan";
  amenities: Array<RatePlanAmenity>;
  /** @deprecated Deprecated in favor of standardBadge */
  badge?: Maybe<Badge>;
  cardFooterContent?: Maybe<ShoppingOfferCardFooterContent>;
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  confidenceMessage?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  etpCloseEvent?: Maybe<EgClickstreamEvent>;
  /** @deprecated Feature will be moved. */
  etpDialogTopMessage?: Maybe<MessageResult>;
  headerMessage?: Maybe<LodgingEnrichedMessage>;
  highlightedMessages?: Maybe<Array<RatePlanMessage>>;
  id: Scalars["String"]["output"];
  loyaltyMessage?: Maybe<LodgingEnrichedMessage>;
  marketingChannelMessage?: Maybe<LodgingEnrichedMessage>;
  marketingSection?: Maybe<MarketingSection>;
  name?: Maybe<Scalars["String"]["output"]>;
  offerNotifications?: Maybe<LodgingNotificationsCard>;
  paymentPolicy: Array<PropertyPaymentPolicyInfo>;
  paymentReassuranceMessage?: Maybe<LodgingEnrichedMessage>;
  priceDetails: Array<Offer>;
  /** @deprecated Use ShoppingButton instead */
  reserveCallToAction?: Maybe<RatePlanCallToAction>;
  /** @deprecated Use cardFooterContent instead */
  shareUrl?: Maybe<LodgingLinkMessage>;
  shoppingButton?: Maybe<ShoppingButton>;
  showRecommendedRateMessage: Scalars["Boolean"]["output"];
  standardBadge?: Maybe<LodgingStandardBadge>;
  totalPriceReassuranceCard?: Maybe<TotalPriceReassuranceCard>;
};

export type RatePlanAmenity = {
  __typename?: "RatePlanAmenity";
  additionalInformation?: Maybe<Scalars["String"]["output"]>;
  category?: Maybe<AmenityCategory>;
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["Int"]["output"]>;
};

export type RatePlanCallToAction = DatelessCheckAvailability | EtpDialog | LodgingForm | LodgingMemberSignInDialog;

export interface RatePlanInfo {
  childRatePlanDisplayName: Scalars["String"]["input"];
  childRatePlanID?: InputMaybe<Scalars["String"]["input"]>;
  parentRatePlanID: Scalars["String"]["input"];
  parentRatePlanName: Scalars["String"]["input"];
}

export type RatePlanMessage =
  | LodgingEnrichedMessage
  | LodgingPlainDialog
  | LodgingPlainMessage
  | LodgingPolicyDialog
  | LodgingPolicyPopover;

export type RatePlanStickyBar = {
  __typename?: "RatePlanStickyBar";
  ratePlanId: Scalars["String"]["output"];
  stickyBar: PropertyDetailsStickyBar;
};

export type RatePlansExpando = LodgingExpando & {
  __typename?: "RatePlansExpando";
  collapseButton: LodgingButton;
  content: RatePlansExpandoContent;
  expandButton: LodgingButton;
  items?: Maybe<Scalars["Int"]["output"]>;
  lines?: Maybe<Scalars["Int"]["output"]>;
};

export type RatePlansExpandoContent = {
  __typename?: "RatePlansExpandoContent";
  ratePlans: Array<RatePlan>;
};

export type RatesEgClickstreamData = {
  __typename?: "RatesEGClickstreamData";
  additional_context?: Maybe<SupplyOnboardingRatesEgClickstreamAdditionalContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
  product_list?: Maybe<Array<SupplyOnboardingRatesEgClickstreamProductList>>;
};

export type Rating = {
  __typename?: "Rating";
  badge: EgdsBadge;
  description?: Maybe<Scalars["String"]["output"]>;
};

export type ReasonCategory =
  | "ACCOUNT_UPDATE"
  | "CANCEL"
  | "CANCEL_OPAQUE"
  | "CHANGE"
  | "CHARGE"
  | "FINANCIAL_ADJUSTMENT"
  | "POINTS_ADJUSTMENT"
  | "PRICE_MATCH";

export type ReasonCodePrimaryElements = EgdsBasicSelect | SecondaryReasonList;

export type ReasonCodeSecondaryElements = EgdsTextAreaInputField | EgdsTextInputField;

export interface ReasonCodesInput {
  isOutsidePenalty: Scalars["Boolean"]["input"];
  isOverRefund?: InputMaybe<Scalars["Boolean"]["input"]>;
  lineOfBusinessType: LineOfBusinessType;
  reasonCategory: ReasonCategory;
  tripStage: TripStage;
  waivingSource: WaivingSource;
}

export interface ReasonCodesMutationInput {
  agentId?: InputMaybe<Scalars["String"]["input"]>;
  agentNotes?: InputMaybe<Scalars["String"]["input"]>;
  primaryReason: Scalars["String"]["input"];
  secondaryReason: Scalars["String"]["input"];
}

export type ReasonCodesView = {
  __typename?: "ReasonCodesView";
  primaryElements: Array<ReasonCodePrimaryElements>;
  secondaryElements?: Maybe<Array<ReasonCodeSecondaryElements>>;
};

export type ReassuranceExpando = {
  __typename?: "ReassuranceExpando";
  content: Array<ReassuranceExpandoContentListItem>;
  item?: Maybe<EgdsExpandoListItem>;
};

export type ReassuranceExpandoContentListItem = {
  __typename?: "ReassuranceExpandoContentListItem";
  graphic?: Maybe<UiGraphic>;
  price?: Maybe<Scalars["String"]["output"]>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type RecaptchaSafetyNetAndroidProviderConfig = {
  __typename?: "RecaptchaSafetyNetAndroidProviderConfig";
  siteKey: Scalars["String"]["output"];
};

export type RecaptchaWebProviderConfig = {
  __typename?: "RecaptchaWebProviderConfig";
  siteKey: Scalars["String"]["output"];
};

export type RecentActivityType = "BROWSING_HISTORY" | "RECENT_SEARCHES" | "VIEWED_OFFERS" | "VIEWED_PRODUCTS";

export type RecentlyViewedPropertiesCarouselWrapper = {
  __typename?: "RecentlyViewedPropertiesCarouselWrapper";
  /** @deprecated Use the index of NonPropertyCard instead, replace with NonPropertyCard::index */
  position?: Maybe<Scalars["Int"]["output"]>;
};

export type RecommendationAnalytics = {
  __typename?: "RecommendationAnalytics";
  event: EgClickstreamEvent;
  recommendationModule: RecommendationModule;
};

export type RecommendationAttribution = {
  __typename?: "RecommendationAttribution";
  closeContent?: Maybe<EgdsButton>;
  content: EgdsSpannableText;
  showContent?: Maybe<EgdsButton>;
};

export type RecommendationImageGallery = {
  __typename?: "RecommendationImageGallery";
  accessibilityText: Scalars["String"]["output"];
  aspectRatio: AspectRatio;
  media: Array<Image>;
  nextButtonAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  previousButtonAccessibilityText?: Maybe<Scalars["String"]["output"]>;
};

export type RecommendationMediaItem = {
  __typename?: "RecommendationMediaItem";
  attribution?: Maybe<RecommendationAttribution>;
  media?: Maybe<Media>;
};

export type RecommendationModule = {
  __typename?: "RecommendationModule";
  recommendationResponseId: Scalars["String"]["output"];
  recommendationTitle?: Maybe<Scalars["String"]["output"]>;
  recommendations: Array<Recommendations>;
};

export type Recommendations = {
  __typename?: "Recommendations";
  presentationDetailsCurrencyCode?: Maybe<Scalars["String"]["output"]>;
  presentationDetailsPriceDisplayed?: Maybe<Scalars["String"]["output"]>;
  presentationDetailsRecommendationName?: Maybe<Scalars["String"]["output"]>;
  recommendationId: Scalars["String"]["output"];
  recommendationPosition?: Maybe<Scalars["String"]["output"]>;
};

export type RecommendedCampaigns = {
  __typename?: "RecommendedCampaigns";
  campaigns: Array<CampaignDetail>;
  paginationInfo: CampaignPagination;
  responseId: Scalars["UUID"]["output"];
};

export type RecommendedOffer = AccessibilityField & {
  __typename?: "RecommendedOffer";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<LodgingHeader>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  priceDetails: Offer;
  units: Array<PropertyUnit>;
};

export type RecommendedType = "BESPOKE" | "DESTINATIONS" | "OFFERS";

export type RedirectBanner = {
  __typename?: "RedirectBanner";
  banner: UiBanner;
  inlineLink?: Maybe<EgdsInlineLink>;
};

export type ReducedDepositDetails = {
  __typename?: "ReducedDepositDetails";
  originalDepositAmount: Scalars["String"]["output"];
  primaryText: Scalars["String"]["output"];
  reducedDepositAmount: Scalars["String"]["output"];
  secondaryText: Scalars["String"]["output"];
};

export type RefreshAction = "LOGOUT" | "NONE";

export type RefundCreditsLedger = {
  __typename?: "RefundCreditsLedger";
  content: WalletRefundCreditsContent;
  title: Scalars["String"]["output"];
};

export type RefundFormMutationElement =
  | AgentToolsErrorBanner
  | AgentToolsSummaryCard
  | BookingConfirmation
  | LodgingRefundMutationError;

export interface RefundFormMutationInput {
  additionalRefundInput?: InputMaybe<Scalars["String"]["input"]>;
  adjustmentBreakdown?: InputMaybe<Array<AdjustmentBreakdownItemInput>>;
  approverContact?: InputMaybe<Scalars["String"]["input"]>;
  approverFirstName?: InputMaybe<Scalars["String"]["input"]>;
  approverLastName?: InputMaybe<Scalars["String"]["input"]>;
  approverTitle?: InputMaybe<Scalars["String"]["input"]>;
  expediaPortionRefund?: InputMaybe<Scalars["Float"]["input"]>;
  paymentAssistSessionId?: InputMaybe<Scalars["String"]["input"]>;
  reasonCodes?: InputMaybe<ReasonCodesMutationInput>;
  reasonCodesAdditional?: InputMaybe<ReasonCodesMutationInput>;
  supplierPortionRefund?: InputMaybe<Scalars["Float"]["input"]>;
  waivingSource?: InputMaybe<WaivingSource>;
}

export type RefundFormMutationResponse = {
  __typename?: "RefundFormMutationResponse";
  refundFormMutationElement: RefundFormMutationElement;
  reportedEvents?: Maybe<Scalars["String"]["output"]>;
  status: RefundStatus;
};

export type RefundMode = "FTC" | "NO_VALUE" | "REFUND";

export type RefundSourcePortionErrorMessages = {
  __typename?: "RefundSourcePortionErrorMessages";
  inputRequired: Scalars["String"]["output"];
  withinRange: Scalars["String"]["output"];
};

export type RefundStatus = "Failure" | "Success";

export type RefundType = "FTC" | "NO_VALUE" | "REFUND";

export type RefundableType = "FULLY" | "NON" | "PARTIALLY" | "UNKNOWN";

export type Region = {
  __typename?: "Region";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  shortName: Scalars["String"]["output"];
  type?: Maybe<RegionType>;
};

export type RegionCompression = {
  __typename?: "RegionCompression";
  level: CompressionLevel;
  regionId: Scalars["String"]["output"];
  regionName: Scalars["String"]["output"];
  score: Scalars["Int"]["output"];
};

export type RegionType =
  | "ADDRESS"
  | "AIRPORT"
  | "CITY"
  | "CONTINENT"
  | "COUNTRY"
  | "HIGH_LEVEL_REGION"
  | "METRO_STATION"
  | "MULTI_CITY_VICINITY"
  | "NEIGHBORHOOD"
  | "POINT_OF_INTEREST"
  | "PROVINCE_STATE"
  | "TRAIN_STATION"
  | "UNKNOWN";

export type RegulatoryContentDetails = {
  __typename?: "RegulatoryContentDetails";
  regulatoryContentSection?: Maybe<RegulatoryContentSection>;
};

export type RegulatoryContentSection = {
  __typename?: "RegulatoryContentSection";
  content: EgdsBulletedList;
  title: Scalars["String"]["output"];
};

export type RelativeUri = Uri & {
  __typename?: "RelativeURI";
  value: Scalars["String"]["output"];
};

export type RelevantArticles = {
  __typename?: "RelevantArticles";
  helpArticleId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type RelevantContent = {
  __typename?: "RelevantContent";
  content: ProductAmenitiesSection;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type RelevantHelpArticleSection = {
  __typename?: "RelevantHelpArticleSection";
  articleLinkIcon?: Maybe<Icon>;
  relevantArticles?: Maybe<Array<RelevantArticles>>;
  relevantArticlesIcon?: Maybe<Icon>;
  relevantArticlesLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated waitMessage is moved to getNewSearchBar query */
  waitMessage?: Maybe<Scalars["String"]["output"]>;
};

export type RelevantHelpArticlesView = HelpArticleSearchErrorView | RelevantHelpArticleSection;

export type RemoveItemResults = {
  __typename?: "RemoveItemResults";
  result: Result;
};

export interface RemovePaymentMethodRequestInput {
  paymentMethod: PaymentMethodInput;
  sessionId: Scalars["String"]["input"];
  sessionToken: Scalars["String"]["input"];
}

export type RentalProtectionCard = {
  __typename?: "RentalProtectionCard";
  clickAction: CarActionableItem;
  dialog: RentalProtectionDialog;
  infos: Array<RentalProtectionInfoLineItem>;
  offerBadge?: Maybe<CarOfferBadge>;
  pricePeriod?: Maybe<Scalars["String"]["output"]>;
  priceSummary?: Maybe<Scalars["String"]["output"]>;
  seeDetailsAction: CarActionableItem;
  selected: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
};

export type RentalProtectionDialog = {
  __typename?: "RentalProtectionDialog";
  content: Array<RentalProtectionDialogContent>;
  description: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type RentalProtectionDialogContent = {
  __typename?: "RentalProtectionDialogContent";
  description: Array<RentalProtectionInfoLineItem>;
  title: Scalars["String"]["output"];
};

export type RentalProtectionInfo = {
  __typename?: "RentalProtectionInfo";
  rentalProtectionCard: Array<RentalProtectionCard>;
  title: Scalars["String"]["output"];
};

export type RentalProtectionInfoLineItem = {
  __typename?: "RentalProtectionInfoLineItem";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type ReportFilterMenu = {
  __typename?: "ReportFilterMenu";
  closeIcon: Icon;
  defaultText?: Maybe<Scalars["String"]["output"]>;
  doneButton: UiPrimaryButton;
  pill: EgdsBasicPill;
  searchTypeahead: EgdsTypeaheadMultiSelect;
  /** @deprecated This field is no longer used, and typeAheadSelectionOptions should be used instead */
  typeAheadData: Array<Scalars["String"]["output"]>;
  typeAheadSelectionOptions: Array<ReportSelectionOptions>;
};

export type ReportPillMenu = {
  __typename?: "ReportPillMenu";
  defaultText?: Maybe<Scalars["String"]["output"]>;
  menu: EgdsTextMenu;
  pill: EgdsBasicPill;
};

export type ReportProblemForm = {
  __typename?: "ReportProblemForm";
  analytics?: Maybe<Array<AnalyticsElement>>;
  comments?: Maybe<EgdsTextAreaInputField>;
  dismissForm: EgdsButton;
  options?: Maybe<Array<VirtualAgentControlFormInput>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  submitForm: EgdsButton;
};

export type ReportSelectMenu = {
  __typename?: "ReportSelectMenu";
  defaultText?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  select: EgdsBasicSelect;
};

export type ReportSelectionOptions = {
  __typename?: "ReportSelectionOptions";
  adgroupId?: Maybe<Scalars["String"]["output"]>;
  adgroupName?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["String"]["output"]>;
  campaignName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ReportingFilters = ReportFilterMenu | ReportPillMenu | ReportSelectMenu;

export type ReportingOptionFilters = {
  __typename?: "ReportingOptionFilters";
  clearAllButton: EgdsBasicPill;
  filterText?: Maybe<Scalars["String"]["output"]>;
  filters: Array<ReportingFilters>;
};

export interface ReservationContext {
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  isManualBookingVr?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservationId?: InputMaybe<Scalars["String"]["input"]>;
}

export type ReservationModifiersBoldText = {
  __typename?: "ReservationModifiersBoldText";
  text: Scalars["String"]["output"];
};

export type ReservationModifiersCallToAction = {
  __typename?: "ReservationModifiersCallToAction";
  actions: SupplyReservationsActions;
  content?: Maybe<Array<ReservationModifiersSpannableText>>;
  icon?: Maybe<Icon>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subtitle?: Maybe<Array<ReservationModifiersSpannableText>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationModifiersCallout = {
  __typename?: "ReservationModifiersCallout";
  content: Scalars["String"]["output"];
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersCancellationFeeCustomOption = {
  __typename?: "ReservationModifiersCancellationFeeCustomOption";
  a11yLabel?: Maybe<Scalars["String"]["output"]>;
  inputA11yLabel?: Maybe<Scalars["String"]["output"]>;
  inputLabel: Scalars["String"]["output"];
  inputNote: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  optionType: ReservationModifiersCancellationFeeOptionType;
};

export type ReservationModifiersCancellationFeeOption =
  | ReservationModifiersCancellationFeeCustomOption
  | ReservationModifiersCancellationFeeStandardOption;

export type ReservationModifiersCancellationFeeOptionType = "CUSTOM_FEE" | "FULL_FEE" | "NO_FEE";

export type ReservationModifiersCancellationFeeStandardOption = {
  __typename?: "ReservationModifiersCancellationFeeStandardOption";
  a11yLabel?: Maybe<Scalars["String"]["output"]>;
  amount: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  optionType: ReservationModifiersCancellationFeeOptionType;
  sublabel?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationModifiersChangeInfo = {
  __typename?: "ReservationModifiersChangeInfo";
  label: Scalars["String"]["output"];
  note?: Maybe<Scalars["String"]["output"]>;
  onTooltipClosedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onTooltipSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  strikethroughValue?: Maybe<Scalars["Boolean"]["output"]>;
  tooltipA11yLabel?: Maybe<Scalars["String"]["output"]>;
  tooltipText?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type ReservationModifiersEmpathyItem = {
  __typename?: "ReservationModifiersEmpathyItem";
  content: ReservationModifiersSpannableText;
  icon: Icon;
};

export type ReservationModifiersEmpathyScreen = {
  __typename?: "ReservationModifiersEmpathyScreen";
  actions: SupplyReservationsActions;
  content: Array<ReservationModifiersEmpathyItem>;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  subtitle: ReservationModifiersSpannableText;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersErrorBanner = {
  __typename?: "ReservationModifiersErrorBanner";
  details: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersInlineLink = {
  __typename?: "ReservationModifiersInlineLink";
  action: SupplyReservationsAction;
  actions: SupplyReservationsActions;
  text: Scalars["String"]["output"];
};

export type ReservationModifiersLinkToLegacyScreen = {
  __typename?: "ReservationModifiersLinkToLegacyScreen";
  location: Scalars["String"]["output"];
  screenType: ReservationModifiersScreenType;
};

export type ReservationModifiersMenuOption = {
  __typename?: "ReservationModifiersMenuOption";
  icon: Icon;
  label: Scalars["String"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  type: ReservationModifiersType;
};

export type ReservationModifiersMenuScreen = {
  __typename?: "ReservationModifiersMenuScreen";
  errorBanner: ReservationModifiersErrorBanner;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  options: Array<ReservationModifiersMenuOption>;
  preface: Scalars["String"]["output"];
  screenType: ReservationModifiersScreenType;
  subTitle?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationModifiersRefundAmountCustomOption = {
  __typename?: "ReservationModifiersRefundAmountCustomOption";
  customAmountRegex: Scalars["String"]["output"];
  decimalPlaces: Scalars["Int"]["output"];
  errorMessage: Scalars["String"]["output"];
  inputLabel: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  maxRefundAmount: Scalars["String"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  optionType: ReservationModifiersRefundAmountOptionType;
};

export type ReservationModifiersRefundAmountOption =
  | ReservationModifiersRefundAmountCustomOption
  | ReservationModifiersRefundAmountPercentageOption;

export type ReservationModifiersRefundAmountOptionType = "CUSTOM_AMOUNT" | "PERCENTAGE_AMOUNT";

export type ReservationModifiersRefundAmountPercentageOption = {
  __typename?: "ReservationModifiersRefundAmountPercentageOption";
  amountLabel: Scalars["String"]["output"];
  bookingAmount: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
  inputLabel: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  locale: Scalars["String"]["output"];
  maxRefundPercentage: Scalars["String"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  optionType: ReservationModifiersRefundAmountOptionType;
};

export type ReservationModifiersReviewChangesScreen = {
  __typename?: "ReservationModifiersReviewChangesScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  content: Array<ReservationModifiersChangeInfo>;
  errorBanner: ReservationModifiersErrorBanner;
  headerIcon?: Maybe<Icon>;
  icon: Icon;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersReviewTermsContentItem = {
  __typename?: "ReservationModifiersReviewTermsContentItem";
  content: ReservationModifiersSpannableText;
  icon: Icon;
  title: Scalars["String"]["output"];
  titleTheme?: Maybe<TextTheme>;
};

export type ReservationModifiersReviewTermsScreen = {
  __typename?: "ReservationModifiersReviewTermsScreen";
  actions: SupplyReservationsActions;
  content: Array<ReservationModifiersReviewTermsContentItem>;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ReservationModifiersScreen =
  | ReservationModifiersEmpathyScreen
  | ReservationModifiersLinkToLegacyScreen
  | ReservationModifiersMenuScreen
  | ReservationModifiersReviewChangesScreen
  | ReservationModifiersReviewTermsScreen
  | ReservationModifiersSetCancellationFeeScreen
  | ReservationModifiersSetCancellationReasonScreen
  | ReservationModifiersSetMessageToGuestScreen
  | ReservationModifiersSetRefundAmountScreen
  | ReservationModifiersSetRefundReasonScreen
  | ReservationModifiersSetStayDatesScreen
  | ReservationModifiersSuccessScreen
  | ReservationModifiersVrSetRefundAmountScreen;

export type ReservationModifiersScreenType =
  | "CONFIRM_DETAILS"
  | "EMPATHY"
  | "LINK_TO_LEGACY"
  | "MENU"
  | "REVIEW_CHANGES"
  | "REVIEW_TERMS"
  | "SET_CANCELLATION_FEE"
  | "SET_CANCELLATION_REASON"
  | "SET_MESSAGE_TO_GUEST"
  | "SET_REFUND_AMOUNT"
  | "SET_REFUND_REASON"
  | "SET_STAY_DATES"
  | "SUCCESS"
  | "VR_SET_REFUND_AMOUNT";

export type ReservationModifiersSetCancellationFeeScreen = {
  __typename?: "ReservationModifiersSetCancellationFeeScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  currency: Scalars["String"]["output"];
  customFeeErrorMessage: Scalars["String"]["output"];
  customFeeRegex: Scalars["String"]["output"];
  decimalPlaces: Scalars["Int"]["output"];
  displayFullFeeAmount?: Maybe<Scalars["Boolean"]["output"]>;
  errorBanner: ReservationModifiersErrorBanner;
  fullFeeAmount: Scalars["String"]["output"];
  guestPaymentAmount: Scalars["String"]["output"];
  guestPaymentText: Scalars["String"]["output"];
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  options: Array<ReservationModifiersCancellationFeeOption>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ReservationModifiersSetCancellationReasonScreen = {
  __typename?: "ReservationModifiersSetCancellationReasonScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  conditionalContent?: Maybe<Array<SetCancellationReasonConditionalContent>>;
  dropdown: EgdsSelect;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onOpenedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersSetMessageToGuestScreen = {
  __typename?: "ReservationModifiersSetMessageToGuestScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  errorBanner: ReservationModifiersErrorBanner;
  input: EgdsTextAreaInputField;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersSetRefundAmountScreen = {
  __typename?: "ReservationModifiersSetRefundAmountScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  availableAmount: Scalars["String"]["output"];
  availableAmountLabel: Scalars["String"]["output"];
  errorBanner: ReservationModifiersErrorBanner;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  refundAmountOptions: Array<ReservationModifiersRefundAmountOption>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ReservationModifiersSetRefundReasonScreen = {
  __typename?: "ReservationModifiersSetRefundReasonScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  dropdown: EgdsSelect;
  dropdownPrompt: Scalars["String"]["output"];
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onOpenedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ReservationModifiersSetStayDatesDateSelector = {
  __typename?: "ReservationModifiersSetStayDatesDateSelector";
  changeInfo: Scalars["String"]["output"];
  endDateInputLabel: Scalars["String"]["output"];
  onCheckinSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onCheckoutSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  originalCheckInDate: Scalars["String"]["output"];
  originalCheckOutDate: Scalars["String"]["output"];
  startDateInputLabel: Scalars["String"]["output"];
  submitButtonLabel: Scalars["String"]["output"];
};

export type ReservationModifiersSetStayDatesScreen = {
  __typename?: "ReservationModifiersSetStayDatesScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  dateSelector: ReservationModifiersSetStayDatesDateSelector;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onReasonOpenedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onReasonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reasonForChangeDropdown: EgdsSelect;
  reasonForChangeInvalid: Scalars["String"]["output"];
  reasonForChangeLabel: Scalars["String"]["output"];
  screenType: ReservationModifiersScreenType;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ReservationModifiersSpannableText = {
  __typename?: "ReservationModifiersSpannableText";
  inlineContent: Array<ReservationModifiersSpannableTextItem>;
};

export type ReservationModifiersSpannableTextItem =
  | ReservationModifiersBoldText
  | ReservationModifiersInlineLink
  | ReservationModifiersText;

export type ReservationModifiersSubsidiaryData = {
  __typename?: "ReservationModifiersSubsidiaryData";
  originalBookedDates: Scalars["String"]["output"];
  originalBookingAmount: Scalars["String"]["output"];
  originalBookingCurrency: Scalars["String"]["output"];
  paymentMethod: Scalars["String"]["output"];
  remittanceType?: Maybe<LodgingSupplierRemittanceType>;
  travelerName: Scalars["String"]["output"];
};

export type ReservationModifiersSuccessScreen = {
  __typename?: "ReservationModifiersSuccessScreen";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  callToAction?: Maybe<ReservationModifiersCallToAction>;
  callout?: Maybe<ReservationModifiersCallout>;
  details: Scalars["String"]["output"];
  icon: Icon;
  messageToGuestErrorBanner?: Maybe<ReservationModifiersErrorBanner>;
  nextStepItems?: Maybe<Array<Scalars["String"]["output"]>>;
  nextStepTitle?: Maybe<Scalars["String"]["output"]>;
  nextSteps?: Maybe<Array<ReservationModifiersSpannableText>>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
};

export type ReservationModifiersText = {
  __typename?: "ReservationModifiersText";
  text: Scalars["String"]["output"];
};

export type ReservationModifiersToolbar = {
  __typename?: "ReservationModifiersToolbar";
  primaryAction: SupplyReservationsAction;
  secondaryAction?: Maybe<SupplyReservationsAction>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  subtitleA11yLabel?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ReservationModifiersType = "CANCEL" | "CHANGE_DATES" | "GUEST_SATISFACTION_REFUND" | "NO_SHOW";

export type ReservationModifiersVrCancelRefundLevel = "FULL" | "HALF" | "ZERO";

export type ReservationModifiersVrSetRefundAmountScreen = {
  __typename?: "ReservationModifiersVRSetRefundAmountScreen";
  actions: SupplyReservationsActions;
  conditionalRefundOptionsContent?: Maybe<Array<SetRefundAmountVrConditionalRefundOptionContent>>;
  nextScreenType?: Maybe<ReservationModifiersScreenType>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  screenType: ReservationModifiersScreenType;
  selectedRefundOption?: Maybe<ReservationModifiersVrCancelRefundLevel>;
  subtitle?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type ResidencyDetails = {
  __typename?: "ResidencyDetails";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  regionCode?: Maybe<Scalars["String"]["output"]>;
};

export interface ResidencyDetailsInput {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  regionCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type ResidencyState = "APPLIED" | "AVAILABLE";

export type ResourceIdentifier = {
  __typename?: "ResourceIdentifier";
  id: Scalars["String"]["output"];
  type: DataSourceType;
};

export type ResponseStatus = "ERROR" | "SUCCESS";

export type ResponseTagType =
  | "CACHE_SOURCE_AIRBLAZE"
  | "CACHE_SOURCE_BYOT_AIRBLAZE"
  | "EXPERIENCE_SEARCH_CACHE_HYDRATOR"
  | "LISTINGS_PAGINATED_SLICED"
  | "LISTING_CACHE_SOURCE"
  | "LISTING_DOWNLEVEL_SOURCE"
  | "MAIN_SEARCH_STACK"
  | "RESPONSE_SUMMARY_CACHED_CONFIG"
  | "RESPONSE_SUMMARY_CACHED_ML"
  | "RESPONSE_SUMMARY_HYBRID"
  | "RESPONSE_SUMMARY_LIVE"
  | "RESPONSE_SUMMARY_REFINEMENTS_CACHE_LIVE"
  | "RESPONSE_SUMMARY_REFINEMENTS_CACHE_REDIS"
  | "RESPONSE_SUMMARY_REFINEMENTS_USER_CACHE"
  | "TEST_SEARCH_STACK"
  | "UNRECOGNIZED";

export type Result = "ERROR" | "SUCCESS";

export type ResultSectionHeading = {
  __typename?: "ResultSectionHeading";
  heading: Scalars["String"]["output"];
  index: Scalars["Int"]["output"];
};

export type ResultTitleModel = {
  __typename?: "ResultTitleModel";
  header?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<Scalars["URL"]["output"]>;
  subHeader?: Maybe<Scalars["String"]["output"]>;
};

export type ResultsHeaderMessage = {
  __typename?: "ResultsHeaderMessage";
  subMessages?: Maybe<Array<ResultsHeaderSubMessage>>;
};

export type ResultsHeaderSubMessage = {
  __typename?: "ResultsHeaderSubMessage";
  icon?: Maybe<Icon>;
  link?: Maybe<Scalars["URL"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  referrerId?: Maybe<Scalars["String"]["output"]>;
};

export type RetailTravelAgentMutateNotificationResult = {
  __typename?: "RetailTravelAgentMutateNotificationResult";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type RetailTravelAgentNotification = {
  __typename?: "RetailTravelAgentNotification";
  content: EgdsStylizedText;
  date: Scalars["String"]["output"];
  dismissButton: RetailTravelAgentNotificationDismissButton;
  heading: EgdsStylizedText;
  icon?: Maybe<Icon>;
  id: Scalars["ID"]["output"];
  link: EgdsStandardLink;
  readIndicator?: Maybe<Icon>;
};

export type RetailTravelAgentNotificationDismissButton = {
  __typename?: "RetailTravelAgentNotificationDismissButton";
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type RetailTravelAgentNotificationResult = {
  __typename?: "RetailTravelAgentNotificationResult";
  footerText: Scalars["String"]["output"];
  notifications: Array<RetailTravelAgentNotification>;
  title: Scalars["String"]["output"];
};

export type RetailTravelAgentRewardsResult = {
  __typename?: "RetailTravelAgentRewardsResult";
  heading: EgdsStylizedText;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  inLineLink: EgdsStandardLink;
  justEnrolledButton?: Maybe<UiTertiaryButton>;
  justEnrolledPopOver?: Maybe<PopOverContent>;
  paragraph: EgdsStylizedText;
  refreshedAt: EgdsStylizedText;
  rewardsProgramState: RewardsProgramState;
  startEarningPointsButton?: Maybe<UiSecondaryButton>;
  tierCards?: Maybe<Array<RetailTravelAgentTierCards>>;
  title: Scalars["String"]["output"];
};

export type RetailTravelAgentTierCards = {
  __typename?: "RetailTravelAgentTierCards";
  background: BackgroundType;
  descriptionPoints: EgdsStylizedText;
  descriptionPointsValue: EgdsStylizedText;
  headingPoints: EgdsStylizedText;
  id: Scalars["ID"]["output"];
  infoIcon: Icon;
  moreInfoPopOver: PopOverContent;
  popOverCTA: EgdsStandardLink;
  redeemYourPointsButton?: Maybe<UiSecondaryButton>;
  token: Scalars["String"]["output"];
};

export type RetryMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "RetryMultiItemShoppingAction";
  shoppingContext: ShoppingContext;
};

export type ReviewAnswer = {
  __typename?: "ReviewAnswer";
  answer?: Maybe<ReviewsFormRatingValue>;
  questionUrn: Scalars["String"]["output"];
};

export interface ReviewAnswerInput {
  answer?: InputMaybe<ReviewsFormRatingValueInput>;
  questionUrn: Scalars["String"]["input"];
}

export type ReviewAnswersResponse = {
  __typename?: "ReviewAnswersResponse";
  reviewAnswers?: Maybe<Array<ReviewAnswer>>;
};

export type ReviewBreakdown = {
  __typename?: "ReviewBreakdown";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  percentage: Scalars["Int"]["output"];
  score?: Maybe<Scalars["String"]["output"]>;
};

export type ReviewCancellationFooter = {
  __typename?: "ReviewCancellationFooter";
  button: BookingServicingButton;
  footerText: Scalars["String"]["output"];
};

export type ReviewCancellationPresentation = {
  __typename?: "ReviewCancellationPresentation";
  content: Array<BookingServicingCancellationContentElement>;
  displayAnalytics: Array<ClientSideAnalytics>;
  heading: Scalars["String"]["output"];
};

export type ReviewCardItem = ReviewCardListItem | ReviewCardTextItem;

export type ReviewCardLineItem = {
  __typename?: "ReviewCardLineItem";
  title: Scalars["String"]["output"];
  value?: Maybe<ReviewCardItem>;
};

export type ReviewCardListItem = {
  __typename?: "ReviewCardListItem";
  values: Array<Scalars["String"]["output"]>;
};

export type ReviewCardTextItem = {
  __typename?: "ReviewCardTextItem";
  value: Scalars["String"]["output"];
};

export type ReviewFlag = {
  __typename?: "ReviewFlag";
  reviewFlagDialog?: Maybe<PropertyReviewFlagDialog>;
  reviewFlagToast?: Maybe<LodgingToastMessage>;
};

export type ReviewFooter = {
  __typename?: "ReviewFooter";
  primary?: Maybe<EgdsStylizedText>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ReviewInteractionType =
  | "HELPFUL_REVIEW"
  | "REVIEW_COLLAPSE_LABEL"
  | "REVIEW_EXPAND_LABEL"
  | "REVIEW_REPORT_FLAG";

export type ReviewLineItem = {
  __typename?: "ReviewLineItem";
  heading: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ReviewScoreRange = CriteriaRange & {
  __typename?: "ReviewScoreRange";
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
};

export interface ReviewScoreRangeInput {
  max?: InputMaybe<Scalars["Float"]["input"]>;
  min?: InputMaybe<Scalars["Float"]["input"]>;
}

export type ReviewSource = "EXTERNAL_REVIEWS" | "INTERNAL_REVIEWS";

export type ReviewSummary = {
  __typename?: "ReviewSummary";
  footer?: Maybe<ReviewFooter>;
  highlightedText: EgdsSpannableText;
};

export type ReviewSummaryDetail = {
  __typename?: "ReviewSummaryDetail";
  /** @deprecated Overall formatted rating over maximum rating, instead use formattedRatingOutOfMaxA11y , replace with formattedRatingOutOfMaxA11y */
  formattedRatingOutOfMax: Scalars["String"]["output"];
  formattedRatingOutOfMaxA11y: LodgingEnrichedMessage;
  label: Scalars["String"]["output"];
  ratingPercentage: Scalars["Int"]["output"];
};

export type ReviewSummaryHighlight = {
  __typename?: "ReviewSummaryHighlight";
  content: Array<Scalars["String"]["output"]>;
  header?: Maybe<EgdsGraphicText>;
};

export type ReviewSummaryInfo = {
  __typename?: "ReviewSummaryInfo";
  content: ProductAmenitiesSection;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
};

export type ReviewThemes = {
  __typename?: "ReviewThemes";
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type ReviewsCarouselCtaLocation = "TRIPS_PAGE" | "UNKNOWN";

export type ReviewsCarouselCard = ProductCarouselCard & {
  __typename?: "ReviewsCarouselCard";
  footer?: Maybe<ReviewFooter>;
  heading?: Maybe<EgdsStylizedText>;
  id?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  link?: Maybe<EgdsStandardLink>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type ReviewsCollectionForm = {
  __typename?: "ReviewsCollectionForm";
  button?: Maybe<ReviewsCollectionFormSubmitButton>;
  message: Scalars["String"]["output"];
  productDetails: EgdsCard;
  sectionGroups: Array<ReviewsCollectionFormSectionGroup>;
};

export type ReviewsCollectionFormBoolRating = {
  __typename?: "ReviewsCollectionFormBoolRating";
  boolValue?: Maybe<Scalars["Boolean"]["output"]>;
  default?: Maybe<Scalars["Boolean"]["output"]>;
  falseValue: ReviewsCollectionFormRatingValue;
  trueValue: ReviewsCollectionFormRatingValue;
};

export type ReviewsCollectionFormCustomValue = {
  __typename?: "ReviewsCollectionFormCustomValue";
  customValue?: Maybe<ReviewsCollectionFormStringRating>;
  value?: Maybe<ReviewsCollectionFormRatingValue>;
};

export type ReviewsCollectionFormDependentQuestions = {
  __typename?: "ReviewsCollectionFormDependentQuestions";
  answers: Array<Scalars["String"]["output"]>;
  questionUrn?: Maybe<Scalars["String"]["output"]>;
};

export type ReviewsCollectionFormNumberRating = {
  __typename?: "ReviewsCollectionFormNumberRating";
  default: Scalars["Int"]["output"];
  maxValue: Scalars["Int"]["output"];
  minValue: Scalars["Int"]["output"];
  numberValue: Scalars["Int"]["output"];
  values: Array<ReviewsCollectionFormRatingValue>;
};

export type ReviewsCollectionFormQuestion = {
  __typename?: "ReviewsCollectionFormQuestion";
  dependentQuestions: Array<ReviewsCollectionFormDependentQuestions>;
  description: Scalars["String"]["output"];
  isRequired: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  rating: ReviewsCollectionFormQuestionRating;
  urn: Scalars["String"]["output"];
};

export type ReviewsCollectionFormQuestionRating =
  | ReviewsCollectionFormBoolRating
  | ReviewsCollectionFormNumberRating
  | ReviewsCollectionFormStringRating
  | ReviewsCollectionFormTagRating;

export type ReviewsCollectionFormRatingValue = {
  __typename?: "ReviewsCollectionFormRatingValue";
  description: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  tagUrn: Scalars["String"]["output"];
};

export type ReviewsCollectionFormSection = {
  __typename?: "ReviewsCollectionFormSection";
  description: Scalars["String"]["output"];
  questions: Array<ReviewsCollectionFormQuestion>;
};

export type ReviewsCollectionFormSectionGroup = {
  __typename?: "ReviewsCollectionFormSectionGroup";
  description: Scalars["String"]["output"];
  sections: Array<ReviewsCollectionFormSection>;
};

export type ReviewsCollectionFormStringRating = {
  __typename?: "ReviewsCollectionFormStringRating";
  default: Scalars["String"]["output"];
  maxLength: Scalars["Int"]["output"];
  minLength: Scalars["Int"]["output"];
  placeholder: Scalars["String"]["output"];
  regex: Scalars["String"]["output"];
  stringValue: Scalars["String"]["output"];
};

export type ReviewsCollectionFormSubmitButton = {
  __typename?: "ReviewsCollectionFormSubmitButton";
  submitButton: EgdsButton;
};

export type ReviewsCollectionFormTagRating = {
  __typename?: "ReviewsCollectionFormTagRating";
  customTagValue?: Maybe<ReviewsCollectionFormCustomValue>;
  isMultiValue: Scalars["Boolean"]["output"];
  selectedTagValues: Array<ReviewsCollectionFormRatingValue>;
  tagUrns: Array<Scalars["String"]["output"]>;
  tagValues: Array<ReviewsCollectionFormRatingValue>;
};

export type ReviewsFormRatingValue = {
  __typename?: "ReviewsFormRatingValue";
  boolValue?: Maybe<Scalars["Boolean"]["output"]>;
  numberValue?: Maybe<Scalars["Int"]["output"]>;
  stringValue?: Maybe<Scalars["String"]["output"]>;
  tagValue?: Maybe<ReviewsFormTagValue>;
};

export interface ReviewsFormRatingValueInput {
  boolValue?: InputMaybe<Scalars["Boolean"]["input"]>;
  numberValue?: InputMaybe<Scalars["Int"]["input"]>;
  stringValue?: InputMaybe<Scalars["String"]["input"]>;
  tagValue?: InputMaybe<ReviewsFormTagValueInput>;
}

export type ReviewsFormTagValue = {
  __typename?: "ReviewsFormTagValue";
  customValue?: Maybe<Scalars["String"]["output"]>;
  tagUrns: Array<Scalars["String"]["output"]>;
};

export interface ReviewsFormTagValueInput {
  customValue?: InputMaybe<Scalars["String"]["input"]>;
  tagUrns: Array<Scalars["String"]["input"]>;
}

export type ReviewsOverview = {
  __typename?: "ReviewsOverview";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  carousel?: Maybe<ProductCarousel>;
  heading?: Maybe<EgdsStylizedText>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  noResultsMessage?: Maybe<EgdsStylizedText>;
  overallRating?: Maybe<Array<EgdsStylizedText>>;
  primaryDisclaimer?: Maybe<ProductTextInfoSection>;
  secondaryDisclaimer?: Maybe<Scalars["String"]["output"]>;
  seeAllReviewsButton?: Maybe<UiSecondaryButton>;
  superlative?: Maybe<EgdsStylizedText>;
};

export type ReviewsSocialInteractionElement = {
  __typename?: "ReviewsSocialInteractionElement";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<SocialInteractionElementAnalyticEvent>;
  primaryIcon: EgdsStandardBadge;
  primaryText: Scalars["String"]["output"];
  propertyId?: Maybe<Scalars["String"]["output"]>;
};

export type RewardTierDescription = {
  __typename?: "RewardTierDescription";
  badge?: Maybe<EgdsLoyaltyBadge>;
  description?: Maybe<EgdsStylizedText>;
  subheadline?: Maybe<EgdsStylizedText>;
};

export type RewardsOption = "ALTAIR" | "AVION" | "NONAVION";

export type RewardsPointSelectionEnum = "APPLY_REWARD_POINTS" | "DO_NOT_APPLY_REWARD_POINTS";

export type RewardsProgramState = "ENROLLED" | "NOT_ENROLLED";

export type RewardsTierTripElementDescriptionSection = {
  __typename?: "RewardsTierTripElementDescriptionSection";
  footnote?: Maybe<EgdsStylizedText>;
  tierDescriptions?: Maybe<Array<RewardTierDescription>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type RewardsTripElementsHeader = {
  __typename?: "RewardsTripElementsHeader";
  brandIcons?: Maybe<Array<UiGraphic>>;
  subtitle?: Maybe<EgdsStylizedText>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type RewardsTripElementsLobSection = {
  __typename?: "RewardsTripElementsLobSection";
  elements?: Maybe<Array<TripElement>>;
};

export type RewardsTripElementsSheet = {
  __typename?: "RewardsTripElementsSheet";
  descriptionSection?: Maybe<RewardsTierTripElementDescriptionSection>;
  dismissButton?: Maybe<UiTertiaryButton>;
  elementsSection?: Maybe<RewardsTripElementsLobSection>;
  header?: Maybe<RewardsTripElementsHeader>;
  impressionAnalytics?: Maybe<ClickstreamAnalyticsData>;
};

export type RichText = {
  __typename?: "RichText";
  value?: Maybe<Scalars["String"]["output"]>;
};

export type RnRContainer = {
  __typename?: "RnRContainer";
  rnrProductPolicies: Array<ProductPolicy>;
  rnrToolbarTitle: Scalars["String"]["output"];
};

export type RnRDetails = {
  __typename?: "RnRDetails";
  rnrContainer: RnRContainer;
};

export type Room = {
  __typename?: "Room";
  adults: Scalars["Int"]["output"];
  children?: Maybe<Array<Child>>;
};

export type RoomInfo = {
  __typename?: "RoomInfo";
  adults: Scalars["Int"]["output"];
  children: Array<Scalars["Int"]["output"]>;
};

export interface RoomInput {
  adults: Scalars["Int"]["input"];
  children?: InputMaybe<Array<ChildInput>>;
}

export type RoomOccupants = {
  __typename?: "RoomOccupants";
  adults?: Maybe<Scalars["Int"]["output"]>;
  agesOfChildren?: Maybe<Array<Scalars["Int"]["output"]>>;
  totalOccupants?: Maybe<Scalars["Int"]["output"]>;
};

export type RoomSoldOutDialog = {
  __typename?: "RoomSoldOutDialog";
  actionButton: UiTertiaryButton;
  description: Scalars["String"]["output"];
  primaryButton: EgdsButton;
  title: Scalars["String"]["output"];
};

export type RoomSoldOutMessaging = {
  __typename?: "RoomSoldOutMessaging";
  dialog: RoomSoldOutDialog;
};

export type RoomSummary = {
  __typename?: "RoomSummary";
  id: Scalars["String"]["output"];
  roomRatingListTitle?: Maybe<Scalars["String"]["output"]>;
  score?: Maybe<Scalars["String"]["output"]>;
  scoreLabel?: Maybe<Scalars["String"]["output"]>;
  tags: Array<Tag>;
};

export type Rooms = {
  __typename?: "Rooms";
  childrenAges?: Maybe<Array<Scalars["Int"]["output"]>>;
  numAdults: Scalars["Int"]["output"];
  numChildren?: Maybe<Scalars["Int"]["output"]>;
};

export type RouteType = "MULTI_DESTINATION" | "ONE_WAY" | "ROUND_TRIP";

export type SeoStructuredData = {
  __typename?: "SEOStructuredData";
  content?: Maybe<Scalars["String"]["output"]>;
  itemprop: Scalars["String"]["output"];
  itemscope: Scalars["Boolean"]["output"];
  itemtype?: Maybe<Scalars["URL"]["output"]>;
};

export type SmsToUri = Uri & {
  __typename?: "SMSToURI";
  body: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type Sailing = {
  __typename?: "Sailing";
  action: UiLinkAction;
  cruiseProductGallery?: Maybe<CruiseProductGallery>;
  /** @deprecated Use subtitle inside offer instead */
  description?: Maybe<Scalars["String"]["output"]>;
  exploreMapButton: CruiseExploreMapButton;
  /** @deprecated Use iconMedia which used EGDS MediaItem instead */
  icon?: Maybe<CruiseMedia>;
  iconMedia?: Maybe<CruiseMediaItem>;
  /** @deprecated Use imageMedia which used EGDS MediaItem instead */
  image?: Maybe<CruiseMedia>;
  imageMedia?: Maybe<CruiseMediaItem>;
  images?: Maybe<Array<CruiseMediaItem>>;
  /** @deprecated Use logoMedia which used EGDS MediaItem instead */
  logo?: Maybe<CruiseMedia>;
  logoMedia?: Maybe<CruiseMediaItem>;
  offer?: Maybe<SailingOffer>;
  pinnedSailingText?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<CruisePrice>;
  sailingToken?: Maybe<Scalars["String"]["output"]>;
  signInLink?: Maybe<CruiseSailingSearchLink>;
  sponsoredSailingAdText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use title inside offer instead */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SailingAction = {
  __typename?: "SailingAction";
  action: UiAction;
  content: Scalars["String"]["output"];
  descriptionText?: Maybe<Scalars["String"]["output"]>;
};

export type SailingCabinCategories = {
  __typename?: "SailingCabinCategories";
  cabinCategorySection: CruiseCabinCategorySection;
};

export type SailingCancellationResults = {
  __typename?: "SailingCancellationResults";
  button: UiSecondaryButton;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SailingDeals = {
  __typename?: "SailingDeals";
  description?: Maybe<Scalars["String"]["output"]>;
  list?: Maybe<Array<Sailing>>;
  navigation: SailingDealsNavigation;
  searchNavigation?: Maybe<CruiseSearchNavigation>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SailingDealsNavigation = {
  __typename?: "SailingDealsNavigation";
  analytics: ClientSideAnalytics;
  nextText?: Maybe<Scalars["String"]["output"]>;
  previousText?: Maybe<Scalars["String"]["output"]>;
};

export type SailingDetailsResults = {
  __typename?: "SailingDetailsResults";
  itinerary: ItineraryDetails;
  itineraryDynamicMap: ItineraryDynamicMapDetails;
  itineraryMap: ItineraryStaticMapDetails;
  itineraryStaticMap: ItineraryStaticMapDetails;
};

export type SailingDialog = {
  __typename?: "SailingDialog";
  close?: Maybe<SailingAction>;
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use iconMediaItem instead */
  icon?: Maybe<CruiseMedia>;
  iconMediaItem?: Maybe<CruiseMediaItem>;
  itinerary: SailingItinerary;
  open?: Maybe<SailingAction>;
  sailingToken?: Maybe<Scalars["String"]["output"]>;
  select?: Maybe<CruiseDialog>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SailingItinerary = CruiseCommonItinerary & {
  __typename?: "SailingItinerary";
  callToAction?: Maybe<UiPrimaryButton>;
  itineraryItems?: Maybe<Array<CruiseItineraryItem>>;
  secondaryItineraryItems?: Maybe<Array<CruiseItineraryItem>>;
};

export type SailingList = Sailing | SponsoredContentPlacement;

export type SailingNavigationBarTab = {
  __typename?: "SailingNavigationBarTab";
  clientSideAnalytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
  sectionName: Scalars["String"]["output"];
};

export type SailingOffer = {
  __typename?: "SailingOffer";
  a11yText?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  dialog?: Maybe<SailingDialog>;
  heading: Scalars["String"]["output"];
  subtitle?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use a11yText instead */
  text?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SailingPagination = {
  __typename?: "SailingPagination";
  limit?: Maybe<Scalars["Int"]["output"]>;
  offset?: Maybe<Scalars["Int"]["output"]>;
  showMoreButton?: Maybe<UiSecondaryButton>;
};

export type SailingSearch = {
  __typename?: "SailingSearch";
  description?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use sailingList instead */
  list?: Maybe<Array<Sailing>>;
  liveAnnouncement?: Maybe<Scalars["String"]["output"]>;
  messagingCard: CruiseMessagingCard;
  noResultsMessagingCard?: Maybe<EgdsStandardMessagingCard>;
  pagination: SailingPagination;
  relaxedSearchHeader?: Maybe<Scalars["String"]["output"]>;
  sailingList?: Maybe<Array<SailingList>>;
  signInLinkAppended?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SailingSearchResults = {
  __typename?: "SailingSearchResults";
  genericErrorsMessage?: Maybe<NoResultsMessage>;
  memberSignInBanner?: Maybe<EgdsStandardMessagingCard>;
  /** @deprecated Use the messaggingCard inside SailingSearch class instead. */
  messagingCard: CruiseMessagingCard;
  pageTitle: Scalars["String"]["output"];
  sailings: SailingSearch;
  termsAndConditions: CruiseSubsection;
};

export interface SaveBrandResultListingCreativeInput {
  bwLogo?: InputMaybe<MediaUploadInput>;
  campaignCreativeData?: InputMaybe<CampaignCreativeDataInput>;
  clickThroughURL?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<MediaUploadInput>;
  logo?: InputMaybe<MediaUploadInput>;
  logoDimensions?: InputMaybe<ImageDimensionsInput>;
  sponsoredLabel?: InputMaybe<Scalars["String"]["input"]>;
}

export type SaveBrandResultListingCreativeResponse =
  | SaveBrandResultListingCreativeResponseError
  | SaveBrandResultListingCreativeResponseSuccess;

export type SaveBrandResultListingCreativeResponseError = {
  __typename?: "SaveBrandResultListingCreativeResponseError";
  errorMessage?: Maybe<UiBanner>;
};

export type SaveBrandResultListingCreativeResponseSuccess = {
  __typename?: "SaveBrandResultListingCreativeResponseSuccess";
  contentUploadErrorMessage?: Maybe<UiBanner>;
  creativeId: Scalars["String"]["output"];
  creativeUrl: Scalars["String"]["output"];
  imagePreSignedUrl?: Maybe<Scalars["String"]["output"]>;
  logoBWPreSignedUrl?: Maybe<Scalars["String"]["output"]>;
  logoPreSignedUrl?: Maybe<Scalars["String"]["output"]>;
};

export interface SavePrefRequestInput {
  canSell: Scalars["Boolean"]["input"];
}

export type SavePrefResponse = {
  __typename?: "SavePrefResponse";
  success: Scalars["Boolean"]["output"];
};

export type SavePropertyCancelPolicyExceptionsResponse = {
  __typename?: "SavePropertyCancelPolicyExceptionsResponse";
  banner: SupplyPropertyCancelPolicyExceptionBanner;
  selectedWaivers: Array<Maybe<Scalars["Int"]["output"]>>;
};

export interface SaveReviewAnswersRequestInput {
  orderId?: InputMaybe<Scalars["String"]["input"]>;
  orderLineId?: InputMaybe<Scalars["String"]["input"]>;
  reviewAnswers?: InputMaybe<Array<ReviewAnswerInput>>;
  reviewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SaveSocialInteractionElement = {
  __typename?: "SaveSocialInteractionElement";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<SocialInteractionElementAnalyticEvent>;
  count?: Maybe<Scalars["Int"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  socialInteractionTripsSaveItem: SocialInteractionTripsSaveItem;
};

export type SavedCardDetails = {
  __typename?: "SavedCardDetails";
  cardNumberInput: CardNumberInput;
  confirmPaymentDetailsButtonText: Scalars["String"]["output"];
  customerNameInput: EgdsTextInputField;
  error?: Maybe<AgentToolsErrorBanner>;
  expiryDateInput: EgdsTextInputField;
  heading: Scalars["String"]["output"];
};

export type SavedItem = {
  __typename?: "SavedItem";
  id?: Maybe<Scalars["String"]["output"]>;
  productId: Scalars["String"]["output"];
  productType?: Maybe<ProductType>;
};

export type SavedTripSummarySection = {
  __typename?: "SavedTripSummarySection";
  accessibility: AccessibilityData;
  headingGroup: Array<EgdsStylizedText>;
  primaryButton: TripsTertiaryButton;
  thumbnail?: Maybe<Image>;
};

export type ScheduledDateRangeValidation = EgdsInputValidation & {
  __typename?: "ScheduledDateRangeValidation";
  errorMessage: Scalars["String"]["output"];
};

export type ScreenType = "ADJUSTMENT" | "CANCEL" | "REVIEW";

export type ScrollButton = UiPrimaryFloatingActionButton;

export type ScrollToButton = UiPrimaryButton;

export type SearchAmountModel = {
  __typename?: "SearchAmountModel";
  amount: Scalars["Float"]["output"];
  currency: Scalars["String"]["output"];
};

export type SearchBar = {
  __typename?: "SearchBar";
  campaignInputField?: Maybe<EgdsInputField>;
  searchBarHeader?: Maybe<Scalars["String"]["output"]>;
  submitCampaignButton?: Maybe<UiPrimaryButton>;
};

export type SearchCarousel = {
  __typename?: "SearchCarousel";
  options?: Maybe<Array<SearchCarouselModel>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SearchCarouselModel = {
  __typename?: "SearchCarouselModel";
  description?: Maybe<Scalars["String"]["output"]>;
  href?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars["String"]["output"]>;
  primaryHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SearchClickstreamEvents = {
  __typename?: "SearchClickstreamEvents";
  additionalEvents: Array<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated searchResultsViewed event is unused */
  searchResultsViewed?: Maybe<SearchResultsViewed>;
};

export interface SearchContextInput {
  dateRange?: InputMaybe<DateRangeInput>;
  destination?: InputMaybe<DestinationInput>;
}

export type SearchCriteria = {
  __typename?: "SearchCriteria";
  dateRange: PropertyDateRange;
  destination: Destination;
  filters?: Maybe<PropertySearchFilters>;
  marketing?: Maybe<PropertyMarketingInfo>;
  originalDateRange?: Maybe<PropertyDateRange>;
  privacyTrackingState?: Maybe<PrivacyTrackingState>;
  propertyShopOptions?: Maybe<PropertyShopOptions>;
  resolvedDateRange?: Maybe<PropertyDateRange>;
  rooms: Array<Room>;
  searchId?: Maybe<Scalars["String"]["output"]>;
  searchIntent?: Maybe<SearchIntent>;
  searchOptions?: Maybe<Array<PropertySearchOptions>>;
  searchPagination?: Maybe<Pagination>;
  shopWithPoints: Scalars["Boolean"]["output"];
  sort?: Maybe<PropertySort>;
};

export interface SearchCriteriaSelectionsInput {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type SearchFormAdaptExSuccessEvent = {
  __typename?: "SearchFormAdaptExSuccessEvent";
  datePrefill?: Maybe<EgUisMicroMessage>;
};

export type SearchFormDurationSpanField = EgdsElement &
  EgdsInputField &
  EgdsSearchFormField & {
    __typename?: "SearchFormDurationSpanField";
    accessibilityTemplate?: Maybe<EgdsLocalizedText>;
    action?: Maybe<EgdsSearchFormAction>;
    egdsElementId: Scalars["String"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export type SearchFormMessaging = {
  __typename?: "SearchFormMessaging";
  /** @deprecated Use leftIcon or rightIcon instead */
  icon?: Maybe<Icon>;
  leftIcon?: Maybe<Icon>;
  moreInfoTrigger?: Maybe<EgdsMoreInfoTrigger>;
  rightIcon?: Maybe<Icon>;
  text?: Maybe<SearchFormMessagingText>;
  tooltipCloseButton?: Maybe<EgdsOverlayButton>;
  tooltipText?: Maybe<Scalars["String"]["output"]>;
};

export type SearchFormMessagingText = {
  __typename?: "SearchFormMessagingText";
  replacementTexts: Array<Scalars["String"]["output"]>;
  template: Scalars["String"]["output"];
};

export type SearchFormPartialStay = {
  __typename?: "SearchFormPartialStay";
  addPartialStay?: Maybe<EgdsBasicCheckBox>;
  dates?: Maybe<EgdsSearchFormDatePickerField>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
};

export type SearchFormPopularFilters = {
  __typename?: "SearchFormPopularFilters";
  filters?: Maybe<Array<EgdsBasicRemovablePill>>;
  template: EgdsBasicLocalizedText;
};

export interface SearchFormPriceInsightsDailyInput {
  checkInDate: DateInput;
  checkOutDate: DateInput;
  gaiaId: Scalars["String"]["input"];
}

export type SearchFormPriceInsightsDailyResponse = {
  __typename?: "SearchFormPriceInsightsDailyResponse";
  days: Array<SearchFormPriceInsightsDay>;
  explainabilityText?: Maybe<Scalars["String"]["output"]>;
};

export type SearchFormPriceInsightsDay = {
  __typename?: "SearchFormPriceInsightsDay";
  date: Date;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SearchFormPriceInsightsMonthlyOption = {
  __typename?: "SearchFormPriceInsightsMonthlyOption";
  button?: Maybe<EgdsToggleButton>;
  price?: Maybe<EgdsBadge>;
};

export type SearchFormPriceInsightsMonthlyResponse = {
  __typename?: "SearchFormPriceInsightsMonthlyResponse";
  explainabilityText?: Maybe<EgdsMoreInfoTrigger>;
  options?: Maybe<Array<SearchFormPriceInsightsMonthlyOption>>;
};

export type SearchFormPriceInsightsResults = {
  __typename?: "SearchFormPriceInsightsResults";
  dailyPrice: SearchFormPriceInsightsDailyResponse;
  flexibleDatesMonthly: SearchFormPriceInsightsMonthlyResponse;
};

export type SearchFormPriceInsightsResultsDailyPriceArgs = {
  criteria: SearchFormPriceInsightsDailyInput;
};

export type SearchFormPriceInsightsResultsFlexibleDatesMonthlyArgs = {
  gaiaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchFormProduct = {
  __typename?: "SearchFormProduct";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  targetURI?: Maybe<Scalars["String"]["output"]>;
  type: SearchFormProductType;
};

export type SearchFormProductSelector = {
  __typename?: "SearchFormProductSelector";
  products: Array<SearchFormProduct>;
};

export type SearchFormProductType = "ACTIVITIES" | "CARS" | "CRUISES" | "FLIGHTS" | "LODGING" | "PACKAGES";

export type SearchFormResults = {
  __typename?: "SearchFormResults";
  activity: EgdsSearchForm;
  car: EgdsSearchForm;
  cruise: EgdsSearchForm;
  flight: EgdsSearchForm;
  groundTransportation: EgdsSearchForm;
  lodging: EgdsSearchForm;
  packages: EgdsSearchForm;
  productSelector: SearchFormProductSelector;
};

export type SearchFormResultsActivityArgs = {
  activitySearchCriteria?: InputMaybe<ActivitiySearchCriteriaInput>;
};

export type SearchFormResultsCarArgs = {
  carSearchCriteria?: InputMaybe<CarSearchCriteriaInput>;
};

export type SearchFormResultsCruiseArgs = {
  cruiseSearchCriteria?: InputMaybe<CruiseSearchCriteriaInput>;
};

export type SearchFormResultsFlightArgs = {
  disabledCabins?: InputMaybe<Array<FlightsCabinClass>>;
  features?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fixedTravelers?: InputMaybe<Scalars["Boolean"]["input"]>;
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
};

export type SearchFormResultsGroundTransportationArgs = {
  groundTransportationSearchCriteria?: InputMaybe<ProductShoppingCriteriaInput>;
};

export type SearchFormResultsLodgingArgs = {
  features?: InputMaybe<Array<Scalars["String"]["input"]>>;
  propertySearchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
};

export type SearchFormResultsPackagesArgs = {
  packageSearchCriteria?: InputMaybe<PackageSearchCriteriaInput>;
  packageShoppingCriteria?: InputMaybe<ProductShoppingCriteriaInput>;
  packageType?: InputMaybe<PackageType>;
};

export type SearchHelpArticlesView = HelpArticleSearchErrorView | MoreHelpArticles;

export type SearchHistoryDayRange = "SEVEN_DAYS" | "THREE_DAYS";

export type SearchHistoryDestinationType = "METRO_CITY";

export type SearchHistoryDisplayData = {
  __typename?: "SearchHistoryDisplayData";
  image?: Maybe<Image>;
  localizedDateRange?: Maybe<Scalars["String"]["output"]>;
  localizedTripName: Scalars["String"]["output"];
};

export type SearchHistoryFilterCriteria = {
  __typename?: "SearchHistoryFilterCriteria";
  endDate: Scalars["String"]["output"];
  regionId: Scalars["String"]["output"];
  regionType?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["String"]["output"];
};

export interface SearchHistoryFilterCriteriaInput {
  endDate: Scalars["String"]["input"];
  regionId: Scalars["String"]["input"];
  regionType?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
}

export interface SearchHistoryGroupingCriteriaInput {
  dayRange?: InputMaybe<SearchHistoryDayRange>;
  destinationType?: InputMaybe<SearchHistoryDestinationType>;
}

export type SearchIntent = {
  __typename?: "SearchIntent";
  interests?: Maybe<Array<Interest>>;
  semDtl?: Maybe<Scalars["String"]["output"]>;
  semdtl?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  userIntent?: Maybe<Scalars["String"]["output"]>;
};

export interface SearchIntentInput {
  interests?: InputMaybe<Array<InterestInput>>;
  semDtl?: InputMaybe<Scalars["String"]["input"]>;
  semdtl?: InputMaybe<Scalars["String"]["input"]>;
  theme?: InputMaybe<Scalars["String"]["input"]>;
  userIntent?: InputMaybe<Scalars["String"]["input"]>;
}

export type SearchLocation = {
  __typename?: "SearchLocation";
  analytics?: Maybe<SearchLocationAnalytics>;
  content?: Maybe<SearchLocationContent>;
};

export type SearchLocationActionResponse = {
  __typename?: "SearchLocationActionResponse";
  status: SearchLocationActionStatus;
};

export type SearchLocationActionStatus = "FAIL" | "SUCCESS";

export type SearchLocationAnalytics = {
  __typename?: "SearchLocationAnalytics";
  close?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  itemSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  open?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  recommendationSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  recommendationsPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SearchLocationBasicItem = {
  __typename?: "SearchLocationBasicItem";
  airportCode?: Maybe<Scalars["String"]["output"]>;
  coordinates?: Maybe<Coordinates>;
  geoType?: Maybe<GeoType>;
  graphic?: Maybe<SearchLocationGraphic>;
  itemAccessibilityText: Scalars["String"]["output"];
  locationFullName?: Maybe<Scalars["String"]["output"]>;
  primaryText: Scalars["String"]["output"];
  propertyId?: Maybe<Scalars["Int"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  remove?: Maybe<SearchLocationButton>;
  secondaryText?: Maybe<Scalars["String"]["output"]>;
};

export type SearchLocationBasicTravelers = {
  __typename?: "SearchLocationBasicTravelers";
  adults: Scalars["Int"]["output"];
  childrenAges?: Maybe<Array<Scalars["Int"]["output"]>>;
  pets?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SearchLocationButton = {
  __typename?: "SearchLocationButton";
  accessibilityText: Scalars["String"]["output"];
  icon: Icon;
  remove: Scalars["AnalyticsPayload"]["output"];
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SearchLocationContent = {
  __typename?: "SearchLocationContent";
  categories?: Maybe<SearchLocationContentCategories>;
  clearInput: UiPrimaryFloatingActionButton;
  close: UiPrimaryFloatingActionButton;
  currentLocation?: Maybe<SearchLocationContentCurrentLocation>;
  enteringInputAccessibility: Scalars["String"]["output"];
  itemSelectedAccessibility: EgdsBasicLocalizedText;
  multiSelection?: Maybe<SearchLocationContentMultiSelection>;
  searchForText: EgdsBasicLocalizedText;
  searchIcon?: Maybe<Icon>;
};

export type SearchLocationContentCategories = {
  __typename?: "SearchLocationContentCategories";
  address?: Maybe<Scalars["String"]["output"]>;
  airport?: Maybe<Scalars["String"]["output"]>;
  alternate?: Maybe<Scalars["String"]["output"]>;
  attraction?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  history?: Maybe<Scalars["String"]["output"]>;
  hotel?: Maybe<Scalars["String"]["output"]>;
  metroStation?: Maybe<Scalars["String"]["output"]>;
  nearby?: Maybe<Scalars["String"]["output"]>;
  popular?: Maybe<Scalars["String"]["output"]>;
  trainStation?: Maybe<Scalars["String"]["output"]>;
  trending?: Maybe<Scalars["String"]["output"]>;
  trendingSearch?: Maybe<Scalars["String"]["output"]>;
};

export type SearchLocationContentCurrentLocation = {
  __typename?: "SearchLocationContentCurrentLocation";
  disabledAccessibility: Scalars["String"]["output"];
  disabledText: Scalars["String"]["output"];
  locationText: Scalars["String"]["output"];
};

export type SearchLocationContentMultiSelection = {
  __typename?: "SearchLocationContentMultiSelection";
  clearAll: UiTertiaryButton;
  done: UiPrimaryButton;
  heading: Scalars["String"]["output"];
  itemRemovedAccessibility: EgdsBasicLocalizedText;
};

export interface SearchLocationCriteriaInput {
  isDestination?: InputMaybe<Scalars["Boolean"]["input"]>;
  isGroundTransfersAirportPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastSearchLatLong?: InputMaybe<Scalars["String"]["input"]>;
  lineOfBusiness: Scalars["String"]["input"];
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}

export type SearchLocationGraphic = Icon | Image;

export type SearchLocationGroup = {
  __typename?: "SearchLocationGroup";
  items: Array<SearchLocationItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SearchLocationItem = SearchLocationBasicItem | SearchLocationItemWithSubItems | SearchLocationRecentSearch;

export type SearchLocationItemWithSubItems = {
  __typename?: "SearchLocationItemWithSubItems";
  item: SearchLocationBasicItem;
  subItems: Array<SearchLocationBasicItem>;
};

export type SearchLocationRecentSearch = {
  __typename?: "SearchLocationRecentSearch";
  dateRange?: Maybe<DateRange>;
  item: SearchLocationBasicItem;
  travelers?: Maybe<SearchLocationTravelerSelector>;
};

export type SearchLocationResults = {
  __typename?: "SearchLocationResults";
  impressionAnalytics: Scalars["AnalyticsPayload"]["output"];
  itemsGroups: Array<SearchLocationGroup>;
  poweredBy: SearchLocationSource;
};

export type SearchLocationSource = "EGTYPEAHEAD" | "GOOGLE" | "RECOMMENDATIONS";

export type SearchLocationTravelerSelector = SearchLocationBasicTravelers | SearchLocationTravelersByRoom;

export type SearchLocationTravelersByRoom = {
  __typename?: "SearchLocationTravelersByRoom";
  rooms: Array<SearchLocationBasicTravelers>;
};

export interface SearchOfferInput {
  offerDetails?: InputMaybe<Array<SelectedValueInput>>;
  offerPrice?: InputMaybe<SearchOfferPriceInput>;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SearchOfferPriceInput {
  multiItemPriceToken?: InputMaybe<Scalars["String"]["input"]>;
  offerTimestamp?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<MoneyInput>;
}

export type SearchResultMessagingCard = SearchResultMessagingModule | ShoppingFiltersMessagingCard;

export type SearchResultMessagingHeader = EgdsHeading;

export type SearchResultMessagingModule = {
  __typename?: "SearchResultMessagingModule";
  header?: Maybe<SearchResultMessagingHeader>;
};

export type SearchResultMessagingSheet = ShoppingFiltersMessagingSheet;

export type SearchResultsViewed = {
  __typename?: "SearchResultsViewed";
  event: EgClickstreamEvent;
  search_request: LodgingSearchRequest;
};

export type SeatChoiceType = "SEAT_CHOICE";

export type SeatMapCabinDimensionDetails = {
  __typename?: "SeatMapCabinDimensionDetails";
  aislesCount: Scalars["Int"]["output"];
  columnsCount: Scalars["Int"]["output"];
  exitsCount: Scalars["Int"]["output"];
  rowsCount: Scalars["Int"]["output"];
  wingDetails?: Maybe<SeatMapCabinWingDetails>;
};

export type SeatMapCabinWingDetails = {
  __typename?: "SeatMapCabinWingDetails";
  endRow: Scalars["Int"]["output"];
  startRow: Scalars["Int"]["output"];
};

export type SeatSegment = {
  __typename?: "SeatSegment";
  fareBasisCode: Scalars["String"]["output"];
  identifier: Scalars["String"]["output"];
  operatingCarrierCode: Scalars["String"]["output"];
};

export type SeatVariant =
  | "ACCESSIBLE"
  | "AISLE"
  | "AVAILABLE"
  | "COLUMN"
  | "EXIT"
  | "GALLEY"
  | "PREFERRED"
  | "RESTROOM"
  | "ROW"
  | "SELECTED"
  | "UNAVAILABLE";

export type SecondaryInfo = {
  __typename?: "SecondaryInfo";
  message: Array<Scalars["String"]["output"]>;
  title?: Maybe<EgdsStylizedText>;
};

export type SecondaryReasonList = {
  __typename?: "SecondaryReasonList";
  secondaryReason: EgdsBasicSelect;
  secondaryReasonOptions: Array<SecondaryReasonOption>;
};

export type SecondaryReasonOption = {
  __typename?: "SecondaryReasonOption";
  options: Array<EgdsBasicOption>;
  primaryReason: Scalars["String"]["output"];
};

export type Section = LegalCheckBoxSection | LegalSection;

export type SectionRow = {
  __typename?: "SectionRow";
  rowContent?: Maybe<Array<Maybe<PortableTextWithLink>>>;
};

export type SectionType = "ORDERED_LIST" | "PARAGRAPH" | "UNORDERED_LIST";

export type SeeAllCategoriesDialog = EgdsDialog & {
  __typename?: "SeeAllCategoriesDialog";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  contents?: Maybe<Array<EgdsCard>>;
  toolbar: EgdsDialogToolbar;
};

export type SeeAllCategoriesLink = {
  __typename?: "SeeAllCategoriesLink";
  dialog?: Maybe<SeeAllCategoriesDialog>;
  trigger: SeeAllCategoriesTrigger;
};

export type SeeAllCategoriesTrigger = {
  __typename?: "SeeAllCategoriesTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  label: Scalars["String"]["output"];
};

export type SeeMoreAction = AmenitiesDialog | CleanlinessDialog | MoreInfoDialog;

export type SelectChangeOfferAction = {
  __typename?: "SelectChangeOfferAction";
  offer: PropertyNaturalKey;
};

export type SelectLogo = {
  __typename?: "SelectLogo";
  actionButtons: Array<UiPrimaryButton>;
  description: EgdsPlainText;
  selections: Array<LogoOption>;
};

export type SelectLogoResponse = LogoManagerResponse & {
  __typename?: "SelectLogoResponse";
  selectLogo: SelectLogo;
  title: EgdsHeading;
};

export type SelectPackageActionInput = {
  __typename?: "SelectPackageActionInput";
  packageOfferId: Scalars["String"]["output"];
};

export type SelectPackageMultiItemShoppingAction = MultiItemShoppingAction & {
  __typename?: "SelectPackageMultiItemShoppingAction";
  packageOfferId: Scalars["String"]["output"];
  shoppingContext: ShoppingContext;
};

export type SelectPaymentMethodDataModule = {
  __typename?: "SelectPaymentMethodDataModule";
  actions?: Maybe<Array<PaymentAction>>;
  checkoutIdentifier?: Maybe<PaymentCheckoutIdentifier>;
  paymentCheckoutElement?: Maybe<PaymentCheckoutElement>;
};

export interface SelectPaymentMethodRequestInput {
  checkoutIdentifier: PaymentCheckoutIdentifierInput;
  fopItems: Array<FopItemInput>;
}

export type SelectedAncillaryTemplate = {
  __typename?: "SelectedAncillaryTemplate";
  template: EgdsLocalizedText;
  type: SelectedAncillaryTemplateType;
};

export type SelectedAncillaryTemplateType = "MULTIPLE" | "NONE" | "ONE";

export type SelectedBundleProductCard = {
  __typename?: "SelectedBundleProductCard";
  actions?: Maybe<Array<SelectedBundleProductCardAction>>;
  featuredHeader?: Maybe<Scalars["String"]["output"]>;
  productContents: Array<SelectedBundleProductContent>;
};

export type SelectedBundleProductCardAction = ShoppingButton | ShoppingLink;

export type SelectedBundleProductContent = {
  __typename?: "SelectedBundleProductContent";
  graphics?: Maybe<Array<UiGraphic>>;
  primarySection: SelectedBundleProductContentPrimarySection;
  secondarySections?: Maybe<Array<SelectedBundleProductContentSecondarySection>>;
};

export type SelectedBundleProductContentPrimarySection = {
  __typename?: "SelectedBundleProductContentPrimarySection";
  badge?: Maybe<EgdsBadge>;
  primary: SelectedBundleProductText;
  secondary?: Maybe<SelectedBundleProductText>;
};

export type SelectedBundleProductContentSecondarySection = {
  __typename?: "SelectedBundleProductContentSecondarySection";
  primary: SelectedBundleProductText;
  secondary?: Maybe<SelectedBundleProductText>;
};

export type SelectedBundleProductHeader = EgdsHeading | ShoppingLink;

export type SelectedBundleProductText = EgdsGraphicText | EgdsPlainText | EgdsSpannableText | EgdsStylizedText;

export type SelectedBundleProductWrapper = {
  __typename?: "SelectedBundleProductWrapper";
  cards?: Maybe<Array<SelectedBundleProductCard>>;
  contents?: Maybe<Array<ShoppingProductCardContentContainer>>;
  disclaimer?: Maybe<Array<DisclaimerCard>>;
  header?: Maybe<Array<SelectedBundleProductHeader>>;
  params?: Maybe<Array<ShoppingProductCardFunctionParametersContainers>>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type SelectedFlightsJourneyCriteria = {
  __typename?: "SelectedFlightsJourneyCriteria";
  arrivalDate?: Maybe<Date>;
  departureDate: Date;
  destination: Scalars["String"]["output"];
  origin: Scalars["String"]["output"];
};

export type SelectedJourneyMetadata = {
  __typename?: "SelectedJourneyMetadata";
  flightsJourneyCriteria: Array<SelectedFlightsJourneyCriteria>;
  gaiaIds: Array<Scalars["String"]["output"]>;
  journeyContinuationId?: Maybe<Scalars["String"]["output"]>;
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelerDetails: Array<FlightsTravelerDetails>;
  tripType: FlightsTripType;
};

export type SelectedValue = {
  __typename?: "SelectedValue";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface SelectedValueInput {
  id: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type SelfReferencedUri = Uri & {
  __typename?: "SelfReferencedURI";
  fragment?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type SendPaymentAssistMutationResponse = {
  __typename?: "SendPaymentAssistMutationResponse";
  error?: Maybe<AgentToolsErrorBanner>;
  successMessage?: Maybe<AgentToolsSuccessBanner>;
};

export type SendPaymentVerificationMutationResponse = {
  __typename?: "SendPaymentVerificationMutationResponse";
  error?: Maybe<AgentToolsErrorBanner>;
  successMessage?: Maybe<AgentToolsSuccessBanner>;
};

export type SerpMetadata = {
  __typename?: "SerpMetadata";
  priceRange?: Maybe<LodgingPlainMessage>;
  priceRating?: Maybe<LodgingPlainMessage>;
};

export interface ServiceActionInput {
  action: ServiceActionType;
  serviceId: Scalars["String"]["input"];
}

export type ServiceActionListItem = {
  __typename?: "ServiceActionListItem";
  connectDisconnectButton?: Maybe<Array<ConnectivitySettingsButton>>;
  icon?: Maybe<Icon>;
  serviceId: Scalars["String"]["output"];
  showBorder?: Maybe<Scalars["Boolean"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ServiceActionType = "CONNECT" | "DISCONNECT";

export type ServiceAutomationAction = ServiceAutomationOpenSheetAction | ServiceAutomationVirtualAgentButtonAction;

export type ServiceAutomationButton = {
  __typename?: "ServiceAutomationButton";
  action: ServiceAutomationAction;
  button: EgdsButton;
  displayAnalytics?: Maybe<ClientSideAnalytics>;
};

export type ServiceAutomationElement = EgdsHeading | EgdsPlainText | EgdsStylizedText | ServiceAutomationButton;

export type ServiceAutomationOpenSheetAction = {
  __typename?: "ServiceAutomationOpenSheetAction";
  sheet: ServiceAutomationSheet;
};

export type ServiceAutomationQuery = {
  __typename?: "ServiceAutomationQuery";
  tripDetailsSupportRequests?: Maybe<ServiceAutomationSupportRequestsView>;
};

export type ServiceAutomationQueryTripDetailsSupportRequestsArgs = {
  input: TripDetailsSupportRequestsInput;
};

export type ServiceAutomationSection = {
  __typename?: "ServiceAutomationSection";
  elements?: Maybe<Array<ServiceAutomationElement>>;
};

export type ServiceAutomationSectionContainer = {
  __typename?: "ServiceAutomationSectionContainer";
  heading?: Maybe<Scalars["String"]["output"]>;
  sections?: Maybe<Array<ServiceAutomationSection>>;
};

export type ServiceAutomationSheet = {
  __typename?: "ServiceAutomationSheet";
  containers: Array<ServiceAutomationSectionContainer>;
  displayAnalytics?: Maybe<Array<ClientSideAnalytics>>;
  sheet: EgdsSheet;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<ServiceAutomationToolbar>;
};

export type ServiceAutomationSupportRequestsView = {
  __typename?: "ServiceAutomationSupportRequestsView";
  button: ServiceAutomationButton;
  requestInformation?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceAutomationToolbar = {
  __typename?: "ServiceAutomationToolbar";
  elevation?: Maybe<ServiceAutomationToolbarElevation>;
  navigationContent?: Maybe<ServiceAutomationToolbarNavigationContent>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  titleTag?: Maybe<EgdsHeadingStyle>;
};

export type ServiceAutomationToolbarElevation = "FLAT" | "FLOATING";

export type ServiceAutomationToolbarNavType = "BACK" | "CLOSE" | "EXPAND_UP";

export type ServiceAutomationToolbarNavigationContent = {
  __typename?: "ServiceAutomationToolbarNavigationContent";
  navType?: Maybe<ServiceAutomationToolbarNavType>;
};

export interface ServiceAutomationVirtualAgent {
  clientOverrides?: Maybe<ServiceAutomationVirtualAgentClientOverrides>;
}

export type ServiceAutomationVirtualAgentButtonAction = ServiceAutomationVirtualAgent & {
  __typename?: "ServiceAutomationVirtualAgentButtonAction";
  clientOverrides?: Maybe<ServiceAutomationVirtualAgentClientOverrides>;
};

export type ServiceAutomationVirtualAgentClientOverrides = {
  __typename?: "ServiceAutomationVirtualAgentClientOverrides";
  conversationProperties?: Maybe<ServiceAutomationVirtualAgentConversationProperties>;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]["output"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]["output"]>;
  intent?: Maybe<Scalars["String"]["output"]>;
  intentArguments?: Maybe<Array<ServiceAutomationVirtualAgentIntentArgument>>;
  subscribedEvents?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ServiceAutomationVirtualAgentConversationProperties = {
  __typename?: "ServiceAutomationVirtualAgentConversationProperties";
  launchPoint?: Maybe<Scalars["String"]["output"]>;
  pageName?: Maybe<Scalars["String"]["output"]>;
  skipWelcome?: Maybe<Scalars["String"]["output"]>;
};

export type ServiceAutomationVirtualAgentIntentArgument = {
  __typename?: "ServiceAutomationVirtualAgentIntentArgument";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ServiceChargeDetail = {
  __typename?: "ServiceChargeDetail";
  charges: Scalars["String"]["output"];
  closeIcon: Icon;
  infoIcon: Icon;
  label: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type ServiceDetail = {
  __typename?: "ServiceDetail";
  button: ConnectivitySettingsButton;
  serviceId?: Maybe<Scalars["String"]["output"]>;
  serviceName: Scalars["String"]["output"];
};

export type SetCancellationReasonBanner = {
  __typename?: "SetCancellationReasonBanner";
  content: ReservationModifiersSpannableText;
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type SetCancellationReasonConditionalContent = {
  __typename?: "SetCancellationReasonConditionalContent";
  banner?: Maybe<SetCancellationReasonBanner>;
  cancellationReasons: Array<VrCancellationReason>;
};

export type SetRefundAmountVrConditionalRefundBreakdownContent = {
  __typename?: "SetRefundAmountVRConditionalRefundBreakdownContent";
  breakdownExpando: EgdsExpandoLink;
  breakdownItems: Array<SetRefundAmountVrConditionalRefundBreakdownItem>;
  breakdownTotal: SetRefundAmountVrConditionalRefundBreakdownItem;
  refundLevel: ReservationModifiersVrCancelRefundLevel;
};

export type SetRefundAmountVrConditionalRefundBreakdownItem = {
  __typename?: "SetRefundAmountVRConditionalRefundBreakdownItem";
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SetRefundAmountVrConditionalRefundOptionContent = {
  __typename?: "SetRefundAmountVRConditionalRefundOptionContent";
  cancellationReasons: Array<VrCancellationReason>;
  options?: Maybe<Array<SetRefundAmountVrRefundOption>>;
  total?: Maybe<Scalars["String"]["output"]>;
};

export type SetRefundAmountVrRefundOption = {
  __typename?: "SetRefundAmountVRRefundOption";
  label: Scalars["String"]["output"];
  refundAmount?: Maybe<Scalars["String"]["output"]>;
  refundBreakdownContent?: Maybe<SetRefundAmountVrConditionalRefundBreakdownContent>;
  refundLevel: ReservationModifiersVrCancelRefundLevel;
};

export type SettingProviderTypeaheadEssAdapterConfig = {
  __typename?: "SettingProviderTypeaheadESSAdapterConfig";
  client: Scalars["String"]["output"];
  dest: Scalars["Boolean"]["output"];
  domain?: Maybe<Scalars["String"]["output"]>;
  domainId?: Maybe<Scalars["String"]["output"]>;
  features: Scalars["String"]["output"];
  lob: Scalars["String"]["output"];
  locale: Scalars["String"]["output"];
  maxResults: Scalars["Int"]["output"];
  regionType: Scalars["Int"]["output"];
  siteId: Scalars["Int"]["output"];
  userId: Scalars["String"]["output"];
};

export type ShareButtonTypes = EgdsOverlayButton | UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export interface ShareContextInput {
  callToDownStreamServices?: InputMaybe<Scalars["Boolean"]["input"]>;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
  forgeType?: InputMaybe<Scalars["String"]["input"]>;
  hasScreenShot?: InputMaybe<Scalars["Boolean"]["input"]>;
  lineOfBusiness?: InputMaybe<AppGrowthLineOfBusiness>;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  rffrURL?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
  userSelectedFilters?: InputMaybe<Array<UserSelectedFiltersInput>>;
}

export interface ShareDestinationContextInput {
  activityId?: InputMaybe<Scalars["String"]["input"]>;
  airportTLA?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<Array<UserSelectedFiltersInput>>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
}

export type ShareInfo = {
  __typename?: "ShareInfo";
  facebookAppId: Scalars["String"]["output"];
  linkCopiedMessage: Scalars["String"]["output"];
  linkList: Array<LodgingLinkMessage>;
  menuName: Scalars["String"]["output"];
  sharedText: Scalars["String"]["output"];
};

export type ShareMedia = Image;

export type ShareSocialInteractionElement = {
  __typename?: "ShareSocialInteractionElement";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<SocialInteractionElementAnalyticEvent>;
  count?: Maybe<Scalars["Int"]["output"]>;
  primaryIcon: Icon;
  primaryText: Scalars["String"]["output"];
  shareImage?: Maybe<Image>;
  shareLink: HttpUri;
  shareMessage: Array<Scalars["String"]["output"]>;
};

export type SharedUiCardMode = "CREATE" | "EDIT" | "READ";

export type SharedUiCardType = "PARTNER_ACCOUNT" | "PARTNER_SELECT" | "PAYMENT_PROFILE";

export type SheetState = "COLLAPSED" | "FULL" | "HALF";

export interface ShopInsurtechOffersRequestInput {
  coveredOfferItemsId?: InputMaybe<Scalars["String"]["input"]>;
  insurtechClientId: InsurtechClientId;
  residencyDetails?: InputMaybe<ResidencyDetailsInput>;
  residencyState?: InputMaybe<ResidencyState>;
  sessionIdentifier?: InputMaybe<Scalars["String"]["input"]>;
}

export type ShopInsurtechOffersResponse = {
  __typename?: "ShopInsurtechOffersResponse";
  /** @deprecated Deprecating */
  badge?: Maybe<EgdsBadge>;
  bottomInfo?: Maybe<InsurtechShoppingModuleBottomInfo>;
  contentSections: Array<InsurtechSection>;
  dialogs?: Maybe<Array<InsurtechDialog>>;
  egClickstreamAnalytics?: Maybe<Array<InsurtechClickstreamAnalyticsData>>;
  /** @deprecated Deprecating as Footer Section will come as an element in Content sections */
  footerSection: InsurtechRadioGroupWrapper;
  header?: Maybe<InsurtechModuleHeading>;
  /** @deprecated Deprecating as it will come in InsurtechModuleHeading */
  heading: Scalars["String"]["output"];
  messageInfo: Array<InsurtechConstantInfo>;
  residency?: Maybe<InsurtechResidencyModule>;
  /** @deprecated Deprecating as this will come as InsurtechResidencyModule */
  residencyCard?: Maybe<InsurtechMessagingCard>;
  testimonial?: Maybe<InsurtechTestimonial>;
  viewId?: Maybe<InsurtechView>;
  views?: Maybe<Array<InsurtechViewModule>>;
};

export type ShoppedProduct = {
  __typename?: "ShoppedProduct";
  products: MultiItemProducts;
  shoppingPath: ShoppingPathType;
};

export interface ShoppedProductInput {
  products: MultiItemProductsInput;
  shoppingPath: ShoppingPathType;
}

export interface ShoppingAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId: Scalars["String"]["output"];
  /** @deprecated Use the event field instead */
  analytics: ClientSideAnalytics;
  event?: Maybe<EgClickstreamEvent>;
}

export type ShoppingActionTrigger = {
  __typename?: "ShoppingActionTrigger";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId: Scalars["String"]["output"];
  triggerType?: Maybe<ShoppingTriggerType>;
};

export type ShoppingActionableIcon = {
  __typename?: "ShoppingActionableIcon";
  actionId: Scalars["String"]["output"];
  icon: Icon;
};

export type ShoppingAdaptExCampaignEvents = {
  __typename?: "ShoppingAdaptExCampaignEvents";
  adaptExAttemptEvents?: Maybe<Array<ShoppingAdaptExEvent>>;
  adaptExSuccessEvents?: Maybe<Array<ShoppingAdaptExEvent>>;
  campaignId: Scalars["String"]["output"];
};

export type ShoppingAdaptExEvent = {
  __typename?: "ShoppingAdaptExEvent";
  banditDisplayed?: Maybe<Scalars["Boolean"]["output"]>;
  eventTarget?: Maybe<Scalars["String"]["output"]>;
  eventType?: Maybe<Scalars["String"]["output"]>;
  payloadId: Scalars["String"]["output"];
};

export interface ShoppingAppliedFilter {
  filter?: Maybe<EgdsPill>;
  nextSearchCriteria?: Maybe<ShoppingSearchCriteria>;
}

export type ShoppingBasicFunctionParams = {
  __typename?: "ShoppingBasicFunctionParams";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type ShoppingButton = {
  __typename?: "ShoppingButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated use actions instead */
  actionId: Scalars["String"]["output"];
  actions?: Maybe<Array<ShoppingActionTrigger>>;
  buttonType: ShoppingButtonType;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingButtonType = "PRIMARY" | "SECONDARY" | "TERTIARY";

export type ShoppingCarouselButton = {
  __typename?: "ShoppingCarouselButton";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type ShoppingCarouselFormat = {
  __typename?: "ShoppingCarouselFormat";
  itemsVisible?: Maybe<Scalars["Int"]["output"]>;
  nextButton?: Maybe<ShoppingCarouselButton>;
  previousButton?: Maybe<ShoppingCarouselButton>;
};

export type ShoppingCoachmark = {
  __typename?: "ShoppingCoachmark";
  dismissTimeoutMS?: Maybe<Scalars["Int"]["output"]>;
  weight: Scalars["Int"]["output"];
};

export type ShoppingContext = {
  __typename?: "ShoppingContext";
  multiItem?: Maybe<MultiItemContext>;
};

export interface ShoppingContextInput {
  multiItem?: InputMaybe<MultiItemContextInput>;
}

export type ShoppingCopyLink = {
  __typename?: "ShoppingCopyLink";
  analytics?: Maybe<ClientSideAnalytics>;
  copiedUrl: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  name: Scalars["String"]["output"];
  referrerId: Scalars["String"]["output"];
  toast: EgdsToast;
};

export type ShoppingDateField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingDateField";
  analytics: ClientSideAnalytics;
  end?: Maybe<DateValue>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  start?: Maybe<DateValue>;
};

export type ShoppingDealsErrorResponse = {
  __typename?: "ShoppingDealsErrorResponse";
  description?: Maybe<Array<EgdsParagraph>>;
  displayAnalytics: Array<ClientSideAnalytics>;
  /** @deprecated Use 'recoveryButton' instead as EGDS button is not recommended to use */
  errorRecoveryButton?: Maybe<EgdsButton>;
  errorType: DealsErrorType;
  graphic?: Maybe<UiGraphic>;
  heading: EgdsHeading;
  recoveryButton?: Maybe<ShoppingButton>;
};

export type ShoppingDisplayPrice = {
  __typename?: "ShoppingDisplayPrice";
  clickActionId?: Maybe<Scalars["String"]["output"]>;
  price: ShoppingFormattedMoney;
  role: Scalars["String"]["output"];
};

export type ShoppingDropdownField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingDropdownField";
  id?: Maybe<Scalars["String"]["output"]>;
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingEnrichedMessage = {
  __typename?: "ShoppingEnrichedMessage";
  state?: Maybe<Scalars["String"]["output"]>;
  value: Scalars["String"]["output"];
};

export type ShoppingFiltersMessagingCard = {
  __typename?: "ShoppingFiltersMessagingCard";
  filters?: Maybe<Array<BasicShoppingAppliedFilter>>;
  heading?: Maybe<ShoppingFiltersMessagingHeading>;
  impressionTracking?: Maybe<ClientSideAnalytics>;
};

export type ShoppingFiltersMessagingHeading = {
  __typename?: "ShoppingFiltersMessagingHeading";
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ShoppingFiltersMessagingSheet = {
  __typename?: "ShoppingFiltersMessagingSheet";
  applyAction?: Maybe<UiPrimaryButton>;
  clearAll?: Maybe<UiSecondaryButton>;
  filters?: Maybe<ShoppingMessagingSheetFilters>;
  heading?: Maybe<ShoppingFiltersMessagingHeading>;
  sheet?: Maybe<EgdsSheet>;
};

export type ShoppingFormattedMoney = {
  __typename?: "ShoppingFormattedMoney";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  formatted: Scalars["String"]["output"];
};

export type ShoppingFunctionType = "CALLBACK" | "SHARED_UI";

export type ShoppingGalleryCarousel = {
  __typename?: "ShoppingGalleryCarousel";
  accessibilityHeadingText: Scalars["String"]["output"];
  media: Array<ShoppingMediaItem>;
  navClickAnalytics?: Maybe<ClientSideAnalytics>;
  nextButtonText?: Maybe<Scalars["String"]["output"]>;
  previousButtonText?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingGlobal = {
  __typename?: "ShoppingGlobal";
  classType?: Maybe<Scalars["String"]["output"]>;
  destinationCode: Scalars["String"]["output"];
  destinationName?: Maybe<Scalars["String"]["output"]>;
  gaia?: Maybe<Gaia>;
  loyaltyMembership?: Maybe<Scalars["String"]["output"]>;
  numAdults?: Maybe<Scalars["Int"]["output"]>;
  numChildren?: Maybe<Scalars["Int"]["output"]>;
  numInfants?: Maybe<Scalars["Int"]["output"]>;
  numSeniors?: Maybe<Scalars["Int"]["output"]>;
  numYouth?: Maybe<Scalars["Int"]["output"]>;
  originCode?: Maybe<Scalars["String"]["output"]>;
  originName?: Maybe<Scalars["String"]["output"]>;
  shoppingHotel: ShoppingHotel;
  travelers?: Maybe<Array<Traveler>>;
  tripEndDate?: Maybe<Scalars["String"]["output"]>;
  tripEndTime?: Maybe<Scalars["String"]["output"]>;
  tripStartDate?: Maybe<Scalars["String"]["output"]>;
  tripStartTime?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingGlobalData = {
  __typename?: "ShoppingGlobalData";
  messageContent: ShoppingGlobal;
  schemaName: Scalars["String"]["output"];
};

export type ShoppingHistogramBucketsCharacteristics = {
  __typename?: "ShoppingHistogramBucketsCharacteristics";
  buckets?: Maybe<Array<Scalars["Int"]["output"]>>;
  id: Scalars["String"]["output"];
};

export type ShoppingHotel = {
  __typename?: "ShoppingHotel";
  numRooms: Scalars["Int"]["output"];
  rooms: Array<Rooms>;
};

export type ShoppingImage = {
  __typename?: "ShoppingImage";
  actionId?: Maybe<Scalars["String"]["output"]>;
  large?: Maybe<Image>;
  medium?: Maybe<Image>;
  small?: Maybe<Image>;
};

export type ShoppingInvokeFunction = ShoppingAction & {
  __typename?: "ShoppingInvokeFunction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  event?: Maybe<EgClickstreamEvent>;
  functionType: ShoppingFunctionType;
  name: Scalars["String"]["output"];
  paramsId?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingJoinListContainer = {
  __typename?: "ShoppingJoinListContainer";
  actions?: Maybe<Array<ShoppingAction>>;
  overlays?: Maybe<Array<ShoppingOverlayContainer>>;
};

export type ShoppingLink = {
  __typename?: "ShoppingLink";
  actionId: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  trailingIcon?: Maybe<Icon>;
};

export type ShoppingListingContainer = {
  __typename?: "ShoppingListingContainer";
  actionItems?: Maybe<Array<ShoppingListingContainerTriggerItem>>;
  clickstreamAnalytics?: Maybe<ShoppingListingContainerClickstreamEvents>;
  format?: Maybe<ShoppingListingContainerFormat>;
  heading?: Maybe<ShoppingListingContainerHeading>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<ShoppingListingContainerItems>;
  listingId?: Maybe<Scalars["String"]["output"]>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type ShoppingListingContainerAdditionalContext = {
  __typename?: "ShoppingListingContainerAdditionalContext";
  componentId: Scalars["String"]["output"];
  componentName: Scalars["String"]["output"];
  componentPosition: Scalars["Int"]["output"];
  contentId: Scalars["String"]["output"];
};

export type ShoppingListingContainerClickstreamEvents = {
  __typename?: "ShoppingListingContainerClickstreamEvents";
  additionalContext: ShoppingListingContainerAdditionalContext;
  contentListAttributes: Array<ShoppingListingContentListAttributes>;
  deselected?: Maybe<EgClickstreamEvent>;
  eventContext: Array<ShoppingListingContainerContextValue>;
  experienceTemplateId?: Maybe<Scalars["String"]["output"]>;
  presented?: Maybe<EgClickstreamEvent>;
  searchId?: Maybe<Scalars["String"]["output"]>;
  selected?: Maybe<EgClickstreamEvent>;
};

export type ShoppingListingContainerContextValue = {
  __typename?: "ShoppingListingContainerContextValue";
  id: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type ShoppingListingContainerFormat = ShoppingCarouselFormat;

export type ShoppingListingContainerHeading = EgdsHeading | ShoppingListingHeader;

export type ShoppingListingContainerItems = LodgingCard | ShoppingSelectableImageCard;

export type ShoppingListingContainerTrigger = ShoppingButton;

export type ShoppingListingContainerTriggerItem = {
  __typename?: "ShoppingListingContainerTriggerItem";
  location: Scalars["String"]["output"];
  trigger: ShoppingListingContainerTrigger;
};

export type ShoppingListingContentListAttributes =
  | CategoryListContentEventAttributes
  | DestinationListContentEventAttributes
  | ProductListContentEventAttributes;

export type ShoppingListingExpandoCardContainer = {
  __typename?: "ShoppingListingExpandoCardContainer";
  expando: EgdsExpandoCard;
  graphic?: Maybe<UiGraphic>;
};

export type ShoppingListingHeader = {
  __typename?: "ShoppingListingHeader";
  disclaimer?: Maybe<LodgingPlainDialog>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type ShoppingLoadSharedUi = {
  __typename?: "ShoppingLoadSharedUI";
  componentName: Scalars["String"]["output"];
};

export type ShoppingMediaItem = {
  __typename?: "ShoppingMediaItem";
  media: Media;
};

export type ShoppingMessagingSheetFilters = ShoppingMultiSelectionField;

export type ShoppingMultiSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMultiSelectionField";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<ShoppingSelectionExpando>;
  id?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  primaryIcon?: Maybe<Icon>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingMultiSelectionStackedTileField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMultiSelectionStackedTileField";
  expando?: Maybe<ShoppingSelectionExpando>;
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingMultiSelectionTileField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMultiSelectionTileField";
  expando?: Maybe<ShoppingSelectionExpando>;
  options: Array<ShoppingSelectableFilterOption>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingMutexField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingMutexField";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<ShoppingMutexFieldOption>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingMutexFieldOption = {
  __typename?: "ShoppingMutexFieldOption";
  option: ShoppingSortAndFilterField;
  value: ShoppingSelectableFilterOption;
};

export type ShoppingNavigateToOverlay = ShoppingAction & {
  __typename?: "ShoppingNavigateToOverlay";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  event?: Maybe<EgClickstreamEvent>;
  overlayId: Scalars["String"]["output"];
};

export type ShoppingNavigateToUri = ShoppingAction & {
  __typename?: "ShoppingNavigateToURI";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  event?: Maybe<EgClickstreamEvent>;
  resource: Uri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"]["output"];
};

export type ShoppingOfferCardFooterContent = {
  __typename?: "ShoppingOfferCardFooterContent";
  link?: Maybe<ShoppingLink>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingOverlay = EgdsSheet | ShoppingCoachmark;

export type ShoppingOverlayContainer = {
  __typename?: "ShoppingOverlayContainer";
  contentId: Scalars["String"]["output"];
  overlay: ShoppingOverlay;
  overlayId: Scalars["String"]["output"];
};

export type ShoppingPathPrimersQuery = MultiItemDetailsPrimersQuery & {
  __typename?: "ShoppingPathPrimersQuery";
  primers: MishopDetailsUiPrimer;
};

export type ShoppingPathType = "ATTACH" | "PACKAGE" | "STANDALONE";

export type ShoppingPillSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingPillSelectionField";
  id?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingPrice = {
  __typename?: "ShoppingPrice";
  displayMessages?: Maybe<Array<ShoppingPriceDisplayMessage>>;
};

export type ShoppingPriceDisplayMessage = {
  __typename?: "ShoppingPriceDisplayMessage";
  lineItems: Array<ShoppingPriceDisplayMessageItem>;
};

export type ShoppingPriceDisplayMessageItem = ShoppingDisplayPrice | ShoppingEnrichedMessage;

export type ShoppingProductCardContent =
  | ShoppingLoadSharedUi
  | ShoppingProductCardSheetListContent
  | ShoppingProductCardSheetSectionalContent
  | ShoppingProductCardSheetTabListContent
  | ShoppingProductCardSheetTabularContent;

export type ShoppingProductCardContentContainer = {
  __typename?: "ShoppingProductCardContentContainer";
  content: ShoppingProductCardContent;
  contentId: Scalars["String"]["output"];
};

export type ShoppingProductCardFooter = ShoppingProductCardFooterLineItem;

export type ShoppingProductCardFooterAction = ShoppingLink;

export type ShoppingProductCardFooterItem = EgdsStylizedText | ShoppingLink;

export type ShoppingProductCardFooterLineItem = {
  __typename?: "ShoppingProductCardFooterLineItem";
  actions?: Maybe<Array<ShoppingProductCardFooterAction>>;
  texts?: Maybe<Array<EgdsStylizedText>>;
};

export type ShoppingProductCardFooterSection = {
  __typename?: "ShoppingProductCardFooterSection";
  actionId?: Maybe<Scalars["String"]["output"]>;
  contents?: Maybe<Array<ShoppingProductCardFooterSectionContent>>;
};

export type ShoppingProductCardFooterSectionContent = {
  __typename?: "ShoppingProductCardFooterSectionContent";
  items?: Maybe<Array<ShoppingProductCardFooterItem>>;
};

export type ShoppingProductCardFunctionParametersContainers = {
  __typename?: "ShoppingProductCardFunctionParametersContainers";
  keyValueParams?: Maybe<Array<ShoppingBasicFunctionParams>>;
  paramsId: Scalars["String"]["output"];
};

export type ShoppingProductCardSheetListBodySection = {
  __typename?: "ShoppingProductCardSheetListBodySection";
  heading?: Maybe<ShoppingStylizedIconText>;
  items: EgdsTextList;
};

export type ShoppingProductCardSheetListContent = {
  __typename?: "ShoppingProductCardSheetListContent";
  body?: Maybe<Array<ShoppingProductCardSheetListBodySection>>;
  footer?: Maybe<ShoppingProductCardSheetPriceAndButtonFooter>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingProductCardSheetPriceAndButtonFooter = {
  __typename?: "ShoppingProductCardSheetPriceAndButtonFooter";
  /** @deprecated Marking this as deprecated because we want this button nullable and not show Book button on More details link for Ground Transfer. Also, making change here would be a breaking change as many clients are using it. */
  button: ShoppingButton;
  buttonV2?: Maybe<ShoppingButton>;
  oldPriceSection?: Maybe<PropertyPrice>;
  /** @deprecated While we want to eventually use this, it doesn't support all usecases yet, so we need to use the oldPriceSection until then. Marking this as deprecated for now */
  priceSection: ShoppingPrice;
};

export type ShoppingProductCardSheetSection = {
  __typename?: "ShoppingProductCardSheetSection";
  lineItems?: Maybe<Array<ShoppingProductCardSheetSectionLineItem>>;
};

export type ShoppingProductCardSheetSectionLineItem = {
  __typename?: "ShoppingProductCardSheetSectionLineItem";
  icons?: Maybe<Array<Icon>>;
  price?: Maybe<ShoppingPrice>;
  texts?: Maybe<Array<EgdsStylizedText>>;
};

export type ShoppingProductCardSheetSectionalContent = {
  __typename?: "ShoppingProductCardSheetSectionalContent";
  button?: Maybe<ShoppingButton>;
  header?: Maybe<Array<EgdsSpannableText>>;
  sections?: Maybe<Array<ShoppingProductCardSheetSection>>;
};

export type ShoppingProductCardSheetTabListContent = {
  __typename?: "ShoppingProductCardSheetTabListContent";
  content?: Maybe<Array<ShoppingProductCardSheetListBodySection>>;
};

export type ShoppingProductCardSheetTabularContent = {
  __typename?: "ShoppingProductCardSheetTabularContent";
  body: ShoppingTabs;
  footer?: Maybe<ShoppingProductCardSheetPriceAndButtonFooter>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingProductContent = {
  __typename?: "ShoppingProductContent";
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  items: Array<ShoppingProductContentItem>;
  maxColumns: Scalars["Int"]["output"];
  structuredData?: Maybe<SeoStructuredData>;
};

export type ShoppingProductContentElement = ShoppingProductContent;

export type ShoppingProductContentElementGroup = {
  __typename?: "ShoppingProductContentElementGroup";
  elements: Array<ShoppingProductContentElement>;
  maxColumns: Scalars["Int"]["output"];
};

export type ShoppingProductContentGraphicsItem = {
  __typename?: "ShoppingProductContentGraphicsItem";
  actionId?: Maybe<Scalars["String"]["output"]>;
  additionalText?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  leadingIcon?: Maybe<Icon>;
  mediaItem?: Maybe<ShoppingMediaItem>;
  state?: Maybe<Scalars["String"]["output"]>;
  structuredData?: Maybe<SeoStructuredData>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  trailingIcon?: Maybe<ShoppingActionableIcon>;
};

export type ShoppingProductContentItem =
  | EgdsPlainText
  | ShoppingProductContentGraphicsItem
  | ShoppingProductContentItemTexts;

export type ShoppingProductContentItemTexts = {
  __typename?: "ShoppingProductContentItemTexts";
  contents?: Maybe<Array<Scalars["String"]["output"]>>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingProductContentSection = {
  __typename?: "ShoppingProductContentSection";
  action?: Maybe<ShoppingProductContentSectionAction>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sectionId?: Maybe<Scalars["String"]["output"]>;
  subSections: Array<ShoppingProductContentSubSection>;
};

export type ShoppingProductContentSectionAction = ShoppingButton | ShoppingLink;

export type ShoppingProductContentSectionGroup = {
  __typename?: "ShoppingProductContentSectionGroup";
  contents?: Maybe<Array<ShoppingProductContentSectionGroupContainer>>;
  expandoPeek?: Maybe<EgdsExpandoPeek>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  sections: Array<ShoppingProductContentSection>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
};

export type ShoppingProductContentSectionGroupContainer = {
  __typename?: "ShoppingProductContentSectionGroupContainer";
  content?: Maybe<Array<ShoppingProductContentSectionGroupContent>>;
  contentId: Scalars["String"]["output"];
};

export type ShoppingProductContentSectionGroupContent =
  | ShoppingGalleryCarousel
  | ShoppingProductContentSection
  | ShoppingProductContentSheet
  | ShoppingProductContentSheetTabsContent
  | ShoppingProductForm;

export type ShoppingProductContentSheet = {
  __typename?: "ShoppingProductContentSheet";
  body?: Maybe<EgdsTextList>;
  footer?: Maybe<Array<ShoppingButton>>;
  header?: Maybe<ShoppingProductHeader>;
};

export type ShoppingProductContentSheetTabsContent = {
  __typename?: "ShoppingProductContentSheetTabsContent";
  body: ShoppingTabs;
};

export type ShoppingProductContentSubSection = {
  __typename?: "ShoppingProductContentSubSection";
  elements: Array<ShoppingProductContentElementGroup>;
  header?: Maybe<ShoppingProductHeader>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  maxColumns: Scalars["Int"]["output"];
};

export type ShoppingProductForm = {
  __typename?: "ShoppingProductForm";
  action?: Maybe<ShoppingProductFormAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disclaimer?: Maybe<Array<EgdsText>>;
  formInput?: Maybe<Array<ShoppingProductFormInputElement>>;
  header?: Maybe<ShoppingProductHeader>;
};

export type ShoppingProductFormAction = ShoppingButton;

export type ShoppingProductFormInputElement =
  | EgdsBasicCheckBox
  | EgdsBasicSelect
  | EgdsDateRangePicker
  | EgdsSearchFormLocationField
  | EgdsSearchFormTravelersField
  | EgdsTextAreaInputField
  | EgdsTextInputField
  | ShoppingProductFormPhoneInputField;

export type ShoppingProductFormPhoneInputField = {
  __typename?: "ShoppingProductFormPhoneInputField";
  countryCode: EgdsBasicSelect;
  phoneNumber: EgdsTextInputField;
};

export type ShoppingProductHeader = {
  __typename?: "ShoppingProductHeader";
  additionalText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export interface ShoppingQuickAccessFilter {
  filterPill?: Maybe<EgdsPill>;
}

export type ShoppingRangeCharacteristics = {
  __typename?: "ShoppingRangeCharacteristics";
  labels?: Maybe<Array<ShoppingRangeLabel>>;
  max: Scalars["Int"]["output"];
  maxLabel: Scalars["String"]["output"];
  min: Scalars["Int"]["output"];
  minLabel: Scalars["String"]["output"];
  minStepDistance?: Maybe<Scalars["Int"]["output"]>;
  step: Scalars["Int"]["output"];
};

export type ShoppingRangeField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingRangeField";
  id?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  range?: Maybe<ShoppingRangeFilterOption>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingRangeFilterOption = ShoppingSortAndFilterOption & {
  __typename?: "ShoppingRangeFilterOption";
  analytics?: Maybe<ClientSideAnalytics>;
  characteristics: ShoppingRangeCharacteristics;
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Replaced with UIGraphic */
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  maxAccessibility?: Maybe<Scalars["String"]["output"]>;
  minAccessibility?: Maybe<Scalars["String"]["output"]>;
  primary: Scalars["String"]["output"];
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  selected: RangeValue;
  sliderType: ShoppingSortAndFilterSliderType;
};

export type ShoppingRangeIndicatorCard = {
  __typename?: "ShoppingRangeIndicatorCard";
  disclaimer?: Maybe<LodgingPlainDialog>;
  message?: Maybe<Scalars["String"]["output"]>;
  rangeIndicator: RangeIndicator;
};

export type ShoppingRangeLabel = {
  __typename?: "ShoppingRangeLabel";
  label: Scalars["String"]["output"];
  value: Scalars["Int"]["output"];
};

export type ShoppingRangeTextInputField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingRangeTextInputField";
  histogram?: Maybe<ShoppingHistogramBucketsCharacteristics>;
  id?: Maybe<Scalars["String"]["output"]>;
  lowerBoundAccessibility?: Maybe<Scalars["String"]["output"]>;
  maxValueAccessibility?: Maybe<EgdsLocalizedText>;
  maxValueAnalytics?: Maybe<ClientSideAnalytics>;
  maxValueInput: EgdsTextInputField;
  minValueAccessibility?: Maybe<EgdsLocalizedText>;
  minValueAnalytics?: Maybe<ClientSideAnalytics>;
  minValueInput: EgdsTextInputField;
  numberFormat?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  range: ShoppingRangeFilterOption;
  secondary?: Maybe<Scalars["String"]["output"]>;
  upperBoundAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingScrollsToView = ShoppingAction & {
  __typename?: "ShoppingScrollsToView";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  event?: Maybe<EgClickstreamEvent>;
  targetRef: Scalars["String"]["output"];
};

export type ShoppingSearchCriteria = {
  __typename?: "ShoppingSearchCriteria";
  booleans?: Maybe<Array<BooleanValue>>;
  counts?: Maybe<Array<NumberValue>>;
  dates?: Maybe<Array<DateValue>>;
  ranges?: Maybe<Array<RangeValue>>;
  selections?: Maybe<Array<SelectedValue>>;
};

export interface ShoppingSearchCriteriaInput {
  booleans?: InputMaybe<Array<BooleanValueInput>>;
  counts?: InputMaybe<Array<NumberValueInput>>;
  dates?: InputMaybe<Array<DateValueInput>>;
  ranges?: InputMaybe<Array<RangeValueInput>>;
  selections?: InputMaybe<Array<SelectedValueInput>>;
}

export type ShoppingSearchListingHeader = ShoppingListingContainer;

export type ShoppingSelectableCardState = "DISABLED" | "SELECTED" | "UNSELECTED";

export type ShoppingSelectableFilterOption = ShoppingSortAndFilterOption & {
  __typename?: "ShoppingSelectableFilterOption";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use selectAnalytics and deselectAnalytics instead */
  analytics: ClientSideAnalytics;
  default: Scalars["Boolean"]["output"];
  defaultRegionId?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  deselectAnalytics: ClientSideAnalytics;
  disabled: Scalars["Boolean"]["output"];
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Replaced with UIGraphic */
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  nestedOptions: Array<ShoppingSelectableFilterOption>;
  primary: Scalars["String"]["output"];
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  selectAnalytics: ClientSideAnalytics;
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export type ShoppingSelectableImageCard = {
  __typename?: "ShoppingSelectableImageCard";
  id: Scalars["String"]["output"];
  image: Image;
  state: ShoppingSelectableCardState;
  title: EgdsText;
};

export type ShoppingSelectedFilter = ShoppingAppliedFilter & {
  __typename?: "ShoppingSelectedFilter";
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  filter?: Maybe<EgdsPill>;
  nextSearchCriteria?: Maybe<ShoppingSearchCriteria>;
};

export type ShoppingSelectedFiltersField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingSelectedFiltersField";
  accessibility?: Maybe<EgdsLocalizedText>;
  primary?: Maybe<Scalars["String"]["output"]>;
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  selectedFiltersOrdering?: Maybe<Array<Scalars["String"]["output"]>>;
  shoppingSelectedFilters?: Maybe<Array<ShoppingSelectedFilter>>;
};

export type ShoppingSelectionExpando = {
  __typename?: "ShoppingSelectionExpando";
  /** @deprecated Use expandAnalytics and collapseAnalytics instead */
  analytics: ClientSideAnalytics;
  collapseAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  collapseAnalytics: ClientSideAnalytics;
  collapseLabel: Scalars["String"]["output"];
  expandAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  expandAnalytics: ClientSideAnalytics;
  expandLabel: Scalars["String"]["output"];
  minimalHeight?: Maybe<Scalars["Int"]["output"]>;
  threshold?: Maybe<Scalars["Int"]["output"]>;
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ShoppingSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingSelectionField";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<ShoppingSelectionExpando>;
  id?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingShareContent = {
  __typename?: "ShoppingShareContent";
  description: Scalars["String"]["output"];
  image?: Maybe<Image>;
  shareLinks: Array<ShoppingShareLink>;
  urlParameterKeys: Array<Scalars["String"]["output"]>;
};

export type ShoppingShareLink = ShoppingCopyLink | ShoppingSocialMediaLink;

export type ShoppingShareLinkAction = {
  __typename?: "ShoppingShareLinkAction";
  content?: Maybe<ShoppingShareContent>;
  sheet: EgdsSheet;
};

export type ShoppingShareLinks = {
  __typename?: "ShoppingShareLinks";
  action?: Maybe<ShoppingShareLinkAction>;
  button: UiTertiaryButton;
};

export type ShoppingSocialMediaLink = {
  __typename?: "ShoppingSocialMediaLink";
  analytics?: Maybe<ClientSideAnalytics>;
  embeddableSource: Scalars["String"]["output"];
  encodedUrlParameterKey: Scalars["String"]["output"];
  graphic?: Maybe<UiGraphic>;
  name: Scalars["String"]["output"];
  referrerId: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type ShoppingSortAndFilterAction = UiAction & {
  __typename?: "ShoppingSortAndFilterAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionType: ShoppingSortAndFilterActionType;
  analytics: ClientSideAnalytics;
};

export type ShoppingSortAndFilterActionType =
  | "CLEAR_DATA"
  | "CLOSE_AND_APPLY"
  | "CLOSE_AND_DO_NOT_APPLY"
  | "OPEN_MODAL";

export interface ShoppingSortAndFilterAppliedFilter {
  nextSearchCriteria: ShoppingSearchCriteria;
  pill: UiRemovablePill;
}

export type ShoppingSortAndFilterButtonTrigger = ShoppingQuickAccessFilter & {
  __typename?: "ShoppingSortAndFilterButtonTrigger";
  analytics?: Maybe<ClientSideAnalytics>;
  container: ShoppingSortAndFilterContainer;
  filterPill?: Maybe<EgdsPill>;
  filterPillActions?: Maybe<Array<ShoppingActionTrigger>>;
};

export type ShoppingSortAndFilterCenteredSheetAiContainer = ShoppingSortAndFilterContainer & {
  __typename?: "ShoppingSortAndFilterCenteredSheetAIContainer";
  applyAction?: Maybe<UiFloatingActionButton>;
  id?: Maybe<Scalars["String"]["output"]>;
  revealAction?: Maybe<UiSecondaryButton>;
  suggestionTitle?: Maybe<EgdsPlainText>;
  suggestions?: Maybe<Array<EgdsTextIconListItem>>;
  textInput?: Maybe<EgdsTextInputField>;
  toolbar?: Maybe<UiToolbar>;
  view: SortAndFiltersViewType;
};

export interface ShoppingSortAndFilterContainer {
  view: SortAndFiltersViewType;
}

export type ShoppingSortAndFilterContent = ShoppingSortAndFilterNotification;

export type ShoppingSortAndFilterContentContainer = {
  __typename?: "ShoppingSortAndFilterContentContainer";
  content: ShoppingSortAndFilterContent;
  contentId: Scalars["String"]["output"];
};

export type ShoppingSortAndFilterDialogContainer = ShoppingSortAndFilterContainer & {
  __typename?: "ShoppingSortAndFilterDialogContainer";
  applyAction?: Maybe<UiFloatingActionButton>;
  revealAction?: Maybe<UiFloatingActionButton>;
  revealButtonActions?: Maybe<Array<ShoppingActionTrigger>>;
  toolbar?: Maybe<UiToolbar>;
  view: SortAndFiltersViewType;
};

export interface ShoppingSortAndFilterField {
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
}

export type ShoppingSortAndFilterNotification = {
  __typename?: "ShoppingSortAndFilterNotification";
  closeButton?: Maybe<ShoppingButton>;
  primary?: Maybe<ShoppingSortAndFilterNotificationMessage>;
  secondaries?: Maybe<Array<ShoppingSortAndFilterNotificationMessage>>;
};

export type ShoppingSortAndFilterNotificationMessage = EgdsPlainText;

export interface ShoppingSortAndFilterOption {
  analytics?: Maybe<ClientSideAnalytics>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated we are replacing icon with UIGraphic, which is a union type of Icon, Illustration and Mark */
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
}

export type ShoppingSortAndFilterQuickFilter = ShoppingQuickAccessFilter & {
  __typename?: "ShoppingSortAndFilterQuickFilter";
  analytics?: Maybe<ClientSideAnalytics>;
  applyAction?: Maybe<EgdsButton>;
  clearAll?: Maybe<EgdsButton>;
  fields: Array<ShoppingSortAndFilterField>;
  filterPill?: Maybe<EgdsPill>;
  filterPillActions?: Maybe<Array<ShoppingActionTrigger>>;
  resultCountLabel?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<UiToolbar>;
};

export type ShoppingSortAndFilterSection = {
  __typename?: "ShoppingSortAndFilterSection";
  fields: Array<ShoppingSortAndFilterField>;
  id?: Maybe<Scalars["String"]["output"]>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingSortAndFilterSliderType = "DUAL" | "SINGLE";

export type ShoppingSortAndFilterToggleFilter = ShoppingQuickAccessFilter & {
  __typename?: "ShoppingSortAndFilterToggleFilter";
  analytics?: Maybe<ClientSideAnalytics>;
  filterPill?: Maybe<EgdsPill>;
  option: ShoppingSortAndFilterOption;
};

export type ShoppingSortAndFilters = {
  __typename?: "ShoppingSortAndFilters";
  /** @deprecated Use revealAction.badge */
  appliedFilterCount: Scalars["Int"]["output"];
  /** @deprecated Use `filtersApplied` instead for displaying removable pills for filters applied. */
  appliedFilters?: Maybe<Array<ShoppingSortAndFilterAppliedFilter>>;
  /** @deprecated Use `containers` instead. */
  applyAction?: Maybe<UiFloatingActionButton>;
  carousel?: Maybe<EgdsCarousel>;
  clearAll?: Maybe<UiTertiaryButton>;
  /** @deprecated Use toolbar.actions.secondaries */
  clearButtonTitle: Scalars["String"]["output"];
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use toolbar.actions.primary */
  close: Icon;
  containers?: Maybe<Array<ShoppingSortAndFilterContainer>>;
  contents?: Maybe<Array<ShoppingSortAndFilterContentContainer>>;
  filterSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  filtersApplied?: Maybe<Array<ShoppingAppliedFilter>>;
  footerLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use quickAccessFilters for filter pills view */
  pillSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  quickAccessFilters?: Maybe<Array<ShoppingQuickAccessFilter>>;
  /** @deprecated Use `containers` instead. */
  revealAction?: Maybe<UiFloatingActionButton>;
  scrollEndAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated Use sortSections and filterSections separately */
  sections: Array<ShoppingSortAndFilterSection>;
  shoppingJoinListContainer?: Maybe<ShoppingJoinListContainer>;
  sortSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  /** @deprecated Use sortSections with ShoppingTabSelectionField type for tabs view */
  tabSections?: Maybe<Array<ShoppingSortAndFilterSection>>;
  /** @deprecated Use toolbar.primary */
  title: Scalars["String"]["output"];
  tnlFields?: Maybe<Array<GraphQlPair>>;
  toggleButton?: Maybe<EgdsStandardSwitch>;
  /** @deprecated Use `containers` instead. */
  toolbar?: Maybe<UiToolbar>;
};

export type ShoppingStepInputField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingStepInputField";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  id?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  stepInput: EgdsBasicStepInput;
};

export type ShoppingStylizedIconText = {
  __typename?: "ShoppingStylizedIconText";
  icon: Icon;
  text: Scalars["String"]["output"];
  theme: TextTheme;
};

export type ShoppingTab = {
  __typename?: "ShoppingTab";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics: ClientSideAnalytics;
  contentId: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
};

export type ShoppingTabSelectionField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingTabSelectionField";
  id?: Maybe<Scalars["String"]["output"]>;
  options?: Maybe<Array<ShoppingSelectableFilterOption>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingTabs = {
  __typename?: "ShoppingTabs";
  navType?: Maybe<ShoppingTabsNavType>;
  selectedTabId?: Maybe<Scalars["String"]["output"]>;
  tabs?: Maybe<Array<ShoppingTab>>;
};

export type ShoppingTabsNavType = "JUMPLINK" | "NATURAL";

export type ShoppingTextInputField = ShoppingSortAndFilterField & {
  __typename?: "ShoppingTextInputField";
  action?: Maybe<ShoppingSortAndFilterAction>;
  analytics: ClientSideAnalytics;
  autoSuggest?: Maybe<AutoSuggestInfo>;
  clearButtonAnalytics?: Maybe<ClientSideAnalytics>;
  clearIcon?: Maybe<Icon>;
  emitters?: Maybe<Array<SortAndFilterSignalEmitter>>;
  icon?: Maybe<Icon>;
  id: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use multiSelections */
  multiSelected?: Maybe<Array<Scalars["String"]["output"]>>;
  multiSelections?: Maybe<Array<ShoppingTextMultiSelection>>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  receivers?: Maybe<Array<SortAndFilterSignalReceiver>>;
  secondary?: Maybe<Scalars["String"]["output"]>;
  selected?: Maybe<Scalars["String"]["output"]>;
  typeaheadInfo?: Maybe<TypeaheadInfo>;
};

export type ShoppingTextMultiSelection = {
  __typename?: "ShoppingTextMultiSelection";
  text: Scalars["String"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type ShoppingTriggerType = "CLICK" | "HOVER" | "LOAD";

export type ShortList = {
  __typename?: "ShortList";
  button: LodgingToggle;
  /** @deprecated DO NOT USE! - ShortList clickTracking will be moved to UCS */
  clickTracking: Array<ShortListDataMap>;
  favorited: Scalars["Boolean"]["output"];
  /** @deprecated Don't use this, it's no longer necessary. */
  metaData: ShortListMetaData;
  removeUrl: Scalars["String"]["output"];
  saveUrl: Scalars["String"]["output"];
  toast?: Maybe<LodgingToastMessage>;
};

export type ShortListDataMap = AllHotelProductsData | ShoppingGlobalData;

export type ShortListMetaData = {
  __typename?: "ShortListMetaData";
  chkIn: Scalars["String"]["output"];
  chkOut: Scalars["String"]["output"];
  hotelId: Scalars["String"]["output"];
  roomConfiguration: Scalars["String"]["output"];
};

export type Shortlist = {
  __typename?: "Shortlist";
  details?: Maybe<Array<ShortlistItem>>;
  summaries?: Maybe<Array<ShortlistSummaryItem>>;
};

export type ShortlistDetailsArgs = {
  destinationFilter?: InputMaybe<ShortlistDestinationFilterInput>;
  productType?: InputMaybe<ProductType>;
};

export type ShortlistSummariesArgs = {
  productType?: InputMaybe<ProductType>;
};

export interface ShortlistDestinationFilterInput {
  destinationType: RegionType;
  regionId: Scalars["String"]["input"];
}

export type ShortlistFlight = ProductInfo & {
  __typename?: "ShortlistFlight";
  dateDisplayString?: Maybe<Scalars["String"]["output"]>;
  originDestination?: Maybe<Scalars["String"]["output"]>;
  routeType?: Maybe<RouteType>;
  type?: Maybe<ProductType>;
};

export type ShortlistGeneric = ProductInfo & {
  __typename?: "ShortlistGeneric";
  type?: Maybe<ProductType>;
};

export type ShortlistItem = {
  __typename?: "ShortlistItem";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  imageURL?: Maybe<Scalars["URL"]["output"]>;
  lastModifiedDate?: Maybe<DateTime>;
  link?: Maybe<Scalars["URL"]["output"]>;
  metadata?: Maybe<Array<ShortlistMetadata>>;
  productId: Scalars["String"]["output"];
  productInfo?: Maybe<ProductInfo>;
  productType?: Maybe<ProductType>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ShortlistMetadata = {
  __typename?: "ShortlistMetadata";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface ShortlistMetadataInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type ShortlistProperty = ProductInfo & {
  __typename?: "ShortlistProperty";
  guestRating?: Maybe<ShortlistRating>;
  numReviews?: Maybe<ShortlistReviews>;
  price?: Maybe<Money>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionName?: Maybe<Scalars["String"]["output"]>;
  star?: Maybe<Scalars["Float"]["output"]>;
  type?: Maybe<ProductType>;
};

export type ShortlistRating = {
  __typename?: "ShortlistRating";
  ratingOverall?: Maybe<Scalars["Float"]["output"]>;
};

export type ShortlistReviews = {
  __typename?: "ShortlistReviews";
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type ShortlistSummaryItem = {
  __typename?: "ShortlistSummaryItem";
  productId: Scalars["String"]["output"];
  productType?: Maybe<ProductType>;
};

export type ShowDropdownOptions = {
  __typename?: "ShowDropdownOptions";
  isDropDownVisible?: Maybe<ActionDetailsType>;
};

export type SideBarActionArea = {
  __typename?: "SideBarActionArea";
  icon: Icon;
  optionItems?: Maybe<Array<UiSecondaryButton>>;
  optionsButton?: Maybe<UiSecondaryButton>;
  step: WizardStep;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SideBarAd = {
  __typename?: "SideBarAd";
  canOverrideImage: Scalars["Boolean"]["output"];
  content: SideBarContent;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type SideBarAdGroup = {
  __typename?: "SideBarAdGroup";
  ads: Array<SideBarAd>;
  id: Scalars["String"]["output"];
  locations: Array<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SideBarCampaign = {
  __typename?: "SideBarCampaign";
  adGroups: Array<SideBarAdGroup>;
  budgetAmount: Scalars["String"]["output"];
  endDate?: Maybe<Scalars["String"]["output"]>;
  goalId?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  productId: Scalars["String"]["output"];
  productName: Scalars["String"]["output"];
  startDate: Scalars["String"]["output"];
};

export type SideBarContent = {
  __typename?: "SideBarContent";
  adType: ProductContext;
  creative?: Maybe<SideBarCreative>;
  locale: Scalars["String"]["output"];
};

export type SideBarCreative = SideBarFlightCreative | SideBarLodgingCreative;

export type SideBarFlightCreative = {
  __typename?: "SideBarFlightCreative";
  description: Scalars["String"]["output"];
  headline: Scalars["String"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SideBarLodgingCreative = {
  __typename?: "SideBarLodgingCreative";
  description: Scalars["String"]["output"];
  headline: Scalars["String"]["output"];
  imageId?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
};

export interface SideSheetQueryContextInput {
  addOnRequested: Array<AddOnRequested>;
  isFirstLoad?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyContinuationId: Scalars["String"]["input"];
}

export type SideSheetType = "BREAKFAST" | "NONREFUNDABLE" | "PACKAGE" | "REFUNDABLE";

export type SignIn = {
  __typename?: "SignIn";
  buttonAction: FlightsAction;
  heading: Scalars["String"]["output"];
};

export type SignInCta = {
  __typename?: "SignInCTA";
  dismissButton: UiTertiaryButton;
  heading: Scalars["String"]["output"];
  illustration: Illustration;
  signInButton: UiPrimaryButton;
  signInCTAClickstreamPayload: SignInCtaClickStreamAnalytics;
  signInCTACookiePayload: SignInCtaCookiePayload;
};

export type SignInCtaClickStreamAnalytics = {
  __typename?: "SignInCTAClickStreamAnalytics";
  event: EgClickstreamEvent;
  experience: Experience;
};

export type SignInCtaCookiePayload = {
  __typename?: "SignInCTACookiePayload";
  cookieName: Scalars["String"]["output"];
  cookieTTL: Scalars["Int"]["output"];
};

export type SignUpSwitch = {
  __typename?: "SignUpSwitch";
  clickstreamSwitchOffSelected?: Maybe<RatesEgClickstreamData>;
  clickstreamSwitchOnSelected?: Maybe<RatesEgClickstreamData>;
  switch: EgdsStandardSwitch;
  switchOnMessage?: Maybe<SupplyOnboardingIconStylizedText>;
};

export type SignUpTooltip = {
  __typename?: "SignUpTooltip";
  clickstreamTooltipSelected?: Maybe<RatesEgClickstreamData>;
  content: SupplyOnboardingTooltip;
};

export type SignalDetail = {
  __typename?: "SignalDetail";
  detailedDescription: Scalars["String"]["output"];
  reasonUrn: Scalars["String"]["output"];
  summary: Scalars["String"]["output"];
};

export type SignalDetails = {
  __typename?: "SignalDetails";
  detailedDescription: Scalars["String"]["output"];
  reasonUrn: Scalars["String"]["output"];
  summary: Scalars["String"]["output"];
};

export type SignalType =
  | "BOOKING_SUCCESS"
  | "CONFIGURATION_ADDED"
  | "DUPLICATE_REQUEST"
  | "INVALID_EMAIL"
  | "OFFER_ADDED"
  | "OFFER_CHANGED"
  | "OFFER_REMOVED"
  | "OFFER_UNAVAILABLE"
  | "OPERATION_FAILED"
  | "PAYMENT_ERROR"
  | "PAYMENT_METHODS_APPLIED"
  | "PAYMENT_METHOD_UPDATED"
  | "PRICE_ADJUSTED"
  | "PRICE_CHANGED"
  | "REQUEST_CONFLICT"
  | "SESSION_EXPIRED"
  | "SESSION_TOKEN_UPDATED"
  | "SIGNAL_TYPE_INVALID"
  | "UNRECOGNIZED"
  | "UNRECOVERABLE_FAILURE";

export type SignedInUser = {
  __typename?: "SignedInUser";
  accessibility: Scalars["String"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
};

export interface SimilarDestinationsCriteriaInput {
  destinationId: Scalars["String"]["input"];
  includeOrigin?: InputMaybe<Scalars["Boolean"]["input"]>;
  lob?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SimilarFlightsContextInput {
  offerIdentifier?: InputMaybe<Scalars["String"]["input"]>;
}

export type SimilarFlightsInfo = {
  __typename?: "SimilarFlightsInfo";
  flightsJourneyCriteria?: Maybe<Array<SimilarFlightsJourneyCriteria>>;
  offerIdentifier: Scalars["String"]["output"];
  searchPreferences?: Maybe<FlightsSearchPreferences>;
  travelerDetails?: Maybe<Array<FlightsTravelerDetails>>;
  tripType?: Maybe<FlightsTripType>;
};

export type SimilarFlightsJourneyCriteria = {
  __typename?: "SimilarFlightsJourneyCriteria";
  arrivalDate?: Maybe<Date>;
  departureDate?: Maybe<Date>;
  destination?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<Scalars["String"]["output"]>;
};

export type SimilarFlightsPrimer = {
  __typename?: "SimilarFlightsPrimer";
  offerIdentifier: Scalars["String"]["output"];
};

export type SimilarImageDialog = {
  __typename?: "SimilarImageDialog";
  fallback?: Maybe<DialogView>;
  primary?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<ProductDialogToolbar>;
  trigger?: Maybe<ProductDialogTriggerMessage>;
};

export type SimilarProperties = {
  __typename?: "SimilarProperties";
  footer?: Maybe<Scalars["String"]["output"]>;
  galleryCarousel?: Maybe<EgdsGalleryCarousel>;
  header?: Maybe<Scalars["String"]["output"]>;
  lodgingRecommendationClickstreamEvent?: Maybe<LodgingRecommendationClickstreamEvent>;
  properties: Array<SimilarProperty>;
  selectionOptions?: Maybe<LodgingSimilarSelectionOptions>;
};

export type SimilarPropertiesDeals = {
  __typename?: "SimilarPropertiesDeals";
  analytics?: Maybe<ClientSideAnalytics>;
  carouselActions?: Maybe<CarouselActions>;
  header?: Maybe<Scalars["String"]["output"]>;
  propertyOffers?: Maybe<Array<PropertyOfferItem>>;
};

export type SimilarProperty = {
  __typename?: "SimilarProperty";
  action: MessagingAction;
  /** @deprecated Deprecated in favour of featuredMessages */
  featuredMessage?: Maybe<LodgingEnrichedMessage>;
  featuredMessages: Array<EgdsGraphicText>;
  image?: Maybe<PropertyImage>;
  items: Array<LodgingEnrichedMessage>;
  price?: Maybe<SimilarPropertyPriceInfo>;
  /** @deprecated Used only for tracking, and will be removed in the future. */
  propertyId?: Maybe<Scalars["String"]["output"]>;
  reviews?: Maybe<PropertyReviewsSummary>;
  title: LodgingHeader;
};

export type SimilarPropertyPriceInfo = {
  __typename?: "SimilarPropertyPriceInfo";
  price: PropertyPrice;
  qualifier?: Maybe<LodgingEnrichedMessage>;
  subText?: Maybe<LodgingHeader>;
};

export type SimpleStepIndicatorChangeSelection = StepIndicatorChangeSelection & {
  __typename?: "SimpleStepIndicatorChangeSelection";
  a11yLabel: Scalars["String"]["output"];
  action: UiLinkAction;
  label: Scalars["String"]["output"];
  multiItemShoppingAction?: Maybe<MultiItemShoppingAction>;
};

export type SimpleStepIndicatorLabel = StepIndicatorLabel & {
  __typename?: "SimpleStepIndicatorLabel";
  detailText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SingleEmailValidation = EgdsInputValidation & {
  __typename?: "SingleEmailValidation";
  errorMessage: Scalars["String"]["output"];
};

export type SingleUnitOfferDetails = AccessibilityField & {
  __typename?: "SingleUnitOfferDetails";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  availabilityCallToAction?: Maybe<AvailabilityCallToAction>;
  displayPrice?: Maybe<DisplayPriceSummaryItem>;
  id: Scalars["String"]["output"];
  installmentPlan?: Maybe<LodgingInstallmentPlan>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  ratePlans?: Maybe<Array<RatePlan>>;
  totalPrice?: Maybe<TotalPriceSummary>;
  view?: Maybe<Scalars["String"]["output"]>;
};

export type SingleUnitOfferDialog = LodgingDialog & {
  __typename?: "SingleUnitOfferDialog";
  content: SingleUnitOfferDetails;
  toolbar: LodgingDialogToolbar;
  trigger: LodgingDialogTriggerMessage;
};

export type SiteConfiguration = {
  __typename?: "SiteConfiguration";
  domain: Scalars["String"]["output"];
  eapid: Scalars["Int"]["output"];
  siteId: Scalars["Int"]["output"];
  tpid: Scalars["Int"]["output"];
};

export interface SiteConfigurationsRequestInput {
  siteIds: Array<Scalars["Int"]["input"]>;
}

export type SiteContext = {
  __typename?: "SiteContext";
  brandId: Scalars["String"]["output"];
  domain: Scalars["String"]["output"];
  langId: Scalars["Int"]["output"];
  partnerAccountId: Scalars["String"]["output"];
  pointOfSaleId: Scalars["String"]["output"];
  privacySellState: PrivacySellState;
  shortDateFormat: Scalars["String"]["output"];
  tpid: Scalars["Int"]["output"];
  uiBrandId: Scalars["String"]["output"];
};

export type SleepingArrangement = {
  __typename?: "SleepingArrangement";
  spaces?: Maybe<Array<SleepingArrangementSpace>>;
  title: Scalars["String"]["output"];
};

export type SleepingArrangementLayout = {
  __typename?: "SleepingArrangementLayout";
  description: Scalars["String"]["output"];
  icons?: Maybe<Array<Icon>>;
};

export type SleepingArrangementSpace = {
  __typename?: "SleepingArrangementSpace";
  layouts?: Maybe<Array<SleepingArrangementLayout>>;
  name: Scalars["String"]["output"];
};

export interface SmartFormAction {
  clickstreamEvent?: Maybe<EgClickstreamEvent>;
  clientSideAnalytics: ClientSideAnalytics;
}

export type SmartFormButton = {
  __typename?: "SmartFormButton";
  actions?: Maybe<Array<SmartFormAction>>;
  button: EgdsButton;
};

export type SmartFormCard = {
  __typename?: "SmartFormCard";
  card: EgdsStandardMessagingCard;
  clickAction: SmartFormOpenSheetAction;
};

export type SmartFormCheckBoxGroup = {
  __typename?: "SmartFormCheckBoxGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  noSelectionValue?: Maybe<Scalars["String"]["output"]>;
  options: Array<SmartFormCheckbox>;
};

export type SmartFormCheckbox = {
  __typename?: "SmartFormCheckbox";
  checkbox: EgdsBasicCheckBox;
  trackingId?: Maybe<Scalars["String"]["output"]>;
};

export type SmartFormCheckboxGroupInputComponent = {
  __typename?: "SmartFormCheckboxGroupInputComponent";
  inputId: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  smartFormCheckBoxGroup: SmartFormCheckBoxGroup;
  triggerOperationOnChange?: Maybe<OperationType>;
};

export type SmartFormClickTrackingAction = SmartFormAction & {
  __typename?: "SmartFormClickTrackingAction";
  clickstreamEvent?: Maybe<EgClickstreamEvent>;
  clientSideAnalytics: ClientSideAnalytics;
};

export type SmartFormContainer = {
  __typename?: "SmartFormContainer";
  elements: Array<SmartFormContainerElement>;
};

export type SmartFormContainerElement = SmartFormSection;

export interface SmartFormDataInput {
  timestamp: Scalars["String"]["input"];
  userInput: SmartFormUserInput;
}

export type SmartFormFieldSet = {
  __typename?: "SmartFormFieldSet";
  description?: Maybe<Scalars["String"]["output"]>;
  elements: Array<SmartFormFieldSetElement>;
  label: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
  requiredLabel?: Maybe<Scalars["String"]["output"]>;
};

export type SmartFormFieldSetElement = SmartFormInput | SmartFormText;

export type SmartFormHeading = {
  __typename?: "SmartFormHeading";
  size: SmartFormHeadingSizeInput;
  tag: SmartFormHeadingTagInput;
  text: Scalars["String"]["output"];
};

export type SmartFormHeadingSizeInput = "EIGHT" | "FIVE" | "FOUR" | "ONE" | "SEVEN" | "SIX" | "THREE" | "TWO";

export type SmartFormHeadingTagInput = "H1" | "H2" | "H3" | "H4" | "H5" | "H6";

export type SmartFormHostCard = {
  __typename?: "SmartFormHostCard";
  description?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  name: Scalars["String"]["output"];
  premierLabel?: Maybe<EgdsGraphicText>;
};

export type SmartFormInput = {
  __typename?: "SmartFormInput";
  actions?: Maybe<Array<SmartFormAction>>;
  inputComponent: SmartFormInputComponent;
  /** @deprecated inputId are moved inside each inputComponent. Try to use that one */
  inputId: Scalars["String"]["output"];
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SmartFormInputComponent =
  | SmartFormCheckboxGroupInputComponent
  | SmartFormPhoneNumberInputComponentV2
  | SmartFormRadioGroupInputComponent
  | SmartFormSelectInputComponent
  | SmartFormTextInputComponent
  | SmartFormTypeaheadInputComponent;

export type SmartFormMutation = {
  __typename?: "SmartFormMutation";
  updateFormData: SmartFormMutationBaseResponse;
};

export type SmartFormMutationUpdateFormDataArgs = {
  data: SmartFormDataInput;
};

export type SmartFormMutationBaseResponse = {
  __typename?: "SmartFormMutationBaseResponse";
  sessionToken: Scalars["String"]["output"];
  signals?: Maybe<Array<Scalars["String"]["output"]>>;
  success: Scalars["Boolean"]["output"];
};

export type SmartFormOpenSheetAction = SmartFormAction & {
  __typename?: "SmartFormOpenSheetAction";
  clickstreamEvent?: Maybe<EgClickstreamEvent>;
  clientSideAnalytics: ClientSideAnalytics;
  sheet: SmartFormSheet;
};

export type SmartFormPhoneNumberInputComponentV2 = {
  __typename?: "SmartFormPhoneNumberInputComponentV2";
  countryCode: SmartFormSelectInputComponent;
  phoneNumber: SmartFormTextInputComponent;
  triggerOperationOnChange?: Maybe<OperationType>;
};

export type SmartFormPlaybackCard = {
  __typename?: "SmartFormPlaybackCard";
  card: EgdsStandardMessagingCard;
  clickAction: SmartFormOpenSheetAction;
  defaultPlayback: SmartFormText;
  leftIcon: Icon;
  required: Scalars["Boolean"]["output"];
  rightIcon: Icon;
  statusIcons: Array<StatusIcon>;
};

export type SmartFormPrefillData = {
  __typename?: "SmartFormPrefillData";
  inputId: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SmartFormPrefillOption = {
  __typename?: "SmartFormPrefillOption";
  label: Scalars["String"]["output"];
  leftIcon?: Maybe<Icon>;
  prefillDataList: Array<SmartFormPrefillData>;
  selected?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SmartFormPrefillSelection = {
  __typename?: "SmartFormPrefillSelection";
  label: Scalars["String"]["output"];
  leftIcon?: Maybe<Icon>;
  listenerInputIds?: Maybe<Array<Scalars["String"]["output"]>>;
  noPrefillOption?: Maybe<SmartFormPrefillOption>;
  options: Array<SmartFormPrefillOption>;
  required: Scalars["Boolean"]["output"];
  rightIcon?: Maybe<Icon>;
};

export type SmartFormQuery = {
  __typename?: "SmartFormQuery";
  view: SmartFormViewResponse;
};

export type SmartFormRadio = {
  __typename?: "SmartFormRadio";
  radioButton: EgdsBasicRadioButton;
  trackingId?: Maybe<Scalars["String"]["output"]>;
};

export type SmartFormRadioGroup = {
  __typename?: "SmartFormRadioGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  options: Array<SmartFormRadio>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type SmartFormRadioGroupInputComponent = {
  __typename?: "SmartFormRadioGroupInputComponent";
  inputId: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  smartFormRadioGroup: SmartFormRadioGroup;
  triggerOperationOnChange?: Maybe<OperationType>;
};

export type SmartFormSection = {
  __typename?: "SmartFormSection";
  analytics?: Maybe<EgClickstreamEvent>;
  elements: Array<SmartFormSectionElement>;
  smartFormHeading?: Maybe<SmartFormHeading>;
};

export type SmartFormSectionElement =
  | SmartFormCard
  | SmartFormFieldSet
  | SmartFormHostCard
  | SmartFormInput
  | SmartFormPlaybackCard
  | SmartFormPrefillSelection
  | SmartFormText;

export type SmartFormSelectInputComponent = EgdsSelect & {
  __typename?: "SmartFormSelectInputComponent";
  disabled: Scalars["Boolean"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  inputId: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  options: Array<EgdsOption>;
  readOnly: Scalars["Boolean"]["output"];
  required: Scalars["Boolean"]["output"];
  triggerOperationOnChange?: Maybe<OperationType>;
};

export type SmartFormSelectInputOption = EgdsOption & {
  __typename?: "SmartFormSelectInputOption";
  label: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  shortLabel: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SmartFormShareableInputId = {
  __typename?: "SmartFormShareableInputId";
  externalKey: Scalars["String"]["output"];
  internalInputId: Scalars["String"]["output"];
};

export type SmartFormSheet = SmartFormSubForm & {
  __typename?: "SmartFormSheet";
  description?: Maybe<Scalars["String"]["output"]>;
  elements: Array<SmartFormSheetElement>;
  heading: Scalars["String"]["output"];
  saveButton?: Maybe<SmartFormButton>;
  sheet: EgdsSheet;
  subFormId: Scalars["String"]["output"];
};

export type SmartFormSheetElement = SmartFormFieldSet | SmartFormPrefillSelection;

export type SmartFormSignalAction = {
  __typename?: "SmartFormSignalAction";
  csrfData: IdentityCsrfAto;
  mutationToCall: Scalars["String"]["output"];
  requiredFields: Array<SmartFormSignalRequiredFields>;
};

export type SmartFormSignalRequiredFields = {
  __typename?: "SmartFormSignalRequiredFields";
  externalKey: Scalars["String"]["output"];
  internalInputId: Scalars["String"]["output"];
};

export interface SmartFormSubForm {
  subFormId: Scalars["String"]["output"];
}

export type SmartFormText = {
  __typename?: "SmartFormText";
  textComponent: EgdsComposableTextElement;
};

export type SmartFormTextInputComponent = EgdsInputField & {
  __typename?: "SmartFormTextInputComponent";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  inputId: Scalars["String"]["output"];
  instructions?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  required: Scalars["Boolean"]["output"];
  rightIcon?: Maybe<Icon>;
  triggerOperationOnChange?: Maybe<OperationType>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
  variant: SmartFormTextInputVariant;
};

export type SmartFormTextInputVariant = "EMAIL" | "NUMERIC" | "TEL" | "TEXT" | "TEXTAREA";

export type SmartFormToastAction = SmartFormAction & {
  __typename?: "SmartFormToastAction";
  clickstreamEvent?: Maybe<EgClickstreamEvent>;
  clientSideAnalytics: ClientSideAnalytics;
  toast?: Maybe<EgdsToast>;
};

export type SmartFormType = "BILLING_ADDRESS" | "CONTACT_INFORMATION";

export type SmartFormTypeaheadInputComponent = {
  __typename?: "SmartFormTypeaheadInputComponent";
  domainId?: Maybe<Scalars["String"]["output"]>;
  inputId: Scalars["String"]["output"];
  triggerOperationOnChange?: Maybe<OperationType>;
  typeahead: EgdsTypeaheadInputField;
};

export interface SmartFormUserFieldInput {
  inputId: Scalars["String"]["input"];
  value: Array<Scalars["String"]["input"]>;
}

export interface SmartFormUserInput {
  userFieldInputs: Array<SmartFormUserFieldInput>;
}

export type SmartFormViewResponse = {
  __typename?: "SmartFormViewResponse";
  containers: Array<SmartFormContainer>;
  shareableInputIds: Array<SmartFormShareableInputId>;
};

export interface SmokingTypeSelectedInput {
  nonSmokingSelected: Scalars["Boolean"]["input"];
  smokingSelected: Scalars["Boolean"]["input"];
}

export type SocialConnectionsAcceptConnectionRequestAction = {
  __typename?: "SocialConnectionsAcceptConnectionRequestAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  userToken: Scalars["String"]["output"];
};

export type SocialConnectionsAction =
  | SocialConnectionsAcceptConnectionRequestAction
  | SocialConnectionsDeclineConnectionRequestAction
  | SocialConnectionsSendConnectionRequestAction
  | SocialConnectionsShowToastAction
  | SocialConnectionsViewProfileAction;

export interface SocialConnectionsAnalyticEvent {
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
}

export type SocialConnectionsAvatar = {
  __typename?: "SocialConnectionsAvatar";
  action?: Maybe<SocialConnectionsAction>;
  egdsAvatar: EgdsAvatar;
  firstName: Scalars["String"]["output"];
};

export type SocialConnectionsBackAction = {
  __typename?: "SocialConnectionsBackAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
};

export type SocialConnectionsBadge = {
  __typename?: "SocialConnectionsBadge";
  badge: EgdsStandardBadge;
};

export type SocialConnectionsBasicRadioGroup = {
  __typename?: "SocialConnectionsBasicRadioGroup";
  basicRadioGroup: EgdsBasicRadioGroup;
};

export type SocialConnectionsButton = {
  __typename?: "SocialConnectionsButton";
  action: SocialConnectionsButtonAction;
  button: EgdsButton;
};

export type SocialConnectionsButtonAction =
  | SocialConnectionsBackAction
  | SocialConnectionsChatWithConnectionAction
  | SocialConnectionsCloseAction
  | SocialConnectionsCreateLinkAction
  | SocialConnectionsNavigateAction
  | SocialConnectionsNavigateToSettingsAction
  | SocialConnectionsOutwardLinkAction
  | SocialConnectionsRemoveConnectionAction
  | SocialConnectionsRemoveConnectionCancelAction
  | SocialConnectionsRemoveConnectionConfirmationAction
  | SocialConnectionsSaveSettingsAction;

export type SocialConnectionsChatWithConnectionAction = {
  __typename?: "SocialConnectionsChatWithConnectionAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
};

export interface SocialConnectionsClientContextInput {
  lineOfBusiness: LineOfBusinessDomain;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  pageLocation: PageLocation;
}

export type SocialConnectionsCloseAction = {
  __typename?: "SocialConnectionsCloseAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  webTargetUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SocialConnectionsCountry = {
  __typename?: "SocialConnectionsCountry";
  icon: Icon;
  name: Scalars["String"]["output"];
};

export type SocialConnectionsCreateLinkAction = {
  __typename?: "SocialConnectionsCreateLinkAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  unexpectedErrorMessage?: Maybe<UiBanner>;
};

export type SocialConnectionsDeclineConnectionRequestAction = {
  __typename?: "SocialConnectionsDeclineConnectionRequestAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  userToken: Scalars["String"]["output"];
};

export type SocialConnectionsDialog = {
  __typename?: "SocialConnectionsDialog";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  dismissButton: SocialConnectionsButton;
  primaryButton?: Maybe<SocialConnectionsButton>;
  secondaryButton?: Maybe<SocialConnectionsButton>;
  text: Array<SocialConnectionsSpannableText>;
};

export type SocialConnectionsHubAcceptedSection = {
  __typename?: "SocialConnectionsHubAcceptedSection";
  heading: Scalars["String"]["output"];
  items: Array<SocialConnectionsAvatar>;
};

export type SocialConnectionsHubFailureResponse = {
  __typename?: "SocialConnectionsHubFailureResponse";
  error: UiBanner;
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
};

export type SocialConnectionsHubPendingRequest = {
  __typename?: "SocialConnectionsHubPendingRequest";
  acceptButton: SocialConnectionsPill;
  denyButton: SocialConnectionsPill;
  requestedFrom: SocialConnectionsAvatar;
};

export type SocialConnectionsHubPendingSection = {
  __typename?: "SocialConnectionsHubPendingSection";
  heading: Scalars["String"]["output"];
  items: Array<SocialConnectionsHubPendingRequest>;
};

export type SocialConnectionsHubPotentialConnection = {
  __typename?: "SocialConnectionsHubPotentialConnection";
  connectButton: SocialConnectionsPill;
  user: SocialConnectionsAvatar;
};

export type SocialConnectionsHubPotentialSection = {
  __typename?: "SocialConnectionsHubPotentialSection";
  heading: Scalars["String"]["output"];
  items: Array<SocialConnectionsHubPotentialConnection>;
};

export type SocialConnectionsHubResponse = SocialConnectionsHubFailureResponse | SocialConnectionsHubSuccessResponse;

export type SocialConnectionsHubSection =
  | SocialConnectionsHubAcceptedSection
  | SocialConnectionsHubPendingSection
  | SocialConnectionsHubPotentialSection
  | SocialConnectionsHubSentSection;

export type SocialConnectionsHubSentRequest = {
  __typename?: "SocialConnectionsHubSentRequest";
  pendingButton: SocialConnectionsPill;
  recipient: SocialConnectionsAvatar;
};

export type SocialConnectionsHubSentSection = {
  __typename?: "SocialConnectionsHubSentSection";
  heading: Scalars["String"]["output"];
  items: Array<SocialConnectionsHubSentRequest>;
};

export type SocialConnectionsHubSuccessResponse = {
  __typename?: "SocialConnectionsHubSuccessResponse";
  backButton: SocialConnectionsButton;
  footer?: Maybe<SocialConnectionsSpannableText>;
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
  navigationTitle: Scalars["String"]["output"];
  sections: Array<SocialConnectionsHubSection>;
};

export type SocialConnectionsImageCard = {
  __typename?: "SocialConnectionsImageCard";
  egdsImageCard: EgdsImageCard;
};

export type SocialConnectionsImpressionAnalyticEvent = SocialConnectionsAnalyticEvent & {
  __typename?: "SocialConnectionsImpressionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SocialConnectionsInlineLink = SocialConnectionsSpannableTextItem & {
  __typename?: "SocialConnectionsInlineLink";
  action: SocialConnectionsLinkAction;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  text: Scalars["String"]["output"];
  trailingIcons?: Maybe<Array<Icon>>;
};

export type SocialConnectionsInteractionAnalyticEvent = SocialConnectionsAnalyticEvent & {
  __typename?: "SocialConnectionsInteractionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SocialConnectionsLinkAction = SocialConnectionsCreateLinkAction | SocialConnectionsOutwardLinkAction;

export type SocialConnectionsManageConnectionFailureResponse = {
  __typename?: "SocialConnectionsManageConnectionFailureResponse";
  error: UiBanner;
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
};

export interface SocialConnectionsManageConnectionRequestInput {
  type: SocialConnectionsManageConnectionRequestType;
  userToken: Scalars["String"]["input"];
}

export type SocialConnectionsManageConnectionRequestType = "ACCEPT" | "REJECT" | "REMOVE" | "REQUEST";

export type SocialConnectionsManageConnectionResponse =
  | SocialConnectionsManageConnectionFailureResponse
  | SocialConnectionsManageConnectionSuccessResponse;

export type SocialConnectionsManageConnectionSuccessResponse = {
  __typename?: "SocialConnectionsManageConnectionSuccessResponse";
  action: SocialConnectionsShowToastAction;
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
  updatedSections: Array<SocialConnectionsHubSection>;
};

export type SocialConnectionsNavigateAction = {
  __typename?: "SocialConnectionsNavigateAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  destination: Scalars["String"]["output"];
};

export type SocialConnectionsNavigateToSettingsAction = {
  __typename?: "SocialConnectionsNavigateToSettingsAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
};

export type SocialConnectionsOtherProfileDetailsCtas = {
  __typename?: "SocialConnectionsOtherProfileDetailsCtas";
  chatButton: SocialConnectionsButton;
  chatDialog: SocialConnectionsDialog;
  removeButton: SocialConnectionsButton;
  removeDialog: SocialConnectionsDialog;
};

export type SocialConnectionsOutwardLinkAction = {
  __typename?: "SocialConnectionsOutwardLinkAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  openLinkInExternalView?: Maybe<Scalars["Boolean"]["output"]>;
  url: Scalars["String"]["output"];
};

export type SocialConnectionsPill = {
  __typename?: "SocialConnectionsPill";
  action?: Maybe<SocialConnectionsAction>;
  pill: EgdsPill;
};

export type SocialConnectionsProfileDetailsCtas =
  | SocialConnectionsOtherProfileDetailsCtas
  | SocialConnectionsSelfProfileDetailsCtas;

export type SocialConnectionsProfileDetailsFailureResponse = {
  __typename?: "SocialConnectionsProfileDetailsFailureResponse";
  error: UiBanner;
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
};

export interface SocialConnectionsProfileDetailsRequestInput {
  userToken?: InputMaybe<Scalars["String"]["input"]>;
}

export type SocialConnectionsProfileDetailsResponse =
  | SocialConnectionsProfileDetailsFailureResponse
  | SocialConnectionsProfileDetailsSuccessResponse;

export type SocialConnectionsProfileDetailsSuccessResponse = {
  __typename?: "SocialConnectionsProfileDetailsSuccessResponse";
  avatar: SocialConnectionsAvatar;
  backButton: SocialConnectionsButton;
  connectionsButton?: Maybe<SocialConnectionsButton>;
  country: SocialConnectionsCountry;
  ctas: SocialConnectionsProfileDetailsCtas;
  fullName: Scalars["String"]["output"];
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
  interests?: Maybe<SocialConnectionsTravelerInterests>;
  loyaltyBadge?: Maybe<SocialConnectionsBadge>;
  navigationTitle: Scalars["String"]["output"];
  travelerBio: SocialConnectionsSpannableText;
  visitedPlaces?: Maybe<SocialConnectionsTravelerVisitedPlaces>;
};

export type SocialConnectionsProfileSettingsFailureResponse = {
  __typename?: "SocialConnectionsProfileSettingsFailureResponse";
  error: UiBanner;
};

export type SocialConnectionsProfileSettingsResponse =
  | SocialConnectionsProfileSettingsFailureResponse
  | SocialConnectionsProfileSettingsSuccessResponse;

export type SocialConnectionsProfileSettingsSection = SocialConnectionsBasicRadioGroup;

export interface SocialConnectionsProfileSettingsSectionInput {
  groupName: Scalars["String"]["input"];
  selectedValues: Array<Scalars["String"]["input"]>;
}

export type SocialConnectionsProfileSettingsSuccessResponse = {
  __typename?: "SocialConnectionsProfileSettingsSuccessResponse";
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
  navigationTitle: Scalars["String"]["output"];
  saveButton: SocialConnectionsButton;
  sections: Array<SocialConnectionsProfileSettingsSection>;
};

export type SocialConnectionsRemoveConnectionAction = {
  __typename?: "SocialConnectionsRemoveConnectionAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  userToken: Scalars["String"]["output"];
};

export type SocialConnectionsRemoveConnectionCancelAction = {
  __typename?: "SocialConnectionsRemoveConnectionCancelAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
};

export type SocialConnectionsRemoveConnectionConfirmationAction = {
  __typename?: "SocialConnectionsRemoveConnectionConfirmationAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
};

export type SocialConnectionsResponse = {
  __typename?: "SocialConnectionsResponse";
  connectionHub: SocialConnectionsHubResponse;
  profileDetails: SocialConnectionsProfileDetailsResponse;
  profileSettings: SocialConnectionsProfileSettingsResponse;
};

export type SocialConnectionsResponseProfileDetailsArgs = {
  requestInput: SocialConnectionsProfileDetailsRequestInput;
};

export type SocialConnectionsResponseProfileSettingsArgs = {
  clientContext: SocialConnectionsClientContextInput;
  context: ContextInput;
};

export type SocialConnectionsSaveProfileSettingsFailureResponse = {
  __typename?: "SocialConnectionsSaveProfileSettingsFailureResponse";
  error: UiBanner;
  impressionAnalytics: Array<SocialConnectionsImpressionAnalyticEvent>;
};

export interface SocialConnectionsSaveProfileSettingsRequestInput {
  sections: Array<SocialConnectionsProfileSettingsSectionInput>;
}

export type SocialConnectionsSaveProfileSettingsResponse =
  | SocialConnectionsSaveProfileSettingsFailureResponse
  | SocialConnectionsSaveProfileSettingsSuccessResponse;

export type SocialConnectionsSaveProfileSettingsSuccessResponse = {
  __typename?: "SocialConnectionsSaveProfileSettingsSuccessResponse";
  action: SocialConnectionsShowToastAction;
};

export type SocialConnectionsSaveSettingsAction = {
  __typename?: "SocialConnectionsSaveSettingsAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
};

export type SocialConnectionsSelfProfileDetailsCtas = {
  __typename?: "SocialConnectionsSelfProfileDetailsCtas";
  settingsButton: SocialConnectionsButton;
};

export type SocialConnectionsSendConnectionRequestAction = {
  __typename?: "SocialConnectionsSendConnectionRequestAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  userToken: Scalars["String"]["output"];
};

export type SocialConnectionsShowToastAction = {
  __typename?: "SocialConnectionsShowToastAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  toast: EgdsToast;
};

export type SocialConnectionsSpannableText = {
  __typename?: "SocialConnectionsSpannableText";
  inlineContent: Array<SocialConnectionsSpannableTextItem>;
};

export interface SocialConnectionsSpannableTextItem {
  text: Scalars["String"]["output"];
}

export type SocialConnectionsText = SocialConnectionsSpannableTextItem & {
  __typename?: "SocialConnectionsText";
  text: Scalars["String"]["output"];
};

export type SocialConnectionsTravelerInterests = {
  __typename?: "SocialConnectionsTravelerInterests";
  heading: Scalars["String"]["output"];
  impressionAnalytics: Array<SocialConnectionsAnalyticEvent>;
  items: Array<SocialConnectionsPill>;
};

export type SocialConnectionsTravelerVisitedPlaces = {
  __typename?: "SocialConnectionsTravelerVisitedPlaces";
  destinationsPreviewCount: Scalars["Int"]["output"];
  heading: Scalars["String"]["output"];
  impressionAnalytics: Array<SocialConnectionsAnalyticEvent>;
  places: Array<SocialConnectionsImageCard>;
};

export type SocialConnectionsViewProfileAction = {
  __typename?: "SocialConnectionsViewProfileAction";
  analytics: Array<SocialConnectionsAnalyticEvent>;
  userToken: Scalars["String"]["output"];
};

export type SocialInteractionElement =
  | LikeSocialInteractionElement
  | PhotosSocialInteractionElement
  | ReviewsSocialInteractionElement
  | SaveSocialInteractionElement
  | ShareSocialInteractionElement;

export type SocialInteractionElementAnalyticEvent = AnalyticEvent & {
  __typename?: "SocialInteractionElementAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SocialInteractionPostType = "EG_STORIES" | "HOTEL_VIDEO" | "PROPERTY_VIDEO" | "VIDEO";

export type SocialInteractionTripsSaveItem = {
  __typename?: "SocialInteractionTripsSaveItem";
  removeTripActionAnalytic: SocialInteractionElementAnalyticEvent;
  saveTripActionAnalytic: SocialInteractionElementAnalyticEvent;
  tripsSaveItem: TripsSaveItem;
};

export type SocialMediaButton = {
  __typename?: "SocialMediaButton";
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  socialMediaImage?: Maybe<ShareMedia>;
  socialMediaShareCustomAnalytics: SocialShareCustomAnalytics;
  socialMediaShareLink: EgdsStandardLink;
};

export type SocialMediaCta = {
  __typename?: "SocialMediaCTA";
  analytics?: Maybe<SocialShareAnalytics>;
  graphic?: Maybe<UiGraphic>;
  shareContent?: Maybe<SocialShareContent>;
  successToast?: Maybe<EgdsToast>;
};

export type SocialShareActionResponse = {
  __typename?: "SocialShareActionResponse";
  analytics?: Maybe<SocialShareAnalytics>;
  serverSideErrorToast?: Maybe<SocialShareErrorToast>;
  shareContent?: Maybe<EgdsSpannableText>;
  shareSheet?: Maybe<CustomSocialShareSheet>;
};

export type SocialShareAnalytics = {
  __typename?: "SocialShareAnalytics";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  successAnalytics?: Maybe<ClientSideAnalytics>;
};

export type SocialShareButtonResponse = {
  __typename?: "SocialShareButtonResponse";
  button?: Maybe<SocialShareButtonType>;
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  clientSideErrorToast: SocialShareErrorToast;
  strategy?: Maybe<SocialShareStrategy>;
};

export type SocialShareButtonType = EgdsOverlayButton | UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type SocialShareContent = {
  __typename?: "SocialShareContent";
  messages?: Maybe<EgdsSpannableText>;
  shareUrl?: Maybe<Uri>;
};

export type SocialShareCustomAnalytics = {
  __typename?: "SocialShareCustomAnalytics";
  clickImpressionAnalytics?: Maybe<ClientSideAnalytics>;
  loadAnalytics?: Maybe<ClientSideAnalytics>;
  outcomeAnalytics?: Maybe<ClientSideAnalytics>;
};

export type SocialShareErrorBanner = {
  __typename?: "SocialShareErrorBanner";
  description?: Maybe<Scalars["String"]["output"]>;
  errorAnalytics: ClientSideAnalytics;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SocialShareErrorToast = {
  __typename?: "SocialShareErrorToast";
  analytics: ClientSideAnalytics;
  text: Scalars["String"]["output"];
};

export type SocialShareSheetResponse = {
  __typename?: "SocialShareSheetResponse";
  clientSideErrorDialog: SocialShareErrorBanner;
  imageCard?: Maybe<EgdsImageCard>;
  message?: Maybe<EgdsSpannableText>;
  serverSideErrorDialog?: Maybe<SocialShareErrorBanner>;
  shareButtonAnalytics?: Maybe<SocialShareCustomAnalytics>;
  shareButtonType?: Maybe<ShareButtonTypes>;
  shareSheetAnalytics?: Maybe<SocialShareCustomAnalytics>;
  socialMediaButtonsForShareSheet?: Maybe<Array<SocialMediaButton>>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<EgdsToolbar>;
};

export type SocialShareStrategy =
  | "ACTIVITY"
  | "FLIGHT"
  | "FLIGHT_SEARCH"
  | "PROPERTY"
  | "PROPERTY_SEARCH"
  | "REQUEST_TO_JOIN_TRIP"
  | "TRIP_INVITE";

export type SoftPackages = {
  __typename?: "SoftPackages";
  actionURL?: Maybe<Scalars["String"]["output"]>;
  addCar?: Maybe<EgdsBasicCheckBox>;
  addFlight?: Maybe<EgdsBasicCheckBox>;
  addHotel?: Maybe<EgdsBasicCheckBox>;
  /** @deprecated Use addCar */
  car?: Maybe<EgdsPill>;
  datesForHotelStay?: Maybe<EgdsSearchFormDatePickerField>;
  /** @deprecated Use addFlight */
  flight?: Maybe<EgdsPill>;
  maxBookableTravelers?: Maybe<Scalars["Int"]["output"]>;
  maxTravelersErrorMessage?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<EgdsSearchFormLocationField>;
  validDaysUpperBoundInclusive?: Maybe<Date>;
};

export type SortAndFilter = {
  __typename?: "SortAndFilter";
  defaults?: Maybe<SortAndFilterDefaults>;
  options?: Maybe<SortAndFilterViewModel>;
  /** @deprecated This is redundant information that can be found elsewhere and pwa specific, replace with SortAndFilterOptions isSelected boolean or the SearchCriteria.Filters */
  selected?: Maybe<SortAndFilterSelected>;
};

export type SortAndFilterBasicFilterPayload = {
  __typename?: "SortAndFilterBasicFilterPayload";
  filterId: Scalars["String"]["output"];
  type: SortAndFilterBasicFilterPayloadType;
};

export type SortAndFilterBasicFilterPayloadType = "OPTION" | "REMOVE";

export type SortAndFilterDefaults = {
  __typename?: "SortAndFilterDefaults";
  regionId?: Maybe<Scalars["String"]["output"]>;
  sort: PropertySort;
};

export type SortAndFilterSelected = {
  __typename?: "SortAndFilterSelected";
  accessibility?: Maybe<Array<Scalars["String"]["output"]>>;
  agencyBusinessModels?: Maybe<Array<Scalars["String"]["output"]>>;
  amenities?: Maybe<Array<Scalars["String"]["output"]>>;
  cleaningAndSafetyType?: Maybe<Array<Scalars["String"]["output"]>>;
  commissionTiers?: Maybe<Array<Scalars["String"]["output"]>>;
  deals?: Maybe<Array<Scalars["String"]["output"]>>;
  destination?: Maybe<Scalars["String"]["output"]>;
  guestRating?: Maybe<Scalars["String"]["output"]>;
  hotelName?: Maybe<Scalars["String"]["output"]>;
  lodging?: Maybe<Array<Scalars["String"]["output"]>>;
  paymentType?: Maybe<Array<Scalars["String"]["output"]>>;
  poi?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated More information is needed so moving list into an object , replace with priceFilterPills */
  priceFilterBuckets?: Maybe<Array<Scalars["Int"]["output"]>>;
  priceFilterPills?: Maybe<PriceFilterPills>;
  propertyStyles?: Maybe<Array<Scalars["String"]["output"]>>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  sort?: Maybe<Scalars["String"]["output"]>;
  star?: Maybe<Scalars["String"]["output"]>;
  stayTypes?: Maybe<Array<Scalars["String"]["output"]>>;
  travelerType?: Maybe<Array<TravelerType>>;
};

export type SortAndFilterSelectedFilterPillPayload = {
  __typename?: "SortAndFilterSelectedFilterPillPayload";
  deselectionSignalId?: Maybe<Scalars["String"]["output"]>;
  filter: EgdsPill;
  filterName: SortAndFilterSignalDataSource;
};

export type SortAndFilterSignalCondition =
  | "DEFAULT_TO_NON_DEFAULT"
  | "NON_DEFAULT_TO_DEFAULT"
  | "NON_DEFAULT_TO_NON_DEFAULT"
  | "SELECTED"
  | "UNSELECTED";

export type SortAndFilterSignalDataSource = EgdsBasicLocalizedText | EgdsCardinalLocalizedText | EgdsPlainText;

export type SortAndFilterSignalEmitter = {
  __typename?: "SortAndFilterSignalEmitter";
  condition: SortAndFilterSignalCondition;
  payload: SortAndFilterSignalPayload;
  signalId: Scalars["String"]["output"];
};

export type SortAndFilterSignalPayload = SortAndFilterBasicFilterPayload | SortAndFilterSelectedFilterPillPayload;

export type SortAndFilterSignalReceiver = {
  __typename?: "SortAndFilterSignalReceiver";
  signalId: Scalars["String"]["output"];
};

export type SortAndFilterViewModel = AccessibilityField & {
  __typename?: "SortAndFilterViewModel";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  appliedFilterCount: Scalars["Int"]["output"];
  label?: Maybe<Scalars["String"]["output"]>;
  sortAndFilter: Array<FilterViewModel>;
};

export type SortAndFiltersViewType =
  | "APPLIED_FILTERS"
  | "FILTERS"
  | "FILTERS_PILLS"
  | "FILTER_BAR"
  | "FILTER_DIALOG"
  | "SORT"
  | "SORT_AND_FILTERS"
  | "SORT_TABS"
  | "USE_REWARDS";

export type SourceType = "ESR" | "GDS";

export type Space = "four" | "half" | "one" | "six" | "three" | "twelve" | "two";

export type SpaceDetails = {
  __typename?: "SpaceDetails";
  floorPlan?: Maybe<FloorPlanDialog>;
  header: LodgingHeader;
  spaces?: Maybe<Array<Spaces>>;
  summary?: Maybe<Array<Scalars["String"]["output"]>>;
  virtualTourPrompt?: Maybe<VirtualTourPrompt>;
};

export type Spaces = {
  __typename?: "Spaces";
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  icons?: Maybe<Array<Icon>>;
  name: Scalars["String"]["output"];
};

export type Spacing = "four" | "half" | "one" | "six" | "sixteen" | "three" | "twelve" | "two" | "unset";

export type SpacingOptions = "FOUR" | "HALF" | "ONE" | "SIX" | "SIXTEEN" | "THREE" | "TWELVE" | "TWO" | "UNSET";

export type SpannableTextDialog = {
  __typename?: "SpannableTextDialog";
  contents: Array<EgdsSpannableText>;
  dialog: EgdsDialog;
  trigger: EgdsText;
};

export type SpeakNextAvailableAgentSheet = {
  __typename?: "SpeakNextAvailableAgentSheet";
  back: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  phoneNumberList: Array<PhoneNumberData>;
  subheading: Scalars["String"]["output"];
  toolBar: ContactUsToolBar;
};

export interface SpeakNextAvailableAgentSheetRequestInput {
  category: Scalars["String"]["input"];
  intent: Scalars["String"]["input"];
  optionType: ContactUsOptionType;
}

export type SpeakNextAvailableAgentSheetView = ContactUsErrorView | SpeakNextAvailableAgentSheet;

export type SpeakNextAvailableAgentView = ContactUsErrorView | SpeakNextAvailableAgentSheet;

export type SpecialEquipment =
  | "ADDITIONAL_DRIVER"
  | "BICYCLE_RACK"
  | "BOOSTER_SEAT"
  | "INFANT_SEAT"
  | "LEFT_HAND_CONTROL"
  | "LUGGAGE_RACK"
  | "NAV_SYSTEM"
  | "RIGHT_HAND_CONTROL"
  | "ROAD_CONGESTION_PAYMENT_SCHEME"
  | "SATELLITE_RADIO"
  | "SKI_RACK"
  | "SNOW_CHAINS"
  | "SNOW_TIRES"
  | "TODDLER_SEAT"
  | "TOLL_PAYMENT_TAG_PASS"
  | "TRAILER_HITCH"
  | "WIFI_ACCESS"
  | "WINTER_PACKAGE";

export type SpecialOfferTypeMessagingSection = {
  __typename?: "SpecialOfferTypeMessagingSection";
  specialOfferTypeMessaging: EgdsMoreInfoTrigger;
};

export type Spinner = {
  __typename?: "Spinner";
  name: Scalars["String"]["output"];
  sdk?: Maybe<Scalars["String"]["output"]>;
  sdl?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  version?: Maybe<Scalars["String"]["output"]>;
};

export type SponsoredContentBeacons = {
  __typename?: "SponsoredContentBeacons";
  /** @deprecated This field is deprecated, please use 'renderBeacons' */
  renderBeacon: Scalars["URL"]["output"];
  renderBeacons: Array<Scalars["URL"]["output"]>;
  /** @deprecated This field is deprecated, please use 'renderBeacons' */
  thirdPartyImpressions: Array<Scalars["URL"]["output"]>;
  viewabilityBeacon?: Maybe<Scalars["URL"]["output"]>;
};

export type SponsoredContentBrandResultListing = {
  __typename?: "SponsoredContentBrandResultListing";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  callToAction?: Maybe<EgdsOverlayButton>;
  feature: Image;
  heading: Scalars["String"]["output"];
  /** @deprecated PayloadAnalytics is the replacement for ClientSideAnalytics */
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"]["output"];
  video?: Maybe<SponsoredContentVideo>;
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]["output"]>;
};

export type SponsoredContentBrandResultListingCars = {
  __typename?: "SponsoredContentBrandResultListingCars";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge: EgdsStandardBadge;
  beacons?: Maybe<SponsoredContentBeacons>;
  callToAction?: Maybe<EgdsInlineLink>;
  heading: Scalars["String"]["output"];
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]["output"]>;
};

export type SponsoredContentBrandResultListingFlights = {
  __typename?: "SponsoredContentBrandResultListingFlights";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  advertiserName: Scalars["String"]["output"];
  badge: EgdsStandardBadge;
  beacons?: Maybe<SponsoredContentBeacons>;
  callToAction?: Maybe<EgdsInlineLink>;
  heading: Scalars["String"]["output"];
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]["output"]>;
};

export type SponsoredContentBrandResultListingLodging = {
  __typename?: "SponsoredContentBrandResultListingLodging";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  /** @deprecated No need for CTA on BRL anymore */
  callToAction?: Maybe<EgdsInlineLink>;
  feature: Image;
  heading: Scalars["String"]["output"];
  /** @deprecated PayloadAnalytics is the replacement for ClientSideAnalytics */
  impressionAnalytics: ClientSideAnalytics;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]["output"]>;
};

export type SponsoredContentContext = {
  __typename?: "SponsoredContentContext";
  pageName: Scalars["String"]["output"];
  sponsoredContentId: Scalars["String"]["output"];
  targeting?: Maybe<SponsoredContentTargeting>;
  variant?: Maybe<Scalars["String"]["output"]>;
};

export interface SponsoredContentContextInput {
  pageName: Scalars["String"]["input"];
  sponsoredContentId: Scalars["String"]["input"];
  targeting?: InputMaybe<SponsoredContentTargetingInput>;
  variant?: InputMaybe<Scalars["String"]["input"]>;
}

export type SponsoredContentFormSection = {
  __typename?: "SponsoredContentFormSection";
  clickThroughURLInputField?: Maybe<EgdsInputField>;
  sponsoredContentSectionHeader?: Maybe<Scalars["String"]["output"]>;
  sponsoredLabelInputField?: Maybe<EgdsInputField>;
};

export type SponsoredContentImageGallery = {
  __typename?: "SponsoredContentImageGallery";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  heading: Scalars["String"]["output"];
  /** @deprecated PayloadAnalytics is the replacement for ClientSideAnalytics */
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  link?: Maybe<UiLinkAction>;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"]["output"];
  tiles: Array<Maybe<EgdsImageLinkCard>>;
};

export type SponsoredContentInterstitialAd = {
  __typename?: "SponsoredContentInterstitialAd";
  beacons?: Maybe<SponsoredContentBeacons>;
  description?: Maybe<Scalars["String"]["output"]>;
  descriptionContinued: Array<Scalars["String"]["output"]>;
  /** @deprecated Use heroImage instead for top level image */
  feature: Image;
  heading: Scalars["String"]["output"];
  headingContinued: Array<Scalars["String"]["output"]>;
  heroImage?: Maybe<Image>;
  impressionAnalytics: ClientSideAnalytics;
  logo?: Maybe<SponsoredContentLogo>;
  /** @deprecated Use optional description instead */
  paragraph: Scalars["String"]["output"];
  pendingSearchText?: Maybe<Scalars["String"]["output"]>;
  sponsoredLabel?: Maybe<Scalars["String"]["output"]>;
};

export type SponsoredContentLocationResolver = "GAIA" | "TLA";

export type SponsoredContentLocationResolverInput = "GAIA" | "TLA";

export type SponsoredContentLogo = {
  __typename?: "SponsoredContentLogo";
  darkMode?: Maybe<Mark>;
  standard?: Maybe<Mark>;
};

export type SponsoredContentMarquee = {
  __typename?: "SponsoredContentMarquee";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  cardType?: Maybe<MarqueeCardType>;
  heading: Scalars["String"]["output"];
  image: Image;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph: Scalars["String"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  renderBeacon: Scalars["URL"]["output"];
  /** @deprecated Please retrieve this from the beacons field */
  viewabilityBeacon?: Maybe<Scalars["URL"]["output"]>;
  viewedImpressionAnalytics: ClientSideAnalytics;
};

export type SponsoredContentMarquees = {
  __typename?: "SponsoredContentMarquees";
  primary: Array<SponsoredContentMarquee>;
  secondary: Array<SponsoredContentMarquee>;
};

export type SponsoredContentMultiListingAd = {
  __typename?: "SponsoredContentMultiListingAd";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  advertisedIds: Array<Scalars["Int"]["output"]>;
  advertiserName: Scalars["String"]["output"];
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  brandDiscoveryProperties?: Maybe<DiscoveryItemsGroup>;
  brandProperties: Array<LodgingCard>;
  callToAction: EgdsInlineLink;
  carouselAnalytics?: Maybe<ClientSideAnalytics>;
  heading?: Maybe<Scalars["String"]["output"]>;
  link: UiLinkAction;
  logo?: Maybe<SponsoredContentLogo>;
  paragraph?: Maybe<Scalars["String"]["output"]>;
  sponsoredContentSearchCriteria?: Maybe<SponsoredContentSearchCriteria>;
};

export type SponsoredContentMultiListingAdBrandDiscoveryPropertiesArgs = {
  configurationIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<ContextInput>;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteriaInput: MultiItemSearchContextInput;
};

export type SponsoredContentMultiListingAdBrandPropertiesArgs = {
  context: ContextInput;
  searchCriteriaInput: MultiItemSearchContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type SponsoredContentMultiListingAdCallToActionArgs = {
  context: ContextInput;
  searchCriteriaInput: MultiItemSearchContextInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
};

export type SponsoredContentPlacement = {
  __typename?: "SponsoredContentPlacement";
  productType: SponsoredContentProductType;
  sponsoredContentContext: SponsoredContentContext;
};

export type SponsoredContentProductType =
  | "ALTERNATE_DESTINATIONS_CAROUSEL"
  | "BRAND_RESULT_LISTING"
  | "BRAND_RESULT_LISTING_CARS"
  | "BRAND_RESULT_LISTING_CRUISES"
  | "BRAND_RESULT_LISTING_FLIGHTS"
  | "BRAND_RESULT_LISTING_LODGING"
  | "BRAND_RESULT_LISTING_LX"
  | "DESTINATION_EXPERIENCE"
  | "MULTI_LISTING_AD"
  | "SPONSORED_PROPERTIES_CAROUSEL";

export type SponsoredContentQuery = {
  __typename?: "SponsoredContentQuery";
  brandResultListing?: Maybe<SponsoredContentBrandResultListing>;
  brandResultListingCars?: Maybe<SponsoredContentBrandResultListingCars>;
  /** @deprecated Schema evolving. Use at your own risk */
  brandResultListingFlights?: Maybe<SponsoredContentBrandResultListingFlights>;
  /** @deprecated Schema evolving. Use at your own risk */
  brandResultListingLodging?: Maybe<SponsoredContentBrandResultListingLodging>;
  imageGallery?: Maybe<SponsoredContentImageGallery>;
  interstitialAd?: Maybe<SponsoredContentInterstitialAd>;
  marquee?: Maybe<SponsoredContentMarquee>;
  marquees?: Maybe<SponsoredContentMarquees>;
  multiListingAd?: Maybe<SponsoredContentMultiListingAd>;
  transparencyOverlay?: Maybe<TransparencyOverlay>;
  videoCarousel?: Maybe<SponsoredContentVideoCarousel>;
};

export type SponsoredContentQueryBrandResultListingArgs = {
  context: ContextInput;
  shoppingCriteriaInput?: InputMaybe<ProductShoppingCriteriaInput>;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryBrandResultListingCarsArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryBrandResultListingFlightsArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryBrandResultListingLodgingArgs = {
  context: ContextInput;
  shoppingCriteriaInput?: InputMaybe<ProductShoppingCriteriaInput>;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryImageGalleryArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryInterstitialAdArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryMarqueeArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryMarqueesArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentQueryMultiListingAdArgs = {
  configurationIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  context: ContextInput;
  pageId?: InputMaybe<Scalars["String"]["input"]>;
  searchCriteriaInput: MultiItemSearchContextInput;
  sponsoredContentContextInput: SponsoredContentContextInput;
};

export type SponsoredContentQueryTransparencyOverlayArgs = {
  adTransparencyId: Scalars["String"]["input"];
  context: ContextInput;
};

export type SponsoredContentQueryVideoCarouselArgs = {
  context: ContextInput;
  sponsoredContentContext: SponsoredContentContextInput;
};

export type SponsoredContentSearchCriteria = {
  __typename?: "SponsoredContentSearchCriteria";
  destinationId: Scalars["String"]["output"];
  endDate: DateInfo;
  rooms: Array<RoomInfo>;
  startDate: DateInfo;
};

export type SponsoredContentTargeting = {
  __typename?: "SponsoredContentTargeting";
  adults?: Maybe<Scalars["Int"]["output"]>;
  cruiseDest?: Maybe<Array<Scalars["String"]["output"]>>;
  dateEnd?: Maybe<Date>;
  dateStart?: Maybe<Date>;
  destination?: Maybe<Scalars["String"]["output"]>;
  kids?: Maybe<Scalars["Int"]["output"]>;
  locationResolver?: Maybe<SponsoredContentLocationResolver>;
  origin?: Maybe<Scalars["String"]["output"]>;
};

export interface SponsoredContentTargetingInput {
  adults?: InputMaybe<Scalars["Int"]["input"]>;
  cruiseDest?: InputMaybe<Array<Scalars["String"]["input"]>>;
  dateEnd?: InputMaybe<DateInput>;
  dateStart?: InputMaybe<DateInput>;
  destination?: InputMaybe<Scalars["String"]["input"]>;
  kids?: InputMaybe<Scalars["Int"]["input"]>;
  locationResolver?: InputMaybe<SponsoredContentLocationResolverInput>;
  origin?: InputMaybe<Scalars["String"]["input"]>;
}

export type SponsoredContentVideo = {
  __typename?: "SponsoredContentVideo";
  analytics?: Maybe<SponsoredContentVideoAnalytics>;
  description: Scalars["String"]["output"];
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  firstQuartileAnalytics?: Maybe<ClientSideAnalytics>;
  horizontalVideo?: Maybe<VideoResource>;
  link?: Maybe<UiLinkAction>;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  midpointAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  muteAnalytics?: Maybe<ClientSideAnalytics>;
  previewDuration?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  thirdQuartileAnalytics?: Maybe<ClientSideAnalytics>;
  thumbnail: Uri;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  unmuteAnalytics?: Maybe<ClientSideAnalytics>;
  url: Uri;
  verticalVideo?: Maybe<VideoResource>;
  videoAccessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  videoBufferingAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  videoCompleteAnalytics?: Maybe<ClientSideAnalytics>;
  /** @deprecated This field is deprecated, please use the 'analytics' field instead */
  viewAnalytics?: Maybe<ClientSideAnalytics>;
  volumeButtonAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type SponsoredContentVideoAnalytics = {
  __typename?: "SponsoredContentVideoAnalytics";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  firstQuartileAnalytics?: Maybe<ClientSideAnalytics>;
  midpointAnalytics?: Maybe<ClientSideAnalytics>;
  muteAnalytics?: Maybe<ClientSideAnalytics>;
  orientationChangeAnalytics?: Maybe<ClientSideAnalytics>;
  pauseAnalytics?: Maybe<ClientSideAnalytics>;
  previewClickAnalytics?: Maybe<ClientSideAnalytics>;
  resumeAnalytics?: Maybe<ClientSideAnalytics>;
  thirdQuartileAnalytics?: Maybe<ClientSideAnalytics>;
  unmuteAnalytics?: Maybe<ClientSideAnalytics>;
  videoBufferingAnalytics?: Maybe<ClientSideAnalytics>;
  videoCompleteAnalytics?: Maybe<ClientSideAnalytics>;
  viewAnalytics?: Maybe<ClientSideAnalytics>;
};

export type SponsoredContentVideoCard = {
  __typename?: "SponsoredContentVideoCard";
  adTransparencyTrigger?: Maybe<AdTransparencyTrigger>;
  analytics?: Maybe<SponsoredContentVideoCardAnalytics>;
  badge?: Maybe<EgdsStandardBadge>;
  beacons?: Maybe<SponsoredContentBeacons>;
  callToAction?: Maybe<VideoCallToActionButton>;
  id?: Maybe<Scalars["String"]["output"]>;
  logo?: Maybe<SponsoredContentLogo>;
  text?: Maybe<EgdsHeading>;
  video: SponsoredContentVideo;
};

export type SponsoredContentVideoCardAnalytics = {
  __typename?: "SponsoredContentVideoCardAnalytics";
  controlsHideAnalytics?: Maybe<ClientSideAnalytics>;
  controlsShowAnalytics?: Maybe<ClientSideAnalytics>;
  overlayElementsHideAnalytics?: Maybe<ClientSideAnalytics>;
  overlayElementsShowAnalytics?: Maybe<ClientSideAnalytics>;
  textClickAnalytics?: Maybe<ClientSideAnalytics>;
};

export type SponsoredContentVideoCarousel = {
  __typename?: "SponsoredContentVideoCarousel";
  carousel: VideoCarousel;
  carouselRenderedAnalytics?: Maybe<ClientSideAnalytics>;
  carouselViewedAnalytics?: Maybe<ClientSideAnalytics>;
  headerClickAnalytics?: Maybe<ClientSideAnalytics>;
  heading: Scalars["String"]["output"];
  paragraph: Scalars["String"]["output"];
  previewOrientationChangeAnalytics?: Maybe<ClientSideAnalytics>;
};

export type SponsoredListing = {
  __typename?: "SponsoredListing";
  brand?: Maybe<Scalars["String"]["output"]>;
  clickRedirectLinkoffTrackingUrl?: Maybe<Scalars["URL"]["output"]>;
  clickRedirectTrackingUrl?: Maybe<Scalars["URL"]["output"]>;
  /** @deprecated click tracking should no longer be done by the client. Please pass PropertyTravelAdTrackingInfo in propertyInfo Offers subquery for click tracking */
  clickTrackingUrl?: Maybe<Scalars["URL"]["output"]>;
  details?: Maybe<Scalars["String"]["output"]>;
  detailsHeadline?: Maybe<Scalars["String"]["output"]>;
  hotelImage?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  impressionTrackingUrl?: Maybe<Scalars["URL"]["output"]>;
  logo?: Maybe<Scalars["String"]["output"]>;
  lotagline?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use position in PropertyTravelAdTrackingInfo */
  position?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use rank in PropertyTravelAdTrackingInfo */
  rank?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use slots in PropertyTravelAdTrackingInfo */
  slots?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use trackingData in PropertyTravelAdTrackingInfo */
  trackingData?: Maybe<Scalars["String"]["output"]>;
  trackingInfo: PropertyTravelAdTrackingInfo;
  vanityUrl?: Maybe<Scalars["URL"]["output"]>;
};

export type SponsoredTrackingBeacons = {
  __typename?: "SponsoredTrackingBeacons";
  clickBeacons?: Maybe<Array<Scalars["URL"]["output"]>>;
  renderBeacons?: Maybe<Array<Scalars["URL"]["output"]>>;
  viewBeacons?: Maybe<Array<Scalars["URL"]["output"]>>;
};

export interface StackingOptionInput {
  allowStacking?: InputMaybe<Scalars["Boolean"]["input"]>;
  promotionId: Scalars["String"]["input"];
}

export type StandardLink = {
  __typename?: "StandardLink";
  analytics?: Maybe<ClickstreamAnalyticsData>;
  link: EgdsStandardLink;
};

export type StarRange = {
  __typename?: "StarRange";
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
};

export interface StarRangeInput {
  max?: InputMaybe<Scalars["Float"]["input"]>;
  min?: InputMaybe<Scalars["Float"]["input"]>;
}

export type StaticCrossSellQueries = {
  __typename?: "StaticCrossSellQueries";
  brandedDealListing: BrandedDealCrossSellUiResponse;
  searchResultListing: StaticSearchResultListingCrossSellResponse;
};

export type StaticCrossSellQueriesBrandedDealListingArgs = {
  price: BrandedDealProductPriceInput;
  subjectToGovernmentApproval?: InputMaybe<Scalars["Boolean"]["input"]>;
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type StaticCrossSellQueriesSearchResultListingArgs = {
  crossSellProductType?: InputMaybe<CrossSellProductType>;
  userLocation?: InputMaybe<CrossSellUserLocationInput>;
};

export type StaticLicensePlate = {
  __typename?: "StaticLicensePlate";
  description: Scalars["String"]["output"];
  feedbackLink: EgdsStandardLink;
  links: Array<EgdsStandardLink>;
  title: Scalars["String"]["output"];
};

export type StaticLodgingMultiItemCrossSell = {
  __typename?: "StaticLodgingMultiItemCrossSell";
  crossSellProductType: LodgingCrossSellProductType;
  fallbackListing?: Maybe<StaticLodgingMultiItemCrossSellFallbackListing>;
  multiItemSearchContext: MultiItemSearchContext;
};

export type StaticLodgingMultiItemCrossSellFallbackListing = MessageResult;

export type StaticMapImage = {
  __typename?: "StaticMapImage";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type StaticMultiItemCrossSellResponse = {
  __typename?: "StaticMultiItemCrossSellResponse";
  crossSellOptions: Array<CrossSellOption>;
  impressionAnalytics: ClientSideAnalytics;
};

export type StaticSearchResultListingCrossSellResponse = {
  __typename?: "StaticSearchResultListingCrossSellResponse";
  crossSell: CrossSellOption;
  impressionAnalytics: ClientSideAnalytics;
};

export type StatusIcon = {
  __typename?: "StatusIcon";
  icon: Icon;
  type: IconType;
};

export type StayConstraints = {
  __typename?: "StayConstraints";
  maximumStayInDays: Scalars["Int"]["output"];
  minimumStayInDays: Scalars["Int"]["output"];
  stayIncrementInDays: Scalars["Int"]["output"];
};

export type StayType = "CONVENTIONAL" | "VACATION_RENTALS";

export type StepIndicator = {
  __typename?: "StepIndicator";
  priceLockup?: Maybe<StepIndicatorPrice>;
  steps: Array<IndicatorStep>;
};

export interface StepIndicatorChangeSelection {
  a11yLabel: Scalars["String"]["output"];
  action: UiLinkAction;
  label: Scalars["String"]["output"];
  multiItemShoppingAction?: Maybe<MultiItemShoppingAction>;
}

export type StepIndicatorItem = {
  __typename?: "StepIndicatorItem";
  disclaimer?: Maybe<PackageUiDisclaimerDialog>;
  message: PackageUiMessageItem;
};

export interface StepIndicatorLabel {
  text: Scalars["String"]["output"];
}

export type StepIndicatorPrice = {
  __typename?: "StepIndicatorPrice";
  /** @deprecated Use packageUIPrice instead */
  a11yPrice: Scalars["String"]["output"];
  badge?: Maybe<EgdsProgramBadge>;
  /** @deprecated Use installmentPlanPlacement instead */
  installmentPlan?: Maybe<InstallmentPlan>;
  installmentPlanPlacement?: Maybe<InstallmentPlanPlacement>;
  /** @deprecated Use packageUIPrice instead */
  lockupPrice: Scalars["String"]["output"];
  /** @deprecated Use packageUIPrice instead */
  priceDisclaimer?: Maybe<PriceDisclaimer>;
  /** @deprecated Use packageUIPrice instead */
  priceSubtextStandard?: Maybe<Array<Scalars["String"]["output"]>>;
  priceSummary: PackageUiPrice;
  /** @deprecated Use packageUIPrice instead */
  strikeThroughDialogText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use packageUIPrice instead */
  strikeThroughPrice?: Maybe<Scalars["String"]["output"]>;
  topItems: Array<StepIndicatorItem>;
};

export type StepReviewCard = {
  __typename?: "StepReviewCard";
  analytics: StepReviewClickstreamData;
  cardLink: StepReviewCardAction;
  description?: Maybe<Scalars["String"]["output"]>;
  editIcon?: Maybe<Icon>;
  editLink?: Maybe<StepReviewEditLink>;
  heading: Scalars["String"]["output"];
  statusIcon: Icon;
};

export type StepReviewCardAction = StepReviewLinkAction | StepReviewReturnAction;

export type StepReviewClickstreamData = IEgClickstreamData & {
  __typename?: "StepReviewClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
  product_list?: Maybe<Array<EgClickstreamProductListData>>;
  virtual_market_coach?: Maybe<EgClickstreamVirtualMarketCoachData>;
};

export type StepReviewEditLink = {
  __typename?: "StepReviewEditLink";
  action: StepReviewCardAction;
  icon?: Maybe<Icon>;
  iconPosition?: Maybe<EgdsIconPosition>;
  size: EgdsLinkSize;
  text: Scalars["String"]["output"];
};

export type StepReviewHeader = {
  __typename?: "StepReviewHeader";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
};

export type StepReviewInlineEditingSheet = {
  __typename?: "StepReviewInlineEditingSheet";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  closeText?: Maybe<Scalars["String"]["output"]>;
  content: StepReviewInlineEditingSheetContent;
  footer: StepReviewInlineEditingSheetFooter;
  heading: Scalars["String"]["output"];
  showAction: SupplySubscribeSignalAction;
};

export type StepReviewInlineEditingSheetButtonAction =
  | StepReviewInlineEditingSheetButtonActionCancel
  | StepReviewInlineEditingSheetButtonActionSave;

export type StepReviewInlineEditingSheetButtonActionCancel = {
  __typename?: "StepReviewInlineEditingSheetButtonActionCancel";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type StepReviewInlineEditingSheetButtonActionSave = {
  __typename?: "StepReviewInlineEditingSheetButtonActionSave";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
};

export type StepReviewInlineEditingSheetButtonType = "PRIMARY" | "SECONDARY";

export type StepReviewInlineEditingSheetContent = {
  __typename?: "StepReviewInlineEditingSheetContent";
  description: Scalars["String"]["output"];
  sections: Array<StepReviewInlineEditingSheetSection>;
};

export type StepReviewInlineEditingSheetFooter = {
  __typename?: "StepReviewInlineEditingSheetFooter";
  buttons: Array<StepReviewInlineEditingSheetFooterButton>;
};

export type StepReviewInlineEditingSheetFooterButton = {
  __typename?: "StepReviewInlineEditingSheetFooterButton";
  action: StepReviewInlineEditingSheetButtonAction;
  text: Scalars["String"]["output"];
  type: StepReviewInlineEditingSheetButtonType;
};

export type StepReviewInlineEditingSheetSection = MinNightStayInputField;

export type StepReviewLinkAction = {
  __typename?: "StepReviewLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: StepReviewClickstreamData;
  resource: Uri;
  target: UiLinkTarget;
  viewed?: Maybe<Scalars["String"]["output"]>;
};

export type StepReviewList = {
  __typename?: "StepReviewList";
  accessibilityLabel: Scalars["String"]["output"];
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  announcement: Scalars["String"]["output"];
  cards: Array<StepReviewCard>;
  header?: Maybe<StepReviewHeader>;
  toast?: Maybe<EgdsToast>;
};

export type StepReviewReturnAction = {
  __typename?: "StepReviewReturnAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: StepReviewClickstreamData;
  icon?: Maybe<Icon>;
  stepName?: Maybe<Scalars["String"]["output"]>;
  viewed?: Maybe<Scalars["String"]["output"]>;
};

export type StickyBottom = {
  __typename?: "StickyBottom";
  reserveButton?: Maybe<UiPrimaryButton>;
  secondaryText: Scalars["String"]["output"];
  tertiaryText?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  viewPriceSummaryA11yText: Scalars["String"]["output"];
  viewPriceSummaryIcon: Icon;
  viewPriceSummaryLink: Scalars["String"]["output"];
};

export type StickyMenuItems = {
  __typename?: "StickyMenuItems";
  accessibility: TripsAccessibilityData;
  action: TripsAction;
  icon?: Maybe<Icon>;
};

export type StoredBankCardPaymentInstrumentInfo = StoredPaymentInstrumentInfo & {
  __typename?: "StoredBankCardPaymentInstrumentInfo";
  brand: Scalars["String"]["output"];
  cvvNotRequired: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  instrumentState: PaymentState;
  lastFour: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  subMethod: Scalars["String"]["output"];
};

export type StoredCardConfig = {
  __typename?: "StoredCardConfig";
  paymentType?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  storedPaymentInstrumentId: Scalars["String"]["output"];
};

export type StoredCardInstrument = StoredPaymentInstrumentInfo & {
  __typename?: "StoredCardInstrument";
  authorized?: Maybe<Scalars["Boolean"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export interface StoredCardInstrumentInput {
  authorized?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
}

export type StoredInstrumentBillingZipCodeField = {
  __typename?: "StoredInstrumentBillingZipCodeField";
  configurationInfo?: Maybe<PaymentConfigurationInfo>;
  content: EgdsTextInputField;
};

export interface StoredPaymentInstrumentInfo {
  id?: Maybe<Scalars["String"]["output"]>;
}

export type StrikeOutType = "ATTACH" | "INVALID" | "LOYALTY" | "PACKAGE" | "PRICE_SURVEY" | "STANDALONE";

export type StringElement = {
  __typename?: "StringElement";
  value: Scalars["String"]["output"];
};

export type StructureType =
  | "AGRITOURISM"
  | "ALL_INCLUSIVE"
  | "APARTMENT"
  | "APART_HOTEL"
  | "BED_AND_BREAKFAST"
  | "CABIN"
  | "CAPSULE_HOTEL"
  | "CARAVAN_PARK"
  | "CASTLE"
  | "CHALET"
  | "CONDO"
  | "CONDO_RESORT"
  | "COTTAGE"
  | "COUNTRY_HOUSE"
  | "CRUISE"
  | "ECO_HOTEL"
  | "GUEST_HOUSE"
  | "HOLIDAY_PARK"
  | "HOSTAL"
  | "HOSTEL"
  | "HOTEL"
  | "HOTEL_RESORT"
  | "HOUSE_BOAT"
  | "INN"
  | "LODGE"
  | "LONGHOUSE"
  | "MOBILE_HOME"
  | "MOTEL"
  | "OVERWATER"
  | "PALACE"
  | "PENSION"
  | "POUSADA_BRAZIL"
  | "POUSADA_PORTUGAL"
  | "RANCH"
  | "RESIDENCE"
  | "RIAD"
  | "RYOKAN"
  | "SAFARI"
  | "SERVICED_APARTMENT"
  | "TOWNHOUSE"
  | "TRADITIONAL_BUILDING"
  | "TREE_HOUSE"
  | "UNKNOWN"
  | "VACATION_HOME"
  | "VACATION_RENTAL"
  | "VACATION_RENTALS"
  | "VILLA"
  | "VIP";

export type StructureTypeValue = {
  __typename?: "StructureTypeValue";
  id: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
};

export type StructuredContentMediaGalleryCarousel = EgdsGalleryCarousel & {
  __typename?: "StructuredContentMediaGalleryCarousel";
  accessibilityHeadingText: Scalars["String"]["output"];
  media: Array<MediaItem>;
  nextButtonText: Scalars["String"]["output"];
  previousButtonText: Scalars["String"]["output"];
};

export type SubHeader = {
  __typename?: "SubHeader";
  button?: Maybe<HeaderButton>;
  logo?: Maybe<SubHeaderLogo>;
  partnerLoyaltyPoints?: Maybe<PartnerLoyaltyPointsComponent>;
  text: Scalars["String"]["output"];
};

export type SubHeaderLogo = {
  __typename?: "SubHeaderLogo";
  action: UiLinkAction;
  imgSrc: Image;
  text: Scalars["String"]["output"];
};

export type SuccessNotification = {
  __typename?: "SuccessNotification";
  closeButton: UiPrimaryButton;
  message: EgdsGraphicText;
};

export type SummarizationFeedbackErrorView = {
  __typename?: "SummarizationFeedbackErrorView";
  errorMessage: UiBanner;
  heading: Scalars["String"]["output"];
  okButton: UiSecondaryButton;
};

export type SummarizationFeedbackOption = {
  __typename?: "SummarizationFeedbackOption";
  description: Scalars["String"]["output"];
  isInputMandatory: Scalars["Boolean"]["output"];
  text: Scalars["String"]["output"];
};

export type SummarizationFeedbackView = SummarizationFeedbackErrorView | SummarizationNegativeFeedbackView;

export type SummarizationNegativeFeedbackView = {
  __typename?: "SummarizationNegativeFeedbackView";
  cancelButton: UiSecondaryButton;
  feedbackComment: EgdsTextInputField;
  heading: Scalars["String"]["output"];
  legalDisclaimer?: Maybe<Scalars["String"]["output"]>;
  subHeading: Scalars["String"]["output"];
  submitButton: UiPrimaryButton;
  summarizationFeedbackOptions: Array<SummarizationFeedbackOption>;
};

export type SummarizedResponseEgds = EgdsUnorderedList;

export type SummarizedReviews = {
  __typename?: "SummarizedReviews";
  disclaimer?: Maybe<EgdsGraphicText>;
  summary?: Maybe<Array<ReviewSummaryInfo>>;
};

export type SummaryUnit = {
  __typename?: "SummaryUnit";
  score?: Maybe<Scalars["String"]["output"]>;
  tags: Array<Tag>;
};

export type SuperlativeText = {
  __typename?: "SuperlativeText";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  text: EgdsStylizedText;
};

export type SupplyActionPanelActions = {
  __typename?: "SupplyActionPanelActions";
  sheetCloseSignal?: Maybe<SupplyActionPanelSignal>;
  sheetOpenSignal?: Maybe<SupplyActionPanelSignal>;
};

export type SupplyActionPanelConfig = SupplyContentAmenitiesGroupConfig;

export type SupplyActionPanelLeaveSheet = {
  __typename?: "SupplyActionPanelLeaveSheet";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  leaveButton: UiPrimaryButton;
  stayButton: UiSecondaryButton;
};

export type SupplyActionPanelMainSheet = {
  __typename?: "SupplyActionPanelMainSheet";
  contentBlockSignal?: Maybe<SupplyActionPanelSignal>;
  heading: Scalars["String"]["output"];
  primaryButton: ActionPanelPrimaryButton;
  secondaryButton?: Maybe<ActionPanelSecondaryButton>;
  subHeading: EgdsLocalizedText;
};

export type SupplyActionPanelSideSheetLayout = {
  __typename?: "SupplyActionPanelSideSheetLayout";
  columns?: Maybe<Scalars["Int"]["output"]>;
  position?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyActionPanelSignal = {
  __typename?: "SupplyActionPanelSignal";
  payload?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type SupplyAmenitiesCoachingActionPanel = SupplyCoachingActionPanel & {
  __typename?: "SupplyAmenitiesCoachingActionPanel";
  confirmedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  dialog: SupplyContentLeaveWithoutSavingDialog;
  groupConfigs: Array<SupplyAmenitiesGroupConfig>;
  saveButton: SupplyContentButton;
  singleStepText?: Maybe<Scalars["String"]["output"]>;
  stepText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyAmenitiesGroup = {
  __typename?: "SupplyAmenitiesGroup";
  analytics?: Maybe<Array<SupplyContentEgClickstreamData>>;
  contents: Array<SupplyAmenitiesGroupContent>;
  description?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyAmenitiesGroupConfig = {
  __typename?: "SupplyAmenitiesGroupConfig";
  description: Scalars["String"]["output"];
  groupId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyAmenitiesGroupContent = SupplyAmenitiesList | SupplyAmenitiesSection | SupplyAmenitiesSubSection;

export interface SupplyAmenitiesGroupInput {
  groupId: Scalars["String"]["input"];
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
  spaceId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyAmenitiesList = {
  __typename?: "SupplyAmenitiesList";
  amenities: Array<SupplyAmenityElement>;
  column: Scalars["Int"]["output"];
};

export type SupplyAmenitiesSection = {
  __typename?: "SupplyAmenitiesSection";
  subSections: Array<SupplyAmenitiesSubSection>;
  title: Scalars["String"]["output"];
};

export type SupplyAmenitiesSubSection = {
  __typename?: "SupplyAmenitiesSubSection";
  amenitiesLists: Array<SupplyAmenitiesList>;
  divider: Scalars["Boolean"]["output"];
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export interface SupplyAmenitiesUpdateInput {
  amenities: Array<SupplyAmenityInput>;
  groupId: Scalars["String"]["input"];
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyAmenityElement = {
  __typename?: "SupplyAmenityElement";
  analytics?: Maybe<Array<SupplyContentEgClickstreamData>>;
  boldTitle?: Maybe<Scalars["Boolean"]["output"]>;
  defaultValue?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  element: SupplyAmenityUiElement;
  elementId: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyAmenityInformativeInput = {
  __typename?: "SupplyAmenityInformativeInput";
  field: EgdsNumberInputField;
  popoverText?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyAmenityInput {
  elementId: Scalars["String"]["input"];
  supplierDescription?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyAmenityToggle = {
  __typename?: "SupplyAmenityToggle";
  checked?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyAmenityUiElement =
  | EgdsBasicCheckBox
  | EgdsBasicLocalizedText
  | EgdsBasicRadioGroup
  | EgdsBasicSelect
  | EgdsBasicStepInput
  | EgdsNumberInputField
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStandardSwitch
  | EgdsTextInputField
  | SupplyAmenityToggle
  | SupplyAmenityValueAndUnit;

export type SupplyAmenityValueAndUnit = {
  __typename?: "SupplyAmenityValueAndUnit";
  mappingValidations: Array<SupplyAmenityValueOrUnitValidations>;
  unit: SupplyAmenityValueOrUnit;
  value: SupplyAmenityValueOrUnit;
};

export type SupplyAmenityValueAndUnitElement = EgdsBasicSelect | EgdsNumberInputField | SupplyAmenityInformativeInput;

export type SupplyAmenityValueOrUnit = {
  __typename?: "SupplyAmenityValueOrUnit";
  defaultValue?: Maybe<Scalars["String"]["output"]>;
  element: SupplyAmenityValueAndUnitElement;
  elementId: Scalars["String"]["output"];
};

export type SupplyAmenityValueOrUnitValidations = {
  __typename?: "SupplyAmenityValueOrUnitValidations";
  unit: Scalars["String"]["output"];
  validations: Array<EgdsInputValidation>;
};

export type SupplyAssignPhotosHeader = {
  __typename?: "SupplyAssignPhotosHeader";
  descriptions?: Maybe<Array<Scalars["String"]["output"]>>;
  heading: EgdsIconText;
};

export type SupplyAssociatedPropertiesSummary = {
  __typename?: "SupplyAssociatedPropertiesSummary";
  associatedPropertiesSummary: Array<SupplyPropertyListItemActionContent>;
};

export type SupplyAttributeDescription = {
  __typename?: "SupplyAttributeDescription";
  addLinkBtn: EgdsStandardLink;
  input: EgdsTextAreaInputField;
};

export type SupplyAttributeDetail = {
  __typename?: "SupplyAttributeDetail";
  attribute: SupplyAttributeInputField;
  locale: EgdsBasicSelect;
};

export interface SupplyAttributeInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  value: Scalars["String"]["input"];
}

export type SupplyAttributeInputField = {
  __typename?: "SupplyAttributeInputField";
  fieldSet: EgdsTextAreaInputField;
  title: Scalars["String"]["output"];
};

export type SupplyAttributeItemCell = {
  __typename?: "SupplyAttributeItemCell";
  content?: Maybe<SupplyAttributeItemContent>;
};

export type SupplyAttributeItemContent =
  | EgdsBasicCheckBox
  | EgdsPlainText
  | SupplyAttributeDescription
  | SupplyAttributeDetail
  | SupplyAttributeInputField
  | SupplyAttributeLastUpdateByInfo
  | SupplyAttributeSelect;

export type SupplyAttributeItemRow = {
  __typename?: "SupplyAttributeItemRow";
  attributeId: Scalars["String"]["output"];
  cells: Array<SupplyAttributeItemCell>;
  id: Scalars["String"]["output"];
};

export type SupplyAttributeLastUpdateByInfo = {
  __typename?: "SupplyAttributeLastUpdateByInfo";
  lastUpdateBy: Scalars["String"]["output"];
  lastUpdateByDate: Scalars["String"]["output"];
};

export type SupplyAttributeSelect = {
  __typename?: "SupplyAttributeSelect";
  fieldSet: EgdsBasicSelect;
  title: Scalars["String"]["output"];
};

export type SupplyAttributesAdminExperience = {
  __typename?: "SupplyAttributesAdminExperience";
  attributeSections: Array<SupplyAttributesSection>;
  ownerSelects: SupplyAttributesOwnerSelects;
  searchInput: EgdsTextInputField;
};

export interface SupplyAttributesAdminExperienceInput {
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
  spaceId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyAttributesOwnerSelects = {
  __typename?: "SupplyAttributesOwnerSelects";
  ratePlanSelect: EgdsBasicSelect;
  roomSelect: EgdsBasicSelect;
  spaceSelect: EgdsBasicSelect;
};

export type SupplyAttributesSection = {
  __typename?: "SupplyAttributesSection";
  attributesTable: SupplyAttributesTable;
  expandoTitle: Scalars["String"]["output"];
};

export type SupplyAttributesTable = {
  __typename?: "SupplyAttributesTable";
  headers: Array<Scalars["String"]["output"]>;
  rows: Array<SupplyAttributeItemRow>;
};

export interface SupplyAttributesUpdateInput {
  attributes: Array<SupplyAttributeInput>;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
  spaceId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyAutoCancelWaiver = {
  __typename?: "SupplyAutoCancelWaiver";
  banner: SupplyAutoCancelWaiverBanner;
  exception: SupplyAutoCancelWaiverException;
  footer: SupplyAutoCancelWaiverFooter;
  header: SupplyAutoCancelWaiverHeader;
  waivers: SupplyAutoCancelWaiverSection;
};

export type SupplyAutoCancelWaiverBanner = {
  __typename?: "SupplyAutoCancelWaiverBanner";
  error: Scalars["String"]["output"];
  loading: Scalars["String"]["output"];
  success: Scalars["String"]["output"];
};

export type SupplyAutoCancelWaiverException = {
  __typename?: "SupplyAutoCancelWaiverException";
  descriptions: Array<SupplyAutoCancelWaiverExceptionContent>;
  title: Scalars["String"]["output"];
};

export type SupplyAutoCancelWaiverExceptionContent = {
  __typename?: "SupplyAutoCancelWaiverExceptionContent";
  action: SupplyReservationsAction;
  text: Scalars["String"]["output"];
};

export type SupplyAutoCancelWaiverFooter = {
  __typename?: "SupplyAutoCancelWaiverFooter";
  cancel: SupplyReservationsAction;
  save: SupplyReservationsAction;
};

export type SupplyAutoCancelWaiverHeader = {
  __typename?: "SupplyAutoCancelWaiverHeader";
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyAutoCancelWaiverSection = {
  __typename?: "SupplyAutoCancelWaiverSection";
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  waiverRules: EgdsCheckBoxGroup;
};

export type SupplyBookingOptions = {
  __typename?: "SupplyBookingOptions";
  checkInCheckOutSection: BookingOptionsSection;
  maxMonthsBeforeBookingSection: BookingOptionsSection;
  minDaysBeforeBookingSection: BookingOptionsSection;
  minStaySection: BookingOptionsSection;
  operationClickstreamData: BookingOptionsOperationClickstreamData;
};

export type SupplyBookingOptionsUpdate = {
  __typename?: "SupplyBookingOptionsUpdate";
  success: Scalars["Boolean"]["output"];
};

export type SupplyBulkAddInventoryResponse = {
  __typename?: "SupplyBulkAddInventoryResponse";
  addInventoryPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  applySelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content?: Maybe<Array<SupplyLocalizedString>>;
  dates?: Maybe<Array<Date>>;
  editDatesButtonPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  editDatesButtonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  editRoomTypeButtonPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  editRoomTypeButtonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  maxIncrement?: Maybe<Scalars["Int"]["output"]>;
  roomTypes?: Maybe<Array<SupplyRoomType>>;
  startingIncrement?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyBulkReview = {
  __typename?: "SupplyBulkReview";
  inventoryReviews?: Maybe<Array<SupplyInventoryReview>>;
  roomType: SupplyRoomType;
};

export type SupplyBulkReviewAddInventoryResponse = {
  __typename?: "SupplyBulkReviewAddInventoryResponse";
  bulkReviews?: Maybe<Array<SupplyBulkReview>>;
};

export type SupplyCampaign = {
  __typename?: "SupplyCampaign";
  campaigns?: Maybe<SupplyCampaignCampaigns>;
  clickStreamPageView?: Maybe<SupplyCampaignClickStreamData>;
  licensePlate?: Maybe<SupplyCampaignLicensePlate>;
};

export type SupplyCampaignCampaignDetail = {
  __typename?: "SupplyCampaignCampaignDetail";
  name?: Maybe<SupplyCampaignText>;
  value?: Maybe<SupplyCampaignText>;
};

export type SupplyCampaignCampaigns = {
  __typename?: "SupplyCampaignCampaigns";
  cards?: Maybe<Array<SupplyCampaignCard>>;
};

export type SupplyCampaignCard = {
  __typename?: "SupplyCampaignCard";
  cardPresentedAnalytics?: Maybe<SupplyCampaignClickStreamData>;
  details?: Maybe<Array<SupplyCampaignCampaignDetail>>;
  icon?: Maybe<Icon>;
  primaryAction?: Maybe<SupplyCampaignLinkAction>;
  title: Scalars["String"]["output"];
};

export type SupplyCampaignClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyCampaignClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyCampaignLicensePlate = {
  __typename?: "SupplyCampaignLicensePlate";
  actionMenu?: Maybe<Array<SupplyCampaignLinkAction>>;
  description?: Maybe<Array<SupplyCampaignText>>;
  title: Scalars["String"]["output"];
};

export type SupplyCampaignLinkAction = {
  __typename?: "SupplyCampaignLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<SupplyCampaignClickStreamData>;
  buttonStyle?: Maybe<EgdsButtonStyle>;
  target?: Maybe<UiLinkTarget>;
  text: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCampaignText = EgdsPlainText | SupplyCampaignLinkAction;

export type SupplyCancelPolicyExceptionSelectedWaiverInformation = {
  __typename?: "SupplyCancelPolicyExceptionSelectedWaiverInformation";
  badge?: Maybe<SupplyReservationsBadge>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
  waiver: Scalars["String"]["output"];
};

export type SupplyCancelPolicyExceptionSelectedWaiversContent = {
  __typename?: "SupplyCancelPolicyExceptionSelectedWaiversContent";
  selectedWaivers: Array<SupplyCancelPolicyExceptionSelectedWaiverInformation>;
  subtitle: Scalars["String"]["output"];
};

export type SupplyCancelPolicyExceptions = {
  __typename?: "SupplyCancelPolicyExceptions";
  errorBanner: SupplyPropertyCancelPolicyExceptionBanner;
  learnMoreLink: SupplyReservationsAction;
  modifyCancelPolicyException: SupplyReservationsAction;
  selectedWaivers?: Maybe<SupplyCancelPolicyExceptionSelectedWaiversContent>;
  sideSheet: SupplyPropertyCancelPolicyExceptionsContent;
  tabTitle: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyCancelWaiverActionButton = EgdsButton & {
  __typename?: "SupplyCancelWaiverActionButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
};

export type SupplyCancellationPolicyDetailsExpandoLink = EgdsExpando & {
  __typename?: "SupplyCancellationPolicyDetailsExpandoLink";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedContent?: Maybe<SupplyCancellationPolicyDetailsSection>;
  expandedLabel: Scalars["String"]["output"];
};

export type SupplyCancellationPolicyDetailsSection = {
  __typename?: "SupplyCancellationPolicyDetailsSection";
  policyName: EgdsStandardLink;
  policyRules: Array<SupplyGuestItemContent>;
  policyTip?: Maybe<EgdsIconText>;
};

export type SupplyCancellationWaiverApprovalContent = {
  __typename?: "SupplyCancellationWaiverApprovalContent";
  cancellationReason: SupplyGuestItemContent;
  expando: SupplyCancellationPolicyDetailsExpandoLink;
  refundInformation: SupplyGuestItemContent;
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface SupplyCancellationWaiverApprovalRequestContext {
  bookingItemId: Scalars["String"]["input"];
  message?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyCancellationWaiverApprovalResponseContent = SupplyRefundDecision & {
  __typename?: "SupplyCancellationWaiverApprovalResponseContent";
  content: Scalars["String"]["output"];
  disclaimerLink: EgdsStandardLink;
  disclaimerText: Scalars["String"]["output"];
  icon: Icon;
  /** @deprecated Replaced by disclaimerLink */
  link: SupplyRefundDisclaimerInlineLink;
  primaryButton: EgdsButton;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyCancellationWaiverApprovalSection = {
  __typename?: "SupplyCancellationWaiverApprovalSection";
  approvalContent: SupplyCancellationWaiverApprovalContent;
  guestMessage: EgdsTextInputField;
  header: EgdsIconText;
  messageCard: EgdsStandardMessagingCard;
  primaryButton: SupplyCancelWaiverActionButton;
  secondaryButton: EgdsButton;
};

export type SupplyCancellationWaiverCardDetails = {
  __typename?: "SupplyCancellationWaiverCardDetails";
  cancellationReason: SupplyGuestItemContent;
  expando: SupplyCancellationPolicyDetailsExpandoLink;
  primaryButton: EgdsButton;
  refundInformation: EgdsStylizedText;
  secondaryButton: EgdsButton;
  title: Scalars["String"]["output"];
};

export type SupplyCancellationWaiverDeclineFlowContent = {
  __typename?: "SupplyCancellationWaiverDeclineFlowContent";
  declineReasonInput: SupplyCancellationWaiverDeclineReasonInput;
  header: EgdsIconText;
  messageCard: EgdsStandardMessagingCard;
  primaryButton: SupplyCancelWaiverActionButton;
  secondaryButton: EgdsButton;
};

export type SupplyCancellationWaiverDeclineReasonInput = {
  __typename?: "SupplyCancellationWaiverDeclineReasonInput";
  guestMessage: EgdsTextInputField;
  selector: EgdsBasicSelect;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyCancellationWaiverDeclineSuccessContent = SupplyRefundDecision & {
  __typename?: "SupplyCancellationWaiverDeclineSuccessContent";
  content: Scalars["String"]["output"];
  expando: SupplyCancellationPolicyDetailsExpandoLink;
  icon: Icon;
  primaryButton: EgdsButton;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface SupplyCancellationWaiverReservationContext {
  bookingItemId: Scalars["String"]["input"];
}

export interface SupplyCoachingActionPanel {
  title: Scalars["String"]["output"];
}

export type SupplyCoachingActionPanelSideSheetLayout = {
  __typename?: "SupplyCoachingActionPanelSideSheetLayout";
  layout?: Maybe<SupplyActionPanelSideSheetLayout>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingFeedbackComponent = {
  __typename?: "SupplyCoachingFeedbackComponent";
  feedbackLinkClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  feedbackLinkText?: Maybe<EgdsSpannableText>;
  feedbackPromptText: Scalars["String"]["output"];
  negativeFeedbackClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  negativeFeedbackPill?: Maybe<EgdsPill>;
  positiveFeedbackClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  positiveFeedbackPill?: Maybe<EgdsPill>;
};

export interface SupplyCoachingParameters {
  coachingTipId?: InputMaybe<Scalars["String"]["input"]>;
  coachingTipSourceId?: InputMaybe<Scalars["String"]["input"]>;
  proofPointStr?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyCoachingTipBlockEmptyState = {
  __typename?: "SupplyCoachingTipBlockEmptyState";
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
};

export type SupplyCoachingTipBlockTabs = {
  __typename?: "SupplyCoachingTipBlockTabs";
  tabsContents?: Maybe<Array<SupplyCoachingTipBlockTabsContent>>;
  /** @deprecated Moved to SupplyCoachingTipBlockTabsContent. We now want to track individual tabs instead of full module */
  tabsPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export interface SupplyCoachingTipBlockTabsAnalyticsContext {
  pageName?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyCoachingTipBlockTabsContent = {
  __typename?: "SupplyCoachingTipBlockTabsContent";
  coachingTipState: Scalars["String"]["output"];
  tabPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  tabSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyCoachingTipCard = {
  __typename?: "SupplyCoachingTipCard";
  /** @deprecated Please use tipCardAction */
  cardAction?: Maybe<SupplyQuickActionOpenSheetAction>;
  cardInfo: SupplyCoachingTipCardInfo;
  clickStreamData?: Maybe<SupplyCoachingTipCardClickStreamData>;
  tagIds?: Maybe<Array<Scalars["String"]["output"]>>;
  tipCardAction?: Maybe<SupplyCoachingTipCardAction>;
};

export type SupplyCoachingTipCardAction =
  | SupplyQuickActionActionedSummaryViewAction
  | SupplyQuickActionOpenHostedViewAction
  | SupplyQuickActionOpenSheetAction
  | SupplyQuickActionSendCardInteractionSignal;

export type SupplyCoachingTipCardClickStreamData = {
  __typename?: "SupplyCoachingTipCardClickStreamData";
  /** @deprecated This field will be deprecated in the future, use cardPresentedAnalyticsPayload instead */
  cardPresented?: Maybe<SupplyVirtualMarketCoachEgClickStreamData>;
  cardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use cardSelectedAnalyticsPayload instead */
  cardSelected?: Maybe<SupplyVirtualMarketCoachEgClickStreamData>;
  cardSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyCoachingTipCardInfo = {
  __typename?: "SupplyCoachingTipCardInfo";
  actionIcon?: Maybe<Icon>;
  badge?: Maybe<EgdsStandardBadge>;
  deepLink?: Maybe<UiLinkAction>;
  description: EgdsSpannableText;
  icon: Icon;
  primaryButton?: Maybe<EgdsButton>;
  secondaryButton?: Maybe<EgdsButton>;
  title: Scalars["String"]["output"];
};

export type SupplyCoachingTipCount = EgdsStandardLink;

export type SupplyCoachingTipCounts = {
  __typename?: "SupplyCoachingTipCounts";
  actionedCount?: Maybe<Scalars["String"]["output"]>;
  activeCount?: Maybe<Scalars["String"]["output"]>;
  postponedCount?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingTipPostponeAnalytics = {
  __typename?: "SupplyCoachingTipPostponeAnalytics";
  postponeSheetClosed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  postponeSheetPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  postponeSubmitted?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyCoachingTipPostponeForm = {
  __typename?: "SupplyCoachingTipPostponeForm";
  clickStreamData?: Maybe<SupplyCoachingTipPostponeAnalytics>;
  durationSelectionPromptTitle?: Maybe<Scalars["String"]["output"]>;
  durationSelectionPrompts?: Maybe<SupplyPostponeSingleSelection>;
  heading: Scalars["String"]["output"];
  notInterestedPromptSubtitle?: Maybe<Scalars["String"]["output"]>;
  notInterestedPromptTitle?: Maybe<Scalars["String"]["output"]>;
  notInterestedPrompts?: Maybe<SupplyPostponeMultipleSelection>;
  saveForLaterPromptTitle?: Maybe<Scalars["String"]["output"]>;
  saveForLaterPrompts?: Maybe<SupplyPostponeSingleSelection>;
  submitButtonLabel: Scalars["String"]["output"];
};

export type SupplyCoachingTipProofPointEgClickstreamCustomAttribute = {
  __typename?: "SupplyCoachingTipProofPointEGClickstreamCustomAttribute";
  comparative_set?: Maybe<Scalars["String"]["output"]>;
  metric?: Maybe<Scalars["String"]["output"]>;
  option?: Maybe<Scalars["String"]["output"]>;
  option_percent?: Maybe<Scalars["String"]["output"]>;
  type_id?: Maybe<Scalars["Int"]["output"]>;
  uplift_percentage?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingTipPropertyFinder = {
  __typename?: "SupplyCoachingTipPropertyFinder";
  buttonText: Scalars["String"]["output"];
  coachingTipTypeSelections: SupplyPropertyFinderTipTypeSelection;
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingTipResultDetailContent = {
  __typename?: "SupplyCoachingTipResultDetailContent";
  elements: Array<SupplyCoachingTipResultDetailElement>;
};

export type SupplyCoachingTipResultDetailElement =
  | EgdsStandardBadge
  | SupplyQuickActionCard
  | SupplyQuickActionSpacing
  | SupplyQuickActionTable
  | SupplyQuickActionText;

export type SupplyCoachingTipResultDetails = {
  __typename?: "SupplyCoachingTipResultDetails";
  content: SupplyCoachingTipResultDetailContent;
  feedback?: Maybe<SupplyCoachingFeedbackComponent>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingTipResultsFooter = {
  __typename?: "SupplyCoachingTipResultsFooter";
  feedback?: Maybe<SupplyCoachingFeedbackComponent>;
  /** @deprecated Use feedback instead */
  feedbackLink?: Maybe<EgdsSpannableText>;
  /** @deprecated Use feedback instead */
  feedbackLinkClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyCoachingTipResultsItem = {
  __typename?: "SupplyCoachingTipResultsItem";
  cardInfo: SupplyCoachingTipResultsItemInfo;
  coachingTipTags?: Maybe<Array<SupplyCoachingTipTag>>;
  resultsItemPresentedClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  summaryViewButton?: Maybe<SupplyCoachingTipResultsSummaryViewButton>;
};

export type SupplyCoachingTipResultsItemInfo = {
  __typename?: "SupplyCoachingTipResultsItemInfo";
  iconName?: Maybe<Scalars["String"]["output"]>;
  lastUpdateTime?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingTipResultsSummaryViewButton = {
  __typename?: "SupplyCoachingTipResultsSummaryViewButton";
  summaryViewButtonText: Scalars["String"]["output"];
  summaryViewClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyCoachingTipTag = {
  __typename?: "SupplyCoachingTipTag";
  /** @deprecated This field will be removed in the future; please use optionalIcon */
  icon: Icon;
  optionalIcon?: Maybe<Icon>;
  tagDeselectedAnalyticsEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  tagId: Scalars["String"]["output"];
  tagSelectedAnalyticsEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SupplyCoachingTipsBlock = {
  __typename?: "SupplyCoachingTipsBlock";
  coachingTipBlockEmptyState?: Maybe<SupplyCoachingTipBlockEmptyState>;
  coachingTipCards: Array<SupplyCoachingTipCard>;
  coachingTipCount?: Maybe<SupplyCoachingTipCount>;
  coachingTipTags?: Maybe<Array<SupplyCoachingTipTag>>;
  lastUpdatedSubtitle?: Maybe<Scalars["String"]["output"]>;
  opportunitiesPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future due to library update, use opportunitiesPresentedAnalyticsPayload instead */
  opportunitiesPresentedClickstreamData?: Maybe<SupplyVirtualMarketCoachEgClickStreamData>;
  title?: Maybe<EgdsHeading>;
};

export interface SupplyCoachingTipsResultDetailParameters {
  coachingTipId?: InputMaybe<Scalars["String"]["input"]>;
  coachingTipSourceId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyCoachingTipsResults = {
  __typename?: "SupplyCoachingTipsResults";
  badgeLabel?: Maybe<Scalars["String"]["output"]>;
  coachingTipTags?: Maybe<Array<SupplyCoachingTipTag>>;
  footer?: Maybe<SupplyCoachingTipResultsFooter>;
  infoBanner?: Maybe<SupplyCoachingTipsResultsInfoBanner>;
  resultItems: Array<SupplyCoachingTipResultsItem>;
  resultsPresentedClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subheader?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  viewLessClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  viewLessText?: Maybe<Scalars["String"]["output"]>;
  viewMoreClickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  viewMoreText?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyCoachingTipsResultsInfoBanner = {
  __typename?: "SupplyCoachingTipsResultsInfoBanner";
  iconName: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyConnectivityProvidersResponse = {
  __typename?: "SupplyConnectivityProvidersResponse";
  errorMessages?: Maybe<Array<SupplyConnectivitySettingsErrorInfo>>;
  providers: Array<ConnectivityProvider>;
};

export type SupplyConnectivitySettingProviderServiceContent = {
  __typename?: "SupplyConnectivitySettingProviderServiceContent";
  arbrTandemRollout?: Maybe<Scalars["String"]["output"]>;
  footerContent?: Maybe<Scalars["String"]["output"]>;
  manageConnectHead?: Maybe<Scalars["String"]["output"]>;
  manageConnectSubhead?: Maybe<Scalars["String"]["output"]>;
  manageDisconnectHead?: Maybe<Scalars["String"]["output"]>;
  manageDisconnectSubhead?: Maybe<Scalars["String"]["output"]>;
  providerTypeAhead?: Maybe<ConnectivitySettingsTypeahead>;
  serviceCard?: Maybe<Array<ServiceActionListItem>>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyConnectivitySettingProviderServiceView = {
  __typename?: "SupplyConnectivitySettingProviderServiceView";
  buttons: Array<ConnectivitySettingsButton>;
  content?: Maybe<SupplyConnectivitySettingProviderServiceContent>;
  errorMessages?: Maybe<Array<SupplyConnectivitySettingsErrorInfo>>;
  stepIndicator?: Maybe<ConnectivitySettingsStepIndicator>;
  stepName: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyConnectivitySettingsErrorInfo = {
  __typename?: "SupplyConnectivitySettingsErrorInfo";
  contactSupportLink?: Maybe<EgdsStandardLink>;
  dismissLink?: Maybe<EgdsStandardLink>;
  errorText: Scalars["String"]["output"];
  errorTitle?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyConnectivitySettingsExperience = {
  __typename?: "SupplyConnectivitySettingsExperience";
  errorMessages?: Maybe<Array<SupplyConnectivitySettingsErrorInfo>>;
  header: ConnectivitySettingsHeader;
  main: ConnectivitySettingsMain;
};

export type SupplyContactMethod = {
  __typename?: "SupplyContactMethod";
  action: SupplyContactMethodAction;
  description: EgdsSpannableText;
  heading: Scalars["String"]["output"];
  icon: Icon;
};

export type SupplyContactMethodAction = {
  __typename?: "SupplyContactMethodAction";
  action: Scalars["String"]["output"];
  form?: Maybe<SupplyContactMethodForm>;
  linkAction?: Maybe<UiLinkAction>;
  linkLabel: Scalars["String"]["output"];
  supportOptionSelectedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  taxonomyId?: Maybe<Scalars["String"]["output"]>;
  vaIntent?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContactMethodForm = SupplyContactMethodSendMessageForm;

export type SupplyContactMethodSendMessageForm = {
  __typename?: "SupplyContactMethodSendMessageForm";
  cancel: EgdsButton;
  email: EgdsInputField;
  firstName: EgdsInputField;
  lastName: EgdsInputField;
  message: EgdsTextAreaInputField;
  submit: EgdsButton;
};

export type SupplyContentAdditionalInfo = {
  __typename?: "SupplyContentAdditionalInfo";
  bathrooms?: Maybe<SupplyContentDescriptionSection>;
  bedrooms?: Maybe<SupplyContentDescriptionSection>;
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  header: SupplyContentHubHeader;
  property?: Maybe<SupplyContentDescriptionSection>;
};

export interface SupplyContentAdditionalInfoUpdateInput {
  bathrooms?: InputMaybe<Scalars["String"]["input"]>;
  bedrooms?: InputMaybe<Scalars["String"]["input"]>;
  property?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyContentAmenitiesGroupConfig = {
  __typename?: "SupplyContentAmenitiesGroupConfig";
  /** @deprecated Use LocalizedDescription instead */
  description: Scalars["String"]["output"];
  groupId: Scalars["String"]["output"];
  localizedDescription: EgdsLocalizedText;
  title: Scalars["String"]["output"];
};

export type SupplyContentAmenity = {
  __typename?: "SupplyContentAmenity";
  amenities: Array<SupplyContentAmenityItem>;
  heading: Scalars["String"]["output"];
};

export interface SupplyContentAmenityInput {
  uuid: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type SupplyContentAmenityItem = {
  __typename?: "SupplyContentAmenityItem";
  element: EgdsBasicCheckBox;
  uuid: Scalars["String"]["output"];
};

export type SupplyContentAmenitySection = SupplyContentAmenity | SupplyContentErrorSection;

export type SupplyContentButton = {
  __typename?: "SupplyContentButton";
  button: EgdsButton;
  buttonSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentCtaTipCard = {
  __typename?: "SupplyContentCTATipCard";
  cta: SupplyContentButton;
  description?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  image?: Maybe<Scalars["URL"]["output"]>;
};

export type SupplyContentCarousel = {
  __typename?: "SupplyContentCarousel";
  cards: Array<SupplyContentCarouselCard>;
  carouselViewed?: Maybe<SupplyContentEgClickstreamData>;
  nextButtonText: Scalars["String"]["output"];
  previousButtonText: Scalars["String"]["output"];
};

export type SupplyContentCarouselCard = {
  __typename?: "SupplyContentCarouselCard";
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  id?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Scalars["URL"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SupplyContentCheckBox = {
  __typename?: "SupplyContentCheckBox";
  checked: Scalars["Boolean"]["output"];
  contentSelected?: Maybe<SupplyContentEgClickstreamData>;
  contentUnSelected?: Maybe<SupplyContentEgClickstreamData>;
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyContentCoachingActionPanel = {
  __typename?: "SupplyContentCoachingActionPanel";
  actionPanelConfig?: Maybe<Array<SupplyActionPanelConfig>>;
  actions: SupplyActionPanelActions;
  leaveSheet?: Maybe<SupplyActionPanelLeaveSheet>;
  mainSheet: SupplyActionPanelMainSheet;
  sideSheetLayout?: Maybe<Array<SupplyCoachingActionPanelSideSheetLayout>>;
};

export interface SupplyContentCoachingParameters {
  coachingTipId?: InputMaybe<Scalars["String"]["input"]>;
  coachingTipSourceId?: InputMaybe<Scalars["String"]["input"]>;
  proofPointStr?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyContentDescriptionBanner = {
  __typename?: "SupplyContentDescriptionBanner";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  icon: Scalars["String"]["output"];
};

export type SupplyContentDescriptionDeleteRecommendationSection = {
  __typename?: "SupplyContentDescriptionDeleteRecommendationSection";
  deleteLink: EgdsStandardLink;
  deleteLinkSelected?: Maybe<SupplyContentEgClickstreamData>;
  toast: EgdsToast;
  undoSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentDescriptionInputSection = {
  __typename?: "SupplyContentDescriptionInputSection";
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  recommendationResult?: Maybe<SupplyContentEgClickstreamData>;
  textArea: SupplyContentTextArea;
  tips?: Maybe<SupplyContentTips>;
};

export type SupplyContentDescriptionLearnMoreSheet = {
  __typename?: "SupplyContentDescriptionLearnMoreSheet";
  content: EgdsTextListSection;
  description: Scalars["String"]["output"];
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyContentDescriptionRecommendationSection = {
  __typename?: "SupplyContentDescriptionRecommendationSection";
  deleteRecommendationSection: SupplyContentDescriptionDeleteRecommendationSection;
  recommendationTipCard: SupplyContentDescriptionRecommendationTipCard;
  recommendationValue: Scalars["String"]["output"];
};

export type SupplyContentDescriptionRecommendationTipCard = {
  __typename?: "SupplyContentDescriptionRecommendationTipCard";
  heading: Scalars["String"]["output"];
  icon: Scalars["String"]["output"];
  instruction: SupplyContentSpannableText;
  learnMoreSheet: SupplyContentDescriptionLearnMoreSheet;
};

export type SupplyContentDescriptionSection = SupplyContentDescriptionInputSection | SupplyContentErrorSection;

export type SupplyContentDescriptionSectionName =
  | "ABOUT_YOU"
  | "BATHROOMS_ADDITIONAL_INFO"
  | "BEDROOMS_ADDITIONAL_INFO"
  | "DESCRIPTION"
  | "HEADLINE"
  | "INTERIOR_SIZE"
  | "MANUAL_TRANSLATION"
  | "PROPERTY_ADDITIONAL_INFO"
  | "PROPERTY_NAME"
  | "PROPERTY_TYPE"
  | "SUITABILITY_AMENITY"
  | "UNIQUE_BENEFITS"
  | "WHY_THIS_PROPERTY";

export type SupplyContentDescriptionUpdate = {
  __typename?: "SupplyContentDescriptionUpdate";
  contentSaved: Array<SupplyContentEgClickstreamData>;
  failures?: Maybe<Array<SupplyContentDescriptionUpdateFailure>>;
  toast?: Maybe<EgdsToast>;
};

export type SupplyContentDescriptionUpdateFailure = {
  __typename?: "SupplyContentDescriptionUpdateFailure";
  error: SupplyContentErrorMessage;
  sectionName: SupplyContentDescriptionSectionName;
};

export type SupplyContentEgClickstreamData = IEgClickstreamData & {
  __typename?: "SupplyContentEgClickstreamData";
  customizedAttributes?: Maybe<Scalars["String"]["output"]>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
};

export type SupplyContentError = {
  __typename?: "SupplyContentError";
  contentError?: Maybe<SupplyContentEgClickstreamData>;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  refresh?: Maybe<SupplyContentButton>;
  suggestion?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentErrorMessage = {
  __typename?: "SupplyContentErrorMessage";
  icon: Icon;
  message: Scalars["String"]["output"];
};

export type SupplyContentErrorSection = {
  __typename?: "SupplyContentErrorSection";
  error: SupplyContentErrorMessage;
  heading: Scalars["String"]["output"];
};

export type SupplyContentExistingManualTranslation = {
  __typename?: "SupplyContentExistingManualTranslation";
  deleteButton: SupplyContentButton;
  locale: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  viewButton: SupplyContentButton;
};

export type SupplyContentExpando = {
  __typename?: "SupplyContentExpando";
  content: SupplyContentExpandoContent;
  contentSelected?: Maybe<SupplyContentEgClickstreamData>;
  trigger: EgdsExpandoLink;
};

export type SupplyContentExpandoContent = SupplyContentGenAiExpandoContent;

export type SupplyContentFeedbackSheetContent = {
  __typename?: "SupplyContentFeedbackSheetContent";
  header: SupplyContentHubHeader;
  questions: Array<SupplyContentCheckBox>;
  sendFeedbackButton: SupplyContentButton;
  skipButton: SupplyContentButton;
};

export type SupplyContentGenAiExpandoContent = {
  __typename?: "SupplyContentGenAIExpandoContent";
  keepOriginalButton: SupplyContentButton;
  label: Scalars["String"]["output"];
};

export type SupplyContentGenAiHeadlineDescription = {
  __typename?: "SupplyContentGenAIHeadlineDescription";
  contentViewed?: Maybe<Array<SupplyContentEgClickstreamData>>;
  description: SupplyContentGenAiResult;
  feedbackSheet?: Maybe<SupplyContentSheet>;
  headline: SupplyContentGenAiResult;
  highlightTipCard?: Maybe<SupplyContentHighlightTipCard>;
  toast?: Maybe<EgdsToast>;
};

export type SupplyContentGenAiResult = SupplyContentErrorMessage | SupplyContentGenAiSuccess;

export type SupplyContentGenAiSuccess = {
  __typename?: "SupplyContentGenAISuccess";
  expando: SupplyContentExpando;
  text: Scalars["String"]["output"];
};

export type SupplyContentHeadlineDescription = {
  __typename?: "SupplyContentHeadlineDescription";
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  description?: Maybe<SupplyContentDescriptionSection>;
  header?: Maybe<SupplyContentHubHeader>;
  headline?: Maybe<SupplyContentDescriptionSection>;
  manualTranslation?: Maybe<SupplyContentManualTranslationSection>;
  propertyName?: Maybe<SupplyContentDescriptionSection>;
  tipCard?: Maybe<SupplyContentCtaTipCard>;
  warningBanner?: Maybe<SupplyContentDescriptionBanner>;
};

export interface SupplyContentHeadlineDescriptionUpdateInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  propertyName?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyContentHighlightTipCard = {
  __typename?: "SupplyContentHighlightTipCard";
  description?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  highlights: EgdsTextListSection;
  icon: Scalars["String"]["output"];
};

export type SupplyContentHomePage = {
  __typename?: "SupplyContentHomePage";
  header?: Maybe<SupplyContentHubHeader>;
  homePageViewed?: Maybe<SupplyContentEgClickstreamData>;
  propertyDetails?: Maybe<SupplyContentPropertyDetailsSection>;
  sections?: Maybe<Array<SupplyContentHubSection>>;
};

export type SupplyContentHomePageHeaderArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  queryIdentifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyContentHomePagePropertyDetailsArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  queryIdentifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyContentHomePageSectionsArgs = {
  context: ContextInput;
  navigationLevel: Scalars["String"]["input"];
  propertyContext: PropertyContext;
  queryIdentifier?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyContentHub = {
  __typename?: "SupplyContentHub";
  contentViewed?: Maybe<SupplyContentEgClickstreamData>;
  header: SupplyContentHubHeader;
  items: Array<SupplyContentHubItem>;
  sectionName?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentHubCrumbItem = {
  __typename?: "SupplyContentHubCrumbItem";
  crumbSelected?: Maybe<SupplyContentEgClickstreamData>;
  link?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SupplyContentHubHeader = {
  __typename?: "SupplyContentHubHeader";
  feedback?: Maybe<SupplyContentText>;
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentHubItem = {
  __typename?: "SupplyContentHubItem";
  contentSelected?: Maybe<SupplyContentEgClickstreamData>;
  heading: Scalars["String"]["output"];
  icon: Icon;
  link: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyContentHubNavigation = {
  __typename?: "SupplyContentHubNavigation";
  backButton: SupplyContentButton;
  breadCrumbs: Array<SupplyContentHubCrumbItem>;
};

export type SupplyContentHubNormalSection = {
  __typename?: "SupplyContentHubNormalSection";
  items?: Maybe<Array<SupplyContentHubItem>>;
  listStyle?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SupplyContentHubSection = SupplyContentError | SupplyContentHubNormalSection;

export interface SupplyContentInputValidation {
  errorMessage: EgdsLocalizedText;
}

export type SupplyContentInteriorSize = {
  __typename?: "SupplyContentInteriorSize";
  heading: Scalars["String"]["output"];
  size: EgdsInputField;
  unit: EgdsBasicSelect;
};

export type SupplyContentInteriorSizeSection = SupplyContentErrorSection | SupplyContentInteriorSize;

export type SupplyContentLeaveWithoutSavingDialog = {
  __typename?: "SupplyContentLeaveWithoutSavingDialog";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  leaveButton: SupplyContentButton;
  stayButton: SupplyContentButton;
};

export type SupplyContentLinkStyleButton = EgdsButton & {
  __typename?: "SupplyContentLinkStyleButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiLinkAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentLocationSharing = {
  __typename?: "SupplyContentLocationSharing";
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  header: SupplyContentHubHeader;
  mapSetting?: Maybe<SupplyContentMapSettingsSection>;
  propertyAddress: SupplyContentPropertyAddressSection;
};

export type SupplyContentManualTranslation = {
  __typename?: "SupplyContentManualTranslation";
  addButton: SupplyContentButton;
  deleteDialog: SupplyContentManualTranslationDeleteDialog;
  description: Scalars["String"]["output"];
  existingManualTranslations?: Maybe<Array<SupplyContentExistingManualTranslation>>;
  heading: Scalars["String"]["output"];
};

export type SupplyContentManualTranslationDeleteDialog = {
  __typename?: "SupplyContentManualTranslationDeleteDialog";
  cancelButton: SupplyContentButton;
  deleteButton: SupplyContentButton;
  heading: Scalars["String"]["output"];
};

export type SupplyContentManualTranslationOperation = "ADD" | "DELETE" | "UPDATE";

export type SupplyContentManualTranslationSection = SupplyContentErrorSection | SupplyContentManualTranslation;

export type SupplyContentManualTranslationSetting = {
  __typename?: "SupplyContentManualTranslationSetting";
  description: SupplyContentTextArea;
  headline: SupplyContentTextArea;
  instruction?: Maybe<Scalars["String"]["output"]>;
  language: EgdsBasicSelect;
  saveButton: SupplyContentButton;
  translationSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentManualTranslationSettingDialog = {
  __typename?: "SupplyContentManualTranslationSettingDialog";
  contentViewed?: Maybe<SupplyContentEgClickstreamData>;
  manualTranslation: SupplyContentManualTranslationSetting;
  toolbar: EgdsDialogToolbar;
};

export type SupplyContentManualTranslationUpdate = {
  __typename?: "SupplyContentManualTranslationUpdate";
  failures?: Maybe<Array<SupplyContentDescriptionUpdateFailure>>;
  successToast?: Maybe<EgdsToast>;
};

export interface SupplyContentManualTranslationUpdateInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  headline?: InputMaybe<Scalars["String"]["input"]>;
  locale: Scalars["String"]["input"];
  operation: SupplyContentManualTranslationOperation;
  optOutAutoTranslation: Scalars["Boolean"]["input"];
}

export type SupplyContentMapSettings = {
  __typename?: "SupplyContentMapSettings";
  description?: Maybe<Scalars["String"]["output"]>;
  displayOptions?: Maybe<EgdsRadioGroup>;
  exactLatitude?: Maybe<Scalars["String"]["output"]>;
  exactLongitude?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  instruction?: Maybe<SupplyContentSpannableText>;
  mapSettingSelected?: Maybe<SupplyContentEgClickstreamData>;
  obfuscatedLatitude?: Maybe<Scalars["String"]["output"]>;
  obfuscatedLongitude?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentMapSettingsSection = SupplyContentErrorSection | SupplyContentMapSettings;

export type SupplyContentMaxLengthInputValidation = SupplyContentInputValidation & {
  __typename?: "SupplyContentMaxLengthInputValidation";
  errorMessage: EgdsLocalizedText;
  maxLength: Scalars["Int"]["output"];
};

export type SupplyContentMinLengthInputValidation = SupplyContentInputValidation & {
  __typename?: "SupplyContentMinLengthInputValidation";
  errorMessage: EgdsLocalizedText;
  minLength: Scalars["Int"]["output"];
};

export type SupplyContentModeration = {
  __typename?: "SupplyContentModeration";
  errorMessage: SupplyContentErrorMessage;
  id: Scalars["String"]["output"];
  rule: Moderation_Rule;
  type: Moderation_Type;
  value: Array<Scalars["String"]["output"]>;
};

export type SupplyContentPropertyAddress = {
  __typename?: "SupplyContentPropertyAddress";
  address?: Maybe<SupplyContentPropertyAddressDetail>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  instruction?: Maybe<SupplyContentSpannableText>;
};

export type SupplyContentPropertyAddressDetail = {
  __typename?: "SupplyContentPropertyAddressDetail";
  address1: Scalars["String"]["output"];
  address2?: Maybe<Scalars["String"]["output"]>;
  cityWithPostalCode: Scalars["String"]["output"];
  countryCode: Scalars["String"]["output"];
};

export type SupplyContentPropertyAddressSection = SupplyContentErrorSection | SupplyContentPropertyAddress;

export type SupplyContentPropertyAnalytics = {
  __typename?: "SupplyContentPropertyAnalytics";
  communitySelected: Array<SupplyContentEgClickstreamData>;
  contentViewed: Array<SupplyContentEgClickstreamData>;
  propertyTypeSelected?: Maybe<SupplyContentEgClickstreamData>;
  renterSelected: Array<SupplyContentEgClickstreamData>;
  sizeChanged?: Maybe<SupplyContentEgClickstreamData>;
  unitSelected?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContentPropertyDetails = {
  __typename?: "SupplyContentPropertyDetails";
  address?: Maybe<Scalars["String"]["output"]>;
  fax?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  modificationLink?: Maybe<SupplyContentText>;
  name?: Maybe<Scalars["String"]["output"]>;
  primaryPhone?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentPropertyDetailsSection = SupplyContentError | SupplyContentPropertyDetails;

export type SupplyContentPropertyType = {
  __typename?: "SupplyContentPropertyType";
  heading: Scalars["String"]["output"];
  type: EgdsBasicSelect;
};

export type SupplyContentPropertyTypeSection = SupplyContentErrorSection | SupplyContentPropertyType;

export type SupplyContentSaveButton = {
  __typename?: "SupplyContentSaveButton";
  button: SupplyContentButton;
  leaveWithoutSavingDialog: SupplyContentLeaveWithoutSavingDialog;
};

export type SupplyContentScore = {
  __typename?: "SupplyContentScore";
  contentScoreViewed?: Maybe<SupplyContentEgClickstreamData>;
  details?: Maybe<SupplyContentScoreDetails>;
  heading: Scalars["String"]["output"];
  summary: SupplyContentScoreSummary;
  toastMsg?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentScoreAllCompleted = {
  __typename?: "SupplyContentScoreAllCompleted";
  furtherStepsMsg: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  icon: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type SupplyContentScoreCard = {
  __typename?: "SupplyContentScoreCard";
  actionLink: SupplyContentText;
  attention: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  improvedScoreMsg: Scalars["String"]["output"];
  score: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyContentScoreCards = {
  __typename?: "SupplyContentScoreCards";
  cards?: Maybe<Array<SupplyContentScoreCard>>;
  heading: Scalars["String"]["output"];
};

export type SupplyContentScoreDetails = SupplyContentScoreAllCompleted | SupplyContentScoreCards;

export type SupplyContentScoreResp = SupplyContentError | SupplyContentScore;

export type SupplyContentScoreSummary = {
  __typename?: "SupplyContentScoreSummary";
  denominator: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  explanation: Array<Scalars["String"]["output"]>;
  explanationLink: SupplyContentText;
  ranking: Scalars["String"]["output"];
  score: Scalars["String"]["output"];
};

export type SupplyContentSheet = {
  __typename?: "SupplyContentSheet";
  content: SupplyContentSheetContent;
  contentViewed?: Maybe<SupplyContentEgClickstreamData>;
  sheetType: EgdsSheetType;
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyContentSheetContent = SupplyContentFeedbackSheetContent;

export type SupplyContentSpannableText = {
  __typename?: "SupplyContentSpannableText";
  contents: Array<SupplyContentText>;
  text: Scalars["String"]["output"];
};

export type SupplyContentText = {
  __typename?: "SupplyContentText";
  action?: Maybe<SupplyContextTextAction>;
  text: EgdsText;
};

export type SupplyContentTextArea = {
  __typename?: "SupplyContentTextArea";
  contentChanged?: Maybe<SupplyContentEgClickstreamData>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  labelTemplate?: Maybe<EgdsLocalizedText>;
  leftIcon?: Maybe<Icon>;
  maxRows?: Maybe<Scalars["Int"]["output"]>;
  minRows?: Maybe<Scalars["Int"]["output"]>;
  moderation?: Maybe<Array<SupplyContentModeration>>;
  moderationPresented?: Maybe<SupplyContentEgClickstreamData>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly: Scalars["Boolean"]["output"];
  recommendation?: Maybe<SupplyContentDescriptionRecommendationSection>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  rightIcon?: Maybe<Icon>;
  validations?: Maybe<Array<SupplyContentInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContentTips = {
  __typename?: "SupplyContentTips";
  carousel: SupplyContentCarousel;
  contentSelected?: Maybe<SupplyContentEgClickstreamData>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<EgdsExpandoLink>;
};

export interface SupplyContentUpdateLocationInput {
  showExactLocation: Scalars["Boolean"]["input"];
}

export type SupplyContentUpdateLocationResult = {
  __typename?: "SupplyContentUpdateLocationResult";
  error?: Maybe<SupplyContentErrorMessage>;
  mapSettingSaved?: Maybe<SupplyContentEgClickstreamData>;
  toast?: Maybe<EgdsToast>;
};

export type SupplyContentYourProperty = {
  __typename?: "SupplyContentYourProperty";
  contentAnalytics?: Maybe<SupplyContentPropertyAnalytics>;
  header: SupplyContentHubHeader;
  interiorSize: SupplyContentInteriorSizeSection;
  propertyName?: Maybe<SupplyContentDescriptionSection>;
  propertyType: SupplyContentPropertyTypeSection;
  suitabilityAmenity: SupplyContentAmenitySection;
};

export interface SupplyContentYourPropertyUpdateInput {
  amenities?: InputMaybe<Array<SupplyContentAmenityInput>>;
  propertyName?: InputMaybe<Scalars["String"]["input"]>;
  propertyTypeId?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["String"]["input"]>;
  unit?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyContentYourStory = {
  __typename?: "SupplyContentYourStory";
  aboutYou: SupplyContentDescriptionSection;
  contentViewed: Array<Maybe<SupplyContentEgClickstreamData>>;
  header: SupplyContentHubHeader;
  uniqueBenefits: SupplyContentDescriptionSection;
  whyThisProperty: SupplyContentDescriptionSection;
};

export interface SupplyContentYourStoryUpdateInput {
  aboutYou?: InputMaybe<Scalars["String"]["input"]>;
  uniqueBenefits?: InputMaybe<Scalars["String"]["input"]>;
  whyThisProperty?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyContextTextAction = {
  __typename?: "SupplyContextTextAction";
  selectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyContractExpando = {
  __typename?: "SupplyContractExpando";
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  expando: SupplyContractExpandoCard;
  expandoAction?: Maybe<SupplyContractingExpandoAction>;
  icon?: Maybe<Icon>;
  text: Array<Scalars["String"]["output"]>;
};

export type SupplyContractExpandoCard = EgdsExpandoCard | EgdsSpannableText;

export type SupplyContractTerms = {
  __typename?: "SupplyContractTerms";
  banners?: Maybe<Array<UiBanner>>;
  elements: Array<SupplyContractTermsElement>;
  notAvailableMessage?: Maybe<EgdsSpannableText>;
  primary: Scalars["String"]["output"];
  primaryLink?: Maybe<EgdsStandardLink>;
};

export type SupplyContractTermsElement =
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsTextSection
  | SupplyContractExpando
  | SupplyGeneralTermsLink;

export type SupplyContractingAcceptTermsAndConditions = {
  __typename?: "SupplyContractingAcceptTermsAndConditions";
  metadata?: Maybe<SupplyContractingEnrollmentMetadata>;
  program?: Maybe<Scalars["String"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingAction = SupplyContractingButton;

export type SupplyContractingAddOns = {
  __typename?: "SupplyContractingAddOns";
  content: SupplyContractingAddOnsContent;
  footers?: Maybe<Array<EgdsSpannableText>>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyContractingAddOnsContent = SupplyContractingAgreementErrorContent | SupplyContractingCarousel;

export type SupplyContractingAgreementAddOnContent = {
  __typename?: "SupplyContractingAgreementAddOnContent";
  messageCard: SupplyContractingMessagingCard;
  playbackText?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingAgreementBasicCard = {
  __typename?: "SupplyContractingAgreementBasicCard";
  content: SupplyContractingAgreementBasicCardContent;
  footers?: Maybe<Array<EgdsSpannableText>>;
  id: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
};

export type SupplyContractingAgreementBasicCardContent =
  | SupplyContractingAgreementBusinessModelContent
  | SupplyContractingAgreementDetails
  | SupplyContractingAgreementErrorContent;

export type SupplyContractingAgreementBasicsExperience = {
  __typename?: "SupplyContractingAgreementBasicsExperience";
  basicCard?: Maybe<Array<SupplyContractingAgreementBasicCard>>;
  footers?: Maybe<Array<EgdsSpannableText>>;
  header: SupplyContractingAgreementHeader;
};

export type SupplyContractingAgreementBusinessModelContent = {
  __typename?: "SupplyContractingAgreementBusinessModelContent";
  content?: Maybe<SupplyContractingAgreementContent>;
};

export type SupplyContractingAgreementContent = {
  __typename?: "SupplyContractingAgreementContent";
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingAgreementDetails = {
  __typename?: "SupplyContractingAgreementDetails";
  info?: Maybe<SupplyContractingInfoCard>;
  overview: SupplyContractingGraphicSection;
};

export type SupplyContractingAgreementErrorContent = {
  __typename?: "SupplyContractingAgreementErrorContent";
  errorTexts: Array<EgdsSpannableText>;
  graphic: SupplyContractingGraphic;
};

export type SupplyContractingAgreementExperience = {
  __typename?: "SupplyContractingAgreementExperience";
  agreementAddOns?: Maybe<SupplyContractingAddOns>;
  agreementBasics?: Maybe<SupplyContractingAgreementBasicsExperience>;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  error?: Maybe<SupplyContractingAgreementErrorContent>;
  footers?: Maybe<Array<EgdsSpannableText>>;
  /** @deprecated header is moved to SupplyContractingAgreementBasicsExperience */
  header?: Maybe<SupplyContractingAgreementHeader>;
};

export type SupplyContractingAgreementExperienceAgreementAddOnsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type SupplyContractingAgreementExperienceAgreementBasicsArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type SupplyContractingAgreementExperienceFootersArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type SupplyContractingAgreementHeader = {
  __typename?: "SupplyContractingAgreementHeader";
  primary: Scalars["String"]["output"];
  primaryLink?: Maybe<EgdsStandardLink>;
};

export type SupplyContractingAlignment = "CENTER" | "LEFT";

export type SupplyContractingBadgeText = {
  __typename?: "SupplyContractingBadgeText";
  badge: EgdsBadgeNotification;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingBreadCrumbMenu = {
  __typename?: "SupplyContractingBreadCrumbMenu";
  breadCrumbItems: Array<SupplyContractingLinkAction>;
};

export type SupplyContractingButton = {
  __typename?: "SupplyContractingButton";
  action?: Maybe<SupplyContractingButtonAction>;
  button: EgdsButton;
};

export type SupplyContractingButtonAction =
  | SupplyContractingAcceptTermsAndConditions
  | SupplyContractingNavigateToRatePlan
  | SupplyContractingNavigateToUrl
  | SupplyContractingOpenSheet;

export type SupplyContractingCard = {
  __typename?: "SupplyContractingCard";
  background?: Maybe<Scalars["String"]["output"]>;
  components?: Maybe<Array<SupplyContractingCardComponent>>;
};

export type SupplyContractingCardComponent = SupplyContractingProgramItem;

export type SupplyContractingCarousel = {
  __typename?: "SupplyContractingCarousel";
  cards: Array<SupplyContractingCarouselCard>;
};

export type SupplyContractingCarouselCard = {
  __typename?: "SupplyContractingCarouselCard";
  content: SupplyContractingCarouselCardContent;
  cta: SupplyContractingButton;
  date: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
  secondary: Scalars["String"]["output"];
};

export type SupplyContractingCarouselCardContent = SupplyContractingAgreementAddOnContent;

export type SupplyContractingClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyContractingClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickStreamContractingPartnerCommonData>;
};

export type SupplyContractingContent = {
  __typename?: "SupplyContractingContent";
  action?: Maybe<SupplyContractingTextAction>;
  alignment: SupplyContractingAlignment;
  text?: Maybe<SupplyContractingText>;
};

export type SupplyContractingEligibilityFeedback = {
  __typename?: "SupplyContractingEligibilityFeedback";
  alignment: SupplyContractingAlignment;
  graphic?: Maybe<UiGraphic>;
  primary: Scalars["String"]["output"];
  secondaries: Array<EgdsSpannableText>;
};

export type SupplyContractingEnrollmentMetadata = {
  __typename?: "SupplyContractingEnrollmentMetadata";
  offerIds: Array<Scalars["String"]["output"]>;
};

export type SupplyContractingExpandoAction = {
  __typename?: "SupplyContractingExpandoAction";
  action?: Maybe<SupplyContractingAction>;
  sheet?: Maybe<SupplyContractingSheet>;
};

export type SupplyContractingFrequentlyAskedQuestions = {
  __typename?: "SupplyContractingFrequentlyAskedQuestions";
  faqs: Array<SupplyContractExpando>;
  heading?: Maybe<SupplyContractingHeading>;
};

export type SupplyContractingGraphic = Icon | SupplyContractingBadgeText | SupplyContractingIllustration;

export type SupplyContractingGraphicSection = {
  __typename?: "SupplyContractingGraphicSection";
  graphic?: Maybe<SupplyContractingGraphic>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyContractingHeading = {
  __typename?: "SupplyContractingHeading";
  alignment?: Maybe<SupplyContractingAlignment>;
  heading: EgdsHeading;
};

export type SupplyContractingIconText = {
  __typename?: "SupplyContractingIconText";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingIllustration = {
  __typename?: "SupplyContractingIllustration";
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingInfoCard = {
  __typename?: "SupplyContractingInfoCard";
  contents?: Maybe<Array<SupplyContractingInfoCardContent>>;
  popovers?: Maybe<Array<SupplyContractingPopover>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyContractingInfoCardContent = EgdsBulletedList | EgdsSpannableList;

export type SupplyContractingLearnMoreSection = {
  __typename?: "SupplyContractingLearnMoreSection";
  content?: Maybe<SupplyContractingContent>;
};

export type SupplyContractingLicensePlate = {
  __typename?: "SupplyContractingLicensePlate";
  breadCrumbMenu?: Maybe<SupplyContractingBreadCrumbMenu>;
  feedback?: Maybe<EgdsStandardLink>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyContractingLinkAction = {
  __typename?: "SupplyContractingLinkAction";
  action: EgdsStandardLink;
  analytics?: Maybe<SupplyContractingClickStreamData>;
};

export type SupplyContractingMessages =
  | SupplyContractingEligibilityFeedback
  | SupplyContractingMessagingBanner
  | SupplyContractingMessagingCard
  | SupplyContractingNotificationBanner;

export type SupplyContractingMessagingBanner = {
  __typename?: "SupplyContractingMessagingBanner";
  contents?: Maybe<Array<SupplyContractingContent>>;
  graphic?: Maybe<UiGraphic>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingMessagingCard = {
  __typename?: "SupplyContractingMessagingCard";
  background?: Maybe<Scalars["String"]["output"]>;
  contents: Array<EgdsSpannableText>;
  graphic?: Maybe<UiGraphic>;
  links?: Maybe<Array<EgdsStandardLink>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingMutationResponse = {
  __typename?: "SupplyContractingMutationResponse";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sideEffects?: Maybe<Array<SupplyContractingSideEffects>>;
  status: SupplyContractingResponseStatus;
};

export type SupplyContractingNavigateToRatePlan = {
  __typename?: "SupplyContractingNavigateToRatePlan";
  url: Scalars["String"]["output"];
};

export type SupplyContractingNavigateToUrl = {
  __typename?: "SupplyContractingNavigateToURL";
  url: Scalars["String"]["output"];
};

export type SupplyContractingNotificationBanner = {
  __typename?: "SupplyContractingNotificationBanner";
  badge?: Maybe<EgdsStandardBadge>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingOpenSheet = {
  __typename?: "SupplyContractingOpenSheet";
  sheet?: Maybe<SupplyContractingSheet>;
  sheetId?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingOpenTermsSheet = {
  __typename?: "SupplyContractingOpenTermsSheet";
  sheetId: Scalars["String"]["output"];
};

export type SupplyContractingOptimizedDistributionComponent =
  | SupplyContractingTermsAcceptance
  | SupplyContractingWholesaleRatePlan;

export type SupplyContractingOptimizedDistributionTemplate = {
  __typename?: "SupplyContractingOptimizedDistributionTemplate";
  components?: Maybe<Array<SupplyContractingOptimizedDistributionComponent>>;
  header?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingPopover = {
  __typename?: "SupplyContractingPopover";
  id: Scalars["String"]["output"];
  text: EgdsParagraph;
};

export type SupplyContractingProgramComponent =
  | SupplyContractingProgramEnrollmentNavigator
  | SupplyContractingTabContainer
  | SupplyContractingTabs
  | SupplyContractingTermsAndConditionsSection
  | SupplyContractingTermsAndConditionsSheet;

export type SupplyContractingProgramEnrollment = {
  __typename?: "SupplyContractingProgramEnrollment";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  components?: Maybe<Array<SupplyContractingProgramComponent>>;
  cta?: Maybe<SupplyContractingButton>;
  enroll?: Maybe<SupplyContractingEnrollmentMetadata>;
  messages?: Maybe<SupplyContractingMessages>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyContractingProgramEnrollmentNavigator = {
  __typename?: "SupplyContractingProgramEnrollmentNavigator";
  contents: Array<SupplyContractingContent>;
  cta?: Maybe<SupplyContractingButton>;
  heading?: Maybe<SupplyContractingHeading>;
};

export type SupplyContractingProgramItem = {
  __typename?: "SupplyContractingProgramItem";
  contents?: Maybe<Array<SupplyContractingContent>>;
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  illustration?: Maybe<SupplyContractingIllustration>;
};

export type SupplyContractingResponseStatus = "FAIL" | "SUCCESS";

export type SupplyContractingSheet = {
  __typename?: "SupplyContractingSheet";
  closeText?: Maybe<Scalars["String"]["output"]>;
  contentTemplate?: Maybe<SupplyContractingSheetTemplate>;
  navType?: Maybe<SupplyContractingToolbarNavType>;
  select?: Maybe<EgdsBasicSelect>;
  sheet: EgdsSheet;
  title?: Maybe<Scalars["String"]["output"]>;
  toolTip?: Maybe<SupplyContractingToolTip>;
};

export type SupplyContractingSheetTemplate = SupplyContractingOptimizedDistributionTemplate | SupplyLegalTemplate;

export type SupplyContractingSideEffects = SupplyContractingProgramEnrollment;

export type SupplyContractingTabComponent =
  | SupplyContractingCard
  | SupplyContractingFrequentlyAskedQuestions
  | SupplyContractingLearnMoreSection
  | SupplyContractingTermsAndConditionsSection;

export type SupplyContractingTabContainer = {
  __typename?: "SupplyContractingTabContainer";
  components?: Maybe<Array<SupplyContractingTabComponent>>;
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  header?: Maybe<SupplyContractingHeading>;
  messageCard?: Maybe<SupplyContractingMessagingCard>;
  tabId: Scalars["String"]["output"];
};

export type SupplyContractingTabs = {
  __typename?: "SupplyContractingTabs";
  tabs: EgdsTabs;
};

export type SupplyContractingTermsAcceptance = {
  __typename?: "SupplyContractingTermsAcceptance";
  acceptTermsCta?: Maybe<SupplyContractingButton>;
  contents?: Maybe<Array<SupplyContractingContent>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  step?: Maybe<SupplyContractingIconText>;
  viewTermsContent?: Maybe<SupplyContractingContent>;
};

export type SupplyContractingTermsAndConditionsSection = {
  __typename?: "SupplyContractingTermsAndConditionsSection";
  content?: Maybe<SupplyContractingContent>;
};

export type SupplyContractingTermsAndConditionsSheet = {
  __typename?: "SupplyContractingTermsAndConditionsSheet";
  sheet: SupplyContractingSheet;
  sheetId: Scalars["String"]["output"];
};

export type SupplyContractingText = EgdsBulletedList | EgdsPlainText | EgdsSpannableText;

export type SupplyContractingTextAction = SupplyContractingOpenTermsSheet;

export type SupplyContractingToolTip = {
  __typename?: "SupplyContractingToolTip";
  content: Array<Scalars["String"]["output"]>;
  trigger: EgdsIconText;
};

export type SupplyContractingToolbarNavType = "BACK" | "CLOSE";

export type SupplyContractingWholesaleRatePlan = {
  __typename?: "SupplyContractingWholesaleRatePlan";
  contents?: Maybe<Array<SupplyContractingContent>>;
  messageCard?: Maybe<SupplyContractingMessagingCard>;
  primary?: Maybe<Scalars["String"]["output"]>;
  ratePlanCta?: Maybe<SupplyContractingButton>;
  step?: Maybe<SupplyContractingIconText>;
};

export type SupplyCountryCodeOption = {
  __typename?: "SupplyCountryCodeOption";
  label: Scalars["String"]["output"];
  masks: Array<Scalars["String"]["output"]>;
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  value: Scalars["String"]["output"];
};

export type SupplyCountryCodeSelection = {
  __typename?: "SupplyCountryCodeSelection";
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  options: Array<SupplyCountryCodeOption>;
};

export interface SupplyCurrentPageContext {
  path?: InputMaybe<Scalars["String"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  view?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyCustomPhoneNumberField = {
  __typename?: "SupplyCustomPhoneNumberField";
  countryCode: SupplyCountryCodeSelection;
  phoneNumber: EgdsTextInputField;
};

export type SupplyDashEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyDashEgClickstream";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partnerCommon?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyDashboardHeader = {
  __typename?: "SupplyDashboardHeader";
  backgroundImages: Array<Image>;
  currentSelectionName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated freezing response for old mobile client to not break */
  dashboardType?: Maybe<SupplyDashboardType>;
  dashboardTypeWithTemplating?: Maybe<SupplyDashboardType>;
  heroImagePresented?: Maybe<SupplyDashEgClickstream>;
  homeHeaderPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  lifeCycleStage?: Maybe<SupplyPartnerLifeCycleStage>;
  opportunity?: Maybe<SupplyDashboardOpportunityIcon>;
  premierHost?: Maybe<SupplyDashboardPremierHost>;
  primary?: Maybe<Scalars["String"]["output"]>;
  selectorAction?: Maybe<UiLinkAction>;
};

export type SupplyDashboardOpportunityIcon = {
  __typename?: "SupplyDashboardOpportunityIcon";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  iconToken: Scalars["String"]["output"];
  opportunityIconPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  opportunityIconSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyDashboardPremierHost = {
  __typename?: "SupplyDashboardPremierHost";
  graphic: UiGraphic;
  premierHostClicked?: Maybe<SupplyDashEgClickstream>;
  /** @deprecated this field is no longer used */
  premierHostIconClicked: SupplyDashEgClickstream;
  premierHostIconPresented?: Maybe<SupplyDashEgClickstream>;
  /** @deprecated this field is no longer used */
  premierHostPresented: SupplyDashEgClickstream;
  /** @deprecated this field is no longer used */
  primary: Scalars["String"]["output"];
  primaryText?: Maybe<Scalars["String"]["output"]>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyDashboardType = "DASHBOARD" | "LEGACY";

export type SupplyDeleteAccount = {
  __typename?: "SupplyDeleteAccount";
  deleteAccountButton?: Maybe<EgdsButton>;
  deleteAccountClosedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  deleteAccountSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  deleteAccountViewedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  doNotDeleteAccountButton?: Maybe<EgdsButton>;
  frictionAlert?: Maybe<SupplyDeleteAccountAlert>;
  sections?: Maybe<Array<SupplyDeleteAccountSection>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyDeleteAccountAlert = {
  __typename?: "SupplyDeleteAccountAlert";
  cancelButton?: Maybe<EgdsButton>;
  confirmButton?: Maybe<EgdsButton>;
  deleteAccountAlertCancelEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  deleteAccountAlertConfirmEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyDeleteAccountMutationResponse = {
  __typename?: "SupplyDeleteAccountMutationResponse";
  alert?: Maybe<SupplyDeleteAccountAlert>;
  deleteAccountResponseEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  isSuccess?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyDeleteAccountSection = {
  __typename?: "SupplyDeleteAccountSection";
  description?: Maybe<Scalars["String"]["output"]>;
  disclaimer?: Maybe<Scalars["String"]["output"]>;
  list?: Maybe<Array<Scalars["String"]["output"]>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyDismissibleCard {
  description: Array<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  key: Scalars["String"]["output"];
  preferenceGroup: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
}

export interface SupplyDismissibleCardInput {
  dismissDurationDays?: InputMaybe<Scalars["Int"]["input"]>;
  key: Scalars["String"]["input"];
  preferenceGroup: Scalars["String"]["input"];
}

export type SupplyDismissibleCardResponse = {
  __typename?: "SupplyDismissibleCardResponse";
  error?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type SupplyEmptyPropertiesSearchResult = {
  __typename?: "SupplyEmptyPropertiesSearchResult";
  primary: EgdsIconText;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyFeeFilterInput {
  includedFees?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type SupplyFeeInputField = {
  __typename?: "SupplyFeeInputField";
  amount: EgdsNumberInputField;
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  currencySymbol?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  productCode: Scalars["String"]["output"];
  ratesAvailable: Scalars["Boolean"]["output"];
  type: EgdsSelect;
};

export type SupplyFeeSignals = {
  __typename?: "SupplyFeeSignals";
  currency: SupplySubscribeSignalAction;
  feeLoad: SupplyPublishSignalAction;
  feeUpdate: SupplyPublishSignalAction;
  rateLoad: SupplySubscribeSignalAction;
  rateReady: SupplySubscribeSignalAction;
};

export type SupplyFees = {
  __typename?: "SupplyFees";
  fields: Array<SupplyFeeInputField>;
  signals?: Maybe<SupplyFeeSignals>;
  title?: Maybe<SupplyFeesTitle>;
};

export interface SupplyFeesInput {
  amount: Scalars["Float"]["input"];
  productCode: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}

export type SupplyFeesTitle = {
  __typename?: "SupplyFeesTitle";
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyGeneralTermsLink = {
  __typename?: "SupplyGeneralTermsLink";
  lastUpdated?: Maybe<Scalars["String"]["output"]>;
  linkText: Scalars["String"]["output"];
  sheet: SupplyGeneralTermsSheet;
};

export type SupplyGeneralTermsSheet = {
  __typename?: "SupplyGeneralTermsSheet";
  content: SupplyLegalTemplate;
  select?: Maybe<EgdsBasicSelect>;
  sheet: EgdsSheet;
  title?: Maybe<Scalars["String"]["output"]>;
  toolTip?: Maybe<SupplyGeneralTermsToolTip>;
};

export type SupplyGeneralTermsToolTip = {
  __typename?: "SupplyGeneralTermsToolTip";
  content: Array<Scalars["String"]["output"]>;
  trigger: EgdsIconText;
};

export type SupplyGuestItemContent = {
  __typename?: "SupplyGuestItemContent";
  primary: Scalars["String"]["output"];
  secondary: Scalars["String"]["output"];
};

export type SupplyHelpContactMethods = {
  __typename?: "SupplyHelpContactMethods";
  heading: Scalars["String"]["output"];
  methods: Array<SupplyContactMethod>;
  supportOptionsPresentedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyHelpContactUs = {
  __typename?: "SupplyHelpContactUs";
  contactMethods?: Maybe<SupplyHelpContactMethods>;
  contactUsModulePresentedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  supportArticles?: Maybe<SupplyHelpSupportArticles>;
  taxonomySelector?: Maybe<SupplyHelpTaxonomySelector>;
  title: Scalars["String"]["output"];
};

export type SupplyHelpSupportArticles = {
  __typename?: "SupplyHelpSupportArticles";
  articles: Array<EgdsStandardLink>;
  heading: Scalars["String"]["output"];
  helpArticleSelectedClickstreamPayloads?: Maybe<Array<Maybe<Scalars["AnalyticsPayload"]["output"]>>>;
};

export type SupplyHelpTaxonomy = {
  __typename?: "SupplyHelpTaxonomy";
  description: EgdsSpannableText;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  taxonomyId: Scalars["String"]["output"];
  taxonomyPresentedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  taxonomySelectedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyHelpTaxonomySelector = {
  __typename?: "SupplyHelpTaxonomySelector";
  description: EgdsSpannableText;
  heading: Scalars["String"]["output"];
  previousOptions?: Maybe<EgdsStandardLink>;
  previousOptionsSelectedClickstreamPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  taxonomies: Array<SupplyHelpTaxonomy>;
  travellerHelpLink?: Maybe<EgdsStandardLink>;
};

export type SupplyHomepage = {
  __typename?: "SupplyHomepage";
  layout?: Maybe<SupplyHomepageLayout>;
};

export type SupplyHomepageComponentMetadata = {
  __typename?: "SupplyHomepageComponentMetadata";
  id: Scalars["String"]["output"];
};

export type SupplyHomepageLayout = {
  __typename?: "SupplyHomepageLayout";
  components: Array<SupplyHomepageComponentMetadata>;
};

export type SupplyIndirectTax = {
  __typename?: "SupplyIndirectTax";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  buttons?: Maybe<SupplyIndirectTaxButtons>;
  taxDetails?: Maybe<Array<SupplyIndirectTaxDetail>>;
  taxDisabledDetail?: Maybe<SupplyIndirectTaxDisabledDetail>;
  title?: Maybe<SupplyIndirectTaxTitle>;
};

export type SupplyIndirectTaxButtons = {
  __typename?: "SupplyIndirectTaxButtons";
  backButton: EgdsButton;
  backButtonAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  nextButton: EgdsButton;
  nextButtonAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyIndirectTaxDetail = {
  __typename?: "SupplyIndirectTaxDetail";
  legalName?: Maybe<SupplyIndirectTaxLegalName>;
  registrationStatus?: Maybe<SupplyIndirectTaxRegistrationStatus>;
  taxId?: Maybe<SupplyIndirectTaxTaxId>;
  taxJurisdictionName: Scalars["String"]["output"];
  taxJurisdictionType: Scalars["String"]["output"];
  taxationCountry: Scalars["String"]["output"];
};

export type SupplyIndirectTaxDisabledDetail = {
  __typename?: "SupplyIndirectTaxDisabledDetail";
  description: Scalars["String"]["output"];
};

export type SupplyIndirectTaxExamplePopover = {
  __typename?: "SupplyIndirectTaxExamplePopover";
  exampleList: Array<EgdsSpannableText>;
  exampleTrigger: EgdsBasicTriggerPill;
};

export interface SupplyIndirectTaxInput {
  taxDetails: Array<SupplyIndirectTaxInputDetail>;
}

export interface SupplyIndirectTaxInputDetail {
  legalName: Scalars["String"]["input"];
  registrationStatus: Scalars["String"]["input"];
  taxId: Scalars["String"]["input"];
  taxJurisdictionName: Scalars["String"]["input"];
  taxJurisdictionType: Scalars["String"]["input"];
  taxationCountry: Scalars["String"]["input"];
}

export type SupplyIndirectTaxLegalName = {
  __typename?: "SupplyIndirectTaxLegalName";
  header: SupplyIndirectTaxTitle;
  legalName: EgdsTextInputField;
};

export type SupplyIndirectTaxNavigate = {
  __typename?: "SupplyIndirectTaxNavigate";
  relativePath: Scalars["String"]["output"];
};

export type SupplyIndirectTaxRegistrationStatus = {
  __typename?: "SupplyIndirectTaxRegistrationStatus";
  header: SupplyIndirectTaxTitle;
  options: EgdsBasicRadioGroup;
};

export type SupplyIndirectTaxTaxId = {
  __typename?: "SupplyIndirectTaxTaxId";
  examplePopover: SupplyIndirectTaxExamplePopover;
  header: SupplyIndirectTaxTitle;
  optionalHeader: SupplyIndirectTaxTitle;
  optionalTaxId: EgdsTextInputField;
  taxId: EgdsTextInputField;
};

export type SupplyIndirectTaxTitle = {
  __typename?: "SupplyIndirectTaxTitle";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
};

export type SupplyInformationPanel = {
  __typename?: "SupplyInformationPanel";
  expando?: Maybe<EgdsExpandoPeek>;
  icon: Icon;
  sections: Array<SupplyInformationPanelSection>;
};

export type SupplyInformationPanelSection = {
  __typename?: "SupplyInformationPanelSection";
  content: EgdsParagraph;
  heading: EgdsHeading;
};

export type SupplyInventoryReview = {
  __typename?: "SupplyInventoryReview";
  addedInventory: Scalars["Int"]["output"];
  currentInventory: Scalars["Int"]["output"];
  date: Date;
  newInventory: Scalars["Int"]["output"];
};

export type SupplyLegalTemplate = {
  __typename?: "SupplyLegalTemplate";
  /** @deprecated Deprecated in favor of htmls */
  html: Scalars["String"]["output"];
  htmls?: Maybe<Array<Scalars["String"]["output"]>>;
  plainText?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyLegalView = {
  __typename?: "SupplyLegalView";
  legalCards: Array<EgdsStandardMessagingCard>;
};

export type SupplyLocalizedPluralString = SupplyLocalizedString & {
  __typename?: "SupplyLocalizedPluralString";
  few?: Maybe<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  many?: Maybe<Scalars["String"]["output"]>;
  one?: Maybe<Scalars["String"]["output"]>;
  other?: Maybe<Scalars["String"]["output"]>;
  two?: Maybe<Scalars["String"]["output"]>;
  zero?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLocalizedSimpleString = SupplyLocalizedString & {
  __typename?: "SupplyLocalizedSimpleString";
  key: Scalars["String"]["output"];
  localization: Scalars["String"]["output"];
};

export interface SupplyLocalizedString {
  key: Scalars["String"]["output"];
}

export type SupplyLoyalty = {
  __typename?: "SupplyLoyalty";
  premierHost?: Maybe<SupplyLoyaltyPremierHost>;
  vip?: Maybe<SupplyLoyaltyVip>;
};

export type SupplyLoyaltyActionId =
  | "VIP_APPLICATION_REVIEW_SHEET"
  | "VIP_BENEFITS_SHEET"
  | "VIP_CONTACTS_SHEET"
  | "VIP_CONTRACT_SHEET"
  | "VIP_CONTRACT_VIEW_SHEET"
  | "VIP_MEMBERS_ONLY_DISCOUNT_SHEET"
  | "VIP_ONBOARDING_ACTIVATION_SUBMIT"
  | "VIP_PROGRAM_PROGRESS_SHEET"
  | "VIP_PROGRAM_SUBMIT"
  | "VIP_VALUE_ADD_SHEET";

export type SupplyLoyaltyBanner = {
  __typename?: "SupplyLoyaltyBanner";
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyBasicText = SupplyLoyaltyText & {
  __typename?: "SupplyLoyaltyBasicText";
  text: Scalars["String"]["output"];
};

export type SupplyLoyaltyButton = {
  __typename?: "SupplyLoyaltyButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId?: Maybe<SupplyLoyaltyActionId>;
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyCallToActionCard = {
  __typename?: "SupplyLoyaltyCallToActionCard";
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]["output"]>;
  variant?: Maybe<SupplyLoyaltyCallToActionCardVariants>;
};

export type SupplyLoyaltyCallToActionCardVariants = "PRIMARY" | "SECONDARY";

export type SupplyLoyaltyCard = {
  __typename?: "SupplyLoyaltyCard";
  description?: Maybe<Array<EgdsText>>;
  graphic?: Maybe<UiGraphic>;
  media?: Maybe<SupplyLoyaltyMedia>;
  messagingCardContent?: Maybe<Array<SupplyLoyaltyText>>;
  messagingCardGraphic?: Maybe<UiGraphic>;
  title?: Maybe<Scalars["String"]["output"]>;
  variant?: Maybe<SupplyLoyaltyCardVariants>;
};

export type SupplyLoyaltyCardVariants =
  | "VIP_ACCESS_BENEFIT"
  | "VIP_HOW_IT_WORKS_REQUIREMENT"
  | "VIP_MESSAGE_HORIZONTAL"
  | "VIP_TRAINING";

export type SupplyLoyaltyClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyLoyaltyClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyLoyaltyComponents =
  | SupplyLoyaltyCallToActionCard
  | SupplyLoyaltyCard
  | SupplyLoyaltyFaqCard
  | SupplyLoyaltyLinkCard
  | SupplyLoyaltyVipAccessBenefitsCard
  | SupplyLoyaltyVipCriteriaCard
  | SupplyLoyaltyVipHowItWorksCard
  | SupplyLoyaltyVipInternalReviewCard;

export type SupplyLoyaltyContact = {
  __typename?: "SupplyLoyaltyContact";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  countryName?: Maybe<Scalars["String"]["output"]>;
  countryPrefix?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyLoyaltyContactInput {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryNameAndPrefix?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyLoyaltyExpandoCard = {
  __typename?: "SupplyLoyaltyExpandoCard";
  collapseAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  collapsedLabel?: Maybe<Scalars["String"]["output"]>;
  expandAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  expandedLabel?: Maybe<Scalars["String"]["output"]>;
  expandedText?: Maybe<Array<SupplyLoyaltyText>>;
};

export type SupplyLoyaltyFaqCard = {
  __typename?: "SupplyLoyaltyFAQCard";
  questions?: Maybe<Array<SupplyLoyaltyExpandoCard>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyLicensePlate = {
  __typename?: "SupplyLoyaltyLicensePlate";
  actionMenu?: Maybe<Array<SupplyLoyaltyLinkAction>>;
  badge?: Maybe<EgdsStandardBadge>;
  badgeDescription?: Maybe<Scalars["String"]["output"]>;
  breadcrumbMenu?: Maybe<Array<SupplyLoyaltyLinkAction>>;
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type SupplyLoyaltyLinkAction = SupplyLoyaltyText & {
  __typename?: "SupplyLoyaltyLinkAction";
  actionId?: Maybe<SupplyLoyaltyActionId>;
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  target?: Maybe<UiLinkTarget>;
  text: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyLinkCard = {
  __typename?: "SupplyLoyaltyLinkCard";
  actionLink?: Maybe<SupplyLoyaltyLinkAction>;
};

export type SupplyLoyaltyMedia = {
  __typename?: "SupplyLoyaltyMedia";
  actionId?: Maybe<SupplyLoyaltyActionId>;
  actionUrl?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  media?: Maybe<Media>;
  target?: Maybe<UiLinkTarget>;
};

export interface SupplyLoyaltyMembersOnlyDiscountInput {
  blueTierDiscountValue?: InputMaybe<Scalars["String"]["input"]>;
  goldTierDiscountValue?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  silverTierDiscountValue?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyLoyaltyMutation = {
  __typename?: "SupplyLoyaltyMutation";
  vip?: Maybe<SupplyLoyaltyVipMutation>;
};

export type SupplyLoyaltyMutationResponse = {
  __typename?: "SupplyLoyaltyMutationResponse";
  sideEffects?: Maybe<Array<SupplyLoyaltySideEffects>>;
  status: SupplyLoyaltyResponseStatus;
};

export type SupplyLoyaltyNavigate = {
  __typename?: "SupplyLoyaltyNavigate";
  url: Scalars["String"]["output"];
};

export type SupplyLoyaltyNotification = {
  __typename?: "SupplyLoyaltyNotification";
  notification: SupplyLoyaltyBanner;
};

export type SupplyLoyaltyPopoverAction = SupplyLoyaltyText & {
  __typename?: "SupplyLoyaltyPopoverAction";
  content?: Maybe<Array<SupplyLoyaltyText>>;
  text: Scalars["String"]["output"];
};

export type SupplyLoyaltyPremierHost = {
  __typename?: "SupplyLoyaltyPremierHost";
  details?: Maybe<SupplyPremierHost>;
  download?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyPremierHostDownloadArgs = {
  type: SupplyPremierHostDownloadType;
};

export type SupplyLoyaltyResponseStatus = "FAIL" | "SUCCESS";

export type SupplyLoyaltySection = {
  __typename?: "SupplyLoyaltySection";
  components?: Maybe<Array<SupplyLoyaltyComponents>>;
  title?: Maybe<Scalars["String"]["output"]>;
  variant?: Maybe<SupplyLoyaltySectionVariant>;
};

export type SupplyLoyaltySectionVariant = "PRIMARY" | "SECONDARY";

export type SupplyLoyaltySelect = {
  __typename?: "SupplyLoyaltySelect";
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  select?: Maybe<EgdsBasicSelect>;
};

export type SupplyLoyaltySheetFooter = {
  __typename?: "SupplyLoyaltySheetFooter";
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltySideEffects = SupplyLoyaltyNavigate | SupplyLoyaltyNotification;

export type SupplyLoyaltySlimCard = {
  __typename?: "SupplyLoyaltySlimCard";
  /** @deprecated use actionLink.actionId instead */
  actionId?: Maybe<SupplyLoyaltyActionId>;
  actionLink?: Maybe<SupplyLoyaltyLinkAction>;
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltySpannableText = SupplyLoyaltyText & {
  __typename?: "SupplyLoyaltySpannableText";
  inlineContent?: Maybe<Array<SupplyLoyaltySpannableTextItem>>;
  text: Scalars["String"]["output"];
};

export type SupplyLoyaltySpannableTextItem =
  | SupplyLoyaltyBasicText
  | SupplyLoyaltyLinkAction
  | SupplyLoyaltyPopoverAction;

export interface SupplyLoyaltyText {
  text: Scalars["String"]["output"];
}

export type SupplyLoyaltyTextInput = {
  __typename?: "SupplyLoyaltyTextInput";
  analytics?: Maybe<SupplyLoyaltyClickStreamData>;
  input?: Maybe<EgdsTextInputField>;
};

export type SupplyLoyaltyToolbar = {
  __typename?: "SupplyLoyaltyToolbar";
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVip = {
  __typename?: "SupplyLoyaltyVIP";
  vipOnboardingActivation?: Maybe<SupplyLoyaltyVipOnboardingActivation>;
  vipProgram?: Maybe<SupplyLoyaltyVipProgram>;
};

export type SupplyLoyaltyVipVipOnboardingActivationArgs = {
  propertyContext: PropertyContext;
};

export type SupplyLoyaltyVipVipProgramArgs = {
  propertyContext: PropertyContext;
};

export type SupplyLoyaltyVipMutation = {
  __typename?: "SupplyLoyaltyVIPMutation";
  vipOnboarding?: Maybe<SupplyLoyaltyMutationResponse>;
  vipOnboardingActivation?: Maybe<SupplyLoyaltyMutationResponse>;
  vipProgramApproval?: Maybe<SupplyLoyaltyMutationResponse>;
};

export type SupplyLoyaltyVipMutationVipOnboardingArgs = {
  contacts?: InputMaybe<Array<SupplyLoyaltyContactInput>>;
  membersOnlyDiscount?: InputMaybe<SupplyLoyaltyMembersOnlyDiscountInput>;
  propertyContext: PropertyContext;
  valueAdds?: InputMaybe<Array<SupplyLoyaltyValueAddInput>>;
};

export type SupplyLoyaltyVipMutationVipOnboardingActivationArgs = {
  account: Scalars["String"]["input"];
  activationDate: DateInput;
  propertyContext: PropertyContext;
};

export type SupplyLoyaltyVipMutationVipProgramApprovalArgs = {
  contacts?: InputMaybe<Array<SupplyLoyaltyContactInput>>;
  creatingValueAdds?: InputMaybe<Array<SupplyLoyaltyValueAddInput>>;
  decision: SupplyLoyaltyVipProgramApprovalDecisionInput;
  decisionReason: Scalars["String"]["input"];
  deletingValueAdds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  propertyContext: PropertyContext;
  updatingValueAdds?: InputMaybe<Array<SupplyLoyaltyValueAddInput>>;
};

export interface SupplyLoyaltyValueAddInput {
  amenityId?: InputMaybe<Scalars["Int"]["input"]>;
  creditValue?: InputMaybe<Scalars["String"]["input"]>;
  discountValue?: InputMaybe<Scalars["String"]["input"]>;
  frequency: Scalars["String"]["input"];
  numberOfGuests?: InputMaybe<Scalars["String"]["input"]>;
  offerId: Scalars["String"]["input"];
  offerTypeId: Scalars["String"]["input"];
}

export type SupplyLoyaltyVipAccessBenefitsCard = {
  __typename?: "SupplyLoyaltyVipAccessBenefitsCard";
  benefits?: Maybe<Array<SupplyLoyaltyCard>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipAdditionalContact = {
  __typename?: "SupplyLoyaltyVipAdditionalContact";
  email?: Maybe<Array<Scalars["String"]["output"]>>;
  emailTitle?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<SupplyLoyaltySelect>;
};

export type SupplyLoyaltyVipApplicationReviewApproval = {
  __typename?: "SupplyLoyaltyVipApplicationReviewApproval";
  approveAction?: Maybe<SupplyLoyaltyButton>;
  approveAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  approveReason?: Maybe<SupplyLoyaltySelect>;
  approveTitle?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Array<EgdsText>>;
  rejectAction?: Maybe<SupplyLoyaltyButton>;
  rejectAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  rejectReason?: Maybe<SupplyLoyaltySelect>;
  rejectTitle?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipApplicationReviewBenefits = {
  __typename?: "SupplyLoyaltyVipApplicationReviewBenefits";
  description?: Maybe<Array<EgdsText>>;
  editAction?: Maybe<SupplyLoyaltyButton>;
  playbackImodDetails?: Maybe<Array<EgdsText>>;
  playbackImodName?: Maybe<Scalars["String"]["output"]>;
  playbackValueAddAverageBookingValue?: Maybe<EgdsText>;
  playbackValueAddsDetails?: Maybe<Array<SupplyLoyaltyVipPlaybackValueAddsDetails>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipApplicationReviewContacts = {
  __typename?: "SupplyLoyaltyVipApplicationReviewContacts";
  description?: Maybe<Array<EgdsText>>;
  editAction?: Maybe<SupplyLoyaltyButton>;
  primaryContactDetails?: Maybe<Array<EgdsText>>;
  primaryContactTitle?: Maybe<Scalars["String"]["output"]>;
  savedContacts?: Maybe<Array<SupplyLoyaltyContact>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipApplicationReviewSheet = {
  __typename?: "SupplyLoyaltyVipApplicationReviewSheet";
  approval?: Maybe<SupplyLoyaltyVipApplicationReviewApproval>;
  benefits?: Maybe<SupplyLoyaltyVipApplicationReviewBenefits>;
  contacts?: Maybe<SupplyLoyaltyVipApplicationReviewContacts>;
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipBenefitsSheet = {
  __typename?: "SupplyLoyaltyVipBenefitsSheet";
  benefits?: Maybe<Array<SupplyLoyaltySlimCard>>;
  description?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipContactsSheet = {
  __typename?: "SupplyLoyaltyVipContactsSheet";
  additionalContactDescription?: Maybe<Scalars["String"]["output"]>;
  additionalContactTitle?: Maybe<Scalars["String"]["output"]>;
  additionalContacts?: Maybe<Array<SupplyLoyaltyVipAdditionalContact>>;
  description?: Maybe<Scalars["String"]["output"]>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  notifications?: Maybe<Array<EgdsText>>;
  primaryContactCountryCode?: Maybe<SupplyLoyaltySelect>;
  primaryContactEmail?: Maybe<Array<Scalars["String"]["output"]>>;
  primaryContactName?: Maybe<SupplyLoyaltySelect>;
  primaryContactPhone?: Maybe<SupplyLoyaltyTextInput>;
  primaryContactSubTitle?: Maybe<Scalars["String"]["output"]>;
  primaryContactTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipContractSheet = {
  __typename?: "SupplyLoyaltyVipContractSheet";
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  legal?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipCriteriaCard = {
  __typename?: "SupplyLoyaltyVipCriteriaCard";
  criteria?: Maybe<Array<SupplyLoyaltyCard>>;
  description?: Maybe<Array<EgdsText>>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipHowItWorksCard = {
  __typename?: "SupplyLoyaltyVipHowItWorksCard";
  requirements?: Maybe<Array<SupplyLoyaltyCard>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipInternalReviewCard = {
  __typename?: "SupplyLoyaltyVipInternalReviewCard";
  description?: Maybe<Array<EgdsText>>;
  icons?: Maybe<Array<Icon>>;
  primaryAction?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipMembersOnlyDiscountSheet = {
  __typename?: "SupplyLoyaltyVipMembersOnlyDiscountSheet";
  datesDescription?: Maybe<Scalars["String"]["output"]>;
  datesTitle?: Maybe<Scalars["String"]["output"]>;
  discountDescription?: Maybe<Scalars["String"]["output"]>;
  discountInputs?: Maybe<Array<SupplyLoyaltyTextInput>>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  higherTierMoreThanLowerTierValidation?: Maybe<Scalars["String"]["output"]>;
  nameInput?: Maybe<SupplyLoyaltyTextInput>;
  nameInputDescription?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipOnboardingActivation = {
  __typename?: "SupplyLoyaltyVipOnboardingActivation";
  accountSelect?: Maybe<EgdsSelect>;
  accountSelectAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  accountSelectDescription?: Maybe<Scalars["String"]["output"]>;
  activateButton?: Maybe<SupplyLoyaltyButton>;
  activateButtonClickAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  activationDate?: Maybe<EgdsSingleDatePicker>;
  activationDateClickAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  activationDateTitle?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use notificationBanner instead */
  banner?: Maybe<UiBanner>;
  clickStreamPageView?: Maybe<SupplyLoyaltyClickStreamData>;
  createAccountLink?: Maybe<SupplyLoyaltyLinkAction>;
  createAccountLinkClickAnalytics?: Maybe<SupplyLoyaltyClickStreamData>;
  notificationBanner?: Maybe<SupplyLoyaltyBanner>;
  pageTitle?: Maybe<Scalars["String"]["output"]>;
  sideEffects?: Maybe<Array<SupplyLoyaltySideEffects>>;
  trainingSubtitles?: Maybe<Array<Scalars["String"]["output"]>>;
  trainingTitle?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipPlaybackValueAddsDetails = {
  __typename?: "SupplyLoyaltyVipPlaybackValueAddsDetails";
  amenityId?: Maybe<Scalars["Int"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  creditValue?: Maybe<Scalars["String"]["output"]>;
  frequency?: Maybe<Scalars["String"]["output"]>;
  numberOfGuests?: Maybe<Scalars["String"]["output"]>;
  offer?: Maybe<Scalars["String"]["output"]>;
  offerType?: Maybe<Scalars["String"]["output"]>;
  percentValue?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipProgram = {
  __typename?: "SupplyLoyaltyVipProgram";
  applicationReview?: Maybe<SupplyLoyaltyVipApplicationReviewSheet>;
  benefits?: Maybe<SupplyLoyaltyVipBenefitsSheet>;
  clickstreamPageView?: Maybe<SupplyLoyaltyClickStreamData>;
  contacts?: Maybe<SupplyLoyaltyVipContactsSheet>;
  contract?: Maybe<SupplyLoyaltyVipContractSheet>;
  contractView?: Maybe<SupplyLoyaltyVipContractSheet>;
  licensePlate?: Maybe<SupplyLoyaltyLicensePlate>;
  membersOnlyDiscount?: Maybe<SupplyLoyaltyVipMembersOnlyDiscountSheet>;
  programProgress?: Maybe<SupplyLoyaltyVipProgramProgressSheet>;
  sections?: Maybe<Array<SupplyLoyaltySection>>;
  sideEffects?: Maybe<Array<SupplyLoyaltySideEffects>>;
  valueAdd?: Maybe<SupplyLoyaltyVipValueAddSheet>;
};

export type SupplyLoyaltyVipProgramApprovalDecisionInput = "APPROVE" | "REJECT";

export type SupplyLoyaltyVipProgramProgressBenefits = {
  __typename?: "SupplyLoyaltyVipProgramProgressBenefits";
  commonElements?: Maybe<SupplyLoyaltyVipProgramProgressCommon>;
  editValueAddsButton?: Maybe<SupplyLoyaltyButton>;
  playbackImodDetails?: Maybe<Array<Scalars["String"]["output"]>>;
  playbackImodName?: Maybe<Scalars["String"]["output"]>;
  playbackImodSelected?: Maybe<Scalars["String"]["output"]>;
  playbackValueAddsDetails?: Maybe<SupplyLoyaltyVipPlaybackValueAddsDetails>;
  playbackValueAddsSelected?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipProgramProgressCommon = {
  __typename?: "SupplyLoyaltyVipProgramProgressCommon";
  content?: Maybe<Array<EgdsText>>;
  editButton?: Maybe<SupplyLoyaltyButton>;
  initialButton?: Maybe<SupplyLoyaltyButton>;
  step?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipProgramProgressContacts = {
  __typename?: "SupplyLoyaltyVipProgramProgressContacts";
  commonElements?: Maybe<SupplyLoyaltyVipProgramProgressCommon>;
};

export type SupplyLoyaltyVipProgramProgressContract = {
  __typename?: "SupplyLoyaltyVipProgramProgressContract";
  commonElements?: Maybe<SupplyLoyaltyVipProgramProgressCommon>;
};

export type SupplyLoyaltyVipProgramProgressSheet = {
  __typename?: "SupplyLoyaltyVipProgramProgressSheet";
  benefits?: Maybe<SupplyLoyaltyVipProgramProgressBenefits>;
  contacts?: Maybe<SupplyLoyaltyVipProgramProgressContacts>;
  contract?: Maybe<SupplyLoyaltyVipProgramProgressContract>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
};

export type SupplyLoyaltyVipValueAdd = {
  __typename?: "SupplyLoyaltyVipValueAdd";
  amenityId?: Maybe<Scalars["Int"]["output"]>;
  category?: Maybe<SupplyLoyaltySelect>;
  credit?: Maybe<SupplyLoyaltyTextInput>;
  discount?: Maybe<SupplyLoyaltyTextInput>;
  offers?: Maybe<Array<SupplyLoyaltyVipValueAddOffer>>;
  removeButton?: Maybe<SupplyLoyaltyButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipValueAddOffer = {
  __typename?: "SupplyLoyaltyVipValueAddOffer";
  categoryId?: Maybe<Scalars["String"]["output"]>;
  details?: Maybe<Array<SupplyLoyaltyVipValueAddOfferDetail>>;
  offer?: Maybe<SupplyLoyaltySelect>;
};

export type SupplyLoyaltyVipValueAddOfferDetail = {
  __typename?: "SupplyLoyaltyVipValueAddOfferDetail";
  offerFrequency?: Maybe<SupplyLoyaltySelect>;
  offerId?: Maybe<Scalars["String"]["output"]>;
  offerNumberOfGuests?: Maybe<Array<SupplyLoyaltyVipValueAddOfferNumberOfGuests>>;
  offerType?: Maybe<SupplyLoyaltySelect>;
};

export type SupplyLoyaltyVipValueAddOfferNumberOfGuests = {
  __typename?: "SupplyLoyaltyVipValueAddOfferNumberOfGuests";
  numberOfGuests?: Maybe<SupplyLoyaltySelect>;
  offerTypeId?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyLoyaltyVipValueAddSheet = {
  __typename?: "SupplyLoyaltyVipValueAddSheet";
  addValueAddButton?: Maybe<SupplyLoyaltyButton>;
  description?: Maybe<Array<EgdsText>>;
  footer?: Maybe<SupplyLoyaltySheetFooter>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyLoyaltyToolbar>;
  valueAdds?: Maybe<Array<SupplyLoyaltyVipValueAdd>>;
};

export type SupplyMarketMakerGrid = {
  __typename?: "SupplyMarketMakerGrid";
  competitorAverageRatesSection?: Maybe<SupplyMarketMakerGridCompetitorAverageRatesSection>;
  competitorRates?: Maybe<SupplyMarketMakerGridCompetitorRatesSection>;
  dates: Array<SupplyMarketMakerGridDate>;
  legend?: Maybe<SupplyMarketMakerGridLegend>;
  navigation?: Maybe<SupplyMarketMakerGridNavigation>;
  rates: SupplyMarketMakerGridRates;
};

export type SupplyMarketMakerGridCompetitorAverageRatesSection = {
  __typename?: "SupplyMarketMakerGridCompetitorAverageRatesSection";
  averageBookedRates?: Maybe<SupplyMarketMakerGridRates>;
  averageUnbookedRates?: Maybe<SupplyMarketMakerGridRates>;
  link?: Maybe<EgdsStandardLink>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketMakerGridCompetitorRatesSection = {
  __typename?: "SupplyMarketMakerGridCompetitorRatesSection";
  collapse?: Maybe<SupplyMarketMakerIconButton>;
  competitors: Array<SupplyMarketMakerGridRates>;
  expand?: Maybe<SupplyMarketMakerIconButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketMakerGridDate = {
  __typename?: "SupplyMarketMakerGridDate";
  monthDay?: Maybe<Scalars["String"]["output"]>;
  weekDay?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketMakerGridLegend = {
  __typename?: "SupplyMarketMakerGridLegend";
  items: Array<SupplyMarketMakerGridLegendItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketMakerGridLegendItem = {
  __typename?: "SupplyMarketMakerGridLegendItem";
  icon: Icon;
  label: Scalars["String"]["output"];
};

export type SupplyMarketMakerGridNavigation = {
  __typename?: "SupplyMarketMakerGridNavigation";
  dateLabel?: Maybe<Scalars["String"]["output"]>;
  next?: Maybe<SupplyMarketMakerIconButton>;
  previous?: Maybe<SupplyMarketMakerIconButton>;
};

export type SupplyMarketMakerGridRate = {
  __typename?: "SupplyMarketMakerGridRate";
  icon?: Maybe<Icon>;
  rate?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketMakerGridRates = {
  __typename?: "SupplyMarketMakerGridRates";
  heading: EgdsStylizedText;
  subheading?: Maybe<EgdsStylizedText>;
  values: Array<SupplyMarketMakerGridRate>;
};

export type SupplyMarketMakerIconButton = {
  __typename?: "SupplyMarketMakerIconButton";
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type SupplyMarketplaceFeed = {
  __typename?: "SupplyMarketplaceFeed";
  feedItems: Array<SupplyMarketplaceFeedItem>;
  marketingFeedAdditionalCardsAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  modulePresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketplaceFeedAction =
  | EgdsDismissAction
  | SupplyMarketplaceFeedActionItem
  | SupplyMarketplaceFeedDismissActionItem;

export interface SupplyMarketplaceFeedActionInput {
  actionType: Scalars["String"]["input"];
  actionUrl: Scalars["String"]["input"];
  iconType: Scalars["String"]["input"];
  label: Scalars["String"]["input"];
}

export type SupplyMarketplaceFeedActionItem = {
  __typename?: "SupplyMarketplaceFeedActionItem";
  actionType?: Maybe<Scalars["String"]["output"]>;
  actionUrl?: Maybe<Scalars["String"]["output"]>;
  iconType?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketplaceFeedDefaultItem = SupplyMarketplaceFeedItem & {
  __typename?: "SupplyMarketplaceFeedDefaultItem";
  body: Scalars["String"]["output"];
  contextMenuActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contextMenuClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contextMenuSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  feedItemId?: Maybe<Scalars["String"]["output"]>;
  feedItemType?: Maybe<Scalars["String"]["output"]>;
  iconType?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  marketingFeedCardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primaryAction?: Maybe<SupplyMarketplaceFeedAction>;
  primaryActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  secondaryActions: Array<SupplyMarketplaceFeedAction>;
  status?: Maybe<MarketingFeedCardStatus>;
  title: Scalars["String"]["output"];
};

export type SupplyMarketplaceFeedDismiss = {
  __typename?: "SupplyMarketplaceFeedDismiss";
  feedItemId?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMarketplaceFeedDismissActionItem = {
  __typename?: "SupplyMarketplaceFeedDismissActionItem";
  actionLabel: Scalars["String"]["output"];
  marketplaceFeedDismissActionSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyMarketplaceFeedEmptyItem = SupplyMarketplaceFeedItem & {
  __typename?: "SupplyMarketplaceFeedEmptyItem";
  body: Scalars["String"]["output"];
  contextMenuActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contextMenuClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contextMenuSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  feedItemId?: Maybe<Scalars["String"]["output"]>;
  feedItemType?: Maybe<Scalars["String"]["output"]>;
  iconType?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  marketingFeedCardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primaryAction?: Maybe<SupplyMarketplaceFeedAction>;
  primaryActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  status?: Maybe<MarketingFeedCardStatus>;
  title: Scalars["String"]["output"];
};

export interface SupplyMarketplaceFeedInput {
  feedItemActionType: Scalars["String"]["input"];
  feedItemId: Scalars["String"]["input"];
}

export interface SupplyMarketplaceFeedItem {
  body: Scalars["String"]["output"];
  contextMenuActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contextMenuClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contextMenuSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  feedItemId?: Maybe<Scalars["String"]["output"]>;
  feedItemType?: Maybe<Scalars["String"]["output"]>;
  iconType?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  marketingFeedCardPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primaryAction?: Maybe<SupplyMarketplaceFeedAction>;
  primaryActionSelectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  status?: Maybe<MarketingFeedCardStatus>;
  title: Scalars["String"]["output"];
}

export interface SupplyMarketplaceFeedItemInput {
  body?: InputMaybe<Scalars["String"]["input"]>;
  feedItemId: Scalars["String"]["input"];
  feedItemType?: InputMaybe<Scalars["String"]["input"]>;
  iconType: Scalars["String"]["input"];
  imageUrl: Scalars["String"]["input"];
  primaryAction: SupplyMarketplaceFeedActionInput;
  segmentations?: InputMaybe<SupplyMarketplaceFeedSegmentationsInput>;
  status?: InputMaybe<MarketingFeedCardStatus>;
  timeToComplete: Scalars["Int"]["input"];
  title: Scalars["String"]["input"];
}

export type SupplyMarketplaceFeedItemUpdateResponse = {
  __typename?: "SupplyMarketplaceFeedItemUpdateResponse";
  feedItem?: Maybe<SupplyMarketplaceFeedItem>;
};

export interface SupplyMarketplaceFeedSegmentationsInput {
  cityList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  countryList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  localeList: Array<Scalars["String"]["input"]>;
  partnerTypeList: Array<Scalars["String"]["input"]>;
  propertyIdList: Array<Scalars["String"]["input"]>;
  stateList?: InputMaybe<Array<Scalars["String"]["input"]>>;
  userIdList?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type SupplyMoreInfoFactor = {
  __typename?: "SupplyMoreInfoFactor";
  content: SupplyMoreInfoFactorContent;
  description: Scalars["String"]["output"];
  trigger: SupplyMoreInfoFactorTrigger;
};

export type SupplyMoreInfoFactorContent = {
  __typename?: "SupplyMoreInfoFactorContent";
  text: Scalars["String"]["output"];
};

export type SupplyMoreInfoFactorTrigger = {
  __typename?: "SupplyMoreInfoFactorTrigger";
  text: EgdsIconText;
};

export type SupplyMultiFactorAuthSelectionView = {
  __typename?: "SupplyMultiFactorAuthSelectionView";
  generateCode: EgdsButton;
  phoneNumberSelection: SupplyPhoneNumberSelectionView;
  verificationTypeView: SupplyPhoneNumberVerifyTypeView;
};

export type SupplyMultiFactorAuthenticationExperience =
  | SupplyMultiFactorLoadErrorView
  | SupplyMultiFactorResultView
  | SupplyMultiFactorSelectionView
  | SupplyMultiFactorVerificationView;

export interface SupplyMultiFactorCodeGenerationInput {
  challengeTicket?: InputMaybe<Scalars["String"]["input"]>;
  countryCode: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  verifyMethod: MfaVerificationMethod;
}

export interface SupplyMultiFactorCodeVerificationInput {
  challengeTicket: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  countryCode: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  verifyMethod: MfaVerificationMethod;
}

export type SupplyMultiFactorLoadErrorView = {
  __typename?: "SupplyMultiFactorLoadErrorView";
  heading: Scalars["String"]["output"];
  serviceError: Scalars["String"]["output"];
};

export type SupplyMultiFactorResult = EgdsStandardMessagingCard | UiBanner;

export type SupplyMultiFactorResultView = {
  __typename?: "SupplyMultiFactorResultView";
  clickstreamSucceeded?: Maybe<TwoFactorAuthEgClickstreamData>;
  heading: Scalars["String"]["output"];
  resultView: SupplyMultiFactorResult;
};

export type SupplyMultiFactorSelectionView = {
  __typename?: "SupplyMultiFactorSelectionView";
  clickstreamPresented?: Maybe<TwoFactorAuthEgClickstreamData>;
  heading: Scalars["String"]["output"];
  legal: SupplyLegalView;
  selectionView: SupplyMultiFactorAuthSelectionView;
  serviceError?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyMultiFactorVerificationView = {
  __typename?: "SupplyMultiFactorVerificationView";
  clickstreamFailure?: Maybe<TwoFactorAuthEgClickstreamData>;
  heading: Scalars["String"]["output"];
  legal: SupplyLegalView;
  serviceError?: Maybe<Scalars["String"]["output"]>;
  verificationView: SupplyPhoneNumberVerificationView;
};

export type SupplyNavigation = {
  __typename?: "SupplyNavigation";
  /** @deprecated Temporarily adding this field so the type doesnt get pruned. */
  deprecateOldMenuSearch?: Maybe<SupplyNavigationMenuSearch>;
  header: SupplyNavigationHeader;
  sidebar: SupplyNavigationSidebar;
};

export type SupplyNavigationAction =
  | SupplyNavigationActionOpenMenu
  | SupplyNavigationLinkWithAnalytics
  | SupplyPublishSignalAction;

export type SupplyNavigationActionItem = {
  __typename?: "SupplyNavigationActionItem";
  link: UiLinkAction;
  text: Scalars["String"]["output"];
};

export type SupplyNavigationActionOpenMenu = {
  __typename?: "SupplyNavigationActionOpenMenu";
  menu: SupplyNavigationHeaderMenu;
};

export interface SupplyNavigationContext {
  fallbackUrls?: InputMaybe<SupplyNavigationContextFallbackUrls>;
  showSidebarTrigger: Scalars["Boolean"]["input"];
  temporaryPartnerContextOverride?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export interface SupplyNavigationContextFallbackUrls {
  helpAndSupportUrl?: InputMaybe<Scalars["String"]["input"]>;
  signInUrl?: InputMaybe<Scalars["String"]["input"]>;
  termsOfUseUrl?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyNavigationDestination = {
  __typename?: "SupplyNavigationDestination";
  analyticsName?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  navigationDestinationPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navigationDestinationSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  target: Scalars["String"]["output"];
};

export type SupplyNavigationDestinations = {
  __typename?: "SupplyNavigationDestinations";
  additionalLinksModulePresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  links: Array<SupplyNavigationDestination>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyNavigationEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyNavigationEgClickstream";
  additionalContext?: Maybe<EgAdditionalContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  mapProductDetail: EgPropertyCount;
  /** @deprecated Removing to be used in another clickstream type */
  notification?: Maybe<EgNotificationAttributes>;
  typeahead?: Maybe<EgTypeAHead>;
};

export type SupplyNavigationHeader = {
  __typename?: "SupplyNavigationHeader";
  accountTrigger: SupplyNavigationHeaderButton;
  logo: SupplyNavigationHeaderLogo;
  /** @deprecated Use AnalyticsPayload instead */
  navigationPresented: SupplyNavigationEgClickstream;
  navigationPresentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  notificationsTrigger?: Maybe<SupplyNavigationHeaderButton>;
  propertyDetailsTrigger?: Maybe<SupplyNavigationHeaderButton>;
  propertyFilterTrigger?: Maybe<SupplyNavigationHeaderButton>;
  searchTrigger?: Maybe<SupplyNavigationHeaderButton>;
  selectPropertyTrigger?: Maybe<SupplyNavigationHeaderButton>;
  sidebarTrigger?: Maybe<SupplyNavigationHeaderButton>;
};

export type SupplyNavigationHeaderButton = {
  __typename?: "SupplyNavigationHeaderButton";
  accessibility: Scalars["String"]["output"];
  action: SupplyNavigationAction;
  buttonIcon: SupplyNavigationHeaderButtonIcon;
  /** @deprecated Use AnalyticsPayload instead */
  headerMenuClosedEvent?: Maybe<SupplyNavigationEgClickstream>;
  headerMenuClosedEventV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navigationSideOpened?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyNavigationHeaderButtonIcon = {
  __typename?: "SupplyNavigationHeaderButtonIcon";
  badge?: Maybe<EgdsBadgeNotification>;
  icon: Icon;
  inverse: Scalars["Boolean"]["output"];
};

export type SupplyNavigationHeaderLogo = {
  __typename?: "SupplyNavigationHeaderLogo";
  action: UiLinkAction;
  graphic: UiGraphic;
  /** @deprecated Use AnalyticsPayload instead */
  headerLogoSelected: SupplyNavigationEgClickstream;
  headerLogoSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyNavigationHeaderMenu = {
  __typename?: "SupplyNavigationHeaderMenu";
  content: SupplyNavigationMenuContentDetails;
  header?: Maybe<SupplyNavigationMenuContentHeader>;
};

export type SupplyNavigationHeaderMenuType =
  | "NOTIFICATIONS"
  | "PORTFOLIO_PROPERTY_FILTER"
  | "PROPERTY_DETAILS"
  | "PROPERTY_FILTER"
  | "SEARCH";

export type SupplyNavigationHeaderSearchTrigger = UiAction & {
  __typename?: "SupplyNavigationHeaderSearchTrigger";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyNavigationIconText = {
  __typename?: "SupplyNavigationIconText";
  iconText: EgdsIconText;
  /** @deprecated Use AnalyticsPayload instead */
  presented: SupplyNavigationEgClickstream;
  presentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyNavigationItem = SupplyNavigationItemLink | SupplyNavigationItemMenu;

export type SupplyNavigationItemLink = {
  __typename?: "SupplyNavigationItemLink";
  active: Scalars["Boolean"]["output"];
  activeNavigationItemIds: Array<Scalars["String"]["output"]>;
  badge?: Maybe<EgdsStandardBadge>;
  badgeType?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  link: UiLinkAction;
  /** @deprecated Use AnalyticsPayload instead */
  menuSelected: SupplyNavigationEgClickstream;
  menuSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navSideItemPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SupplyNavigationItemLinkBadge = {
  __typename?: "SupplyNavigationItemLinkBadge";
  badge: EgdsStandardBadge;
};

export type SupplyNavigationItemMenu = {
  __typename?: "SupplyNavigationItemMenu";
  active: Scalars["Boolean"]["output"];
  badge?: Maybe<EgdsStandardBadge>;
  badgeType?: Maybe<Scalars["String"]["output"]>;
  children: Array<SupplyNavigationItemLink>;
  icon?: Maybe<Icon>;
  menuClosed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  menuItemLink?: Maybe<UiLinkAction>;
  menuOpened?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Use AnalyticsPayload instead */
  menuPresented: SupplyNavigationEgClickstream;
  menuPresentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Use AnalyticsPayload instead */
  menuSelected: SupplyNavigationEgClickstream;
  /** @deprecated Use menu opened and menu closed instead */
  menuSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navSideItemPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SupplyNavigationLazyMenuContent = {
  __typename?: "SupplyNavigationLazyMenuContent";
  menuType: SupplyNavigationHeaderMenuType;
};

export type SupplyNavigationLinkWithAnalytics = {
  __typename?: "SupplyNavigationLinkWithAnalytics";
  httpUri: Scalars["String"]["output"];
  selected: Scalars["AnalyticsPayload"]["output"];
};

export type SupplyNavigationMenuContent = {
  __typename?: "SupplyNavigationMenuContent";
  badge?: Maybe<EgdsStandardBadge>;
  /** @deprecated Use AnalyticsPayload instead */
  menuContentPresented: SupplyNavigationEgClickstream;
  menuContentPresentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sections: Array<SupplyNavigationMenuContentSection>;
};

export type SupplyNavigationMenuContentDetails = SupplyNavigationLazyMenuContent | SupplyNavigationMenuContent;

export type SupplyNavigationMenuContentHeader = {
  __typename?: "SupplyNavigationMenuContentHeader";
  borderSeparator: Scalars["Boolean"]["output"];
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type SupplyNavigationMenuContentListItemAction = {
  __typename?: "SupplyNavigationMenuContentListItemAction";
  action: UiLinkAction;
  borderSeparator: Scalars["Boolean"]["output"];
  /** @deprecated Use AnalyticsPayload instead */
  menuContentSectionItemSelected: SupplyNavigationEgClickstream;
  menuContentSectionItemSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primary: SupplyNavigationMenuContentListItemActionPrimary;
  secondary?: Maybe<SupplyNavigationMenuContentListItemActionSecondary>;
};

export type SupplyNavigationMenuContentListItemActionPrimary = EgdsPlainText | EgdsStylizedText;

export type SupplyNavigationMenuContentListItemActionSecondary = EgdsPlainText | EgdsStylizedText | Icon;

export type SupplyNavigationMenuContentSection =
  | SupplyNavigationMenuContentSectionWithListItemActions
  | SupplyNavigationMenuContentSectionWithSubSections
  | SupplyNavigationNotificationMenuContentSection;

export type SupplyNavigationMenuContentSectionWithListItemActions = {
  __typename?: "SupplyNavigationMenuContentSectionWithListItemActions";
  actions: Array<SupplyNavigationMenuContentListItemAction>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyNavigationMenuContentSectionWithSubSections = {
  __typename?: "SupplyNavigationMenuContentSectionWithSubSections";
  subSections: Array<SupplyNavigationMenuContentSubSection>;
};

export type SupplyNavigationMenuContentSubSection = {
  __typename?: "SupplyNavigationMenuContentSubSection";
  actions: Array<SupplyNavigationMenuContentListItemAction>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyNavigationMenuSearch = {
  __typename?: "SupplyNavigationMenuSearch";
  /** @deprecated This field is no longer used */
  content: SupplyNavigationMenuSearchContent;
  /** @deprecated This field is no longer used */
  trigger: SupplyNavigationHeaderSearchTrigger;
};

export type SupplyNavigationMenuSearchContent =
  | SupplyNavigationMenuSearchEmptyContent
  | SupplyNavigationMenuSearchEmptyResponseContent
  | SupplyNavigationMenuSearchSuccessContent;

export type SupplyNavigationMenuSearchEmptyContent = {
  __typename?: "SupplyNavigationMenuSearchEmptyContent";
  /** @deprecated This field is no longer used */
  helpAction: SupplyNavigationActionItem;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyNavigationMenuSearchEmptyResponseContent = {
  __typename?: "SupplyNavigationMenuSearchEmptyResponseContent";
  /** @deprecated This field is no longer used */
  helpAction: SupplyNavigationActionItem;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]["output"]>;
  viewAll?: Maybe<SupplyNavigationActionItem>;
};

export type SupplyNavigationMenuSearchResultItems = {
  __typename?: "SupplyNavigationMenuSearchResultItems";
  link: UiLinkAction;
  reservationId: Scalars["String"]["output"];
  stayDates: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyNavigationMenuSearchSuccessContent = {
  __typename?: "SupplyNavigationMenuSearchSuccessContent";
  helpAction: SupplyNavigationActionItem;
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]["output"]>;
  results: Array<SupplyNavigationMenuSearchResultItems>;
  seeMore?: Maybe<SupplyNavigationActionItem>;
};

export interface SupplyNavigationNotificationActivityInput {
  activityType: Scalars["String"]["input"];
  channel: Scalars["String"]["input"];
  notificationIds: Array<Scalars["String"]["input"]>;
}

export type SupplyNavigationNotificationActivityNotificationIds = {
  __typename?: "SupplyNavigationNotificationActivityNotificationIds";
  notificationIds: Array<Scalars["String"]["output"]>;
};

export type SupplyNavigationNotificationContent = EgdsIconText | SupplyNavigationIconText | SupplyNavigationMenuContent;

export type SupplyNavigationNotificationMenuContentSection = {
  __typename?: "SupplyNavigationNotificationMenuContentSection";
  expando?: Maybe<EgdsExpandoPeek>;
  expandoAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  notificationItems: Array<SupplyNavigationNotificationMenuListItem>;
};

export type SupplyNavigationNotificationMenuListItem = {
  __typename?: "SupplyNavigationNotificationMenuListItem";
  action?: Maybe<UiLinkAction>;
  badge: EgdsBadgeNotification;
  notificationId: Scalars["String"]["output"];
  /** @deprecated Use AnalyticsPayload instead */
  notificationSelected: SupplyNavigationEgClickstream;
  notificationSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primary: SupplyNavigationMenuContentListItemActionPrimary;
  secondary: SupplyNavigationMenuContentListItemActionSecondary;
};

export type SupplyNavigationReservationAction = {
  __typename?: "SupplyNavigationReservationAction";
  action: UiLinkAction;
  navigationReservationActionSelected: Scalars["AnalyticsPayload"]["output"];
  primary: Scalars["String"]["output"];
};

export type SupplyNavigationReservationResults = {
  __typename?: "SupplyNavigationReservationResults";
  action?: Maybe<EgdsButton>;
  /** @deprecated Replaced by searchItems */
  items: Array<SupplyNavigationReservationSearchItem>;
  message?: Maybe<EgdsIconText>;
  searchItems: Array<SupplyNavigationReservationSearchItemV2>;
  secondaryAction?: Maybe<SupplyNavigationReservationAction>;
};

export type SupplyNavigationReservationSearchContent = {
  __typename?: "SupplyNavigationReservationSearchContent";
  action: SupplyNavigationReservationAction;
  navigationSearchCleared?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navigationSearchInputted?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navigationSearchPresented: Scalars["AnalyticsPayload"]["output"];
  navigationSearchSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  results: SupplyNavigationReservationResults;
  searchInput: EgdsTextInputField;
};

export type SupplyNavigationReservationSearchContentResultsArgs = {
  keyword?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyNavigationReservationSearchItem = {
  __typename?: "SupplyNavigationReservationSearchItem";
  action: UiLinkAction;
  primary: Scalars["String"]["output"];
  secondary: Scalars["String"]["output"];
  tertiary: Scalars["String"]["output"];
};

export type SupplyNavigationReservationSearchItemV2 = {
  __typename?: "SupplyNavigationReservationSearchItemV2";
  action: UiLinkAction;
  navigationReservationSearchItemSelected: Scalars["AnalyticsPayload"]["output"];
  primary: EgdsSpannableText;
  secondary: EgdsSpannableText;
  tertiary: EgdsSpannableText;
};

export type SupplyNavigationSidebar = {
  __typename?: "SupplyNavigationSidebar";
  footer: SupplyNavigationSidebarFooter;
  items: Array<SupplyNavigationItem>;
  /** @deprecated Use AnalyticsPayload instead */
  navigationSidePresented: SupplyNavigationEgClickstream;
  navigationSidePresentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyNavigationSidebarFooter = {
  __typename?: "SupplyNavigationSidebarFooter";
  copyright: Scalars["String"]["output"];
  legalLinks: Array<SupplyNavigationSidebarFooterLink>;
};

export type SupplyNavigationSidebarFooterLink = {
  __typename?: "SupplyNavigationSidebarFooterLink";
  footerLinkAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  icon?: Maybe<Icon>;
  link: UiLinkAction;
  text: Scalars["String"]["output"];
};

export type SupplyNavigationUserImpersonationBanner = {
  __typename?: "SupplyNavigationUserImpersonationBanner";
  icon: Icon;
  link: EgdsStandardLink;
  primary: Scalars["String"]["output"];
  secondary: Scalars["String"]["output"];
};

export interface SupplyNewListingOfferEnrollmentInput {
  enrolled: Scalars["Boolean"]["input"];
}

export type SupplyNightlyPaymentDetailsSection = {
  __typename?: "SupplyNightlyPaymentDetailsSection";
  label: EgdsStylizedText;
  nightlyPaymentLineItems: Array<SupplyPaymentDetailsLineItem>;
};

export type SupplyOnboardingAction = {
  __typename?: "SupplyOnboardingAction";
  buttonType: SupplyOnboardingButtonType;
  currentStep: Scalars["String"]["output"];
};

export type SupplyOnboardingActionResource = {
  __typename?: "SupplyOnboardingActionResource";
  relativePath: Scalars["String"]["output"];
};

export type SupplyOnboardingAttestation = {
  __typename?: "SupplyOnboardingAttestation";
  analytics?: Maybe<SupplyOnboardingAttestationAnalytics>;
  attestationCheckbox: EgdsCheckBox;
  heading?: Maybe<Scalars["String"]["output"]>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyOnboardingAttestationAnalytics = {
  __typename?: "SupplyOnboardingAttestationAnalytics";
  checked?: Maybe<SupplyOnboardingAttestationAnalyticsData>;
  unchecked?: Maybe<SupplyOnboardingAttestationAnalyticsData>;
};

export type SupplyOnboardingAttestationAnalyticsData = {
  __typename?: "SupplyOnboardingAttestationAnalyticsData";
  additional_context?: Maybe<EgClickstreamPartnerAdditionalContextData>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  form?: Maybe<EgClickstreamPartnerFormData>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingAvailability = {
  __typename?: "SupplyOnboardingAvailability";
  availabilityCards: Array<SupplyOnboardingAvailabilityCard>;
  dialog?: Maybe<SupplyOnboardingAvailabilityConfirmation>;
  saveAction?: Maybe<SupplyPublishSignalAction>;
  selected?: Maybe<SupplyOnboardingAvailabilityType>;
  validAction?: Maybe<SupplyPublishSignalAction>;
};

export type SupplyOnboardingAvailabilityButton = {
  __typename?: "SupplyOnboardingAvailabilityButton";
  button: EgdsButton;
  selectedAnalytics: SupplyOnboardingAvailabilityClickstreamData;
};

export type SupplyOnboardingAvailabilityCard = {
  __typename?: "SupplyOnboardingAvailabilityCard";
  accessibilityLabel: Scalars["String"]["output"];
  availabilityType: SupplyOnboardingAvailabilityType;
  description: Scalars["String"]["output"];
  illustration: Illustration;
  selectedAnalytics: SupplyOnboardingAvailabilityClickstreamData;
  title: Scalars["String"]["output"];
};

export type SupplyOnboardingAvailabilityClickstreamData = {
  __typename?: "SupplyOnboardingAvailabilityClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingAvailabilityConfirmation = {
  __typename?: "SupplyOnboardingAvailabilityConfirmation";
  accessibilityLabel: Scalars["String"]["output"];
  cancelButton: SupplyOnboardingAvailabilityButton;
  confirmButton: SupplyOnboardingAvailabilityButton;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyOnboardingAvailabilityType = "BLOCK" | "OPEN";

export type SupplyOnboardingAvailabilityUpdate = {
  __typename?: "SupplyOnboardingAvailabilityUpdate";
  success: SupplyOnboardingAvailabilityType;
};

export type SupplyOnboardingBackButton = {
  __typename?: "SupplyOnboardingBackButton";
  action?: Maybe<UiLinkAction>;
  analytics: SupplyOnboardingPublishListingClickstreamData;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyOnboardingBusinessInfoEventAnalyticsData = {
  __typename?: "SupplyOnboardingBusinessInfoEventAnalyticsData";
  additional_context?: Maybe<EgClickstreamPartnerAdditionalContextData>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  form?: Maybe<EgClickstreamPartnerFormData>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingButton = {
  __typename?: "SupplyOnboardingButton";
  action: SupplyOnboardingAction;
  analytics: SupplyOnboardingNavigationClickstreamData;
  button: SupplyOnboardingNavigationButton;
  disabledAction?: Maybe<SupplySubscribeSignalAction>;
  hideAction?: Maybe<SupplySubscribeSignalAction>;
  saveAction?: Maybe<SupplySubscribeSignalAction>;
};

export type SupplyOnboardingButtonType = "BACK" | "DONE" | "LINK" | "NEXT" | "SKIP";

export type SupplyOnboardingFloatingButton = {
  __typename?: "SupplyOnboardingFloatingButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyOnboardingHostTypeResult = {
  __typename?: "SupplyOnboardingHostTypeResult";
  hostType: Scalars["String"]["output"];
};

export type SupplyOnboardingIconStylizedText = {
  __typename?: "SupplyOnboardingIconStylizedText";
  icon: Icon;
  stylizedText: EgdsStylizedText;
  subText?: Maybe<EgdsText>;
};

export type SupplyOnboardingIconStylizedTextList = {
  __typename?: "SupplyOnboardingIconStylizedTextList";
  listItems: Array<SupplyOnboardingIconStylizedText>;
};

export type SupplyOnboardingNavigationButton =
  | SupplyOnboardingFloatingButton
  | UiPrimaryButton
  | UiSecondaryButton
  | UiTertiaryButton;

export type SupplyOnboardingNavigationClickstreamData = {
  __typename?: "SupplyOnboardingNavigationClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingNavigationHeader = {
  __typename?: "SupplyOnboardingNavigationHeader";
  logo: SupplyOnboardingNavigationHeaderLogo;
  saveIndicator?: Maybe<SupplyOnboardingSaveIndicator>;
};

export type SupplyOnboardingNavigationHeaderLogo = {
  __typename?: "SupplyOnboardingNavigationHeaderLogo";
  action: UiLinkAction;
  headerLogoSelected: EgClickstreamData;
  imgSrc: Image;
};

export type SupplyOnboardingPageTitle = {
  __typename?: "SupplyOnboardingPageTitle";
  description?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export interface SupplyOnboardingPartnerAttestationInput {
  attestation: Scalars["Boolean"]["input"];
}

export type SupplyOnboardingPartnerAttestationResult = {
  __typename?: "SupplyOnboardingPartnerAttestationResult";
  attestation: Scalars["Boolean"]["output"];
};

export type SupplyOnboardingPartnerBillingAddress = {
  __typename?: "SupplyOnboardingPartnerBillingAddress";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  postalCode: Scalars["String"]["output"];
  stateProvince: Scalars["String"]["output"];
  streetAddress: Scalars["String"]["output"];
  unit?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyOnboardingPartnerBillingAddressInput {
  city: Scalars["String"]["input"];
  country: Scalars["String"]["input"];
  isAddingAddress: Scalars["Boolean"]["input"];
  postalCode: Scalars["String"]["input"];
  stateProvince: Scalars["String"]["input"];
  streetAddress: Scalars["String"]["input"];
  unit?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyOnboardingPartnerBillingAddressSection = {
  __typename?: "SupplyOnboardingPartnerBillingAddressSection";
  address: SupplyPartnerBillingAddress;
  analytics: SupplyPartnerInfoAnalytics;
  country: SupplyPartnerBillingCountry;
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  sameAsPropertyAddress?: Maybe<SupplyPartnerBillingAddressSameAsProperty>;
};

export interface SupplyOnboardingPartnerBusinessNameInput {
  businessName: Scalars["String"]["input"];
}

export type SupplyOnboardingPartnerBusinessNameResult = {
  __typename?: "SupplyOnboardingPartnerBusinessNameResult";
  businessName: Scalars["String"]["output"];
};

export type SupplyOnboardingPartnerBusinessNameSection = {
  __typename?: "SupplyOnboardingPartnerBusinessNameSection";
  analytics: SupplyPartnerInfoAnalytics;
  businessName: EgdsTextInputField;
  heading: Scalars["String"]["output"];
};

export type SupplyOnboardingPartnerError = {
  __typename?: "SupplyOnboardingPartnerError";
  heading: Scalars["String"]["output"];
  icon: Icon;
  message: Scalars["String"]["output"];
};

export type SupplyOnboardingPartnerHostTypeSection = {
  __typename?: "SupplyOnboardingPartnerHostTypeSection";
  analytics?: Maybe<SupplyPartnerInfoAnalytics>;
  heading: Scalars["String"]["output"];
  hostTypes: EgdsBasicRadioGroup;
};

export interface SupplyOnboardingPartnerLegalNameInput {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
}

export type SupplyOnboardingPartnerLegalNameResult = {
  __typename?: "SupplyOnboardingPartnerLegalNameResult";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type SupplyOnboardingPartnerLegalNameSection = {
  __typename?: "SupplyOnboardingPartnerLegalNameSection";
  analytics: SupplyPartnerInfoAnalytics;
  firstName: EgdsTextInputField;
  heading: Scalars["String"]["output"];
  lastName: EgdsTextInputField;
  sameAsAccountHolder: SupplyPartnerLegalNameSameAsAccountHolder;
};

export type SupplyOnboardingPartnerName = {
  __typename?: "SupplyOnboardingPartnerName";
  firstName: Scalars["String"]["output"];
  lastName: Scalars["String"]["output"];
};

export type SupplyOnboardingPaymentTermsAndConditionsView = {
  __typename?: "SupplyOnboardingPaymentTermsAndConditionsView";
  acceptanceCheckbox: EgdsBasicCheckBox;
  clickstreamAcceptanceChecked?: Maybe<EgClickstreamData>;
  clickstreamAcceptanceUnchecked?: Maybe<EgClickstreamData>;
  heading: Scalars["String"]["output"];
};

export type SupplyOnboardingPropertyRatesExperience = {
  __typename?: "SupplyOnboardingPropertyRatesExperience";
  baseRateAutomationView?: Maybe<SupplyPropertyBaseRateAutomationSignUpView>;
  currencyView: SupplyPropertyRatesBankingCountryCurrencyView;
  errorView?: Maybe<SupplyPropertyRatesErrorView>;
  heading: Scalars["String"]["output"];
  offerView: SupplyPropertyRatesOfferView;
  pageDescription?: Maybe<Scalars["String"]["output"]>;
  rateView: SupplyPropertyRatesNightlyBaseRateView;
};

export type SupplyOnboardingPublishListing = {
  __typename?: "SupplyOnboardingPublishListing";
  accessibilityLabel: Scalars["String"]["output"];
  clickstreamAnalytics?: Maybe<SupplyOnboardingPublishListingClickstreamData>;
  contentList?: Maybe<Array<EgdsSpannableText>>;
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  illustration?: Maybe<Illustration>;
  publishButton?: Maybe<SupplyOnboardingPublishListingButton>;
  saveErrorBanner?: Maybe<UiBanner>;
  supplyOnboardingBackButton?: Maybe<SupplyOnboardingBackButton>;
};

export type SupplyOnboardingPublishListingAction = {
  __typename?: "SupplyOnboardingPublishListingAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: SupplyOnboardingPublishListingClickstreamData;
  event?: Maybe<SupplyOnboardingPublishListingSubmissionEvent>;
  signal?: Maybe<SupplyPublishSignalAction>;
};

export type SupplyOnboardingPublishListingButton = {
  __typename?: "SupplyOnboardingPublishListingButton";
  action: SupplyOnboardingPublishListingButtonAction;
  primary: Scalars["String"]["output"];
};

export type SupplyOnboardingPublishListingButtonAction = SupplyOnboardingPublishListingAction | UiLinkAction;

export type SupplyOnboardingPublishListingClickstreamData = IEgClickstreamData & {
  __typename?: "SupplyOnboardingPublishListingClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
  product_list?: Maybe<Array<EgClickstreamProductListData>>;
  virtual_market_coach?: Maybe<EgClickstreamVirtualMarketCoachData>;
};

export type SupplyOnboardingPublishListingResult = {
  __typename?: "SupplyOnboardingPublishListingResult";
  submitConfirmation?: Maybe<SupplyOnboardingPublishListing>;
  success: Scalars["Boolean"]["output"];
};

export type SupplyOnboardingPublishListingSubmissionEvent = {
  __typename?: "SupplyOnboardingPublishListingSubmissionEvent";
  bidTarget: Scalars["String"]["output"];
  bidTargetUuid: Scalars["String"]["output"];
  eventName: Scalars["String"]["output"];
  newSupplier: Scalars["Boolean"]["output"];
};

export type SupplyOnboardingRatesEgClickstreamActivityProduct = {
  __typename?: "SupplyOnboardingRatesEGClickstreamActivityProduct";
  pricing?: Maybe<SupplyOnboardingRatesEgClickstreamActivityProductPricing>;
};

export type SupplyOnboardingRatesEgClickstreamActivityProductPricing = {
  __typename?: "SupplyOnboardingRatesEGClickstreamActivityProductPricing";
  banking_country?: Maybe<Scalars["String"]["output"]>;
  payment_currency?: Maybe<Scalars["String"]["output"]>;
  price_base_amount?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyOnboardingRatesEgClickstreamProductList = {
  __typename?: "SupplyOnboardingRatesEGClickstreamProductList";
  activity_product?: Maybe<SupplyOnboardingRatesEgClickstreamActivityProduct>;
};

export type SupplyOnboardingRatesEgClickstreamAdditionalContext = {
  __typename?: "SupplyOnboardingRatesEgClickstreamAdditionalContext";
  user_interface?: Maybe<SupplyOnboardingRatesEgClickstreamUserInterface>;
};

export type SupplyOnboardingRatesEgClickstreamUserInterface = {
  __typename?: "SupplyOnboardingRatesEgClickstreamUserInterface";
  component_badge_name?: Maybe<Scalars["String"]["output"]>;
  component_element_text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyOnboardingReadiness = {
  __typename?: "SupplyOnboardingReadiness";
  hideAction?: Maybe<SupplySubscribeSignalAction>;
  stepReviewList: StepReviewList;
};

export type SupplyOnboardingReviewError = {
  __typename?: "SupplyOnboardingReviewError";
  errorDetailList?: Maybe<Array<EgdsText>>;
  errorText: EgdsText;
  icon: Icon;
  text?: Maybe<EgdsText>;
};

export type SupplyOnboardingReviewExperience = {
  __typename?: "SupplyOnboardingReviewExperience";
  attestation?: Maybe<SupplyOnboardingAttestation>;
  banner?: Maybe<UiBanner>;
  clickstreamPageView?: Maybe<EgClickstreamData>;
  informationPanelV1?: Maybe<SupplyInformationPanel>;
  sections: Array<SupplyOnboardingReviewSection>;
  signal?: Maybe<SupplyPublishSignalAction>;
};

export type SupplyOnboardingReviewPhotoList = {
  __typename?: "SupplyOnboardingReviewPhotoList";
  imageList: Array<Image>;
  scrimEdit?: Maybe<SupplyReviewEditLink>;
  scrimIcon: Icon;
};

export type SupplyOnboardingReviewSection = SupplyPlaybackSection | SupplyPlaybackSectionList;

export type SupplyOnboardingSaveIndicator = {
  __typename?: "SupplyOnboardingSaveIndicator";
  popoverText?: Maybe<Scalars["String"]["output"]>;
  savedText: Scalars["String"]["output"];
  savingText: Scalars["String"]["output"];
};

export type SupplyOnboardingTipCard = {
  __typename?: "SupplyOnboardingTipCard";
  icon: Icon;
  sections: Array<SupplyTipCardSection>;
};

export type SupplyOnboardingTipCardId = "AMENITIES" | "DESCRIPTION" | "HEADLINE" | "PHOTOS" | "REGULATORY";

export type SupplyOnboardingTipContent = EgdsParagraph | EgdsSpannableText;

export type SupplyOnboardingTooltip = {
  __typename?: "SupplyOnboardingTooltip";
  icon?: Maybe<Icon>;
  moreInfoText: Scalars["String"]["output"];
  text: EgdsSpannableText;
};

export type SupplyOnboardingWelcome = {
  __typename?: "SupplyOnboardingWelcome";
  content: SupplyOnboardingWelcomeSectionContent;
  informationPanel: SupplyOnboardingWelcomeInformationPanel;
  primaryButton: SupplyOnboardingWelcomeButton;
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyOnboardingWelcomeButton = {
  __typename?: "SupplyOnboardingWelcomeButton";
  action: SupplyOnboardingWelcomeButtonAction;
  button: EgdsButton;
};

export type SupplyOnboardingWelcomeButtonAction = {
  __typename?: "SupplyOnboardingWelcomeButtonAction";
  analytics: SupplyOnboardingWelcomeClickstreamData;
};

export type SupplyOnboardingWelcomeClickstreamData = {
  __typename?: "SupplyOnboardingWelcomeClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyOnboardingWelcomeInformationPanel = {
  __typename?: "SupplyOnboardingWelcomeInformationPanel";
  content: Scalars["String"]["output"];
  iconV1?: Maybe<Icon>;
};

export type SupplyOnboardingWelcomeNavigationUpdate = {
  __typename?: "SupplyOnboardingWelcomeNavigationUpdate";
  relativePath: Scalars["String"]["output"];
};

export type SupplyOnboardingWelcomeSectionContent = {
  __typename?: "SupplyOnboardingWelcomeSectionContent";
  content: EgdsBulletedList;
  title: Scalars["String"]["output"];
};

export type SupplyOnboardingWorkFlowNavigation = {
  __typename?: "SupplyOnboardingWorkFlowNavigation";
  backButton?: Maybe<SupplyOnboardingButton>;
  currentStep: Scalars["String"]["output"];
  groups: Array<SupplyOnboardingWorkFlowNavigationGroup>;
  nextButton?: Maybe<SupplyOnboardingButton>;
  skipButton?: Maybe<SupplyOnboardingButton>;
  skippable: Scalars["Boolean"]["output"];
};

export type SupplyOnboardingWorkFlowNavigationGroup = {
  __typename?: "SupplyOnboardingWorkFlowNavigationGroup";
  identifier: Scalars["String"]["output"];
  label: EgdsStylizedText;
  smallLabel?: Maybe<EgdsStylizedText>;
  steps: Array<EgdsProgressBar>;
};

export type SupplyOnboardingWorkFlowNavigationUpdate = {
  __typename?: "SupplyOnboardingWorkFlowNavigationUpdate";
  resource?: Maybe<SupplyOnboardingActionResource>;
};

export type SupplyOpportunityHubLicensePlate = {
  __typename?: "SupplyOpportunityHubLicensePlate";
  description: Scalars["String"]["output"];
  /** @deprecated Feedback link is optional. Use feedbackLinkV2 instead. */
  feedbackLink: EgdsStandardLink;
  feedbackLinkV2?: Maybe<EgdsStandardLink>;
  title: Scalars["String"]["output"];
};

export type SupplyPcRoomManagementPageHeader = {
  __typename?: "SupplyPCRoomManagementPageHeader";
  breadcrumbs?: Maybe<SupplyProductCreationBreadCrumbs>;
  feedback?: Maybe<SupplyProductCreationText>;
  header: SupplyProductCreationSectionHeader;
};

export type SupplyPcRoomManagementWorkflow = {
  __typename?: "SupplyPCRoomManagementWorkflow";
  header?: Maybe<SupplyPcRoomManagementPageHeader>;
  navigator?: Maybe<SupplyPcRoomManagementWorkflowNavigator>;
};

export type SupplyPcRoomManagementWorkflowHeaderArgs = {
  context: ContextInput;
  params: SupplyPcRoomManagementWorkflowInput;
  propertyContext: PropertyContext;
};

export type SupplyPcRoomManagementWorkflowNavigatorArgs = {
  context: ContextInput;
  params: SupplyPcRoomManagementWorkflowInput;
  propertyContext: PropertyContext;
};

export interface SupplyPcRoomManagementWorkflowInput {
  copyRoomTypeId?: InputMaybe<Scalars["String"]["input"]>;
  isAddPath?: InputMaybe<Scalars["Boolean"]["input"]>;
  pageName: SupplyPcRoomManagementWorkflowPageName;
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
  workflowType: SupplyPcRoomManagementWorkflowType;
}

export type SupplyPcRoomManagementWorkflowNavigator = {
  __typename?: "SupplyPCRoomManagementWorkflowNavigator";
  backButton?: Maybe<SupplyProductCreationButton>;
  leaveWithoutSavingDialog?: Maybe<SupplyProductCreationLeaveWithoutSavingDialog>;
  nextButton?: Maybe<SupplyProductCreationButton>;
};

export type SupplyPcRoomManagementWorkflowPageName = "COPY_RATE_PLAN" | "RATE_PLAN" | "ROOM_INFO" | "ROOM_NAME";

export type SupplyPcRoomManagementWorkflowType = "ADD" | "COPY" | "EDIT";

export type SupplyPageLevelBanner = {
  __typename?: "SupplyPageLevelBanner";
  banners: Array<SupplyPageNotificationBanner>;
  cards: Array<SupplyPageLevelNotificationBannerCard>;
  presented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyPageLevelBannerCardLink = {
  __typename?: "SupplyPageLevelBannerCardLink";
  actioned: Scalars["AnalyticsPayload"]["output"];
  link: UiLinkAction;
  text: Scalars["String"]["output"];
};

export type SupplyPageLevelNotificationBannerCard = {
  __typename?: "SupplyPageLevelNotificationBannerCard";
  closed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  links: Array<SupplyPageLevelBannerCardLink>;
  presented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyPageNotificationBanner = {
  __typename?: "SupplyPageNotificationBanner";
  closed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: Scalars["String"]["output"];
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  links: Array<SupplyPageLevelBannerCardLink>;
  presented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPartnerAddressProvince = EgdsBasicSelect | EgdsTextInputField;

export type SupplyPartnerBillingAddress = {
  __typename?: "SupplyPartnerBillingAddress";
  city: EgdsTextInputField;
  input: Array<SupplyPartnerBillingAddressInput>;
  postalCode: EgdsTextInputField;
  state: SupplyPartnerAddressProvince;
  streetAddress: EgdsTextInputField;
  unit: EgdsTextInputField;
};

export type SupplyPartnerBillingAddressInput = EgdsBasicSelect | EgdsTextInputField;

export type SupplyPartnerBillingAddressSameAsProperty = {
  __typename?: "SupplyPartnerBillingAddressSameAsProperty";
  analytics: SupplyOnboardingBusinessInfoEventAnalyticsData;
  flagSelector?: Maybe<EgdsCheckBox>;
};

export type SupplyPartnerBillingCountry = {
  __typename?: "SupplyPartnerBillingCountry";
  analytics: SupplyOnboardingBusinessInfoEventAnalyticsData;
  countrySelector: EgdsBasicSelect;
};

export type SupplyPartnerCard = {
  __typename?: "SupplyPartnerCard";
  actions: Array<SupplyPartnerTrackedAction>;
  id: Scalars["String"]["output"];
  onSelected?: Maybe<SupplyPartnerTrackedAction>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPartnerCardDetail = {
  __typename?: "SupplyPartnerCardDetail";
  actions: Array<SupplyPartnerTrackedAction>;
  clipboardCopyMessage?: Maybe<Scalars["String"]["output"]>;
  copyContentButton?: Maybe<SupplyPartnerTrackedAction>;
  listTitle?: Maybe<Scalars["String"]["output"]>;
  paginatedListItems?: Maybe<SupplyPartnerPaginatedListItems>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPartnerCardDetailPaginatedListItemsArgs = {
  context: ContextInput;
  id: Scalars["String"]["input"];
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyPartnerInfoAnalytics = {
  __typename?: "SupplyPartnerInfoAnalytics";
  failed: SupplyOnboardingBusinessInfoEventAnalyticsData;
  selected: SupplyOnboardingBusinessInfoEventAnalyticsData;
  submitted: SupplyOnboardingBusinessInfoEventAnalyticsData;
  succeeded: SupplyOnboardingBusinessInfoEventAnalyticsData;
};

export type SupplyPartnerInformation = {
  __typename?: "SupplyPartnerInformation";
  attestation?: Maybe<SupplyOnboardingAttestation>;
  billingAddress: SupplyOnboardingPartnerBillingAddressSection;
  businessName?: Maybe<SupplyOnboardingPartnerBusinessNameSection>;
  error?: Maybe<SupplyOnboardingPartnerError>;
  heading: Scalars["String"]["output"];
  hostType: SupplyOnboardingPartnerHostTypeSection;
  legalName: SupplyOnboardingPartnerLegalNameSection;
  partnerName: SupplyOnboardingPartnerName;
  propertyAddressForBilling: SupplyOnboardingPartnerBillingAddress;
};

export type SupplyPartnerLegalNameSameAsAccountHolder = {
  __typename?: "SupplyPartnerLegalNameSameAsAccountHolder";
  analytics: SupplyOnboardingBusinessInfoEventAnalyticsData;
  flagSelector: EgdsCheckBox;
};

export type SupplyPartnerLifeCycleStage =
  | "LIFE_CYCLE_STAGE_ALL_GROWN_UP"
  | "LIFE_CYCLE_STAGE_BABY"
  | "LIFE_CYCLE_STAGE_KID"
  | "LIFE_CYCLE_STAGE_TEENAGER"
  | "LIFE_CYCLE_STAGE_YOUNG_ADULT"
  | "UNRECOGNIZED";

export type SupplyPartnerPaginatedListItem = {
  __typename?: "SupplyPartnerPaginatedListItem";
  badge?: Maybe<EgdsStandardBadge>;
  trackedAction: SupplyPartnerTrackedAction;
};

export type SupplyPartnerPaginatedListItems = {
  __typename?: "SupplyPartnerPaginatedListItems";
  /** @deprecated Deprecated. Use listItems instead. */
  items: Array<SupplyPartnerTrackedAction>;
  listItems: Array<SupplyPartnerPaginatedListItem>;
  nextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyPartnerPayoutDeferredAccountInput {
  bankingCountry: Scalars["String"]["input"];
  currency: Scalars["String"]["input"];
  currencyV1?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyPartnerSelectResponse = {
  __typename?: "SupplyPartnerSelectResponse";
  cardDetail?: Maybe<SupplyPartnerCardDetail>;
  header: Scalars["String"]["output"];
  headerActions: Array<SupplyPartnerTrackedAction>;
  summary?: Maybe<SupplyPartnerSummary>;
};

export type SupplyPartnerSelectResponseCardDetailArgs = {
  context: ContextInput;
  id: Scalars["String"]["input"];
};

export type SupplyPartnerSelectResponseSummaryArgs = {
  context: ContextInput;
  nextPageToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyPartnerSignUpButton = {
  __typename?: "SupplyPartnerSignUpButton";
  eventSubmitted?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  label: Scalars["String"]["output"];
};

export type SupplyPartnerSignUpEstimateHeading =
  | SupplyPartnerSignUpEstimateHeadingAmount
  | SupplyPartnerSignUpEstimateHeadingSubtitle
  | SupplyPartnerSignUpEstimateHeadingTitle;

export type SupplyPartnerSignUpEstimateHeadingAmount = {
  __typename?: "SupplyPartnerSignUpEstimateHeadingAmount";
  icon: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyPartnerSignUpEstimateHeadingSubtitle = {
  __typename?: "SupplyPartnerSignUpEstimateHeadingSubtitle";
  text: Scalars["String"]["output"];
};

export type SupplyPartnerSignUpEstimateHeadingTitle = {
  __typename?: "SupplyPartnerSignUpEstimateHeadingTitle";
  text: Scalars["String"]["output"];
};

export type SupplyPartnerSignUpEstimateResults = {
  __typename?: "SupplyPartnerSignUpEstimateResults";
  callToActionDescription: Scalars["String"]["output"];
  callToActionTitle: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  estimatePageViewed: Scalars["AnalyticsPayload"]["output"];
  heading: Array<SupplyPartnerSignUpEstimateHeading>;
  nextButton: SupplyPartnerSignUpButton;
};

export interface SupplyPartnerSignUpEstimateResultsInput {
  bathroomNum: Scalars["Float"]["input"];
  bedroomNum: Scalars["Int"]["input"];
  lat?: InputMaybe<Scalars["Float"]["input"]>;
  lng?: InputMaybe<Scalars["Float"]["input"]>;
}

export type SupplyPartnerSummary = {
  __typename?: "SupplyPartnerSummary";
  banner?: Maybe<UiBanner>;
  bottomAction?: Maybe<SupplyPartnerTrackedAction>;
  cards: Array<SupplyPartnerCard>;
  cardsNextPageToken?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPartnerTrackedAction = {
  __typename?: "SupplyPartnerTrackedAction";
  button?: Maybe<UiPrimaryButton>;
  eventName?: Maybe<Scalars["String"]["output"]>;
  eventVersion?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  iconLinkAction?: Maybe<Uri>;
  link?: Maybe<UiLinkAction>;
  payload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyPaymentDetails = {
  __typename?: "SupplyPaymentDetails";
  detailedPaymentLineItems: SupplyPaymentDisplayDetailed;
  summaryPaymentLineItems: Array<SupplyPaymentDetailsLineItem>;
};

export type SupplyPaymentDetailsAmountLineItem = {
  __typename?: "SupplyPaymentDetailsAmountLineItem";
  amount: EgdsStylizedText;
  icon?: Maybe<Icon>;
};

export type SupplyPaymentDetailsDisclaimerLineItem = {
  __typename?: "SupplyPaymentDetailsDisclaimerLineItem";
  lineItemText: EgdsStylizedText;
  link?: Maybe<EgdsStandardLink>;
};

export type SupplyPaymentDetailsExpandoComponentLineItems = {
  __typename?: "SupplyPaymentDetailsExpandoComponentLineItems";
  expando: SupplyPaymentDetailsExpandoLink;
  nightlyPaymentList: Array<SupplyNightlyPaymentDetailsSection>;
  nightlyPaymentTitle?: Maybe<SupplyPaymentDetailsTitleLineItem>;
};

export type SupplyPaymentDetailsExpandoLineItem = ISupplyPaymentLineItem &
  ISupplyPaymentMoreInfo & {
    __typename?: "SupplyPaymentDetailsExpandoLineItem";
    amount: SupplyPaymentDetailsAmountLineItem;
    expando: SupplyPaymentDetailsExpandoLink;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    subLineItems: Array<SupplyPaymentDetailsSubLineItem>;
    tooltip?: Maybe<SupplyPaymentTooltip>;
  };

export type SupplyPaymentDetailsExpandoLink = EgdsExpando & {
  __typename?: "SupplyPaymentDetailsExpandoLink";
  alignment?: Maybe<SupplyPaymentDetailsExpandoPositioning>;
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedLabel: Scalars["String"]["output"];
};

export type SupplyPaymentDetailsExpandoPositioning = "LEADING" | "TRAILING";

export type SupplyPaymentDetailsLineItem =
  | SupplyPaymentDetailsDisclaimerLineItem
  | SupplyPaymentDetailsExpandoLineItem
  | SupplyPaymentDetailsSheetToggleLink
  | SupplyPaymentDetailsSubLineItem
  | SupplyPaymentDetailsTitleLineItem
  | SupplyPaymentDetailsTotalLineItem;

export type SupplyPaymentDetailsSheetComponentLineItems = {
  __typename?: "SupplyPaymentDetailsSheetComponentLineItems";
  childLineItems: Array<SupplyPaymentDetailsLineItem>;
  sheet: SupplyPaymentDisplaySidePanelProps;
};

export type SupplyPaymentDetailsSheetToggleLink = {
  __typename?: "SupplyPaymentDetailsSheetToggleLink";
  lineItemText: EgdsStylizedText;
  link: EgdsStandardLink;
};

export type SupplyPaymentDetailsSubLineItem = ISupplyPaymentLineItem &
  ISupplyPaymentMoreInfo & {
    __typename?: "SupplyPaymentDetailsSubLineItem";
    amount: SupplyPaymentDetailsAmountLineItem;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyPaymentTooltip>;
  };

export type SupplyPaymentDetailsTitleLineItem = ISupplyPaymentMoreInfo & {
  __typename?: "SupplyPaymentDetailsTitleLineItem";
  badge?: Maybe<EgdsStandardBadge>;
  footerNoteText?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  tooltip?: Maybe<SupplyPaymentTooltip>;
};

export type SupplyPaymentDetailsTotalLineItem = ISupplyPaymentLineItem &
  ISupplyPaymentMoreInfo & {
    __typename?: "SupplyPaymentDetailsTotalLineItem";
    amount: SupplyPaymentDetailsAmountLineItem;
    badge?: Maybe<EgdsStandardBadge>;
    borderSeparator: Scalars["Boolean"]["output"];
    currencyCode?: Maybe<EgdsStylizedText>;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyPaymentTooltip>;
  };

export type SupplyPaymentDisplayDetailed =
  | SupplyPaymentDetailsExpandoComponentLineItems
  | SupplyPaymentDetailsSheetComponentLineItems;

export type SupplyPaymentDisplaySidePanelProps = {
  __typename?: "SupplyPaymentDisplaySidePanelProps";
  navType?: Maybe<SupplyPaymentDisplayToolbarIcon>;
  toolbarTitle?: Maybe<Scalars["String"]["output"]>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyPaymentDisplayToolbarIcon = "BACK" | "CLOSE";

export type SupplyPaymentTooltip = {
  __typename?: "SupplyPaymentTooltip";
  contents?: Maybe<Array<SupplyPaymentTooltipContent>>;
  icon: Icon;
};

export type SupplyPaymentTooltipContent = {
  __typename?: "SupplyPaymentTooltipContent";
  tooltipHeader?: Maybe<EgdsStylizedText>;
  tooltipLink?: Maybe<EgdsStandardLink>;
  tooltipText: EgdsStylizedText;
};

export type SupplyPerformanceCard = SupplyPerformanceCurrencyCard | SupplyPerformanceSummaryAggregateCard;

export type SupplyPerformanceContent = SupplyPerformanceErrorContent | SupplyPerformanceSummaryContent;

export type SupplyPerformanceCurrencyCard = {
  __typename?: "SupplyPerformanceCurrencyCard";
  action?: Maybe<UiLinkAction>;
  amount?: Maybe<Scalars["Float"]["output"]>;
  currency?: Maybe<Scalars["String"]["output"]>;
  formattedContent?: Maybe<Scalars["String"]["output"]>;
  metricsPresented: SupplyPerformanceSummaryEgClickstream;
  metricsSelected: SupplyPerformanceSummaryEgClickstream;
  primary: Scalars["String"]["output"];
  secondary: EgdsIconText;
};

export type SupplyPerformanceErrorContent = {
  __typename?: "SupplyPerformanceErrorContent";
  button?: Maybe<EgdsButton>;
  icon?: Maybe<Icon>;
  performanceRefreshSelected: SupplyPerformanceSummaryEgClickstream;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPerformanceSummary = {
  __typename?: "SupplyPerformanceSummary";
  content: SupplyPerformanceContent;
  modulePresented: SupplyPerformanceSummaryEgClickstream;
  title: Scalars["String"]["output"];
};

export type SupplyPerformanceSummaryAggregateCard = {
  __typename?: "SupplyPerformanceSummaryAggregateCard";
  action?: Maybe<UiLinkAction>;
  content: Array<SupplyPerformanceSummaryCategoryContent>;
  metricsPresented: SupplyPerformanceSummaryEgClickstream;
  metricsSelected: SupplyPerformanceSummaryEgClickstream;
  title: Scalars["String"]["output"];
};

export type SupplyPerformanceSummaryCategoryContent = {
  __typename?: "SupplyPerformanceSummaryCategoryContent";
  content: Scalars["String"]["output"];
  metricsPresented: SupplyPerformanceSummaryEgClickstream;
  /** @deprecated Renaming this field, please use metricsPresented instead. */
  metricsSelected: SupplyPerformanceSummaryEgClickstream;
  title: Scalars["String"]["output"];
};

export type SupplyPerformanceSummaryContent = {
  __typename?: "SupplyPerformanceSummaryContent";
  summaryCards: Array<SupplyPerformanceCard>;
};

export type SupplyPerformanceSummaryEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyPerformanceSummaryEgClickstream";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
};

export type SupplyPerformanceSummaryFinanceWidget = {
  __typename?: "SupplyPerformanceSummaryFinanceWidget";
  formattedMonthlyPayout: Scalars["String"]["output"];
  monthlyBookings: Scalars["String"]["output"];
  monthlyPayoutsChart?: Maybe<Array<SupplyPerformanceSummaryMonthlyPayout>>;
  propertyLink: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyPerformanceSummaryMonthlyPayout = {
  __typename?: "SupplyPerformanceSummaryMonthlyPayout";
  amount: Scalars["Float"]["output"];
  month: Scalars["String"]["output"];
};

export type SupplyPhoneNumberSelectionView = {
  __typename?: "SupplyPhoneNumberSelectionView";
  addNewPhoneView?: Maybe<SupplyCustomPhoneNumberField>;
  heading: Scalars["String"]["output"];
  options: EgdsBasicRadioGroup;
};

export type SupplyPhoneNumberVerificationView = {
  __typename?: "SupplyPhoneNumberVerificationView";
  challengeTicket: Scalars["String"]["output"];
  changeVerifyMethod: EgdsButton;
  codeInput: EgdsTextInputField;
  generateNewCode: EgdsButton;
  heading: Scalars["String"]["output"];
  invalidCodeMessage?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  newCodeMessage?: Maybe<EgdsToast>;
  verifyCode: EgdsButton;
};

export type SupplyPhoneNumberVerifyTypeView = {
  __typename?: "SupplyPhoneNumberVerifyTypeView";
  heading: Scalars["String"]["output"];
  options: EgdsBasicRadioGroup;
};

export type SupplyPhotoAssignDialog = {
  __typename?: "SupplyPhotoAssignDialog";
  confirm: SupplyPhotosAction;
  heading: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotoAssignExperience = {
  __typename?: "SupplyPhotoAssignExperience";
  actions: SupplyPhotoAssignmentActions;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  dialog?: Maybe<SupplyPhotoAssignDialog>;
  header: SupplyAssignPhotosHeader;
  noPhotosTip?: Maybe<SupplyPhotosNoPhotosTip>;
  photoGroups: Array<SupplyPhotoUploaderPhotoGroup>;
  /** @deprecated use selectedPhotosTip */
  selectedPhotoTip: SupplyPhotosSelectedPhotoTip;
  selectedPhotosTip?: Maybe<SupplyPhotosSelectedPhotosTip>;
};

export type SupplyPhotoAssignPhotoCell = {
  __typename?: "SupplyPhotoAssignPhotoCell";
  checkBox: EgdsBasicCheckBox;
  id: Scalars["String"]["output"];
  image: Image;
  status: SupplyPhotosPhotoStatus;
};

export type SupplyPhotoAssignmentActionPhotoAssign = {
  __typename?: "SupplyPhotoAssignmentActionPhotoAssign";
  partialLoadingError: SupplyPhotosInlineError;
  /** @deprecated use partialLoadingError */
  partialLoadingErrorSignal: SupplyPhotosSignal;
  saveError: SupplyPhotosInlineError;
  /** @deprecated use saveError */
  saveErrorSignal: SupplyPhotosSignal;
};

export type SupplyPhotoAssignmentActions = {
  __typename?: "SupplyPhotoAssignmentActions";
  assign: SupplyPhotoAssignmentActionPhotoAssign;
};

export type SupplyPhotoCoachingActionPanel = SupplyCoachingActionPanel & {
  __typename?: "SupplyPhotoCoachingActionPanel";
  confirmedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  dialog: SupplyContentLeaveWithoutSavingDialog;
  saveButton: SupplyContentButton;
  skipButton: SupplyContentButton;
  skippedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  stepText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyPhotoSpacesExperience = {
  __typename?: "SupplyPhotoSpacesExperience";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  heading: SupplyPhotosLocalizedTextTemplate;
  spacesList?: Maybe<Array<SupplyPhotoSpacesList>>;
  toast: SupplyPhotoSpacesToast;
};

export type SupplyPhotoSpacesGridCell = {
  __typename?: "SupplyPhotoSpacesGridCell";
  actions: SupplyPhotoSpacesGridCellActions;
  id: Scalars["String"]["output"];
  image: Image;
  status: SupplyPhotosPhotoStatus;
};

export type SupplyPhotoSpacesGridCellActions = {
  __typename?: "SupplyPhotoSpacesGridCellActions";
  unassign: SupplyPhotosAction;
};

export type SupplyPhotoSpacesList = {
  __typename?: "SupplyPhotoSpacesList";
  assignButton: SupplyPhotosAction;
  description?: Maybe<SupplyPhotosLocalizedTextTemplate>;
  heading: EgdsIconText;
  photoGrid: SupplyPhotoUploaderGrid;
  spaceCompleteStatus?: Maybe<EgdsIconText>;
  spaceId: Scalars["String"]["output"];
  spaceType: Scalars["String"]["output"];
  subHeading?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyPhotoSpacesToast = {
  __typename?: "SupplyPhotoSpacesToast";
  assignSucceed: SupplyPhotosLocalizedTextTemplate;
  unassignFailed: SupplyPhotosLocalizedTextTemplate;
  unassignSucceed: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotoUploaderActionPhotoDelete = {
  __typename?: "SupplyPhotoUploaderActionPhotoDelete";
  confirmSheet: SupplyPhotoUploaderPhotoDeleteSheet;
  deleteIconButton: SupplyPhotosAction;
  failedErrorSignal: SupplyPhotosSignal;
  limitDeleteSheet?: Maybe<SupplyPhotoUploaderPhotoLimitDeleteSheet>;
};

export type SupplyPhotoUploaderActionPhotoEdit = {
  __typename?: "SupplyPhotoUploaderActionPhotoEdit";
  deleteFailed?: Maybe<SupplyPhotosBannerError>;
  editPhotoSheet?: Maybe<SupplyPhotoUploaderEditSheet>;
  leaveEditSheet?: Maybe<SupplyPhotoUploaderLeaveOrNextEditSheet>;
  nextPhotoSheet?: Maybe<SupplyPhotoUploaderLeaveOrNextEditSheet>;
  saveCaptionFailed?: Maybe<SupplyPhotosBannerError>;
};

export type SupplyPhotoUploaderActionPhotoUpload = {
  __typename?: "SupplyPhotoUploaderActionPhotoUpload";
  awsConfig: SupplyPhotoUploaderAwsConfiguration;
  bulkSizeErrorSignal: SupplyPhotosSignal;
  serverErrorSignal: SupplyPhotosSignal;
  statusToast: SupplyPhotoUploaderUploadingStatusToast;
  validations: Array<SupplyPhotosValidation>;
};

export type SupplyPhotoUploaderActions = {
  __typename?: "SupplyPhotoUploaderActions";
  delete: SupplyPhotoUploaderActionPhotoDelete;
  edit?: Maybe<SupplyPhotoUploaderActionPhotoEdit>;
  upload: SupplyPhotoUploaderActionPhotoUpload;
};

export type SupplyPhotoUploaderAwsConfiguration = {
  __typename?: "SupplyPhotoUploaderAwsConfiguration";
  identityId: Scalars["String"]["output"];
  poolId: Scalars["String"]["output"];
  provider: Scalars["String"]["output"];
  providerToken: Scalars["String"]["output"];
  region: Scalars["String"]["output"];
  s3Bucket: Scalars["String"]["output"];
  s3Region: Scalars["String"]["output"];
  s3UrlDomainName: Scalars["String"]["output"];
};

export type SupplyPhotoUploaderBulkFileSizeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderBulkFileSizeValidation";
  errorMessage: SupplyPhotosLocalizedTextTemplate;
  maxBulkFileSize: Scalars["Int"]["output"];
};

export type SupplyPhotoUploaderDropZone = {
  __typename?: "SupplyPhotoUploaderDropZone";
  addPhotoButton: SupplyPhotosAction;
  heading?: Maybe<Scalars["String"]["output"]>;
  requirementTips?: Maybe<SupplyPhotosPhotoRequirementTips>;
  requirementTipsSheet?: Maybe<SupplyPhotosPhotoRequirementTipsSheet>;
};

export type SupplyPhotoUploaderEditCaption = {
  __typename?: "SupplyPhotoUploaderEditCaption";
  textarea?: Maybe<EgdsTextAreaInputField>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPhotoUploaderEditSheet = {
  __typename?: "SupplyPhotoUploaderEditSheet";
  caption?: Maybe<SupplyPhotoUploaderEditCaption>;
  closeButton: SupplyPhotosAction;
  deleteButton: SupplyPhotosAction;
  label?: Maybe<SupplyPhotosLocalizedTextTemplate>;
  rotateButton: SupplyPhotosAction;
  saveButton: SupplyPhotosAction;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPhotoUploaderErrorExpandoCard = {
  __typename?: "SupplyPhotoUploaderErrorExpandoCard";
  collapsedLabel?: Maybe<SupplyPhotosLocalizedTextTemplate>;
  description: Scalars["String"]["output"];
  dismissButton: SupplyPhotosAction;
  expanded: Scalars["Boolean"]["output"];
  expandedLabel: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotoUploaderExperience = {
  __typename?: "SupplyPhotoUploaderExperience";
  actions: SupplyPhotoUploaderActions;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  dropZone?: Maybe<SupplyPhotoUploaderDropZone>;
  errorsCard: SupplyPhotoUploaderErrorExpandoCard;
  groupsHeader?: Maybe<SupplyPhotoUploaderGroupsHeader>;
  /** @deprecated use pageHeader */
  header: SupplyPhotosHeader;
  minimumRequirement?: Maybe<SupplyPhotoUploaderPhotoMinimumRequirement>;
  pageHeader?: Maybe<SupplyPhotosHeader>;
  photoGroups: Array<SupplyPhotoUploaderPhotoGroup>;
  showCoaching?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyPhotoUploaderFileSizeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderFileSizeValidation";
  errorMessage: SupplyPhotosLocalizedTextTemplate;
  maxFileSize: Scalars["Int"]["output"];
};

export type SupplyPhotoUploaderFileTypeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderFileTypeValidation";
  acceptableTypes: Array<Scalars["String"]["output"]>;
  errorMessage: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotoUploaderGrid = {
  __typename?: "SupplyPhotoUploaderGrid";
  cells: Array<SupplyPhotoUploaderGridCell>;
  columns: Scalars["Int"]["output"];
};

export type SupplyPhotoUploaderGridCell =
  | SupplyPhotoAssignPhotoCell
  | SupplyPhotoSpacesGridCell
  | SupplyPhotoUploaderPhotoCell;

export type SupplyPhotoUploaderGridCellActions = {
  __typename?: "SupplyPhotoUploaderGridCellActions";
  delete?: Maybe<SupplyPhotosAction>;
  edit?: Maybe<SupplyPhotosAction>;
};

export type SupplyPhotoUploaderGridCellBadge = {
  __typename?: "SupplyPhotoUploaderGridCellBadge";
  badge?: Maybe<EgdsStandardBadge>;
  icon?: Maybe<Icon>;
};

export type SupplyPhotoUploaderGroupsHeader = {
  __typename?: "SupplyPhotoUploaderGroupsHeader";
  reorderButton?: Maybe<SupplyPhotosAction>;
  totalPhoto?: Maybe<SupplyPhotosLocalizedTextTemplate>;
};

export type SupplyPhotoUploaderLeaveOrNextEditSheet = {
  __typename?: "SupplyPhotoUploaderLeaveOrNextEditSheet";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  leaveButton: SupplyPhotosAction;
  saveButton: SupplyPhotosAction;
};

export type SupplyPhotoUploaderPhotoCell = {
  __typename?: "SupplyPhotoUploaderPhotoCell";
  actions: SupplyPhotoUploaderGridCellActions;
  cellBadge?: Maybe<SupplyPhotoUploaderGridCellBadge>;
  id: Scalars["String"]["output"];
  image: Image;
  /** @deprecated use cellBadge */
  label?: Maybe<Scalars["String"]["output"]>;
  lastUpdatedTime?: Maybe<Scalars["String"]["output"]>;
  rank?: Maybe<Scalars["Int"]["output"]>;
  status: SupplyPhotosPhotoStatus;
};

export type SupplyPhotoUploaderPhotoDeleteSheet = {
  __typename?: "SupplyPhotoUploaderPhotoDeleteSheet";
  cancelButton: SupplyPhotosAction;
  deleteButton: SupplyPhotosAction;
  description?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
};

export type SupplyPhotoUploaderPhotoGroup = {
  __typename?: "SupplyPhotoUploaderPhotoGroup";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  photoGrid: SupplyPhotoUploaderGrid;
};

export type SupplyPhotoUploaderPhotoLimitDeleteSheet = {
  __typename?: "SupplyPhotoUploaderPhotoLimitDeleteSheet";
  continueButton: SupplyPhotosAction;
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  minimumPhotos?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyPhotoUploaderPhotoMinimumRequirement = {
  __typename?: "SupplyPhotoUploaderPhotoMinimumRequirement";
  closeToGoLiveTip?: Maybe<SupplyPhotosLocalizedTextTemplate>;
  icon?: Maybe<Icon>;
  minimumPhotos: Scalars["Int"]["output"];
  readyToGoLiveTip?: Maybe<SupplyPhotosLocalizedTextTemplate>;
};

export type SupplyPhotoUploaderPhotoSizeValidation = SupplyPhotosValidation & {
  __typename?: "SupplyPhotoUploaderPhotoSizeValidation";
  errorMessage: SupplyPhotosLocalizedTextTemplate;
  minimumHeight: Scalars["Int"]["output"];
  minimumWidth: Scalars["Int"]["output"];
};

export type SupplyPhotoUploaderUploadingStatusToast = {
  __typename?: "SupplyPhotoUploaderUploadingStatusToast";
  fail: SupplyPhotosLocalizedTextTemplate;
  processing: SupplyPhotosLocalizedTextTemplate;
  succeed: SupplyPhotosLocalizedTextTemplate;
  succeedWithError: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotosAction = {
  __typename?: "SupplyPhotosAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  href?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  type: SupplyPhotosActionType;
};

export type SupplyPhotosActionType =
  | "CARD_BUTTON"
  | "LINK"
  | "LINK_BUTTON"
  | "PRIMARY_BUTTON"
  | "SECONDARY_BUTTON"
  | "TERTIARY_BUTTON"
  | "TOOL_BAR_BUTTON";

export type SupplyPhotosAddExperience = {
  __typename?: "SupplyPhotosAddExperience";
  addedPhotos: Array<SupplyPhotoUploaderPhotoCell>;
  failedPhotos: Array<SupplyPhotosFailedToMutatePhoto>;
};

export interface SupplyPhotosAddPhotoInput {
  fileName: Scalars["String"]["input"];
  fileUrl: Scalars["String"]["input"];
  rank?: InputMaybe<Scalars["Int"]["input"]>;
  roomIds: Array<Scalars["String"]["input"]>;
  spaceIds: Array<Scalars["String"]["input"]>;
}

export type SupplyPhotosAssignExperience = {
  __typename?: "SupplyPhotosAssignExperience";
  assignedPhotoIds: Array<Scalars["String"]["output"]>;
  failedPhotos: Array<SupplyPhotosFailedToMutatePhoto>;
};

export type SupplyPhotosBannerError = {
  __typename?: "SupplyPhotosBannerError";
  canDismiss?: Maybe<Scalars["Boolean"]["output"]>;
  canRetry?: Maybe<Scalars["Boolean"]["output"]>;
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type SupplyPhotosDeleteExperience = {
  __typename?: "SupplyPhotosDeleteExperience";
  deletedPhotoIds: Array<Scalars["String"]["output"]>;
  failedPhotos: Array<SupplyPhotosFailedToMutatePhoto>;
};

export type SupplyPhotosErrorBanner = {
  __typename?: "SupplyPhotosErrorBanner";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  defaultError: SupplyPhotosBannerError;
  dismissLinkButton: SupplyPhotosAction;
  refreshCard: SupplyPhotosErrorRefreshCard;
  retryFailedError: SupplyPhotosBannerError;
  retryLinkButton: SupplyPhotosAction;
};

export type SupplyPhotosErrorRefreshCard = {
  __typename?: "SupplyPhotosErrorRefreshCard";
  content: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  refreshButton: SupplyPhotosAction;
};

export type SupplyPhotosExperience = {
  __typename?: "SupplyPhotosExperience";
  errorBanner: SupplyPhotosErrorBanner;
  navTabs: SupplyPhotosNavTabs;
  tipsCard: SupplyPhotosTipsCard;
  unAssignedSpaceCard: SupplyPhotosUnAssignedSpaceTipCard;
};

export interface SupplyPhotosExperienceContext {
  category?: InputMaybe<Scalars["String"]["input"]>;
  spaceId?: InputMaybe<Scalars["String"]["input"]>;
  view: Scalars["String"]["input"];
}

export type SupplyPhotosFailedToMutatePhoto = {
  __typename?: "SupplyPhotosFailedToMutatePhoto";
  id: Scalars["String"]["output"];
  image?: Maybe<Image>;
  reason?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPhotosHeader = {
  __typename?: "SupplyPhotosHeader";
  descriptions?: Maybe<Array<Scalars["String"]["output"]>>;
  heading: Scalars["String"]["output"];
};

export type SupplyPhotosInlineBannerError = {
  __typename?: "SupplyPhotosInlineBannerError";
  dismissButton?: Maybe<SupplyPhotosAction>;
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  retryButton?: Maybe<SupplyPhotosAction>;
};

export type SupplyPhotosInlineError = {
  __typename?: "SupplyPhotosInlineError";
  defaultError: SupplyPhotosInlineBannerError;
  retryFailedError?: Maybe<SupplyPhotosInlineBannerError>;
};

export type SupplyPhotosLocalizedTextTemplate = {
  __typename?: "SupplyPhotosLocalizedTextTemplate";
  template: Scalars["String"]["output"];
};

export type SupplyPhotosNavTabs = {
  __typename?: "SupplyPhotosNavTabs";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  nav: EgdsTabs;
};

export type SupplyPhotosNoPhotosTip = {
  __typename?: "SupplyPhotosNoPhotosTip";
  noPhotosTipHeader: SupplyPhotosHeader;
  noPhotosTipIcon: Icon;
};

export type SupplyPhotosPhotoRequirementTip = EgdsPlainText | SupplyPhotosLocalizedTextTemplate;

export type SupplyPhotosPhotoRequirementTips = {
  __typename?: "SupplyPhotosPhotoRequirementTips";
  content: Array<SupplyPhotosPhotoRequirementTip>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPhotosPhotoRequirementTipsSheet = {
  __typename?: "SupplyPhotosPhotoRequirementTipsSheet";
  linkButton: SupplyPhotosAction;
  requirementTips: SupplyPhotosPhotoRequirementTips;
};

export type SupplyPhotosPhotoStatus = "ACTIVE" | "PROCESSING";

export type SupplyPhotosSelectedPhotoTip = {
  __typename?: "SupplyPhotosSelectedPhotoTip";
  /** @deprecated use noSelectedIcon */
  noSelectedIco: Icon;
  noSelectedIcon: Icon;
  /** @deprecated use selectedIcon */
  selectedIco: Icon;
  selectedIcon: Icon;
  tipContext: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotosSelectedPhotosTip = {
  __typename?: "SupplyPhotosSelectedPhotosTip";
  noSelectedIcon: Icon;
  selectedIcon: Icon;
  tipContext: SupplyPhotosLocalizedTextTemplate;
};

export type SupplyPhotosSignal = {
  __typename?: "SupplyPhotosSignal";
  payload?: Maybe<SupplyPhotosSignalPayload>;
  type: Scalars["String"]["output"];
};

export type SupplyPhotosSignalPayload = SupplyPhotosBannerError;

export type SupplyPhotosTipsCard = {
  __typename?: "SupplyPhotosTipsCard";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  descriptions?: Maybe<Array<SupplyPhotosLocalizedTextTemplate>>;
  icon?: Maybe<Icon>;
  title: Scalars["String"]["output"];
};

export type SupplyPhotosUnAssignedSpaceTipCard = {
  __typename?: "SupplyPhotosUnAssignedSpaceTipCard";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  badge?: Maybe<EgdsStandardBadge>;
  description: SupplyPhotosLocalizedTextTemplate;
  linkButton: SupplyPhotosAction;
  title: Scalars["String"]["output"];
};

export type SupplyPhotosUnassignExperience = {
  __typename?: "SupplyPhotosUnassignExperience";
  failedPhotos: Array<SupplyPhotosFailedToMutatePhoto>;
  unassignedPhotoIds: Array<Scalars["String"]["output"]>;
};

export interface SupplyPhotosValidation {
  errorMessage: SupplyPhotosLocalizedTextTemplate;
}

export type SupplyPlaybackSection = {
  __typename?: "SupplyPlaybackSection";
  clickstreamInformation?: Maybe<EgClickstreamData>;
  content: SupplyReviewSectionListItemContent;
  contentListColumns?: Maybe<Scalars["Int"]["output"]>;
  editLink?: Maybe<SupplyReviewEditLink>;
  icon: Icon;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyPlaybackSectionList = {
  __typename?: "SupplyPlaybackSectionList";
  content: Array<SupplyReviewSectionListItem>;
  editLink?: Maybe<SupplyReviewEditLink>;
  icon: Icon;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyPostponeMultipleSelection = {
  __typename?: "SupplyPostponeMultipleSelection";
  options: Array<SupplyPostponeSelectionOption>;
};

export type SupplyPostponeSelectionOption = {
  __typename?: "SupplyPostponeSelectionOption";
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyPostponeSingleSelection = {
  __typename?: "SupplyPostponeSingleSelection";
  options: Array<SupplyPostponeSelectionOption>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPostponeSubmitAnalytics = {
  __typename?: "SupplyPostponeSubmitAnalytics";
  postponeFailed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  postponeSucceeded?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyPostponeSubmitResponse = {
  __typename?: "SupplyPostponeSubmitResponse";
  clickStreamData?: Maybe<SupplyPostponeSubmitAnalytics>;
  error?: Maybe<EgdsErrorSummary>;
  success?: Maybe<SupplyPostponeSuccessResponse>;
};

export type SupplyPostponeSuccessResponse = {
  __typename?: "SupplyPostponeSuccessResponse";
  descriptions: Array<Scalars["String"]["output"]>;
  successButtonLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyPremierHost = {
  __typename?: "SupplyPremierHost";
  scorecard?: Maybe<PremierHostScorecard>;
  status?: Maybe<PremierHostStatus>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPremierHostDownloadType = "BOOKING_DETAILS" | "LISTINGS_SUMMARY";

export type SupplyPreviewRoomNameResult = EgdsPlainText | SupplyProductCreationErrorSection;

export type SupplyProductCreateRateStatus = {
  __typename?: "SupplyProductCreateRateStatus";
  icon: Icon;
  status: Scalars["String"]["output"];
};

export type SupplyProductCreationAriEnabled = {
  __typename?: "SupplyProductCreationARIEnabled";
  checkbox: SupplyProductCreationCommonCheckbox;
  header: SupplyProductCreationSectionHeader;
  visible: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationAddAnotherRoomTypeInstruction = {
  __typename?: "SupplyProductCreationAddAnotherRoomTypeInstruction";
  button: SupplyProductCreationButton;
  text: EgdsIconText;
};

export type SupplyProductCreationAdditionalOccupantCharge = {
  __typename?: "SupplyProductCreationAdditionalOccupantCharge";
  chargeInstruction: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  input: SupplyProductCreationInputField;
};

export interface SupplyProductCreationBaseRateManagementInput {
  additionalOccupantCharge?: InputMaybe<Scalars["Float"]["input"]>;
  baseRate?: InputMaybe<Scalars["Float"]["input"]>;
  copyRoomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  peopleIncludedInBaseRate?: InputMaybe<Scalars["Int"]["input"]>;
  priceType: PricingType;
  ratePlanID?: InputMaybe<Scalars["String"]["input"]>;
  roomMaxOccupancy?: InputMaybe<Scalars["Int"]["input"]>;
  roomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyProductCreationBaseRateSection = {
  __typename?: "SupplyProductCreationBaseRateSection";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  header: SupplyProductCreationSectionHeader;
  priceType: PricingType;
  ratePlanID?: Maybe<Scalars["String"]["output"]>;
  rateSetting: SupplyProductCreationRateSetting;
  rateSummary: SupplyProductCreationRateSummary;
};

export interface SupplyProductCreationBed {
  quantity: Scalars["Int"]["input"];
  size: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
}

export type SupplyProductCreationBedConfiguration = {
  __typename?: "SupplyProductCreationBedConfiguration";
  bedNumber: EgdsStepInput;
  bedType: SupplyProductCreationSelectMenuField;
  selectedBedSize?: Maybe<Scalars["String"]["output"]>;
  selectedBedType?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyProductCreationBedGroup {
  beds?: InputMaybe<Array<SupplyProductCreationBed>>;
}

export interface SupplyProductCreationBedTypeConfiguration {
  bedGroups?: InputMaybe<Array<SupplyProductCreationBedGroup>>;
}

export type SupplyProductCreationBreadCrumbItem = {
  __typename?: "SupplyProductCreationBreadCrumbItem";
  text: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationBreadCrumbs = {
  __typename?: "SupplyProductCreationBreadCrumbs";
  crumbs: Array<SupplyProductCreationBreadCrumbItem>;
};

export type SupplyProductCreationBreakfastContentSection = {
  __typename?: "SupplyProductCreationBreakfastContentSection";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  baseRatePlanId: Scalars["String"]["output"];
  breakfastTypeSelect: SupplyProductCreationSelect;
  deleteSection?: Maybe<SupplyProductCreationDeleteRatePlanSection>;
  description: Scalars["String"]["output"];
  ratePlanId?: Maybe<Scalars["String"]["output"]>;
  rateSetting: SupplyProductCreationBreakfastRateSetting;
  suggestedRateHeader: SupplyProductCreationSectionHeader;
};

export type SupplyProductCreationBreakfastRateSetting = {
  __typename?: "SupplyProductCreationBreakfastRateSetting";
  rateIncreaseSection: SupplyProductCreationRateIncreaseSection;
  rateSummarySection?: Maybe<SupplyProductCreationSideSheetRateSummary>;
};

export type SupplyProductCreationButton = {
  __typename?: "SupplyProductCreationButton";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: EgdsButton;
  clickFailureAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  clickSuccessAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  targetUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationCharge = {
  __typename?: "SupplyProductCreationCharge";
  amount: SupplyProductCreationInputField;
  chargeType: SupplyProductCreationSelectMenuField;
};

export type SupplyProductCreationCheckbox = {
  __typename?: "SupplyProductCreationCheckbox";
  accessibility: Scalars["String"]["output"];
  badge?: Maybe<EgdsStandardBadge>;
  conflictCategoryName?: Maybe<SupplyProductCreationRoomNameAttributeCategoryName>;
  description?: Maybe<Scalars["String"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  label: Scalars["String"]["output"];
  name: SupplyProductCreationRoomNameAttributeCategoryName;
  nestedSelection?: Maybe<SupplyProductCreationSelect>;
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationChildRate = {
  __typename?: "SupplyProductCreationChildRate";
  adjustment: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  occupancyRates: Array<SupplyProductCreationOccupancyRate>;
  rate: Scalars["String"]["output"];
};

export interface SupplyProductCreationChildRateManagementInput {
  checkedRatePlans: Array<RatePlanInfo>;
  discount: Scalars["Float"]["input"];
  discountChanged: Scalars["Boolean"]["input"];
  rateType: SideSheetType;
  roomTypeKey: Scalars["String"]["input"];
  uncheckedRatePlans: Array<InputMaybe<RatePlanInfo>>;
}

export type SupplyProductCreationChildRatePlanContentSection = {
  __typename?: "SupplyProductCreationChildRatePlanContentSection";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  deleteSection?: Maybe<SupplyProductCreationDeleteRatePlanSection>;
  /** @deprecated Use paragraph instead */
  description: Scalars["String"]["output"];
  paragraph: Array<SupplyProductCreationParagraph>;
  rateSetting: SupplyProductCreationChildRateSetting;
  suggestedSettingsHeader: SupplyProductCreationSectionHeader;
};

export type SupplyProductCreationChildRatePlanManagementResult = {
  __typename?: "SupplyProductCreationChildRatePlanManagementResult";
  error?: Maybe<SupplyProductCreationErrorBanner>;
  partialSuccessSummary?: Maybe<SupplyProductCreationPartialSuccessSummary>;
  success: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationChildRateSetting = {
  __typename?: "SupplyProductCreationChildRateSetting";
  discountExplanation?: Maybe<Scalars["String"]["output"]>;
  discountInput: SupplyProductCreationInput;
  ratePlanSelection: SupplyProductCreationRatePlanSelection;
  rateSummarySection: SupplyProductCreationChildRateSummary;
  rateThreshold?: Maybe<SupplyProductCreationRateThreshold>;
};

export type SupplyProductCreationChildRateSummary = {
  __typename?: "SupplyProductCreationChildRateSummary";
  currencyCode: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
};

export type SupplyProductCreationCommonCheckbox = {
  __typename?: "SupplyProductCreationCommonCheckbox";
  description?: Maybe<Scalars["String"]["output"]>;
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  label: Scalars["String"]["output"];
  moreInfoTrigger?: Maybe<SupplyProductCreationMoreInfoTrigger>;
  state: EgdsCheckBoxState;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationDatePickerInput = {
  __typename?: "SupplyProductCreationDatePickerInput";
  datePicker: EgdsSingleDatePicker;
  input: SupplyProductCreationInputField;
};

export type SupplyProductCreationDeleteRatePlanConfirmSection = {
  __typename?: "SupplyProductCreationDeleteRatePlanConfirmSection";
  cancelButton: SupplyProductCreationButton;
  deleteButton: SupplyProductCreationButton;
  heading: Scalars["String"]["output"];
};

export type SupplyProductCreationDeleteRatePlanSection = {
  __typename?: "SupplyProductCreationDeleteRatePlanSection";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: SupplyProductCreationButton;
  confirmationDialog: SupplyProductCreationDeleteRatePlanConfirmSection;
};

export interface SupplyProductCreationDeleteRatePlansInput {
  ratePlanIDs: Array<Scalars["String"]["input"]>;
}

export interface SupplyProductCreationDeleteRoomInput {
  roomTypeId: Scalars["String"]["input"];
}

export type SupplyProductCreationDeleteRoomResult = {
  __typename?: "SupplyProductCreationDeleteRoomResult";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  needReRender?: Maybe<Scalars["Boolean"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationErrorBanner = {
  __typename?: "SupplyProductCreationErrorBanner";
  message: Scalars["String"]["output"];
};

export type SupplyProductCreationErrorSection = {
  __typename?: "SupplyProductCreationErrorSection";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationExperience = {
  __typename?: "SupplyProductCreationExperience";
  header?: Maybe<SupplyProductCreationStepHeader>;
  mainContent?: Maybe<SupplyProductCreationMainContent>;
  navigator?: Maybe<SupplyProductCreationNavigator>;
};

export type SupplyProductCreationExperienceHeaderArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationExperienceMainContentArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationExperienceNavigatorArgs = {
  context: ContextInput;
  params: SupplyProductCreationExperienceInputParam;
  propertyContext: PropertyContext;
};

export interface SupplyProductCreationExperienceInputParam {
  copyRoomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  isAddPath?: InputMaybe<Scalars["Boolean"]["input"]>;
  roomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  showPricingModel?: InputMaybe<Scalars["Boolean"]["input"]>;
  step: Scalars["String"]["input"];
}

export type SupplyProductCreationExtraBed = {
  __typename?: "SupplyProductCreationExtraBed";
  additionalCharge?: Maybe<SupplyProductCreationCharge>;
  bedNumber: EgdsStepInput;
  bedType: SupplyProductCreationSelectMenuField;
  chargeType?: Maybe<SupplyProductCreationSwitch>;
};

export type SupplyProductCreationExtraBedCheckbox = {
  __typename?: "SupplyProductCreationExtraBedCheckbox";
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  instruction?: Maybe<SupplyProductCreationSpannableText>;
  label: Scalars["String"]["output"];
  state: EgdsCheckBoxState;
  subSection?: Maybe<SupplyProductCreationExtraBed>;
  value: Scalars["String"]["output"];
};

export type SupplyProductCreationExtraBedSection = {
  __typename?: "SupplyProductCreationExtraBedSection";
  header: SupplyProductCreationSectionHeader;
  radioGroup: SupplyProductCreationRadioGroup;
};

export type SupplyProductCreationExtraBeds = {
  __typename?: "SupplyProductCreationExtraBeds";
  beds: Array<SupplyProductCreationExtraBedCheckbox>;
};

export type SupplyProductCreationFieldError = {
  __typename?: "SupplyProductCreationFieldError";
  errorMessage: Scalars["String"]["output"];
  fieldName: Scalars["String"]["output"];
};

export type SupplyProductCreationFinishedRoomsEmptyState = {
  __typename?: "SupplyProductCreationFinishedRoomsEmptyState";
  button: SupplyProductCreationButton;
  heading: Scalars["String"]["output"];
  instruction: Scalars["String"]["output"];
  visible: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationGuestPay = {
  __typename?: "SupplyProductCreationGuestPay";
  accessibility: Scalars["String"]["output"];
  occupancy: Scalars["String"]["output"];
  pay: Scalars["String"]["output"];
};

export interface SupplyProductCreationIndependentRateManagementInput {
  baseRatePlanId: Scalars["String"]["input"];
  breakfastType: Scalars["String"]["input"];
  breakfastTypeChanged?: InputMaybe<Scalars["Boolean"]["input"]>;
  rateIncrease: SupplyProductCreationRateIncrease;
  rateIncreaseChanged?: InputMaybe<Scalars["Boolean"]["input"]>;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeKey: Scalars["String"]["input"];
}

export type SupplyProductCreationInformativeInputField = {
  __typename?: "SupplyProductCreationInformativeInputField";
  inputField: SupplyProductCreationInputField;
  popoverText: Scalars["String"]["output"];
};

export type SupplyProductCreationInput = {
  __typename?: "SupplyProductCreationInput";
  heading: Scalars["String"]["output"];
  input: SupplyProductCreationInputField;
};

export type SupplyProductCreationInputField = {
  __typename?: "SupplyProductCreationInputField";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  instructions?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  readOnly?: Maybe<Scalars["Boolean"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
  validationFailureAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  validations?: Maybe<Array<SupplyProductCreationInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
  warnings?: Maybe<Array<SupplyProductCreationInputWarning>>;
};

export interface SupplyProductCreationInputValidation {
  errorMessage: Scalars["String"]["output"];
}

export interface SupplyProductCreationInputWarning {
  errorMessage: EgdsText;
}

export type SupplyProductCreationLeaveWithoutSavingDialog = {
  __typename?: "SupplyProductCreationLeaveWithoutSavingDialog";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  leaveButton: SupplyProductCreationButton;
  stayButton: SupplyProductCreationButton;
};

export type SupplyProductCreationLessThanInputWarning = SupplyProductCreationInputWarning & {
  __typename?: "SupplyProductCreationLessThanInputWarning";
  errorMessage: EgdsText;
  value?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyProductCreationMainContent =
  | SupplyProductCreationBaseRateSection
  | SupplyProductCreationErrorSection
  | SupplyProductCreationPopularRateSection;

export type SupplyProductCreationManagementResult = {
  __typename?: "SupplyProductCreationManagementResult";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  error?: Maybe<Scalars["String"]["output"]>;
  fieldError?: Maybe<SupplyProductCreationFieldError>;
  success: Scalars["Boolean"]["output"];
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationManagementResultMessage = {
  __typename?: "SupplyProductCreationManagementResultMessage";
  description: Scalars["String"]["output"];
  ratePlanDisplayNames: Array<Scalars["String"]["output"]>;
};

export type SupplyProductCreationMaxInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationMaxInputValidation";
  errorMessage: Scalars["String"]["output"];
  maxValue?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyProductCreationMenu = {
  __typename?: "SupplyProductCreationMenu";
  expandoLink?: Maybe<EgdsExpandoLink>;
  invisibleItems?: Maybe<Array<SupplyProductCreationMenuItem>>;
  title?: Maybe<Scalars["String"]["output"]>;
  visibleItems: Array<SupplyProductCreationMenuItem>;
};

export type SupplyProductCreationMenuItem = {
  __typename?: "SupplyProductCreationMenuItem";
  description?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  subSections?: Maybe<Array<SupplyProductCreationSubSection>>;
  value: Scalars["String"]["output"];
};

export type SupplyProductCreationMinInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationMinInputValidation";
  errorMessage: Scalars["String"]["output"];
  minValue?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyProductCreationMoreInfoTrigger = {
  __typename?: "SupplyProductCreationMoreInfoTrigger";
  icon: Icon;
  text: Scalars["String"]["output"];
  triggerInfo: Scalars["String"]["output"];
};

export type SupplyProductCreationMoreThanInputWarning = SupplyProductCreationInputWarning & {
  __typename?: "SupplyProductCreationMoreThanInputWarning";
  errorMessage: EgdsText;
  value?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyProductCreationNavigationType = "BACK" | "NEXT";

export type SupplyProductCreationNavigator = {
  __typename?: "SupplyProductCreationNavigator";
  /** @deprecated Use `backNavButton` instead. */
  backButton: SupplyProductCreationButton;
  backNavButton?: Maybe<SupplyProductCreationButton>;
  leaveWithoutSavingDialog?: Maybe<SupplyProductCreationLeaveWithoutSavingDialog>;
  nextButton: SupplyProductCreationButton;
  stepIndicator: Scalars["String"]["output"];
  stepIndicatorAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationNoOverlapInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationNoOverlapInputValidation";
  errorMessage: Scalars["String"]["output"];
};

export type SupplyProductCreationNoRateAvailableMessage = {
  __typename?: "SupplyProductCreationNoRateAvailableMessage";
  heading: Scalars["String"]["output"];
  icon: Icon;
  paragraph: Scalars["String"]["output"];
};

export type SupplyProductCreationOccupancy = {
  __typename?: "SupplyProductCreationOccupancy";
  instruction: EgdsText;
  occupancyNumber: SupplyProductCreationInputField;
};

export type SupplyProductCreationOccupancyRate = {
  __typename?: "SupplyProductCreationOccupancyRate";
  occupancy: Scalars["Int"]["output"];
  rate: Scalars["String"]["output"];
};

export type SupplyProductCreationParagraph = {
  __typename?: "SupplyProductCreationParagraph";
  description: Scalars["String"]["output"];
  instructions?: Maybe<EgdsBulletedList>;
};

export type SupplyProductCreationParentRatePlanCheckbox = {
  __typename?: "SupplyProductCreationParentRatePlanCheckbox";
  checked: Scalars["Boolean"]["output"];
  childRatePlanDisplayName: Scalars["String"]["output"];
  childRatePlanID?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  parentMaxOccupancyExtraCharge?: Maybe<Scalars["Float"]["output"]>;
  parentMaxOccupancyRate?: Maybe<Scalars["Float"]["output"]>;
  parentMinOccupancyRate?: Maybe<Scalars["Float"]["output"]>;
  parentRatePlanID: Scalars["String"]["output"];
  parentRatePlanName: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyProductCreationPartialSuccessSummary = {
  __typename?: "SupplyProductCreationPartialSuccessSummary";
  button: SupplyProductCreationButton;
  failureMessage: SupplyProductCreationManagementResultMessage;
  successMessage: SupplyProductCreationManagementResultMessage;
};

export type SupplyProductCreationPopularRateCard = {
  __typename?: "SupplyProductCreationPopularRateCard";
  button?: Maybe<SupplyProductCreationButton>;
  description: Scalars["String"]["output"];
  header: SupplyProductCreationPopularRateCardHeader;
  ratePlanID?: Maybe<Scalars["String"]["output"]>;
  type: SupplyProductCreationPopularRateType;
  whyThisIsValuable: SupplyProductCreationWhyThisIsValuableSection;
};

export type SupplyProductCreationPopularRateCardDecoration = EgdsStandardBadge | SupplyProductCreateRateStatus;

export type SupplyProductCreationPopularRateCardHeader = {
  __typename?: "SupplyProductCreationPopularRateCardHeader";
  decoration: SupplyProductCreationPopularRateCardDecoration;
  heading: Scalars["String"]["output"];
  rateAdjustment?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationPopularRateSection = {
  __typename?: "SupplyProductCreationPopularRateSection";
  content: SupplyProductCreationPopularRateSectionContent;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyProductCreationPopularRateSectionContent =
  | SupplyProductCreationNoRateAvailableMessage
  | SupplyProductCreationPopularRates;

export type SupplyProductCreationPopularRateType = "BREAKFAST" | "MOD" | "NONREFUNDABLE" | "PACKAGE" | "REFUNDABLE";

export type SupplyProductCreationPopularRates = {
  __typename?: "SupplyProductCreationPopularRates";
  contractualRates?: Maybe<SupplyProductCreationRateCategory>;
  customizeRates?: Maybe<SupplyProductCreationRateCategory>;
};

export type SupplyProductCreationPreviewBedConfiguration = {
  __typename?: "SupplyProductCreationPreviewBedConfiguration";
  legacyBedIds?: Maybe<Array<Scalars["String"]["output"]>>;
  recommendedOccupancy?: Maybe<SupplyProductCreationRecommendOccupancy>;
};

export interface SupplyProductCreationPreviewBedConfigurationInput {
  inRoomBeds: Array<SupplyProductCreationBed>;
  uponRequestBeds?: InputMaybe<Array<SupplyProductCreationBed>>;
}

export type SupplyProductCreationPreviewBedConfigurationResult =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationPreviewBedConfiguration;

export type SupplyProductCreationPreviewRates = {
  __typename?: "SupplyProductCreationPreviewRates";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  guestNumberSelect: SupplyProductCreationSelect;
  heading: Scalars["String"]["output"];
  rates: Array<SupplyProductCreationRate>;
};

export type SupplyProductCreationPricingCard = {
  __typename?: "SupplyProductCreationPricingCard";
  contents: Array<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  pricingExample: SupplyProductCreationPricingExample;
  pricingType: PricingType;
  title: Scalars["String"]["output"];
};

export type SupplyProductCreationPricingDetail = {
  __typename?: "SupplyProductCreationPricingDetail";
  header: SupplyProductCreationSectionHeader;
  pricingCards: Array<SupplyProductCreationPricingCard>;
  selected?: Maybe<PricingType>;
  selectedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyProductCreationPricingExample = {
  __typename?: "SupplyProductCreationPricingExample";
  guestPayTitle: Scalars["String"]["output"];
  guestPays: Array<SupplyProductCreationGuestPay>;
  occupancyTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyProductCreationPricingModel = {
  __typename?: "SupplyProductCreationPricingModel";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  pricingModel: SupplyProductCreationPricingModelContent;
};

export type SupplyProductCreationPricingModelContent =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationPricingModelSection;

export type SupplyProductCreationPricingModelSection = {
  __typename?: "SupplyProductCreationPricingModelSection";
  pricingDetail?: Maybe<SupplyProductCreationPricingDetail>;
  pricingState: SupplyProductCreationPricingState;
};

export type SupplyProductCreationPricingState = {
  __typename?: "SupplyProductCreationPricingState";
  connectivity?: Maybe<SupplyProductCreationText>;
  header: SupplyProductCreationSectionHeader;
  icon: Icon;
  instruction: SupplyProductCreationSpannableText;
};

export type SupplyProductCreationRadioButton = {
  __typename?: "SupplyProductCreationRadioButton";
  description?: Maybe<Scalars["String"]["output"]>;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  label: Scalars["String"]["output"];
  subSection?: Maybe<SupplyProductCreationRadioButtonSubSection>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationRadioButtonSubSection =
  | SupplyProductCreationExtraBeds
  | SupplyProductCreationInputField;

export type SupplyProductCreationRadioGroup = {
  __typename?: "SupplyProductCreationRadioGroup";
  groupName: Scalars["String"]["output"];
  options: Array<SupplyProductCreationRadioButton>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationRangeInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRangeInputValidation";
  errorMessage: Scalars["String"]["output"];
  /** @deprecated Use maxValue */
  max: Scalars["Int"]["output"];
  maxValue: Scalars["Float"]["output"];
  /** @deprecated Use minValue */
  min: Scalars["Int"]["output"];
  minValue: Scalars["Float"]["output"];
};

export type SupplyProductCreationRate = {
  __typename?: "SupplyProductCreationRate";
  childRates?: Maybe<Array<SupplyProductCreationChildRate>>;
  currencyCode: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  occupancyRates: Array<SupplyProductCreationOccupancyRate>;
  rate: Scalars["String"]["output"];
};

export type SupplyProductCreationRateAdjustmentMaxInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRateAdjustmentMaxInputValidation";
  errorMessage: Scalars["String"]["output"];
  maxValue?: Maybe<Scalars["Float"]["output"]>;
  type: RateAdjustmentType;
};

export type SupplyProductCreationRateCategory = {
  __typename?: "SupplyProductCreationRateCategory";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<SupplyProductCreationSectionHeader>;
  rates: Array<SupplyProductCreationPopularRateCard>;
};

export interface SupplyProductCreationRateIncrease {
  rateIncrease: Scalars["Float"]["input"];
  rateIncreaseType: RateAdjustmentType;
}

export type SupplyProductCreationRateIncreaseSection = {
  __typename?: "SupplyProductCreationRateIncreaseSection";
  heading: Scalars["String"]["output"];
  rateIncreaseExplanation?: Maybe<Scalars["String"]["output"]>;
  rateIncreaseTypeSelect: SupplyProductCreationSelectField;
  rateIncreaseValueInput: SupplyProductCreationInputField;
  thresholds?: Maybe<Array<SupplyProductCreationRateIncreaseThreshold>>;
};

export type SupplyProductCreationRateIncreaseThreshold = {
  __typename?: "SupplyProductCreationRateIncreaseThreshold";
  maxValue: Scalars["Float"]["output"];
  type: RateAdjustmentType;
};

export type SupplyProductCreationRatePlanManagementResult = {
  __typename?: "SupplyProductCreationRatePlanManagementResult";
  error?: Maybe<SupplyProductCreationErrorBanner>;
  success: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationRatePlanSelection = {
  __typename?: "SupplyProductCreationRatePlanSelection";
  heading: Scalars["String"]["output"];
  info: Scalars["String"]["output"];
  ratePlanCheckBoxes: Array<SupplyProductCreationParentRatePlanCheckbox>;
};

export type SupplyProductCreationRatePlanSideSheet = {
  __typename?: "SupplyProductCreationRatePlanSideSheet";
  button: SupplyProductCreationButton;
  closeAccessibility?: Maybe<Scalars["String"]["output"]>;
  closeWithAllRatesDeletedAccessibility?: Maybe<Scalars["String"]["output"]>;
  contentSection?: Maybe<SupplyProductCreationRatePlanSideSheetContent>;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  openAccessibility?: Maybe<Scalars["String"]["output"]>;
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyProductCreationRatePlanSideSheetContent =
  | SupplyProductCreationBreakfastContentSection
  | SupplyProductCreationChildRatePlanContentSection
  | SupplyProductCreationErrorBanner;

export interface SupplyProductCreationRatePlanSideSheetInput {
  roomTypeKey: Scalars["String"]["input"];
  type: SideSheetType;
}

export type SupplyProductCreationRateSetting = {
  __typename?: "SupplyProductCreationRateSetting";
  additionalOccupantCharge: SupplyProductCreationAdditionalOccupantCharge;
  baseRateInput: SupplyProductCreationInputField;
  instruction: Scalars["String"]["output"];
  peopleCountSelect: SupplyProductCreationSelectField;
  rateThreshold?: Maybe<SupplyProductCreationRateThreshold>;
};

export type SupplyProductCreationRateSummary = {
  __typename?: "SupplyProductCreationRateSummary";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  expandoPeek: EgdsExpando;
  expandoSelectedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  guestPayAccessibility?: Maybe<EgdsCardinalLocalizedText>;
  guestPayTitle: Scalars["String"]["output"];
  guestPayUnit: Scalars["String"]["output"];
  instruction: Scalars["String"]["output"];
  occupancyTitle: Scalars["String"]["output"];
};

export type SupplyProductCreationRateThreshold = {
  __typename?: "SupplyProductCreationRateThreshold";
  maxValue: Scalars["Float"]["output"];
  minValue: Scalars["Float"]["output"];
  type: RateAdjustmentType;
};

export type SupplyProductCreationRecommendOccupancy = {
  __typename?: "SupplyProductCreationRecommendOccupancy";
  adults?: Maybe<Scalars["Int"]["output"]>;
  children?: Maybe<Scalars["Int"]["output"]>;
  total?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyProductCreationRecommendedOccupancy = {
  __typename?: "SupplyProductCreationRecommendedOccupancy";
  header: SupplyProductCreationSectionHeader;
  instruction: EgdsIconText;
  occupancy: SupplyProductCreationOccupancy;
};

export type SupplyProductCreationRegexInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRegexInputValidation";
  errorMessage: Scalars["String"]["output"];
  pattern: Scalars["String"]["output"];
};

export type SupplyProductCreationRequiredInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationRequiredInputValidation";
  errorMessage: Scalars["String"]["output"];
};

export type SupplyProductCreationReviewRoomName = {
  __typename?: "SupplyProductCreationReviewRoomName";
  accessibility: Scalars["String"]["output"];
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  description: Scalars["String"]["output"];
  roomNameCard: SupplyProductCreationRoomNameCard;
};

export type SupplyProductCreationReviewRoomNameExperience =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationReviewRoomName;

export type SupplyProductCreationRoomAmenityInfo = {
  __typename?: "SupplyProductCreationRoomAmenityInfo";
  icon: Icon;
  instruction: SupplyProductCreationSpannableText;
};

export type SupplyProductCreationRoomBasicInfo = {
  __typename?: "SupplyProductCreationRoomBasicInfo";
  header: SupplyProductCreationSectionHeader;
  roomClass: SupplyProductCreationSelectMenuField;
  roomInventory: SupplyProductCreationInput;
  roomType: SupplyProductCreationSelectMenuField;
  smokingPolicy: SupplyProductCreationSelectMenu;
};

export type SupplyProductCreationRoomCode = {
  __typename?: "SupplyProductCreationRoomCode";
  header: SupplyProductCreationMoreInfoTrigger;
  radioGroup: SupplyProductCreationRadioGroup;
};

export type SupplyProductCreationRoomInfo = {
  __typename?: "SupplyProductCreationRoomInfo";
  basicInfo: SupplyProductCreationRoomBasicInfo;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  extraBeds?: Maybe<SupplyProductCreationExtraBedSection>;
  otherSettings?: Maybe<SupplyProductCreationRoomOtherSettings>;
  recommendedOccupancy: SupplyProductCreationRecommendedOccupancy;
  roomTypeKey?: Maybe<Scalars["String"]["output"]>;
  sleepingSpace: SupplyProductCreationSleepingSpaceSettings;
};

export type SupplyProductCreationRoomInfoExperience = {
  __typename?: "SupplyProductCreationRoomInfoExperience";
  previewBedConfiguration?: Maybe<SupplyProductCreationPreviewBedConfigurationResult>;
  roomInfo?: Maybe<SupplyProductCreationRoomInfoSection>;
};

export type SupplyProductCreationRoomInfoExperiencePreviewBedConfigurationArgs = {
  context: ContextInput;
  previewBedConfigurationInput: SupplyProductCreationPreviewBedConfigurationInput;
};

export type SupplyProductCreationRoomInfoExperienceRoomInfoArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
  roomInfoInput: SupplyProductCreationRoomInfoInput;
};

export interface SupplyProductCreationRoomInfoInput {
  copyRoomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SupplyProductCreationRoomInfoManagementInput {
  legacyBedIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  maxOccupancy: Scalars["Int"]["input"];
  numberOfBedrooms?: InputMaybe<Scalars["Int"]["input"]>;
  numberOfLivingRooms?: InputMaybe<Scalars["Int"]["input"]>;
  numberOfRooms?: InputMaybe<Scalars["Int"]["input"]>;
  roomClass?: InputMaybe<Scalars["String"]["input"]>;
  roomType: Scalars["String"]["input"];
  roomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
  smokingPolicy: Scalars["String"]["input"];
  spaces: Array<SupplyProductCreationSpace>;
}

export type SupplyProductCreationRoomInfoSection = SupplyProductCreationErrorSection | SupplyProductCreationRoomInfo;

export type SupplyProductCreationRoomNameAttribute =
  | SupplyProductCreationCheckbox
  | SupplyProductCreationRoomNameRequiredAttribute;

export type SupplyProductCreationRoomNameAttributeCategoryName =
  | "ACCESSIBILITY"
  | "AREA"
  | "BEDROOM_DETAILS"
  | "FEATURE_AMENITY"
  | "OTHER_BEDROOM_DETAILS"
  | "OUTDOOR_SPACE"
  | "ROOM_CLASS"
  | "ROOM_TYPE"
  | "SMOKING_POLICY"
  | "VIEW";

export type SupplyProductCreationRoomNameAttributeGroup = {
  __typename?: "SupplyProductCreationRoomNameAttributeGroup";
  attributes: Array<SupplyProductCreationRoomNameAttribute>;
  heading: Scalars["String"]["output"];
  maxSelection?: Maybe<Scalars["Float"]["output"]>;
};

export interface SupplyProductCreationRoomNameAttributeInput {
  attributeId: Scalars["String"]["input"];
  sectionId: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
}

export interface SupplyProductCreationRoomNameAttributesInput {
  attributes: Array<SupplyProductCreationRoomNameAttributeInput>;
  roomTypeKey?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyProductCreationRoomNameCard = {
  __typename?: "SupplyProductCreationRoomNameCard";
  button: SupplyProductCreationButton;
  icon: Scalars["String"]["output"];
  roomNameDisplay: SupplyProductCreationRoomNameDisplay;
};

export type SupplyProductCreationRoomNameContent = {
  __typename?: "SupplyProductCreationRoomNameContent";
  accessibility: Scalars["String"]["output"];
  attributes: SupplyProductCreationRoomNameAttributeGroup;
  description: Scalars["String"]["output"];
  features: SupplyProductCreationRoomNameAttributeGroup;
  roomAmenityInfo?: Maybe<SupplyProductCreationRoomAmenityInfo>;
  roomNameDisplay: SupplyProductCreationRoomNameDisplay;
};

export type SupplyProductCreationRoomNameContentSection =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationRoomNameContent;

export type SupplyProductCreationRoomNameDisplay = {
  __typename?: "SupplyProductCreationRoomNameDisplay";
  description: Scalars["String"]["output"];
  roomName: Scalars["String"]["output"];
};

export type SupplyProductCreationRoomNameExperience = {
  __typename?: "SupplyProductCreationRoomNameExperience";
  manageNameSideSheet?: Maybe<SupplyProductCreationRoomNameSideSheetExperience>;
  reviewNameCard?: Maybe<SupplyProductCreationReviewRoomNameExperience>;
};

export type SupplyProductCreationRoomNameExperienceManageNameSideSheetArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameInput;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationRoomNameExperienceReviewNameCardArgs = {
  context: ContextInput;
  input: SupplyProductCreationRoomNameInput;
  propertyContext: PropertyContext;
};

export interface SupplyProductCreationRoomNameInput {
  isAddPath?: InputMaybe<Scalars["Boolean"]["input"]>;
  roomTypeKey: Scalars["String"]["input"];
}

export type SupplyProductCreationRoomNameManagementResult = {
  __typename?: "SupplyProductCreationRoomNameManagementResult";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  roomName?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationRoomNameRequiredAttribute = {
  __typename?: "SupplyProductCreationRoomNameRequiredAttribute";
  description?: Maybe<Scalars["String"]["output"]>;
  icon: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyProductCreationRoomNameSideSheet = {
  __typename?: "SupplyProductCreationRoomNameSideSheet";
  button: SupplyProductCreationButton;
  closeAccessibility: Scalars["String"]["output"];
  contentSection: SupplyProductCreationRoomNameContentSection;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  openAccessibility: Scalars["String"]["output"];
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyProductCreationRoomNameSideSheetExperience =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationRoomNameSideSheet;

export interface SupplyProductCreationRoomNameUpdateInput {
  roomTypeId: Scalars["String"]["input"];
}

export type SupplyProductCreationRoomOtherSettings = {
  __typename?: "SupplyProductCreationRoomOtherSettings";
  ariEnabled?: Maybe<SupplyProductCreationAriEnabled>;
  header: SupplyProductCreationSectionHeader;
  roomCode?: Maybe<SupplyProductCreationRoomCode>;
  roomTaxes?: Maybe<SupplyProductCreationRoomTaxes>;
};

export type SupplyProductCreationRoomOverviewEmptyState =
  | SupplyProductCreationFinishedRoomsEmptyState
  | SupplyProductCreationUnfinishedRoomsEmptyState;

export type SupplyProductCreationRoomOverviewExperience = {
  __typename?: "SupplyProductCreationRoomOverviewExperience";
  header?: Maybe<SupplyProductCreationRoomOverviewPageHeader>;
  mainContent?: Maybe<SupplyProductCreationRoomOverviewMainContentSection>;
};

export type SupplyProductCreationRoomOverviewExperienceHeaderArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationRoomOverviewExperienceMainContentArgs = {
  context: ContextInput;
  propertyContext: PropertyContext;
};

export type SupplyProductCreationRoomOverviewFullState = {
  __typename?: "SupplyProductCreationRoomOverviewFullState";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  instruction?: Maybe<SupplyProductCreationRoomOverviewInstruction>;
  mainContent: SupplyProductCreationRoomOverviewFullStateMainContent;
};

export type SupplyProductCreationRoomOverviewFullStateMainContent = {
  __typename?: "SupplyProductCreationRoomOverviewFullStateMainContent";
  collapsedLabel: EgdsCardinalLocalizedText;
  expandoLink: EgdsExpandoLink;
  invisibleRoomTypeCards: Array<SupplyProductCreationRoomTypeCard>;
  visibleRoomTypeCards: Array<SupplyProductCreationRoomTypeCard>;
};

export type SupplyProductCreationRoomOverviewInstruction =
  | EgdsPlainText
  | SupplyProductCreationAddAnotherRoomTypeInstruction;

export type SupplyProductCreationRoomOverviewMainContent = {
  __typename?: "SupplyProductCreationRoomOverviewMainContent";
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  navigationButtons?: Maybe<SupplyProductCreationRoomOverviewNavigationButtons>;
  roomsSection: SupplyProductCreationRoomOverviewRoomsSection;
};

export type SupplyProductCreationRoomOverviewMainContentSection =
  | SupplyProductCreationErrorSection
  | SupplyProductCreationRoomOverviewMainContent;

export type SupplyProductCreationRoomOverviewNavigationButtons = {
  __typename?: "SupplyProductCreationRoomOverviewNavigationButtons";
  backButton: SupplyProductCreationButton;
  nextButton: SupplyProductCreationButton;
};

export type SupplyProductCreationRoomOverviewPageHeader = {
  __typename?: "SupplyProductCreationRoomOverviewPageHeader";
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationRoomOverviewRoomsSection = {
  __typename?: "SupplyProductCreationRoomOverviewRoomsSection";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  tabContents: Array<SupplyProductCreationRoomOverviewTabContent>;
  tabs: SupplyProductCreationRoomOverviewTabs;
};

export type SupplyProductCreationRoomOverviewTab = {
  __typename?: "SupplyProductCreationRoomOverviewTab";
  emptyStateLabel: EgdsPlainText;
  fullStateLabel: EgdsCardinalLocalizedText;
  tabId: SupplyProductCreationRoomOverviewTabName;
};

export type SupplyProductCreationRoomOverviewTabContent = {
  __typename?: "SupplyProductCreationRoomOverviewTabContent";
  contentId: SupplyProductCreationRoomOverviewTabName;
  emptyState: SupplyProductCreationRoomOverviewEmptyState;
  fullState?: Maybe<SupplyProductCreationRoomOverviewFullState>;
};

export type SupplyProductCreationRoomOverviewTabName = "FINISHED_ROOMS" | "UNFINISHED_ROOMS";

export type SupplyProductCreationRoomOverviewTabs = {
  __typename?: "SupplyProductCreationRoomOverviewTabs";
  selectedTabId: SupplyProductCreationRoomOverviewTabName;
  tabs: Array<SupplyProductCreationRoomOverviewTab>;
};

export type SupplyProductCreationRoomTaxes = {
  __typename?: "SupplyProductCreationRoomTaxes";
  addMoreButton: SupplyProductCreationButton;
  header: SupplyProductCreationSectionHeader;
  taxes: Array<SupplyProductCreationTax>;
  visible: Scalars["Boolean"]["output"];
  whenUseThis: SupplyProductCreationMoreInfoTrigger;
};

export type SupplyProductCreationRoomTypeCard = {
  __typename?: "SupplyProductCreationRoomTypeCard";
  actions?: Maybe<SupplyProductCreationRoomTypeCardActions>;
  baseRate?: Maybe<SupplyProductCreationRoomTypeInfoPiece>;
  basicInfo: SupplyProductCreationRoomTypeInfoPiece;
  beds: SupplyProductCreationRoomTypeInfoPiece;
  roomTypeId: Scalars["String"]["output"];
};

export type SupplyProductCreationRoomTypeCardAction = {
  __typename?: "SupplyProductCreationRoomTypeCardAction";
  actionSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  label: Scalars["String"]["output"];
  targetUrl?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationRoomTypeCardActions = {
  __typename?: "SupplyProductCreationRoomTypeCardActions";
  actions: Array<SupplyProductCreationButton>;
  deleteConfirmationDialog: SupplyProductCreationRoomTypeDeleteConfirmationDialog;
  moreActions?: Maybe<SupplyProductCreationRoomTypeCardMoreActions>;
};

export type SupplyProductCreationRoomTypeCardMoreActions = {
  __typename?: "SupplyProductCreationRoomTypeCardMoreActions";
  actions: Array<SupplyProductCreationRoomTypeCardAction>;
  moreActionsButton: SupplyProductCreationButton;
};

export type SupplyProductCreationRoomTypeDeleteConfirmationDialog = {
  __typename?: "SupplyProductCreationRoomTypeDeleteConfirmationDialog";
  body: Scalars["String"]["output"];
  cancelButton: SupplyProductCreationButton;
  confirmButton: SupplyProductCreationButton;
  heading: Scalars["String"]["output"];
};

export type SupplyProductCreationRoomTypeInfoPiece = {
  __typename?: "SupplyProductCreationRoomTypeInfoPiece";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  badge?: Maybe<EgdsStandardBadge>;
  name: Scalars["String"]["output"];
  value: Array<Scalars["String"]["output"]>;
};

export type SupplyProductCreationSectionHeader = {
  __typename?: "SupplyProductCreationSectionHeader";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationSelect = {
  __typename?: "SupplyProductCreationSelect";
  heading: Scalars["String"]["output"];
  select: SupplyProductCreationSelectField;
};

export type SupplyProductCreationSelectField = {
  __typename?: "SupplyProductCreationSelectField";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  select: EgdsSelect;
};

export type SupplyProductCreationSelectMenu = {
  __typename?: "SupplyProductCreationSelectMenu";
  heading: Scalars["String"]["output"];
  select: SupplyProductCreationSelectMenuField;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationSelectMenuField = {
  __typename?: "SupplyProductCreationSelectMenuField";
  label: Scalars["String"]["output"];
  menu: SupplyProductCreationMenu;
  required: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationSideSheetRateSummary = {
  __typename?: "SupplyProductCreationSideSheetRateSummary";
  currencyCode: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  maxOccupancyBaseRate: Scalars["Float"]["output"];
  maxOccupancyExtraCharge: Scalars["Float"]["output"];
  ratePlanName?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationSleepingSpace = {
  __typename?: "SupplyProductCreationSleepingSpace";
  accessibility: Scalars["String"]["output"];
  addAnotherBedButton: SupplyProductCreationButton;
  bedConfiguration: SupplyProductCreationBedConfiguration;
  deleteButton: SupplyProductCreationButton;
  icon: Scalars["String"]["output"];
  items?: Maybe<Array<SupplyProductCreationSleepingSpaceItem>>;
  nameTemplate: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type SupplyProductCreationSleepingSpaceItem = {
  __typename?: "SupplyProductCreationSleepingSpaceItem";
  accessibility: Scalars["String"]["output"];
  addAnotherBedButton: SupplyProductCreationButton;
  beds: Array<SupplyProductCreationBedConfiguration>;
  id: Scalars["String"]["output"];
  name: EgdsIconText;
  type?: Maybe<Scalars["String"]["output"]>;
  typeNumber?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyProductCreationSleepingSpaceSettings = {
  __typename?: "SupplyProductCreationSleepingSpaceSettings";
  header: SupplyProductCreationSectionHeader;
  spaces?: Maybe<Array<SupplyProductCreationSleepingSpace>>;
};

export interface SupplyProductCreationSpace {
  bedTypeConfiguration?: InputMaybe<SupplyProductCreationBedTypeConfiguration>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  typeNumber?: InputMaybe<Scalars["Int"]["input"]>;
}

export type SupplyProductCreationSpannableText = {
  __typename?: "SupplyProductCreationSpannableText";
  contents: Array<SupplyProductCreationText>;
  text: Scalars["String"]["output"];
};

export type SupplyProductCreationStartEarlierThanEndDateInputValidation = SupplyProductCreationInputValidation & {
  __typename?: "SupplyProductCreationStartEarlierThanEndDateInputValidation";
  errorMessage: Scalars["String"]["output"];
};

export type SupplyProductCreationStepHeader = {
  __typename?: "SupplyProductCreationStepHeader";
  exitButton: SupplyProductCreationButton;
  leaveWithoutSavingDialog: SupplyProductCreationLeaveWithoutSavingDialog;
  title: SupplyProductCreationStepTitle;
};

export type SupplyProductCreationStepTitle = {
  __typename?: "SupplyProductCreationStepTitle";
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProductCreationSubSection = {
  __typename?: "SupplyProductCreationSubSection";
  id: Scalars["String"]["output"];
  section: SupplyProductCreationSubSectionItem;
  visible: Scalars["Boolean"]["output"];
};

export type SupplyProductCreationSubSectionItem =
  | SupplyProductCreationInformativeInputField
  | SupplyProductCreationInputField
  | SupplyProductCreationSelectMenuField;

export type SupplyProductCreationSwitch = {
  __typename?: "SupplyProductCreationSwitch";
  checked: Scalars["Boolean"]["output"];
  checkedLabel: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  uncheckedLabel: Scalars["String"]["output"];
};

export type SupplyProductCreationTax = {
  __typename?: "SupplyProductCreationTax";
  amount: SupplyProductCreationInputField;
  deleteButton?: Maybe<SupplyProductCreationButton>;
  endDate: SupplyProductCreationDatePickerInput;
  noEndDateCheckbox: SupplyProductCreationCommonCheckbox;
  startDate: SupplyProductCreationDatePickerInput;
};

export type SupplyProductCreationText = {
  __typename?: "SupplyProductCreationText";
  action?: Maybe<SupplyProductCreationTextAction>;
  text: EgdsText;
};

export type SupplyProductCreationTextAction = {
  __typename?: "SupplyProductCreationTextAction";
  clickedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyProductCreationTipCard = {
  __typename?: "SupplyProductCreationTipCard";
  contents: Array<SupplyProductCreationTipContent>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
};

export interface SupplyProductCreationTipCardInput {
  cardName: Scalars["String"]["input"];
}

export type SupplyProductCreationTipContent = EgdsBulletedList | SupplyProductCreationText;

export type SupplyProductCreationUnfinishedRoomsEmptyState = {
  __typename?: "SupplyProductCreationUnfinishedRoomsEmptyState";
  moreInfoTrigger: SupplyProductCreationMoreInfoTrigger;
  visible: Scalars["Boolean"]["output"];
};

export interface SupplyProductCreationUpdateStepStatusInput {
  type: SupplyProductCreationNavigationType;
}

export type SupplyProductCreationWhyThisIsValuableSection = {
  __typename?: "SupplyProductCreationWhyThisIsValuableSection";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  expando?: Maybe<EgdsExpando>;
  instructions: Array<Scalars["String"]["output"]>;
};

export type SupplyProgramsAcceptTermsAndConditions = {
  __typename?: "SupplyProgramsAcceptTermsAndConditions";
  offerIds: Array<Scalars["String"]["output"]>;
  program: Scalars["String"]["output"];
  propertyId: Scalars["String"]["output"];
};

export type SupplyProgramsAlignment = "CENTER" | "LEFT";

export type SupplyProgramsBadge = {
  __typename?: "SupplyProgramsBadge";
  primary: Scalars["String"]["output"];
  theme: Scalars["String"]["output"];
};

export type SupplyProgramsBadgeComponent = SupplyProgramsBadge | SupplyProgramsIconText;

export type SupplyProgramsBanner = {
  __typename?: "SupplyProgramsBanner";
  graphic?: Maybe<SupplyProgramsGraphic>;
  links?: Maybe<Array<EgdsStandardLink>>;
  primary: Scalars["String"]["output"];
  secondary: EgdsSpannableText;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProgramsBenefits = {
  __typename?: "SupplyProgramsBenefits";
  cards: Array<SupplyProgramsCard>;
  primary: Scalars["String"]["output"];
};

export type SupplyProgramsBreadcrumbComponent = EgdsPlainText | EgdsStandardLink;

export type SupplyProgramsButton = {
  __typename?: "SupplyProgramsButton";
  action?: Maybe<SupplyProgramsButtonAction>;
  button: SupplyProgramsStandardButton;
};

export type SupplyProgramsButtonAction =
  | SupplyProgramsAcceptTermsAndConditions
  | SupplyProgramsCreateRatePlanAction
  | SupplyProgramsOpenSheetAction
  | SupplyProgramsPreferredLanguageAction;

export type SupplyProgramsCard = {
  __typename?: "SupplyProgramsCard";
  graphic?: Maybe<SupplyProgramsGraphic>;
  messageCard?: Maybe<SupplyProgramsMessagingCard>;
  popovers?: Maybe<Array<SupplyProgramsSheet>>;
  primary: Scalars["String"]["output"];
  secondaries: Array<SupplyProgramsText>;
  tooltip?: Maybe<SupplyProgramsTooltip>;
};

export type SupplyProgramsContent = {
  __typename?: "SupplyProgramsContent";
  alignment: SupplyProgramsAlignment;
  text?: Maybe<SupplyProgramsText>;
};

export type SupplyProgramsCreateRatePlanAction = {
  __typename?: "SupplyProgramsCreateRatePlanAction";
  url: Scalars["String"]["output"];
};

export type SupplyProgramsCreateRatePlanStep = {
  __typename?: "SupplyProgramsCreateRatePlanStep";
  contents?: Maybe<Array<SupplyProgramsContent>>;
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  messageCard?: Maybe<SupplyProgramsMessagingCard>;
  primary: Scalars["String"]["output"];
  primaryCta: SupplyProgramsButton;
  step: SupplyProgramsIconText;
};

export type SupplyProgramsEligibilityError = {
  __typename?: "SupplyProgramsEligibilityError";
  alignment?: Maybe<SupplyProgramsAlignment>;
  graphic?: Maybe<SupplyProgramsGraphic>;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type SupplyProgramsEnrollmentExperience = {
  __typename?: "SupplyProgramsEnrollmentExperience";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  benefits?: Maybe<SupplyProgramsBenefits>;
  error?: Maybe<SupplyProgramsError>;
  faq?: Maybe<SupplyProgramsFaq>;
  header?: Maybe<SupplyProgramsHeader>;
  howItWorks?: Maybe<SupplyProgramsHowItWorks>;
  licensePlate?: Maybe<SupplyProgramsLicensePlate>;
  postOptInMessagingCard?: Maybe<SupplyProgramsMessagingCard>;
  primaryAction?: Maybe<SupplyProgramsButton>;
  sideSheet?: Maybe<SupplyProgramsSheet>;
  termsLink?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProgramsEnrollmentExperienceAnalyticsPayloadArgs = {
  program: Scalars["String"]["input"];
};

export type SupplyProgramsEnrollmentExperienceBenefitsArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperienceFaqArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperienceHeaderArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperienceHowItWorksArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperienceLicensePlateArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperiencePostOptInMessagingCardArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperiencePrimaryActionArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperienceSideSheetArgs = {
  context: ContextInput;
  input: SupplyProgramsSideSheetInput;
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentExperienceTermsLinkArgs = {
  context: ContextInput;
  program: Scalars["String"]["input"];
  propertyContext: PropertyContext;
};

export type SupplyProgramsEnrollmentFlowComponent =
  | SupplyProgramsCreateRatePlanStep
  | SupplyProgramsSignTermsAndConditionsStep;

export type SupplyProgramsEnrollmentFlowTemplate = {
  __typename?: "SupplyProgramsEnrollmentFlowTemplate";
  components: Array<SupplyProgramsEnrollmentFlowComponent>;
  primary: Scalars["String"]["output"];
};

export type SupplyProgramsEnrollmentProgress = {
  __typename?: "SupplyProgramsEnrollmentProgress";
  badge: SupplyProgramsBadgeComponent;
  label: Scalars["String"]["output"];
};

export type SupplyProgramsEnrollmentStatus =
  | "ACCEPTED_TERMS"
  | "ACTIVE_ENROLLMENT"
  | "ELIGIBLE_TO_ENROLL"
  | "NOT_ELIGIBLE";

export type SupplyProgramsError = SupplyProgramsBanner | SupplyProgramsEligibilityError;

export type SupplyProgramsErrorTemplate = {
  __typename?: "SupplyProgramsErrorTemplate";
  error: SupplyProgramsError;
};

export type SupplyProgramsExpando = {
  __typename?: "SupplyProgramsExpando";
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  expando: EgdsExpandoCard;
  texts: Array<Scalars["String"]["output"]>;
};

export type SupplyProgramsFaq = {
  __typename?: "SupplyProgramsFAQ";
  faqs: Array<SupplyProgramsExpando>;
  primary: Scalars["String"]["output"];
  secondary: EgdsSpannableText;
};

export type SupplyProgramsFullTermsAndConditions = {
  __typename?: "SupplyProgramsFullTermsAndConditions";
  htmls: Array<Scalars["String"]["output"]>;
  preferredHtmls?: Maybe<Array<Scalars["String"]["output"]>>;
  primary: Scalars["String"]["output"];
};

export type SupplyProgramsGraphic = Icon | Illustration;

export type SupplyProgramsHeader = {
  __typename?: "SupplyProgramsHeader";
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyProgramsHowItWorks = {
  __typename?: "SupplyProgramsHowItWorks";
  banner?: Maybe<SupplyProgramsMessagingCard>;
  cards: Array<SupplyProgramsCard>;
  primary: Scalars["String"]["output"];
};

export type SupplyProgramsIconText = {
  __typename?: "SupplyProgramsIconText";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type SupplyProgramsLicensePlate = {
  __typename?: "SupplyProgramsLicensePlate";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  breadcrumbMenu?: Maybe<Array<SupplyProgramsBreadcrumbComponent>>;
  feedback?: Maybe<EgdsStandardLink>;
  primary: Scalars["String"]["output"];
  progress?: Maybe<SupplyProgramsEnrollmentProgress>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyProgramsMessagingCard = {
  __typename?: "SupplyProgramsMessagingCard";
  background?: Maybe<Scalars["String"]["output"]>;
  contents: Array<EgdsSpannableText>;
  graphic?: Maybe<SupplyProgramsGraphic>;
  links?: Maybe<Array<EgdsStandardLink>>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProgramsMutationResponse = {
  __typename?: "SupplyProgramsMutationResponse";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sideEffects?: Maybe<Array<SupplyProgramsSideEffects>>;
  status: SupplyProgramsResponseStatus;
};

export interface SupplyProgramsOffersAcceptanceInput {
  offerIds: Array<Scalars["String"]["input"]>;
  program: Scalars["String"]["input"];
}

export type SupplyProgramsOpenPopoverAction = {
  __typename?: "SupplyProgramsOpenPopoverAction";
  sheetType: SupplyProgramsSheetType;
};

export type SupplyProgramsOpenSheetAction = {
  __typename?: "SupplyProgramsOpenSheetAction";
  navType?: Maybe<SupplyProgramsToolbarNavType>;
  program: Scalars["String"]["output"];
  sheetType: SupplyProgramsSheetType;
  status?: Maybe<SupplyProgramsEnrollmentStatus>;
};

export type SupplyProgramsPopoverTemplate = {
  __typename?: "SupplyProgramsPopoverTemplate";
  messages: Array<Scalars["String"]["output"]>;
};

export type SupplyProgramsPreferredLanguage = {
  __typename?: "SupplyProgramsPreferredLanguage";
  disclaimerCard: SupplyProgramsMessagingCard;
  englishLanguageCta: SupplyProgramsButton;
  primaryCta: SupplyProgramsButton;
};

export type SupplyProgramsPreferredLanguageAction = {
  __typename?: "SupplyProgramsPreferredLanguageAction";
  placeholder?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProgramsResponseStatus = "FAILURE" | "SUCCESS";

export type SupplyProgramsSheet = {
  __typename?: "SupplyProgramsSheet";
  closeText?: Maybe<Scalars["String"]["output"]>;
  contentTemplate?: Maybe<SupplyProgramsSheetTemplate>;
  navType?: Maybe<SupplyProgramsToolbarNavType>;
  sheet: EgdsSheet;
  sheetType?: Maybe<SupplyProgramsSheetType>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyProgramsSheetFooter = {
  __typename?: "SupplyProgramsSheetFooter";
  contents?: Maybe<Array<SupplyProgramsContent>>;
  primaryCta?: Maybe<SupplyProgramsButton>;
};

export type SupplyProgramsSheetTemplate =
  | SupplyProgramsEnrollmentFlowTemplate
  | SupplyProgramsErrorTemplate
  | SupplyProgramsPopoverTemplate
  | SupplyProgramsViewTermsAndConditionsTemplate;

export type SupplyProgramsSheetType =
  | "ENROLLMENT"
  | "HOW_IT_WORKS_POPOVER"
  | "VIEW_TERMS_AND_CONDITIONS"
  | "VIEW_TERMS_AND_CONDITIONS_ONLY";

export type SupplyProgramsSideEffects = SupplyProgramsBanner;

export interface SupplyProgramsSideSheetInput {
  navType: SupplyProgramsToolbarNavType;
  program: Scalars["String"]["input"];
  sheetType: SupplyProgramsSheetType;
}

export type SupplyProgramsSignTermsAndConditionsStep = {
  __typename?: "SupplyProgramsSignTermsAndConditionsStep";
  contents?: Maybe<Array<SupplyProgramsContent>>;
  dateSigned?: Maybe<Scalars["String"]["output"]>;
  divider?: Maybe<Scalars["Boolean"]["output"]>;
  primary: Scalars["String"]["output"];
  primaryCta: SupplyProgramsButton;
  step: SupplyProgramsIconText;
};

export type SupplyProgramsStandardButton = UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type SupplyProgramsTermsAndConditionsComponent =
  | SupplyProgramsPreferredLanguage
  | SupplyProgramsTermsAndConditionsSection;

export type SupplyProgramsTermsAndConditionsSection = {
  __typename?: "SupplyProgramsTermsAndConditionsSection";
  highlights?: Maybe<Array<SupplyProgramsContent>>;
  primary: Scalars["String"]["output"];
  terms?: Maybe<SupplyProgramsFullTermsAndConditions>;
};

export type SupplyProgramsText = EgdsBulletedList | EgdsPlainText;

export type SupplyProgramsToolbarNavType = "BACK" | "CLOSE";

export type SupplyProgramsTooltip = {
  __typename?: "SupplyProgramsTooltip";
  action?: Maybe<SupplyProgramsTooltipAction>;
  /** @deprecated not used in the current design */
  button?: Maybe<SupplyProgramsButton>;
  graphic?: Maybe<SupplyProgramsGraphic>;
  /** @deprecated moved to contentTemplate in SupplyProgramsSheet */
  messages: Array<Scalars["String"]["output"]>;
};

export type SupplyProgramsTooltipAction = SupplyProgramsOpenPopoverAction;

export type SupplyProgramsViewTermsAndConditionsTemplate = {
  __typename?: "SupplyProgramsViewTermsAndConditionsTemplate";
  components: Array<SupplyProgramsTermsAndConditionsComponent>;
  footer?: Maybe<SupplyProgramsSheetFooter>;
  primary: Scalars["String"]["output"];
};

export type SupplyPromotionActionMenu = {
  __typename?: "SupplyPromotionActionMenu";
  actions: Array<SupplyPromotionLinkAction>;
};

export type SupplyPromotionBlockoutDates = {
  __typename?: "SupplyPromotionBlockoutDates";
  addBlockoutButton: SupplyPromotionButton;
  blockEndsLabel: Scalars["String"]["output"];
  blockStartsLabel: Scalars["String"]["output"];
  blockoutStatus: Scalars["String"]["output"];
  content: Scalars["String"]["output"];
  newBlockoutDateRangePicker: EgdsDateRangePicker;
  overlappingBlockoutDatesErrorText?: Maybe<Scalars["String"]["output"]>;
  removeBlockoutButton: SupplyPromotionButton;
  selectedBlockoutDates?: Maybe<Array<EgdsDateRangePicker>>;
  title: Scalars["String"]["output"];
};

export type SupplyPromotionBookingLimit = {
  __typename?: "SupplyPromotionBookingLimit";
  action: SupplyPromotionButton;
  input: SupplyPromotionNumberInput;
  inputTitle?: Maybe<Scalars["String"]["output"]>;
  remove: SupplyPromotionButton;
  showEditMode?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyPromotionBookingWindow = {
  __typename?: "SupplyPromotionBookingWindow";
  earlyBooking: SupplyPromotionNumberRange;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionBreadcrumbMenu = {
  __typename?: "SupplyPromotionBreadcrumbMenu";
  breadcrumbItems: Array<SupplyPromotionLinkAction>;
};

export type SupplyPromotionButton = {
  __typename?: "SupplyPromotionButton";
  action: EgdsButton;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionCard = {
  __typename?: "SupplyPromotionCard";
  checkbox?: Maybe<SupplyPromotionCheckbox>;
  content?: Maybe<EgdsSpannableText>;
  icon?: Maybe<Icon>;
};

export type SupplyPromotionCardListComponent = {
  __typename?: "SupplyPromotionCardListComponent";
  cards: Array<SupplyPromotionCard>;
};

export type SupplyPromotionCardSelectionComponent = {
  __typename?: "SupplyPromotionCardSelectionComponent";
  cards: Array<SupplyPromotionCard>;
  selectAll?: Maybe<SupplyPromotionCheckbox>;
};

export type SupplyPromotionCheckbox = {
  __typename?: "SupplyPromotionCheckbox";
  action: EgdsBasicCheckBox;
  analyticsDeselected?: Maybe<SupplyPromotionClickStreamData>;
  analyticsSelected?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyPromotionClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickStreamPartnerCommon>;
};

export type SupplyPromotionCoachingMessage = {
  __typename?: "SupplyPromotionCoachingMessage";
  /** @deprecated Deprecated in favor of contentBlocks */
  content?: Maybe<Array<SupplyPromotionCoachingMessageText>>;
  contentBlocks?: Maybe<Array<SupplyPromotionCoachingMessageContent>>;
  icon: Icon;
  link?: Maybe<SupplyPromotionLinkAction>;
  subTitles?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type SupplyPromotionCoachingMessageContent = {
  __typename?: "SupplyPromotionCoachingMessageContent";
  content?: Maybe<Array<SupplyPromotionCoachingMessageText>>;
};

export type SupplyPromotionCoachingMessageText = EgdsBulletedList | EgdsPlainText | EgdsSpannableText;

export type SupplyPromotionComponent =
  | SupplyPromotionCardListComponent
  | SupplyPromotionCardSelectionComponent
  | SupplyPromotionCoachingMessage;

export type SupplyPromotionContainer = {
  __typename?: "SupplyPromotionContainer";
  sections: Array<SupplyPromotionSection>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyPromotionCreateMembersOnlyDealsTiersInput {
  blockoutDates?: InputMaybe<Array<SupplyPromotionDateRangeInput>>;
  bookingDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  bookingLimit?: InputMaybe<Scalars["Int"]["input"]>;
  canStackToOtherPromotions?: InputMaybe<Scalars["Boolean"]["input"]>;
  isManaged?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNegotiated?: InputMaybe<Scalars["Boolean"]["input"]>;
  memberTiersDiscounts: SupplyPromotionMemberTiersDiscountsInput;
  name: Scalars["String"]["input"];
  promotionRunDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  ratePlanIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  restrictions?: InputMaybe<SupplyPromotionRestrictionsInput>;
  stayDates?: InputMaybe<SupplyPromotionDateRangeInput>;
}

export type SupplyPromotionCreateTieredMembersOnlyDeal = {
  __typename?: "SupplyPromotionCreateTieredMembersOnlyDeal";
  cancel: SupplyPromotionButton;
  clickStreamPageView?: Maybe<SupplyPromotionClickStreamData>;
  create: SupplyPromotionButton;
  legalDisclaimer: SupplyPromotionLegalDisclaimer;
  licensePlate: SupplyPromotionLicensePlate;
  sections: Array<SupplyPromotionMemberTiersSection>;
};

export type SupplyPromotionDatePicker = {
  __typename?: "SupplyPromotionDatePicker";
  datePicker: SupplyPromotionDatePickerUnion;
  endDateInputFieldLabel?: Maybe<Scalars["String"]["output"]>;
  startDateInputFieldLabel: Scalars["String"]["output"];
};

export type SupplyPromotionDatePickerUnion = EgdsDateRangePicker | EgdsSingleDatePicker;

export interface SupplyPromotionDateRangeInput {
  endDate?: InputMaybe<DateInput>;
  startDate: DateInput;
}

export type SupplyPromotionDates = {
  __typename?: "SupplyPromotionDates";
  content?: Maybe<Scalars["String"]["output"]>;
  dateLabel?: Maybe<Scalars["String"]["output"]>;
  dateRange?: Maybe<SupplyPromotionDatePicker>;
  singleDate?: Maybe<SupplyPromotionDatePicker>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionFilters = {
  __typename?: "SupplyPromotionFilters";
  filters: Array<SupplyPromotionPill>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionInternalSettings = {
  __typename?: "SupplyPromotionInternalSettings";
  content?: Maybe<Scalars["String"]["output"]>;
  managed?: Maybe<SupplyPromotionSwitch>;
  negotiated?: Maybe<SupplyPromotionSwitch>;
  titleText?: Maybe<SupplyPromotionText>;
};

export type SupplyPromotionLegalDisclaimer = {
  __typename?: "SupplyPromotionLegalDisclaimer";
  action?: Maybe<SupplyPromotionButton>;
  content?: Maybe<Array<Maybe<SupplyPromotionText>>>;
  sheet?: Maybe<SupplyPromotionLegalDisclaimerSheet>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionLegalDisclaimerSheet = {
  __typename?: "SupplyPromotionLegalDisclaimerSheet";
  content?: Maybe<Array<SupplyPromotionText>>;
  title?: Maybe<Scalars["String"]["output"]>;
  toolbar: SupplyPromotionToolbar;
};

export type SupplyPromotionLengthOfStay = {
  __typename?: "SupplyPromotionLengthOfStay";
  numberRange: SupplyPromotionNumberRange;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionLicensePlate = {
  __typename?: "SupplyPromotionLicensePlate";
  actionMenu: SupplyPromotionActionMenu;
  breadcrumbMenu: SupplyPromotionBreadcrumbMenu;
  content?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyPromotionLinkAction = {
  __typename?: "SupplyPromotionLinkAction";
  action: EgdsStandardLink;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionMemberTiersCombinePromotions = {
  __typename?: "SupplyPromotionMemberTiersCombinePromotions";
  content?: Maybe<Scalars["String"]["output"]>;
  editAction?: Maybe<SupplyPromotionButton>;
  status: SupplyPromotionSwitch;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionMemberTiersComponent =
  | SupplyPromotionBookingLimit
  | SupplyPromotionCoachingMessage
  | SupplyPromotionInternalSettings
  | SupplyPromotionMemberTiersCombinePromotions
  | SupplyPromotionMemberTiersDiscounts
  | SupplyPromotionName
  | SupplyPromotionRestrictions
  | SupplyPromotionRoomsAndRates
  | SupplyPromotionRunDates;

export type SupplyPromotionMemberTiersDiscount = {
  __typename?: "SupplyPromotionMemberTiersDiscount";
  discount: EgdsNumberInputField;
  discountInputAnalytics?: Maybe<SupplyPromotionClickStreamData>;
  status?: Maybe<SupplyPromotionSwitch>;
};

export type SupplyPromotionMemberTiersDiscounts = {
  __typename?: "SupplyPromotionMemberTiersDiscounts";
  content: Scalars["String"]["output"];
  link?: Maybe<SupplyPromotionLinkAction>;
  matchingNumberValidation?: Maybe<SupplyPromotionMemberTiersMultiInputNumberValidation>;
  memberDiscounts: Array<SupplyPromotionMemberTiersDiscount>;
  title: Scalars["String"]["output"];
};

export interface SupplyPromotionMemberTiersDiscountsInput {
  baseMemberDiscount: Scalars["Int"]["input"];
  middleMemberDiscount?: InputMaybe<Scalars["Int"]["input"]>;
  topMemberDiscount?: InputMaybe<Scalars["Int"]["input"]>;
}

export type SupplyPromotionMemberTiersMultiInputNumberValidation = {
  __typename?: "SupplyPromotionMemberTiersMultiInputNumberValidation";
  errorMessage: Scalars["String"]["output"];
};

export type SupplyPromotionMemberTiersSection = {
  __typename?: "SupplyPromotionMemberTiersSection";
  components: Array<SupplyPromotionMemberTiersComponent>;
  heading?: Maybe<Scalars["String"]["output"]>;
  subHeadings?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type SupplyPromotionMutationResponse = {
  __typename?: "SupplyPromotionMutationResponse";
  sideEffects?: Maybe<Array<SupplyPromotionSideEffects>>;
  status: SupplyPromotionResponseStatus;
};

export type SupplyPromotionName = {
  __typename?: "SupplyPromotionName";
  content?: Maybe<Scalars["String"]["output"]>;
  nameInput: EgdsTextInputField;
  nameInputAnalytics?: Maybe<SupplyPromotionClickStreamData>;
  title: Scalars["String"]["output"];
};

export type SupplyPromotionNavigateToHub = {
  __typename?: "SupplyPromotionNavigateToHub";
  url: Scalars["String"]["output"];
};

export type SupplyPromotionNotification = {
  __typename?: "SupplyPromotionNotification";
  notification: UiBanner;
  type: SupplyPromotionNotificationType;
};

export type SupplyPromotionNotificationType = "ERROR" | "SUCCESS";

export type SupplyPromotionNumberInput = {
  __typename?: "SupplyPromotionNumberInput";
  analytics?: Maybe<SupplyPromotionClickStreamData>;
  input: EgdsNumberInputField;
};

export type SupplyPromotionNumberRange = {
  __typename?: "SupplyPromotionNumberRange";
  max: SupplyPromotionNumberInput;
  maxLowerThanMinErrorText?: Maybe<Scalars["String"]["output"]>;
  min: SupplyPromotionNumberInput;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyPromotionNumberRangeInput {
  max: Scalars["Int"]["input"];
  min: Scalars["Int"]["input"];
}

export type SupplyPromotionPill = {
  __typename?: "SupplyPromotionPill";
  action: EgdsBasicPill;
  analyticsDeselected?: Maybe<SupplyPromotionClickStreamData>;
  analyticsSelected?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionPlayground = {
  __typename?: "SupplyPromotionPlayground";
  clickStreamPageView?: Maybe<SupplyPromotionClickStreamData>;
  inputs: Array<SupplyPromotionPlaygroundInput>;
  licensePlate: SupplyPromotionLicensePlate;
  /** @deprecated Deprecated in favor of inputs */
  roomsAndRates?: Maybe<SupplyPromotionSelect>;
  submit: SupplyPromotionButton;
};

export type SupplyPromotionPlaygroundInput = SupplyPromotionDates | SupplyPromotionSelect;

export interface SupplyPromotionPlaygroundInputs {
  memberLevel: Scalars["Int"]["input"];
  reservationDate: DateInput;
  roomRatePlanId: Scalars["String"]["input"];
  travelDates: SupplyPromotionDateRangeInput;
}

export type SupplyPromotionPlaygroundResult = {
  __typename?: "SupplyPromotionPlaygroundResult";
  bestDiscount?: Maybe<SupplyPromotionCoachingMessage>;
  promotionCards?: Maybe<Array<SupplyPromotionCard>>;
};

export type SupplyPromotionRateOption = {
  __typename?: "SupplyPromotionRateOption";
  filterMeta: Array<Scalars["String"]["output"]>;
  rateOption: SupplyPromotionCheckbox;
};

export type SupplyPromotionReservationDates = {
  __typename?: "SupplyPromotionReservationDates";
  advancedMode: SupplyPromotionButton;
  bookingAfterStayEndDateValidation: Scalars["String"]["output"];
  bookingAfterStayStartDateValidation: Scalars["String"]["output"];
  bookingDates: SupplyPromotionDates;
  initialReservationMode: SupplyPromotionReservationMode;
  runDates: SupplyPromotionDates;
  standardMode: SupplyPromotionButton;
  stayDates: SupplyPromotionDates;
  stayStartDateBookingWindowValidation?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionReservationMode = "ADVANCED" | "STANDARD";

export type SupplyPromotionResponseStatus = "FAIL" | "SUCCESS";

export type SupplyPromotionRestriction = SupplyPromotionBookingWindow | SupplyPromotionLengthOfStay;

export type SupplyPromotionRestrictions = {
  __typename?: "SupplyPromotionRestrictions";
  cancel?: Maybe<SupplyPromotionButton>;
  coachingContent?: Maybe<Array<SupplyPromotionText>>;
  coachingMessage?: Maybe<SupplyPromotionCoachingMessage>;
  content?: Maybe<Scalars["String"]["output"]>;
  edit?: Maybe<SupplyPromotionButton>;
  restrictions?: Maybe<Array<SupplyPromotionRestriction>>;
  showEditMode?: Maybe<Scalars["Boolean"]["output"]>;
  summary?: Maybe<Array<SupplyPromotionText>>;
  title: Scalars["String"]["output"];
};

export interface SupplyPromotionRestrictionsInput {
  earlyBooking?: InputMaybe<SupplyPromotionNumberRangeInput>;
  lengthOfStay?: InputMaybe<SupplyPromotionNumberRangeInput>;
}

export type SupplyPromotionRoomsAndRates = {
  __typename?: "SupplyPromotionRoomsAndRates";
  content: Scalars["String"]["output"];
  edit?: Maybe<SupplyPromotionButton>;
  reactiveContent: SupplyPromotionRoomsAndRatesLabels;
  sheet?: Maybe<SupplyPromotionRoomsAndRatesSheet>;
};

export type SupplyPromotionRoomsAndRatesLabels = {
  __typename?: "SupplyPromotionRoomsAndRatesLabels";
  noRoomsAndRates: Scalars["String"]["output"];
  roomsAndRates: Scalars["String"]["output"];
  roomsAndRatesAll: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of roomsAndRates */
  roomsAndRatesMany: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of roomsAndRates */
  roomsAndRatesSingle: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of roomsAndRates */
  roomsSingle: Scalars["String"]["output"];
};

export type SupplyPromotionRoomsAndRatesOption = {
  __typename?: "SupplyPromotionRoomsAndRatesOption";
  filterMeta: Array<Scalars["String"]["output"]>;
  rateOptions: Array<SupplyPromotionRateOption>;
  roomOption: SupplyPromotionCheckbox;
};

export type SupplyPromotionRoomsAndRatesSheet = {
  __typename?: "SupplyPromotionRoomsAndRatesSheet";
  actions: Array<SupplyPromotionButton>;
  allRoomsAndRatesOption: SupplyPromotionCheckbox;
  content: Scalars["String"]["output"];
  noRoomsAndRatesFoundLabel: Scalars["String"]["output"];
  roomsAndRatesOptions: Array<SupplyPromotionRoomsAndRatesOption>;
  searchFilters: SupplyPromotionFilters;
  searchInput: EgdsTextInputField;
  selectedLabel: SupplyPromotionRoomsAndRatesLabels;
  toolbar: SupplyPromotionToolbar;
};

export type SupplyPromotionRunDates = {
  __typename?: "SupplyPromotionRunDates";
  blockoutDateOccursBeforeRunStartDateErrorText: Scalars["String"]["output"];
  blockoutDates: SupplyPromotionBlockoutDates;
  content: Scalars["String"]["output"];
  reservationDates?: Maybe<SupplyPromotionReservationDates>;
  title: Scalars["String"]["output"];
};

export type SupplyPromotionSection = {
  __typename?: "SupplyPromotionSection";
  components: Array<SupplyPromotionComponent>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionSelect = {
  __typename?: "SupplyPromotionSelect";
  action: EgdsBasicSelect;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionSheetFooter = {
  __typename?: "SupplyPromotionSheetFooter";
  buttons: Array<SupplyPromotionButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPromotionSideEffects =
  | SupplyPromotionNavigateToHub
  | SupplyPromotionNotification
  | SupplyPromotionToast;

export type SupplyPromotionStackingOptionsSheet = {
  __typename?: "SupplyPromotionStackingOptionsSheet";
  actionFooter?: Maybe<SupplyPromotionSheetFooter>;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
  container?: Maybe<SupplyPromotionContainer>;
  content?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<SupplyPromotionToolbar>;
};

export type SupplyPromotionSwitch = {
  __typename?: "SupplyPromotionSwitch";
  action: UiToggle;
  analytics?: Maybe<SupplyPromotionClickStreamData>;
};

export type SupplyPromotionText = EgdsBulletedList | EgdsPlainText | EgdsSpannableText;

export type SupplyPromotionToast = {
  __typename?: "SupplyPromotionToast";
  analytics?: Maybe<SupplyPromotionClickStreamData>;
  toast: EgdsToast;
};

export type SupplyPromotionToolbar = {
  __typename?: "SupplyPromotionToolbar";
  analyticsPrimary?: Maybe<SupplyPromotionClickStreamData>;
  toolbar?: Maybe<UiToolbar>;
};

export interface SupplyPromotionUpdateMembersOnlyDealsTiersInput {
  blockoutDates?: InputMaybe<Array<SupplyPromotionDateRangeInput>>;
  bookingDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  bookingLimit?: InputMaybe<Scalars["Int"]["input"]>;
  canStackToOtherPromotions?: InputMaybe<Scalars["Boolean"]["input"]>;
  isManaged?: InputMaybe<Scalars["Boolean"]["input"]>;
  isNegotiated?: InputMaybe<Scalars["Boolean"]["input"]>;
  memberTiersDiscounts: SupplyPromotionMemberTiersDiscountsInput;
  name: Scalars["String"]["input"];
  promotionRunDates?: InputMaybe<SupplyPromotionDateRangeInput>;
  ratePlanIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  restrictions?: InputMaybe<SupplyPromotionRestrictionsInput>;
  stayDates?: InputMaybe<SupplyPromotionDateRangeInput>;
}

export type SupplyPromotionUpdateTieredMembersOnlyDeal = {
  __typename?: "SupplyPromotionUpdateTieredMembersOnlyDeal";
  cancel: SupplyPromotionButton;
  clickStreamPageView?: Maybe<SupplyPromotionClickStreamData>;
  legalDisclaimer: SupplyPromotionLegalDisclaimer;
  licensePlate: SupplyPromotionLicensePlate;
  sections: Array<SupplyPromotionMemberTiersSection>;
  update: SupplyPromotionButton;
};

export type SupplyPropertiesSummaryContent = EgdsIconText | SupplyAssociatedPropertiesSummary;

export type SupplyPropertyBaseRateAutomationSignUpView = {
  __typename?: "SupplyPropertyBaseRateAutomationSignUpView";
  clickstreamLinkSelected?: Maybe<RatesEgClickstreamData>;
  heading?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<EgdsStandardLink>;
  signUp: SignUpSwitch;
  tooltip?: Maybe<SignUpTooltip>;
};

export type SupplyPropertyCancelPolicyExceptionBanner = {
  __typename?: "SupplyPropertyCancelPolicyExceptionBanner";
  icon?: Maybe<Icon>;
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyPropertyCancelPolicyExceptionFooter = {
  __typename?: "SupplyPropertyCancelPolicyExceptionFooter";
  footerNote: Scalars["String"]["output"];
  saveAction?: Maybe<SupplyReservationsAction>;
};

export type SupplyPropertyCancelPolicyExceptionLegislationInfo = {
  __typename?: "SupplyPropertyCancelPolicyExceptionLegislationInfo";
  icon: Icon;
  message: Scalars["String"]["output"];
};

export type SupplyPropertyCancelPolicyExceptionWaiverRules = {
  __typename?: "SupplyPropertyCancelPolicyExceptionWaiverRules";
  title: Scalars["String"]["output"];
  waiverRules: EgdsCheckBoxGroup;
};

export type SupplyPropertyCancelPolicyExceptionsContent = {
  __typename?: "SupplyPropertyCancelPolicyExceptionsContent";
  errorBanner: SupplyPropertyCancelPolicyExceptionBanner;
  footer: SupplyPropertyCancelPolicyExceptionFooter;
  legislationInformation?: Maybe<SupplyPropertyCancelPolicyExceptionLegislationInfo>;
  toolbar: SupplyPropertyCancelPolicyExceptionsToolbar;
  waiversSection: SupplyPropertyCancelPolicyExceptionWaiverRules;
};

export type SupplyPropertyCancelPolicyExceptionsToolbar = {
  __typename?: "SupplyPropertyCancelPolicyExceptionsToolbar";
  closeAction: SupplyReservationsAction;
  title: Scalars["String"]["output"];
};

export type SupplyPropertyEditorPage = {
  __typename?: "SupplyPropertyEditorPage";
  header: SupplyPropertyEditorPageHeader;
};

export type SupplyPropertyEditorPageHeader = {
  __typename?: "SupplyPropertyEditorPageHeader";
  description?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyPropertyFilterEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyPropertyFilterEgClickstream";
  additionalContext?: Maybe<EgTypeaheadAdditionalContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  mapProductDetail: EgPropertyCount;
  typeahead?: Maybe<EgTypeahead>;
};

export type SupplyPropertyFilterHeader = {
  __typename?: "SupplyPropertyFilterHeader";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPropertyFilterSection = {
  __typename?: "SupplyPropertyFilterSection";
  header?: Maybe<SupplyPropertyFilterHeader>;
  propertiesSummaryContent: SupplyPropertiesSummaryContent;
  /** @deprecated Use AnalyticsPayload instead */
  propertyFilterPresented: SupplyPropertyFilterEgClickstream;
  propertyFilterPresentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  propertySearch?: Maybe<EgdsTextInputField>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchCleared?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchClearedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchInputted?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchInputtedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchPresented?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchPresentedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchSelected?: Maybe<SupplyPropertyFilterEgClickstream>;
  propertySearchSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  secondaryButton?: Maybe<SupplySeeAllPropertiesButton>;
  /** @deprecated Use AnalyticsPayload instead */
  seeAllPropertiesSelected?: Maybe<SupplyPropertyFilterEgClickstream>;
  seeAllPropertiesSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  travelerLink?: Maybe<EgdsStandardLink>;
  travelerLinkPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  travelerLinkSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyPropertyFinderSearchResponse = {
  __typename?: "SupplyPropertyFinderSearchResponse";
  error?: Maybe<EgdsErrorSummary>;
  success?: Maybe<SupplyPropertyFinderSearchResultsView>;
};

export type SupplyPropertyFinderSearchResult = {
  __typename?: "SupplyPropertyFinderSearchResult";
  propertyId: Scalars["String"]["output"];
  propertyName: Scalars["String"]["output"];
  propertyOppyHubUrl: Scalars["String"]["output"];
};

export type SupplyPropertyFinderSearchResultsView = {
  __typename?: "SupplyPropertyFinderSearchResultsView";
  results: Array<SupplyPropertyFinderSearchResult>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyPropertyFinderTipTypeSelection = {
  __typename?: "SupplyPropertyFinderTipTypeSelection";
  label: Scalars["String"]["output"];
  options: Array<SupplyPropertyFinderTipTypeSelectionOption>;
  selected?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyPropertyFinderTipTypeSelectionOption = {
  __typename?: "SupplyPropertyFinderTipTypeSelectionOption";
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyPropertyListItemActionContent = {
  __typename?: "SupplyPropertyListItemActionContent";
  description: EgdsStylizedText;
  icon?: Maybe<Icon>;
  link?: Maybe<UiLinkAction>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use AnalyticsPayload instead */
  propertySearchItemSelected: SupplyPropertyFilterEgClickstream;
  propertySearchItemSelectedV2?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  statusMessage?: Maybe<EgdsStylizedText>;
  thumbnail: SupplyPropertyThumbnailImage;
  title: EgdsStylizedText;
};

export interface SupplyPropertyRateInput {
  baseRate: Scalars["Float"]["input"];
  currencyCode: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
}

export type SupplyPropertyRatesBankingCountryCurrencyView = {
  __typename?: "SupplyPropertyRatesBankingCountryCurrencyView";
  bankingCountryHeading: Scalars["String"]["output"];
  bankingCountrySelect: EgdsBasicSelect;
  bankingCountrySubHeading: Scalars["String"]["output"];
  bankingCurrencySelect: SupplyPropertyRatesBankingCurrencySelect;
  bankingCurrencySelectV1?: Maybe<SupplyPropertyRatesBankingCurrencySelect>;
  clickstreamBankingInputted: RatesEgClickstreamData;
  clickstreamBankingSelected: RatesEgClickstreamData;
  clickstreamCurrencyInputted: RatesEgClickstreamData;
  clickstreamCurrencyInputtedV1?: Maybe<RatesEgClickstreamData>;
  clickstreamCurrencySelected: RatesEgClickstreamData;
  clickstreamCurrencySelectedV1?: Maybe<RatesEgClickstreamData>;
  currencyHeading: Scalars["String"]["output"];
  currencyHeadingV1?: Maybe<Scalars["String"]["output"]>;
  currencySubHeading: Scalars["String"]["output"];
  currencySubHeadingV1?: Maybe<Scalars["String"]["output"]>;
  ratesReadySignal: Scalars["String"]["output"];
  saveErrorSignal: Scalars["String"]["output"];
  signal: Scalars["String"]["output"];
};

export type SupplyPropertyRatesBankingCurrencySelect = {
  __typename?: "SupplyPropertyRatesBankingCurrencySelect";
  disabled: Scalars["Boolean"]["output"];
  label: Scalars["String"]["output"];
  options: Array<SupplyPropertyRatesCurrencyOptionV2>;
};

export type SupplyPropertyRatesCurrencyOption = EgdsOption & {
  __typename?: "SupplyPropertyRatesCurrencyOption";
  acceptedCountries: Array<Scalars["String"]["output"]>;
  currencySymbol: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  value: Scalars["String"]["output"];
};

export type SupplyPropertyRatesCurrencyOptionV2 = {
  __typename?: "SupplyPropertyRatesCurrencyOptionV2";
  acceptedCountries: Array<Scalars["String"]["output"]>;
  currencySymbol: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  selected?: Maybe<Scalars["Boolean"]["output"]>;
  value: Scalars["String"]["output"];
};

export type SupplyPropertyRatesErrorView = {
  __typename?: "SupplyPropertyRatesErrorView";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
};

export type SupplyPropertyRatesNightlyBaseRateView = {
  __typename?: "SupplyPropertyRatesNightlyBaseRateView";
  clickstreamNightlyBaseRateInputted: RatesEgClickstreamData;
  clickstreamNightlyBaseRateSelected: RatesEgClickstreamData;
  currency?: Maybe<Scalars["String"]["output"]>;
  currencySymbol?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  /** @deprecated Deprecated because it is not used anymore */
  iconText: EgdsIconText;
  inputHint?: Maybe<Scalars["String"]["output"]>;
  rateInput: EgdsNumberInputField;
  saveErrorSignal: Scalars["String"]["output"];
  signal: Scalars["String"]["output"];
  /** @deprecated Deprecated in favor of subHeadingV1 */
  subHeading: Scalars["String"]["output"];
  subHeadingV1?: Maybe<SupplyPropertyRatesSubHeadingUnion>;
};

export type SupplyPropertyRatesOfferView = {
  __typename?: "SupplyPropertyRatesOfferView";
  clickstreamDiscountCheckSelected: RatesEgClickstreamData;
  clickstreamDiscountLinkSelected: RatesEgClickstreamData;
  discountInfo: EgdsStandardLink;
  heading: Scalars["String"]["output"];
  options: EgdsBasicRadioGroup;
  saveErrorSignal: Scalars["String"]["output"];
  subHeading: Scalars["String"]["output"];
};

export type SupplyPropertyRatesSubHeading = {
  __typename?: "SupplyPropertyRatesSubHeading";
  text: Scalars["String"]["output"];
};

export type SupplyPropertyRatesSubHeadingRecommendation = {
  __typename?: "SupplyPropertyRatesSubHeadingRecommendation";
  clickstreamRateMLRecommendation?: Maybe<RatesEgClickstreamData>;
  clickstreamRateMLTooltip?: Maybe<RatesEgClickstreamData>;
  icon: Icon;
  moreInfoText: Scalars["String"]["output"];
  text: EgdsSpannableText;
};

export type SupplyPropertyRatesSubHeadingUnion =
  | SupplyPropertyRatesSubHeading
  | SupplyPropertyRatesSubHeadingRecommendation;

export type SupplyPropertySearchResultContent = SupplyAssociatedPropertiesSummary | SupplyEmptyPropertiesSearchResult;

export type SupplyPropertyThumbnailImage = Icon | Image;

export type SupplyPublishSignalAction = {
  __typename?: "SupplyPublishSignalAction";
  signal: Scalars["String"]["output"];
};

export type SupplyQuickActionAcknowledgeButton = {
  __typename?: "SupplyQuickActionAcknowledgeButton";
  button: EgdsButton;
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
};

export interface SupplyQuickActionAction {
  analytics: EgClickstreamEvent;
}

export type SupplyQuickActionActionedSummaryViewAction = {
  __typename?: "SupplyQuickActionActionedSummaryViewAction";
  button: EgdsButton;
  message: EgdsErrorSummary;
};

export type SupplyQuickActionCard = {
  __typename?: "SupplyQuickActionCard";
  backgroundTheme?: Maybe<Scalars["String"]["output"]>;
  cardElements: Array<SupplyQuickActionCardElements>;
  icon?: Maybe<Icon>;
  link?: Maybe<EgdsStandardLink>;
};

export type SupplyQuickActionCardElements = SupplyQuickActionSpacing | SupplyQuickActionText;

export type SupplyQuickActionCheckBoxGroupInput = {
  __typename?: "SupplyQuickActionCheckBoxGroupInput";
  checkBoxGroup: EgdsCheckBoxGroup;
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SupplyQuickActionClickStreamPartnerCommon = {
  __typename?: "SupplyQuickActionClickStreamPartnerCommon";
  promotion_id?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyQuickActionComparisonType = "GREATER_THAN" | "LESSER_THAN";

export type SupplyQuickActionComparisonValidation = {
  __typename?: "SupplyQuickActionComparisonValidation";
  comparator: SupplyQuickActionComparisonType;
  errorMessage: Scalars["String"]["output"];
  formIdToCompare: Scalars["String"]["output"];
};

export type SupplyQuickActionEgdsValidation = {
  __typename?: "SupplyQuickActionEGDSValidation";
  validation: EgdsInputValidation;
};

export type SupplyQuickActionEgClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyQuickActionEgClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<SupplyQuickActionClickStreamPartnerCommon>;
  virtual_market_coach?: Maybe<SupplyVmcEgClickstreamCustomAttribute>;
};

export type SupplyQuickActionElementVisibility = "HIDE_SELF" | "SHOW_SELF";

export type SupplyQuickActionExpando = {
  __typename?: "SupplyQuickActionExpando";
  collapsedLabel: Scalars["String"]["output"];
  /** @deprecated Please use expanded Items for the expando content */
  expandedContent: Scalars["String"]["output"];
  expandedItems: Array<SupplyQuickActionExpandoContent>;
  expandedLabel: Scalars["String"]["output"];
};

export type SupplyQuickActionExpandoContent =
  | EgdsBulletedList
  | EgdsPlainText
  | EgdsStandardLink
  | SupplyQuickActionSpacing
  | UiSecondaryButton
  | UiTertiaryButton;

export type SupplyQuickActionExternalElement = {
  __typename?: "SupplyQuickActionExternalElement";
  elementId: Scalars["String"]["output"];
  parameters?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyQuickActionFieldProcessor = {
  __typename?: "SupplyQuickActionFieldProcessor";
  operation: SupplyQuickActionFieldProcessorOperation;
  pattern: Scalars["String"]["output"];
  publisherInputId: Scalars["String"]["output"];
};

export type SupplyQuickActionFieldProcessorOperation =
  | "PERCENT_CALC_AND_REPLACE"
  | "PLAIN_REPLACE"
  | "SUBTRACT_AND_REPLACE";

export type SupplyQuickActionFieldSet = {
  __typename?: "SupplyQuickActionFieldSet";
  description?: Maybe<Scalars["String"]["output"]>;
  direction?: Maybe<SupplyQuickActionFieldSetDirection>;
  elements: Array<SupplyQuickActionFieldSetElement>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyQuickActionFieldSetDirection = "HORIZONTAL" | "VERTICAL";

export type SupplyQuickActionFieldSetElement =
  | SupplyQuickActionCard
  | SupplyQuickActionExpando
  | SupplyQuickActionFormInput
  | SupplyQuickActionSpacing
  | SupplyQuickActionTable
  | SupplyQuickActionText;

export interface SupplyQuickActionForm {
  formId: Scalars["String"]["output"];
}

export type SupplyQuickActionFormContext = {
  __typename?: "SupplyQuickActionFormContext";
  key: Scalars["String"]["output"];
  values: Array<Scalars["String"]["output"]>;
};

export interface SupplyQuickActionFormContextInput {
  key: Scalars["String"]["input"];
  values: Array<Scalars["String"]["input"]>;
}

export type SupplyQuickActionFormInput = SupplyQuickActionSubscribeElement & {
  __typename?: "SupplyQuickActionFormInput";
  formValidations: Array<SupplyQuickActionFormInputValidation>;
  inputComponent: SupplyQuickActionFormInputElement;
  inputId: Scalars["String"]["output"];
  publishes: Array<SupplyQuickActionPublishEvent>;
  subscribesTo: Array<SupplyQuickActionSubscribeEvent>;
  /** @deprecated This field will be deprecated in the future, use formValidations instead */
  validations: Array<EgdsInputValidation>;
};

export type SupplyQuickActionFormInputElement =
  | EgdsBasicCheckBox
  | EgdsBasicRadioGroup
  | EgdsBasicStepInput
  | EgdsCompositeDatePicker
  | EgdsNumberInputField
  | EgdsSingleDatePicker
  | EgdsStandardSwitch
  | EgdsTextInputField
  | SupplyQuickActionCheckBoxGroupInput
  | SupplyQuickActionSlider;

export type SupplyQuickActionFormInputValidation =
  | SupplyQuickActionComparisonValidation
  | SupplyQuickActionEgdsValidation;

export type SupplyQuickActionFromTemplateErrorDetail = {
  __typename?: "SupplyQuickActionFromTemplateErrorDetail";
  errorContext?: Maybe<Scalars["String"]["output"]>;
  errorMessage: Scalars["String"]["output"];
  errorValue?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyQuickActionFromTemplateErrorResponse = {
  __typename?: "SupplyQuickActionFromTemplateErrorResponse";
  errorDetail: Array<SupplyQuickActionFromTemplateErrorDetail>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyQuickActionFromTemplateResponse = {
  __typename?: "SupplyQuickActionFromTemplateResponse";
  quickActionSheet?: Maybe<SupplyQuickActionSheet>;
  templateErrorResponse?: Maybe<SupplyQuickActionFromTemplateErrorResponse>;
};

export type SupplyQuickActionHostedViewAnalytics = {
  __typename?: "SupplyQuickActionHostedViewAnalytics";
  sheetAnalytics?: Maybe<SupplyQuickActionSheetEventAnalytics>;
  submitAnalytics?: Maybe<SupplyQuickActionSubmitAnalytics>;
};

export type SupplyQuickActionOpenHostedViewAction = {
  __typename?: "SupplyQuickActionOpenHostedViewAction";
  clickStreamData?: Maybe<SupplyQuickActionHostedViewAnalytics>;
  errorMessage: EgdsErrorSummary;
  url: HttpUri;
};

export type SupplyQuickActionOpenSheetAction = SupplyQuickActionAction & {
  __typename?: "SupplyQuickActionOpenSheetAction";
  /** @deprecated We are not using analytics field */
  analytics: EgClickstreamEvent;
  sheet: SupplyQuickActionSheet;
};

export type SupplyQuickActionPublishEvent = {
  __typename?: "SupplyQuickActionPublishEvent";
  type: Scalars["String"]["output"];
};

export type SupplyQuickActionRedirectButton = {
  __typename?: "SupplyQuickActionRedirectButton";
  button: EgdsButton;
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
  link: HttpUri;
};

export type SupplyQuickActionSendCardInteractionSignal = {
  __typename?: "SupplyQuickActionSendCardInteractionSignal";
  payload?: Maybe<SupplyQuickActionSendCardInteractionSignalPayload>;
  signalType: Scalars["String"]["output"];
};

export type SupplyQuickActionSendCardInteractionSignalPayload = {
  __typename?: "SupplyQuickActionSendCardInteractionSignalPayload";
  clickStreamData?: Maybe<SupplyQuickActionSheetEventAnalytics>;
  coachingTipId: Scalars["String"]["output"];
  submitContext?: Maybe<Array<SupplyQuickActionFormContext>>;
};

export type SupplyQuickActionSheet = SupplyQuickActionForm & {
  __typename?: "SupplyQuickActionSheet";
  clickStreamData?: Maybe<SupplyQuickActionSheetEventAnalytics>;
  elements: Array<SupplyQuickActionSheetElement>;
  formId: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  sheet: EgdsSheet;
  submit: Array<SupplyQuickActionSubmitElements>;
};

export type SupplyQuickActionSheetElement =
  | SupplyQuickActionCard
  | SupplyQuickActionExpando
  | SupplyQuickActionExternalElement
  | SupplyQuickActionFieldSet
  | SupplyQuickActionFormInput
  | SupplyQuickActionSpacing
  | SupplyQuickActionTable
  | SupplyQuickActionText
  | UiBanner;

export type SupplyQuickActionSheetEventAnalytics = {
  __typename?: "SupplyQuickActionSheetEventAnalytics";
  /** @deprecated This field will be deprecated in the future, use quickActionSheetClosedAnalyticsPayload instead */
  quickActionSheetClosed?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSheetClosedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionSheetPresentedAnalyticsPayload instead */
  quickActionSheetPresented?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSheetPresentedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyQuickActionSlider = {
  __typename?: "SupplyQuickActionSlider";
  enabled?: Maybe<Scalars["Boolean"]["output"]>;
  range: RangeValue;
  step: Scalars["Int"]["output"];
};

export type SupplyQuickActionSpacing = {
  __typename?: "SupplyQuickActionSpacing";
  horizontal?: Maybe<SupplyQuickActionSpacingSize>;
  horizontalRule?: Maybe<Scalars["Boolean"]["output"]>;
  vertical?: Maybe<SupplyQuickActionSpacingSize>;
};

export type SupplyQuickActionSpacingSize =
  | "SPACING__0X__HALF"
  | "SPACING__1X"
  | "SPACING__2X"
  | "SPACING__3X"
  | "SPACING__4X"
  | "SPACING__5X"
  | "SPACING__6X"
  | "SPACING__8X"
  | "SPACING__10X"
  | "SPACING__11X"
  | "SPACING__12X"
  | "SPACING__14X"
  | "SPACING__16X";

export type SupplyQuickActionSubmitAnalytics = {
  __typename?: "SupplyQuickActionSubmitAnalytics";
  quickActionAcknowledgedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionFailedAnalyticsPayload instead */
  quickActionFailed?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionFailedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  quickActionRedirectedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionSubmittedAnalyticsPayload instead */
  quickActionSubmitted?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSubmittedAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use quickActionSucceededAnalyticsPayload instead */
  quickActionSucceeded?: Maybe<SupplyQuickActionEgClickStreamData>;
  quickActionSucceededAnalyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyQuickActionSubmitButton = {
  __typename?: "SupplyQuickActionSubmitButton";
  actionContext?: Maybe<Array<SupplyQuickActionFormContext>>;
  alwaysDisabled?: Maybe<Scalars["Boolean"]["output"]>;
  button: EgdsButton;
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
};

export type SupplyQuickActionSubmitContentElement =
  | SupplyCoachingFeedbackComponent
  | SupplyQuickActionSpacing
  | SupplyQuickActionSubmitDetailList
  | SupplyQuickActionText
  | UiSecondaryButton;

export type SupplyQuickActionSubmitDetailList = {
  __typename?: "SupplyQuickActionSubmitDetailList";
  submitDetailListItems: Array<SupplyQuickActionSubmitDetailListItem>;
};

export type SupplyQuickActionSubmitDetailListItem = SupplyQuickActionSpacing | SupplyQuickActionText;

export type SupplyQuickActionSubmitElements =
  | SupplyQuickActionAcknowledgeButton
  | SupplyQuickActionRedirectButton
  | SupplyQuickActionSubmitButton
  | SupplyQuickActionSubmitPostpone;

export type SupplyQuickActionSubmitPanelFromTemplateErrorResponse = {
  __typename?: "SupplyQuickActionSubmitPanelFromTemplateErrorResponse";
  coachingTipError?: Maybe<SupplyQuickActionFromTemplateErrorDetail>;
  dynamicContentError?: Maybe<SupplyQuickActionFromTemplateErrorDetail>;
  submitPanelTemplateError?: Maybe<SupplyQuickActionFromTemplateErrorDetail>;
};

export type SupplyQuickActionSubmitPanelFromTemplateResponse = {
  __typename?: "SupplyQuickActionSubmitPanelFromTemplateResponse";
  quickActionSubmitErrorResponse?: Maybe<SupplyQuickActionSubmitPanelFromTemplateErrorResponse>;
  quickActionSubmitSuccessResponse?: Maybe<SupplyQuickActionSubmitSuccessResponse>;
};

export type SupplyQuickActionSubmitPostpone = {
  __typename?: "SupplyQuickActionSubmitPostpone";
  buttonLabel: Scalars["String"]["output"];
  clickStreamData?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use button for postpone */
  link?: Maybe<EgdsStandardLink>;
  postponeContext: Array<SupplyQuickActionFormContext>;
};

export type SupplyQuickActionSubmitResponse = {
  __typename?: "SupplyQuickActionSubmitResponse";
  clickStreamData?: Maybe<SupplyQuickActionSubmitAnalytics>;
  error?: Maybe<EgdsErrorSummary>;
  success?: Maybe<SupplyQuickActionSubmitSuccessResponse>;
};

export type SupplyQuickActionSubmitSuccessResponse = {
  __typename?: "SupplyQuickActionSubmitSuccessResponse";
  button?: Maybe<EgdsButton>;
  contentElements: Array<SupplyQuickActionSubmitContentElement>;
  /** @deprecated This field will be deprecated in the future, use contentElements instead */
  description: SupplyQuickActionText;
  icon: Icon;
  title: SupplyQuickActionText;
};

export interface SupplyQuickActionSubscribeElement {
  subscribesTo: Array<SupplyQuickActionSubscribeEvent>;
}

export type SupplyQuickActionSubscribeEvent =
  | SupplyQuickActionSubscribeReplaceEvent
  | SupplyQuickActionSubscribeVisibilityEvent;

export type SupplyQuickActionSubscribeReplaceEvent = {
  __typename?: "SupplyQuickActionSubscribeReplaceEvent";
  processors: Array<SupplyQuickActionFieldProcessor>;
  textWithPattern: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type SupplyQuickActionSubscribeVisibilityEvent = {
  __typename?: "SupplyQuickActionSubscribeVisibilityEvent";
  type: Scalars["String"]["output"];
  visibility?: Maybe<SupplyQuickActionElementVisibility>;
};

export type SupplyQuickActionTable = {
  __typename?: "SupplyQuickActionTable";
  columnHeaders?: Maybe<Array<Scalars["String"]["output"]>>;
  rows: Array<SupplyQuickActionTableItemRows>;
  title: Scalars["String"]["output"];
};

export type SupplyQuickActionTableItemCell = {
  __typename?: "SupplyQuickActionTableItemCell";
  text: Scalars["String"]["output"];
  type?: Maybe<SupplyQuickActionTableItemCellType>;
};

export type SupplyQuickActionTableItemCellType = "NORMAL" | "STRIKETHROUGH";

export type SupplyQuickActionTableItemRows = {
  __typename?: "SupplyQuickActionTableItemRows";
  cells: Array<SupplyQuickActionTableItemCell>;
};

export type SupplyQuickActionText = SupplyQuickActionSubscribeElement & {
  __typename?: "SupplyQuickActionText";
  subscribesTo: Array<SupplyQuickActionSubscribeEvent>;
  textComponent: SupplyQuickActionTextContent;
};

export type SupplyQuickActionTextContent =
  | EgdsHeading
  | EgdsIconText
  | EgdsParagraph
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsStylizedText
  | EgdsTextIconList
  | EgdsTextListSection
  | EgdsTextSection;

export type SupplyRateAvailabilityObservationCard = {
  __typename?: "SupplyRateAvailabilityObservationCard";
  border?: Maybe<Scalars["Boolean"]["output"]>;
  overflow?: Maybe<Scalars["Boolean"]["output"]>;
  padded?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRateAvailabilityObservationElement =
  | EgdsIconText
  | EgdsLoyaltyBadge
  | EgdsPlainText
  | EgdsStandardLink
  | EgdsStylizedText
  | SupplyRateAvailabilityObservationCard
  | SupplyRateAvailabilityObservationLayoutFlex
  | SupplyRateAvailabilityObservationLayoutFlexItem
  | SupplyRateAvailabilityObservationSpacing
  | SupplyRateAvailabilityObservationStandardLink
  | SupplyRateAvailabilityObservationTooltip
  | SupplySupplyRateAvailabilityObservationBadgeTooltip;

export type SupplyRateAvailabilityObservationGroupedItems = {
  __typename?: "SupplyRateAvailabilityObservationGroupedItems";
  items: Array<SupplyRateAvailabilityObservationItem>;
  pagination?: Maybe<SupplyRateAvailabilityObservationsPagination>;
};

export type SupplyRateAvailabilityObservationItem = {
  __typename?: "SupplyRateAvailabilityObservationItem";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: Array<SupplyRateAvailabilityObservationNode>;
  header: SupplyRateAvailabilityObservationNode;
};

export type SupplyRateAvailabilityObservationLayoutFlex = {
  __typename?: "SupplyRateAvailabilityObservationLayoutFlex";
  alignContent?: Maybe<Scalars["String"]["output"]>;
  alignItems?: Maybe<Scalars["String"]["output"]>;
  direction?: Maybe<Scalars["String"]["output"]>;
  justifyContent?: Maybe<Scalars["String"]["output"]>;
  space?: Maybe<Scalars["String"]["output"]>;
  wrap?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRateAvailabilityObservationLayoutFlexItem = {
  __typename?: "SupplyRateAvailabilityObservationLayoutFlexItem";
  alignSelf?: Maybe<Scalars["String"]["output"]>;
  grow?: Maybe<Scalars["Int"]["output"]>;
  shrink?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyRateAvailabilityObservationNode = {
  __typename?: "SupplyRateAvailabilityObservationNode";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  children: Array<SupplyRateAvailabilityObservationNode>;
  element: SupplyRateAvailabilityObservationElement;
};

export type SupplyRateAvailabilityObservationSpacing = {
  __typename?: "SupplyRateAvailabilityObservationSpacing";
  horizontal?: Maybe<SupplyRateAvailabilityObservationSpacingSize>;
  horizontalRule: Scalars["Boolean"]["output"];
  vertical?: Maybe<SupplyRateAvailabilityObservationSpacingSize>;
};

export type SupplyRateAvailabilityObservationSpacingSize =
  | "SPACING__0X__HALF"
  | "SPACING__1X"
  | "SPACING__2X"
  | "SPACING__3X"
  | "SPACING__4X"
  | "SPACING__5X"
  | "SPACING__6X"
  | "SPACING__8X";

export type SupplyRateAvailabilityObservationStandardLink = {
  __typename?: "SupplyRateAvailabilityObservationStandardLink";
  align?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  inline?: Maybe<Scalars["Boolean"]["output"]>;
  link: EgdsStandardLink;
};

export type SupplyRateAvailabilityObservationTooltip = {
  __typename?: "SupplyRateAvailabilityObservationTooltip";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: EgdsSpannableText;
  icon: Icon;
};

export type SupplyRateAvailabilityObservations = {
  __typename?: "SupplyRateAvailabilityObservations";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  banner?: Maybe<SupplyRateAvailabilityObservationsBanner>;
  expandoLink?: Maybe<EgdsExpandoLink>;
  exportLinks: Array<EgdsStandardLink>;
  groupedItems: Array<SupplyRateAvailabilityObservationGroupedItems>;
};

export type SupplyRateAvailabilityObservationsBanner = {
  __typename?: "SupplyRateAvailabilityObservationsBanner";
  actionsAnalytics?: Maybe<Array<Scalars["AnalyticsPayload"]["output"]>>;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  banner: UiBanner;
};

export interface SupplyRateAvailabilityObservationsFilter {
  key: Scalars["String"]["input"];
  value: Array<Scalars["String"]["input"]>;
}

export interface SupplyRateAvailabilityObservationsOptions {
  filters: Array<SupplyRateAvailabilityObservationsFilter>;
}

export type SupplyRateAvailabilityObservationsPagination = {
  __typename?: "SupplyRateAvailabilityObservationsPagination";
  nextButton: SupplyRateAvailabilityObservationsPaginationButton;
  previousButton: SupplyRateAvailabilityObservationsPaginationButton;
  text: EgdsPlainText;
};

export type SupplyRateAvailabilityObservationsPaginationButton = {
  __typename?: "SupplyRateAvailabilityObservationsPaginationButton";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: EgdsButton;
  groupedItemsIndex: Scalars["Int"]["output"];
};

export type SupplyRatePlan = {
  __typename?: "SupplyRatePlan";
  ratePlanBaseRates?: Maybe<SupplyRatePlanBaseRates>;
  ratePlanCancellationPolicy?: Maybe<SupplyRatePlanCancellationPolicy>;
  ratePlanCompensation?: Maybe<SupplyRatePlanCompensation>;
  ratePlanFee?: Maybe<SupplyRatePlanFee>;
  ratePlanIdentifiers?: Maybe<SupplyRatePlanIdentifiers>;
  ratePlanName?: Maybe<SupplyRatePlanName>;
  ratePlanRestrictions?: Maybe<SupplyRatePlanRestrictions>;
  ratePlanRoomTypes?: Maybe<SupplyRatePlanRoomTypes>;
  ratePlanType?: Maybe<SupplyRatePlanType>;
  ratePlanValueAdds?: Maybe<SupplyRatePlanValueAdds>;
};

export type SupplyRatePlanAssociatedRoomType = {
  __typename?: "SupplyRatePlanAssociatedRoomType";
  roomTypeId: Scalars["String"]["output"];
  roomTypeName: Scalars["String"]["output"];
};

export type SupplyRatePlanBaseRates = {
  __typename?: "SupplyRatePlanBaseRates";
  additionalPerOccupantInput?: Maybe<SupplyRatePlanNumberField>;
  baseRateInput: SupplyRatePlanNumberField;
  header: SupplyRatePlanSectionHeader;
  occupantsIncludedSelect: SupplyRatePlanSelectField;
  ratePerOccupantPreview?: Maybe<SupplyRatePlanBaseRatesRatePerOccupantPreview>;
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanBaseRatesRatePerOccupantPreview = {
  __typename?: "SupplyRatePlanBaseRatesRatePerOccupantPreview";
  occupancyLabel: Scalars["String"]["output"];
  occupantIcon: SupplyRatePlanIcon;
  rateLabel: Scalars["String"]["output"];
  ratePerNightStringTemplate: Scalars["String"]["output"];
  showLessLabel: Scalars["String"]["output"];
  showMoreLabel: Scalars["String"]["output"];
  viewRatePerOccupantLabel: Scalars["String"]["output"];
};

export type SupplyRatePlanCancellationPolicy = {
  __typename?: "SupplyRatePlanCancellationPolicy";
  cancellationPolicySelectionSheet: SupplyRatePlanCancellationPolicySelectionSheet;
  changeButtonLabel?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  selectButtonLabel?: Maybe<Scalars["String"]["output"]>;
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanCancellationPolicySelectionSheet = {
  __typename?: "SupplyRatePlanCancellationPolicySelectionSheet";
  applyButtonLabel?: Maybe<Scalars["String"]["output"]>;
  cancelButtonLabel?: Maybe<Scalars["String"]["output"]>;
  cancellationPolicyOptions: Array<SupplyRatePlanRadioButton>;
  createNewPolicyDescription?: Maybe<Scalars["String"]["output"]>;
  createNewPolicyExpandoTitle?: Maybe<Scalars["String"]["output"]>;
  createNewPolicyLinkText?: Maybe<Scalars["String"]["output"]>;
  createNewPolicyLinkUrl?: Maybe<Scalars["String"]["output"]>;
  navigationContentNavIconLabel?: Maybe<Scalars["String"]["output"]>;
  toolbarTitle?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanCheckBox = {
  __typename?: "SupplyRatePlanCheckBox";
  checked: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyRatePlanCompensation = {
  __typename?: "SupplyRatePlanCompensation";
  badgeText?: Maybe<Scalars["String"]["output"]>;
  compensationRates: Array<SupplyRatePlanCompensationRate>;
  compensationRulesDropdown?: Maybe<SupplyRatePlanCompensationRulesDropdown>;
  heading: Scalars["String"]["output"];
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanCompensationRate = {
  __typename?: "SupplyRatePlanCompensationRate";
  name: Scalars["String"]["output"];
  rate: Scalars["String"]["output"];
};

export type SupplyRatePlanCompensationRule = {
  __typename?: "SupplyRatePlanCompensationRule";
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyRatePlanCompensationRulesDropdown = {
  __typename?: "SupplyRatePlanCompensationRulesDropdown";
  compensationRules: Array<SupplyRatePlanCompensationRule>;
  selectedRuleValue: Scalars["String"]["output"];
};

export type SupplyRatePlanCountrySelectionSheet = {
  __typename?: "SupplyRatePlanCountrySelectionSheet";
  applyButtonLabel: Scalars["String"]["output"];
  countrySelectionRadioOptions: Array<SupplyRatePlanRadioButton>;
  searchInput?: Maybe<SupplyRatePlanTypeahead>;
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyRatePlanCreationOption = "B2B_DISTRIBUTION_CUSTOM" | "BUSINESS_CUSTOM" | "STANDARD_CUSTOM";

export type SupplyRatePlanDatesRestriction = {
  __typename?: "SupplyRatePlanDatesRestriction";
  addButtonLabel: Scalars["String"]["output"];
  dateRanges?: Maybe<Array<SupplyRatePlanDatesRestrictionDateRange>>;
};

export type SupplyRatePlanDatesRestrictionDateRange = {
  __typename?: "SupplyRatePlanDatesRestrictionDateRange";
  endDate: Scalars["String"]["output"];
  startDate: Scalars["String"]["output"];
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SupplyRatePlanEditOption = "REACTIVATE";

export type SupplyRatePlanFee = {
  __typename?: "SupplyRatePlanFee";
  changeButtonLabel: Scalars["String"]["output"];
  feeSelectionSheet: SupplyRatePlanFeeSelectionSheet;
  heading: Scalars["String"]["output"];
  selectedFeeType: Scalars["String"]["output"];
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanFeeOption = {
  __typename?: "SupplyRatePlanFeeOption";
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  label: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type SupplyRatePlanFeeSelectionSheet = {
  __typename?: "SupplyRatePlanFeeSelectionSheet";
  applyButtonLabel: Scalars["String"]["output"];
  cancelButtonLabel: Scalars["String"]["output"];
  feeOptions: Array<SupplyRatePlanFeeOption>;
  helpText: SupplyRatePlanFeeSelectionSheetHelpText;
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyRatePlanFeeSelectionSheetHelpText = {
  __typename?: "SupplyRatePlanFeeSelectionSheetHelpText";
  description: Scalars["String"]["output"];
  linkText: Scalars["String"]["output"];
  linkUrl: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyRatePlanIcon = {
  __typename?: "SupplyRatePlanIcon";
  label?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SupplyRatePlanIdentifiers = {
  __typename?: "SupplyRatePlanIdentifiers";
  heading: Scalars["String"]["output"];
  ratePlanIdentifierId?: Maybe<Scalars["String"]["output"]>;
  ratePlanNotificationCodeInput: Array<SupplyRatePlanTextField>;
  subheading: Scalars["String"]["output"];
};

export interface SupplyRatePlanInput {
  roomTypeId: Array<Scalars["String"]["input"]>;
}

export type SupplyRatePlanName = {
  __typename?: "SupplyRatePlanName";
  heading: Scalars["String"]["output"];
  ratePlanNameInput: SupplyRatePlanTextField;
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanNumberField = {
  __typename?: "SupplyRatePlanNumberField";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanOperationType = "COPY" | "CREATE" | "EDIT";

export interface SupplyRatePlanQueryInput {
  createOption?: InputMaybe<SupplyRatePlanCreationOption>;
  editOption?: InputMaybe<SupplyRatePlanEditOption>;
  inBREnrollmentFlow?: InputMaybe<Scalars["Boolean"]["input"]>;
  operationType?: InputMaybe<SupplyRatePlanOperationType>;
  ratePlanId?: InputMaybe<Scalars["String"]["input"]>;
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyRatePlanRadioButton = {
  __typename?: "SupplyRatePlanRadioButton";
  description?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  tooltipText?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanRangeValuesRestriction = {
  __typename?: "SupplyRatePlanRangeValuesRestriction";
  errorMessage: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  maxValue: Scalars["Int"]["output"];
  maxValueLabel: Scalars["String"]["output"];
  minValue: Scalars["Int"]["output"];
  minValueLabel: Scalars["String"]["output"];
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SupplyRatePlanRestrictions = {
  __typename?: "SupplyRatePlanRestrictions";
  advanceAndLastMinuteBookingInput?: Maybe<SupplyRatePlanRangeValuesRestriction>;
  certainBookingDatesInput?: Maybe<SupplyRatePlanDatesRestriction>;
  certainStayDatesInput?: Maybe<SupplyRatePlanDatesRestriction>;
  countrySelectionSheet?: Maybe<SupplyRatePlanCountrySelectionSheet>;
  datePicker?: Maybe<EgdsDateRangePicker>;
  heading: Scalars["String"]["output"];
  lengthOfStayInput?: Maybe<SupplyRatePlanRangeValuesRestriction>;
  limitToCertainTravelersInput?: Maybe<LimitToCertainTravelersInput>;
  ratePlanRestrictionsCheckBoxGroupOptions?: Maybe<Array<SupplyRatePlanCheckBox>>;
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanRightRail = {
  __typename?: "SupplyRatePlanRightRail";
  contentSection: SupplyRatePlanRightRailContentSection;
  contentViewed?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyRatePlanRightRailContentSection =
  | SupplyRatePlanSimplifiedRightRailContent
  | SupplyRatePlanSimplifiedRightRailErrorContent;

export interface SupplyRatePlanRightRailInput {
  isInactiveRoom?: InputMaybe<Scalars["Boolean"]["input"]>;
  roomTypeId?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyRatePlanRoomTypeSelectionBasicSection = {
  __typename?: "SupplyRatePlanRoomTypeSelectionBasicSection";
  deselectAllButtonLabel: Scalars["String"]["output"];
  roomTypeCheckBoxes: Array<SupplyRatePlanCheckBox>;
  selectAllButtonLabel: Scalars["String"]["output"];
};

export type SupplyRatePlanRoomTypeSelectionSheet = {
  __typename?: "SupplyRatePlanRoomTypeSelectionSheet";
  activeSection: SupplyRatePlanRoomTypeSelectionSheetSection;
  activeSectionBasic?: Maybe<SupplyRatePlanRoomTypeSelectionBasicSection>;
  activeSectionWithExpando?: Maybe<SupplyRatePlanRoomTypeSelectionSheetSection>;
  applyButtonLabel: Scalars["String"]["output"];
  cancelButtonLabel: Scalars["String"]["output"];
  inactiveSection: SupplyRatePlanRoomTypeSelectionSheetSection;
  inactiveSectionWithExpando?: Maybe<SupplyRatePlanRoomTypeSelectionSheetSection>;
  navigationContentNavIconLabel: Scalars["String"]["output"];
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyRatePlanRoomTypeSelectionSheetSection = {
  __typename?: "SupplyRatePlanRoomTypeSelectionSheetSection";
  description?: Maybe<Scalars["String"]["output"]>;
  deselectAllButtonLabel: Scalars["String"]["output"];
  expandoTitle: Scalars["String"]["output"];
  roomTypeCheckBoxes: Array<SupplyRatePlanCheckBox>;
  selectAllButtonLabel: Scalars["String"]["output"];
};

export type SupplyRatePlanRoomTypes = {
  __typename?: "SupplyRatePlanRoomTypes";
  associatedRoomType?: Maybe<SupplyRatePlanAssociatedRoomType>;
  changeRoomTypeLabel: Scalars["String"]["output"];
  changeRoomTypesLabel?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  roomTypeSelectionSheet: SupplyRatePlanRoomTypeSelectionSheet;
  roomTypesSelectionSheet?: Maybe<SupplyRatePlanRoomTypeSelectionSheet>;
  selectRoomTypeLabel: Scalars["String"]["output"];
  selectRoomTypesLabel?: Maybe<Scalars["String"]["output"]>;
  subheading: Scalars["String"]["output"];
};

export type SupplyRatePlanSectionHeader = {
  __typename?: "SupplyRatePlanSectionHeader";
  title: Scalars["String"]["output"];
  tooltip?: Maybe<SupplyRatePlanTooltip>;
};

export type SupplyRatePlanSelectField = {
  __typename?: "SupplyRatePlanSelectField";
  label: Scalars["String"]["output"];
  options: Array<SupplyRatePlanSelectFieldOption>;
};

export type SupplyRatePlanSelectFieldOption = {
  __typename?: "SupplyRatePlanSelectFieldOption";
  label: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyRatePlanSimplifiedRightRailBanner = {
  __typename?: "SupplyRatePlanSimplifiedRightRailBanner";
  description?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanSimplifiedRightRailContent = {
  __typename?: "SupplyRatePlanSimplifiedRightRailContent";
  banner?: Maybe<SupplyRatePlanSimplifiedRightRailBanner>;
  cards: Array<SupplyRatePlanTypeCard>;
};

export type SupplyRatePlanSimplifiedRightRailErrorContent = {
  __typename?: "SupplyRatePlanSimplifiedRightRailErrorContent";
  banner?: Maybe<SupplyRatePlanSimplifiedRightRailBanner>;
};

export type SupplyRatePlanSwitch = {
  __typename?: "SupplyRatePlanSwitch";
  checked: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  editButtonLabel?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyRatePlanTextField = {
  __typename?: "SupplyRatePlanTextField";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanTooltip = {
  __typename?: "SupplyRatePlanTooltip";
  content: Array<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  linkText: Scalars["String"]["output"];
  linkUrl: Scalars["String"]["output"];
};

export type SupplyRatePlanType = {
  __typename?: "SupplyRatePlanType";
  discountInput?: Maybe<SupplyRatePlanNumberField>;
  heading: Scalars["String"]["output"];
  ratePlanTypeCheckBoxGroupDisabled: Scalars["Boolean"]["output"];
  ratePlanTypeCheckBoxGroupErrorMessage?: Maybe<Scalars["String"]["output"]>;
  ratePlanTypeCheckBoxGroupName: Scalars["String"]["output"];
  ratePlanTypeCheckBoxGroupOptions?: Maybe<Array<SupplyRatePlanCheckBox>>;
  ratePlanTypeText?: Maybe<Scalars["String"]["output"]>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanTypeCard = {
  __typename?: "SupplyRatePlanTypeCard";
  accessibilityText?: Maybe<Scalars["String"]["output"]>;
  contentSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  description: Scalars["String"]["output"];
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanTypeahead = {
  __typename?: "SupplyRatePlanTypeahead";
  clearAllText: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  renderType?: Maybe<EgdsTypeaheadRenderType>;
  selectedCountries: Array<Scalars["String"]["output"]>;
  selectedItemHeading?: Maybe<Scalars["String"]["output"]>;
  showPills?: Maybe<Scalars["Boolean"]["output"]>;
  typeaheadEmptyResultPlaceholder?: Maybe<Scalars["String"]["output"]>;
  typeaheadPlaceholder?: Maybe<Scalars["String"]["output"]>;
  validations?: Maybe<Array<EgdsInputValidation>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRatePlanValueAddCategory = {
  __typename?: "SupplyRatePlanValueAddCategory";
  categoryTitle: Scalars["String"]["output"];
  expanded?: Maybe<Scalars["Boolean"]["output"]>;
  valueAddOptions: Array<SupplyRatePlanCheckBox>;
};

export type SupplyRatePlanValueAdds = {
  __typename?: "SupplyRatePlanValueAdds";
  changeButtonLabel: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  selectButtonLabel: Scalars["String"]["output"];
  selectedValueAdds?: Maybe<Array<Scalars["String"]["output"]>>;
  subheading: Scalars["String"]["output"];
  valueAddsSheet: SupplyRatePlanValueAddsSheet;
};

export type SupplyRatePlanValueAddsSheet = {
  __typename?: "SupplyRatePlanValueAddsSheet";
  applyButtonLabel: Scalars["String"]["output"];
  cancelButtonLabel: Scalars["String"]["output"];
  navigationContentNavIconLabel?: Maybe<Scalars["String"]["output"]>;
  toolbarSubtitle: Scalars["String"]["output"];
  toolbarTitle: Scalars["String"]["output"];
  valueAddCategories: Array<SupplyRatePlanValueAddCategory>;
  valueAddUncategorizedOptions: Array<SupplyRatePlanCheckBox>;
};

export interface SupplyRefundDecision {
  content: Scalars["String"]["output"];
  icon: Icon;
  primaryButton: EgdsButton;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
}

export type SupplyRefundDisclaimerInlineLink = {
  __typename?: "SupplyRefundDisclaimerInlineLink";
  formatText: Scalars["String"]["output"];
  fullText: Scalars["String"]["output"];
  inlineLink: Array<EgdsInlineLink>;
  placeholderText: Scalars["String"]["output"];
};

export type SupplyRegulationAddressCountrySpecialRule = {
  __typename?: "SupplyRegulationAddressCountrySpecialRule";
  countries: Array<Scalars["String"]["output"]>;
  maxLength?: Maybe<Scalars["Int"]["output"]>;
  regExp?: Maybe<Scalars["String"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRegulationAddressCountryStates = {
  __typename?: "SupplyRegulationAddressCountryStates";
  country: Scalars["String"]["output"];
  stateProvinces: Array<SupplyRegulationAddressSelectOption>;
};

export type SupplyRegulationAddressFieldRules = {
  __typename?: "SupplyRegulationAddressFieldRules";
  countrySpecialRules?: Maybe<Array<SupplyRegulationAddressCountrySpecialRule>>;
  maxLength?: Maybe<Scalars["Int"]["output"]>;
  regExp?: Maybe<Scalars["String"]["output"]>;
  required?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRegulationAddressFieldsConfig = {
  __typename?: "SupplyRegulationAddressFieldsConfig";
  address1?: Maybe<SupplyRegulationAddressFieldRules>;
  address2?: Maybe<SupplyRegulationAddressFieldRules>;
  city?: Maybe<SupplyRegulationAddressFieldRules>;
  postalCode?: Maybe<SupplyRegulationAddressFieldRules>;
  stateProvince?: Maybe<SupplyRegulationAddressFieldRules>;
};

export type SupplyRegulationAddressSelectOption = {
  __typename?: "SupplyRegulationAddressSelectOption";
  label: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyRegulationAddressStateProvince = {
  __typename?: "SupplyRegulationAddressStateProvince";
  inputElement: SupplyRegulationOptionalTextInputField;
  selectElement: EgdsBasicSelect;
};

export type SupplyRegulationBreadcrumbMenu = {
  __typename?: "SupplyRegulationBreadcrumbMenu";
  breadcrumbItems?: Maybe<Array<SupplyRegulationText>>;
};

export type SupplyRegulationDac = {
  __typename?: "SupplyRegulationDac";
  buttons?: Maybe<SupplyRegulationDacButtons>;
  confirmation?: Maybe<SupplyRegulationDacConfirmation>;
  header?: Maybe<SupplyRegulationDacHeader>;
  showButtonsDivide?: Maybe<Scalars["Boolean"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulationDacButtons = {
  __typename?: "SupplyRegulationDacButtons";
  defaultReturnUrl: Scalars["String"]["output"];
  saveAndExitButton: EgdsButton;
  saveAndExitDialog: SupplyRegulationDacExitDialog;
  submitButton: EgdsButton;
};

export type SupplyRegulationDacConfirmation = {
  __typename?: "SupplyRegulationDacConfirmation";
  editButton: EgdsButton;
  icon: Icon;
  title: Scalars["String"]["output"];
  viewButton: EgdsButton;
};

export type SupplyRegulationDacExitDialog = {
  __typename?: "SupplyRegulationDacExitDialog";
  description: Scalars["String"]["output"];
  exitButton: EgdsButton;
  heading: Scalars["String"]["output"];
  stayButton: EgdsButton;
};

export type SupplyRegulationDacHeader = {
  __typename?: "SupplyRegulationDacHeader";
  /** @deprecated Please use descriptions */
  description?: Maybe<EgdsSpannableText>;
  descriptions?: Maybe<Array<EgdsSpannableText>>;
  pcBreadcrumbMenu?: Maybe<SupplyRegulationBreadcrumbMenu>;
  title: Scalars["String"]["output"];
};

export type SupplyRegulationDacTaxIds = {
  __typename?: "SupplyRegulationDacTaxIds";
  addButton: EgdsStandardLink;
  country: EgdsBasicSelect;
  countryOptions?: Maybe<Array<SupplyRegulationDacTaxIdsCountryOption>>;
  removeIcon: Icon;
  taxId: EgdsTextInputField;
  value: Scalars["String"]["output"];
};

export type SupplyRegulationDacTaxIdsCountryOption = {
  __typename?: "SupplyRegulationDacTaxIdsCountryOption";
  country: Scalars["String"]["output"];
  validations?: Maybe<Array<EgdsInputValidation>>;
};

export type SupplyRegulationDataSharing = {
  __typename?: "SupplyRegulationDataSharing";
  buttons?: Maybe<SupplyRegulationDataSharingButtons>;
  completionView?: Maybe<SupplyRegulationDataSharingCompletionView>;
  header?: Maybe<SupplyRegulationDataSharingHeader>;
  regulatoryType?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulationDataSharingButtons = {
  __typename?: "SupplyRegulationDataSharingButtons";
  defaultReturnUrl: Scalars["String"]["output"];
  saveAndExitButton?: Maybe<EgdsButton>;
  saveAndExitDialog?: Maybe<SupplyRegulationDataSharingSaveDialog>;
  showButtonsDivide?: Maybe<Scalars["Boolean"]["output"]>;
  submitButton?: Maybe<EgdsButton>;
};

export type SupplyRegulationDataSharingCompletionView = {
  __typename?: "SupplyRegulationDataSharingCompletionView";
  editButton?: Maybe<EgdsButton>;
  icon?: Maybe<Icon>;
  title: Scalars["String"]["output"];
  viewButton?: Maybe<EgdsButton>;
};

export type SupplyRegulationDataSharingHeader = {
  __typename?: "SupplyRegulationDataSharingHeader";
  /** @deprecated Please use descriptions */
  description?: Maybe<EgdsSpannableText>;
  descriptions?: Maybe<Array<EgdsSpannableText>>;
  pcBreadcrumbMenu?: Maybe<SupplyRegulationBreadcrumbMenu>;
  title: Scalars["String"]["output"];
};

export type SupplyRegulationDataSharingSaveDialog = {
  __typename?: "SupplyRegulationDataSharingSaveDialog";
  description?: Maybe<Scalars["String"]["output"]>;
  exitButton?: Maybe<EgdsButton>;
  stayButton?: Maybe<EgdsButton>;
  title: Scalars["String"]["output"];
};

export type SupplyRegulationDateFormatOptions = {
  __typename?: "SupplyRegulationDateFormatOptions";
  day?: Maybe<Scalars["String"]["output"]>;
  month?: Maybe<Scalars["String"]["output"]>;
  year?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulationDatePicker = {
  __typename?: "SupplyRegulationDatePicker";
  dateSelector?: Maybe<SupplyRegulationDateSelector>;
};

export type SupplyRegulationDateSelector = {
  __typename?: "SupplyRegulationDateSelector";
  allowClearDates?: Maybe<Scalars["Boolean"]["output"]>;
  autoCloseOnSelection?: Maybe<Scalars["Boolean"]["output"]>;
  clearButtonLabel?: Maybe<Scalars["String"]["output"]>;
  dateFormatOptions?: Maybe<SupplyRegulationDateFormatOptions>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  enablePastDates?: Maybe<Scalars["Boolean"]["output"]>;
  endDate?: Maybe<Scalars["String"]["output"]>;
  inputLabel1: Scalars["String"]["output"];
  inputLabel2?: Maybe<Scalars["String"]["output"]>;
  isSingleDateSelection?: Maybe<Scalars["Boolean"]["output"]>;
  maxDate?: Maybe<Scalars["String"]["output"]>;
  minDate?: Maybe<Scalars["String"]["output"]>;
  startDate: Scalars["String"]["output"];
  submitButtonLabel: Scalars["String"]["output"];
  validations?: Maybe<Array<SupplyRegulatoryFlowInputValidationRule>>;
};

export type SupplyRegulationDescriptionWithSheet = {
  __typename?: "SupplyRegulationDescriptionWithSheet";
  desktopSheetContent?: Maybe<Scalars["String"]["output"]>;
  message: Scalars["String"]["output"];
  mobileSheetContent?: Maybe<SupplyRegulationMobileSheetContent>;
  sheetTrigger?: Maybe<EgdsBasicTriggerPill>;
};

export type SupplyRegulationLegalAddress = {
  __typename?: "SupplyRegulationLegalAddress";
  address1?: Maybe<EgdsTextInputField>;
  address2?: Maybe<EgdsTextInputField>;
  city?: Maybe<SupplyRegulationOptionalTextInputField>;
  country?: Maybe<EgdsBasicSelect>;
  countryStates: Array<SupplyRegulationAddressCountryStates>;
  fieldRulesConfig: SupplyRegulationAddressFieldsConfig;
  postalCode?: Maybe<EgdsTextInputField>;
  stateProvince?: Maybe<SupplyRegulationAddressStateProvince>;
  value: Scalars["String"]["output"];
};

export type SupplyRegulationMobileSheetContent = {
  __typename?: "SupplyRegulationMobileSheetContent";
  description: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulationOptionalTextInputField = {
  __typename?: "SupplyRegulationOptionalTextInputField";
  optionalLabel?: Maybe<Scalars["String"]["output"]>;
  optionalValidations?: Maybe<Array<EgdsInputValidation>>;
  textInputField?: Maybe<EgdsTextInputField>;
};

export type SupplyRegulationRangeValidation = {
  __typename?: "SupplyRegulationRangeValidation";
  errorMessage: Scalars["String"]["output"];
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulationText = EgdsPlainText | EgdsStandardLink;

export type SupplyRegulatoryCheckBox = {
  __typename?: "SupplyRegulatoryCheckBox";
  checkbox: EgdsBasicCheckBox;
  descriptions?: Maybe<Array<SupplyRegulatorySettingDescription>>;
};

export type SupplyRegulatoryConfirmation = {
  __typename?: "SupplyRegulatoryConfirmation";
  icon: Icon;
  message: Scalars["String"]["output"];
  navigationButtons?: Maybe<SupplyRegulatoryNavigationButtons>;
};

export type SupplyRegulatoryDialog = {
  __typename?: "SupplyRegulatoryDialog";
  cancelButton?: Maybe<EgdsButton>;
  confirmButton?: Maybe<EgdsButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyRegulatoryFlow = {
  __typename?: "SupplyRegulatoryFlow";
  analyticsPayload?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  confirmation?: Maybe<SupplyRegulatoryConfirmation>;
  dac?: Maybe<SupplyRegulationDac>;
  dataSharing?: Maybe<SupplyRegulationDataSharing>;
  /** @deprecated hostType will be used as a section of sections, so move hostType to sections and deprecated it */
  hostType?: Maybe<SupplyRegulatoryFlowSection>;
  navigationButtons?: Maybe<SupplyRegulatoryNavigationButtons>;
  sections: Array<SupplyRegulatoryFlowSection>;
  status?: Maybe<SupplyRegulatoryStatus>;
  title?: Maybe<SupplyRegulatoryFlowTitle>;
};

export type SupplyRegulatoryFlowInputValidationRule =
  | EgdsMaxLengthInputValidation
  | EgdsRegexInputValidation
  | EgdsRequiredInputValidation
  | SupplyRegulationRangeValidation
  | SupplyRegulatoryFlowThirdPartyValidationRule;

export type SupplyRegulatoryFlowSection = {
  __typename?: "SupplyRegulatoryFlowSection";
  content?: Maybe<SupplyRegulatoryFlowSectionContent>;
  id: Scalars["String"]["output"];
  preconditions?: Maybe<Array<SupplyRegulatoryFlowSectionPrecondition>>;
  settings: Array<SupplyRegulatorySetting>;
};

export type SupplyRegulatoryFlowSectionCompletenessCondition = {
  __typename?: "SupplyRegulatoryFlowSectionCompletenessCondition";
  completed: Scalars["Boolean"]["output"];
  sectionId: Scalars["String"]["output"];
};

export type SupplyRegulatoryFlowSectionComplianceCondition = {
  __typename?: "SupplyRegulatoryFlowSectionComplianceCondition";
  compliant: Scalars["Boolean"]["output"];
  sectionId: Scalars["String"]["output"];
};

export type SupplyRegulatoryFlowSectionCondition =
  | SupplyRegulatoryFlowSectionCompletenessCondition
  | SupplyRegulatoryFlowSectionComplianceCondition
  | SupplyRegulatoryFlowSectionValidityCondition
  | SupplyRegulatoryFlowSectionValueCondition
  | SupplyRegulatoryFlowSectionVisibilityCondition;

export type SupplyRegulatoryFlowSectionContent = SupplyRegulatoryFlowVerifyAddressSectionContent;

export type SupplyRegulatoryFlowSectionPrecondition = {
  __typename?: "SupplyRegulatoryFlowSectionPrecondition";
  conditions: Array<SupplyRegulatoryFlowSectionCondition>;
};

export type SupplyRegulatoryFlowSectionValidityCondition = {
  __typename?: "SupplyRegulatoryFlowSectionValidityCondition";
  sectionId: Scalars["String"]["output"];
  valid: Scalars["Boolean"]["output"];
};

export type SupplyRegulatoryFlowSectionValueCondition = {
  __typename?: "SupplyRegulatoryFlowSectionValueCondition";
  sectionId: Scalars["String"]["output"];
  value: Array<Scalars["String"]["output"]>;
};

export type SupplyRegulatoryFlowSectionVisibilityCondition = {
  __typename?: "SupplyRegulatoryFlowSectionVisibilityCondition";
  sectionId: Scalars["String"]["output"];
  visible: Scalars["Boolean"]["output"];
};

export type SupplyRegulatoryFlowThirdPartyValidation = {
  __typename?: "SupplyRegulatoryFlowThirdPartyValidation";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulatoryFlowThirdPartyValidationDependentField = {
  __typename?: "SupplyRegulatoryFlowThirdPartyValidationDependentField";
  field: Scalars["String"]["output"];
  section: Scalars["String"]["output"];
};

export interface SupplyRegulatoryFlowThirdPartyValidationInput {
  field: Scalars["String"]["input"];
  options: Array<SupplyRegulatoryFlowThirdPartyValidationOption>;
  rule: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export interface SupplyRegulatoryFlowThirdPartyValidationOption {
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type SupplyRegulatoryFlowThirdPartyValidationRule = {
  __typename?: "SupplyRegulatoryFlowThirdPartyValidationRule";
  dependentFields: Array<SupplyRegulatoryFlowThirdPartyValidationDependentField>;
  errorMessage: Scalars["String"]["output"];
  field: Scalars["String"]["output"];
  rule: Scalars["String"]["output"];
};

export type SupplyRegulatoryFlowTitle = {
  __typename?: "SupplyRegulatoryFlowTitle";
  description?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyRegulatoryFlowVerifyAddressSectionContent = {
  __typename?: "SupplyRegulatoryFlowVerifyAddressSectionContent";
  additionalText?: Maybe<EgdsPlainText>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<EgdsStandardLink>;
  refreshButton?: Maybe<EgdsButton>;
  text?: Maybe<EgdsPlainText>;
};

export type SupplyRegulatoryNavigationButtons = {
  __typename?: "SupplyRegulatoryNavigationButtons";
  buttons: Array<EgdsButton>;
  /** @deprecated nextButton has been moved to nested SupplyRegulatoryNavigationButtonGroup */
  nextButton: EgdsButton;
};

export type SupplyRegulatoryRadioButton = {
  __typename?: "SupplyRegulatoryRadioButton";
  button: EgdsRadioButton;
  contents?: Maybe<Array<SupplyRegulatoryRadioButtonContent>>;
};

export type SupplyRegulatoryRadioButtonContent =
  | EgdsPlainText
  | EgdsSingleDatePicker
  | EgdsSpannableText
  | SupplyRegulationDatePicker
  | SupplyRegulatoryCheckBox
  | SupplyRegulatoryRegistrationNumber
  | SupplyRegulatorySelect;

export type SupplyRegulatoryRadioGroup = {
  __typename?: "SupplyRegulatoryRadioGroup";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  groupName: Scalars["String"]["output"];
  isOptional?: Maybe<Scalars["Boolean"]["output"]>;
  options: Array<SupplyRegulatoryRadioButton>;
  selected?: Maybe<Scalars["String"]["output"]>;
  shouldResetValue?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRegulatoryRegistrationNumber = {
  __typename?: "SupplyRegulatoryRegistrationNumber";
  additionalContent?: Maybe<SupplyRegulatoryRegistrationNumberContent>;
  additionalContents?: Maybe<Array<SupplyRegulatoryRegistrationNumberContent>>;
  description: Scalars["String"]["output"];
  formatDescription?: Maybe<Scalars["String"]["output"]>;
  numberField: EgdsTextInputField;
  validations?: Maybe<Array<SupplyRegulatoryFlowInputValidationRule>>;
};

export type SupplyRegulatoryRegistrationNumberContent = EgdsPlainText | EgdsSpannableText | SupplyRegulatoryCheckBox;

export type SupplyRegulatoryRegistrationNumberGroup = {
  __typename?: "SupplyRegulatoryRegistrationNumberGroup";
  columns?: Maybe<Scalars["Int"]["output"]>;
  groupDescription?: Maybe<Scalars["String"]["output"]>;
  groupValidationMessages?: Maybe<Array<SupplyRegulatoryValidationMessage>>;
  registrationNumbers: Array<SupplyRegulatoryRegistrationNumber>;
  valid?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRegulatorySelect = {
  __typename?: "SupplyRegulatorySelect";
  conditionValue?: Maybe<Scalars["String"]["output"]>;
  conditionalRightContent?: Maybe<SupplyRegulatorySelectContent>;
  selectField: EgdsBasicSelect;
  shouldBreakLineOnMobile?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRegulatorySelectContent = SupplyRegulatoryRegistrationNumber;

export type SupplyRegulatorySetting = {
  __typename?: "SupplyRegulatorySetting";
  descriptions?: Maybe<Array<SupplyRegulatorySettingDescription>>;
  fieldSet?: Maybe<SupplyRegulatorySettingFieldSet>;
  title: Scalars["String"]["output"];
};

export type SupplyRegulatorySettingDescription =
  | EgdsBulletedList
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStandardLink
  | SupplyRegulationDescriptionWithSheet;

export type SupplyRegulatorySettingFieldSet =
  | EgdsBasicCheckBox
  | EgdsBasicSelect
  | EgdsSingleDatePicker
  | SupplyRegulationDacTaxIds
  | SupplyRegulationDatePicker
  | SupplyRegulationLegalAddress
  | SupplyRegulatoryRadioGroup
  | SupplyRegulatoryRegistrationNumber
  | SupplyRegulatoryRegistrationNumberGroup
  | SupplyRegulatorySelect
  | SupplyRegulatoryTypeahead
  | SupplyRegulatoryUpload;

export interface SupplyRegulatorySettingInput {
  name: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyRegulatoryStatus = "COMPLIANT" | "NONE" | "NOT_COMPLIANT";

export type SupplyRegulatoryTypeahead = {
  __typename?: "SupplyRegulatoryTypeahead";
  customAdapterConfig?: Maybe<SupplyRegulatoryTypeaheadCustomAdapterConfig>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  leftIcon?: Maybe<Icon>;
  placeholder?: Maybe<Scalars["String"]["output"]>;
  renderType?: Maybe<EgdsTypeaheadRenderType>;
  typeaheadEmptyResultPlaceholder?: Maybe<Scalars["String"]["output"]>;
  typeaheadPlaceholder?: Maybe<Scalars["String"]["output"]>;
  validations?: Maybe<Array<SupplyRegulatoryFlowInputValidationRule>>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulatoryTypeaheadCustomAdapterConfig = {
  __typename?: "SupplyRegulatoryTypeaheadCustomAdapterConfig";
  allResults?: Maybe<Array<SupplyRegulatoryTypeaheadResult>>;
  suggestedResults?: Maybe<Array<SupplyRegulatoryTypeaheadResult>>;
};

export type SupplyRegulatoryTypeaheadResult = {
  __typename?: "SupplyRegulatoryTypeaheadResult";
  icon?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulatoryUpload = {
  __typename?: "SupplyRegulatoryUpload";
  fileLimitConfig?: Maybe<SupplyRegulatoryUploadFileLimitConfig>;
  fileList?: Maybe<Array<SupplyRegulatoryUploadFile>>;
  isSmallLoader?: Maybe<Scalars["Boolean"]["output"]>;
  removeDialog?: Maybe<SupplyRegulatoryDialog>;
  removeIcon?: Maybe<Icon>;
  uploadButton?: Maybe<EgdsButton>;
  uploadMessages?: Maybe<SupplyRegulatoryUploadMessages>;
};

export type SupplyRegulatoryUploadFile = {
  __typename?: "SupplyRegulatoryUploadFile";
  fileName?: Maybe<SupplyRegulationText>;
  fileSize?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulatoryUploadFileLimitConfig = {
  __typename?: "SupplyRegulatoryUploadFileLimitConfig";
  formatLimit?: Maybe<SupplyRegulatoryUploadFileLimitItem>;
  maxSizeLimit?: Maybe<SupplyRegulatoryUploadFileLimitItem>;
  minSizeLimit?: Maybe<SupplyRegulatoryUploadFileLimitItem>;
};

export type SupplyRegulatoryUploadFileLimitItem = {
  __typename?: "SupplyRegulatoryUploadFileLimitItem";
  icon?: Maybe<Icon>;
  limitValue: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type SupplyRegulatoryUploadMessages = {
  __typename?: "SupplyRegulatoryUploadMessages";
  failedMessage?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRegulatoryValidationMessage = {
  __typename?: "SupplyRegulatoryValidationMessage";
  content?: Maybe<Array<SupplyRegulatoryValidationMessageContent>>;
  type: Scalars["String"]["output"];
  visible?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyRegulatoryValidationMessageContent = EgdsIconText | EgdsStandardLink;

export type SupplyReservationDetailsComponentType =
  | "ACCESS_INSTRUCTIONS"
  | "AUTOMATED_PAYOUT_STATUSES"
  | "CANCELLATION_POLICY"
  | "DAMAGE_DEPOSIT"
  | "INVOICE_STATUSES"
  | "MINI_AVAILABILITY_CALENDAR"
  | "PARTNER_NOTES"
  | "PAYMENT_DETAILS"
  | "PAYMENT_REQUEST_STATUSES"
  | "PAYMENT_SCHEDULE"
  | "PAYMENT_SUMMARY"
  | "RENTAL_AGREEMENT"
  | "STAY_DETAILS"
  | "TRAVELER_INFO";

export type SupplyReservationModifiers = {
  __typename?: "SupplyReservationModifiers";
  errorBanner: ReservationModifiersErrorBanner;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reservationModifiersType?: Maybe<ReservationModifiersType>;
  screens: Array<ReservationModifiersScreen>;
  serviceErrorBanner?: Maybe<ReservationModifiersErrorBanner>;
  subsidiaryData?: Maybe<ReservationModifiersSubsidiaryData>;
  toolbar: ReservationModifiersToolbar;
};

export type SupplyReservationOverview = {
  __typename?: "SupplyReservationOverview";
  summary?: Maybe<SupplyReservationOverviewSummary>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationOverviewEmpty = {
  __typename?: "SupplyReservationOverviewEmpty";
  actionDestination?: Maybe<SupplyNavigationDestination>;
  description?: Maybe<Scalars["String"]["output"]>;
  moduleReservationsEmptyPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationOverviewSection = {
  __typename?: "SupplyReservationOverviewSection";
  actionDestination?: Maybe<SupplyNavigationDestination>;
  count?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyReservationOverviewSuccess = {
  __typename?: "SupplyReservationOverviewSuccess";
  actionDestination?: Maybe<SupplyNavigationDestination>;
  moduleReservationsPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reservationSections?: Maybe<Array<SupplyReservationOverviewSection>>;
};

export type SupplyReservationOverviewSummary = SupplyReservationOverviewEmpty | SupplyReservationOverviewSuccess;

export type SupplyReservationStatusType =
  | "ARRIVING_TODAY"
  | "BOOKED"
  | "BOOKING_REQUEST"
  | "BOOKING_REQUEST_DECLINED"
  | "BOOKING_REQUEST_EXPIRED"
  | "CANCELED"
  | "CANCELLATION_REQUEST"
  | "CURRENT_STAY"
  | "INQUIRY"
  | "POST_STAY"
  | "PRE_APPROVAL_EXPIRED"
  | "PRE_APPROVED"
  | "REQUEST_WITHDRAWN"
  | "UNKNOWN";

export interface SupplyReservationsAccessIdentifierInput {
  id: Scalars["String"]["input"];
  idType: Scalars["String"]["input"];
}

export type SupplyReservationsAccessInstructionPhoto = {
  __typename?: "SupplyReservationsAccessInstructionPhoto";
  imageId: Scalars["UUID"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  photoUrl: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface SupplyReservationsAccessInstructionPhotoInput {
  imageId: Scalars["UUID"]["input"];
  photoUrl: Scalars["String"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyReservationsAccessInstructions = {
  __typename?: "SupplyReservationsAccessInstructions";
  accessCodeSection?: Maybe<SupplyReservationsAccessInstructionsSection>;
  accessTypeSection?: Maybe<SupplyReservationsAccessInstructionsSection>;
  editAction: SupplyReservationsActions;
  /** @deprecated use gallerySection, photos are located in gallerySection.photos */
  gallery?: Maybe<Array<SupplyReservationsAccessInstructionPhoto>>;
  gallerySection?: Maybe<SupplyReservationsAccessInstructionsGallery>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  otherSections?: Maybe<Array<SupplyReservationsAccessInstructionsSection>>;
  titleSection: SupplyReservationsAccessInstructionsSection;
};

export type SupplyReservationsAccessInstructionsGallery = {
  __typename?: "SupplyReservationsAccessInstructionsGallery";
  imageCarouselSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  liveAnnounce: Scalars["String"]["output"];
  navIconLabel: Scalars["String"]["output"];
  nextLabel: Scalars["String"]["output"];
  photos?: Maybe<Array<SupplyReservationsAccessInstructionPhoto>>;
  prevLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export interface SupplyReservationsAccessInstructionsInput {
  accessCode?: InputMaybe<Scalars["String"]["input"]>;
  accessType: SupplyReservationsAccessTypes;
  gallery?: InputMaybe<Array<InputMaybe<SupplyReservationsAccessInstructionPhotoInput>>>;
  instructions: Scalars["String"]["input"];
}

export type SupplyReservationsAccessInstructionsSection = {
  __typename?: "SupplyReservationsAccessInstructionsSection";
  action?: Maybe<SupplyReservationsAction>;
  collapseLabel?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  expandLabel?: Maybe<Scalars["String"]["output"]>;
  onCollapsedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onExpandedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsAccessTypes = "DOORMAN" | "KEYPAD" | "LOCKBOX" | "OTHER" | "PICKUP" | "SMART_LOCK";

export type SupplyReservationsAction = {
  __typename?: "SupplyReservationsAction";
  accessibilityLabel: Scalars["String"]["output"];
  actionType?: Maybe<SupplyReservationsActionType>;
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  icon?: Maybe<Scalars["String"]["output"]>;
  label: Scalars["String"]["output"];
  linkAction?: Maybe<UiLinkAction>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  selectedAnalytics?: Maybe<SupplyReservationsClickstreamData>;
};

export type SupplyReservationsActionStation = {
  __typename?: "SupplyReservationsActionStation";
  actionStationPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: UiPrimaryButton;
  countDown: Scalars["Boolean"]["output"];
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  status: EgdsStylizedText;
  staySummary: Scalars["String"]["output"];
  timer?: Maybe<SupplyReservationsTimer>;
};

export type SupplyReservationsActionType =
  | "ACCESS_INSTRUCTIONS_ADD"
  | "ACCESS_INSTRUCTIONS_EDIT"
  | "AUTOMATED_PAYOUT_STATUS_ACTION"
  | "AUTO_CANCEL_WAIVERS_CANCEL_CHANGES"
  | "AUTO_CANCEL_WAIVERS_SAVE_CHANGES"
  | "DAMAGE_PROTECTION_REFUND_DEPOSIT"
  | "DAMAGE_PROTECTION_REPORT_DAMAGE"
  | "DAMAGE_PROTECTION_REPORT_DAMAGE_EXTERNAL"
  | "DAMAGE_PROTECTION_SEE_DAMAGE_REPORT"
  | "DAMAGE_PROTECTION_SEE_POLICY"
  | "EVC_MANAGE_CARD_MORE_INFO"
  | "EVC_MANAGE_CARD_SEARCH"
  | "EVC_MANAGE_FEEDBACK"
  | "EVC_MANAGE_RESERVATION_DETAILS"
  | "GUEST_INFO_EMAIL"
  | "GUEST_INFO_PHONE"
  | "GUEST_INFO_VIEW_PROFILE"
  | "HEADER_ARCHIVE"
  | "HEADER_DETAILS"
  | "HEADER_FEEDBACK"
  | "HEADER_HELP_CENTER"
  | "HEADER_MARK_AS_READ"
  | "HEADER_MARK_AS_SPAM"
  | "HEADER_MARK_AS_UNREAD"
  | "HEADER_PRINT"
  | "HEADER_SHARE"
  | "HEADER_UNARCHIVE"
  | "INVOICE_STATUS_ACTION"
  | "NOTES_ADD"
  | "NOTES_CANCEL"
  | "NOTES_EDIT"
  | "NOTES_SAVE"
  | "PAYMENT_REQUEST_STATUS_ACTION"
  | "PAYMENT_SCHEDULE_ADD_EXTRA_CHARGE"
  | "PAYMENT_SCHEDULE_CANCEL_PAYMENT_REQUEST"
  | "PAYMENT_SCHEDULE_EDIT_PAYMENT_SCHEDULE"
  | "PAYMENT_SCHEDULE_MARK_PAID"
  | "PAYMENT_SCHEDULE_RESEND_REQUEST"
  | "PAYMENT_SCHEDULE_SEND_A_REFUND"
  | "PAYMENT_SUMMARY_VIEW"
  | "RENTAL_AGREEMENT_VIEW"
  | "RESERVATION_MODIFIERS_BACK"
  | "RESERVATION_MODIFIERS_CLOSE"
  | "RESERVATION_MODIFIERS_NEXT"
  | "RESERVATION_MODIFIERS_SAVE_CANCEL"
  | "RESERVATION_MODIFIERS_SAVE_CHANGE_DATES"
  | "RESERVATION_MODIFIERS_SAVE_GUEST_SATISFACTION_REFUND"
  | "RESERVATION_MODIFIERS_SAVE_NO_SHOW"
  | "STAY_DETAILS_ACCEPT"
  | "STAY_DETAILS_ADD_REMOVE_GUESTS"
  | "STAY_DETAILS_CANCEL_PRE_APPROVAL"
  | "STAY_DETAILS_CANCEL_RESERVATION"
  | "STAY_DETAILS_CHANGE_DATES"
  | "STAY_DETAILS_CHANGE_TIME"
  | "STAY_DETAILS_CONFIRM_CANCELLATION"
  | "STAY_DETAILS_DECLINE"
  | "STAY_DETAILS_EDIT_DATES"
  | "STAY_DETAILS_EDIT_QUOTE"
  | "STAY_DETAILS_EDIT_RESERVATION"
  | "STAY_DETAILS_SEND_PRE_APPROVAL"
  | "VIEW_IN_CALENDAR";

export type SupplyReservationsActions = {
  __typename?: "SupplyReservationsActions";
  actions: Array<SupplyReservationsAction>;
  actionsPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  disabled?: Maybe<Scalars["Boolean"]["output"]>;
  grouping?: Maybe<Array<Scalars["Int"]["output"]>>;
  label?: Maybe<Scalars["String"]["output"]>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  renderHint: SupplyReservationsRenderHint;
};

export type SupplyReservationsBadge = {
  __typename?: "SupplyReservationsBadge";
  accessibilityLabel: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  theme: Scalars["String"]["output"];
};

export type SupplyReservationsBookingDetailsContent =
  | SupplyReservationsBookingRequestDetails
  | SupplyReservationsBookingRequestErrorState;

export type SupplyReservationsBookingRequestDetails = {
  __typename?: "SupplyReservationsBookingRequestDetails";
  action: UiPrimaryButton;
  declineReasons?: Maybe<EgdsBasicSelect>;
  declineWarning?: Maybe<Scalars["String"]["output"]>;
  detailsHeading: Scalars["String"]["output"];
  editQuote?: Maybe<UiSecondaryButton>;
  input?: Maybe<SupplyReservationsTextArea>;
  instructions: Scalars["String"]["output"];
  reservationDetails?: Maybe<SupplyReservationsStayDetails>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsBookingRequestErrorState = {
  __typename?: "SupplyReservationsBookingRequestErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"]["output"];
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsCanceledTwentyFourPolicy = {
  __typename?: "SupplyReservationsCanceledTwentyFourPolicy";
  expando: SupplyReservationsCancellationPolicyDetailsExpandoLink;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsCancellationPolicy = {
  __typename?: "SupplyReservationsCancellationPolicy";
  expando: SupplyReservationsCancellationPolicyDetailsExpandoLink;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsCancellationPolicyDetailsExpandoLink = {
  __typename?: "SupplyReservationsCancellationPolicyDetailsExpandoLink";
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedContent?: Maybe<SupplyReservationsCancellationPolicyDetailsSection>;
  expandedLabel: Scalars["String"]["output"];
  link?: Maybe<EgdsStandardLink>;
  title: Scalars["String"]["output"];
  toggleLink?: Maybe<SupplyReservationsCancellationPolicyDetailsToggleLink>;
};

export type SupplyReservationsCancellationPolicyDetailsSection = {
  __typename?: "SupplyReservationsCancellationPolicyDetailsSection";
  policyName: EgdsStandardLink;
  policyNameClickedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  policyTip?: Maybe<EgdsIconText>;
  rules: Array<SupplyReservationsCancellationPolicyRule>;
};

export type SupplyReservationsCancellationPolicyDetailsToggleLink = {
  __typename?: "SupplyReservationsCancellationPolicyDetailsToggleLink";
  collapsedLabel?: Maybe<Scalars["String"]["output"]>;
  expanded?: Maybe<Scalars["Boolean"]["output"]>;
  expandedLabel?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsCancellationPolicyRule = {
  __typename?: "SupplyReservationsCancellationPolicyRule";
  primary: Scalars["String"]["output"];
  secondary: Scalars["String"]["output"];
};

export type SupplyReservationsClickstreamContext = {
  __typename?: "SupplyReservationsClickstreamContext";
  cancellation_fee?: Maybe<Scalars["String"]["output"]>;
  cancellation_reason?: Maybe<Scalars["String"]["output"]>;
  error?: Maybe<ClickstreamError>;
  impression_duration?: Maybe<Scalars["Int"]["output"]>;
  no_show_cancellation_fee?: Maybe<Scalars["String"]["output"]>;
  trip_state?: Maybe<Scalars["String"]["output"]>;
  workflow?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsClickstreamData = {
  __typename?: "SupplyReservationsClickstreamData";
  additional_context?: Maybe<SupplyReservationsClickstreamContext>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyReservationsConfirmationContent = {
  __typename?: "SupplyReservationsConfirmationContent";
  confirm: UiPrimaryButton;
  heading: Scalars["String"]["output"];
  helpUrl?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  preApprovalSent?: Maybe<Scalars["Boolean"]["output"]>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  violations?: Maybe<Array<SupplyReservationsViolations>>;
};

export type SupplyReservationsDamageDeposit = {
  __typename?: "SupplyReservationsDamageDeposit";
  actions?: Maybe<SupplyReservationsActions>;
  defaultExpanded: Scalars["Boolean"]["output"];
  listItems: Array<SupplyReservationsDamageDepositListItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  secondaryActions?: Maybe<SupplyReservationsActions>;
  secondaryListItems?: Maybe<Array<SupplyReservationsDamageDepositListItem>>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsDamageDepositListItem = {
  __typename?: "SupplyReservationsDamageDepositListItem";
  primary: EgdsStylizedText;
  secondary?: Maybe<EgdsStylizedText>;
  subtext?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsDeclineContent =
  | SupplyReservationsBookingRequestDetails
  | SupplyReservationsBookingRequestErrorState
  | SupplyReservationsDeclineOptionsContent;

export type SupplyReservationsDeclineOption = "BLOCK_DATES" | "LEAVE_DATES";

export type SupplyReservationsDeclineOptionsContent = {
  __typename?: "SupplyReservationsDeclineOptionsContent";
  bookingDates: SupplyReservationsStayDetailsItem;
  decline: UiPrimaryButton;
  heading: Scalars["String"]["output"];
  instructions: Scalars["String"]["output"];
  options: EgdsRadioGroup;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsDeclineStep = "CONFIRM" | "REASON_AND_MESSAGE";

export type SupplyReservationsDetailsContent = {
  __typename?: "SupplyReservationsDetailsContent";
  backToConversationButton?: Maybe<EgdsStandardLink>;
  errorState?: Maybe<SupplyReservationsDetailsErrorState>;
  reservationDetailsCapturedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reservationDetailsViewedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reservationId?: Maybe<Scalars["String"]["output"]>;
  supplyReservationsAccessInstructions?: Maybe<SupplyReservationsAccessInstructions>;
  supplyReservationsCanceledTwentyFourPolicy?: Maybe<SupplyReservationsCanceledTwentyFourPolicy>;
  supplyReservationsCancellationPolicy?: Maybe<SupplyReservationsCancellationPolicy>;
  supplyReservationsDamageDeposit?: Maybe<SupplyReservationsDamageDeposit>;
  supplyReservationsGuestInfo?: Maybe<SupplyReservationsGuestInfo>;
  /** @deprecated use supplyReservationsPaymentStatuses */
  supplyReservationsInvoiceStatuses?: Maybe<SupplyReservationsInvoiceStatuses>;
  supplyReservationsMiniAvailabilityCalendar?: Maybe<SupplyReservationsMiniAvailabilityCalendar>;
  supplyReservationsPartnerNotes?: Maybe<SupplyReservationsPartnerNotes>;
  supplyReservationsPaymentDetails?: Maybe<SupplyReservationsPaymentDetails>;
  /** @deprecated use supplyReservationsPaymentStatuses */
  supplyReservationsPaymentRequestStatuses?: Maybe<SupplyReservationsPaymentRequestStatuses>;
  supplyReservationsPaymentSchedule?: Maybe<SupplyReservationsPaymentSchedule>;
  supplyReservationsPaymentStatuses?: Maybe<Array<SupplyReservationsPaymentStatuses>>;
  supplyReservationsPaymentSummary?: Maybe<SupplyReservationsPaymentSummary>;
  supplyReservationsRentalAgreement?: Maybe<SupplyReservationsRentalAgreement>;
  supplyReservationsStayDetails?: Maybe<SupplyReservationsStayDetails>;
  supplyReservationsTravelerInfo?: Maybe<SupplyReservationsTravelerInfo>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsDetailsErrorState = {
  __typename?: "SupplyReservationsDetailsErrorState";
  button: UiSecondaryButton;
  description: EgdsStylizedText;
  icon: Icon;
  title: EgdsStylizedText;
};

export type SupplyReservationsEvcManagerActivityTable = {
  __typename?: "SupplyReservationsEvcManagerActivityTable";
  additionalComputationRows?: Maybe<Array<SupplyReservationsEvcManagerCardActivityAdditionalComputationRows>>;
  cardActivityDataList?: Maybe<Array<SupplyReservationsEvcManagerCardActivity>>;
  columnHeaders?: Maybe<Array<SupplyReservationsEvcManagerTableListColumnHeader>>;
  emptyState?: Maybe<SupplyReservationsEvcManagerEmptyState>;
};

export type SupplyReservationsEvcManagerCardActivity = {
  __typename?: "SupplyReservationsEvcManagerCardActivity";
  amount?: Maybe<Scalars["String"]["output"]>;
  authCode?: Maybe<Scalars["String"]["output"]>;
  authDate?: Maybe<Scalars["String"]["output"]>;
  postedDate?: Maybe<Scalars["String"]["output"]>;
  statusOrDeclineReason?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsEvcManagerCardActivityAdditionalComputationRows = {
  __typename?: "SupplyReservationsEvcManagerCardActivityAdditionalComputationRows";
  label?: Maybe<Scalars["String"]["output"]>;
  strikeThroughValue?: Maybe<Scalars["Boolean"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsEvcManagerCardDataItemCardDetailsObject = {
  __typename?: "SupplyReservationsEvcManagerCardDataItemCardDetailsObject";
  title: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyReservationsEvcManagerCardInfo = {
  __typename?: "SupplyReservationsEvcManagerCardInfo";
  activityTable?: Maybe<SupplyReservationsEvcManagerActivityTable>;
  cardDetails: SupplyReservationsEvcManagerCardInfoCardDetails;
  chargeBeforeDate?: Maybe<Scalars["String"]["output"]>;
  checkInDate?: Maybe<Scalars["String"]["output"]>;
  guestName: Scalars["String"]["output"];
  reasonToRefund?: Maybe<Scalars["String"]["output"]>;
  remainingBalance?: Maybe<Scalars["String"]["output"]>;
  reservationId: SupplyReservationsAction;
  totalToCharge?: Maybe<Scalars["String"]["output"]>;
  totalToRefund?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsEvcManagerCardInfoCardDetails = {
  __typename?: "SupplyReservationsEvcManagerCardInfoCardDetails";
  details: Array<SupplyReservationsEvcManagerCardDataItemCardDetailsObject>;
};

export type SupplyReservationsEvcManagerContent = {
  __typename?: "SupplyReservationsEvcManagerContent";
  heading?: Maybe<SupplyReservationsEvcManagerHeading>;
  mainContent?: Maybe<SupplyReservationsEvcManagerMainContent>;
};

export type SupplyReservationsEvcManagerEmptyState = {
  __typename?: "SupplyReservationsEvcManagerEmptyState";
  icon?: Maybe<Icon>;
  message?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsEvcManagerHeading = {
  __typename?: "SupplyReservationsEvcManagerHeading";
  feedbackLink?: Maybe<SupplyReservationsAction>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsEvcManagerLookupView = {
  __typename?: "SupplyReservationsEvcManagerLookupView";
  errorBanner: SupplyReservationsEvcManagerLookupViewInfoBanner;
  infoBanner?: Maybe<SupplyReservationsEvcManagerLookupViewInfoBanner>;
  searchFormSection: SupplyReservationsEvcManagerLookupViewSearchForm;
  tabTitle: Scalars["String"]["output"];
};

export type SupplyReservationsEvcManagerLookupViewInfoBanner = {
  __typename?: "SupplyReservationsEvcManagerLookupViewInfoBanner";
  icon?: Maybe<Icon>;
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsEvcManagerLookupViewSearchForm = {
  __typename?: "SupplyReservationsEvcManagerLookupViewSearchForm";
  datePicker?: Maybe<EgdsDateRangePicker>;
  heading?: Maybe<SupplyReservationsEvcManagerLookupViewSearchHeading>;
  inputForms?: Maybe<SupplyReservationsEvcManagerLookupViewSearchInputs>;
  searchAction?: Maybe<SupplyReservationsAction>;
};

export type SupplyReservationsEvcManagerLookupViewSearchHeading = {
  __typename?: "SupplyReservationsEvcManagerLookupViewSearchHeading";
  subTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsEvcManagerLookupViewSearchInputs = {
  __typename?: "SupplyReservationsEvcManagerLookupViewSearchInputs";
  separatorText?: Maybe<Scalars["String"]["output"]>;
  textInputFields?: Maybe<Array<EgdsTextInputField>>;
};

export type SupplyReservationsEvcManagerMainContent = {
  __typename?: "SupplyReservationsEvcManagerMainContent";
  defaultView?: Maybe<SupplyReservationsEvcManagerTabType>;
  tabViews: Array<SupplyReservationsEvcManagerView>;
};

export type SupplyReservationsEvcManagerManageView = {
  __typename?: "SupplyReservationsEvcManagerManageView";
  cardsReadyToChargeSection?: Maybe<SupplyReservationsEvcManagerSearchResults>;
  cardsToRefundSection?: Maybe<SupplyReservationsEvcManagerSearchResults>;
  tabBadge?: Maybe<SupplyReservationsBadge>;
  tabTitle: Scalars["String"]["output"];
};

export type SupplyReservationsEvcManagerSearchResults = {
  __typename?: "SupplyReservationsEvcManagerSearchResults";
  addressInfoData: Scalars["String"]["output"];
  addressInfoTitle: Scalars["String"]["output"];
  badge?: Maybe<SupplyReservationsBadge>;
  cardDataTable?: Maybe<SupplyReservationsEvcManagerTable>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsEvcManagerTabType = "EVC_LOOKUP" | "EVC_MANAGE";

export type SupplyReservationsEvcManagerTable = {
  __typename?: "SupplyReservationsEvcManagerTable";
  cardDataList?: Maybe<Array<SupplyReservationsEvcManagerCardInfo>>;
  columnHeaders?: Maybe<Array<SupplyReservationsEvcManagerTableListColumnHeader>>;
  emptyState?: Maybe<SupplyReservationsEvcManagerEmptyState>;
  errorBanner?: Maybe<SupplyReservationsEvcManagerLookupViewInfoBanner>;
  pagination?: Maybe<SupplyReservationsTablePagination>;
};

export type SupplyReservationsEvcManagerTableListColumnHeader = {
  __typename?: "SupplyReservationsEvcManagerTableListColumnHeader";
  label?: Maybe<Scalars["String"]["output"]>;
  sortingIcon?: Maybe<Icon>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsEvcManagerTableType = "EVC_CARDS_TO_CHARGE" | "EVC_CARDS_TO_REFUND";

export type SupplyReservationsEvcManagerView =
  | SupplyReservationsEvcManagerLookupView
  | SupplyReservationsEvcManagerManageView;

export interface SupplyReservationsEvcSearchInput {
  checkInDate: Scalars["String"]["input"];
  inputType: SupplyReservationsEvcSearchInputType;
  textInput: Scalars["String"]["input"];
}

export type SupplyReservationsEvcSearchInputType = "EVC_NUMBER" | "RESERVATION_ID";

export interface SupplyReservationsEvcSearchPageInput {
  currPageIdentifier?: InputMaybe<SupplyReservationsEvcTablePaginationPageInfo>;
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageRequestedIdentifier?: InputMaybe<SupplyReservationsEvcTablePaginationPageInfo>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  prevPageIdentifiers?: InputMaybe<Array<InputMaybe<SupplyReservationsEvcTablePaginationPageInfo>>>;
}

export interface SupplyReservationsEvcTablePaginationPageInfo {
  pageIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  pageNum?: InputMaybe<Scalars["Int"]["input"]>;
}

export type SupplyReservationsGenericTrustSignal = {
  __typename?: "SupplyReservationsGenericTrustSignal";
  icon: Icon;
  label: Scalars["String"]["output"];
  sublabel?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsGuestInfo = {
  __typename?: "SupplyReservationsGuestInfo";
  bottomButtons: SupplyReservationsActions;
  defaultExpanded: Scalars["Boolean"]["output"];
  hideProfile?: Maybe<Scalars["Boolean"]["output"]>;
  listItems: Array<SupplyReservationsGuestInfoListItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  phoneNumberCopiedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  quickActionButtons?: Maybe<SupplyReservationsActions>;
  title?: Maybe<Scalars["String"]["output"]>;
  travelerProfile?: Maybe<SupplyReservationsTravelerProfile>;
};

export type SupplyReservationsGuestInfoListItem = EgdsTextIconLinkListItem | EgdsTextIconListItem;

export type SupplyReservationsHeader = {
  __typename?: "SupplyReservationsHeader";
  actionStation?: Maybe<SupplyReservationsActionStation>;
  backArrowSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  backUrl?: Maybe<HttpUri>;
  badges?: Maybe<Array<SupplyReservationsHeaderBadge>>;
  detailsLink?: Maybe<SupplyReservationsAction>;
  headerPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Moved to interactionMessages query */
  ipmBanner?: Maybe<SupplyReservationsIpmBanner>;
  /** @deprecated Moved to interactionMessages query */
  listingHiddenBanner?: Maybe<SupplyReservationsListingHiddenBanner>;
  /** @deprecated Moved to interactionMessages query */
  listingReactivatedDialog?: Maybe<SupplyReservationsListingReactivatedDialog>;
  menuActions?: Maybe<SupplyReservationsActions>;
  quickActions?: Maybe<SupplyReservationsActions>;
  subtitle?: Maybe<SupplyReservationsHeaderStatus>;
  tabs?: Maybe<Array<SupplyReservationsHeaderTab>>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsHeaderBadge = {
  __typename?: "SupplyReservationsHeaderBadge";
  accessibilityLabel: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  theme: Scalars["String"]["output"];
};

export type SupplyReservationsHeaderStatus = {
  __typename?: "SupplyReservationsHeaderStatus";
  status?: Maybe<Scalars["String"]["output"]>;
  statusTheme?: Maybe<TextTheme>;
  text: Scalars["String"]["output"];
};

export type SupplyReservationsHeaderTab = {
  __typename?: "SupplyReservationsHeaderTab";
  accessibilityLabel: Scalars["String"]["output"];
  actions: SupplyReservationsActions;
  identifier: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type SupplyReservationsIpmBanner = {
  __typename?: "SupplyReservationsIPMBanner";
  header: Scalars["String"]["output"];
  icon: Icon;
  theme?: Maybe<BannerThemes>;
};

export interface SupplyReservationsInteractionAddChatMessageRequest {
  attachmentIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  interactionId: Scalars["String"]["input"];
  reply?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyReservationsInteractionAvatar = {
  __typename?: "SupplyReservationsInteractionAvatar";
  icon?: Maybe<Icon>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsInteractionBannerTheme = "CRITICAL" | "ERROR";

export type SupplyReservationsInteractionChatDocument = {
  __typename?: "SupplyReservationsInteractionChatDocument";
  attachmentUrl: Scalars["String"]["output"];
  description: EgdsStylizedText;
  fileName: EgdsStylizedText;
  icon: Icon;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionChatImageSize = "LARGE" | "SMALL";

export type SupplyReservationsInteractionChatMediaGroup = {
  __typename?: "SupplyReservationsInteractionChatMediaGroup";
  images: Array<SupplyReservationsInteractionChatMediaImage>;
  size: SupplyReservationsInteractionChatImageSize;
};

export type SupplyReservationsInteractionChatMediaImage = {
  __typename?: "SupplyReservationsInteractionChatMediaImage";
  attachmentUrl: Scalars["String"]["output"];
  image: Image;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  shareSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionChatMessage =
  | SupplyReservationsInteractionChatDocument
  | SupplyReservationsInteractionChatMediaGroup
  | SupplyReservationsInteractionChatTextMessage;

export type SupplyReservationsInteractionChatTextMessage = {
  __typename?: "SupplyReservationsInteractionChatTextMessage";
  primary: EgdsStylizedText;
};

export type SupplyReservationsInteractionCoachmark = {
  __typename?: "SupplyReservationsInteractionCoachmark";
  card: SupplyReservationsInteractionCoachmarkCard;
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionCoachmarkCard = {
  __typename?: "SupplyReservationsInteractionCoachmarkCard";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  graphic: Icon;
  heading: Scalars["String"]["output"];
  link: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  rightIcon: Icon;
};

export type SupplyReservationsInteractionEmptyState = {
  __typename?: "SupplyReservationsInteractionEmptyState";
  action?: Maybe<SupplyReservationsInteractionEmptyStateAction>;
  columnHeaders?: Maybe<Array<SupplyReservationsInteractionListColumn>>;
  description: EgdsStylizedText;
  descriptions: Array<EgdsStylizedText>;
  error?: Maybe<SupplyReservationsInteractionErrorState>;
  icon?: Maybe<Icon>;
  image?: Maybe<Scalars["URL"]["output"]>;
  reservationsCalendarSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: EgdsStylizedText;
};

export type SupplyReservationsInteractionEmptyStateAction = EgdsStandardLink | UiSecondaryButton;

export type SupplyReservationsInteractionErrorState = {
  __typename?: "SupplyReservationsInteractionErrorState";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  button: UiPrimaryButton;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionIpmBanner = {
  __typename?: "SupplyReservationsInteractionIPMBanner";
  header: Scalars["String"]["output"];
  icon: Icon;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  theme?: Maybe<SupplyReservationsInteractionBannerTheme>;
};

export type SupplyReservationsInteractionList = {
  __typename?: "SupplyReservationsInteractionList";
  actions: SupplyReservationsInteractionListActions;
  columnHeaders: Array<SupplyReservationsInteractionListColumn>;
  interactionList: Array<SupplyReservationsInteractionListItemType>;
  pagination: SupplyReservationsInteractionsPagination;
};

export type SupplyReservationsInteractionListAction = {
  __typename?: "SupplyReservationsInteractionListAction";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  button: UiTertiaryButton;
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  listItemAction: SupplyReservationsInteractionListItemAction;
};

export type SupplyReservationsInteractionListActionDialog = {
  __typename?: "SupplyReservationsInteractionListActionDialog";
  body: Array<Scalars["String"]["output"]>;
  cancelAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  confirmAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  /** @deprecated Use footerActions for future changes */
  footer: EgdsDialogFooter;
  footerActions: SupplyReservationsInteractionListActionDialogFooterActions;
  presentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  /** @deprecated Use cancelAnalytics for future changes */
  spamCancelAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  /** @deprecated Use confirmAnalytics for future changes */
  spamConfirmAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  toolbar: EgdsDialogToolbar;
};

export type SupplyReservationsInteractionListActionDialogFooterAction = {
  __typename?: "SupplyReservationsInteractionListActionDialogFooterAction";
  actionType: SupplyReservationsInteractionListActionDialogFooterActionType;
  disabled: Scalars["Boolean"]["output"];
  text: Scalars["String"]["output"];
  /** @deprecated Use actionType for future changes */
  type: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionListActionDialogFooterActionType = "PRIMARY" | "TERTIARY";

export type SupplyReservationsInteractionListActionDialogFooterActions = {
  __typename?: "SupplyReservationsInteractionListActionDialogFooterActions";
  cancelAction: SupplyReservationsInteractionListActionDialogFooterAction;
  primaryAction: SupplyReservationsInteractionListActionDialogFooterAction;
};

export type SupplyReservationsInteractionListActions = {
  __typename?: "SupplyReservationsInteractionListActions";
  actions: Array<SupplyReservationsInteractionListAction>;
  allSelectedText: Scalars["String"]["output"];
  numberOfSelectedText: Scalars["String"]["output"];
  /** @deprecated Use numberOfSelectedText */
  numberSelectedText: Scalars["String"]["output"];
  swipeActions: Array<SupplyReservationsInteractionListSwipeAction>;
};

export type SupplyReservationsInteractionListColumn = {
  __typename?: "SupplyReservationsInteractionListColumn";
  checkbox?: Maybe<EgdsBasicCheckBox>;
  horizontalAlign?: Maybe<SupplyReservationsInteractionListColumnHorizontalAlign>;
  label: Scalars["String"]["output"];
  sortingIcon?: Maybe<Icon>;
  value?: Maybe<Scalars["String"]["output"]>;
  verticalAlign?: Maybe<SupplyReservationsInteractionListColumnVerticalAlign>;
};

export type SupplyReservationsInteractionListColumnHorizontalAlign =
  | "CENTER"
  | "END"
  | "JUSTIFY"
  | "JUSTIFY_ALL"
  | "LEFT"
  | "MATCH_PARENT"
  | "RIGHT"
  | "START";

export type SupplyReservationsInteractionListColumnVerticalAlign =
  | "BASELINE"
  | "BOTTOM"
  | "MIDDLE"
  | "SUB"
  | "SUPER"
  | "TEXT_BOTTOM"
  | "TEXT_TOP"
  | "TOP";

export type SupplyReservationsInteractionListItemAction = "ARCHIVE" | "READ" | "SPAM" | "UNARCHIVE" | "UNREAD";

export type SupplyReservationsInteractionListItemHobbyist = SupplyReservationsInteractionListItemShared & {
  __typename?: "SupplyReservationsInteractionListItemHobbyist";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  arriving?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  avatar?: Maybe<SupplyReservationsInteractionAvatar>;
  callButton?: Maybe<UiSecondaryButton>;
  callButtonPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  callButtonSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  countdownBadge?: Maybe<SupplyReservationsTimerBadge>;
  countdownTime?: Maybe<SupplyReservationsInteractionListItemStyledCountdown>;
  countupBadge?: Maybe<SupplyReservationsTimerBadge>;
  countupTime?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  detailLink?: Maybe<HttpUri>;
  firstName: SupplyReservationsInteractionListItemStyledString;
  guestCount?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  guestCountsWithStayDates: SupplyReservationsInteractionListItemStyledString;
  guestName: SupplyReservationsInteractionListItemStyledString;
  headline?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  interactionId: Scalars["String"]["output"];
  interactionLink?: Maybe<HttpUri>;
  itemPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  lastMessage?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  lastName: SupplyReservationsInteractionListItemStyledString;
  /** @deprecated Use lastMessage for future changes */
  message: SupplyReservationsInteractionListItemStyledString;
  messageButton?: Maybe<UiSecondaryButton>;
  messageButtonPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  messageButtonSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  propertyDetails?: Maybe<Scalars["String"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  receivedTime: SupplyReservationsInteractionListItemStyledString;
  reservationReferenceNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  status: SupplyReservationsInteractionListItemStyledString;
  stayDates: SupplyReservationsInteractionListItemStyledString;
  stayDatesShort: SupplyReservationsInteractionListItemStyledString;
  unread?: Maybe<EgdsBadgeNotification>;
  validActions: Array<SupplyReservationsInteractionListItemAction>;
};

export type SupplyReservationsInteractionListItemOptimizer = SupplyReservationsInteractionListItemShared & {
  __typename?: "SupplyReservationsInteractionListItemOptimizer";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  arriving?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  avatar?: Maybe<SupplyReservationsInteractionAvatar>;
  confirmationNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  countdownBadge?: Maybe<SupplyReservationsTimerBadge>;
  countupBadge?: Maybe<SupplyReservationsTimerBadge>;
  detailLink?: Maybe<HttpUri>;
  firstName: SupplyReservationsInteractionListItemStyledString;
  interactionId: Scalars["String"]["output"];
  interactionLink?: Maybe<HttpUri>;
  itemPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  lastName: SupplyReservationsInteractionListItemStyledString;
  paymentAmount?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  receivedTime?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  reservationReferenceNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  roomType?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  stayDates: SupplyReservationsInteractionListItemStyledString;
  stayDatesShort: SupplyReservationsInteractionListItemStyledString;
  unread?: Maybe<EgdsBadgeNotification>;
  validActions: Array<SupplyReservationsInteractionListItemAction>;
};

export interface SupplyReservationsInteractionListItemShared {
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  arriving?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  avatar?: Maybe<SupplyReservationsInteractionAvatar>;
  countdownBadge?: Maybe<SupplyReservationsTimerBadge>;
  countupBadge?: Maybe<SupplyReservationsTimerBadge>;
  detailLink?: Maybe<HttpUri>;
  firstName: SupplyReservationsInteractionListItemStyledString;
  interactionId: Scalars["String"]["output"];
  interactionLink?: Maybe<HttpUri>;
  itemPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  lastName: SupplyReservationsInteractionListItemStyledString;
  receivedTime?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  reservationReferenceNumber?: Maybe<SupplyReservationsInteractionListItemStyledString>;
  stayDates: SupplyReservationsInteractionListItemStyledString;
  stayDatesShort: SupplyReservationsInteractionListItemStyledString;
  unread?: Maybe<EgdsBadgeNotification>;
  validActions: Array<SupplyReservationsInteractionListItemAction>;
}

export interface SupplyReservationsInteractionListItemStyledBase {
  hint: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  text: EgdsStylizedText;
}

export type SupplyReservationsInteractionListItemStyledCountdown = SupplyReservationsInteractionListItemStyledBase & {
  __typename?: "SupplyReservationsInteractionListItemStyledCountdown";
  expiresOn: Scalars["String"]["output"];
  hint: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  text: EgdsStylizedText;
};

export type SupplyReservationsInteractionListItemStyledString = SupplyReservationsInteractionListItemStyledBase & {
  __typename?: "SupplyReservationsInteractionListItemStyledString";
  backgroundColor?: Maybe<Scalars["String"]["output"]>;
  hint: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  label?: Maybe<EgdsStylizedText>;
  text: EgdsStylizedText;
};

export type SupplyReservationsInteractionListItemType =
  | SupplyReservationsInteractionListItemHobbyist
  | SupplyReservationsInteractionListItemOptimizer;

export type SupplyReservationsInteractionListSwipeAction = {
  __typename?: "SupplyReservationsInteractionListSwipeAction";
  action: SupplyReservationsInteractionListItemAction;
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  backgroundColor: Scalars["String"]["output"];
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  displayEdge: SupplyReservationsInteractionListSwipeActionEdge;
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionListSwipeActionEdge = "LEADING" | "TRAILING";

export type SupplyReservationsInteractionListingHiddenBanner = {
  __typename?: "SupplyReservationsInteractionListingHiddenBanner";
  /** @deprecated Use reactivateButton instead */
  button: UiSecondaryButton;
  buttonSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  dueDate: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  icon: Icon;
  listingName: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  propertyId: Scalars["String"]["output"];
  reactivateButton?: Maybe<UiSecondaryButton>;
  reactivateFailureEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  toggleSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionListingReactivatedDialog = {
  __typename?: "SupplyReservationsInteractionListingReactivatedDialog";
  errorButton?: Maybe<UiPrimaryButton>;
  errorDescription?: Maybe<Scalars["String"]["output"]>;
  errorTitle?: Maybe<Scalars["String"]["output"]>;
  header: Scalars["String"]["output"];
  icon: Icon;
  listingName: Scalars["String"]["output"];
  propertyId: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessage =
  | SupplyReservationsInteractionCoachmark
  | SupplyReservationsInteractionMessageGroup
  | SupplyReservationsInteractionSessionMarker
  | SupplyReservationsInteractionSystemMessage;

export type SupplyReservationsInteractionMessageGroup = {
  __typename?: "SupplyReservationsInteractionMessageGroup";
  chatMessages: Array<SupplyReservationsInteractionChatMessage>;
  footer: SupplyReservationsInteractionMessageGroupFooter;
  header: EgdsStylizedText;
  mode: SupplyReservationsInteractionMessageMode;
};

export type SupplyReservationsInteractionMessageGroupFooter = {
  __typename?: "SupplyReservationsInteractionMessageGroupFooter";
  /** @deprecated Use markAsSpamDialog for future changes */
  dialog?: Maybe<SupplyReservationsInteractionListActionDialog>;
  icon?: Maybe<Icon>;
  link?: Maybe<EgdsStandardLink>;
  markAsSpamDialog?: Maybe<SupplyReservationsInteractionMessageMarkAsSpamDialog>;
  status?: Maybe<EgdsStylizedText>;
  text?: Maybe<EgdsStylizedText>;
  timeStamp: EgdsStylizedText;
};

export type SupplyReservationsInteractionMessageMarkAsSpamDialog = {
  __typename?: "SupplyReservationsInteractionMessageMarkAsSpamDialog";
  body: Array<Scalars["String"]["output"]>;
  cancelAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  confirmAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  footerActions: SupplyReservationsInteractionMessagesDialogFooterActions;
  presentedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  toolbar: EgdsDialogToolbar;
};

export type SupplyReservationsInteractionMessageMode = "INCOMING" | "OUTGOING";

export interface SupplyReservationsInteractionMessagesClientData {
  timeZone: Scalars["String"]["input"];
}

export type SupplyReservationsInteractionMessagesDetailsContent =
  | SupplyReservationsInteractionMessagesDetailsContentList
  | SupplyReservationsInteractionMessagesDetailsErrorState;

export type SupplyReservationsInteractionMessagesDetailsContentList = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsContentList";
  entries: Array<SupplyReservationsInteractionMessagesDetailsEntry>;
  linkType?: Maybe<SupplyReservationsInteractionMessagesDetailsLinkType>;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesDetailsEntry =
  | EgdsStandardLink
  | SupplyReservationsInteractionMessagesDetailsEntryLineItem
  | SupplyReservationsInteractionMessagesDetailsEntrySpacing;

export type SupplyReservationsInteractionMessagesDetailsEntryLineItem = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsEntryLineItem";
  amount?: Maybe<EgdsStylizedText>;
  icon?: Maybe<Icon>;
  label: EgdsStylizedText;
  rating?: Maybe<EgdsRating>;
  sublabel?: Maybe<EgdsStylizedText>;
};

export type SupplyReservationsInteractionMessagesDetailsEntrySpacing = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsEntrySpacing";
  separatorLine: Scalars["Boolean"]["output"];
};

export type SupplyReservationsInteractionMessagesDetailsErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesDetailsErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"]["output"];
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesDetailsLinkType =
  | "CHARGEBACKS"
  | "SEE_DAMAGE_REPORT"
  | "VIEW_CSA_CLAIM";

export type SupplyReservationsInteractionMessagesDialogFooterAction = {
  __typename?: "SupplyReservationsInteractionMessagesDialogFooterAction";
  actionType: SupplyReservationsInteractionMessagesDialogFooterActionType;
  disabled: Scalars["Boolean"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesDialogFooterActionType = "PRIMARY" | "TERTIARY";

export type SupplyReservationsInteractionMessagesDialogFooterActions = {
  __typename?: "SupplyReservationsInteractionMessagesDialogFooterActions";
  cancelAction: SupplyReservationsInteractionMessagesDialogFooterAction;
  isButtonStacked: Scalars["Boolean"]["output"];
  primaryAction: SupplyReservationsInteractionMessagesDialogFooterAction;
};

export type SupplyReservationsInteractionMessagesErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesErrorState";
  button: UiSecondaryButton;
  description: EgdsStylizedText;
  icon: Icon;
  title: EgdsStylizedText;
};

export type SupplyReservationsInteractionMessagesGraphqlErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesGraphqlErrorState";
  button?: Maybe<UiSecondaryButton>;
  description: Scalars["String"]["output"];
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesList = {
  __typename?: "SupplyReservationsInteractionMessagesList";
  conversationMarker: UiMessagingCard;
  ipmBanner?: Maybe<SupplyReservationsInteractionIpmBanner>;
  listingHiddenBanner?: Maybe<SupplyReservationsInteractionListingHiddenBanner>;
  listingReactivatedDialog?: Maybe<SupplyReservationsInteractionListingReactivatedDialog>;
  messages?: Maybe<Array<SupplyReservationsInteractionMessage>>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReply = {
  __typename?: "SupplyReservationsInteractionMessagesReply";
  content: SupplyReservationsInteractionMessagesReplyContent;
  pendingMessage: SupplyReservationsInteractionReplyPendingMessage;
};

export type SupplyReservationsInteractionMessagesReplyAttachments = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachments";
  content: SupplyReservationsInteractionMessagesReplyAttachmentsContainer;
  toolbar: SupplyReservationsInteractionMessagesReplyModalToolbar;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsCategory = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsCategory";
  attachments?: Maybe<Array<SupplyReservationsInteractionMessagesReplyAttachmentsEntryItem>>;
  label: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsContainer =
  | SupplyReservationsInteractionMessagesReplyAttachmentsContent
  | SupplyReservationsInteractionMessagesReplyAttachmentsEmptyState
  | SupplyReservationsInteractionMessagesReplyAttachmentsErrorState;

export type SupplyReservationsInteractionMessagesReplyAttachmentsContent = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsContent";
  acceptFileTypes: Array<Scalars["String"]["output"]>;
  actionButtonText: Scalars["String"]["output"];
  attachmentsServiceEndpoint: Scalars["String"]["output"];
  categories: Array<SupplyReservationsInteractionMessagesReplyAttachmentsCategory>;
  errorBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner;
  fileLimits: SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits;
  newFileSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  takeAPhotoButton?: Maybe<UiSecondaryButton>;
  takeAPhotoSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  uploadFileButton: UiSecondaryButton;
  uploadFileSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsEmptyState = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsEmptyState";
  acceptFileTypes: Array<Scalars["String"]["output"]>;
  actionButtonText: Scalars["String"]["output"];
  attachmentsServiceEndpoint: Scalars["String"]["output"];
  categories: Array<SupplyReservationsInteractionMessagesReplyAttachmentsCategory>;
  description: Scalars["String"]["output"];
  errorBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner;
  fileLimits: SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits;
  icon: Icon;
  newFileSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  takeAPhotoButton?: Maybe<UiSecondaryButton>;
  takeAPhotoSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
  uploadFileButton: UiSecondaryButton;
  uploadFileSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsEntryItem = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsEntryItem";
  id: Scalars["String"]["output"];
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subText?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorBanner";
  approachingLimitPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  errorTexts: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerTexts;
  limitReachedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  serverErrorBanner?: Maybe<SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent>;
  uploadFailedPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  uploadSuccessPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent";
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerTexts = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerTexts";
  /** @deprecated Replaced by approachingFileLimitSingularBanner and approachingFileLimitPluralBanner */
  approachingFileLimitBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  approachingFileLimitPluralBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  approachingFileLimitSingularBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  attachmentsLimitReachedBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  fileTooLargeBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
  uploadFailureBanner: SupplyReservationsInteractionMessagesReplyAttachmentsErrorBannerContent;
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"]["output"];
  errorEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  icon: Icon;
  reloadEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits = {
  __typename?: "SupplyReservationsInteractionMessagesReplyAttachmentsFileLimits";
  reachingLimit: Scalars["Int"]["output"];
  uploadLimit: Scalars["Int"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyContent = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContent";
  attachments: SupplyReservationsInteractionMessagesReplyContentSheet;
  collapsedMenu: SupplyReservationsInteractionMessagesReplyContentMenu;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sendEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  submitEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  templates: SupplyReservationsInteractionMessagesReplyContentSheet;
  textArea: SupplyReservationsInteractionMessagesReplyContentTextArea;
};

export type SupplyReservationsInteractionMessagesReplyContentMenu = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentMenu";
  menuButton: UiTertiaryButton;
  menuListItems: SupplyReservationsInteractionMessagesReplyContentMenuItems;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyContentMenuItem = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentMenuItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  iconName?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyContentMenuItems = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentMenuItems";
  attachmentsItem: SupplyReservationsInteractionMessagesReplyContentMenuItem;
  templatesItem: SupplyReservationsInteractionMessagesReplyContentMenuItem;
};

export type SupplyReservationsInteractionMessagesReplyContentModal = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentModal";
  action: UiLinkAction;
  actionLabel: Scalars["String"]["output"];
  closeEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  graphqlErrorState: SupplyReservationsInteractionMessagesGraphqlErrorState;
  manageEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyContentSheet = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentSheet";
  button: UiTertiaryButton;
  contentModal: SupplyReservationsInteractionMessagesReplyContentModal;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyContentTextArea = {
  __typename?: "SupplyReservationsInteractionMessagesReplyContentTextArea";
  accessibility: Scalars["String"]["output"];
  maxLengthError: Scalars["String"]["output"];
  placeholder: Scalars["String"]["output"];
  removeEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sendButtonAccessibility: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyModalToolbar = {
  __typename?: "SupplyReservationsInteractionMessagesReplyModalToolbar";
  closeButton: UiTertiaryButton;
  closeButtonSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  manageButton: UiTertiaryButton;
  manageButtonAction: UiLinkAction;
  manageButtonSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyTemplates = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplates";
  content: SupplyReservationsInteractionMessagesReplyTemplatesContent;
  toolbar: SupplyReservationsInteractionMessagesReplyModalToolbar;
};

export type SupplyReservationsInteractionMessagesReplyTemplatesCategory = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesCategory";
  label: Scalars["String"]["output"];
  templates: Array<SupplyReservationsInteractionMessagesReplyTemplatesEntry>;
};

export type SupplyReservationsInteractionMessagesReplyTemplatesCategoryList = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesCategoryList";
  categories: Array<SupplyReservationsInteractionMessagesReplyTemplatesCategory>;
  confirmationTexts: SupplyReservationsInteractionMessagesReplyTemplatesConfirmationTexts;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionMessagesReplyTemplatesConfirmationTexts = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesConfirmationTexts";
  acceptButtonLabel: Scalars["String"]["output"];
  cancelButtonLabel: Scalars["String"]["output"];
  warningMessage: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesContent =
  | SupplyReservationsInteractionMessagesReplyTemplatesCategoryList
  | SupplyReservationsInteractionMessagesReplyTemplatesErrorState;

export type SupplyReservationsInteractionMessagesReplyTemplatesEntry =
  | SupplyReservationsInteractionMessagesReplyTemplatesEntryItem
  | SupplyReservationsInteractionMessagesReplyTemplatesEntrySeparator;

export type SupplyReservationsInteractionMessagesReplyTemplatesEntryItem = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesEntryItem";
  attachments?: Maybe<Array<SupplyReservationsInteractionMessagesReplyTemplatesEntryItemAttachment>>;
  button: UiSecondaryButton;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesEntryItemAttachment = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesEntryItemAttachment";
  attachmentType: Scalars["String"]["output"];
  attachmentUrl: Scalars["String"]["output"];
  attachmentUuid: Scalars["String"]["output"];
  thumbnailUrl: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesEntrySeparator = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesEntrySeparator";
  separatorLine: Scalars["Boolean"]["output"];
};

export type SupplyReservationsInteractionMessagesReplyTemplatesErrorState = {
  __typename?: "SupplyReservationsInteractionMessagesReplyTemplatesErrorState";
  button: UiSecondaryButton;
  description: Scalars["String"]["output"];
  icon: Icon;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reloadEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionMessagesResponse = {
  __typename?: "SupplyReservationsInteractionMessagesResponse";
  interactionMessageDetails: SupplyReservationsInteractionMessagesDetailsContent;
  interactionMessagesList: SupplyReservationsInteractionMessagesView;
  interactionMessagesReplyAttachments?: Maybe<SupplyReservationsInteractionMessagesReplyAttachments>;
  interactionMessagesReplyTemplates: SupplyReservationsInteractionMessagesReplyTemplates;
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessageDetailsArgs = {
  interactionId?: InputMaybe<Scalars["String"]["input"]>;
  interactionMessageId: Scalars["String"]["input"];
  interactionMessageType?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessagesListArgs = {
  interactionId: Scalars["String"]["input"];
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessagesReplyAttachmentsArgs = {
  interactionId: Scalars["String"]["input"];
};

export type SupplyReservationsInteractionMessagesResponseInteractionMessagesReplyTemplatesArgs = {
  context: ContextInput;
  interactionId: Scalars["String"]["input"];
};

export type SupplyReservationsInteractionMessagesView = {
  __typename?: "SupplyReservationsInteractionMessagesView";
  content: SupplyReservationsInteractionMessagesViewContent;
  reply: SupplyReservationsInteractionMessagesReply;
  shouldMarkAsRead: Scalars["Boolean"]["output"];
};

export type SupplyReservationsInteractionMessagesViewContent =
  | SupplyReservationsInteractionMessagesErrorState
  | SupplyReservationsInteractionMessagesList;

export type SupplyReservationsInteractionReplyPendingMessage = {
  __typename?: "SupplyReservationsInteractionReplyPendingMessage";
  document: SupplyReservationsInteractionChatDocument;
  footer: SupplyReservationsInteractionReplyPendingMessageFooter;
  header: EgdsStylizedText;
  textMessage: SupplyReservationsInteractionChatTextMessage;
};

export type SupplyReservationsInteractionReplyPendingMessageFooter = {
  __typename?: "SupplyReservationsInteractionReplyPendingMessageFooter";
  error: SupplyReservationsInteractionMessageGroupFooter;
  retryEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sending: SupplyReservationsInteractionMessageGroupFooter;
};

export type SupplyReservationsInteractionResponse = {
  __typename?: "SupplyReservationsInteractionResponse";
  downloadInteractions: Scalars["String"]["output"];
  interactionList: SupplyReservationsInteractionView;
  interactionsCount: SupplyReservationsInteractionsCount;
  responsivenessMetrics?: Maybe<SupplyReservationsInteractionsResponsiveness>;
  upcomingInteractions: SupplyReservationsInteractionsUpcomingView;
};

export type SupplyReservationsInteractionResponseDownloadInteractionsArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]["input"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type SupplyReservationsInteractionResponseInteractionListArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  paginationInput?: InputMaybe<SupplyReservationsInteractionsPageInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]["input"]>;
  sortInput?: InputMaybe<SupplyReservationsInteractionsSort>;
};

export type SupplyReservationsInteractionResponseInteractionsCountArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  filterInput?: InputMaybe<SupplyReservationsInteractionsFilterInput>;
  propertyContext?: InputMaybe<PropertyContext>;
  searchInput?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupplyReservationsInteractionResponseResponsivenessMetricsArgs = {
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type SupplyReservationsInteractionResponseUpcomingInteractionsArgs = {
  clientDataInput?: InputMaybe<SupplyReservationsInteractionsClientDataInput>;
  context: ContextInput;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type SupplyReservationsInteractionSessionMarker = {
  __typename?: "SupplyReservationsInteractionSessionMarker";
  primary: Scalars["String"]["output"];
  separatorLine?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyReservationsInteractionSystemMessage = {
  __typename?: "SupplyReservationsInteractionSystemMessage";
  card: EgdsStandardMessagingCard;
  dialog?: Maybe<SupplyReservationsInteractionSystemMessageDialog>;
  eyebrow: EgdsStylizedText;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsInteractionSystemMessageDialog = {
  __typename?: "SupplyReservationsInteractionSystemMessageDialog";
  graphqlErrorState: SupplyReservationsInteractionMessagesGraphqlErrorState;
  interactionId?: Maybe<Scalars["String"]["output"]>;
  interactionMessageId: Scalars["String"]["output"];
  interactionMessageType?: Maybe<Scalars["String"]["output"]>;
  presentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  toolbarTitle: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionUpdateResponse = {
  __typename?: "SupplyReservationsInteractionUpdateResponse";
  updateInteractions: SupplyReservationsInteractionsListItemActionResponse;
};

export type SupplyReservationsInteractionUpdateResponseUpdateInteractionsArgs = {
  action: SupplyReservationsInteractionListItemAction;
  context: ContextInput;
  interactionIds: Array<Scalars["String"]["input"]>;
  propertyContext?: InputMaybe<PropertyContext>;
};

export type SupplyReservationsInteractionView = {
  __typename?: "SupplyReservationsInteractionView";
  content: SupplyReservationsInteractionViewContent;
  experimentalFlags?: Maybe<Array<Scalars["String"]["output"]>>;
  filters: SupplyReservationsInteractionsQuickFilters;
  header: SupplyReservationsInteractionsHeader;
  pageDialog?: Maybe<SupplyReservationsInteractionsPageDialog>;
  pageViewAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  searchHistory: SupplyReservationsInteractionsSearchHistory;
  searchInput: EgdsTextInputField;
  searchNameAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  searchReservationAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  selectedFiltersAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  sideSheet: SupplyReservationsInteractionsFilterSideSheet;
};

export type SupplyReservationsInteractionViewContent =
  | SupplyReservationsInteractionEmptyState
  | SupplyReservationsInteractionList;

export type SupplyReservationsInteractionViewType = "CAROUSEL_VIEW" | "LIST_VIEW";

export type SupplyReservationsInteractionsBasicPill = SupplyReservationsInteractionsBasicPillShared &
  SupplyReservationsInteractionsPillShared & {
    __typename?: "SupplyReservationsInteractionsBasicPill";
    filters: Array<Scalars["String"]["output"]>;
    pill: EgdsBasicPill;
    sort?: Maybe<SupplyReservationsInteractionsSort>;
  };

export interface SupplyReservationsInteractionsBasicPillShared {
  filters: Array<Scalars["String"]["output"]>;
  sort?: Maybe<SupplyReservationsInteractionsSort>;
}

export type SupplyReservationsInteractionsBasicTriggerPill = SupplyReservationsInteractionsPillShared & {
  __typename?: "SupplyReservationsInteractionsBasicTriggerPill";
  pill: EgdsBasicTriggerPill;
  target: SupplyReservationsInteractionsTriggerTarget;
};

export type SupplyReservationsInteractionsClickStream = IEgClickstreamData & {
  __typename?: "SupplyReservationsInteractionsClickStream";
  additionalContext?: Maybe<EgClickstreamAdditionalContext>;
  customizedAttributes?: Maybe<Scalars["String"]["output"]>;
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partnerCommon?: Maybe<EgClickstreamPartnerCommonData>;
};

export interface SupplyReservationsInteractionsClientDataInput {
  timezone: Scalars["String"]["input"];
}

export interface SupplyReservationsInteractionsClientInput {
  timezone: Scalars["String"]["input"];
}

export type SupplyReservationsInteractionsCount = {
  __typename?: "SupplyReservationsInteractionsCount";
  count: Scalars["Int"]["output"];
  unreadCount: Scalars["Int"]["output"];
  viewMessagesCount: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionsExpandoContent = EgdsCheckBoxGroup | EgdsDateRangePicker;

export type SupplyReservationsInteractionsFilterExpandos = {
  __typename?: "SupplyReservationsInteractionsFilterExpandos";
  clear: UiTertiaryButton;
  content: SupplyReservationsInteractionsExpandoContent;
  display: EgdsExpandoListItem;
  title: Scalars["String"]["output"];
};

export interface SupplyReservationsInteractionsFilterInput {
  endDate?: InputMaybe<Scalars["String"]["input"]>;
  filters?: InputMaybe<Array<Scalars["String"]["input"]>>;
  startDate?: InputMaybe<Scalars["String"]["input"]>;
}

export type SupplyReservationsInteractionsFilterPill = EgdsBasicPill | SupplyReservationsInteractionsTriggerPill;

export type SupplyReservationsInteractionsFilterSideSheet = {
  __typename?: "SupplyReservationsInteractionsFilterSideSheet";
  applyFilters: UiPrimaryFloatingActionButton;
  display: EgdsSheet;
  expandos: Array<SupplyReservationsInteractionsFilterExpandos>;
  filterSortToolbar: UiToolbar;
  sort: EgdsBasicSelect;
  toolbar: UiToolbar;
};

export type SupplyReservationsInteractionsHeader = {
  __typename?: "SupplyReservationsInteractionsHeader";
  dialog: SupplyReservationsInteractionListActionDialog;
  primaryActions: Array<UiTertiaryButton>;
  primaryButtons: Array<UiTertiaryButton>;
  primaryButtonsAnalytics?: Maybe<Array<SupplyReservationsInteractionsClickStream>>;
  secondaryButtons: Array<UiTertiaryButton>;
  secondaryButtonsAnalytics?: Maybe<Array<SupplyReservationsInteractionsClickStream>>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionsListItemActionResponse =
  | SupplyReservationsInteractionErrorState
  | SupplyReservationsInteractionsListItemActionResponseContent;

export type SupplyReservationsInteractionsListItemActionResponseContent = {
  __typename?: "SupplyReservationsInteractionsListItemActionResponseContent";
  actionCompletedText: Scalars["String"]["output"];
  undoAction: SupplyReservationsInteractionsListItemUndoAction;
  undoCompletedText: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionsListItemUndoAction = {
  __typename?: "SupplyReservationsInteractionsListItemUndoAction";
  button: UiTertiaryButton;
  interactionIds: Array<Scalars["String"]["output"]>;
  listItemAction: SupplyReservationsInteractionListItemAction;
};

export type SupplyReservationsInteractionsPageDialog = {
  __typename?: "SupplyReservationsInteractionsPageDialog";
  body: Scalars["String"]["output"];
  closedAnalytics: SupplyReservationsInteractionsClickStream;
  footer: SupplyReservationsInteractionsPageDialogFooterActions;
  heading: EgdsDialogToolbar;
  shownAnalytics: SupplyReservationsInteractionsClickStream;
};

export type SupplyReservationsInteractionsPageDialogFooterActions = {
  __typename?: "SupplyReservationsInteractionsPageDialogFooterActions";
  primaryAction: SupplyReservationsInteractionListActionDialogFooterAction;
};

export interface SupplyReservationsInteractionsPageInput {
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
}

export type SupplyReservationsInteractionsPagination = {
  __typename?: "SupplyReservationsInteractionsPagination";
  paginationIndexDetails: SupplyReservationsInteractionsPaginationIndexDetails;
  paginationTrigger: SupplyReservationsInteractionsPaginationTrigger;
};

export type SupplyReservationsInteractionsPaginationIndexDetails = {
  __typename?: "SupplyReservationsInteractionsPaginationIndexDetails";
  itemsPerPage: EgdsBasicSelect;
  selectPaginationItemAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  showPaginationDropdownAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  startPageIndex: EgdsNumberInputField;
};

export type SupplyReservationsInteractionsPaginationTrigger = {
  __typename?: "SupplyReservationsInteractionsPaginationTrigger";
  nextRecords: SupplyReservationsInteractionsPaginationTriggerButton;
  previousRecords: SupplyReservationsInteractionsPaginationTriggerButton;
  recordCount: Scalars["String"]["output"];
  recordSummary: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionsPaginationTriggerButton = {
  __typename?: "SupplyReservationsInteractionsPaginationTriggerButton";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  button: UiSecondaryButton;
  triggerDetails?: Maybe<SupplyReservationsInteractionsPaginationTriggerPageDetails>;
};

export type SupplyReservationsInteractionsPaginationTriggerPageDetails = {
  __typename?: "SupplyReservationsInteractionsPaginationTriggerPageDetails";
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  pageSize: Scalars["Int"]["output"];
};

export interface SupplyReservationsInteractionsPillShared {
  pill: EgdsPill;
}

export type SupplyReservationsInteractionsQuickFilters = {
  __typename?: "SupplyReservationsInteractionsQuickFilters";
  clearFilters?: Maybe<UiTertiaryButton>;
  defaultFilterPills: Array<SupplyReservationsInteractionsPillShared>;
  /** @deprecated Use defaultFilterPills instead */
  defaultPills: Array<EgdsBasicPill>;
  filterAccess: UiTertiaryButton;
  /** @deprecated Use selectedFilterPills instead */
  filterPills?: Maybe<Array<EgdsBasicRemovablePill>>;
  filterSortSheetTrigger: UiTertiaryButton;
  /** @deprecated Use selectedFilterPills instead */
  quickFilterPills: Array<SupplyReservationsInteractionsFilterPill>;
  selectedFilterPills: Array<SupplyReservationsInteractionsPillShared>;
};

export type SupplyReservationsInteractionsRemoveablePill = SupplyReservationsInteractionsBasicPillShared &
  SupplyReservationsInteractionsPillShared & {
    __typename?: "SupplyReservationsInteractionsRemoveablePill";
    filters: Array<Scalars["String"]["output"]>;
    pill: EgdsBasicRemovablePill;
    sort?: Maybe<SupplyReservationsInteractionsSort>;
  };

export type SupplyReservationsInteractionsResponsiveness = {
  __typename?: "SupplyReservationsInteractionsResponsiveness";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  banner: SupplyReservationsInteractionsResponsivenessBanner;
  sideSheet: SupplyReservationsInteractionsResponsivenessSideSheet;
};

export type SupplyReservationsInteractionsResponsivenessBanner =
  | SupplyReservationsInteractionsResponsivenessBannerMultiPropertyState
  | SupplyReservationsInteractionsResponsivenessBannerSinglePropertyState;

export type SupplyReservationsInteractionsResponsivenessBannerMultiPropertyState = {
  __typename?: "SupplyReservationsInteractionsResponsivenessBannerMultiPropertyState";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  description: EgdsStylizedText;
  icon: Icon;
  title?: Maybe<EgdsStylizedText>;
};

export type SupplyReservationsInteractionsResponsivenessBannerSinglePropertyState = {
  __typename?: "SupplyReservationsInteractionsResponsivenessBannerSinglePropertyState";
  acceptanceRateItem?: Maybe<EgdsSpannableText>;
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  closedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  openSideSheetLink?: Maybe<EgdsStandardLink>;
  openedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  responseRateItem?: Maybe<EgdsSpannableText>;
  responseTimeItem?: Maybe<EgdsSpannableText>;
  sideSheetPresentedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  trackResponseMetricsBannerLink: EgdsStandardLink;
  trackResponseMetricsBannerLinkAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsResponsivenessCardExpandoContent = {
  __typename?: "SupplyReservationsInteractionsResponsivenessCardExpandoContent";
  analytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  description: EgdsSpannableText;
  icon?: Maybe<Icon>;
  indicator: EgdsStylizedText;
  insight?: Maybe<EgdsSpannableText>;
  label: EgdsSpannableText;
};

export type SupplyReservationsInteractionsResponsivenessExpandoCard = {
  __typename?: "SupplyReservationsInteractionsResponsivenessExpandoCard";
  closedAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  content: SupplyReservationsInteractionsResponsivenessCardExpandoContent;
  display: EgdsExpandoListItem;
  openAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsResponsivenessSideSheet = {
  __typename?: "SupplyReservationsInteractionsResponsivenessSideSheet";
  description: EgdsStylizedText;
  display: EgdsSheet;
  downloadAppParagraph: EgdsSpannableText;
  downloadAppParagraphAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  expandoCards: Array<SupplyReservationsInteractionsResponsivenessExpandoCard>;
  learnMoreLink: EgdsStandardLink;
  learnMoreLinkAnalytics?: Maybe<SupplyReservationsInteractionsClickStream>;
  message?: Maybe<SupplyReservationsInteractionsResponsivenessSideSheetBanner>;
};

export type SupplyReservationsInteractionsResponsivenessSideSheetBanner = {
  __typename?: "SupplyReservationsInteractionsResponsivenessSideSheetBanner";
  heading?: Maybe<EgdsStylizedText>;
  icon?: Maybe<Icon>;
  message: EgdsStylizedText;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsInteractionsSearchHistory = {
  __typename?: "SupplyReservationsInteractionsSearchHistory";
  action: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionsSort =
  | "CHECKIN_DATE_ASC"
  | "CHECKIN_DATE_DESC"
  | "GUEST_ASC"
  | "GUEST_DESC"
  | "RECEIVED_ON_ASC"
  | "RECEIVED_ON_DESC";

export type SupplyReservationsInteractionsTriggerPill = {
  __typename?: "SupplyReservationsInteractionsTriggerPill";
  target: EgdsDateRangePicker;
  triggerPill: EgdsBasicTriggerPill;
};

export type SupplyReservationsInteractionsTriggerTarget = EgdsDateRangePicker | EgdsSheet;

export type SupplyReservationsInteractionsUpcomingContent = {
  __typename?: "SupplyReservationsInteractionsUpcomingContent";
  action?: Maybe<EgdsStandardLink>;
  empty?: Maybe<SupplyReservationsInteractionEmptyState>;
  /** @deprecated Use title and action */
  header: SupplyReservationsInteractionsUpcomingHeader;
  interactionList: Array<SupplyReservationsInteractionListItemType>;
  reservationViewType?: Maybe<SupplyReservationsInteractionViewType>;
  reservationViewTypePresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  reservationsModulePresented?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: Scalars["String"]["output"];
  viewAllReservationsSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsUpcomingErrorView = {
  __typename?: "SupplyReservationsInteractionsUpcomingErrorView";
  action?: Maybe<EgdsStandardLink>;
  content: SupplyReservationsInteractionsUpcomingErrorViewContent;
  /** @deprecated Use title and action */
  header: SupplyReservationsInteractionsUpcomingHeader;
  reservationsModulePresented?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: Scalars["String"]["output"];
  viewAllReservationsSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsUpcomingErrorViewContent = {
  __typename?: "SupplyReservationsInteractionsUpcomingErrorViewContent";
  button: UiTertiaryButton;
  description: Scalars["String"]["output"];
  icon: Icon;
  reservationsRefreshSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsInteractionsUpcomingHeader = {
  __typename?: "SupplyReservationsInteractionsUpcomingHeader";
  action?: Maybe<EgdsStandardLink>;
  title: Scalars["String"]["output"];
  /** @deprecated this component is no longer used */
  viewAllReservationsSelected?: Maybe<SupplyReservationsInteractionsClickStream>;
};

export type SupplyReservationsInteractionsUpcomingView =
  | SupplyReservationsInteractionsUpcomingContent
  | SupplyReservationsInteractionsUpcomingErrorView;

export type SupplyReservationsInvoiceStatus = {
  __typename?: "SupplyReservationsInvoiceStatus";
  action: SupplyReservationsAction;
  description?: Maybe<Scalars["String"]["output"]>;
  invoiceId?: Maybe<Scalars["String"]["output"]>;
  invoiceIdLabel?: Maybe<Scalars["String"]["output"]>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
};

export type SupplyReservationsInvoiceStatuses = {
  __typename?: "SupplyReservationsInvoiceStatuses";
  nextButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  paginationLabel?: Maybe<Scalars["String"]["output"]>;
  previousButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  statuses: Array<SupplyReservationsInvoiceStatus>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsListingHiddenBanner = {
  __typename?: "SupplyReservationsListingHiddenBanner";
  button: UiSecondaryButton;
  dueDate: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  icon: Icon;
  listingName: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  propertyId: Scalars["String"]["output"];
};

export type SupplyReservationsListingReactivatedDialog = {
  __typename?: "SupplyReservationsListingReactivatedDialog";
  header: Scalars["String"]["output"];
  icon: Icon;
  listingName: Scalars["String"]["output"];
  propertyId: Scalars["String"]["output"];
};

export type SupplyReservationsMiniAvailabilityCalendar = {
  __typename?: "SupplyReservationsMiniAvailabilityCalendar";
  url: Scalars["String"]["output"];
};

export type SupplyReservationsNightlyPaymentDetailsSection = {
  __typename?: "SupplyReservationsNightlyPaymentDetailsSection";
  label: EgdsStylizedText;
  nightlyPaymentLineItems: Array<SupplyReservationsPaymentDetailsLineItem>;
};

export type SupplyReservationsPartnerNotes = {
  __typename?: "SupplyReservationsPartnerNotes";
  actions: SupplyReservationsActions;
  alert: SupplyReservationsPartnerNotesEditAlert;
  collapsedLabel: Scalars["String"]["output"];
  content?: Maybe<Scalars["String"]["output"]>;
  errorMessage: Scalars["String"]["output"];
  expandedLabel: Scalars["String"]["output"];
  input?: Maybe<EgdsTextAreaInputField>;
  noteCreditCardValidationErrorMessagePresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  noteEditorDismissedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  noteEditorPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  noteSaveErrorMessagePresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  noteSaveFailedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  noteSaveSubmittedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  noteSaveSucceededEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  toast?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsPartnerNotesEditAlert = {
  __typename?: "SupplyReservationsPartnerNotesEditAlert";
  message: Scalars["String"]["output"];
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onPrimaryActionSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onSecondaryActionSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  primaryActionTitle: Scalars["String"]["output"];
  secondaryActionTitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsPayment = "OFFLINE" | "ONLINE";

export type SupplyReservationsPaymentDetails = {
  __typename?: "SupplyReservationsPaymentDetails";
  detailedPaymentLineItems: SupplyReservationsPaymentDisplayDetailed;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  summaryPaymentLineItems: Array<SupplyReservationsPaymentDetailsLineItem>;
};

export type SupplyReservationsPaymentDetailsAmountLineItem = {
  __typename?: "SupplyReservationsPaymentDetailsAmountLineItem";
  amount: EgdsStylizedText;
  icon?: Maybe<Icon>;
};

export type SupplyReservationsPaymentDetailsDisclaimerLineItem = {
  __typename?: "SupplyReservationsPaymentDetailsDisclaimerLineItem";
  lineItemText: EgdsStylizedText;
  link?: Maybe<EgdsStandardLink>;
};

export type SupplyReservationsPaymentDetailsExpandoComponentLineItems = {
  __typename?: "SupplyReservationsPaymentDetailsExpandoComponentLineItems";
  expando: SupplyReservationsPaymentDetailsExpandoLink;
  nightlyPaymentList: Array<SupplyReservationsNightlyPaymentDetailsSection>;
  nightlyPaymentTitle?: Maybe<SupplyReservationsPaymentDetailsTitleLineItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsPaymentDetailsExpandoLink = {
  __typename?: "SupplyReservationsPaymentDetailsExpandoLink";
  alignment?: Maybe<SupplyReservationsPaymentDetailsExpandoPositioning>;
  collapseAnalytics?: Maybe<ClientSideAnalytics>;
  collapsedLabel: Scalars["String"]["output"];
  expandAnalytics?: Maybe<ClientSideAnalytics>;
  expanded: Scalars["Boolean"]["output"];
  expandedLabel: Scalars["String"]["output"];
};

export type SupplyReservationsPaymentDetailsExpandoPositioning = "LEADING" | "TRAILING";

export type SupplyReservationsPaymentDetailsLineItem =
  | SupplyReservationsPaymentDetailsDisclaimerLineItem
  | SupplyReservationsPaymentDetailsSheetToggleLink
  | SupplyReservationsPaymentDetailsSubLineItem
  | SupplyReservationsPaymentDetailsTitleLineItem
  | SupplyReservationsPaymentDetailsTotalLineItem
  | SupplyReservationsPaymentExpandoLineItem;

export type SupplyReservationsPaymentDetailsSheetComponentLineItems = {
  __typename?: "SupplyReservationsPaymentDetailsSheetComponentLineItems";
  childLineItems: Array<SupplyReservationsPaymentDetailsLineItem>;
  onDismissedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sheet: SupplyReservationsPaymentDisplaySidePanelProps;
};

export type SupplyReservationsPaymentDetailsSheetToggleLink = {
  __typename?: "SupplyReservationsPaymentDetailsSheetToggleLink";
  lineItemText: EgdsStylizedText;
  link: EgdsStandardLink;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsPaymentDetailsSubLineItem = ISupplyReservationsPaymentLineItem &
  ISupplyReservationsPaymentMoreInfo & {
    __typename?: "SupplyReservationsPaymentDetailsSubLineItem";
    amount: SupplyReservationsPaymentDetailsAmountLineItem;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyReservationsToolTip>;
  };

export type SupplyReservationsPaymentDetailsTitleLineItem = ISupplyReservationsPaymentMoreInfo & {
  __typename?: "SupplyReservationsPaymentDetailsTitleLineItem";
  badge?: Maybe<EgdsStandardBadge>;
  footerNoteText?: Maybe<EgdsStylizedText>;
  title: EgdsStylizedText;
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsPaymentDetailsTotalLineItem = ISupplyReservationsPaymentLineItem &
  ISupplyReservationsPaymentMoreInfo & {
    __typename?: "SupplyReservationsPaymentDetailsTotalLineItem";
    amount: SupplyReservationsPaymentDetailsAmountLineItem;
    badge?: Maybe<EgdsStandardBadge>;
    borderSeparator: Scalars["Boolean"]["output"];
    currencyCode?: Maybe<EgdsStylizedText>;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    tooltip?: Maybe<SupplyReservationsToolTip>;
  };

export type SupplyReservationsPaymentDisplayDetailed =
  | SupplyReservationsPaymentDetailsExpandoComponentLineItems
  | SupplyReservationsPaymentDetailsSheetComponentLineItems;

export type SupplyReservationsPaymentDisplaySidePanelProps = {
  __typename?: "SupplyReservationsPaymentDisplaySidePanelProps";
  navType?: Maybe<SupplyReservationsPaymentDisplayToolbarIcon>;
  toolbarTitle?: Maybe<Scalars["String"]["output"]>;
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsPaymentDisplayToolbarIcon = "BACK" | "CLOSE";

export type SupplyReservationsPaymentExpandoLineItem = ISupplyReservationsPaymentLineItem &
  ISupplyReservationsPaymentMoreInfo & {
    __typename?: "SupplyReservationsPaymentExpandoLineItem";
    amount: SupplyReservationsPaymentDetailsAmountLineItem;
    expando: SupplyReservationsPaymentDetailsExpandoLink;
    footerNoteText?: Maybe<EgdsStylizedText>;
    lineItemText: EgdsStylizedText;
    originalAmount?: Maybe<SupplyReservationsPaymentDetailsAmountLineItem>;
    sideNoteText?: Maybe<EgdsStylizedText>;
    subLineItems: Array<SupplyReservationsPaymentDetailsSubLineItem>;
    tooltip?: Maybe<SupplyReservationsToolTip>;
  };

export type SupplyReservationsPaymentRequestStatus = {
  __typename?: "SupplyReservationsPaymentRequestStatus";
  description?: Maybe<Scalars["String"]["output"]>;
  paymentRequestAction: SupplyReservationsAction;
  paymentRequestId?: Maybe<Scalars["String"]["output"]>;
  paymentRequestIdLabel?: Maybe<Scalars["String"]["output"]>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
};

export type SupplyReservationsPaymentRequestStatuses = {
  __typename?: "SupplyReservationsPaymentRequestStatuses";
  nextButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  paginationLabel?: Maybe<Scalars["String"]["output"]>;
  previousButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  statuses: Array<SupplyReservationsPaymentRequestStatus>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsPaymentSchedule = {
  __typename?: "SupplyReservationsPaymentSchedule";
  actions?: Maybe<SupplyReservationsActions>;
  contents?: Maybe<Array<SupplyReservationsPaymentScheduleItem>>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  tooltip?: Maybe<SupplyReservationsToolTip>;
};

export type SupplyReservationsPaymentScheduleItem = {
  __typename?: "SupplyReservationsPaymentScheduleItem";
  actions?: Maybe<SupplyReservationsActions>;
  amount: Scalars["String"]["output"];
  details: Array<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  onExpandoToggledEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  refunds?: Maybe<Array<SupplyReservationsPaymentScheduleItemRefund>>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsPaymentScheduleItemRefund = {
  __typename?: "SupplyReservationsPaymentScheduleItemRefund";
  amount: Scalars["String"]["output"];
  details: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsPaymentStatus = {
  __typename?: "SupplyReservationsPaymentStatus";
  action: SupplyReservationsAction;
  description?: Maybe<Scalars["String"]["output"]>;
  paymentId?: Maybe<Scalars["String"]["output"]>;
  paymentIdLabel?: Maybe<Scalars["String"]["output"]>;
  statusBadge?: Maybe<SupplyReservationsBadge>;
};

export type SupplyReservationsPaymentStatuses = {
  __typename?: "SupplyReservationsPaymentStatuses";
  nextButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  paginationLabel?: Maybe<Scalars["String"]["output"]>;
  previousButton?: Maybe<SupplyReservationsTablePaginationTriggerButton>;
  statuses: Array<SupplyReservationsPaymentStatus>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsPaymentSummary = {
  __typename?: "SupplyReservationsPaymentSummary";
  bottomText?: Maybe<EgdsStylizedText>;
  button?: Maybe<SupplyReservationsAction>;
  lineItems: Array<SupplyReservationsPaymentSummaryLineItem>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsPaymentSummaryLineItem = {
  __typename?: "SupplyReservationsPaymentSummaryLineItem";
  endIcon?: Maybe<Icon>;
  endText?: Maybe<EgdsStylizedText>;
  primary?: Maybe<EgdsStylizedText>;
  secondary?: Maybe<EgdsStylizedText>;
  startIcon?: Maybe<Icon>;
  topSpacing?: Maybe<Scalars["Boolean"]["output"]>;
};

export type SupplyReservationsPropertySnoozedStatus = {
  __typename?: "SupplyReservationsPropertySnoozedStatus";
  disabled: Scalars["Boolean"]["output"];
  statusEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyReservationsRenderHint = "COLLAPSED" | "LIST";

export type SupplyReservationsRentalAgreement = {
  __typename?: "SupplyReservationsRentalAgreement";
  content: SupplyReservationsRentalAgreementContent;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: Scalars["String"]["output"];
  /** @deprecated use viewRentalAgreementButton which is optional */
  viewAgreementButton: SupplyReservationsAction;
  viewRentalAgreementButton?: Maybe<SupplyReservationsAction>;
};

export type SupplyReservationsRentalAgreementContent = {
  __typename?: "SupplyReservationsRentalAgreementContent";
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsReplyContentTextArea = {
  __typename?: "SupplyReservationsReplyContentTextArea";
  accessibility: Scalars["String"]["output"];
  maxLengthError: Scalars["String"]["output"];
  placeholder: Scalars["String"]["output"];
  removeEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  selectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sendButton?: Maybe<SupplyReservationsAction>;
};

export type SupplyReservationsStayDetails = {
  __typename?: "SupplyReservationsStayDetails";
  action?: Maybe<SupplyReservationsAction>;
  actions: SupplyReservationsActions;
  bookingStateMessage?: Maybe<SupplyReservationsStayDetailsMessage>;
  content: Array<SupplyReservationsStayDetailsItem>;
  onPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title: SupplyReservationsStayDetailsTitle;
};

export type SupplyReservationsStayDetailsBookingStateAndReservationId = {
  __typename?: "SupplyReservationsStayDetailsBookingStateAndReservationId";
  bookingState: EgdsStylizedText;
  reservationId: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsCopyButton = {
  __typename?: "SupplyReservationsStayDetailsCopyButton";
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  toast: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsCopyableReservationId = {
  __typename?: "SupplyReservationsStayDetailsCopyableReservationId";
  copyButton: SupplyReservationsStayDetailsCopyButton;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsItem = {
  __typename?: "SupplyReservationsStayDetailsItem";
  action?: Maybe<SupplyReservationsAction>;
  icon: Scalars["String"]["output"];
  link?: Maybe<SupplyReservationsStayDetailsItemLink>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  subtitleExtra?: Maybe<Array<Scalars["String"]["output"]>>;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsItemExpando = {
  __typename?: "SupplyReservationsStayDetailsItemExpando";
  content: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsItemLink = {
  __typename?: "SupplyReservationsStayDetailsItemLink";
  content: SupplyReservationsStayDetailsItemModal;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsItemModal = {
  __typename?: "SupplyReservationsStayDetailsItemModal";
  confirmButton: EgdsButton;
  expando: SupplyReservationsStayDetailsItemExpando;
  input: EgdsTextInputField;
  title: Scalars["String"]["output"];
};

export type SupplyReservationsStayDetailsMessage = {
  __typename?: "SupplyReservationsStayDetailsMessage";
  bookingStateMessage?: Maybe<Scalars["String"]["output"]>;
  timerBadge?: Maybe<EgdsStandardBadge>;
  timerDate?: Maybe<Scalars["String"]["output"]>;
  timerType?: Maybe<SupplyReservationsStayDetailsMessageTimerType>;
};

export type SupplyReservationsStayDetailsMessageTimerType = "COUNT_DOWN" | "COUNT_UP";

export type SupplyReservationsStayDetailsTitle =
  | SupplyReservationsStayDetailsBookingStateAndReservationId
  | SupplyReservationsStayDetailsCopyableReservationId;

export type SupplyReservationsTablePagination = {
  __typename?: "SupplyReservationsTablePagination";
  currentPageIdentifier: SupplyReservationsTablePaginationPageInfo;
  paginationIndexDetails?: Maybe<SupplyReservationsTablePaginationIndexDetails>;
  paginationTrigger: SupplyReservationsTablePaginationTrigger;
  prevPageIdentifiers?: Maybe<Array<Maybe<SupplyReservationsTablePaginationPageInfo>>>;
};

export type SupplyReservationsTablePaginationIndexDetails = {
  __typename?: "SupplyReservationsTablePaginationIndexDetails";
  itemsPerPage: EgdsBasicSelect;
  startPageIndex?: Maybe<EgdsNumberInputField>;
};

export type SupplyReservationsTablePaginationPageInfo = {
  __typename?: "SupplyReservationsTablePaginationPageInfo";
  pageIdentifier?: Maybe<Scalars["String"]["output"]>;
  pageNum?: Maybe<Scalars["Int"]["output"]>;
};

export type SupplyReservationsTablePaginationTrigger = {
  __typename?: "SupplyReservationsTablePaginationTrigger";
  nextRecords: SupplyReservationsTablePaginationTriggerButton;
  previousRecords: SupplyReservationsTablePaginationTriggerButton;
  recordCount: Scalars["String"]["output"];
  recordSummary: Scalars["String"]["output"];
};

export type SupplyReservationsTablePaginationTriggerButton = {
  __typename?: "SupplyReservationsTablePaginationTriggerButton";
  action: SupplyReservationsAction;
  triggerDetails?: Maybe<SupplyReservationsTablePaginationTriggerPageDetails>;
};

export type SupplyReservationsTablePaginationTriggerPageDetails = {
  __typename?: "SupplyReservationsTablePaginationTriggerPageDetails";
  pageInfo?: Maybe<SupplyReservationsTablePaginationPageInfo>;
  pageSize: Scalars["Int"]["output"];
};

export type SupplyReservationsTextArea = {
  __typename?: "SupplyReservationsTextArea";
  attachFile: UiPrimaryFloatingActionButton;
  heading: Scalars["String"]["output"];
  instructions: Scalars["String"]["output"];
  message: SupplyReservationsReplyContentTextArea;
  useTemplate: UiPrimaryFloatingActionButton;
};

export type SupplyReservationsTimer = {
  __typename?: "SupplyReservationsTimer";
  expiresOn?: Maybe<Scalars["String"]["output"]>;
  hint?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  text: EgdsStylizedText;
};

export type SupplyReservationsTimerBadge = {
  __typename?: "SupplyReservationsTimerBadge";
  badge: EgdsStandardBadge;
  dateTime: Scalars["String"]["output"];
  type: SupplyReservationsTimerBadgeType;
};

export type SupplyReservationsTimerBadgeType = "COUNT_DOWN" | "COUNT_UP";

export type SupplyReservationsToolTip = {
  __typename?: "SupplyReservationsToolTip";
  content?: Maybe<Array<SupplyReservationsTooltipContent>>;
  contentDismissedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  contentPresentedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  icon?: Maybe<Icon>;
  onSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReservationsTooltipContent = {
  __typename?: "SupplyReservationsTooltipContent";
  tooltipHeader?: Maybe<Scalars["String"]["output"]>;
  tooltipLink?: Maybe<EgdsStandardLink>;
  tooltipLinkSelectedEvent?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  tooltipText: Scalars["String"]["output"];
};

export type SupplyReservationsTravelerIdCard = {
  __typename?: "SupplyReservationsTravelerIDCard";
  hideProfile?: Maybe<Scalars["Boolean"]["output"]>;
  travelerName: Scalars["String"]["output"];
  travelerProfile: SupplyReservationsTravelerProfile;
  trustSignals: Array<SupplyReservationsTrustSignal>;
  viewProfileAction: SupplyReservationsAction;
};

export type SupplyReservationsTravelerInfo = SupplyReservationsGuestInfo | SupplyReservationsTravelerIdCard;

export type SupplyReservationsTravelerProfile = {
  __typename?: "SupplyReservationsTravelerProfile";
  closeSheetA11y: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  openSheetA11y: Scalars["String"]["output"];
  travelerName: Scalars["String"]["output"];
  trustSignals: Array<SupplyReservationsTrustSignal>;
  verifiedInfo?: Maybe<SupplyReservationsTravelerProfileVerifiedInfo>;
};

export type SupplyReservationsTravelerProfileVerifiedInfo = {
  __typename?: "SupplyReservationsTravelerProfileVerifiedInfo";
  footer: Scalars["String"]["output"];
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  trustSignals: Array<SupplyReservationsGenericTrustSignal>;
};

export type SupplyReservationsTravelerRating = {
  __typename?: "SupplyReservationsTravelerRating";
  label: Scalars["String"]["output"];
  sublabel: Scalars["String"]["output"];
};

export type SupplyReservationsTrustSignal = SupplyReservationsGenericTrustSignal | SupplyReservationsTravelerRating;

export type SupplyReservationsUpdateBookingContent =
  | SupplyReservationsBookingRequestErrorState
  | SupplyReservationsConfirmationContent;

export type SupplyReservationsViolations = {
  __typename?: "SupplyReservationsViolations";
  code: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

export type SupplyReviewEditLink = EgdsStandardLink | StepReviewReturnAction;

export type SupplyReviewRoomsAndSpacesCard = SupplyRoomsAndSpacesCard & {
  __typename?: "SupplyReviewRoomsAndSpacesCard";
  heading: Scalars["String"]["output"];
  list: EgdsBulletedList;
};

export type SupplyReviewSectionListItem = {
  __typename?: "SupplyReviewSectionListItem";
  clickstreamInformation?: Maybe<EgClickstreamData>;
  content: SupplyReviewSectionListItemContent;
  contentListColumns?: Maybe<Scalars["Int"]["output"]>;
  editLink?: Maybe<SupplyReviewEditLink>;
  expando?: Maybe<EgdsExpandoPeek>;
  /** @deprecated Use headingV1 instead we want to make this optional */
  heading: Scalars["String"]["output"];
  headingV1?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReviewSectionListItemContent =
  | EgdsParagraph
  | EgdsPlainText
  | EgdsTextIconList
  | EgdsUnorderedList
  | SupplyOnboardingIconStylizedText
  | SupplyOnboardingIconStylizedTextList
  | SupplyOnboardingReviewError
  | SupplyOnboardingReviewPhotoList
  | SupplyRoomsAndSpacesCarousel;

export type SupplyReviewsActionId =
  | "REVIEWS_DOWNLOAD_SHEET"
  | "REVIEWS_FILTERS_SHEET"
  | "REVIEWS_RESPOND_SHEET"
  | "REVIEWS_SHARE_SHEET";

export type SupplyReviewsButton = {
  __typename?: "SupplyReviewsButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionId?: Maybe<SupplyReviewsActionId>;
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  buttonStyle?: Maybe<EgdsButtonStyle>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReviewsClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyReviewsClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  partner_common?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyReviewsDownloadSheet = SupplyReviewsSheet & {
  __typename?: "SupplyReviewsDownloadSheet";
  datePicker?: Maybe<EgdsDateRangePicker>;
  description?: Maybe<Array<SupplyReviewsText>>;
  footer?: Maybe<SupplyReviewsSheetFooter>;
  toolbar?: Maybe<SupplyReviewsToolbar>;
};

export type SupplyReviewsExperience = {
  __typename?: "SupplyReviewsExperience";
  clickstreamPageView?: Maybe<SupplyReviewsClickStreamData>;
  filters?: Maybe<SupplyReviewsFilters>;
  licensePlate: SupplyReviewsLicensePlate;
  list?: Maybe<Array<SupplyReviewsReviewCard>>;
  sheets?: Maybe<Array<SupplyReviewsSheet>>;
};

export type SupplyReviewsFilterCheckbox = {
  __typename?: "SupplyReviewsFilterCheckbox";
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  checkbox?: Maybe<EgdsCheckBoxGroup>;
  filterId: Scalars["String"]["output"];
};

export type SupplyReviewsFilterOption =
  | SupplyReviewsFilterCheckbox
  | SupplyReviewsFilterRadioGroup
  | SupplyReviewsFilterSwitch;

export type SupplyReviewsFilterRadioGroup = {
  __typename?: "SupplyReviewsFilterRadioGroup";
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  filterId: Scalars["String"]["output"];
  radioGroup?: Maybe<EgdsRadioGroup>;
};

export type SupplyReviewsFilterSwitch = {
  __typename?: "SupplyReviewsFilterSwitch";
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  filterId: Scalars["String"]["output"];
  switch?: Maybe<EgdsStandardSwitch>;
};

export type SupplyReviewsFilters = {
  __typename?: "SupplyReviewsFilters";
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  pills?: Maybe<Array<SupplyReviewsPill>>;
};

export type SupplyReviewsFiltersSheet = SupplyReviewsSheet & {
  __typename?: "SupplyReviewsFiltersSheet";
  filterByTitle?: Maybe<Scalars["String"]["output"]>;
  filterOptions?: Maybe<Array<SupplyReviewsFilterOption>>;
  footer?: Maybe<SupplyReviewsSheetFooter>;
  toolbar?: Maybe<SupplyReviewsToolbar>;
};

export type SupplyReviewsLicensePlate = {
  __typename?: "SupplyReviewsLicensePlate";
  actionMenu?: Maybe<Array<SupplyReviewsLinkAction>>;
  description?: Maybe<Array<SupplyReviewsText>>;
  title: Scalars["String"]["output"];
};

export type SupplyReviewsLinkAction = {
  __typename?: "SupplyReviewsLinkAction";
  actionId?: Maybe<SupplyReviewsActionId>;
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  target?: Maybe<UiLinkTarget>;
  text?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReviewsPill = {
  __typename?: "SupplyReviewsPill";
  actionId?: Maybe<SupplyReviewsActionId>;
  analytics?: Maybe<SupplyReviewsClickStreamData>;
  basicPill?: Maybe<EgdsBasicPill>;
  removablePill?: Maybe<EgdsRemovablePill>;
};

export type SupplyReviewsRespondSheet = SupplyReviewsSheet & {
  __typename?: "SupplyReviewsRespondSheet";
  footer?: Maybe<SupplyReviewsSheetFooter>;
  guidelinesCard?: Maybe<Array<SupplyReviewsText>>;
  name: EgdsInputField;
  response: EgdsTextAreaInputField;
  termsLink?: Maybe<SupplyReviewsLinkAction>;
  toolbar?: Maybe<SupplyReviewsToolbar>;
};

export type SupplyReviewsReviewCard = {
  __typename?: "SupplyReviewsReviewCard";
  actions?: Maybe<Array<SupplyReviewsButton>>;
  content?: Maybe<SupplyReviewsReviewContent>;
  rating?: Maybe<Array<SupplyReviewsText>>;
  response?: Maybe<SupplyReviewsReviewResponse>;
  title?: Maybe<Scalars["String"]["output"]>;
  traveler?: Maybe<SupplyReviewsReviewTraveler>;
};

export type SupplyReviewsReviewContent = {
  __typename?: "SupplyReviewsReviewContent";
  collapseAnalytics?: Maybe<SupplyReviewsClickStreamData>;
  collapsedLabel?: Maybe<Scalars["String"]["output"]>;
  content?: Maybe<Array<SupplyReviewsText>>;
  expandAnalytics?: Maybe<SupplyReviewsClickStreamData>;
  expanded?: Maybe<Scalars["Boolean"]["output"]>;
  expandedLabel?: Maybe<Scalars["String"]["output"]>;
  expando?: Maybe<EgdsExpando>;
};

export type SupplyReviewsReviewResponse = {
  __typename?: "SupplyReviewsReviewResponse";
  avatar?: Maybe<EgdsAvatar>;
  content?: Maybe<Array<SupplyReviewsText>>;
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReviewsReviewTraveler = {
  __typename?: "SupplyReviewsReviewTraveler";
  action?: Maybe<SupplyReviewsLinkAction>;
  avatar?: Maybe<EgdsAvatar>;
  details?: Maybe<Array<SupplyReviewsText>>;
  name?: Maybe<Scalars["String"]["output"]>;
  smallDetails?: Maybe<Array<SupplyReviewsText>>;
};

export type SupplyReviewsShareFeedbackSheet = SupplyReviewsSheet & {
  __typename?: "SupplyReviewsShareFeedbackSheet";
  description?: Maybe<Array<SupplyReviewsText>>;
  email: EgdsEmailInputField;
  footer?: Maybe<SupplyReviewsSheetFooter>;
  message: EgdsTextAreaInputField;
  toolbar?: Maybe<SupplyReviewsToolbar>;
};

export interface SupplyReviewsSheet {
  footer?: Maybe<SupplyReviewsSheetFooter>;
  toolbar?: Maybe<SupplyReviewsToolbar>;
}

export type SupplyReviewsSheetFooter = {
  __typename?: "SupplyReviewsSheetFooter";
  primaryAction?: Maybe<SupplyReviewsButton>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyReviewsText = EgdsIconText | EgdsPlainText | EgdsSpannableText | EgdsStandardLink | EgdsStylizedText;

export type SupplyReviewsToolbar = {
  __typename?: "SupplyReviewsToolbar";
  primaryAction?: Maybe<SupplyReviewsButton>;
  secondaryAction?: Maybe<SupplyReviewsButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRoomType = {
  __typename?: "SupplyRoomType";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export interface SupplyRoomTypeMappingActionContext {
  mappings: Array<Scalars["String"]["input"]>;
}

export type SupplyRoomTypeMappingContent = {
  __typename?: "SupplyRoomTypeMappingContent";
  elements: Array<SupplyRoomTypeMappingContentElement>;
};

export type SupplyRoomTypeMappingContentElement =
  | SupplyRoomTypeMappingList
  | SupplyRoomTypeMappingParagraph
  | SupplyRoomTypeMappingSpacing
  | SupplyRoomTypeMappingTextInput;

export type SupplyRoomTypeMappingDialogAction = {
  __typename?: "SupplyRoomTypeMappingDialogAction";
  analytics: Scalars["AnalyticsPayload"]["output"];
  button: EgdsButton;
};

export type SupplyRoomTypeMappingDialogContext = {
  __typename?: "SupplyRoomTypeMappingDialogContext";
  competitorProperty: SupplyRoomTypeMappingProperty;
};

export type SupplyRoomTypeMappingDialogError = {
  __typename?: "SupplyRoomTypeMappingDialogError";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  error?: Maybe<UiBanner>;
  heading: Scalars["String"]["output"];
  primaryAction?: Maybe<SupplyRoomTypeMappingDialogAction>;
};

export type SupplyRoomTypeMappingDialogSuccess = {
  __typename?: "SupplyRoomTypeMappingDialogSuccess";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  context: SupplyRoomTypeMappingDialogContext;
  views: Array<SupplyRoomTypeMappingView>;
};

export type SupplyRoomTypeMappingExperience = SupplyRoomTypeMappingDialogError | SupplyRoomTypeMappingDialogSuccess;

export type SupplyRoomTypeMappingFormSelect = {
  __typename?: "SupplyRoomTypeMappingFormSelect";
  component: EgdsBasicSelect;
  validations: Array<SupplyRoomTypeMappingInputValidation>;
};

export type SupplyRoomTypeMappingInputDuplicationValidation = SupplyRoomTypeMappingInputValidation & {
  __typename?: "SupplyRoomTypeMappingInputDuplicationValidation";
  errorMessage: Scalars["String"]["output"];
  scope: Array<Scalars["String"]["output"]>;
};

export interface SupplyRoomTypeMappingInputValidation {
  errorMessage: Scalars["String"]["output"];
}

export type SupplyRoomTypeMappingList = {
  __typename?: "SupplyRoomTypeMappingList";
  items: Array<SupplyRoomTypeMappingListItem>;
};

export type SupplyRoomTypeMappingListItem = {
  __typename?: "SupplyRoomTypeMappingListItem";
  cells: Array<SupplyRoomTypeMappingRoomCell>;
};

export type SupplyRoomTypeMappingParagraph = {
  __typename?: "SupplyRoomTypeMappingParagraph";
  text: EgdsSpannableText;
};

export type SupplyRoomTypeMappingProperty = {
  __typename?: "SupplyRoomTypeMappingProperty";
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export interface SupplyRoomTypeMappingPropertyInput {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}

export interface SupplyRoomTypeMappingPropertyValidationInput {
  action: SupplyRoomTypeMappingUserAction;
  associatedId?: InputMaybe<Scalars["String"]["input"]>;
  competitorProperty: SupplyRoomTypeMappingPropertyInput;
}

export type SupplyRoomTypeMappingRoomCell = {
  __typename?: "SupplyRoomTypeMappingRoomCell";
  content?: Maybe<SupplyRoomTypeMappingRoomCellContent>;
  header?: Maybe<SupplyRoomTypeMappingRoomCellHeader>;
};

export type SupplyRoomTypeMappingRoomCellContent = EgdsSpannableText | SupplyRoomTypeMappingFormSelect;

export type SupplyRoomTypeMappingRoomCellHeader = EgdsSpannableText;

export type SupplyRoomTypeMappingSpacing = {
  __typename?: "SupplyRoomTypeMappingSpacing";
  horizontal?: Maybe<SupplyRoomTypeMappingSpacingSize>;
  horizontalRule: Scalars["Boolean"]["output"];
  vertical?: Maybe<SupplyRoomTypeMappingSpacingSize>;
};

export type SupplyRoomTypeMappingSpacingSize =
  | "SPACING__0X__HALF"
  | "SPACING__1X"
  | "SPACING__2X"
  | "SPACING__3X"
  | "SPACING__4X"
  | "SPACING__5X"
  | "SPACING__6X"
  | "SPACING__8X";

export type SupplyRoomTypeMappingStatusCard = {
  __typename?: "SupplyRoomTypeMappingStatusCard";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: SupplyRoomTypeMappingStatusCardContent;
  header: SupplyRoomTypeMappingStatusCardHeader;
};

export type SupplyRoomTypeMappingStatusCardContent =
  | SupplyRoomTypeMappingStatusCardContentError
  | SupplyRoomTypeMappingStatusCardContentSuccess;

export type SupplyRoomTypeMappingStatusCardContentError = {
  __typename?: "SupplyRoomTypeMappingStatusCardContentError";
  error: UiBanner;
};

export type SupplyRoomTypeMappingStatusCardContentItem = {
  __typename?: "SupplyRoomTypeMappingStatusCardContentItem";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: UiPrimaryButton;
  competitorName: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  tooltip?: Maybe<SupplyRoomTypeMappingStatusCardTooltip>;
};

export type SupplyRoomTypeMappingStatusCardContentSuccess = {
  __typename?: "SupplyRoomTypeMappingStatusCardContentSuccess";
  elements: Array<SupplyRoomTypeMappingStatusCardContentItem>;
};

export type SupplyRoomTypeMappingStatusCardHeader = {
  __typename?: "SupplyRoomTypeMappingStatusCardHeader";
  title: Scalars["String"]["output"];
  tooltip: SupplyRoomTypeMappingStatusCardTooltip;
};

export type SupplyRoomTypeMappingStatusCardTooltip = {
  __typename?: "SupplyRoomTypeMappingStatusCardTooltip";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  content: EgdsSpannableText;
  icon: Icon;
};

export type SupplyRoomTypeMappingSubmitError = {
  __typename?: "SupplyRoomTypeMappingSubmitError";
  analytics: Scalars["AnalyticsPayload"]["output"];
  error?: Maybe<UiBanner>;
};

export type SupplyRoomTypeMappingSubmitResponse = SupplyRoomTypeMappingSubmitError | SupplyRoomTypeMappingSubmitSuccess;

export type SupplyRoomTypeMappingSubmitSuccess = {
  __typename?: "SupplyRoomTypeMappingSubmitSuccess";
  analytics: Scalars["AnalyticsPayload"]["output"];
};

export type SupplyRoomTypeMappingTextInput = {
  __typename?: "SupplyRoomTypeMappingTextInput";
  input: EgdsTextInputField;
};

export type SupplyRoomTypeMappingUserAction = "CONFIRM" | "REJECT";

export type SupplyRoomTypeMappingView = {
  __typename?: "SupplyRoomTypeMappingView";
  closeAnalytics: Scalars["AnalyticsPayload"]["output"];
  content: SupplyRoomTypeMappingContent;
  currentPhase: SupplyRoomTypeMappingViewPhase;
  heading: Scalars["String"]["output"];
  nextPhase?: Maybe<SupplyRoomTypeMappingViewPhase>;
  presentAnalytics: Scalars["AnalyticsPayload"]["output"];
  primaryAction: SupplyRoomTypeMappingDialogAction;
  secondaryAction?: Maybe<SupplyRoomTypeMappingDialogAction>;
  secondaryNextPhase?: Maybe<SupplyRoomTypeMappingViewPhase>;
};

export interface SupplyRoomTypeMappingViewOption {
  competitorName?: InputMaybe<Scalars["String"]["input"]>;
  startingFrom?: InputMaybe<SupplyRoomTypeMappingViewPhase>;
  type?: InputMaybe<SupplyRoomTypeMappingViewType>;
}

export type SupplyRoomTypeMappingViewPhase =
  | "VIEW_PROPERTY_MAPPING"
  | "VIEW_PROPERTY_MAPPING_INPUT"
  | "VIEW_ROOM_TYPE_MAPPING";

export type SupplyRoomTypeMappingViewType = "DIALOG";

export interface SupplyRoomsAndSpacesCard {
  heading: Scalars["String"]["output"];
}

export type SupplyRoomsAndSpacesCardCarouselItem = {
  __typename?: "SupplyRoomsAndSpacesCardCarouselItem";
  card: SupplyRoomsAndSpacesCard;
  expando?: Maybe<EgdsExpandoPeek>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyRoomsAndSpacesCarousel = {
  __typename?: "SupplyRoomsAndSpacesCarousel";
  carouselId?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<SupplyRoomsAndSpacesCardCarouselItem>>;
  nextButton?: Maybe<EgdsButton>;
  previousButton?: Maybe<EgdsButton>;
};

export type SupplySafetyDevicesConfirm = {
  __typename?: "SupplySafetyDevicesConfirm";
  button?: Maybe<SupplySafetyDevicesConfirmButton>;
  content?: Maybe<SupplySafetyDevicesConfirmContent>;
};

export type SupplySafetyDevicesConfirmButton = {
  __typename?: "SupplySafetyDevicesConfirmButton";
  button: EgdsButton;
  targetUrl: Scalars["String"]["output"];
};

export type SupplySafetyDevicesConfirmContent = {
  __typename?: "SupplySafetyDevicesConfirmContent";
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  returnViewButton: EgdsButton;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  targetUrl: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBanner = {
  __typename?: "SupplyScoreBanner";
  description: Scalars["String"]["output"];
  icon: Icon;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBelowMinimumStandard = {
  __typename?: "SupplyScoreBelowMinimumStandard";
  acceleratorLink: SupplyScoreLink;
  activeStep: Scalars["Float"]["output"];
  clickstreamPresented: SupplyScoreEgClickstreamData;
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  links?: Maybe<Array<SupplyScoreLink>>;
  step1ContentText: Scalars["String"]["output"];
  step1Label: Scalars["String"]["output"];
  step2ContentText: Scalars["String"]["output"];
  step2Label: Scalars["String"]["output"];
  step3ContentText: Scalars["String"]["output"];
  step3Label: Scalars["String"]["output"];
  step4ContentText: Scalars["String"]["output"];
  step4Label: Scalars["String"]["output"];
  step5ContentText: Scalars["String"]["output"];
  step5Label: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  travelAdsLink: SupplyScoreLink;
};

export type SupplyScoreBelowMinimumStandardChangeNotification = {
  __typename?: "SupplyScoreBelowMinimumStandardChangeNotification";
  clickstreamPresented: SupplyScoreEgClickstreamData;
  description: Array<Scalars["String"]["output"]>;
  icon: Icon;
  links: Array<SupplyScoreLink>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreBenefitsLadder = {
  __typename?: "SupplyScoreBenefitsLadder";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  elements: Array<SupplyScoreBenefitsLadderElement>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreBenefitsLadderActionContext = {
  __typename?: "SupplyScoreBenefitsLadderActionContext";
  actionType: Scalars["String"]["output"];
  targetId: Scalars["String"]["output"];
};

export type SupplyScoreBenefitsLadderButtonAction = {
  __typename?: "SupplyScoreBenefitsLadderButtonAction";
  actionContext: SupplyScoreBenefitsLadderActionContext;
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  button: EgdsButton;
  disabled: Scalars["Boolean"]["output"];
  isFullWidth: Scalars["Boolean"]["output"];
};

export type SupplyScoreBenefitsLadderCard = {
  __typename?: "SupplyScoreBenefitsLadderCard";
  background?: Maybe<Scalars["String"]["output"]>;
  elements: Array<SupplyScoreBenefitsLadderElement>;
  footers?: Maybe<Array<SupplyScoreBenefitsLadderElement>>;
};

export type SupplyScoreBenefitsLadderElement =
  | EgdsProgressBar
  | SupplyScoreBenefitsLadderButtonAction
  | SupplyScoreBenefitsLadderCard
  | SupplyScoreBenefitsLadderExpando
  | SupplyScoreBenefitsLadderExpandoList
  | SupplyScoreBenefitsLadderFlexBadgeText
  | SupplyScoreBenefitsLadderFlexIconText
  | SupplyScoreBenefitsLadderFlexText
  | SupplyScoreBenefitsLadderGridSection
  | SupplyScoreBenefitsLadderHeadingText
  | SupplyScoreBenefitsLadderImageSection
  | SupplyScoreBenefitsLadderList
  | SupplyScoreBenefitsLadderMessageCard
  | SupplyScoreBenefitsLadderPanel
  | SupplyScoreBenefitsLadderProgressBar
  | SupplyScoreBenefitsLadderSkeleton
  | SupplyScoreBenefitsLadderSpacing
  | SupplyScoreBenefitsLadderStepIndicator
  | UiBanner;

export type SupplyScoreBenefitsLadderExpando = {
  __typename?: "SupplyScoreBenefitsLadderExpando";
  collapsedAccessibility?: Maybe<Scalars["String"]["output"]>;
  collapsedLabel: Scalars["String"]["output"];
  elements: Array<SupplyScoreBenefitsLadderElement>;
  expanded: Scalars["Boolean"]["output"];
  expandedAccessibility?: Maybe<Scalars["String"]["output"]>;
  expandedLabel: Scalars["String"]["output"];
  lines: Scalars["Int"]["output"];
};

export type SupplyScoreBenefitsLadderExpandoList = {
  __typename?: "SupplyScoreBenefitsLadderExpandoList";
  elements: Array<SupplyScoreBenefitsLadderElement>;
  expanded: Scalars["Boolean"]["output"];
  titleElement: SupplyScoreBenefitsLadderElement;
  triggerPosition?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderFlexBadgeText = {
  __typename?: "SupplyScoreBenefitsLadderFlexBadgeText";
  badge: EgdsBadge;
  content: EgdsSpannableText;
  maxWidth?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyScoreBenefitsLadderFlexIconText = {
  __typename?: "SupplyScoreBenefitsLadderFlexIconText";
  content: EgdsSpannableText;
  icon: Icon;
  maxWidth?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyScoreBenefitsLadderFlexText = {
  __typename?: "SupplyScoreBenefitsLadderFlexText";
  content: EgdsSpannableText;
  maxWidth?: Maybe<Scalars["Float"]["output"]>;
};

export type SupplyScoreBenefitsLadderGridColumnConfig = {
  __typename?: "SupplyScoreBenefitsLadderGridColumnConfig";
  values: Array<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderGridSection = {
  __typename?: "SupplyScoreBenefitsLadderGridSection";
  columnConfig?: Maybe<SupplyScoreBenefitsLadderGridColumnConfig>;
  elements: Array<SupplyScoreBenefitsLadderElement>;
};

export type SupplyScoreBenefitsLadderHeadingText = {
  __typename?: "SupplyScoreBenefitsLadderHeadingText";
  size?: Maybe<Scalars["String"]["output"]>;
  tag?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type SupplyScoreBenefitsLadderImageItem = {
  __typename?: "SupplyScoreBenefitsLadderImageItem";
  description?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["String"]["output"]>;
  source: Scalars["String"]["output"];
  width?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderImageSection = {
  __typename?: "SupplyScoreBenefitsLadderImageSection";
  columns?: Maybe<Scalars["Int"]["output"]>;
  imageItems: Array<SupplyScoreBenefitsLadderImageItem>;
};

export type SupplyScoreBenefitsLadderList = {
  __typename?: "SupplyScoreBenefitsLadderList";
  bullet?: Maybe<Scalars["String"]["output"]>;
  items: Array<SupplyScoreBenefitsLadderListItem>;
  size?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderListItem = {
  __typename?: "SupplyScoreBenefitsLadderListItem";
  content: EgdsSpannableText;
  icon?: Maybe<Icon>;
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderMessageCard = {
  __typename?: "SupplyScoreBenefitsLadderMessageCard";
  /** @deprecated To use elements field */
  content: EgdsSpannableText;
  elements: Array<SupplyScoreBenefitsLadderElement>;
  icon: Icon;
};

export type SupplyScoreBenefitsLadderPanel = {
  __typename?: "SupplyScoreBenefitsLadderPanel";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  elementId: Scalars["String"]["output"];
  elements: Array<SupplyScoreBenefitsLadderElement>;
  toolbar?: Maybe<SupplyScoreBenefitsLadderToolbar>;
};

export type SupplyScoreBenefitsLadderProgressBar = {
  __typename?: "SupplyScoreBenefitsLadderProgressBar";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  percent: Scalars["Int"]["output"];
  progressDescription: Scalars["String"]["output"];
  theme?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreBenefitsLadderSkeleton = {
  __typename?: "SupplyScoreBenefitsLadderSkeleton";
  content?: Maybe<SupplyScoreBenefitsLadderSkeletonContent>;
  position?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderSkeletonContent = {
  __typename?: "SupplyScoreBenefitsLadderSkeletonContent";
  animation?: Maybe<Scalars["Boolean"]["output"]>;
  height?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  width?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreBenefitsLadderSpacing = {
  __typename?: "SupplyScoreBenefitsLadderSpacing";
  horizontal?: Maybe<SupplyScoreBenefitsLadderSpacingSize>;
  horizontalRule?: Maybe<Scalars["Boolean"]["output"]>;
  vertical?: Maybe<SupplyScoreBenefitsLadderSpacingSize>;
};

export type SupplyScoreBenefitsLadderSpacingSize =
  | "SPACING__0X__HALF"
  | "SPACING__1X"
  | "SPACING__2X"
  | "SPACING__3X"
  | "SPACING__4X"
  | "SPACING__5X"
  | "SPACING__6X"
  | "SPACING__8X";

export type SupplyScoreBenefitsLadderStepIndicator = {
  __typename?: "SupplyScoreBenefitsLadderStepIndicator";
  activeStep: Scalars["Float"]["output"];
  direction?: Maybe<Scalars["String"]["output"]>;
  labelSize?: Maybe<Scalars["String"]["output"]>;
  stepIndicatorSize?: Maybe<Scalars["String"]["output"]>;
  steps: Array<SupplyScoreBenefitsLadderStepIndicatorStep>;
};

export type SupplyScoreBenefitsLadderStepIndicatorStep = {
  __typename?: "SupplyScoreBenefitsLadderStepIndicatorStep";
  iconType?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type SupplyScoreBenefitsLadderToolbar = {
  __typename?: "SupplyScoreBenefitsLadderToolbar";
  actionContext: SupplyScoreBenefitsLadderActionContext;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreCategory = {
  __typename?: "SupplyScoreCategory";
  belowMinimumStandard?: Maybe<SupplyScoreBelowMinimumStandard>;
  belowMinimumStandardChangeNotification?: Maybe<SupplyScoreBelowMinimumStandardChangeNotification>;
  clickstreamFactorsPresented?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Removed from UI */
  compsetRank: EgdsStylizedText;
  content: SupplyScoreCategoryContent;
  description: Scalars["String"]["output"];
  label?: Maybe<SupplyScoreCategoryOverviewLabel>;
  maximumSubScore: Scalars["String"]["output"];
  moreInfo: SupplyScoreToolTip;
  needsAttentionFactors: Array<SupplyScoreValueFactor>;
  originalSubscore?: Maybe<Scalars["Int"]["output"]>;
  previewFactorsAllInfo?: Maybe<SupplyScorePreviewFactorsAllInfo>;
  progressAccessibility?: Maybe<Scalars["String"]["output"]>;
  scorePreviewInfo?: Maybe<SupplyScoreDetailPreviewFactorsInfo>;
  subScore: EgdsStylizedText;
  supplyScoreDismissibleCard?: Maybe<SupplyScoreDismissibleCard>;
  title: Scalars["String"]["output"];
  transitionBanner?: Maybe<SupplyScoreBanner>;
  travelerInformativeError?: Maybe<SupplyScoreTravelerInformativeError>;
  /** @deprecated Replaced by label */
  warningMessage?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreCategoryContent = SupplyScoreCategoryFactorContent | SupplyScoreEmptyErrorState;

export type SupplyScoreCategoryFactorContent = {
  __typename?: "SupplyScoreCategoryFactorContent";
  factors: Array<SupplyScoreFactor>;
};

export type SupplyScoreCategoryOverview = {
  __typename?: "SupplyScoreCategoryOverview";
  attentionCount?: Maybe<Scalars["Int"]["output"]>;
  label?: Maybe<SupplyScoreCategoryOverviewLabel>;
  score?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  /** @deprecated This attribute can be deleted after the dyff for NBF is enabled */
  warning?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreCategoryOverviewLabel = {
  __typename?: "SupplyScoreCategoryOverviewLabel";
  notificationType?: Maybe<SupplyScoreFactorNotificationType>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreCategoryScoreInfo = {
  __typename?: "SupplyScoreCategoryScoreInfo";
  color: Scalars["String"]["output"];
  score: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyScoreCompsetRank = {
  __typename?: "SupplyScoreCompsetRank";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type SupplyScoreCompsetRankAudit =
  | SupplyScoreCompsetRankAuditContent
  | SupplyScoreCompsetRankAuditGeneralError
  | SupplyScoreCompsetRankAuditNonCompliantCompset;

export type SupplyScoreCompsetRankAuditContent = {
  __typename?: "SupplyScoreCompsetRankAuditContent";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  link: EgdsStandardLink;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreCompsetRankAuditGeneralError = {
  __typename?: "SupplyScoreCompsetRankAuditGeneralError";
  error: UiBanner;
  message: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreCompsetRankAuditNonCompliantCompset = {
  __typename?: "SupplyScoreCompsetRankAuditNonCompliantCompset";
  content: SupplyScoreCompsetRankAuditNonCompliantContent;
  message: EgdsSpannableText;
  title: Scalars["String"]["output"];
};

export type SupplyScoreCompsetRankAuditNonCompliantContent = {
  __typename?: "SupplyScoreCompsetRankAuditNonCompliantContent";
  buttonLink: SupplyScoreLinkButton;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"]["output"];
  link: EgdsStandardLink;
  title: Scalars["String"]["output"];
};

export type SupplyScoreDetailPreviewFactorsInfo = {
  __typename?: "SupplyScoreDetailPreviewFactorsInfo";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"]["output"];
  notificationState: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreDetails = {
  __typename?: "SupplyScoreDetails";
  clickstreamViewed?: Maybe<SupplyScoreEgClickstreamData>;
  externalModuleOverview: SupplyScoreExternalModuleOverview;
  licensePlate: StaticLicensePlate;
  /** @deprecated Use messageBanners */
  messageBanner?: Maybe<SupplyDismissibleCard>;
  messageBanners?: Maybe<Array<SupplyDismissibleCard>>;
  sections: Array<SupplyScoreSection>;
};

export type SupplyScoreDismissibleCard = SupplyDismissibleCard & {
  __typename?: "SupplyScoreDismissibleCard";
  clickstreamCloseSelected?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Using stylizedDescription */
  description: Array<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  inlineLink?: Maybe<SupplyScoreLink>;
  key: Scalars["String"]["output"];
  links?: Maybe<Array<SupplyScoreLink>>;
  preferenceGroup: Scalars["String"]["output"];
  stylizedDescription?: Maybe<EgdsSpannableText>;
  stylizedTitle?: Maybe<EgdsText>;
  /** @deprecated Using stylizedTitle */
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreEgClickstreamCustomAttribute = {
  __typename?: "SupplyScoreEgClickstreamCustomAttribute";
  category_attention_count?: Maybe<Scalars["Int"]["output"]>;
  category_name?: Maybe<Scalars["String"]["output"]>;
  category_name_value?: Maybe<Scalars["Int"]["output"]>;
  factor_list?: Maybe<Array<SupplyScoreFactorsEgClickstreamCustomAttribute>>;
};

export type SupplyScoreEgClickstreamData = IEgClickstreamData & {
  __typename?: "SupplyScoreEgClickstreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  feedback?: Maybe<SupplyScoreEgClickstreamFeedback>;
  search_request?: Maybe<SupplyScoreEgClickstreamSearchRequest>;
  supply_score_list?: Maybe<Array<SupplyScoreEgClickstreamCustomAttribute>>;
};

export type SupplyScoreEgClickstreamFeedback = {
  __typename?: "SupplyScoreEgClickstreamFeedback";
  feedback_list?: Maybe<Array<Scalars["String"]["output"]>>;
  feedback_reason?: Maybe<Scalars["String"]["output"]>;
  feedback_type?: Maybe<Scalars["String"]["output"]>;
  prompt_id?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreEgClickstreamSearchRequest = {
  __typename?: "SupplyScoreEgClickstreamSearchRequest";
  filters?: Maybe<Array<SupplyScoreEgClickstreamSearchRequestFilterItem>>;
};

export type SupplyScoreEgClickstreamSearchRequestFilterItem = {
  __typename?: "SupplyScoreEgClickstreamSearchRequestFilterItem";
  category?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreEmptyErrorState = {
  __typename?: "SupplyScoreEmptyErrorState";
  action: UiLinkAction;
  description: Scalars["String"]["output"];
  icon: Icon;
  linkText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreExpandoDescription = {
  __typename?: "SupplyScoreExpandoDescription";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  showLess: Scalars["String"]["output"];
  showMore: Scalars["String"]["output"];
  text: Array<Scalars["String"]["output"]>;
};

export type SupplyScoreExternalModuleOverview = {
  __typename?: "SupplyScoreExternalModuleOverview";
  belowMinimumStandardTier6Banner?: Maybe<SupplyScoreBanner>;
  description: Scalars["String"]["output"];
  guestExperience: SupplyScoreCategoryOverview;
  productAttractiveness: SupplyScoreCategoryOverview;
  title: Scalars["String"]["output"];
  viewVisibilityPerformance?: Maybe<SupplyScoreLinkButton>;
};

export type SupplyScoreFactor = SupplyMoreInfoFactor | SupplyScoreValueFactor;

export type SupplyScoreFactorAuditAction = SupplyScoreFactorAuditDirectLink | SupplyScoreFactorAuditLinkAction;

export type SupplyScoreFactorAuditContentTip = {
  __typename?: "SupplyScoreFactorAuditContentTip";
  /** @deprecated Removed from UI */
  description: Scalars["String"]["output"];
  /** @deprecated Removed from UI */
  subItems: Array<SupplyScoreFactorAuditSubItems>;
  /** @deprecated Removed from UI */
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorAuditContentToolbar = {
  __typename?: "SupplyScoreFactorAuditContentToolbar";
  closeLabel: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorAuditContentWrapper = {
  __typename?: "SupplyScoreFactorAuditContentWrapper";
  /** @deprecated Removed from UI */
  bmlAudit?: Maybe<SupplyScoreFactorBmlAudit>;
  clickstreamBackSelected?: Maybe<SupplyScoreEgClickstreamData>;
  clickstreamCloseSelected?: Maybe<SupplyScoreEgClickstreamData>;
  compsetRankAudit?: Maybe<SupplyScoreCompsetRankAudit>;
  expandoDescription?: Maybe<SupplyScoreExpandoDescription>;
  factorAuditTrailDescription?: Maybe<Scalars["String"]["output"]>;
  factorAuditTrailTitle?: Maybe<Scalars["String"]["output"]>;
  factorType: Scalars["String"]["output"];
  informativeSection?: Maybe<SupplyScoreFactorInformationPanel>;
  panelContent: SupplyScoreFactorPanelContent;
  scoreDisplay: SupplyScoreFactorScoreDisplay;
  supplyScoreNeedsAttentionAudit?: Maybe<SupplyScoreNeedsAttentionAudit>;
  supplyScoreNextBestFactorAudit?: Maybe<SupplyScoreNextBestFactorAudit>;
  toolbar: SupplyScoreFactorAuditContentToolbar;
};

export type SupplyScoreFactorAuditDirectLink = {
  __typename?: "SupplyScoreFactorAuditDirectLink";
  action: UiLinkAction;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFactorAuditLinkAction = {
  __typename?: "SupplyScoreFactorAuditLinkAction";
  contentWrapper: SupplyScoreFactorAuditContentWrapper;
  trigger: SupplyScoreFactorAuditTrigger;
};

export type SupplyScoreFactorAuditSubItemLink = {
  __typename?: "SupplyScoreFactorAuditSubItemLink";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  link: EgdsStandardLink;
};

export type SupplyScoreFactorAuditSubItems = {
  __typename?: "SupplyScoreFactorAuditSubItems";
  links: Array<SupplyScoreFactorAuditSubItemLink>;
  text: Scalars["String"]["output"];
};

export type SupplyScoreFactorAuditTrail =
  | SupplyScoreFactorAuditTrailError
  | SupplyScoreFactorBmlAuditContent
  | SupplyScoreFactorBmlAuditEmpty
  | SupplyScoreFactorNoNegativeReviewAuditTrail
  | SupplyScoreFactorNoReviewAuditTrail
  | SupplyScoreFactorReservationAuditTrail
  | SupplyScoreFactorReviewAuditTrail;

export type SupplyScoreFactorAuditTrailError = {
  __typename?: "SupplyScoreFactorAuditTrailError";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"]["output"];
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorAuditTrigger = {
  __typename?: "SupplyScoreFactorAuditTrigger";
  accessibility: Scalars["String"]["output"];
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFactorBmlAudit = SupplyScoreFactorBmlAuditContent | SupplyScoreFactorBmlAuditEmpty;

export type SupplyScoreFactorBmlAuditContent = {
  __typename?: "SupplyScoreFactorBmlAuditContent";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  items: Array<SupplyScoreFactorBmlAuditItem>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorBmlAuditEmpty = {
  __typename?: "SupplyScoreFactorBmlAuditEmpty";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorBmlAuditItem = {
  __typename?: "SupplyScoreFactorBmlAuditItem";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  link: Scalars["String"]["output"];
  observationType: SupplyScoreFactorBmlAuditItemObervationType;
  text: Scalars["String"]["output"];
};

export type SupplyScoreFactorBmlAuditItemObervationType = "AVAILABILITY" | "RATE";

export type SupplyScoreFactorEmptyFilterContentAuditTrail = {
  __typename?: "SupplyScoreFactorEmptyFilterContentAuditTrail";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type SupplyScoreFactorInformationPanel = {
  __typename?: "SupplyScoreFactorInformationPanel";
  description: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFactorNoDataReservationAuditTrail = {
  __typename?: "SupplyScoreFactorNoDataReservationAuditTrail";
  description?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFactorNoNegativeReviewAuditTrail = {
  __typename?: "SupplyScoreFactorNoNegativeReviewAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  content: SupplyScoreFactorNoNegativeReviewAuditTrailContent;
  description: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorNoNegativeReviewAuditTrailContent = {
  __typename?: "SupplyScoreFactorNoNegativeReviewAuditTrailContent";
  description?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  showAllReviewsClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  showAllReviewsLink: EgdsStandardLink;
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorNoReviewAuditTrail = {
  __typename?: "SupplyScoreFactorNoReviewAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"]["output"];
  requestReviewClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Deprecated in favor of requestReviewLinkText and requestReviewLinkHref */
  requestReviewLink: EgdsStandardLink;
  requestReviewLinkHref?: Maybe<Scalars["String"]["output"]>;
  requestReviewLinkText?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorNotification = {
  __typename?: "SupplyScoreFactorNotification";
  /** @deprecated Removed from UI */
  icon: Icon;
  notificationType: SupplyScoreFactorNotificationType;
  stylizedText: EgdsStylizedText;
};

export type SupplyScoreFactorNotificationType = "DOING_GREAT" | "NEEDS_ATTENTION" | "NEXT_BEST_FACTOR";

export type SupplyScoreFactorPanelContent = ISupplyScoreFactorPanelContent & {
  __typename?: "SupplyScoreFactorPanelContent";
  /** @deprecated duplicated in audit trail schema */
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  elements: Array<SupplyScoreFactorPanelContentElement>;
};

export type SupplyScoreFactorPanelContentBasic = {
  __typename?: "SupplyScoreFactorPanelContentBasic";
  descriptionItems: Array<SupplyScoreFactorPanelContentDescription>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFactorPanelContentDescription = {
  __typename?: "SupplyScoreFactorPanelContentDescription";
  icon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type SupplyScoreFactorPanelContentElement =
  | SupplyScoreFactorPanelContentBasic
  | SupplyScoreFactorPanelContentImprovement
  | SupplyScoreFactorPanelContentSection;

export type SupplyScoreFactorPanelContentImprovement = {
  __typename?: "SupplyScoreFactorPanelContentImprovement";
  /** @deprecated Removed from UI */
  tip: SupplyScoreFactorAuditContentTip;
};

export type SupplyScoreFactorPanelContentSection = {
  __typename?: "SupplyScoreFactorPanelContentSection";
  items: Array<SupplyScoreFactorPanelContentSectionItem>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFactorPanelContentSectionItem = {
  __typename?: "SupplyScoreFactorPanelContentSectionItem";
  action?: Maybe<UiLinkAction>;
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  content: Array<Scalars["String"]["output"]>;
  leadingIcon?: Maybe<Icon>;
  tailingIcon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFactorReservationAuditTrail = {
  __typename?: "SupplyScoreFactorReservationAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  contactUsClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  contactUsLink?: Maybe<EgdsStandardLink>;
  description: Scalars["String"]["output"];
  emptyData?: Maybe<SupplyScoreFactorNoDataReservationAuditTrail>;
  /** @deprecated Deprecated in favor of emptyData */
  emptyDataDescription?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated in favor of emptyData */
  emptyDataIcon?: Maybe<Icon>;
  /** @deprecated Deprecated in favor of emptyData */
  emptyDataTitle?: Maybe<Scalars["String"]["output"]>;
  highlight?: Maybe<SupplyScoreFactorReservationAuditTrailHighlight>;
  items?: Maybe<Array<SupplyScoreFactorReservationAuditTrailItem>>;
  noBookings?: Maybe<SupplyScoreFactorNoDataReservationAuditTrail>;
  showMoreClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  showMoreLink?: Maybe<EgdsStandardLink>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorReservationAuditTrailHighlight = {
  __typename?: "SupplyScoreFactorReservationAuditTrailHighlight";
  description?: Maybe<Scalars["String"]["output"]>;
  expandoText?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFactorReservationAuditTrailItem = {
  __typename?: "SupplyScoreFactorReservationAuditTrailItem";
  date: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  tooltipText?: Maybe<Scalars["String"]["output"]>;
  travelerNameClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  travelerNameLink: EgdsStandardLink;
  /** @deprecated use travelerNameLink instead */
  travellerNameLink: EgdsStandardLink;
};

export type SupplyScoreFactorReviewAuditTrail = {
  __typename?: "SupplyScoreFactorReviewAuditTrail";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  description: Scalars["String"]["output"];
  emptyFilterContent?: Maybe<SupplyScoreFactorEmptyFilterContentAuditTrail>;
  filters?: Maybe<Array<SupplyScoreFactorReviewAuditTrailSentimentFilterItem>>;
  filtersClickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  highlightedReviewsClickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  items: Array<SupplyScoreFactorReviewAuditTrailItem>;
  reportReviewClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated Deprecated in favor of reportReviewLinkText and reportReviewLinkHref */
  reportReviewLink: EgdsStandardLink;
  reportReviewLinkHref?: Maybe<Scalars["String"]["output"]>;
  reportReviewLinkText?: Maybe<Scalars["String"]["output"]>;
  respondReviewClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  respondReviewLink: EgdsStandardLink;
  showMoreClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  showMoreLink?: Maybe<EgdsStandardLink>;
  showMoreTextTemplate?: Maybe<EgdsLocalizedTextTemplate>;
  sidePanelSubtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreFactorReviewAuditTrailItem = {
  __typename?: "SupplyScoreFactorReviewAuditTrailItem";
  expando?: Maybe<EgdsExpandoPeek>;
  icon: Icon;
  rating: Scalars["String"]["output"];
  reservationLink: Scalars["String"]["output"];
  reviewAccess?: Maybe<SupplyScoreFactorReviewAuditTrailItemAccess>;
  reviewCreatedDate: Scalars["String"]["output"];
  /** @deprecated use reviewDetailStyled instead */
  reviewDetail: Scalars["String"]["output"];
  reviewDetailSeeMoreClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  reviewDetailStyled: EgdsStylizedText;
  /** @deprecated remove from UI */
  reviewFromOtherSite?: Maybe<Scalars["String"]["output"]>;
  travelerName: Scalars["String"]["output"];
  travelerNameClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
  /** @deprecated use travelerName instead */
  travelerNameLink: EgdsSpannableText;
  value: Scalars["String"]["output"];
};

export type SupplyScoreFactorReviewAuditTrailItemAccess = {
  __typename?: "SupplyScoreFactorReviewAuditTrailItemAccess";
  icon?: Maybe<Icon>;
  propertyId: Scalars["String"]["output"];
  reservationId: Scalars["String"]["output"];
  reviewId: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyScoreFactorReviewAuditTrailSentimentFilterItem = {
  __typename?: "SupplyScoreFactorReviewAuditTrailSentimentFilterItem";
  pill: EgdsBasicPill;
  pillClickstreamClicked?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFactorScoreDisplay = {
  __typename?: "SupplyScoreFactorScoreDisplay";
  notificationHeader?: Maybe<SupplyScoreFactorNotification>;
  notificationState?: Maybe<Scalars["String"]["output"]>;
  stylizedValue: EgdsStylizedText;
  tooltip?: Maybe<SupplyScoreFactorScoreDisplayTooltip>;
};

export type SupplyScoreFactorScoreDisplayTooltip = {
  __typename?: "SupplyScoreFactorScoreDisplayTooltip";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  contents: Array<Scalars["String"]["output"]>;
  icon: Icon;
};

export type SupplyScoreFactorsEgClickstreamCustomAttribute = {
  __typename?: "SupplyScoreFactorsEgClickstreamCustomAttribute";
  factor_audit_trail_count?: Maybe<Scalars["Int"]["output"]>;
  factor_audit_trail_ids?: Maybe<Array<Scalars["String"]["output"]>>;
  factor_audit_trail_state?: Maybe<Scalars["String"]["output"]>;
  factor_badge_type?: Maybe<Scalars["String"]["output"]>;
  factor_card_position?: Maybe<Scalars["Int"]["output"]>;
  factor_card_target_link?: Maybe<Scalars["String"]["output"]>;
  factor_card_target_link_type?: Maybe<Scalars["String"]["output"]>;
  factor_competitive_set_rank?: Maybe<Scalars["Int"]["output"]>;
  factor_competitive_set_size?: Maybe<Scalars["Int"]["output"]>;
  factor_type?: Maybe<Scalars["String"]["output"]>;
  factor_value?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreFilterMenu = {
  __typename?: "SupplyScoreFilterMenu";
  title: Scalars["String"]["output"];
  trigger: SupplyScoreFilterMenuTrigger;
};

export type SupplyScoreFilterMenuItem = {
  __typename?: "SupplyScoreFilterMenuItem";
  action: SupplyScoreFilterMenuItemAction;
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type SupplyScoreFilterMenuItemAction = {
  __typename?: "SupplyScoreFilterMenuItemAction";
  selectedAnalytics?: Maybe<SupplyScoreEgClickstreamData>;
};

export type SupplyScoreFilterMenuTrigger = {
  __typename?: "SupplyScoreFilterMenuTrigger";
  accessibility: Scalars["String"]["output"];
  contentItems: Array<SupplyScoreFilterMenuItem>;
  text: Scalars["String"]["output"];
};

export type SupplyScoreLink = {
  __typename?: "SupplyScoreLink";
  clickstreamSelected: SupplyScoreEgClickstreamData;
  href: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  type?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyScoreLinkButton = {
  __typename?: "SupplyScoreLinkButton";
  action: UiLinkAction;
  button: EgdsButton;
};

export type SupplyScoreMessaging = {
  __typename?: "SupplyScoreMessaging";
  icon?: Maybe<Icon>;
  links: Array<EgdsStandardLink>;
  messages: Array<Scalars["String"]["output"]>;
  theme: SupplyScoreMessagingTheme;
};

export type SupplyScoreMessagingTheme = "INFO" | "MESSAGE" | "WARNING";

export type SupplyScoreNeedsAttentionAudit = {
  __typename?: "SupplyScoreNeedsAttentionAudit";
  clickstreamSelected?: Maybe<SupplyScoreEgClickstreamData>;
  linkHref?: Maybe<Scalars["String"]["output"]>;
  linkText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScoreNextBestFactorAudit = {
  __typename?: "SupplyScoreNextBestFactorAudit";
  icon: Icon;
  text: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SupplyScorePerformanceOverview = {
  __typename?: "SupplyScorePerformanceOverview";
  clickstreamPresented?: Maybe<SupplyScoreEgClickstreamData>;
  filterValue?: Maybe<Scalars["String"]["output"]>;
  items: Array<SupplyScorePerformanceOverviewItem>;
};

export type SupplyScorePerformanceOverviewItem = {
  __typename?: "SupplyScorePerformanceOverviewItem";
  competitiveSetRank?: Maybe<SupplyScoreCompsetRank>;
  /** @deprecated Use competitiveSetRank */
  compsetRank?: Maybe<EgdsStylizedText>;
  moreInfo: SupplyScoreToolTip;
  title: Scalars["String"]["output"];
  value: EgdsStylizedText;
};

export type SupplyScorePreviewFactorsAllInfo = {
  __typename?: "SupplyScorePreviewFactorsAllInfo";
  categoryScoreInfo: Array<SupplyScoreCategoryScoreInfo>;
  scorePreviewInfo: SupplyScoreDetailPreviewFactorsInfo;
};

export type SupplyScoreSection = {
  __typename?: "SupplyScoreSection";
  filterMenu?: Maybe<SupplyScoreFilterMenu>;
  items: Array<SupplyScoreSectionItem>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type SupplyScoreSectionItem = SupplyScoreCategory | SupplyScoreMessaging | SupplyScorePerformanceOverview;

export type SupplyScoreToolTip = {
  __typename?: "SupplyScoreToolTip";
  content: Array<Scalars["String"]["output"]>;
  trigger: EgdsIconText;
};

export type SupplyScoreTravelerInformativeError = {
  __typename?: "SupplyScoreTravelerInformativeError";
  description: Scalars["String"]["output"];
  icon: Icon;
  title?: Maybe<EgdsStylizedText>;
};

export type SupplyScoreValueFactor = {
  __typename?: "SupplyScoreValueFactor";
  action?: Maybe<SupplyScoreFactorAuditAction>;
  compsetRank?: Maybe<SupplyScoreCompsetRank>;
  notificationHeader?: Maybe<SupplyScoreFactorNotification>;
  notificationState?: Maybe<Scalars["String"]["output"]>;
  stylizedTitle: EgdsStylizedText;
  stylizedValue: EgdsStylizedText;
};

export type SupplySeeAllPropertiesButton = {
  __typename?: "SupplySeeAllPropertiesButton";
  action: UiLinkAction;
  disabled: Scalars["Boolean"]["output"];
  primary: Scalars["String"]["output"];
};

export type SupplySubscribeSignalAction = {
  __typename?: "SupplySubscribeSignalAction";
  signal: Scalars["String"]["output"];
};

export type SupplySupplyRateAvailabilityObservationBadgeTooltip = {
  __typename?: "SupplySupplyRateAvailabilityObservationBadgeTooltip";
  analytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  badge: EgdsLoyaltyBadge;
  content: EgdsSpannableText;
};

export type SupplyTaxes = SupplyTaxesEditor | SupplyTaxesMessage;

export type SupplyTaxesAmountDetails = {
  __typename?: "SupplyTaxesAmountDetails";
  age: EgdsNumberInputField;
  ageBoxEnteredAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  ageBoxSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  ageTitle: Scalars["String"]["output"];
  amount: EgdsNumberInputField;
  amountBoxEnteredAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  amountBoxSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  collectAtCheckinRadioButtonSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export interface SupplyTaxesAmountInput {
  age: Scalars["Int"]["input"];
  amount: Scalars["Float"]["input"];
}

export type SupplyTaxesEditor = {
  __typename?: "SupplyTaxesEditor";
  errorMessagePresentedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  heading: Scalars["String"]["output"];
  nextButtonInitiatedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  optionDetails: Array<SupplyTaxesOption>;
  options: EgdsBasicRadioGroup;
  subheading?: Maybe<Scalars["String"]["output"]>;
  taxPageViewedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export interface SupplyTaxesInput {
  taxesAmount?: InputMaybe<SupplyTaxesAmountInput>;
  taxesPercent?: InputMaybe<SupplyTaxesPercentInput>;
  type: SupplyTaxesType;
}

export type SupplyTaxesLink = {
  __typename?: "SupplyTaxesLink";
  href: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type SupplyTaxesMessage = {
  __typename?: "SupplyTaxesMessage";
  errorMessagePresentedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  message: Scalars["String"]["output"];
  nextButtonInitiatedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  taxMessagePageViewedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export type SupplyTaxesNotInclude = {
  __typename?: "SupplyTaxesNotInclude";
  collectAsRequiredRadioButtonSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  message: Scalars["String"]["output"];
};

export type SupplyTaxesOption = {
  __typename?: "SupplyTaxesOption";
  optionDetails: SupplyTaxesOptionDetails;
  parent: SupplyTaxesType;
};

export type SupplyTaxesOptionDetails = SupplyTaxesAmountDetails | SupplyTaxesNotInclude | SupplyTaxesPercentDetails;

export type SupplyTaxesPercentDetails = {
  __typename?: "SupplyTaxesPercentDetails";
  collectDuringBookingRadioButtonSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  link?: Maybe<SupplyTaxesLink>;
  percent: EgdsNumberInputField;
  percentBoxEnteredAnalytics?: Maybe<SupplyContentEgClickstreamData>;
  percentBoxSelectedAnalytics?: Maybe<SupplyContentEgClickstreamData>;
};

export interface SupplyTaxesPercentInput {
  percent: Scalars["Float"]["input"];
}

export type SupplyTaxesType = "AMOUNT" | "NOT_INCLUDED" | "PERCENT";

export type SupplyTipCardSection = {
  __typename?: "SupplyTipCardSection";
  content: SupplyOnboardingTipContent;
  /** @deprecated use title */
  heading: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyTopTask = {
  __typename?: "SupplyTopTask";
  moduleTopTaskPresented: SupplyTopTasksEgClickstream;
  refreshSelected: SupplyTopTasksEgClickstream;
  tasks: Array<SupplyTopTaskCard>;
  /** @deprecated Use toptaskHeading instead */
  title?: Maybe<Scalars["String"]["output"]>;
  topTaskAdditionalCardsViewed: SupplyTopTasksEgClickstream;
  topTaskHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyTopTaskButton = {
  __typename?: "SupplyTopTaskButton";
  action: UiLinkAction;
  actionLabel: Scalars["String"]["output"];
  topTaskButtonSelected: SupplyTopTasksEgClickstream;
  topTaskOpportunityCardSelected?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type SupplyTopTaskCard = SupplyTopTaskDefaultCard | SupplyTopTaskPlaceholderCard;

export type SupplyTopTaskCardButtonAction =
  | EgdsDismissAction
  | SupplyTopTaskButton
  | SupplyTopTaskDismissButtonComponent;

export type SupplyTopTaskCardContent = {
  __typename?: "SupplyTopTaskCardContent";
  countDownExpiredAction?: Maybe<SupplyTopTaskButton>;
  countDownExpiredLabel?: Maybe<Scalars["String"]["output"]>;
  countDownTarget?: Maybe<Scalars["String"]["output"]>;
  icon: Icon;
  label: Scalars["String"]["output"];
  labelTheme?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries: Array<SupplyTopTaskSecondaryElements>;
  title: EgdsStylizedText;
};

export type SupplyTopTaskDefaultCard = {
  __typename?: "SupplyTopTaskDefaultCard";
  buttons: Array<SupplyTopTaskCardButtonAction>;
  content: SupplyTopTaskCardContent;
  taskId: Scalars["String"]["output"];
  taskType?: Maybe<Scalars["String"]["output"]>;
  topTaskCardPresented: SupplyTopTasksEgClickstream;
  topTaskOpportunityCardPresented?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  /** @deprecated Will be replaced by SupplyTopTaskDismissButtonComponent's clickstream */
  topTaskSecondaryActionSelected: SupplyTopTasksEgClickstream;
  topTaskType: TopTaskTypes;
};

export type SupplyTopTaskDismiss = {
  __typename?: "SupplyTopTaskDismiss";
  taskId?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyTopTaskDismissButtonComponent = {
  __typename?: "SupplyTopTaskDismissButtonComponent";
  actionLabel: Scalars["String"]["output"];
  topTaskDismissButtonSelected: SupplyTopTasksEgClickstream;
};

export type SupplyTopTaskPlaceholderCard = {
  __typename?: "SupplyTopTaskPlaceholderCard";
  action?: Maybe<UiLinkAction>;
  actionLabel: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  image?: Maybe<Image>;
  primary?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  topTaskPlaceholderCardPresented?: Maybe<SupplyTopTasksEgClickstream>;
  topTaskPrimaryActionClicked?: Maybe<SupplyTopTasksEgClickstream>;
};

export type SupplyTopTaskSecondaryElements = {
  __typename?: "SupplyTopTaskSecondaryElements";
  simpleText?: Maybe<Scalars["String"]["output"]>;
  timeStamp?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyTopTasksEgClickstream = IEgClickstreamData & {
  __typename?: "SupplyTopTasksEgClickstream";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
  partnerCommon?: Maybe<EgClickstreamPartnerCommonData>;
};

export type SupplyUpdateNewListingOfferEnrollment = {
  __typename?: "SupplyUpdateNewListingOfferEnrollment";
  enrolled: Scalars["Boolean"]["output"];
};

export type SupplyUpdatePartnerPayoutDeferredAccount = {
  __typename?: "SupplyUpdatePartnerPayoutDeferredAccount";
  bankingCountry: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  currencySymbol?: Maybe<Scalars["String"]["output"]>;
  merchantAccountUrl: Scalars["String"]["output"];
  payoutConfigUrl: Scalars["String"]["output"];
  subHeading?: Maybe<SupplyPropertyRatesSubHeadingUnion>;
  validation?: Maybe<EgdsInputValidation>;
};

export type SupplyUpdatePropertyRates = {
  __typename?: "SupplyUpdatePropertyRates";
  baseRate: Scalars["Float"]["output"];
};

export type SupplyUserProfileBasicInfoForm = {
  __typename?: "SupplyUserProfileBasicInfoForm";
  basicInfoSections: SupplyUserProfileBasicInfoSections;
  formCommon: SupplyUserProfileFormCommon;
};

export type SupplyUserProfileBasicInfoSections = {
  __typename?: "SupplyUserProfileBasicInfoSections";
  name: SupplyUserProfileFullNameSection;
};

export interface SupplyUserProfileBasicInfoUpdateRequest {
  name?: InputMaybe<SupplyUserProfileNameInput>;
}

export type SupplyUserProfileBasicInfoUpdateValidationErrors = {
  __typename?: "SupplyUserProfileBasicInfoUpdateValidationErrors";
  firstName?: Maybe<SupplyUserProfileCustomInputValidation>;
  lastName?: Maybe<SupplyUserProfileCustomInputValidation>;
};

export type SupplyUserProfileButton = UiPrimaryButton | UiTertiaryButton;

export type SupplyUserProfileCommonHeading = {
  __typename?: "SupplyUserProfileCommonHeading";
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyUserProfileContactForm = {
  __typename?: "SupplyUserProfileContactForm";
  contactInfoSections: SupplyUserProfileContactSections;
  formCommon: SupplyUserProfileFormCommon;
};

export type SupplyUserProfileContactSections = {
  __typename?: "SupplyUserProfileContactSections";
  phoneNumber: SupplyUserProfilePhoneNumberSection;
};

export interface SupplyUserProfileContactUpdateRequest {
  phoneNumber?: InputMaybe<SupplyUserProfilePhoneNumberInput>;
}

export type SupplyUserProfileContactUpdateValidationErrors = {
  __typename?: "SupplyUserProfileContactUpdateValidationErrors";
  countryCode?: Maybe<SupplyUserProfileCustomInputValidation>;
  phoneNumber?: Maybe<SupplyUserProfileCustomInputValidation>;
};

export type SupplyUserProfileCreateProfile = {
  __typename?: "SupplyUserProfileCreateProfile";
  success: Scalars["Boolean"]["output"];
  toast?: Maybe<EgdsToast>;
  validationErrors?: Maybe<SupplyUserProfileCreateProfileValidationErrors>;
};

export type SupplyUserProfileCreateProfileValidationErrors = {
  __typename?: "SupplyUserProfileCreateProfileValidationErrors";
  basicInfo?: Maybe<SupplyUserProfileBasicInfoUpdateValidationErrors>;
  contact?: Maybe<SupplyUserProfileContactUpdateValidationErrors>;
};

export type SupplyUserProfileCustomInputValidation = {
  __typename?: "SupplyUserProfileCustomInputValidation";
  errorMessage: Scalars["String"]["output"];
};

export type SupplyUserProfileFormCommon = {
  __typename?: "SupplyUserProfileFormCommon";
  banner?: Maybe<UiBanner>;
  edit: SupplyUserProfileTrackedButton;
  heading: SupplyUserProfileCommonHeading;
  save: SupplyUserProfileTrackedButton;
};

export type SupplyUserProfileFullNameSection = {
  __typename?: "SupplyUserProfileFullNameSection";
  common: SupplyUserProfileSectionCommon;
  firstName: EgdsTextInputField;
  lastName: EgdsTextInputField;
};

export type SupplyUserProfileMutation = {
  __typename?: "SupplyUserProfileMutation";
  createProfile: SupplyUserProfileCreateProfile;
  updateBasicInfo: SupplyUserProfileUpdateBasicInfo;
  updateContact: SupplyUserProfileUpdateContact;
};

export type SupplyUserProfileMutationCreateProfileArgs = {
  context: ContextInput;
  updateBasicInfoRequest: SupplyUserProfileBasicInfoUpdateRequest;
  updateContactRequest: SupplyUserProfileContactUpdateRequest;
};

export type SupplyUserProfileMutationUpdateBasicInfoArgs = {
  context: ContextInput;
  updateBasicInfoRequest: SupplyUserProfileBasicInfoUpdateRequest;
};

export type SupplyUserProfileMutationUpdateContactArgs = {
  context: ContextInput;
  updateContactRequest: SupplyUserProfileContactUpdateRequest;
};

export interface SupplyUserProfileNameInput {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
}

export interface SupplyUserProfilePhoneNumberInput {
  countryCode: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
}

export type SupplyUserProfilePhoneNumberSection = {
  __typename?: "SupplyUserProfilePhoneNumberSection";
  common: SupplyUserProfileSectionCommon;
  countryCode: EgdsBasicSelect;
  phoneNumber: EgdsTextInputField;
};

export type SupplyUserProfileQuery = {
  __typename?: "SupplyUserProfileQuery";
  userProfileBasicInfoForm: SupplyUserProfileBasicInfoForm;
  userProfileContactForm: SupplyUserProfileContactForm;
  userProfileSignUpForm: SupplyUserProfileSignUpForm;
};

export type SupplyUserProfileQueryUserProfileBasicInfoFormArgs = {
  context: ContextInput;
};

export type SupplyUserProfileQueryUserProfileContactFormArgs = {
  context: ContextInput;
};

export type SupplyUserProfileQueryUserProfileSignUpFormArgs = {
  context: ContextInput;
};

export type SupplyUserProfileSectionCommon = {
  __typename?: "SupplyUserProfileSectionCommon";
  fieldHeading: Scalars["String"]["output"];
  fieldSubHeading?: Maybe<Scalars["String"]["output"]>;
  fullValue: Scalars["String"]["output"];
};

export type SupplyUserProfileSignUpForm = {
  __typename?: "SupplyUserProfileSignUpForm";
  formCommon: SupplyUserProfileFormCommon;
  signUpSections: SupplyUserProfileSignUpSectionsResponse;
};

export type SupplyUserProfileSignUpSectionsResponse = {
  __typename?: "SupplyUserProfileSignUpSectionsResponse";
  name: SupplyUserProfileFullNameSection;
  phoneNumber: SupplyUserProfilePhoneNumberSection;
};

export type SupplyUserProfileTrackedButton = {
  __typename?: "SupplyUserProfileTrackedButton";
  analytics: Scalars["AnalyticsPayload"]["output"];
  button: SupplyUserProfileButton;
};

export type SupplyUserProfileUpdateBasicInfo = {
  __typename?: "SupplyUserProfileUpdateBasicInfo";
  success: Scalars["Boolean"]["output"];
  toast?: Maybe<EgdsToast>;
  validationErrors?: Maybe<SupplyUserProfileBasicInfoUpdateValidationErrors>;
};

export type SupplyUserProfileUpdateContact = {
  __typename?: "SupplyUserProfileUpdateContact";
  success: Scalars["Boolean"]["output"];
  toast?: Maybe<EgdsToast>;
  validationErrors?: Maybe<SupplyUserProfileContactUpdateValidationErrors>;
};

export type SupplyUserSettingsDashboardHeaderResponse = {
  __typename?: "SupplyUserSettingsDashboardHeaderResponse";
  heading: Scalars["String"]["output"];
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type SupplyVirtualMarketCoachEgClickStreamData = IEgClickstreamData & {
  __typename?: "SupplyVirtualMarketCoachEgClickStreamData";
  event?: Maybe<EgClickstreamEvent>;
  experience?: Maybe<EgClickstreamExperience>;
  virtual_market_coach?: Maybe<SupplyVmcEgClickstreamCustomAttribute>;
};

export type SupplyVmcEgClickstreamCustomAttribute = {
  __typename?: "SupplyVmcEGClickstreamCustomAttribute";
  /** @deprecated This field will be deprecated in the future, use tip_id instead */
  coaching_tip_id?: Maybe<Scalars["Int"]["output"]>;
  coaching_tip_proofpoint?: Maybe<SupplyCoachingTipProofPointEgClickstreamCustomAttribute>;
  coaching_tip_source_id?: Maybe<Scalars["String"]["output"]>;
  opportunities_available?: Maybe<Scalars["Int"]["output"]>;
  /** @deprecated This field will be deprecated in the future, use tip_sort_position instead */
  sort_position?: Maybe<Scalars["String"]["output"]>;
  tip_id?: Maybe<Scalars["String"]["output"]>;
  tip_sort_position?: Maybe<Scalars["Int"]["output"]>;
};

export type SupportingText = {
  __typename?: "SupportingText";
  size?: Maybe<Scalars["Int"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<Scalars["String"]["output"]>;
  weight?: Maybe<Scalars["String"]["output"]>;
};

export type SweepstakesAction = {
  __typename?: "SweepstakesAction";
  action?: Maybe<UiLinkAction>;
  actionButtonType?: Maybe<ActionButtonTypes>;
  actionType?: Maybe<Scalars["String"]["output"]>;
  campaignId?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  rightNavIcon?: Maybe<Icon>;
  shareText?: Maybe<Scalars["String"]["output"]>;
  standardBadge?: Maybe<EgdsStandardBadge>;
  triviaId?: Maybe<Scalars["String"]["output"]>;
  uiAction: AppGrowthUiAction;
};

export type SweepstakesBannerQuery = AppGrowthQueryImpressionSupportResponse &
  AppGrowthQueryResponse & {
    __typename?: "SweepstakesBannerQuery";
    clientSideAnalytics: ClientSideAnalytics;
    clientSideAnalyticsImpression: ClientSideAnalytics;
    clientSideErrorDialog: AppGrowthDialog;
    logo?: Maybe<AppGrowthMediaItem>;
    primaryButton?: Maybe<AppGrowthButton>;
    progressBar?: Maybe<EgdsProgressBar>;
    serverSideErrorDialog?: Maybe<AppGrowthDialog>;
    summary?: Maybe<AppGrowthSummary>;
  };

export type SweepstakesBodySection = {
  __typename?: "SweepstakesBodySection";
  actions?: Maybe<Array<SweepstakesAction>>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type SweepstakesDashboardQuery = AppGrowthQueryResponse & {
  __typename?: "SweepstakesDashboardQuery";
  bodySection?: Maybe<SweepstakesBodySection>;
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  footerSection?: Maybe<SweepstakesFooterSection>;
  headerSection?: Maybe<SweepstakesHeaderSection>;
  notification?: Maybe<AppGrowthActionNotification>;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
  signupPrompt?: Maybe<SweepstakesSignupPrompt>;
  userNotificationState?: Maybe<UserNotificationState>;
};

export type SweepstakesDisenrollFailureResponse = {
  __typename?: "SweepstakesDisenrollFailureResponse";
  message: Scalars["String"]["output"];
};

export type SweepstakesDisenrollResponse = SweepstakesDisenrollFailureResponse | SweepstakesDisenrollSuccessResponse;

export type SweepstakesDisenrollSuccessResponse = {
  __typename?: "SweepstakesDisenrollSuccessResponse";
  message: Scalars["String"]["output"];
};

export type SweepstakesFooterSection = {
  __typename?: "SweepstakesFooterSection";
  content: EgdsSpannableText;
};

export type SweepstakesHeaderSection = {
  __typename?: "SweepstakesHeaderSection";
  /** @deprecated Sweepstakes header shouldn't be using this field and instead be using navigation. */
  backToHome?: Maybe<AppGrowthTextIconLinkListItem>;
  backgroundImageUrl?: Maybe<AppGrowthMediaItem>;
  date?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  iconLogo?: Maybe<AppGrowthMediaItem>;
  link?: Maybe<EgdsStandardLink>;
  name?: Maybe<Scalars["String"]["output"]>;
  navigation?: Maybe<AppGrowthNavigation>;
  progressBar?: Maybe<EgdsProgressBar>;
};

export type SweepstakesSignupPrompt = {
  __typename?: "SweepstakesSignupPrompt";
  backgroundImageUrl?: Maybe<AppGrowthMediaItem>;
  /** @deprecated Field marked as deprecated. It is replaced by backgroundImageUrl. */
  backgroundLogo?: Maybe<AppGrowthMediaItem>;
  checkbox: EgdsCheckBox;
  clientSideAnalytics: ClientSideAnalytics;
  clientSideAnalyticsLogin?: Maybe<ClientSideAnalytics>;
  /** @deprecated This should not be used anymore. Field to be replaced by navigation. */
  closeAction?: Maybe<AppGrowthTextIconLinkListItem>;
  content?: Maybe<EgdsSpannableText>;
  /** @deprecated don't use anymore - use content now */
  detailsText?: Maybe<Scalars["String"]["output"]>;
  iconLogo?: Maybe<AppGrowthMediaItem>;
  navigation?: Maybe<AppGrowthNavigation>;
  primaryButton?: Maybe<AppGrowthButton>;
  summary?: Maybe<AppGrowthSummary>;
};

export type SweepstakesUserActionHistoryQuery = {
  __typename?: "SweepstakesUserActionHistoryQuery";
  actionData: Array<ActionHistory>;
  maxEntries: Scalars["Int"]["output"];
  /** @deprecated Don't use this anymore */
  randomUser: Scalars["String"]["output"];
};

export type SweepstakesUserWipeQuery = {
  __typename?: "SweepstakesUserWipeQuery";
  ids: Array<Scalars["String"]["output"]>;
};

export type TaapAcademyComponent = {
  __typename?: "TaapAcademyComponent";
  academyLink?: Maybe<EgdsInlineLink>;
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  iconUrl: Scalars["String"]["output"];
};

export type TabContent = {
  __typename?: "TabContent";
  providers?: Maybe<Array<ConnectedServiceProvider>>;
  services?: Maybe<Array<ConnectedService>>;
  tabId: ConnectivitySettingsTabName;
};

export type TabStatus = "NOT_SELECTED" | "SELECTED";

export type TabType = "EQUAL_WIDTH" | "NATURAL";

export type TableInput = "AD" | "AD_GROUP" | "CAMPAIGN";

export type Tag = {
  __typename?: "Tag";
  label: Scalars["String"]["output"];
};

export type TargetingCategories = {
  __typename?: "TargetingCategories";
  heading: EgdsHeading;
  items: EgdsBulletedList;
};

export interface TargetingInput {
  targetingId?: InputMaybe<Scalars["UUID"]["input"]>;
  targetingValue: Scalars["String"]["input"];
}

export type TelUri = Uri & {
  __typename?: "TelURI";
  phoneNumber: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type Telesales = {
  __typename?: "Telesales";
  description: LodgingPlainMessage;
  telesalesNumber: TelUri;
  tracking: ClientSideAnalytics;
};

export type Template = {
  __typename?: "Template";
  components: Array<TemplateComponent>;
  id?: Maybe<Scalars["String"]["output"]>;
  version?: Maybe<Scalars["String"]["output"]>;
};

export interface TemplateAnalytics {
  analytics: ClientSideAnalytics;
  analyticsIdentifier: AnalyticsKey;
}

export type TemplateComponent = {
  __typename?: "TemplateComponent";
  children: Array<TemplateComponent>;
  config: ComponentConfig;
  metadata: ComponentMetaData;
  type: Scalars["String"]["output"];
};

export type TemplateConfig = {
  __typename?: "TemplateConfig";
  default: Scalars["String"]["output"];
  platform?: Maybe<Array<DeviceTypeTemplate>>;
};

export type TemplateModel = {
  __typename?: "TemplateModel";
  key: Scalars["String"]["output"];
  source: Scalars["String"]["output"];
};

export type TermsAndConditions = {
  __typename?: "TermsAndConditions";
  body: Scalars["String"]["output"];
  linkText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type TermsAndConditionsContent = {
  __typename?: "TermsAndConditionsContent";
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  sections: Array<CouponDetailsContainer>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TestimonialHeadingElements = EgdsIconText | EgdsPlainText;

export interface TextBreadcrumb {
  text: Scalars["String"]["output"];
  uiLinkAction?: Maybe<UiLinkAction>;
}

export type TextDirection = "LTR" | "RTL";

export type TextTheme = "EMPHASIS" | "INVERSE" | "LOYALTY" | "NEGATIVE" | "POSITIVE" | "PRIMARY" | "SECONDARY";

export type TextWeight = "BOLD" | "MEDIUM" | "REGULAR";

export type TextWithFile = TravelExperienceContent & {
  __typename?: "TextWithFile";
  content: Array<Maybe<EgdsElement>>;
  file?: Maybe<EgdsStandardLink>;
  id: Scalars["ID"]["output"];
};

export type TextWrapper = {
  __typename?: "TextWrapper";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  text: EgdsText;
};

export type TextWrapperFlightsPhrase = {
  __typename?: "TextWrapperFlightsPhrase";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  sustainabilityFlightPhrase: Array<FlightsPhrase>;
};

export type Theme =
  | "BRAND"
  | "BRANDED_DEAL"
  | "DEAL_ADD_ON"
  | "DEAL_BUNDLED"
  | "DEAL_GENERIC"
  | "DEAL_MEMBER"
  | "FAMILY_FRIENDLY"
  | "INFO"
  | "LINK"
  | "LOYALTY"
  | "LOYALTY_EXTRA_HIGH_TIER"
  | "LOYALTY_GLOBAL_EXTRA_HIGH_TIER"
  | "LOYALTY_GLOBAL_HIGH_TIER"
  | "LOYALTY_GLOBAL_LOW_TIER"
  | "LOYALTY_GLOBAL_MIDDLE_TIER"
  | "LOYALTY_HIGH_TIER"
  | "LOYALTY_LOW_TIER"
  | "LOYALTY_MIDDLE_TIER"
  | "NOTIFICATION"
  | "PROMOTED"
  | "SAVED"
  | "SPONSORED"
  | "STANDARD"
  | "SUCCESS"
  | "SUCCESS_MEDIUM"
  | "SUPPLIER_PROMO"
  | "URGENT"
  | "VIEWED"
  | "VIP"
  | "VIP_ACCESS";

export type TierCreditsDetails = TierMaintenanceAndProgressCriteria;

export type TierMaintenanceAndProgressCriteria = {
  __typename?: "TierMaintenanceAndProgressCriteria";
  amount?: Maybe<LoyaltyAmount>;
  hotelNights?: Maybe<Scalars["Int"]["output"]>;
  points?: Maybe<Scalars["Float"]["output"]>;
  tierName?: Maybe<Scalars["String"]["output"]>;
  tripItems?: Maybe<Scalars["Int"]["output"]>;
};

export type Timezone = {
  __typename?: "Timezone";
  name: Scalars["String"]["output"];
  offset: Scalars["String"]["output"];
};

export type ToastDismissDuration = "INDEFINITE" | "LONG" | "SHORT";

export type TogglePhase = {
  __typename?: "TogglePhase";
  accessibilityMessage?: Maybe<Scalars["String"]["output"]>;
  action: Scalars["String"]["output"];
  analytics: FlightsAnalytics;
  icon?: Maybe<Icon>;
};

export type ToggleableCardContentSection = {
  __typename?: "ToggleableCardContentSection";
  attachSwitchToggle?: Maybe<FlightsSwitchToggle>;
  description?: Maybe<Scalars["String"]["output"]>;
  infoDialogTrigger?: Maybe<InsurTechExperienceDialogTrigger>;
  /** @deprecated Use infoDialogTrigger to benefit from dialog query component */
  infoTrigger: FlightsDialogTrigger;
  price?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use description instead of subHeading favoring nullability */
  subHeading: Scalars["String"]["output"];
  /** @deprecated Use attachToggle instead of switchToggle favoring nullability */
  switchToggle: FlightsSwitchToggle;
};

export type ToggleableCardHeadingSection = {
  __typename?: "ToggleableCardHeadingSection";
  badge?: Maybe<EgdsLoyaltyBadge>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use graphic and title for more flexibility */
  header: EgdsGraphicText;
  price?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ToolbarNavType = "CLOSE" | "EXPAND_DOWN" | "EXPAND_UP";

export type TopLevelCampaignWizard = {
  __typename?: "TopLevelCampaignWizard";
  wizardNavigator: CampaignWizardNavigation;
  wizardSideBar: CampaignWizardSideBar;
  wizardStep: CampaignWizardStep;
};

export type TopLevelCampaignWizardWizardSideBarArgs = {
  campaignInput?: InputMaybe<CampaignInput>;
  productContext: ProductContext;
  productId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TopLevelCampaignWizardWizardStepArgs = {
  campaignInput?: InputMaybe<CampaignInput>;
  mode?: InputMaybe<WizardMode>;
  productContext: ProductContext;
  selectedProductId?: InputMaybe<Scalars["String"]["input"]>;
  wizardStep: WizardStep;
};

export interface TopTaskAction {
  cardAction?: InputMaybe<CardActions>;
  taskId: Scalars["String"]["input"];
  taskType?: InputMaybe<Scalars["String"]["input"]>;
}

export interface TopTaskCardAction {
  cardAction: CardActions;
  taskId: Scalars["String"]["input"];
  taskType?: InputMaybe<Scalars["String"]["input"]>;
  topTaskType: TopTaskTypes;
}

export type TopTaskTypes =
  | "ACCELERATOR_OPPORTUNITY"
  | "ADD_AMENITIES"
  | "ADD_MINIMUM_STAY"
  | "ADD_PHOTOS"
  | "ADD_SAFETY_DEVICES_AMENITIES"
  | "ARRIVING"
  | "AUTORATEMATCH_OPPORTUNITY"
  | "BANK_DETAILS"
  | "BOOKING_REQUEST"
  | "BOOKING_SMS_OPT_IN"
  | "CANCELLATION"
  | "CANCELLATION_REQUEST"
  | "CONFIRM_CONTACT"
  | "CONFIRM_IDENTITY"
  | "CURRENT_STAY"
  | "DIRECT_DEBIT"
  | "INQUIRY"
  | "LOWER_FEES"
  | "MINIMUMINVENTORY_OPPORTUNITY"
  | "MOBILEDRR_OPPORTUNITY"
  | "OPPORTUNITY"
  | "RATE_TRAVELER"
  | "TAX_FORM"
  | "TMODDRR_OPPORTUNITY"
  | "UNKNOWN"
  | "UNKNOWN__"
  | "UPDATE_TAX_INFORMATION"
  | "VRPAYMENTS_TIP";

export type TopTenProductsCarouselComponent = {
  __typename?: "TopTenProductsCarouselComponent";
  carousel: ActivityCarouselContainer;
  heading?: Maybe<Scalars["String"]["output"]>;
  seeAllLink?: Maybe<EgdsStandardLink>;
};

export type TotalPriceReassuranceCard = {
  __typename?: "TotalPriceReassuranceCard";
  backgroundTheme: EgdsCardBackgroundTheme;
  description: Scalars["String"]["output"];
  graphic: UiGraphic;
  themeProviderColor: EgdsThemeProviderColor;
  title: Scalars["String"]["output"];
};

export type TotalPriceSummary = {
  __typename?: "TotalPriceSummary";
  amount?: Maybe<LodgingEnrichedMessage>;
  label?: Maybe<LodgingEnrichedMessage>;
};

export type TrackingData = {
  __typename?: "TrackingData";
  totalPriceAmount: Scalars["Float"]["output"];
  totalPriceCurrencyCode: Scalars["String"]["output"];
};

export type TransparencyEntity = {
  __typename?: "TransparencyEntity";
  expando?: Maybe<EgdsExpandoPeek>;
  label: Scalars["String"]["output"];
  names: Scalars["String"]["output"];
};

export type TransparencyOverlay = {
  __typename?: "TransparencyOverlay";
  callToAction: EgdsInlineLink;
  entities: Array<TransparencyEntity>;
  targetingCategories: TargetingCategories;
};

export type TransparencyOverlayError = {
  __typename?: "TransparencyOverlayError";
  allowedRetryAttempts?: Maybe<Scalars["Int"]["output"]>;
  button?: Maybe<UiPrimaryButton>;
  description: Scalars["String"]["output"];
  exceededAttemptsMessage?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
};

export type TransparencyOverlaySkeleton = {
  __typename?: "TransparencyOverlaySkeleton";
  error: TransparencyOverlayError;
  title: Scalars["String"]["output"];
};

export type TravelAgentAuthenticationOptionsResponse = {
  __typename?: "TravelAgentAuthenticationOptionsResponse";
  joinNow: UiSecondaryButton;
  logo: Mark;
  signIn: UiPrimaryButton;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type TravelAgentDashboard = {
  __typename?: "TravelAgentDashboard";
  heading?: Maybe<Scalars["String"]["output"]>;
  sections: Array<TravelAgentDashboardSection>;
};

export type TravelAgentDashboardLabelValue = {
  __typename?: "TravelAgentDashboardLabelValue";
  label?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type TravelAgentDashboardProgress = {
  __typename?: "TravelAgentDashboardProgress";
  progressBar?: Maybe<EgdsProgressBar>;
  progressBarNote?: Maybe<Scalars["String"]["output"]>;
};

export type TravelAgentDashboardSection = {
  __typename?: "TravelAgentDashboardSection";
  cardActionLink?: Maybe<ActionLink>;
  cardLink?: Maybe<UiLinkAction>;
  clickableActionLink?: Maybe<ActionLink>;
  clickableLink?: Maybe<EgdsStandardLink>;
  content?: Maybe<TravelAgentDashboardSectionContent>;
  heading?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type TravelAgentDashboardSectionContent = TravelAgentDashboardProgress | TravelAgentDashboardSubheadingList;

export type TravelAgentDashboardSubheadingList = {
  __typename?: "TravelAgentDashboardSubheadingList";
  labelValues?: Maybe<Array<TravelAgentDashboardLabelValue>>;
  subheadings: Array<Maybe<Scalars["String"]["output"]>>;
};

export type TravelAgentPackageRatesDetail = {
  __typename?: "TravelAgentPackageRatesDetail";
  heading?: Maybe<Scalars["String"]["output"]>;
  termsAndConditions: Scalars["String"]["output"];
};

export type TravelAgentSearchWizardConfig = {
  __typename?: "TravelAgentSearchWizardConfig";
  enabledLobs: Array<Scalars["String"]["output"]>;
  packageRatesDetail?: Maybe<TravelAgentPackageRatesDetail>;
  packageRatesEnabled: Scalars["Boolean"]["output"];
};

export interface TravelContentAttributesInput {
  contentId: Scalars["String"]["input"];
  preview: Scalars["Boolean"]["input"];
}

export interface TravelContentUriContextInput {
  attributes: UriContextAttributesInput;
  identifiers: UriContextIdentifiersInput;
}

export interface TravelExperienceContent {
  id: Scalars["ID"]["output"];
}

export type TravelExperienceContentResults = {
  __typename?: "TravelExperienceContentResults";
  editorialComponent?: Maybe<EditorialComponent>;
  portableTextDocumentWithLink?: Maybe<PortableTextWithLink>;
};

export type TravelExperienceHero = TravelExperienceContent & {
  __typename?: "TravelExperienceHero";
  alt?: Maybe<Scalars["String"]["output"]>;
  author?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  image: Image;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<EgdsText>>;
};

export type TravelGuide = {
  __typename?: "TravelGuide";
  url?: Maybe<Scalars["URL"]["output"]>;
};

export interface TravelGuidePageContextInput {
  pageType: PageType;
  placeId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface TravelMemberGroupInput {
  adults: Scalars["Int"]["input"];
  childrenAges?: InputMaybe<Array<Scalars["Int"]["input"]>>;
}

export interface TravelSearchCriteriaInput {
  activity?: InputMaybe<ActivitiySearchCriteriaInput>;
  car?: InputMaybe<CarOfferCriteriaInput>;
  cruise?: InputMaybe<CruiseSailingDetailsCriteriaInput>;
  property?: InputMaybe<PropertySearchCriteriaInput>;
}

export type TravelShop = TravelShopCard;

export type TravelShopCard = {
  __typename?: "TravelShopCard";
  avatar: EgdsAvatar;
  background?: Maybe<EgdsImageLink>;
  description?: Maybe<Scalars["String"]["output"]>;
  impression?: Maybe<ClientSideAnalytics>;
  subTitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type TravelShopCollectionsResponse = {
  __typename?: "TravelShopCollectionsResponse";
  cards: Array<TravelShop>;
  description: Scalars["String"]["output"];
  header: Scalars["String"]["output"];
  impression: ClientSideAnalytics;
};

export type TravelShopType = "COLLECTIONS" | "SHOPS";

export interface TravelStoreV1CarDetailsInput {
  cleaningSafetyInformation: Scalars["Boolean"]["input"];
  periodPricing: Scalars["Boolean"]["input"];
  showAds: Scalars["Boolean"]["input"];
}

export interface TravelStoreV1CarSearchInput {
  cleaningSafetyInformation: Scalars["Boolean"]["input"];
  defaultSortOption: Scalars["String"]["input"];
  filterOptions: Array<Scalars["String"]["input"]>;
  messagingOptions: Array<Scalars["String"]["input"]>;
  periodPricing: Scalars["Boolean"]["input"];
  showAds: Scalars["Boolean"]["input"];
  sortOptions: Array<Scalars["String"]["input"]>;
}

export type TravelStoryButton = {
  __typename?: "TravelStoryButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  style: EgdsButtonStyle;
  uiAction: TravelStoryUiAction;
};

export type TravelStoryCarousel = {
  __typename?: "TravelStoryCarousel";
  carousel: Array<TravelStoryVideoContentSection>;
  clientSideAnalytics: ClientSideAnalytics;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  watchAllItem: TravelStoryWatchAllItem;
  watchMoreVideosLink?: Maybe<EgdsStandardLink>;
};

export type TravelStoryDestinationSearchContext = {
  __typename?: "TravelStoryDestinationSearchContext";
  regionId: Scalars["String"]["output"];
};

export type TravelStoryFooter = {
  __typename?: "TravelStoryFooter";
  actionButtons?: Maybe<Array<TravelStoryButton>>;
  destinationSearch?: Maybe<TravelStoryDestinationSearchContext>;
  hotelSearchCriteria?: Maybe<TravelStoryHotelSearchContext>;
};

export type TravelStoryHotelSearchContext = {
  __typename?: "TravelStoryHotelSearchContext";
  checkInDate: Scalars["String"]["output"];
  checkOutDate: Scalars["String"]["output"];
  numberOfAdults: Scalars["String"]["output"];
  numberOfChildren: Scalars["String"]["output"];
  regionId: Scalars["String"]["output"];
};

export type TravelStoryOverlayContentSection = {
  __typename?: "TravelStoryOverlayContentSection";
  toolbar: EgdsToolbar;
  travelStoryFooter: TravelStoryFooter;
};

export type TravelStoryUiAction = {
  __typename?: "TravelStoryUIAction";
  clientSideAnalytics?: Maybe<ClientSideAnalytics>;
  deeplink?: Maybe<UiLinkAction>;
};

export type TravelStoryVideoContentSection = {
  __typename?: "TravelStoryVideoContentSection";
  caption?: Maybe<Scalars["String"]["output"]>;
  carouselToFullPageLink?: Maybe<TravelStoryUiAction>;
  place?: Maybe<Scalars["String"]["output"]>;
  regionId: Scalars["String"]["output"];
  video: Video;
  viewCount?: Maybe<Scalars["String"]["output"]>;
  viewCountIcon?: Maybe<Icon>;
};

export type TravelStoryVlog = {
  __typename?: "TravelStoryVlog";
  swipeAnalytics: ClientSideAnalytics;
  travelStoryOverlayContentSection: TravelStoryOverlayContentSection;
  travelStoryVideoContentSection: TravelStoryVideoContentSection;
};

export type TravelStoryWatchAllItem = {
  __typename?: "TravelStoryWatchAllItem";
  videoContentSection: TravelStoryVideoContentSection;
  watchAllTitle: Scalars["String"]["output"];
};

export type TravelTriviaBannerQuery = AppGrowthQueryImpressionSupportResponse &
  AppGrowthQueryResponse & {
    __typename?: "TravelTriviaBannerQuery";
    clientSideAnalytics: ClientSideAnalytics;
    clientSideAnalyticsImpression: ClientSideAnalytics;
    clientSideErrorDialog: AppGrowthDialog;
    logo?: Maybe<AppGrowthMediaItem>;
    primaryButton?: Maybe<AppGrowthButton>;
    progressBar?: Maybe<EgdsProgressBar>;
    serverSideErrorDialog?: Maybe<AppGrowthDialog>;
    summary?: Maybe<AppGrowthSummary>;
  };

export type TraveladsBenefits = {
  __typename?: "TraveladsBenefits";
  bookingMetric: TraveladsMetric;
  cardBody: Scalars["String"]["output"];
  cardTitle: Scalars["String"]["output"];
  visibilityMetric: TraveladsMetric;
};

export type TraveladsBody = {
  __typename?: "TraveladsBody";
  benefitsSection?: Maybe<TraveladsBenefits>;
  featuredCampaignsSection?: Maybe<FeaturedCustomerCampaigns>;
  footnoteText?: Maybe<Scalars["String"]["output"]>;
  traveladsSection: GoToTravelAds;
};

export type TraveladsBullet = {
  __typename?: "TraveladsBullet";
  icon?: Maybe<Icon>;
  text: EgdsSpannableText;
};

export type TraveladsHeader = {
  __typename?: "TraveladsHeader";
  body: Scalars["String"]["output"];
  currentPageText: Scalars["String"]["output"];
  previousPageText: Scalars["String"]["output"];
  previousPageUrl: Scalars["URL"]["output"];
  title: Scalars["String"]["output"];
};

export type TraveladsMetric = {
  __typename?: "TraveladsMetric";
  percentage: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type Traveler = {
  __typename?: "Traveler";
  age?: Maybe<Scalars["Int"]["output"]>;
  ageCategory?: Maybe<Scalars["Int"]["output"]>;
  primary?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TravelerCouponActive = {
  __typename?: "TravelerCouponActive";
  bookingDateEnd: Scalars["String"]["output"];
  discount: Scalars["String"]["output"];
  logo: Mark;
  name: Scalars["String"]["output"];
  termsAndConditions: UiTertiaryButton;
  travelDateEnd: Scalars["String"]["output"];
  useCoupon: UiTertiaryButton;
};

export type TravelerCouponUsedOrExpired = {
  __typename?: "TravelerCouponUsedOrExpired";
  discount: Scalars["String"]["output"];
  logo: Mark;
  name: Scalars["String"]["output"];
  usedOrExpiredDate: Scalars["String"]["output"];
};

export type TravelerCoupons = TravelerCouponsError | TravelerCouponsSuccess;

export type TravelerCouponsActiveView = {
  __typename?: "TravelerCouponsActiveView";
  coupons?: Maybe<Array<TravelerCouponActive>>;
  noCouponsDescription?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type TravelerCouponsContent = {
  __typename?: "TravelerCouponsContent";
  activeCoupons: TravelerCouponsActiveView;
  description: Scalars["String"]["output"];
  howTo: EgdsStandardLink;
  usedOrExpiredCoupons?: Maybe<TravelerCouponsUsedOrExpiredView>;
};

export type TravelerCouponsError = {
  __typename?: "TravelerCouponsError";
  error: Error;
};

export type TravelerCouponsSuccess = {
  __typename?: "TravelerCouponsSuccess";
  container: EgdsFullScreenDialog;
  content: TravelerCouponsContent;
};

export type TravelerCouponsUsedOrExpiredView = {
  __typename?: "TravelerCouponsUsedOrExpiredView";
  coupons: Array<TravelerCouponUsedOrExpired>;
  seeCoupons: EgdsExpandoLink;
};

export type TravelerDetails = {
  __typename?: "TravelerDetails";
  age?: Maybe<Scalars["Int"]["output"]>;
  type: TravelerDetailsType;
};

export interface TravelerDetailsInput {
  age?: InputMaybe<Scalars["Int"]["input"]>;
  type: TravelerDetailsType;
}

export type TravelerDetailsInputType = {
  __typename?: "TravelerDetailsInputType";
  age?: Maybe<Scalars["Int"]["output"]>;
  type: TravelerDetailsType;
};

export type TravelerDetailsType = "ADULT" | "CHILD" | "INFANT_IN_LAP" | "INFANT_IN_SEAT";

export type TravelerOccupancyGroup = {
  __typename?: "TravelerOccupancyGroup";
  travelers: Array<TravelerDetails>;
};

export interface TravelerOccupancyGroupInput {
  travelers: Array<TravelerDetailsInput>;
}

export type TravelerSelectorForm = {
  __typename?: "TravelerSelectorForm";
  submitButton: UiPrimaryButton;
  title?: Maybe<LodgingEnrichedMessage>;
  unitGroups: Array<LodgingUnitGroup>;
};

export type TravelerSwitchMutualExclusion = {
  __typename?: "TravelerSwitchMutualExclusion";
  primarySwitchId: Scalars["String"]["output"];
  secondarySwitchId: Scalars["String"]["output"];
};

export type TravelerType = "ADULTS_ONLY" | "BUSINESS" | "ECO_CERTIFIED" | "FAMILY" | "LGBT" | "ROMANTIC";

export interface TravellerAncillarySelectionInput {
  ancillaryTokens: Array<Scalars["String"]["input"]>;
  travelerIndex: Scalars["Int"]["input"];
}

export type TravellerClickAction = {
  __typename?: "TravellerClickAction";
  clickAnalytics?: Maybe<ClientSideAnalytics>;
  clickEvent?: Maybe<EgClickstreamEvent>;
};

export type TravellerSearch = {
  __typename?: "TravellerSearch";
  badge?: Maybe<EgdsStandardBadge>;
  floatingActionButton?: Maybe<ProductPrimaryFloatingActionButton>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<TravellerClickAction>;
  paragraph?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated as searchAction field is no longer in use in Traveller Q&A design */
  searchAction?: Maybe<TravellerClickAction>;
  /** @deprecated Deprecated as title field is no longer in use in Traveller Q&A design */
  title?: Maybe<Scalars["String"]["output"]>;
  travellerSearchDialog?: Maybe<TravellerSearchDialog>;
  travellerSearchDialogTrigger?: Maybe<TravellerSearchDialogTrigger>;
  travellerSearchInput?: Maybe<TravellerSearchInput>;
};

export type TravellerSearchButton = {
  __typename?: "TravellerSearchButton";
  button: UiPrimaryButton;
  searchAction?: Maybe<TravellerClickAction>;
};

export type TravellerSearchDialog = {
  __typename?: "TravellerSearchDialog";
  closeAction?: Maybe<TravellerClickAction>;
  dialogToolbar?: Maybe<EgdsDialogToolbar>;
  errorMessage?: Maybe<EgdsErrorSummary>;
  impressionAnalytics?: Maybe<TravellerClickAction>;
  loadingStateAccessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Deprecated as scrollAction field is no longer in use in Traveller Q&A design */
  scrollAction?: Maybe<TravellerClickAction>;
  travellerSearchInput?: Maybe<TravellerSearchInput>;
};

export type TravellerSearchDialogCta = {
  __typename?: "TravellerSearchDialogCTA";
  button?: Maybe<UiSecondaryButton>;
  impressionAnalytics?: Maybe<TravellerClickAction>;
  targetRef?: Maybe<Scalars["String"]["output"]>;
};

export type TravellerSearchDialogTrigger = {
  __typename?: "TravellerSearchDialogTrigger";
  button: UiSecondaryButton;
};

export type TravellerSearchFeedback = {
  __typename?: "TravellerSearchFeedback";
  title?: Maybe<Scalars["String"]["output"]>;
  travellerSearchFeedbackActions?: Maybe<Array<TravellerSearchFeedbackAction>>;
};

export type TravellerSearchFeedbackAction = {
  __typename?: "TravellerSearchFeedbackAction";
  clickAction?: Maybe<TravellerClickAction>;
  /** @deprecated Deprecated in favour of TravellerClickAction */
  clickEvent?: Maybe<EgClickstreamEvent>;
  directFeedbackCallToAction?: Maybe<DirectFeedbackCallToAction>;
};

export type TravellerSearchInput = {
  __typename?: "TravellerSearchInput";
  searchButton: TravellerSearchButton;
  searchInputField: TravellerSearchInputField;
};

export type TravellerSearchInputField = {
  __typename?: "TravellerSearchInputField";
  inputAction?: Maybe<TravellerClickAction>;
  textInput: EgdsTextInputField;
};

export type TravellerSearchResultsResponse = {
  __typename?: "TravellerSearchResultsResponse";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  callToAction?: Maybe<TravellerSearchDialogCta>;
  content?: Maybe<TravellerSearchSummary>;
  disclaimerText?: Maybe<Scalars["String"]["output"]>;
  noResultsMessage?: Maybe<Scalars["String"]["output"]>;
  review?: Maybe<TravellerSearchSummary>;
};

export type TravellerSearchSummary = {
  __typename?: "TravellerSearchSummary";
  description?: Maybe<EgdsGraphicText>;
  heading?: Maybe<Scalars["String"]["output"]>;
  highlightedText: EgdsSpannableText;
  itemSummary?: Maybe<ItemSummary>;
  /** @deprecated Deprecated in favour of ItemSummary */
  items?: Maybe<Array<ReviewSummary>>;
  /** @deprecated Deprecated in favour of itemHeader */
  subHeading?: Maybe<Scalars["String"]["output"]>;
  travellerSearchFeedback?: Maybe<TravellerSearchFeedback>;
};

export type Trip = {
  __typename?: "Trip";
  creation: TripsViewResponse;
  edit: TripsViewResponse;
  invite: TripsViewResponse;
  itemDetails: TripsViewResponse;
  itemEmailItinerary: TripsViewResponse;
  itemEssentialInfo: TripsViewResponse;
  itemItinerary: TripsViewResponse;
  itemManageBooking: TripsViewResponse;
  itemPricingAndRewards: TripsViewResponse;
  itemReceipt: TripsViewResponse;
  itemVoucher: TripsViewResponse;
  itemVoucherPrint: TripsViewResponse;
  manageGuests: TripsViewResponse;
  moveTripItem: TripsViewResponse;
  overview: TripsViewResponse;
  overviewItinerary: TripsViewResponse;
  pendingInvite: TripsViewResponse;
  savedItemPrice: TripsSavedItemPrice;
};

export type TripCreationArgs = {
  metadata?: InputMaybe<TripCreationMetadataInput>;
  source?: InputMaybe<TripCreationEntryPoint>;
};

export type TripEditArgs = {
  overview: TripOverviewInput;
};

export type TripInviteArgs = {
  overview: TripOverviewInput;
};

export type TripItemDetailsArgs = {
  item: TripItemInput;
  userToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripItemEmailItineraryArgs = {
  item: TripItemInput;
};

export type TripItemEssentialInfoArgs = {
  essentialItemInput: EssentialInfoItemInput;
  item: TripItemInput;
};

export type TripItemItineraryArgs = {
  item: TripItemInput;
};

export type TripItemManageBookingArgs = {
  item: TripItemInput;
};

export type TripItemPricingAndRewardsArgs = {
  item: TripItemInput;
};

export type TripItemReceiptArgs = {
  item: TripItemInput;
};

export type TripItemVoucherArgs = {
  item: TripItemInput;
};

export type TripItemVoucherPrintArgs = {
  item: TripItemInput;
};

export type TripManageGuestsArgs = {
  overview: TripOverviewInput;
};

export type TripMoveTripItemArgs = {
  item: TripItemInput;
};

export type TripOverviewArgs = {
  overview: TripOverviewInput;
  userToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripOverviewItineraryArgs = {
  overview: TripOverviewInput;
};

export type TripPendingInviteArgs = {
  tripInvite: TripInviteInput;
};

export type TripSavedItemPriceArgs = {
  item: TripItemInput;
};

export type TripAssistGoalFailureResponse = {
  __typename?: "TripAssistGoalFailureResponse";
  accessibility: Scalars["String"]["output"];
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  refreshButton: TripsUiRefreshAction;
};

export type TripAssistGoalMessagingCard = {
  __typename?: "TripAssistGoalMessagingCard";
  actions?: Maybe<Array<TripAssistGoalMessagingCardAction>>;
  card: TripsMessagingCard;
};

export type TripAssistGoalMessagingCardAction =
  | TripsUiDownloadAppBannerAction
  | TripsUiiOpenInviteToTripSheetAction
  | TripsUiOpenInviteToTripSheetAction
  | TripsUiTextLinkAction;

export type TripAssistGoalResponse = TripAssistGoalFailureResponse | TripAssistGoalSuccessResponse;

export type TripAssistGoalSuccessResponse = {
  __typename?: "TripAssistGoalSuccessResponse";
  heading: Scalars["String"]["output"];
  tasks: Array<TripAssistGoalMessagingCard>;
};

export type TripAssistGoalType = "BOOK" | "PLAN";

export type TripAttachSection = {
  __typename?: "TripAttachSection";
  title: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripBooking = BookingDetail | UnSupportedBooking;

export type TripBookingModule = {
  __typename?: "TripBookingModule";
  orderId: Scalars["String"]["output"];
  posOrderReferenceNumber: Scalars["String"]["output"];
  transactionStatus: Scalars["String"]["output"];
};

export type TripCareAttributes = {
  __typename?: "TripCareAttributes";
  orderLineNumbers?: Maybe<Scalars["String"]["output"]>;
  vendor?: Maybe<Scalars["String"]["output"]>;
};

export type TripCareBanner = {
  __typename?: "TripCareBanner";
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  link?: Maybe<TripCareLinkPhrase>;
  message: Scalars["String"]["output"];
};

export type TripCareButton = {
  __typename?: "TripCareButton";
  action: TripCareButtonAction;
  icon: Icon;
  state?: Maybe<TripCareButtonState>;
  tag: Scalars["String"]["output"];
};

export type TripCareButtonAction = "BACK" | "CANCEL_POLICY" | "DISMISS_POPUP" | "POPUP" | "RELOAD";

export type TripCareButtonState = "DISABLED" | "ENABLED";

export type TripCareCountryPhoneComponent = {
  __typename?: "TripCareCountryPhoneComponent";
  countryName: TripCareTextPhrase;
  phoneNumber: TripCarePhoneNumberComponent;
};

export type TripCareCountryPhoneListComponent = {
  __typename?: "TripCareCountryPhoneListComponent";
  countryPhoneComponents: Array<TripCareCountryPhoneComponent>;
};

export type TripCareDialog = {
  __typename?: "TripCareDialog";
  footer: TripCareDialogFooter;
  header: TripCareHeader;
  section: TripCareSection;
  tag?: Maybe<TripCareTextPhrase>;
  uniqueDialogId: Scalars["String"]["output"];
};

export type TripCareDialogFooter = {
  __typename?: "TripCareDialogFooter";
  buttonOne: TripCareButton;
  buttonThree?: Maybe<TripCareButton>;
  buttonTwo?: Maybe<TripCareButton>;
  stacked: Scalars["Boolean"]["output"];
};

export type TripCareEmailComponent = {
  __typename?: "TripCareEmailComponent";
  emailId: Scalars["String"]["output"];
  icon: Icon;
  message: TripCareTextPhrase;
};

export type TripCareError = {
  __typename?: "TripCareError";
  errorBody?: Maybe<TripCareSection>;
  errorCode: Scalars["String"]["output"];
  errorMessage: Scalars["String"]["output"];
  httpStatus: HttpStatus;
};

export type TripCareExpando = {
  __typename?: "TripCareExpando";
  analytics?: Maybe<ClientSideAnalytics>;
  details: TripCareSection;
  heading: TripCareTextPhrase;
  isVisible: Scalars["Boolean"]["output"];
};

export type TripCareExpandoPeek = {
  __typename?: "TripCareExpandoPeek";
  analytics?: Maybe<ClientSideAnalytics>;
  collapseLabel: Scalars["String"]["output"];
  details: TripCareSectionComponent;
  expandLabel: Scalars["String"]["output"];
};

export type TripCareFullScreenDialog = {
  __typename?: "TripCareFullScreenDialog";
  header: TripCareHeader;
  section: TripCareSection;
  tag: Scalars["String"]["output"];
  uniqueDialogId: Scalars["String"]["output"];
};

export type TripCareHeader = {
  __typename?: "TripCareHeader";
  additionalHeaders: Array<TripCarePhrase>;
  heading: TripCareTextPhrase;
  subHeading?: Maybe<TripCareTextPhrase>;
};

export type TripCareLayoutCard = {
  __typename?: "TripCareLayoutCard";
  columnNumber: Scalars["Int"]["output"];
  section: TripCareSectionComponent;
};

export type TripCareLayoutComponent = TripCareLayoutCard | TripCareLayoutSection;

export type TripCareLayoutSection = {
  __typename?: "TripCareLayoutSection";
  columnNumber: Scalars["Int"]["output"];
  section: TripCareSectionComponent;
};

export type TripCareLayoutStyle = "CARD_BORDER_TOP";

export type TripCareLinkPhrase = {
  __typename?: "TripCareLinkPhrase";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  label: Scalars["String"]["output"];
  phraseType?: Maybe<TripCarePhraseType>;
  styles?: Maybe<Array<TripCarePhraseStyle>>;
  url: HttpUri;
};

export type TripCareListComponent = {
  __typename?: "TripCareListComponent";
  contentList: Array<TripCarePhrase>;
  heading?: Maybe<TripCareTextPhrase>;
  listType: TripCareListType;
  subHeading?: Maybe<TripCareTextPhrase>;
};

export type TripCareListType = "BULLET" | "NO_BULLET" | "NUMBERED";

export type TripCareOverviewSection = {
  __typename?: "TripCareOverviewSection";
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  link: TripCareLinkPhrase;
};

export type TripCarePhoneNumberComponent = {
  __typename?: "TripCarePhoneNumberComponent";
  icon?: Maybe<Icon>;
  identifier: Scalars["String"]["output"];
  message?: Maybe<TripCareSentence>;
  phone: Scalars["String"]["output"];
  regionDetails?: Maybe<TripCareTextPhrase>;
};

export type TripCarePhrase =
  | TripCareDialog
  | TripCareFullScreenDialog
  | TripCareLinkPhrase
  | TripCareSentence
  | TripCareTextPhrase;

export type TripCarePhraseStyle =
  | "BOLD"
  | "HR_DIVIDER_BOTTOM"
  | "HR_DIVIDER_TOP"
  | "SIZE_100"
  | "SIZE_200"
  | "SIZE_300"
  | "SIZE_400"
  | "SIZE_500"
  | "SIZE_600"
  | "SIZE_700"
  | "SIZE_800"
  | "SIZE_900"
  | "SMALL"
  | "SPACING_FOUR"
  | "SPACING_HALF"
  | "SPACING_ONE"
  | "SPACING_SIX"
  | "SPACING_THREE"
  | "SPACING_TWO"
  | "THEME_EMPHASIS"
  | "THEME_NEGATIVE"
  | "THEME_POSITIVE"
  | "WEIGHT_BOLD"
  | "WEIGHT_MEDIUM"
  | "WEIGHT_REGULAR";

export type TripCarePhraseType = "BUTTON" | "LINK" | "TEXT";

export type TripCareSection = {
  __typename?: "TripCareSection";
  footer?: Maybe<Array<TripCarePhrase>>;
  heading?: Maybe<TripCareTextPhrase>;
  sections?: Maybe<Array<TripCareSectionComponent>>;
  styles?: Maybe<Array<TripCareLayoutStyle>>;
  subHeading?: Maybe<TripCareTextPhrase>;
};

export type TripCareSectionComponent =
  | TripCareBanner
  | TripCareButton
  | TripCareCountryPhoneComponent
  | TripCareCountryPhoneListComponent
  | TripCareDialog
  | TripCareDialogFooter
  | TripCareEmailComponent
  | TripCareExpando
  | TripCareExpandoPeek
  | TripCareFullScreenDialog
  | TripCareLinkPhrase
  | TripCareListComponent
  | TripCareOverviewSection
  | TripCarePhoneNumberComponent
  | TripCareSection
  | TripCareSentence
  | TripCareTextPhrase;

export type TripCareSentence = {
  __typename?: "TripCareSentence";
  phrases: Array<TripCarePhrase>;
  styles?: Maybe<Array<TripCarePhraseStyle>>;
};

export type TripCareTextPhrase = {
  __typename?: "TripCareTextPhrase";
  content: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  phraseType?: Maybe<TripCarePhraseType>;
  styles?: Maybe<Array<TripCarePhraseStyle>>;
};

export type TripCarousel = {
  __typename?: "TripCarousel";
  accessibilityHeader?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  header?: Maybe<Scalars["String"]["output"]>;
  nextButton: TripCarouselControlButton;
  peek?: Maybe<Scalars["Boolean"]["output"]>;
  prevButton: TripCarouselControlButton;
};

export type TripCarouselControlButton = {
  __typename?: "TripCarouselControlButton";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<ClientSideAnalytics>;
};

export type TripCategory = {
  __typename?: "TripCategory";
  header: Scalars["String"]["output"];
  tabTitle: Scalars["String"]["output"];
  tripInfos?: Maybe<Array<HelpCenterTripInfo>>;
};

export interface TripCollaborationChatEntryPointButtonRequestInput {
  conversationType: ConversationType;
  tripId: Scalars["String"]["input"];
}

export type TripCollaborationChatEntryPointButtonResponse = {
  __typename?: "TripCollaborationChatEntryPointButtonResponse";
  entryPointButton: UiSecondaryButton;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  vacInitializationParams: TripCollaborationChatEntryPointVacInitializationParams;
};

export type TripCollaborationChatEntryPointCard = {
  __typename?: "TripCollaborationChatEntryPointCard";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  primary: Scalars["String"]["output"];
  primaryIcon: EgdsBadgeNotification;
  secondary?: Maybe<Scalars["String"]["output"]>;
  trailingIcon: Icon;
  vacInitializationParams: TripCollaborationChatEntryPointVacInitializationParams;
};

export interface TripCollaborationChatEntryPointRequestInput {
  conversationType: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
}

export type TripCollaborationChatEntryPointResponse = {
  __typename?: "TripCollaborationChatEntryPointResponse";
  tripCollaborationChatEntryPointCard: TripCollaborationChatEntryPointCard;
};

export type TripCollaborationChatEntryPointVacInitializationParams = {
  __typename?: "TripCollaborationChatEntryPointVacInitializationParams";
  conversationAttributes?: Maybe<Array<VirtualAgentControlAttribute>>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  conversationMode: ConversationMode;
  conversationType: ConversationType;
  participantId?: Maybe<Scalars["String"]["output"]>;
};

export type TripCollaborationChatHeader = {
  __typename?: "TripCollaborationChatHeader";
  backIcon: Icon;
  inviteAccessibility: Scalars["String"]["output"];
  inviteAnalytics: ClientSideAnalytics;
  inviteIcon: Icon;
  inviteImage?: Maybe<Image>;
  inviteLink: HttpUri;
  inviteMessage: Array<Scalars["String"]["output"]>;
  moreOptionsIcon: Icon;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export interface TripCollaborationChatHeaderRequestInput {
  tripId: Scalars["String"]["input"];
}

export type TripCollaborationChatHeaderResponse = {
  __typename?: "TripCollaborationChatHeaderResponse";
  tripCollaborationChatHeader: TripCollaborationChatHeader;
};

export type TripCollaborationDisclaimerSection = {
  __typename?: "TripCollaborationDisclaimerSection";
  primaryText: Scalars["String"]["output"];
  resourceLinks: Array<TripCollaborationResourceLink>;
};

export type TripCollaborationOnboardingScreenResponse = {
  __typename?: "TripCollaborationOnboardingScreenResponse";
  closeButtonAnalytics: ClientSideAnalytics;
  closeButtonIcon: Icon;
  disclaimerSection: TripCollaborationDisclaimerSection;
  getStartedButton: EgdsButton;
  headerText: Scalars["String"]["output"];
  image: Image;
  inspirationSection: TripCollaborationOnboardingScreenSection;
  travelingWithGroupSection: TripCollaborationOnboardingScreenSection;
};

export type TripCollaborationOnboardingScreenSection = {
  __typename?: "TripCollaborationOnboardingScreenSection";
  icon: Icon;
  primaryText: Scalars["String"]["output"];
  primaryTextWeight: TextWeight;
  secondaryText: Scalars["String"]["output"];
  secondaryTextWeight: TextWeight;
};

export type TripCollaborationResourceLink = {
  __typename?: "TripCollaborationResourceLink";
  resourceName: Scalars["String"]["output"];
  resourceUrl: Scalars["URL"]["output"];
};

export type TripContextualCardCarousel = {
  __typename?: "TripContextualCardCarousel";
  carousel: TripCarousel;
  content: Array<TripsContextualMessagingCard>;
};

export type TripCreationEntryPoint = "HOTEL_SEARCH_RESULTS" | "TRIPS_LIST" | "TRIP_OVERVIEW";

export type TripCreationMetadata = {
  __typename?: "TripCreationMetadata";
  /** @deprecated TripCreationEntryPoint has been deprecated and will be removed in future */
  entryPoint: TripCreationEntryPoint;
  /** @deprecated itemId has been deprecated., replace with saveItemInput.itemId */
  itemId?: Maybe<Scalars["String"]["output"]>;
  moveItem?: Maybe<TripItem>;
  saveItemInput?: Maybe<TripsPlan>;
};

export interface TripCreationMetadataInput {
  entryPoint?: InputMaybe<TripCreationEntryPoint>;
  itemId?: InputMaybe<Scalars["String"]["input"]>;
  moveItemInput?: InputMaybe<TripItemInput>;
  saveItemInput?: InputMaybe<TripsPlanInput>;
}

export type TripCustomerSupportSuccessResponse = TripsCustomerSupportResponse & {
  __typename?: "TripCustomerSupportSuccessResponse";
  accessibility: Scalars["String"]["output"];
  content: TripsCustomerSupportContent;
  heading: Scalars["String"]["output"];
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  secondaries: Array<EgdsPlainText>;
};

export interface TripDetailsSupportRequestsInput {
  buttonStyle: EgdsButtonStyle;
  itineraryNumber: Scalars["String"]["input"];
  lineOfBusiness: Scalars["String"]["input"];
  orderLineId: Scalars["String"]["input"];
}

export type TripElement = {
  __typename?: "TripElement";
  icon?: Maybe<UiGraphic>;
  subtitle?: Maybe<EgdsStylizedText>;
  title?: Maybe<EgdsStylizedText>;
};

export type TripEntity = "ACTIVITY_PRODUCT" | "CAR_PRODUCT" | "FLIGHT_SEARCH" | "LODGING_PRODUCT" | "PACKAGE_PRODUCT";

export interface TripInfoContextInput {
  bookingItemId?: InputMaybe<Scalars["String"]["input"]>;
  itineraryLink?: InputMaybe<Scalars["String"]["input"]>;
  orderLineId?: InputMaybe<Scalars["String"]["input"]>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  propertyName?: InputMaybe<Scalars["String"]["input"]>;
  tripEndDate?: InputMaybe<Scalars["String"]["input"]>;
  tripStartDate?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripIntentAirport = {
  __typename?: "TripIntentAirport";
  cityRegionId?: Maybe<Scalars["String"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
};

export type TripIntentDestination = {
  __typename?: "TripIntentDestination";
  airport?: Maybe<TripIntentAirport>;
  coordinates?: Maybe<Coordinates>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type TripIntentOrigin = {
  __typename?: "TripIntentOrigin";
  airport?: Maybe<TripIntentAirport>;
  coordinates?: Maybe<Coordinates>;
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type TripIntentPerson = {
  __typename?: "TripIntentPerson";
  age?: Maybe<Scalars["Int"]["output"]>;
  type: TripIntentPersonType;
};

export type TripIntentPersonType = "ADULT" | "CHILD";

export type TripIntentRoom = {
  __typename?: "TripIntentRoom";
  childAges: Array<Scalars["Int"]["output"]>;
  numberOfAdults: Scalars["Int"]["output"];
  numberOfChildren: Scalars["Int"]["output"];
};

export type TripIntentWhen = {
  __typename?: "TripIntentWhen";
  fromDate: ZonedDateTime;
  toDate: ZonedDateTime;
};

export type TripIntentWhere = {
  __typename?: "TripIntentWhere";
  destination?: Maybe<TripIntentDestination>;
  origin?: Maybe<TripIntentOrigin>;
};

export type TripIntentWho = {
  __typename?: "TripIntentWho";
  people: Array<TripIntentPerson>;
  rooms: Array<TripIntentRoom>;
};

export type TripIntentWhy = {
  __typename?: "TripIntentWhy";
  interests: Array<Interest>;
};

export interface TripInviteInput {
  inviteId: Scalars["String"]["input"];
}

export type TripItem = {
  __typename?: "TripItem";
  filter?: Maybe<Scalars["String"]["output"]>;
  segments?: Maybe<Array<Scalars["String"]["output"]>>;
  tripEntity?: Maybe<TripEntity>;
  tripItemId: Scalars["String"]["output"];
  tripViewId: Scalars["String"]["output"];
};

export type TripItemContextualCardsPrimer = {
  __typename?: "TripItemContextualCardsPrimer";
  placeHolder?: Maybe<Image>;
  tripItemId: Scalars["String"]["output"];
  tripViewId?: Maybe<Scalars["String"]["output"]>;
};

export type TripItemContextualCardsResponse = {
  __typename?: "TripItemContextualCardsResponse";
  cardsCarousel?: Maybe<TripContextualCardCarousel>;
  image: Image;
};

export interface TripItemInput {
  filter?: InputMaybe<Scalars["String"]["input"]>;
  segments?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripEntity?: InputMaybe<TripEntity>;
  tripItemId: Scalars["String"]["input"];
  tripViewId: Scalars["String"]["input"];
}

export type TripItemTemplateResponse = {
  __typename?: "TripItemTemplateResponse";
  template: Scalars["String"]["output"];
  /** @deprecated Use template field as string type instead of templateResponse as Template */
  templateResponse?: Maybe<Template>;
};

export type TripItemsResponse = {
  __typename?: "TripItemsResponse";
  /** @deprecated Use itemGroups instead */
  groups: Array<TripsItemsGroup>;
  itemGroups: Array<TripsUiItemsGroup>;
};

export interface TripItemsToCompareInput {
  tripId: Scalars["String"]["input"];
  tripItemIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
}

export type TripMapResponse = {
  __typename?: "TripMapResponse";
  floatingMapButton: TripsUiOpenMapFloatingActionButton;
  fullScreenMap: TripsUiFullScreenMap;
  staticMap: TripsUiStaticMap;
};

export type TripMutation = {
  __typename?: "TripMutation";
  acceptInvite: TripsViewResponse;
  cancel: TripsViewResponse;
  cancelActivity: TripsViewResponse;
  cancelCar: TripsViewResponse;
  cancelInsurance: TripsViewResponse;
  createTrip: TripsViewResponse;
  deleteTrip: TripsViewResponse;
  editTrip: TripsViewResponse;
  moveTripItem: TripsViewResponse;
  saveItemToTrip: TripsViewResponse;
  /** @deprecated saveStayToTrip mutation has been deprecated, replace with saveItemToTrip mutation */
  saveStayToTrip: TripsViewResponse;
  sendInvite: TripsViewResponse;
  sendItineraryEmail: TripsViewResponse;
  unsaveItemFromTrip: TripsViewResponse;
  /** @deprecated unsaveStayFromTrip mutation has been deprecated, replace with unsaveItemFromTrip mutation */
  unsaveStayFromTrip: TripsViewResponse;
  updateItemDates: TripsViewResponse;
  updateItemPriceAlertStatus: TripsMutationResponse;
};

export type TripMutationAcceptInviteArgs = {
  tripInvite: TripInviteInput;
};

export type TripMutationCancelArgs = {
  cancellationAttributes?: InputMaybe<TripsCancellationAttributesInput>;
  cancellationType: TripsCancellationType;
  item: TripItemInput;
};

export type TripMutationCancelActivityArgs = {
  item: TripItemInput;
  orderLineNumbers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  orderNumber: Scalars["String"]["input"];
};

export type TripMutationCancelCarArgs = {
  item: TripItemInput;
  orderLineNumbers: Array<Scalars["String"]["input"]>;
};

export type TripMutationCancelInsuranceArgs = {
  item: TripItemInput;
  orderLineNumber: Scalars["String"]["input"];
};

export type TripMutationCreateTripArgs = {
  inputs: Array<GraphQlPairInput>;
};

export type TripMutationDeleteTripArgs = {
  tripId: Scalars["String"]["input"];
};

export type TripMutationEditTripArgs = {
  inputs: Array<GraphQlPairInput>;
  overview: TripOverviewInput;
};

export type TripMutationMoveTripItemArgs = {
  inputs?: InputMaybe<Array<GraphQlPairInput>>;
  item: TripItemInput;
  toTripId?: InputMaybe<Scalars["String"]["input"]>;
  toTripName?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripMutationSaveItemToTripArgs = {
  itemInput: TripsPlanInput;
  newTripInputs?: InputMaybe<Array<GraphQlPairInput>>;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripMutationSaveStayToTripArgs = {
  inputs?: InputMaybe<Array<GraphQlPairInput>>;
  stayInput: TripsSaveStayInput;
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripMutationSendInviteArgs = {
  inputs: Array<GraphQlPairInput>;
  overview: TripOverviewInput;
};

export type TripMutationSendItineraryEmailArgs = {
  inputs: Array<GraphQlPairInput>;
  item: TripItemInput;
};

export type TripMutationUnsaveItemFromTripArgs = {
  tripEntity: TripEntity;
  tripItem: TripItemInput;
};

export type TripMutationUnsaveStayFromTripArgs = {
  tripItem: TripItemInput;
};

export type TripMutationUpdateItemDatesArgs = {
  dateRange: DateRangeInput;
  tripEntity: TripEntity;
  tripItem: TripItemInput;
};

export type TripMutationUpdateItemPriceAlertStatusArgs = {
  alertStatus: TripsToggleStatus;
  tripItem: TripItemInput;
};

export type TripOverview = {
  __typename?: "TripOverview";
  filter?: Maybe<Scalars["String"]["output"]>;
  inviteId?: Maybe<Scalars["String"]["output"]>;
  segments?: Maybe<Array<Scalars["String"]["output"]>>;
  tripViewId: Scalars["String"]["output"];
};

export interface TripOverviewInput {
  filter?: InputMaybe<Scalars["String"]["input"]>;
  inviteId?: InputMaybe<Scalars["String"]["input"]>;
  segments?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tripViewId: Scalars["String"]["input"];
}

export type TripRecommendation = {
  __typename?: "TripRecommendation";
  currencyCode?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  position?: Maybe<Scalars["String"]["output"]>;
  priceDisplayed: Scalars["String"]["output"];
};

export type TripRecommendationModule = {
  __typename?: "TripRecommendationModule";
  recommendations: Array<TripRecommendation>;
  responseId: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type TripSafetyButtonsSection = TripSafetyGuideDialogContentSection & {
  __typename?: "TripSafetyButtonsSection";
  buttons: Array<EgdsButton>;
  header: Scalars["String"]["output"];
};

export type TripSafetyGuideDialogContent = {
  __typename?: "TripSafetyGuideDialogContent";
  sections: Array<TripSafetyGuideDialogContentSection>;
};

export interface TripSafetyGuideDialogContentSection {
  header: Scalars["String"]["output"];
}

export type TripSafetyMessagingCardsSection = TripSafetyGuideDialogContentSection & {
  __typename?: "TripSafetyMessagingCardsSection";
  header: Scalars["String"]["output"];
  items: Array<EgdsStandardMessagingCard>;
};

export type TripSaveItemButton = UiPrimaryButton | UiSecondaryButton;

export type TripSaveItemButtonView = {
  __typename?: "TripSaveItemButtonView";
  button?: Maybe<TripSaveItemButton>;
};

export type TripSaveItemHeartView = {
  __typename?: "TripSaveItemHeartView";
  heartOutlineType?: Maybe<HeartOutlineType>;
};

export type TripSaveItemViewType = TripSaveItemButtonView | TripSaveItemHeartView;

export type TripScheduleChangeStatus = "FLIGHT_SCHEDULE_CHANGE" | "NO_CHANGE";

export type TripStage = "MID_TRAVEL" | "POST_TRAVEL" | "PRE_TRAVEL";

export type TripState = "CURRENT" | "PAST" | "POTENTIAL" | "UPCOMING";

export type TripSummaryContent = {
  __typename?: "TripSummaryContent";
  header: LodgingHeader;
  /** @deprecated Use installmentPlanOptions instead */
  installmentPlan?: Maybe<InstallmentPlan>;
  installmentPlanOptions?: Maybe<LodgingInstallmentPlanOptions>;
  price: PropertyPrice;
  summary: Array<LodgingEnrichedMessage>;
  tripSummaryHeaderBanner?: Maybe<EgdsStandardMessage>;
};

export type TripSummaryHeading = EgdsSectionHeading & {
  __typename?: "TripSummaryHeading";
  heading: EgdsHeading;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export interface TripViewIdInput {
  id: Scalars["String"]["input"];
  type: TripViewIdType;
}

export type TripViewIdType = "TRIP_ITEM_ID" | "TRIP_VIEW_ID";

export type Trips = {
  __typename?: "Trips";
  customer: TripsViewResponse;
  filtered: TripsViewResponse;
  findItineraryNumber: TripsViewResponse;
  hero: HomeResponse;
  important: TripsViewResponse;
  plan: TripsViewResponse;
  recentSearches: TripsRecentSearchesResponse;
  referAFriend?: Maybe<TripsReferAFriendTile>;
  saveItem?: Maybe<TripsSaveItemResponse>;
  screenshot: TripsViewResponse;
  searchBooking: TripsViewResponse;
};

export type TripsCustomerArgs = {
  isPrefetch?: InputMaybe<Scalars["Boolean"]["input"]>;
  segments?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type TripsFilteredArgs = {
  filter: TripsFilterInput;
};

export type TripsFindItineraryNumberArgs = {
  inputs?: InputMaybe<Array<GraphQlPairInput>>;
  viewType: TripsFindItineraryNumberView;
};

export type TripsPlanArgs = {
  input: TripsPlanInput;
};

export type TripsSaveItemArgs = {
  input: TripsSaveItemInput;
};

export type TripsScreenshotArgs = {
  input: TripsScreenshotInput;
};

export type TripsSearchBookingArgs = {
  searchInput?: InputMaybe<Array<GraphQlPairInput>>;
  viewType: TripsSearchBookingView;
};

export type TripsAcceptInviteAndNavigateToOverviewAction = TripsAction & {
  __typename?: "TripsAcceptInviteAndNavigateToOverviewAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  overview: TripOverview;
  overviewUrl?: Maybe<Scalars["URL"]["output"]>;
};

export type TripsAccessibilityData = AccessibilityData & {
  __typename?: "TripsAccessibilityData";
  hint?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export interface TripsAction {
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
}

export type TripsActionList = {
  __typename?: "TripsActionList";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionList: Array<TripsActionListItem>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsActionListItem = {
  __typename?: "TripsActionListItem";
  action: TripsNavigateToViewAction;
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsAddToCalendarAction = TripsAction & {
  __typename?: "TripsAddToCalendarAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  entries: Array<TripsAddToCalendarEventAttributes>;
  tripId: Scalars["String"]["output"];
};

export type TripsAddToCalendarEventAttributes = {
  __typename?: "TripsAddToCalendarEventAttributes";
  address?: Maybe<Scalars["String"]["output"]>;
  description: Array<Scalars["String"]["output"]>;
  endDateTime?: Maybe<Scalars["String"]["output"]>;
  eventTimeZone?: Maybe<Scalars["String"]["output"]>;
  productId?: Maybe<Scalars["String"]["output"]>;
  startDateTime: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type TripsAddToWalletAction = TripsAction & {
  __typename?: "TripsAddToWalletAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  pointOfSaleOrderReferenceNumber: Scalars["String"]["output"];
};

export type TripsAlbumCard = {
  __typename?: "TripsAlbumCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UiLinkAction;
  button?: Maybe<TripsUiButton>;
  icons?: Maybe<Array<TripsAlbumCardIcon>>;
  image?: Maybe<Image>;
  impressionTracking: ClientSideAnalytics;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsAlbumCardIcon = {
  __typename?: "TripsAlbumCardIcon";
  icons: Array<Icon>;
  label: Scalars["String"]["output"];
};

export type TripsAlbumCarousel = {
  __typename?: "TripsAlbumCarousel";
  carousel: TripCarousel;
  content: Array<TripsAlbumCard>;
  moreInfoLink?: Maybe<EgdsStandardLink>;
};

export type TripsAvatar = {
  __typename?: "TripsAvatar";
  name: Scalars["String"]["output"];
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type TripsAvatarGroup = TripsFlexContainerItem & {
  __typename?: "TripsAvatarGroup";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsNavigateToViewAction>;
  avatarSize: TripsAvatarSize;
  avatars: Array<TripsAvatar>;
  grow?: Maybe<Scalars["Float"]["output"]>;
  showBorder: Scalars["Boolean"]["output"];
};

export type TripsAvatarSize = "EXTRA_LARGE" | "LARGE" | "MEDIUM" | "SMALL";

export type TripsBadge = {
  __typename?: "TripsBadge";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  text?: Maybe<Scalars["String"]["output"]>;
  theme?: Maybe<TripsBadgeTheme>;
};

export type TripsBadgeTheme =
  | "LOYALTY_HIGH_TIER"
  | "LOYALTY_LOW_TIER"
  | "LOYALTY_MIDDLE_TIER"
  | "SAVED"
  | "STATUS_NEGATIVE"
  | "STATUS_POSITIVE"
  | "UTILITY_STANDARD"
  | "VIP";

export type TripsBookingInfoType = "CONFIRMATION_NUMBER" | "ITINERARY_NUMBER" | "RESERVATION_ID";

export type TripsBookingInformation = {
  __typename?: "TripsBookingInformation";
  accessibility: Scalars["String"]["output"];
  label: TripsBookingInfoType;
  value: Scalars["String"]["output"];
};

export type TripsBottomMenu = TripsMenu & {
  __typename?: "TripsBottomMenu";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  items: Array<TripsMenuItem>;
  menuItems: Array<TripsMenuItem>;
  menuTitle?: Maybe<Scalars["String"]["output"]>;
};

export type TripsBottomSheet = {
  __typename?: "TripsBottomSheet";
  elements: Array<TripsTertiaryButton>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export interface TripsButton {
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  width: TripsButtonWidth;
}

export type TripsButtonWidth = "MATCH_PARENT_CONTAINER" | "WRAP_CONTENT";

export type TripsCalendarSelectionType = "SINGLE" | "START_AND_END" | "START_AND_END_WITH_SAME_DATE";

export type TripsCancelActivityAction = TripsAction & {
  __typename?: "TripsCancelActivityAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  orderLineNumbers?: Maybe<Array<Scalars["String"]["output"]>>;
  orderNumber: Scalars["String"]["output"];
};

export type TripsCancelAssuranceAction = TripsAction & {
  __typename?: "TripsCancelAssuranceAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  orderLineNumber: Scalars["String"]["output"];
};

export type TripsCancelCarAction = TripsAction & {
  __typename?: "TripsCancelCarAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  orderLineNumbers: Array<Scalars["String"]["output"]>;
};

export type TripsCancelFlightAction = TripsAction & {
  __typename?: "TripsCancelFlightAction";
  airRecordLocator: Scalars["String"]["output"];
  airlineConfirmationCode: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  tripNumber: Scalars["String"]["output"];
  userToken: Scalars["String"]["output"];
};

export type TripsCancelInsuranceAction = TripsAction & {
  __typename?: "TripsCancelInsuranceAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  orderLineNumber: Scalars["String"]["output"];
};

export type TripsCancellationAction = TripsAction & {
  __typename?: "TripsCancellationAction";
  analytics: ClientSideAnalytics;
  cancellationAttributes?: Maybe<TripsCancellationAttributes>;
  cancellationType: TripsCancellationType;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item?: Maybe<TripItem>;
  /** @deprecated Deprecated, please use `cancellationAttributes` instead */
  orderLineNumbers?: Maybe<Array<Scalars["String"]["output"]>>;
  /** @deprecated Deprecated, please use `cancellationAttributes` instead */
  orderNumber?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCancellationAttributes = {
  __typename?: "TripsCancellationAttributes";
  orderLineNumbers?: Maybe<Array<Scalars["String"]["output"]>>;
  orderNumber?: Maybe<Scalars["String"]["output"]>;
  penaltyAmount?: Maybe<Scalars["String"]["output"]>;
  refundAmount?: Maybe<Scalars["String"]["output"]>;
  refundInCash?: Maybe<Scalars["String"]["output"]>;
  refundInPoints?: Maybe<Scalars["String"]["output"]>;
};

export interface TripsCancellationAttributesInput {
  orderLineNumbers?: InputMaybe<Array<Scalars["String"]["input"]>>;
  orderNumber?: InputMaybe<Scalars["String"]["input"]>;
  penaltyAmount?: InputMaybe<Scalars["String"]["input"]>;
  refundAmount?: InputMaybe<Scalars["String"]["input"]>;
  refundInCash?: InputMaybe<Scalars["String"]["input"]>;
  refundInPoints?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsCancellationTimelineFailureResponse = TripsCancellationTimelineResponse & {
  __typename?: "TripsCancellationTimelineFailureResponse";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  bodyText: Array<Scalars["String"]["output"]>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primaryButton: TripsUiButton;
};

export type TripsCancellationTimelineIdType = "TRIP_ITEM_ID";

export interface TripsCancellationTimelineResponse {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  bodyText: Array<Scalars["String"]["output"]>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primaryButton: TripsUiButton;
}

export type TripsCancellationTimelineSuccessResponse = TripsCancellationTimelineResponse & {
  __typename?: "TripsCancellationTimelineSuccessResponse";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  bodyText: Array<Scalars["String"]["output"]>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primaryButton: TripsUiButton;
  stepIndicatorHorizontal?: Maybe<TripsUiStepIndicatorHorizontal>;
  title: Scalars["String"]["output"];
};

export type TripsCancellationType = "ACTIVITY" | "CAR" | "CRUISE" | "HOTEL" | "INSURANCE";

export interface TripsCard {
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
}

export type TripsCardState = "SELECTED" | "UNSELECTED";

export type TripsCarouselAccessibilityData = AccessibilityData & {
  __typename?: "TripsCarouselAccessibilityData";
  hint?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  nextButton?: Maybe<Scalars["String"]["output"]>;
  prevButton?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCarouselContainer = TripsContainer & {
  __typename?: "TripsCarouselContainer";
  accessibility?: Maybe<AccessibilityData>;
  elements: Array<TripsComposableElement>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  subheading?: Maybe<TripsCarouselSubHeader>;
};

export type TripsCarouselSubHeader = EgdsGraphicText | EgdsPlainText;

export type TripsChangeItemDatesAction = TripsAction & {
  __typename?: "TripsChangeItemDatesAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  tripEntity: TripEntity;
};

export type TripsChangeSaveItemState = {
  __typename?: "TripsChangeSaveItemState";
  analytics: ClientSideAnalytics;
  toggleState: Scalars["Boolean"]["output"];
  tripItemId: Scalars["String"]["output"];
};

export type TripsCloseDialogAction = TripsAction & {
  __typename?: "TripsCloseDialogAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsCloseDialogButton = {
  __typename?: "TripsCloseDialogButton";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsCloseDialogAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCoachmark = {
  __typename?: "TripsCoachmark";
  body?: Maybe<Array<Scalars["String"]["output"]>>;
  button?: Maybe<UiPrimaryButton>;
  dismissEventAnalytics?: Maybe<ClientSideAnalytics>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCollection = {
  __typename?: "TripsCollection";
  tripsAlbumCarousel?: Maybe<TripsAlbumCarousel>;
};

export type TripsComposableDrawer = TripsDrawer & {
  __typename?: "TripsComposableDrawer";
  customerNotificationBanner?: Maybe<TripsCustomerNotificationQueryParameters>;
  elements: Array<TripsComposableElement>;
  /** @deprecated header has been deprecated in favour of tripsViewHeader */
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  tripsViewHeader?: Maybe<TripsViewHeader>;
  type?: Maybe<TripsComposableDrawerType>;
};

export type TripsComposableDrawerType = "BOTTOM" | "CENTER" | "FULL" | "SIDE";

export type TripsComposableElement =
  | EventRecommendationPrimer
  | TripItemContextualCardsPrimer
  | TripsAvatarGroup
  | TripsCarouselContainer
  | TripsContainerDivider
  | TripsContentCard
  | TripsEmbeddedContentCard
  | TripsFittedImageCard
  | TripsFlexContainer
  | TripsFlightPathMapCard
  | TripsForm
  | TripsFormContainer
  | TripsFullBleedImageCard
  | TripsIllustrationCard
  | TripsImageSlimCard
  | TripsImageTopCard
  | TripsItemRecallCard
  | TripsLodgingUpgradesPrimer
  | TripsMapCard
  | TripsMediaGallery
  | TripsOverlayButton
  | TripsPageBreak
  | TripsPriceAlertSwitchCard
  | TripsPricePresentation
  | TripsPrimaryButton
  | TripsReviewsCarouselPrimer
  | TripsSecondaryButton
  | TripsSectionContainer
  | TripsServiceRequestsButtonPrimer
  | TripsSlimCard
  | TripsSlimCardContainer
  | TripsStepIndicatorStep
  | TripsStepIndicatorVertical
  | TripsTertiaryButton
  | TripsValidatedInput
  | TripsWishlistPrimer;

export interface TripsContainer {
  elements: Array<TripsComposableElement>;
}

export type TripsContainerDivider = {
  __typename?: "TripsContainerDivider";
  divider: TripsDivider;
};

export interface TripsContent {
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
}

export type TripsContentCard = TripsCard & {
  __typename?: "TripsContentCard";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  rows: Array<TripsContent>;
  /** @deprecated Do not request, will always be null. */
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsContentColumns = TripsContent & {
  __typename?: "TripsContentColumns";
  columns: Array<TripsContent>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsContentInlineLinkItem = {
  __typename?: "TripsContentInlineLinkItem";
  items: Array<TripsContentItem>;
};

export type TripsContentItem = TripsContentInlineLinkItem | TripsContentLinkItem | TripsContentTextItem;

export type TripsContentLinkItem = {
  __typename?: "TripsContentLinkItem";
  /**
   * @deprecated `action` of type TripsLinkAction has been deprecated. Use `linkAction` instead.
   *          TripContentLinkItem now supports any action conforming to TripsAction.
   */
  action: TripsLinkAction;
  icon?: Maybe<Icon>;
  linkAction: TripsAction;
  linkText: Scalars["String"]["output"];
};

export type TripsContentList = TripsContent & {
  __typename?: "TripsContentList";
  items?: Maybe<Array<TripsViewContentLineItem>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  theme: TripsContentTheme;
};

export type TripsContentOrderedList = TripsContent & {
  __typename?: "TripsContentOrderedList";
  items?: Maybe<Array<TripsContentItem>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsContentParagraph = TripsContent & {
  __typename?: "TripsContentParagraph";
  items?: Maybe<Array<TripsContentItem>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsContentTextItem = {
  __typename?: "TripsContentTextItem";
  action?: Maybe<TripsAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsContentTheme = "BULLETS" | "NO_BULLETS" | "ORDERED" | "PARAGRAPH";

export type TripsContentType = "PDF";

export type TripsContentUnorderedList = TripsContent & {
  __typename?: "TripsContentUnorderedList";
  items?: Maybe<Array<TripsContentItem>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  theme?: Maybe<TripsContentUnorderedListTheme>;
};

export type TripsContentUnorderedListTheme = "BULLETS" | "NO_BULLETS";

export type TripsContextualMessagingCard = {
  __typename?: "TripsContextualMessagingCard";
  actions?: Maybe<Array<TripsContextualMessagingCardAction>>;
  card: TripsMessagingCard;
};

export type TripsContextualMessagingCardAction = TripsUiTextLinkAction | TripsVaInitAction;

export type TripsCreateEmptyTrip = {
  __typename?: "TripsCreateEmptyTrip";
  metadata?: Maybe<TripCreationMetadata>;
};

export type TripsCreateNewTripAction = TripsAction & {
  __typename?: "TripsCreateNewTripAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  itemId: Scalars["String"]["output"];
};

export type TripsCreateTripFromItem = {
  __typename?: "TripsCreateTripFromItem";
  input: TripsPlan;
};

export type TripsCreateTripFromItemAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsCreateTripFromItemAction";
    analytics: ClientSideAnalytics;
    enabledForOfflineView: Scalars["Boolean"]["output"];
    inputIds: Array<Scalars["String"]["output"]>;
    /** @deprecated itemId has been deprecated., replace with saveItemInput.itemId */
    itemId: Scalars["String"]["output"];
    saveItemInput?: Maybe<TripsPlan>;
  };

export type TripsCreateTripFromMovedItem = {
  __typename?: "TripsCreateTripFromMovedItem";
  item: TripItem;
};

export type TripsCustomerNotificationOpenInAppAction = TripsAction & {
  __typename?: "TripsCustomerNotificationOpenInAppAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  notificationAttributes: TripsCustomerNotificationQueryParameters;
};

export type TripsCustomerNotificationOptionalContext = {
  __typename?: "TripsCustomerNotificationOptionalContext";
  itineraryNumber?: Maybe<Scalars["String"]["output"]>;
  journeyCriterias?: Maybe<Array<TripsCustomerNotificationOptionalContextJourneyCriteria>>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionIds?: Maybe<Array<Scalars["String"]["output"]>>;
  tripId?: Maybe<Scalars["String"]["output"]>;
  tripItemId?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCustomerNotificationOptionalContextJourneyCriteria = {
  __typename?: "TripsCustomerNotificationOptionalContextJourneyCriteria";
  dateRange?: Maybe<DateRange>;
  destination?: Maybe<TripsCustomerNotificationOptionalContextJourneyCriteriaLocation>;
  origin?: Maybe<TripsCustomerNotificationOptionalContextJourneyCriteriaLocation>;
  tripScheduleChangeStatus?: Maybe<TripsCustomerNotificationTripScheduleChangeStatus>;
  tripsData?: Maybe<TripsData>;
};

export type TripsCustomerNotificationOptionalContextJourneyCriteriaLocation = {
  __typename?: "TripsCustomerNotificationOptionalContextJourneyCriteriaLocation";
  airportTLA?: Maybe<Scalars["String"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCustomerNotificationQueryParameters = {
  __typename?: "TripsCustomerNotificationQueryParameters";
  funnelLocation?: Maybe<Scalars["String"]["output"]>;
  lineOfBusiness?: Maybe<Scalars["String"]["output"]>;
  notificationLocation?: Maybe<Scalars["String"]["output"]>;
  optionalContext?: Maybe<TripsCustomerNotificationOptionalContext>;
  xPageID?: Maybe<Scalars["String"]["output"]>;
};

export type TripsCustomerNotificationTripScheduleChangeStatus = "FLIGHT_SCHEDULE_CHANGE" | "NO_CHANGE";

export type TripsCustomerSupportAction = TripsVaInitAction | UiLinkAction;

export type TripsCustomerSupportCta = {
  __typename?: "TripsCustomerSupportCTA";
  action: TripsCustomerSupportAction;
  button: TripsUiSecondaryButton;
};

export type TripsCustomerSupportContent = {
  __typename?: "TripsCustomerSupportContent";
  bookingInformation?: Maybe<Array<TripsBookingInformation>>;
  supportCTAs: Array<TripsCustomerSupportCta>;
};

export type TripsCustomerSupportFailureResponse = TripsCustomerSupportResponse & {
  __typename?: "TripsCustomerSupportFailureResponse";
  accessibility: Scalars["String"]["output"];
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  refreshButton: TripsUiRefreshAction;
  text: Scalars["String"]["output"];
};

export interface TripsCustomerSupportResponse {
  accessibility: Scalars["String"]["output"];
  impressionAnalytics: ClientSideImpressionEventAnalytics;
}

export type TripsData = {
  __typename?: "TripsData";
  flightProduct?: Maybe<FlightProduct>;
};

export interface TripsDataInput {
  flightProduct?: InputMaybe<FlightProductInput>;
}

export type TripsDatePickerAnalytics = {
  __typename?: "TripsDatePickerAnalytics";
  closeAnalytics: ClientSideAnalytics;
  nextPageAnalytics: ClientSideAnalytics;
  previousPageAnalytics: ClientSideAnalytics;
};

export type TripsDatePickerAttributes = {
  __typename?: "TripsDatePickerAttributes";
  analytics: TripsDatePickerAnalytics;
  buttonText: Scalars["String"]["output"];
  calendarSelectionType: TripsCalendarSelectionType;
  changeDatesAction: TripsChangeItemDatesAction;
  daysBookableInAdvance: Scalars["Int"]["output"];
  itemDates: DateRange;
  maxDateRange: Scalars["Int"]["output"];
  maxDateRangeMessage?: Maybe<Scalars["String"]["output"]>;
  midnightBookingOffset?: Maybe<Scalars["Int"]["output"]>;
  minDateRange: Scalars["Int"]["output"];
  productId?: Maybe<Scalars["String"]["output"]>;
  tripEntity: TripEntity;
};

export type TripsDeleteTripAction = TripsAction & {
  __typename?: "TripsDeleteTripAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  overview: TripOverview;
};

export interface TripsDialog {
  body: Array<TripsContent>;
  heading?: Maybe<Scalars["String"]["output"]>;
}

export type TripsDialogButtonLayout = "INLINE" | "STACKED";

export type TripsDismissAction = TripsAction & {
  __typename?: "TripsDismissAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsDismissDrawerAction = TripsAction & {
  __typename?: "TripsDismissDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsDisplayMode = "DEFAULT" | "MODAL" | "NEW_TAB";

export type TripsDivider = "NARROW" | "WIDE";

export interface TripsDrawer {
  elements: Array<TripsComposableElement>;
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
}

export type TripsDrawerHeader = {
  __typename?: "TripsDrawerHeader";
  action?: Maybe<TripsTertiaryButton>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsEmbeddedContentCard = TripsCard &
  TripsFlexContainerItem & {
    __typename?: "TripsEmbeddedContentCard";
    grow?: Maybe<Scalars["Float"]["output"]>;
    impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
    items: Array<TripsEmbeddedContentList>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export type TripsEmbeddedContentItem = TripsSignalListener & {
  __typename?: "TripsEmbeddedContentItem";
  action?: Maybe<TripsLinkAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
  signal?: Maybe<TripsSignal>;
};

export type TripsEmbeddedContentLineItem = {
  __typename?: "TripsEmbeddedContentLineItem";
  items: Array<TripsEmbeddedContentItem>;
};

export type TripsEmbeddedContentList = TripsContent & {
  __typename?: "TripsEmbeddedContentList";
  items?: Maybe<Array<TripsEmbeddedContentLineItem>>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  theme: TripsContentTheme;
};

export type TripsEmblem =
  | EgdsBadgeNotification
  | EgdsLoyaltyBadge
  | EgdsProgramBadge
  | EgdsStandardBadge
  | Mark
  | TripsBadge;

export type TripsEmblemsInlineContent = TripsContent & {
  __typename?: "TripsEmblemsInlineContent";
  emblems: Array<TripsEmblem>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsEmitSignal = {
  __typename?: "TripsEmitSignal";
  signal: TripsSignal;
  values: Array<TripsSignalPair>;
};

export type TripsExploreItinerariesAction = TripsAction & {
  __typename?: "TripsExploreItinerariesAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsFilter = {
  __typename?: "TripsFilter";
  filter: Scalars["String"]["output"];
};

export interface TripsFilterInput {
  filter: Scalars["String"]["input"];
}

export type TripsFindItineraryNumberView = "FIND_ITINERARY_OTHER_EMAIL" | "FIND_ITINERARY_USER_EMAIL" | "RESULT";

export type TripsFittedImageCard = TripsCard & {
  __typename?: "TripsFittedImageCard";
  accessibility?: Maybe<AccessibilityData>;
  image: Image;
  imageType?: Maybe<TripsFittedImageType>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsFittedImageType = "BARCODE" | "E_FRANKING_STAMP" | "QR_CODE";

export type TripsFlexContainer = {
  __typename?: "TripsFlexContainer";
  alignItems: FlexAlignItems;
  direction: FlexDirection;
  elements: Array<TripsFlexContainerItem>;
  justifyContent: FlexJustifyContent;
  wrap: FlexWrap;
};

export interface TripsFlexContainerItem {
  grow?: Maybe<Scalars["Float"]["output"]>;
}

export type TripsFlightPath = {
  __typename?: "TripsFlightPath";
  coordinates: Array<Coordinates>;
};

export type TripsFlightPathMapCard = TripsCard & {
  __typename?: "TripsFlightPathMapCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  data: TripsFlightPathMapData;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsFlightPathMapData = {
  __typename?: "TripsFlightPathMapData";
  paths: Array<TripsFlightPath>;
};

export type TripsFloatingActionButton = {
  __typename?: "TripsFloatingActionButton";
  action?: Maybe<TripsAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsFloatingActionDrawer = TripsDrawer & {
  __typename?: "TripsFloatingActionDrawer";
  elements: Array<TripsComposableElement>;
  floatingButton?: Maybe<TripsFloatingButton>;
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
};

export type TripsFloatingButton = {
  __typename?: "TripsFloatingButton";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsFloatingMenu = TripsMenu & {
  __typename?: "TripsFloatingMenu";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  items: Array<TripsMenuItem>;
  menuItems: Array<TripsMenuItem>;
  menuTitle?: Maybe<Scalars["String"]["output"]>;
};

export type TripsForOfflineViewFailure = {
  __typename?: "TripsForOfflineViewFailure";
  error: Scalars["String"]["output"];
  maxRetryCount: Scalars["Int"]["output"];
};

export type TripsForOfflineViewResponse = TripsForOfflineViewFailure | TripsForOfflineViewSuccess;

export type TripsForOfflineViewSuccess = {
  __typename?: "TripsForOfflineViewSuccess";
  bookedTrips: Array<OfflineTrip>;
  clientSideImpressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsForm = {
  __typename?: "TripsForm";
  elements: Array<TripsFormElement>;
};

export type TripsFormAction = TripsAction & {
  __typename?: "TripsFormAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  formData?: Maybe<TripsFormData>;
  type: TripsFormType;
  validatedInputAnalytics?: Maybe<Array<TripsValidatedInputAnalytics>>;
  validatedInputIds: Array<Scalars["String"]["output"]>;
};

export type TripsFormContainer = TripsContainer & {
  __typename?: "TripsFormContainer";
  elements: Array<TripsComposableElement>;
  formTheme?: Maybe<TripsFormContainerTheme>;
};

export type TripsFormContainerTheme = "MATCH_PARENT" | "WRAP_CONTENT";

export type TripsFormData =
  | TripsCreateEmptyTrip
  | TripsCreateTripFromItem
  | TripsCreateTripFromMovedItem
  | TripsInvite
  | TripsSendItineraryEmail
  | TripsUpdateTrip;

export type TripsFormElement = TripsContentTextItem | TripsPrimaryButton | TripsSecondaryButton | TripsValidatedInput;

export interface TripsFormSubmitAction {
  inputIds: Array<Scalars["String"]["output"]>;
}

export type TripsFormType =
  | "CREATE_NEW_TRIP_FROM_ITEM"
  | "FIND_ITINERARY_NUMBER"
  | "INVITE"
  | "SAVE_NEW_TRIP"
  | "SEARCH_BOOKING"
  | "SEND_ITINERARY_EMAIL"
  | "UPDATE_TRIP";

export type TripsFullBleedImageCard = TripsCard & {
  __typename?: "TripsFullBleedImageCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  background?: Maybe<Image>;
  badgeList?: Maybe<Array<EgdsBadge>>;
  /** @deprecated TripsBadge field has been deprecated. Rather use new EGDS Badges., replace with badgeList */
  badges?: Maybe<Array<TripsBadge>>;
  enrichedSecondaries?: Maybe<Array<TripsGraphicTextGroup>>;
  icons?: Maybe<Array<Icon>>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  inviteButton?: Maybe<TripsOverlayButton>;
  primary?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated secondaries field has been deprecated. Rather use enrichedSecondaries, replace with enrichedSecondaries */
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  tripsViewContentItem?: Maybe<TripsViewContentItem>;
};

export type TripsFullScreenDialog = {
  __typename?: "TripsFullScreenDialog";
  closeButton?: Maybe<TripsCloseDialogButton>;
  content?: Maybe<Array<TripsEmbeddedContentCard>>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsGraphicTextGroup = {
  __typename?: "TripsGraphicTextGroup";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  items?: Maybe<Array<TripsText>>;
};

export type TripsIcon = {
  __typename?: "TripsIcon";
  action?: Maybe<TripsAction>;
  icon?: Maybe<Icon>;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type TripsIconToggle = TripsSignalListener & {
  __typename?: "TripsIconToggle";
  disabledIcon?: Maybe<TripsIcon>;
  enabledIcon?: Maybe<TripsIcon>;
  impressionAnalytics?: Maybe<ClientSideImpressionAnalytics>;
  initialStatus: TripsToggleStatus;
  signal?: Maybe<TripsSignal>;
};

export type TripsIllustrationCard = TripsCard & {
  __typename?: "TripsIllustrationCard";
  illustration?: Maybe<Illustration>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsImageActionToast = TripsToast & {
  __typename?: "TripsImageActionToast";
  accessibility?: Maybe<AccessibilityData>;
  button: TripsTertiaryButton;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  link?: Maybe<EgdsStandardLink>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  thumbnail?: Maybe<Image>;
};

export type TripsImageSlimCard = TripsCard &
  TripsSignalListener & {
    __typename?: "TripsImageSlimCard";
    accessibility?: Maybe<AccessibilityData>;
    badgeList?: Maybe<Array<EgdsBadge>>;
    /** @deprecated TripsBadge field has been deprecated. Rather use new EGDS Badges., replace with badgeList */
    badges?: Maybe<Array<TripsBadge>>;
    cardIcon?: Maybe<TripsIcon>;
    /** @deprecated TripsImageSlimCard icon field has been deprecated. Rather use action associated with cardIcon., replace with cardIcon */
    icon?: Maybe<Icon>;
    impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
    itemPricePrimer?: Maybe<TripsItemPricePrimer>;
    priceAlertToggle?: Maybe<TripsIconToggle>;
    primary?: Maybe<Scalars["String"]["output"]>;
    primaryAction?: Maybe<TripsAction>;
    secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
    secondaryAction?: Maybe<TripsAction>;
    signal?: Maybe<TripsSignal>;
    state?: Maybe<TripsCardState>;
    thumbnail?: Maybe<Image>;
  };

export type TripsImageTopCard = TripsCard & {
  __typename?: "TripsImageTopCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  background?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsInfoToast = TripsToast & {
  __typename?: "TripsInfoToast";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"]["output"];
};

export type TripsInlineActionToast = TripsToast & {
  __typename?: "TripsInlineActionToast";
  button: TripsButton;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"]["output"];
};

export type TripsInlineContent = {
  __typename?: "TripsInlineContent";
  items: Array<TripsViewContentItem>;
};

export type TripsInlinedActionsDialog = TripsDialog & {
  __typename?: "TripsInlinedActionsDialog";
  body: Array<TripsContent>;
  buttons: Array<TripsButton>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsInlinedActionsDrawer = TripsDrawer & {
  __typename?: "TripsInlinedActionsDrawer";
  buttons: Array<TripsButton>;
  elements: Array<TripsComposableElement>;
  header?: Maybe<TripsDrawerHeader>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
};

export type TripsInputType = "MULTIPLE" | "SINGLE";

export type TripsInvite = {
  __typename?: "TripsInvite";
  overview: TripOverview;
};

export type TripsInviteAcceptAction = TripsAction & {
  __typename?: "TripsInviteAcceptAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  inviteId: Scalars["String"]["output"];
};

export type TripsInviteAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsInviteAction";
    analytics: ClientSideAnalytics;
    enabledForOfflineView: Scalars["Boolean"]["output"];
    inputIds: Array<Scalars["String"]["output"]>;
    overview: TripOverview;
  };

export type TripsItemCard =
  | TripsPlannedItemCard
  | TripsUiBookableItineraryItemCard
  | TripsUiBookedItemCard
  | TripsUiCanceledItemCard
  | TripsUiItineraryItemCard;

export type TripsItemCardMedia = Image | TripsUiItemCardMediaGalleryImage;

export type TripsItemPricePrimer = TripsSavedItemPricePrimer;

export type TripsItemRecallCard = TripsCard & {
  __typename?: "TripsItemRecallCard";
  accessibility?: Maybe<AccessibilityData>;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsItemsGroup = {
  __typename?: "TripsItemsGroup";
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  button?: Maybe<TripsUiButton>;
  buttonAction?: Maybe<UiLinkAction>;
  heading?: Maybe<Scalars["String"]["output"]>;
  messages?: Maybe<Array<Scalars["String"]["output"]>>;
  sections?: Maybe<Array<TripsItemsSection>>;
};

export interface TripsItemsGroupFilterInput {
  type: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export interface TripsItemsGroupInput {
  filters: Array<TripsItemsGroupFilterInput>;
  sort?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsItemsSection = {
  __typename?: "TripsItemsSection";
  actionArea?: Maybe<TripsUiItemsSectionActionArea>;
  cards: Array<TripsItemCard>;
  gridLayoutEnabled?: Maybe<Scalars["Boolean"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsLinkAction = TripsAction & {
  __typename?: "TripsLinkAction";
  analytics: ClientSideAnalytics;
  clickstreamAnalytics?: Maybe<ClickstreamAnalytics>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  resource: Uri;
  target: TripsLinkTarget;
};

export type TripsLinkTarget = "EXTERNAL" | "INTERNAL";

export type TripsLoaderDialog = TripsDialog & {
  __typename?: "TripsLoaderDialog";
  body: Array<TripsContent>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsLodgingUpgradesPrimer = {
  __typename?: "TripsLodgingUpgradesPrimer";
  itineraryNumber?: Maybe<Scalars["String"]["output"]>;
};

export type TripsMap = {
  __typename?: "TripsMap";
  bounds?: Maybe<TripsMapBounds>;
  locale: Scalars["String"]["output"];
  mapCenter: TripsMapCenter;
  markers: Array<TripsMapMarker>;
  paths: Array<TripsFlightPathMapData>;
};

export type TripsMapAction = TripsAction & {
  __typename?: "TripsMapAction";
  analytics: ClientSideAnalytics;
  data: TripsMapData;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsMapBounds = {
  __typename?: "TripsMapBounds";
  ne: Coordinates;
  sw: Coordinates;
};

export type TripsMapCard = TripsCard & {
  __typename?: "TripsMapCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  data: TripsMapData;
  image?: Maybe<Image>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsMapCenter = {
  __typename?: "TripsMapCenter";
  center: Coordinates;
  zoom?: Maybe<Scalars["Int"]["output"]>;
};

export type TripsMapData = {
  __typename?: "TripsMapData";
  center: Coordinates;
  zoom: Scalars["Int"]["output"];
};

export type TripsMapDirectionsAction = TripsAction & {
  __typename?: "TripsMapDirectionsAction";
  analytics: ClientSideAnalytics;
  data: TripsMapData;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  url: Scalars["URL"]["output"];
};

export type TripsMapMarker = {
  __typename?: "TripsMapMarker";
  analytics?: Maybe<ClientSideAnalytics>;
  content?: Maybe<TripsMarkerContent>;
  coordinates: Coordinates;
  label: Scalars["String"]["output"];
  type?: Maybe<TripsMarkerType>;
};

export type TripsMarkerContent = {
  __typename?: "TripsMarkerContent";
  description?: Maybe<Scalars["String"]["output"]>;
  image?: Maybe<Image>;
  primary: Scalars["String"]["output"];
};

export type TripsMarkerType =
  | "ActivityEventMarker"
  | "ActivityGuestPickupMarker"
  | "ActivityLocationMarker"
  | "ActivityRedemptionMarker"
  | "AirportMarker"
  | "CarMarker"
  | "CruiseMarker"
  | "HotelMarker"
  | "PlaceMarker";

export type TripsMedia = MediaItem & {
  __typename?: "TripsMedia";
  accessibilityData?: Maybe<AccessibilityData>;
  media: Media;
};

export type TripsMediaGallery = EgdsElement &
  EgdsGalleryCarousel & {
    __typename?: "TripsMediaGallery";
    accessibilityHeadingText: Scalars["String"]["output"];
    analytics?: Maybe<ClientSideAnalytics>;
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    media: Array<TripsMedia>;
    mediaGalleryDialogToolbar: TripsToolbar;
    nextButtonText?: Maybe<Scalars["String"]["output"]>;
    previousButtonText?: Maybe<Scalars["String"]["output"]>;
  };

export interface TripsMenu {
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  items: Array<TripsMenuItem>;
  /** @deprecated TripsMenu menuItems field has been deprecated. Rather use items., replace with items */
  menuItems: Array<TripsMenuItem>;
  /** @deprecated TripsMenu menuTitle field has been deprecated. See TripsMenuTitle as a menu item., replace with TripsMenuTitle */
  menuTitle?: Maybe<Scalars["String"]["output"]>;
}

export interface TripsMenuItem {
  primary: Scalars["String"]["output"];
  /** @deprecated TripsMenuItem text field has been deprecated., replace with primary */
  text: Scalars["String"]["output"];
}

export type TripsMenuItemToggle = TripsMenuItem &
  TripsSignalListener & {
    __typename?: "TripsMenuItemToggle";
    disabledMenuItem?: Maybe<TripsMenuListItem>;
    enabledMenuItem?: Maybe<TripsMenuListItem>;
    initialStatus: TripsToggleStatus;
    primary: Scalars["String"]["output"];
    signal?: Maybe<TripsSignal>;
    text: Scalars["String"]["output"];
  };

export type TripsMenuListItem = TripsMenuItem & {
  __typename?: "TripsMenuListItem";
  accessibility: AccessibilityData;
  action: TripsAction;
  icon?: Maybe<Icon>;
  paymentType?: Maybe<Mark>;
  primary: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type TripsMenuListTitle = TripsMenuItem & {
  __typename?: "TripsMenuListTitle";
  primary: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type TripsMenuTitle = TripsMenuItem & {
  __typename?: "TripsMenuTitle";
  primary: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type TripsMessagingCard = {
  __typename?: "TripsMessagingCard";
  accessibility: Scalars["String"]["output"];
  analytics?: Maybe<ClientSideAnalytics>;
  background?: Maybe<BackgroundType>;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
  trailingGraphic?: Maybe<UiGraphic>;
};

export type TripsMessagingLinkCard = {
  __typename?: "TripsMessagingLinkCard";
  heading?: Maybe<Scalars["String"]["output"]>;
  link: EgdsStandardLink;
  message: Scalars["String"]["output"];
  rightIcon?: Maybe<Icon>;
};

export type TripsModalDialog = {
  __typename?: "TripsModalDialog";
  buttonLayout: TripsDialogButtonLayout;
  buttons?: Maybe<Array<TripsButton>>;
  content?: Maybe<Array<TripsEmbeddedContentList>>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsMoveItemToTripAction = TripsAction & {
  __typename?: "TripsMoveItemToTripAction";
  analytics: ClientSideAnalytics;
  data: TripsMoveTripItemData;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsMoveTripItemData = {
  __typename?: "TripsMoveTripItemData";
  item: TripItem;
  toTripId?: Maybe<Scalars["String"]["output"]>;
  toTripName?: Maybe<Scalars["String"]["output"]>;
};

export type TripsMultiSectionDialog = {
  __typename?: "TripsMultiSectionDialog";
  accessibility: TripsAccessibilityData;
  sections: Array<TripsMultiSectionDialogSection>;
};

export type TripsMultiSectionDialogSection = {
  __typename?: "TripsMultiSectionDialogSection";
  buttonLayout: TripsDialogButtonLayout;
  buttons?: Maybe<Array<TripsButton>>;
  content?: Maybe<Array<TripsEmbeddedContentList>>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsMutationActionFailureResponse = TripsMutationActionResponse & {
  __typename?: "TripsMutationActionFailureResponse";
  action: TripsAction;
};

export interface TripsMutationActionResponse {
  action: TripsAction;
}

export type TripsMutationActionSuccessResponse = TripsMutationActionResponse & {
  __typename?: "TripsMutationActionSuccessResponse";
  action: TripsAction;
};

export type TripsMutationResponse =
  | TripsMutationActionFailureResponse
  | TripsMutationActionSuccessResponse
  | TripsMutationToastFailureResponse
  | TripsMutationToastSuccessResponse;

export type TripsMutationToastFailureResponse = TripsMutationToastResponse & {
  __typename?: "TripsMutationToastFailureResponse";
  toast: TripsToast;
};

export interface TripsMutationToastResponse {
  toast: TripsToast;
}

export type TripsMutationToastSuccessResponse = TripsMutationToastResponse & {
  __typename?: "TripsMutationToastSuccessResponse";
  action?: Maybe<TripsAction>;
  toast: TripsToast;
};

export type TripsNavigateToFilteredViewAction = TripsAction & {
  __typename?: "TripsNavigateToFilteredViewAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  filter: TripsFilter;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToItemDetailsAction = TripsAction & {
  __typename?: "TripsNavigateToItemDetailsAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToItemPricingAndRewardsAction = TripsAction & {
  __typename?: "TripsNavigateToItemPricingAndRewardsAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToItemReceiptAction = TripsAction & {
  __typename?: "TripsNavigateToItemReceiptAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToItemVoucherAction = TripsAction & {
  __typename?: "TripsNavigateToItemVoucherAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToListViewAction = TripsAction & {
  __typename?: "TripsNavigateToListViewAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  listViewId: Scalars["String"]["output"];
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToManageBookingAction = TripsAction & {
  __typename?: "TripsNavigateToManageBookingAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToManageTripAction = TripsAction & {
  __typename?: "TripsNavigateToManageTripAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  tripViewId: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type TripsNavigateToTripOverviewAction = TripsAction & {
  __typename?: "TripsNavigateToTripOverviewAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  overview: TripOverview;
  url: Scalars["URL"]["output"];
};

export type TripsNavigateToViewAction = TripsAction & {
  __typename?: "TripsNavigateToViewAction";
  analytics: ClientSideAnalytics;
  displayMode: TripsDisplayMode;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  essentialInfoItem?: Maybe<EssentialInfoItem>;
  inviteId?: Maybe<Scalars["String"]["output"]>;
  tripItemId?: Maybe<Scalars["String"]["output"]>;
  tripViewId?: Maybe<Scalars["String"]["output"]>;
  updateHistory?: Maybe<UpdateHistory>;
  viewFilter?: Maybe<TripsFilter>;
  viewType: TripsViewType;
  viewUrl?: Maybe<Scalars["URL"]["output"]>;
};

export type TripsNavigation = HeaderSharedUi & {
  __typename?: "TripsNavigation";
  skipSsr: Scalars["Boolean"]["output"];
};

export interface TripsNotAuthorizedInput {
  filter?: InputMaybe<Scalars["String"]["input"]>;
  inviteId?: InputMaybe<Scalars["String"]["input"]>;
  screen?: InputMaybe<TripsScreen>;
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsNotAuthorizedSignInButton = {
  __typename?: "TripsNotAuthorizedSignInButton";
  action: UiLinkAction;
  button: TripsUiButton;
};

export type TripsNotAuthorizedView = {
  __typename?: "TripsNotAuthorizedView";
  header: Scalars["String"]["output"];
  illustration: Illustration;
  searchForBookingCard: TripsMessagingLinkCard;
  /** @deprecated Use tripsSignInButton instead */
  signInButton?: Maybe<EgdsButton>;
  tripsSignInButton?: Maybe<TripsNotAuthorizedSignInButton>;
  viewTitle: Scalars["String"]["output"];
};

export type TripsOpenBottomSheetAction = TripsAction & {
  __typename?: "TripsOpenBottomSheetAction";
  analytics: ClientSideAnalytics;
  bottomSheet: TripsBottomSheet;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsOpenChangeDatesDatePickerAction = TripsAction & {
  __typename?: "TripsOpenChangeDatesDatePickerAction";
  analytics: ClientSideAnalytics;
  attributes: TripsDatePickerAttributes;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsOpenCreateNewTripDrawerAction = TripsAction & {
  __typename?: "TripsOpenCreateNewTripDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  /** @deprecated Use TripsOpenCreateNewTripDrawerForItemAction instead if you need passing an itemId */
  itemId: Scalars["String"]["output"];
};

export type TripsOpenCreateNewTripDrawerForItemAction = TripsAction & {
  __typename?: "TripsOpenCreateNewTripDrawerForItemAction";
  analytics: ClientSideAnalytics;
  createTripMetadata?: Maybe<TripCreationMetadata>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  /** @deprecated itemId has been deprecated., replace with createTripMetadata.saveItemInput.itemId */
  itemId: Scalars["String"]["output"];
  /** @deprecated TripCreationEntryPoint has been deprecated and will be removed in future */
  source: TripCreationEntryPoint;
};

export type TripsOpenDialogAction = TripsAction & {
  __typename?: "TripsOpenDialogAction";
  analytics: ClientSideAnalytics;
  /** @deprecated Deprecated in favour of 'modalDialog' */
  dialog: TripsDialog;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  modalDialog?: Maybe<TripsModalDialog>;
};

export type TripsOpenDrawerAction = TripsAction & {
  __typename?: "TripsOpenDrawerAction";
  analytics: ClientSideAnalytics;
  drawer: TripsStaticContentDrawer;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsOpenEditTripDrawerAction = TripsAction & {
  __typename?: "TripsOpenEditTripDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  overview: TripOverview;
};

export type TripsOpenEmailDrawerAction = TripsAction & {
  __typename?: "TripsOpenEmailDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
};

export type TripsOpenFullScreenDialogAction = TripsAction & {
  __typename?: "TripsOpenFullScreenDialogAction";
  analytics: ClientSideAnalytics;
  dialog?: Maybe<TripsFullScreenDialog>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  /** @deprecated Deprecated in favour of 'dialog' */
  view: TripsViewResponse;
};

export type TripsOpenInviteDrawerAction = TripsAction & {
  __typename?: "TripsOpenInviteDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  overview: TripOverview;
};

export type TripsOpenInviteTripItemDrawerAction = TripsAction & {
  __typename?: "TripsOpenInviteTripItemDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
};

export type TripsOpenMenuAction = TripsAction & {
  __typename?: "TripsOpenMenuAction";
  analytics: ClientSideAnalytics;
  bottomMenu?: Maybe<TripsBottomMenu>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  floatingMenu?: Maybe<TripsFloatingMenu>;
};

export type TripsOpenMoveTripItemDrawerAction = TripsAction & {
  __typename?: "TripsOpenMoveTripItemDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  item: TripItem;
};

export type TripsOpenMultiSectionDialogAction = TripsAction & {
  __typename?: "TripsOpenMultiSectionDialogAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  multiSectionDialog: TripsMultiSectionDialog;
};

export type TripsOpenRequestSystemNotificationsDialogAction = TripsAction & {
  __typename?: "TripsOpenRequestSystemNotificationsDialogAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
};

export type TripsOpenSaveToTripDrawerAction = TripsAction & {
  __typename?: "TripsOpenSaveToTripDrawerAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  input: TripsPlan;
};

export type TripsOverlayButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsOverlayButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"]["output"];
    grow?: Maybe<Scalars["Float"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
    width: TripsButtonWidth;
  };

export type TripsPageBreak = {
  __typename?: "TripsPageBreak";
  _empty?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TripsPlan = {
  __typename?: "TripsPlan";
  attributes?: Maybe<TripsSaveItemAttributes>;
  itemId: Scalars["String"]["output"];
  pageLocation?: Maybe<PageLocation>;
  /** @deprecated `source` has been deprecated in favour of `pageLocation`. */
  source: TripsPlanSourceType;
  subscriptionAttributes?: Maybe<TripsSubscriptionAttributes>;
  tripItem?: Maybe<TripItem>;
};

export interface TripsPlanInput {
  attributes?: InputMaybe<TripsSaveItemAttributesInput>;
  itemId: Scalars["String"]["input"];
  pageLocation?: InputMaybe<PageLocation>;
  productSearchCriteria?: InputMaybe<TripsProductSearchCriteriaInput>;
  source?: InputMaybe<TripsPlanSourceType>;
  subscriptionAttributes?: InputMaybe<TripsSubscriptionAttributesInput>;
  tripItem?: InputMaybe<TripItemInput>;
}

export type TripsPlanSourceType =
  | "ACTIVITY_DETAILS"
  | "ACTIVITY_SEARCH"
  | "CAR_INFOSITE"
  | "CAR_INFOSITE_V2"
  | "CAR_SEARCH"
  | "CAR_SEARCH_V2"
  | "HOTEL_SEARCH"
  | "LODGING_DETAILS"
  | "LODGING_SEARCH"
  | "PACKAGE_DETAILS"
  | "TRIP_OVERVIEW";

export type TripsPlannedItemCard = {
  __typename?: "TripsPlannedItemCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use cardAction instead */
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  badge?: Maybe<TripsUiPlannedItemCardBadge>;
  cardAction: TripsUiPlannedItemCardAction;
  commentsAndVotes?: Maybe<Array<TripsUiItemCardActionCount>>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  identifier: Scalars["String"]["output"];
  itemPricePrimer?: Maybe<TripsUiItemPricePrimer>;
  logo?: Maybe<Image>;
  media?: Maybe<TripsItemCardMedia>;
  mediaPosition: TripsUiItemCardMediaPosition;
  menu?: Maybe<TripsUiItemCardMenu>;
  priceAlertsToggle?: Maybe<TripsUiItemCardPriceAlertsToggle>;
  primary: Scalars["String"]["output"];
  /** @deprecated Use enrichedSecondaries instead */
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsPrefetchOperation = TripsPrefetchTripItemDetails | TripsPrefetchTripOverview;

export type TripsPrefetchTripItemDetails = {
  __typename?: "TripsPrefetchTripItemDetails";
  item: TripItem;
};

export type TripsPrefetchTripOverview = {
  __typename?: "TripsPrefetchTripOverview";
  overview: TripOverview;
};

export type TripsPriceAlertAction = TripsAction & {
  __typename?: "TripsPriceAlertAction";
  alertStatus: TripsToggleStatus;
  analytics: ClientSideAnalytics;
  emitSignals?: Maybe<Array<TripsEmitSignal>>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  tripItem: TripItem;
};

export type TripsPriceAlertSwitchCard = {
  __typename?: "TripsPriceAlertSwitchCard";
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  switch: EgdsStandardSwitch;
};

export type TripsPricePresentation = {
  __typename?: "TripsPricePresentation";
  impressionAnalytics?: Maybe<ClientSideImpressionAnalytics>;
  pricePresentation?: Maybe<PricePresentation>;
};

export type TripsPrimaryButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsPrimaryButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"]["output"];
    grow?: Maybe<Scalars["Float"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
    width: TripsButtonWidth;
  };

export type TripsProductSearchCriteriaAttributes = {
  __typename?: "TripsProductSearchCriteriaAttributes";
  flightSearchCriteria?: Maybe<FlightSearchCriteria>;
};

export interface TripsProductSearchCriteriaInput {
  flightSearchCriteria?: InputMaybe<FlightSearchCriteriaInput>;
  propertySearchCriteria?: InputMaybe<PropertySearchCriteriaInput>;
  tripsFlightSearchCriteria?: InputMaybe<TripsSaveFlightSearchCriteriaInput>;
}

export type TripsRecentSearchPriceAlertAction = TripsAction & {
  __typename?: "TripsRecentSearchPriceAlertAction";
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  productSearchCriteria?: Maybe<TripsProductSearchCriteriaAttributes>;
  subscriptionId?: Maybe<Scalars["String"]["output"]>;
};

export type TripsRecentSearchesListResponse = {
  __typename?: "TripsRecentSearchesListResponse";
  cards: Array<TripsSlimCard>;
  expandoLink?: Maybe<EgdsExpandoLink>;
  header: Scalars["String"]["output"];
  seeAllSearches?: Maybe<EgdsStandardLink>;
};

export type TripsRecentSearchesResponse =
  | TripsRecentSearchesListResponse
  | TripsRecentSearchesResponseError
  | TripsRecentSearchesResponseSuccess;

export type TripsRecentSearchesResponseError = {
  __typename?: "TripsRecentSearchesResponseError";
  message: UiEmptyState;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TripsRecentSearchesResponseSuccess = {
  __typename?: "TripsRecentSearchesResponseSuccess";
  carousel?: Maybe<TripsCarouselContainer>;
  elements: Array<TripsActionList>;
  heading: Scalars["String"]["output"];
};

export type TripsReferAFriendTile = {
  __typename?: "TripsReferAFriendTile";
  analytics: ClientSideAnalytics;
  heading: Scalars["String"]["output"];
  icon?: Maybe<Icon>;
  impressionTracking: ClientSideImpressionAnalytics;
  message: Scalars["String"]["output"];
};

export type TripsResponseStatus = "FAIL" | "SUCCESS";

export type TripsRetryOperationAction = UiAction & {
  __typename?: "TripsRetryOperationAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type TripsReviewCollectionCard = {
  __typename?: "TripsReviewCollectionCard";
  description: EgdsStylizedText;
  heading: EgdsStylizedText;
  image: Image;
  link?: Maybe<EgdsStandardLink>;
  openedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  orderNumber?: Maybe<Scalars["String"]["output"]>;
  ratingLinks?: Maybe<Array<EgdsStandardLink>>;
  subHeading: EgdsHeading;
};

export type TripsReviewCollectionCarousel = {
  __typename?: "TripsReviewCollectionCarousel";
  carousel: EgdsCarousel;
  heading?: Maybe<EgdsHeading>;
  presentedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type TripsReviewCollectionCarouselCard = EgdsCarouselItem & {
  __typename?: "TripsReviewCollectionCarouselCard";
  card: TripsReviewCollectionCard;
  id?: Maybe<Scalars["String"]["output"]>;
  selectedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
};

export type TripsReviewCollectionResponse = {
  __typename?: "TripsReviewCollectionResponse";
  reviewsCarousel?: Maybe<TripsReviewCollectionCarousel>;
  reviewsSheet?: Maybe<TripsReviewCollectionSheet>;
};

export type TripsReviewCollectionSheet = {
  __typename?: "TripsReviewCollectionSheet";
  card: TripsReviewCollectionCard;
  closedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  heading: EgdsHeading;
  presentedAnalytics?: Maybe<Scalars["AnalyticsPayload"]["output"]>;
  sheet: EgdsSheet;
};

export type TripsReviewsCarouselPrimer = {
  __typename?: "TripsReviewsCarouselPrimer";
  noop?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TripsSaveActionSheet = TripsSheet & {
  __typename?: "TripsSaveActionSheet";
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionTracking: ClientSideImpressionAnalytics;
  primaryCTA: TripsPrimaryButton;
  savedTripSummarySection: SavedTripSummarySection;
  secondaryCTA: TripsSecondaryButton;
  toolbar: TripsToolbar;
};

export type TripsSaveActivityAttributes = {
  __typename?: "TripsSaveActivityAttributes";
  dateRange?: Maybe<DateRange>;
  regionId?: Maybe<Scalars["String"]["output"]>;
};

export interface TripsSaveActivityAttributesInput {
  dateRange?: InputMaybe<DateRangeInput>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsSaveCarOfferAttributes = {
  __typename?: "TripsSaveCarOfferAttributes";
  categoryCode: Scalars["String"]["output"];
  fuelAcCode: Scalars["String"]["output"];
  offerToken: Scalars["String"]["output"];
  searchCriteria: PrimaryCarCriteria;
  transmissionDriveCode: Scalars["String"]["output"];
  typeCode: Scalars["String"]["output"];
  vendorCode: Scalars["String"]["output"];
};

export interface TripsSaveCarOfferAttributesInput {
  categoryCode: Scalars["String"]["input"];
  fuelAcCode: Scalars["String"]["input"];
  offerToken: Scalars["String"]["input"];
  searchCriteria: PrimaryCarCriteriaInput;
  transmissionDriveCode: Scalars["String"]["input"];
  typeCode: Scalars["String"]["input"];
  vendorCode: Scalars["String"]["input"];
}

export type TripsSaveFlightSearchAttributes = {
  __typename?: "TripsSaveFlightSearchAttributes";
  searchCriteria: FlightSearchCriteria;
};

export interface TripsSaveFlightSearchCriteriaInput {
  flightSearchFilters: Array<FlightsSearchFilterValuesInput>;
  primary: PrimaryFlightCriteriaInput;
}

export type TripsSaveItem = {
  __typename?: "TripsSaveItem";
  attributes?: Maybe<TripsSaveItemAttributes>;
  coachmark?: Maybe<TripsCoachmark>;
  initialChecked: Scalars["Boolean"]["output"];
  itemId: Scalars["String"]["output"];
  remove: TripsSaveItemProperties;
  save: TripsSaveItemProperties;
  source: PageLocation;
  subscriptionAttributes?: Maybe<TripsSaveItemSubscriptionAttributes>;
  viewType?: Maybe<TripSaveItemViewType>;
};

export type TripsSaveItemAttributes =
  | TripsSaveActivityAttributes
  | TripsSaveCarOfferAttributes
  | TripsSaveFlightSearchAttributes
  | TripsSavePackageAttributes
  | TripsSaveStayAttributes;

export interface TripsSaveItemAttributesInput {
  activityAttributes?: InputMaybe<TripsSaveActivityAttributesInput>;
  carAttributes?: InputMaybe<TripsSaveCarOfferAttributesInput>;
  flightAttributes?: InputMaybe<FlightSearchCriteriaInput>;
  packageAttributes?: InputMaybe<TripsSavePackageAttributesInput>;
  stayAttributes?: InputMaybe<TripsSaveStayAttributesInput>;
}

export type TripsSaveItemDetails = {
  __typename?: "TripsSaveItemDetails";
  graphicText?: Maybe<EgdsGraphicText>;
  heading?: Maybe<Scalars["String"]["output"]>;
  notificationsToggle?: Maybe<TripsIconToggle>;
  primary: TripsInlineContent;
  saveItemButton: TripsSecondaryButton;
};

export interface TripsSaveItemInput {
  anchorPrice?: InputMaybe<Scalars["String"]["input"]>;
  productSearchCriteria: TripsProductSearchCriteriaInput;
}

export type TripsSaveItemProperties = {
  __typename?: "TripsSaveItemProperties";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  adaptexSuccessCampaignIds?: Maybe<Array<AdaptexCampaignTrackingDetail>>;
  analytics?: Maybe<ClientSideAnalytics>;
  label?: Maybe<Scalars["String"]["output"]>;
  tooltip?: Maybe<Scalars["String"]["output"]>;
};

export type TripsSaveItemResponse = {
  __typename?: "TripsSaveItemResponse";
  saveItemButton: TripsSecondaryButton;
  saveItemDetails: TripsSaveItemDetails;
  saveItemToTripToggle: TripsSaveItemToggle;
  /** @deprecated Don't use saveItemToggle, instead use saveItemToTripToggle which contains the action handler. */
  saveItemToggle?: Maybe<TripsSaveItem>;
  subscriptionAttributes: TripsSubscriptionAttributes;
};

export type TripsSaveItemSubscriptionAttributes = {
  __typename?: "TripsSaveItemSubscriptionAttributes";
  anchorPrice?: Maybe<Scalars["String"]["output"]>;
};

export type TripsSaveItemToTripAction = TripsAction & {
  __typename?: "TripsSaveItemToTripAction";
  accessibility?: Maybe<AccessibilityData>;
  analytics: ClientSideAnalytics;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  /** @deprecated itemId has been deprecated in favour of saveItemInput.itemId */
  itemId: Scalars["String"]["output"];
  saveItemInput?: Maybe<TripsPlan>;
  tripId: Scalars["String"]["output"];
};

export type TripsSaveItemToggle = {
  __typename?: "TripsSaveItemToggle";
  action: TripsAction;
  item: TripsSaveItem;
};

export type TripsSaveItemVariant = "CONTROL" | "VARIANT_B" | "VARIANT_C";

export type TripsSaveItemWrapper = {
  __typename?: "TripsSaveItemWrapper";
  tripsSaveItem: TripsSaveItem;
  variant?: Maybe<TripsSaveItemVariant>;
};

export type TripsSaveNewTripAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsSaveNewTripAction";
    analytics: ClientSideAnalytics;
    enabledForOfflineView: Scalars["Boolean"]["output"];
    inputIds: Array<Scalars["String"]["output"]>;
    metadata?: Maybe<TripCreationMetadata>;
  };

export type TripsSavePackageAttributes = {
  __typename?: "TripsSavePackageAttributes";
  packageOfferId?: Maybe<Scalars["String"]["output"]>;
  sessionId: Scalars["String"]["output"];
};

export interface TripsSavePackageAttributesInput {
  packageOfferId?: InputMaybe<Scalars["String"]["input"]>;
  sessionId: Scalars["String"]["input"];
}

export type TripsSaveStayAttributes = {
  __typename?: "TripsSaveStayAttributes";
  checkInDate?: Maybe<Date>;
  checkoutDate?: Maybe<Date>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  roomConfiguration?: Maybe<Array<PropertyRoom>>;
};

export interface TripsSaveStayAttributesInput {
  checkInDate?: InputMaybe<DateInput>;
  checkoutDate?: InputMaybe<DateInput>;
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  roomConfiguration?: InputMaybe<Array<PropertyRoomInput>>;
}

export interface TripsSaveStayInput {
  checkInDate?: InputMaybe<DateTimeInput>;
  checkoutDate?: InputMaybe<DateTimeInput>;
  propertyId: Scalars["String"]["input"];
  regionId?: InputMaybe<Scalars["String"]["input"]>;
  roomConfiguration?: InputMaybe<Array<TripsStayRoomTravelerInfoInput>>;
  sourceType?: InputMaybe<TripsPlanSourceType>;
}

export type TripsSavedItemPrice = {
  __typename?: "TripsSavedItemPrice";
  impressionAnalytics: ClientSideImpressionAnalytics;
  leadingGraphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsSavedItemPricePrimer = {
  __typename?: "TripsSavedItemPricePrimer";
  tripItem: TripItem;
};

export type TripsScreen =
  | "INVITE_LANDING_PAGE"
  | "PUBLIC_TRIP_BOARD"
  | "TRIP_ASSIST"
  | "TRIP_ITEM_DETAILS"
  | "TRIP_ITEM_ITINERARY"
  | "TRIP_ITEM_MANAGE_BOOKING"
  | "TRIP_ITEM_PRICING_REWARDS"
  | "TRIP_ITEM_RECEIPT"
  | "TRIP_LIST"
  | "TRIP_MANAGE_GUESTS"
  | "TRIP_OVERVIEW"
  | "TRIP_OVERVIEW_ITINERARY";

export interface TripsScreenshotInput {
  itemId: Scalars["String"]["input"];
  pageLocation?: InputMaybe<PageLocation>;
  saveItemAttributes: TripsSaveItemAttributesInput;
}

export type TripsSearchBookingView =
  | "SEARCH_BY_ITINERARY_NUMBER"
  | "SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL"
  | "SEARCH_RESULT";

export type TripsSecondaryButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsSecondaryButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"]["output"];
    grow?: Maybe<Scalars["Float"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
    width: TripsButtonWidth;
  };

export type TripsSectionContainer = TripsContainer & {
  __typename?: "TripsSectionContainer";
  elements: Array<TripsComposableElement>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionAnalytics?: Maybe<ClientSideImpressionAnalytics>;
  subTexts?: Maybe<Array<Scalars["String"]["output"]>>;
  subheadings?: Maybe<Array<Scalars["String"]["output"]>>;
  theme?: Maybe<ContainerTheme>;
};

export type TripsSendItineraryEmail = {
  __typename?: "TripsSendItineraryEmail";
  item: TripItem;
};

export type TripsSendItineraryEmailAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsSendItineraryEmailAction";
    analytics: ClientSideAnalytics;
    enabledForOfflineView: Scalars["Boolean"]["output"];
    inputIds: Array<Scalars["String"]["output"]>;
    item: TripItem;
  };

export type TripsServiceRequestsButtonPrimer = {
  __typename?: "TripsServiceRequestsButtonPrimer";
  buttonStyle: EgdsButtonStyle;
  itineraryNumber: Scalars["String"]["output"];
  lineOfBusiness: Scalars["String"]["output"];
  orderLineId: Scalars["String"]["output"];
};

export interface TripsSheet {
  description?: Maybe<Array<Scalars["String"]["output"]>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  impressionTracking: ClientSideImpressionAnalytics;
  toolbar: TripsToolbar;
}

export type TripsSideEffects =
  | CopyToClipboardAction
  | TripsAcceptInviteAndNavigateToOverviewAction
  | TripsAddToCalendarAction
  | TripsAddToWalletAction
  | TripsCancelActivityAction
  | TripsCancelAssuranceAction
  | TripsCancelCarAction
  | TripsCancelFlightAction
  | TripsCancelInsuranceAction
  | TripsCancellationAction
  | TripsChangeItemDatesAction
  | TripsChangeSaveItemState
  | TripsCloseDialogAction
  | TripsCreateNewTripAction
  | TripsCreateTripFromItemAction
  | TripsCustomerNotificationOpenInAppAction
  | TripsDeleteTripAction
  | TripsDismissAction
  | TripsDismissDrawerAction
  | TripsExploreItinerariesAction
  | TripsFormAction
  | TripsImageActionToast
  | TripsInfoToast
  | TripsInlineActionToast
  | TripsInviteAcceptAction
  | TripsInviteAction
  | TripsLinkAction
  | TripsMapAction
  | TripsMapDirectionsAction
  | TripsMoveItemToTripAction
  | TripsNavigateToFilteredViewAction
  | TripsNavigateToItemDetailsAction
  | TripsNavigateToItemPricingAndRewardsAction
  | TripsNavigateToItemReceiptAction
  | TripsNavigateToItemVoucherAction
  | TripsNavigateToListViewAction
  | TripsNavigateToManageBookingAction
  | TripsNavigateToManageTripAction
  | TripsNavigateToTripOverviewAction
  | TripsNavigateToViewAction
  | TripsOpenBottomSheetAction
  | TripsOpenChangeDatesDatePickerAction
  | TripsOpenCreateNewTripDrawerAction
  | TripsOpenCreateNewTripDrawerForItemAction
  | TripsOpenDialogAction
  | TripsOpenDrawerAction
  | TripsOpenEditTripDrawerAction
  | TripsOpenEmailDrawerAction
  | TripsOpenFullScreenDialogAction
  | TripsOpenInviteDrawerAction
  | TripsOpenInviteTripItemDrawerAction
  | TripsOpenMenuAction
  | TripsOpenMoveTripItemDrawerAction
  | TripsOpenMultiSectionDialogAction
  | TripsOpenRequestSystemNotificationsDialogAction
  | TripsOpenSaveToTripDrawerAction
  | TripsPriceAlertAction
  | TripsRecentSearchPriceAlertAction
  | TripsSaveActionSheet
  | TripsSaveItemToTripAction
  | TripsSaveNewTripAction
  | TripsSendItineraryEmailAction
  | TripsStackedActionToast
  | TripsUnsaveItemFromTripAction
  | TripsUpdateTripAction
  | TripsVirtualAgentInitAction;

export type TripsSignal = {
  __typename?: "TripsSignal";
  reference?: Maybe<Scalars["String"]["output"]>;
  type: TripsSignalType;
};

export type TripsSignalFieldIdExistingValues = {
  __typename?: "TripsSignalFieldIdExistingValues";
  ids: Array<Scalars["String"]["output"]>;
  prefixes?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsSignalFieldIdValue = {
  __typename?: "TripsSignalFieldIdValue";
  id: Scalars["String"]["output"];
};

export interface TripsSignalListener {
  signal?: Maybe<TripsSignal>;
}

export type TripsSignalPair = {
  __typename?: "TripsSignalPair";
  key: TripsSignalPairKey;
  value: TripsSignalPairValue;
};

export type TripsSignalPairKey = "PRIMARY" | "SECONDARY" | "STATUS";

export type TripsSignalPairValue = TripsSignalFieldIdExistingValues | TripsSignalFieldIdValue | TripsSignalValue;

export type TripsSignalType = "REMOVE" | "UPDATE";

export type TripsSignalValue = {
  __typename?: "TripsSignalValue";
  value: Scalars["String"]["output"];
};

export type TripsSlimCard = TripsCard & {
  __typename?: "TripsSlimCard";
  accessibility?: Maybe<AccessibilityData>;
  action?: Maybe<TripsAction>;
  graphic?: Maybe<UiGraphic>;
  /** @deprecated Use graphic */
  icon?: Maybe<Icon>;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  links?: Maybe<Array<EgdsStandardLink>>;
  priceAlertNotification?: Maybe<TripsIconToggle>;
  primary?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use subTexts */
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  subTexts?: Maybe<Array<TripsText>>;
};

export type TripsSlimCardContainer = {
  __typename?: "TripsSlimCardContainer";
  heading?: Maybe<Scalars["String"]["output"]>;
  slimCards: Array<TripsSlimCard>;
  subHeaders?: Maybe<Array<TripsText>>;
};

export type TripsStackedActionToast = TripsToast & {
  __typename?: "TripsStackedActionToast";
  button: TripsButton;
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"]["output"];
};

export type TripsStackedActionsDialog = TripsDialog & {
  __typename?: "TripsStackedActionsDialog";
  body: Array<TripsContent>;
  buttons: Array<TripsButton>;
  heading?: Maybe<Scalars["String"]["output"]>;
};

export type TripsStaticContentDrawer = {
  __typename?: "TripsStaticContentDrawer";
  buttons?: Maybe<Array<TripsButton>>;
  content?: Maybe<Array<TripsStaticContentDrawerContent>>;
  heading?: Maybe<Scalars["String"]["output"]>;
  toolbar?: Maybe<TripsToolbar>;
};

export type TripsStaticContentDrawerContent = EgdsPlainText | Image;

export interface TripsStayRoomTravelerInfoInput {
  childrenAges: Array<Scalars["Int"]["input"]>;
  numberOfAdults: Scalars["Int"]["input"];
  pets: Scalars["Boolean"]["input"];
}

export type TripsStepIndicatorStep = {
  __typename?: "TripsStepIndicatorStep";
  content?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  state: TripsTimelineStates;
  trackLabelA11y?: Maybe<Scalars["String"]["output"]>;
  trackLabelIcon?: Maybe<Icon>;
  trackLabelText?: Maybe<Scalars["String"]["output"]>;
};

export type TripsStepIndicatorVertical = {
  __typename?: "TripsStepIndicatorVertical";
  accessibilityLabel: Scalars["String"]["output"];
  steps: Array<TripsStepIndicatorStep>;
};

export type TripsStickyMenu = {
  __typename?: "TripsStickyMenu";
  accessibility: TripsAccessibilityData;
  menuItems: Array<StickyMenuItems>;
};

export type TripsSubscriptionAttributes = {
  __typename?: "TripsSubscriptionAttributes";
  anchorPrice?: Maybe<Scalars["String"]["output"]>;
  subscriptionInputs?: Maybe<Array<GraphQlPair>>;
};

export interface TripsSubscriptionAttributesInput {
  anchorPrice?: InputMaybe<Scalars["String"]["input"]>;
  subscriptionInputs?: InputMaybe<Array<GraphQlPairInput>>;
}

export type TripsTertiaryButton = TripsButton &
  TripsFlexContainerItem & {
    __typename?: "TripsTertiaryButton";
    accessibility?: Maybe<AccessibilityData>;
    action?: Maybe<TripsAction>;
    disabled: Scalars["Boolean"]["output"];
    grow?: Maybe<Scalars["Float"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
    width: TripsButtonWidth;
  };

export type TripsText = EgdsGraphicText | EgdsPlainText;

export type TripsTimelineStates = "CURRENT" | "FUTURE" | "PAST";

export interface TripsToast {
  impressionTracking?: Maybe<ClientSideImpressionAnalytics>;
  primary: Scalars["String"]["output"];
}

export type TripsToggleStatus = "DISABLED" | "ENABLED";

export type TripsToolbar = {
  __typename?: "TripsToolbar";
  accessibility?: Maybe<AccessibilityData>;
  actions: TripsToolbarActions;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsToolbarActions = {
  __typename?: "TripsToolbarActions";
  primary: TripsTertiaryButton;
  secondaries?: Maybe<Array<TripsTertiaryButton>>;
};

export type TripsUiAcceptRequestToJoinTripButton = {
  __typename?: "TripsUIAcceptRequestToJoinTripButton";
  button: TripsUiButton;
  primer: TripsUiAcceptRequestToJoinTripPrimer;
};

export type TripsUiAcceptRequestToJoinTripDialog = {
  __typename?: "TripsUIAcceptRequestToJoinTripDialog";
  acceptButton: TripsUiAcceptRequestToJoinTripButton;
  declineButton: TripsUiDeclineRequestToJoinTripButton;
  dialog: TripsUiDialog;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiAcceptRequestToJoinTripFailureResponse = {
  __typename?: "TripsUIAcceptRequestToJoinTripFailureResponse";
  toast: TripsUiToast;
};

export type TripsUiAcceptRequestToJoinTripPrimer = {
  __typename?: "TripsUIAcceptRequestToJoinTripPrimer";
  requestToJoinId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiAcceptRequestToJoinTripResponse =
  | TripsUiAcceptRequestToJoinTripDialog
  | TripsUiAcceptRequestToJoinTripFailureResponse;

export type TripsUiActionCount = {
  __typename?: "TripsUIActionCount";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  count: Scalars["Int"]["output"];
  icon: Icon;
  id: Scalars["String"]["output"];
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  timestamp: Scalars["String"]["output"];
};

export type TripsUiActionCountToggle = {
  __typename?: "TripsUIActionCountToggle";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibility: Scalars["String"]["output"];
  checkedAnalytics: ClientSideAnalytics;
  checkedCount: Scalars["Int"]["output"];
  checkedIcon: Icon;
  uncheckedAccessibility: Scalars["String"]["output"];
  uncheckedAnalytics: ClientSideAnalytics;
  uncheckedCount: Scalars["Int"]["output"];
  uncheckedIcon: Icon;
};

export type TripsUiAttachQualificationType = "CARMIP" | "HOTMIP";

export type TripsUiAttachSavings = TripsUiAttachSavingsBanner | TripsUiAttachSavingsSheet;

export type TripsUiAttachSavingsBanner = {
  __typename?: "TripsUIAttachSavingsBanner";
  content: TripsUiMessagingCard;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiAttachSavingsMessages = EgdsPlainText | EgdsStylizedText;

export type TripsUiAttachSavingsSheet = {
  __typename?: "TripsUIAttachSavingsSheet";
  content: TripsUiAttachSavingsSheetContent;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  sheet: EgdsSheet;
};

export type TripsUiAttachSavingsSheetContent = {
  __typename?: "TripsUIAttachSavingsSheetContent";
  button: TripsUiExternalLinkPrimaryButton;
  graphic: UiGraphic;
  heading: Scalars["String"]["output"];
  /** @deprecated message is deprecated. Please use messages instead. */
  message: Scalars["String"]["output"];
  messages: Array<TripsUiAttachSavingsMessages>;
};

export type TripsUiAttachSavingsVariant = "BANNER" | "SHEET";

export type TripsUiAvatar = TripsUiIconAvatar | TripsUiImageAvatar | TripsUiTextAvatar;

export type TripsUiAvatarGroup = {
  __typename?: "TripsUIAvatarGroup";
  items: Array<TripsUiAvatarGroupItem>;
};

export type TripsUiAvatarGroupItem =
  | TripsUiIconAvatarGroupItem
  | TripsUiImageAvatarGroupItem
  | TripsUiTextAvatarGroupItem;

export type TripsUiBasicMessagingCard = TripsUiMessagingCard & {
  __typename?: "TripsUIBasicMessagingCard";
  icon?: Maybe<UiGraphic>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiBookableItineraryItemCard = {
  __typename?: "TripsUIBookableItineraryItemCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UiLinkAction;
  analytics?: Maybe<ClientSideAnalytics>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  identifier: Scalars["String"]["output"];
  itemPricePrimer: TripsUiItemPricePrimer;
  media?: Maybe<TripsItemCardMedia>;
  primary: Scalars["String"]["output"];
  saveToggle: TripsUiItineraryItemSaveToggle;
};

export type TripsUiBookedItemCard = {
  __typename?: "TripsUIBookedItemCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use cardAction instead */
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  badge?: Maybe<EgdsStandardBadge>;
  cardAction?: Maybe<TripsUiBookedItemCardAction>;
  contextualCardPrimer?: Maybe<TripsUiItemCardContextualContentPrimer>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  identifier: Scalars["String"]["output"];
  logo?: Maybe<Image>;
  media?: Maybe<TripsItemCardMedia>;
  menu?: Maybe<TripsUiItemCardMenu>;
  primary: Scalars["String"]["output"];
};

export type TripsUiBookedItemCardAction = UiLinkAction;

export type TripsUiBulkChangeDatesButton = {
  __typename?: "TripsUIBulkChangeDatesButton";
  button: TripsUiSecondaryButton;
  datePickerAttributes: TripsUiDatePickerAttributes;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export interface TripsUiButton {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
}

export type TripsUiCanceledItemCard = {
  __typename?: "TripsUICanceledItemCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use cardAction instead */
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  badge?: Maybe<EgdsStandardBadge>;
  cardAction: TripsUiCanceledItemCardAction;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  identifier: Scalars["String"]["output"];
  logo?: Maybe<Image>;
  media?: Maybe<TripsItemCardMedia>;
  menu?: Maybe<TripsUiItemCardMenu>;
  primary: Scalars["String"]["output"];
};

export type TripsUiCanceledItemCardAction = UiLinkAction;

export type TripsUiCardBackgroundTheme =
  | "GLOBAL_LOYALTY_EXTRA_HIGH"
  | "GLOBAL_LOYALTY_HIGH"
  | "GLOBAL_LOYALTY_LOW"
  | "GLOBAL_LOYALTY_MID"
  | "HIGHLIGHT"
  | "PRIMARY"
  | "SECONDARY";

export type TripsUiCardBorder = "SELECTED" | "SOLID";

export type TripsUiCardButton = {
  __typename?: "TripsUICardButton";
  analytics: ClientSideAnalytics;
  leadingGraphic?: Maybe<UiGraphic>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  trailingIcon?: Maybe<Icon>;
};

export type TripsUiChatGptEntrypointResponse = {
  __typename?: "TripsUIChatGPTEntrypointResponse";
  card: TripsUiMessagingLinkCard;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiCloseDialogButton = {
  __typename?: "TripsUICloseDialogButton";
  button: TripsUiButton;
};

export type TripsUiCloseMapFloatingActionButton = {
  __typename?: "TripsUICloseMapFloatingActionButton";
  /** @deprecated Use icon.description instead */
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon: Icon;
};

export type TripsUiCollapsableMessagingBannerCard = TripsUiMessagingCard & {
  __typename?: "TripsUICollapsableMessagingBannerCard";
  icon?: Maybe<UiGraphic>;
  link: UiLinkAction;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  themeProvider?: Maybe<EgdsThemeProvider>;
  trailingIcon?: Maybe<Icon>;
};

export type TripsUiComment = {
  __typename?: "TripsUIComment";
  avatar: TripsUiAvatar;
  comment: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  timestamp: Scalars["String"]["output"];
};

export type TripsUiComments = {
  __typename?: "TripsUIComments";
  content: TripsUiCommentsTabContent;
  disclaimerBanner?: Maybe<TripsUiMessagingBannerCard>;
  postCommentSection: TripsUiPostCommentSection;
};

export type TripsUiCommentsAndVotesFailureResponse = {
  __typename?: "TripsUICommentsAndVotesFailureResponse";
  primary: Scalars["String"]["output"];
  retryButton: TripsUiCommentsAndVotesRetryButton;
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiCommentsAndVotesResponse = TripsUiCommentsAndVotesFailureResponse | TripsUiCommentsAndVotesTabs;

export type TripsUiCommentsAndVotesRetryButton = {
  __typename?: "TripsUICommentsAndVotesRetryButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiCommentsAndVotesRetryPrimer;
};

export type TripsUiCommentsAndVotesRetryPrimer = {
  __typename?: "TripsUICommentsAndVotesRetryPrimer";
  itemId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiCommentsAndVotesSheet = {
  __typename?: "TripsUICommentsAndVotesSheet";
  fallback: TripsUiCommentsAndVotesFailureResponse;
  itemReplayCard?: Maybe<TripsUiCommentsAndVotesSheetItemReplayCard>;
  primer: TripsUiCommentsAndVotesSheetPrimer;
  toolbar: TripsUiCommentsAndVotesSheetToolbar;
};

export type TripsUiCommentsAndVotesSheetItemReplayCard = {
  __typename?: "TripsUICommentsAndVotesSheetItemReplayCard";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  media?: Maybe<TripsUiCommentsAndVotesSheetItemReplayCardMedia>;
  mediaPosition: TripsUiItemCardMediaPosition;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
};

export type TripsUiCommentsAndVotesSheetItemReplayCardMedia = Image | TripsUiItemCardMediaGalleryImage;

export type TripsUiCommentsAndVotesSheetPrimer = {
  __typename?: "TripsUICommentsAndVotesSheetPrimer";
  defaultTab?: Maybe<Scalars["String"]["output"]>;
  itemId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiCommentsAndVotesSheetToolbar = {
  __typename?: "TripsUICommentsAndVotesSheetToolbar";
  button?: Maybe<TripsUiCommentsAndVotesSheetToolbarButton>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiCommentsAndVotesSheetToolbarButton = {
  __typename?: "TripsUICommentsAndVotesSheetToolbarButton";
  action: TripsUiCommentsAndVotesSheetToolbarButtonAction;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
};

export type TripsUiCommentsAndVotesSheetToolbarButtonAction = TripsUiViewPackageAction | UiLinkAction;

export type TripsUiCommentsAndVotesTabs = {
  __typename?: "TripsUICommentsAndVotesTabs";
  commentsTab: TripsUiCommentsTab;
  defaultTab: Scalars["String"]["output"];
  votesTab: TripsUiVotesTab;
};

export type TripsUiCommentsList = {
  __typename?: "TripsUICommentsList";
  comments: Array<TripsUiComment>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiCommentsTab = {
  __typename?: "TripsUICommentsTab";
  content: TripsUiComments;
  tab: TripsUiTab;
};

export type TripsUiCommentsTabContent = TripsUiCommentsList | TripsUiTabContentEmpty;

export type TripsUiCompareItemsButton = {
  __typename?: "TripsUICompareItemsButton";
  button: TripsUiSecondaryButton;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  sheet: TripsUiCompareItemsSheet;
};

export type TripsUiCompareItemsPrimer = {
  __typename?: "TripsUICompareItemsPrimer";
  itemIds: Array<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
};

export type TripsUiCompareItemsSheet = {
  __typename?: "TripsUICompareItemsSheet";
  primer: TripsUiCompareItemsPrimer;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiConnectExternalMailboxPrimer = {
  __typename?: "TripsUIConnectExternalMailboxPrimer";
  mailboxType: Scalars["String"]["output"];
  redirectUri: HttpUri;
};

export type TripsUiConnectExternalMailboxResponse = {
  __typename?: "TripsUIConnectExternalMailboxResponse";
  redirectUri: HttpUri;
};

export type TripsUiCopyInviteLinkButton = {
  __typename?: "TripsUICopyInviteLinkButton";
  copiedButton: TripsUiPrimaryButton;
  copyButton: TripsUiPrimaryButton;
};

export type TripsUiCopyRequestToJoinTripLinkButton = {
  __typename?: "TripsUICopyRequestToJoinTripLinkButton";
  copiedButton: TripsUiButton;
  copyButton: TripsUiButton;
};

export type TripsUiCopyRequestToJoinTripSheet = {
  __typename?: "TripsUICopyRequestToJoinTripSheet";
  button: TripsUiCopyRequestToJoinTripLinkButton;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiCreateInviteErrorResponse = {
  __typename?: "TripsUICreateInviteErrorResponse";
  toast: TripsUiToast;
};

export type TripsUiCreateInviteResponse = TripsUiCreateInviteErrorResponse | TripsUiCreateInviteSuccessResponse;

export type TripsUiCreateInviteSuccessResponse = {
  __typename?: "TripsUICreateInviteSuccessResponse";
  shareSheetButton: TripsUiOpenInviteToTripShareSheetButton;
};

export type TripsUiCreateTripAndSaveItemSaveButton = {
  __typename?: "TripsUICreateTripAndSaveItemSaveButton";
  button: TripsUiPrimaryButton;
  createTripPrimer: TripsUiCreateTripPrimer;
};

export type TripsUiCreateTripFormSheet = {
  __typename?: "TripsUICreateTripFormSheet";
  /** @deprecated DO NOT USE! */
  itemId?: Maybe<Scalars["String"]["output"]>;
  primary: Scalars["String"]["output"];
  /** @deprecated DO NOT USE! Use submitButton. */
  saveButton: TripsUiPrimaryButton;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  submitButton: TripsUiCreateTripFormSheetSaveButton;
  toolbar: TripsUiSheetToolbar;
  tripNameInput: EgdsTextInputField;
};

export type TripsUiCreateTripFormSheetSaveButton = TripsUiCreateTripAndSaveItemSaveButton | TripsUiCreateTripSaveButton;

export type TripsUiCreateTripItineraryTemplatePrimer = {
  __typename?: "TripsUICreateTripItineraryTemplatePrimer";
  pollingInterval?: Maybe<Scalars["Int"]["output"]>;
  tripId: Scalars["String"]["output"];
};

export type TripsUiCreateTripItineraryTemplateResponse = {
  __typename?: "TripsUICreateTripItineraryTemplateResponse";
  status: TripsUiResponseStatus;
};

export type TripsUiCreateTripPrimer = {
  __typename?: "TripsUICreateTripPrimer";
  itemId: Scalars["String"]["output"];
  operationType: Scalars["String"]["output"];
  tripContext: TripsUiTripContext;
};

export type TripsUiCreateTripResponse = {
  __typename?: "TripsUICreateTripResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiCreateTripSaveButton = {
  __typename?: "TripsUICreateTripSaveButton";
  button: TripsUiPrimaryButton;
};

export type TripsUiCustomerNotificationOptionalContext = {
  __typename?: "TripsUICustomerNotificationOptionalContext";
  itineraryNumber?: Maybe<Scalars["String"]["output"]>;
  journeyCriteria: Array<TripsUiCustomerNotificationOptionalContextJourneyCriteria>;
  regionId?: Maybe<Scalars["String"]["output"]>;
  tripContext?: Maybe<TripsUiCustomerNotificationTripContext>;
  /** @deprecated Use tripContext */
  tripId: Scalars["String"]["output"];
  /** @deprecated Use tripContext */
  tripItemId: Scalars["String"]["output"];
};

export type TripsUiCustomerNotificationOptionalContextJourneyCriteria = {
  __typename?: "TripsUICustomerNotificationOptionalContextJourneyCriteria";
  dateRange?: Maybe<DateRange>;
  destination?: Maybe<TripsUiCustomerNotificationOptionalContextJourneyCriteriaLocation>;
  origin?: Maybe<TripsUiCustomerNotificationOptionalContextJourneyCriteriaLocation>;
  tripScheduleChangeStatus?: Maybe<TripsUiCustomerNotificationTripScheduleChangeStatus>;
};

export type TripsUiCustomerNotificationOptionalContextJourneyCriteriaLocation = {
  __typename?: "TripsUICustomerNotificationOptionalContextJourneyCriteriaLocation";
  airportTLA?: Maybe<Scalars["String"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  regionId?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiCustomerNotificationPrimer = {
  __typename?: "TripsUICustomerNotificationPrimer";
  funnelLocation: Scalars["String"]["output"];
  lineOfBusiness: Scalars["String"]["output"];
  notificationLocation: Scalars["String"]["output"];
  optionalContext?: Maybe<TripsUiCustomerNotificationOptionalContext>;
  xPageId: Scalars["String"]["output"];
};

export type TripsUiCustomerNotificationTripContext = {
  __typename?: "TripsUICustomerNotificationTripContext";
  itemId?: Maybe<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
};

export type TripsUiCustomerNotificationTripScheduleChangeStatus = "FLIGHT_SCHEDULE_CHANGE" | "NO_CHANGE";

export type TripsUiDatePickerAnalytics = {
  __typename?: "TripsUIDatePickerAnalytics";
  closeAnalytics: ClientSideAnalytics;
  nextPageAnalytics: ClientSideAnalytics;
  previousPageAnalytics: ClientSideAnalytics;
  submitAnalytics: ClientSideAnalytics;
};

export type TripsUiDatePickerAttributes = {
  __typename?: "TripsUIDatePickerAttributes";
  analytics: TripsUiDatePickerAnalytics;
  buttonText: Scalars["String"]["output"];
  daysBookableInAdvance: Scalars["Int"]["output"];
  endDate?: Maybe<Date>;
  footerText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use updateItemDatesPrimer.itemIds instead */
  itemId: Scalars["String"]["output"];
  itemType: Scalars["String"]["output"];
  maxDateRange: Scalars["Int"]["output"];
  midnightBookingOffset?: Maybe<Scalars["Int"]["output"]>;
  minDateRange: Scalars["Int"]["output"];
  productId?: Maybe<Scalars["String"]["output"]>;
  singleDateSelect: Scalars["Boolean"]["output"];
  startDate?: Maybe<Date>;
  /** @deprecated Use updateItemDatesPrimer.tripId instead */
  tripId: Scalars["String"]["output"];
  updateItemDatesPrimer: TripsUiUpdateTripItemDatesPrimer;
};

export type TripsUiDeclineRequestToJoinTripButton = {
  __typename?: "TripsUIDeclineRequestToJoinTripButton";
  button: TripsUiButton;
  toast: TripsUiToast;
};

export type TripsUiDeleteTripDialog = {
  __typename?: "TripsUIDeleteTripDialog";
  content: Array<Scalars["String"]["output"]>;
  dialog: TripsUiDialog;
  footerButtons: Array<TripsUiDeleteTripDialogFooterButton>;
};

export type TripsUiDeleteTripDialogCloseButton = {
  __typename?: "TripsUIDeleteTripDialogCloseButton";
  button: TripsUiTertiaryButton;
};

export type TripsUiDeleteTripDialogFooterButton =
  | TripsUiDeleteTripDialogCloseButton
  | TripsUiDeleteTripDialogSubmitButton;

export type TripsUiDeleteTripDialogSubmitButton = {
  __typename?: "TripsUIDeleteTripDialogSubmitButton";
  button: TripsUiPrimaryButton;
  errorToast: TripsUiToast;
  primer: TripsUiDeleteTripPrimer;
};

export type TripsUiDeleteTripPrimer = {
  __typename?: "TripsUIDeleteTripPrimer";
  tripId: Scalars["String"]["output"];
};

export type TripsUiDeleteTripResponse = {
  __typename?: "TripsUIDeleteTripResponse";
  toast: TripsUiToast;
};

export type TripsUiDestinationGuidePrimer = {
  __typename?: "TripsUIDestinationGuidePrimer";
  components: Array<TripsUiDestinationGuidePrimerComponent>;
};

export type TripsUiDestinationGuidePrimerBestTimeToGoComponent = TripsUiDestinationGuidePrimerComponent & {
  __typename?: "TripsUIDestinationGuidePrimerBestTimeToGoComponent";
  regionId: Scalars["String"]["output"];
};

export interface TripsUiDestinationGuidePrimerComponent {
  regionId: Scalars["String"]["output"];
}

export type TripsUiDestinationGuidePrimerHeadingComponent = TripsUiDestinationGuidePrimerComponent & {
  __typename?: "TripsUIDestinationGuidePrimerHeadingComponent";
  regionId: Scalars["String"]["output"];
};

export type TripsUiDestinationGuidePrimerNeighborhoodComponent = TripsUiDestinationGuidePrimerComponent & {
  __typename?: "TripsUIDestinationGuidePrimerNeighborhoodComponent";
  regionId: Scalars["String"]["output"];
  strategy: Scalars["String"]["output"];
  type: Scalars["String"]["output"];
};

export type TripsUiDestinationGuidePrimerRecommendationsComponent = TripsUiDestinationGuidePrimerComponent & {
  __typename?: "TripsUIDestinationGuidePrimerRecommendationsComponent";
  pageType: Scalars["String"]["output"];
  policy: Scalars["String"]["output"];
  regionId: Scalars["String"]["output"];
};

export type TripsUiDestinationGuidePrimerSimilarDestinationsComponent = TripsUiDestinationGuidePrimerComponent & {
  __typename?: "TripsUIDestinationGuidePrimerSimilarDestinationsComponent";
  regionId: Scalars["String"]["output"];
};

export type TripsUiDestinationGuidePrimerThingsToDoComponent = TripsUiDestinationGuidePrimerComponent & {
  __typename?: "TripsUIDestinationGuidePrimerThingsToDoComponent";
  regionId: Scalars["String"]["output"];
};

export type TripsUiDialog = {
  __typename?: "TripsUIDialog";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideImpressionEventAnalytics;
  closeAnalytics: ClientSideAnalytics;
};

export type TripsUiDialogFooterButtonLayout = "INLINE" | "STACKED";

export type TripsUiDisconnectExternalMailboxesResponse = {
  __typename?: "TripsUIDisconnectExternalMailboxesResponse";
  toast: TripsUiToast;
};

export type TripsUiDownloadAppBannerAction = {
  __typename?: "TripsUIDownloadAppBannerAction";
  analytics: ClientSideAnalytics;
  customerNotificationPrimer: TripsUiCustomerNotificationPrimer;
};

export type TripsUiEditTripFormSheet = {
  __typename?: "TripsUIEditTripFormSheet";
  errorToast: TripsUiToast;
  primary: Scalars["String"]["output"];
  primer: TripsUiEditTripPrimer;
  secondaries: Array<Scalars["String"]["output"]>;
  submitButton: TripsUiPrimaryButton;
  toolbar: TripsUiSheetToolbar;
  tripDescriptionInput: EgdsTextAreaInputField;
  tripNameInput: EgdsTextInputField;
};

export type TripsUiEditTripPrimer = {
  __typename?: "TripsUIEditTripPrimer";
  tripId: Scalars["String"]["output"];
};

export type TripsUiEditTripResponse = {
  __typename?: "TripsUIEditTripResponse";
  toast: TripsUiToast;
};

export type TripsUiEnrichedSecondary = EgdsGraphicText | EgdsPlainText | TripsUiTruncatedText;

export type TripsUiErrorDialog = {
  __typename?: "TripsUIErrorDialog";
  dialog?: Maybe<TripsUiDialog>;
  footer?: Maybe<TripsUiPrimaryButton>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiEventsRecommendationsResponse = {
  __typename?: "TripsUIEventsRecommendationsResponse";
  albumCarousel?: Maybe<TripsAlbumCarousel>;
  body?: Maybe<Array<Scalars["String"]["output"]>>;
  header?: Maybe<Scalars["String"]["output"]>;
  seeAllEventsCta: TripsUiLinkButton;
  subHeader: Scalars["String"]["output"];
};

export type TripsUiExpandoTextList = {
  __typename?: "TripsUIExpandoTextList";
  content: Array<Scalars["String"]["output"]>;
  expando: EgdsExpandoListItem;
};

export type TripsUiExternalLinkPrimaryButton = {
  __typename?: "TripsUIExternalLinkPrimaryButton";
  button: TripsUiPrimaryButton;
  link: UiLinkAction;
};

export type TripsUiFavoriteToggle = {
  __typename?: "TripsUIFavoriteToggle";
  analytics: ClientSideAnalytics;
  isSelected: Scalars["Boolean"]["output"];
  selectedAccessibilityLabel: Scalars["String"]["output"];
  unselectedAccessibilityLabel: Scalars["String"]["output"];
};

export type TripsUiFloatingButton = {
  __typename?: "TripsUIFloatingButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiFullScreenMap = {
  __typename?: "TripsUIFullScreenMap";
  /** @deprecated This is a temporary field that will eventually be replaced. Do not use. */
  cards?: Maybe<Array<TripsUiMapItemCard>>;
  /** @deprecated Use TripsUIStaticMapContent.closeButton instead */
  closeButton: TripsUiCloseMapFloatingActionButton;
  enrichedCards?: Maybe<Array<TripsItemCard>>;
  impressionAnalytics?: Maybe<ClientSideAnalytics>;
  map: EgdsBasicMap;
};

export type TripsUiFullScreenMapSheet = {
  __typename?: "TripsUIFullScreenMapSheet";
  closeButton: TripsUiCloseMapFloatingActionButton;
  map: TripsUiFullScreenMap;
};

export type TripsUiGoToLodgingCheckoutAction = {
  __typename?: "TripsUIGoToLodgingCheckoutAction";
  button: TripsUiButton;
  naturalKey: PropertyNaturalKey;
};

export type TripsUiGraphicTrigger = {
  __typename?: "TripsUIGraphicTrigger";
  analytics: ClientSideAnalytics;
  graphic: UiGraphic;
};

export type TripsUiiOpenInviteToTripSheetAction = {
  __typename?: "TripsUIIOpenInviteToTripSheetAction";
  analytics: ClientSideAnalytics;
  primer: TripsUiInviteToTripSheetPrimer;
};

export type TripsUiIconAvatar = {
  __typename?: "TripsUIIconAvatar";
  icon: Icon;
};

export type TripsUiIconAvatarGroupItem = {
  __typename?: "TripsUIIconAvatarGroupItem";
  icon: Icon;
};

export type TripsUiIllustratedContent = {
  __typename?: "TripsUIIllustratedContent";
  illustration: Illustration;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiImageAvatar = {
  __typename?: "TripsUIImageAvatar";
  image: Image;
};

export type TripsUiImageAvatarGroupItem = {
  __typename?: "TripsUIImageAvatarGroupItem";
  image: Image;
};

export type TripsUiImageGalleryCarouselSheet = {
  __typename?: "TripsUIImageGalleryCarouselSheet";
  carousel: TripCarousel;
  items: Array<TripsUiImageGalleryMedia>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiImageGalleryDetailsAction = TripsUiViewPackageAction | UiLinkAction;

export type TripsUiImageGalleryErrorResponse = {
  __typename?: "TripsUIImageGalleryErrorResponse";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  retryButton: TripsUiImageGalleryRetryButton;
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiImageGalleryFloatingButton = {
  __typename?: "TripsUIImageGalleryFloatingButton";
  action: TripsUiImageGalleryDetailsAction;
  button: TripsUiFloatingButton;
};

export type TripsUiImageGalleryLayout = "GRID" | "LIST";

export type TripsUiImageGalleryMedia = {
  __typename?: "TripsUIImageGalleryMedia";
  action?: Maybe<TripsUiImageGalleryMediaAction>;
  identifier: Scalars["String"]["output"];
  media: Media;
  position: TripsUiImageGalleryMediaPosition;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiImageGalleryMediaAction = {
  __typename?: "TripsUIImageGalleryMediaAction";
  analytics: ClientSideAnalytics;
};

export type TripsUiImageGalleryMediaPosition = {
  __typename?: "TripsUIImageGalleryMediaPosition";
  accessibility: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type TripsUiImageGalleryPrimer = {
  __typename?: "TripsUIImageGalleryPrimer";
  itemId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiImageGalleryResponse = TripsUiImageGalleryErrorResponse | TripsUiImageGallerySuccessResponse;

export type TripsUiImageGalleryRetryButton = {
  __typename?: "TripsUIImageGalleryRetryButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiImageGalleryPrimer;
};

export type TripsUiImageGallerySheetToolbar = {
  __typename?: "TripsUIImageGallerySheetToolbar";
  closeAccessibility: Scalars["String"]["output"];
  closeAnalytics: ClientSideAnalytics;
  closeText?: Maybe<Scalars["String"]["output"]>;
  gridViewButton: TripsUiTertiaryButton;
  listViewButton: TripsUiTertiaryButton;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiImageGallerySuccessResponse = {
  __typename?: "TripsUIImageGallerySuccessResponse";
  floatingButton?: Maybe<TripsUiImageGalleryFloatingButton>;
  gallerySheet: TripsUiImageGalleryCarouselSheet;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  media: Array<TripsUiImageGalleryMedia>;
  primary: Scalars["String"]["output"];
};

export type TripsUiInviteToTripButton = TripsUiOpenInviteToTripShareSheetButton | TripsUiOpenInviteToTripSheetButton;

export type TripsUiInviteToTripSheet = {
  __typename?: "TripsUIInviteToTripSheet";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  sections: Array<TripsUiInviteToTripSheetSection>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiInviteToTripSheetCopyInviteSection = {
  __typename?: "TripsUIInviteToTripSheetCopyInviteSection";
  button: TripsUiCopyInviteLinkButton;
  heading: Scalars["String"]["output"];
};

export type TripsUiInviteToTripSheetEmailInputSection = {
  __typename?: "TripsUIInviteToTripSheetEmailInputSection";
  button: TripsUiSendInviteButton;
  emailInput: EgdsInputField;
  heading: Scalars["String"]["output"];
  messageInput: EgdsTextAreaInputField;
};

export type TripsUiInviteToTripSheetPrimer = {
  __typename?: "TripsUIInviteToTripSheetPrimer";
  tripId: Scalars["String"]["output"];
};

export type TripsUiInviteToTripSheetResponse = TripsUiInviteToTripSheet;

export type TripsUiInviteToTripSheetSection =
  | TripsUiInviteToTripSheetCopyInviteSection
  | TripsUiInviteToTripSheetEmailInputSection
  | TripsUiInviteToTripSheetTextSection;

export type TripsUiInviteToTripSheetTextSection = {
  __typename?: "TripsUIInviteToTripSheetTextSection";
  text: Scalars["String"]["output"];
};

export type TripsUiItemCardActionCount = TripsUiItemCardCommentActionCount | TripsUiItemCardVoteActionCount;

export type TripsUiItemCardCommentActionCount = {
  __typename?: "TripsUIItemCardCommentActionCount";
  sheet: TripsUiCommentsAndVotesSheet;
  trigger: TripsUiActionCount;
};

export type TripsUiItemCardContextualContentPrimer = {
  __typename?: "TripsUIItemCardContextualContentPrimer";
  itemId: Scalars["String"]["output"];
};

export type TripsUiItemCardContextualContentResponse =
  | TripsUiItemCardCopyContextualContent
  | TripsUiItemCardNavigationContextualContent;

export type TripsUiItemCardCopyContextualContent = {
  __typename?: "TripsUIItemCardCopyContextualContent";
  button?: Maybe<TripsUiButton>;
  buttonAction?: Maybe<TripsUiItemCardCopyContextualContentAction>;
  icon: Icon;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
};

export type TripsUiItemCardCopyContextualContentAction = {
  __typename?: "TripsUIItemCardCopyContextualContentAction";
  copyText: Scalars["String"]["output"];
  toast: TripsUiToast;
};

export type TripsUiItemCardMediaGalleryButton = {
  __typename?: "TripsUIItemCardMediaGalleryButton";
  analytics: ClientSideAnalytics;
  icon: Icon;
  identifier: Scalars["String"]["output"];
  sheet: TripsUiItemCardMediaGallerySheet;
};

export type TripsUiItemCardMediaGalleryImage = {
  __typename?: "TripsUIItemCardMediaGalleryImage";
  action: TripsUiItemCardMediaGalleryImageAction;
  badge?: Maybe<EgdsStandardBadge>;
  galleryButton: TripsUiItemCardMediaGalleryButton;
  identifier: Scalars["String"]["output"];
  image: Image;
};

export type TripsUiItemCardMediaGalleryImageAction = {
  __typename?: "TripsUIItemCardMediaGalleryImageAction";
  analytics: ClientSideAnalytics;
  sheet: TripsUiItemCardMediaGallerySheet;
};

export type TripsUiItemCardMediaGallerySheet = {
  __typename?: "TripsUIItemCardMediaGallerySheet";
  galleryToolbar: TripsUiImageGallerySheetToolbar;
  initialLayout: TripsUiImageGalleryLayout;
  primer: TripsUiImageGalleryPrimer;
  /** @deprecated Use galleryToolbar instead */
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiItemCardMediaPosition = "LEFT" | "TOP";

export type TripsUiItemCardMenu = {
  __typename?: "TripsUIItemCardMenu";
  items: Array<TripsUiItemCardMenuItem>;
  menu: TripsUiMenu;
};

export type TripsUiItemCardMenuItem =
  | TripsUiItemCardMenuItemChangeDates
  | TripsUiItemCardMenuItemMoveConnectedItem
  | TripsUiItemCardMenuItemPriceAlerts
  | TripsUiItemCardMenuItemPriceAlertsInApp
  | TripsUiItemCardMenuItemRemoveItemFromTrip
  | TripsUiItemCardMenuItemSaveToTrip
  | TripsUiItemCardMenuItemUpdateTrip
  | TripsUiItemCardMenuViewPackage
  | TripsUiMenuItemLink;

export type TripsUiItemCardMenuItemChangeDates = {
  __typename?: "TripsUIItemCardMenuItemChangeDates";
  analytics: ClientSideAnalytics;
  datePickerAttributes: TripsUiDatePickerAttributes;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemMoveConnectedItem = {
  __typename?: "TripsUIItemCardMenuItemMoveConnectedItem";
  analytics: ClientSideAnalytics;
  dialog: TripsUiMoveConnectedItemDialog;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemPlanningSheet = {
  __typename?: "TripsUIItemCardMenuItemPlanningSheet";
  toolbar: TripsUiItemCardMenuItemPlanningSheetToolbar;
  tripPlanningPrimer: TripsUiTripPlanningPrimer;
};

export type TripsUiItemCardMenuItemPlanningSheetToolbar = {
  __typename?: "TripsUIItemCardMenuItemPlanningSheetToolbar";
  createTripButton: TripsUiSheetToolbarCreateTripButton;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiItemCardMenuItemPriceAlerts = {
  __typename?: "TripsUIItemCardMenuItemPriceAlerts";
  analytics: ClientSideAnalytics;
  /** @deprecated Use analytics instead */
  disabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use item instead */
  disabledMenuItem: TripsUiMenuItem;
  /** @deprecated Use analytics instead */
  enabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use item instead */
  enabledMenuItem: TripsUiMenuItem;
  item: TripsUiMenuItem;
  /** @deprecated Don't use */
  priceAlertsEnabled: Scalars["Boolean"]["output"];
  updateStatePrimer: TripsUiUpdateItemPriceAlertsStatePrimer;
};

export type TripsUiItemCardMenuItemPriceAlertsInApp = {
  __typename?: "TripsUIItemCardMenuItemPriceAlertsInApp";
  analytics: ClientSideAnalytics;
  customerNotificationPrimer: TripsUiCustomerNotificationPrimer;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemRefreshItineraryItem = {
  __typename?: "TripsUIItemCardMenuItemRefreshItineraryItem";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  primer: TripsUiRefreshItineraryItemPrimer;
};

export type TripsUiItemCardMenuItemRemoveItemFromItinerary = {
  __typename?: "TripsUIItemCardMenuItemRemoveItemFromItinerary";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  primer: TripsUiRemoveItineraryItemPrimer;
};

export type TripsUiItemCardMenuItemRemoveItemFromTrip = {
  __typename?: "TripsUIItemCardMenuItemRemoveItemFromTrip";
  analytics: ClientSideAnalytics;
  dialog: TripsUiRemoveItemFromTripDialog;
  item: TripsUiMenuItem;
};

export type TripsUiItemCardMenuItemSaveToTrip = {
  __typename?: "TripsUIItemCardMenuItemSaveToTrip";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  plannedTripsPrimer: TripsUiPlannedTripsPrimer;
};

export type TripsUiItemCardMenuItemUpdateTrip = {
  __typename?: "TripsUIItemCardMenuItemUpdateTrip";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  planningSheet: TripsUiTripPlanningSheet;
  /** @deprecated Use planningSheet instead */
  sheet: TripsUiItemCardMenuItemPlanningSheet;
};

export type TripsUiItemCardMenuViewPackage = {
  __typename?: "TripsUIItemCardMenuViewPackage";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  primer: TripsUiSelectPackagePrimer;
};

export type TripsUiItemCardNavigationContextualContent = {
  __typename?: "TripsUIItemCardNavigationContextualContent";
  icon: Icon;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  linkAction: UiLinkAction;
  primary: Scalars["String"]["output"];
  trailingIcon?: Maybe<Icon>;
};

export type TripsUiItemCardPriceAlertsToggle = {
  __typename?: "TripsUIItemCardPriceAlertsToggle";
  analytics: ClientSideImpressionEventAnalytics;
  primer: TripsUiUpdateItemPriceAlertsStatePrimer;
  toggle: TripsUiPriceAlertsIconToggle;
};

export type TripsUiItemCardVoteActionCount = {
  __typename?: "TripsUIItemCardVoteActionCount";
  action: TripsUiVoteAction;
  toggle: TripsUiActionCountToggle;
};

export type TripsUiItemEnrichedPrice = {
  __typename?: "TripsUIItemEnrichedPrice";
  action?: Maybe<TripsUiItemPriceAction>;
  /** @deprecated Use leadPrice instead */
  lead: Scalars["String"]["output"];
  leadPrice: TripsUiItemLeadPrice;
  secondaries: Array<Scalars["String"]["output"]>;
  strikethrough?: Maybe<TripsUiItemStrikethroughPrice>;
};

export type TripsUiItemLeadPrice = {
  __typename?: "TripsUIItemLeadPrice";
  accessibility: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
};

export type TripsUiItemPrice = TripsUiItemEnrichedPrice | TripsUiItemSimplePrice | TripsUiItemUnavailablePrice;

export type TripsUiItemPriceAction = TripsUiGoToLodgingCheckoutAction;

export type TripsUiItemPricePrimer = {
  __typename?: "TripsUIItemPricePrimer";
  itemId: Scalars["String"]["output"];
  /** @deprecated This is temporary until all traffic from the savedItemPrice query in bex-api-trips is migrated to the new tripItemPrice query */
  newQuery: Scalars["Boolean"]["output"];
};

export type TripsUiItemPriceResponse = {
  __typename?: "TripsUIItemPriceResponse";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  price: TripsUiItemPrice;
};

export type TripsUiItemSimplePrice = {
  __typename?: "TripsUIItemSimplePrice";
  leadingIcon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  secondary: Scalars["String"]["output"];
};

export type TripsUiItemStrikethroughPrice = {
  __typename?: "TripsUIItemStrikethroughPrice";
  accessibility: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
  strikethroughDialog: TripsUiItemStrikethroughPriceDialog;
};

export type TripsUiItemStrikethroughPriceDialog = {
  __typename?: "TripsUIItemStrikethroughPriceDialog";
  closeButton: TripsUiTertiaryButton;
  /** @deprecated use secondaries */
  content: Scalars["String"]["output"];
  dialog: TripsUiDialog;
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiItemUnavailablePrice = {
  __typename?: "TripsUIItemUnavailablePrice";
  leadingIcon?: Maybe<Icon>;
  text: Scalars["String"]["output"];
};

export type TripsUiItemsEmptyState = {
  __typename?: "TripsUIItemsEmptyState";
  analytics: ClientSideImpressionEventAnalytics;
  button?: Maybe<TripsUiButton>;
  buttonAction?: Maybe<UiLinkAction>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiItemsGroup = TripsItemsGroup | TripsUiItemsEmptyState;

export type TripsUiItemsSectionActionArea = {
  __typename?: "TripsUIItemsSectionActionArea";
  content: Array<TripsUiItemsSectionActionAreaButton>;
};

export type TripsUiItemsSectionActionAreaButton = TripsUiBulkChangeDatesButton | TripsUiCompareItemsButton;

export type TripsUiItineraryAiExploreItem = TripsUiItinerarySectionItem & {
  __typename?: "TripsUIItineraryAIExploreItem";
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  fakeSearchCardButton: TripsUiItineraryAiSuggestionCardButton;
  graphic: UiGraphic;
  identifier: Scalars["String"]["output"];
  primary: Scalars["String"]["output"];
  suggestionCardButtons: Array<TripsUiItineraryAiSuggestionCardButton>;
};

export type TripsUiItineraryAiRecommendationsAction = TripsUiItineraryAiRecommendationsActionSwitch | TripsUiLinkButton;

export type TripsUiItineraryAiRecommendationsActionSwitch = {
  __typename?: "TripsUIItineraryAIRecommendationsActionSwitch";
  errorToast: TripsUiToast;
  primer: TripsUiCreateTripItineraryTemplatePrimer;
  switch: EgdsStandardSwitch;
};

export type TripsUiItineraryAiSuggestionCardButton = {
  __typename?: "TripsUIItineraryAISuggestionCardButton";
  action: TripsUiItineraryAiSuggestionCardButtonAction;
  button: TripsUiCardButton;
};

export type TripsUiItineraryAiSuggestionCardButtonAction = UiLinkAction;

export type TripsUiItineraryBuilderCard =
  | TripsUiItineraryBuilderPreferencesCard
  | TripsUiItineraryBuilderRecommendationsCard
  | TripsUiItineraryBuilderRecommendationsErrorCard;

export type TripsUiItineraryBuilderPreferencesAction = TripsUiItineraryBuilderPreferencesSelectionAction;

export type TripsUiItineraryBuilderPreferencesCard = {
  __typename?: "TripsUIItineraryBuilderPreferencesCard";
  action: TripsUiItineraryBuilderPreferencesAction;
  content: Array<Scalars["String"]["output"]>;
  graphic: UiGraphic;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  replay?: Maybe<TripsUiItineraryBuilderPreferencesReplay>;
};

export type TripsUiItineraryBuilderPreferencesReplay = {
  __typename?: "TripsUIItineraryBuilderPreferencesReplay";
  action?: Maybe<TripsUiItineraryBuilderPreferencesReplayAction>;
  badges: Array<EgdsStandardBadge>;
};

export type TripsUiItineraryBuilderPreferencesReplayAction = {
  __typename?: "TripsUIItineraryBuilderPreferencesReplayAction";
  button: TripsUiTertiaryButton;
  sheet: TripsUiItineraryBuilderPreferencesSheet;
};

export type TripsUiItineraryBuilderPreferencesSelectionAction = {
  __typename?: "TripsUIItineraryBuilderPreferencesSelectionAction";
  sheet: TripsUiItineraryBuilderPreferencesSheet;
  switch: EgdsStandardSwitch;
};

export type TripsUiItineraryBuilderPreferencesSheet = {
  __typename?: "TripsUIItineraryBuilderPreferencesSheet";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  preferences: Array<EgdsBasicPill>;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
  submitButton?: Maybe<TripsUiItineraryBuilderPreferencesSubmitButton>;
  textArea: TripsUiItineraryBuilderPreferencesTextArea;
  toolbar: TripsUiItineraryBuilderPreferencesSheetToolbar;
};

export type TripsUiItineraryBuilderPreferencesSheetToolbar = {
  __typename?: "TripsUIItineraryBuilderPreferencesSheetToolbar";
  action: TripsUiSheetToolbar;
  clearButton?: Maybe<TripsUiTertiaryButton>;
};

export type TripsUiItineraryBuilderPreferencesSubmitButton = {
  __typename?: "TripsUIItineraryBuilderPreferencesSubmitButton";
  button: TripsUiPrimaryButton;
  errorToast: TripsUiToast;
  primer: TripsUiCreateTripItineraryTemplatePrimer;
};

export type TripsUiItineraryBuilderPreferencesTextArea = {
  __typename?: "TripsUIItineraryBuilderPreferencesTextArea";
  analytics?: Maybe<ClientSideAnalytics>;
  description?: Maybe<Scalars["String"]["output"]>;
  textArea: EgdsTextAreaInputField;
};

export type TripsUiItineraryBuilderRecommendationsCard = {
  __typename?: "TripsUIItineraryBuilderRecommendationsCard";
  action: TripsUiItineraryAiRecommendationsAction;
  content: Array<Scalars["String"]["output"]>;
  graphic: UiGraphic;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiItineraryBuilderRecommendationsErrorAction =
  | TripsUiItineraryAiRecommendationsActionSwitch
  | TripsUiItineraryBuilderPreferencesSelectionAction;

export type TripsUiItineraryBuilderRecommendationsErrorCard = {
  __typename?: "TripsUIItineraryBuilderRecommendationsErrorCard";
  action: TripsUiItineraryBuilderRecommendationsErrorAction;
  content: Array<Scalars["String"]["output"]>;
  graphic: UiGraphic;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export type TripsUiItineraryBuilderSmokeTestDialog = {
  __typename?: "TripsUIItineraryBuilderSmokeTestDialog";
  closeButton: TripsUiButton;
  content: Array<Scalars["String"]["output"]>;
  dialog: TripsUiDialog;
  heading: Scalars["String"]["output"];
};

export type TripsUiItineraryCardMenu = {
  __typename?: "TripsUIItineraryCardMenu";
  items: Array<TripsUiItineraryCardMenuItem>;
  menu: TripsUiMenu;
};

export type TripsUiItineraryCardMenuItem =
  | TripsUiItemCardMenuItemRefreshItineraryItem
  | TripsUiItemCardMenuItemRemoveItemFromItinerary
  | TripsUiItineraryCardMenuItemMoveToAnotherDay;

export type TripsUiItineraryCardMenuItemMoveToAnotherDay = {
  __typename?: "TripsUIItineraryCardMenuItemMoveToAnotherDay";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  smokeTestDialog?: Maybe<TripsUiItineraryBuilderSmokeTestDialog>;
};

export type TripsUiItineraryFailureResponse = {
  __typename?: "TripsUIItineraryFailureResponse";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  /** @deprecated Use retryButton instead. */
  refreshButton: TripsUiItineraryRefreshButton;
  retryButton?: Maybe<TripsUiItineraryRefreshButton>;
  toolbar?: Maybe<TripsUiToolbar>;
};

export type TripsUiItineraryItem = TripsUiItinerarySectionItem & {
  __typename?: "TripsUIItineraryItem";
  card?: Maybe<TripsItemCard>;
  enrichedSecondaries: Array<TripsUiEnrichedSecondary>;
  icon: Icon;
  identifier: Scalars["String"]["output"];
  itineraryItemId: Scalars["String"]["output"];
  menu?: Maybe<TripsUiItineraryCardMenu>;
  /** @deprecated Use enrichedSecondaries instead */
  secondaries: Array<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type TripsUiItineraryItemCard = {
  __typename?: "TripsUIItineraryItemCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UiLinkAction;
  analytics?: Maybe<ClientSideImpressionEventAnalytics>;
  enrichedSecondaries?: Maybe<Array<TripsUiEnrichedSecondary>>;
  identifier: Scalars["String"]["output"];
  itemPricePrimer: TripsUiItemPricePrimer;
  media?: Maybe<TripsItemCardMedia>;
  primary: Scalars["String"]["output"];
  source: Scalars["String"]["output"];
  tripSaveItem: TripsSaveItem;
};

export interface TripsUiItineraryItemSaveToggle {
  toggle: TripsUiFavoriteToggle;
}

export type TripsUiItineraryLoadingResponse = {
  __typename?: "TripsUIItineraryLoadingResponse";
  card?: Maybe<TripsUiItineraryBuilderCard>;
  pollingInterval: Scalars["Int"]["output"];
  sections: Array<TripsUiItinerarySection>;
};

export interface TripsUiItineraryPreferenceInput {
  text: Scalars["String"]["input"];
  urn: Scalars["String"]["input"];
}

export interface TripsUiItineraryPreferencesInput {
  additionalPreferencesText?: InputMaybe<Scalars["String"]["input"]>;
  selectedPreferences?: InputMaybe<Array<TripsUiItineraryPreferenceInput>>;
}

export type TripsUiItineraryPrimer = {
  __typename?: "TripsUIItineraryPrimer";
  tripId: Scalars["String"]["output"];
};

export type TripsUiItineraryRefreshButton = {
  __typename?: "TripsUIItineraryRefreshButton";
  button: TripsUiPrimaryButton;
  tripsItineraryPrimer: TripsUiItineraryPrimer;
};

export type TripsUiItineraryResponse =
  | TripsUiItemsEmptyState
  | TripsUiItineraryFailureResponse
  | TripsUiItineraryLoadingResponse
  | TripsUiItinerarySuccessResponse;

export type TripsUiItinerarySection = {
  __typename?: "TripsUIItinerarySection";
  heading: Scalars["String"]["output"];
  headingAccessibilityText?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated items is deprecated, use sectionItems instead. */
  items?: Maybe<Array<TripsUiItineraryItem>>;
  sectionItems?: Maybe<Array<TripsUiItinerarySectionItem>>;
};

export interface TripsUiItinerarySectionItem {
  identifier: Scalars["String"]["output"];
}

export type TripsUiItinerarySuccessResponse = {
  __typename?: "TripsUIItinerarySuccessResponse";
  card?: Maybe<TripsUiItineraryBuilderCard>;
  sections: Array<TripsUiItinerarySection>;
  toolbar?: Maybe<TripsUiToolbar>;
};

export type TripsUiLinkButton = {
  __typename?: "TripsUILinkButton";
  action: UiLinkAction;
  button: TripsUiButton;
};

export type TripsUiLinkEmailCardButton = {
  __typename?: "TripsUILinkEmailCardButton";
  button: TripsUiCardButton;
  errorToast: TripsUiToast;
  primer: TripsUiConnectExternalMailboxPrimer;
};

export type TripsUiLoyaltyOverview = {
  __typename?: "TripsUILoyaltyOverview";
  badge: EgdsLoyaltyBadge;
  text: Scalars["String"]["output"];
};

export type TripsUiManageExternalItemsCheckboxCard = {
  __typename?: "TripsUIManageExternalItemsCheckboxCard";
  accessibility: Scalars["String"]["output"];
  actionType: TripsUiManageExternalItemsCheckboxCardActionType;
  analytics: ClientSideAnalytics;
  card: TripsUiBasicMessagingCard;
  itemId: Scalars["String"]["output"];
  state: EgdsCheckBoxState;
};

export type TripsUiManageExternalItemsCheckboxCardActionType = "TOGGLE_ADD_LIST" | "TOGGLE_REMOVE_LIST";

export interface TripsUiManageExternalItemsEntryPointAction {
  analytics: ClientSideAnalytics;
}

export type TripsUiManageExternalItemsEntryPointCard = {
  __typename?: "TripsUIManageExternalItemsEntryPointCard";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<TripsUiManageExternalItemsEntryPointAction>;
  analytics: ClientSideImpressionEventAnalytics;
  backgroundTheme?: Maybe<TripsUiCardBackgroundTheme>;
  border?: Maybe<TripsUiCardBorder>;
  bottomSection?: Maybe<TripsUiManageExternalItemsEntryPointCardBottomSection>;
  leftGraphic: TripsUiManageExternalItemsEntryPointGraphic;
  primary: Scalars["String"]["output"];
  rightGraphic?: Maybe<TripsUiManageExternalItemsEntryPointGraphic>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiManageExternalItemsEntryPointCardBottomSection = {
  __typename?: "TripsUIManageExternalItemsEntryPointCardBottomSection";
  icon: Icon;
  text: Scalars["String"]["output"];
};

export type TripsUiManageExternalItemsEntryPointGraphic = Icon;

export type TripsUiManageExternalItemsEntryPointLinkEmailAction = TripsUiManageExternalItemsEntryPointAction & {
  __typename?: "TripsUIManageExternalItemsEntryPointLinkEmailAction";
  analytics: ClientSideAnalytics;
  emailButtons: Array<TripsUiLinkEmailCardButton>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries: Array<Scalars["String"]["output"]>;
  tertiaries: Array<Scalars["String"]["output"]>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiManageExternalItemsEntryPointManageItemsAction = TripsUiManageExternalItemsEntryPointAction & {
  __typename?: "TripsUIManageExternalItemsEntryPointManageItemsAction";
  analytics: ClientSideAnalytics;
  primary: Scalars["String"]["output"];
  sections: Array<TripsUiManageExternalItemsSheetSection>;
  submitButton: TripsUiManageItemsSubmitButton;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiManageExternalItemsEntryPointOnboardingAction = TripsUiManageExternalItemsEntryPointAction & {
  __typename?: "TripsUIManageExternalItemsEntryPointOnboardingAction";
  analytics: ClientSideAnalytics;
  illustrations: Array<TripsUiIllustratedContent>;
  learnMoreButton: TripsUiManageExternalItemsLearnMoreActionButton;
  linkEmailButton: TripsUiManageExternalItemsLinkEmailActionButton;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiManageExternalItemsLearnMoreAction = {
  __typename?: "TripsUIManageExternalItemsLearnMoreAction";
  analytics: ClientSideAnalytics;
  expandos: Array<TripsUiExpandoTextList>;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiManageExternalItemsLearnMoreActionButton = {
  __typename?: "TripsUIManageExternalItemsLearnMoreActionButton";
  action: TripsUiManageExternalItemsLearnMoreAction;
  button: TripsUiTertiaryButton;
};

export type TripsUiManageExternalItemsLinkEmailActionButton = {
  __typename?: "TripsUIManageExternalItemsLinkEmailActionButton";
  action: TripsUiManageExternalItemsEntryPointLinkEmailAction;
  button: TripsUiPrimaryButton;
};

export type TripsUiManageExternalItemsResponse = TripsUiManageExternalItemsEntryPointCard;

export type TripsUiManageExternalItemsSheetSection = {
  __typename?: "TripsUIManageExternalItemsSheetSection";
  cards: Array<TripsUiManageExternalItemsCheckboxCard>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiManageItemsSubmitButton = {
  __typename?: "TripsUIManageItemsSubmitButton";
  button: TripsUiPrimaryButton;
  errorToast: TripsUiToast;
  primer: TripsUiSaveExternalItemsPrimer;
};

export type TripsUiManageParticipantsAction = TripsUiOpenManageParticipantsSheetAction;

export type TripsUiManageParticipantsResponse = TripsUiManageParticipantsSuccessResponse;

export type TripsUiManageParticipantsSheet = {
  __typename?: "TripsUIManageParticipantsSheet";
  heading: Scalars["String"]["output"];
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  sections: Array<TripsUiManageParticipantsSheetSection>;
  subHeading?: Maybe<Scalars["String"]["output"]>;
  toolbar: TripsUiManageParticipantsSheetToolbar;
};

export type TripsUiManageParticipantsSheetSection = {
  __typename?: "TripsUIManageParticipantsSheetSection";
  content?: Maybe<Scalars["String"]["output"]>;
  items: Array<TripsUiManageParticipantsSheetSectionItem>;
};

export type TripsUiManageParticipantsSheetSectionItem = {
  __typename?: "TripsUIManageParticipantsSheetSectionItem";
  avatar: TripsUiTextAvatar;
  text: Scalars["String"]["output"];
};

export type TripsUiManageParticipantsSheetToolbar = {
  __typename?: "TripsUIManageParticipantsSheetToolbar";
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiManageParticipantsSuccessResponse = {
  __typename?: "TripsUIManageParticipantsSuccessResponse";
  button: TripsUiInviteToTripButton;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  participantAvatars: TripsUiParticipantAvatars;
};

export type TripsUiMapItemCard = {
  __typename?: "TripsUIMapItemCard";
  action?: Maybe<TripsUiMapItemCardAction>;
  closeAnalytics: ClientSideAnalytics;
  identifier: Scalars["String"]["output"];
  image?: Maybe<Image>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiMapItemCardAction = UiLinkAction;

export type TripsUiMarkCoachmarkAsSeenResponse = {
  __typename?: "TripsUIMarkCoachmarkAsSeenResponse";
  status: TripsUiResponseStatus;
};

export type TripsUiMenu = {
  __typename?: "TripsUIMenu";
  /** @deprecated DO NOT USE. This field will be removed as it is redundant analytics. */
  analytics: ClientSideImpressionEventAnalytics;
  trigger: TripsUiMenuTrigger;
};

export type TripsUiMenuItem = {
  __typename?: "TripsUIMenuItem";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  title: Scalars["String"]["output"];
};

export type TripsUiMenuItemLink = {
  __typename?: "TripsUIMenuItemLink";
  action: UiLinkAction;
  item: TripsUiMenuItem;
};

export type TripsUiMenuTrigger = TripsUiGraphicTrigger | TripsUiTertiaryButton;

export type TripsUiMessagingActionCard = TripsUiMessagingCard & {
  __typename?: "TripsUIMessagingActionCard";
  icon?: Maybe<UiGraphic>;
  links: Array<EgdsStandardLink>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiMessagingBannerCard = TripsUiMessagingCard & {
  __typename?: "TripsUIMessagingBannerCard";
  icon?: Maybe<UiGraphic>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  theme: TripsUiMessagingBannerCardTheme;
};

export type TripsUiMessagingBannerCardTheme = "CRITICAL" | "DEFAULT" | "ERROR";

export interface TripsUiMessagingCard {
  icon?: Maybe<UiGraphic>;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
}

export type TripsUiMessagingLinkCard = TripsUiMessagingCard & {
  __typename?: "TripsUIMessagingLinkCard";
  backgroundTheme?: Maybe<TripsUiCardBackgroundTheme>;
  icon?: Maybe<UiGraphic>;
  link: UiLinkAction;
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  trailingIcon?: Maybe<Icon>;
};

export type TripsUiMoveConnectedItemButton = {
  __typename?: "TripsUIMoveConnectedItemButton";
  button: TripsUiButton;
  planningSheet: TripsUiTripPlanningSheet;
};

export type TripsUiMoveConnectedItemDialog = {
  __typename?: "TripsUIMoveConnectedItemDialog";
  content: Array<Scalars["String"]["output"]>;
  dialog: TripsUiDialog;
  footerButtons: TripsUiMoveConnectedItemDialogFooter;
};

export type TripsUiMoveConnectedItemDialogButton = TripsUiCloseDialogButton | TripsUiMoveConnectedItemButton;

export type TripsUiMoveConnectedItemDialogFooter = {
  __typename?: "TripsUIMoveConnectedItemDialogFooter";
  buttons: Array<TripsUiMoveConnectedItemDialogButton>;
  layout?: Maybe<TripsUiDialogFooterButtonLayout>;
};

export type TripsUiMultiEmailValidation = EgdsInputValidation & {
  __typename?: "TripsUIMultiEmailValidation";
  errorMessage: Scalars["String"]["output"];
};

export type TripsUiNotAuthorizedSheet = {
  __typename?: "TripsUINotAuthorizedSheet";
  createAccountButton: TripsUiLinkButton;
  primary: Scalars["String"]["output"];
  signInButton: TripsNotAuthorizedSignInButton;
  toolbar: TripsUiToolbar;
};

export type TripsUiOpenInviteToTripShareSheetButton = {
  __typename?: "TripsUIOpenInviteToTripShareSheetButton";
  button: TripsUiTertiaryButton;
  image?: Maybe<Image>;
  link: HttpUri;
  messages: Array<Scalars["String"]["output"]>;
  tripName?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiOpenInviteToTripSheetAction = {
  __typename?: "TripsUIOpenInviteToTripSheetAction";
  analytics: ClientSideAnalytics;
  primer: TripsUiInviteToTripSheetPrimer;
};

export type TripsUiOpenInviteToTripSheetButton = {
  __typename?: "TripsUIOpenInviteToTripSheetButton";
  button: TripsUiButton;
  primer: TripsUiInviteToTripSheetPrimer;
};

export type TripsUiOpenManageParticipantsSheetAction = {
  __typename?: "TripsUIOpenManageParticipantsSheetAction";
  analytics: ClientSideAnalytics;
  sheet: TripsUiManageParticipantsSheet;
};

export type TripsUiOpenMapFloatingActionButton = {
  __typename?: "TripsUIOpenMapFloatingActionButton";
  analytics: ClientSideAnalytics;
  button: TripsUiFloatingButton;
  sheet: TripsUiFullScreenMapSheet;
};

export type TripsUiOpenRequestToJoinTripSheetButton = {
  __typename?: "TripsUIOpenRequestToJoinTripSheetButton";
  button: TripsUiButton;
  primer: TripsUiRequestToJoinTripSheetPrimer;
};

export type TripsUiOverviewTabs = {
  __typename?: "TripsUIOverviewTabs";
  selectedTabId: Scalars["String"]["output"];
  tabs: Array<TripsUiTab>;
};

export type TripsUiPageHeader = {
  __typename?: "TripsUIPageHeader";
  primary: Scalars["String"]["output"];
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsUiPageHeaderResponse = {
  __typename?: "TripsUIPageHeaderResponse";
  header: TripsUiPageHeader;
  loyaltyOverview?: Maybe<TripsUiLoyaltyOverview>;
  navToolbar: TripsUiTopNavToolbar;
};

export type TripsUiParticipantAvatar = {
  __typename?: "TripsUIParticipantAvatar";
  /** @deprecated use manageParticipantsAction instead */
  action: UiLinkAction;
  avatarGroup: TripsUiAvatar;
  manageParticipantsAction: TripsUiManageParticipantsAction;
};

export type TripsUiParticipantAvatars = TripsUiParticipantAvatar | TripsUiParticipantsAvatarGroup;

export type TripsUiParticipantsAvatarGroup = {
  __typename?: "TripsUIParticipantsAvatarGroup";
  /** @deprecated use manageParticipantsAction instead */
  action: UiLinkAction;
  avatarGroup: TripsUiAvatarGroup;
  manageParticipantsAction: TripsUiManageParticipantsAction;
};

export type TripsUiPlannedItemCardAction = TripsUiViewPackageAction | UiLinkAction;

export type TripsUiPlannedItemCardBadge = EgdsStandardBadge | TripsUiSavedBadge;

export type TripsUiPlannedTripsPrimer = {
  __typename?: "TripsUIPlannedTripsPrimer";
  attributes: TripsSaveItemAttributes;
  itemId?: Maybe<Scalars["String"]["output"]>;
  pageLocation: PageLocation;
};

export type TripsUiPlanningCard = TripsUiPlanningTripCard;

export type TripsUiPlanningFailureResponse = {
  __typename?: "TripsUIPlanningFailureResponse";
  primary: Scalars["String"]["output"];
  tryAgainButton: TripsUiPrimaryButton;
};

export type TripsUiPlanningResponse =
  | TripsUiPlanningFailureResponse
  | TripsUiPlanningSuccessResponse
  | TripsUiPlanningUnauthenticatedResponse;

export type TripsUiPlanningSuccessResponse = {
  __typename?: "TripsUIPlanningSuccessResponse";
  cards: Array<TripsUiPlanningCard>;
  primary: Scalars["String"]["output"];
};

export type TripsUiPlanningTripCard = {
  __typename?: "TripsUIPlanningTripCard";
  accessibility: Scalars["String"]["output"];
  action: TripsUiUpdateTripItemTripPrimer;
  analytics: ClientSideAnalytics;
  /** @deprecated Use saveIcon instead */
  icon: Icon;
  image?: Maybe<Image>;
  primary: Scalars["String"]["output"];
  saveIcon?: Maybe<Icon>;
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiPlanningUnauthenticatedResponse = {
  __typename?: "TripsUIPlanningUnauthenticatedResponse";
  primary: Scalars["String"]["output"];
  signInButton: TripsUiLinkButton;
};

export type TripsUiPostCommentButton = {
  __typename?: "TripsUIPostCommentButton";
  button: TripsUiTertiaryButton;
  primer: TripsUiSaveCommentPrimer;
};

export type TripsUiPostCommentSection = {
  __typename?: "TripsUIPostCommentSection";
  input: EgdsTextAreaInputField;
  postButton: TripsUiPostCommentButton;
};

export type TripsUiPriceAlertsIconToggle = {
  __typename?: "TripsUIPriceAlertsIconToggle";
  accessibilityLabel: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  /** @deprecated Use accessibilityLabel instead */
  disabledAccessibilityLabel: Scalars["String"]["output"];
  /** @deprecated Use analytics instead */
  disabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use icon instead */
  disabledIcon: Icon;
  /** @deprecated Don't use */
  enabled: Scalars["Boolean"]["output"];
  /** @deprecated Use accessibilityLabel instead */
  enabledAccessibilityLabel: Scalars["String"]["output"];
  /** @deprecated Use analytics instead */
  enabledAnalytics: ClientSideAnalytics;
  /** @deprecated Use icon instead */
  enabledIcon: Icon;
  icon: Icon;
};

export type TripsUiPrimaryButton = TripsUiButton & {
  __typename?: "TripsUIPrimaryButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiPrimerQuery = {
  __typename?: "TripsUIPrimerQuery";
  customerNotification: TripsUiCustomerNotificationPrimer;
  destinationGuide?: Maybe<TripsUiDestinationGuidePrimer>;
};

export type TripsUiPrimerQueryCustomerNotificationArgs = {
  tripId?: InputMaybe<Scalars["String"]["input"]>;
};

export type TripsUiPrimerQueryDestinationGuideArgs = {
  destinationContext: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
};

export type TripsUiPublicItineraryHeaderResponse = {
  __typename?: "TripsUIPublicItineraryHeaderResponse";
  creatorAvatar: TripsUiAvatar;
  creatorHeading: EgdsHeading;
  header: TripsUiPageHeader;
  saveTripButton: TripsUiSaveItineraryToTripButton;
  sharePublicLinkButton: TripsUiSharePublicLinkButton;
  signInCard?: Maybe<TripsUiMessagingActionCard>;
};

export type TripsUiRefreshAction = {
  __typename?: "TripsUIRefreshAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  primary: Scalars["String"]["output"];
};

export type TripsUiRefreshItineraryItemPrimer = {
  __typename?: "TripsUIRefreshItineraryItemPrimer";
  itineraryId: Scalars["String"]["output"];
  itineraryItemId: Scalars["String"]["output"];
};

export type TripsUiRemoveItemFromTripButton = {
  __typename?: "TripsUIRemoveItemFromTripButton";
  button: TripsUiButton;
  primer: TripsUiRemoveItemFromTripPrimer;
};

export type TripsUiRemoveItemFromTripDialog = {
  __typename?: "TripsUIRemoveItemFromTripDialog";
  content: Array<Scalars["String"]["output"]>;
  dialog: TripsUiDialog;
  footer: TripsUiRemoveItemFromTripDialogFooter;
};

export type TripsUiRemoveItemFromTripDialogButton = TripsUiCloseDialogButton | TripsUiRemoveItemFromTripButton;

export type TripsUiRemoveItemFromTripDialogFooter = {
  __typename?: "TripsUIRemoveItemFromTripDialogFooter";
  buttons: Array<TripsUiRemoveItemFromTripDialogButton>;
  layout?: Maybe<TripsUiDialogFooterButtonLayout>;
};

export type TripsUiRemoveItemFromTripPrimer = {
  __typename?: "TripsUIRemoveItemFromTripPrimer";
  itemId: Scalars["String"]["output"];
  productId?: Maybe<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
};

export type TripsUiRemoveItineraryItemPrimer = {
  __typename?: "TripsUIRemoveItineraryItemPrimer";
  itineraryId: Scalars["String"]["output"];
  itineraryItemId: Scalars["String"]["output"];
};

export type TripsUiRemoveTripItemResponse = {
  __typename?: "TripsUIRemoveTripItemResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiRequestToJoinTripResponse = {
  __typename?: "TripsUIRequestToJoinTripResponse";
  button: TripsUiOpenRequestToJoinTripSheetButton;
  illustration: Illustration;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiRequestToJoinTripShareSheet = {
  __typename?: "TripsUIRequestToJoinTripShareSheet";
  messages: Array<Scalars["String"]["output"]>;
};

export type TripsUiRequestToJoinTripSheetPrimer = {
  __typename?: "TripsUIRequestToJoinTripSheetPrimer";
  tripId: Scalars["String"]["output"];
};

export type TripsUiRequestToJoinTripSheetResponse =
  | TripsUiCopyRequestToJoinTripSheet
  | TripsUiRequestToJoinTripShareSheet;

export type TripsUiResponseStatus = "FAIL" | "SUCCESS";

export interface TripsUiSaveActivityCriteriaInput {
  products?: InputMaybe<Array<TripsUiSaveActivityProductCriteriaInput>>;
}

export interface TripsUiSaveActivityProductCriteriaInput {
  dateRange?: InputMaybe<DateRangeInput>;
  itemId: Scalars["String"]["input"];
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsUiSaveCommentPrimer = {
  __typename?: "TripsUISaveCommentPrimer";
  commentId?: Maybe<Scalars["String"]["output"]>;
  itemId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiSaveExternalItemsPrimer = {
  __typename?: "TripsUISaveExternalItemsPrimer";
  itemIdsToAdd: Array<Scalars["String"]["output"]>;
  itemIdsToRemove: Array<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
};

export type TripsUiSaveExternalItemsToTripResponse = {
  __typename?: "TripsUISaveExternalItemsToTripResponse";
  toast: TripsUiToast;
};

export type TripsUiSaveItem = TripsSaveItem;

export type TripsUiSaveItemResponse = {
  __typename?: "TripsUISaveItemResponse";
  saveItem: TripsUiSaveItem;
  toast?: Maybe<TripsUiToast>;
};

export type TripsUiSaveItineraryAsTripButton = {
  __typename?: "TripsUISaveItineraryAsTripButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiSavePublicItineraryAsTripPrimer;
};

export type TripsUiSaveItineraryAsTripDialog = {
  __typename?: "TripsUISaveItineraryAsTripDialog";
  content: Array<Scalars["String"]["output"]>;
  dialog: TripsUiDialog;
  footerButtons: Array<TripsUiSaveItineraryAsTripDialogButton>;
};

export type TripsUiSaveItineraryAsTripDialogButton = TripsUiCloseDialogButton | TripsUiSaveItineraryAsTripButton;

export type TripsUiSaveItineraryAsTripFailureResponse = {
  __typename?: "TripsUISaveItineraryAsTripFailureResponse";
  dialog: TripsUiErrorDialog;
};

export type TripsUiSaveItineraryAsTripResponse =
  | TripsUiSaveItineraryAsTripFailureResponse
  | TripsUiSaveItineraryAsTripSuccessResponse;

export type TripsUiSaveItineraryAsTripSuccessResponse = {
  __typename?: "TripsUISaveItineraryAsTripSuccessResponse";
  analytics: ClientSideImpressionEventAnalytics;
  redirectUrl: HttpUri;
};

export type TripsUiSaveItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUISaveItineraryItem";
  primer: TripsUiSaveTripItemsPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiSaveItineraryToTripButton =
  | TripsUiSaveItineraryToTripDialogButton
  | TripsUiSaveItineraryToTripSheetButton;

export type TripsUiSaveItineraryToTripDialogButton = {
  __typename?: "TripsUISaveItineraryToTripDialogButton";
  action: TripsUiSaveItineraryAsTripDialog;
  analytics: ClientSideImpressionEventAnalytics;
  button: TripsUiPrimaryButton;
};

export type TripsUiSaveItineraryToTripSheetButton = {
  __typename?: "TripsUISaveItineraryToTripSheetButton";
  action: TripsUiNotAuthorizedSheet;
  analytics: ClientSideImpressionEventAnalytics;
  button: TripsUiPrimaryButton;
};

export interface TripsUiSaveLodgingCriteriaInput {
  products?: InputMaybe<Array<TripsUiSaveLodgingProductCriteriaInput>>;
}

export interface TripsUiSaveLodgingProductCriteriaInput {
  dateRange?: InputMaybe<DateRangeInput>;
  itemId: Scalars["String"]["input"];
  regionId?: InputMaybe<Scalars["String"]["input"]>;
}

export interface TripsUiSavePointOfInterestCriteriaInput {
  location?: InputMaybe<CoordinatesInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  regionId: Scalars["String"]["input"];
}

export type TripsUiSavePublicItineraryAsTripPrimer = {
  __typename?: "TripsUISavePublicItineraryAsTripPrimer";
  itineraryId: Scalars["String"]["output"];
};

export type TripsUiSaveTripItemCommentFailureResponse = {
  __typename?: "TripsUISaveTripItemCommentFailureResponse";
  toast: TripsUiToast;
};

export type TripsUiSaveTripItemCommentResponse =
  | TripsUiSaveTripItemCommentFailureResponse
  | TripsUiSaveTripItemCommentSuccessResponse;

export type TripsUiSaveTripItemCommentSuccessResponse = {
  __typename?: "TripsUISaveTripItemCommentSuccessResponse";
  actionCount: TripsUiActionCount;
  comments: Array<TripsUiComment>;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
};

export interface TripsUiSaveTripItemsCriteriaInput {
  activity?: InputMaybe<TripsUiSaveActivityCriteriaInput>;
  lodging?: InputMaybe<TripsUiSaveLodgingCriteriaInput>;
  pointsOfInterest?: InputMaybe<Array<TripsUiSavePointOfInterestCriteriaInput>>;
}

export type TripsUiSaveTripItemsPrimer = {
  __typename?: "TripsUISaveTripItemsPrimer";
  /** @deprecated after save mutation is updated */
  endDate?: Maybe<Date>;
  itemId: Scalars["String"]["output"];
  /** @deprecated after save mutation is updated */
  startDate?: Maybe<Date>;
  tripId?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiSaveTripItemsResponse = {
  __typename?: "TripsUISaveTripItemsResponse";
  status: TripsUiResponseStatus;
  toast?: Maybe<TripsUiToast>;
  url?: Maybe<Uri>;
};

export type TripsUiSavedBadge = {
  __typename?: "TripsUISavedBadge";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  size?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiSavedItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUISavedItineraryItem";
  primer: TripsUiSaveTripItemsPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiSecondaryButton = TripsUiButton & {
  __typename?: "TripsUISecondaryButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiSelectPackagePrimer = {
  __typename?: "TripsUISelectPackagePrimer";
  errorToast: TripsUiToast;
  packageOfferId: Scalars["String"]["output"];
  sessionId: Scalars["String"]["output"];
};

export type TripsUiSendInviteButton = {
  __typename?: "TripsUISendInviteButton";
  button: TripsUiPrimaryButton;
  primer: TripsUiSendInvitePrimer;
};

export type TripsUiSendInvitePrimer = {
  __typename?: "TripsUISendInvitePrimer";
  tripId: Scalars["String"]["output"];
};

export type TripsUiSendTripInviteResponse = {
  __typename?: "TripsUISendTripInviteResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiSharePublicLinkAction = {
  __typename?: "TripsUISharePublicLinkAction";
  dialog: TripsUiSharePublicLinkDialog;
  url: HttpUri;
};

export type TripsUiSharePublicLinkButton = {
  __typename?: "TripsUISharePublicLinkButton";
  action: TripsUiSharePublicLinkAction;
  button: TripsUiTertiaryButton;
};

export type TripsUiSharePublicLinkDialog = {
  __typename?: "TripsUISharePublicLinkDialog";
  content: Array<Scalars["String"]["output"]>;
  dialog: TripsUiDialog;
  footer: TripsUiTertiaryButton;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiSheetToolbar = {
  __typename?: "TripsUISheetToolbar";
  closeAccessibility: Scalars["String"]["output"];
  closeAnalytics: ClientSideAnalytics;
  closeText?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiSheetToolbarCreateTripButton = {
  __typename?: "TripsUISheetToolbarCreateTripButton";
  action: TripsUiSheetToolbarCreateTripFormAction;
  button: TripsUiTertiaryButton;
};

export type TripsUiSheetToolbarCreateTripFormAction = {
  __typename?: "TripsUISheetToolbarCreateTripFormAction";
  sheet: TripsUiCreateTripFormSheet;
};

export type TripsUiStaticMap = {
  __typename?: "TripsUIStaticMap";
  map: EgdsBasicMap;
  mapImage: Image;
  openMapAction: TripsUiStaticMapAction;
};

export type TripsUiStaticMapAction = {
  __typename?: "TripsUIStaticMapAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  content: TripsUiStaticMapContent;
  /** @deprecated Use TripsUIStaticMapContent.map instead */
  fullScreenMap: TripsUiFullScreenMap;
};

export type TripsUiStaticMapContent = {
  __typename?: "TripsUIStaticMapContent";
  closeButton: TripsUiCloseMapFloatingActionButton;
  map: TripsUiFullScreenMap;
};

export type TripsUiStepIndicatorHorizontal = {
  __typename?: "TripsUIStepIndicatorHorizontal";
  accessibilityLabel: Scalars["String"]["output"];
  activeStep: Scalars["Int"]["output"];
  steps: Array<TripsUiStepIndicatorStep>;
};

export type TripsUiStepIndicatorStep = {
  __typename?: "TripsUIStepIndicatorStep";
  content?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  label: Scalars["String"]["output"];
  state: TripState;
  trackLabelA11y?: Maybe<Scalars["String"]["output"]>;
  trackLabelIcon?: Maybe<Icon>;
  trackLabelText?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiTab = {
  __typename?: "TripsUITab";
  analytics: ClientSideAnalytics;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  name: Scalars["String"]["output"];
  tabId: Scalars["String"]["output"];
};

export type TripsUiTabContentEmpty = {
  __typename?: "TripsUITabContentEmpty";
  icon: Icon;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  secondaries: Array<Scalars["String"]["output"]>;
};

export type TripsUiTertiaryButton = TripsUiButton & {
  __typename?: "TripsUITertiaryButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics?: Maybe<ClientSideAnalytics>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiTextAvatar = {
  __typename?: "TripsUITextAvatar";
  text: Scalars["String"]["output"];
};

export type TripsUiTextAvatarGroupItem = {
  __typename?: "TripsUITextAvatarGroupItem";
  text: Scalars["String"]["output"];
};

export type TripsUiTextLinkAction = {
  __typename?: "TripsUITextLinkAction";
  action: UiLinkAction;
  text: Scalars["String"]["output"];
};

export type TripsUiToast = {
  __typename?: "TripsUIToast";
  action?: Maybe<TripsUiToastAction>;
  actionText?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideImpressionEventAnalytics;
  text: Scalars["String"]["output"];
};

export type TripsUiToastAction =
  | TripsUiiOpenInviteToTripSheetAction
  | TripsUiOpenInviteToTripSheetAction
  | UiLinkAction;

export type TripsUiToolbar = {
  __typename?: "TripsUIToolbar";
  navigationButton?: Maybe<TripsUiToolbarNavigationButton>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiToolbarNavigationBackButton = TripsUiToolbarNavigationButton & {
  __typename?: "TripsUIToolbarNavigationBackButton";
  accessibility: Scalars["String"]["output"];
  action: UiLinkAction;
  analytics: ClientSideAnalytics;
  icon: Icon;
  label?: Maybe<Scalars["String"]["output"]>;
};

export interface TripsUiToolbarNavigationButton {
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon: Icon;
  label?: Maybe<Scalars["String"]["output"]>;
}

export type TripsUiToolbarNavigationCloseButton = TripsUiToolbarNavigationButton & {
  __typename?: "TripsUIToolbarNavigationCloseButton";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  icon: Icon;
  label?: Maybe<Scalars["String"]["output"]>;
};

export type TripsUiTopNavActionContent = TripsUiTopNavMenu;

export type TripsUiTopNavMenu = {
  __typename?: "TripsUITopNavMenu";
  items: Array<TripsUiTopNavMenuItem>;
  menu: TripsUiMenu;
};

export interface TripsUiTopNavMenuItem {
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
}

export type TripsUiTopNavMenuItemDeleteTrip = TripsUiTopNavMenuItem & {
  __typename?: "TripsUITopNavMenuItemDeleteTrip";
  analytics: ClientSideAnalytics;
  dialog: TripsUiDeleteTripDialog;
  item: TripsUiMenuItem;
};

export type TripsUiTopNavMenuItemEditTrip = TripsUiTopNavMenuItem & {
  __typename?: "TripsUITopNavMenuItemEditTrip";
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
  sheet: TripsUiEditTripFormSheet;
};

export type TripsUiTopNavMenuItemPrint = TripsUiTopNavMenuItem & {
  __typename?: "TripsUITopNavMenuItemPrint";
  action: UiLinkAction;
  analytics: ClientSideAnalytics;
  item: TripsUiMenuItem;
};

export type TripsUiTopNavToolbar = {
  __typename?: "TripsUITopNavToolbar";
  actionContent: Array<TripsUiTopNavActionContent>;
  toolbar: TripsUiToolbar;
};

export type TripsUiTripContext = {
  __typename?: "TripsUITripContext";
  fromTripId: Scalars["String"]["output"];
  toTripId?: Maybe<Scalars["String"]["output"]>;
};

export interface TripsUiTripContextInput {
  fromTripId: Scalars["String"]["input"];
  toTripId?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsUiTripItemContext = {
  __typename?: "TripsUITripItemContext";
  itemId: Scalars["String"]["output"];
  operationType: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export interface TripsUiTripItemContextInput {
  itemId: Scalars["String"]["input"];
  operationType: Scalars["String"]["input"];
  tripId: Scalars["String"]["input"];
}

export type TripsUiTripItineraryItemResponse = {
  __typename?: "TripsUITripItineraryItemResponse";
  status: TripsUiResponseStatus;
  toast?: Maybe<TripsUiToast>;
};

export type TripsUiTripPlanningPrimer = {
  __typename?: "TripsUITripPlanningPrimer";
  /** @deprecated Use tripsUITripItemContext-itemId instead */
  itemId: Scalars["String"]["output"];
  /** @deprecated Use tripsUITripItemContext-tripId instead */
  tripId: Scalars["String"]["output"];
  tripsUITripItemContext?: Maybe<TripsUiTripItemContext>;
};

export type TripsUiTripPlanningSheet = {
  __typename?: "TripsUITripPlanningSheet";
  toolbar: TripsUiTripPlanningSheetToolbar;
  tripPlanningPrimer: TripsUiTripPlanningPrimer;
};

export type TripsUiTripPlanningSheetToolbar = {
  __typename?: "TripsUITripPlanningSheetToolbar";
  createTripButton: TripsUiSheetToolbarCreateTripButton;
  toolbar: TripsUiSheetToolbar;
};

export type TripsUiTruncatedText = {
  __typename?: "TripsUITruncatedText";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  text: Scalars["String"]["output"];
};

export type TripsUiUnsaveItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUIUnsaveItineraryItem";
  primer: TripsUiUpdateTripItemTripPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiUnsavedItineraryItem = TripsUiItineraryItemSaveToggle & {
  __typename?: "TripsUIUnsavedItineraryItem";
  primer: TripsUiUpdateTripItemTripPrimer;
  toggle: TripsUiFavoriteToggle;
};

export type TripsUiUpdateItemPriceAlertsStatePrimer = {
  __typename?: "TripsUIUpdateItemPriceAlertsStatePrimer";
  alertEnabled: Scalars["Boolean"]["output"];
  itemId: Scalars["String"]["output"];
  subscriptionId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiUpdateTripItemDatesPrimer = {
  __typename?: "TripsUIUpdateTripItemDatesPrimer";
  itemIds: Array<Scalars["String"]["output"]>;
  tripId: Scalars["String"]["output"];
};

export type TripsUiUpdateTripItemDatesResponse = {
  __typename?: "TripsUIUpdateTripItemDatesResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemPriceAlertStatusResponse = {
  __typename?: "TripsUIUpdateTripItemPriceAlertStatusResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemTripPrimer = {
  __typename?: "TripsUIUpdateTripItemTripPrimer";
  itemId: Scalars["String"]["output"];
  operationType: Scalars["String"]["output"];
  tripContext: TripsUiTripContext;
};

export type TripsUiUpdateTripItemTripResponse = {
  __typename?: "TripsUIUpdateTripItemTripResponse";
  status: TripsUiResponseStatus;
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemVoteFailureResponse = {
  __typename?: "TripsUIUpdateTripItemVoteFailureResponse";
  toast: TripsUiToast;
};

export type TripsUiUpdateTripItemVoteResponse = TripsUiUpdateTripItemVoteFailureResponse;

export type TripsUiViewPackageAction = {
  __typename?: "TripsUIViewPackageAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  primer: TripsUiSelectPackagePrimer;
};

export type TripsUiVoteAction = {
  __typename?: "TripsUIVoteAction";
  itemId: Scalars["String"]["output"];
  tripId: Scalars["String"]["output"];
};

export type TripsUiVoter = {
  __typename?: "TripsUIVoter";
  avatar: TripsUiAvatar;
  name: Scalars["String"]["output"];
};

export type TripsUiVotes = {
  __typename?: "TripsUIVotes";
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  primary: Scalars["String"]["output"];
  voters: Array<TripsUiVoter>;
};

export type TripsUiVotesTab = {
  __typename?: "TripsUIVotesTab";
  content: TripsUiVotesTabContent;
  tab: TripsUiTab;
};

export type TripsUiVotesTabContent = TripsUiTabContentEmpty | TripsUiVotes;

export type TripsUnsaveItemFromTripAction = TripsAction & {
  __typename?: "TripsUnsaveItemFromTripAction";
  accessibility?: Maybe<AccessibilityData>;
  analytics: ClientSideAnalytics;
  emitSignals?: Maybe<Array<TripsEmitSignal>>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  itemId: Scalars["String"]["output"];
  /** @deprecated lineOfBusiness has been deprecated in favour of tripEntity */
  lineOfBusiness: LineOfBusinessDomain;
  tripEntity: TripEntity;
  tripItem: TripItem;
};

export type TripsUpdateTrip = {
  __typename?: "TripsUpdateTrip";
  emitSignals?: Maybe<Array<TripsEmitSignal>>;
  overview: TripOverview;
};

export type TripsUpdateTripAction = TripsAction &
  TripsFormSubmitAction & {
    __typename?: "TripsUpdateTripAction";
    analytics: ClientSideAnalytics;
    emitSignals?: Maybe<Array<TripsEmitSignal>>;
    enabledForOfflineView: Scalars["Boolean"]["output"];
    inputIds: Array<Scalars["String"]["output"]>;
    overview: TripOverview;
  };

export type TripsVaConversationProperties = {
  __typename?: "TripsVAConversationProperties";
  launchPoint?: Maybe<Scalars["String"]["output"]>;
  skipWelcome?: Maybe<Scalars["String"]["output"]>;
};

export type TripsVaInitAction = {
  __typename?: "TripsVAInitAction";
  accessibility: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  applicationName?: Maybe<Scalars["String"]["output"]>;
  clientOverrides?: Maybe<TripsVaOverrideData>;
  text: Scalars["String"]["output"];
};

export type TripsVaIntentValue = {
  __typename?: "TripsVAIntentValue";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type TripsVaOverrideData = {
  __typename?: "TripsVAOverrideData";
  conversationProperties?: Maybe<TripsVaConversationProperties>;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]["output"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]["output"]>;
  intentArguments?: Maybe<Array<TripsVaIntentValue>>;
  subscribedEvents?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsValidatedInput = EgdsElement &
  EgdsInputField & {
    __typename?: "TripsValidatedInput";
    egdsElementId: Scalars["String"]["output"];
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    inputType?: Maybe<TripsInputType>;
    instructions?: Maybe<Scalars["String"]["output"]>;
    label?: Maybe<Scalars["String"]["output"]>;
    labelTemplate?: Maybe<EgdsLocalizedText>;
    leftIcon?: Maybe<Icon>;
    placeholder?: Maybe<Scalars["String"]["output"]>;
    readOnly: Scalars["Boolean"]["output"];
    required?: Maybe<Scalars["Boolean"]["output"]>;
    rightIcon?: Maybe<Icon>;
    validations?: Maybe<Array<EgdsInputValidation>>;
    value?: Maybe<Scalars["String"]["output"]>;
  };

export interface TripsValidatedInputAnalytics {
  analytics: ClientSideAnalytics;
  egdsElementId: Scalars["String"]["output"];
}

export type TripsValidatedInputChangeAnalytics = TripsValidatedInputAnalytics & {
  __typename?: "TripsValidatedInputChangeAnalytics";
  analytics: ClientSideAnalytics;
  egdsElementId: Scalars["String"]["output"];
  referenceValue: Scalars["String"]["output"];
};

export type TripsView = {
  __typename?: "TripsView";
  contentType?: Maybe<TripsContentType>;
  customerNotificationBanner?: Maybe<TripsCustomerNotificationQueryParameters>;
  customerNotifications?: Maybe<TripsCustomerNotificationQueryParameters>;
  egTripsCards: Array<EgdsImageCard>;
  egTripsMap?: Maybe<EgdsBasicMap>;
  elements: Array<TripsComposableElement>;
  floatingActionButton?: Maybe<TripsFloatingActionButton>;
  header?: Maybe<TripsViewHeader>;
  messagingCard?: Maybe<TripsSlimCard>;
  pageTitle?: Maybe<Scalars["String"]["output"]>;
  prefetchOperations?: Maybe<Array<TripsPrefetchOperation>>;
  status?: Maybe<TripsResponseStatus>;
  stickyMenu?: Maybe<TripsStickyMenu>;
  /** @deprecated toast attribute is deprecated., replace with tripsSideEffects */
  toast?: Maybe<TripsToast>;
  /** @deprecated TripsMap is no longer used. egTripsMap and egTripsCards should be used together instead. */
  tripsMap?: Maybe<TripsMap>;
  tripsSideEffects?: Maybe<Array<TripsSideEffects>>;
};

export type TripsViewContentItem = {
  __typename?: "TripsViewContentItem";
  action?: Maybe<TripsAction>;
  icon?: Maybe<Icon>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export type TripsViewContentLineItem = {
  __typename?: "TripsViewContentLineItem";
  items: Array<TripsViewContentItem>;
};

export type TripsViewHeader = TripsSignalListener & {
  __typename?: "TripsViewHeader";
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
  signal?: Maybe<TripsSignal>;
  toolbar?: Maybe<TripsToolbar>;
};

export type TripsViewResponse =
  | TripsComposableDrawer
  | TripsFloatingActionDrawer
  | TripsInlinedActionsDrawer
  | TripsView;

export type TripsViewType =
  | "ACTIVITIES_SEARCH_RESULTS"
  | "ASC_ACCEPT"
  | "ASC_CANCEL"
  | "BOOKING_FLIGHT_CANCEL"
  | "BOOKING_FLIGHT_CHANGE"
  | "BOOKING_SERVICING_HEADS_UP"
  | "CAMPAIGNS"
  | "CARS_SEARCH_RESULTS"
  | "CHECKOUT_VAS_CART"
  | "FILTERED"
  | "FIND_ITINERARY_BY_OTHER_EMAIL"
  | "FIND_ITINERARY_BY_USER_EMAIL"
  | "FLIGHTS_SEARCH_RESULTS"
  | "ITEM_DETAILS"
  | "ITEM_ESSENTIAL_INFO"
  | "ITEM_PRICING_AND_REWARDS"
  | "ITEM_VOUCHER"
  | "ITINERARY"
  | "LOB_SELECTION_VIEW"
  | "LODGING_SEARCH_RESULTS"
  | "LOGIN_PROMPT"
  | "MANAGE_BOOKING"
  | "MANAGE_GUESTS"
  | "OVERVIEW"
  | "PACKAGES_FC_SEARCH_RESULTS"
  | "PACKAGES_FHC_SEARCH_RESULTS"
  | "PACKAGES_HC_SEARCH_RESULTS"
  | "PACKAGES_HF_SEARCH_RESULTS"
  | "PENDING_INVITE"
  | "SEARCH_BY_ITINERARY_NUMBER"
  | "SEARCH_BY_ITINERARY_NUMBER_AND_EMAIL"
  | "TRIPS_LIST"
  | "TRIP_ASSIST";

export type TripsVirtualAgentInitAction = TripsAction & {
  __typename?: "TripsVirtualAgentInitAction";
  analytics: ClientSideAnalytics;
  applicationName?: Maybe<Scalars["String"]["output"]>;
  clientOverrides?: Maybe<TripsVirtualAgentOverrideData>;
  enabledForOfflineView: Scalars["Boolean"]["output"];
  pageName?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Request this value from the chatBotConfig query going forward. */
  resource?: Maybe<Uri>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type TripsVirtualAgentOverrideData = {
  __typename?: "TripsVirtualAgentOverrideData";
  conversationProperties?: Maybe<VirtualAgentConversationProperties>;
  enableAutoOpenChatWidget?: Maybe<Scalars["Boolean"]["output"]>;
  enableProactiveConversation?: Maybe<Scalars["Boolean"]["output"]>;
  intentArguments?: Maybe<Array<VirtualAgentIntentValue>>;
  /** @deprecated Please use intentArguments instead */
  intentMessage?: Maybe<VirtualAgentIntentMessage>;
  subscribedEvents?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TripsWebView = {
  __typename?: "TripsWebView";
  clientSideImpression: ClientSideImpressionEventAnalytics;
  messageCard?: Maybe<TripsUiMessagingActionCard>;
  url: Uri;
};

export interface TripsWebViewInput {
  filter?: InputMaybe<Scalars["String"]["input"]>;
  inviteId?: InputMaybe<Scalars["String"]["input"]>;
  screen?: InputMaybe<TripsScreen>;
  tripItemId?: InputMaybe<Scalars["String"]["input"]>;
  tripViewId?: InputMaybe<Scalars["String"]["input"]>;
}

export type TripsWidgetTextWithIconViewModel = {
  __typename?: "TripsWidgetTextWithIconViewModel";
  icon: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

export type TripsWidgetTripDetailItemRelevance = {
  __typename?: "TripsWidgetTripDetailItemRelevance";
  duration: Scalars["Int"]["output"];
  score: Scalars["Int"]["output"];
};

export type TripsWidgetTripProductDetailViewModel = {
  __typename?: "TripsWidgetTripProductDetailViewModel";
  deeplinkUrl: Scalars["URL"]["output"];
  icon: Scalars["String"]["output"];
  subtitleText: Scalars["String"]["output"];
  titleText: Scalars["String"]["output"];
};

export type TripsWishlistPrimer = {
  __typename?: "TripsWishlistPrimer";
  identifier?: Maybe<Scalars["String"]["output"]>;
};

export type TriviaAnswer = {
  __typename?: "TriviaAnswer";
  answer: AppGrowthTextIconLinkListItem;
  id?: Maybe<Scalars["String"]["output"]>;
  result: Array<AppGrowthTextIconLinkListItem>;
};

export interface TriviaAnswerSubmitInput {
  answerId: Scalars["String"]["input"];
  questionId: Scalars["String"]["input"];
}

export type TriviaFinishedQuery = AppGrowthQueryResponse & {
  __typename?: "TriviaFinishedQuery";
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  logo: AppGrowthMediaItem;
  primaryButton?: Maybe<AppGrowthButton>;
  secondaryButton?: Maybe<AppGrowthButton>;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
  subheading?: Maybe<Scalars["String"]["output"]>;
};

export type TriviaHowToQuery = AppGrowthQueryResponse & {
  __typename?: "TriviaHowToQuery";
  actionStart: AppGrowthButton;
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  closeAction?: Maybe<AppGrowthTextIconLinkListItem>;
  heading?: Maybe<Scalars["String"]["output"]>;
  instructions: EgdsOrderedList;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
};

export type TriviaScreen = {
  __typename?: "TriviaScreen";
  /** @deprecated Field should not be used anymore. Will be replaced by navigation */
  closeAction?: Maybe<AppGrowthTextIconLinkListItem>;
  heading?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  navigation?: Maybe<AppGrowthNavigation>;
  possibleAnswers?: Maybe<Array<TriviaAnswer>>;
  primaryButton?: Maybe<AppGrowthButton>;
  text?: Maybe<Scalars["String"]["output"]>;
  time?: Maybe<Scalars["Int"]["output"]>;
  timesUpText?: Maybe<Scalars["String"]["output"]>;
};

export type TriviaStartQuery = AppGrowthQueryResponse & {
  __typename?: "TriviaStartQuery";
  clientSideAnalytics: ClientSideAnalytics;
  clientSideErrorDialog: AppGrowthDialog;
  questions?: Maybe<Array<TriviaScreen>>;
  serverSideErrorDialog?: Maybe<AppGrowthDialog>;
};

export interface TriviaStartRequestInput {
  campaignId: Scalars["String"]["input"];
  triviaId: Scalars["String"]["input"];
}

export interface TriviaSubmitRequestInput {
  campaignId: Scalars["String"]["input"];
  results: Array<TriviaAnswerSubmitInput>;
  triviaId: Scalars["String"]["input"];
}

export type TwoFactorAuthEgClickstreamData = {
  __typename?: "TwoFactorAuthEGClickstreamData";
  event: EgClickstreamEvent;
  experience: EgClickstreamExperience;
};

export type TypeWithIcon = {
  __typename?: "TypeWithIcon";
  icon: Icon;
  type: Scalars["String"]["output"];
};

export type TypeaheadAdapterDetails = {
  __typename?: "TypeaheadAdapterDetails";
  adapterRequest?: Maybe<TypeaheadAdapterRequest>;
  categoryDetails?: Maybe<Array<TypeaheadCategoryDetails>>;
  domainId: Scalars["String"]["output"];
};

export type TypeaheadAdapterRequest = {
  __typename?: "TypeaheadAdapterRequest";
  includeMetadata?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type TypeaheadCategoryDetails = {
  __typename?: "TypeaheadCategoryDetails";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type TypeaheadInfo = {
  __typename?: "TypeaheadInfo";
  allowExactMatch: Scalars["Boolean"]["output"];
  categorize: Scalars["Boolean"]["output"];
  client?: Maybe<Scalars["String"]["output"]>;
  dismissButtonText?: Maybe<Scalars["String"]["output"]>;
  domain?: Maybe<Scalars["String"]["output"]>;
  drivable: Scalars["Boolean"]["output"];
  emptyResultsPlaceholder?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated This will be removed when typeahead service will start using principal token to pull expUserId */
  expUserId?: Maybe<Scalars["String"]["output"]>;
  isDestination: Scalars["Boolean"]["output"];
  /** @deprecated Use typeaheadMultiSelect.isMultiLocation */
  isMultiLocation?: Maybe<Scalars["Boolean"]["output"]>;
  lineOfBusiness?: Maybe<Scalars["String"]["output"]>;
  listTitle: Scalars["Boolean"]["output"];
  maxNumberOfResults?: Maybe<Scalars["Int"]["output"]>;
  packageType?: Maybe<Scalars["String"]["output"]>;
  personalize: Scalars["Boolean"]["output"];
  regionId?: Maybe<Scalars["String"]["output"]>;
  regionType?: Maybe<Scalars["Int"]["output"]>;
  showCurrentLocation?: Maybe<Scalars["Boolean"]["output"]>;
  subLob?: Maybe<Scalars["String"]["output"]>;
  trending: Scalars["Boolean"]["output"];
  typeaheadFeatures?: Maybe<Scalars["String"]["output"]>;
  typeaheadMultiSelect?: Maybe<EgdsTypeaheadMultiSelect>;
};

export type TypeaheadTriggerMenu = {
  __typename?: "TypeaheadTriggerMenu";
  addIcon: Icon;
  title: Scalars["String"]["output"];
  types: Array<TypeWithIcon>;
};

export interface TypingIndicatorEventContentInput {
  start: Scalars["Boolean"]["input"];
}

export interface UiAction {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
}

export type UiActionName = "MODULE_REFRESH" | "TRIGGER_RENDER_QUERY";

export type UiBanner = EgdsElement & {
  __typename?: "UIBanner";
  actions: Array<UiLinkAction>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  fullWidth?: Maybe<Scalars["Boolean"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  message: Scalars["String"]["output"];
  theme?: Maybe<Scalars["String"]["output"]>;
};

export type UiBannerAction = UiBannerLinkAction | UiNotificationAction;

export type UiBannerLinkAction = {
  __typename?: "UIBannerLinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"]["output"];
};

export type UiButton = UiPrimaryButton | UiSecondaryButton;

export type UiComponentsMetadata = {
  __typename?: "UIComponentsMetadata";
  flightsJourneyPlaybackExperience?: Maybe<JourneyExperience>;
  flightsPrefetchSteps?: Maybe<Array<FlightsStep>>;
  flightsSupportedAncillaries?: Maybe<FlightsSupportedAncillaryInfo>;
  multiItemDynamicCrossSellPrimer?: Maybe<MultiItemDynamicCrossSellPrimer>;
  similarFlights?: Maybe<SimilarFlightsPrimer>;
  stepIndicator?: Maybe<FlightsStepIndicator>;
};

export type UiCreatePriceInsightsTrackingAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleAction &
  PriceInsightsTrackingToggleActionData & {
    __typename?: "UICreatePriceInsightsTrackingAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    existingTrackingDialog?: Maybe<PriceInsightsExistingTrackingDialogAction>;
    lowestPredictedPrice?: Maybe<Scalars["Float"]["output"]>;
    lowestPredictedPriceDate?: Maybe<Scalars["String"]["output"]>;
    priceShown: Scalars["String"]["output"];
    priceTrend?: Maybe<Scalars["String"]["output"]>;
    systemNotificationPermissionsDisabledAction: UiDisableSystemNotificationsAction;
    timeSeriesTierShown: Scalars["Int"]["output"];
  };

export type UiDisableSystemNotificationsAction = PriceInsightsTrackingToggleAction & {
  __typename?: "UIDisableSystemNotificationsAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  cancelButtonText: Scalars["String"]["output"];
  confirmationButtonText: Scalars["String"]["output"];
  dialogMessageHeading: Scalars["String"]["output"];
  dialogMessageSubHeading: Scalars["String"]["output"];
  displayAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  egcsDisplayAnalytics?: Maybe<EgClickstreamEvent>;
};

export type UiEmptyState = EgdsElement & {
  __typename?: "UIEmptyState";
  body: Scalars["String"]["output"];
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Icon>;
  primaryButton?: Maybe<UiPrimaryButton>;
  tertiaryButton?: Maybe<UiTertiaryButton>;
};

export type UiFlightAction = UiAction & {
  __typename?: "UIFlightAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
};

export type UiFlightFloatingActionButton = UiFloatingActionButton & {
  __typename?: "UIFlightFloatingActionButton";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  badge?: Maybe<Scalars["Int"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
};

export interface UiFloatingActionButton {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
   *         If need to specify action have the implementation of EGDSButton add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  analytics?: Maybe<ClientSideAnalytics>;
  badge?: Maybe<Scalars["Int"]["output"]>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
  primary?: Maybe<Scalars["String"]["output"]>;
}

export type UiGraphic = Icon | Illustration | Mark;

export type UiLinkAction = UiAction & {
  __typename?: "UILinkAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  analytics: ClientSideAnalytics;
  resource: Uri;
  target: UiLinkTarget;
  useRelativePath: Scalars["Boolean"]["output"];
};

export type UiLinkTarget = "EXTERNAL" | "INTERNAL";

export type UiMessagingCard = EgdsElement & {
  __typename?: "UIMessagingCard";
  actions?: Maybe<UiMessagingCardActions>;
  egdsElementId?: Maybe<Scalars["String"]["output"]>;
  graphic?: Maybe<UiGraphic>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type UiMessagingCardActions = {
  __typename?: "UIMessagingCardActions";
  primary?: Maybe<UiTertiaryButton>;
  secondaries?: Maybe<Array<UiTertiaryButton>>;
};

export type UiNotificationAction = {
  __typename?: "UINotificationAction";
  notificationSlot: NotificationLocationOnPage;
};

export interface UiPill {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of UIPill add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  selected: Scalars["Boolean"]["output"];
}

export type UiPrimaryButton = EgdsButton &
  EgdsElement & {
    __typename?: "UIPrimaryButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export type UiPrimaryFloatingActionButton = EgdsElement &
  UiFloatingActionButton & {
    __typename?: "UIPrimaryFloatingActionButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    badge?: Maybe<Scalars["Int"]["output"]>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export interface UiRemovablePill {
  accessibility?: Maybe<Scalars["String"]["output"]>;
  /**
   * @deprecated
   *         UIAction should not be used as a field because it allows for too many possible types.
   *         If need to specify action have the implementation of UIRemovablePill add a field for action with interface type of less number of possible subtypes
   *         or a union type with a manageable number of members
   *
   */
  action?: Maybe<UiAction>;
  disabled: Scalars["Boolean"]["output"];
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  remove?: Maybe<Icon>;
  selected: Scalars["Boolean"]["output"];
}

export type UiSecondaryButton = EgdsButton &
  EgdsElement & {
    __typename?: "UISecondaryButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export type UiSecondaryFloatingActionButton = EgdsElement &
  UiFloatingActionButton & {
    __typename?: "UISecondaryFloatingActionButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    badge?: Maybe<Scalars["Int"]["output"]>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    impressionAnalytics?: Maybe<ClientSideImpressionEventAnalytics>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export type UiSignal =
  | "ABANDON_BOOKING"
  | "ABANDON_BOOKING_SUCCESS"
  | "BOOKING_ALREADY_BOOKED"
  | "BOOKING_ERROR"
  | "BOOKING_SUCCESS"
  | "BOOKING_SUSPENDED"
  | "CONFIGURATION_ADDED"
  | "CONTINUE_BOOKING"
  | "DUPLICATE_REQUEST"
  | "GENERIC_DOWNSTREAM_ERROR"
  | "INSURANCE_UNAVAILABLE"
  | "INVALID_EMAIL"
  | "OFFER_ADDED"
  | "OFFER_CHANGED"
  | "OFFER_REMOVED"
  | "OFFER_UNAVAILABLE"
  | "OPERATION_FAILED"
  | "PAYMENT_ERROR"
  | "PAYMENT_METHODS_APPLIED"
  | "PAYMENT_METHOD_UPDATED"
  | "PRICE_ADJUSTED"
  | "PRICE_CHANGED"
  | "PROMOTION_CHANGED"
  | "REQUEST_CONFLICT"
  | "SESSION_EXPIRED"
  | "SESSION_TOKEN_UPDATED"
  | "UNKNOWN"
  | "UNRECOVERABLE_FAILURE"
  | "USER_STATE_UPDATED";

export type UiSignalDetails = {
  __typename?: "UISignalDetails";
  description?: Maybe<Scalars["String"]["output"]>;
  reasonUrn: Scalars["String"]["output"];
  summary?: Maybe<Scalars["String"]["output"]>;
};

export interface UiSignalDetailsInput {
  description?: InputMaybe<Scalars["String"]["input"]>;
  reasonUrn: Scalars["String"]["input"];
  summary?: InputMaybe<Scalars["String"]["input"]>;
}

export type UiSignalPayload = {
  __typename?: "UISignalPayload";
  publisherModule: Scalars["String"]["output"];
  signalDetails?: Maybe<Array<UiSignalDetails>>;
  signalUrn: Scalars["String"]["output"];
};

export interface UiSignalPayloadInput {
  publisherModule: Scalars["String"]["input"];
  signalDetails?: InputMaybe<Array<UiSignalDetailsInput>>;
  signalUrn: Scalars["String"]["input"];
}

export type UiSignalV2 = {
  __typename?: "UISignalV2";
  payload: UiSignalPayload;
  topic: Scalars["String"]["output"];
};

export type UiSimpleText = {
  __typename?: "UISimpleText";
  text: Scalars["String"]["output"];
};

export type UiTertiaryButton = EgdsButton &
  EgdsElement & {
    __typename?: "UITertiaryButton";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    /**
     * @deprecated
     *         UIAction should not be used as a field because it allows for too many possible types. Use composition instead.
     *         https://pages.github.expedia.biz/egds/experience-api-docs/docs/schema/shared-types/#do-not-implement-uiaction-interface
     *
     */
    action?: Maybe<UiAction>;
    analytics?: Maybe<ClientSideAnalytics>;
    disabled: Scalars["Boolean"]["output"];
    egdsElementId?: Maybe<Scalars["String"]["output"]>;
    icon?: Maybe<Icon>;
    primary?: Maybe<Scalars["String"]["output"]>;
  };

export type UiTextList = {
  __typename?: "UITextList";
  heading?: Maybe<Scalars["String"]["output"]>;
  texts?: Maybe<Array<Scalars["String"]["output"]>>;
};

export interface UiToggle {
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedLabel: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  /** @deprecated Use checked or unchecked label */
  label: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedLabel: Scalars["String"]["output"];
}

export type UiToolbar = {
  __typename?: "UIToolbar";
  actions: UiToolbarActions;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondaries?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type UiToolbarActions = {
  __typename?: "UIToolbarActions";
  primary: UiTertiaryButton;
  secondaries?: Maybe<Array<UiTertiaryButton>>;
};

export type UiValidateTravelerLoggedInAction = ClientSideErrorRepresentation &
  PriceInsightsTrackingToggleAction & {
    __typename?: "UIValidateTravelerLoggedInAction";
    accessibility?: Maybe<Scalars["String"]["output"]>;
    analytics: ClientSideAnalytics;
    clientSideErrorRepresentationAction?: Maybe<PriceInsightsTrackingRetryDialogAction>;
    clientSideErrorRepresentationDialog?: Maybe<ClientSideErrorRepresentationDialog>;
    egcsDisplayAnalytics?: Maybe<EgClickstreamEvent>;
    signInAction: UiLinkAction;
  };

export interface Uri {
  value: Scalars["String"]["output"];
}

export type UnSupportedBooking = {
  __typename?: "UnSupportedBooking";
  clickstreamPayload: BookingActionsClickStreamAnalytics;
  description: Scalars["String"]["output"];
  goToTrips?: Maybe<UiSecondaryButton>;
  heading: Scalars["String"]["output"];
};

export type UnionText = {
  __typename?: "UnionText";
  link?: Maybe<EgdsInlineLink>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export interface UnitCard {
  removeLink?: Maybe<EgdsStandardLink>;
}

export type UnitOccupancy = {
  __typename?: "UnitOccupancy";
  adults: Scalars["Int"]["output"];
  children: Scalars["Int"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  people: Scalars["Int"]["output"];
};

export type UniversalProfileAboutYouField = {
  __typename?: "UniversalProfileAboutYouField";
  aboutYou: UniversalProfileTextAreaInputField;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileAcceptedFileFormatsValidation = UniversalProfileImageValidation & {
  __typename?: "UniversalProfileAcceptedFileFormatsValidation";
  analytics: Array<UniversalProfileImpressionAnalyticEvent>;
  errorSummary: UniversalProfileErrorSummary;
  fileFormats: Array<Scalars["String"]["output"]>;
};

export type UniversalProfileAccessibilityNeedsField = {
  __typename?: "UniversalProfileAccessibilityNeedsField";
  accessibilityNeeds: UniversalProfileBasicSelect;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileAccountManagementMessagingCard = {
  __typename?: "UniversalProfileAccountManagementMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileAccountManagementOptionType;
};

export type UniversalProfileAccountManagementOptionType = "TRAVELER_ARRANGER";

export type UniversalProfileAccountManagementSettings = {
  __typename?: "UniversalProfileAccountManagementSettings";
  clearSearchAndSavedData: UniversalProfileStandardLink;
  copyDUAID?: Maybe<UniversalProfileStandardLink>;
  deleteAccount?: Maybe<UniversalProfileStandardLink>;
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileAccountManagementMessagingCard>;
  searchHistory?: Maybe<UniversalProfileSearchHistoryComponent>;
};

export type UniversalProfileAccountTakeOverCaptchaWidget = {
  __typename?: "UniversalProfileAccountTakeOverCaptchaWidget";
  links?: Maybe<Array<UniversalProfileAccountTakeOverLink>>;
  placementContext?: Maybe<Array<UniversalProfileAccountTakeOverPlacementContext>>;
};

export type UniversalProfileAccountTakeOverCsrfWidget = {
  __typename?: "UniversalProfileAccountTakeOverCsrfWidget";
  content: Scalars["String"]["output"];
};

export type UniversalProfileAccountTakeOverLink = {
  __typename?: "UniversalProfileAccountTakeOverLink";
  loadBeforeWidget?: Maybe<Scalars["Boolean"]["output"]>;
  mimeType: Scalars["String"]["output"];
  section?: Maybe<Scalars["String"]["output"]>;
  uri: Scalars["String"]["output"];
};

export type UniversalProfileAccountTakeOverPlacementContext = {
  __typename?: "UniversalProfileAccountTakeOverPlacementContext";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type UniversalProfileAccountTakeOverWidget =
  | UniversalProfileAccountTakeOverCaptchaWidget
  | UniversalProfileAccountTakeOverCsrfWidget;

export interface UniversalProfileAccountTakeOverWidgetRequestInput {
  content?: InputMaybe<Scalars["String"]["input"]>;
  type: UniversalProfileAccountTakeOverWidgetTypeRequest;
}

export type UniversalProfileAccountTakeOverWidgetTypeRequest = "CAPTCHA" | "CSRF";

export interface UniversalProfileAction {
  analytics: Array<UniversalProfileAnalyticEvent>;
}

export type UniversalProfileActionResponse =
  | UniversalProfileAdditionalTravelerSuccessResponse
  | UniversalProfileAvatarUploadConfirmationComponent
  | UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse
  | UniversalProfileErrorResponse
  | UniversalProfileSuccessResponse;

export type UniversalProfileAdditionalTravelerContent = {
  __typename?: "UniversalProfileAdditionalTravelerContent";
  addTravelerButton: UniversalProfileButton;
  travelers: Array<UniversalProfileStandardLink>;
};

export interface UniversalProfileAdditionalTravelerContextInput {
  accountTakeOverWidgets?: InputMaybe<Array<UniversalProfileAccountTakeOverWidgetRequestInput>>;
  additionalTravelerIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
}

export type UniversalProfileAdditionalTravelerCreateAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerCreateAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"]["output"];
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileAdditionalTravelerFooter = {
  __typename?: "UniversalProfileAdditionalTravelerFooter";
  doneButton?: Maybe<UniversalProfileButton>;
  removeAdditionalTravelerSheet?: Maybe<UniversalProfileRemoveAdditionalTravelerSheet>;
  removeTravelerButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileAdditionalTravelerForm = {
  __typename?: "UniversalProfileAdditionalTravelerForm";
  additionalTraveler: UniversalProfileInformationBasicForm;
};

export type UniversalProfileAdditionalTravelerFormAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerFormAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"]["output"];
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileAdditionalTravelerInfoAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerInfoAction";
  additionalTravelerId?: Maybe<Scalars["String"]["output"]>;
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileAdditionalTravelerInformation = {
  __typename?: "UniversalProfileAdditionalTravelerInformation";
  basicInformation: UniversalProfileInformation;
  footer: UniversalProfileAdditionalTravelerFooter;
  heading: UniversalProfileHeading;
  moreDetails: UniversalProfileMoreDetails;
};

export type UniversalProfileAdditionalTravelerSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileAdditionalTravelerSuccessResponse = {
  __typename?: "UniversalProfileAdditionalTravelerSuccessResponse";
  clickstreamAnalytics: Array<UniversalProfileAnalyticEvent>;
  message: UniversalProfileToast;
  travelerId: Scalars["String"]["output"];
};

export type UniversalProfileAdditionalTravelerViewAction = UniversalProfileAction & {
  __typename?: "UniversalProfileAdditionalTravelerViewAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"]["output"];
  redirectUrl?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileAdditionalTravelers = {
  __typename?: "UniversalProfileAdditionalTravelers";
  collapseAnalyticEvents?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  container: EgdsExpandoCard;
  content: UniversalProfileExpandoContent;
  expandAnalyticEvents?: Maybe<Array<UniversalProfileAnalyticEvent>>;
};

export type UniversalProfileAddressField = {
  __typename?: "UniversalProfileAddressField";
  addressLine1: UniversalProfileTextInputField;
  addressLine2: UniversalProfileTextInputField;
  city: UniversalProfileTextInputField;
  country: UniversalProfileBasicSelect;
  heading: UniversalProfileContentHeading;
  state: UniversalProfileCountryStateField;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  zipCode: UniversalProfileTextInputField;
};

export interface UniversalProfileAddressInput {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  stateCode?: InputMaybe<Scalars["String"]["input"]>;
  zipCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type UniversalProfileAgeRangeValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileAgeRangeValidationRule";
  errorMessage: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  min: Scalars["Int"]["output"];
};

export interface UniversalProfileAnalyticEvent {
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
}

export type UniversalProfileAnalyticsEventSubType = "BLURRED" | "FOCUSED" | "INPUTTED" | "SUCCEEDED";

export type UniversalProfileAppPermission = {
  __typename?: "UniversalProfileAppPermission";
  cameraAccess: UniversalProfileDialog;
  photoAccess: UniversalProfileDialog;
};

export type UniversalProfileAppearanceField = {
  __typename?: "UniversalProfileAppearanceField";
  appearance: UniversalProfileBasicRadioGroup;
  heading: UniversalProfileHeading;
};

export interface UniversalProfileAtoAction {
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
}

export type UniversalProfileAttribute = UniversalProfileDefaultAttribute;

export type UniversalProfileAvatar = {
  __typename?: "UniversalProfileAvatar";
  action?: Maybe<UniversalProfileAction>;
  egdsAvatar: EgdsAvatar;
};

export type UniversalProfileAvatarChangeComponent = {
  __typename?: "UniversalProfileAvatarChangeComponent";
  acceptedFileFormatsValidation: UniversalProfileAcceptedFileFormatsValidation;
  analytics: Array<UniversalProfileAnalyticEvent>;
  appPermission: UniversalProfileAppPermission;
  avatar: UniversalProfileAvatar;
  choosePhotoButton: UniversalProfileButton;
  choosePhotoSheet?: Maybe<UniversalProfileChoosePhotoSheet>;
  minFileSizeValidation?: Maybe<UniversalProfileFileSizeValidation>;
  minImageDimensionsValidation: UniversalProfileImageDimensionsValidation;
};

export type UniversalProfileAvatarCropComponent = {
  __typename?: "UniversalProfileAvatarCropComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  choosePhotoButton: UniversalProfileButton;
  discardUpdatesDialog: UniversalProfileDialog;
  imageCropper: UniversalProfileImageCropper;
  imageUploadConfiguration: UniversalProfileImageUploadConfiguration;
  maxFileSizeValidation: UniversalProfileFileSizeValidation;
  maxImageDimensionsValidation: UniversalProfileImageDimensionsValidation;
  saveButton: UniversalProfileButton;
  uploadFailureError: UniversalProfileCropperErrorSummary;
  uploadingText: Scalars["String"]["output"];
};

export type UniversalProfileAvatarForm = UniversalProfileAvatarFormComponent | UniversalProfileSignInComponent;

export type UniversalProfileAvatarFormComponent = {
  __typename?: "UniversalProfileAvatarFormComponent";
  changeAvatarForm: UniversalProfileChangeAvatarFormComponent;
};

export type UniversalProfileAvatarUploadConfirmationComponent = {
  __typename?: "UniversalProfileAvatarUploadConfirmationComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  confirmButton: UniversalProfileButton;
  heading: UniversalProfileHeading;
  navigationBar: UniversalProfileNavigationBar;
};

export type UniversalProfileAvatarUploadRequestType = "ADD_IMAGE" | "CHANGE_IMAGE";

export type UniversalProfileBasicCheckBox = UniversalProfileInput & {
  __typename?: "UniversalProfileBasicCheckBox";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBasicCheckBox;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfileBasicInformationDateOfBirthInput {
  day?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
}

export type UniversalProfileBasicInformationForm = {
  __typename?: "UniversalProfileBasicInformationForm";
  basicInformation: UniversalProfileInformationBasicForm;
};

export type UniversalProfileBasicInformationFormAction = UniversalProfileAction & {
  __typename?: "UniversalProfileBasicInformationFormAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileBasicInformationNameInput {
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  middleName?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UniversalProfileBasicInformationRequestInput {
  accessibilityNeeds?: InputMaybe<Scalars["String"]["input"]>;
  bio?: InputMaybe<Scalars["String"]["input"]>;
  dateOfBirth: UniversalProfileBasicInformationDateOfBirthInput;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  name: UniversalProfileBasicInformationNameInput;
}

export type UniversalProfileBasicInformationSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileBasicInformationSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileBasicRadioGroup = UniversalProfileInput & {
  __typename?: "UniversalProfileBasicRadioGroup";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBasicRadioGroup;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileBasicSelect = UniversalProfileInput & {
  __typename?: "UniversalProfileBasicSelect";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBasicSelect;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileBrandPreferences = {
  __typename?: "UniversalProfileBrandPreferences";
  appearance: UniversalProfileAppearanceField;
  copyInformation?: Maybe<UniversalProfileButton>;
  heading: UniversalProfileHeading;
  locationAndLanguage: UniversalProfileLocationAndLanguage;
  type: UniversalProfilePreferencesOptionType;
};

export type UniversalProfileBulletedList = UniversalProfileInput & {
  __typename?: "UniversalProfileBulletedList";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsBulletedList;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileButton = {
  __typename?: "UniversalProfileButton";
  action: UniversalProfileAction;
  button: EgdsButton;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileCallLinksSheet = {
  __typename?: "UniversalProfileCallLinksSheet";
  cancelButton: UniversalProfileButton;
  egdsSheet: EgdsSheet;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  rows: Array<UniversalProfileLinkRow>;
  title: Scalars["String"]["output"];
};

export type UniversalProfileCallPhoneNumberAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCallPhoneNumberAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  phone: TelUri;
};

export type UniversalProfileCancelAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCancelAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCategorySwitchOperator = "OFF" | "ON";

export type UniversalProfileChangeAvatarAction = UniversalProfileAction & {
  __typename?: "UniversalProfileChangeAvatarAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileChangeAvatarFormComponent = {
  __typename?: "UniversalProfileChangeAvatarFormComponent";
  changeSection: UniversalProfileAvatarChangeComponent;
  cropSection: UniversalProfileAvatarCropComponent;
  navigationBar: UniversalProfileNavigationBar;
};

export interface UniversalProfileChangeEmailRequestInput {
  cmsToken: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
}

export type UniversalProfileCheckBoxGroup = UniversalProfileInput & {
  __typename?: "UniversalProfileCheckBoxGroup";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsCheckBoxGroup;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileCheckboxAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileCheckboxAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
  subType: UniversalProfileCheckboxAnalyticsEventSubType;
};

export type UniversalProfileCheckboxAnalyticsEventSubType = "DESELECTED" | "SELECTED";

export type UniversalProfileChoosePhotoSheet = {
  __typename?: "UniversalProfileChoosePhotoSheet";
  cancelButton?: Maybe<UniversalProfileButton>;
  description: Scalars["String"]["output"];
  selectImageButton: UniversalProfileButton;
  takePhotoButton: UniversalProfileButton;
  title: Scalars["String"]["output"];
};

export type UniversalProfileClearHistoryAction = UniversalProfileAction & {
  __typename?: "UniversalProfileClearHistoryAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileClientInfoInput {
  appVersion?: InputMaybe<Scalars["String"]["input"]>;
  channelType: UniversalProfileClientType;
}

export type UniversalProfileClientType = "ANDROID" | "IOS" | "WEB";

export type UniversalProfileCloseAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCloseAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCloseButton = {
  __typename?: "UniversalProfileCloseButton";
  action?: Maybe<UniversalProfileAction>;
};

export type UniversalProfileCommPrefsSmsContactInfoDetails = {
  __typename?: "UniversalProfileCommPrefsSmsContactInfoDetails";
  contentHeading?: Maybe<UniversalProfileContentHeading>;
  countryCode: UniversalProfileBasicSelect;
  heading: UniversalProfileHeading;
  number: UniversalProfileNumberInputField;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileCommunicationCategoryPreferences = {
  __typename?: "UniversalProfileCommunicationCategoryPreferences";
  categories?: Maybe<Array<UniversalProfileCommunicationPreferencesCategorySubSection>>;
  description?: Maybe<UniversalProfileHeading>;
  error?: Maybe<UniversalProfileErrorSummary>;
  saveButton?: Maybe<UniversalProfileButton>;
  unsubscribeField?: Maybe<UniversalProfileCommunicationPreferencesCategorySubSection>;
};

export type UniversalProfileCommunicationChannelPreferences = {
  __typename?: "UniversalProfileCommunicationChannelPreferences";
  description?: Maybe<UniversalProfileHeading>;
  links?: Maybe<Array<UniversalProfileStandardLink>>;
  options?: Maybe<Array<UniversalProfileCommunicationPreferencesOptionCard>>;
};

export interface UniversalProfileCommunicationPreferenceEmailRequestInput {
  categoryConsent: Array<UniversalProfileEmailCommunicationPreferenceUpdateInput>;
  unsubscribeFromAll: Scalars["Boolean"]["input"];
}

export interface UniversalProfileCommunicationPreferenceRequestInput {
  updates: Array<UniversalProfileCommunicationPreferenceUpdateInput>;
}

export interface UniversalProfileCommunicationPreferenceUnsubRequestInput {
  channel: CommunicationChannel;
  checksum: Scalars["String"]["input"];
  egAccountId: Scalars["String"]["input"];
  emlCid?: InputMaybe<Scalars["String"]["input"]>;
  emlDtl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UniversalProfileCommunicationPreferenceUnsubscribeCategoriesRequestInput {
  categoryTypes: Array<UniversalProfileCommunicationPreferencesCategoryType>;
  channel: CommunicationChannel;
  checksum: Scalars["String"]["input"];
  egAccountId: Scalars["String"]["input"];
  emailUnsubscribeAll?: InputMaybe<Scalars["Boolean"]["input"]>;
  emlCid?: InputMaybe<Scalars["String"]["input"]>;
  emlDtl?: InputMaybe<Scalars["String"]["input"]>;
  isStrict: Scalars["Boolean"]["input"];
}

export interface UniversalProfileCommunicationPreferenceUnsubscribePreferenceRequestInput {
  categoryType: UniversalProfileCommunicationPreferencesCategoryType;
  channel: CommunicationChannel;
  checksum: Scalars["String"]["input"];
  egAccountId: Scalars["String"]["input"];
  emlCid?: InputMaybe<Scalars["String"]["input"]>;
  emlDtl?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UniversalProfileCommunicationPreferenceUpdateInput {
  category: UniversalProfileCommunicationPreferencesCategoryType;
  selectedChannels?: InputMaybe<Array<CommunicationChannel>>;
}

export type UniversalProfileCommunicationPreferences = {
  __typename?: "UniversalProfileCommunicationPreferences";
  communicationBrandPreferences: CommunicationPreferencesBrandComponent;
  communicationCommonPreferences: CommunicationPreferencesMainComponent;
};

export type UniversalProfileCommunicationPreferencesCategoryAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesCategoryAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  category?: Maybe<CommunicationPreferencesCategory>;
};

export type UniversalProfileCommunicationPreferencesCategoryFormSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesCategoryFormSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCommunicationPreferencesCategorySubSection = {
  __typename?: "UniversalProfileCommunicationPreferencesCategorySubSection";
  heading?: Maybe<UniversalProfileHeading>;
  options?: Maybe<Array<UniversalProfileCommunicationPreferencesCheckboxInput>>;
};

export type UniversalProfileCommunicationPreferencesCategoryType =
  | "ACCOUNT_SUPPORT"
  | "CONFIRMATION_AND_CRITICAL_UPDATES"
  | "OFFERS_AND_INSPIRATION"
  | "ONE_KEY"
  | "ONE_KEY_MONTHLY_STATEMENTS"
  | "ONE_KEY_REWARD_ACCOUNT_UPDATES"
  | "PARTNERS_OFFERS_WITH_US"
  | "PERSONALIZED_TRIP_RECOMMENDATIONS"
  | "REVIEWS"
  | "SURVEYS"
  | "THE_TRAVEL_EDIT"
  | "TRIP_BOARD_ACTIVITY"
  | "TRIP_PLANNING_ASSISTANCE";

export type UniversalProfileCommunicationPreferencesChannelAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesChannelAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  category?: Maybe<CommunicationPreferencesCategory>;
  channel?: Maybe<CommunicationChannel>;
};

export type UniversalProfileCommunicationPreferencesCheckBoxComponent = {
  __typename?: "UniversalProfileCommunicationPreferencesCheckBoxComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  categoryType?: Maybe<UniversalProfileCommunicationPreferencesCategoryType>;
  channelType?: Maybe<CommunicationChannel>;
  commPrefsSMSContactInfo?: Maybe<UniversalProfileCommunicationPreferencesSmsContactInfoForm>;
  universalProfileBasicCheckBox: UniversalProfileBasicCheckBox;
};

export type UniversalProfileCommunicationPreferencesCheckboxInput = UniversalProfileInput & {
  __typename?: "UniversalProfileCommunicationPreferencesCheckboxInput";
  action?: Maybe<UniversalProfileAction>;
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  description?: Maybe<UniversalProfileHeading>;
  input?: Maybe<EgdsBasicCheckBox>;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfileCommunicationPreferencesContextInput {
  category?: InputMaybe<CommunicationPreferencesCategory>;
  channel?: InputMaybe<CommunicationChannel>;
}

export type UniversalProfileCommunicationPreferencesEmailSelectionComponent = {
  __typename?: "UniversalProfileCommunicationPreferencesEmailSelectionComponent";
  categoryType?: Maybe<UniversalProfileCommunicationPreferencesCategoryType>;
  emailSelectionSwitch?: Maybe<EgdsStandardSwitch>;
  title: Scalars["String"]["output"];
  universalProfileSwitchChangeCriteria: UniversalProfileSwitchChangeCriteria;
};

export type UniversalProfileCommunicationPreferencesExperience =
  | UniversalProfileCommunicationCategoryPreferences
  | UniversalProfileCommunicationChannelPreferences
  | UniversalProfileCommunicationPreferencesLanding
  | UniversalProfileSignInComponent;

export type UniversalProfileCommunicationPreferencesForm =
  | CommunicationPreferencesChannelSelectionForm
  | CommunicationPreferencesForm
  | UniversalProfileCommPrefsSmsContactInfoDetails
  | UniversalProfileCommunicationPreferencesCheckBoxComponent
  | UniversalProfileCommunicationPreferencesManageEmailForm
  | UniversalProfileCommunicationPreferencesSmsContactInfoForm
  | UniversalProfileCommunicationPreferencesUnsubscribeCategoriesForm
  | UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeResponse
  | UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse;

export type UniversalProfileCommunicationPreferencesFormType =
  | "COMMUNICATION_PREFERENCE_CATEGORY_FORM"
  | "COMMUNICATION_PREFERENCE_COMPLETENESS_MODULE"
  | "COMMUNICATION_PREFERENCE_MANAGE_EMAIL_FORM"
  | "COMMUNICATION_PREFERENCE_UNSUBSCRIBE_CATEGORY_FORM"
  | "COMMUNICATION_PREFERENCE_UNSUBSCRIBE_STRICT_FORM";

export type UniversalProfileCommunicationPreferencesLanding = {
  __typename?: "UniversalProfileCommunicationPreferencesLanding";
  description?: Maybe<UniversalProfileHeading>;
  links?: Maybe<Array<UniversalProfileStandardLink>>;
  multiplePOSDescription?: Maybe<UniversalProfileHeading>;
  options?: Maybe<Array<UniversalProfileCommunicationPreferencesSubSectionComponent>>;
};

export type UniversalProfileCommunicationPreferencesManageEmailAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesManageEmailAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  commPrefsBrandExperienceType?: Maybe<UniversalProfileSubExperienceType>;
  type?: Maybe<CommunicationPreferencesOptionsType>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileCommunicationPreferencesManageEmailForm = {
  __typename?: "UniversalProfileCommunicationPreferencesManageEmailForm";
  cancelButton: UniversalProfileButton;
  categorySwitches: Array<UniversalProfileCommunicationPreferencesEmailSelectionComponent>;
  description: Scalars["String"]["output"];
  summary: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  unsubscribeSwitch: UniversalProfileCommunicationPreferencesEmailSelectionComponent;
  updatePreferencesButton: UniversalProfileButton;
};

export type UniversalProfileCommunicationPreferencesManageEmailFormSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesManageEmailFormSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCommunicationPreferencesOptionCard = {
  __typename?: "UniversalProfileCommunicationPreferencesOptionCard";
  action?: Maybe<UniversalProfileAction>;
  card?: Maybe<EgdsStandardMessagingCard>;
};

export type UniversalProfileCommunicationPreferencesSmsContactInfoForm = {
  __typename?: "UniversalProfileCommunicationPreferencesSMSContactInfoForm";
  contactInfoDetails: UniversalProfileCommPrefsSmsContactInfoDetails;
  criteria: UniversalProfileSwitchChangeCriteria;
  description: Scalars["String"]["output"];
  save: UniversalProfileButton;
  title: Scalars["String"]["output"];
};

export type UniversalProfileCommunicationPreferencesSubSectionComponent = {
  __typename?: "UniversalProfileCommunicationPreferencesSubSectionComponent";
  heading?: Maybe<UniversalProfileHeading>;
  options?: Maybe<Array<UniversalProfileCommunicationPreferencesOptionCard>>;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeCategoriesForm = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeCategoriesForm";
  categories: Array<UniversalProfileCommunicationPreferencesCheckBoxComponent>;
  heading: UniversalProfileHeading;
  saveButton: UniversalProfileButton;
  unsubscribeAll: UniversalProfileCommunicationPreferencesCheckBoxComponent;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeCategoriesResponse";
  analytics: Array<UniversalProfileAnalyticEvent>;
  heading: UniversalProfileHeading;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeResponse = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeResponse";
  analytics: Array<UniversalProfileAnalyticEvent>;
  button: UniversalProfileButton;
  heading: UniversalProfileHeading;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeSaveAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse = {
  __typename?: "UniversalProfileCommunicationPreferencesUnsubscribeSignoutResponse";
  heading: UniversalProfileHeading;
  signOutLauncher?: Maybe<UniversalProfileSignOutLauncher>;
};

export type UniversalProfileCompletenessModule = {
  __typename?: "UniversalProfileCompletenessModule";
  prompts: Array<UniversalProfileCompletenessModulePrompt>;
};

export type UniversalProfileCompletenessModuleAddMobileDismissAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModuleAddMobileDismissAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModuleAddMobilePresentedAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModuleAddMobilePresentedAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModuleComponent =
  | UniversalProfileCompletenessModule
  | UniversalProfileSignInComponent;

export type UniversalProfileCompletenessModuleConfirmMobileDismissAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModuleConfirmMobileDismissAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModuleConfirmMobilePresentedAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModuleConfirmMobilePresentedAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileCompletenessModuleContextInput {
  completenessModuleRequest?: InputMaybe<UniversalProfileCompletenessModuleRequestInput>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
}

export type UniversalProfileCompletenessModuleEmailDismissAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModuleEmailDismissAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModuleEmailEnableAction = UniversalProfileAction &
  UniversalProfileAtoAction & {
    __typename?: "UniversalProfileCompletenessModuleEmailEnableAction";
    accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
    analytics: Array<UniversalProfileAnalyticEvent>;
  };

export type UniversalProfileCompletenessModuleEmailPresentedAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModuleEmailPresentedAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModulePrompt = {
  __typename?: "UniversalProfileCompletenessModulePrompt";
  action?: Maybe<UniversalProfileAction>;
  buttons: Array<UniversalProfileButton>;
  graphic: UiGraphic;
  subtitle: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  type: UniversalProfileCompletenessModulePromptType;
};

export type UniversalProfileCompletenessModulePromptType =
  | "ADD_MOBILE_NUMBER"
  | "CONFIRM_MOBILE_NUMBER"
  | "EMAIL_PREFERENCES"
  | "ONE_KEY_CREDIT_CARD"
  | "PUSH_NOTIFICATION_PREFERENCES";

export type UniversalProfileCompletenessModulePushDismissAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModulePushDismissAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModulePushEnableAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModulePushEnableAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCompletenessModulePushPresentedAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCompletenessModulePushPresentedAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileCompletenessModuleRequestInput {
  emailPreferences?: InputMaybe<EmailPreferencesCompletenessModuleRequestInput>;
  mobileNumber?: InputMaybe<MobileNumberCompletenessModuleRequestInput>;
  pushNotificationPreferences?: InputMaybe<PushNotificationPreferencesCompletenessModuleRequestInput>;
}

export interface UniversalProfileConfirmAvatarUploadRequestInput {
  uploadRequestType: UniversalProfileAvatarUploadRequestType;
}

export type UniversalProfileConfirmationLeavingView = {
  __typename?: "UniversalProfileConfirmationLeavingView";
  confirmationLeaving: UniversalProfileBulletedList;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileContactInformationForm = {
  __typename?: "UniversalProfileContactInformationForm";
  contactInformation: UniversalProfileInformationBasicForm;
};

export type UniversalProfileContactInformationFormAction = UniversalProfileAction & {
  __typename?: "UniversalProfileContactInformationFormAction";
  additionalTravelerIdentifier?: Maybe<Scalars["String"]["output"]>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileContactInformationRequestInput {
  address: UniversalProfileAddressInput;
  emergencyContact: UniversalProfileEmergencyContactInput;
  phone: UniversalProfilePhoneInput;
}

export type UniversalProfileContactInformationSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileContactInformationSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileContentHeading = {
  __typename?: "UniversalProfileContentHeading";
  description?: Maybe<Scalars["String"]["output"]>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export interface UniversalProfileContextInput {
  accountTakeOverWidgets?: InputMaybe<Array<UniversalProfileAccountTakeOverWidgetRequestInput>>;
  additionalTravelerIdentifier?: InputMaybe<Scalars["String"]["input"]>;
  cmsToken?: InputMaybe<Scalars["String"]["input"]>;
  communicationPreferencesCategoryType?: InputMaybe<UniversalProfileCommunicationPreferencesCategoryType>;
  communicationPreferencesFormType?: InputMaybe<UniversalProfileCommunicationPreferencesFormType>;
  communicationPreferencesUnsubscribeContext?: InputMaybe<UniversalProfileCommunicationPreferenceUnsubRequestInput>;
  completenessModulePromptType?: InputMaybe<UniversalProfileCompletenessModulePromptType>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
  isPushDeviceSettingsEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  settingsFormType?: InputMaybe<UniversalProfileSettingsFormType>;
  subExperienceType?: InputMaybe<UniversalProfileSubExperienceType>;
}

export type UniversalProfileContinueAction = UniversalProfileAction & {
  __typename?: "UniversalProfileContinueAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCopySupportInfoAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCopySupportInfoAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCountryField = {
  __typename?: "UniversalProfileCountryField";
  country: UniversalProfileBasicRadioGroup;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileCountryForm = {
  __typename?: "UniversalProfileCountryForm";
  confirmation: UniversalProfileSettingsBasicForm;
  country: UniversalProfileSettingsBasicForm;
};

export type UniversalProfileCountrySaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileCountrySaveAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileCountryStateField = {
  __typename?: "UniversalProfileCountryStateField";
  defaultFieldIdentifier: Scalars["String"]["output"];
  fieldIdentificationCriteria?: Maybe<Array<UniversalProfileFieldIdentificationCriteria>>;
  fields: Array<UniversalProfileInput>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileCropperErrorSummary = {
  __typename?: "UniversalProfileCropperErrorSummary";
  analytics: Array<UniversalProfileAnalyticEvent>;
  errorSummary: UniversalProfileErrorSummary;
};

export type UniversalProfileDashboard = UniversalProfileDashboardComponent | UniversalProfileSignInComponent;

export type UniversalProfileDashboardComponent = {
  __typename?: "UniversalProfileDashboardComponent";
  accountUpgradeStatus?: Maybe<Scalars["String"]["output"]>;
  heading: UniversalProfileHeading;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  options: Array<UniversalProfileDashboardMessagingCard>;
  publicProfileOptions: Array<UniversalProfileDashboardMessagingCard>;
  signInButton?: Maybe<UniversalProfileButton>;
  signOutLauncher?: Maybe<UniversalProfileSignOutLauncher>;
};

export type UniversalProfileDashboardMessagingCard = {
  __typename?: "UniversalProfileDashboardMessagingCard";
  action: UniversalProfileAction;
  analyticEvents?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  analytics?: Maybe<Array<UniversalProfileInteractionAnalyticEvent>>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileDashboardOptionType;
};

export type UniversalProfileDashboardOptionType =
  | "COMMUNICATIONS"
  | "CONNECTIONS"
  | "COUPONS"
  | "CREDITS"
  | "EG_LABS"
  | "HELP"
  | "LEGAL"
  | "PAYMENTS"
  | "PROFILE"
  | "PUBLIC_PROFILE"
  | "REVIEWS"
  | "SETTINGS";

export type UniversalProfileDateField = {
  __typename?: "UniversalProfileDateField";
  day?: Maybe<UniversalProfileNumberInputField>;
  heading?: Maybe<UniversalProfileContentHeading>;
  month?: Maybe<UniversalProfileNumberInputField>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
  year?: Maybe<UniversalProfileNumberInputField>;
};

export type UniversalProfileDateOfBirthField = {
  __typename?: "UniversalProfileDateOfBirthField";
  day: UniversalProfileNumberInputField;
  heading: UniversalProfileContentHeading;
  month: UniversalProfileNumberInputField;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
  year: UniversalProfileNumberInputField;
};

export type UniversalProfileDefaultAttribute = {
  __typename?: "UniversalProfileDefaultAttribute";
  analytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  attribute: Scalars["String"]["output"];
  message?: Maybe<Scalars["String"]["output"]>;
  updateAttribute?: Maybe<UniversalProfileStandardLink>;
  value: Scalars["String"]["output"];
};

export type UniversalProfileDeleteAccountAction = UniversalProfileAction & {
  __typename?: "UniversalProfileDeleteAccountAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileDeleteAccountForm = {
  __typename?: "UniversalProfileDeleteAccountForm";
  accountVerification: UniversalProfileSettingsBasicForm;
  confirmation?: Maybe<UniversalProfileSettingsBasicForm>;
  reason: UniversalProfileSettingsBasicForm;
  unableDelete?: Maybe<UniversalProfileSettingsBasicForm>;
};

export type UniversalProfileDeleteAccountViewAction = UniversalProfileAction & {
  __typename?: "UniversalProfileDeleteAccountViewAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileDeleteMobileNumberAction = UniversalProfileAction & {
  __typename?: "UniversalProfileDeleteMobileNumberAction";
  accountTakeOverWidgets?: Maybe<Array<UniversalProfileAccountTakeOverWidget>>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileDeletePhoneNumberSheet = {
  __typename?: "UniversalProfileDeletePhoneNumberSheet";
  analytics: Array<UniversalProfileAnalyticEvent>;
  cancelButton: UniversalProfileButton;
  deleteButton: UniversalProfileButton;
  description: Scalars["String"]["output"];
  egdsSheet: EgdsSheet;
  title: Scalars["String"]["output"];
};

export interface UniversalProfileDeleteVerifiedPhoneRequestInput {
  flowEntrypointIdentifier?: InputMaybe<FlowEntrypointIdentifier>;
  intent?: InputMaybe<UniversalProfileVerifiedPhoneIntent>;
}

export type UniversalProfileDependsOnCriteria = {
  __typename?: "UniversalProfileDependsOnCriteria";
  dependantFieldId: Scalars["String"]["output"];
  field: Scalars["String"]["output"];
  regex: Scalars["String"]["output"];
};

export type UniversalProfileDependsOnFieldIdentificationCriteria = {
  __typename?: "UniversalProfileDependsOnFieldIdentificationCriteria";
  dependsOnFieldIdentifier: Scalars["String"]["output"];
  dependsOnFieldValues: Array<Scalars["String"]["output"]>;
  fieldIdentifier: Scalars["String"]["output"];
  operator: UniversalProfileDependsOnFieldIdentificationCriteriaOperator;
};

export type UniversalProfileDependsOnFieldIdentificationCriteriaOperator = "EXCLUDES" | "INCLUDES";

export type UniversalProfileDependsOnValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileDependsOnValidationRule";
  analytics: Array<UniversalProfileAnalyticEvent>;
  criteria: UniversalProfileDependsOnCriteria;
  errorMessage: Scalars["String"]["output"];
  fieldIdentifier: Scalars["String"]["output"];
  required: Scalars["Boolean"]["output"];
};

export type UniversalProfileDialog = {
  __typename?: "UniversalProfileDialog";
  analytics: Array<UniversalProfileAnalyticEvent>;
  cancelButton: UniversalProfileButton;
  confirmButton: UniversalProfileButton;
  heading: UniversalProfileHeading;
  title: Scalars["String"]["output"];
};

export interface UniversalProfileEmailCommunicationPreferenceUpdateInput {
  category: UniversalProfileCommunicationPreferencesCategoryType;
  emailOptIn: Scalars["Boolean"]["input"];
}

export type UniversalProfileEmailEditAction = UniversalProfileAction & {
  __typename?: "UniversalProfileEmailEditAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileEmailField = {
  __typename?: "UniversalProfileEmailField";
  emailField: UniversalProfileEmailInputField;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileEmailForm = {
  __typename?: "UniversalProfileEmailForm";
  email: UniversalProfileSettingsBasicForm;
};

export type UniversalProfileEmailInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileEmailInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsEmailInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileEmailView = {
  __typename?: "UniversalProfileEmailView";
  email: UniversalProfileAttribute;
  hint?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileEmergencyContactField = {
  __typename?: "UniversalProfileEmergencyContactField";
  contactName: UniversalProfileTextInputField;
  heading: UniversalProfileContentHeading;
  phone: UniversalProfilePhoneField;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfileEmergencyContactInput {
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  phone: UniversalProfilePhoneInput;
}

export type UniversalProfileErrorField = {
  __typename?: "UniversalProfileErrorField";
  errorMessage: Scalars["String"]["output"];
  /** @deprecated To be replaced by universalProfileIdentifier */
  id: Scalars["String"]["output"];
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileErrorResponse = {
  __typename?: "UniversalProfileErrorResponse";
  accountTakeOverWidgets?: Maybe<Array<UniversalProfileAccountTakeOverWidget>>;
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  clickstreamAnalytics: Array<UniversalProfileAnalyticEvent>;
  errorFields?: Maybe<Array<UniversalProfileErrorField>>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
};

export type UniversalProfileErrorSummary = {
  __typename?: "UniversalProfileErrorSummary";
  summary: UiBanner;
};

export type UniversalProfileExpandoContent = UniversalProfileAdditionalTravelerContent | UniversalProfileLegalContent;

export type UniversalProfileExperience =
  | UniversalProfileAdditionalTravelerInformation
  | UniversalProfileCommunicationPreferences
  | UniversalProfileHelpAndFeedbackComponent
  | UniversalProfileHelpAndFeedbackHelpCenterComponent
  | UniversalProfileLegalInformation
  | UniversalProfileRedirectComponent
  | UniversalProfileSessionManagementInformation
  | UniversalProfileSignInComponent
  | UniversalProfileTravelerDocumentsInformation
  | UniversalProfileTravelerInformation
  | UniversalProfileTravelerSettings;

export type UniversalProfileExperienceType =
  | "ADDITIONAL_TRAVELER"
  | "COMMUNICATION_PREFERENCES"
  | "HELP"
  | "LEGAL"
  | "PROFILE"
  | "SESSION_MANAGEMENT"
  | "SETTINGS"
  | "TRAVELER_DOCUMENTS";

export type UniversalProfileExternalRedirectAction = UniversalProfileAction & {
  __typename?: "UniversalProfileExternalRedirectAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileFieldIdentificationCriteria = UniversalProfileDependsOnFieldIdentificationCriteria;

export type UniversalProfileFileSizeValidation = UniversalProfileImageValidation & {
  __typename?: "UniversalProfileFileSizeValidation";
  analytics: Array<UniversalProfileImpressionAnalyticEvent>;
  errorSummary: UniversalProfileErrorSummary;
  sizeInMb: Scalars["Float"]["output"];
};

export type UniversalProfileForgotPasswordAction = UniversalProfileAction & {
  __typename?: "UniversalProfileForgotPasswordAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileFormSubmitConfirmationChannelBasedCriteria = {
  __typename?: "UniversalProfileFormSubmitConfirmationChannelBasedCriteria";
  channels: Array<CommunicationChannel>;
  fieldIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileFormSubmitConfirmationCriteria = UniversalProfileFormSubmitConfirmationChannelBasedCriteria;

export type UniversalProfileFormSubmitConfirmationDialog = {
  __typename?: "UniversalProfileFormSubmitConfirmationDialog";
  cancelButton: UniversalProfileButton;
  confirmButton: UniversalProfileButton;
  contentHeading?: Maybe<UniversalProfileContentHeading>;
  formConfirmationCriteria?: Maybe<UniversalProfileFormSubmitConfirmationCriteria>;
  heading: UniversalProfileHeading;
  pushDeviceSettings?: Maybe<UiBanner>;
};

export type UniversalProfileGenderField = {
  __typename?: "UniversalProfileGenderField";
  gender: UniversalProfileBasicRadioGroup;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileHeading = {
  __typename?: "UniversalProfileHeading";
  description?: Maybe<Scalars["String"]["output"]>;
  iconList?: Maybe<Array<UiGraphic>>;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileHelpAndFeedbackComponent = {
  __typename?: "UniversalProfileHelpAndFeedbackComponent";
  analytics: Array<UniversalProfileAnalyticEvent>;
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileHelpAndFeedbackMessagingCard>;
  showVirtualAgent?: Maybe<Scalars["Boolean"]["output"]>;
  virtualAgentComponent?: Maybe<UniversalProfileVirtualAgentComponent>;
};

export type UniversalProfileHelpAndFeedbackHelpCenterComponent = {
  __typename?: "UniversalProfileHelpAndFeedbackHelpCenterComponent";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileHelpAndFeedbackHelpCenterMessagingCard>;
};

export type UniversalProfileHelpAndFeedbackHelpCenterMessagingCard = {
  __typename?: "UniversalProfileHelpAndFeedbackHelpCenterMessagingCard";
  action: UniversalProfileAction;
  card: EgdsStandardMessagingCard;
  type: HelpAndFeedbackHelpCenterOptionsType;
};

export type UniversalProfileHelpAndFeedbackMessagingCard = {
  __typename?: "UniversalProfileHelpAndFeedbackMessagingCard";
  action: UniversalProfileAction;
  card: EgdsStandardMessagingCard;
  sheet?: Maybe<UniversalProfileSheet>;
  type: HelpAndFeedbackOptionsType;
};

export type UniversalProfileImageCropper = {
  __typename?: "UniversalProfileImageCropper";
  dragAnalytics: Array<UniversalProfileInteractionAnalyticEvent>;
  zoomAnalytics: Array<UniversalProfileInteractionAnalyticEvent>;
  zoomHeading: Scalars["String"]["output"];
  zoomSensitivity: Scalars["Float"]["output"];
};

export type UniversalProfileImageDimensionsValidation = UniversalProfileImageValidation & {
  __typename?: "UniversalProfileImageDimensionsValidation";
  analytics: Array<UniversalProfileImpressionAnalyticEvent>;
  errorSummary: UniversalProfileErrorSummary;
  imageDimensions: Dimensions;
};

export type UniversalProfileImageUploadCategory = "PROFILE_AVATAR";

export type UniversalProfileImageUploadConfiguration = {
  __typename?: "UniversalProfileImageUploadConfiguration";
  category: UniversalProfileImageUploadCategory;
  uploadUrl: Scalars["String"]["output"];
};

export interface UniversalProfileImageValidation {
  errorSummary: UniversalProfileErrorSummary;
}

export type UniversalProfileImpressionAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileImpressionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type UniversalProfileInformation = {
  __typename?: "UniversalProfileInformation";
  edit: UniversalProfileButton;
  heading: UniversalProfileHeading;
  information: Array<UniversalProfileAttribute>;
};

export type UniversalProfileInformationBasicForm = {
  __typename?: "UniversalProfileInformationBasicForm";
  cancelButton?: Maybe<UniversalProfileButton>;
  closeButton?: Maybe<UniversalProfileCloseButton>;
  content: Array<UniversalProfileInformationFormContent>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  heading: UniversalProfileHeading;
  impression: UniversalProfileImpressionAnalyticEvent;
  saveButton?: Maybe<UniversalProfileButton>;
  title: Scalars["String"]["output"];
};

export type UniversalProfileInformationForm =
  | UniversalProfileAdditionalTravelerForm
  | UniversalProfileBasicInformationForm
  | UniversalProfileContactInformationForm
  | UniversalProfileSignInComponent
  | UniversalProfileTravelerDocumentForm
  | UniversalProfileTsaInformationForm;

export type UniversalProfileInformationFormContent =
  | UniversalProfileAboutYouField
  | UniversalProfileAccessibilityNeedsField
  | UniversalProfileAddressField
  | UniversalProfileCloseButton
  | UniversalProfileDateField
  | UniversalProfileDateOfBirthField
  | UniversalProfileEmailField
  | UniversalProfileEmailView
  | UniversalProfileEmergencyContactField
  | UniversalProfileGenderField
  | UniversalProfileKnownTravelerNumberField
  | UniversalProfileNameField
  | UniversalProfilePassportField
  | UniversalProfilePhoneField
  | UniversalProfilePhoneView
  | UniversalProfileRedressNumberField;

export type UniversalProfileInformationFormType =
  | "COMMUNICATION_PREFERENCE_UNSUB"
  | "COMPLETENESS_MODULE"
  | "CONFIRM_AVATAR_UPLOAD"
  | "DELETE_VERIFIED_PHONE_NUMBER"
  | "UPDATE_ADDITIONAL_TRAVELER_INFO"
  | "UPDATE_BASIC_INFORMATION"
  | "UPDATE_COMMUNICATION_PREFERENCE"
  | "UPDATE_CONTACT_INFORMATION"
  | "UPDATE_CONTACT_INFORMATION_EMAIL"
  | "UPDATE_CONTACT_INFORMATION_PHONE_NUMBER"
  | "UPDATE_PASSPORT_INFO"
  | "UPDATE_TRAVELER_DOCUMENT"
  | "UPDATE_TSA_INFORMATION";

export interface UniversalProfileInput {
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
}

export type UniversalProfileIntegerRangeValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileIntegerRangeValidationRule";
  errorMessage: Scalars["String"]["output"];
  max: Scalars["Int"]["output"];
  min: Scalars["Int"]["output"];
};

export type UniversalProfileInteractionAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileInteractionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
  subType?: Maybe<UniversalProfileAnalyticsEventSubType>;
};

export type UniversalProfileKnownTravelerNumberField = {
  __typename?: "UniversalProfileKnownTravelerNumberField";
  heading: UniversalProfileContentHeading;
  knownTravelerNumber: UniversalProfileTsaTextInputField;
};

export type UniversalProfileLanguageField = {
  __typename?: "UniversalProfileLanguageField";
  heading: UniversalProfileContentHeading;
  language: UniversalProfileBasicRadioGroup;
};

export type UniversalProfileLanguageForm = {
  __typename?: "UniversalProfileLanguageForm";
  language: UniversalProfileSettingsBasicForm;
};

export type UniversalProfileLegal = {
  __typename?: "UniversalProfileLegal";
  container: EgdsExpandoCard;
  content: UniversalProfileExpandoContent;
};

export type UniversalProfileLegalAction = UniversalProfileAction & {
  __typename?: "UniversalProfileLegalAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileLegalContent = {
  __typename?: "UniversalProfileLegalContent";
  links: Array<UniversalProfileStandardLink>;
};

export type UniversalProfileLegalInformation = {
  __typename?: "UniversalProfileLegalInformation";
  analytics: Array<UniversalProfileAnalyticEvent>;
  legal?: Maybe<UniversalProfileLegal>;
};

export type UniversalProfileLinkRow = UniversalProfilePhoneLinkRow;

export type UniversalProfileLocationAndLanguage = {
  __typename?: "UniversalProfileLocationAndLanguage";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileLocationAndLanguageMessagingCard>;
};

export type UniversalProfileLocationAndLanguageMessagingCard = {
  __typename?: "UniversalProfileLocationAndLanguageMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  enabled: Scalars["Boolean"]["output"];
  type: UniversalProfileLocationAndLanguageOptionType;
};

export type UniversalProfileLocationAndLanguageOptionType = "COUNTY" | "CURRENCY" | "LANGUAGE";

export type UniversalProfileLoginInformationSettings = {
  __typename?: "UniversalProfileLoginInformationSettings";
  heading?: Maybe<UniversalProfileHeading>;
  options: Array<UniversalProfileLoginInformationSettingsMessagingCard>;
  signInButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileLoginInformationSettingsMessagingCard = {
  __typename?: "UniversalProfileLoginInformationSettingsMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  sheet?: Maybe<UniversalProfileDeletePhoneNumberSheet>;
  type: UniversalProfileLoginInformationSettingsOptionType;
};

export type UniversalProfileLoginInformationSettingsOptionType =
  | "CHANGE_PASSWORD"
  | "CONNECTED_DEVICES"
  | "DELETE_PHONE_NUMBER"
  | "EMAIL"
  | "PHONE_NUMBER";

export type UniversalProfileLoyaltyBadge = {
  __typename?: "UniversalProfileLoyaltyBadge";
  creditCardIcon?: Maybe<UiGraphic>;
  separator?: Maybe<Scalars["String"]["output"]>;
  tier?: Maybe<UniversalProfileStandardBadge>;
};

export type UniversalProfileMinDateValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileMinDateValidationRule";
  errorMessage: Scalars["String"]["output"];
  minDay?: Maybe<Scalars["Int"]["output"]>;
  minMonth?: Maybe<Scalars["Int"]["output"]>;
  minYear?: Maybe<Scalars["Int"]["output"]>;
};

export type UniversalProfileMoreDetails = {
  __typename?: "UniversalProfileMoreDetails";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfileMoreDetailsMessagingCard>;
};

export type UniversalProfileMoreDetailsMessagingCard = {
  __typename?: "UniversalProfileMoreDetailsMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  type: UniversalProfileMoreDetailsOptionType;
};

export type UniversalProfileMoreDetailsOptionType =
  | "AIRPORT_SECURITY"
  | "FLIGHT_PREFERENCES"
  | "REWARD_PROGRAM"
  | "TRAVELER_DOCUMENTS";

export type UniversalProfileNameField = {
  __typename?: "UniversalProfileNameField";
  firstName: UniversalProfileTextInputField;
  heading: UniversalProfileContentHeading;
  lastName: UniversalProfileTextInputField;
  middleName?: Maybe<UniversalProfileTextInputField>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileNavigationBar = {
  __typename?: "UniversalProfileNavigationBar";
  cancelButton?: Maybe<UniversalProfileButton>;
  dismissAction: UniversalProfileCloseAction;
  heading: UniversalProfileHeading;
};

export type UniversalProfileNumberInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileNumberInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsNumberInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileOpenPermissionSettingsAction = UniversalProfileAction & {
  __typename?: "UniversalProfileOpenPermissionSettingsAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileOpenRegionPickerAction = UniversalProfileAction & {
  __typename?: "UniversalProfileOpenRegionPickerAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileOutcomeAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfileOutcomeAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type UniversalProfilePageViewAnalyticEvent = UniversalProfileAnalyticEvent & {
  __typename?: "UniversalProfilePageViewAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type UniversalProfilePassport = {
  __typename?: "UniversalProfilePassport";
  addExpiryDateField?: Maybe<UniversalProfilePassportAddExpiryDateField>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  expiryDateField?: Maybe<UniversalProfileDateField>;
  heading?: Maybe<UniversalProfileHeading>;
  passportField?: Maybe<UniversalProfilePassportField>;
  removeButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfilePassportAddExpiryDateField = {
  __typename?: "UniversalProfilePassportAddExpiryDateField";
  addDate?: Maybe<UniversalProfileStandardLink>;
  heading?: Maybe<UniversalProfileContentHeading>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export interface UniversalProfilePassportExpiryDateInput {
  day?: InputMaybe<Scalars["Int"]["input"]>;
  month?: InputMaybe<Scalars["Int"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
}

export type UniversalProfilePassportField = {
  __typename?: "UniversalProfilePassportField";
  country: UniversalProfileBasicSelect;
  heading?: Maybe<UniversalProfileContentHeading>;
  passportNumber: UniversalProfileTextInputField;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
};

export interface UniversalProfilePassportInformationRequestInput {
  country?: InputMaybe<Scalars["String"]["input"]>;
  expiryDate?: InputMaybe<UniversalProfilePassportExpiryDateInput>;
  passportNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export type UniversalProfilePasswordCheckAction = UniversalProfileAction & {
  __typename?: "UniversalProfilePasswordCheckAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfilePasswordEditAction = UniversalProfileAction & {
  __typename?: "UniversalProfilePasswordEditAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfilePasswordField = {
  __typename?: "UniversalProfilePasswordField";
  heading: UniversalProfileContentHeading;
  password: UniversalProfilePasswordInputField;
};

export type UniversalProfilePasswordForm = {
  __typename?: "UniversalProfilePasswordForm";
  newPassword: UniversalProfileSettingsBasicForm;
  validateAccount: UniversalProfileSettingsBasicForm;
};

export type UniversalProfilePasswordInputField = UniversalProfileInput & {
  __typename?: "UniversalProfilePasswordInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsPasswordInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfilePhoneField = {
  __typename?: "UniversalProfilePhoneField";
  countryCode: UniversalProfileBasicSelect;
  heading: UniversalProfileContentHeading;
  number: UniversalProfileNumberInputField;
  smsInfo?: Maybe<UniversalProfileBasicCheckBox>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export interface UniversalProfilePhoneInput {
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  number?: InputMaybe<Scalars["String"]["input"]>;
  smsInfo?: InputMaybe<Scalars["Boolean"]["input"]>;
}

export type UniversalProfilePhoneLinkRow = {
  __typename?: "UniversalProfilePhoneLinkRow";
  action: UniversalProfileAction;
  heading?: Maybe<EgdsSpannableText>;
  leadingIcon: Icon;
};

export type UniversalProfilePhoneView = {
  __typename?: "UniversalProfilePhoneView";
  hint?: Maybe<Scalars["String"]["output"]>;
  phone: UniversalProfileAttribute;
  smsInfo?: Maybe<UniversalProfileBasicCheckBox>;
};

export type UniversalProfilePreferences = {
  __typename?: "UniversalProfilePreferences";
  heading: UniversalProfileHeading;
  options: Array<UniversalProfilePreferencesMessagingCard>;
  subOptions: Array<UniversalProfileBrandPreferences>;
};

export type UniversalProfilePreferencesMessagingCard = {
  __typename?: "UniversalProfilePreferencesMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card: EgdsStandardMessagingCard;
  disable?: Maybe<Scalars["Boolean"]["output"]>;
  type: UniversalProfilePreferencesOptionType;
};

export type UniversalProfilePreferencesOptionType = "COUNTRY" | "CURRENCY" | "DISPLAY_APPEARANCE" | "LANGUAGE";

export type UniversalProfileReasonLeavingField = {
  __typename?: "UniversalProfileReasonLeavingField";
  checkboxGroup: UniversalProfileCheckBoxGroup;
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileRedirectAction = UniversalProfileAction & {
  __typename?: "UniversalProfileRedirectAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"]["output"];
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileRedirectComponent = {
  __typename?: "UniversalProfileRedirectComponent";
  action: UniversalProfileRedirectAction;
};

export type UniversalProfileRedressNumberField = {
  __typename?: "UniversalProfileRedressNumberField";
  heading: UniversalProfileContentHeading;
  redressNumber: UniversalProfileTsaTextInputField;
};

export type UniversalProfileRegexValidationRule = UniversalProfileValidationRule & {
  __typename?: "UniversalProfileRegexValidationRule";
  analytics: Array<UniversalProfileAnalyticEvent>;
  errorMessage: Scalars["String"]["output"];
  regex: Scalars["String"]["output"];
};

export type UniversalProfileRemoveAdditionalTravelerSheet = {
  __typename?: "UniversalProfileRemoveAdditionalTravelerSheet";
  cancelButton?: Maybe<UniversalProfileButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  removeButton?: Maybe<UniversalProfileButton>;
  sheet?: Maybe<EgdsSheet>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileResponse = {
  __typename?: "UniversalProfileResponse";
  communicationPreferences: UniversalProfileCommunicationPreferencesExperience;
  completenessModule?: Maybe<UniversalProfileCompletenessModuleComponent>;
  dashboard: UniversalProfileDashboard;
  passport: UniversalProfileExperience;
  travelerAvatarForm: UniversalProfileAvatarForm;
  travelerCommunicationPreferencesForm: UniversalProfileCommunicationPreferencesForm;
  travelerCompanionInformation: UniversalProfileExperience;
  travelerCompanionInformationForm: UniversalProfileInformationForm;
  travelerContext: UniversalProfileTravelerContext;
  travelerDocuments: UniversalProfileExperience;
  travelerInformation: UniversalProfileExperience;
  travelerInformationForm: UniversalProfileInformationForm;
  travelerPassportInformationEditForm: UniversalProfileInformationForm;
  travelerProfileSummary?: Maybe<UniversalProfileTravelerProfileSummary>;
  travelerSettingsForm: UniversalProfileSettingsForm;
};

export type UniversalProfileResponseCommunicationPreferencesArgs = {
  communicationPreferencesContext: UniversalProfileCommunicationPreferencesContextInput;
};

export type UniversalProfileResponseCompletenessModuleArgs = {
  universalProfileContext: UniversalProfileCompletenessModuleContextInput;
};

export type UniversalProfileResponseDashboardArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponsePassportArgs = {
  universalProfileContext: UniversalProfileTravelerDocumentsContextInput;
};

export type UniversalProfileResponseTravelerAvatarFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerCommunicationPreferencesFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerCompanionInformationArgs = {
  universalProfileContext: UniversalProfileAdditionalTravelerContextInput;
};

export type UniversalProfileResponseTravelerCompanionInformationFormArgs = {
  universalProfileContext: UniversalProfileAdditionalTravelerContextInput;
};

export type UniversalProfileResponseTravelerContextArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerDocumentsArgs = {
  universalProfileContext: UniversalProfileTravelerDocumentsContextInput;
};

export type UniversalProfileResponseTravelerInformationArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerInformationFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerPassportInformationEditFormArgs = {
  universalProfileContext: UniversalProfileTravelerDocumentsContextInput;
};

export type UniversalProfileResponseTravelerProfileSummaryArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileResponseTravelerSettingsFormArgs = {
  universalProfileContext: UniversalProfileContextInput;
};

export type UniversalProfileSaveImageAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSaveImageAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  avatarUploadRequestType: UniversalProfileAvatarUploadRequestType;
};

export type UniversalProfileSearchHistoryComponent = {
  __typename?: "UniversalProfileSearchHistoryComponent";
  clearRecentSearchDialog?: Maybe<UniversalProfileDialog>;
  clearSearchAndSavedData?: Maybe<UniversalProfileStandardLink>;
};

export type UniversalProfileSessionManagement = {
  __typename?: "UniversalProfileSessionManagement";
  heading?: Maybe<UniversalProfileHeading>;
  options?: Maybe<Array<UniversalProfileSessionManagementCard>>;
  sessionsFooter?: Maybe<UniversalProfileSessionManagementFooter>;
  subHeading?: Maybe<UniversalProfileSessionManagementSubHeading>;
};

export type UniversalProfileSessionManagementCard = {
  __typename?: "UniversalProfileSessionManagementCard";
  action?: Maybe<UniversalProfileAction>;
  card?: Maybe<EgdsStandardMessagingCard>;
};

export type UniversalProfileSessionManagementFooter = {
  __typename?: "UniversalProfileSessionManagementFooter";
  footerText?: Maybe<Scalars["String"]["output"]>;
  iconList: Array<UiGraphic>;
};

export type UniversalProfileSessionManagementInformation = {
  __typename?: "UniversalProfileSessionManagementInformation";
  sessionManagement?: Maybe<UniversalProfileSessionManagement>;
};

export type UniversalProfileSessionManagementSubHeading = {
  __typename?: "UniversalProfileSessionManagementSubHeading";
  signoutOfAllDevicesButton?: Maybe<UniversalProfileButton>;
  subHeadingText?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileSettingsBasicForm = {
  __typename?: "UniversalProfileSettingsBasicForm";
  analytics: Array<UniversalProfileAnalyticEvent>;
  cancelButton?: Maybe<UniversalProfileButton>;
  closeButton?: Maybe<UniversalProfileCloseButton>;
  content: Array<UniversalProfileSettingsFormContent>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  heading: UniversalProfileHeading;
  saveButton?: Maybe<UniversalProfileButton>;
  sheet?: Maybe<UniversalProfileSettingsBasicSheet>;
  validation: Array<UniversalProfileValidationRule>;
};

export type UniversalProfileSettingsBasicSheet = {
  __typename?: "UniversalProfileSettingsBasicSheet";
  analytics: Array<UniversalProfileAnalyticEvent>;
  cancelButton?: Maybe<UniversalProfileButton>;
  confirmButton?: Maybe<UniversalProfileButton>;
  description?: Maybe<Scalars["String"]["output"]>;
  egdsSheet?: Maybe<EgdsSheet>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileSettingsForm =
  | UniversalProfileCountryForm
  | UniversalProfileDeleteAccountForm
  | UniversalProfileEmailForm
  | UniversalProfileLanguageForm
  | UniversalProfilePasswordForm
  | UniversalProfileRedirectComponent
  | UniversalProfileSignInComponent;

export type UniversalProfileSettingsFormContent =
  | UniversalProfileButton
  | UniversalProfileConfirmationLeavingView
  | UniversalProfileCountryField
  | UniversalProfileEmailInputField
  | UniversalProfileEmailView
  | UniversalProfileLanguageField
  | UniversalProfilePasswordField
  | UniversalProfileReasonLeavingField
  | UniversalProfileStrengthMeter;

export type UniversalProfileSettingsFormType =
  | "DELETE_ACCOUNT"
  | "UPDATE_COUNTRY"
  | "UPDATE_EMAIL"
  | "UPDATE_LANGUAGE"
  | "UPDATE_PASSWORD";

export type UniversalProfileSheet =
  | UniversalProfileCallLinksSheet
  | UniversalProfileChoosePhotoSheet
  | UniversalProfileDeletePhoneNumberSheet
  | UniversalProfileRemoveAdditionalTravelerSheet
  | UniversalProfileSettingsBasicSheet
  | UniversalProfileTsaInformationSheet;

export type UniversalProfileShowSheetAction = UniversalProfileAction & {
  __typename?: "UniversalProfileShowSheetAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileSignInAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSignInAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileSignInComponent = {
  __typename?: "UniversalProfileSignInComponent";
  createAccountButton: UniversalProfileButton;
  illustration: UniversalProfileThemeIllustration;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  signInButton: UniversalProfileButton;
};

export type UniversalProfileSignOutAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSignOutAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileSignOutDialog = {
  __typename?: "UniversalProfileSignOutDialog";
  cancelButton: UniversalProfileButton;
  confirmButton: UniversalProfileButton;
  heading: UniversalProfileHeading;
  impression: Array<UniversalProfileImpressionAnalyticEvent>;
  title: Scalars["String"]["output"];
};

export type UniversalProfileSignOutDialogLaunchAction = UniversalProfileAction & {
  __typename?: "UniversalProfileSignOutDialogLaunchAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileSignOutLauncher = {
  __typename?: "UniversalProfileSignOutLauncher";
  dialog: UniversalProfileSignOutDialog;
  launchButton: UniversalProfileButton;
};

export interface UniversalProfileSmsContactInformationRequestInput {
  phone: UniversalProfilePhoneInput;
}

export type UniversalProfileStandardBadge = {
  __typename?: "UniversalProfileStandardBadge";
  badge: EgdsStandardBadge;
};

export type UniversalProfileStandardLink = {
  __typename?: "UniversalProfileStandardLink";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  action: UniversalProfileAction;
  disabled: Scalars["Boolean"]["output"];
  subtext?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type UniversalProfileStrengthMeter = {
  __typename?: "UniversalProfileStrengthMeter";
  heading: UniversalProfileContentHeading;
};

export type UniversalProfileSubExperienceType =
  | "COMMUNICATION_PREFERENCES_EXPEDIA"
  | "COMMUNICATION_PREFERENCES_HOTELS"
  | "COMMUNICATION_PREFERENCES_VRBO"
  | "COMMUNICATION_PREFERENCES_WOTIF"
  | "VISIT_HELP_CENTER";

export type UniversalProfileSuccessResponse = {
  __typename?: "UniversalProfileSuccessResponse";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  clickstreamAnalytics: Array<UniversalProfileAnalyticEvent>;
  message: UniversalProfileToast;
};

export type UniversalProfileSwitchChangeCriteria = {
  __typename?: "UniversalProfileSwitchChangeCriteria";
  fieldIdentifier?: Maybe<Scalars["String"]["output"]>;
  operator: UniversalProfileCategorySwitchOperator;
};

export type UniversalProfileTextAreaInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileTextAreaInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsTextAreaInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileTextInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileTextInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  input: EgdsTextInputField;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileThemeIllustration = {
  __typename?: "UniversalProfileThemeIllustration";
  dark: Illustration;
  standard: Illustration;
};

export type UniversalProfileToast = {
  __typename?: "UniversalProfileToast";
  toast: EgdsToast;
};

export interface UniversalProfileTravelerCompanionContactInfoInput {
  email?: InputMaybe<Scalars["String"]["input"]>;
  primaryPhone: UniversalProfilePhoneInput;
}

export interface UniversalProfileTravelerCompanionRequestInput {
  accessibilityNeeds?: InputMaybe<Scalars["String"]["input"]>;
  contactInfo: UniversalProfileTravelerCompanionContactInfoInput;
  dateOfBirth: UniversalProfileBasicInformationDateOfBirthInput;
  emergencyContact: UniversalProfileEmergencyContactInput;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  name: UniversalProfileBasicInformationNameInput;
  travelerId?: InputMaybe<Scalars["String"]["input"]>;
  tsaInfo?: InputMaybe<UniversalProfileTsaInformationRequestInput>;
}

export type UniversalProfileTravelerContext = UniversalProfileTravelerContextComponent;

export type UniversalProfileTravelerContextComponent = {
  __typename?: "UniversalProfileTravelerContextComponent";
  accountUpgradeStatus?: Maybe<Scalars["String"]["output"]>;
  authenticationFactorAction?: Maybe<Scalars["String"]["output"]>;
  userBrandName?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileTravelerDocumentCreateAction = UniversalProfileAction & {
  __typename?: "UniversalProfileTravelerDocumentCreateAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
  isNewTab: Scalars["Boolean"]["output"];
  redirectUrl: Scalars["String"]["output"];
};

export type UniversalProfileTravelerDocumentForm = {
  __typename?: "UniversalProfileTravelerDocumentForm";
  heading?: Maybe<UniversalProfileHeading>;
  passportInformation?: Maybe<UniversalProfileTravelerDocumentInformationForm>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileTravelerDocumentInformationForm = {
  __typename?: "UniversalProfileTravelerDocumentInformationForm";
  content?: Maybe<Array<UniversalProfileInformationFormContent>>;
  saveButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileTravelerDocumentSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileTravelerDocumentSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileTravelerDocuments = {
  __typename?: "UniversalProfileTravelerDocuments";
  addAnotherButton?: Maybe<UniversalProfileButton>;
  heading?: Maybe<UniversalProfileHeading>;
  options?: Maybe<Array<UniversalProfileTravelerDocumentsMessagingCard>>;
};

export interface UniversalProfileTravelerDocumentsContextInput {
  accountTakeOverWidgets?: InputMaybe<Array<UniversalProfileAccountTakeOverWidgetRequestInput>>;
  experienceType?: InputMaybe<UniversalProfileExperienceType>;
  informationFormType?: InputMaybe<UniversalProfileInformationFormType>;
  travelerDocIdentifier?: InputMaybe<Scalars["String"]["input"]>;
}

export type UniversalProfileTravelerDocumentsInformation = {
  __typename?: "UniversalProfileTravelerDocumentsInformation";
  passport: UniversalProfilePassport;
  travelerDocuments: UniversalProfileTravelerDocuments;
};

export type UniversalProfileTravelerDocumentsMessagingCard = {
  __typename?: "UniversalProfileTravelerDocumentsMessagingCard";
  action?: Maybe<UniversalProfileAction>;
  card?: Maybe<EgdsStandardMessagingCard>;
  errorMessage?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<UniversalProfileTravelerDocumentsOptionType>;
};

export type UniversalProfileTravelerDocumentsOptionType = "PASSPORT";

export type UniversalProfileTravelerInformation = {
  __typename?: "UniversalProfileTravelerInformation";
  additionalTravelers?: Maybe<UniversalProfileAdditionalTravelers>;
  analytics: Array<UniversalProfileAnalyticEvent>;
  basicInformation: UniversalProfileInformation;
  contactInformation: UniversalProfileInformation;
  heading?: Maybe<UniversalProfileHeading>;
  moreDetails?: Maybe<UniversalProfileMoreDetails>;
};

export type UniversalProfileTravelerProfileSummary = UniversalProfileTravelerProfileSummaryComponent;

export type UniversalProfileTravelerProfileSummaryComponent = {
  __typename?: "UniversalProfileTravelerProfileSummaryComponent";
  avatar?: Maybe<UniversalProfileAvatar>;
  email?: Maybe<Scalars["String"]["output"]>;
  loyaltyBadge?: Maybe<UniversalProfileLoyaltyBadge>;
  tierBadge?: Maybe<UniversalProfileStandardBadge>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UniversalProfileTravelerSettings = {
  __typename?: "UniversalProfileTravelerSettings";
  accountManagement: UniversalProfileAccountManagementSettings;
  analytics: Array<UniversalProfileAnalyticEvent>;
  heading: UniversalProfileHeading;
  legal?: Maybe<UniversalProfileLegal>;
  loginInformation: UniversalProfileLoginInformationSettings;
  preferences: UniversalProfilePreferences;
};

export type UniversalProfileTsaForm = {
  __typename?: "UniversalProfileTsaForm";
  content: Array<UniversalProfileTsaTextInputField>;
  errorSummary?: Maybe<UniversalProfileErrorSummary>;
  heading: UniversalProfileHeading;
  inputContent?: Maybe<Array<UniversalProfileInformationFormContent>>;
  saveButton?: Maybe<UniversalProfileButton>;
};

export type UniversalProfileTsaInformationForm = {
  __typename?: "UniversalProfileTsaInformationForm";
  tsaInformation: UniversalProfileTsaForm;
};

export interface UniversalProfileTsaInformationRequestInput {
  knownTravelerNumber?: InputMaybe<Scalars["String"]["input"]>;
  redressNumber?: InputMaybe<Scalars["String"]["input"]>;
}

export type UniversalProfileTsaInformationSaveAction = UniversalProfileAction & {
  __typename?: "UniversalProfileTsaInformationSaveAction";
  accountTakeOverWidgets: Array<UniversalProfileAccountTakeOverWidget>;
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileTsaInformationSheet = {
  __typename?: "UniversalProfileTsaInformationSheet";
  confirmButton: UniversalProfileButton;
  egdsSheet: EgdsSheet;
  title: Scalars["String"]["output"];
};

export type UniversalProfileTsaTextInputField = UniversalProfileInput & {
  __typename?: "UniversalProfileTsaTextInputField";
  analytics?: Maybe<Array<EgClickstreamEvent>>;
  iconInfoDismiss: Icon;
  iconInfoOutline: Icon;
  input?: Maybe<EgdsTextInputField>;
  inputAnalytics?: Maybe<Array<UniversalProfileAnalyticEvent>>;
  sheet?: Maybe<UniversalProfileTsaInformationSheet>;
  universalProfileIdentifier?: Maybe<Scalars["String"]["output"]>;
  validation?: Maybe<Array<UniversalProfileValidationRule>>;
};

export type UniversalProfileUpdatePhoneAction = UniversalProfileAction & {
  __typename?: "UniversalProfileUpdatePhoneAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export interface UniversalProfileValidationRule {
  errorMessage: Scalars["String"]["output"];
}

export type UniversalProfileVerifiedPhoneIntent = "DELETE";

export type UniversalProfileVirtualAgentAction = UniversalProfileAction & {
  __typename?: "UniversalProfileVirtualAgentAction";
  analytics: Array<UniversalProfileAnalyticEvent>;
};

export type UniversalProfileVirtualAgentComponent = {
  __typename?: "UniversalProfileVirtualAgentComponent";
  action?: Maybe<UniversalProfileAction>;
};

export type UniversalSortAndFilterAnalytics = {
  __typename?: "UniversalSortAndFilterAnalytics";
  onDisplayErrorAnalytics: Array<ClientSideAnalytics>;
};

export type UnmappedFilter = {
  __typename?: "UnmappedFilter";
  filterId: Scalars["String"]["output"];
  filterValue: Scalars["String"]["output"];
};

export interface UnmappedFilterInput {
  filterId: Scalars["String"]["input"];
  filterValue: Scalars["String"]["input"];
}

export type UpdateAction = "ACCEPT" | "SHARE";

export interface UpdateAdGroupCreativesInput {
  campaignId: Scalars["String"]["input"];
  sourceAdGroupId: Scalars["String"]["input"];
  targetAdGroupIds: Array<Scalars["String"]["input"]>;
}

export interface UpdateApprovalStatusInput {
  creativeId: Scalars["String"]["input"];
  isApproved: Scalars["Boolean"]["input"];
}

export type UpdateApprovalStatusResponse = {
  __typename?: "UpdateApprovalStatusResponse";
  errorMessage?: Maybe<UiBanner>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateHistory = "REMOVE";

export interface UpdateInsurtechOfferRequestInput {
  coveredOfferTokens?: InputMaybe<Array<OfferIdentityInput>>;
  offersToAdd: Array<OfferIdentityInput>;
  offersToRemove: Array<OfferIdentityInput>;
  sessionIdentifier?: InputMaybe<Scalars["String"]["input"]>;
}

export type UpdateInsurtechOfferResponse = {
  __typename?: "UpdateInsurtechOfferResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  selectedOffers: Array<OfferIdentity>;
  /** @deprecated Use uiSignals instead Refer to RFC-590 */
  signals?: Maybe<Array<InsurtechSignal>>;
  status: InsurtechMutationStatus;
  uiSignals: Array<UiSignalV2>;
  updateTrip?: Maybe<UpdateTripResponse>;
};

export interface UpdateJourneyContinuationIdRequestInput {
  additionalProducts?: InputMaybe<AdditionalProductsInput>;
  journeyContinuationId: Scalars["String"]["input"];
}

export type UpdateJourneyContinuationIdResponse = {
  __typename?: "UpdateJourneyContinuationIdResponse";
  journeyContinuationId: Scalars["String"]["output"];
};

export type UpdatePaymentAssistSessionCardTypeResponse = {
  __typename?: "UpdatePaymentAssistSessionCardTypeResponse";
  status?: Maybe<ResponseStatus>;
};

export type UpdatePaymentAssistSessionStatusResponse = {
  __typename?: "UpdatePaymentAssistSessionStatusResponse";
  status?: Maybe<ResponseStatus>;
};

export interface UpdatePaymentMethodMutationRequestInput {
  bookingParameters?: InputMaybe<Array<PaymentConfigurationItemInput>>;
  checkoutIdentifier: PaymentCheckoutIdentifierInput;
  fopItems: Array<FopItemInput>;
}

export type UpdatePaymentMethodMutationResponse = {
  __typename?: "UpdatePaymentMethodMutationResponse";
  actions?: Maybe<Array<PaymentAction>>;
  checkoutIdentifier?: Maybe<PaymentCheckoutIdentifier>;
  updatedFops: Array<IPaymentFop>;
  updatedPaymentCheckoutElement?: Maybe<PaymentCheckoutElement>;
};

export type UpdatePaymentStep = {
  __typename?: "UpdatePaymentStep";
  backButton: Scalars["String"]["output"];
  submitButton: Scalars["String"]["output"];
};

export type UpdatePaymentView = {
  __typename?: "UpdatePaymentView";
  paymentAssistWidgetUrl: Scalars["String"]["output"];
  updatePaymentError?: Maybe<AgentToolsErrorBanner>;
};

export type UpdatePresence = {
  __typename?: "UpdatePresence";
  currentState: AgentState;
  message: Scalars["String"]["output"];
};

export type UpdatePresenceResponse = {
  __typename?: "UpdatePresenceResponse";
  message: Scalars["String"]["output"];
  userMigrationReport: UserMigrationReport;
};

export interface UpdateProviderServicesInput {
  providerId: Scalars["String"]["input"];
  serviceActions: Array<ServiceActionInput>;
}

export type UpdateSupplySelectedWaiversResponse = {
  __typename?: "UpdateSupplySelectedWaiversResponse";
  selectedWaivers: Array<Maybe<Scalars["Int"]["output"]>>;
};

export type UpdateTripResponse = {
  __typename?: "UpdateTripResponse";
  price?: Maybe<Scalars["String"]["output"]>;
  response?: Maybe<Scalars["String"]["output"]>;
};

export type UploadButton = {
  __typename?: "UploadButton";
  button?: Maybe<UiPrimaryButton>;
  status?: Maybe<EgdsMoreInfoTrigger>;
  value?: Maybe<MediaContentData>;
};

export type UploadLogo = {
  __typename?: "UploadLogo";
  actionButtons: Array<UiSecondaryButton>;
  options: Array<LogoManagerOption>;
};

export type UploadLogoResponse = LogoManagerResponse & {
  __typename?: "UploadLogoResponse";
  attachLogo: UiSecondaryButton;
  descriptions: Array<LogoManagerMessage>;
  errorBanners: Array<UiBanner>;
  errorMessages: Array<ErrorMessage>;
  successNotification: SuccessNotification;
  timeout: Scalars["Int"]["output"];
  title: EgdsHeading;
  uploadLogo: UploadLogo;
};

export type UploadLogoStatusResponse = {
  __typename?: "UploadLogoStatusResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  status: UploadLogoStatusType;
};

export type UploadLogoStatusType = "FAILURE" | "PENDING" | "SUCCESS";

export type UpvotePropertyReviewWithAccessibility = AccessibilityField & {
  __typename?: "UpvotePropertyReviewWithAccessibility";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use accessibilityLabel, replace with accessibilityLabel */
  label?: Maybe<Scalars["String"]["output"]>;
  primaryDisplayString?: Maybe<Scalars["String"]["output"]>;
};

export type Uri = Uri & {
  __typename?: "Uri";
  value: Scalars["String"]["output"];
};

export interface UriAttributesInput {
  gaiaType?: InputMaybe<Scalars["String"]["input"]>;
  lodgingUrlCode?: InputMaybe<Scalars["String"]["input"]>;
}

export type UriClassification = "CLASSIFICATION_DEALS" | "CLASSIFICATION_INVALID" | "CLASSIFICATION_LEGAL";

export interface UriContextAttributesInput {
  affinity?: InputMaybe<Scalars["String"]["input"]>;
  airCarrierCode?: InputMaybe<Scalars["String"]["input"]>;
  carClass?: InputMaybe<Scalars["String"]["input"]>;
  carSupplier?: InputMaybe<Scalars["String"]["input"]>;
  gaiaType?: InputMaybe<Scalars["String"]["input"]>;
  lodgingUrlCode?: InputMaybe<Scalars["String"]["input"]>;
  starRating?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UriContextIdentifiersInput {
  destination: Scalars["String"]["input"];
  hotelId?: InputMaybe<Scalars["Int"]["input"]>;
  origin?: InputMaybe<Scalars["String"]["input"]>;
}

export interface UriContextInput {
  attributes?: InputMaybe<UriAttributesInput>;
  campaign?: InputMaybe<Scalars["String"]["input"]>;
  classification?: InputMaybe<UriClassification>;
  destination?: InputMaybe<Scalars["String"]["input"]>;
  funnelLocation?: InputMaybe<PageLocation>;
  identifiers?: InputMaybe<UriIdentifiersInput>;
  legalConcept?: InputMaybe<LegalConcept>;
}

export interface UriIdentifiersInput {
  accommodationType?: InputMaybe<Scalars["String"]["input"]>;
  airport?: InputMaybe<Scalars["String"]["input"]>;
  brand?: InputMaybe<Scalars["String"]["input"]>;
  carClass?: InputMaybe<Scalars["String"]["input"]>;
  carSupplierLocation?: InputMaybe<Scalars["String"]["input"]>;
  carrierCode?: InputMaybe<Scalars["String"]["input"]>;
  company?: InputMaybe<Scalars["String"]["input"]>;
  cruiseLine?: InputMaybe<Scalars["String"]["input"]>;
  destination?: InputMaybe<Scalars["String"]["input"]>;
  discriminator?: InputMaybe<Scalars["String"]["input"]>;
  feature?: InputMaybe<Scalars["String"]["input"]>;
  flightRoute?: InputMaybe<Scalars["String"]["input"]>;
  funnelLocation?: InputMaybe<Scalars["String"]["input"]>;
  hotel?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lineOfBusiness?: InputMaybe<Scalars["String"]["input"]>;
  lodgingType?: InputMaybe<Scalars["String"]["input"]>;
  origin?: InputMaybe<Scalars["String"]["input"]>;
  ship?: InputMaybe<Scalars["String"]["input"]>;
  starRating?: InputMaybe<Scalars["String"]["input"]>;
  theme?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
}

export type UserActionDialog = {
  __typename?: "UserActionDialog";
  analytics: ClientSideAnalytics;
  body?: Maybe<Scalars["String"]["output"]>;
  buttons: Array<EgdsButton>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UserActionMenuItem = {
  __typename?: "UserActionMenuItem";
  analytics: ClientSideAnalytics;
  icon: Icon;
  title: Scalars["String"]["output"];
};

export type UserActivitySignal = "COLD" | "WARM";

export type UserAnswer = {
  __typename?: "UserAnswer";
  answer: Scalars["String"]["output"];
  answerTime: DateTime;
  displayName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  upVotes: FormattedNumber;
};

export type UserAuthentication = {
  __typename?: "UserAuthentication";
  signIn?: Maybe<SignIn>;
  signedInUser?: Maybe<SignedInUser>;
};

export type UserAuthenticationResponse = {
  __typename?: "UserAuthenticationResponse";
  userAuthentication: UserAuthentication;
};

export type UserFeedback = {
  __typename?: "UserFeedback";
  options: Array<UserFeedbackOption>;
  submit: CarActionableItem;
  submitConfirmation: Scalars["String"]["output"];
  userSurveyTitle: Scalars["String"]["output"];
};

export type UserFeedbackOption = {
  __typename?: "UserFeedbackOption";
  inputHeading: Scalars["String"]["output"];
  inputTextPlaceholder: Scalars["String"]["output"];
  option: CarActionableItem;
};

export interface UserLocationInput {
  userCountryCode?: InputMaybe<Scalars["String"]["input"]>;
  userLatitude?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  userLongitude?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  userSelectedOrigin?: InputMaybe<Scalars["String"]["input"]>;
}

export type UserMigrationReport = {
  __typename?: "UserMigrationReport";
  userIdentifierUsed?: Maybe<Scalars["String"]["output"]>;
  userUriReceived: Scalars["Boolean"]["output"];
  userVersionUsed?: Maybe<Scalars["String"]["output"]>;
  usernameReceived: Scalars["Boolean"]["output"];
};

export type UserNotificationState = {
  __typename?: "UserNotificationState";
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  optInButton?: Maybe<AppGrowthButton>;
  optOutButton?: Maybe<AppGrowthButton>;
  optedIn?: Maybe<Scalars["String"]["output"]>;
};

export type UserProperties = {
  __typename?: "UserProperties";
  countryCode?: Maybe<Scalars["String"]["output"]>;
  hotelId?: Maybe<Scalars["String"]["output"]>;
  hotelName?: Maybe<Scalars["String"]["output"]>;
  ip?: Maybe<Scalars["String"]["output"]>;
  isNluDisabled?: Maybe<Scalars["String"]["output"]>;
  pagePath?: Maybe<Scalars["String"]["output"]>;
  token?: Maybe<Scalars["String"]["output"]>;
};

export type UserQuestion = {
  __typename?: "UserQuestion";
  answers?: Maybe<Array<UserAnswer>>;
  createDate?: Maybe<DateTime>;
  id: Scalars["String"]["output"];
  question: Scalars["String"]["output"];
  questioner?: Maybe<Scalars["String"]["output"]>;
  totalAnswers: FormattedNumber;
};

export interface UserSelectedFiltersInput {
  filterName?: InputMaybe<Scalars["String"]["input"]>;
  filterValue?: InputMaybe<Scalars["String"]["input"]>;
}

export type VaConversationProperties = {
  __typename?: "VAConversationProperties";
  launchPoint?: Maybe<Scalars["String"]["output"]>;
};

export type VrCancellationReason =
  | "CONFLICT_WITH_HOUSE_RULES"
  | "DATES_BOOKED_EXTERNALLY"
  | "EXTENUATING_CIRCUMSTANCES"
  | "GUEST_NEEDS_TO_CANCEL"
  | "NEW_OWNERSHIP_OF_PROPERTY"
  | "PAYMENT_NOT_RECEIVED";

export type VacOrchestrator = {
  __typename?: "VacOrchestrator";
  auth: VacOrchestratorAuthentication;
};

export type VacOrchestratorAuthentication = {
  __typename?: "VacOrchestratorAuthentication";
  subscriptionId: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

export type ValuePropBannerCardItem = EgdsCarouselItem & {
  __typename?: "ValuePropBannerCardItem";
  card: EgdsCard;
  disclosure?: Maybe<ValuePropositionBannerDisclosure>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type ValuePropositionBanner = {
  __typename?: "ValuePropositionBanner";
  carousel: EgdsCarousel;
  disclosure?: Maybe<ValuePropositionBannerDisclosure>;
  grid?: Maybe<ValuePropositionBannerGrid>;
  header: EgdsHeading;
};

export type ValuePropositionBannerDisclosure = {
  __typename?: "ValuePropositionBannerDisclosure";
  content: ValuePropositionBannerDisclosurePopover;
  icon?: Maybe<Icon>;
  openAnalytics?: Maybe<ClientSideAnalytics>;
};

export type ValuePropositionBannerDisclosurePopover = EgdsDialog & {
  __typename?: "ValuePropositionBannerDisclosurePopover";
  closeAnalytics?: Maybe<ClientSideAnalytics>;
  details?: Maybe<EgdsTextList>;
  footer?: Maybe<EgdsDialogFooter>;
  header: EgdsSectionHeading;
};

export type ValuePropositionBannerGrid = {
  __typename?: "ValuePropositionBannerGrid";
  analytics?: Maybe<ClientSideAnalytics>;
  items: Array<EgdsCard>;
};

export type VehicleDetails = {
  __typename?: "VehicleDetails";
  attributes: Array<VehicleFeature>;
  category: Scalars["String"]["output"];
  categoryIcon?: Maybe<Icon>;
  description?: Maybe<Scalars["String"]["output"]>;
  ecoFriendlyFuel?: Maybe<CarsRichText>;
  features: Array<VehicleFeature>;
  fuelInfo: Array<VehicleFeature>;
  image?: Maybe<Image>;
};

export type VehicleFeature = {
  __typename?: "VehicleFeature";
  icon?: Maybe<Icon>;
  info?: Maybe<VehicleFeatureInfo>;
  text: Scalars["String"]["output"];
};

export type VehicleFeatureInfo = {
  __typename?: "VehicleFeatureInfo";
  carActionableItem: CarActionableItem;
  carActionableItemOnClose?: Maybe<CarActionableItem>;
  vehicleFeatureDialog: CarsDialog;
};

export type Video = {
  __typename?: "Video";
  description: Scalars["String"]["output"];
  playPauseAnalytics?: Maybe<ClientSideAnalytics>;
  thumbnail?: Maybe<Uri>;
  thumbnailClickAnalytics?: Maybe<ClientSideAnalytics>;
  url: Uri;
};

export type VideoCallToActionButton = {
  __typename?: "VideoCallToActionButton";
  action: UiLinkAction;
  button: UiPrimaryButton;
};

export type VideoCarousel = {
  __typename?: "VideoCarousel";
  items: Array<SponsoredContentVideoCard>;
  leftScrollAnalytics?: Maybe<ClientSideAnalytics>;
  nextButton?: Maybe<UiPrimaryButton>;
  previousButton?: Maybe<UiPrimaryButton>;
  rightScrollAnalytics?: Maybe<ClientSideAnalytics>;
};

export type VideoControls = {
  __typename?: "VideoControls";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  moreOptionsAccessibility?: Maybe<Scalars["String"]["output"]>;
  soundOffAccessibility?: Maybe<Scalars["String"]["output"]>;
  soundOnAccessibility?: Maybe<Scalars["String"]["output"]>;
  videoPausedAccessibility?: Maybe<Scalars["String"]["output"]>;
  videoPlayingAccessibility?: Maybe<Scalars["String"]["output"]>;
};

export interface VideoExperienceCarousalRequestInput {
  creatorHandle?: InputMaybe<Scalars["String"]["input"]>;
  creatorId?: InputMaybe<Scalars["String"]["input"]>;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
  productId: VideoExperienceProductId;
  screenId: Scalars["String"]["input"];
}

export type VideoExperienceCarousalResponse = {
  __typename?: "VideoExperienceCarousalResponse";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  cta?: Maybe<HttpUri>;
  description?: Maybe<Scalars["String"]["output"]>;
  heading: Scalars["String"]["output"];
  immersiveView: ImmersiveViewType;
  impressionAnalytics: ClientSideImpressionEventAnalytics;
  leftSwipeAnalytics: ClientSideAnalytics;
  rightSwipeAnalytics: ClientSideAnalytics;
  videoElements: Array<VideoExperienceVideoElement>;
  watchAllAnalytics: ClientSideAnalytics;
  watchAllText?: Maybe<Scalars["String"]["output"]>;
};

export type VideoExperienceComponentType = "CAROUSEL" | "VIDEO_PLAYER";

export type VideoExperienceInteractionType =
  | "BUTTON_TAP"
  | "CLICK"
  | "PAUSE"
  | "PLAY"
  | "SWIPE"
  | "VIEW"
  | "WATCH_TIME";

export type VideoExperienceProductId = "EG_STORIES" | "MESO_ADS" | "TRAVEL_SHOP";

export interface VideoExperienceSaveVideoInteractionRequestInput {
  productId: Scalars["String"]["input"];
  screenId: Scalars["String"]["input"];
  videoComponentType: VideoExperienceComponentType;
  videoId: Scalars["String"]["input"];
  videoInteractionType: VideoExperienceInteractionType;
}

export type VideoExperienceSaveVideoInteractionResponse = {
  __typename?: "VideoExperienceSaveVideoInteractionResponse";
  isSuccess: Scalars["Boolean"]["output"];
};

export type VideoExperienceVideoElement = {
  __typename?: "VideoExperienceVideoElement";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  clickAnalytics: Array<ClientSideAnalytics>;
  fullVideoUrl: Scalars["URL"]["output"];
  height?: Maybe<Scalars["Int"]["output"]>;
  immersiveViewElement: ImmersiveViewElement;
  impressionAnalytics: Array<ClientSideImpressionEventAnalytics>;
  numberOfViews: Scalars["Int"]["output"];
  price?: Maybe<Scalars["String"]["output"]>;
  propertyId?: Maybe<Scalars["String"]["output"]>;
  propertyName?: Maybe<Scalars["String"]["output"]>;
  rating?: Maybe<Rating>;
  regionName?: Maybe<Scalars["String"]["output"]>;
  shortVideoUrl: Scalars["URL"]["output"];
  videoTitle: Scalars["String"]["output"];
  width?: Maybe<Scalars["Int"]["output"]>;
};

export interface VideoPostOverlayRequestInput {
  creatorId?: InputMaybe<Scalars["String"]["input"]>;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
  propertyId?: InputMaybe<Scalars["String"]["input"]>;
  screenId: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
}

export type VideoPostOverlayResponse = {
  __typename?: "VideoPostOverlayResponse";
  postSummary?: Maybe<PostSummary>;
  progressionBar?: Maybe<ProgressionBar>;
};

export type VideoResource = {
  __typename?: "VideoResource";
  thumbnail: Uri;
  url: Uri;
};

export type VirtualAgentCancelIntentMessage = VirtualAgentIntentMessage & {
  __typename?: "VirtualAgentCancelIntentMessage";
  action: Scalars["String"]["output"];
  emailAddress?: Maybe<Scalars["String"]["output"]>;
  intent: Scalars["String"]["output"];
  orderLineId: Scalars["String"]["output"];
  orderNumber: Scalars["String"]["output"];
  product: Scalars["String"]["output"];
};

export type VirtualAgentCheckoutResponse = {
  __typename?: "VirtualAgentCheckoutResponse";
  status: VirtualAgentCheckoutStatus;
};

export type VirtualAgentCheckoutStatus = "FAILED" | "SUCCESS";

export interface VirtualAgentContextInput {
  itineraryNumber: Scalars["String"]["input"];
  opaqueToken: Scalars["String"]["input"];
}

export type VirtualAgentControl = {
  __typename?: "VirtualAgentControl";
  chat: VirtualAgentControlChat;
  chatConfig: VirtualAgentControlChatConfig;
  feedback?: Maybe<VirtualAgentControlFeedback>;
  settingsMenu?: Maybe<VirtualAgentControlMenuView>;
  trigger?: Maybe<VirtualAgentControlTrigger>;
};

export type VirtualAgentControlAblyConfig = {
  __typename?: "VirtualAgentControlAblyConfig";
  clientId: Scalars["String"]["output"];
  token: Scalars["String"]["output"];
};

export type VirtualAgentControlActionEvent = {
  __typename?: "VirtualAgentControlActionEvent";
  eventType: VirtualAgentControlMenuItemType;
  payload?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlActionable = {
  __typename?: "VirtualAgentControlActionable";
  action: VirtualAgentControlUiAction;
  element: VirtualAgentControlActionableElement;
};

export type VirtualAgentControlActionableElement = {
  __typename?: "VirtualAgentControlActionableElement";
  button?: Maybe<EgdsButton>;
  link?: Maybe<EgdsStandardLink>;
};

export interface VirtualAgentControlAnalyticEvent {
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
}

export type VirtualAgentControlAnalyticEventSubType = "BLURRED" | "FOCUSED" | "INPUTTED" | "SUCCEEDED";

export type VirtualAgentControlAndroidEvent = {
  __typename?: "VirtualAgentControlAndroidEvent";
  inboundMessage?: Maybe<VirtualAgentControlInboundMessage>;
};

export type VirtualAgentControlAttachmentData = {
  __typename?: "VirtualAgentControlAttachmentData";
  mediaType: Scalars["String"]["output"];
  mediaUrl: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  size: Scalars["String"]["output"];
  uniqueAttachmentId: Scalars["String"]["output"];
};

export interface VirtualAgentControlAttachmentDataInput {
  mediaType: Scalars["String"]["input"];
  mediaUrl: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  size: Scalars["String"]["input"];
  uniqueAttachmentId: Scalars["String"]["input"];
}

export type VirtualAgentControlAttribute = {
  __typename?: "VirtualAgentControlAttribute";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export interface VirtualAgentControlAttributeInput {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}

export type VirtualAgentControlAvatar = AvatarGroup;

export type VirtualAgentControlButtonMenuListItem = VirtualAgentControlMenuListItem & {
  __typename?: "VirtualAgentControlButtonMenuListItem";
  accessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  action?: Maybe<VirtualAgentControlActionEvent>;
  analytics?: Maybe<ClientSideAnalytics>;
  icon: Icon;
  id: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
};

export type VirtualAgentControlChannelOrigin = {
  __typename?: "VirtualAgentControlChannelOrigin";
  businessSegmentGuid: Scalars["String"]["output"];
  channelOriginGuid: Scalars["String"]["output"];
  channelOriginId: Scalars["String"]["output"];
  partnerGuid: Scalars["String"]["output"];
};

export type VirtualAgentControlChat = {
  __typename?: "VirtualAgentControlChat";
  analytics?: Maybe<Array<AnalyticsElement>>;
  conversationIntro?: Maybe<VirtualAgentControlConversationIntro>;
  footer: VirtualAgentControlChatFooter;
  header?: Maybe<VirtualAgentControlHeader>;
};

export type VirtualAgentControlChatConfig = {
  __typename?: "VirtualAgentControlChatConfig";
  channelOrigin: VirtualAgentControlChannelOrigin;
  chatCapabilities?: Maybe<ChatCapabilities>;
};

export type VirtualAgentControlChatContent = {
  __typename?: "VirtualAgentControlChatContent";
  /** @deprecated Prefer virtualAgentControl.chat.conversationIntro */
  conversationIntro?: Maybe<VirtualAgentControlConversationIntro>;
  elements: Array<VirtualAgentControlChatElement>;
};

export type VirtualAgentControlChatElement =
  | VirtualAgentControlInboundMessageGroup
  | VirtualAgentControlMessageSeparator
  | VirtualAgentControlOutboundMessageElementGroup
  | VirtualAgentControlOutboundMessageGroup;

export type VirtualAgentControlChatFooter = {
  __typename?: "VirtualAgentControlChatFooter";
  attach?: Maybe<EgdsButton>;
  disabledInputPlaceholder: Scalars["String"]["output"];
  input: VirtualAgentControlInput;
  microphone?: Maybe<EgdsButton>;
  send: EgdsButton;
};

export type VirtualAgentControlChatHeader = {
  __typename?: "VirtualAgentControlChatHeader";
  avatar: VirtualAgentControlAvatar;
  minimize?: Maybe<EgdsButton>;
  text: Scalars["String"]["output"];
  triggerSettingsMenu?: Maybe<UiTertiaryButton>;
};

export type VirtualAgentControlChatbotPopupItem = VirtualAgentControlPopupItem & {
  __typename?: "VirtualAgentControlChatbotPopupItem";
  avatar?: Maybe<Avatar>;
  primaryText?: Maybe<EgdsText>;
  secondaryText?: Maybe<EgdsText>;
  showBottomSeparator?: Maybe<Scalars["Boolean"]["output"]>;
};

export type VirtualAgentControlCoachmark = {
  __typename?: "VirtualAgentControlCoachmark";
  button?: Maybe<EgdsButton>;
  direction?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type VirtualAgentControlConfig = {
  __typename?: "VirtualAgentControlConfig";
  authToken?: Maybe<Scalars["String"]["output"]>;
  localizationContent: VirtualAgentControlLocalizationContent;
  webSocket?: Maybe<VirtualAgentControlWebSocketConfig>;
};

export interface VirtualAgentControlConversationEventInput {
  content?: InputMaybe<EventContentInput>;
  eventType: VirtualAgentControlEventType;
}

export type VirtualAgentControlConversationEventResponse = {
  __typename?: "VirtualAgentControlConversationEventResponse";
  retryText?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlConversationIntro = {
  __typename?: "VirtualAgentControlConversationIntro";
  bottomBorder?: Maybe<VirtualAgentControlMessageSeparator>;
  primary?: Maybe<Scalars["String"]["output"]>;
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlDialog = {
  __typename?: "VirtualAgentControlDialog";
  close: UiTertiaryButton;
  text?: Maybe<Array<DynamicCardText>>;
};

export type VirtualAgentControlDialogAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlDialogAction";
  analytics?: Maybe<ClientSideAnalytics>;
  srcDoc: Scalars["String"]["output"];
  toolbar: UiToolbar;
};

export type VirtualAgentControlDynamicCard = {
  __typename?: "VirtualAgentControlDynamicCard";
  cover?: Maybe<Image>;
  /** @deprecated New use cases should leverage DynamicCard, and new field of the same name */
  elements: Array<DynamicCardElement>;
};

export type VirtualAgentControlDynamicCardItem = EgdsCarouselItem & {
  __typename?: "VirtualAgentControlDynamicCardItem";
  card: VirtualAgentControlDynamicCard;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlErrorBanner = {
  __typename?: "VirtualAgentControlErrorBanner";
  header: Scalars["String"]["output"];
  retryText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type VirtualAgentControlErrorFullPageView = {
  __typename?: "VirtualAgentControlErrorFullPageView";
  header: Scalars["String"]["output"];
  icon: Icon;
  retryText?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
};

export type VirtualAgentControlEventType = "CONVERSATION_READ" | "HEARTBEAT" | "TYPING_INDICATOR";

export type VirtualAgentControlFeedback = {
  __typename?: "VirtualAgentControlFeedback";
  form: VirtualAgentControlFeedbackForm;
  openForm: EgdsButton;
};

export type VirtualAgentControlFeedbackForm = ReportProblemForm;

export type VirtualAgentControlFileItem = {
  __typename?: "VirtualAgentControlFileItem";
  attachmentLink: EgdsStandardLink;
  attachmentMetadata?: Maybe<VirtualAgentControlAttachmentData>;
};

export type VirtualAgentControlFormInput = EgdsBasicCheckBox;

export type VirtualAgentControlGetSurveyData = {
  __typename?: "VirtualAgentControlGetSurveyData";
  answers?: Maybe<Array<VirtualAgentControlSurveyAnswer>>;
  channelType?: Maybe<Scalars["String"]["output"]>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  deliveryType?: Maybe<Scalars["String"]["output"]>;
  emailAddress?: Maybe<Scalars["String"]["output"]>;
  formState?: Maybe<Scalars["String"]["output"]>;
  offered?: Maybe<Scalars["String"]["output"]>;
  partnerIconURL?: Maybe<Scalars["String"]["output"]>;
  partnerId?: Maybe<Scalars["String"]["output"]>;
  partnerName?: Maybe<Scalars["String"]["output"]>;
  surveyId: Scalars["String"]["output"];
  surveySubmitted: Scalars["Boolean"]["output"];
  surveyType?: Maybe<Scalars["String"]["output"]>;
  updated?: Maybe<Scalars["String"]["output"]>;
};

export interface VirtualAgentControlGetSurveyDataInput {
  answers?: InputMaybe<Array<VirtualAgentControlSurveyAnswerInput>>;
  channelType?: InputMaybe<Scalars["String"]["input"]>;
  conversationId?: InputMaybe<Scalars["String"]["input"]>;
  deliveryType?: InputMaybe<Scalars["String"]["input"]>;
  emailAddress?: InputMaybe<Scalars["String"]["input"]>;
  formState?: InputMaybe<Scalars["String"]["input"]>;
  offered?: InputMaybe<Scalars["String"]["input"]>;
  partnerIconURL?: InputMaybe<Scalars["String"]["input"]>;
  partnerId?: InputMaybe<Scalars["String"]["input"]>;
  partnerName?: InputMaybe<Scalars["String"]["input"]>;
  surveyId: Scalars["String"]["input"];
  surveySubmitted: Scalars["Boolean"]["input"];
  surveyType?: InputMaybe<Scalars["String"]["input"]>;
  updated?: InputMaybe<Scalars["String"]["input"]>;
}

export interface VirtualAgentControlGetSurveyInput {
  surveyId: Scalars["String"]["input"];
}

export type VirtualAgentControlGetSurveyResult = {
  __typename?: "VirtualAgentControlGetSurveyResult";
  surveyState: VirtualAgentControlGetSurveyData;
};

export type VirtualAgentControlHeader = VirtualAgentControlChatHeader | VirtualAgentControlToolbar;

export type VirtualAgentControlHorizontalBreak = {
  __typename?: "VirtualAgentControlHorizontalBreak";
  label?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlImpressionAnalyticEvent = VirtualAgentControlAnalyticEvent & {
  __typename?: "VirtualAgentControlImpressionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type VirtualAgentControlInboundCardMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundCardMessage";
  avatar?: Maybe<Avatar>;
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  header?: Maybe<Scalars["String"]["output"]>;
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"]["output"];
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInboundDynamicCardMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundDynamicCardMessage";
  avatar?: Maybe<Avatar>;
  card?: Maybe<DynamicCard>;
  cardCarousel?: Maybe<DynamicCardCarousel>;
  cardPayload?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Prefer the new field cardCarousel with the new Dynamic Card schema */
  carousel?: Maybe<EgdsCarousel>;
  /** @deprecated Prefer the new field card with the new Dynamic Card schema */
  dynamicCard?: Maybe<VirtualAgentControlDynamicCard>;
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  metadata: VirtualAgentControlMessageMetadata;
  quickReplies?: Maybe<Array<VirtualAgentControlQuickReplyItem>>;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInboundFileMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundFileMessage";
  /** @deprecated Prefer attachmentMetadata */
  attachment?: Maybe<VirtualAgentControlAttachmentData>;
  /** @deprecated Prefer attachmentList */
  attachmentLink: EgdsStandardLink;
  attachmentList?: Maybe<Array<VirtualAgentControlFileItem>>;
  /** @deprecated Prefer attachmentList */
  attachmentMetadata?: Maybe<VirtualAgentControlAttachmentData>;
  avatar?: Maybe<Avatar>;
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  metadata: VirtualAgentControlMessageMetadata;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export interface VirtualAgentControlInboundMessage {
  avatar?: Maybe<Avatar>;
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  metadata: VirtualAgentControlMessageMetadata;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
}

export type VirtualAgentControlInboundMessageGroup = {
  __typename?: "VirtualAgentControlInboundMessageGroup";
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  messages: Array<VirtualAgentControlInboundMessage>;
  timeStamp?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlInboundQuickReplyMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundQuickReplyMessage";
  avatar?: Maybe<Avatar>;
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  metadata: VirtualAgentControlMessageMetadata;
  quickReplies?: Maybe<Array<VirtualAgentControlQuickReplyItem>>;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInboundTextMessage = VirtualAgentControlInboundMessage & {
  __typename?: "VirtualAgentControlInboundTextMessage";
  attachmentList?: Maybe<Array<VirtualAgentControlFileItem>>;
  avatar?: Maybe<Avatar>;
  eyebrow?: Maybe<Scalars["String"]["output"]>;
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"]["output"];
  quickReplies?: Maybe<Array<VirtualAgentControlQuickReplyItem>>;
  stateUpdates?: Maybe<VirtualAgentControlStateUpdates>;
};

export type VirtualAgentControlInput = EgdsTextInputField | EgdsTypeaheadInputField;

export type VirtualAgentControlInteractionAnalyticEvent = VirtualAgentControlAnalyticEvent & {
  __typename?: "VirtualAgentControlInteractionAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
  subType?: Maybe<VirtualAgentControlAnalyticEventSubType>;
};

export type VirtualAgentControlLocalizationActivityIndicator = {
  __typename?: "VirtualAgentControlLocalizationActivityIndicator";
  activityIndicatorMessage: Scalars["String"]["output"];
  humanTyping: Scalars["String"]["output"];
  multipleParticipantsTyping: Scalars["String"]["output"];
  virtualAgentTyping: Scalars["String"]["output"];
};

export type VirtualAgentControlLocalizationAttachment = {
  __typename?: "VirtualAgentControlLocalizationAttachment";
  downloadError: Scalars["String"]["output"];
  downloadProcessing: Scalars["String"]["output"];
  downloadSuccess: Scalars["String"]["output"];
  uploadChooseAction: Scalars["String"]["output"];
  uploadError: Scalars["String"]["output"];
  uploadFileCountError: Scalars["String"]["output"];
  uploadFileCountErrorDismiss: Scalars["String"]["output"];
  uploadFileSizeError: Scalars["String"]["output"];
  uploadOptionsChooseFiles: Scalars["String"]["output"];
  uploadOptionsFiles: Scalars["String"]["output"];
  uploadOptionsPhotoLibrary: Scalars["String"]["output"];
  uploadOptionsPhotos: Scalars["String"]["output"];
  uploadProcessing: Scalars["String"]["output"];
  uploadSuccess: Scalars["String"]["output"];
};

export type VirtualAgentControlLocalizationContent = {
  __typename?: "VirtualAgentControlLocalizationContent";
  activityIndicator?: Maybe<VirtualAgentControlLocalizationActivityIndicator>;
  attachment?: Maybe<VirtualAgentControlLocalizationAttachment>;
  dialog?: Maybe<VirtualAgentControlLocalizationDialog>;
  error?: Maybe<VirtualAgentControlLocalizationError>;
  id: Scalars["String"]["output"];
  message?: Maybe<VirtualAgentControlLocalizationMessage>;
  participant?: Maybe<VirtualAgentControlLocalizationParticipant>;
};

export type VirtualAgentControlLocalizationDialog = {
  __typename?: "VirtualAgentControlLocalizationDialog";
  closeWebviewWindow: Scalars["String"]["output"];
};

export type VirtualAgentControlLocalizationError = {
  __typename?: "VirtualAgentControlLocalizationError";
  checkConnection: Scalars["String"]["output"];
  exitChatLine1: Scalars["String"]["output"];
  exitChatLine2: Scalars["String"]["output"];
  havingTroubleLoading: Scalars["String"]["output"];
  lostConnection: Scalars["String"]["output"];
  refreshOrReconnect: Scalars["String"]["output"];
  refreshOrTryLater: Scalars["String"]["output"];
  refreshToContinue: Scalars["String"]["output"];
  seemOffline: Scalars["String"]["output"];
  sessionExpired: Scalars["String"]["output"];
  somethingWentWrong: Scalars["String"]["output"];
  technicalProblem: Scalars["String"]["output"];
  tryToReconnect: Scalars["String"]["output"];
};

export type VirtualAgentControlLocalizationMessage = {
  __typename?: "VirtualAgentControlLocalizationMessage";
  separatorToday: Scalars["String"]["output"];
  separatorYesterday: Scalars["String"]["output"];
  statusDelivered: Scalars["String"]["output"];
  statusNotDelivered: Scalars["String"]["output"];
  statusRetry: Scalars["String"]["output"];
  statusSending: Scalars["String"]["output"];
};

export type VirtualAgentControlLocalizationParticipant = {
  __typename?: "VirtualAgentControlLocalizationParticipant";
  eventJoined: Scalars["String"]["output"];
  eventLeft: Scalars["String"]["output"];
  typeHumanAgent: Scalars["String"]["output"];
  typeSupplier: Scalars["String"]["output"];
  typeTraveler: Scalars["String"]["output"];
  typeVirtualAgent: Scalars["String"]["output"];
};

export type VirtualAgentControlMenuBottomSheetView = {
  __typename?: "VirtualAgentControlMenuBottomSheetView";
  element: Array<VirtualAgentControlMenuListItem>;
  toolbar?: Maybe<VirtualAgentControlMenuToolbar>;
};

export type VirtualAgentControlMenuItemType =
  | "DOWNLOAD_TRANSCRIPT"
  | "REPORT_SPAM"
  | "TOGGLE_BLOCK_CONVERSATION"
  | "TOGGLE_SOUND";

export interface VirtualAgentControlMenuListItem {
  id: Scalars["String"]["output"];
}

export type VirtualAgentControlMenuPopoverView = {
  __typename?: "VirtualAgentControlMenuPopoverView";
  element: Array<VirtualAgentControlMenuListItem>;
};

export type VirtualAgentControlMenuToolbar = {
  __typename?: "VirtualAgentControlMenuToolbar";
  navigationButton: UiTertiaryButton;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlMenuView = VirtualAgentControlMenuBottomSheetView | VirtualAgentControlMenuPopoverView;

export interface VirtualAgentControlMessageInput {
  attachments?: InputMaybe<Array<VirtualAgentControlAttachmentDataInput>>;
  intent?: InputMaybe<Scalars["String"]["input"]>;
  messageTriggerLocation?: InputMaybe<VirtualAgentControlMessageTriggerLocation>;
  messageType?: InputMaybe<VirtualAgentControlMessageType>;
  payload?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  umid: Scalars["String"]["input"];
}

export type VirtualAgentControlMessageMetadata = {
  __typename?: "VirtualAgentControlMessageMetadata";
  isActivityIndicatorMessage?: Maybe<Scalars["Boolean"]["output"]>;
  messageAuthor?: Maybe<Scalars["String"]["output"]>;
  messageId: Scalars["String"]["output"];
  timeStamp?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlMessageSeparator = {
  __typename?: "VirtualAgentControlMessageSeparator";
  primary: Scalars["String"]["output"];
  style?: Maybe<VirtualAgentControlMessageSeparatorTextStyle>;
  timestamp?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlMessageSeparatorTextStyle = {
  __typename?: "VirtualAgentControlMessageSeparatorTextStyle";
  bold?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type VirtualAgentControlMessageTriggerLocation = "EXTERNAL" | "INTERNAL";

export type VirtualAgentControlMessageType = "FILE" | "INTENT" | "POSTBACK" | "TEXT";

export type VirtualAgentControlMessages = {
  __typename?: "VirtualAgentControlMessages";
  messages: Array<VirtualAgentControlChatElement>;
  meta?: Maybe<VirtualAgentControlMessagesMetadata>;
};

export type VirtualAgentControlMessagesMetadata = {
  __typename?: "VirtualAgentControlMessagesMetadata";
  participants: Array<ConversationParticipant>;
};

export type VirtualAgentControlNotificationConfiguration = {
  __typename?: "VirtualAgentControlNotificationConfiguration";
  audioUrl?: Maybe<Scalars["String"]["output"]>;
  enableSound: Scalars["Boolean"]["output"];
};

export type VirtualAgentControlOutboundFileMessage = VirtualAgentControlOutboundMessageElement & {
  __typename?: "VirtualAgentControlOutboundFileMessage";
  /** @deprecated Prefer attachmentMetadata */
  attachment?: Maybe<VirtualAgentControlAttachmentData>;
  /** @deprecated Prefer attachmentList */
  attachmentLink: EgdsStandardLink;
  attachmentList?: Maybe<Array<VirtualAgentControlFileItem>>;
  /** @deprecated Prefer attachmentList */
  attachmentMetadata?: Maybe<VirtualAgentControlAttachmentData>;
  metadata: VirtualAgentControlMessageMetadata;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlOutboundMessage = {
  __typename?: "VirtualAgentControlOutboundMessage";
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
};

export interface VirtualAgentControlOutboundMessageElement {
  metadata: VirtualAgentControlMessageMetadata;
  status?: Maybe<Scalars["String"]["output"]>;
}

export type VirtualAgentControlOutboundMessageElementGroup = {
  __typename?: "VirtualAgentControlOutboundMessageElementGroup";
  messages: Array<VirtualAgentControlOutboundMessageElement>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlOutboundMessageGroup = {
  __typename?: "VirtualAgentControlOutboundMessageGroup";
  messages: Array<VirtualAgentControlOutboundMessage>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlOutboundTextMessage = VirtualAgentControlOutboundMessageElement & {
  __typename?: "VirtualAgentControlOutboundTextMessage";
  attachmentList?: Maybe<Array<VirtualAgentControlFileItem>>;
  metadata: VirtualAgentControlMessageMetadata;
  primary: Scalars["String"]["output"];
  status?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlPageViewAnalyticEvent = VirtualAgentControlAnalyticEvent & {
  __typename?: "VirtualAgentControlPageViewAnalyticEvent";
  eventName: Scalars["String"]["output"];
  eventVersion: Scalars["String"]["output"];
  payload: Scalars["AnalyticsPayload"]["output"];
};

export type VirtualAgentControlPopup = {
  __typename?: "VirtualAgentControlPopup";
  children?: Maybe<Array<VirtualAgentControlPopupItem>>;
};

export interface VirtualAgentControlPopupItem {
  showBottomSeparator?: Maybe<Scalars["Boolean"]["output"]>;
}

export type VirtualAgentControlPresenceConfiguration = {
  __typename?: "VirtualAgentControlPresenceConfiguration";
  heartbeatIntervalInMillis?: Maybe<Scalars["Int"]["output"]>;
  mode: VirtualAgentControlPresenceMode;
};

export type VirtualAgentControlPresenceMode = "HEARTBEAT" | "NONE";

export type VirtualAgentControlProgressBar = {
  __typename?: "VirtualAgentControlProgressBar";
  progressBar: EgdsProgressBar;
  theme?: Maybe<VirtualAgentControlProgressBarTheme>;
};

export type VirtualAgentControlProgressBarTheme = "MUTED" | "POSITIVE" | "STANDARD";

export type VirtualAgentControlQuickReplyItem = {
  __typename?: "VirtualAgentControlQuickReplyItem";
  button: EgdsButton;
  outboundMessage?: Maybe<Scalars["String"]["output"]>;
  payload?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlRating = {
  __typename?: "VirtualAgentControlRating";
  badge?: Maybe<EgdsBadge>;
  icon?: Maybe<Scalars["String"]["output"]>;
  primary?: Maybe<Scalars["String"]["output"]>;
  rating: Scalars["Float"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlRedirectAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlRedirectAction";
  analytics?: Maybe<ClientSideAnalytics>;
  target: UiLinkTarget;
  url: Scalars["String"]["output"];
};

export type VirtualAgentControlSendMessageAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlSendMessageAction";
  analytics?: Maybe<ClientSideAnalytics>;
  message: Scalars["String"]["output"];
  payload?: Maybe<Scalars["String"]["output"]>;
};

export type VirtualAgentControlSendMessageResult = {
  __typename?: "VirtualAgentControlSendMessageResult";
  deliveryStatus: Scalars["String"]["output"];
  messageId?: Maybe<Scalars["String"]["output"]>;
  umid: Scalars["String"]["output"];
};

export type VirtualAgentControlStateUpdates = {
  __typename?: "VirtualAgentControlStateUpdates";
  chatInput?: Maybe<ChatInput>;
};

export type VirtualAgentControlStepIndicator = {
  __typename?: "VirtualAgentControlStepIndicator";
  accessibilityLabel: Scalars["String"]["output"];
  activeStep: Scalars["Int"]["output"];
  steps: Array<VirtualAgentControlStepIndicatorStep>;
};

export type VirtualAgentControlStepIndicatorStep = {
  __typename?: "VirtualAgentControlStepIndicatorStep";
  icon?: Maybe<Icon>;
  primary: Scalars["String"]["output"];
  secondary?: Maybe<Scalars["String"]["output"]>;
};

export interface VirtualAgentControlSubmitSurveyInput {
  surveyData: VirtualAgentControlGetSurveyDataInput;
}

export type VirtualAgentControlSubmitSurveyResult = {
  __typename?: "VirtualAgentControlSubmitSurveyResult";
  success: Scalars["Boolean"]["output"];
};

export type VirtualAgentControlSurveyAnswer = {
  __typename?: "VirtualAgentControlSurveyAnswer";
  answer?: Maybe<Scalars["String"]["output"]>;
  answerDataType?: Maybe<Scalars["String"]["output"]>;
  order: Scalars["String"]["output"];
  question: Scalars["String"]["output"];
  questionCategory: Scalars["String"]["output"];
};

export interface VirtualAgentControlSurveyAnswerInput {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerDataType?: InputMaybe<Scalars["String"]["input"]>;
  order: Scalars["String"]["input"];
  question: Scalars["String"]["input"];
  questionCategory: Scalars["String"]["input"];
}

export type VirtualAgentControlToggleMenuListItem = VirtualAgentControlMenuListItem & {
  __typename?: "VirtualAgentControlToggleMenuListItem";
  checked: Scalars["Boolean"]["output"];
  checkedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  checkedAction?: Maybe<VirtualAgentControlActionEvent>;
  checkedAnalytics?: Maybe<ClientSideAnalytics>;
  checkedDescription?: Maybe<Scalars["String"]["output"]>;
  checkedIcon: Icon;
  checkedLabel: Scalars["String"]["output"];
  checkedToast?: Maybe<EgdsToast>;
  id: Scalars["String"]["output"];
  uncheckedAccessibilityLabel?: Maybe<Scalars["String"]["output"]>;
  uncheckedAction?: Maybe<VirtualAgentControlActionEvent>;
  uncheckedAnalytics?: Maybe<ClientSideAnalytics>;
  uncheckedDescription?: Maybe<Scalars["String"]["output"]>;
  uncheckedIcon: Icon;
  uncheckedLabel: Scalars["String"]["output"];
  uncheckedToast?: Maybe<EgdsToast>;
};

export type VirtualAgentControlToolbar = {
  __typename?: "VirtualAgentControlToolbar";
  closeButton?: Maybe<EgdsButton>;
};

export type VirtualAgentControlTrigger = {
  __typename?: "VirtualAgentControlTrigger";
  autoOpen: Scalars["Boolean"]["output"];
  button?: Maybe<VirtualAgentControlTriggerButton>;
};

export type VirtualAgentControlTriggerButton =
  | UiPrimaryButton
  | UiPrimaryFloatingActionButton
  | UiSecondaryButton
  | UiSecondaryFloatingActionButton
  | UiTertiaryButton;

export interface VirtualAgentControlUiAction {
  analytics?: Maybe<ClientSideAnalytics>;
}

export type VirtualAgentControlWebSocketConfig = {
  __typename?: "VirtualAgentControlWebSocketConfig";
  ablyConfig: VirtualAgentControlAblyConfig;
  subscriptionId: Scalars["String"]["output"];
};

export type VirtualAgentControlWebview = {
  __typename?: "VirtualAgentControlWebview";
  close: EgdsButton;
  srcDoc?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<HttpUri>;
};

export type VirtualAgentControlWebviewAction = VirtualAgentControlUiAction & {
  __typename?: "VirtualAgentControlWebviewAction";
  analytics?: Maybe<ClientSideAnalytics>;
  toolbar: UiToolbar;
  uri: Scalars["String"]["output"];
};

export type VirtualAgentConversationProperties = {
  __typename?: "VirtualAgentConversationProperties";
  launchPoint?: Maybe<Scalars["String"]["output"]>;
  pageName?: Maybe<Scalars["String"]["output"]>;
  skipWelcome?: Maybe<Scalars["String"]["output"]>;
};

export interface VirtualAgentIntentMessage {
  intent: Scalars["String"]["output"];
}

export type VirtualAgentIntentValue = {
  __typename?: "VirtualAgentIntentValue";
  id: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type VirtualAgentView = {
  __typename?: "VirtualAgentView";
  nativeWebVirtualAgent: HelpCenterNativeWebVa;
};

export type VirtualTourPrompt = {
  __typename?: "VirtualTourPrompt";
  button?: Maybe<EgdsOverlayButton>;
  caption?: Maybe<EgdsPlainText>;
  heading?: Maybe<EgdsHeading>;
  heroImage?: Maybe<EgdsPhotosphereImage>;
  url?: Maybe<Scalars["URL"]["output"]>;
};

export type VoiceOfTheCustomer = {
  __typename?: "VoiceOfTheCustomer";
  accessibilityMessage: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  displayAction: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  relativeURI: HttpUri;
};

export type WaiverSectionContent = {
  __typename?: "WaiverSectionContent";
  /** @deprecated Use table instead */
  agentToolsCalculator?: Maybe<FlightWaiverTable>;
  cancellationType?: Maybe<FlightsCancellationType>;
  flowApprover?: Maybe<AgentToolsWaiverContact>;
  ftcDates?: Maybe<AgentToolsFtcDates>;
  overRefundSectionContent?: Maybe<OverRefundSectionContent>;
  refundSource?: Maybe<EgdsBasicRadioGroup>;
  review?: Maybe<UiPrimaryButton>;
  table?: Maybe<FlightsWaiverTable>;
  title: Scalars["String"]["output"];
};

export type WaiverSectionError = {
  __typename?: "WaiverSectionError";
  error?: Maybe<Scalars["String"]["output"]>;
};

export interface WaiverSectionInput {
  airProviderLocator?: InputMaybe<Scalars["String"]["input"]>;
  isManualModification?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderNumber: Scalars["String"]["input"];
  partner: Partner;
  refundMode?: InputMaybe<RefundMode>;
  waivingType?: InputMaybe<WaiverType>;
}

export type WaiverSectionSuccess = {
  __typename?: "WaiverSectionSuccess";
  contents: WaiverSectionContent;
};

export type WaiverSectionView = WaiverSectionError | WaiverSectionSuccess;

export type WaiverType = "ELP" | "INSURANCE" | "NO_WAIVER" | "SUPPLIER";

export type WaivingSource = "COMBINED" | "EXPEDIA" | "NO_WAIVER" | "SUPPLIER";

export type WalletFaqExpandableText = {
  __typename?: "WalletFAQExpandableText";
  content: Array<WalletFaqTextField>;
  title: EgdsExpandoListItem;
};

export type WalletFaqScreen = {
  __typename?: "WalletFAQScreen";
  content?: Maybe<Array<WalletFaqExpandableText>>;
  title: EgdsPlainText;
};

export type WalletFaqTextField =
  | EgdsBulletedList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableList
  | EgdsSpannableText
  | EgdsStandardLink
  | EgdsUnorderedList;

export type WalletLedgerScreen = {
  __typename?: "WalletLedgerScreen";
  tabs: WalletLedgerTabs;
  title: EgdsPlainText;
};

export type WalletLedgerTabType = "ONE_KEY_CASH" | "REFUND_CREDITS";

export type WalletLedgerTabs = EgdsTabs & {
  __typename?: "WalletLedgerTabs";
  selectedTabId?: Maybe<Scalars["String"]["output"]>;
  tabs: Array<LedgerTab>;
};

export type WalletRefundCreditsActivity = {
  __typename?: "WalletRefundCreditsActivity";
  records: Array<WalletRefundCreditsEntry>;
};

export type WalletRefundCreditsContent = WalletRefundCreditsActivity | WalletRefundCreditsNoActivity;

export type WalletRefundCreditsEntry = {
  __typename?: "WalletRefundCreditsEntry";
  content: WalletRefundCreditsExpandoContent;
  expando: WalletRefundCreditsExpandoCardItem;
};

export type WalletRefundCreditsExpandoCardItem = {
  __typename?: "WalletRefundCreditsExpandoCardItem";
  expandoCard: EgdsExpandoCard;
  icon: Icon;
  status: WalletRefundStatusSection;
};

export type WalletRefundCreditsExpandoContent = {
  __typename?: "WalletRefundCreditsExpandoContent";
  sections: Array<WalletRefundCreditsSection>;
  title: Scalars["String"]["output"];
};

export type WalletRefundCreditsNoActivity = {
  __typename?: "WalletRefundCreditsNoActivity";
  description: EgdsPlainText;
  graphic: UiGraphic;
  header: EgdsPlainText;
};

export type WalletRefundCreditsSection = {
  __typename?: "WalletRefundCreditsSection";
  details: WalletRefundCreditsSectionDetails;
  header: EgdsPlainText;
};

export type WalletRefundCreditsSectionDetails = EgdsPlainText | EgdsStandardLink;

export type WalletRefundStatusSection = {
  __typename?: "WalletRefundStatusSection";
  amountChanged: EgdsStylizedText;
  description?: Maybe<EgdsStylizedText>;
};

export type WhatsappUri = Uri & {
  __typename?: "WhatsappURI";
  text: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type WidgetCardType = "GET_THERE" | "THINGS_TO_DO" | "VIBES" | "WEATHER";

export type WidgetResponse = LegacyWidgetComponent;

export type WishlistContent = DestinationWishlistContent | Image;

export type WishlistHeading = InfoWishlistHeading | PersonalWishlistHeading;

export type WishlistPageType = "DISCOVER" | "EXPANDED_WISHLIST" | "TRIPS";

export type WishlistUpdateResponse = {
  __typename?: "WishlistUpdateResponse";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  deeplink?: Maybe<Scalars["String"]["output"]>;
  impression?: Maybe<ClientSideAnalytics>;
  placeId?: Maybe<Scalars["String"]["output"]>;
  toast: EgdsToast;
};

export type WizardAdditionalUserInputRequiredAction = UiAction & {
  __typename?: "WizardAdditionalUserInputRequiredAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
  inputSource: WizardAdditionalUserInputSource;
};

export type WizardAdditionalUserInputSource = {
  __typename?: "WizardAdditionalUserInputSource";
  requestedInputs: Array<Scalars["String"]["output"]>;
  resource: Uri;
};

export type WizardMode = "CREATE" | "EDIT";

export type WizardStep = "AD" | "AD_GROUP" | "CAMPAIGN" | "REVIEW";

export type WizardSubmitAction = UiAction & {
  __typename?: "WizardSubmitAction";
  accessibility?: Maybe<Scalars["String"]["output"]>;
  actionContext: Scalars["String"]["output"];
  analytics: ClientSideAnalytics;
};

export type WorkFlow = {
  __typename?: "WorkFlow";
  automationStatus: Scalars["String"]["output"];
  automationStatusReason: Scalars["String"]["output"];
  channel: Scalars["String"]["output"];
  intent: Scalars["String"]["output"];
  orderLineId?: Maybe<Scalars["String"]["output"]>;
  productLine: Scalars["String"]["output"];
  subIntent?: Maybe<Scalars["String"]["output"]>;
};

export type WorkFlowItem = {
  __typename?: "WorkFlowItem";
  automationStatus: Scalars["String"]["output"];
  channel: Scalars["String"]["output"];
  intent: Scalars["String"]["output"];
  orderLineId: Scalars["String"]["output"];
  product: Scalars["String"]["output"];
};

export type WorkerInfo = {
  __typename?: "WorkerInfo";
  instanceId: Scalars["String"]["output"];
  workerId: Scalars["String"]["output"];
};

export type ZonedDateTime = {
  __typename?: "ZonedDateTime";
  day: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  epochSeconds: Scalars["String"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  formatted: Scalars["String"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  fullDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  fullTimeFormat?: Maybe<Scalars["String"]["output"]>;
  hour: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  longDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  longTimeFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  mediumTimeFormat?: Maybe<Scalars["String"]["output"]>;
  minute: Scalars["Int"]["output"];
  month: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  raw: Scalars["String"]["output"];
  second: Scalars["Int"]["output"];
  /** @deprecated Use platform formatters and return localized strings instead */
  shortDateFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  shortTimeFormat?: Maybe<Scalars["String"]["output"]>;
  /** @deprecated Use platform formatters and return localized strings instead */
  timeZoneOffsetSeconds: Scalars["Int"]["output"];
  year: Scalars["Int"]["output"];
};

export type ZonedDateTimeFormattedArgs = {
  format: Scalars["String"]["input"];
};

export type AdPreviewContent = LslAdPreviewContent;

export type ClickActionType = EgdsStandardLink | UiPrimaryButton | UiSecondaryButton | UiTertiaryButton;

export type Description =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsLevelList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsUnorderedList
  | LevelOneList
  | PortableTextHtmlTableSection
  | RichText;

export type Element = AgencyQuoteListTooltip | EgdsBasicCheckBox | EgdsPlainText | EgdsStandardLink;

export type InstallmentPlanDetails = AffirmContentDetails | AffirmDetails | KlarnaAppDetails | KlarnaDetails;

export type List = EgdsOrderedList | EgdsTextIconList | EgdsUnorderedList;

export type Messages = EgdsPlainText | MultiItemMessage;

export type PortableTextContent =
  | EgdsHeading
  | EgdsInlineLink
  | EgdsLevelList
  | EgdsOrderedList
  | EgdsPlainText
  | EgdsSpannableText
  | EgdsStylizedText
  | EgdsUnorderedList
  | LevelOneList
  | PortableTextHtmlTableSection;

export type SpecialOfferTypeMessagingWrapper = {
  __typename?: "specialOfferTypeMessagingWrapper";
  specialOfferTypeMessaging: EgdsMoreInfoTrigger;
};

export interface SupplyReviewsContext {
  filters?: InputMaybe<Array<SupplyReviewsFilterInput>>;
}

export interface SupplyReviewsFilterInput {
  key: Scalars["String"]["input"];
  value: Array<Scalars["String"]["input"]>;
}

export type BlossomNotificationQueryVariables = Exact<{
  context: ContextInput;
  notificationLocation: NotificationLocationOnPage;
  lineOfBusiness: LineOfBusinessDomain;
  funnelLocation: PageLocation;
}>;

export type BlossomNotificationQuery = {
  __typename?: "Query";
  notification: {
    __typename?: "NotificationQuery";
    inlineNotification: {
      __typename?: "InlineNotification";
      type: NotificationType;
      notificationLocation?: NotificationLocationOnPage | null;
      title?: {
        __typename?: "NotificationPhrase";
        items: Array<
          | {
              __typename: "NotificationPhraseLinkNode";
              target?: NotificationLinkTarget | null;
              text: string;
              styles: Array<NotificationPhrasePartStyle>;
              uri?:
                | { __typename: "ClipboardURI"; value: string }
                | { __typename: "FBMessengerURI"; value: string }
                | { __typename: "GeoURI"; value: string }
                | { __typename: "HttpURI"; value: string }
                | { __typename: "MailToURI"; value: string }
                | { __typename: "MobileAppURI"; value: string }
                | { __typename: "RelativeURI"; value: string }
                | { __typename: "SMSToURI"; value: string }
                | { __typename: "SelfReferencedURI"; value: string }
                | { __typename: "TelURI"; value: string }
                | { __typename: "Uri"; value: string }
                | { __typename: "WhatsappURI"; value: string }
                | null;
              actions?: Array<
                | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
                | { __typename: "NotificationAnalyticsEgClickstream" }
                | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
                | { __typename: "NotificationMarketing" }
                | { __typename: "NotificationSchema" }
                | { __typename: "NotificationSpecifications" }
              > | null;
            }
          | { __typename: "NotificationPhraseTextNode"; text: string; styles: Array<NotificationPhrasePartStyle> }
        >;
      } | null;
      body?: Array<{
        __typename?: "NotificationPhrase";
        items: Array<
          | {
              __typename: "NotificationPhraseLinkNode";
              target?: NotificationLinkTarget | null;
              id?: string | null;
              text: string;
              styles: Array<NotificationPhrasePartStyle>;
              uri?:
                | { __typename: "ClipboardURI"; value: string }
                | { __typename: "FBMessengerURI"; value: string }
                | { __typename: "GeoURI"; value: string }
                | { __typename: "HttpURI"; value: string }
                | { __typename: "MailToURI"; value: string }
                | { __typename: "MobileAppURI"; value: string }
                | { __typename: "RelativeURI"; value: string }
                | { __typename: "SMSToURI"; value: string }
                | { __typename: "SelfReferencedURI"; value: string }
                | { __typename: "TelURI"; value: string }
                | { __typename: "Uri"; value: string }
                | { __typename: "WhatsappURI"; value: string }
                | null;
              actions?: Array<
                | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
                | { __typename: "NotificationAnalyticsEgClickstream" }
                | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
                | { __typename: "NotificationMarketing" }
                | { __typename: "NotificationSchema" }
                | { __typename: "NotificationSpecifications" }
              > | null;
              icon?: { __typename?: "Icon"; id: string; description: string } | null;
            }
          | { __typename: "NotificationPhraseTextNode"; text: string; styles: Array<NotificationPhrasePartStyle> }
        >;
      }> | null;
      containerLink?: {
        __typename?: "NotificationContainerLink";
        target?: NotificationLinkTarget | null;
        uri?:
          | { __typename: "ClipboardURI"; value: string }
          | { __typename: "FBMessengerURI"; value: string }
          | { __typename: "GeoURI"; value: string }
          | { __typename: "HttpURI"; value: string }
          | { __typename: "MailToURI"; value: string }
          | { __typename: "MobileAppURI"; value: string }
          | { __typename: "RelativeURI"; value: string }
          | { __typename: "SMSToURI"; value: string }
          | { __typename: "SelfReferencedURI"; value: string }
          | { __typename: "TelURI"; value: string }
          | { __typename: "Uri"; value: string }
          | { __typename: "WhatsappURI"; value: string }
          | null;
        actions?: Array<
          | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
          | { __typename: "NotificationAnalyticsEgClickstream" }
          | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
          | { __typename: "NotificationMarketing" }
          | { __typename: "NotificationSchema" }
          | { __typename: "NotificationSpecifications" }
        > | null;
      } | null;
      logo?: { __typename?: "Image"; description: string; url: any } | null;
      links?: Array<{
        __typename?: "NotificationPhraseLinkNode";
        id?: string | null;
        text: string;
        uri?:
          | { __typename: "ClipboardURI"; value: string }
          | { __typename: "FBMessengerURI"; value: string }
          | { __typename: "GeoURI"; value: string }
          | { __typename: "HttpURI"; value: string }
          | { __typename: "MailToURI"; value: string }
          | { __typename: "MobileAppURI"; value: string }
          | { __typename: "RelativeURI"; value: string }
          | { __typename: "SMSToURI"; value: string }
          | { __typename: "SelfReferencedURI"; value: string }
          | { __typename: "TelURI"; value: string }
          | { __typename: "Uri"; value: string }
          | { __typename: "WhatsappURI"; value: string }
          | null;
        icon?: { __typename?: "Icon"; id: string; description: string } | null;
        actions?: Array<
          | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
          | { __typename: "NotificationAnalyticsEgClickstream" }
          | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
          | { __typename: "NotificationMarketing" }
          | { __typename: "NotificationSchema" }
          | { __typename: "NotificationSpecifications" }
        > | null;
      }> | null;
      dismiss?: {
        __typename?: "NotificationDismiss";
        icon: { __typename?: "Icon"; id: string; description: string };
        actions?: Array<
          | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
          | { __typename: "NotificationAnalyticsEgClickstream" }
          | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
          | { __typename: "NotificationMarketing" }
          | { __typename: "NotificationSchema" }
          | { __typename: "NotificationSpecifications" }
        > | null;
      } | null;
      revealActions?: Array<
        | { __typename: "NotificationAnalytics"; description?: string | null; referrerId: string }
        | { __typename: "NotificationAnalyticsEgClickstream" }
        | { __typename: "NotificationCookie"; name: string; value: string; expires: string }
        | { __typename: "NotificationMarketing" }
        | { __typename: "NotificationSchema" }
        | { __typename: "NotificationSpecifications" }
      > | null;
      backgroundImage?: { __typename?: "Image"; url: any } | null;
    };
  };
};

export type CampaignByIdQueryQueryVariables = Exact<{
  productType?: InputMaybe<Array<CampaignProductType> | CampaignProductType>;
  context: ContextInput;
  userLocation?: InputMaybe<UserLocationInput>;
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  enableTripAttach?: InputMaybe<Scalars["Boolean"]["input"]>;
  displayPropertyCount?: InputMaybe<Scalars["Boolean"]["input"]>;
  campaignId: Scalars["String"]["input"];
  pageSize?: InputMaybe<Scalars["Int"]["input"]>;
  pageNumber: Scalars["Int"]["input"];
  enableLivePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  returnPath?: InputMaybe<Scalars["String"]["input"]>;
  destinationId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CampaignByIdQueryQuery = {
  __typename?: "Query";
  campaignRecommendations?: {
    __typename?: "RecommendedCampaigns";
    responseId: any;
    campaigns: Array<{
      __typename?: "CampaignDetail";
      headline: string;
      fromOriginHeaderText?: string | null;
      termsAndConditions: string;
      productTypes: Array<string>;
      recommendedType: RecommendedType;
      campaignId: string;
      campaignContentPaginationInfo?: {
        __typename?: "CampaignContentPaginationInfo";
        hasMoreContentPages: boolean;
      } | null;
      description: { __typename?: "CampaignDescriptions"; short?: string | null; long?: string | null };
      offers?: Array<
        | {
            __typename: "ActivityOfferItem";
            title?: string | null;
            description?: string | null;
            duration?: string | null;
            durationLabel?: string | null;
            city: string;
            province?: string | null;
            dates?: string | null;
            freeCancellationMessage?: string | null;
            name: string;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            image: { __typename?: "Image"; description: string; url: any };
            activityOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string; qualifierText: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
          }
        | {
            __typename: "DestinationOfferItem";
            affinities?: Array<string> | null;
            propertyCount?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: { __typename?: "OfferPrice"; value: string; qualifierText: string; disclaimerText: string };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "FlightOfferItem";
            airlineName?: string | null;
            originCity?: string | null;
            originAirportCode?: string | null;
            destinationCity?: string | null;
            destinationAirportCode?: string | null;
            tripType?: string | null;
            flightClass?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: { __typename?: "OfferPrice"; value: string; qualifierText: string; prefixText?: string | null };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "HotelOfferItem";
            location?: string | null;
            starRating?: number | null;
            guestReviewRating?: string | null;
            guestReviewRatingQualifierText?: string | null;
            guestReviewCount?: string | null;
            vipMessage?: string | null;
            freeCancellationMessage?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            discountBadge?: {
              __typename?: "CampaignOfferBadge";
              text?: string | null;
              type: CampaignOfferBadgeType;
            } | null;
            mod?: {
              __typename?: "MODItem";
              modTagText?: string | null;
              modAvailabilityText?: string | null;
              modSignInText?: string | null;
              signInUrl?: { __typename?: "HttpURI"; value: string; relativePath: string } | null;
            } | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PackageOfferItem";
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            flightOfferItem?: {
              __typename?: "FlightOfferItem";
              airlineName?: string | null;
              originCity?: string | null;
              originAirportCode?: string | null;
              destinationCity?: string | null;
              destinationAirportCode?: string | null;
              airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            } | null;
            hotelOfferItem?: {
              __typename?: "HotelOfferItem";
              name: string;
              location?: string | null;
              vipMessage?: string | null;
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PropertyOfferItem";
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
      > | null;
    }>;
    paginationInfo: { __typename?: "CampaignPagination"; referrer: string };
  } | null;
};

export type CampaignRecommendationsWithOffersQueryQueryVariables = Exact<{
  numCampaigns: Scalars["Int"]["input"];
  pageNumberOfCampaigns: Scalars["Int"]["input"];
  maxNumberOfCampaigns?: InputMaybe<Scalars["Int"]["input"]>;
  productType?: InputMaybe<Array<CampaignProductType> | CampaignProductType>;
  recommendationType?: InputMaybe<Array<InputMaybe<RecommendedType>> | InputMaybe<RecommendedType>>;
  context: ContextInput;
  userLocation?: InputMaybe<UserLocationInput>;
  referrer?: InputMaybe<Scalars["String"]["input"]>;
  userTripState?: InputMaybe<Scalars["String"]["input"]>;
  enableLivePricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  returnPath?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CampaignRecommendationsWithOffersQueryQuery = {
  __typename?: "Query";
  campaignRecommendations?: {
    __typename?: "RecommendedCampaigns";
    responseId: any;
    campaigns: Array<{
      __typename?: "CampaignDetail";
      headline: string;
      fromOriginHeaderText?: string | null;
      termsAndConditions: string;
      productTypes: Array<string>;
      recommendedType: RecommendedType;
      campaignId: string;
      description: { __typename?: "CampaignDescriptions"; short?: string | null; long?: string | null };
      offers?: Array<
        | {
            __typename: "ActivityOfferItem";
            title?: string | null;
            description?: string | null;
            duration?: string | null;
            durationLabel?: string | null;
            city: string;
            province?: string | null;
            dates?: string | null;
            freeCancellationMessage?: string | null;
            name: string;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            image: { __typename?: "Image"; description: string; url: any };
            activityOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string; qualifierText: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
          }
        | {
            __typename: "DestinationOfferItem";
            affinities?: Array<string> | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "FlightOfferItem";
            airlineName?: string | null;
            originCity?: string | null;
            originAirportCode?: string | null;
            destinationCity?: string | null;
            destinationAirportCode?: string | null;
            tripType?: string | null;
            flightClass?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            flightOffer?: {
              __typename?: "OfferData";
              price: { __typename?: "OfferPrice"; value: string; qualifierText: string; prefixText?: string | null };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "HotelOfferItem";
            location?: string | null;
            starRating?: number | null;
            guestReviewRating?: string | null;
            guestReviewRatingQualifierText?: string | null;
            guestReviewCount?: string | null;
            vipMessage?: string | null;
            freeCancellationMessage?: string | null;
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            badge?: { __typename?: "CampaignOfferBadge"; text?: string | null; type: CampaignOfferBadgeType } | null;
            discountBadge?: {
              __typename?: "CampaignOfferBadge";
              text?: string | null;
              type: CampaignOfferBadgeType;
            } | null;
            mod?: {
              __typename?: "MODItem";
              modTagText?: string | null;
              modAvailabilityText?: string | null;
              modSignInText?: string | null;
              signInUrl?: { __typename?: "HttpURI"; value: string; relativePath: string } | null;
            } | null;
            lodgingOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                disclaimerText: string;
                allyText?: string | null;
              };
              strikeThroughPrice?: { __typename?: "OfferPrice"; value: string } | null;
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PackageOfferItem";
            dates?: string | null;
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            flightOfferItem?: {
              __typename?: "FlightOfferItem";
              airlineName?: string | null;
              originCity?: string | null;
              originAirportCode?: string | null;
              destinationCity?: string | null;
              destinationAirportCode?: string | null;
              airlineImage?: { __typename?: "Image"; description: string; url: any } | null;
            } | null;
            hotelOfferItem?: {
              __typename?: "HotelOfferItem";
              name: string;
              location?: string | null;
              vipMessage?: string | null;
            } | null;
            packageOffer?: {
              __typename?: "OfferData";
              price: {
                __typename?: "OfferPrice";
                value: string;
                qualifierText: string;
                prefixText?: string | null;
                checkPriceText?: string | null;
              };
              actionLink: { __typename?: "OfferActionLink"; href: string; text: string };
            } | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
        | {
            __typename: "PropertyOfferItem";
            name: string;
            description?: string | null;
            secondaryContent?: string | null;
            image: { __typename?: "Image"; description: string; url: any };
          }
      > | null;
    }>;
    paginationInfo: { __typename?: "CampaignPagination"; referrer: string; hasNextPage: boolean };
  } | null;
};

export type CustomerPropertySearchByIdQueryQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type CustomerPropertySearchByIdQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      name: string;
      id: string;
      infositeURL: string;
      price?: {
        __typename?: "PropertyPrice";
        options?: Array<{
          __typename?: "PropertyPriceOption";
          displayPrice?: {
            __typename?: "Money";
            formatted: string;
            amount: number;
            currencyInfo?: { __typename?: "Currency"; symbol: string; code: string; name: string } | null;
          } | null;
        }> | null;
      } | null;
      image?: { __typename?: "Image"; url: any } | null;
    }>;
    searchCriteria: {
      __typename?: "SearchCriteria";
      dateRange: {
        __typename?: "PropertyDateRange";
        checkInDate: { __typename?: "Date"; isoFormat: string; day: number; month: number; year: number };
        checkOutDate: { __typename?: "Date"; isoFormat: string; day: number; month: number; year: number };
      };
    };
  };
};

export type CustomerSearchHistoryAllQueryQueryVariables = Exact<{
  context: ContextInput;
  lobs?: InputMaybe<Array<HistoryType> | HistoryType>;
  numberOfResults?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CustomerSearchHistoryAllQueryQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "Customer";
    searchHistory?: Array<{
      __typename?: "History";
      type?: HistoryType | null;
      items?: Array<{
        __typename?: "HistoryItem";
        id?: string | null;
        localizedDateRange?: string | null;
        localizedAsOfDateTime?: string | null;
        lastViewedDate: {
          __typename?: "ZonedDateTime";
          day: number;
          hour: number;
          minute: number;
          second: number;
          month: number;
          year: number;
        };
        startDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        endDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        originalSearchPrice?: {
          __typename?: "Money";
          amount: number;
          formatted: string;
          currencyInfo?: { __typename?: "Currency"; code: string; name: string; symbol: string } | null;
        } | null;
        productInfo?:
          | {
              __typename?: "HistoryActivityDetail";
              name?: string | null;
              duration?: string | null;
              leadTicketType?: string | null;
              searchUrl?: string | null;
              imageUrl?: { __typename?: "Image"; description: string; url: any } | null;
            }
          | {
              __typename?: "HistoryActivitySearch";
              searchUrl?: string | null;
              regionDetail?: { __typename?: "GeographicFeature"; name: string } | null;
            }
          | { __typename?: "HistoryCarDetail" }
          | { __typename?: "HistoryCarSearch" }
          | { __typename?: "HistoryCruiseSearch" }
          | {
              __typename?: "HistoryFlightDetail";
              localizedFlightName?: string | null;
              searchUrl?: string | null;
              routeType?: string | null;
              flightPassengers?: {
                __typename?: "FlightPassengers";
                adults?: number | null;
                agesOfChildren?: Array<number> | null;
              } | null;
            }
          | {
              __typename?: "HistoryFlightSearch";
              localizedFlightName?: string | null;
              searchUrl?: string | null;
              routeType?: string | null;
              flightPassengers?: {
                __typename?: "FlightPassengers";
                adults?: number | null;
                agesOfChildren?: Array<number> | null;
              } | null;
            }
          | {
              __typename?: "HistoryPackageSearch";
              localizedPackageName?: string | null;
              localizedRegionName?: string | null;
              localizedPackagesTemplate?: string | null;
              searchUrl?: string | null;
            }
          | {
              __typename?: "HistoryPropertyDetail";
              regionId?: string | null;
              formattedRating: string;
              formattedReviewCount: string;
              propertyId?: string | null;
              searchUrl?: string | null;
              summary?: { __typename?: "PropertySummary"; id: string; name: string } | null;
              regionDetail?: { __typename?: "GeographicFeature"; name: string } | null;
              reviewInfo?: {
                __typename?: "PropertyReviews";
                summary: {
                  __typename?: "PropertyReviewSummary";
                  averageOverallRating: { __typename?: "FormattedNumber"; formatted: string };
                  totalCount: { __typename?: "FormattedNumber"; formatted: string };
                };
              } | null;
              gallery?: {
                __typename?: "PropertyGallery";
                featured?: { __typename?: "Image"; description: string; url: any } | null;
              } | null;
            }
          | {
              __typename?: "HistoryPropertySearch";
              localizedRoomsTemplate?: string | null;
              searchUrl?: string | null;
              regionDetail?: { __typename?: "GeographicFeature"; name: string } | null;
            }
          | null;
      } | null> | null;
    }> | null;
  };
};

export type CustomerSearchHistoryQueryQueryVariables = Exact<{
  context: ContextInput;
  lobs?: InputMaybe<Array<HistoryType> | HistoryType>;
  numberOfResults?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CustomerSearchHistoryQueryQuery = {
  __typename?: "Query";
  customer: {
    __typename?: "Customer";
    searchHistory?: Array<{
      __typename?: "History";
      type?: HistoryType | null;
      items?: Array<{
        __typename?: "HistoryItem";
        startDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        endDate: { __typename?: "ZonedDateTime"; raw: string; year: number; month: number; day: number };
        originalSearchPrice?: {
          __typename?: "Money";
          amount: number;
          formatted: string;
          currencyInfo?: { __typename?: "Currency"; code: string; name: string; symbol: string } | null;
        } | null;
        productInfo?:
          | { __typename?: "HistoryActivityDetail" }
          | { __typename?: "HistoryActivitySearch" }
          | { __typename?: "HistoryCarDetail" }
          | { __typename?: "HistoryCarSearch" }
          | { __typename?: "HistoryCruiseSearch" }
          | { __typename?: "HistoryFlightDetail" }
          | { __typename?: "HistoryFlightSearch" }
          | { __typename?: "HistoryPackageSearch" }
          | {
              __typename?: "HistoryPropertyDetail";
              regionId?: string | null;
              propertyId?: string | null;
              propertySummary?: { __typename?: "PropertySummary"; id: string; name: string } | null;
              propertyImageGallery?: {
                __typename?: "PropertyImageGallery";
                featured?: { __typename?: "PropertyImage"; image: { __typename?: "Image"; url: any } } | null;
              } | null;
            }
          | { __typename?: "HistoryPropertySearch" }
          | null;
      } | null> | null;
    }> | null;
  };
};

export type DxPropertySearchQueryQueryVariables = Exact<{
  context: ContextInput;
  hotelIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type DxPropertySearchQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      id: string;
      name: string;
      infositeURL: string;
      price?: {
        __typename?: "PropertyPrice";
        lead?: { __typename?: "Money"; formatted: string; amount: number } | null;
      } | null;
      reviews: { __typename?: "PropertyReviewsSummary"; score?: number | null; total?: number | null };
      gallery?: Array<{ __typename?: "Image"; url: any; description: string }> | null;
    }>;
  };
};

export type MapPropertySearchByLatLongQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type MapPropertySearchByLatLongQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      name: string;
      id: string;
      infositeURL: string;
      star?: number | null;
      price?: {
        __typename?: "PropertyPrice";
        lead?: { __typename?: "Money"; formatted: string; amount: number } | null;
      } | null;
      reviews: {
        __typename?: "PropertyReviewsSummary";
        score?: number | null;
        total?: number | null;
        localizedSubtitle?: string | null;
      };
      image?: { __typename?: "Image"; url: any } | null;
      mapMarker?: {
        __typename?: "MapMarker";
        latLong: { __typename?: "Coordinates"; latitude: number; longitude: number };
      } | null;
    }>;
    summary: {
      __typename?: "PropertyResultsSummary";
      messages?: Array<{
        __typename?: "MessageResult";
        type?: MessageType | null;
        title?: { __typename?: "MessagingResultTitle"; text?: string | null } | null;
        subtitle?: { __typename?: "MessagingResultTitle"; text?: string | null } | null;
      }> | null;
    };
  };
};

export type MerchCouponsQueryQueryVariables = Exact<{
  context: ContextInput;
  couponIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type MerchCouponsQueryQuery = {
  __typename?: "Query";
  merchCoupons?: {
    __typename?: "MerchCoupons";
    coupons: Array<{
      __typename?: "CouponDetail";
      id: string;
      name: string;
      statusMessage: string;
      termsAndConditions: {
        __typename?: "CouponTermsAndConditions";
        text: string;
        informationUrl: { __typename?: "HttpURI"; value: string };
      };
    }>;
  } | null;
};

export type CouponDetailFragmentFragment = {
  __typename?: "CouponDetail";
  id: string;
  name: string;
  statusMessage: string;
  termsAndConditions: {
    __typename?: "CouponTermsAndConditions";
    text: string;
    informationUrl: { __typename?: "HttpURI"; value: string };
  };
};

export type MojoLandingQueryQueryVariables = Exact<{
  context: ContextInput;
  mojoContext: MojoContextInput;
  url: Scalars["URL"]["input"];
}>;

export type MojoLandingQueryQuery = {
  __typename?: "Query";
  mojoLandingSection?: { __typename?: "MojoLandingSection"; data?: string | null } | null;
};

export type MojoQueryQueryVariables = Exact<{
  context: ContextInput;
  mojoContext: MojoContextInput;
}>;

export type MojoQueryQuery = {
  __typename?: "Query";
  mojoSection?: { __typename?: "MojoSection"; data: string } | null;
};

export type PrefetchSearchResultsMutationVariables = Exact<{
  context: ContextInput;
  criteria: PropertySearchCriteriaInput;
  shoppingContext?: InputMaybe<ShoppingContextInput>;
}>;

export type PrefetchSearchResultsMutation = {
  __typename?: "Mutation";
  preloadPropertySearch: {
    __typename?: "PropertySearchPreloadResults";
    criteria: {
      __typename?: "PropertySearchCriteria";
      primary: {
        __typename?: "PrimaryPropertyCriteria";
        destination: { __typename?: "Destination"; regionId?: string | null; regionName?: string | null };
      };
    };
  };
};

export type PropertyFilterSearchQueryQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type PropertyFilterSearchQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    sortAndFilter: {
      __typename?: "SortAndFilter";
      selected?: {
        __typename?: "SortAndFilterSelected";
        priceFilterPills?: {
          __typename?: "PriceFilterPills";
          aggregate?: { __typename?: "PriceFilterPillsAggregate"; label?: string | null } | null;
        } | null;
      } | null;
    };
    propertySearchListings: Array<
      | { __typename?: "EGDSStandardMessagingCard" }
      | { __typename?: "FlexibleDateSearchCard" }
      | { __typename?: "LodgingCard" }
      | { __typename?: "LodgingHeading" }
      | { __typename?: "LodgingIntentCard" }
      | { __typename?: "LodgingListingDivider" }
      | { __typename?: "LodgingSurveyTrigger" }
      | {
          __typename: "MessageResult";
          index?: number | null;
          type?: MessageType | null;
          title?: {
            __typename?: "MessagingResultTitle";
            text?: string | null;
            icon?: { __typename?: "Icon"; description: string; id: string } | null;
          } | null;
          subtitle?: { __typename?: "MessagingResultTitle"; text?: string | null } | null;
        }
      | { __typename?: "NestedListingContainer" }
      | {
          __typename: "Property";
          id: string;
          name: string;
          addressMessaging?: string | null;
          star?: number | null;
          structureType: StructureType;
          offerSummary: {
            __typename?: "OfferSummary";
            messages?: Array<{ __typename?: "OfferSummaryMessage"; type?: string | null }> | null;
          };
          propertyImage?: { __typename?: "PropertyImage"; image: { __typename?: "Image"; url: any } } | null;
          destinationInfo: {
            __typename?: "PropertyDestinationInfo";
            distanceFromMessaging?: string | null;
            distanceFromDestination?: { __typename?: "Distance"; unit: DistanceUnit; value: number } | null;
          };
          mapMarker?: {
            __typename?: "MapMarker";
            latLong: { __typename?: "Coordinates"; latitude: number; longitude: number };
          } | null;
          reviews: { __typename?: "PropertyReviewsSummary"; score?: number | null; total?: number | null };
          price?: {
            __typename?: "PropertyPrice";
            priceMessages?: Array<{ __typename?: "LodgingPlainMessage"; value: string }> | null;
            options?: Array<{
              __typename?: "PropertyPriceOption";
              displayPrice?: {
                __typename?: "Money";
                amount: number;
                formatted: string;
                currencyInfo?: { __typename?: "Currency"; code: string } | null;
              } | null;
            }> | null;
          } | null;
          propertyDetailsLink?: {
            __typename?: "LodgingLink";
            uri: { __typename?: "HttpURI"; relativePath: string; value: string };
          } | null;
        }
      | { __typename?: "PropertyListingsIconTextExpando" }
      | { __typename?: "PropertySearchListingPlaceholder" }
      | { __typename?: "PropertySearchListingsOverfilteredCard" }
      | { __typename?: "PropertySearchListingsSoldOutCard" }
      | { __typename?: "RecentlyViewedPropertiesCarouselWrapper" }
      | { __typename?: "ShoppingListingContainer" }
      | { __typename?: "SponsoredContentPlacement" }
      | { __typename?: "StaticLodgingMultiItemCrossSell" }
      | { __typename?: "TotalPriceReassuranceCard" }
    >;
  };
};

export type PropertyFiltersQueryQueryVariables = Exact<{
  context: ContextInput;
  destination: DestinationInput;
  criteria?: InputMaybe<PropertySearchCriteriaInput>;
}>;

export type PropertyFiltersQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    sortAndFilter: {
      __typename?: "SortAndFilter";
      options?: {
        __typename?: "SortAndFilterViewModel";
        sortAndFilter: Array<{
          __typename?: "FilterViewModel";
          label?: string | null;
          subLabel?: string | null;
          name: string;
          max?: number | null;
          min?: number | null;
          step?: number | null;
          options: Array<{
            __typename?: "OptionViewModel";
            label?: string | null;
            optionValue?: string | null;
            filterCategory?: string | null;
            description?: string | null;
            selectedLabel?: string | null;
            isSelected: boolean;
            range?: { __typename?: "FilterOptionRange"; max?: number | null; min?: number | null } | null;
          }>;
        }>;
      } | null;
    };
  };
};

export type QueryPropertyHeroImageQueryVariables = Exact<{
  context: ContextInput;
  propertyId: Scalars["String"]["input"];
}>;

export type QueryPropertyHeroImageQuery = {
  __typename?: "Query";
  propertyInfo: {
    __typename?: "PropertyInfo";
    id: string;
    gallery?: {
      __typename?: "PropertyGallery";
      featured?: { __typename?: "Image"; description: string; url: any } | null;
    } | null;
  };
};

export type QueryPropertyReviewsHeadlineQueryVariables = Exact<{
  context: ContextInput;
  propertyId: Scalars["String"]["input"];
}>;

export type QueryPropertyReviewsHeadlineQuery = {
  __typename?: "Query";
  propertyInfo: {
    __typename?: "PropertyInfo";
    id: string;
    infositeUrl?: any | null;
    summary?: {
      __typename?: "PropertySummary";
      id: string;
      name: string;
      overview?: { __typename?: "PropertyOverview"; starRating?: number | null } | null;
      location?: {
        __typename?: "PropertyLocation";
        address?: {
          __typename?: "PropertyAddress";
          city?: string | null;
          countryCode?: string | null;
          firstAddressLine?: string | null;
          secondAddressLine?: string | null;
          postalCode?: string | null;
          province?: string | null;
        } | null;
      } | null;
    } | null;
  };
};

export type PropertySearchQueryQueryVariables = Exact<{
  context: ContextInput;
  hotelIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type PropertySearchQueryQuery = {
  __typename?: "Query";
  propertySearch: {
    __typename?: "PropertySearchResults";
    properties: Array<{
      __typename?: "Property";
      id: string;
      name: string;
      gallery?: Array<{ __typename?: "Image"; url: any; description: string }> | null;
    }>;
  };
};

export type TravelAgentSearchWizardConfigQueryQueryVariables = Exact<{
  context: ContextInput;
}>;

export type TravelAgentSearchWizardConfigQueryQuery = {
  __typename?: "Query";
  travelAgentSearchWizardConfig?: {
    __typename?: "TravelAgentSearchWizardConfig";
    packageRatesEnabled: boolean;
    enabledLobs: Array<string>;
    packageRatesDetail?: {
      __typename?: "TravelAgentPackageRatesDetail";
      heading?: string | null;
      termsAndConditions: string;
    } | null;
  } | null;
};

export const CouponDetailFragmentFragmentDoc = ({
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CouponDetailFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CouponDetail" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "statusMessage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "termsAndConditions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "informationUrl" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CouponDetailFragmentFragment, unknown>;
export const BlossomNotificationDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "BlossomNotification" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "notificationLocation" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "NotificationLocationOnPage" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lineOfBusiness" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LineOfBusinessDomain" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "funnelLocation" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "PageLocation" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "notification" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "inlineNotification" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "lineOfBusiness" },
                      value: { kind: "Variable", name: { kind: "Name", value: "lineOfBusiness" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "notificationLocation" },
                      value: { kind: "Variable", name: { kind: "Name", value: "notificationLocation" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pageLocation" },
                      value: { kind: "Variable", name: { kind: "Name", value: "funnelLocation" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "notificationLocation" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "title" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "styles" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationPhraseLinkNode" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uri" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actions" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationAnalytics" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "description" } },
                                                    { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationCookie" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "name" } },
                                                    { kind: "Field", name: { kind: "Name", value: "value" } },
                                                    { kind: "Field", name: { kind: "Name", value: "expires" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "body" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "items" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                  { kind: "Field", name: { kind: "Name", value: "styles" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationPhraseLinkNode" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "uri" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actions" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationAnalytics" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "description" } },
                                                    { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "NotificationCookie" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "name" } },
                                                    { kind: "Field", name: { kind: "Name", value: "value" } },
                                                    { kind: "Field", name: { kind: "Name", value: "expires" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "icon" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "containerLink" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "target" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationAnalytics" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationCookie" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                        { kind: "Field", name: { kind: "Name", value: "expires" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "logo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "links" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "text" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "uri" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationAnalytics" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationCookie" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                        { kind: "Field", name: { kind: "Name", value: "expires" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dismiss" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "icon" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "actions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationAnalytics" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "NotificationCookie" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                        { kind: "Field", name: { kind: "Name", value: "expires" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "revealActions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "NotificationAnalytics" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "referrerId" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NotificationCookie" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "value" } },
                                  { kind: "Field", name: { kind: "Name", value: "expires" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "backgroundImage" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<BlossomNotificationQuery, BlossomNotificationQueryVariables>;
export const CampaignByIdQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CampaignByIdQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productType" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "CampaignProductType" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UserLocationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "enableTripAttach" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "displayPropertyCount" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "campaignId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageNumber" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destinationId" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "campaignRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "productType" },
                value: { kind: "Variable", name: { kind: "Name", value: "productType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userLocation" },
                value: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "referrer" },
                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "enableTripAttach" },
                value: { kind: "Variable", name: { kind: "Name", value: "enableTripAttach" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "campaignParameters" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "campaignId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "campaignId" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "contentPagination" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "pageSize" },
                            value: { kind: "Variable", name: { kind: "Name", value: "pageSize" } },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "pageNumber" },
                            value: { kind: "Variable", name: { kind: "Name", value: "pageNumber" } },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "displayPropertyCount" },
                value: { kind: "Variable", name: { kind: "Name", value: "displayPropertyCount" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "includeDeals" },
                value: { kind: "BooleanValue", value: true },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "enableLivePricing" },
                value: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destinationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "destinationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaigns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "campaignId" },
                        name: { kind: "Name", value: "id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "headline" } },
                      { kind: "Field", name: { kind: "Name", value: "fromOriginHeaderText" } },
                      { kind: "Field", name: { kind: "Name", value: "termsAndConditions" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "campaignContentPaginationInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "hasMoreContentPages" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "short" } },
                            { kind: "Field", name: { kind: "Name", value: "long" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "productTypes" } },
                      { kind: "Field", name: { kind: "Name", value: "recommendedType" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "secondaryContent" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DestinationOfferItem" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "affinities" } },
                                  { kind: "Field", name: { kind: "Name", value: "propertyCount" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "HotelOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "location" } },
                                  { kind: "Field", name: { kind: "Name", value: "starRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRatingQualifierText" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewCount" } },
                                  { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "discountBadge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mod" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "modTagText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modAvailabilityText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modSignInText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "signInUrl" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "returnPath" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "relativePath" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FlightOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "airlineImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "tripType" } },
                                  { kind: "Field", name: { kind: "Name", value: "flightClass" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PackageOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "airlineImage" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hotelOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "location" } },
                                        { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ActivityOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "duration" } },
                                  { kind: "Field", name: { kind: "Name", value: "durationLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "province" } },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "responseId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "referrer" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CampaignByIdQueryQuery, CampaignByIdQueryQueryVariables>;
export const CampaignRecommendationsWithOffersQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CampaignRecommendationsWithOffersQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numCampaigns" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pageNumberOfCampaigns" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "maxNumberOfCampaigns" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "productType" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "CampaignProductType" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "recommendationType" } },
          type: { kind: "ListType", type: { kind: "NamedType", name: { kind: "Name", value: "RecommendedType" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UserLocationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userTripState" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "campaignRecommendations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "numberOfCampaigns" },
                value: { kind: "Variable", name: { kind: "Name", value: "numCampaigns" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pageNumber" },
                value: { kind: "Variable", name: { kind: "Name", value: "pageNumberOfCampaigns" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "maxNumberOfCampaigns" },
                value: { kind: "Variable", name: { kind: "Name", value: "maxNumberOfCampaigns" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "productType" },
                value: { kind: "Variable", name: { kind: "Name", value: "productType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "recommendationType" },
                value: { kind: "Variable", name: { kind: "Name", value: "recommendationType" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userLocation" },
                value: { kind: "Variable", name: { kind: "Name", value: "userLocation" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "referrer" },
                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "includeDeals" },
                value: { kind: "BooleanValue", value: true },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "userTripState" },
                value: { kind: "Variable", name: { kind: "Name", value: "userTripState" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "enableLivePricing" },
                value: { kind: "Variable", name: { kind: "Name", value: "enableLivePricing" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "campaigns" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "campaignId" },
                        name: { kind: "Name", value: "id" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "headline" } },
                      { kind: "Field", name: { kind: "Name", value: "fromOriginHeaderText" } },
                      { kind: "Field", name: { kind: "Name", value: "termsAndConditions" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "short" } },
                            { kind: "Field", name: { kind: "Name", value: "long" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "productTypes" } },
                      { kind: "Field", name: { kind: "Name", value: "recommendedType" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "offers" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "secondaryContent" } },
                            {
                              kind: "InlineFragment",
                              typeCondition: {
                                kind: "NamedType",
                                name: { kind: "Name", value: "DestinationOfferItem" },
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "affinities" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "HotelOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "location" } },
                                  { kind: "Field", name: { kind: "Name", value: "starRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRating" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewRatingQualifierText" } },
                                  { kind: "Field", name: { kind: "Name", value: "guestReviewCount" } },
                                  { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "discountBadge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mod" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "modTagText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modAvailabilityText" } },
                                        { kind: "Field", name: { kind: "Name", value: "modSignInText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "signInUrl" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "returnPath" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "returnPath" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "relativePath" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lodgingOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "FlightOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "airlineImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                  { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "tripType" } },
                                  { kind: "Field", name: { kind: "Name", value: "flightClass" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PackageOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "flightOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "airlineName" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "airlineImage" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "originCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "originAirportCode" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationCity" } },
                                        { kind: "Field", name: { kind: "Name", value: "destinationAirportCode" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "hotelOfferItem" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "location" } },
                                        { kind: "Field", name: { kind: "Name", value: "vipMessage" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "packageOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "prefixText" } },
                                              { kind: "Field", name: { kind: "Name", value: "checkPriceText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                ],
                              },
                            },
                            {
                              kind: "InlineFragment",
                              typeCondition: { kind: "NamedType", name: { kind: "Name", value: "ActivityOfferItem" } },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "badge" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "text" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  { kind: "Field", name: { kind: "Name", value: "duration" } },
                                  { kind: "Field", name: { kind: "Name", value: "durationLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "province" } },
                                  { kind: "Field", name: { kind: "Name", value: "dates" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCancellationMessage" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "activityOffer" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "price" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                              { kind: "Field", name: { kind: "Name", value: "disclaimerText" } },
                                              { kind: "Field", name: { kind: "Name", value: "allyText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "strikeThroughPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "value" } },
                                              { kind: "Field", name: { kind: "Name", value: "qualifierText" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "actionLink" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "href" } },
                                              { kind: "Field", name: { kind: "Name", value: "text" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "responseId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "paginationInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "referrer" } },
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CampaignRecommendationsWithOffersQueryQuery,
  CampaignRecommendationsWithOffersQueryQueryVariables
>;
export const CustomerPropertySearchByIdQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomerPropertySearchByIdQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "infositeURL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "options" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "displayPrice" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                        { kind: "Field", name: { kind: "Name", value: "amount" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "currencyInfo" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                              { kind: "Field", name: { kind: "Name", value: "code" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchCriteria" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dateRange" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "checkInDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "isoFormat" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "checkOutDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "isoFormat" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerPropertySearchByIdQueryQuery, CustomerPropertySearchByIdQueryQueryVariables>;
export const CustomerSearchHistoryAllQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomerSearchHistoryAllQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "HistoryType" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "customer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchHistory" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "types" },
                      value: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "numberOfResults" },
                      value: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "localizedDateRange" } },
                            { kind: "Field", name: { kind: "Name", value: "localizedAsOfDateTime" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastViewedDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                  { kind: "Field", name: { kind: "Name", value: "hour" } },
                                  { kind: "Field", name: { kind: "Name", value: "minute" } },
                                  { kind: "Field", name: { kind: "Name", value: "second" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalSearchPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currencyInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "code" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryActivitySearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "regionDetail" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryActivityDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "duration" } },
                                        { kind: "Field", name: { kind: "Name", value: "leadTicketType" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "imageUrl" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "description" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryFlightSearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "localizedFlightName" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                        { kind: "Field", name: { kind: "Name", value: "routeType" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "flightPassengers" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "adults" } },
                                              { kind: "Field", name: { kind: "Name", value: "agesOfChildren" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryFlightDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "localizedFlightName" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                        { kind: "Field", name: { kind: "Name", value: "routeType" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "flightPassengers" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "adults" } },
                                              { kind: "Field", name: { kind: "Name", value: "agesOfChildren" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPackageSearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "localizedPackageName" } },
                                        { kind: "Field", name: { kind: "Name", value: "localizedRegionName" } },
                                        { kind: "Field", name: { kind: "Name", value: "localizedPackagesTemplate" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPropertySearch" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "regionDetail" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "localizedRoomsTemplate" } },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPropertyDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "regionId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "summary" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "regionDetail" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "reviewInfo" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "summary" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "averageOverallRating" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "totalCount" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "formattedRating" } },
                                        { kind: "Field", name: { kind: "Name", value: "formattedReviewCount" } },
                                        { kind: "Field", name: { kind: "Name", value: "propertyId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "gallery" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "featured" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "description" } },
                                                    { kind: "Field", name: { kind: "Name", value: "url" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "searchUrl" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerSearchHistoryAllQueryQuery, CustomerSearchHistoryAllQueryQueryVariables>;
export const CustomerSearchHistoryQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CustomerSearchHistoryQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
          type: {
            kind: "ListType",
            type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "HistoryType" } } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "customer" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchHistory" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "types" },
                      value: { kind: "Variable", name: { kind: "Name", value: "lobs" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "numberOfResults" },
                      value: { kind: "Variable", name: { kind: "Name", value: "numberOfResults" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "items" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "startDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endDate" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "raw" } },
                                  { kind: "Field", name: { kind: "Name", value: "year" } },
                                  { kind: "Field", name: { kind: "Name", value: "month" } },
                                  { kind: "Field", name: { kind: "Name", value: "day" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "originalSearchPrice" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "currencyInfo" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "code" } },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "productInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "HistoryPropertyDetail" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "regionId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "propertySummary" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "context" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "context" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "propertyId" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "propertyImageGallery" },
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: { kind: "Name", value: "context" },
                                              value: { kind: "Variable", name: { kind: "Name", value: "context" } },
                                            },
                                          ],
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "featured" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "image" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "url" } },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CustomerSearchHistoryQueryQuery, CustomerSearchHistoryQueryQueryVariables>;
export const DxPropertySearchQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "DxPropertySearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyIds" },
                      value: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "EnumValue", value: "REVIEW" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "infositeURL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lead" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                            { kind: "Field", name: { kind: "Name", value: "total" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gallery" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<DxPropertySearchQueryQuery, DxPropertySearchQueryQueryVariables>;
export const MapPropertySearchByLatLongDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MapPropertySearchByLatLong" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "EnumValue", value: "DISTANCE" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "infositeURL" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "price" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lead" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                  { kind: "Field", name: { kind: "Name", value: "amount" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "reviews" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "score" } },
                            { kind: "Field", name: { kind: "Name", value: "total" } },
                            { kind: "Field", name: { kind: "Name", value: "localizedSubtitle" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "star" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mapMarker" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "latLong" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "latitude" } },
                                  { kind: "Field", name: { kind: "Name", value: "longitude" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "messages" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subtitle" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MapPropertySearchByLatLongQuery, MapPropertySearchByLatLongQueryVariables>;
export const MerchCouponsQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MerchCouponsQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "couponIds" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "merchCoupons" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "couponIds" },
                value: { kind: "Variable", name: { kind: "Name", value: "couponIds" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coupons" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CouponDetailFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CouponDetailFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "CouponDetail" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "statusMessage" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "termsAndConditions" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "text" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "informationUrl" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MerchCouponsQueryQuery, MerchCouponsQueryQueryVariables>;
export const MojoLandingQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MojoLandingQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "mojoContext" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MojoContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "url" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "URL" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mojoLandingSection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "mojoContext" },
                value: { kind: "Variable", name: { kind: "Name", value: "mojoContext" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "url" },
                value: { kind: "Variable", name: { kind: "Name", value: "url" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "data" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MojoLandingQueryQuery, MojoLandingQueryQueryVariables>;
export const MojoQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MojoQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "mojoContext" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MojoContextInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "mojoSection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "mojoContext" },
                value: { kind: "Variable", name: { kind: "Name", value: "mojoContext" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "data" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<MojoQueryQuery, MojoQueryQueryVariables>;
export const PrefetchSearchResultsDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PrefetchSearchResults" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "shoppingContext" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ShoppingContextInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "preloadPropertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "shoppingContext" },
                value: { kind: "Variable", name: { kind: "Name", value: "shoppingContext" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "criteria" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primary" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "destination" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "regionId" } },
                                  { kind: "Field", name: { kind: "Name", value: "regionName" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PrefetchSearchResultsMutation, PrefetchSearchResultsMutationVariables>;
export const PropertyFilterSearchQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PropertyFilterSearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sortAndFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "selected" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "priceFilterPills" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "aggregate" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "label" } }],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "propertySearchListings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MessageResult" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "icon" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "text" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subtitle" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "text" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "index" } },
                            { kind: "Field", name: { kind: "Name", value: "type" } },
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                          ],
                        },
                      },
                      {
                        kind: "InlineFragment",
                        typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Property" } },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "addressMessaging" } },
                            { kind: "Field", name: { kind: "Name", value: "star" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "offerSummary" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "messages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "type" } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "propertyImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "url" } }],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "destinationInfo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "distanceFromMessaging" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "distanceFromDestination" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "unit" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mapMarker" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "latLong" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "latitude" } },
                                        { kind: "Field", name: { kind: "Name", value: "longitude" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviews" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "score" } },
                                  { kind: "Field", name: { kind: "Name", value: "total" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "price" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "priceMessages" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [{ kind: "Field", name: { kind: "Name", value: "value" } }],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "displayPrice" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "amount" } },
                                              { kind: "Field", name: { kind: "Name", value: "formatted" } },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "currencyInfo" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "code" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "propertyDetailsLink" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uri" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "relativePath" } },
                                        { kind: "Field", name: { kind: "Name", value: "value" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "structureType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PropertyFilterSearchQueryQuery, PropertyFilterSearchQueryQueryVariables>;
export const PropertyFiltersQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PropertyFiltersQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "destination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DestinationInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PropertySearchCriteriaInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: { kind: "Variable", name: { kind: "Name", value: "destination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "criteria" },
                value: { kind: "Variable", name: { kind: "Name", value: "criteria" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sortAndFilter" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "options" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "sortAndFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "label" } },
                                  { kind: "Field", name: { kind: "Name", value: "subLabel" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "max" } },
                                  { kind: "Field", name: { kind: "Name", value: "min" } },
                                  { kind: "Field", name: { kind: "Name", value: "step" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "options" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "label" } },
                                        { kind: "Field", name: { kind: "Name", value: "optionValue" } },
                                        { kind: "Field", name: { kind: "Name", value: "filterCategory" } },
                                        { kind: "Field", name: { kind: "Name", value: "description" } },
                                        { kind: "Field", name: { kind: "Name", value: "selectedLabel" } },
                                        { kind: "Field", name: { kind: "Name", value: "isSelected" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "range" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "max" } },
                                              { kind: "Field", name: { kind: "Name", value: "min" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PropertyFiltersQueryQuery, PropertyFiltersQueryQueryVariables>;
export const QueryPropertyHeroImageDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QueryPropertyHeroImage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertyInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "propertyId" },
                value: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "gallery" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "featured" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<QueryPropertyHeroImageQuery, QueryPropertyHeroImageQueryVariables>;
export const QueryPropertyReviewsHeadlineDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QueryPropertyReviewsHeadline" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertyInfo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "propertyId" },
                value: { kind: "Variable", name: { kind: "Name", value: "propertyId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "infositeUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "summary" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "overview" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "starRating" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "location" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "address" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "city" } },
                                  { kind: "Field", name: { kind: "Name", value: "countryCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstAddressLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "secondAddressLine" } },
                                  { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                                  { kind: "Field", name: { kind: "Name", value: "province" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<QueryPropertyReviewsHeadlineQuery, QueryPropertyReviewsHeadlineQueryVariables>;
export const PropertySearchQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "PropertySearchQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertySearch" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "destination" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyIds" },
                      value: { kind: "Variable", name: { kind: "Name", value: "hotelIds" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gallery" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "url" } },
                            { kind: "Field", name: { kind: "Name", value: "description" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<PropertySearchQueryQuery, PropertySearchQueryQueryVariables>;
export const TravelAgentSearchWizardConfigQueryDocument = ({
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TravelAgentSearchWizardConfigQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "context" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ContextInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "travelAgentSearchWizardConfig" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "context" },
                value: { kind: "Variable", name: { kind: "Name", value: "context" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "packageRatesEnabled" } },
                { kind: "Field", name: { kind: "Name", value: "enabledLobs" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "packageRatesDetail" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "heading" } },
                      { kind: "Field", name: { kind: "Name", value: "termsAndConditions" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  TravelAgentSearchWizardConfigQueryQuery,
  TravelAgentSearchWizardConfigQueryQueryVariables
>;
