import * as React from "react";

import { EGDSCard, EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSFigureAspectRatioType, EGDSImage, EGDSFigure } from "@egds/react-core/images";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridTrack,
  EGDSLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { EGDSLayoutFlex, EGDSLayoutFlexItem, EGDSLayoutFlexProps } from "@egds/react-core/layout-flex";
import { EGDSScrim, EGDSScrimType, EGDSScrimTypes } from "@egds/react-core/scrim";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { SpacingSize } from "src/components/utility/FlexAttributesUtil";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";

export type ScrimCardProps = {
  altText?: string;
  id: string;
  dataTestId?: string;
  className: string;
  titleSlot?: JSX.Element | JSX.Element[];
  aspectRatio: EGDSFigureAspectRatioType;
  backgroundImageURL: string;
  subtitleSlot?: JSX.Element | JSX.Element[];
  footerSlot?: JSX.Element | JSX.Element[];
  scrimType?: EGDSScrimTypes;
  spacing?: EGDSSpacingProps;
  interiorSpacing?: EGDSSpacingProps;
  domRef?: React.RefObject<HTMLElement> | null;
  justifyContent?: EGDSLayoutFlexProps["justifyContent"];
  mainGridColumns?: EGDSLayoutConditionalGridTrack;
  headingCols?: EGDSLayoutConditionalGridSpan;
  padding?: string;
  border?: boolean;
  verticallyCenter?: boolean;
  lazyLoading?: "lazy" | "eager" | "off";
};

export const ScrimCard: React.FC<ScrimCardProps> = ({
  id,
  dataTestId = "scrim-card",
  className,
  titleSlot,
  aspectRatio,
  backgroundImageURL,
  altText,
  subtitleSlot = null,
  footerSlot = null,
  scrimType = EGDSScrimType.OVERLAY,
  spacing = {},
  interiorSpacing = { padding: { inline: "six" } },
  domRef = null,
  justifyContent = "space-between",
  mainGridColumns = { small: 1, medium: 2 },
  headingCols = { small: 1, medium: 2 },
  padding,
  border = true,
  verticallyCenter = false,
  lazyLoading = "off",
}) => {
  const subheadingCols: EGDSLayoutConditionalGridSpan = { small: 1, medium: 1 };
  const hasNoPadding = padding === SpacingSize.NONE;
  const blockendSpacing = hasNoPadding ? "unset" : "six";
  const layoutPosition = verticallyCenter ? "center" : "zero";
  const layoutType = verticallyCenter ? "absolute" : "relative";

  return (
    <EGDSSpacing padding={{ blockend: blockendSpacing }}>
      <section data-testid={dataTestId} id={id} ref={domRef}>
        <EGDSSpacing {...spacing}>
          <EGDSCard className={className} border={border}>
            <EGDSCardContentSection padded={false}>
              <EGDSFigure ratio={aspectRatio}>
                <EGDSImage alt={altText} src={backgroundImageURL} lazyLoading={lazyLoading} />
                <EGDSScrim type={scrimType}>
                  <EGDSSpacing {...interiorSpacing}>
                    <EGDSLayoutPosition type={layoutType} position={{ top: layoutPosition }} cloneElement>
                      <EGDSLayoutFlex direction="column" justifyContent={justifyContent} style={{ height: "inherit" }}>
                        <EGDSLayoutFlexItem>
                          <div>
                            {titleSlot && (
                              <EGDSLayoutGrid columns={mainGridColumns}>
                                <EGDSLayoutGridItem colSpan={headingCols}>{titleSlot}</EGDSLayoutGridItem>
                              </EGDSLayoutGrid>
                            )}

                            {subtitleSlot && (
                              <EGDSSpacing padding={{ blockstart: "three" }}>
                                <EGDSLayoutGrid columns={mainGridColumns}>
                                  <EGDSLayoutGridItem colSpan={subheadingCols}>{subtitleSlot}</EGDSLayoutGridItem>
                                </EGDSLayoutGrid>
                              </EGDSSpacing>
                            )}
                          </div>
                        </EGDSLayoutFlexItem>

                        {footerSlot && (
                          <EGDSLayoutFlexItem>
                            <div>{footerSlot}</div>
                          </EGDSLayoutFlexItem>
                        )}
                      </EGDSLayoutFlex>
                    </EGDSLayoutPosition>
                  </EGDSSpacing>
                </EGDSScrim>
              </EGDSFigure>
            </EGDSCardContentSection>
          </EGDSCard>
        </EGDSSpacing>
      </section>
    </EGDSSpacing>
  );
};
