import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSText } from "@egds/react-core/text";

import { Hotel } from "typings/microserviceModels/hotels-flex-module";

interface ValuePropositionProps {
  hotel: Hotel;
  className?: string;
}

const ValueProposition = React.memo(({ hotel, className = "" }: ValuePropositionProps) => {
  const { freeCancellation, payLater } = hotel;

  if (!(freeCancellation || payLater)) {
    return null;
  }

  const { formatText } = useLocalization();

  return (
    <EGDSLayoutFlex direction="column" className={`ValueProposition ${className}`}>
      {freeCancellation && <EGDSText size={300}>{formatText("hotels.freeCancellation")}</EGDSText>}
      {payLater && <EGDSText size={300}>{formatText("hotels.reserveNowPayLater")}</EGDSText>}
    </EGDSLayoutFlex>
  );
});

export default ValueProposition;
