import * as React from "react";
import { observer } from "mobx-react";

import {
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridStartEnd,
} from "@egds/react-core/layout-grid";

import { LOBState } from "stores/wizard/state";
import { TravelersFieldConfig } from "stores/wizard/config";
import { travelersState } from "stores/wizard/state/global/travelers";

import { TRAVELERS_HIDDEN_INPUT_TYPE } from "components/shared/TravelersField";

import { TravelersField } from "../TravelersField/components/TravelersField";
import { NoRehydratationValues, TravelersTriggerTypes } from "../TravelersField/typings";
import { travelersMetadata } from "../TravelersField/utils";

import { Travelers as TravelersType } from "src/components/shared/TravelersField/typings";

export interface TravelersProps {
  colSpan?: EGDSLayoutConditionalGridSpan;
  colStart?: EGDSLayoutConditionalGridStartEnd;
  colEnd?: EGDSLayoutConditionalGridStartEnd;
  lobState: LOBState;
  config?: TravelersFieldConfig;
  hidden?: boolean;
  tripLobType?: string;
  noRehydratationValues?: NoRehydratationValues;
  triggerType?: TravelersTriggerTypes;
  isApiCallEnabled?: boolean;
  handleMenuTriggerState?: (menuState: boolean) => void;
  submitForm?: () => void;
}

export const Travelers = observer((props: TravelersProps) => {
  if (props.hidden) {
    return null;
  }

  const config: TravelersFieldConfig = props.config ? props.config : props.lobState.config.travelers;
  const travelers = props.lobState.travelers ? props.lobState.travelers : travelersState;

  const hotelPackageTravelersMetadata = props.lobState.hotelPackageTravelersMetadata
    ? props.lobState.hotelPackageTravelersMetadata
    : travelersMetadata(travelers.hotelPackage);

  const hotelTravelersMetadata = props.lobState.hotelTravelersMetadata
    ? props.lobState.hotelTravelersMetadata
    : travelersMetadata(travelers.hotel);

  const nonHotelTravelersMetadata = props.lobState.nonHotelTravelersMetadata
    ? props.lobState.nonHotelTravelersMetadata
    : travelersMetadata(travelers.nonHotel);

  const cruiseTravelersMetadata = props.lobState.cruiseTravelersMetadata
    ? props.lobState.cruiseTravelersMetadata
    : travelersMetadata(travelers.cruise);

  const saveTravelers = (travelers: TravelersType) => {
    const updateTravelers = config.withRooms
      ? props.lobState.updateHotelTravelersSelection!
      : props.lobState.updateNonHotelTravelersSelection!;

    updateTravelers(travelers);
  };

  const onSave = (type: "SUBMIT" | "CHANGE", travelers: TravelersType) => {
    saveTravelers(travelers);
    if (type === "SUBMIT") {
      props.submitForm?.();
    }
  };

  return (
    <EGDSLayoutGridItem colSpan={props.colSpan} colStart={props.colStart} colEnd={props.colEnd}>
      <div className="TravelersField">
        <TravelersField
          travelers={
            config.withRooms && props.tripLobType
              ? travelers.hotelPackage
              : config.withRooms
              ? travelers.hotel
              : config.hiddenInputType === TRAVELERS_HIDDEN_INPUT_TYPE.CRUISE_SEARCH
              ? travelers.cruise
              : travelers.nonHotel
          }
          config={config}
          onSave={onSave}
          metadata={
            config.withRooms && props.tripLobType
              ? hotelPackageTravelersMetadata
              : config.withRooms
              ? hotelTravelersMetadata
              : config.hiddenInputType === TRAVELERS_HIDDEN_INPUT_TYPE.CRUISE_SEARCH
              ? cruiseTravelersMetadata
              : nonHotelTravelersMetadata
          }
          tripLobType={props.tripLobType}
          setChange={props.lobState.setTravelersValue}
          isChanged={props.lobState.travelersValueChanged}
          noRehydratationValues={props.noRehydratationValues}
          lobState={props.lobState}
          triggerType={props.triggerType}
          isApiCallEnabled={props.isApiCallEnabled}
          handleMenuTriggerState={props.handleMenuTriggerState}
        />
      </div>
    </EGDSLayoutGridItem>
  );
});
