import * as React from "react";
import { WidthColumnProps } from "./typings";
import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";
import { Viewport, ViewSmall, ViewMedium, ViewLarge, ViewSizes } from "@shared-ui/viewport-context";
import { ExtendedTemplateComponent } from "src/stores/ExperienceTemplateStore/typings";

export const GridContainerColumnsContext = React.createContext<number | undefined>(undefined);

export const widthVariableViewport = {
  [ViewSizes.SMALL]: "mobileWidth",
  [ViewSizes.MEDIUM]: "tabletWidth",
  [ViewSizes.LARGE]: "width",
};

const columnsForWidthMapping: WidthColumnProps = {
  "25": 4,
  "33": 3,
  "50": 2,
  "66": 3,
  "75": 4,
  "100": 1,
};

type HighestWidthViewport = Record<ViewSizes.SMALL | ViewSizes.MEDIUM | ViewSizes.LARGE, string | undefined>;

export const GridContainer: React.FC<{ templateComponent: ExtendedTemplateComponent }> = ({
  templateComponent,
  children,
}) => {
  const highestWidthViewport: HighestWidthViewport = {
    [ViewSizes.SMALL]: undefined,
    [ViewSizes.MEDIUM]: undefined,
    [ViewSizes.LARGE]: undefined,
  };

  templateComponent.children?.forEach(({ config }) => {
    Object.keys(widthVariableViewport).forEach((viewPortWidth: keyof typeof widthVariableViewport) => {
      // @ts-ignore
      const widthPercentage = config[widthVariableViewport[viewPortWidth]];
      const highestWidth = highestWidthViewport[viewPortWidth];
      if (widthPercentage && +widthPercentage < 100 && (!highestWidth || +widthPercentage < +highestWidth)) {
        highestWidthViewport[viewPortWidth] = widthPercentage;
      }
    });
  });

  const gridContainerContent = (viewSize: ViewSizes) => {
    if (
      !highestWidthViewport[viewSize as keyof HighestWidthViewport] &&
      viewSize === ViewSizes.LARGE &&
      templateComponent.config.view === "grid-container"
    ) {
      highestWidthViewport[viewSize] = "100";
    }
    const viewPortWidth: string | undefined = highestWidthViewport[viewSize as keyof HighestWidthViewport];

    return (
      <>
        {!viewPortWidth ? (
          <>{children}</>
        ) : (
          <GridContainerColumnsContext.Provider value={columnsForWidthMapping[viewPortWidth as keyof WidthColumnProps]}>
            <EGDSLayoutGrid
              className="GridContainer"
              columns={columnsForWidthMapping[viewPortWidth as keyof WidthColumnProps]}
              space={
                templateComponent.config.view === "grid-container"
                  ? {
                      column: "four",
                    }
                  : undefined
              }
            >
              {children}
            </EGDSLayoutGrid>
          </GridContainerColumnsContext.Provider>
        )}
      </>
    );
  };

  return (
    <Viewport>
      <ViewLarge>{gridContainerContent(ViewSizes.LARGE)}</ViewLarge>
      <ViewMedium>{gridContainerContent(ViewSizes.MEDIUM)}</ViewMedium>
      <ViewSmall>{gridContainerContent(ViewSizes.SMALL)}</ViewSmall>
    </Viewport>
  );
};
