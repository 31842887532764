import { EGDSFigureAspectRatioType } from "@egds/react-core/images";

export const convertAspectRatioToType = (
  ratio: string,
  defaultValue: EGDSFigureAspectRatioType | null
): EGDSFigureAspectRatioType => {
  switch (ratio) {
    case "1-1":
      return EGDSFigureAspectRatioType.R1_1;
    case "4-3":
      return EGDSFigureAspectRatioType.R4_3;
    case "3-2":
      return EGDSFigureAspectRatioType.R3_2;
    case "16-9":
      return EGDSFigureAspectRatioType.R16_9;
    case "21-9":
      return EGDSFigureAspectRatioType.R21_9;
    case "3-4":
      return EGDSFigureAspectRatioType.R3_4;
    case "4-1":
      return EGDSFigureAspectRatioType.R4_1;
    default:
      return defaultValue || EGDSFigureAspectRatioType.R1_1;
  }
};
