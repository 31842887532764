import * as React from "react";
import { ItemKeyHelper } from "src/components/utility/ItemKeyHelper";
import { DurationProps } from "./typings";
import { EGDSSelect } from "@egds/react-core/input";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { ThirdPPDuration } from "src/stores/wizard/state/thirdPartyPackage/typings";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

export const Duration = observer((props: DurationProps) => {
  const { durations, colSpan, changeDuration, invalidDurationKey, inputName, inputValue, placeholderKey } = props;
  const optionKeyHelper = new ItemKeyHelper("duration-options");
  const { formatText } = useLocalization();
  const placeholderText: string = formatText(placeholderKey);
  const invalidText: string = formatText(invalidDurationKey);

  return (
    <EGDSLayoutGridItem colSpan={colSpan}>
      <div data-testid="threepp-duration">
        <input type="hidden" data-testid="threepp-duration-input" name={inputName} value={inputValue} />
        <EGDSSelect
          icon="access_time"
          id="threepp-duration-select"
          label={placeholderText}
          placeholder={placeholderText}
          onChange={changeDuration}
          invalid={invalidText}
          value={inputValue || undefined}
        >
          {durations.map((duration: ThirdPPDuration) => {
            const { value, text } = duration;

            return (
              <option value={value} key={optionKeyHelper.next()}>
                {text}
              </option>
            );
          })}
        </EGDSSelect>
      </div>
    </EGDSLayoutGridItem>
  );
});
