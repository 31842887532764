import * as React from "react";
import { ViewLarge, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EGDSSheet, EGDSSheetContent } from "@egds/react-core/sheet";
import { PositionType, EGDSMenu, EGDSMenuContainer, EGDSMenuTrigger } from "@egds/react-core/menu";
import { EGDSToolbar, EGDSToolbarProps } from "@egds/react-core/toolbar";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { AdaptiveMenuDialogTrigger } from "./AdaptiveMenuDialogTrigger";

export interface AdaptiveMenuDialogProps {
  dialogWrapper: (children: any) => JSX.Element;
  showAdaptive: boolean;
  menuPosition?: PositionType;
  adaptiveTrigger?: () => JSX.Element;
  toolbar?: EGDSToolbarProps;
  children?: React.ReactElement;
  handleMenuClick?: (e?: any, eventData?: any) => void;
  handleMenuDismiss?: (e?: any, eventData?: any) => void;
  handleDialogClick?: (e?: any, eventData?: any) => void;
}

export class AdaptiveMenuDialog extends React.PureComponent<AdaptiveMenuDialogProps> {
  constructor(props: AdaptiveMenuDialogProps) {
    super(props);

    this.renderDialog = this.renderDialog.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuDismiss = this.handleMenuDismiss.bind(this);
  }

  public renderDialog(): JSX.Element {
    const { children, showAdaptive, toolbar } = this.props;
    return (
      <EGDSSheet type="full" isVisible={showAdaptive} triggerRef={React.createRef<HTMLElement>()}>
        {toolbar && <EGDSToolbar {...toolbar} />}
        <EGDSSheetContent>{children}</EGDSSheetContent>
      </EGDSSheet>
    );
  }

  public handleMenuClick(event: React.MouseEvent) {
    if (this.props.handleMenuClick) {
      this.props.handleMenuClick(event);
    }
  }

  public handleMenuDismiss() {
    if (this.props.handleMenuDismiss) {
      this.props.handleMenuDismiss();
    }
  }

  public renderMenu(props: AdaptiveMenuDialogProps): JSX.Element {
    const { adaptiveTrigger, menuPosition, showAdaptive } = props;

    return (
      <EGDSMenu
        id="adaptive-menu"
        isOpen={showAdaptive}
        onTriggerClick={this.handleMenuClick}
        onDismiss={this.handleMenuDismiss}
      >
        <EGDSMenuTrigger>{adaptiveTrigger!()}</EGDSMenuTrigger>
        <EGDSMenuContainer
          autoPosition
          maxHeight={4000}
          position={menuPosition}
          width={376}
          intersectionObserverRoot="#adaptive-menu"
        >
          <EGDSSpacing padding="three">
            <div>{this.props.children}</div>
          </EGDSSpacing>
        </EGDSMenuContainer>
      </EGDSMenu>
    );
  }

  public render() {
    const { dialogWrapper, handleDialogClick, adaptiveTrigger } = this.props;

    return (
      <div>
        <Viewport>
          <ViewSmall>
            <React.Fragment>
              {dialogWrapper(this.renderDialog())}
              <AdaptiveMenuDialogTrigger onClick={handleDialogClick} trigger={adaptiveTrigger!()} />
            </React.Fragment>
          </ViewSmall>
          <ViewLarge>{this.renderMenu(this.props)}</ViewLarge>
        </Viewport>
      </div>
    );
  }
}
