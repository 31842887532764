import * as React from "react";

import { EGDSFigure, EGDSImage } from "@egds/react-core/images";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSCard, EGDSCardLink, EGDSCardContentSection } from "@egds/react-core/cards";

import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { AttributionText } from "components/shared/AttributionText/AttributionText";
import { useLocalization } from "@shared-ui/localization-context";
import { DestinationCardProps } from "components/shared/DestinationCardView/typings";
import { EGDSSpacing } from "@egds/react-core/spacing";

const DestinationCard = (props: DestinationCardProps) => {
  const { name, description, url, dxImage, localiseKey, moduleName } = props;
  const { formatText } = useLocalization();
  const isDescriptionPresent = Boolean(description?.length);

  return (
    <EGDSCard border>
      <EGDSFigure ratio={EGDSFigure.AspectRatio.R16_9} className="uitk-card-roundcorner-all">
        <EGDSImage src={dxImage.mediaUrl} alt={dxImage.alt} placeholderImage lazyLoading="lazy" />
        <AttributionText
          source={dxImage.source}
          origin={dxImage.origin}
          attribUrl={dxImage.attribUrl}
          authorName={dxImage.authorName}
          authorUrl={dxImage.authorUrl}
          licenseSuite={dxImage.licenseSuite}
          licenseName={dxImage.licenseName}
          licenseUrl={dxImage.licenseUrl}
        />
      </EGDSFigure>
      <EGDSCardContentSection>
        <EGDSHeading tag="h3" size={6}>
          {name}
        </EGDSHeading>

        {isDescriptionPresent && (
          <EGDSSpacing margin={{ blockstart: "two" }}>
            <EGDSText size={300}>{description?.join("")}</EGDSText>
          </EGDSSpacing>
        )}
      </EGDSCardContentSection>
      <EGDSCardLink>
        <TrackedLink
          aria-label={formatText(localiseKey, name)}
          data-testid="destinationCardLink"
          moduleName={moduleName}
          rfrr="link-destination"
          href={url}
        />
      </EGDSCardLink>
    </EGDSCard>
  );
};

export default DestinationCard;
