import * as React from "react";

import { Experiment, ExperimentControl, ExperimentVariant, useExperiment } from "@shared-ui/experiment-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";

import { RandomAccessOne } from "@shared-ui/retail-product-random-access-one";
import { ProductBexApiWrapper } from "src/components/shared/BexApiWrapper/ProductBexApiWrapper";

const WrappedRandomAccessOne = ProductBexApiWrapper(RandomAccessOne);

export const CheckInOutModule = ({ context }: { context: ExtendedContextStore }) => {
  const isShowingModularisedSection = useExperiment("PDP_MODULARISED_LOCATION_SECTION").exposure.bucket === 1;
  const additionalInputs = {
    featureId: "check-in-check-out",
  };
  const checkInOutExperiment = (
    <Experiment name="Add_check_in_and_out_times_" logExposure={true}>
      <ExperimentControl />
      <ExperimentVariant bucket={1}>
        <EGDSSpacing margin={{ blockend: "six" }}>
          <div>
            <WrappedRandomAccessOne context={context} additionalInputs={additionalInputs} />
          </div>
        </EGDSSpacing>
      </ExperimentVariant>
    </Experiment>
  );

  return isShowingModularisedSection ? (
    <EGDSLayoutGrid columns={{ small: 1, medium: 1, large: 2 }}>
      <EGDSLayoutGridItem colSpan={{ small: 1, medium: 1, large: 1 }}>{checkInOutExperiment}</EGDSLayoutGridItem>
    </EGDSLayoutGrid>
  ) : (
    checkInOutExperiment
  );
};
