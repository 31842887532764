import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";

import DestinationCard from "./DestinationCard";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { dxOptimizedImage } from "src/components/utility/DxOptimizedImage";

import { useLocalization } from "@shared-ui/localization-context";
import { CardsViewProps, Destination } from "components/shared/DestinationCardView/typings";
import { IsomorphicCarousel } from "../IsomorphicCarousel/IsomorphicCarousel";

const CardsView = (props: CardsViewProps) => {
  const { baseKey, destinations, localiseKey, moduleName } = props;
  const keyHelper = new ItemKeyHelper(baseKey);
  const { formatText } = useLocalization();

  const destinationCards = destinations.map((destination: Destination) => {
    const name = destination.localisedName || destination.name;

    const optimizedImage = dxOptimizedImage(destination.image, name, 512, 288);

    return (
      <DestinationCard
        key={keyHelper.next()}
        name={name}
        description={destination.description}
        url={destination.url}
        dxImage={optimizedImage}
        localiseKey={localiseKey}
        moduleName={moduleName}
      />
    );
  });

  return (
    <Viewport>
      <ViewSmall>
        <IsomorphicCarousel
          carouselName="DestinationCards"
          buttonText={{
            nextButton: formatText("carousel.item.next"),
            prevButton: formatText("carousel.item.prev"),
          }}
          itemsVisible={{ sm: 1, md: 1, lg: 1 }}
          pageBy={1}
          peek
        >
          {destinationCards}
        </IsomorphicCarousel>
      </ViewSmall>
      <ViewMedium>
        <EGDSLayoutGrid columns={{ medium: 2, large: 3, extra_large: 4 }} space="three">
          {destinationCards}
        </EGDSLayoutGrid>
      </ViewMedium>
    </Viewport>
  );
};

export default CardsView;
