import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { PropertyFilteredReviews } from "@shared-ui/lodging-reviews";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

import { EGDSSheet, EGDSSheetContent } from "@egds/react-core/sheet";
import { EGDSToolbar, EGDSToolbarProps } from "@egds/react-core/toolbar";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

const WrappedPropertyFilteredReviews = PropertyBexApiWrapper(PropertyFilteredReviews);

export const PropertyFilteredReviewsDialog: React.FC<
  ComponentProps & { onClose: () => void; triggerRef?: React.RefObject<HTMLElement> }
> = ({ context, onClose, triggerRef = null }) => {
  const { formatText } = useLocalization();

  const toolbarProps: EGDSToolbarProps = {
    toolbarTitle: formatText("reviews.dialog.title"),
    navigationContent: {
      navIconLabel: formatText("reviews.dialog.close.a11yLabel"),
      onClick: onClose,
    },
  };

  const sheet = (sheetType: "full" | "centered", dialogHeight?: number) => (
    <EGDSSheet type={sheetType} height={dialogHeight} isVisible triggerRef={triggerRef} returnFocusOnClose>
      <EGDSToolbar {...toolbarProps} />
      <EGDSSheetContent fullSheetContentMaxWidth="extra-large">
        <WrappedPropertyFilteredReviews context={context} />
      </EGDSSheetContent>
    </EGDSSheet>
  );

  return (
    <Viewport>
      <ViewSmall>{sheet("full")}</ViewSmall>
      <ViewMedium>{sheet("centered", 1200)}</ViewMedium>
    </Viewport>
  );
};
