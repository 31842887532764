import * as React from "react";

import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

import { LocationSection } from "@shared-ui/retail-product-location-section";
import { PropertySummaryGeo } from "@shared-ui/lodging-property-details";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ProductBexApiWrapper } from "components/shared/BexApiWrapper/ProductBexApiWrapper";
import { EGDSFigureAspectRatioType } from "@egds/react-core/images";

const WrappedSummaryGeo = PropertyBexApiWrapper(PropertySummaryGeo);
const WrappedProductLocationSection = ProductBexApiWrapper(LocationSection);

interface LocationSectionModuleProps {
  context: ExtendedContextStore;
  onClickMap: () => void;
  aspectRatio?: EGDSFigureAspectRatioType;
}

export const LocationSectionModule: React.FC<LocationSectionModuleProps> = ({ context, onClickMap, aspectRatio }) => (
  <Experiment name="PDP_LOCATION_SECTION_MIGRATION_WEB">
    <ExperimentControl>
      <WrappedSummaryGeo context={context} onClickMap={onClickMap} />
    </ExperimentControl>
    <ExperimentVariant bucket={1}>
      <WrappedProductLocationSection context={context} />
    </ExperimentVariant>
  </Experiment>
);
