import * as React from "react";

import DirectDistance from "./DirectDistance";
import GuestRating from "./GuestRating";
import { CONTENT_TYPE } from "components/flexComponents/Hotels/views/HotelsViews/HotelUtils";
import { EGDSTextProps } from "@egds/react-core/text";

import { HotelsFlexModuleResult, Hotel } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

interface DistanceOrRatingProps {
  model: HotelsFlexModuleResult;
  hotel: Hotel;
  context: ExtendedContextStore;
  showIcon?: boolean;
  alignment?: EGDSTextProps["align"];
}

const DistanceOrRating = ({ model, hotel, context, showIcon, alignment }: DistanceOrRatingProps) => {
  const { contentRecipe, showDistanceOrReviewRating } = model;

  if (!showDistanceOrReviewRating) {
    return null;
  }

  return contentRecipe === CONTENT_TYPE.REVIEW ? (
    <GuestRating hotel={hotel} alignment={alignment} />
  ) : (
    <DirectDistance hotel={hotel} context={context} showIcon={showIcon} alignment={alignment} />
  );
};

export default DistanceOrRating;
