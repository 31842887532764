import * as React from "react";
import { LOBState } from "src/stores/wizard/state";
import {
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridStartEnd,
} from "@egds/react-core/layout-grid";
import { EGDSButtonSize, EGDSPrimaryButton } from "@egds/react-core/button";
import { EGDSIconProps, EGDSIcon } from "@egds/react-core/icons";
import { useLocalization } from "@shared-ui/localization-context";
import { FlexClickTracker } from "src/components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { CobrandImage } from "components/shared/CobrandImage/CobrandImage";
import { GlobalConfig } from "stores/wizard/config";

const ButtonTracked = FlexClickTracker(EGDSPrimaryButton);

export interface SubmitButtonProps {
  lobState: LOBState;
  colSpan?: EGDSLayoutConditionalGridSpan;
  colStart?: EGDSLayoutConditionalGridStartEnd;
  colEnd?: EGDSLayoutConditionalGridStartEnd;
  className?: string;
  rfrr?: string;
  uisPrimeMicroMessages?: object[];
  globalConfig?: GlobalConfig;
  disabled?: boolean;
  moduleName?: string;
  testId?: string;
  iconName?: EGDSIconProps["name"];
}

export const SubmitButton: React.FunctionComponent<SubmitButtonProps> = ({
  colSpan,
  colStart,
  colEnd,
  lobState,
  className,
  rfrr,
  uisPrimeMicroMessages,
  globalConfig,
  disabled,
  moduleName,
  testId = "submit-button",
  iconName,
}) => {
  const { formatText } = useLocalization();
  const moduleNameType = moduleName || "StorefrontWizard";

  return (
    <EGDSLayoutGridItem colSpan={colSpan} colStart={colStart} colEnd={colEnd} className={className ?? ""}>
      <div>
        <ButtonTracked
          data-testid={testId}
          tag="button"
          type="submit"
          size={EGDSButtonSize.LARGE}
          isFullWidth
          moduleName={moduleNameType}
          rfrr={rfrr || `SearchClick_NA`}
          uisPrimeMicroMessages={uisPrimeMicroMessages}
          disabled={disabled}
        >
          {formatText(lobState.config.form.submitButton.locToken)} {iconName && <EGDSIcon name={iconName} />}
        </ButtonTracked>
        <CobrandImage globalConfig={globalConfig} />
      </div>
    </EGDSLayoutGridItem>
  );
};
