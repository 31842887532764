// External libraries
import * as React from "react";

// UITK
import { EGDSInput, EGDSInputProps } from "@egds/react-core/input";

export const TypeaheadFallbackInput: React.FC<EGDSInputProps> = (props) => {
  const { value } = props;
  const [inputValue, setInputValue] = React.useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <EGDSInput
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      type="text"
      spellCheck={false}
      icon="place"
      value={inputValue}
      onChange={handleChange}
      {...props}
    />
  );
};

/**
 * Custom Hook to lazy loading the UITK Typeahead package
 */
export const useLazyTypeahead = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [typeaheadModule, setTypeaheadModule] = React.useState<typeof import("@egds/react-core/typeahead") | null>(
    null
  );

  const loadTypeahead = React.useCallback(async () => {
    setIsLoading(true);
    const module = await import("@egds/react-core/typeahead");
    setTypeaheadModule(module);
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    loadTypeahead();
  }, [loadTypeahead]);

  return {
    typeaheadModule,
    isLoading,
  };
};
